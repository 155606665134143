import {RootState} from "../../../../../redux/Store";
import {createSelector} from "@reduxjs/toolkit";
import {FormField} from "shared-components/dist/models/form-field/FormField";
import {DateOption, SerializableDateOption} from "shared/dist/models/date-option/DateOption";
import {addDays, startOfDay} from "date-fns";
import {coverStartDateAsDateOptionSelector} from "../../../../quote/vehicle/your-cover/redux/selectors/CoverDetailsSelectors";

export const dateOfFirstPaymentAsSerializableDateOptionSelector = createSelector(
  coverStartDateAsDateOptionSelector,
  (dateOfFirstPayment: FormField<DateOption>): FormField<SerializableDateOption> => {
    return {
      ...dateOfFirstPayment,
      value: dateOfFirstPayment.value ? {
        ...dateOfFirstPayment.value,
        date: addDays(startOfDay(dateOfFirstPayment?.value.date), 30).toISOString()
      } : undefined
    };
  }
);

export const isMonthlyPaymentValid = createSelector(
  (state: RootState) => state.monthlyPayment.sortCode,
  (state: RootState) => state.monthlyPayment.accountNumber,
  (
    sortCodeField,
    accountNumber
  ): boolean =>
    [
      sortCodeField.status,
      accountNumber.status
    ].every(status => status === "success")
);
