import React, {FC} from "react";
import {isCurrentOwnerChanged} from "../../redux/VehicleDetailsSlice";
import {hasVehicleSummarySelector} from "../../redux/selectors/VehicleSummarySelectors";
import BooleanQuestion from "../../../../../../structure/questions/question/variants/boolean-question/BooleanQuestion";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import PurchaseDateQuestion from "./purchase-date/PurchaseDateQuestion";
import {logYourCarGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const HaveYouBoughtCarYetQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const isCurrentOwner = useAppSelector(state => state.vehicleDetails.isCurrentOwner);
  const isVisible = useAppSelector(hasVehicleSummarySelector);

  if (!isVisible) return null;

  const tooltip: TooltipProps = {
    description: "vehicleQuote.haveYouBoughtTheCarYetQuestion.tooltip.description",
  };

  function onBoughtCarYetChanged(value: boolean): void {
    dispatch(isCurrentOwnerChanged(value));

    logYourCarGoogleAnalyticsEvent({
      categorySuffix: "car_bought_yet",
      action: value,
    });
  }

  return (
    <>
      <BooleanQuestion
        id="have-you-bought-your-car-yet"
        title="vehicleQuote.haveYouBoughtTheCarYetQuestion.title"
        tooltip={tooltip}
        formField={isCurrentOwner}
        onAnswer={onBoughtCarYetChanged}
      />
      {isCurrentOwner.value === true && (
        <PurchaseDateQuestion/>
      )}
    </>
  );
};

export default HaveYouBoughtCarYetQuestion;
