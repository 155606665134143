import React, {FC} from "react";
import RadioConfirmation from "shared-components/dist/form/radio-confirmation/RadioConfirmation";
import SubQuestion from "../../SubQuestion";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {Status} from "shared-components/dist/status-indicator/Status";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";

interface Props {
  id: string;
  title: TranslationKey;
  value: boolean | undefined;
  status?: Status;
  errorMessage?: TranslationKey;
  onAnswer: (value: boolean) => void;
  tooltip?: TooltipProps;
}

const BooleanSubQuestion: FC<Props> = (
  {
    id,
    title,
    value,
    status,
    errorMessage,
    onAnswer,
    tooltip,
  }
) => (
  <SubQuestion
    id={id}
    title={title}
    status={status}
    errorMessage={errorMessage}
    tooltip={tooltip}
  >
    <RadioConfirmation
      name={id}
      selectedGroupOption={value}
      setSelectedGroupOption={onAnswer}
    />
  </SubQuestion>
);

export default BooleanSubQuestion;