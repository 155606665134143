import {AppThunkDispatch} from "../../../../../redux/Store";
import {RequiredFormField} from "shared-components/dist/models/form-field/FormField";
import {Modifications} from "../components/modifications/model/Modification";
import {
  flagUnansweredModificationsSubQuestions,
  modificationsInvalid
} from "../redux/VehicleDetailsSlice";
import {VehicleDetails} from "../redux/VehicleDetails";

export function flagUnansweredModificationsQuestions(
  {modifications}: VehicleDetails,
  dispatch: AppThunkDispatch
): void {
  if (hasModificationsUnanswered(modifications)) {
    dispatch(modificationsInvalid("quote.errors.questionIncomplete"));
  }
  if (modifications.value.isFormVisible) dispatch(flagUnansweredModificationsSubQuestions());
}

const hasModificationsUnanswered = (modifications: RequiredFormField<Modifications>): boolean => {
  return modifications.value.hasModifications === undefined && modifications.status === "default";
};
