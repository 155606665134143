/* eslint-disable react/display-name */
import {FC} from "react";
import "./VehicleDetailsList.css";
import {TranslationId, TranslationKey} from "shared-components/dist/translations/TranslationKey";
import FormattedMessage from "shared-components/src/translations/components/FormattedMessage";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {regLookupPriceBelowTrackerThresholdSelector} from "../../redux/selectors/RegLookupVehicleSelectors";
import {
  AdditionalVehicleDetails,
  Vehicle
} from "../../../../../../graphql/queries/vehicle-lookup/registration-lookup/model/Vehicle";
import {vehicleAssumptionsVisibleSelector} from "../../redux/selectors/VehiclePageStateSelectors";
import {useAppSelector} from "../../../../../../redux/Hooks";
import {AlarmInstallerOption} from "../../../shared/questions/vehicle-alarm/VehicleAlarmInstallerOptions";
import {AlarmInstaller} from "shared/dist/generated/graphql/resolvers-types";

interface Props {
  title: TranslationKey;
}

function additionalDetailsFrom(
  vehicle: Vehicle | undefined,
  estimatedValueUnderAssumeTrackerPrice: boolean
): AdditionalVehicleDetails {
  return {
    driverPosition: vehicle?.driverPosition,
    seats: vehicle?.seats,
    hasTracker: estimatedValueUnderAssumeTrackerPrice ? vehicle?.hasTracker : undefined,
    imported: vehicle?.imported,
    importType: vehicle?.importType,
    estimatedValue: vehicle?.estimatedValue,
    hasAlarm: vehicle?.hasAlarm,
    alarmFittedBy: vehicle?.alarmFittedBy
  };
}

function determineVehicleAlarmMessage(vehicleAlarmInstaller?: AlarmInstallerOption): TranslationId {
  if (vehicleAlarmInstaller === undefined) return "vehicleQuote.assumptions.listItems.alarm.notFitted";

  if (vehicleAlarmInstaller.id === AlarmInstaller.Unknown) return "vehicleQuote.assumptions.listItems.alarm.unknown";

  return "vehicleQuote.assumptions.listItems.alarm";
}

const VehicleDetailsList: FC<Props> = ({title}) => {
  const vehicle = useAppSelector(state => state.vehicleDetails.vehicleFromRegLookup);
  const isVisible = useAppSelector(vehicleAssumptionsVisibleSelector);
  const regLookupPriceBelowTrackerThreshold = useAppSelector(regLookupPriceBelowTrackerThresholdSelector);

  if (!vehicle || !isVisible) return null;

  const additionalDetails = additionalDetailsFrom(vehicle, regLookupPriceBelowTrackerThreshold);
  const showTracker = regLookupPriceBelowTrackerThreshold;

  return (
    <div className="vehicle-details-list">
      <h5>{lookupI18nString(title)}</h5>
      <div className="vehicle-details-list__container">
        <ul>
          {additionalDetails.driverPosition && (
            <FormattedMessage
              id="vehicleQuote.assumptions.listItems.driverPosition"
              values={{
                li: (msg: string) => <li>{msg}</li>,
                driverPosition: <b className="vehicle-details-list__lowercase">{additionalDetails.driverPosition}</b>
              }}
            />
          )}
          {additionalDetails.seats && (
            <FormattedMessage
              id="vehicleQuote.assumptions.listItems.seats"
              values={{
                li: (msg: string) => <li>{msg}</li>,
                seats: <b>{additionalDetails.seats}</b>
              }}
            />
          )}
          {(showTracker && additionalDetails.hasTracker !== undefined) && (
            <FormattedMessage
              id="vehicleQuote.assumptions.listItems.trackerDevice"
              values={{
                li: (msg: string) => <li>{msg}</li>,
                hasTracker: additionalDetails.hasTracker
                  ? (
                    <FormattedMessage
                      id="vehicleQuote.assumptions.listItems.hasTracker"
                      values={{
                        span: (msg: string) => <span>{msg}</span>
                      }}
                    />
                  )
                  : (
                    <FormattedMessage
                      id="vehicleQuote.assumptions.listItems.hasNoTracker"
                      values={{
                        span: (msg: string) => <span>{msg}</span>,
                        b: (msg: string) => <b>{msg}</b>
                      }}
                    />
                  )
              }}
            />
          )}
            <FormattedMessage
              id={determineVehicleAlarmMessage(additionalDetails.alarmFittedBy)}
              values={{
                li: (msg: string) => <li>{msg}</li>,
                alarmInstaller: <b>{additionalDetails.alarmFittedBy?.description}</b>
              }}
            />
          <li>
            {additionalDetails.imported !== undefined && (
              <FormattedMessage
                id="vehicleQuote.assumptions.listItems.imported"
                values={{
                  imported: additionalDetails.imported
                    ? <></>
                    : <b>{lookupI18nString("vehicleQuote.assumptions.listItems.imported.nonImported")}</b>
                }}
              />
            )}
            {additionalDetails.importType !== null && (
              <FormattedMessage
                id="vehicleQuote.assumptions.listItems.importType"
                values={{
                  span: (msg: string) => <span>{msg}</span>,
                  importType: additionalDetails.importType === "EU"
                    ? <b>{lookupI18nString("vehicleQuote.assumptions.listItems.importType.eu")}</b>
                    : <b>{lookupI18nString("vehicleQuote.assumptions.listItems.importType.nonEu")}</b>
                }}
              />
            )}
          </li>
          {additionalDetails.estimatedValue && (
            <FormattedMessage
              id="vehicleQuote.assumptions.listItems.estimatedValue"
              values={{
                li: (msg: string) => <li>{msg}</li>,
                estimatedValue: <b>{`£${additionalDetails.estimatedValue}`}</b>
              }}
            />
          )}
        </ul>
      </div>
    </div>
  );
};

export default VehicleDetailsList;