import {AppThunkDispatch, RootState} from "../../../../../redux/Store";
import {
  requotedWithoutComparisonChanged,
  resetSelectedQuote,
  selectedQuoteChanged
} from "../../your-quote/redux/QuoteDetailsSlice";

export function handleRefreshedQuotes(getState: () => RootState, dispatch: AppThunkDispatch): void {
  const quoteDetails = getState().quoteDetails;
  const newVersionOfSelectedQuote = quoteDetails.insurerQuotes?.find((quote) => (
    quote.insurerSchemeReference === quoteDetails.quoteInsurerSchemeReference
    && quote.quoteType === quoteDetails.quoteType
  ));

  if (!newVersionOfSelectedQuote) {
    dispatch(resetSelectedQuote());
  } else {
    dispatch(selectedQuoteChanged(newVersionOfSelectedQuote));
  }

  dispatch(requotedWithoutComparisonChanged(true));
}