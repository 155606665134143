import React, {FC, useEffect, useState} from "react";
import {dateOfBirthChanged, dateOfBirthInvalid, dateOfBirthReset} from "../../redux/PersonalDetailsSlice";
import DateOfBirthQuestion from "../../../shared/questions/date-of-birth/DateOfBirthQuestion";
import {logYourDetailsGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {isOldEnoughForLicenceLengthValidator} from "../../../shared/questions/date-of-birth/validators/isOldEnoughForLicenceLengthValidator";
import {coverStartDateAsDateOptionSelector} from "../../../your-cover/redux/selectors/CoverDetailsSelectors";
import {dateOfBirthAsDateSelector} from "../../redux/selectors/PersonalDetailsSelectors";
import {hasBeenUKResidentForLessThanAlive} from "../../../shared/questions/date-of-birth/validators/HasBeenUKResidentForLessThanAlive";
import {isOldEnoughToBeAHomeowner} from "../../../shared/questions/date-of-birth/validators/IsOldEnoughToBeAHomeowner";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const ProposerDateOfBirthQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const dateOfBirthField = useAppSelector(state => dateOfBirthAsDateSelector(state));
  const coverStartDate = useAppSelector(state => coverStartDateAsDateOptionSelector(state).value?.date);
  const licenceLength = useAppSelector(state => state.personalDetails.licenceDuration.value);
  const residencyDuration = useAppSelector(state => state.personalDetails.ukResidencyDuration.value?.years);
  const isHomeowner = useAppSelector(state => state.personalDetails.isHomeowner.value);

  const [revalidate, setRevalidate] = useState(false);

  const onRevalidated = (): void => setRevalidate(false);

  useEffect(() => {
    setRevalidate(true);
  }, [residencyDuration, licenceLength, isHomeowner]);

  const onChange = (value: Date): void => {
    logYourDetailsGoogleAnalyticsEvent({
      categorySuffix: "date_of_birth",
      action: "Answered",
    });
    dispatch(dateOfBirthChanged(value.toISOString()));
  };

  return (
    <DateOfBirthQuestion
      id="proposer-date-of-birth"
      title="personalDetails.dateOfBirthQuestion.title"
      dateOfBirthField={dateOfBirthField}
      dateOfBirthChanged={onChange}
      dateOfBirthInvalid={errorMessage => dispatch(dateOfBirthInvalid(errorMessage))}
      dateOfBirthReset={() => dispatch(dateOfBirthReset())}
      coverStartDate={coverStartDate}
      revalidate={revalidate}
      onRevalidated={onRevalidated}
      validators={[
        isOldEnoughForLicenceLengthValidator(licenceLength),
        hasBeenUKResidentForLessThanAlive(residencyDuration),
        isOldEnoughToBeAHomeowner(isHomeowner)
      ]}
    />
  );
};

export default ProposerDateOfBirthQuestion;
