import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";

export enum OwnOrCompanyCar {
  CompanyCar = "COMPANY_CAR",
  OwnPolicy = "OWN_POLICY"
}

export const OWN_OR_COMPANY_CAR = [
  {
    id: OwnOrCompanyCar.CompanyCar,
    description: lookupI18nString("coverDetails.ownOrCompanyCarQuestion.option.companyCar")
  },
  {
    id: OwnOrCompanyCar.OwnPolicy,
    description: lookupI18nString("coverDetails.ownOrCompanyCarQuestion.option.ownPolicy")
}
];

export type OwnOrCompanyCarOption = typeof OWN_OR_COMPANY_CAR[number];

export const OWN_OR_COMPANY_CAR_DESCRIPTIONS = OWN_OR_COMPANY_CAR.map(option => option.description);