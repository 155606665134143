import {FC} from "react";
import {useScrollToTopOnRoute} from "../../utils/custom-hooks/ScrollToTop";
import {usePageViewTracker} from "shared-components/dist/analytics/GoogleAnalytics";
import {useHotjarIdentifier} from "../../hotjar/Hotjar";

const BasePage: FC = ({children}) => {
  useScrollToTopOnRoute();
  usePageViewTracker();
  useHotjarIdentifier();

  return <>{children}</>;
};

export default BasePage;
