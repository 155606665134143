import React, {FC} from "react";
import {ownerChanged} from "../../../redux/VehicleDetailsSlice";
import DropdownSubQuestion from "../../../../../../../structure/questions/sub-question/variants/dropdown-sub-question/DropdownSubQuestion";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import {
  VEHICLE_HOLDER_DESCRIPTIONS,
  VEHICLE_HOLDERS
} from "../../../../../../../utils/constants/VehicleHolderOption";
import OwnerStop from "../stop/OwnerStop";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const OwnerQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const ownerField = useAppSelector(state => state.vehicleDetails.owner);

  const onOwnerSelected = (selectedOwner: string): void => {
    const owner = VEHICLE_HOLDERS.find(owner => owner.description === selectedOwner);

    if (owner) dispatch(ownerChanged(owner));
  };

  const hasError = ownerField.status === "error";

  const tooltip: TooltipProps = {
    description: "vehicleQuote.ownerQuestion.tooltip.description",
  };

  return (
    <>
      <DropdownSubQuestion
        id="OwnerQuestion"
        title="vehicleQuote.ownerQuestion.title"
        tooltip={tooltip}
        value={ownerField.value?.description}
        options={VEHICLE_HOLDER_DESCRIPTIONS}
        onChange={onOwnerSelected}
        status={hasError ? "error" : "default"}
        errorMessage={ownerField.errorMessage}
      />
      <OwnerStop/>
    </>
  );
};

export default OwnerQuestion;