import {buildCrossFieldValidator, CrossFieldValidator, ReduxQuestion} from "../../Questions";
import {
  canHaveBeenUkResidentForDurationGivenTheDateOfBirth
} from "../../../../pages/quote/vehicle/shared/questions/date-of-birth/validators/HasBeenUKResidentForLessThanAlive";
import {FormField} from "shared-components/dist/models/form-field/FormField";

export const ukResidencyDurationValidators = (dateOfBirthQuestion: ReduxQuestion<Date, FormField<Date>>): CrossFieldValidator<Duration>[] => [
  buildCrossFieldValidator(
    state => dateOfBirthQuestion.selector(state).value,
    [canHaveBeenUkResidentForDurationGivenTheDateOfBirth]
  )
];