import {isNoClaimsBonusExpiryDateValid} from "../../../../../your-cover/redux/selectors/helpers/IsNoClaimsBonusValid";
import {isCompanyCarBonusValid} from "../../../../../your-cover/redux/selectors/helpers/IsCompanyCarBonusValid";
import {isAfter, startOfDay} from "date-fns";

export function shouldNoClaimsBonusWarningBeVisible(
  coverStartDate: Date | undefined,
  existingPolicyExpiryDate: Date | undefined
): boolean {
  if (!coverStartDate || !existingPolicyExpiryDate) return false;

  return !isNoClaimsBonusExpiryDateValid(coverStartDate, existingPolicyExpiryDate);
}

export function shouldCompanyCarBonusWarningBeVisible(
  coverStartDate: Date | undefined,
  companyCarPolicyExpiryDate: Date | undefined
): boolean {
  if (!coverStartDate || !companyCarPolicyExpiryDate) return false;

  return !isCompanyCarBonusValid(coverStartDate, companyCarPolicyExpiryDate);
}

export function shouldPreviousPolicyExpiryWarningBeVisible(
  coverStartDate: Date | undefined,
  vehiclePreviousPolicyExpiryDate: Date | undefined
): boolean {
  if (!coverStartDate || !vehiclePreviousPolicyExpiryDate) return false;

  return isAfter(startOfDay(vehiclePreviousPolicyExpiryDate), startOfDay(coverStartDate));
}

export function shouldCompanyCarBonusAndPreviousPolicyExpiryWarningBeVisible(
  coverStartDate: Date | undefined,
  vehiclePreviousPolicyExpiryDate: Date | undefined,
  companyCarPolicyExpiryDate: Date | undefined
): boolean {
  if (!coverStartDate || !vehiclePreviousPolicyExpiryDate || !companyCarPolicyExpiryDate) return false;

  return shouldCompanyCarBonusWarningBeVisible(coverStartDate, companyCarPolicyExpiryDate) && shouldPreviousPolicyExpiryWarningBeVisible(coverStartDate, vehiclePreviousPolicyExpiryDate);
}