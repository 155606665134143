import React, {FC} from "react";
import {hasMedicalConditionChanged, medicalConditionChanged} from "../../redux/UpdateAdditionalDriverSlice";
import MedicalConditionQuestion from "../../../../shared/questions/medical-condition/MedicalConditionQuestion";
import {medicalConditionStopVisibleSelector} from "../../redux/selectors/MedicalConditionStopVisibleSelector";
import {logAdditionalDriverGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const AdditionalDriverMedicalConditionQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const hasMedicalConditionField = useAppSelector(state => state.updateAdditionalDriver.hasMedicalCondition);
  const medicalConditionField = useAppSelector(state => state.updateAdditionalDriver.medicalCondition);
  const isStopVisible = useAppSelector(medicalConditionStopVisibleSelector);

  const onHasMedicalConditionsAnswered = (answer: boolean): void => {
    logAdditionalDriverGoogleAnalyticsEvent({
      categorySuffix: "medical_conditions",
      action: answer,
    });

    dispatch(hasMedicalConditionChanged(answer));
  };

  return (
    <MedicalConditionQuestion
      id="additional-driver-has-medical-condition-question"
      title="additionalDriver.hasMedicalConditionQuestion"
      tooltip={{
        description: "additionalDriver.hasMedicalConditionQuestion.tooltip.description",
      }}
      hasMedicalConditionField={hasMedicalConditionField}
      medicalConditionField={medicalConditionField}
      hasMedicalConditionChanged={onHasMedicalConditionsAnswered}
      medicalConditionChanged={value => dispatch(medicalConditionChanged(value))}
      subQuestionId="additional-driver-medical-condition-question"
      subQuestionTitle="additionalDriver.medicalConditionQuestion"
      isStopVisible={isStopVisible}
    />
  );
};

export default AdditionalDriverMedicalConditionQuestion;
