import React, {FC} from "react";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {
  StringRadioColumnQuestion
} from "../../../../../../structure/questions/question/variants/radio-column-question/StringRadioColumnQuestion";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import {ProposedClassOfUse} from "shared/dist/generated/graphql/resolvers-types";
import DEFAULT_CLASS_OF_USE_DESCRIPTIONS from "./DefaultClassOfUseDescriptions";
import {FormField} from "shared-components/dist/models/form-field/FormField";
import {ClassOfUseOption} from "./ClassOfUseOption";
import getKeyForValueInRecord from "shared/dist/stdlib/GetKeyForValueInRecord";

interface OwnProps {
  id: string;
  title: TranslationKey;
  classOfUse: FormField<ClassOfUseOption>;
  classOfUseChanged: (classOfUse: ClassOfUseOption) => void;
  classOfUseDescriptions?: Record<ProposedClassOfUse, string>;
  tooltip?: TooltipProps;
}

const ClassOfUseQuestion: FC<OwnProps> = (
  {
    id,
    title,
    classOfUse,
    classOfUseChanged,
    classOfUseDescriptions = DEFAULT_CLASS_OF_USE_DESCRIPTIONS,
    tooltip
  }
) => {
  const onSelection = (selectedDescription: string): void => {
    const classOfUse = getKeyForValueInRecord(selectedDescription, classOfUseDescriptions);

    if (!classOfUse) return;

    classOfUseChanged({
      id: classOfUse as ProposedClassOfUse,
      description: selectedDescription
    });
  };

  return (
    <StringRadioColumnQuestion
      questionProps={{
        id,
        title,
        status: classOfUse.status,
        errorMessage: classOfUse.errorMessage,
        tooltip
      }}
      radioColumnProps={{
        options: Object.values(classOfUseDescriptions),
        name: "class-of-use-field",
        selectedOption: classOfUse.value?.description,
        onSelection
      }}
    />
  );
};

export default ClassOfUseQuestion;