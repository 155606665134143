import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {buildQuoteRoute} from "../../../../../utils/navigation/NavigationHelpers";
import {COMPARE_QUOTES} from "../../../../../router/models/Routes";
import {useAppSelector} from "../../../../../redux/Hooks";

export function useNavigateOnQuoteUnavailable(): void {
  const quoteInsurerSchemeReference = useAppSelector(state => state.quoteDetails.quoteInsurerSchemeReference);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!quoteInsurerSchemeReference) navigate(buildQuoteRoute(location.pathname, COMPARE_QUOTES), {state: {fromQuotePage: true}});
  }, [quoteInsurerSchemeReference, navigate, location]);
}