import {useEffect, useState} from "react";
import {LoginResponse} from "../../../../user/authentication/models/login/LoginResponse";
import {authenticateUser, signOutUser} from "../../../../user/authentication/services/UserAuthenticationService";
import {base64ToString} from "../../../../utils/service/base64/Base64Conversion";
import {CognitoUser} from "amazon-cognito-identity-js";

export type FirstTimeLoginStatus = "UserAlreadyActivated" | "LoggingIn" | "ResetPasswordNeeded";

export interface FirstTimeLogin {
  firstTimeLoginStatus: FirstTimeLoginStatus;
  cognitoUser?: CognitoUser;
}

export function useFirstTimeLoginToken(
  email: string | undefined,
  token: string | undefined
): FirstTimeLogin {
  const [loginResponse, setLoginResponse] = useState<LoginResponse | undefined>(undefined);

  useEffect(() => {
    signOutUser();

    if (email && token) {
      authenticateUser(base64ToString(email), token)
        .then(setLoginResponse);
    }
  }, [email, token]);

  if (loginResponse?.loginResult === "ResetPassword") {
    return {
      firstTimeLoginStatus: "ResetPasswordNeeded",
      cognitoUser: loginResponse.cognitoUser
    };
  }
  if (loginResponse?.loginResult === "InvalidCredentials") {
    return {
      firstTimeLoginStatus: "UserAlreadyActivated"
    };
  }

  return {
    firstTimeLoginStatus: "LoggingIn"
  };
}
