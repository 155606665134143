import React, {FC} from "react";
import {Close, Menu} from "@mui/icons-material";
import "./MobileMenuButton.css";

interface Props {
  showCloseIcon: boolean,
  menuButtonToggled: () => void;
}

const MobileMenuButton: FC<Props> = ({showCloseIcon, menuButtonToggled}) => (
  <div className="mobile-menu-button"
       onClick={menuButtonToggled}>
    {showCloseIcon ? <MobileMenuCloseButton/> : <Menu/>}
  </div>
);

const MobileMenuCloseButton: FC = () => (
  <div className="mobile-menu-button__close-icon">
    <Close/>
  </div>
);

export default MobileMenuButton;
