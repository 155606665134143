import {RootState} from "../../../../../../redux/Store";
import {toSafeDate} from "shared/dist/stdlib/Dates";
import {createSelector} from "@reduxjs/toolkit";
import {FormField} from "shared-components/dist/models/form-field/FormField";
import {DateFormField} from "shared-components/dist/models/form-field/variants/DateFormField";

const vehiclePurchaseDateSelector = (state: RootState): FormField<string> => state.vehicleDetails.dateOfPurchase;

export const vehiclePurchaseDateAsDateSelector = createSelector(
  vehiclePurchaseDateSelector,
  (vehiclePurchaseDate: FormField<string>): DateFormField => {
    return {
      ...vehiclePurchaseDate,
      value: toSafeDate(vehiclePurchaseDate.value)
    };
  }
);