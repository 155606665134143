import {useNavigate} from "react-router-dom";
import {endOfDay, isAfter, startOfDay} from "date-fns";
import {addDays} from "date-fns/fp";
import {useEffect, useState} from "react";
import {hasSavedQuoteExpired} from "./ExpiredSavedQuoteDetection";
import {QuoteRetrievalStatus} from "../../../models/QuoteRetrievalStatus";
import {savedQuoteGenerationDateCleared} from "../../../redux/QuoteDetailsSlice";
import {isCoverStartDateExpiredSelector} from "../../../../your-cover/redux/selectors/CoverDetailsSelectors";
import {useFeatureFlags} from "shared-components/dist/feature-flags/hooks/UseFeatureFlags";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";
import {refreshQuotes} from "../../../../shared/redux/RefreshQuotes";

const SAVED_QUOTE_PRICE_GUARANTEE_IN_DAYS = 5;

interface UseExpiredPriceGuaranteeDetection {
  expiredPriceGuaranteeModalVisible: boolean;
  hideExpiredPriceGuaranteeModal: () => Promise<void>;
}

export function useExpiredPriceGuaranteeDetection(): UseExpiredPriceGuaranteeDetection {
  const {monthlyPaymentsFlag} = useFeatureFlags();
  const [expiredPriceGuaranteeModalVisible, setExpiredPriceGuaranteeModalVisible] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const quoteRetrievalStatus = useAppSelector(state => state.quoteDetails.quoteRetrievalStatus);
  const quoteGenerationDate = useAppSelector(state => state.quoteDetails.savedQuoteGenerationDate);
  const isCoverStartDateExpired = useAppSelector(isCoverStartDateExpiredSelector);

  useEffect(() => {
    if (quoteRetrievalStatus !== QuoteRetrievalStatus.SUCCESS) return;
    if (quoteGenerationDate === undefined) return;
    if (isCoverStartDateExpired) return;

    const start = startOfDay(new Date(quoteGenerationDate));
    if (hasSavedQuoteExpired(start)) return;

    const now = new Date();
    const priceGuaranteeEnd = endOfDay(addDays(SAVED_QUOTE_PRICE_GUARANTEE_IN_DAYS, start));

    if (isAfter(now, priceGuaranteeEnd)) setExpiredPriceGuaranteeModalVisible(true);
  }, [quoteGenerationDate, navigate, dispatch, quoteRetrievalStatus, isCoverStartDateExpired]);

  const hideExpiredPriceGuaranteeModal = async (): Promise<void> => {
    setExpiredPriceGuaranteeModalVisible(false);

    await Promise.all([
      dispatch(savedQuoteGenerationDateCleared()),
    ]).then(() => {
      dispatch(refreshQuotes({
        monthlyPaymentsEnabled: monthlyPaymentsFlag,
      }));
    });
  };

  return {expiredPriceGuaranteeModalVisible, hideExpiredPriceGuaranteeModal};
}
