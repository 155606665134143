import React, {Dispatch, FC, SetStateAction} from "react";
import RenewalDetailsConfirmationTableItem, {RenewalDetailsConfirmationTableItemProps} from "../table-items/RenewalDetailsConfirmationTableItem";
import RenewalDetailsConfirmationTableHeader from "../table-items/RenewalDetailsConfirmationTableHeader";
import RenewalDetailsConfirmationButtonFooter from "../table-items/RenewalDetailsConfirmationButtonFooter";
import "./RenewalsConfirmYourDetails.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {EmploymentStatus, MedicalCondition, Policy, PolicyHolder, StoredAtNight} from "shared/dist/generated/graphql/resolvers-types";
import {formatDateStringFromIsoString} from "shared/dist/stdlib/DateFormat";
import {convertEnumValueToFormattedString} from "shared-components/dist/utils/helpers/EnumHelper";
import _ from "lodash";
import {formatClassOfUseToString, formatGender} from "../utils/Formatters";
import {
  convertDurationInMonthsToYears,
  isIndustryApplicableToEmploymentStatus,
  isJobTitleApplicableToEmploymentStatus,
  titleInfersGender
} from "../utils/Helpers";

interface Props {
  confirmed: boolean;
  setConfirmed: Dispatch<SetStateAction<boolean>>;
  setEditModalVisible: Dispatch<SetStateAction<boolean>>;
  policy: Policy;
  policyHolder: PolicyHolder;
  previousSectionConfirmed: boolean;
}

const RenewalsConfirmYourDetails: FC<Props> = ({confirmed, setConfirmed, setEditModalVisible, policy, policyHolder, previousSectionConfirmed}) => {
  const items = getStructuredDetails(policy, policyHolder);

  return (
    <div
      className={confirmed ? "renewals-confirm-your-details--confirmed" : `renewals-confirm-your-details${previousSectionConfirmed ? "" : "--disabled"} unconfirmed`}
      data-testid="renewals-confirm-your-details"
    >
      <RenewalDetailsConfirmationTableHeader
        title={lookupI18nString("portal.renewals.detailConfirmation.yourDetails")}
        confirmed={confirmed}
      />
      {items.map((item, index) => (
        <RenewalDetailsConfirmationTableItem
          key={index}
          testId={item.testId}
          title={item.title}
          subtitle={item.subtitle}
          listItems={item.listItems}
        />
      ))}
      <RenewalDetailsConfirmationButtonFooter
        testId={"your-details"}
        confirmed={confirmed}
        setConfirmed={setConfirmed}
        setEditModalVisible={setEditModalVisible}
        disabled={!previousSectionConfirmed}
      />
    </div>
  );
};

function getStructuredDetails(policy: Policy, policyHolder: PolicyHolder): RenewalDetailsConfirmationTableItemProps[] {
  const itemList: RenewalDetailsConfirmationTableItemProps[] = [
    {
      testId: "your-details-title",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.title"),
      subtitle: policyHolder.title
    },
    {
      testId: "your-details-first-name",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.firstName"),
      subtitle: policyHolder.firstName
    },
    {
      testId: "your-details-surname",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.surname"),
      subtitle: policyHolder.lastName
    },
    {
      testId: "your-details-dob",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.dateOfBirth"),
      subtitle: formatDateStringFromIsoString(policyHolder.dateOfBirth)
    },
  ];

  if (!titleInfersGender(policyHolder.title)) {
    itemList.push({
      testId: "your-details-gender",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.gender"),
      subtitle: formatGender(policyHolder.gender)
    });
  }

  itemList.push(
    {
      testId: "your-details-address",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.address"),
      subtitle: `${policyHolder.address.firstLine}, ${policyHolder.address.city}, ${policyHolder.address.postcode}`
    },
    {
      testId: "your-details-car-at-address",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.carAtAddress"),
      subtitle: "Yes"
    },
    {
      testId: "your-details-vehicle-stored-at-night",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.carKeptAtNight"),
      subtitle: formatStoredAtNightEnumToString(policy.vehicleDetails.storedAtNight)
    }
  );

  if (policyHolder.isHomeowner != null) {
    itemList.push({
      testId: "your-details-home-owner",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.homeOwner"),
      subtitle: policyHolder.isHomeowner ? lookupI18nString("general.boolean.yes") : lookupI18nString("general.boolean.no")
    });
  }

  itemList.push(
    {
      testId: "your-details-uk-residency-duration",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.ukResidency.lengthOfTime"),
      subtitle: convertDurationInMonthsToYears(policyHolder.ukResidencyDurationMonths)
    },
    {
      testId: "your-details-email",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.email"),
      subtitle: policyHolder.metadata.email
    },
    {
      testId: "your-details-phone-number",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.phoneNumber"),
      subtitle: policyHolder.metadata.phoneNumber
    },
    {
      testId: "your-details-relationship-status",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.relationshipStatus"),
      subtitle: convertEnumValueToFormattedString(policyHolder.maritalStatus)
    },
    {
      testId: "your-details-employment-status",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.employmentStatus"),
      subtitle: _.startCase(policyHolder.primaryEmployment.status)
    }
  );

  if (policyHolder.primaryEmployment.industry && isIndustryApplicableToEmploymentStatus(policyHolder.primaryEmployment.status)) {
    itemList.push(
      {
        testId: "your-details-industry",
        title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.industry"),
        subtitle: policyHolder.primaryEmployment.industry
      });
  }

  if (policyHolder.primaryEmployment.job && isJobTitleApplicableToEmploymentStatus(policyHolder.primaryEmployment.status, policyHolder.primaryEmployment.job)) {
    itemList.push(
      {
        testId: "your-details-job-title",
        title: getTitleBasedOnEmploymentStatus(policyHolder.primaryEmployment.status),
        subtitle: policyHolder.primaryEmployment.job
      });
  }

  itemList.push(
    {
      testId: "your-details-second-job",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.secondJob"),
      subtitle: policyHolder.secondaryEmployment ? lookupI18nString("general.boolean.yes") : lookupI18nString("general.boolean.no")
    }
  );

  if (policyHolder.secondaryEmployment?.job && policyHolder.secondaryEmployment?.industry) {
    itemList.push(
      {
        testId: "your-details-second-job-industry",
        title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.secondJobIndustry"),
        subtitle: policyHolder.secondaryEmployment.industry
      },
      {
        testId: "your-details-second-job-title",
        title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.secondJobTitle"),
        subtitle: policyHolder.secondaryEmployment.job
      }
    );
  }

  itemList.push(
    {
      testId: "your-details-class-of-use",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.carUse"),
      subtitle: formatClassOfUseToString(policyHolder.classOfUse ?? undefined)
    },
    {
      testId: "your-details-main-driver",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.mainDriver"),
      subtitle: policyHolder.isMainDriver ? lookupI18nString("general.boolean.yes") : lookupI18nString("general.boolean.no")
    },
    {
      testId: "your-details-licence",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.licence"),
      subtitle: `${convertEnumValueToFormattedString(policyHolder.licence.countryOfIssue)} - ${convertEnumValueToFormattedString(policyHolder.licence.type)}`
    },
    {
      testId: "your-details-licence-duration",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.licenceDuration"),
      subtitle: policyHolder.licence.durationMonths ? convertDurationInMonthsToYears(policyHolder.licence.durationMonths) : "-"
    },
    {
      testId: "your-details-medical-conditions",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.medicalConditions"),
      subtitle: policyHolder.medicalConditionsDeclared ? formatMedicalCondition(policyHolder.medicalCondition ?? undefined) : lookupI18nString("general.boolean.no")
    },
    {
      testId: "your-details-claims",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.claims"),
      subtitle: policyHolder.claims && policyHolder.claims.length > 0 ? lookupI18nString("general.boolean.yes") : lookupI18nString("general.boolean.no")
    },
    {
      testId: "your-details-convictions",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.convictions"),
      subtitle: policyHolder.convictions && policyHolder.convictions.length > 0 ? lookupI18nString("general.boolean.yes") : lookupI18nString("general.boolean.no"),
      listItems: [
        lookupI18nString("personalDetails.convictionsQuestions.hasConviction.description.motoringOrFixedPenalty"),
        lookupI18nString("personalDetails.convictionsQuestions.hasConviction.description.pendingProsecutions"),
        lookupI18nString("personalDetails.convictionsQuestions.hasConviction.description.drivingDisqualification"),
      ],
    },
    {
      // this is currently an inferred no - causes a hard stop in the quote flow, and we do not store the answer
      testId: "your-details-non-motoring-convictions",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.nonMotoringConvictions"),
      subtitle: lookupI18nString("general.boolean.no")
    },
    {
      testId: "your-details-has-cancellations",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.insuranceDeclined"),
      subtitle: policyHolder.hasCancellations ? lookupI18nString("general.boolean.yes") : lookupI18nString("general.boolean.no")
    }
  );

  return itemList;
}

const getTitleBasedOnEmploymentStatus = (employmentStatus: EmploymentStatus): string => {
  switch (employmentStatus) {
    case EmploymentStatus.Retired:
      return lookupI18nString("portal.renewals.detailConfirmation.yourDetails.jobTitle.retired");
    case EmploymentStatus.Unemployed:
      return lookupI18nString("portal.renewals.detailConfirmation.yourDetails.jobTitle.unemployed");
    default:
      return lookupI18nString("portal.renewals.detailConfirmation.yourDetails.jobTitle");
  }
};

function formatStoredAtNightEnumToString(storedAtNight: StoredAtNight): string {
  switch (storedAtNight) {
    case StoredAtNight.OnTheStreet:
      return lookupI18nString("portal.renewals.detailConfirmation.yourDetails.storedAtNight.onTheStreet");
    case StoredAtNight.OnTheDriveway:
      return lookupI18nString("portal.renewals.detailConfirmation.yourDetails.storedAtNight.inTheDriveway");
    case StoredAtNight.LockedCompound:
      return lookupI18nString("portal.renewals.detailConfirmation.yourDetails.storedAtNight.inALockedCompound");
    case StoredAtNight.LockedGarage:
    case StoredAtNight.UnlockedGarage:
    case StoredAtNight.Garage:
      return lookupI18nString("portal.renewals.detailConfirmation.yourDetails.storedAtNight.inAGarage");
    case StoredAtNight.Carport:
      return lookupI18nString("portal.renewals.detailConfirmation.yourDetails.storedAtNight.inACarport");
    case StoredAtNight.Carpark:
      return lookupI18nString("portal.renewals.detailConfirmation.yourDetails.storedAtNight.inACarPark");
    case StoredAtNight.UnlockedCompound:
      return lookupI18nString("portal.renewals.detailConfirmation.yourDetails.storedAtNight.inAnUnlockedCompound");
    case StoredAtNight.Other:
      return lookupI18nString("portal.renewals.detailConfirmation.yourDetails.storedAtNight.other");
  }
}

function formatMedicalCondition(medicalCondition?: MedicalCondition): string {
  if (!medicalCondition) return `${lookupI18nString("general.boolean.yes")}`;
  return `${lookupI18nString("general.boolean.yes")} - ${formatMedicalConditionEnumToString(medicalCondition)}`;
}

function formatMedicalConditionEnumToString(medicalCondition: MedicalCondition): string {
  switch (medicalCondition) {
    case MedicalCondition.DvlaAwareNoRestrictions:
      return lookupI18nString("structure.questions.medicalConditions.dvlaAwareNoRestrictions");
    case MedicalCondition.DvlaUnaware:
      return lookupI18nString("structure.questions.medicalConditions.dvlaUnaware");
    case MedicalCondition.RestrictionOneYear:
      return lookupI18nString("structure.questions.medicalConditions.restricted.oneYear");
    case MedicalCondition.RestrictionTwoYears:
      return lookupI18nString("structure.questions.medicalConditions.restricted.twoYears");
    case MedicalCondition.RestrictionThreeYears:
      return lookupI18nString("structure.questions.medicalConditions.restricted.threeYears");
    case MedicalCondition.RestrictionFiveYears:
      return lookupI18nString("structure.questions.medicalConditions.restricted.fiveYears");
  }
}

export default RenewalsConfirmYourDetails;
