import {FC} from "react";
import "./RenewalReady.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import Button from "shared-components/dist/buttons/button/Button";
import PlainCard from "shared-components/dist/cards/plain-card/PlainCard";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../../../../../../redux/Hooks";
import {resetLoadRenewalSummaryStatus} from "../../../../../renewals/redux/RenewalSlice";

interface Props {
  insurer: string,
  isRebrokeOnly?: boolean;
}

const RenewalReady: FC<Props> = ({insurer, isRebrokeOnly = false}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const navigateToRenewalSummary = (): void => {
    dispatch(resetLoadRenewalSummaryStatus());
    navigate("summary");
  };

  return (
    <PlainCard>
      <div className="renewal-ready">
        <h1 className="renewal-ready__title">{lookupI18nString("renewal.readyPage.yourRenewalQuoteReady")}</h1>

        {!isRebrokeOnly && <h2 className="renewal-ready__subtitle" data-testid="renewal-ready__insurer-sub-title">{lookupI18nString({
          id: "renewal.readyPage.insurer",
          templateVariables: {
            insurer
          }
        })}</h2>}

        <p className="renewal-ready__description">{lookupI18nString("renewal.readyPage.clickBelowStarted")}</p>

        <Button
          data-testid="renewal-ready__button"
          variant="secondary"
          small={true}
          onClick={navigateToRenewalSummary}
        >
          {lookupI18nString("renewal.startRenewal")}
        </Button>
      </div>
    </PlainCard>
  );
};

export default RenewalReady;