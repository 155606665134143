import {Status} from "shared-components/dist/status-indicator/Status";
import {Option} from "../../../../../../../structure/form/typeahead/models/Option";
import {CustomisedAbiListItem} from "shared-components/dist/models/CustomisedAbiListItem";
import {useJobs} from "../../../../../../../graphql/queries/employment-lookup/jobs-lookup/JobsLookup";
import {toFormStatus} from "../../../../../../../graphql/services/ToFormStatus";
import {ConditionalFormField} from "shared-components/dist/models/form-field/ConditionalFormField";

export interface JobsResult {
  status: Status | undefined;
  jobs: Option<string>[];
  onJobChanged: (selection: Option<string>) => void;
}

export function useJobsProps(
  job: ConditionalFormField<CustomisedAbiListItem | undefined>,
  jobChanged: (value: CustomisedAbiListItem) => void
): JobsResult {
  const {loading: loadingJobs, error: jobsError, data: jobsResult} = useJobs();
  const jobs: Option<string>[] = jobsResult?.map(job => ({
    value: job.vtCode,
    label: job.description
  })) ?? [];
  const status = toFormStatus(job, loadingJobs, jobsError);

  const onJobChanged = (selection: Option<string>): void => {
    const type = jobs.find(type => type.label === selection.label);

    if (type) {
      jobChanged({
        description: selection.label,
        vtCode: selection.value
      });
    }
  };

  return {status, jobs, onJobChanged};
}