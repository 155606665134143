import React, {FC} from "react";
import {Status} from "shared-components/dist/status-indicator/Status";
import "./SubContainer.css";

interface Props {
  className?: string;
  status?: Status;
}

const SubContainer: FC<Props> = ({
  status = "default",
  children,
  className
}) => (
  <div className={`sub-container sub-container--${status} ${className ?? ""}${status === "error" ? ` ${status}` : ""}`}>
    {children}
  </div>
);

export default SubContainer;