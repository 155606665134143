import {RootState} from "../../../../../../../redux/Store";
import {FormField, RequiredFormField} from "shared-components/dist/models/form-field/FormField";
import {toSafeDate} from "shared/dist/stdlib/Dates";
import {DateFormField} from "shared-components/dist/models/form-field/variants/DateFormField";
import {createSelector} from "@reduxjs/toolkit";
import {Claims, SerializableClaims, toClaim} from "../../../../shared/questions/claims/models/Claims";
import {
  Convictions,
  SerializableConvictions,
  toConviction
} from "../../../../shared/questions/convictions/models/Conviction";

const dateOfBirthSelector = (state: RootState): FormField<string> => state.updateAdditionalDriver.dateOfBirth;
export const serializableClaimsSelector = (state: RootState): RequiredFormField<SerializableClaims> => state.updateAdditionalDriver.claims;
export const serializableConvictionsSelector = (state: RootState): RequiredFormField<SerializableConvictions> => state.updateAdditionalDriver.convictions;

export const dateOfBirthAsDateSelector = createSelector(
  dateOfBirthSelector,
  (dateOfBirth: FormField<string>): DateFormField => {
    return {
      ...dateOfBirth,
      value: toSafeDate(dateOfBirth.value)
    };
  }
);

export const claimsSelector = createSelector(
  serializableClaimsSelector,
  (serializableClaims: RequiredFormField<SerializableClaims>): RequiredFormField<Claims> => {
    return {
      ...serializableClaims,
      value: {
        ...serializableClaims.value,
        claims: serializableClaims.value.claims.map(toClaim)
      }
    };
  }
);

export const convictionsSelector = createSelector(
  serializableConvictionsSelector,
  (serializableConvictions: RequiredFormField<SerializableConvictions>): RequiredFormField<Convictions> => {
    return {
      ...serializableConvictions,
      value: {
        ...serializableConvictions.value,
        convictions: serializableConvictions.value.convictions.map(toConviction)
      }
    };
  }
);