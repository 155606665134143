import {FC} from "react";
import "./HeadlinePrice.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {Price} from "shared/dist/generated/graphql/resolvers-types";
import {displayLoanValue} from "../../../../../utils/service/string/DisplayLoanValue";

interface Props {
  price: Price | undefined;
  showMonthlySuffix?: boolean;
  testId: string;
}

const HeadlinePrice: FC<Props> = ({price, showMonthlySuffix = false, testId}) => {
  const [priceIntegral, priceFractional] = price
    ? displayLoanValue(price).split(".")
    : ["£---", "--"];

  const priceFractionalWithSuffix = showMonthlySuffix
    ? `${priceFractional}${lookupI18nString("portal.renewals.summary.priceSummary.card.monthly.costPerMonth")}`
    : priceFractional;

  return (
    <div className="headline-price" data-testid={testId}>
      <span className="headline-price__integral">{priceIntegral}</span>
      <span className="headline-price__fractional">.{priceFractionalWithSuffix}</span>
    </div>
  );
};

export default HeadlinePrice;
