import {
  companyCarDeclarationAcceptanceInvalid,
  companyCarPolicyExpiryDateInvalid,
  yearsCompanyCarBonusInvalid
} from "../redux/CoverDetailsSlice";
import {AppThunkDispatch} from "../../../../../redux/Store";
import {isConditionalFormFieldInvalid} from "shared-components/dist/models/form-field/ConditionalFormField";
import {CoverDetailsFields} from "../redux/CoverDetailsFields";

export function checkCompanyCarBonusQuestions(coverDetails: CoverDetailsFields, dispatch: AppThunkDispatch): void {
  checkCompanyCarDeclarationAcceptanceQuestion(coverDetails, dispatch);
  checkYearsCompanyCarBonusQuestion(coverDetails, dispatch);
  checkCompanyCarPolicyExpiryDateQuestion(coverDetails, dispatch);
}

function checkCompanyCarDeclarationAcceptanceQuestion(
  {companyCarDeclarationAcceptance}: CoverDetailsFields,
  dispatch: AppThunkDispatch
): void {
  if (isConditionalFormFieldInvalid(companyCarDeclarationAcceptance)) {
    dispatch(companyCarDeclarationAcceptanceInvalid("quote.errors.questionIncomplete"));
  }
}

function checkYearsCompanyCarBonusQuestion(
  {yearsCompanyCarBonus}: CoverDetailsFields,
  dispatch: AppThunkDispatch
): void {
  if (isConditionalFormFieldInvalid(yearsCompanyCarBonus)) {
    dispatch(yearsCompanyCarBonusInvalid("quote.errors.questionIncomplete"));
  }
}

function checkCompanyCarPolicyExpiryDateQuestion(
  {companyCarPolicyExpiryDate}: CoverDetailsFields,
  dispatch: AppThunkDispatch
): void {
  if (isConditionalFormFieldInvalid(companyCarPolicyExpiryDate)) {
    dispatch(companyCarPolicyExpiryDateInvalid("quote.errors.questionIncomplete"));
  }
}