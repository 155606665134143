import {PolicyExtraType} from "shared/dist/generated/graphql/resolvers-types";

export function mapGoogleAnalyticsPolicyExtraNameFrom(policyExtra: PolicyExtraType): string {
  switch (policyExtra) {
    case PolicyExtraType.CourtesyCar:
      return "Courtesy Car";
    case PolicyExtraType.LegalExpenses:
      return "Legal Expenses";
    case PolicyExtraType.RoadsideAssistancePlatinum:
      return "Roadside Assistance Platinum";
    case PolicyExtraType.SmartfobKeycare:
      return "SmartFob Keycare";
    case PolicyExtraType.ExcessInsurance:
      return "Excess Insurance";
  }
}