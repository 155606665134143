import {AppThunk, AppThunkDispatch} from "../../../../../redux/Store";
import {flagUnansweredManualLookupQuestions} from "./FlagUnansweredManualLookupQuestions";
import {flagUnansweredAdditionalVehicleDetailsQuestions} from "./FlagUnansweredAdditionalVehicleDetailsQuestions";
import {hasVehicleSummarySelector} from "../redux/selectors/VehicleSummarySelectors";
import {flagUnansweredMainVehicleFormQuestions} from "./FlagUnansweredMainVehicleFormQuestions";
import {FormField, RequiredFormField} from "shared-components/dist/models/form-field/FormField";
import {modificationsInvalid, registrationNumberLookupInvalid} from "../redux/VehicleDetailsSlice";
import {isManualVehicleLookupFormActive} from "../redux/selectors/ManualVehicleLookupFormSelectors";
import {checkBreakInCoverDeclaration} from "../../your-cover/validation/FlagUnansweredCoverDetailsQuestions";
import {Modifications} from "../components/modifications/model/Modification";
import {isValidRegistrationNumber} from "../../../../../utils/validation/RegistrationNumber";

export const flagUnansweredQuestions = (): AppThunk => async (dispatch, getState) => {
  const rootState = getState();

  if (isManualVehicleLookupFormActive(rootState)) {
    flagUnansweredManualLookupQuestions(rootState.manualVehicleLookupForm, dispatch);
  } else if (!hasVehicleSummarySelector(rootState)) {
    flagUnansweredRegistrationNumberLookup(rootState.vehicleDetails.registrationNumberLookup, dispatch);
  }

  if (!hasVehicleSummarySelector(rootState)) return;

  flagUnansweredAdditionalVehicleDetailsQuestions(rootState, dispatch);
  flagUnansweredMainVehicleFormQuestions(rootState, dispatch);
  flagUnaddedModifications(rootState.vehicleDetails.modifications, dispatch);
  checkBreakInCoverDeclaration(rootState.coverDetails, dispatch);
};

function flagUnansweredRegistrationNumberLookup(registrationNumber: FormField<string>, dispatch: AppThunkDispatch): void {
  if (registrationNumber.status !== "default") return;

  if (registrationNumber.value === undefined) {
    dispatch(registrationNumberLookupInvalid("quote.errors.questionIncomplete"));
  } else if (!isValidRegistrationNumber(registrationNumber.value)) {
    dispatch(registrationNumberLookupInvalid("vehicleQuote.registrationLookup.errors.validation.invalidRegistrationNumber"));
  }
}

function flagUnaddedModifications(modifications: RequiredFormField<Modifications>, dispatch: AppThunkDispatch): void {
  if (!modifications.value.hasModifications) return;

  if (modifications.value.isFormVisible) dispatch(modificationsInvalid("quote.errors.questionIncomplete"));
}
