import React, {FC} from "react";
import QuoteStep from "../shared/quote-step/QuoteStep";
import {flagUnansweredPersonalDetailQuestions} from "./validation/FlagUnansweredPersonalDetailQuestions";
import {quoteSteps} from "../shared/quote-step/QuoteSteps";
import AddressQuestion from "./components/address/AddressQuestion";
import StoredAtNightQuestion from "./components/stored-at-night/StoredAtNightQuestion";
import CarKeptAtHomeQuestion from "./components/car-kept-at-home-question/CarKeptAtHomeQuestion";
import AdditionalDriversQuestion from "./components/additional-drivers-question/AdditionalDriversQuestion";
import MainDriverQuestion from "./components/main-driver/MainDriverQuestion";
import ProposerTitleQuestion from "./components/title/ProposerTitleQuestion";
import ProposerFirstNameQuestion from "./components/first-name/ProposerFirstNameQuestion";
import ProposerSurnameQuestion from "./components/surname/ProposerSurnameQuestion";
import ProposerDateOfBirthQuestion from "./components/date-of-birth/ProposerDateOfBirthQuestion";
import ProposerGenderQuestion from "./components/gender/ProposerGenderQuestion";
import ProposerMedicalConditionQuestion from "./components/medical-condition/ProposerMedicalConditionQuestion";
import ProposerPhoneNumberQuestion from "./components/phone-number/ProposerPhoneNumberQuestion";
import ProposerMaritalStatusQuestion from "./components/marital-status/ProposerMaritalStatusQuestion";
import ProposerLicenceTypeQuestion from "./components/licence-type/ProposerLicenceTypeQuestion";
import ProposerLicenceCountryOfIssueQuestion from "./components/licence-country-of-issue/ProposerLicenceCountryOfIssueQuestion";
import ProposerPrimaryEmploymentQuestion from "./components/employment/ProposerPrimaryEmploymentQuestion";
import ProposerLicenceDurationQuestion from "./components/licence-duration/ProposerLicenceDurationQuestion";
import ProposerClassOfUseQuestion from "./components/class-of-use/ProposerClassOfUseQuestion";
import ProposerConvictionsQuestion from "./components/convictions/ProposerConvictionsQuestion";
import ProposerResidencyQuestion from "./components/residency/ProposerResidencyQuestion";
import ProposerInsuranceDeclinedQuestion from "./components/insurance-declined-question/ProposerInsuranceDeclinedQuestion";
import ProposerNonMotoringConvictionsQuestion from "./components/non-motoring-convictions/ProposerNonMotoringConvictionsQuestion";
import {useScrollToElement} from "./hooks/ScrollToElement";
import {useAnchor} from "../../../../utils/custom-hooks/Anchor";
import ProposerSecondaryEmploymentQuestion from "./components/employment/ProposerSecondaryEmploymentQuestion";
import ProposerClaimsQuestion from "./components/claims/ProposerClaimsQuestion";
import {useLocation, useNavigate} from "react-router-dom";
import {buildQuoteRoute} from "../../../../utils/navigation/NavigationHelpers";
import {logYourDetailsGoogleAnalyticsEvent} from "../../../../utils/analytics/MotorAnalytics";
import ProposerIsHomeownerQuestion from "./components/is-homeowner-question/ProposerIsHomeownerQuestion";
import ProposerEmailAddressQuestion from "./components/email-address/ProposerEmailAddressQueston";
import {useEmailValidator} from "../shared/hooks/UseEmailValidator";
import {useAppDispatch, useAppSelector} from "../../../../redux/Hooks";

const YourDetails: FC = () => {
  const dispatch = useAppDispatch();
  const isPreviousPageValid = useAppSelector(quoteSteps.yourDetails.previousRoute.validator);
  const arePersonalDetailsValid = useAppSelector(quoteSteps.yourDetails.nextRoute.validator);
  const email = useAppSelector(state => state.personalDetails.proposerEmailAddress.value);

  const anchor = useAnchor();
  const emailValidator = useEmailValidator();
  const location = useLocation();
  const navigate = useNavigate();
  useScrollToElement(anchor);

  const nextRoute = buildQuoteRoute(location.pathname, quoteSteps.yourDetails.nextRoute);
  const previousRoute = buildQuoteRoute(location.pathname, quoteSteps.yourDetails.previousRoute);

  if (!isPreviousPageValid) {
    navigate(previousRoute);
    return null;
  }

  function onSubmit(): void {
    dispatch(flagUnansweredPersonalDetailQuestions());

    logYourDetailsGoogleAnalyticsEvent({
      categorySuffix: "go_to_next_page",
      action: arePersonalDetailsValid ? "Success" : "Failure",
    });
  }

  async function performAsyncValidation(): Promise<void> {
    await emailValidator(email);
  }

  return (
    <QuoteStep
      forwardButtonLabel="personalDetails.footerButton"
      nextRoute={nextRoute}
      previousRoute={previousRoute}
      onSubmit={onSubmit}
      onAsyncSubmit={performAsyncValidation}
      isFormValid={arePersonalDetailsValid}
      title="personalDetails.title"
    >
      <ProposerTitleQuestion/>
      <ProposerFirstNameQuestion/>
      <ProposerSurnameQuestion/>
      <ProposerDateOfBirthQuestion/>
      <ProposerGenderQuestion/>
      <AddressQuestion/>
      <CarKeptAtHomeQuestion/>
      <StoredAtNightQuestion/>
      <ProposerIsHomeownerQuestion/>
      <ProposerResidencyQuestion/>
      <ProposerEmailAddressQuestion/>
      <ProposerPhoneNumberQuestion/>
      <ProposerMaritalStatusQuestion/>
      <ProposerPrimaryEmploymentQuestion/>
      <ProposerSecondaryEmploymentQuestion/>
      <ProposerClassOfUseQuestion/>
      <ProposerLicenceTypeQuestion/>
      <ProposerLicenceCountryOfIssueQuestion/>
      <ProposerLicenceDurationQuestion/>
      <ProposerMedicalConditionQuestion/>
      <ProposerClaimsQuestion/>
      <ProposerConvictionsQuestion/>
      <ProposerNonMotoringConvictionsQuestion/>
      <ProposerInsuranceDeclinedQuestion/>
      <AdditionalDriversQuestion/>
      <MainDriverQuestion autoFocus={anchor === "main-driver-question"}/>
    </QuoteStep>
  );
};

export default YourDetails;
