import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../../../../../redux/Store";

export const selectIsSelectedQuoteTheCheapest = createSelector(
  (state: RootState) => state.quoteDetails.insurerQuotes,
  (state: RootState) => state.quoteDetails.quotePrice,
  (insurerQuotes, quotePrice) => {
    if (!insurerQuotes || !quotePrice) return false;
    return !insurerQuotes.some(insurerQuote => insurerQuote.premium < quotePrice);
  }
);