import {SerializableConviction} from "../../../../../pages/quote/vehicle/shared/questions/convictions/models/Conviction";
import {
  ConvictionOffenceInput,
  ConvictionOffenceItem,
  ConvictionTypeInput,
  ConvictionTypeItem,
  QuoteConviction
} from "shared/dist/generated/graphql/resolvers-types";
import {parseDurationAsMonths} from "../../../../../utils/service/parsers/DurationToMonthsParser";
import {DRIVING_WHILE_USING_MOBILE_PHONE_PARENT_CATEGORY} from "../../../../../pages/quote/vehicle/shared/questions/convictions/validation/IsMobilePhoneConvictionType";
import {removeTypename} from "../../../../../utils/graphql/ApolloClient";

const drivingWhileUsingMobilePhoneOffence = {
  offenceCode: "CU",
  offenceCodeNumber: "80"
};

export function mapQuoteConvictions(convictions: SerializableConviction[]): QuoteConviction[] {
  return convictions.map(conviction => {
    return {
      date: conviction.date,
      disqualificationPeriodMonths: parseDurationAsMonths(conviction.drivingBan),
      fine: conviction.fine,
      penaltyPoints: conviction.penaltyPoints,
      sampleType: conviction.testMethod?.id,
      sampleLevel: conviction.alcoholLevel,
      ...parseOffenceCodes(conviction),
      type: removeTypename<ConvictionTypeItem, ConvictionTypeInput>(conviction.type),
      offence: removeTypename<ConvictionOffenceItem, ConvictionOffenceInput>(conviction.offence),
    };
  });
}

function parseOffenceCodes(conviction: SerializableConviction): Pick<QuoteConviction, "offenceCode" | "offenceCodeNumber"> {
  if (conviction.type.parentCategory === DRIVING_WHILE_USING_MOBILE_PHONE_PARENT_CATEGORY) return drivingWhileUsingMobilePhoneOffence;

  validateConvictionOffenceOldReference(conviction.offence.oldReference);
  const offenceCode = conviction.offence.oldReference.slice(0,2);
  const offenceCodeNumber = conviction.offence.oldReference.slice(2,4);

  return {
    offenceCode,
    offenceCodeNumber
  };
}

function validateConvictionOffenceOldReference(oldReference: string): void {
  if (!/^[A-Z]{2}\d{2}$/.test(oldReference)) throw Error("Old reference is of invalid type");
}
