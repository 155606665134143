import {useEffect} from "react";
import {canProtectNoClaimsBonusSelector} from "../../../../../your-cover/redux/selectors/CoverDetailsSelectors";
import {useAppDispatch, useAppSelector} from "../../../../../../../../redux/Hooks";
import {
  loadNoClaimsDiscountProtectionData
} from "../../../../../your-quote/redux/thunks/LoadNoClaimsDiscountProtectionData";
import {
  loadNoClaimsDiscountProtectionCost
} from "../../../../../your-quote/redux/thunks/LoadNoClaimsDiscountProtectionCost";

export function useLoadNoClaimsDiscountInfo(): void {
  const dispatch = useAppDispatch();
  const canProtectNoClaimsBonus = useAppSelector(canProtectNoClaimsBonusSelector);
  const hasProtectedNoClaimsBonus = useAppSelector(state => state.coverDetails.protectNoClaimsBonus.value);
  const quoteReference = useAppSelector(state => state.quoteDetails.quoteReference);

  useEffect(() => {
    if (!canProtectNoClaimsBonus || !quoteReference) return;

    if (canProtectNoClaimsBonus) dispatch(loadNoClaimsDiscountProtectionData());
    if (hasProtectedNoClaimsBonus) dispatch(loadNoClaimsDiscountProtectionCost());
  }, [
    canProtectNoClaimsBonus,
    dispatch,
    hasProtectedNoClaimsBonus,
    quoteReference
  ]);
}