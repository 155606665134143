import React, {FC} from "react";
import DropdownQuestion
  from "../../../../../../../structure/questions/question/variants/dropdown-question/DropdownQuestion";
import {
  RELATIONSHIP_TO_PROPOSER_DESCRIPTIONS,
  RELATIONSHIP_TO_PROPOSER_OPTIONS,
  RelationshipToProposerOption
} from "./model/RelationshipToProposerOption";
import {relationshipToProposerChanged, relationshipToProposerInvalid} from "../../redux/UpdateAdditionalDriverSlice";
import {runValidators} from "shared-components/dist/utils/validation/Validator";
import {
  civilPartnerSelectedValidator,
  commonLawSpouseSelectedValidator,
  multipleCivilPartnerSelectedValidator,
  multipleCommonLawSpouseSelectedValidator,
  multipleSpouseSelectedValidator,
  spouseSelectedValidator
} from "./validators/MaritalStatusValidators";
import {
  childSelectedValidator,
  grandchildSelectedValidator,
  grandparentSelectedValidator,
  parentSelectedValidator
} from "../date-of-birth/validators/RelationshipToProposerValidators";
import {
  businessPartnerSelectedValidator,
  employeeSelectedValidator,
  employerSelectedValidator
} from "../employment/validators/EmploymentStatusValidators";
import {logAdditionalDriverGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {
  dateOfBirthAsDateSelector as proposerDateOfBirthSelector
} from "../../../../your-details/redux/selectors/PersonalDetailsSelectors";
import {dateOfBirthAsDateSelector} from "../../redux/selectors/AdditionalDriverDateTypeSelectors";
import {
  relationshipToProposerMatchesMaritalStatusValidator
} from "../marital-status/validators/AdditionalDriverMaritalStatusValidators";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const RelationshipToProposerQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const statusField = useAppSelector(state => state.updateAdditionalDriver.relationshipToProposer);
  const proposerMaritalStatus = useAppSelector(state => state.personalDetails.maritalStatus.value?.id);
  const proposerDateOfBirth = useAppSelector(state => proposerDateOfBirthSelector(state).value);
  const dateOfBirth = useAppSelector(state => dateOfBirthAsDateSelector(state).value);
  const proposerPrimaryEmploymentStatus = useAppSelector(state => state.personalDetails.primaryEmployment.employmentStatus.value?.vtCode);
  const primaryEmploymentStatus = useAppSelector(state => state.updateAdditionalDriver.primaryEmployment.employmentStatus.value?.vtCode);
  const additionalDrivers = useAppSelector(state => state.personalDetails.additionalDrivers);
  const driverMaritalStatus = useAppSelector(state => state.updateAdditionalDriver.maritalStatus.value?.id);
  const driverId = useAppSelector(state => state.updateAdditionalDriver.idOfDriverBeingEdited);

  const onDriverRelationshipSelected = (selection: string): void => {
    const mappedStatus = RELATIONSHIP_TO_PROPOSER_OPTIONS.find(relationshipToProposer =>
      relationshipToProposer.description === selection
    );
    if (!mappedStatus) return;

    const validationResult = runValidators(
      mappedStatus,
      relationshipToProposerMatchesMaritalStatusValidator(driverMaritalStatus),
      spouseSelectedValidator(proposerMaritalStatus),
      civilPartnerSelectedValidator(proposerMaritalStatus),
      commonLawSpouseSelectedValidator(proposerMaritalStatus),
      multipleSpouseSelectedValidator({additionalDrivers, driverId}),
      multipleCivilPartnerSelectedValidator({additionalDrivers, driverId}),
      multipleCommonLawSpouseSelectedValidator({additionalDrivers, driverId}),
      childSelectedValidator({
        additionalDriverDateOfBirth: dateOfBirth,
        proposerDateOfBirth
      }),
      grandchildSelectedValidator({
        additionalDriverDateOfBirth: dateOfBirth,
        proposerDateOfBirth
      }),
      parentSelectedValidator({
        additionalDriverDateOfBirth: dateOfBirth,
        proposerDateOfBirth
      }),
      grandparentSelectedValidator({
        additionalDriverDateOfBirth: dateOfBirth,
        proposerDateOfBirth
      }),
      employeeSelectedValidator({
        proposerEmploymentStatus: proposerPrimaryEmploymentStatus,
        employmentStatus: primaryEmploymentStatus
      }),
      employerSelectedValidator({
        proposerEmploymentStatus: proposerPrimaryEmploymentStatus,
        employmentStatus: primaryEmploymentStatus
      }),
      businessPartnerSelectedValidator({
        proposerEmploymentStatus: proposerPrimaryEmploymentStatus,
        employmentStatus: primaryEmploymentStatus
      }),
    );

    validationResult.passed
      ? onValidRelationshipToProposer(mappedStatus)
      : dispatch(relationshipToProposerInvalid(validationResult.errorMessage));
  };

  const onValidRelationshipToProposer = (status: RelationshipToProposerOption): void => {
    logAdditionalDriverGoogleAnalyticsEvent({
      categorySuffix: "relationship_to_policyholder",
      action: "Answered"
    });

    dispatch(relationshipToProposerChanged(status));
  };

  return (
    <DropdownQuestion
      id="relationship-to-proposer-question"
      title="additionalDriver.relationshipToProposerQuestion.title"
      options={RELATIONSHIP_TO_PROPOSER_DESCRIPTIONS}
      value={statusField.value?.description}
      onChange={onDriverRelationshipSelected}
      placeholder="additionalDriver.relationshipToProposerQuestion.placeholder"
      status={statusField.status}
      errorMessage={statusField.errorMessage}
      hideSpacer
    />
  );
};

export default RelationshipToProposerQuestion;