import React, {FC} from "react";
import {useParams} from "react-router-dom";
import Modal from "shared-components/dist/modals/Modal";
import SimpleModalHeader from "shared-components/dist/modals/simple-modal-header/SimpleModalHeader";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import ButtonGroup from "shared-components/dist/buttons/button-group/ButtonGroup";
import Button from "shared-components/dist/buttons/button/Button";
import "./RemoveAdditionalDriverModal.css";
import {removeAdditionalDriver} from "../../shared/redux/thunks/RemoveAdditionalDriver";
import {useAppDispatch} from "../../../../../../redux/Hooks";

interface Props {
  dismiss: () => void;
  visible: boolean;
  onRemove: () => void;
}

const RemoveAdditionalDriverModal: FC<Props> = (
  {
    dismiss,
    visible,
    onRemove
  }
) => {
  const dispatch = useAppDispatch();

  const {driverId} = useParams<{ driverId: string }>();

  function onRemoveDriver(): void {
    if (!driverId) return;

    dispatch(removeAdditionalDriver(driverId));
    dismiss();
    onRemove();
  }

  if (!driverId) return null;

  return (
    <Modal
      className="remove-additional-driver-modal"
      visible={visible}
      onDismiss={dismiss}
      header={<SimpleModalHeader onDismiss={dismiss} title="additionalDriver.remove.modal.title"/>}
      body={<span>{lookupI18nString("additionalDriver.remove.modal.description")}</span>}
      footer={
        <ButtonGroup>
          <Button onClick={dismiss} variant="tertiary">
            {lookupI18nString("additionalDriver.remove.modal.buttons.cancel")}
          </Button>

          <Button onClick={onRemoveDriver}>
            {lookupI18nString("additionalDriver.remove.modal.buttons.remove")}
          </Button>
        </ButtonGroup>
      }
    />
  );
};

export default RemoveAdditionalDriverModal;
