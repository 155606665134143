import React, {FC} from "react";
import ProtectedNoClaimsBonusInformation from "./ProtectedNoClaimsBonusInformation";
import "./ProtectedNoClaimsBonusInformation.css";
import {useAppSelector} from "../../../../redux/Hooks";
import {useNavigate} from "react-router-dom";
import {useEffectOnFirstRender} from "shared-components/src/utils/hooks/UseEffectOnFirstRender";
import QuoteHeader from "../shared/quote-step/header/QuoteHeader";

const QuoteSummaryProtectedNoClaimsBonusInformation: FC = () => {
  const noClaimsDiscountProtectionData = useAppSelector(state => state.quoteDetails.noClaimsDiscountProtectionData);
  const yearsNoClaimsBonus = useAppSelector(state => state.coverDetails.yearsNoClaimsBonus);
  const insurerName = useAppSelector(state => state.quoteDetails.insurerName);

  const navigate = useNavigate();

  useEffectOnFirstRender(() => {
    if (!noClaimsDiscountProtectionData || !yearsNoClaimsBonus.value || !insurerName) navigate(-1);
  });

  return (
    <div data-testid="quote-summary-protected-no-claims-info">
      <ProtectedNoClaimsBonusInformation
        header={<QuoteHeader/>}
        isPortal={false}
        noClaimsDiscountProtectionData={noClaimsDiscountProtectionData}
        yearsNoClaimsBonus={yearsNoClaimsBonus.value}
        insurerName={insurerName}
      />
    </div>
  );
};

export default QuoteSummaryProtectedNoClaimsBonusInformation;