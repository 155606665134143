import {loadQuotes} from "../../your-quote/redux/thunks/LoadQuotes";
import {createAppAsyncThunk} from "../../../../../redux/CreateAppAsyncThunk";
import {quoteRetrievalStatusChanged} from "../../your-quote/redux/QuoteDetailsSlice";
import {QuoteRetrievalStatus} from "../../your-quote/models/QuoteRetrievalStatus";
import {handleRefreshedQuotes} from "./HandleRefreshedQuotes";

interface RefreshQuotesParams {
  monthlyPaymentsEnabled: boolean;
}

export const refreshQuotes = createAppAsyncThunk(
  "quoteDetails/refreshQuotes",
  async ({monthlyPaymentsEnabled}: RefreshQuotesParams, {dispatch, getState}) => {
    await dispatch(quoteRetrievalStatusChanged(QuoteRetrievalStatus.LOADING));

    await dispatch(loadQuotes({monthlyPaymentsEnabled}));

    handleRefreshedQuotes(getState, dispatch);
  }
);
