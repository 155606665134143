import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {ClaimStatus} from "shared/dist/generated/graphql/resolvers-types";

export const CLAIM_STATUSES = [
  {
    id: ClaimStatus.Settled,
    description: lookupI18nString("structure.questions.claims.status.settled")
  },
  {
    id: ClaimStatus.Pending,
    description: lookupI18nString("structure.questions.claims.status.pending")
  }
] as const;

export type ClaimStatusOption = typeof CLAIM_STATUSES[number];

export const CLAIM_STATUS_OPTIONS = CLAIM_STATUSES.map(status => status.description);