import {RenewalNotice, RenewalsQueryRenewalNoticeArgs} from "shared/dist/generated/graphql/resolvers-types";
import {apolloClient} from "../../apollo/ApolloClientProvider";
import {
  PORTAL_FETCH_RENEWAL_NOTICES_QUERY,
  PortalFetchRenewalNoticesResult
} from "shared/dist/graphql/queries/portal/RenewalNoticeQuery";

export async function loadRenewalNoticeQuery(args: RenewalsQueryRenewalNoticeArgs): Promise<RenewalNotice> {
  return new Promise((resolve, reject) => {
    apolloClient.query<PortalFetchRenewalNoticesResult, RenewalsQueryRenewalNoticeArgs>({
      query: PORTAL_FETCH_RENEWAL_NOTICES_QUERY,
      variables: args
    }).then(result => resolve(result.data.renewals.renewalNotice))
      .catch(error => reject(error));
  });
}