import React, {FC, ReactElement, SyntheticEvent, useState} from "react";
import "./EditAccountSetting.css";
import Button from "shared-components/dist/buttons/button/Button";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import Rag from "shared-components/dist/information/rag/Rag";
import Spacer from "shared-components/dist/spacer/Spacer";
import InputField from "shared-components/dist/form/input-field/InputField";
import {Refresh} from "@mui/icons-material";

export type ButtonState = "Default" | "Disabled" | "Loading"

interface Props {
  accountSettingInputComponent: ReactElement;
  isVisible: boolean;
  sendCodeButtonState: ButtonState;
  showSendCodeInputErrorMessage: boolean;
  sendCodeInputErrorMessage: TranslationKey;
  onSendConfirmationCode: () => void;
  onResendConfirmationCode: () => void;
  shouldShowConfirmationCodeInput: boolean;
  verifyButtonState: ButtonState;
  codeSentDestination: string;
  showConfirmationCodeInputErrorMessage: boolean;
  confirmationCodeInputErrorMessage: TranslationKey;
  onVerifyAccountSetting: (verificationCode: string) => void;
}

const EditAccountSetting: FC<Props> = (
  {
    accountSettingInputComponent,
    isVisible,
    sendCodeButtonState,
    showSendCodeInputErrorMessage,
    sendCodeInputErrorMessage,
    onSendConfirmationCode,
    onResendConfirmationCode,
    shouldShowConfirmationCodeInput,
    verifyButtonState,
    codeSentDestination,
    showConfirmationCodeInputErrorMessage,
    confirmationCodeInputErrorMessage,
    onVerifyAccountSetting
  }
) => {
  const [verificationCode, setVerificationCode] = useState("");
  if (!isVisible) return null;

  function sendConfirmationCode(event: SyntheticEvent): void {
    event.preventDefault();
    onSendConfirmationCode();
  }

  function resendConfirmationCode(event: SyntheticEvent): void {
    event.preventDefault();
    onResendConfirmationCode();
  }

  function verifyAccountSetting(event: SyntheticEvent): void {
    event.preventDefault();
    onVerifyAccountSetting(verificationCode.trim());
  }

  return (
    <div className="edit-account-setting">
      {shouldShowConfirmationCodeInput && (
        <Rag description={{
          id: "accountSettings.form.codeSentSuccess",
          templateVariables: {
            destination: codeSentDestination
          }
        }}/>
      )}
      <form onSubmit={sendConfirmationCode}>
        <div className="edit-account-setting__input">
          {accountSettingInputComponent}
        </div>
        <Button
          className="send-code-button"
          disabled={sendCodeButtonState === "Disabled"}
          loading={sendCodeButtonState === "Loading"}
          type="submit"
        >
          {lookupI18nString("accountSettings.form.sendCode")}
        </Button>
      </form>
      {showSendCodeInputErrorMessage && (
        <Rag
          description={sendCodeInputErrorMessage}
          status="error"
        />
      )}
      {shouldShowConfirmationCodeInput && (
        <>
          <Spacer/>
          <form onSubmit={verifyAccountSetting}>
            <div className="edit-account-setting__input">
              <InputField
                name="confirmation-code"
                onValueChange={setVerificationCode}
                placeholder={"accountSettings.form.confirmationCode.placeholder"}
              />
            </div>
            <Button
              disabled={verifyButtonState === "Disabled"}
              loading={verifyButtonState === "Loading"}
              type="submit"
            >
              {lookupI18nString("accountSettings.form.verify")}
            </Button>
          </form>
          <Button
            variant="link"
            className="resend-code-button"
            rightIcon={<Refresh/>}
            onClick={resendConfirmationCode}
          >
            {lookupI18nString("accountSettings.form.resendCode")}
          </Button>
          {showConfirmationCodeInputErrorMessage && (
            <Rag
              description={confirmationCodeInputErrorMessage}
              status="error"
            />
          )}
        </>
      )}
    </div>
  );
};

export default EditAccountSetting;
