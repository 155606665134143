import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../../../../../../redux/Store";
import {isAggregatorQuoteSelector} from "./AggregatorQuoteSelectors";

export const hasMultipleQuotesSelector = createSelector(
  (state: RootState) => state.quoteDetails.insurerQuotes,
  (insurerQuotes): boolean => insurerQuotes !== undefined && insurerQuotes?.length > 1
);

export const shouldAllowUserToCompareQuotesSelector = createSelector(
  hasMultipleQuotesSelector,
  (state: RootState) => state.quoteDetails.didUserSelectQuoteOnCompareYourQuotes,
  isAggregatorQuoteSelector,
  (hasMultipleQuotes, hasUserSelectedQuoteOnCompareYourQuotes, isAggregatorQuote) => {
    if (isAggregatorQuote) return hasMultipleQuotes && hasUserSelectedQuoteOnCompareYourQuotes;

    return hasMultipleQuotes;
  }
);