import React, {FC} from "react";
import {insuranceDeclinedQuestionChanged} from "../../redux/UpdateAdditionalDriverSlice";
import InsuranceDeclinedQuestion from "../../../../shared/questions/insurance-declined-question/InsuranceDeclinedQuestion";
import {insuranceDeclinedStopVisibleSelector} from "../../redux/selectors/InsuranceDeclinedStopVisibleSelector";
import {logAdditionalDriverGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const AdditionalDriverInsuranceDeclinedQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const hasInsuranceDeclined = useAppSelector(state => state.updateAdditionalDriver.hasInsuranceDeclined);
  const isStopVisible = useAppSelector(insuranceDeclinedStopVisibleSelector);

  const onHasInsuranceDeclinedAnswered = (answer: boolean): void => {
    logAdditionalDriverGoogleAnalyticsEvent({
      categorySuffix: "insurance_declined_cancelled",
      action: answer
    });

    dispatch(insuranceDeclinedQuestionChanged(answer));
  };

  return (
    <InsuranceDeclinedQuestion
      title="additionalDriver.insuranceDeclinedQuestion.title"
      formField={hasInsuranceDeclined}
      onAnswer={onHasInsuranceDeclinedAnswered}
      isStopVisible={isStopVisible}
    />
  );
};

export default AdditionalDriverInsuranceDeclinedQuestion;
