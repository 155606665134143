import {FC} from "react";
import ModalStop from "../../../../../../../../structure/information/stop/modal/ModalStop";
import Button from "shared-components/dist/buttons/button/Button";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./UnderageFinanceStop.css";
import {openZendeskChat} from "../../../../../../../../zendesk/Zendesk";

interface Props {
  visible: boolean;
  dismiss: () => void;
}

const UnderageFinanceStop: FC<Props> = ({visible, dismiss}) => {
  const getInTouchClicked = (): void => {
    dismiss();
    openZendeskChat();
  };

  return (
    <ModalStop
      title="compareYourQuote.underageMonthlyPaymentStop.title"
      description="compareYourQuote.underageMonthlyPaymentStop.description"
      visible={visible}
      footer={
        <div className="underage-finance-stop__footer">
          <Button
            variant="tertiary"
            expanded
            onClick={dismiss}
          >
            {lookupI18nString("compareYourQuote.underageMonthlyPaymentStop.close")}
          </Button>

          <Button
            expanded
            onClick={getInTouchClicked}
          >
            {lookupI18nString("compareYourQuote.underageMonthlyPaymentStop.contact")}
          </Button>
        </div>
      }
    />
  );
};

export default UnderageFinanceStop;