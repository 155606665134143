import React, {FC} from "react";
import {usePolicyQueryParams} from "../../../router/params/Params";
import PolicyErrorPage from "../policy-error/PolicyErrorPage";
import {fetchPolicyFor} from "shared/dist/graphql/queries/portal/PortalDashboardQuery";
import PolicySummary from "./sub-pages/summary/PolicySummary";
import CustomerPortalContainerWithSidebar from "../shared/container/CustomerPortalContainerWithSidebar";
import PoliciesHeader from "./header/PoliciesHeader";
import PoliciesSidebar from "./sidebar/PoliciesSidebar";
import {policyViewSidebarMenuItems} from "../../../structure/navigation/navbar/menu/MenuItems";
import PolicyLoading from "./loading/PolicyLoading";
import {endDateIsWithinRenewalDueRange} from "../../../utils/validation/RenewalDueValidator";

const PolicyView: FC = () => {
  const {policyId} = usePolicyQueryParams();
  const {loading, error, data} = fetchPolicyFor(policyId);

  if (loading) return <PolicyLoading/>;

  if (!data || !data.policy || error) return <PolicyErrorPage/>;

  const hasRenewalsTab = endDateIsWithinRenewalDueRange(data.policy.metadata.coverEndDate);

  return (
    <CustomerPortalContainerWithSidebar
      header={
        <PoliciesHeader
          policy={data.policy}
          pageSubHeader="policies.header.subHeader.coverSummary"/>
      }
      mainContent={
          <PolicySummary policy={data.policy}/>
      }
      sidebar={<PoliciesSidebar menuItems={policyViewSidebarMenuItems(policyId, hasRenewalsTab)}/>}
    />
  );
};

export default PolicyView;
