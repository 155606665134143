import React, {FC} from "react";
import {protectNoClaimsBonusChanged} from "../../../redux/CoverDetailsSlice";
import {mapValueToYesOrNoEventType} from "shared-components/dist/analytics/GoogleAnalyticsEventsService";
import {logYourCoverGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import BooleanQuestion
  from "../../../../../../../structure/questions/question/variants/boolean-question/BooleanQuestion";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const ProtectNoClaimsBonusQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const protectNoClaimsBonusField = useAppSelector(state => state.coverDetails.protectNoClaimsBonus);

  const onProtectNoClaimsBonusChanged = (value: boolean): void => {
    logYourCoverGoogleAnalyticsEvent({
      categorySuffix: "no_claims_bonus_protection",
      action: mapValueToYesOrNoEventType(value),
    });

    dispatch(protectNoClaimsBonusChanged(value));
  };

  return (
    <>
      <BooleanQuestion
        id="protect-no-claims-bonus-question"
        title="coverDetails.protectNoClaimsBonusSubQuestion.title"
        formField={protectNoClaimsBonusField}
        onAnswer={onProtectNoClaimsBonusChanged}
        hideSpacer
        tooltip={{
          title: "coverDetails.protectNoClaimsBonusSubQuestion.tooltip.title",
          description: "coverDetails.protectNoClaimsBonusSubQuestion.tooltip.description"
        }}
      />
    </>
  );
};

export default ProtectNoClaimsBonusQuestion;
