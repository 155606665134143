import {RootState} from "../../../../../../../redux/Store";
import {createSelector} from "@reduxjs/toolkit";
import {isValidFormField, RequiredFormField} from "shared-components/dist/models/form-field/FormField";
import {activeFormFields} from "shared-components/dist/models/form-field/FormFields";
import {isValidClaimsList} from "../../../../shared/questions/claims/validation/ClaimValidator";
import {isValidConvictionsList} from "../../../../shared/questions/convictions/validation/ValidConviction";
import {Convictions} from "../../../../shared/questions/convictions/models/Conviction";
import {Claims} from "../../../../shared/questions/claims/models/Claims";
import {claimsSelector, convictionsSelector} from "./AdditionalDriverDateTypeSelectors";
import {UpdateAdditionalDriver} from "../UpdateAdditionalDriver";

const updateAdditionalDriverSelector = (state: RootState): UpdateAdditionalDriver => state.updateAdditionalDriver;

export const additionalDriverDetailsValidSelector = createSelector(
  updateAdditionalDriverSelector,
  claimsSelector,
  convictionsSelector,
  (
    updateAdditionalDriver: UpdateAdditionalDriver,
    claims: RequiredFormField<Claims>,
    convictions: RequiredFormField<Convictions>
  ): boolean => {
    return isValidFormField(...activeFormFields(updateAdditionalDriver)) &&
      isValidClaimsList(claims.value) &&
      isValidConvictionsList(convictions.value) &&
      isValidFormField(...activeFormFields(updateAdditionalDriver.primaryEmployment)) &&
      isValidFormField(...activeFormFields(updateAdditionalDriver.secondaryEmployment));
  }
);