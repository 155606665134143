import React, {FC} from "react";
import OwnOrCompanyCarQuestion from "./own-or-company-car/OwnOrCompanyCarQuestion";
import {shouldShowNamedDriverIneligiblePanelSelector} from "../../redux/selectors/CoverDetailsSelectors";
import CompanyCarBonusQuestions from "./company-car-bonus-questions/CompanyCarBonusQuestions";
import NamedConfirmation from "./named-confirmation/NamedConfirmation";
import "./NamedDriverAndCompanyCarQuestionPanel.css";
import IsPreviousNamedDriverQuestion from "./shared/is-previous-named-driver/IsPreviousNamedDriverQuestion";
import {OwnOrCompanyCar} from "./own-or-company-car/OwnOrCompanyCarOption";
import PreviousNamedDriverQuestions from "./shared/is-previous-named-driver/PreviousNamedDriverQuestions";
import PreviousCoverDetailsForm from "../previously-held-insurance/previous-cover-details-form/PreviousCoverDetailsForm";
import QuestionSetCompanyCarIneligibleInfoCard from "./company-car-ineligible-info-card/QuestionSetCompanyCarIneligibleInfoCard";
import NamedDriverIneligiblePanel from "./shared/named-driver-ineligible-panel/NamedDriverIneligiblePanel";
import {isNamedDriverAXAEligibleSelector} from "../../redux/selectors/named-driver/IsNamedDriverAXAEligible";
import CompanyCarBonusIneligiblePanel from "./shared/company-car-bonus-ineligible-panel/CompanyCarBonusIneligiblePanel";
import HasVehicleBeenInsuredQuestion from "./shared/has-vehicle-been-insured/HasVehicleBeenInsuredQuestion";
import VehiclePreviousPolicyExpiryDateQuestion from "./shared/vehicle-previous-policy-expiry-date/VehiclePreviousPolicyExpiryDateQuestion";
import QuoteJourneyStop from "../../../shared/stops/quote-journey-stop/QuoteJourneyStop";
import {
  selectNoBreakInCoverDetected,
  selectShouldShowBreakInCoverSoftStop
} from "../../redux/selectors/named-driver/VehicleBreakInCoverSelectors";
import NamedDriverOnOwnVehicleQuestion from "./shared/named-driver-on-own-vehicle/NamedDriverOnOwnVehicleQuestion";
import {useAppSelector} from "../../../../../../redux/Hooks";
import SubQuestionForm from "../../../../../../structure/questions/sub-question-form/SubQuestionForm";

const NamedDriverAndCompanyCarQuestionPanel: FC = () => {
  const previouslyHeldInsuranceField = useAppSelector(state => state.coverDetails.previouslyHeldInsurance);
  const isPreviousNamedDriverField = useAppSelector(state => state.coverDetails.isPreviousNamedDriver);
  const ownOrCompanyCarField = useAppSelector(state => state.coverDetails.ownOrCompanyCar);
  const companyCarDeclarationAcceptanceField = useAppSelector(state => state.coverDetails.companyCarDeclarationAcceptance);
  const namedDriverDeclarationAcceptanceField = useAppSelector(state => state.coverDetails.namedDriverDeclarationAcceptance);
  const shouldShowNamedDriverIneligiblePanel = useAppSelector(shouldShowNamedDriverIneligiblePanelSelector);
  const isEligibleForNamedDriver = useAppSelector(isNamedDriverAXAEligibleSelector);
  const hasVehicleBeenInsuredField = useAppSelector(state => state.coverDetails.hasVehicleBeenInsured);
  const namedDriverOnOwnVehicleField = useAppSelector(state => state.coverDetails.namedDriverOnOwnVehicle);
  const vehiclePreviousPolicyExpiryDateField = useAppSelector(state => state.coverDetails.vehiclePreviousPolicyExpiryDate);
  const shouldShowBreakInCoverSoftStop = useAppSelector(selectShouldShowBreakInCoverSoftStop);
  const noBreakInCoverDetected = useAppSelector(selectNoBreakInCoverDetected);

  const isCompanyCarSelected = ownOrCompanyCarField.value?.id === OwnOrCompanyCar.CompanyCar;
  const isOwnPolicySelected = ownOrCompanyCarField.value?.id === OwnOrCompanyCar.OwnPolicy;
  const shouldShowPreviousNamedDriverQuestions = isEligibleForNamedDriver && previouslyHeldInsuranceField.value === false;

  const getInfoPanel = (): React.ReactNode => {
    if (isCompanyCarSelected && !isEligibleForNamedDriver) return <CompanyCarBonusIneligiblePanel/>;
    if (shouldShowPreviousNamedDriverQuestions && shouldShowNamedDriverIneligiblePanel) return <NamedDriverIneligiblePanel/>;
    if (shouldShowPreviousNamedDriverQuestions && namedDriverDeclarationAcceptanceField.isActive) return <NamedConfirmation/>;

    return null;
  };

  return (
    <div className="named-driver-and-company-car-questions-panel">
      <SubQuestionForm
        infoPanel={getInfoPanel()}
        id="named-driver-and-company-car-questions-panel"
      >
        {ownOrCompanyCarField.isActive && <OwnOrCompanyCarQuestion/>}
        {hasVehicleBeenInsuredField.isActive && <HasVehicleBeenInsuredQuestion/>}
        {namedDriverOnOwnVehicleField.isActive && <NamedDriverOnOwnVehicleQuestion/>}
        {vehiclePreviousPolicyExpiryDateField.isActive && <VehiclePreviousPolicyExpiryDateQuestion/>}

        <QuoteJourneyStop
          stopProps={{
            title: "coverDetails.namedDriverBreakInCover.softStop.title",
            description: "coverDetails.namedDriverBreakInCover.softStop.description",
            visible: shouldShowBreakInCoverSoftStop
          }}
        />

        {isCompanyCarSelected && isEligibleForNamedDriver && noBreakInCoverDetected && <CompanyCarBonusQuestions/>}
        {isOwnPolicySelected && <PreviousCoverDetailsForm/>}

        {shouldShowPreviousNamedDriverQuestions &&
          <>
            {isPreviousNamedDriverField.isActive && <IsPreviousNamedDriverQuestion hideSpacer/>}
            <PreviousNamedDriverQuestions/>
          </>
        }
      </SubQuestionForm>
      {companyCarDeclarationAcceptanceField.value === false && <QuestionSetCompanyCarIneligibleInfoCard/>}
    </div>
  );
};

export default NamedDriverAndCompanyCarQuestionPanel;