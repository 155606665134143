import React, {FC} from "react";
import "./RenewalExpired.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import Button from "shared-components/dist/buttons/button/Button";
import FormattedMessage from "shared-components/src/translations/components/FormattedMessage";
import {openZendeskChat} from "../../../../../../../zendesk/Zendesk";
import {format} from "date-fns";
import PlainCard from "shared-components/src/cards/plain-card/PlainCard";

interface Props {
  policyEndDate: Date
}

const RenewalExpired: FC<Props> = ({policyEndDate}) => {
  const formattedPolicyEndDate = format(policyEndDate, "dd/MM/yy 'at' HH:mm");
  return (
    <PlainCard>
      <div className="renewal-expired">
        <h1 className="renewal-expired__title">{lookupI18nString("renewal.expiredPage.yourRenewalOfferHasExpired")}</h1>

        <p id="renewal-expired__description" data-testid="online-access-expired">
          <FormattedMessage
            id="renewal.expiredPage.onlineAccessExpired"
            values={{
              br: <br/>,
              policyEndDate: formattedPolicyEndDate
            }}/>
        </p>

        <Button
          data-testid="renewal-expired__button"
          variant="secondary"
          small={true}
          onClick={openZendeskChat}>
          {lookupI18nString("renewal.expiredPage.getInTouch")}
        </Button>
      </div>
    </PlainCard>
  );
};

export default RenewalExpired;