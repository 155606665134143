import React, {FC} from "react";
import {useAppSelector} from "../../../../../redux/Hooks";
import NoClaimsProtectionCard from "../../../../quote/vehicle/shared/quote-summary/components/no-claims-protection-card/NoClaimsProtectionCard";
import {useNavigate} from "react-router-dom";
import {
  selectExistingPolicyHolderForRenewal,
  selectIsEligibleForNoClaimsBonusProtection,
  selectNoClaimsBonusProtectionCost,
  selectNoClaimsBonusProtectionData,
  selectShouldProtectNoClaimsBonus
} from "../../redux/RenewalSelectors";
import {usePolicyQueryParams} from "../../../../../router/params/Params";

const RenewalsConfirmNoClaimsProtectionCard: FC = () => {
  const {policyId} = usePolicyQueryParams();
  const isEligibleForNoClaimsBonusProtection = useAppSelector(selectIsEligibleForNoClaimsBonusProtection);
  const protectNoClaimsBonus = useAppSelector(selectShouldProtectNoClaimsBonus);
  const {noClaimsBonusYears} = useAppSelector(selectExistingPolicyHolderForRenewal) ?? {noClaimsBonusYears: undefined};
  const noClaimsDiscountProtectionCost = useAppSelector(selectNoClaimsBonusProtectionCost);
  const noClaimsDiscountProtectionData = useAppSelector(selectNoClaimsBonusProtectionData);

  const navigate = useNavigate();

  const onInfoClicked = (): void => {
    navigate(`/portal/policy/${policyId}/renewals/summary/confirm-details/protected-no-claims-bonus-information`);
  };

  const onEditClicked = (): void => {
    navigate(`/portal/policy/${policyId}/renewals/summary#protected-no-claims-table`);
  };

  return (
    <NoClaimsProtectionCard
      canProtectNoClaimsBonus={isEligibleForNoClaimsBonusProtection}
      noClaimsDiscountProtectionData={noClaimsDiscountProtectionData}
      protectNoClaimsBonus={protectNoClaimsBonus}
      yearsNoClaimsBonus={noClaimsBonusYears ?? undefined}
      noClaimsDiscountProtectionCost={noClaimsDiscountProtectionCost}
      onInfoClicked={onInfoClicked}
      onEditClicked={onEditClicked}
    />
  );
};

export default RenewalsConfirmNoClaimsProtectionCard;