import {gql, useQuery} from "@apollo/client";
import {AbiListItem} from "shared/dist/generated/graphql/resolvers-types";
import {QueryResult} from "@apollo/client/react/types/types";

export interface GetIndustriesResult {
  quote: {
    abiCodes: {
      industries: AbiListItem[];
    };
  };
}

const GET_INDUSTRIES = gql`
  query GetIndustries {
    quote {
      abiCodes {
        industries {
          vtCode
          vtDescription
        }
      }
    }
  }
`;

export const useIndustriesQuery = (): QueryResult<GetIndustriesResult> => useQuery(GET_INDUSTRIES);