import React, {Dispatch, FC, ReactNode, SetStateAction} from "react";
import RenewalDetailsConfirmationTableHeader from "../table-items/RenewalDetailsConfirmationTableHeader";
import RenewalDetailsConfirmationButtonFooter from "../table-items/RenewalDetailsConfirmationButtonFooter";
import "./RenewalsConfirmAdditionalDriverConvictions.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {AdditionalDriver} from "shared/dist/generated/graphql/resolvers-types";
import {formatConvictions} from "../utils/Formatters";

interface Props {
  confirmed: boolean;
  setConfirmed: Dispatch<SetStateAction<boolean>>;
  setEditModalVisible: Dispatch<SetStateAction<boolean>>;
  additionalDrivers: AdditionalDriver[];
  previousSectionConfirmed: boolean;
}

const RenewalsConfirmAdditionalDriverConvictions: FC<Props> = ({
  confirmed,
  setConfirmed,
  setEditModalVisible,
  additionalDrivers,
  previousSectionConfirmed
}) => {
  const items = getStructuredAdditionalDriverConvictions(additionalDrivers);

  return (
    <div
      className={confirmed
        ? "renewals-confirm-additional-driver-convictions--confirmed"
        : `renewals-confirm-additional-driver-convictions${previousSectionConfirmed ? "" : "--disabled"} unconfirmed`}
      data-testid="renewals-confirm-additional-driver-convictions"
    >
      <RenewalDetailsConfirmationTableHeader
        title={lookupI18nString("portal.renewals.detailConfirmation.additionalDriverConvictions")}
        confirmed={confirmed}
      />
      {items}
      <RenewalDetailsConfirmationButtonFooter
        testId={"your-convictions"}
        confirmed={confirmed}
        setConfirmed={setConfirmed}
        setEditModalVisible={setEditModalVisible}
        disabled={!previousSectionConfirmed}
      />
    </div>
  );
};

function getStructuredAdditionalDriverConvictions(additionalDrivers: AdditionalDriver[]): ReactNode {
  return additionalDrivers.map((additionalDriver) => {
    return formatConvictions(additionalDriver.convictions, `${additionalDriver.firstName} ${additionalDriver.lastName}`);
  });
}

export default RenewalsConfirmAdditionalDriverConvictions;
