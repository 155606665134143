import {AnyAction, Middleware, PayloadAction} from "@reduxjs/toolkit";
import type {AppThunkDispatch, RootState} from "../Store";
import {
  personalDetailsSliceName, phoneNumberChanged,
  proposerEmailAddressChanged
} from "../../pages/quote/vehicle/your-details/redux/PersonalDetailsSlice";
import {vehicleDetailsSliceName} from "../../pages/quote/vehicle/your-vehicle/redux/VehicleDetailsSlice";
import {coverDetailsSliceName} from "../../pages/quote/vehicle/your-cover/redux/CoverDetailsSlice";
import {requoteIsRequired} from "../../pages/quote/vehicle/your-quote/redux/QuoteDetailsSlice";
import {additionalDriverAdded, additionalDriverEdited, additionalDriverRemoved} from "../Actions";

export const RiskChangesTrackingMiddleware: Middleware<{}, RootState, AppThunkDispatch> = store => next => action => {
  const isRequoteAlreadyRequired = store.getState().quoteDetails.isRequoteRequired;

  if (!isRequoteAlreadyRequired && doesActionChangeRisk(action)) {
    store.dispatch(requoteIsRequired());
  }

  return next(action);
};

const doesActionChangeRisk = (action: AnyAction): boolean => {
  if (isActionUpdatingAdditionalDrivers(action.type)) return true;

  if (!isPayloadAction(action)) return false;

  if (isActionUpdatingContactDetails(action.type)) return false;

  return isActionForAUserDetailSlice(action.type);
};

const isActionUpdatingAdditionalDrivers = (actionType: string): boolean => [
  additionalDriverAdded.type,
  additionalDriverEdited.type,
  additionalDriverRemoved.type
].includes(actionType);

const isActionUpdatingContactDetails = (actionType: string): boolean => (
  actionType === proposerEmailAddressChanged.type || actionType === phoneNumberChanged.type
);

const isPayloadAction = (action: AnyAction): action is PayloadAction => "payload" in action && "type" in action;

const isActionForAUserDetailSlice = (actionType: string): boolean => {
  const slice = actionType.split("/")[0];

  const userDetailsSliceNames: string[] = [
    personalDetailsSliceName,
    vehicleDetailsSliceName,
    coverDetailsSliceName
  ];

  return userDetailsSliceNames.includes(slice);
};