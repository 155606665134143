import {
  ApplePayFinancedPolicySetUpRequest,
  ApplePayPaymentToken,
  FinancedPolicyPaymentResult,
  Mutation
} from "shared/dist/generated/graphql/resolvers-types";
import {apolloClient} from "../../../../../../graphql/apollo/ApolloClientProvider";
import {
  APPLE_PAY_SET_UP_FINANCED_POLICY,
  ApplePayFinancedPolicyPaymentVariables
} from "shared/dist/graphql/mutations/payment/ApplePaySetUpFinancedPolicyMutation";
import {
  APPLE_PAY_PROCESS_FINANCED_RENEWAL,
  ApplePayFinancedRenewalPaymentVariables
} from "shared/dist/graphql/mutations/payment/ApplePayProcessFinancedRenewalMutation";
import {ProposedFinancedRenewalRequest} from "../../../../shared/models/ProposedFinancedRenewalRequest";

export async function applePayPurchaseFinancedPolicyMutation(request: ApplePayFinancedPolicySetUpRequest): Promise<FinancedPolicyPaymentResult> {
  const response = await apolloClient.mutate<Pick<Mutation, "policySetup">, ApplePayFinancedPolicyPaymentVariables>({
    mutation: APPLE_PAY_SET_UP_FINANCED_POLICY,
    variables: {
      request
    }
  });
  return response.data?.policySetup.applePay.setUpFinancedPolicy.result ?? FinancedPolicyPaymentResult.SomethingWentWrong;
}

export async function applePayPurchaseFinancedRenewalMutation(payment: ApplePayJS.ApplePayPayment, proposedPolicyRequest: ProposedFinancedRenewalRequest): Promise<FinancedPolicyPaymentResult> {
  const response = await apolloClient.mutate<Pick<Mutation, "renewals">, ApplePayFinancedRenewalPaymentVariables>({
    mutation: APPLE_PAY_PROCESS_FINANCED_RENEWAL,
    variables: {
      request: {
        payment: {
          token: payment.token.paymentData as ApplePayPaymentToken
        },
        ...proposedPolicyRequest
      }
    }
  });
  return response.data?.renewals.applePay.processFinancedRenewal.result ?? FinancedPolicyPaymentResult.SomethingWentWrong;
}
