import {apolloClient} from "../../apollo/ApolloClientProvider";
import {gql} from "@apollo/client";
import {Query} from "shared/dist/generated/graphql/resolvers-types";
import {logger} from "../../../utils/logging/Logger";

export type ZendeskDetailsResult = Pick<Query, "zendesk">;

const ZENDESK_DETAILS_QUERY = gql`
  query ZendeskDetails {
    zendesk {
      generateAuthenticationToken {
        token
      }
    }
  }
`;

export async function generateZendeskChatToken(): Promise<string> {
  try {
    const result = await apolloClient.query<ZendeskDetailsResult>({
      query: ZENDESK_DETAILS_QUERY,
    });

    if (!result.data?.zendesk?.generateAuthenticationToken?.token) {
      throw new Error("Zendesk token did not generate successfully");
    }
    return result.data.zendesk.generateAuthenticationToken.token;
  } catch (error) {
    logger.error("Failed to generate zendesk chat token", error);
    throw error;
  }
}
