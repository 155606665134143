import React, {FC, useCallback, useEffect, useState} from "react";
import CardHolderName from "./components/CardHolderName";
import CardNumber from "./components/CardNumber";
import CardSecurityNumber from "./components/CardSecurityNumber";
import CardExpiry from "./components/CardExpiry";
import SubmitButton from "./components/SubmitButton";
import "./CardPaymentForm.css";
import {useGlobalPayAccessToken} from "./globalpay/GlobalPayAccessTokenQuery";
import {createGlobalPayCreditCardForm} from "./globalpay/GlobalPayCreditCardFormInjector";
import {IError, ISuccess} from "@globalpayments/js/types/internal/gateways";
import SpinnerIcon from "shared-components/dist/spinner/spinner-icon/SpinnerIcon";
import {useNavigate} from "react-router-dom";
import {QUOTE_PAYMENT_SUCCESS} from "../../../../router/models/Routes";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import PaymentFailedModal from "../payment-failed-modal/PaymentFailedModal";
import {loadGlobalPayProvider} from "./globalpay/GlobalPayProvider";
import {CardPaymentFormStatus} from "./models/CardPaymentFormStatus";
import {FinancedPolicyPaymentResponse, FullPolicyPaymentResponse, FullPolicyPaymentResult, ThreeDSecureResponse} from "shared/dist/generated/graphql/resolvers-types";
import Title from "shared-components/dist/title/Title";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {transactionIdChanged} from "../../../quote/vehicle/your-quote/redux/QuoteDetailsSlice";
import {logPaymentGoogleAnalyticsEvent} from "../../../../utils/analytics/PaymentAnalytics";
import {ChallengeWindowSize, handleInitiateAuthentication} from "globalpayments-3ds";
import {IInitiateAuthenticationResponseData} from "globalpayments-3ds/dist/types/interfaces";
import {Nullable} from "shared/dist/stdlib/TypeScriptHelpers";
import {logger} from "../../../../utils/logging/Logger";
import {quoteSteps} from "../../../quote/vehicle/shared/quote-step/QuoteSteps";
import {useAppDispatch} from "../../../../redux/Hooks";
import {PaymentFlowType} from "../models/PaymentFlowType";
import {renewalTransactionIdChanged} from "../../../portal/renewals/redux/RenewalSlice";
import {usePolicyQueryParams} from "../../../../router/params/Params";

export type MakeCardPayment = (globalPayFormResponse: ISuccess, threeDSecureTransactionReference?: string) => Promise<MakeCardPaymentResponse>;
export type MakeCardPaymentResponse = FinancedPolicyPaymentResponse | FullPolicyPaymentResponse;

interface Props {
  makePayment: MakeCardPayment;
  paymentFlowType: PaymentFlowType
}

const CardPaymentForm: FC<Props> = ({makePayment, paymentFlowType}) => {
  const dispatch = useAppDispatch();
  const {policyId} = usePolicyQueryParams();

  const {hasAccessTokenRetrievalError, accessToken} = useGlobalPayAccessToken();
  const navigate = useNavigate();
  const [formStatus, setFormStatus] = useState<CardPaymentFormStatus>("Loading");
  const [formError, setFormError] = useState<string | undefined>(undefined);

  useEffect(() => {
    const showFormAfterDelay = async (): Promise<void> => {
      function sleep(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

      await sleep(1000);
      setFormStatus("FormReady");
    };

    if (formStatus !== "ShowDelayedLoading") return;
    showFormAfterDelay();
  }, [formStatus]);

  useEffect(() => {
    let isCancelled = false;
    loadGlobalPayProvider().then(
      () => {
        if (!isCancelled) {
          setFormStatus("ProviderReady");
        }
      }
    );
    return () => {
      isCancelled = true;
    };
  }, []);

  const handlePaymentError = useCallback((paymentResponse?: MakeCardPaymentResponse): void => {
    if (!paymentResponse) paymentResponse = {result: FullPolicyPaymentResult.SomethingWentWrong};

    logPaymentGoogleAnalyticsEvent({
      categorySuffix: "card.submit",
      action: "Failure",
    });
    setFormStatus("PaymentError");
    setFormError(paymentResponse.result);
  }, [setFormStatus, setFormError]);

  const handlePaymentSuccess = useCallback((cardDetails: ISuccess): void => {
    logPaymentGoogleAnalyticsEvent({
      categorySuffix: "card.submit",
      action: "Success",
    });
    if (paymentFlowType === PaymentFlowType.QUOTE) {
      dispatch(transactionIdChanged(cardDetails.paymentReference));
      navigate(QUOTE_PAYMENT_SUCCESS);
    } else {
      dispatch(renewalTransactionIdChanged(cardDetails.paymentReference));
      navigate(`/portal/policy/${policyId}/renewals/payment/success`);
    }
  }, [dispatch, navigate, paymentFlowType, policyId]);

  const handleCardValidationError = useCallback((error: IError) => {
    logger.warn("Global pay form failure", error);
    setFormStatus("FormError");
    setFormError("FORM_LOADING_ERROR");
  }, [setFormStatus, setFormError]);

  const handleNormalPaymentResponse = useCallback((cardDetails: ISuccess, paymentResponse: MakeCardPaymentResponse): void => {
    if (paymentResponse.result === "SUCCESS") {
      handlePaymentSuccess(cardDetails);
    } else {
      handlePaymentError(paymentResponse);
    }
  }, [handlePaymentSuccess, handlePaymentError]);

  const handlePaymentRequiresThreeDSecure = useCallback(async (threeDSecureResponse: Nullable<ThreeDSecureResponse>, cardDetails: ISuccess): Promise<void> => {
    if (!threeDSecureResponse) return;

    let challengeSuccessful = false;
    const challengeModalCloseDetector = detectChallengeModalClosed(() => {
      // Need to give the GlobalPay library a chance to respond first
      setTimeout(() => {
        if (!challengeSuccessful) {
          handlePaymentError();
        }
      }, 500);
    });

    try {
      await showThreeDSecureChallengeModal(threeDSecureResponse);
      challengeSuccessful = true;
      setFormStatus("Loading");
      const paymentResponse = await makePayment(cardDetails, threeDSecureResponse.serverTransactionId);
      handleNormalPaymentResponse(cardDetails, paymentResponse);
    } catch (error) {
      logger.error("Failed to submit payment info", error);
      handlePaymentError();
    } finally {
      challengeModalCloseDetector.disconnect();
    }
  }, [makePayment, handleNormalPaymentResponse, handlePaymentError]);

  const handleCardValidationSuccess = useCallback(async (cardDetails: ISuccess): Promise<void> => {
    try {
      setFormStatus("Loading");
      const paymentResponse = await makePayment(cardDetails);

      if (paymentResponse.result === "REQUIRES_3D_SECURE") {
        setFormStatus("3dsLoading");
        await handlePaymentRequiresThreeDSecure(paymentResponse.threeDSecureResponse, cardDetails);
      } else {
        handleNormalPaymentResponse(cardDetails, paymentResponse);
      }
    } catch (error) {
      logger.error("Failed to submit payment info", error);
      handlePaymentError();
    }
  }, [makePayment, handlePaymentRequiresThreeDSecure, handleNormalPaymentResponse, handlePaymentError]);

  useEffect(() => {
    if (!accessToken || formStatus !== "ProviderReady") return;

    createGlobalPayCreditCardForm({
      accessToken,
      onCardValidationSuccess: handleCardValidationSuccess,
      onError: handleCardValidationError
    });
    setFormStatus("ShowDelayedLoading");
  }, [accessToken, formStatus, handleCardValidationSuccess, handleCardValidationError]);

  const onPaymentFailedModalDismissed = (): void => {
    setFormError(undefined);
    if (paymentFlowType === PaymentFlowType.QUOTE && formError === "POLICY_START_DATE_EXPIRED") navigate(quoteSteps.yourQuote.routes.new);
  };

  if (hasAccessTokenRetrievalError) {
    return <div className="card-payment-form__failure">{lookupI18nString("paymentFlow.byCard.failure")}</div>;
  }

  return (
    <div className="card-payment-form__container">
      <Title title="paymentFlow.byCard.form.cardDetails.title"/>

      {["ProviderReady", "Loading", "ShowDelayedLoading"].includes(formStatus) &&
        <div className="card-payment-form__loading-spinner"><SpinnerIcon/></div>
      }

      <div className={`card-payment-form${["Loading", "3dsLoading"].includes(formStatus) ? " card-payment-form--hidden" : ""}`}>
        <CardHolderName/>
        <CardNumber/>
        <CardSecurityNumber/>
        <CardExpiry/>
        <SubmitButton/>
      </div>

      <div id="acs-iframe-target"/>

      <PaymentFailedModal
        visible={formError !== undefined}
        onDismiss={onPaymentFailedModalDismissed}
        message={getModalErrorMessage(formError)}
        buttonText={getModalButtonText(formError)}
      />
    </div>
  );
};

async function showThreeDSecureChallengeModal(threeDSecureResponse: ThreeDSecureResponse): Promise<void> {
  await handleInitiateAuthentication(threeDSecureResponse as IInitiateAuthenticationResponseData, {
    displayMode: "embedded",
    windowSize: ChallengeWindowSize.Windowed500x600,
    origin: threeDSecureResponse.notificationLambdaOrigin,
    target: document.getElementById("acs-iframe-target") as HTMLElement
  });
}

// This beast is taken from https://stackoverflow.com/a/41884887/10022371. Basically this allows us to detect if an
// element has been removed, which we use to detect if GlobalPay's "overlay" has been removed.
// This is needed as GlobalPay don't provide this notification as part of their library.
function detectChallengeModalClosed(onModalClosed: () => void): MutationObserver {
  const observer = new MutationObserver(mutationRecords => {
    mutationRecords.forEach(mutation => {
      mutation.removedNodes.forEach(node => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (node.id.includes("GlobalPayments-overlay-")) {
          onModalClosed();
          observer.disconnect();
        }
      });
    });
  });
  observer.observe(document.getElementsByTagName("body")[0], {subtree: false, childList: true});
  return observer;
}

const getModalErrorMessage = (formError: string | undefined): TranslationKey => {
  if (!formError) return "paymentFlow.error.modal.footer.description.contact";

  switch (formError) {
    case "UNABLE_TO_MAKE_DEPOSIT_PAYMENT":
      return "paymentFlow.error.modal.footer.description.retry";
    case "UNABLE_TO_MAKE_PAYMENT":
      return "paymentFlow.error.modal.footer.description.retry";
    case "POLICY_START_DATE_EXPIRED":
      return "paymentFlow.error.modal.footer.expiredStartDate.description";
    default:
      return "paymentFlow.error.modal.footer.description.contact";
  }
};

const getModalButtonText = (formError: string | undefined): TranslationKey => {
  if (!formError) return "paymentFlow.error.modal.footer.button";

  switch (formError) {
    case "POLICY_START_DATE_EXPIRED":
      return "paymentFlow.error.modal.footer.expiredStartDate.button";
    default:
      return "paymentFlow.error.modal.footer.button";
  }
};

export default CardPaymentForm;