import React, {FC} from "react";
import {licenceTypeChanged} from "../../redux/PersonalDetailsSlice";
import LicenceTypeQuestion from "../../../shared/questions/licence-type/LicenceTypeQuestion";
import {LicenceTypeOption} from "../../../shared/questions/licence-type/LicenceTypeOption";
import {logYourDetailsGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const ProposerLicenceTypeQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const licenceTypeField = useAppSelector(state => state.personalDetails.licenceType);

  const onChanged = (answer: LicenceTypeOption): void => {
    logYourDetailsGoogleAnalyticsEvent({
      categorySuffix: "type_of_licence",
      action: answer.description.toLowerCase(),
    });
    dispatch(licenceTypeChanged(answer));
  };

  return (
    <LicenceTypeQuestion
      id="proposer-licence-type"
      title="personalDetails.licenceTypeQuestion"
      licenceTypeField={licenceTypeField}
      licenceTypeChanged={onChanged}
    />
  );
};

export default ProposerLicenceTypeQuestion;
