import {FC, useState} from "react";
import "./PolicyExtrasTable.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import PolicyExtrasTableRow from "./PolicyExtrasTableRow";
import {useAppDispatch, useAppSelector} from "../../../../../redux/Hooks";
import {
  selectActiveRenewalNotice,
  selectAvailablePolicyExtras,
  selectPolicyExtras,
  selectRebrokedQuote,
  selectRenewalDepositPercentage,
  selectRenewalQuote
} from "../../redux/RenewalSelectors";
import {PolicyExtraType} from "shared/dist/generated/graphql/resolvers-types";
import {usePolicyQueryParams} from "../../../../../router/params/Params";
import {RenewalPolicyExtras} from "../../models/RenewalPolicyExtras";
import {policyExtrasChanged} from "../../redux/RenewalSlice";
import {loadRenewalProspectiveLoans} from "../../redux/thunks/LoadRenewalProspectiveLoans";
import Button from "shared-components/dist/buttons/button/Button";
import ExcessProtectionOptionModal from "../excess-protection-option-modal/ExcessProtectionOptionModal";
import {
  getProspectivePolicyExtraByType,
  getProspectivePolicyExtraOptionByOptionType
} from "../../../shared/utils/ProspectivePolicyExtraUtils";
import {upsertRebrokeExtras} from "../../redux/thunks/UpsertRebrokeExtras";

const PolicyExtrasTable: FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const {policyId} = usePolicyQueryParams();
  const dispatch = useAppDispatch();
  const depositPercentage = useAppSelector(selectRenewalDepositPercentage);
  const renewalNotice = useAppSelector(selectActiveRenewalNotice);
  const availablePolicyExtras = useAppSelector(selectAvailablePolicyExtras);
  const policyExtras = useAppSelector(selectPolicyExtras);
  const renewalQuote = useAppSelector(selectRenewalQuote);
  const rebrokeQuote = useAppSelector(selectRebrokedQuote);

  const togglePolicyExtra = (extraType: keyof RenewalPolicyExtras): void => {
    const newPolicyExtras: RenewalPolicyExtras = {
      ...policyExtras,
      [extraType]: {...policyExtras[extraType], enabled: !policyExtras[extraType].enabled}
    };
    dispatch(policyExtrasChanged(newPolicyExtras));
    if (renewalQuote) dispatch(loadRenewalProspectiveLoans({
      policyId,
      depositPercentage,
      policyExtras: newPolicyExtras
    }));
    if (rebrokeQuote && rebrokeQuote.reference && rebrokeQuote.sequenceNumber) {
      dispatch(upsertRebrokeExtras({
        quoteReference: rebrokeQuote.reference,
        quoteSequenceNumber: rebrokeQuote.sequenceNumber,
        policyExtras: newPolicyExtras,
        depositPercentage,
      }));
    }
  };

  return (
    <>
      <div className="policy-extras-table" data-testid="policy-extras-table">
        <div className="policy-extras-table__header">
          <div>{lookupI18nString("portal.renewals.summary.extrasTable.header.column1")}</div>
          <div>{lookupI18nString("portal.renewals.summary.extrasTable.header.column2")}</div>
          <div>{lookupI18nString("portal.renewals.summary.extrasTable.header.column3")}</div>
        </div>

        <PolicyExtrasTableRow
          title="portal.renewals.summary.extrasTable.smartfobKeycare.title"
          description="portal.renewals.summary.extrasTable.smartfobKeycare.description"
          documentLink={getProspectivePolicyExtraByType(availablePolicyExtras, PolicyExtraType.SmartfobKeycare)?.document.url}
          thisYearsPrice={getProspectivePolicyExtraByType(availablePolicyExtras, PolicyExtraType.SmartfobKeycare)?.options?.[0]?.price}
          included={policyExtras.smartfobKeycare.enabled}
          toggleIncluded={() => togglePolicyExtra("smartfobKeycare")}
          lastYearsPrice={renewalNotice?.keyCare ?? undefined}
          testId="policy-extras-table__smartfob-keycare"
        />

        <PolicyExtrasTableRow
          title="portal.renewals.summary.extrasTable.roadsideAssistance.title"
          description="portal.renewals.summary.extrasTable.roadsideAssistance.description"
          documentLink={getProspectivePolicyExtraByType(availablePolicyExtras, PolicyExtraType.RoadsideAssistancePlatinum)?.document.url}
          thisYearsPrice={getProspectivePolicyExtraByType(availablePolicyExtras, PolicyExtraType.RoadsideAssistancePlatinum)?.options?.[0]?.price}
          included={policyExtras.roadsideAssistancePlatinum.enabled}
          toggleIncluded={() => togglePolicyExtra("roadsideAssistancePlatinum")}
          lastYearsPrice={renewalNotice?.breakdownCover ?? undefined}
          testId="policy-extras-table__roadside-assistance"
        />

        <PolicyExtrasTableRow
          title="portal.renewals.summary.extrasTable.courtesyCar.title"
          description="portal.renewals.summary.extrasTable.courtesyCar.description"
          documentLink={getProspectivePolicyExtraByType(availablePolicyExtras, PolicyExtraType.CourtesyCar)?.document.url}
          thisYearsPrice={getProspectivePolicyExtraByType(availablePolicyExtras, PolicyExtraType.CourtesyCar)?.options?.[0]?.price}
          included={policyExtras.courtesyCar.enabled}
          toggleIncluded={() => togglePolicyExtra("courtesyCar")}
          lastYearsPrice={renewalNotice?.replacementCar ?? undefined}
          testId="policy-extras-table__courtesy-car"
        />

        <PolicyExtrasTableRow
          title="portal.renewals.summary.extrasTable.legalExpenses.title"
          description="portal.renewals.summary.extrasTable.legalExpenses.description"
          documentLink={getProspectivePolicyExtraByType(availablePolicyExtras, PolicyExtraType.LegalExpenses)?.document.url}
          thisYearsPrice={getProspectivePolicyExtraByType(availablePolicyExtras, PolicyExtraType.LegalExpenses)?.options?.[0]?.price}
          included={policyExtras.legalExpenses.enabled}
          toggleIncluded={() => togglePolicyExtra("legalExpenses")}
          lastYearsPrice={renewalNotice?.legalExpenses ?? undefined}
          testId="policy-extras-table__legal-expenses"
        />

        <PolicyExtrasTableRow
          title="portal.renewals.summary.extrasTable.excessInsurance.title"
          description="portal.renewals.summary.extrasTable.excessInsurance.description"
          documentLink={getProspectivePolicyExtraByType(availablePolicyExtras, PolicyExtraType.ExcessInsurance)?.document.url}
          thisYearsPrice={getProspectivePolicyExtraOptionByOptionType(availablePolicyExtras, PolicyExtraType.ExcessInsurance, policyExtras.excessInsurance.type)?.price}
          included={policyExtras.excessInsurance.enabled}
          toggleIncluded={() => togglePolicyExtra("excessInsurance")}
          lastYearsPrice={renewalNotice?.excessInsurance ?? undefined}
          priceCellChildren={
            <Button
              className="policy-extras-table__cell__link-button"
              variant="link"
              onClick={() => setIsVisible(true)}
            >
              {getProspectivePolicyExtraOptionByOptionType(availablePolicyExtras, PolicyExtraType.ExcessInsurance, policyExtras.excessInsurance.type)?.description} -
              Edit
            </Button>
          }
          testId="policy-extras-table__excess-insurance"
        />
      </div>

      <ExcessProtectionOptionModal isVisible={isVisible} setIsVisible={setIsVisible}/>
    </>
  );
};

export default PolicyExtrasTable;