import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "shared-components/dist/styles/app.css";
import App from "./app/components/App";
import {RawIntlProvider} from "react-intl";
import {getIntl} from "shared-components/src/translations/IntlProvider";
import {initializeGoogleAnalytics} from "shared-components/dist/analytics/GoogleAnalytics";
import {initializeZendesk} from "./zendesk/Zendesk";
import {StrollWindow, ZendeskWindowControl} from "./providers/Window";
import {initialiseLaunchDarklyProvider} from "shared-components/dist/feature-flags/provider/LaunchDarkly";
import {initializeHotjar} from "./hotjar/Hotjar";

declare global {
  interface Window {
    Stroll: StrollWindow;
    zE: ZendeskWindowControl;
  }
}

initializeGoogleAnalytics(window.Stroll.IS_PRODUCTION ? "G-ZB77BBZXP2" : "G-KYFCH7Y6CT");
if (window.Stroll.IS_PRODUCTION) initializeHotjar();
initializeZendesk();

if (!("USER_POOL_ID" in window) || !("CLIENT_ID" in window)) {
  throw new Error("Cognito configuration missing - be sure to run 'yarn inject-cognito-configuration'");
}

(async () => {
    const LaunchDarklyProvider = await initialiseLaunchDarklyProvider("STROLL_ANONYMOUS_USER");

    ReactDOM.render(
      <React.StrictMode>
        <RawIntlProvider value={getIntl()}>
          <LaunchDarklyProvider>
            <App/>
          </LaunchDarklyProvider>
        </RawIntlProvider>
      </React.StrictMode>,
      document.getElementById("root")
    );
  }
)();
