import {ProposedClassOfUse} from "shared/dist/generated/graphql/resolvers-types";
import {employmentStatusAllowsSoliciting} from "../../../shared/validation/ValidateEmploymentStatus";
import {conditionalFieldReset} from "shared-components/dist/models/form-field/FormField";
import {PersonalDetails} from "../PersonalDetails";

export function updateSolicitingQuestionStatus(classOfUse: ProposedClassOfUse | undefined, state: PersonalDetails): void {
  const hasBusinessUse = (classOfUse === ProposedClassOfUse.SocialCommutingAndBusiness);

  if (employmentStatusAllowsSoliciting(state.primaryEmployment.employmentStatus.value, state.secondaryEmployment.employmentStatus.value) && hasBusinessUse) {
    state.soliciting.isActive = true;
  } else {
    conditionalFieldReset(state.soliciting);
  }
}