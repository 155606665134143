import React, {FC} from "react";
import "./ContactDetails.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import ContactDetailConfirmationListItem from "./components/confirmation/list-item/ContactDetailConfirmationListItem";
import {
  hasContactDetailsBeenConfirmedChanged,
  hasContactDetailsBeenConfirmedReset
} from "../../../redux/QuoteDetailsSlice";
import ContactDetailsConfirmationQuestion from "./components/confirmation/question/ContactDetailsConfirmationQuestion";
import {ContactDetailsForm} from "./components/form/ContactDetailsForm";
import {phoneNumberChanged, proposerEmailAddressChanged} from "../../../../your-details/redux/PersonalDetailsSlice";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const ContactDetails: FC = () => {
  const dispatch = useAppDispatch();
  const proposerEmailAddress = useAppSelector(state => state.personalDetails.proposerEmailAddress);
  const proposerPhoneNumber = useAppSelector(state => state.personalDetails.phoneNumber);
  const hasContactDetailsBeenConfirmed = useAppSelector(state => state.quoteDetails.hasContactDetailsBeenConfirmed);

  const onSaveChanges = (newEmail?: string, newPhoneNumber?: string): void => {
    if (!newEmail || !newPhoneNumber) return;

    dispatch(proposerEmailAddressChanged(newEmail));
    dispatch(phoneNumberChanged(newPhoneNumber));
  };

  return (
    <div className="contact-details">
      <div className="contact-details__description">
        <h4>{lookupI18nString("quoteDetails.contactDetails.title")}</h4>
        <p>{lookupI18nString("quoteDetails.contactDetails.description")}</p>
      </div>

      <div className="contact-details__confirmation">
        <h5>{lookupI18nString("quoteDetails.contactDetails.confirmation.title")}</h5>

        <ul className="contact-details__confirmation__details-list">
          <ContactDetailConfirmationListItem
            label="quoteDetails.contactDetails.confirmation.email.label"
            formField={proposerEmailAddress}
          />
          <ContactDetailConfirmationListItem
            label="quoteDetails.contactDetails.confirmation.phoneNumber.label"
            formField={proposerPhoneNumber}
          />
        </ul>

        <ContactDetailsConfirmationQuestion
          {...hasContactDetailsBeenConfirmed}
          onChange={value => dispatch(hasContactDetailsBeenConfirmedChanged(value))}
        />
      </div>

      <ContactDetailsForm
        proposerPhoneNumber={proposerPhoneNumber}
        proposerEmailAddress={proposerEmailAddress}
        onSave={onSaveChanges}
        show={hasContactDetailsBeenConfirmed.value === false}
        onClose={() => dispatch(hasContactDetailsBeenConfirmedReset())}
      />
    </div>
  );
};

export default ContactDetails;
