import React, {FC} from "react";
import PaymentStep from "../../shared/payment-step/PaymentStep";
import Button from "shared-components/dist/buttons/button/Button";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import GooglePay from "../../shared/google-pay/GooglePay";
import {FullPolicyPaymentResult, Price} from "shared/dist/generated/graphql/resolvers-types";
import PaymentFailedModal from "../../shared/payment-failed-modal/PaymentFailedModal";
import PaymentInProgress from "../../shared/in-progress/PaymentInProgress";
import ApplePay from "../../shared/apple-pay/ApplePay";
import {isSafari} from "react-device-detect";

interface Props {
  loading: boolean;
  successPagePath: string;
  totalUnfinancedAmountPayable: Price | undefined;
  onPaymentByCard: () => void;
  onPaymentWithApplePay: (payment: ApplePayJS.ApplePayPayment) => Promise<FullPolicyPaymentResult>;
  onPaymentWithGooglePay: (paymentData: google.payments.api.PaymentData) => void;
  paymentFailed: boolean;
  resetPaymentFailed: () => void;
}

const AnnualPaymentLanding: FC<Props> = ({
  loading,
  successPagePath,
  totalUnfinancedAmountPayable,
  onPaymentByCard,
  onPaymentWithApplePay,
  onPaymentWithGooglePay,
  paymentFailed,
  resetPaymentFailed
}) => (
  <>
    {loading
      ? <PaymentInProgress/>
      : <PaymentStep
        title="paymentFlow.annual.landing.mobileTitle"
        description="paymentFlow.annual.landing.description"
        sidebarTitle={lookupI18nString("paymentFlow.annual.landing.sidebar.title")}
        sidebarContent={lookupI18nString("paymentFlow.annual.landing.sidebar.description")}
        footerContent={
          <>
            <Button
              expanded
              onClick={onPaymentByCard}
            >
              {lookupI18nString("paymentFlow.annual.landing.payByCard")}
            </Button>
            {(isSafari && totalUnfinancedAmountPayable) &&
              <ApplePay
                successPagePath={successPagePath}
                amountPayable={totalUnfinancedAmountPayable}
                makePayment={onPaymentWithApplePay}
              />
            }
            {totalUnfinancedAmountPayable &&
              <GooglePay
                amountPayable={totalUnfinancedAmountPayable}
                makePayment={onPaymentWithGooglePay}
              />
            }
          </>
        }
      />
    }
    <PaymentFailedModal
      visible={paymentFailed}
      onDismiss={resetPaymentFailed}
      message="paymentFlow.error.modal.footer.description.contact"
    />
  </>
);

export default AnnualPaymentLanding;
