import React, {FC} from "react";
import BooleanQuestion from "../../../../../../structure/questions/question/variants/boolean-question/BooleanQuestion";
import {isCarKeptAtHomeChanged} from "../../redux/PersonalDetailsSlice";
import CarNotKeptAtHomeStop from "./CarNotKeptAtHomeStop";
import {logYourDetailsGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const CarKeptAtHomeQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const isCarKeptAtHome = useAppSelector(state => state.personalDetails.isCarKeptAtHome);

  const onChanged = (answer: boolean): void => {
    logYourDetailsGoogleAnalyticsEvent({
      categorySuffix: "kept_at_address",
      action: answer,
    });
    dispatch(isCarKeptAtHomeChanged(answer));
  };

  return (
    <>
      <BooleanQuestion
        id="car-kept-at-home-question"
        title="personalDetails.isCarKeptAtHomeQuestion.title"
        formField={isCarKeptAtHome}
        onAnswer={onChanged}
        tooltip={{description: "personalDetails.isCarKeptAtHomeQuestion.tooltip.description"}}
      />
      <CarNotKeptAtHomeStop/>
    </>
  );
};

export default CarKeptAtHomeQuestion;
