import React, {FC} from "react";
import {hasNonMotoringConvictionsChanged} from "../../redux/UpdateAdditionalDriverSlice";
import NonMotoringConvictionsQuestion
  from "../../../../shared/questions/non-motoring-convictions/NonMotoringConvictionsQuestion";
import {nonMotoringConvictionsStopVisibleSelector} from "../../redux/selectors/NonMotoringConvictionsStopVisibleSelector";
import {logAdditionalDriverGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const AdditionalDriverNonMotoringConvictionsQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const nonMotoringConvictionsField = useAppSelector(state => state.updateAdditionalDriver.hasNonMotoringConvictions);
  const isStopVisible = useAppSelector(nonMotoringConvictionsStopVisibleSelector);

  const onHasNonMotoringConvictionsAnswered = (answer: boolean): void => {
    logAdditionalDriverGoogleAnalyticsEvent({
      categorySuffix: "non_motoring_convictions",
      action: answer
    });

    dispatch(hasNonMotoringConvictionsChanged(answer));
  };

  return (
    <NonMotoringConvictionsQuestion
      id="additional-driver-non-motoring-convictions-question"
      title="additionalDriver.nonMotoringConvictionsQuestion"
      nonMotoringConvictionsField={nonMotoringConvictionsField}
      onComplete={onHasNonMotoringConvictionsAnswered}
      isStopVisible={isStopVisible}
    />
  );
};

export default AdditionalDriverNonMotoringConvictionsQuestion;