import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../../../../redux/Hooks";
import {yourVehicleFormValid} from "../../your-vehicle/redux/selectors/ValidatedVehicleSelectors";
import {personalDetailsValidSelector} from "../../your-details/redux/selectors/PersonalDetailsSelectors";
import {
  coverDetailsValidSelector, coverStartDateAsDateOptionSelector,
  existingPolicyExpiryDateAsDateSelector, vehiclePreviousPolicyExpiryDateAsDateSelector
} from "../../your-cover/redux/selectors/CoverDetailsSelectors";
import {vehiclePurchaseDateAsDateSelector} from "../../your-vehicle/redux/selectors/VehicleDateTypeSelectors";
import {detectBreakInCover} from "../../shared/services/BreakInCoverDetector";
import {quoteSteps} from "../../shared/quote-step/QuoteSteps";
import {isAggregatorQuoteSelector} from "../redux/selectors/AggregatorQuoteSelectors";
import {useFeatureFlags} from "shared-components/dist/feature-flags/hooks/UseFeatureFlags";
import {useEffectOnFirstRender} from "shared-components/dist/utils/hooks/UseEffectOnFirstRender";
import {useState} from "react";

export function useValidateWholeQuoteOnLoad(): boolean {
  const navigate = useNavigate();
  const isYourVehicleFormValid = useAppSelector(yourVehicleFormValid);
  const arePersonalDetailsValid = useAppSelector(personalDetailsValidSelector);
  const areCoverDetailsValid = useAppSelector(coverDetailsValidSelector);
  const hasAcceptedBreakInCoverDeclaration = useAppSelector(state => state.coverDetails.breakInCoverDeclarationAcceptance.value);
  const carPurchaseDate = useAppSelector(vehiclePurchaseDateAsDateSelector);
  const previousCoverExpiryDate = useAppSelector(existingPolicyExpiryDateAsDateSelector);
  const coverStartDate = useAppSelector(coverStartDateAsDateOptionSelector);
  const hasPreviouslyBeenInsured = useAppSelector(state => state.coverDetails.previouslyHeldInsurance.value);
  const vehiclePreviousPolicyExpiryDate = useAppSelector(vehiclePreviousPolicyExpiryDateAsDateSelector);
  const isAggregatorQuote = useAppSelector(isAggregatorQuoteSelector);
  const {compareNiPhase2Flag} = useFeatureFlags();
  const [isValidating, setIsValidating] = useState(true);

  const hasBreakInCover = detectBreakInCover({
    carPurchaseDate: carPurchaseDate.value,
    previousCoverExpiryDate: previousCoverExpiryDate.value,
    coverStartDate: coverStartDate?.value?.date,
    hasPreviouslyBeenInsured,
    vehiclePreviousPolicyExpiryDate: vehiclePreviousPolicyExpiryDate.value
  });

  const navigateToYourCover = (): void => navigate(quoteSteps.yourCover.routes.new);

  const validateQuoteFlowPages = (): void => {
    if (!isYourVehicleFormValid) navigate(quoteSteps.yourCar.routes.new);
    if (!arePersonalDetailsValid) navigate(quoteSteps.yourDetails.routes.new);
    if (!areCoverDetailsValid) navigate(quoteSteps.yourCover.routes.new);
  };

  const validateBreakInCover = (): void => {
    if (hasBreakInCover === "CANNOT_INSURE_ONLINE") navigateToYourCover();
    if (hasBreakInCover === "DECLARATION_REQUIRED" && !hasAcceptedBreakInCoverDeclaration) navigateToYourCover();
  };

  useEffectOnFirstRender(() => {
    validateQuoteFlowPages();

    if (!isAggregatorQuote && !compareNiPhase2Flag) {
      validateBreakInCover();
    }

    setIsValidating(false);
  });

  return isValidating;
}