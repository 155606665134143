import React, {FC, useEffect} from "react";
import SubQuestion, {SubQuestionProps} from "../../SubQuestion";
import RadioColumn, {RadioColumnProps} from "shared-components/dist/form/radio-column/RadioColumn";

interface Props<T> {
  subQuestionProps: SubQuestionProps;
  radioColumnProps: RadioColumnProps<T>;
}

export type RadioColumnSubQuestionWithGenericProps<T = unknown> = FC<Props<T>>;

const RadioColumnSubQuestion: RadioColumnSubQuestionWithGenericProps = (
  {
    subQuestionProps,
    radioColumnProps
  }
) => {
  useEffect(() => {
    if (radioColumnProps.options?.length === 1 && !radioColumnProps.selectedOption) {
      radioColumnProps.onSelection(radioColumnProps.options[0]);
    }
  }, [radioColumnProps]);

  return (
    <SubQuestion {...subQuestionProps}>
      <RadioColumn {...radioColumnProps}/>
    </SubQuestion>
  );
};

export default RadioColumnSubQuestion;