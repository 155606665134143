import React, {FC} from "react";
import {Validator} from "shared-components/dist/utils/validation/Validator";
import SubQuestion from "../../SubQuestion";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {Status} from "shared-components/dist/status-indicator/Status";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import Datepicker from "shared-components/dist/form/datepicker/Datepicker";

interface Props {
  id: string;
  title: TranslationKey;
  value: Date | undefined;
  status?: Status;
  errorMessage?: TranslationKey;
  onComplete: (date: Date) => void;
  onInvalid: (errorMessage: TranslationKey) => void;
  onClear: () => void;
  validators?: Validator<Date>[];
  tooltip?: TooltipProps;
  minDate?: Date;
  maxDate?: Date;
}

const DateSubQuestion: FC<Props> = (
  {
    id,
    title,
    value,
    status,
    errorMessage,
    onComplete,
    onInvalid,
    onClear,
    validators,
    tooltip,
    minDate,
    maxDate
  }
) => (
  <SubQuestion
    id={id}
    title={title}
    errorMessage={errorMessage}
    status={status}
    tooltip={tooltip}
  >
    <Datepicker
      name={id}
      value={value}
      onComplete={onComplete}
      onInvalid={onInvalid}
      onClear={onClear}
      validators={validators}
      minDate={minDate}
      maxDate={maxDate}
    />
  </SubQuestion>
);

export default DateSubQuestion;
