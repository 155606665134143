import React, {FC} from "react";
import CurrentAdditionalDrivers from "./CurrentAdditionalDrivers";
import {usePolicyQueryParams} from "../../../../../router/params/Params";
import PolicyErrorPage from "../../../policy-error/PolicyErrorPage";
import PoliciesHeader from "../../header/PoliciesHeader";
import PoliciesSidebar from "../../sidebar/PoliciesSidebar";
import {policyViewSidebarMenuItems} from "../../../../../structure/navigation/navbar/menu/MenuItems";
import CustomerPortalContainerWithSidebar from "../../../shared/container/CustomerPortalContainerWithSidebar";
import {fetchPolicyFor} from "shared/dist/graphql/queries/portal/PortalDashboardQuery";
import PolicyLoading from "../../loading/PolicyLoading";
import {endDateIsWithinRenewalDueRange} from "../../../../../utils/validation/RenewalDueValidator";

const PolicyViewDrivers: FC = () => {
  const {policyId} = usePolicyQueryParams();
  const {loading, error, data} = fetchPolicyFor(policyId);

  if (loading) return <PolicyLoading/>;

  if (error || !data || !data.policy) return <PolicyErrorPage/>;

  const hasRenewalsTab = endDateIsWithinRenewalDueRange(data.policy.metadata.coverEndDate);

  return (
    <CustomerPortalContainerWithSidebar
      header={
        <PoliciesHeader
          policy={data.policy}
          pageSubHeader="policies.header.subHeader.coverSummary"/>
      }
      mainContent={<CurrentAdditionalDrivers policy={data.policy}/>}
      sidebar={<PoliciesSidebar menuItems={policyViewSidebarMenuItems(policyId, hasRenewalsTab)}/>}
    />
  );
};

export default PolicyViewDrivers;