import {useEffect} from "react";
import {selectedQuoteChanged} from "../../../../redux/QuoteDetailsSlice";
import {quoteSteps} from "../../../../../shared/quote-step/QuoteSteps";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../../../../../redux/Hooks";

export function useHandleSingleQuoteAvailable(): void {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const insurerQuotes = useAppSelector(state => state.quoteDetails.insurerQuotes);

  useEffect(() =>{
    if (insurerQuotes?.length === 1) {
      dispatch(selectedQuoteChanged(insurerQuotes[0]));
      navigate(quoteSteps.yourQuote.routes.new);
    }
  }, [navigate, insurerQuotes, dispatch]);
}