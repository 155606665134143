import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";

export const ALLOY_WHEELS_OPTIONS = [
  {
    id: "less-than-2-inches",
    description: lookupI18nString("vehicleQuote.modifications.alloyWheelsQuestion.options.lessThan2Inches")
  },
  {
    id: "greater-than-2-inches",
    description: lookupI18nString("vehicleQuote.modifications.alloyWheelsQuestion.options.moreThan2Inches")
  }
] as const;

export type AlloyWheelsOption = typeof ALLOY_WHEELS_OPTIONS[number];

export const ALLOY_WHEELS_DESCRIPTIONS = ALLOY_WHEELS_OPTIONS.map(alloys => alloys.description);
