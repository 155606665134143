import React, {FC} from "react";
import {BooleanFormField} from "shared-components/dist/models/form-field/variants/BooleanFormField";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import Question from "../../Question";
import RadioConfirmation from "shared-components/dist/form/radio-confirmation/RadioConfirmation";

interface Props {
  id: string;
  title: TranslationKey;
  description?: TranslationKey;
  formField: BooleanFormField;
  onAnswer: (value: boolean) => void;
  tooltip?: TooltipProps;
  hideSpacer?: boolean;
}

const BooleanQuestion: FC<Props> = ({
  id,
  title,
  description,
  formField,
  onAnswer,
  tooltip,
  hideSpacer,
  children
}) => {
  return (
    <Question
      id={id}
      title={title}
      description={description}
      status={formField.status}
      errorMessage={formField.errorMessage}
      tooltip={tooltip}
      hideSpacer={hideSpacer}
    >
      <>
        <RadioConfirmation
          name={id}
          selectedGroupOption={formField.value}
          setSelectedGroupOption={onAnswer}
        />
        {children}
      </>
    </Question>
  );
};

export default BooleanQuestion;