import {generateZendeskChatToken} from "../graphql/queries/zendesk/GenerateZendeskChatToken";
import {logger} from "../utils/logging/Logger";
import {getCurrentAvailableSession} from "../user/authentication/session/SessionManager";

export function signOutZendeskChat(): void {
  try {
    window.zE("webWidget", "logout");
  } catch (error) {
    logger.error("Unable to logout zendesk chat", error);
  }
}

export async function initializeZendesk(): Promise<void> {
  try {
    await authenticateUserThatHasCurrentSession();
  } catch (error) {
    logger.error("Unable to configure zendesk chat", error);
  }
}

async function authenticateUserThatHasCurrentSession(): Promise<void> {
  try {
    const session = await getCurrentAvailableSession();

    if (!session) return;

    authenticateUserWithZendesk();
  } catch {
    logger.debug("User not logged in. Leaving Zendesk chat unauthenticated for now.");
  }
}

function authenticateUserWithZendesk(): void {
  window.zE("webWidget", "updateSettings", {
    webWidget: {
      authenticate: {
        chat: {
          jwtFn: (callback: (token: string | undefined) => void) => {
            generateZendeskChatToken().then(callback).catch(error => {
              logger.error("Unable able to generate zendesk chat token", error);
              callback("invalid-token");
            });
          }
        }
      }
    }
  });
}

export function reauthenticateZendeskChat(): void {
  try {
    window.zE("webWidget", "chat:reauthenticate");
  } catch (error) {
    logger.error("Unable to reauthenticate zendesk chat", error);
  }
}

export function openZendeskChat(): void {
  window.zE("webWidget", "open");
}