import {ConvictionOffenceItem, ConvictionTypeItem} from "shared/dist/generated/graphql/resolvers-types";
import {AlcoholTestMethodOption} from "./AlcoholTestMethods";

interface SharedConvictionsProps {
  hasConvictions: boolean | undefined;
  shouldFlagUnansweredQuestions: boolean;
  isFormVisible: boolean;
}

interface SharedConvictionProps {
  type: ConvictionTypeItem;
  offence: ConvictionOffenceItem;
  penaltyPoints: number;
  fine: number;
  drivingBan: Duration;
  testMethod?: AlcoholTestMethodOption;
  alcoholLevel?: number;
}

export interface Convictions extends SharedConvictionsProps {
  convictions: Conviction[];
}

export interface Conviction extends SharedConvictionProps {
  date: Date;
}

export interface SerializableConvictions extends SharedConvictionsProps {
  convictions: SerializableConviction[];
}

export interface SerializableConviction extends SharedConvictionProps {
  date: string;
}

export const toConviction = (serializableConviction: SerializableConviction): Conviction => {
  return {
    ...serializableConviction,
    date: new Date(serializableConviction.date)
  };
};

export const toSerializableConviction = (conviction: Conviction): SerializableConviction => {
  return {
    ...conviction,
    date: conviction.date.toISOString()
  };
};