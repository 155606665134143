import React, {FC, useState} from "react";
import PasswordInput from "shared-components/dist/form/password-input-field/PasswordInput";
import Button from "shared-components/dist/buttons//button/Button";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./EditPassword.css";
import Spacer from "shared-components/dist/spacer/Spacer";

export type SaveButtonState = "Default" | "Saving" | "RequestComplete"

interface Props {
  onCurrentPasswordEntered: (value: string) => void;
  onNewPasswordEntered: (value: string) => void;
  onConfirmPasswordEntered: (value: string) => void;
  isSaveButtonEnabled: boolean;
  saveButtonState: SaveButtonState;
}

const EditPassword: FC<Props> = (
  {
    onCurrentPasswordEntered,
    onNewPasswordEntered,
    onConfirmPasswordEntered,
    isSaveButtonEnabled,
    saveButtonState
  }
) => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");
  const [currentPassword, setCurrentPassword] = useState<string>("");

  const onCurrentPasswordChange = (value: string): void => {
    setCurrentPassword(value);
    onCurrentPasswordEntered?.(value);
  };

  const onNewPasswordChange = (value: string): void => {
    setNewPassword(value);
    onNewPasswordEntered?.(value);
  };

  const onConfirmPasswordChange = (value: string): void => {
    setConfirmNewPassword(value);
    onConfirmPasswordEntered?.(value);
  };

  return (
    <>
      <PasswordInput
        name="current-password"
        value={currentPassword}
        onComplete={onCurrentPasswordChange}
        onChange={onCurrentPasswordChange}
        placeholder="accountSettings.form.currentPassword"
        autoCompleteType="current-password"
      />
      <Spacer/>

      <div className="edit-password">
        <PasswordInput
          name="new-password"
          value={newPassword}
          onComplete={onNewPasswordChange}
          onChange={onNewPasswordChange}
          placeholder="accountSettings.form.newPassword"
          autoCompleteType="new-password"
        />
        <div className={"confirm-password-container"}>
          <div className={"confirm-password-container__input"}>
            <PasswordInput
              name="confirm-new-password"
              value={confirmNewPassword}
              onComplete={onConfirmPasswordChange}
              onChange={onConfirmPasswordChange}
              placeholder={"accountSettings.form.confirmPassword"}
              autoCompleteType="confirm-new-password"
            />
          </div>
          <Button
            disabled={!isSaveButtonEnabled}
            type="submit"
            expanded
            loading={saveButtonState === "Saving"}
          >
            {lookupI18nString("accountSettings.form.save")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default EditPassword;
