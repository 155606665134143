import {
  sendEcommerceAnalyticsPurchaseEvent
} from "shared-components/dist/analytics/GoogleAnalyticsEcommerceEventsService";
import {useEffectOnFirstRender} from "shared-components/dist/utils/hooks/UseEffectOnFirstRender";
import {useBuildEcommerceItemsList} from "../helpers/UseBuildEcommerceItemsList";
import {useAppSelector} from "../../../redux/Hooks";
import {paymentTransactionIdSelector} from "../../../pages/payment/shared/redux/PersonalDetailsSelector";
import {PaymentFlowType} from "../../../pages/payment/shared/models/PaymentFlowType";
import {selectPolicyPaymentAmount} from "../../../redux/selectors/PolicyPaymentSelectors";

export function useEcommerceAddPurchase(paymentFlowType: PaymentFlowType): void {
  const items = useBuildEcommerceItemsList();
  const transactionId = useAppSelector(paymentTransactionIdSelector(paymentFlowType));
  const amountPaid = useAppSelector(selectPolicyPaymentAmount);

  useEffectOnFirstRender(() => {
    if (items === undefined) return;

    sendEcommerceAnalyticsPurchaseEvent({
      transaction_id: transactionId ?? new Date().toISOString(),
      items,
      value: Number(amountPaid.amount),
      currency: amountPaid.currency
    });
  });
}
