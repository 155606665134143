import React, {FC, ReactChild} from "react";
import Navbar from "../../../../structure/navigation/navbar/Navbar";
import RemindersContainer from "../reminders/RemindersContainer";
import CustomerPortalContentLayout
  from "../../../../structure/layouts/customer-portal-content-layout/CustomerPortalContentLayout";
import CustomerPortalContainer from "./CustomerPortalContainer";

interface Props {
  header: ReactChild;
  mainContent: ReactChild;
  className?: string;
}

const CustomerPortalContainerWithoutSidebar: FC<Props> = ({
  header,
  mainContent,
  className
}) => (
  <CustomerPortalContainer>
    <Navbar/>
    <RemindersContainer/>
    <CustomerPortalContentLayout
      header={header}
      mainContent={mainContent}
      className={className}/>
  </CustomerPortalContainer>
);

export default CustomerPortalContainerWithoutSidebar;
