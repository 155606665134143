import {FC} from "react";
import {logYourCoverGoogleAnalyticsEvent} from "../../../../../../../../utils/analytics/MotorAnalytics";
import DateQuestion from "../../../../../../../../structure/questions/question/variants/date-question/DateQuestion";
import {companyCarPolicyExpiryDateAsDateSelector} from "../../../../redux/selectors/CoverDetailsSelectors";
import {
  companyCarPolicyExpiryDateChanged,
  companyCarPolicyExpiryDateInvalid,
  companyCarPolicyExpiryDateReset
} from "../../../../redux/CoverDetailsSlice";
import {useAppDispatch, useAppSelector} from "../../../../../../../../redux/Hooks";

const CompanyCarPolicyExpiryDateQuestion: FC = () => {
  const companyCarPolicyExpiryDate = useAppSelector(state => companyCarPolicyExpiryDateAsDateSelector(state));
  const dispatch = useAppDispatch();

  const onExpiryDateChanged = (expiryDate: Date): void => {
    const dateString = expiryDate.toISOString();

    logYourCoverGoogleAnalyticsEvent({
      categorySuffix: "company_policy_expiry",
      action: "Click",
    });

    dispatch(companyCarPolicyExpiryDateChanged(dateString));
  };

  return (
    <DateQuestion
      id="company-car-policy-expiry-date-question"
      title="coverDetails.companyCarPolicyExpiryDateQuestion.title"
      formField={companyCarPolicyExpiryDate}
      onComplete={onExpiryDateChanged}
      onInvalid={(errorMessage) => dispatch(companyCarPolicyExpiryDateInvalid(errorMessage))}
      onClear={() => dispatch(companyCarPolicyExpiryDateReset())}
      hideSpacer
      tooltip={{
        description: "coverDetails.companyCarPolicyExpiryDateQuestion.tooltip.description"
      }}
    />
  );
};

export default CompanyCarPolicyExpiryDateQuestion;