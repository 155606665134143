import {Location} from "history";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {QUOTE} from "../../../../../../router/models/Routes";

export function showPromptOnExitWhenLeavingQuoteFlow(location: Location<unknown>): string | undefined {
  return !isDestinationPathWithinOnGoingQuoteFlow(location.pathname)
    ? lookupI18nString("vehicleQuote.promptOnExit")
    : undefined;
}

const isDestinationPathWithinOnGoingQuoteFlow = (fullPath: string): boolean => {
  const spiltPath = fullPath.split("/");
  const initialPath = spiltPath[1] ?? "";

  return `/${initialPath}`.startsWith(QUOTE);
};
