import {
  emitGoogleTagManagerEvent,
  QUOTE_CONVERSION_DESTINATION,
  PURCHASE_CONVERSION_DESTINATION,
  emitGoogleTagManagerPurchaseEvent,
} from "./GoogleTagManagerService";

interface TagManagerEmitEventParams {
  emailAddress?: string;
  phoneNumber?: string;
}

interface PaymentTagManagerEmitEventParams extends TagManagerEmitEventParams {
  transactionId: string;
  value: string;
  currency: string;
}

export function emitQuotePageEvent({emailAddress, phoneNumber}: TagManagerEmitEventParams): void {
  if (emailAddress && phoneNumber) {
    emitGoogleTagManagerEvent({
      sendTo: QUOTE_CONVERSION_DESTINATION,
      emailAddress,
      phoneNumber
    });
  }
}

export function emitPaymentSuccessPageEvent({emailAddress, phoneNumber, transactionId, value, currency}: PaymentTagManagerEmitEventParams): void {
  if (emailAddress && phoneNumber) {
    emitGoogleTagManagerPurchaseEvent({
      sendTo: PURCHASE_CONVERSION_DESTINATION,
      emailAddress,
      phoneNumber,
      transactionId,
      value,
      currency
    });
  }
}