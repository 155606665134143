import {FC, ReactElement} from "react";
import "./InsurerQuoteCardBody.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import Button from "shared-components/dist/buttons/button/Button";
import {InsurerQuote} from "../../../../pages/quote/vehicle/your-quote/models/InsurerQuote";
import {displayLoanValue} from "../../../../utils/service/string/DisplayLoanValue";
import {LoadingStatus} from "../../../../pages/quote/vehicle/your-quote/models/LoadingStatus";
import InsurerQuoteCardRow from "./InsurerQuoteCardRow";
import {CurrencyFormat} from "../../../../utils/money/CurrencyFormat";
import {Price} from "shared/dist/generated/graphql/resolvers-types";

interface Props {
  isMonthly?: boolean;
  cost: Price;
  quote: InsurerQuote;
  index: number;
  depositPercentage: number;
  closeBrothersLoanStatus: LoadingStatus;
  extrasTotal?: Price;
  onButtonClick: (selectedQuote: InsurerQuote) => void;
  toolTip: ReactElement;
  numberOfInstalments: number | undefined;
  adminFee: Price | undefined;
}

const InsurerQuoteCardBody: FC<Props> = (
  {
    isMonthly,
    cost,
    quote,
    depositPercentage,
    closeBrothersLoanStatus,
    index,
    extrasTotal,
    onButtonClick,
    toolTip,
    numberOfInstalments,
    adminFee
  }) => {
  const costOfQuote = CurrencyFormat.formatPriceWithSymbol(cost).split(".");
  const adminFeeCost = (adminFee?.amount === "0.00") ? "Free" : CurrencyFormat.formatPriceWithSymbol(adminFee);
  const selectedLoan = quote.selectedLoanWithPrice;
  const totalAmountPayable = quote.selectedLoanWithPrice?.totalAmountPayable;
  const firstInstalmentAmount = quote.selectedLoanWithPrice?.firstInstalmentAmount;
  const premium = quote.premiumPrice;

  return (
    <div className="insurer-quote-card-body">
      <div className="insurer-quote-card-body__main">
        <span id={`cost-${index}`} className="insurer-quote-card-body__main__price">
          {costOfQuote[0]}
          <span className="insurer-quote-card-body__main__price__detail">.{costOfQuote[1]}</span>
          {isMonthly &&
            <span
              className="insurer-quote-card-body__main__price__detail">{lookupI18nString("insurerQuoteCard.costPerMonth")}</span>
          }
        </span>
        {toolTip}
      </div>
      {isMonthly && numberOfInstalments &&
        <p id={`instalments-${index}`}>{lookupI18nString({
          id: "insurerQuoteCard.numberOfInstalments",
          templateVariables: {numberOfInstalments}
        })}
        </p>}
      <div className="insurer-quote-card-body__wrapper">
        <InsurerQuoteCardRow
          id={`insurer-name-${index}`}
          description={lookupI18nString("insurerQuoteCard.body.quoteWith")}
          value={quote.insurer}
          className="highlighted"
        />

        <InsurerQuoteCardRow
          id={`insurance-premium-${index}`}
          description={lookupI18nString("insurerQuoteCard.body.insurancePremium")}
          value={CurrencyFormat.formatPriceWithSymbol(premium)}
        />

        {extrasTotal &&
          <InsurerQuoteCardRow
            id={`extras-${index}`}
            description={lookupI18nString("insurerQuoteCard.body.Extras")}
            value={CurrencyFormat.formatPriceWithSymbol(extrasTotal)}
          />
        }

        <InsurerQuoteCardRow
          id={`admin-fee-${index}`}
          description={lookupI18nString("insurerQuoteCard.body.adminFee")}
          value={adminFeeCost}
        />

        {(isMonthly && selectedLoan && firstInstalmentAmount && totalAmountPayable) &&
          <>
            <InsurerQuoteCardRow
              id={`loan-deposit-${index}`}
              description={lookupI18nString("insurerQuoteCard.body.deposit")}
              value={`${displayLoanValue(firstInstalmentAmount, closeBrothersLoanStatus)} (${depositPercentage}%)`}
            />

            <InsurerQuoteCardRow
              id={`total-payable-${index}`}
              description={lookupI18nString({
                id: "quoteDetails.priceCard.totalPayableAmount",
                templateVariables: {
                  aprPercentage: selectedLoan.apr
                }
              })}
              value={displayLoanValue(totalAmountPayable, closeBrothersLoanStatus)}
            />
          </>
        }

      </div>

      <Button
        onClick={() => onButtonClick(quote)}
        className="insurer-quote-card-body__button"
        variant={"secondary"}
        expanded>
        {lookupI18nString("insurerQuoteCard.button.text")}
      </Button>
    </div>
  );
};

export default InsurerQuoteCardBody;
