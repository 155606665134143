import React, {FC, FormEvent, MouseEvent} from "react";
import Button from "shared-components/dist/buttons/button/Button";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import ButtonGroup from "shared-components/dist/buttons/button-group/ButtonGroup";

interface Props {
  onConfirmLogout: (event: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>) => void;
  onDismiss: () => void;
}

const LogoutModalButtons: FC<Props> = ({onConfirmLogout, onDismiss}) => (
  <ButtonGroup>
    <Button
      variant="tertiary"
      onClick={onDismiss}
      expanded
    >
      {lookupI18nString("navbar.logout.modal.buttons.cancel")}
    </Button>

    <Button
      type="submit"
      variant="primary"
      onClick={onConfirmLogout}
      expanded
    >
      {lookupI18nString("navbar.logout.modal.buttons.confirm")}
    </Button>
  </ButtonGroup>
);

export default LogoutModalButtons;
