import React, {FC} from "react";
import {
  convictionAdded,
  convictionRemoved,
  convictionStatusChanged,
  flagUnansweredConvictionsSubQuestions,
  hasConvictionsChanged,
  isConvictionsFormVisibleSet
} from "../../redux/UpdateAdditionalDriverSlice";
import {toSerializableConviction} from "../../../../shared/questions/convictions/models/Conviction";
import ConvictionsQuestion from "../../../../shared/questions/convictions/ConvictionsQuestion";
import {logAdditionalDriverGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {convictionsSelector} from "../../redux/selectors/AdditionalDriverDateTypeSelectors";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const AdditionalDriverConvictionsQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const convictions = useAppSelector(convictionsSelector);
  const shouldFlagUnansweredQuestions = useAppSelector(state => state.updateAdditionalDriver.convictions.value.shouldFlagUnansweredQuestions);
  const isFormVisible = useAppSelector(state => state.updateAdditionalDriver.convictions.value.isFormVisible);

  const onHasConvictionsAnswered = (answer: boolean): void => {
    logAdditionalDriverGoogleAnalyticsEvent({
      categorySuffix: "motor_related_accidents",
      action: answer,
    });

    dispatch(hasConvictionsChanged(answer));
  };

  return (
    <ConvictionsQuestion
      id="additional-driver-convictions"
      title="additionalDriver.convictionsQuestions.hasConviction.title"
      descriptionListItems={[
        "personalDetails.convictionsQuestions.hasConviction.description.motoringOrFixedPenalty",
        "personalDetails.convictionsQuestions.hasConviction.description.pendingProsecutions",
        "personalDetails.convictionsQuestions.hasConviction.description.drivingDisqualification"
      ]}
      tooltip={{
        title: "additionalDriver.convictionsQuestions.hasConviction.tooltip.title",
        description: "additionalDriver.convictionsQuestions.hasConviction.tooltip.description"
      }}
      convictions={convictions}
      hasConvictionsChanged={onHasConvictionsAnswered}
      convictionAdded={conviction => dispatch(convictionAdded(toSerializableConviction(conviction)))}
      convictionRemoved={index => dispatch(convictionRemoved(index))}
      shouldFlagUnansweredQuestions={shouldFlagUnansweredQuestions}
      flagUnansweredSubQuestions={() => dispatch(flagUnansweredConvictionsSubQuestions())}
      isFormVisible={isFormVisible}
      isFormVisibleSet={value => dispatch(isConvictionsFormVisibleSet(value))}
      isAdditionalDriverForm
      convictionStatusChanged={status => dispatch(convictionStatusChanged(status))}
    />
  );
};

export default AdditionalDriverConvictionsQuestion;
