import React, {FC} from "react";
import {fuelTypeSelected} from "../../../../../../../redux/Actions";
import {StringRadioColumnSubQuestion} from "../../../../../../../structure/questions/sub-question/variants/radio-column-sub-question/variants/StringRadioColumnSubQuestion";
import {fuelTypeInvalid} from "../../../redux/ManualVehicleLookupFormSlice";
import {useFuelTypes} from "../../../../../../../graphql/queries/vehicle-lookup/manual-vehicle-lookup/ManualVehicleLookup";
import ManualVehicleLookupProhibitedFuelTypeStop from "./prohibited-fuel-type-stop/ManualVehicleLookupProhibitedFuelTypeStop";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const YourVehicleFuelType: FC = () => {
  const dispatch = useAppDispatch();
  const makeValue = useAppSelector(state => state.manualVehicleLookupForm.make.value);
  const modelValue = useAppSelector(state => state.manualVehicleLookupForm.model.value);
  const fuelType = useAppSelector(state => state.manualVehicleLookupForm.fuelType);

  const {error, loading, data} = useFuelTypes(makeValue, modelValue);

  const fetchError = !!(!loading && error);

  if (fetchError) dispatch(fuelTypeInvalid("vehicleQuote.manualVehicleLookup.errors.fuelTypesFetchFailed"));

  const dropdownOptions = !loading && data
    ? data.vehicle.vehicleOptions.fuelType
    : [];

  return (
    <>
      <StringRadioColumnSubQuestion
        subQuestionProps={{
          title: "vehicleQuote.manualVehicleLookup.fuelType",
          id: "manual-vehicle-fuel-type",
          errorMessage: fuelType.errorMessage,
          status: loading ? "loading" : fuelType.status
        }}
        radioColumnProps={{
          name: "manual-vehicle-fuel-type-radio-column",
          options: dropdownOptions,
          selectedOption: fuelType.value,
          onSelection: value => dispatch(fuelTypeSelected(value)),
          disabled: fetchError
        }}
      />
      <ManualVehicleLookupProhibitedFuelTypeStop/>
    </>
  );
};

export default YourVehicleFuelType;
