import React, {FC} from "react";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import Button from "shared-components/dist/buttons/button/Button";
import {openZendeskChat} from "../../../../../../../zendesk/Zendesk";
import PlainCard from "shared-components/src/cards/plain-card/PlainCard";
import "./RenewalComplete.css";

const RenewalComplete: FC = () => (
  <PlainCard>
    <div className="renewal-complete">
      <h1>{lookupI18nString("renewal.completePage.title")}</h1>

      <p>{lookupI18nString("renewal.completePage.description")}</p>

      <Button
        data-testid="renewal-complete__button"
        variant="secondary"
        onClick={openZendeskChat}
        expanded
      >
        {lookupI18nString("renewal.completePage.getInTouch")}
      </Button>
    </div>
  </PlainCard>
);

export default RenewalComplete;