import {RootState} from "../../../../../../redux/Store";
import {
  AdditionalDriverQuoteValidationError,
  QuoteValidationError
} from "./QuoteValidationError";
import {Selector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";
import {quoteValidationErrorsEntityAdapter} from "./QuoteValidationErrorEntityAdapter";

const baseSelectors = quoteValidationErrorsEntityAdapter.getSelectors<RootState>(state => state.quoteDetails.quoteValidationErrors);

const selectAllValidationErrorsInSection = <T extends QuoteValidationError>(section: T["section"]): Selector<RootState, T[]> => {
  return createSelector(
    baseSelectors.selectAll,
    (allErrors: QuoteValidationError[]) => allErrors.filter((error): error is T => error.section === section)
  );
};

const quoteValidationErrorsSelectors = {
  ...baseSelectors,
  selectAllValidationErrorsInSection,
  selectQuoteValidationErrorsForAdditionalDrivers: selectAllValidationErrorsInSection<AdditionalDriverQuoteValidationError>("ADDITIONAL_DRIVER"),
  selectQuoteValidationErrorsForCoverDetails: selectAllValidationErrorsInSection("COVER_DETAILS"),
  selectQuoteValidationErrorsForVehicleDetails: selectAllValidationErrorsInSection("VEHICLE_DETAILS"),
  selectQuoteValidationErrorsForPersonalDetails: selectAllValidationErrorsInSection("PERSONAL_DETAILS"),
};

export default quoteValidationErrorsSelectors;