import React, {FC} from "react";
import {yearsNoClaimsBonusChanged,} from "../../../redux/CoverDetailsSlice";
import DropdownQuestion
  from "../../../../../../../structure/questions/question/variants/dropdown-question/DropdownQuestion";
import {NO_CLAIMS_BONUS_YEARS_OPTIONS} from "../../constants/NoClaimsBonusYearsDropdownOptions";
import {isNamedDriverAXAEligibleSelector} from "../../../redux/selectors/named-driver/IsNamedDriverAXAEligible";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const YearsNoClaimsBonusQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const yearsNoClaimsBonusField = useAppSelector(state => state.coverDetails.yearsNoClaimsBonus);
  const isEligibleForNamedDriver = useAppSelector(isNamedDriverAXAEligibleSelector);

  return (
    <>
      <DropdownQuestion
        id="years-no-claims-bonus-question"
        title="coverDetails.yearsNoClaimsBonusSubQuestion.title"
        value={yearsNoClaimsBonusField.value}
        status={yearsNoClaimsBonusField.status}
        placeholder="coverDetails.yearsNoClaimsBonusSubQuestion.placeholder"
        errorMessage={yearsNoClaimsBonusField.errorMessage}
        onChange={value => dispatch(yearsNoClaimsBonusChanged({value, isEligibleForNamedDriver}))}
        options={NO_CLAIMS_BONUS_YEARS_OPTIONS}
        tooltip={{
          title: "coverDetails.yearsNoClaimsBonusSubQuestion.tooltip.title",
          description: "coverDetails.yearsNoClaimsBonusSubQuestion.tooltip.description",
          isVisibleByDefault: true
        }}
        hideSpacer
      />
    </>
  );
};

export default YearsNoClaimsBonusQuestion;
