import {AppThunk, AppThunkDispatch} from "../../../../../../redux/Store";
import {
  additionalDriverClassOfUseInvalid,
  dateOfBirthInvalid,
  relationshipToProposerInvalid,
  firstNameInvalid,
  genderInvalid,
  householdNumberOfCarsInvalid,
  insuranceDeclinedQuestionInvalid,
  licenceCountryOfIssueInvalid,
  licenceDurationInvalid,
  licenceTypeInvalid,
  maritalStatusInvalid,
  hasNonMotoringConvictionsInvalid,
  surnameInvalid,
  titleInvalid
} from "../redux/UpdateAdditionalDriverSlice";
import {flagUnansweredUkResidencyQuestions} from "./FlagUnansweredUkResidencyQuestions";
import {flagUnansweredMedicalConditionQuestions} from "./FlagUnansweredMedicalConditionQuestions";
import {flagUnansweredConvictionsQuestions} from "./FlagUnansweredConvictionsQuestions";
import {flagUnansweredPrimaryEmploymentQuestions} from "./FlagUnansweredPrimaryEmploymentQuestions";
import {flagUnansweredSecondaryEmploymentQuestions} from "./FlagUnansweredSecondaryEmploymentQuestions";
import {flagUnansweredClaimsQuestions} from "./FlagUnansweredClaimsQuestions";
import {isConditionalFormFieldInvalid} from "shared-components/dist/models/form-field/ConditionalFormField";
import {UpdateAdditionalDriver} from "../redux/UpdateAdditionalDriver";

type CheckFunction = (updateAdditionalDriver: UpdateAdditionalDriver, dispatch: AppThunkDispatch) => void;

export const flagUnansweredAdditionalDriverQuestions = (): AppThunk => async (dispatch, getState) => {
  [
    checkRelationshipToProposer,
    checkTitle,
    checkFirstName,
    checkSurname,
    checkDateOfBirth,
    checkHouseholdNumberOfCars,
    checkGender,
    checkMaritalStatus,
    checkClassOfUse,
    checkLicenceType,
    checkLicenceCountryOfIssue,
    checkLicenceDuration,
    checkNonMotoringConvictions,
    checkInsuranceDeclined,
    flagUnansweredUkResidencyQuestions,
    flagUnansweredPrimaryEmploymentQuestions,
    flagUnansweredSecondaryEmploymentQuestions,
    flagUnansweredMedicalConditionQuestions,
    flagUnansweredClaimsQuestions,
    flagUnansweredConvictionsQuestions,
  ].forEach((checkFunction: CheckFunction) => checkFunction(getState().updateAdditionalDriver, dispatch));
};

function checkRelationshipToProposer({relationshipToProposer}: UpdateAdditionalDriver, dispatch: AppThunkDispatch): void {
  if (relationshipToProposer.status === "default") dispatch(relationshipToProposerInvalid("quote.errors.questionIncomplete"));
}

function checkTitle({title}: UpdateAdditionalDriver, dispatch: AppThunkDispatch): void {
  if (title.status === "default") dispatch(titleInvalid("quote.errors.questionIncomplete"));
}

function checkFirstName({firstName}: UpdateAdditionalDriver, dispatch: AppThunkDispatch): void {
  if (firstName.status === "default") dispatch(firstNameInvalid("quote.errors.questionIncomplete"));
}

function checkSurname({surname}: UpdateAdditionalDriver, dispatch: AppThunkDispatch): void {
  if (surname.status === "default") dispatch(surnameInvalid("quote.errors.questionIncomplete"));
}

function checkGender({gender}: UpdateAdditionalDriver, dispatch: AppThunkDispatch): void {
  if (gender.isActive && gender.status === "default") dispatch(genderInvalid("quote.errors.questionIncomplete"));
}

function checkDateOfBirth({dateOfBirth}: UpdateAdditionalDriver, dispatch: AppThunkDispatch): void {
  if (dateOfBirth.status === "default") dispatch(dateOfBirthInvalid("quote.errors.questionIncomplete"));
}

function checkHouseholdNumberOfCars({householdNumberOfCars}: UpdateAdditionalDriver, dispatch: AppThunkDispatch): void {
  if (isConditionalFormFieldInvalid(householdNumberOfCars)) dispatch(householdNumberOfCarsInvalid("quote.errors.questionIncomplete"));
}

function checkMaritalStatus({maritalStatus}: UpdateAdditionalDriver, dispatch: AppThunkDispatch): void {
  if (maritalStatus.status === "default") dispatch(maritalStatusInvalid("quote.errors.questionIncomplete"));
}

function checkClassOfUse({classOfUse}: UpdateAdditionalDriver, dispatch: AppThunkDispatch): void {
  if (classOfUse.status === "default") dispatch(additionalDriverClassOfUseInvalid("quote.errors.questionIncomplete"));
}

function checkLicenceType({licenceType}: UpdateAdditionalDriver, dispatch: AppThunkDispatch): void {
  if (licenceType.status === "default") dispatch(licenceTypeInvalid("quote.errors.questionIncomplete"));
}

function checkLicenceCountryOfIssue({licenceCountryOfIssue}: UpdateAdditionalDriver, dispatch: AppThunkDispatch): void {
  if (licenceCountryOfIssue.status === "default") dispatch(licenceCountryOfIssueInvalid("quote.errors.questionIncomplete"));
}

function checkLicenceDuration({licenceDuration}: UpdateAdditionalDriver, dispatch: AppThunkDispatch): void {
  if (licenceDuration.status === "default") dispatch(licenceDurationInvalid("quote.errors.questionIncomplete"));
}

function checkNonMotoringConvictions({hasNonMotoringConvictions}: UpdateAdditionalDriver, dispatch: AppThunkDispatch): void {
  if (hasNonMotoringConvictions.status === "default") dispatch(hasNonMotoringConvictionsInvalid("quote.errors.questionIncomplete"));
}

function checkInsuranceDeclined({hasInsuranceDeclined}: UpdateAdditionalDriver, dispatch: AppThunkDispatch): void {
  if (hasInsuranceDeclined.status === "default") dispatch(insuranceDeclinedQuestionInvalid("quote.errors.questionIncomplete"));
}