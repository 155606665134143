import {AdditionalVehicleDetailsFormQuestions} from "./AdditionalVehicleDetailsFormQuestions";
import {VehicleDetails} from "./VehicleDetails";
import {PayloadAction} from "@reduxjs/toolkit";
import {DriverPosition} from "../../../../../graphql/queries/vehicle-lookup/registration-lookup/model/DriverPosition";
import {conditionalFieldReset, fieldChanged, fieldInvalid} from "shared-components/dist/models/form-field/FormField";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {ImportType} from "../../../../../graphql/queries/vehicle-lookup/registration-lookup/model/ImportType";
import {AlarmInstallerOption} from "../../shared/questions/vehicle-alarm/VehicleAlarmInstallerOptions";

export const additionalVehicleDetailsFormQuestionsInitialState: AdditionalVehicleDetailsFormQuestions = {
  driverPosition: {
    status: "default",
    value: undefined
  },
  seats: {
    status: "default",
    value: undefined
  },
  hasTracker: {
    status: "default",
    value: undefined
  },
  hasAlarm: {
    status: "default",
    value: undefined
  },
  alarmFittedBy: {
    status: "default",
    value: undefined,
    isActive: false
  },
  imported: {
    status: "default",
    value: undefined
  },
  importType: {
    status: "default",
    value: undefined,
    isActive: false
  },
  estimatedValue: {
    status: "default",
    value: undefined
  }
};

export const additionalVehicleDetailsFormQuestionsReducers = {
  driverPositionChanged(state: VehicleDetails, action: PayloadAction<DriverPosition>): void {
    fieldChanged(state.additionalVehicleDetails.driverPosition, action.payload);
  },
  driverPositionInvalid(state: VehicleDetails, action: PayloadAction<TranslationKey>): void {
    fieldInvalid(state.additionalVehicleDetails.driverPosition, action.payload);
  },
  seatsChanged(state: VehicleDetails, action: PayloadAction<number>): void {
    fieldChanged(state.additionalVehicleDetails.seats, action.payload);
  },
  seatsInvalid(state: VehicleDetails, action: PayloadAction<TranslationKey>): void {
    fieldInvalid(state.additionalVehicleDetails.seats, action.payload);
  },
  hasAlarmChanged(state: VehicleDetails, action: PayloadAction<boolean>): void {
    fieldChanged(state.additionalVehicleDetails.hasAlarm, action.payload);

    if (action.payload) {
      state.additionalVehicleDetails.alarmFittedBy.isActive = true;
    } else {
      conditionalFieldReset(state.additionalVehicleDetails.alarmFittedBy);
    }
  },
  hasAlarmInvalid(state: VehicleDetails, action: PayloadAction<TranslationKey>): void {
    fieldInvalid(state.additionalVehicleDetails.hasAlarm, action.payload);
  },
  isAlarmFittedByChanged(state: VehicleDetails, action: PayloadAction<AlarmInstallerOption>): void {
    fieldChanged(state.additionalVehicleDetails.alarmFittedBy, action.payload);
  },
  isAlarmFittedByInvalid(state: VehicleDetails, action: PayloadAction<TranslationKey>): void {
    fieldInvalid(state.additionalVehicleDetails.alarmFittedBy, action.payload);
  },
  additionalDetailsHasTrackerChanged(state: VehicleDetails, action: PayloadAction<boolean>): void {
    fieldChanged(state.additionalVehicleDetails.hasTracker, action.payload);
  },
  additionalDetailsHasTrackerInvalid(state: VehicleDetails, action: PayloadAction<TranslationKey>): void {
    fieldInvalid(state.additionalVehicleDetails.hasTracker, action.payload);
  },
  isImportedChanged(state: VehicleDetails, action: PayloadAction<boolean>): void {
    fieldChanged(state.additionalVehicleDetails.imported, action.payload);

    if (action.payload) {
      state.additionalVehicleDetails.importType.isActive = true;
    } else {
      conditionalFieldReset(state.additionalVehicleDetails.importType);
    }
  },
  isImportedInvalid(state: VehicleDetails, action: PayloadAction<TranslationKey>): void {
    fieldInvalid(state.additionalVehicleDetails.imported, action.payload);
  },
  importTypeChanged(state: VehicleDetails, action: PayloadAction<ImportType>): void {
    fieldChanged(state.additionalVehicleDetails.importType, action.payload);
  },
  importTypeInvalid(state: VehicleDetails, action: PayloadAction<TranslationKey>): void {
    fieldInvalid(state.additionalVehicleDetails.importType, action.payload);
  },
  estimatedValueChanged(state: VehicleDetails, action: PayloadAction<number>): void {
    fieldChanged(state.additionalVehicleDetails.estimatedValue, action.payload);
  },
  estimatedValueInvalid(state: VehicleDetails, action: PayloadAction<TranslationKey>): void {
    fieldInvalid(state.additionalVehicleDetails.estimatedValue, action.payload);
  }
};