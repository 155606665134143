import React, {FC} from "react";
import QuoteJourneyStop from "../quote-journey-stop/QuoteJourneyStop";

interface Props {
  isVisible: boolean;
  isSubContainer: boolean;
}

const EuImportTypeStop: FC<Props> = (
  {
    isVisible,
    isSubContainer
  }
) => (
  <QuoteJourneyStop
    stopProps={{
      visible: isVisible,
      title: "vehicleQuote.additionalVehicleDetailsForm.importType.eu.stop.title",
      description: "quoteJourney.shared.softStop.description"
    }}
    isSubContainer={isSubContainer}
  />
);

export default EuImportTypeStop;