import {gql} from "@apollo/client";
import {apolloClient} from "../../../apollo/ApolloClientProvider";
import {QuoteQueryDoesPolicyHolderExistArgs} from "shared/dist/generated/graphql/resolvers-types";

export interface DoesPolicyHolderExistResult {
  quote: {
    doesPolicyHolderExist: boolean;
  }
}

const DOES_POLICY_HOLDER_EXIST_QUERY = gql`
  query DoesPolicyHolderExist($email: String!) {
    quote {
      doesPolicyHolderExist(email: $email)
    }
  }
`;

export function doesPolicyHolderExistQuery(doesPolicyHolderExistArgs: QuoteQueryDoesPolicyHolderExistArgs): Promise<boolean> {
  return new Promise((resolve, reject) => {
    apolloClient.query<DoesPolicyHolderExistResult, QuoteQueryDoesPolicyHolderExistArgs>({
      query: DOES_POLICY_HOLDER_EXIST_QUERY,
      variables: doesPolicyHolderExistArgs,
      fetchPolicy: "no-cache"
    }).then(result => resolve(result.data.quote.doesPolicyHolderExist))
      .catch(error => reject(error));
  });
}
