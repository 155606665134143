import {FC} from "react";
import CurrentlyOrPreviouslyInsuredQuestion
  from "../currently-or-previously-insured/CurrentlyOrPreviouslyInsuredQuestion";
import VehicleTypeQuestion from "../vehicle-type/VehicleTypeQuestion";
import YearsNamedDriverQuestion from "../years-named-driver/YearsNamedDriverQuestion";
import {useAppSelector} from "../../../../../../../../redux/Hooks";

const PreviousNamedDriverQuestions: FC = () => {
  const isCurrentlyOrPreviouslyInsuredField = useAppSelector(state => state.coverDetails.isCurrentlyOrPreviouslyInsured);
  const vehicleTypeField = useAppSelector(state => state.coverDetails.vehicleType);
  const yearsNamedDriverField = useAppSelector(state => state.coverDetails.yearsNamedDriver);

  return (
    <>
      {isCurrentlyOrPreviouslyInsuredField.isActive && <CurrentlyOrPreviouslyInsuredQuestion/>}
      {vehicleTypeField.isActive && <VehicleTypeQuestion/>}
      {yearsNamedDriverField.isActive && <YearsNamedDriverQuestion/>}
    </>
  );
};

export default PreviousNamedDriverQuestions;