import {Status} from "shared-components/dist/status-indicator/Status";

export function calculateDuration(months: number): Duration {
  return {
    months: months % 12,
    years: Math.floor(months / 12)
  };
}

export const determineStatus = (condition: boolean): Status => condition ? "success" : "default";
