import React, {FC} from "react";
import {PolicyDocument} from "shared/dist/generated/graphql/resolvers-types";
import CurrentDocument from "../CurrentDocument";
import ExternalLink from "shared-components/dist/links/ExternalLink";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {formatDateStringToDocumentDateTimeFormat} from "shared/dist/stdlib/DateFormat";
import {mapDocumentTypeToTitle} from "../helpers/MapDocumentTypeToTitle";
import "./PolicyExtrasDocumentList.css";
import {calculateDocumentFileSize} from "shared-components/dist/utils/helpers/display/DocumentFileSizeConverter";
import {logPortalGoogleAnalyticsEvent} from "../../../../../../../../utils/analytics/PortalAnalytics";
import {mapDocumentTypeToGoogleAnalyticsEventSuffix} from "../helpers/MapDocumentTypeToGoogleAnalyticsEventSuffix";

interface Props {
  policyExtraDocuments: PolicyDocument[];
}

const PolicyExtrasDocumentList: FC<Props> = ({policyExtraDocuments}) => {
  const onDocumentClick = (document: PolicyDocument): void => {
    logPortalGoogleAnalyticsEvent({
      categorySuffix: mapDocumentTypeToGoogleAnalyticsEventSuffix(document.type),
      action: "Click"
    });
  };

  if (!policyExtraDocuments || policyExtraDocuments.length === 0) {
    return <div/>;
  }

  return (
    <div className="policy-extras-document-list">
      <CurrentDocument
        title="policies.documents.type.policyExtras.title"
        body={
          <div className="policy-extras-document-list__links">
            {policyExtraDocuments.map((document, position) =>
              document &&
              <div className="policy-extras-document-list__links__document" key={position}>
                <ExternalLink
                  key={position}
                  url={document.url}
                  onClick={() => onDocumentClick(document)}
                >
                  {getPolicyExtraDocumentDetails(document)}
                </ExternalLink>
                {getFileSizeString(document.fileSize)}
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};

function getPolicyExtraDocumentDetails(policyDocument: PolicyDocument): string {
  return lookupI18nString(mapDocumentTypeToTitle(policyDocument.type))
    + getLastModifiedDateString(policyDocument.effectiveDate);
}

const getLastModifiedDateString = (lastModifiedDate: string | undefined | null): string => `${lastModifiedDate ? ` - ${formatDateStringToDocumentDateTimeFormat(lastModifiedDate)}` : ""}`;

const getFileSizeString = (fileSize: number | undefined | null): string => `${fileSize ? ` ${calculateDocumentFileSize(fileSize)}` : ""}`;

export default PolicyExtrasDocumentList;
