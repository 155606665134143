import React, {FC, useState} from "react";
import "./ForgotPasswordModal.css";
import ForgotPasswordModalBody, {ForgotPasswordModalDismissedReason} from "./body/ForgotPasswordModalBody";
import ModalTabGroup, {ModalTab} from "shared-components/dist/modals/modal-tab-group/ModalTabGroup";
import Modal from "shared-components/dist/modals/Modal";

interface Props {
  visible: boolean;
  onDismissed: (reason: ForgotPasswordModalDismissedReason) => void;
  initialEmailAddress?: string;
}

const modalTab: ModalTab = {
  text: "login.forgotPassword.modal.header"
};

const ForgotPasswordModal: FC<Props> = ({
  visible,
  onDismissed,
  initialEmailAddress
}) => {
  const [activeTab, setActiveTab] = useState<ModalTab>(modalTab);

  return (
    <Modal
      visible={visible}
      onDismiss={() => onDismissed("Cancelled")}
      className="forgot-password-modal"
      header={
        <ModalTabGroup
          modalTabs={[modalTab]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      }
      body={
        <ForgotPasswordModalBody
          onDismissed={(reason) => onDismissed(reason)}
          initialEmailAddress={initialEmailAddress}
        />
      }
    />
  );
};

export default ForgotPasswordModal;
