import React, {FC} from "react";
import {modelSelected} from "../../../../../../../redux/Actions";
import {modelInvalid} from "../../../redux/ManualVehicleLookupFormSlice";
import {useModelsProps} from "./hooks/UseModelsProps";
import {toStringOptionFromString} from "../../../../../../../structure/form/typeahead/variants/StringTypeahead";
import {StringTypeaheadSubQuestion} from "../../../../../../../structure/questions/sub-question/variants/typeahead-sub-question/variants/StringTypeaheadSubQuestion";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const YourVehicleModel: FC = () => {
  const dispatch = useAppDispatch();
  const makeValue = useAppSelector(state => state.manualVehicleLookupForm.make.value);
  const model = useAppSelector(state => state.manualVehicleLookupForm.model);

  const {status, onModelSelected, models} = useModelsProps(makeValue, model, model => dispatch(modelSelected(model)));

  if (status === "error") dispatch(modelInvalid("vehicleQuote.manualVehicleLookup.errors.modelsFetchFailed"));

  return (
    <StringTypeaheadSubQuestion
      id="manual-vehicle-lookup-model"
      title="vehicleQuote.manualVehicleLookup.model"
      errorMessage={model.errorMessage}
      status={status}
      typeaheadProps={{
        options: models,
        selectedOption: toStringOptionFromString(model),
        onSelection: onModelSelected,
        name: "manual-vehicle-lookup-model-typeahead",
        loading: status === "loading",
        placeholder: "vehicleQuote.manualVehicleLookup.model.typeahead.placeholder"
      }}
      showQuestionWhenLoading={false}
    />
  );
};

export default YourVehicleModel;
