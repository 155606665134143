import React, {FC} from "react";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import {genderChanged} from "../../redux/PersonalDetailsSlice";
import GenderQuestion from "../../../shared/questions/gender/GenderQuestion";
import {GenderOption} from "../../../shared/questions/gender/Genders";
import {logYourDetailsGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const tooltip: TooltipProps = {
  description: "personalDetails.genderQuestion.tooltip.description"
};

const ProposerGenderQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const genderField = useAppSelector(state => state.personalDetails.gender);

  const onGenderChanged = (gender: GenderOption): void => {
    logYourDetailsGoogleAnalyticsEvent({
      categorySuffix: "gender",
      action: gender.description,
    });
    dispatch(genderChanged(gender));
  };

  return (
    <GenderQuestion
      id="proposer-gender"
      title="personalDetails.genderQuestion"
      tooltip={tooltip}
      genderField={genderField}
      genderChanged={onGenderChanged}
    />
  );
};

export default ProposerGenderQuestion;