import React, {FC, ReactElement, useState} from "react";
import "./PasswordConfirmation.css";
import InlineQuestion from "../../questions/inline-question/InlineQuestion";
import PasswordInput from "shared-components/dist/form/password-input-field/PasswordInput";
import {checkPasswordFormat, checkPasswordsMatch, PasswordFormatState, PasswordMatchState} from "./PasswordValidators";
import {base64ToString} from "../../../utils/service/base64/Base64Conversion";
import {Status} from "shared-components/dist/status-indicator/Status";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import InformationPanel from "shared-components/dist/information/panel/InformationPanel";

interface PasswordConfirmationProps {
  isPasswordViolatingPasswordPolicy: boolean;
  onChange: (passwordMatchState: PasswordMatchState, passwordFormatState: PasswordFormatState, password?: string) => void;
  submitButton?: ReactElement;
  base64Email: string;
}

const PasswordConfirmation: FC<PasswordConfirmationProps> = (
  {
    isPasswordViolatingPasswordPolicy,
    onChange,
    submitButton,
    base64Email
  }
) => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [repeatedPassword, setRepeatedPassword] = useState<string>("");
  const [passwordsMatch, setPasswordsMatchState] = useState<boolean>(true);
  const [passwordFormatValid, setPasswordFormatValid] = useState<boolean>(true);
  const userEmail = base64ToString(base64Email);

  function onPasswordEntered(newPassword: string): void {
    setNewPassword(newPassword);
    validatePasswords(newPassword, repeatedPassword);
  }

  function onRepeatPasswordEntered(repeatedPassword: string): void {
    setRepeatedPassword(repeatedPassword);
    validatePasswords(newPassword, repeatedPassword);
  }

  function validatePasswords(newPassword: string, repeatedPassword: string): void {
    const passwordFormatState = checkPasswordFormat(newPassword, userEmail);
    const passwordMatchState = checkPasswordsMatch(newPassword, repeatedPassword);
    onChange(passwordMatchState, passwordFormatState, newPassword);
    setErrorState(passwordMatchState, passwordFormatState);
  }

  function onPasswordChange(newPassword: string): void {
    setNewPassword(newPassword);
    validatePasswordState(newPassword, repeatedPassword);
  }

  function onRepeatPasswordChange(repeatedPassword: string): void {
    setRepeatedPassword(repeatedPassword);
    validatePasswordState(newPassword, repeatedPassword);
  }

  function validatePasswordState(newPassword: string, repeatedPassword: string): void {
    const passwordFormatState = checkPasswordFormat(newPassword, userEmail);
    const passwordMatchState = checkPasswordsMatch(newPassword, repeatedPassword);
    onChange(passwordMatchState, passwordFormatState, newPassword);

    if (passwordFormatState === "Valid") setPasswordFormatValid(true);
    if (passwordMatchState === "Valid") setPasswordsMatchState(true);
  }

  function setErrorState(passwordMatchState: PasswordMatchState, passwordFormatState: PasswordFormatState): void {
    setPasswordsMatchState(passwordMatchState !== "Invalid");
    setPasswordFormatValid(passwordFormatState !== "Invalid");
  }

  function getQuestionStatus(): Status {
    if (isPasswordViolatingPasswordPolicy || !passwordFormatValid) return "error";
    if (!passwordsMatch) return "error";
    return "hidden";
  }

  function getQuestionErrorMessage(): TranslationKey {
    if (isPasswordViolatingPasswordPolicy || !passwordFormatValid) return "passwordConfirmation.invalidPasswordPolicy.error.message";
    if (!passwordsMatch) return "passwordConfirmation.matchingPasswords.error.message";
    return "passwordConfirmation.error.message.genericFailure";
  }

  return (
    <>
      <InlineQuestion
        id="password-policy-format"
        status={getQuestionStatus()}
        errorMessage={getQuestionErrorMessage()}
      >
        <div className="password-confirmation">
          <PasswordInput
            name="new-password"
            value={newPassword}
            onComplete={onPasswordEntered}
            onChange={onPasswordChange}
            placeholder={"passwordConfirmation.passwordInput.placeholder"}
            autoCompleteType="new-password"
          />
          <div className={"repeat-password-container"}>
            <div className={"repeat-password-container__input"}>
              <PasswordInput
                name="repeat-new-password"
                value={repeatedPassword}
                onComplete={onRepeatPasswordEntered}
                onChange={onRepeatPasswordChange}
                placeholder={"passwordConfirmation.repeatPasswordInput.placeholder"}
                autoCompleteType="repeat-new-password"
              />
            </div>
          </div>
          {submitButton}
        </div>
      </InlineQuestion>

      <InformationPanel
        className="password-confirmation__information-panel"
        title="accountSettings.form.error.password.format.title"
        listDescription={[
          "accountSettings.form.error.password.specialCharacter",
          "accountSettings.form.error.password.upperCaseLetter",
          "accountSettings.form.error.password.number",
          "accountSettings.form.error.password.minimumLength",
          "accountSettings.form.error.password.emailAddress"
        ]}
      />
    </>
  );
};

export default PasswordConfirmation;
