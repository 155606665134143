import {createIntl, createIntlCache, IntlShape} from "react-intl";
import englishTranslations from "./strings/en/translations";
import inContextTranslations from "./strings/zh/translations";

const englishIntl = createIntl({
  locale: "en",
  messages: englishTranslations
}, createIntlCache());

const inContextIntl = createIntl({
  locale: "zh",
  messages: inContextTranslations
}, createIntlCache());

export const getIntl = (): IntlShape => {
  const twoLetterCode = sessionStorage.getItem("lang") ?? "en";
  switch (twoLetterCode) {
    case "zh": return inContextIntl;
    case "en":
    default: return englishIntl;
  }
};