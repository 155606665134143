import {FC} from "react";
import ExpandableCard from "../expandable-card/ExpandableCard";
import Button from "shared-components/dist/buttons/button/Button";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {NorthEast} from "@mui/icons-material";
import "./RenewalDocumentCard.css";
import {useAppSelector} from "../../../../../../../redux/Hooks";
import {selectPolicyDocuments} from "../../../../redux/RenewalSelectors";

const RenewalDocumentCard: FC = () => {
  const documents = useAppSelector(selectPolicyDocuments);

  return (
    <ExpandableCard
      title="portal.renewals.summary.footer.policyDocuments.title"
      description="portal.renewals.summary.footer.policyDocuments.description"
      className="renewal-document-card"
    >
      {documents &&
        <>
          <Button
            data-testid={"renewal-document-card__ipid"}
            variant="link"
            rightIcon={<NorthEast/>}
            onClick={() => open(documents.insuranceProductInformation)}
          >
            {lookupI18nString("portal.renewals.summary.footer.policyDocuments.button.policyInfo")}
          </Button>

          <Button
            data-testid={"renewal-document-card__policy-booklet"}
            variant="link"
            rightIcon={<NorthEast/>}
            onClick={() => open(documents.policyBooklet)}
          >
            {lookupI18nString("portal.renewals.summary.footer.policyDocuments.button.policyBooklet")}
          </Button>
        </>
      }

      {!documents &&
        <Button
          data-testid={"renewal-document-card__policy-documents"}
          variant="link"
          rightIcon={<NorthEast/>}
          onClick={() => open("https://strollinsurance.co.uk/policy-booklets")}
        >
          {lookupI18nString("portal.renewals.summary.footer.policyDocuments.button.policyDocuments")}
        </Button>
      }
    </ExpandableCard>
  );
};

export default RenewalDocumentCard;