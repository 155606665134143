import {useAppDispatch, useAppSelector} from "../../../../../redux/Hooks";
import {useEffect} from "react";
import {useFeatureFlags} from "shared-components/dist/feature-flags/hooks/UseFeatureFlags";
import {isAggregatorQuoteSelector} from "../redux/selectors/AggregatorQuoteSelectors";
import {setQuoteValidationSectionBeingResolved} from "../redux/QuoteDetailsSlice";

export const useDetectShouldShowAggregatorUnansweredQuestionsModal = (setShouldShowAggregatorUnansweredQuestionsModal: (value: boolean) => void): void => {
  const {compareNiPhase2Flag} = useFeatureFlags();
  const dispatch = useAppDispatch();
  const isAggregatorQuote = useAppSelector(isAggregatorQuoteSelector);
  const breakInCover = useAppSelector(state => state.coverDetails.breakInCover);
  const breakInCoverDeclarationAcceptance = useAppSelector(state => state.coverDetails.breakInCoverDeclarationAcceptance.value ?? false);

  useEffect(() => {
    if (!compareNiPhase2Flag || !isAggregatorQuote) return;

    if (breakInCover === "NO_BREAK_IN_COVER" || (breakInCover === "DECLARATION_REQUIRED" && breakInCoverDeclarationAcceptance)) return;

    dispatch(setQuoteValidationSectionBeingResolved("COVER_DETAILS"));
    setShouldShowAggregatorUnansweredQuestionsModal(true);
  }, [breakInCover, breakInCoverDeclarationAcceptance, compareNiPhase2Flag, dispatch, isAggregatorQuote, setShouldShowAggregatorUnansweredQuestionsModal]);
};