import React, {FC, useCallback} from "react";
import {licenceDurationChanged, licenceDurationInvalid, licenceDurationReset} from "../../redux/PersonalDetailsSlice";
import LicenceDurationQuestion from "../../../shared/questions/licence-duration/LicenceDurationQuestion";
import {dateOfBirthAsDateSelector} from "../../redux/selectors/PersonalDetailsSelectors";
import {logYourDetailsGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const ProposerLicenceDurationQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const licenceDuration = useAppSelector(state => state.personalDetails.licenceDuration);
  const dateOfBirth = useAppSelector(state => dateOfBirthAsDateSelector(state).value);

  const onReset = useCallback(() => {
    dispatch(licenceDurationReset());
  }, [dispatch]);

  return (
    <LicenceDurationQuestion
      id="proposer-licence-duration"
      title="personalDetails.licenceDurationQuestion"
      licenceDuration={licenceDuration}
      licenceDurationChanged={duration => dispatch(licenceDurationChanged(duration))}
      licenceDurationInvalid={errorMessage => dispatch(licenceDurationInvalid(errorMessage))}
      licenceDurationReset={onReset}
      dateOfBirth={dateOfBirth}
      googleAnalyticsLogger={logYourDetailsGoogleAnalyticsEvent}
      tooltip={{
        description: "personalDetails.licenceDurationQuestion.tooltip.description"
      }}
    />
  );
};

export default ProposerLicenceDurationQuestion;
