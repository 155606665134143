import {FC} from "react";
import {useNavigate} from "react-router-dom";
import {QUOTE_ANNUAL_PAYMENT_BY_CARD, QUOTE_PAYMENT_SUCCESS} from "../../../../../router/models/Routes";
import {logPaymentGoogleAnalyticsEvent} from "../../../../../utils/analytics/PaymentAnalytics";
import {useEcommerceBeginCheckout} from "../../../../../utils/analytics/hooks/EcommerceBeginCheckout";
import {ApplePayPaymentToken, FullPolicyPaymentResult} from "shared/dist/generated/graphql/resolvers-types";
import {useGooglePayPurchaseFullPolicy} from "../../payment-processors/google-pay/GooglePayPolicyPaymentProcessor";
import {proposedPolicyRequestSelector} from "../../../shared/redux/ProposedPolicyRequestSelector";
import {applePayPurchaseFullPolicyMutation} from "../../payment-processors/apple-pay/ApplePayPolicyPaymentProcessor";
import {useBuildEcommerceItemsList} from "../../../../../utils/analytics/helpers/UseBuildEcommerceItemsList";
import {
  sendEcommerceAnalyticsAddPaymentInfoEvent
} from "shared-components/dist/analytics/GoogleAnalyticsEcommerceEventsService";
import {useAppSelector} from "../../../../../redux/Hooks";
import {
  useQuotePriceProps
} from "../../../../quote/vehicle/shared/quote-summary/components/quote-price-card/hooks/UseQuotePricesProps";
import QuoteNavigationPrompter from "../../../../quote/vehicle/shared/router/QuoteNavigationPrompter";
import AnnualPaymentLanding from "../AnnualPaymentLanding";

type AnnualPaymentType = `Annual - ${string}`;

const QuoteAnnualPaymentLanding: FC = () => {
  const {totalUnfinancedAmountPayable} = useQuotePriceProps();
  const proposedPolicyRequest = useAppSelector(state => proposedPolicyRequestSelector(state));

  useEcommerceBeginCheckout();
  const ecommerceItems = useBuildEcommerceItemsList();
  const navigate = useNavigate();
  const {
    makePayment,
    loading,
    paymentFailed,
    resetPaymentFailed
  } = useGooglePayPurchaseFullPolicy(proposedPolicyRequest, () => addEcommercePaymentInfoForType("Annual - Google Pay"));

  const onPaymentWithApplePay = async (payment: ApplePayJS.ApplePayPayment): Promise<FullPolicyPaymentResult> => {
    addEcommercePaymentInfoForType("Annual - Apple Pay");

    return await applePayPurchaseFullPolicyMutation({
      payment: {
        token: payment.token.paymentData as ApplePayPaymentToken
      },
      magicLinkId: proposedPolicyRequest.magicLinkId,
      proposerEmail: proposedPolicyRequest.proposer.email,
      proposerPhoneNumber: proposedPolicyRequest.proposer.phoneNumber,
      quoteReference: proposedPolicyRequest.quoteReference,
      quoteSequenceNumber: proposedPolicyRequest.quoteSequenceNumber,
    });
  };

  const onPaymentByCard = (): void => {
    addEcommercePaymentInfoForType("Annual - Debit/Credit Card (Global Payments)");
    logPaymentGoogleAnalyticsEvent({
      categorySuffix: "annual.type.card",
      action: "Click",
    });
    navigate(QUOTE_ANNUAL_PAYMENT_BY_CARD);
  };

  const addEcommercePaymentInfoForType = (type: AnnualPaymentType): void => {
    sendEcommerceAnalyticsAddPaymentInfoEvent({
      items: ecommerceItems ?? [],
      payment_type: type,
      value: Number(totalUnfinancedAmountPayable?.amount),
      currency: totalUnfinancedAmountPayable?.currency ?? "GBP"
    });
  };

  return (
    <>
      <QuoteNavigationPrompter/>
      <AnnualPaymentLanding
        loading={loading}
        successPagePath={QUOTE_PAYMENT_SUCCESS}
        totalUnfinancedAmountPayable={totalUnfinancedAmountPayable}
        onPaymentByCard={onPaymentByCard}
        onPaymentWithApplePay={onPaymentWithApplePay}
        onPaymentWithGooglePay={makePayment}
        paymentFailed={paymentFailed}
        resetPaymentFailed={resetPaymentFailed}
      />
    </>
  );
};

export default QuoteAnnualPaymentLanding;