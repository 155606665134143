export interface ScrollToProps {
  duration: number;
  delay: number;
  smooth: "easeInOutQuart";
  offset: number;
}

export const DEFAULT_SCROLL_TO_PROPS: ScrollToProps = {
  duration: 500,
  delay: 0,
  smooth: "easeInOutQuart",
  offset: -75
};
