import React, {FC} from "react";
import {
  ACCIDENT_DESCRIPTION_DISPLAY_OPTIONS,
  ACCIDENT_DESCRIPTION_OPTIONS,
  ACCIDENT_DESCRIPTIONS,
  AccidentDescriptionOption
} from "../models/AccidentDescriptionOption";
import DropdownSubQuestion
  from "../../../../../../../structure/questions/sub-question/variants/dropdown-sub-question/DropdownSubQuestion";
import {ClaimsFormSubQuestionProps} from "./ClaimsFormSubQuestionProps";

type Props = ClaimsFormSubQuestionProps<AccidentDescriptionOption>;

const AccidentDescriptionSubQuestion: FC<Props> = (
  {
    value,
    onChange,
    status,
    isAdditionalDriverForm
  }
) => {
  const onAccidentDescriptionSelected = (selection: string): void => {
    const selectedAccidentDescription = ACCIDENT_DESCRIPTIONS.find(description => description.vtCode === selection);
    if (!selectedAccidentDescription) return;

    onChange(selectedAccidentDescription);
  };

  return (
    <DropdownSubQuestion
      id="accident-description-sub-question"
      title={
        isAdditionalDriverForm
          ? "additionalDriver.claims.accidentDescription.title"
          : "personalDetails.claims.accidentDescription.title"
      }
      errorMessage="quote.errors.questionIncomplete"
      status={status}
      value={value?.vtCode}
      options={ACCIDENT_DESCRIPTION_OPTIONS}
      displayValues={ACCIDENT_DESCRIPTION_DISPLAY_OPTIONS}
      onChange={onAccidentDescriptionSelected}
    />
  );
};

export default AccidentDescriptionSubQuestion;