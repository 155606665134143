import React, {FC} from "react";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./PolicyExtras.css";
import {PolicyExtraMetadata, PolicyExtraType} from "shared/dist/generated/graphql/resolvers-types";
import {
  getPolicyExtraDescriptionKeyFrom,
  getPolicyExtraNameKeyFrom,
  getPolicyExtraOptionDescriptionKeyFrom
} from "../../../../../quote/vehicle/shared/quote-summary/components/policy-extras-selection/helpers/PolicyExtraTranslationKeyMapper";
import PolicyExtrasList
  from "../../../../../quote/vehicle/shared/quote-summary/components/policy-extras-selection/components/policy-extras-list/PolicyExtrasList";
import AccordionList from "shared-components/dist/lists/accordian-list/AccordionList";
import {
  PolicyExtrasListItemProps
} from "../../../../../quote/vehicle/shared/quote-summary/components/policy-extras-selection/components/policy-extras-list-item/PolicyExtrasListItem";
import Button from "shared-components/dist/buttons/button/Button";
import {useNavigate} from "react-router-dom";
import {logPortalGoogleAnalyticsEvent} from "../../../../../../utils/analytics/PortalAnalytics";
import {usePolicyQueryParams} from "../../../../../../router/params/Params";

interface OwnProps {
  policyExtras: PolicyExtraMetadata[];
}

const PolicyExtras: FC<OwnProps> = ({policyExtras}) => {
  const {policyId} = usePolicyQueryParams();
  const navigate = useNavigate();

  const onPolicyExtraViewDocumentsAnchorClick = (googleAnalyticsSuffix: string): void => {
    navigate(policyDocumentsPath);
    logPortalGoogleAnalyticsEvent({
      categorySuffix: googleAnalyticsSuffix,
      action: "Click"
    });
  };

  const policyExtraListItems: PolicyExtrasListItemProps[] = policyExtras?.map((policyExtra) => {
    const name = getPolicyExtraNameKeyFrom(policyExtra.type);
    const optionDescription = getPolicyExtraOptionDescriptionKeyFrom(policyExtra?.optionType);
    const googleAnalyticsSuffix = getGoogleAnalyticsSuffixFrom(policyExtra.type);

    return {
      type: policyExtra.type,
      name,
      description: getPolicyExtraDescriptionKeyFrom(policyExtra.type),
      onAnchorClick: () => onPolicyExtraViewDocumentsAnchorClick(googleAnalyticsSuffix),
      anchorText: "policies.mainContent.policyExtras.viewDocuments",
      price: Number(policyExtra.policyExtraCost?.gross) ?? undefined,
      startDate: policyExtra.startDate,
      toggleIsVisible: false,
      optionDescription
    };
  });

  if (policyExtraListItems.length === 0) { return <></>; }

  const policyDocumentsPath = `/portal/policy/${policyId}/documents`;

  return (
    <div className="policy-extras-accordion">
      <AccordionList
        items={[{
          title: lookupI18nString("policies.mainContent.policyExtras.title"),
          contents: <PolicyExtrasList items={policyExtraListItems.map(policyExtra => ({...policyExtra, price: undefined}))}/>
        }]}
        defaultOpenIndexes={[0]}
        footer={
          <Button
            variant="tertiary"
            className="view-policy-documents"
            onClick={() => {
              navigate(policyDocumentsPath);
              logPortalGoogleAnalyticsEvent({
                categorySuffix: "policy.summary.policy_extras.view_documents.all_documents",
                action: "Click"
              });
            }}
            expanded>
            {lookupI18nString("policies.mainContent.policyExtras.viewDocuments")}
          </Button>
        }
      />
    </div>
  );
};

function getGoogleAnalyticsSuffixFrom(policyExtraType: PolicyExtraType): string {
  switch (policyExtraType) {
    case PolicyExtraType.SmartfobKeycare:
      return "policy.summary.policy_extras.view_documents.smartfob_keycare";
    case PolicyExtraType.CourtesyCar:
      return "policy.summary.policy_extras.view_documents.courtesy_car";
    case PolicyExtraType.ExcessInsurance:
      return "policy.summary.policy_extras.view_documents.excess_insurance";
    case PolicyExtraType.RoadsideAssistancePlatinum:
      return "policy.summary.policy_extras.view_documents.roadside_assistance_platinum";
    case PolicyExtraType.LegalExpenses:
      return "policy.summary.policy_extras.view_documents.legal_expenses";
  }
}

export default PolicyExtras;
