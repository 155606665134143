import {Claim, Claims} from "../models/Claims";
import {isAccident} from "../helpers/IsAccident";

export function isValidClaim(claim: Partial<Claim>): claim is Claim {
  return isAccident(claim.incidentType)
    ? isValidAccidentClaim(claim)
    : hasRequiredClaimFields(claim);
}

function isValidAccidentClaim(claim: Partial<Claim>): boolean {
  return claim.accidentDescription !== undefined && hasRequiredClaimFields(claim);
}

function hasRequiredClaimFields(claim: Partial<Claim>): claim is Claim {
  return [
    claim.date,
    claim.status,
    claim.incidentType,
    claim.atFault,
    claim.whichPolicyClaimedAgainst,
    claim.noClaimsAffected,
    claim.bodilyInjuryOccurred,
    claim.value
  ].every(field => field !== undefined);
}

export function isValidClaimsList(claims: Claims): boolean {
  if (!claims.hasClaims) return true;

  return claims.claims.length !== 0 && !claims.isFormVisible;
}
