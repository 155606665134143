import React, {FC, useEffect} from "react";
import AdditionalDetailsDriverPosition from "./additional-details-driver-position/AdditionalDetailsDriverPosition";
import AdditionalDetailsSeats from "./additional-details-seats/AdditionalDetailsSeats";
import AdditionalDetailsHasTracker from "./additional-details-has-tracker/AdditionalDetailsHasTracker";
import AdditionalDetailsImported from "./additional-details-imported/AdditionalDetailsImported";
import AdditionalDetailsImportType from "./additional-details-import-type/AdditionalDetailsImportType";
import AdditionalDetailsEstimatedValue from "./additional-details-estimated-value/AdditionalDetailsEstimatedValue";
import SubQuestionForm from "../../../../../../structure/questions/sub-question-form/SubQuestionForm";
import {additionalVehicleDetailsFormVisibleSelector} from "../../redux/selectors/VehiclePageStateSelectors";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";
import AdditionalDetailsHasAlarm from "./additional-details-has-alarm/AdditionalDetailsHasAlarm";
import AdditionalDetailsAlarmInstaller from "./additional-details-alarm-installer/AdditionalDetailsAlarmInstaller";
import {useFeatureFlags} from "shared-components/dist/feature-flags/hooks/UseFeatureFlags";
import {hasAlarmChanged, isAlarmFittedByChanged} from "../../redux/VehicleDetailsSlice";
import {AlarmInstaller} from "shared/dist/generated/graphql/resolvers-types";
import {VEHICLE_ALARM_INSTALLER_OPTIONS} from "../../../shared/questions/vehicle-alarm/VehicleAlarmInstallerOptions";

const AdditionalVehicleDetailsForm: FC = () => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector(additionalVehicleDetailsFormVisibleSelector);
  const vehicle = useAppSelector(state => state.vehicleDetails.vehicleFromRegLookup);
  const {vehicleAlarmQuestionFlag} = useFeatureFlags();

  useEffect(() => {
    if (isVisible && !vehicleAlarmQuestionFlag) {
      const alarmInstaller = vehicle?.alarmFittedBy?.id ?? AlarmInstaller.Manufacturer;
      dispatch(hasAlarmChanged(vehicle?.hasAlarm ?? true));
      dispatch(isAlarmFittedByChanged({
        id: alarmInstaller,
        description: VEHICLE_ALARM_INSTALLER_OPTIONS[alarmInstaller]
      }));
    }
  }, [isVisible, vehicleAlarmQuestionFlag, vehicle, dispatch]);

  if (!isVisible) return null;

  return (
    <SubQuestionForm>
      <AdditionalDetailsDriverPosition/>
      <AdditionalDetailsSeats/>
      <AdditionalDetailsHasTracker/>
      {vehicleAlarmQuestionFlag &&
        <>
          <AdditionalDetailsHasAlarm/>
          <AdditionalDetailsAlarmInstaller/>
        </>
      }
      <AdditionalDetailsImported/>
      <AdditionalDetailsImportType/>
      <AdditionalDetailsEstimatedValue/>
    </SubQuestionForm>
  );
};

export default AdditionalVehicleDetailsForm;