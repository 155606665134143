import {RootState} from "../../../../../../redux/Store";
import {Vehicle} from "../../../../../../graphql/queries/vehicle-lookup/registration-lookup/model/Vehicle";
import {createSelector, OutputSelector} from "@reduxjs/toolkit";
import {validatedVehicleFromRegLookup} from "./ValidatedVehicleFromRegLookup";
import {validatedVehicleFromManualLookup} from "./ValidatedVehicleFromManualLookup";
import {manualVehicleLookupFormCompleted} from "./ManualVehicleLookupFormSelectors";
import {hasKnownOwnerAndKeeper} from "../../validation/IsOwnerAndRegisteredKeeperValidation";
import {hasValidModifications} from "../../validation/ModificationsValidation";
import {haveYouBoughtYourCarYetQuestionIsValid} from "../../validation/HaveYouBoughtYourCarYetValidation";
import {hasTrackerQuestionHiddenOrAnswered} from "../../validation/hasTrackerQuestionHiddenOrAnswered";

export const validatedVehicleSelector = (state: RootState): Vehicle | undefined => {
  if (state.vehicleDetails.vehicleFromRegLookup) {
    return validatedVehicleFromRegLookup(state);
  } else if (manualVehicleLookupFormCompleted(state)) {
    return validatedVehicleFromManualLookup(state);
  } else {
    return undefined;
  }
};

export const validatedQuestionSet = (state: RootState): boolean => {
  const vehicleDetails = state.vehicleDetails;

  return [
    hasValidModifications,
    haveYouBoughtYourCarYetQuestionIsValid,
    hasKnownOwnerAndKeeper,
    hasTrackerQuestionHiddenOrAnswered
  ].every(validation => validation(vehicleDetails));
};

type SelectorArray = (typeof validatedVehicleSelector | typeof validatedQuestionSet)[]

type YouVehicleFormValidSelector = OutputSelector<SelectorArray,
  boolean,
  (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    vehicle: Vehicle | undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    validatedQuestions: boolean,
  ) => boolean>;

export const yourVehicleFormValid = (): YouVehicleFormValidSelector => createSelector(
  validatedVehicleSelector,
  validatedQuestionSet,
  (vehicle, validatedQuestions) =>
    validatedQuestions && vehicle !== undefined
);
