import React, {FC} from "react";
import Question from "../../Question";
import {Validator} from "shared-components/dist/utils/validation/Validator";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {DateFormField} from "shared-components/dist/models/form-field/variants/DateFormField";
import Datepicker from "shared-components/dist/form/datepicker/Datepicker";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";

interface Props {
  id: string;
  title: TranslationKey;
  formField: DateFormField;
  defaultCalendarValue?: Date;
  onComplete: (date: Date) => void;
  onInvalid: (errorMessage: TranslationKey) => void;
  onClear: () => void;
  validators?: Validator<Date>[];
  revalidate?: boolean;
  onRevalidated?: () => void;
  tooltip?: TooltipProps;
  minDate?: Date;
  maxDate?: Date;
  hideSpacer?: boolean;
}

const DateQuestion: FC<Props> = (
  {
    id,
    title,
    formField,
    defaultCalendarValue,
    onComplete,
    onInvalid,
    onClear,
    validators,
    revalidate,
    onRevalidated,
    tooltip,
    minDate,
    maxDate,
    hideSpacer,
    children
  }
) => (
  <Question
    title={title}
    id={id}
    errorMessage={formField.errorMessage}
    status={formField.status}
    tooltip={tooltip}
    hideSpacer={hideSpacer}
  >
    <>
      <Datepicker
        name={id}
        value={formField.value}
        defaultCalendarValue={defaultCalendarValue}
        onComplete={onComplete}
        onInvalid={onInvalid}
        onClear={onClear}
        validators={validators}
        revalidate={revalidate}
        onRevalidated={onRevalidated}
        maxDate={maxDate}
        minDate={minDate}
      />
      {children}
    </>
  </Question>
);

export default DateQuestion;
