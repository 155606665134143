import React, {FC} from "react";
import {yearOfManufactureSelected} from "../../../../../../../redux/Actions";
import DropdownSubQuestion
  from "../../../../../../../structure/questions/sub-question/variants/dropdown-sub-question/DropdownSubQuestion";
import {yearOfManufactureInvalid} from "../../../redux/ManualVehicleLookupFormSlice";
import {useYearsOfManufacture} from "../../../../../../../graphql/queries/vehicle-lookup/manual-vehicle-lookup/ManualVehicleLookup";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import YearOfManufactureStop from "../../year-of-manufacture-stop/YearOfManufactureStop";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const YourVehicleYearOfManufacture: FC = () => {
  const dispatch = useAppDispatch();
  const makeValue = useAppSelector(state => state.manualVehicleLookupForm.make.value);
  const modelValue = useAppSelector(state => state.manualVehicleLookupForm.model.value);
  const fuelTypeValue = useAppSelector(state => state.manualVehicleLookupForm.fuelType.value);
  const transmissionTypeValue = useAppSelector(state => state.manualVehicleLookupForm.transmissionType.value);
  const yearOfManufacture = useAppSelector(state => state.manualVehicleLookupForm.yearOfManufacture);

  const {error, loading, data} = useYearsOfManufacture(makeValue, modelValue, fuelTypeValue, transmissionTypeValue);

  const fetchError = !!(!loading && error);

  if (fetchError) dispatch(yearOfManufactureInvalid("vehicleQuote.manualVehicleLookup.errors.yearOfManufactureFetchFailed"));

  const tooltip: TooltipProps = {
    title: "vehicleQuote.manualVehicleLookup.yearOfManufacture.tooltip.title",
    description: "vehicleQuote.manualVehicleLookup.yearOfManufacture.tooltip.description"
  };

  return (
    <>
      <DropdownSubQuestion
        title="vehicleQuote.manualVehicleLookup.yearOfManufacture"
        id="manual-vehicle-lookup-year-of-manufacture"
        tooltip={tooltip}
        errorMessage={yearOfManufacture.errorMessage}
        status={loading ? "loading" : yearOfManufacture.status}
        value={yearOfManufacture.value}
        options={data?.vehicle?.vehicleOptions?.yearsOfManufacture ?? []}
        onChange={yearOfManufacture => dispatch(yearOfManufactureSelected(yearOfManufacture))}
        disabled={fetchError}
      />
      <YearOfManufactureStop/>
    </>
  );
};

export default YourVehicleYearOfManufacture;
