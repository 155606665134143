import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../../../../redux/Store";
import {ProposedPolicyRequest, QuoteVehicle} from "shared/dist/generated/graphql/resolvers-types";
import {ProposedFinancedPolicySetupRequest} from "../models/ProposedFinancedPolicySetupRequest";
import {mapVehicleToGraphql} from "../../../../graphql/mutations/quote/get-quotes/mappers/VehicleDetailsMapper";
import {parseDurationAsMonths} from "../../../../utils/service/parsers/DurationToMonthsParser";
import {startOfDay} from "date-fns/fp";
import {fullMonthsBetween, now} from "shared/dist/stdlib/Dates";
import {mapPolicyClaims} from "./mappers/PolicyClaimsMapper";
import {mapPolicyConvictions} from "./mappers/PolicyConvictionsMapper";
import {mapAddressInput} from "../../../../utils/graphql/MapAddressInput";
import {parseMainDriver} from "../../../quote/vehicle/your-details/redux/parsers/ParseMainDriver";
import {mapAdditionalDrivers} from "./mappers/PolicyAdditionalDriversMapper";
import {mapBreakInCoverDeclaration} from "./mappers/BreakInCoverDeclarationMapper";
import {formatIsoDateStringFromIsoDateTime} from "shared/dist/stdlib/DateFormat";
import {CoverDetailsFields} from "../../../quote/vehicle/your-cover/redux/CoverDetailsFields";
import {MonthlyPayment} from "../../monthly/direct-debit-setup/redux/MonthlyPayment";
import {QuoteDetails} from "../../../quote/vehicle/your-quote/redux/QuoteDetails";
import {PersonalDetails} from "../../../quote/vehicle/your-details/redux/PersonalDetails";
import {CLOSE_BROTHERS_INTEREST_RATE} from "shared/dist/constants/CloseBrothersInterestRate";
import {PolicySetupQuoteValues} from "shared/dist/event-models/policy-setup/PolicySetupEvent";

const quoteDetailsSelector = (state: RootState): QuoteDetails => state.quoteDetails;
const coverDetailsSelector = (state: RootState): CoverDetailsFields => state.coverDetails;
const personalDetailsSelector = (state: RootState): PersonalDetails => state.personalDetails;
const vehicleSelector = (state: RootState): QuoteVehicle => mapVehicleToGraphql(state);
const monthlyPaymentSelector = (state: RootState): MonthlyPayment => state.monthlyPayment;

export const selectPolicySetupQuoteValues = createSelector(
  quoteDetailsSelector,
  personalDetailsSelector,
  vehicleSelector,
  (quote,
   proposer,
  ): PolicySetupQuoteValues => {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    return {
      proposerEmail: proposer.proposerEmailAddress.value!,
      proposerPhoneNumber: proposer.phoneNumber.value!,
      quoteReference: quote.quoteReference!,
      quoteSequenceNumber: quote.quoteSequenceNumber!,
    };
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }
);

//TODO: Remove this. We don't send all of this info to the backend anymore.
export const proposedPolicyRequestSelector = createSelector(
  quoteDetailsSelector,
  coverDetailsSelector,
  personalDetailsSelector,
  vehicleSelector,
  (quote, cover, proposer, vehicle): ProposedPolicyRequest => {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    return {
      magicLinkId: quote.magicLinkId!,
      policyExtras: quote.policyExtras.map(extra => ({
        type: extra.id,
        optionType: extra.optionType
      })),
      proposer: {
        email: proposer.proposerEmailAddress.value!,
        phoneNumber: proposer.phoneNumber.value!,
        title: proposer.title.value!.vtCode,
        firstName: proposer.firstName.value!,
        lastName: proposer.surname.value!,
        gender: proposer.gender.value!.id,
        dateOfBirth: formatIsoDateStringFromIsoDateTime(proposer.dateOfBirth.value!),
        maritalStatus: proposer.maritalStatus.value!.id,
        address: mapAddressInput(proposer.address.value!),
        primaryEmployment: {
          status: proposer.primaryEmployment.employmentStatus.value!.vtCode,
          job: proposer.primaryEmployment.job.value?.description,
          industry: proposer.primaryEmployment.industry.value?.description,
        },
        secondaryEmployment: !proposer.hasSecondaryEmployment.value ? undefined : {
          status: proposer.secondaryEmployment.employmentStatus.value!.vtCode,
          job: proposer.secondaryEmployment.job.value?.description,
          industry: proposer.secondaryEmployment.industry.value?.description,
        },
        ukResidencyDurationMonths: proposer.ukResidencyDuration.isActive
          ? parseDurationAsMonths(proposer.ukResidencyDuration.value)
          : fullMonthsBetween(new Date(proposer.dateOfBirth.value!), startOfDay(now())),
        licence: {
          type: proposer.licenceType.value!.id,
          countryOfIssue: proposer.licenceCountryOfIssue.value!.id,
          durationMonths: parseDurationAsMonths(proposer.licenceDuration.value!)
        },
        classOfUse: proposer.classOfUse.value!.id,
        isMainDriver: parseMainDriver(proposer),
        claims: mapPolicyClaims(proposer.claims.value.claims),
        convictions: mapPolicyConvictions(proposer.convictions.value.convictions),
        medicalConditionsDeclared: proposer.hasMedicalCondition.value!,
        medicalCondition: proposer.medicalCondition.value?.id,
        mileage: cover.averageMileage.value,
        businessMileage: cover.averageBusinessMileage.value,
        isHomeowner: proposer.isHomeowner.value,
        noClaimsBonusYears: cover.yearsNoClaimsBonus.value ?? "0",
      },
      coverDetails: {
        coverType: cover.coverType.value!.description,
        voluntaryExcess: parseInt(cover.voluntaryExcess.value!),
        noClaimsProtection: cover.protectNoClaimsBonus.value ?? false,
        breakInCoverDeclaration: mapBreakInCoverDeclaration(cover.breakInCoverDeclarationAcceptance.value),
      },
      vehicleDetails: {
        registeredVehicle: {
          registration: vehicle.registration!,
          make: vehicle.make,
          model: vehicle.model,
          trim: vehicle.trim,
          bodyStyle: vehicle.bodyStyle,
          engineCapacityCc: vehicle.engineCapacityCc,
          engineCapacityLitres: vehicle?.engineCapacityLitres,
          fuelType: vehicle.fuelType,
          transmissionType: vehicle.transmissionType,
          yearOfManufacture: vehicle.yearOfManufacture,
          commercialVehicle: vehicle.commercialVehicle,
          estimatedValue: vehicle.estimatedValue,
          doors: vehicle.doors,
          seats: vehicle.seats,
          driverPosition: vehicle.driverPosition,
          imported: vehicle.imported,
          importType: vehicle.importType
        },
        hasTrackingDeviceInstalled: vehicle.hasTracker,
        keeper: vehicle.keeper,
        owner: vehicle.owner,
        modifications: vehicle.modifications,
        dateOfPurchase: new Date(vehicle.dateOfPurchase!.split("/").reverse().join("-")).toISOString(),
        storedAtNight: proposer.storedAtNight.value!.id
      },
      additionalDrivers: mapAdditionalDrivers(proposer.additionalDrivers, proposer.mainDriver.value),
      quoteReference: quote.quoteReference!,
      quoteSequenceNumber: quote.quoteSequenceNumber!,
      insurerScheme: quote.quoteInsurerSchemeReference!
    };
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }
);

export const selectProposedFinancedPolicyRequestWithPrice = createSelector(
  quoteDetailsSelector,
  monthlyPaymentSelector,
  selectPolicySetupQuoteValues,
  (
    quote,
    monthlyPayment,
    quoteValues,
  ): ProposedFinancedPolicySetupRequest => {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    const selectedLoan = quote.selectedLoanWithPrice;

    return {
      depositPercentage: quote.depositPercentage,
      installments: selectedLoan!.numberOfInstalments,
      installmentAmount: parseFloat(selectedLoan!.subsequentInstalmentAmounts.amount),
      loanSetUp: {
        rateReference: selectedLoan!.rateReference,
        brokerLoanReference: `${quote.quoteReference}-${quote.quoteSequenceNumber}`,
        bankSortCode: monthlyPayment.sortCode.value!,
        bankAccountNumber: monthlyPayment.accountNumber.value!
      },
      quoteValues,
      financeInformation:
        {
          pricePerMonth: selectedLoan!.subsequentInstalmentAmounts.amount,
          premium: quote.premiumAsPrice!.amount,
          adminFee: quote.premiumPriceBreakdownDetails?.adminFee.amount ?? "0.00",
          financeFee: selectedLoan!.financeFee.amount,
          deposit: selectedLoan!.firstInstalmentAmount.amount,
          totalFinance: selectedLoan!.totalFinance.amount,
          interestRate: `${CLOSE_BROTHERS_INTEREST_RATE}`,
          annualPercentageRate: `${selectedLoan!.apr}`,
          numberOfInstalments: selectedLoan!.numberOfInstalments
        }
    };
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }
);
