import React, {FC, useState} from "react";
import "./FooterButtonGroup.css";
import Button from "shared-components/dist/buttons/button/Button";
import {ArrowBack, ArrowForward} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {useNavigateAfterValidation} from "../../hooks/NavigateAfterValidation";
import {AppThunk} from "../../../../../../redux/Store";

interface Props {
  forwardButtonLabel: TranslationKey;
  nextRoute?: string;
  previousRoute?: string;
  onSubmit?: () => void;
  onAsyncSubmit?: () => Promise<void> | Promise<AppThunk>;
  isFormValid?: boolean;
  onGoBackClicked?: () => void;
  isFinalFlowRoute?: boolean;
  afterForwardNavigation?: () => void;
}

const FooterButtonGroup: FC<Props> = ({
  forwardButtonLabel,
  nextRoute,
  previousRoute,
  onSubmit,
  onAsyncSubmit,
  isFormValid,
  onGoBackClicked,
  afterForwardNavigation,
  isFinalFlowRoute = false
}) => {
  const navigate = useNavigate();
  const {navigateWhenValid} = useNavigateAfterValidation(nextRoute, isFormValid, afterForwardNavigation);
  const [validationInProgress, setValidationInProgress] = useState(false);

  const stepForward = async (): Promise<void> => {
    setValidationInProgress(true);
    await onAsyncSubmit?.();
    onSubmit?.();
    setValidationInProgress(false);

    navigateWhenValid();
  };

  const stepBackward = (): void => {
    onGoBackClicked?.();

    if (previousRoute) {
      navigate(previousRoute);
    }
  };

  const shouldShowBackButton = (): boolean => previousRoute !== undefined;

  return (
    <div className="footer-button-group">
      <div className="footer-button-group__container">
        <div className="footer-button-group__container__buttons">
          {!isFinalFlowRoute &&
            <>
              <div className="footer-button-group__container__buttons--left">
                {shouldShowBackButton() &&
                  <Button
                    variant="tertiary"
                    onClick={stepBackward}
                    leftIcon={<ArrowBack/>}
                  />
                }
              </div>

              <div className="footer-button-group__container__buttons--right">
                <Button
                  id="footer-forward-button"
                  variant="secondary"
                  onClick={stepForward}
                  rightIcon={<ArrowForward/>}
                  loading={validationInProgress}
                  expanded
                >
                  {lookupI18nString(forwardButtonLabel)}
                </Button>
              </div>
            </>
          }

          {isFinalFlowRoute &&
            <div className="footer-button-group__container__buttons--full">
              <Button
                id="footer-forward-button"
                variant="secondary"
                onClick={stepForward}
                loading={validationInProgress}
                expanded
              >
                {lookupI18nString(forwardButtonLabel)}
              </Button>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default FooterButtonGroup;
