import React, {FC} from "react";
import "./PaymentSuccessConfetti.css";
import {Confetti} from "./components/confetti/Confetti";
import PaymentSuccessHeader from "./components/payment-success-header/PaymentSuccessHeader";
import PaymentSuccessBody from "./components/payment-success-body/PaymentSuccessBody";
import {PaymentFlowType} from "../shared/models/PaymentFlowType";

interface Props {
  paymentFlowType: PaymentFlowType;
}

/**
 * Do not call this page directly on the react router to ensure back button is disabled
 */
const PaymentSuccessConfetti: FC<Props> = ({paymentFlowType}) => (
  <div className="payment-success">
    <Confetti/>
    <PaymentSuccessHeader/>
    <PaymentSuccessBody paymentFlowType={paymentFlowType}/>
  </div>
);

export default PaymentSuccessConfetti;
