import {quoteDocumentsQuery} from "../../../../../../../../graphql/queries/quote/documents/Documents";
import {useEffect, useState} from "react";
import {QuoteDocuments} from "shared/dist/generated/graphql/resolvers-types";
import {logger} from "../../../../../../../../utils/logging/Logger";

export interface UseQuoteDocumentsProps {
  quoteReference: string | undefined;
  schemeReference: string | undefined;
  coverType: string | undefined;
  policyStartDate: string | undefined;
}

export function useQuoteDocuments(queryArguments: UseQuoteDocumentsProps): QuoteDocuments | undefined {
  const [quoteDocuments, setQuoteDocuments] = useState<QuoteDocuments | undefined>(undefined);

  useEffect(() => {
      if (!queryArguments.quoteReference ||
        !queryArguments.schemeReference ||
        !queryArguments.policyStartDate ||
        !queryArguments.coverType) return;

      quoteDocumentsQuery({
        quoteReference: queryArguments.quoteReference,
        schemeReference: queryArguments.schemeReference,
        coverType: queryArguments.coverType,
        effectiveDate: queryArguments.policyStartDate
      })
        .then(setQuoteDocuments)
        .catch(error => {
          logger.error("Quote documents failed to load", error);
          setQuoteDocuments(undefined);
        });
    }, [
      queryArguments.quoteReference,
      queryArguments.schemeReference,
      queryArguments.coverType,
      queryArguments.policyStartDate
    ]
  );

  return quoteDocuments;
}