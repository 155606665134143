import React, {FC} from "react";
import Question from "../../../../../../structure/questions/question/Question";
import PhoneNumberInput, {PhoneCountryCodes} from "shared-components/dist/form/phone-number-input/PhoneNumberInput";
import {FormField} from "shared-components/dist/models/form-field/FormField";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {removeAll} from "shared-components/dist/utils/stdlib/StringUtils";
import {WHITESPACE} from "shared-components/dist/utils/constants/Regex";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import {parsePhoneNumber} from "react-phone-number-input";

interface OwnProps {
  id: string;
  title: TranslationKey;
  tooltip?: TooltipProps;
  phoneNumberField: FormField<string>;
  onComplete: (value: string) => void;
  onInvalid: () => void;
  onCountryCodeChange: () => void;
  placeholder?: TranslationKey;
  disabled?: boolean;
}

const PhoneNumberQuestion: FC<OwnProps> = (
  {
    id,
    title,
    tooltip,
    phoneNumberField,
    onComplete,
    onInvalid,
    placeholder,
    disabled,
    onCountryCodeChange
  }
) => {
  const onInputComplete = (value: string): void => {
    onComplete(removeAll(value, WHITESPACE));
  };

  const getDefaultCountryFromFormField = (): PhoneCountryCodes | undefined => {
    if (phoneNumberField.value === undefined) return undefined;

    const parsedPhoneNumber = parsePhoneNumber(phoneNumberField.value);
    return parsedPhoneNumber?.country as PhoneCountryCodes ?? undefined;
  };

  return (
    <Question
      id={id}
      title={title}
      status={phoneNumberField.status}
      errorMessage={phoneNumberField.errorMessage}
      tooltip={tooltip}
    >
      <PhoneNumberInput
        name="phoneNumber"
        value={phoneNumberField.value}
        onComplete={onInputComplete}
        onInvalid={onInvalid}
        placeholder={placeholder}
        disabled={disabled}
        onCountryCodeChange={onCountryCodeChange}
        defaultCountry={getDefaultCountryFromFormField()}
        invalidateIfFieldOnlyContainsCountryCode
      />
    </Question>
  );
};

export default PhoneNumberQuestion;
