export function buildApplePayPaymentRequest(amount: string): ApplePayJS.ApplePayPaymentRequest {
  return {
    countryCode: "GB",
    currencyCode: "GBP",
    merchantCapabilities: [
      "supports3DS"
    ],
    supportedNetworks: [
      "visa",
      "masterCard",
    ],
    total: {
      label: `Stroll Insurance Services Ltd.${window.Stroll.IS_PRODUCTION ? "" : " (TEST)"}`,
      type: "final",
      amount
    }
  };
}