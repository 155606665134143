import React, {FC} from "react";
import QuoteJourneyStop from "../../../../../shared/stops/quote-journey-stop/QuoteJourneyStop";

interface Props {
  isVisible: boolean;
}

const OtherModificationStop: FC<Props> = ({isVisible}) => (
  <QuoteJourneyStop
    stopProps={{
      visible: isVisible,
      title: "vehicleQuote.modifications.selection.options.other.stop.title",
      description: "quoteJourney.shared.softStop.description"
    }}
    isSubContainer
  />
);

export default OtherModificationStop;