import React, {FC} from "react";
import NonEuImportTypeStop from "../../../../shared/stops/import-type-stops/NonEuImportTypeStop";
import {shouldShowAssumptionsNonEuImportVehicleStopSelector} from "../../../redux/selectors/VehiclePageStateSelectors";
import {useAppSelector} from "../../../../../../../redux/Hooks";

const AssumptionsNonEuImportVehicleStop: FC = () => {
  const isVisible = useAppSelector(shouldShowAssumptionsNonEuImportVehicleStopSelector);

  return (
    <NonEuImportTypeStop
      isVisible={isVisible}
      isSubContainer={false}
    />
  );
};

export default AssumptionsNonEuImportVehicleStop;
