import {FC} from "react";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {GetApp} from "@mui/icons-material";
import {PolicyDocument} from "shared/dist/generated/graphql/resolvers-types";
import {mapDocumentTypeToTitle} from "../current-document/helpers/MapDocumentTypeToTitle";
import {formatDateStringToDocumentDateTimeFormat} from "shared/dist/stdlib/DateFormat";
import {calculateDocumentFileSize} from "shared-components/dist/utils/helpers/display/DocumentFileSizeConverter";
import {ReactComponent as PdfFileIcon} from "../../../../../../../assets/icons/documents/pdf-file-icon.svg";
import {mapDocumentTypeToDocumentDescription} from "../current-document/helpers/MapDocumentTypeToDocumentDescription";
import "./CurrentPolicyDocument.css";
import {logPortalGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/PortalAnalytics";
import {mapDocumentTypeToGoogleAnalyticsEventSuffix} from "../current-document/helpers/MapDocumentTypeToGoogleAnalyticsEventSuffix";

interface Props {
  policyDocument: PolicyDocument;
}

const CurrentPolicyDocument: FC<Props> = ({policyDocument}) => {
  const onActivePolicyDocumentClick = (document: PolicyDocument): void => {
    window.open(document.url);
    logPortalGoogleAnalyticsEvent({
      categorySuffix: mapDocumentTypeToGoogleAnalyticsEventSuffix(document.type),
      action: "Click"
    });
  };

  return (
    <div className="current-policy-document" onClick={() => onActivePolicyDocumentClick(policyDocument)}>
      <div className="current-policy-document__container">
        <div className="current-policy-document__container__content">
          <GetApp/>

          <div className="current-policy-document__container__content__text">
            <span>{lookupI18nString(mapDocumentTypeToTitle(policyDocument.type))}</span>
            <p>{lookupI18nString(mapDocumentTypeToDocumentDescription(policyDocument.type))}</p>
          </div>
        </div>

        <div className="current-policy-document__container__meta-data">
          <div className="current-policy-document__container__meta-data__details">
            {policyDocument.effectiveDate &&
            <div className="current-policy-document__container__meta-data__details__date-time">
              {formatDateStringToDocumentDateTimeFormat(policyDocument.effectiveDate)}
            </div>
            }

            <div className="current-policy-document__container__meta-data__details__file-size">
              {policyDocument.fileSize && <p>{calculateDocumentFileSize(policyDocument.fileSize)}</p>}

              <PdfFileIcon/>
            </div>
          </div>
        </div>
      </div>
      <hr/>
    </div>
  );
};

export default CurrentPolicyDocument;
