import React, {FC} from "react";
import QuoteHeader from "../../../quote/vehicle/shared/quote-step/header/QuoteHeader";
import FullPageSpinner from "shared-components/dist/spinner/full-page-spinner/FullPageSpinner";

const PaymentInProgress: FC = () => (
  <>
    <QuoteHeader showButtons={false}/>
    <FullPageSpinner message="paymentFlow.inProgress"/>
  </>
);

export default PaymentInProgress;