import React, {FC} from "react";
import {GOOGLE_PLAY_STORE_URL} from "shared/dist/constants/Urls";
import AppStoreButton from "./AppStoreButton";
import {ReactComponent as GooglePlayStoreLogoSmall} from "../../../assets/icons/logos/app-stores/play-store-small.svg";

interface Props {
  onClick?: () => void;
}

const GooglePlayStoreButtonSmall: FC<Props> = ({onClick}) => (
  <AppStoreButton
    onClick={onClick}
    icon={<GooglePlayStoreLogoSmall/>}
    url={GOOGLE_PLAY_STORE_URL}
  />
);

export default GooglePlayStoreButtonSmall;