import {DocumentType} from "shared/dist/generated/graphql/resolvers-types";
import {TranslationKey} from "shared-components/src/translations/TranslationKey";

export function mapDocumentTypeToTitle(documentType: DocumentType): TranslationKey {
  switch (documentType) {
    case DocumentType.CertificateAndScheduleOfInsurance:
      return "policies.documents.type.certificateAndScheduleOfInsurance.title";
    case DocumentType.StatementOfFact:
      return "policies.documents.type.statementOfFact.title";
    case DocumentType.NoClaimsBonusProtection:
      return "policies.documents.type.noClaimsBonusProtection.title";
    case DocumentType.InsuranceProductInformation:
      return "policies.documents.type.insuranceProductInformation.title";
    case DocumentType.TermsOfBusinessAgreement:
      return "policies.documents.type.termsOfBusinessAgreement.title";
    case DocumentType.PolicyBooklet:
      return "policies.documents.type.policyWordingBooklet.title";
    case DocumentType.SmartfobKeycare:
      return "policies.documents.type.smartfobKeycare.title";
    case DocumentType.RoadsideAssistancePlatinum:
      return "policies.documents.type.roadsideAssistancePlatinum.title";
    case DocumentType.LegalExpenses:
      return "policies.documents.type.legalExpenses.title";
    case DocumentType.ExcessInsurance:
      return "policies.documents.type.excessInsurance.title";
    case DocumentType.ExcessInsuranceScheduleOfInsurance:
      return "policies.documents.type.excessInsuranceSchedule.title";
    case DocumentType.CourtesyCar:
      return "policies.documents.type.courtesyCar.title";
    case DocumentType.PolicySummary:
      return "policies.documents.type.policySummary.title";
    default:
      throw Error(`No translation key found for ${documentType}`);
  }
}
