import {RootState} from "../../../../../../redux/Store";
import {createSelector} from "@reduxjs/toolkit";
import {MedicalConditionOption} from "../../../shared/questions/medical-condition/MedicalConditionOption";
import {MedicalCondition} from "shared/dist/generated/graphql/resolvers-types";

const medicalConditionSelector = (state: RootState): MedicalConditionOption | undefined => state.personalDetails.medicalCondition.value;

export const medicalConditionStopVisibleSelector = createSelector(
  medicalConditionSelector,
  (medicalCondition: MedicalConditionOption | undefined): boolean =>
    medicalCondition !== undefined && medicalCondition.id === MedicalCondition.DvlaUnaware
);