import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Vehicle} from "../../../../../graphql/queries/vehicle-lookup/registration-lookup/model/Vehicle";
import {
  engineCapacityLitresSelected,
  fuelTypeSelected,
  fullSpecSelected,
  makeSelected,
  modelSelected,
  policyPurchased,
  quoteReset,
  storedQuoteLoaded,
  transmissionTypeSelected,
  trimLevelSelected,
  vehicleDetailsReset,
  vehicleLookupReceived,
  yearOfManufactureSelected
} from "../../../../../redux/Actions";
import {
  conditionalFieldReset,
  fieldChanged,
  fieldInvalid,
  fieldReset
} from "shared-components/dist/models/form-field/FormField";
import {Status} from "shared-components/dist/status-indicator/Status";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {Modification} from "../components/modifications/model/Modification";
import {LoadedQuote} from "../../your-quote/models/LoadedQuote";
import {VehicleHolderOption} from "../../../../../utils/constants/VehicleHolderOption";
import {VehicleDetails} from "./VehicleDetails";
import {
  additionalVehicleDetailsFormQuestionsInitialState,
  additionalVehicleDetailsFormQuestionsReducers
} from "./AdditionalVehicleDetailsFormQuestionsReducer";
import {AlarmInstaller} from "shared/dist/generated/graphql/resolvers-types";
import {VEHICLE_ALARM_INSTALLER_OPTIONS} from "../../shared/questions/vehicle-alarm/VehicleAlarmInstallerOptions";

export const initialState: VehicleDetails = {
  registrationNumberLookup: {
    status: "default",
    value: undefined
  },
  vehicleFromRegLookup: undefined,
  assumptionsCorrect: {
    status: "default",
    value: undefined
  },
  additionalVehicleDetails: additionalVehicleDetailsFormQuestionsInitialState,
  hasTracker: {
    status: "default",
    value: undefined
  },
  modifications: {
    status: "default",
    value: {
      hasModifications: undefined,
      modifications: [],
      shouldFlagUnansweredQuestions: false,
      isFormVisible: false
    }
  },
  isCurrentOwner: {
    status: "default",
    value: undefined
  },
  dateOfPurchase: {
    status: "default",
    value: undefined
  },
  isOwnerAndRegisteredKeeper: {
    status: "default",
    value: undefined
  },
  owner: {
    status: "default",
    value: undefined,
    isActive: false
  },
  registeredKeeper: {
    status: "default",
    value: undefined,
    isActive: false
  }
};

const vehicleDetailsSlice = createSlice({
  name: "vehicleQuote",
  initialState,
  reducers: {
    registrationNumberLookupChanged(state, action: PayloadAction<string>): void {
      state.registrationNumberLookup.value = action.payload;
    },
    registrationNumberLookupStatusChanged(state, action: PayloadAction<Status>): void {
      state.registrationNumberLookup.status = action.payload;
    },
    registrationNumberLookupInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.registrationNumberLookup, action.payload);
    },
    assumptionsCorrectChanged(state, action: PayloadAction<boolean>): void {
      fieldChanged(state.assumptionsCorrect, action.payload);
      state.additionalVehicleDetails = additionalVehicleDetailsFormQuestionsInitialState;
    },
    assumptionsCorrectReset(state): void {
      fieldReset(state.assumptionsCorrect);
    },
    assumptionsCorrectInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.assumptionsCorrect, action.payload);
    },
    hasModificationsChanged(state, action: PayloadAction<boolean>): void {
      state.modifications.value.hasModifications = action.payload;
      state.modifications.value.modifications = [];
      state.modifications.errorMessage = undefined;
      state.modifications.status = "success";
    },
    modificationsInvalid(state, action: PayloadAction<TranslationKey>): void {
      state.modifications.status = "error";
      state.modifications.errorMessage = action.payload;
    },
    flagUnansweredModificationsSubQuestions(state): void {
      state.modifications.value.shouldFlagUnansweredQuestions = true;
    },
    isFormVisibleSet(state, action: PayloadAction<boolean>): void {
      state.modifications.value.isFormVisible = action.payload;
      state.modifications.value.shouldFlagUnansweredQuestions = false;
    },
    modificationsChanged(state, action: PayloadAction<Modification[]>): void {
      state.modifications.value.modifications = action.payload;
      state.modifications.status = "success";
    },
    modificationsStatusChanged(state, action: PayloadAction<Status>): void {
      state.modifications.status = action.payload;
    },
    hasTrackerChanged(state, action: PayloadAction<boolean>): void {
      fieldChanged(state.hasTracker, action.payload);
    },
    hasTrackerInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.hasTracker, action.payload);
    },
    isCurrentOwnerChanged(state, action: PayloadAction<boolean>): void {
      fieldChanged(state.isCurrentOwner, action.payload);
      state.dateOfPurchase = initialState.dateOfPurchase;
    },
    isCurrentOwnerInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.isCurrentOwner, action.payload);
    },
    dateOfPurchaseChanged(state, action: PayloadAction<string>): void {
      fieldChanged(state.dateOfPurchase, action.payload);
    },
    dateOfPurchaseReset(state): void {
      fieldReset(state.dateOfPurchase);
    },
    dateOfPurchaseInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.dateOfPurchase, action.payload);
    },
    isOwnerAndRegisteredKeeperChanged(state, action: PayloadAction<boolean>): void {
      fieldChanged(state.isOwnerAndRegisteredKeeper, action.payload);

      if (action.payload) {
        conditionalFieldReset(state.owner);
        conditionalFieldReset(state.registeredKeeper);
      } else {
        state.owner.isActive = true;
        state.registeredKeeper.isActive = true;
      }
    },
    isOwnerAndRegisteredKeeperInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.isOwnerAndRegisteredKeeper, action.payload);
    },
    ownerChanged(state, action: PayloadAction<VehicleHolderOption>): void {
      fieldChanged(state.owner, action.payload);
    },
    ownerInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.owner, action.payload);
    },
    registeredKeeperChanged(state, action: PayloadAction<VehicleHolderOption>): void {
      fieldChanged(state.registeredKeeper, action.payload);
    },
    registeredKeeperInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.registeredKeeper, action.payload);
    },
    aggregatorQuoteVehicleRegistrationChanged(state, action: PayloadAction<string>): void {
      if (state.vehicleFromRegLookup) {
        state.vehicleFromRegLookup.registration = action.payload;
      }
    },
    ...additionalVehicleDetailsFormQuestionsReducers
  },
  extraReducers: {
    [vehicleLookupReceived.type]: (state, action: PayloadAction<Vehicle>): VehicleDetails => {
      return {
        ...initialState,
        registrationNumberLookup: {
          ...initialState.registrationNumberLookup,
          value: state.registrationNumberLookup.value
        },
        vehicleFromRegLookup: action.payload
      };
    },
    [quoteReset.type]: () => initialState,
    [vehicleDetailsReset.type]: () => initialState,
    [makeSelected.type]: () => initialState,
    [modelSelected.type]: () => initialState,
    [fuelTypeSelected.type]: () => initialState,
    [transmissionTypeSelected.type]: () => initialState,
    [yearOfManufactureSelected.type]: () => initialState,
    [engineCapacityLitresSelected.type]: () => initialState,
    [trimLevelSelected.type]: () => initialState,
    [fullSpecSelected.type]: () => initialState,
    [policyPurchased.type]: () => initialState,
    [vehicleLookupReceived.type]: (state, action: PayloadAction<Vehicle>): VehicleDetails => {
      return {
        ...initialState,
        vehicleFromRegLookup: action.payload
      };
    },
    [storedQuoteLoaded.type]: (state, action: PayloadAction<LoadedQuote>) => {
      const vehicleDetails = action.payload.quoteArguments.vehicleDetails;
      if (vehicleDetails.vehicleFromRegLookup && vehicleDetails.vehicleFromRegLookup?.hasAlarm === undefined) {
        return {
          ...initialState,
          ...vehicleDetails,
          vehicleFromRegLookup: {
            ...vehicleDetails.vehicleFromRegLookup,
            hasAlarm: true,
            alarmFittedBy: {
              id: AlarmInstaller.Manufacturer,
              description: VEHICLE_ALARM_INSTALLER_OPTIONS[AlarmInstaller.Manufacturer]
            }
          }
        };
      }

      return {
        ...initialState,
        ...action.payload.quoteArguments.vehicleDetails
      };
    }
  }
});

export const {
  registrationNumberLookupChanged,
  registrationNumberLookupStatusChanged,
  registrationNumberLookupInvalid,
  assumptionsCorrectChanged,
  assumptionsCorrectReset,
  assumptionsCorrectInvalid,
  hasModificationsChanged,
  modificationsInvalid,
  modificationsChanged,
  modificationsStatusChanged,
  flagUnansweredModificationsSubQuestions,
  isFormVisibleSet,
  hasTrackerChanged,
  hasTrackerInvalid,
  isCurrentOwnerChanged,
  isCurrentOwnerInvalid,
  dateOfPurchaseChanged,
  dateOfPurchaseReset,
  dateOfPurchaseInvalid,
  isOwnerAndRegisteredKeeperChanged,
  isOwnerAndRegisteredKeeperInvalid,
  ownerChanged,
  ownerInvalid,
  registeredKeeperChanged,
  registeredKeeperInvalid,
  driverPositionChanged,
  driverPositionInvalid,
  seatsChanged,
  seatsInvalid,
  hasAlarmChanged,
  hasAlarmInvalid,
  isAlarmFittedByChanged,
  isAlarmFittedByInvalid,
  additionalDetailsHasTrackerChanged,
  additionalDetailsHasTrackerInvalid,
  isImportedChanged,
  isImportedInvalid,
  importTypeChanged,
  importTypeInvalid,
  estimatedValueChanged,
  estimatedValueInvalid,
  aggregatorQuoteVehicleRegistrationChanged
} = vehicleDetailsSlice.actions;

export const vehicleDetailsReducer = vehicleDetailsSlice.reducer;

export const vehicleDetailsSliceName = vehicleDetailsSlice.name;
