import React, {FC} from "react";
import CurrentDocuments from "./current-documents/CurrentDocuments";
import Rag from "shared-components/dist/information/rag/Rag";
import "./PolicyViewDocuments.css";
import {useNavigate} from "react-router-dom";
import {usePolicyQueryParams} from "../../../../../router/params/Params";
import ArchivedDocuments from "./archived-documents/ArchivedDocuments";
import {Policy} from "shared/dist/generated/graphql/resolvers-types";
import PolicyErrorPage from "../../../policy-error/PolicyErrorPage";
import {fetchPolicyFor} from "shared/dist/graphql/queries/portal/PortalDashboardQuery";
import PoliciesHeader from "../../header/PoliciesHeader";
import PoliciesSidebar from "../../sidebar/PoliciesSidebar";
import {policyViewSidebarMenuItems} from "../../../../../structure/navigation/navbar/menu/MenuItems";
import CustomerPortalContainerWithSidebar from "../../../shared/container/CustomerPortalContainerWithSidebar";
import PolicyLoading from "../../loading/PolicyLoading";
import {isAfter} from "date-fns";
import {now} from "shared/dist/stdlib/Dates";
import {endDateIsWithinRenewalDueRange} from "../../../../../utils/validation/RenewalDueValidator";

const PolicyViewDocuments: FC = () => {
  const navigate = useNavigate();
  const {policyId} = usePolicyQueryParams();
  const {loading, error, data} = fetchPolicyFor(policyId);

  if (loading) return <PolicyLoading/>;

  if (error) return <PolicyErrorPage/>;

  if (!data || !data.policy) return <PolicyErrorPage/>;

  function shouldShowUpcomingDocumentsMessage(policy: Policy): boolean {
    if (policyIsUpcoming(policy)) return false;
    if (!policy.documentation) return false;
    if (!policy.documentation.upcoming) return false;
    return policy.documentation.upcoming.length > 0;
  }

  const hasRenewalsTab = endDateIsWithinRenewalDueRange(data.policy.metadata.coverEndDate);

  function policyIsUpcoming(policy: Policy): boolean {
    return isAfter(new Date(policy.coverDetails.startDate), now());
  }

  return (
    <CustomerPortalContainerWithSidebar
      header={
        <PoliciesHeader
          policy={data.policy}
          pageSubHeader="policies.header.subHeader.coverSummary"/>
      }
      mainContent={
        <>
          {shouldShowUpcomingDocumentsMessage(data.policy) &&
            <Rag
              className="policy-documents__rag"
              title="policies.upcomingDocuments.card.title"
              description="policies.upcomingDocuments.card.description"
              anchorText="policies.upcomingDocuments.card.button"
              onAnchorClick={() => navigate(`/portal/policy/${data.policy.id}/documents/upcoming`)}
            />
          }

          <CurrentDocuments policy={data.policy}/>
          <ArchivedDocuments policy={data.policy}/>
        </>
      }
      sidebar={<PoliciesSidebar menuItems={policyViewSidebarMenuItems(policyId, hasRenewalsTab)}/>}
    />
  );
};

export default PolicyViewDocuments;

