import {claimsInvalid, flagUnansweredClaimsSubQuestions} from "../redux/PersonalDetailsSlice";
import {AppThunkDispatch} from "../../../../../redux/Store";
import {RequiredFormField} from "shared-components/dist/models/form-field/FormField";
import {SerializableClaims} from "../../shared/questions/claims/models/Claims";
import {PersonalDetails} from "../redux/PersonalDetails";

export function flagUnansweredClaimsQuestions({claims}: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (hasClaimsUnanswered(claims) || claims.value.isFormVisible) {
    dispatch(claimsInvalid("quote.errors.questionIncomplete"));
  }
  if (claims.value.isFormVisible) dispatch(flagUnansweredClaimsSubQuestions());
}

const hasClaimsUnanswered = (claims: RequiredFormField<SerializableClaims>): boolean => {
  return claims.value.hasClaims === undefined && claims.status === "default";
};
