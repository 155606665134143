import React, {FC, useState} from "react";
import DisplayAccountSetting from "../../account-setting/display-account-setting/DisplayAccountSetting";
import EditAccountSetting from "../../account-setting/edit-account-setting/EditAccountSetting";
import {EditAccountSettingState} from "../../account-setting/edit-account-setting/state/EditAccountSettingState";
import {isValidEmailAddress} from "shared-components/dist/utils/validation/EmailAddresses";
import {
  getConfirmationCodeInputErrorMessage,
  getSendCodeButtonState,
  getSendCodeInputErrorMessage,
  getVerifyButtonState,
  isConfirmationCodeEnteredInvalid,
  isEditAccountSettingVisible,
  isEditAccountValueInvalid,
  isEditingAccountSetting,
  onAccountSettingInputChange,
  onEditAccountSettingToggled,
  onInvalidAccountSetting,
  onResendConfirmationCode,
  onSendConfirmationCode,
  onValidAccountSetting,
  onVerifyAccountSetting,
  shouldDisplayConfirmationCodeInput
} from "../../account-setting/edit-account-setting/state/EditAccountSettingStateHandlers";
import {
  sendEmailVerificationCode,
  updateEmailAddress,
  verifyEmailAddress
} from "../../../../../../user/attributes/services/email/UpdateEmailService";
import EmailAddressInput from "shared-components/dist/form/email-address-input/EmailAddressInput";
import {EditAccountSettingError} from "../../account-setting/edit-account-setting/state/EditAccountSettingError";
import {logPortalGoogleAnalyticsEvent} from "../../../../../../utils/analytics/PortalAnalytics";

interface Props {
  email: string;
  isEmailUnverified: boolean;
  onEmailUpdated: () => void;
  onEmailVerified: () => void;
}

const EmailAccountSetting: FC<Props> = (
  {
    email,
    isEmailUnverified,
    onEmailUpdated,
    onEmailVerified
  }
) => {
  const [updatedEmail, setUpdatedEmail] = useState(isEmailUnverified ? email : "");
  const [state, setState] = useState<EditAccountSettingState>(isEmailUnverified ? "CodeSent" : "Closed");
  const [error, setError] = useState<EditAccountSettingError>("None");

  return (
    <>
      <DisplayAccountSetting
        name="email"
        headerText="accountSettings.header.emailAddress"
        value={email}
        onEditToggled={() => {
          onEditAccountSettingToggled(setState, setError, state);
          logPortalGoogleAnalyticsEvent({
            categorySuffix: "account.edit.email.section",
            action: "Click"
          });
        }}
        isEditingAccountSetting={isEditingAccountSetting(state)}
      />
      <EditAccountSetting
        accountSettingInputComponent={
          <EmailAddressInput
            name="edit-email"
            onComplete={() => onValidAccountSetting(setState, setError)}
            onInvalid={() => onInvalidAccountSetting(setState, setError, setUpdatedEmail)}
            onChange={(inputValue) => onAccountSettingInputChange(
              setState,
              setError,
              setUpdatedEmail,
              inputValue,
              isValidEmailAddress
            )}
          />
        }
        isVisible={isEditAccountSettingVisible(state)}
        sendCodeButtonState={getSendCodeButtonState(state)}
        showSendCodeInputErrorMessage={isEditAccountValueInvalid(state, error)}
        sendCodeInputErrorMessage={getSendCodeInputErrorMessage(error, "accountSettings.form.invalidEmail")}
        onSendConfirmationCode={() => onSendConfirmationCode(
          isValidEmailAddress,
          setState,
          setError,
          updatedEmail,
          updateEmailAddress,
          onEmailUpdated
        )}
        onResendConfirmationCode={() => onResendConfirmationCode(setState, setError, sendEmailVerificationCode)}
        shouldShowConfirmationCodeInput={shouldDisplayConfirmationCodeInput(state, error)}
        verifyButtonState={getVerifyButtonState(state)}
        codeSentDestination={updatedEmail}
        showConfirmationCodeInputErrorMessage={isConfirmationCodeEnteredInvalid(error)}
        confirmationCodeInputErrorMessage={getConfirmationCodeInputErrorMessage(error)}
        onVerifyAccountSetting={(verificationCode: string) => onVerifyAccountSetting(
          setState,
          setError,
          verifyEmailAddress,
          verificationCode,
          onEmailVerified
        )}
      />
    </>
  );
};

export default EmailAccountSetting;
