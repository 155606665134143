import {QueryResult, useQuery} from "@apollo/client";
import {VehicleOptionsQueryResult} from "../registration-lookup/VehicleLookup";
import {FUEL_TYPES_LOOKUP_QUERY} from "./model/FuelTypesQuery";
import {TRANSMISSION_TYPES_LOOKUP_QUERY} from "./model/TransmissionTypesQuery";
import {YEARS_OF_MANUFACTURE_LOOKUP_QUERY} from "./model/YearsOfManufactureQuery";
import {ENGINE_CAPACITY_LITRES_LOOKUP_QUERY} from "./model/EngineCapacityLitresQuery";
import {TRIM_LEVELS_LOOKUP_QUERY} from "./model/TrimLevelsQuery";
import {FULL_SPEC_LOOKUP_QUERY, VehicleFullSpecQueryResult} from "./model/FullSpecsQuery";

export function useFuelTypes(
  makeValue: string | undefined,
  modelValue: string | undefined
): QueryResult<VehicleOptionsQueryResult> {
  return useQuery<VehicleOptionsQueryResult>(
    FUEL_TYPES_LOOKUP_QUERY,
    {
      variables: {
        filters: {
          make: makeValue,
          model: modelValue
        }
      }
    }
  );
}

export function useTransmissionTypes(
  makeValue: string | undefined,
  modelValue: string | undefined,
  fuelTypeValue: string | undefined
): QueryResult<VehicleOptionsQueryResult> {
  return useQuery<VehicleOptionsQueryResult>(
    TRANSMISSION_TYPES_LOOKUP_QUERY,
    {
      variables: {
        filters: {
          make: makeValue,
          model: modelValue,
          fuelType: fuelTypeValue
        }
      }
    }
  );
}

export function useYearsOfManufacture(
  makeValue: string | undefined,
  modelValue: string | undefined,
  fuelTypeValue: string | undefined,
  transmissionTypeValue: string | undefined
): QueryResult<VehicleOptionsQueryResult> {
  return useQuery<VehicleOptionsQueryResult>(
    YEARS_OF_MANUFACTURE_LOOKUP_QUERY,
    {
      variables: {
        filters: {
          make: makeValue,
          model: modelValue,
          fuelType: fuelTypeValue,
          transmissionType: transmissionTypeValue
        }
      }
    }
  );
}

export function useEngineCapacities(
  makeValue: string | undefined,
  modelValue: string | undefined,
  fuelTypeValue: string | undefined,
  transmissionTypeValue: string | undefined,
  yearOfManufactureValue: string | undefined
): QueryResult<VehicleOptionsQueryResult> {
  return useQuery<VehicleOptionsQueryResult>(
    ENGINE_CAPACITY_LITRES_LOOKUP_QUERY,
    {
      variables: {
        filters: {
          make: makeValue,
          model: modelValue,
          fuelType: fuelTypeValue,
          transmissionType: transmissionTypeValue,
          yearsOfManufacture: yearOfManufactureValue
        }
      }
    }
  );
}

export function useTrimLevels(
  makeValue: string | undefined,
  modelValue: string | undefined,
  fuelTypeValue: string | undefined,
  transmissionTypeValue: string | undefined,
  yearOfManufactureValue: string | undefined,
  engineCapacityLitresValue: string | undefined
): QueryResult<VehicleOptionsQueryResult> {
  return useQuery<VehicleOptionsQueryResult>(
    TRIM_LEVELS_LOOKUP_QUERY,
    {
      variables: {
        filters: {
          make: makeValue,
          model: modelValue,
          fuelType: fuelTypeValue,
          transmissionType: transmissionTypeValue,
          yearsOfManufacture: yearOfManufactureValue,
          engineCapacityLitres: engineCapacityLitresValue
        }
      }
    }
  );
}

export function useFullSpec(
  filters: {
    make: string | undefined;
    model: string | undefined;
    fuelType: string | undefined;
    transmissionType: string | undefined;
    yearOfManufacture: string | undefined;
    engineCapacityLitres: string | undefined;
    trim: string | undefined;
  }
): QueryResult<VehicleFullSpecQueryResult> {
  return useQuery<VehicleFullSpecQueryResult>(
    FULL_SPEC_LOOKUP_QUERY,
    {
      variables: {
        filters: filters
      }
    }
  );
}
