import React, {FC} from "react";
import IntegerInputField from "shared-components/dist/form/integer-input-field/IntegerInputField";
import {Validator} from "shared-components/dist/utils/validation/Validator";

interface Props {
  id: string;
  value: number | undefined;
  onComplete: (value: number) => void;
  onInvalid?: () => void;
  validators?: Validator<string>[];
}

const MILEAGE_STEP_SIZE = 500;

const MileageInput: FC<Props> = (
  {
    id,
    value,
    onComplete,
    onInvalid,
    validators = []
  }
) => (
  <IntegerInputField
    id={id}
    value={value}
    onComplete={onComplete}
    onInvalid={onInvalid}
    validators={validators}
    step={MILEAGE_STEP_SIZE}
    placeholder="coverDetails.averageMileageQuestion.placeholder"
  />
);

export default MileageInput;