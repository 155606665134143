import React, {FC} from "react";
import {ALLOY_WHEELS_DESCRIPTIONS, ALLOY_WHEELS_OPTIONS} from "../model/AlloyWheelsOption";
import {Modification} from "../model/Modification";
import {Status} from "shared-components/dist/status-indicator/Status";
import {StringRadioColumnSubQuestion} from "../../../../../../../structure/questions/sub-question/variants/radio-column-sub-question/variants/StringRadioColumnSubQuestion";
import {ModificationType} from "shared/dist/generated/graphql/resolvers-types";

interface Props {
  modification: Modification | undefined;
  onSetModification: (modification: Modification, isFormValid?: boolean) => void;
  setIsAlloyWheelStop: (value: boolean) => void;
  status: Status;
}

const AlloyWheelsQuestion: FC<Props> = (
  {
    modification,
    onSetModification,
    setIsAlloyWheelStop,
    status
  }
) => {
  if (!modification || modification.type.id !== ModificationType.AlloyWheels) return null;

  const onAlloyWheelsOptionSelected = (selection: string): void => {
    const alloyWheelsOption = ALLOY_WHEELS_OPTIONS.find(option => option.description === selection);
    if (!alloyWheelsOption) return;

    if (alloyWheelsOption.id === "greater-than-2-inches") {
      onSetModification({...modification, option: alloyWheelsOption}, false);
      setIsAlloyWheelStop(true);
    } else {
      onSetModification({...modification, option: alloyWheelsOption}, true);
      setIsAlloyWheelStop(false);
    }
  };

  return (
    <StringRadioColumnSubQuestion
      subQuestionProps={{
        id: "alloy-wheels-question",
        title: "vehicleQuote.modifications.alloyWheelsQuestion.title",
        status,
        errorMessage: "quote.errors.questionIncomplete"
      }}
      radioColumnProps={{
        options: ALLOY_WHEELS_DESCRIPTIONS,
        name: "alloy-wheels-question",
        onSelection: onAlloyWheelsOptionSelected,
        selectedOption: modification.option?.description,
      }}
    />
  );
};

export default AlloyWheelsQuestion;