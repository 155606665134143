import React, {FC} from "react";
import "./DocumentUploadDescription.css";
import FormattedMessage from "shared-components/src/translations/components/FormattedMessage";
import {UploadedDocumentType} from "shared/dist/generated/graphql/resolvers-types";
import {TranslationId, TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";

interface Props {
  uploadedDocumentType: UploadedDocumentType | undefined;
}

const DocumentUploadDescription: FC<Props> = ({uploadedDocumentType}) => {
  function getDocumentUploadDescriptionTitle(): TranslationKey {
    switch (uploadedDocumentType) {
      default:
      case UploadedDocumentType.LicencePlasticFront:
      case UploadedDocumentType.LicencePlasticBack:
      case UploadedDocumentType.LicencePaperFront:
      case UploadedDocumentType.LicencePaperBack:
        return "document.upload.form.proofOfIdentity";
      case UploadedDocumentType.NcbProof:
        return "document.upload.form.ncb";
      case UploadedDocumentType.NamedDriverExperienceProof:
        return "document.upload.form.namedDriverExperience";
      case UploadedDocumentType.CompanyCarBonusProof:
        return "document.upload.form.companyCarBonus";
    }
  }

  function getDocumentUploadDescriptionRequirements(): TranslationId {
    switch (uploadedDocumentType) {
      default:
      case UploadedDocumentType.LicencePlasticFront:
      case UploadedDocumentType.LicencePlasticBack:
      case UploadedDocumentType.LicencePaperFront:
      case UploadedDocumentType.LicencePaperBack:
        return "document.upload.form.proofOfIdentity.description";
      case UploadedDocumentType.NcbProof:
        return "document.upload.form.ncb.description";
      case UploadedDocumentType.NamedDriverExperienceProof:
        return "document.upload.form.namedDriverExperience.description";
      case UploadedDocumentType.CompanyCarBonusProof:
        return "document.upload.form.companyCarBonus.description";
    }
  }

  if ([UploadedDocumentType.Other, undefined].includes(uploadedDocumentType)) return null;

  return (
    <div className="document-upload-description">
      <p className="document-upload-description__title">{lookupI18nString(getDocumentUploadDescriptionTitle())}</p>
      <br/>
      <div className="document-upload-description__description">
        <FormattedMessage
          id={getDocumentUploadDescriptionRequirements()}
          values={{
            ul: function formatUnorderedList(list: string) {
              return <ul>{list}</ul>;
            },
            li: function formatListItemTags(message: string) {
              return <li>{message}</li>;
            },
            b: function formatBoldTags(chunks: string) {
              return <b>{chunks}</b>;
            },
          }}
        />
      </div>
    </div>
  );
};

export default DocumentUploadDescription;
