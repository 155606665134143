import React, {FC, SyntheticEvent, useState} from "react";
import "./ResetPassword.css";
import Button from "shared-components/dist/buttons/button/Button";
import {Navigate} from "react-router-dom";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {LOGIN} from "../../../router/models/Routes";
import {base64ToString} from "../../../utils/service/base64/Base64Conversion";
import {useResetPasswordQueryParams} from "./hooks/ResetPasswordQueryParamsHook";
import {confirmPasswordReset} from "./redux/ConfirmPasswordReset";
import PortalSplashScreenHeaderBar
  from "../../../structure/navigation/portal-splash-screen-header-bar/PortalSplashScreenHeaderBar";
import PasswordConfirmation from "../../../structure/form/password-confirmation/PasswordConfirmation";
import InlineQuestion from "../../../structure/questions/inline-question/InlineQuestion";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {
  PasswordFormatState,
  PasswordMatchState
} from "../../../structure/form/password-confirmation/PasswordValidators";
import LoginFormLogo from "../shared/components/login-form-logo/LoginFormLogo";
import {useAppDispatch, useAppSelector} from "../../../redux/Hooks";

const ResetPassword: FC = () => {
  const dispatch = useAppDispatch();
  const resetPasswordResult = useAppSelector(state => state.userAuthentication.resetPasswordResult);

  const {email, code} = useResetPasswordQueryParams();
  const [password, setPassword] = useState<string>("");
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState<boolean>(false);
  const isPasswordViolatingPasswordPolicy = resetPasswordResult === "InvalidPasswordFormat";
  const resetPasswordFailed = resetPasswordResult === "Failed";
  const hasRequestFailure = resetPasswordResult === "FailedToAuthenticateWithNewPassword";
  const resetPasswordCodeHasExpired = resetPasswordResult === "ExpiredPasswordResetCode";
  const isPasswordResetSuccessful = resetPasswordResult === "Success";
  const displayErrorMessage = resetPasswordFailed || resetPasswordCodeHasExpired;

  if (!email || !code || hasRequestFailure || isPasswordResetSuccessful) return <Navigate to={LOGIN} replace/>;

  function onConfirmPasswordChange(passwordMatchState: PasswordMatchState, passwordFormatState: PasswordFormatState, newPassword?: string): void {
    setIsSubmitButtonEnabled(passwordMatchState === "Valid" && passwordFormatState === "Valid");
    setPassword(newPassword ?? "");
  }

  const savePasswordButtonClicked = (): void => dispatch(confirmPasswordReset(base64ToString(email), code, password));

  function performPasswordReset(event: SyntheticEvent): void {
    event.preventDefault();
    if (isSubmitButtonEnabled) savePasswordButtonClicked();
  }

  function determineErrorMessage(): TranslationKey {
    if (resetPasswordCodeHasExpired) return "login.forgotPassword.resetPasswordCodeHasExpired";

    return "login.forgotPassword.genericFailure";
  }

  return (
    <div className="reset-password">
      <PortalSplashScreenHeaderBar/>
      <div className="reset-password__content">
        <div className="reset-password__content__dialog">
          <LoginFormLogo/>
          <form onSubmit={performPasswordReset}>
            <div className="form-fields">
              <InlineQuestion
                id="reset-password-failed"
                status={displayErrorMessage ? "error" : "hidden"}
                errorMessage={determineErrorMessage()}
              >
                <PasswordConfirmation
                  isPasswordViolatingPasswordPolicy={isPasswordViolatingPasswordPolicy}
                  onChange={onConfirmPasswordChange}
                  submitButton={
                    <Button
                      type="submit"
                      disabled={!isSubmitButtonEnabled}
                      className="reset-password-button">
                      {lookupI18nString("login.forgotPassword.submitButton")}
                    </Button>
                  }
                  base64Email={email}
                />
              </InlineQuestion>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
