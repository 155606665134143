import React, {FC} from "react";
import {registeredKeeperStopVisibleSelector} from "../../../redux/selectors/owner-registered-keeper-selectors/OwnerRegisteredKeeperStopVisibleSelector";
import QuoteJourneyStop from "../../../../shared/stops/quote-journey-stop/QuoteJourneyStop";
import {useAppSelector} from "../../../../../../../redux/Hooks";

const RegisteredKeeperStop: FC = () => {
  const isVisible = useAppSelector(registeredKeeperStopVisibleSelector);

  return (
    <QuoteJourneyStop
      stopProps={{
        visible: isVisible,
        title: "vehicleQuote.registeredKeeperQuestion.stop.title",
        description: "vehicleQuote.registeredKeeperQuestion.stop.description"
      }}
      isSubContainer={true}
    />
  );
};

export default RegisteredKeeperStop;