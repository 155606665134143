import React, {FC, useEffect, useState} from "react";
import {createAnchorUrl} from "../../../../../utils/navigation/CreateAnchorUrl";
import ContentWithSidebarLayout
  from "../../../../../structure/layouts/content-with-sidebar-layout/ContentWithSidebarLayout";
import Questions from "../shared/Questions";
import SidebarWithBackNavigation
  from "../../../../../structure/sidebar/sidebar-with-back-navigation/SidebarWithBackNavigation";
import ButtonGroup from "shared-components/dist/buttons/button-group/ButtonGroup";
import Button from "shared-components/dist/buttons/button/Button";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {YOUR_DETAILS} from "../../../../../router/models/Routes";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {flagUnansweredAdditionalDriverQuestions} from "../shared/validation/FlagUnansweredAdditionalDriverQuestions";
import {saveAdditionalDriver} from "../shared/redux/thunks/SaveAdditionalDriver";
import "./EditAdditionalDriver.css";
import {loadAdditionalDriver} from "../shared/redux/thunks/LoadAdditionalDriver";
import RemoveAdditionalDriverModal from "./remove-additional-driver-modal/RemoveAdditionalDriverModal";
import EditAdditionalDriverNavigationPrompter from "./EditAdditionalDriverNavigationPrompter";
import FullPageSpinner from "shared-components/dist/spinner/full-page-spinner/FullPageSpinner";
import {useDoAfterFinalRenderAction} from "../../../../../utils/custom-hooks/DoAfterFinalRenderAction";
import {quoteSteps} from "../../shared/quote-step/QuoteSteps";
import {useScrollToError} from "../../../../../utils/validation/hooks/ScrollToError";
import {additionalDriversSelector} from "../../your-details/redux/selectors/PersonalDetailsSelectors";
import QuoteHeader from "../../shared/quote-step/header/QuoteHeader";
import {useAppDispatch, useAppSelector} from "../../../../../redux/Hooks";
import {useEffectOnFirstRender} from "shared-components/dist/utils/hooks/UseEffectOnFirstRender";

const EditAdditionalDriver: FC = () => {
  const dispatch = useAppDispatch();
  const additionalDrivers = useAppSelector(additionalDriversSelector);
  const firstName = useAppSelector(state => state.updateAdditionalDriver.firstName.value);
  const surname = useAppSelector(state => state.updateAdditionalDriver.surname.value);
  const additionalDriverDetailsValid = useAppSelector(quoteSteps.editAdditionalDriver.validator);

  const isResolveQuoteValidationFlowForDriverActive = useAppSelector(state => state.quoteDetails.quoteValidationSectionBeingResolved === "ADDITIONAL_DRIVER");
  const [shouldNavigateToRefineYourQuote, setShouldNavigateToRefineYourQuote] = useState(false);

  const [driverLoaded, setDriverLoaded] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [shouldPromptOnLeave, setShouldPromptOnLeave] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const {driverId} = useParams<{ driverId: string }>();
  const {scrollToError} = useScrollToError({
    includeStops: true
  });

  useEffect(() => {
    if (isResolveQuoteValidationFlowForDriverActive && driverLoaded) scrollToError();
  }, [driverLoaded, isResolveQuoteValidationFlowForDriverActive, scrollToError]);

  useEffectOnFirstRender(() => {
    const loadDriver = async (): Promise<void> => {
      const additionalDriver = additionalDrivers.find(additionalDriver => additionalDriver.id === driverId);

      if (!additionalDriver) {
        navigate(-1);
      } else {
        await dispatch(loadAdditionalDriver(additionalDriver));
        setDriverLoaded(true);
        setShouldNavigateToRefineYourQuote(
          isResolveQuoteValidationFlowForDriverActive
          || location?.state?.pageTitle === "additionalDriver.edit.sidebar.title.refineYourQuote"
        );
      }
    };

    loadDriver();
  });

  const onSaveDriver = (): void => {
    if (additionalDriverDetailsValid && driverId) {
      setShouldPromptOnLeave(false);
      dispatch(saveAdditionalDriver(driverId));

      onDriverSaved();
    } else {
      dispatch(flagUnansweredAdditionalDriverQuestions());
      scrollToError();
    }
  };

  const onCancel = (): void => {
    navigate(-1);
  };

  const onCompletionOfAction = (): void => {
    if (shouldNavigateToRefineYourQuote) {
      navigate(-1);
    } else {
      navigate(createAnchorUrl(
        buildYourDetailsRoute(),
        "additional-drivers-question"
      ));
    }
  };

  const onDriverSaved = useDoAfterFinalRenderAction(onCompletionOfAction, [navigate, location]);

  const onDriverRemoved = useDoAfterFinalRenderAction(onCompletionOfAction, [navigate, location]);

  const buildYourDetailsRoute = (): string => {
    const currentPath = location.pathname.split("/");

    return currentPath
      .slice(0, currentPath.length - 2)
      .join("/")
      .concat(YOUR_DETAILS);
  };

  const getDriverName = (): string => {
    if (!firstName || !surname) return "";

    return `${firstName} ${surname}`;
  };

  if (!driverLoaded) return <FullPageSpinner/>;

  return (
    <>
      <RemoveAdditionalDriverModal
        visible={modalVisible}
        dismiss={() => setModalVisible(false)}
        onRemove={() => {
          setShouldPromptOnLeave(false);
          onDriverRemoved();
        }}
      />
      <EditAdditionalDriverNavigationPrompter promptVisible={shouldPromptOnLeave}/>
      <ContentWithSidebarLayout
        isPageFooterVisible={false}
        className="edit-additional-driver"
        header={<QuoteHeader showButtons={false}/>}
        mainContent={<Questions title="additionalDriver.edit.title" titleValue={getDriverName()}/>}
        sidebar={
          <div className="edit-additional-driver__sidebar">
            <SidebarWithBackNavigation
              title={location?.state?.pageTitle ?? "additionalDriver.edit.sidebar.title.goBack"}
              onBackButtonClicked={onCancel}
            />
            <Button
              className="edit-additional-driver__delete--sidebar"
              variant="delete"
              onClick={() => setModalVisible(true)}
            >
              {lookupI18nString("additionalDriver.remove.button")}
            </Button>
          </div>
        }
        footer={
          <>
            <ButtonGroup className="edit-additional-driver__button-group">
              {!isResolveQuoteValidationFlowForDriverActive &&
                <Button
                  variant="tertiary"
                  onClick={onCancel}
                  expanded
                >
                  {lookupI18nString("additionalDriver.edit.footer.cancel")}
                </Button>
              }

              <Button
                onClick={onSaveDriver}
                expanded
              >
                {lookupI18nString("additionalDriver.edit.footer.save")}
              </Button>
            </ButtonGroup>
            <div className="edit-additional-driver__delete--mobile">
              <Button
                variant="delete"
                onClick={() => setModalVisible(true)}
                expanded
              >
                {lookupI18nString("additionalDriver.remove.button")}
              </Button>
            </div>
          </>
        }
      />
    </>
  );
};

export default EditAdditionalDriver;