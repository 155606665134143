import React, {FC} from "react";
import {carNotKeptAtHomeStopVisibleSelector} from "../../redux/selectors/CarNotKeptAtHomeStopVisibleSelector";
import QuoteJourneyStop from "../../../shared/stops/quote-journey-stop/QuoteJourneyStop";
import {useAppSelector} from "../../../../../../redux/Hooks";

const CarNotKeptAtHomeStop: FC = () => {
  const isVisible = useAppSelector(carNotKeptAtHomeStopVisibleSelector);

  return (
    <QuoteJourneyStop
      stopProps={{
        visible: isVisible,
        title: "personalDetails.isCarKeptAtHomeQuestion.stop.title",
        description: "quoteJourney.shared.softStop.description"
      }}
    />
  );
};

export default CarNotKeptAtHomeStop;