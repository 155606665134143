import React, {FC} from "react";
import {canProtectNoClaimsBonusSelector} from "../../../../your-cover/redux/selectors/CoverDetailsSelectors";
import "./NoClaimsProtectionCard.css";
import {useAppSelector} from "../../../../../../../redux/Hooks";
import NoClaimsProtectionCard from "./NoClaimsProtectionCard";
import {useNavigate} from "react-router-dom";
import {buildQuoteRoute} from "../../../../../../../utils/navigation/NavigationHelpers";
import {PROTECTED_NO_CLAIMS_BONUS_INFORMATION, YOUR_COVER} from "../../../../../../../router/models/Routes";
import {useLoadNoClaimsDiscountInfo} from "./hooks/UseLoadNoClaimsDiscountInfo";
import {useIsEditFlowEnabledForQuote} from "../../../../your-quote/hooks/IsEditFlowEnabledForQuote";

const QuoteSummaryNoClaimsProtectionCard: FC = () => {
  const canProtectNoClaimsBonus = useAppSelector(canProtectNoClaimsBonusSelector);
  const noClaimsDiscountProtectionData = useAppSelector(state => state.quoteDetails.noClaimsDiscountProtectionData);
  const protectNoClaimsBonus = useAppSelector(state => state.coverDetails.protectNoClaimsBonus.value);
  const yearsNoClaimsBonus = useAppSelector(state => state.coverDetails.yearsNoClaimsBonus);
  const noClaimsDiscountProtectionCost = useAppSelector(state => state.quoteDetails.noClaimsDiscountProtectionCostAsPrice);

  const isEditFlowEnabled = useIsEditFlowEnabledForQuote();

  const navigate = useNavigate();
  useLoadNoClaimsDiscountInfo();

  const onInfoClicked = (): void => {
    navigate(buildQuoteRoute(location.pathname, PROTECTED_NO_CLAIMS_BONUS_INFORMATION));
  };

  const onEditClicked = (): void => {
    navigate(buildQuoteRoute(location.pathname, `${YOUR_COVER}#previously-held-insurance-question`));
  };

  return (
    <NoClaimsProtectionCard
      canProtectNoClaimsBonus={canProtectNoClaimsBonus}
      noClaimsDiscountProtectionData={noClaimsDiscountProtectionData}
      protectNoClaimsBonus={protectNoClaimsBonus}
      yearsNoClaimsBonus={yearsNoClaimsBonus.value}
      noClaimsDiscountProtectionCost={noClaimsDiscountProtectionCost}
      onInfoClicked={onInfoClicked}
      onEditClicked={isEditFlowEnabled ? onEditClicked : undefined}
    />
  );
};

export default QuoteSummaryNoClaimsProtectionCard;