import {buildUseReduxQuestionFromRecord, SectionQuestionsRecord} from "../Questions";
import {buildThunkToRunAllValidationForSection} from "../RunAllValidationForSection";
import {coverDetailsSliceName} from "../../../pages/quote/vehicle/your-cover/redux/CoverDetailsSlice";
import {coverType} from "./questions/CoverType";
import {voluntaryExcess} from "./questions/VoluntaryExcess";
import {averageMileage} from "./questions/AverageMileage";
import {averageBusinessMileage} from "./questions/AverageBusinessMileage";
import {previouslyHeldInsurance} from "./questions/PreviouslyHeldInsurance";
import {coverStartDate} from "./questions/CoverStartDate";
import {previousNamedDriverQuestions} from "./questions/PreviousNamedDriverQuestions";

const CoverDetailsQuestionRecord = {
  coverType,
  voluntaryExcess,
  averageMileage,
  averageBusinessMileage,
  ...previousNamedDriverQuestions,
  previouslyHeldInsurance,
  coverStartDate,
} as const satisfies SectionQuestionsRecord;

export const useCoverDetailsQuestion = buildUseReduxQuestionFromRecord(CoverDetailsQuestionRecord);

export const validateAllCoverDetailsQuestions = buildThunkToRunAllValidationForSection(
  CoverDetailsQuestionRecord,
  coverDetailsSliceName
);