import {createAppAsyncThunk} from "../../../../../redux/CreateAppAsyncThunk";
import {fetchProspectiveLoansQuery} from "../../../../quote/vehicle/your-quote/redux/thunks/FetchProspectiveLoansQuery";

export interface LoadRebrokeProspectiveLoansArgs {
  reference: string;
  sequenceNumber: number;
  depositPercentage: number;
}

export const loadRebrokeProspectiveLoans = createAppAsyncThunk(
  "renewalSlice/loadRebrokeProspectiveLoans",
  async ({reference, sequenceNumber, depositPercentage}: LoadRebrokeProspectiveLoansArgs) => {
    return await fetchProspectiveLoansQuery(reference, sequenceNumber, depositPercentage);
  }
);