import React, {FC} from "react";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {BooleanFormField, ConditionalBooleanFormField} from "shared-components/dist/models/form-field/variants/BooleanFormField";
import BooleanQuestion from "../../../../../../structure/questions/question/variants/boolean-question/BooleanQuestion";
import SubQuestionForm from "../../../../../../structure/questions/sub-question-form/SubQuestionForm";
import SecondaryEmploymentStatusSubQuestion, {SecondaryEmploymentStatusQuestionDisplayProps} from "./components/SecondaryEmploymentStatusSubQuestion";
import {EmploymentStatusOption} from "./models/EmploymentStatusOption";
import {ConditionalFormField} from "shared-components/dist/models/form-field/ConditionalFormField";
import EmploymentJobAndIndustryQuestions, {EmployedQuestionsDisplayProps} from "./components/EmploymentJobAndIndustryQuestions";
import UnemployedSubQuestions, {UnemployedQuestionsDisplayProps} from "./components/UnemployedSubQuestions";
import {CustomisedAbiListItem} from "shared-components/dist/models/CustomisedAbiListItem";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";

export interface SecondaryEmploymentQuestionDisplayProps {
  secondaryEmploymentTitle: TranslationKey;
  secondaryEmploymentTooltip: TooltipProps;
}

interface OwnProps {
  id: string;
  hasSecondaryEmployment: BooleanFormField;
  hasSecondaryEmploymentChanged: (value: boolean) => void;
  employmentStatus: ConditionalFormField<EmploymentStatusOption>;
  employmentStatusChanged: (selection: string) => void;
  employedQuestionsActive: boolean;
  unemployedQuestionsActive: boolean;
  industry: ConditionalFormField<CustomisedAbiListItem>;
  job: ConditionalFormField<CustomisedAbiListItem>;
  workedInPastYear: ConditionalBooleanFormField;
  industryChanged: (value: CustomisedAbiListItem) => void;
  jobChanged: (value: CustomisedAbiListItem) => void;
  workedInPastYearChanged: (value: boolean) => void;
  employmentStatusQuestion: SecondaryEmploymentStatusQuestionDisplayProps;
  employedQuestions: EmployedQuestionsDisplayProps;
  unemployedQuestions: UnemployedQuestionsDisplayProps;
}

type Props = OwnProps & SecondaryEmploymentQuestionDisplayProps;

const SecondaryEmploymentQuestion: FC<Props> = (
  {
    id,
    secondaryEmploymentTitle,
    secondaryEmploymentTooltip,
    hasSecondaryEmployment,
    hasSecondaryEmploymentChanged,
    employmentStatus,
    employmentStatusChanged,
    employedQuestionsActive,
    unemployedQuestionsActive,
    industry,
    job,
    workedInPastYear,
    industryChanged,
    jobChanged,
    workedInPastYearChanged,
    employmentStatusQuestion,
    employedQuestions,
    unemployedQuestions,
  }
) => (
  <>
    <BooleanQuestion
      id={id}
      title={secondaryEmploymentTitle}
      formField={hasSecondaryEmployment}
      onAnswer={hasSecondaryEmploymentChanged}
      tooltip={secondaryEmploymentTooltip}
    >
    </BooleanQuestion>
    {employmentStatus.isActive &&
      <SubQuestionForm>
        <SecondaryEmploymentStatusSubQuestion
          idPrefix={id}
          employmentStatus={employmentStatus}
          employmentStatusChanged={employmentStatusChanged}
          {...employmentStatusQuestion}
        />

        {employedQuestionsActive && (
          <EmploymentJobAndIndustryQuestions
            idPrefix={id}
            industry={industry}
            job={job}
            industryChanged={industryChanged}
            jobChanged={jobChanged}
            {...employedQuestions}
          />
        )}

        {unemployedQuestionsActive && (
          <UnemployedSubQuestions
            idPrefix={id}
            job={job}
            workedInPastYear={workedInPastYear}
            jobChanged={jobChanged}
            workedInPastYearChanged={workedInPastYearChanged}
            {...unemployedQuestions}
          />
        )}
      </SubQuestionForm>
    }
  </>
);

export default SecondaryEmploymentQuestion;
