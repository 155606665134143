import React, {FC, useEffect} from "react";
import {Duration} from "date-fns";
import DurationQuestion from "../../../../../../structure/questions/question/variants/duration-question/DurationQuestion";
import {QuestionProps} from "../../../../../../structure/questions/question/Question";
import {useDurationDropdownProps} from "shared-components/dist/form/duration-dropdown/hooks/useDurationDropdownProps";
import {DurationFormField} from "shared-components/dist/models/form-field/variants/DurationFormField";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import {isOldEnoughForLicenceLengthValidator} from "../date-of-birth/validators/isOldEnoughForLicenceLengthValidator";
import {MotorGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {useIsFirstRender} from "../../../../../../utils/custom-hooks/UseIsFirstRender";

interface OwnProps {
  id: string;
  title: TranslationKey;
  licenceDuration: DurationFormField;
  licenceDurationChanged: (duration: Duration) => void;
  licenceDurationInvalid: (error: TranslationKey) => void;
  licenceDurationReset: () => void;
  dateOfBirth: Date | undefined;
  googleAnalyticsLogger: ({categorySuffix, action}: MotorGoogleAnalyticsEvent) => void
  tooltip: TooltipProps;
}

const LicenceDurationQuestion: FC<OwnProps> = (
  {
    id,
    title,
    licenceDuration,
    licenceDurationChanged,
    licenceDurationInvalid,
    licenceDurationReset,
    dateOfBirth,
    googleAnalyticsLogger,
    tooltip
  }
) => {
  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    if (licenceDuration.status === "error" && !isFirstRender) licenceDurationReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateOfBirth, licenceDurationReset]);

  const onChange = (duration: Duration): void => {
    if (dateOfBirth) {
      const result = isOldEnoughForLicenceLengthValidator(duration)(dateOfBirth);
      if (!result.passed) {
        licenceDurationInvalid(result.errorMessage);
        return;
      }
    }

    googleAnalyticsLogger({
      categorySuffix: "held_licence_how_long",
      action: "Answered",
    });
    licenceDurationChanged(duration);
  };

  const questionProps: QuestionProps = {
    id,
    title,
    tooltip,
    errorMessage: licenceDuration.errorMessage,
    status: licenceDuration.status
  };

  const {yearsDropdown, monthsDropdown} = useDurationDropdownProps({
    id: questionProps.id,
    duration: licenceDuration.value,
    onChange,
    maxYear: 60,
    yearToStopShowingMonthDropdown: 1,
    onlyShowMonthDropdownOnYearSelected: true
  });

  return (
    <DurationQuestion
      question={questionProps}
      yearsDropdown={yearsDropdown}
      monthsDropdown={{
        ...monthsDropdown,
        disabledBehaviour: "Hide"
      }}
    />
  );
};

export default LicenceDurationQuestion;
