import React, {FC} from "react";
import {insuranceDeclinedQuestionChanged} from "../../redux/PersonalDetailsSlice";
import InsuranceDeclinedQuestion from "../../../shared/questions/insurance-declined-question/InsuranceDeclinedQuestion";
import {insuranceDeclinedStopVisibleSelector} from "../../redux/selectors/InsuranceDeclinedStopVisibleSelector";
import {logYourDetailsGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const ProposerInsuranceDeclinedQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const hasInsuranceDeclined = useAppSelector(state => state.personalDetails.hasInsuranceDeclined);
  const isStopVisible = useAppSelector(insuranceDeclinedStopVisibleSelector);

  const onChanged = (answer: boolean): void => {
    logYourDetailsGoogleAnalyticsEvent({
      categorySuffix: "insurance_declined_cancelled",
      action: answer,
    });
    dispatch(insuranceDeclinedQuestionChanged(answer));
  };

  return (
    <InsuranceDeclinedQuestion
      title="personalDetails.insuranceDeclinedQuestion.title"
      formField={hasInsuranceDeclined}
      onAnswer={onChanged}
      isStopVisible={isStopVisible}
    />
  );
};

export default ProposerInsuranceDeclinedQuestion;
