import {
  existingPolicyExpiryDateInvalid,
  previouslyHeldInsuranceInvalid,
  protectNoClaimsBonusInvalid,
  yearsNoClaimsBonusInvalid
} from "../redux/CoverDetailsSlice";
import {AppThunkDispatch} from "../../../../../redux/Store";
import {isConditionalFormFieldInvalid} from "shared-components/dist/models/form-field/ConditionalFormField";
import {CoverDetailsFields} from "../redux/CoverDetailsFields";

export function checkNoClaimsBonusQuestions(coverDetails: CoverDetailsFields, dispatch: AppThunkDispatch): void {
  checkPreviouslyHeldInsuranceQuestion(coverDetails, dispatch);
  checkYearsNoClaimsBonusQuestion(coverDetails, dispatch);
  checkProtectNoClaimsBonusQuestion(coverDetails, dispatch);
  checkExistingPolicyExpiryDateQuestion(coverDetails, dispatch);
}

function checkPreviouslyHeldInsuranceQuestion(
  {previouslyHeldInsurance}: CoverDetailsFields,
  dispatch: AppThunkDispatch
): void {
  if (previouslyHeldInsurance.status === "default") {
    dispatch(previouslyHeldInsuranceInvalid("quote.errors.questionIncomplete"));
  }
}

function checkYearsNoClaimsBonusQuestion(
  {yearsNoClaimsBonus}: CoverDetailsFields,
  dispatch: AppThunkDispatch
): void {
  if (isConditionalFormFieldInvalid(yearsNoClaimsBonus)) {
    dispatch(yearsNoClaimsBonusInvalid("quote.errors.questionIncomplete"));
  }
}

function checkProtectNoClaimsBonusQuestion(
  {protectNoClaimsBonus}: CoverDetailsFields,
  dispatch: AppThunkDispatch
): void {
  if (isConditionalFormFieldInvalid(protectNoClaimsBonus)) {
    dispatch(protectNoClaimsBonusInvalid("quote.errors.questionIncomplete"));
  }
}

function checkExistingPolicyExpiryDateQuestion(
  {existingPolicyExpiryDate}: CoverDetailsFields,
  dispatch: AppThunkDispatch
): void {
  if (isConditionalFormFieldInvalid(existingPolicyExpiryDate)) {
    dispatch(existingPolicyExpiryDateInvalid("quote.errors.questionIncomplete"));
  }
}