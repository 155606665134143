import React, {FC} from "react";
import {shouldShowAssumptionsProhibitedFuelTypeStopSelector} from "../../../redux/selectors/ManualVehicleLookupFormSelectors";
import ProhibitedFuelTypeStop from "../../../../shared/stops/prohibited-fuel-type-stop/ProhibitedFuelTypeStop";
import {useAppSelector} from "../../../../../../../redux/Hooks";

const AssumptionsProhibitedFuelTypeStop: FC = () => {
  const isVisible = useAppSelector(shouldShowAssumptionsProhibitedFuelTypeStopSelector);

  return (
    <ProhibitedFuelTypeStop
      isVisible={isVisible}
      isSubContainer={false}
    />
  );
};

export default AssumptionsProhibitedFuelTypeStop;