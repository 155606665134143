import React, {FC} from "react";
import PrimaryEmploymentQuestion from "../../../../shared/questions/employment/PrimaryEmploymentQuestion";
import {EMPLOYMENT_STATUS, EmploymentStatusOption} from "../../../../shared/questions/employment/models/EmploymentStatusOption";
import {
  primaryEmploymentIndustryChanged,
  primaryEmploymentJobChanged,
  primaryEmploymentStatusChanged,
  primaryEmploymentStatusInvalid,
  primaryEmploymentWorkedInPastYearChanged
} from "../../redux/UpdateAdditionalDriverSlice";
import {isEmployed, isUnemployed} from "../../../../shared/validation/ValidateEmploymentStatus";
import {runValidators} from "shared-components/dist/utils/validation/Validator";
import {businessPartnerSelectedValidator, employeeSelectedValidator, employerSelectedValidator} from "./validators/EmploymentStatusValidators";
import {logAdditionalDriverGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const AdditionalDriverPrimaryEmploymentQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const employmentStatus = useAppSelector(state => state.updateAdditionalDriver.primaryEmployment.employmentStatus);
  const relationshipToProposer = useAppSelector(state => state.updateAdditionalDriver.relationshipToProposer.value);
  const proposerEmploymentStatus = useAppSelector(state => state.personalDetails.primaryEmployment.employmentStatus.value?.vtCode);
  const employedQuestionsActive = useAppSelector(state => isEmployed(state.updateAdditionalDriver.primaryEmployment.employmentStatus.value));
  const unemployedQuestionsActive = useAppSelector(state => isUnemployed(state.updateAdditionalDriver.primaryEmployment.employmentStatus.value));
  const industry = useAppSelector(state => state.updateAdditionalDriver.primaryEmployment.industry);
  const job = useAppSelector(state => state.updateAdditionalDriver.primaryEmployment.job);
  const workedInPastYear = useAppSelector(state => state.updateAdditionalDriver.primaryEmployment.workedInPastYear);

  const onEmploymentStatusChanged = (selection: string): void => {
    const employmentStatus = EMPLOYMENT_STATUS.find(type => type.description === selection);
    if (!employmentStatus) return;

    const validationResult = runValidators(
      relationshipToProposer,
      employeeSelectedValidator({proposerEmploymentStatus, employmentStatus: employmentStatus.vtCode}),
      employerSelectedValidator({proposerEmploymentStatus, employmentStatus: employmentStatus.vtCode}),
      businessPartnerSelectedValidator({proposerEmploymentStatus, employmentStatus: employmentStatus.vtCode}),
    );

    validationResult.passed
      ? onValidEmploymentStatus(employmentStatus)
      : dispatch(primaryEmploymentStatusInvalid(validationResult.errorMessage));
  };

  const onValidEmploymentStatus = (status: EmploymentStatusOption): void => {
    logAdditionalDriverGoogleAnalyticsEvent({
      categorySuffix: "employment_status",
      action: "Answered"
    });

    dispatch(primaryEmploymentStatusChanged(status));
  };

  return (
    <PrimaryEmploymentQuestion
      id="additional-driver-primary-employment"
      title="additionalDriver.primaryEmploymentQuestion"
      placeholder="additionalDriver.primaryEmploymentQuestion.dropdownPlaceholder"
      employmentStatus={employmentStatus}
      employmentStatusChanged={onEmploymentStatusChanged}
      industry={industry}
      job={job}
      workedInPastYear={workedInPastYear}
      industryChanged={value => dispatch(primaryEmploymentIndustryChanged(value))}
      jobChanged={value => dispatch(primaryEmploymentJobChanged(value))}
      workedInPastYearChanged={value => dispatch(primaryEmploymentWorkedInPastYearChanged(value))}
      employedQuestionsActive={employedQuestionsActive}
      unemployedQuestionsActive={unemployedQuestionsActive}
      employedQuestions={{
        industryQuestionTitle: "additionalDriver.primaryEmploymentQuestion.employed.industry.title",
        jobQuestionTitle: "additionalDriver.primaryEmploymentQuestion.employed.job.title",
        industryQuestionTooltip: {
          title: "additionalDriver.primaryEmploymentQuestion.employed.industry.tooltip.title",
          description: "additionalDriver.primaryEmploymentQuestion.employed.industry.tooltip.description",
        },
        jobQuestionTooltip: {
          description: "additionalDriver.primaryEmploymentQuestion.employed.job.tooltip.description",
        },
      }}
      unemployedQuestions={{
        workedInPastYearQuestionTitle: "additionalDriver.primaryEmploymentQuestion.unemployed.hasWorkedInPastYear.title",
        previousJobQuestionTitle: "additionalDriver.primaryEmploymentQuestion.unemployed.previousJob.title",
        previousJobQuestionTooltip: {
          description: "additionalDriver.primaryEmploymentQuestion.unemployed.previousJob.tooltip.description"
        },
      }}
    />
  );
};

export default AdditionalDriverPrimaryEmploymentQuestion;
