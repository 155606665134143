import React, {FC} from "react";
import "./SidebarMenu.css";
import {useLocation} from "react-router-dom";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {isCurrentRouteWithin} from "../../../utils/navigation/NavigationHelpers";
import {QuoteMenuItem, vehicleQuoteMenuItems} from "../../../pages/quote/vehicle/shared/quote-step/QuoteMenuItem";

const SidebarMenu: FC = () => {
  const location = useLocation();

  const menuItemClassName = (menuItem: QuoteMenuItem): string => {
    let className = "sidebar-nav__item";
    if (isCurrentRouteWithin(menuItem.path, location.pathname)) className += " sidebar-nav__item--active";
    return className;
  };

  return (
    <div className="sidebar">
      <div className="sidebar-nav">
        {vehicleQuoteMenuItems.map((item, position) => (
          <div className={menuItemClassName(item)} key={position}>
            <div className="sidebar-nav__item-marker">
              {position + 1}
            </div>

            <div className="sidebar-nav__item-text">
              <h5>{lookupI18nString(item.title)}</h5>
              <p>{lookupI18nString(item.description)}</p>
            </div>

            <span className="sidebar-nav__item-divider"/>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SidebarMenu;
