import React, {FC} from "react";
import "./QuoteResult.css";
import QuotePriceCard from "../quote-price-card/QuotePriceCard";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import TabGroup, {TabGroupOption} from "../../../../../../../structure/navigation/tab-group/TabGroup";
import {paymentFrequencyChanged} from "../../../../your-quote/redux/QuoteDetailsSlice";
import {logger} from "../../../../../../../utils/logging/Logger";
import {useFeatureFlags} from "shared-components/dist/feature-flags/hooks/UseFeatureFlags";
import ErrorHeader from "shared-components/dist/questions/error-header/ErrorHeader";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";
import {useQuotePriceProps} from "../quote-price-card/hooks/UseQuotePricesProps";

const QuoteResult: FC = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(state => state.quoteDetails.quoteLoading);
  const quoteError = useAppSelector(state => state.quoteDetails.quoteError);
  const paymentFrequency = useAppSelector(state => state.quoteDetails.paymentFrequency);
  const {monthlyPaymentsAvailable} = useQuotePriceProps();

  const {monthlyPaymentsFlag} = useFeatureFlags();

  if (isLoading) return <div>{lookupI18nString("quoteDetails.quoteResult.loading")}</div>;

  if (quoteError) {
    logger.log(quoteError);
    return <ErrorHeader title={{
      id: "quoteDetails.quoteResult.error",
      templateVariables: {
        message: quoteError
      }
    }}/>;
  }

  const tabGroupOptions: TabGroupOption[] = [
    {
      title: "quoteDetails.tabGroup.annualCost",
      onClick: () => dispatch(paymentFrequencyChanged("Annual"))
    },
    {
      title: "quoteDetails.tabGroup.monthlyCost",
      onClick: () => dispatch(paymentFrequencyChanged("Monthly"))
    }
  ];

  return (
    <div className="quote-result grid-x">
      {monthlyPaymentsAvailable && monthlyPaymentsFlag &&
        <TabGroup
          option={paymentFrequency === "Annual" ? tabGroupOptions[0].title : tabGroupOptions[1].title}
          options={tabGroupOptions}
        />
      }

      <QuotePriceCard/>
    </div>
  );
};

export default QuoteResult;
