import React, {FC} from "react";
import "./RemindersPaginationButtons.css";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";

export interface ReminderPaginationButtonsProps {
  numberOfReminders: number
  onIndexChanged: (index: number) => void
  className?: string
  visibleIndex: number;
}

const RemindersPaginationButtons: FC<ReminderPaginationButtonsProps> = (
  {
    numberOfReminders,
    onIndexChanged,
    className,
    visibleIndex
  }
) => {
  const isPreviousButtonEnabled = visibleIndex > 0;
  const isNextButtonEnabled = (visibleIndex + 1) !== numberOfReminders;

  const goToPreviousReminder = (): void => updateIndex(visibleIndex - 1);
  const goToNextReminder = (): void => updateIndex(visibleIndex + 1);
  const updateIndex = (newVisibleIndex: number): void => {
    onIndexChanged(newVisibleIndex);
  };

  return (
    <div className={`reminders-pagination-buttons ${className}`}>
      <span className="reminders-pagination-buttons__previous-button">
        <button onClick={goToPreviousReminder} disabled={!isPreviousButtonEnabled}>
          <ChevronLeft/>
        </button>
      </span>
      <span className="reminders-pagination-buttons__page-counter">
        <p>{visibleIndex+1}/{numberOfReminders}</p>
      </span>
      <span className="reminders-pagination-buttons__next-button">
        <button onClick={goToNextReminder} disabled={!isNextButtonEnabled}>
          <ChevronRight/>
        </button>
      </span>
    </div>
  );
};

export default RemindersPaginationButtons;
