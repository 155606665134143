import React, {FC} from "react";
import BooleanQuestion from "../../../../../../structure/questions/question/variants/boolean-question/BooleanQuestion";
import {BooleanFormField} from "shared-components/dist/models/form-field/variants/BooleanFormField";
import ResidencyDurationSubQuestion from "./ResidencyDurationSubQuestion";
import {DurationConditionalFormField} from "shared-components/dist/models/form-field/variants/DurationFormField";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";

interface Props {
  id: string;
  subQuestionId: string;
  title: TranslationKey;
  subQuestionTitle: TranslationKey;
  tooltip: TooltipProps;
  hasUkResidencyFromBirth: BooleanFormField;
  hasUkResidencyFromBirthChanged: (value: boolean) => void;
  ukResidencyDuration: DurationConditionalFormField;
  ukResidencyDurationChanged: (value: Duration) => void;
  ukResidencyDurationInvalid: (value: TranslationKey) => void;
  ukResidencyDurationReset: () => void;
  dateOfBirth: Date | undefined;
  isStopVisible: boolean;
  isAdditionalDriverForm?: boolean;
}

const ResidencyQuestion: FC<Props> = ({
  id,
  subQuestionId,
  title,
  subQuestionTitle,
  tooltip,
  hasUkResidencyFromBirth,
  hasUkResidencyFromBirthChanged,
  ukResidencyDuration,
  ukResidencyDurationChanged,
  ukResidencyDurationInvalid,
  ukResidencyDurationReset,
  dateOfBirth,
  isStopVisible,
  isAdditionalDriverForm = false
}) => (
  <>
    <BooleanQuestion
      id={id}
      title={title}
      formField={hasUkResidencyFromBirth}
      onAnswer={hasUkResidencyFromBirthChanged}
      tooltip={tooltip}
    />

    <ResidencyDurationSubQuestion
      id={subQuestionId}
      title={subQuestionTitle}
      ukResidencyDuration={ukResidencyDuration}
      ukResidencyDurationChanged={ukResidencyDurationChanged}
      ukResidencyDurationInvalid={ukResidencyDurationInvalid}
      ukResidencyDurationReset={ukResidencyDurationReset}
      dateOfBirth={dateOfBirth}
      isStopVisible={isStopVisible}
      isAdditionalDriverForm={isAdditionalDriverForm}
    />
  </>
);

export default ResidencyQuestion;
