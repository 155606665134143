import {
  hasUkResidencyFromBirthChanged,
  ukResidencyDurationChanged,
  ukResidencyDurationInvalid,
  ukResidencyDurationReset
} from "../../../../pages/quote/vehicle/additional-driver/shared/redux/UpdateAdditionalDriverSlice";
import {ukResidencyDurationValidators} from "../../shared/driver-validation/UkResidencyDurationValidators";
import {dateOfBirth} from "./DateOfBirth";
import {SectionQuestionsRecord} from "../../Questions";

export const ukResidency = {
  hasUkResidencyFromBirth: {
    selector: state => state.updateAdditionalDriver.hasUkResidencyFromBirth,
    onChange: hasUkResidencyFromBirthChanged
  },
  ukResidencyDuration: {
    selector: state => state.updateAdditionalDriver.ukResidencyDuration,
    onChange: ukResidencyDurationChanged,
    onInvalid: ukResidencyDurationInvalid,
    onReset: ukResidencyDurationReset,
    crossFieldValidators: ukResidencyDurationValidators(dateOfBirth)
  },
} as const satisfies SectionQuestionsRecord;