import {createAppAsyncThunk} from "../../../../../redux/CreateAppAsyncThunk";
import {loadRenewalProspectiveLoansQuery} from "../../../../../graphql/queries/portal/LoadRenewalProspectiveLoansQuery";
import {mapRenewalPolicyExtrasToQuoteExtras, RenewalPolicyExtras} from "../../models/RenewalPolicyExtras";

export interface LoadRenewalProspectiveLoansArgs {
  policyId: string;
  policyExtras: RenewalPolicyExtras;
  depositPercentage: number;
}

export const loadRenewalProspectiveLoans = createAppAsyncThunk(
  "renewalSlice/loadRenewalProspectiveLoans",
  async ({policyId, policyExtras, depositPercentage}: LoadRenewalProspectiveLoansArgs) => {
    return await loadRenewalProspectiveLoansQuery({
      policyId,
      depositPercentage,
      policyExtras: mapRenewalPolicyExtrasToQuoteExtras(policyExtras)
    });
  }
);