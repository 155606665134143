import {
  addMonths,
  addYears,
  differenceInCalendarDays,
  differenceInMonths,
  differenceInYears,
  eachDayOfInterval,
  parse,
  startOfDay,
  startOfMonth,
  subMonths
} from "date-fns";
import {utcToZonedTime, zonedTimeToUtc} from "date-fns-tz";

export const now = (): Date => new Date();
export const toSafeDate = (isoString: string | undefined): Date | undefined => isoString ? new Date(isoString) : undefined;

export function firstDayOfPreviousMonth(): Date {
  return startOfMonth(subMonths(now(), 1));
}

export function firstDayOfMonthsInFuture(numberOfMonths: number): Date {
  return startOfMonth(addMonths(now(), numberOfMonths));
}

export function datesBetween(start: Date, end: Date): Date[] {
  return eachDayOfInterval({start, end});
}

export function daysBetween(firstDate: Date, secondDate: Date): number {
  return differenceInCalendarDays(secondDate, firstDate);
}

export function fullMonthsBetween(firstDate: Date, secondDate: Date): number {
  return differenceInMonths(secondDate, firstDate);
}

export function convertDDMMYYYYWithDashesToDate(searchQuery: string): Date {
  return parse(searchQuery, "dd-MM-yyyy", new Date());
}

export function addOneYear(date: string): string {
  const startDate = new Date(date);
  const endDate = addYears(startDate, 1);
  return endDate.toISOString();
}

export function yearsBetween(firstDate: Date, secondDate: Date): number {
  return differenceInYears(secondDate, firstDate);
}

export function getStartOfDayInTimezone(utcDate: Date, timeZone: string): Date {
  const timeZoneDate = utcToZonedTime(utcDate, timeZone);
  const timeZoneStartOfDay = startOfDay(timeZoneDate);
  return zonedTimeToUtc(timeZoneStartOfDay, timeZone);
}

export function getDateFromFormattedString(date: string, formatString: string, timezone: string): Date {
  const parsedDate = parse(date, formatString, new Date());
  return zonedTimeToUtc(parsedDate, timezone);
}

/**
 * combines a utc date object with a "HH:mm" time from a different timeZone. note this will overwrite any existing
 * time component of the date object.
 */
export function combineUtcDateWithTimeInTimezone(utcDate: Date, time: string, timeZone: string): Date {
  const dateInTz = parse(time, "HH:mm", utcToZonedTime(utcDate, timeZone));
  return zonedTimeToUtc(dateInTz, timeZone);
}

/**
 * Convenience method that use the current date/time and which are, therefore, awkward to test. That functionality can
 * be isolated here and then mocked out for testing.
 */
export function getCurrentYear(): number {
  return new Date().getFullYear();
}

export function parseStringToDateOrUndefined(dateString: string | undefined): Date | undefined {
  return dateString ? new Date(dateString) : undefined;
}

export function minusMonths(date: Date, numberOfMonths: number): Date {
  return subMonths(date, numberOfMonths);
}