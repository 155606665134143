import {AggregatorQuoteResult, CoverType} from "shared/src/generated/graphql/resolvers-types";
import {CoverDetailsFields} from "../../../quote/vehicle/your-cover/redux/CoverDetailsFields";
import {
  COVER_TYPES,
  CoverType as AbiCoverType
} from "../../../quote/vehicle/your-cover/components/cover-type/models/CoverType";
import {BreakInCover, detectBreakInCover} from "../../../quote/vehicle/shared/services/BreakInCoverDetector";
import {isToday, isTomorrow, subDays} from "date-fns";
import {now} from "shared/src/stdlib/Dates";
import {buildConditionalFormField, buildFormField} from "./BuildFormField";
import {
  initialState,
  initialState as initialCoverDetailsState
} from "../../../quote/vehicle/your-cover/redux/CoverDetailsSlice";
import {
  canProtectNoClaimsBonus
} from "../../../quote/vehicle/your-cover/components/previously-held-insurance/helpers/CanProtectNoClaimsBonus";
import {toReadableDateFormat} from "shared/dist/stdlib/DateFormat";
import {FormField} from "shared-components/dist/models/form-field/FormField";
import {SerializableDateOption} from "shared/dist/models/date-option/DateOption";
import {doesAnyDriverHaveBusinessUse} from "../../../quote/vehicle/shared/redux/ShouldShowBusinessMileageQuestion";

export function buildCover(quote: AggregatorQuoteResult, isCompareNiPhaseTwoFlagEnabled: boolean): CoverDetailsFields {
  const previousCoverExpiryDate = buildCoverExpiryDate(
    quote.coverDetails.periodBetweenInsurance ?? undefined
  );
  const yearsNoClaimsBonus = quote.coverDetails.yearsNoClaimsBonus >= 9
    ? "9+"
    : quote.coverDetails.yearsNoClaimsBonus.toString();
  const shouldShowBusinessMileageQuestion = doesAnyDriverHaveBusinessUse(
    quote.personalDetails.proposedClassOfUse,
    quote.additionalDrivers?.map(driver => driver.proposedClassOfUse)
  );
  const hasPreviouslyHeldInsurance = quote.coverDetails.previouslyHeldInsurance;

  return {
    ...initialCoverDetailsState,
    coverStartDate: buildCoverStartDate(quote.coverDetails.startDate),
    coverType: buildFormField(mapCoverType(quote.coverDetails.coverType)),
    voluntaryExcess: quote.coverDetails.coverType !== CoverType.ThirdParty
      ? buildConditionalFormField(quote.coverDetails.voluntaryExcess.toString())
      : initialState.voluntaryExcess,
    previouslyHeldInsurance: buildFormField(hasPreviouslyHeldInsurance),
    yearsNoClaimsBonus: buildConditionalFormField(hasPreviouslyHeldInsurance ? yearsNoClaimsBonus : undefined),
    protectNoClaimsBonus: buildConditionalFormField(
      canProtectNoClaimsBonus(yearsNoClaimsBonus)
        ? quote.coverDetails.protectNoClaimsBonus
        : undefined
    ),
    averageMileage: buildFormField(quote.coverDetails.mileage),
    averageBusinessMileage: buildConditionalFormField(
        shouldShowBusinessMileageQuestion
          ? quote.coverDetails.businessMileage ?? undefined
          : undefined
    ),
    breakInCover: buildBreakInCover(quote, previousCoverExpiryDate),
    breakInCoverDeclarationAcceptance: isCompareNiPhaseTwoFlagEnabled ? buildConditionalFormField<boolean>(undefined) : buildConditionalFormField(true),
    hasEnteredNamedDriverFlow: false,
    existingPolicyExpiryDate: buildConditionalFormField(
      previousCoverExpiryDate?.toISOString()
    ),
    isPreviousNamedDriver: buildConditionalFormField(!!quote.coverDetails.namedDriverExperience)
  };
}

function mapCoverType(coverType: CoverType): AbiCoverType {
  switch (coverType) {
    case CoverType.Comprehensive:
      return getCoverTypeByVtCode("C");
    case CoverType.ThirdParty:
      return getCoverTypeByVtCode("O");
    case CoverType.ThirdPartyFireAndTheft:
      return getCoverTypeByVtCode("T");
    default:
      throw new Error(`Unknown CoverType: ${coverType}`);
  }
}

function getCoverTypeByVtCode(vtCode: string): AbiCoverType {
  const coverType = COVER_TYPES.find(type => type.vtCode === vtCode);

  if (!coverType) throw new Error(`Unknown Cover Type vt code: ${vtCode}`);

  return coverType;
}

function buildBreakInCover(quote: AggregatorQuoteResult, previousCoverExpiryDate: Date | undefined): BreakInCover {
  return detectBreakInCover({
    carPurchaseDate: quote.vehicleDetails.dateOfPurchase ? new Date(quote.vehicleDetails.dateOfPurchase) : undefined,
    coverStartDate: new Date(quote.coverDetails.startDate),
    hasPreviouslyBeenInsured: quote.coverDetails.previouslyHeldInsurance,
    previousCoverExpiryDate,
    vehiclePreviousPolicyExpiryDate: undefined
  });
}

function buildCoverExpiryDate(periodBetweenInsurance: number | undefined): Date | undefined {
  if (!periodBetweenInsurance && periodBetweenInsurance !== 0) {
    return undefined;
  }

  return subDays(now(), periodBetweenInsurance);
}

function buildCoverStartDate(dateString: string): FormField<SerializableDateOption> {
  const date = new Date(dateString);
  const description = isToday(date) ? "Today"
      : isTomorrow(date) ? "Tomorrow"
      : toReadableDateFormat(date);

  return buildFormField({
    date: dateString,
    description
  }, {status: "success"});
}