import React, {FC, useEffect} from "react";
import BooleanQuestion from "../../../../../../structure/questions/question/variants/boolean-question/BooleanQuestion";
import {isHomeownerChanged, isHomeownerInvalid, isHomeownerReset} from "../../redux/PersonalDetailsSlice";
import {dateOfBirthAsDateSelector} from "../../redux/selectors/PersonalDetailsSelectors";
import {isOldEnoughToBeAHomeowner} from "../../../shared/questions/date-of-birth/validators/IsOldEnoughToBeAHomeowner";
import {getIdFromTranslationKey} from "shared-components/dist/translations/TranslationKey";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";
import {useIsFirstRender} from "../../../../../../utils/custom-hooks/UseIsFirstRender";

const ProposerIsHomeownerQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const isHomeowner = useAppSelector(state => state.personalDetails.isHomeowner);
  const dateOfBirth = useAppSelector(state => dateOfBirthAsDateSelector(state).value);

  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    if (hasErrorMessageDueToDateOfBirth() && !isFirstRender) dispatch(isHomeownerReset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateOfBirth]);

  const hasErrorMessageDueToDateOfBirth = (): boolean => {
    if (!isHomeowner.errorMessage) return false;

    return getIdFromTranslationKey(isHomeowner.errorMessage) === "personalDetails.dateOfBirthQuestion.errors.isTooYoung.toBeAHomeowner";
  };

  const onAnswer = (answer: boolean): void => {
    if (dateOfBirth) {
      const result = isOldEnoughToBeAHomeowner(answer)(dateOfBirth);

      if (!result.passed) {
        dispatch(isHomeownerInvalid(result.errorMessage));
        return;
      }
    }

    dispatch(isHomeownerChanged(answer));
  };

  return (
    <BooleanQuestion
      id="proposer-is-homeowner-question"
      title="personalDetails.isHomeownerQuestion.title"
      tooltip={{description: "personalDetails.isHomeownerQuestion.tooltip"}}
      formField={isHomeowner}
      onAnswer={onAnswer}
    />
  );
};

export default ProposerIsHomeownerQuestion;
