import {FC} from "react";
import "./AnnualisedBreakdownCard.css";
import Button from "shared-components/dist/buttons/button/Button";
import KeyItemRow from "shared-components/dist/lists/key-item-list/KeyItemRow";
import FormattedMessage from "shared-components/src/translations/components/FormattedMessage";
import {toGregorianFormat} from "shared/dist/stdlib/DateFormat";
import {useNavigate} from "react-router-dom";
import {MidTermAdjustmentMetadata, RenewalNotice} from "shared/dist/generated/graphql/resolvers-types";
import {CurrencyFormat} from "../../../../../utils/money/CurrencyFormat";
import {isMobile} from "react-device-detect";
import {FormattedPrice} from "../../../../../utils/money/Price";

interface Props {
  midTermAdjustmentMetadata: MidTermAdjustmentMetadata[];
  renewalNotice: RenewalNotice;
}

const AnnualisedBreakdownCard: FC<Props> = ({midTermAdjustmentMetadata, renewalNotice}) => {
  const navigate = useNavigate();

  const [lastYearsPriceIntegral, lastYearsPriceFractional] = CurrencyFormat.formatPriceWithSymbol(renewalNotice.lastYearsPremium).split(".");

  const goBack = (): void => navigate(-1);

  return (
    <div className="annualised-breakdown-card">
      <div className="annualised-breakdown-card__header">
        <div>
          <p className="annualised-breakdown-card__header__title">
            <FormattedMessage id="portal.renewals.annualised.breakdown.card.header.title"/>
          </p>
          <p className="annualised-breakdown-card__header__subtitle">
            <FormattedMessage id="portal.renewals.annualised.breakdown.card.header.subtitle"/>
          </p>
        </div>

        <div className="annualised-breakdown-card__header__price">
          {isMobile
            ? <p className="annualised-breakdown-card__header__price--mobile" data-testid="annualised-breakdown-card__price--mobile">
                <FormattedPrice value={renewalNotice.lastYearsPremium}/>
              </p>
            : <>
              <span className="annualised-breakdown-card__header__price--big" data-testid="annualised-breakdown-card__price--big">
                {lastYearsPriceIntegral}
              </span>
              <span className="annualised-breakdown-card__header__price--small" data-testid="annualised-breakdown-card__price--small">
                .{lastYearsPriceFractional}
              </span>
            </>
          }
        </div>
      </div>

      <div className="annualised-breakdown-card__body">
        {midTermAdjustmentMetadata.map((mta, index) => (
          <KeyItemRow
            key={index}
            keyValue={<FormattedMessage
              id="portal.renewals.annualised.breakdown.card.body.policyUpdate"
              values={{
                span: (msg: string) => <span>{msg}</span>,
                date: toGregorianFormat(new Date(mta.effectiveDate))
              }}
            />}
            item={CurrencyFormat.formatPriceWithSymbol(mta.total)}
            testId="annualised-breakdown-card__mta-row"
          />
        ))}

        <KeyItemRow
          keyValue={<FormattedMessage id="portal.renewals.annualised.breakdown.card.body.annualised"/>}
          item={CurrencyFormat.formatPriceWithSymbol(renewalNotice.annualised)}
          testId="annualised-breakdown-card__annualised-row"
        />
      </div>

      <div className="annualised-breakdown-card__footer">
        <Button
          onClick={goBack}
          variant="secondary"
          expanded
          data-testid="annualised-breakdown-card__footer__button"
        >
          <FormattedMessage id="portal.renewals.annualised.breakdown.card.footer.button"/>
        </Button>
      </div>
    </div>
  );
};

export default AnnualisedBreakdownCard;