import React, {FC} from "react";
import "./RenewalRevoked.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import FormattedMessage from "shared-components/src/translations/components/FormattedMessage";
import PlainCard from "shared-components/src/cards/plain-card/PlainCard";
import ReducedHelpCard from "../../components/reduced-help-card/ReducedHelpCard";

const RenewalRevoked: FC = () => {
  return (
    <div>
      <PlainCard>
        <div className="revoked">
          <h1 className="revoked__title">{lookupI18nString("renewal.revokedPage.revoked")}</h1>
          <h2 className="revoked__subtitle">{lookupI18nString("renewal.revokedPage.wereReviewingYourPolicy")}</h2>
          <h5 className="revoked__list-title">{lookupI18nString("renewal.revokedPage.nextSteps")}</h5>
          <div className="revoked__list">
            <FormattedMessage
              id="renewal.revokedPage.nextStepDetails"
              values={{
                ul: (list: string) => <ul>{list}</ul>,
                li: (msg: string) => <li className="revoked__list-item">{msg}</li>
              }}
            />
          </div>
        </div>
      </PlainCard>
      <br/>
      <ReducedHelpCard/>
    </div>
  );
};

export default RenewalRevoked;