import React, {FC} from "react";
import {YOUR_COVER} from "../../../../../../../router/models/Routes";
import Button from "shared-components/dist/buttons//button/Button";
import {useLocation, useNavigate} from "react-router-dom";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import TranslatableKeyItemList from "shared-components/dist/lists/key-item-list/TranslatableKeyItemList";
import {buildQuoteRoute} from "../../../../../../../utils/navigation/NavigationHelpers";
import {logYourQuoteGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {formatDateStringFromIsoString} from "shared/dist/stdlib/DateFormat";
import {useAppSelector} from "../../../../../../../redux/Hooks";
import {useIsEditFlowEnabledForQuote} from "../../../../your-quote/hooks/IsEditFlowEnabledForQuote";

const CoverDetailsList: FC = () => {
  const coverDetails = useAppSelector(state => state.coverDetails);
  const compulsoryExcess = useAppSelector(state => state.quoteDetails.compulsoryExcess);
  const voluntaryExcess = useAppSelector(state => state.quoteDetails.voluntaryExcess);
  const isEditFlowEnabled = useIsEditFlowEnabledForQuote();

  const navigate = useNavigate();
  const location = useLocation();

  const {
    coverStartDate,
    yearsNoClaimsBonus,
    averageMileage,
    averageBusinessMileage,
    coverType
  } = coverDetails;

  const totalAverageMileage = (averageMileage.value ?? 0) + (averageBusinessMileage.value ?? 0);

  return <>
    <TranslatableKeyItemList
      rows={[
        {keyValue: "quoteDetails.summary.yourCover.startDate", item: coverStartDate.value ? formatDateStringFromIsoString(coverStartDate.value.date) : lookupI18nString("structure.form.dateInput.genericError")},
        {keyValue: "quoteDetails.summary.yourCover.type", item: coverType.value?.description ?? lookupI18nString("structure.form.dateInput.genericError")},
        {keyValue: "quoteDetails.summary.yourCover.compulsoryExcess", item: `£${compulsoryExcess || 0}`},
        {keyValue: "quoteDetails.summary.yourCover.voluntaryExcess", item: `£${voluntaryExcess || 0}`},
        {keyValue: "quoteDetails.summary.yourCover.noClaimsBonus", item: yearsNoClaimsBonus.value},
        {keyValue: "quoteDetails.summary.yourCover.mileage", item: totalAverageMileage},
      ]}
    />

    {isEditFlowEnabled &&
      <Button
        className="quote-summary-edit-button"
        variant="ghost"
        onClick={() => {
          logYourQuoteGoogleAnalyticsEvent({
            categorySuffix: "your_cover.edit",
            action: "Click"
          });
          navigate(buildQuoteRoute(location.pathname, YOUR_COVER));
        }}
      >
        {lookupI18nString("quoteDetails.summary.editButton")}
      </Button>
    }
  </>;
};

export default CoverDetailsList;