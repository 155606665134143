import React, {FC} from "react";
import SubQuestion from "../../../../../../../structure/questions/sub-question/SubQuestion";
import {importTypeChanged} from "../../../redux/VehicleDetailsSlice";
import {ImportTypeOptions, importTypeOptions, ImportTypeRadioColumn} from "../models/ImportTypeOptions";
import {importTypeOptionFrom} from "../../../../../../../graphql/queries/vehicle-lookup/registration-lookup/model/ImportType";
import AdditionalDetailsEuImportVehicleStop from "./import-type-stops/AdditionalDetailsEuImportVehicleStop";
import AdditionalDetailsNonEuImportVehicleStop from "./import-type-stops/AdditionalDetailsNonEuImportVehicleStop";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const AdditionalDetailsImportType: FC = () => {
  const dispatch = useAppDispatch();
  const importType = useAppSelector(state => state.vehicleDetails.additionalVehicleDetails.importType);

  if (!importType.isActive) return null;

  const onSelection = (importType: ImportTypeOptions): void => {
    dispatch(importTypeChanged(importType === "EU" ? "EU" : "NonEU"));
  };

  return (
    <>
      <SubQuestion
        title="vehicleQuote.additionalVehicleDetailsForm.importType"
        id="importType"
        errorMessage={importType.errorMessage}
        status={importType.status}
      >
        <ImportTypeRadioColumn
          name="importType"
          options={importTypeOptions}
          selectedOption={importType.value && importTypeOptionFrom(importType.value)}
          onSelection={onSelection}
        />
      </SubQuestion>
      <AdditionalDetailsEuImportVehicleStop/>
      <AdditionalDetailsNonEuImportVehicleStop/>
    </>
  );
};

export default AdditionalDetailsImportType;
