import {getUserAttributes, InternalCognitoUser} from "../../attributes/services/get/GetUserAttributesService";
import {getCurrentAuthenticatedUser} from "./UserAuthenticationService";

export const POLICY_HOLDER_ID_COGNITO_ATTRIBUTE_NAME = "preferred_username";

export async function getCurrentPolicyHolderId(): Promise<string | undefined> {
  const currentAuthenticatedUser = await getCurrentAuthenticatedUser();
  if (!currentAuthenticatedUser) return undefined;

  const userAttributes = await getUserAttributes(currentAuthenticatedUser as InternalCognitoUser);
  if (!userAttributes) return undefined;

  const preferredUserNameAttribute = userAttributes.find(attribute => attribute.Name === POLICY_HOLDER_ID_COGNITO_ATTRIBUTE_NAME);
  if (!preferredUserNameAttribute) return undefined;

  return preferredUserNameAttribute.Value;
}