import {RootState} from "../../../../../../redux/Store";
import {Vehicle} from "../../../../../../graphql/queries/vehicle-lookup/registration-lookup/model/Vehicle";
import {
  shouldUseMainTrackerQuestion,
  validatedAdditionalVehicleDetailsFromFormInput
} from "./ValidatedAdditionalVehicleDetailsFromFormInput";
import {BooleanFormField} from "shared-components/dist/models/form-field/variants/BooleanFormField";

export function validatedVehicleFromRegLookup(state: RootState): Vehicle | undefined {
  const vehicleDetails = state.vehicleDetails;

  if (vehicleDetails.vehicleFromRegLookup === undefined) return undefined;

  const useMainTrackerQuestion = shouldUseMainTrackerQuestion(vehicleDetails.vehicleFromRegLookup);
  if (vehicleDetails.assumptionsCorrect.value) {
    if (useMainTrackerQuestion) {
      return vehicleBuildUsingMainTrackerQuestion(
        vehicleDetails.vehicleFromRegLookup,
        vehicleDetails.hasTracker
      );
    } else {
      return vehicleDetails.vehicleFromRegLookup;
    }
  }

  const additionalVehicleDetails = validatedAdditionalVehicleDetailsFromFormInput(state.vehicleDetails, useMainTrackerQuestion);

  if (vehicleDetails.vehicleFromRegLookup && additionalVehicleDetails) {
    return {
      ...vehicleDetails.vehicleFromRegLookup,
      ...additionalVehicleDetails,
    };
  }
}

function vehicleBuildUsingMainTrackerQuestion(
  vehicle: Vehicle,
  mainHasTrackerQuestion: BooleanFormField
): Vehicle | undefined {
  if (mainHasTrackerQuestion.status === "success") {
    return {
      ...vehicle,
      hasTracker: mainHasTrackerQuestion.value,
    };
  } else {
    return undefined;
  }
}
