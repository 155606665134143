import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../Store";
import {PolicyExtraOptionType, Price} from "shared/dist/generated/graphql/resolvers-types";
import {QUOTE_ANNUAL_PAYMENT, QUOTE_MONTHLY_PAYMENT} from "../../router/models/Routes";
import {PaymentFlowType} from "../../pages/payment/shared/models/PaymentFlowType";
import {
  selectRebrokeAdminFee,
  selectRebrokeInsurerName,
  selectRenewalAdminFee,
  selectRenewalInsurerName,
  selectRenewalPolicyExtras,
  selectRenewalRebrokePaymentFrequency,
  selectTotalFinancedRebrokeAmount,
  selectTotalFinancedRenewalAmount,
  selectTotalUnFinancedRebrokeAmount,
  selectTotalUnfinancedRenewalAmount
} from "../../pages/portal/renewals/redux/RenewalSelectors";
import {
  quotePaymentFrequencySelector,
  selectQuoteAdminFee,
  selectQuoteExtras,
  selectQuoteInsurerName,
  selectQuoteTotalFinancedAmount
} from "../../pages/quote/vehicle/your-quote/redux/selectors/QuoteDetailsSelectors";
import {PaymentFrequency} from "../../pages/quote/vehicle/your-quote/models/SavedQuote";
import {EcommercePolicyExtra} from "../../utils/analytics/helpers/UseBuildEcommerceItemsList";
import {getEnabledPolicyExtras} from "shared/dist/models/policy/AvailablePolicyExtras";
import {mapRenewalPolicyExtrasToQuoteExtras} from "../../pages/portal/renewals/models/RenewalPolicyExtras";

const totalUnfinancedQuoteAmountPayable = (state: RootState): Price | undefined => state.quoteDetails.totalUnfinancedAmountPayable;

export const selectPaymentNavigationRoute = createSelector(
  (state: RootState) => state.quoteDetails.paymentFrequency,
  (paymentFrequency): string => {
    if (paymentFrequency === "Monthly") return QUOTE_MONTHLY_PAYMENT;

    return QUOTE_ANNUAL_PAYMENT;
  }
);

const selectPaymentFlowType = createSelector(
  (state: RootState) => state.renewal.selectedQuoteType,
  (renewalRebrokeSelectedQuoteType): PaymentFlowType => {
    if (!renewalRebrokeSelectedQuoteType) {
      return PaymentFlowType.QUOTE;
    }

    if (renewalRebrokeSelectedQuoteType === "REBROKE") {
      return PaymentFlowType.REBROKE;
    }

    return PaymentFlowType.RENEWAL;
  }
);

export const selectPaymentFrequency = createSelector(
  selectRenewalRebrokePaymentFrequency,
  quotePaymentFrequencySelector,
  selectPaymentFlowType,
  (renewalRebrokePaymentFrequency, quotePaymentFrequency, paymentFlowType): PaymentFrequency => {
    if (paymentFlowType === PaymentFlowType.QUOTE) {
      return quotePaymentFrequency;
    }
    return renewalRebrokePaymentFrequency || "Annual";
  }
);

export const totalUnfinancedAmountSelector = createSelector(
  selectTotalUnfinancedRenewalAmount,
  totalUnfinancedQuoteAmountPayable,
  selectTotalUnFinancedRebrokeAmount,
  selectPaymentFlowType,
  (renewalAmount,
   quoteAmount,
   rebrokeAmount,
   paymentFlowType): Price => {
    return getPriceForPaymentFlowType(
      renewalAmount,
      quoteAmount,
      rebrokeAmount,
      paymentFlowType);
  }
);

export const totalFinancedAmountSelector = createSelector(
  selectTotalFinancedRenewalAmount,
  selectQuoteTotalFinancedAmount,
  selectTotalFinancedRebrokeAmount,
  selectPaymentFlowType,
  (renewalAmount,
   quoteAmount,
   rebrokeAmount,
   paymentFlowType): Price => {

    return getPriceForPaymentFlowType(
      renewalAmount,
      quoteAmount,
      rebrokeAmount,
      paymentFlowType);
  }
);

function getPriceForPaymentFlowType(
  renewalAmount: Price | undefined,
  quoteAmount: Price | undefined,
  rebrokeAmount: Price | undefined,
  paymentFlowType: PaymentFlowType): Price {
  let amount = undefined;

  if (paymentFlowType === PaymentFlowType.RENEWAL) {
    amount = renewalAmount;
  }

  if (paymentFlowType === PaymentFlowType.REBROKE) {
    amount = rebrokeAmount;
  }

  if (paymentFlowType === PaymentFlowType.QUOTE) {
    amount = quoteAmount;
  }

  return amount ?? {amount: "0.00", currency: "GBP"};
}

export const selectPolicyPaymentAmount = createSelector(
  selectPaymentFrequency,
  totalUnfinancedAmountSelector,
  totalFinancedAmountSelector,
  (paymentFrequency, totalUnfinancedAmount, totalFinancedAmount): Price => {
    if (paymentFrequency === "Monthly") {
      return totalFinancedAmount;
    }
    return totalUnfinancedAmount;
  }
);

export const selectPolicyInsurerName = createSelector(
  selectPaymentFlowType,
  selectQuoteInsurerName,
  selectRenewalInsurerName,
  selectRebrokeInsurerName,
  (paymentFlowType, quoteInsurerName, renewalInsurerName, rebrokeInsurerName): string => {
    let insurerName = undefined;

    if (paymentFlowType === PaymentFlowType.RENEWAL) {
      insurerName = renewalInsurerName;
    }

    if (paymentFlowType === PaymentFlowType.REBROKE) {
      insurerName = rebrokeInsurerName;
    }

    if (paymentFlowType === PaymentFlowType.QUOTE) {
      insurerName = quoteInsurerName;
    }

    return insurerName || "";
  }
);

export const selectPolicyAdminFee = createSelector(
  selectPaymentFlowType,
  selectRenewalAdminFee,
  selectRebrokeAdminFee,
  selectQuoteAdminFee,
  (paymentFlowType: PaymentFlowType,
   renewalAdminFee: Price | undefined,
   rebrokeAdminFee: Price | undefined,
   quoteAdminFee: Price | undefined,
  ): Price => {
    return getPriceForPaymentFlowType(
      renewalAdminFee,
      quoteAdminFee,
      rebrokeAdminFee,
      paymentFlowType);
  }
);

export const selectPolicyExtrasForEcommerce = createSelector(
  selectPaymentFlowType,
  selectQuoteExtras,
  selectRenewalPolicyExtras,
  (paymentFlowType, quoteExtras, renewalExtras) => {
    if (paymentFlowType === PaymentFlowType.QUOTE) {
      return getEcommerceExtrasValues(quoteExtras.map(extra => extra.optionType));
    }
    const extraOptionTypes = mapRenewalPolicyExtrasToQuoteExtras(renewalExtras).map(extra => extra.optionType);
    return getEcommerceExtrasValues(extraOptionTypes);
  }
);

function getEcommerceExtrasValues(optionTypes: PolicyExtraOptionType[]): EcommercePolicyExtra[] {
  return getEnabledPolicyExtras()
    .filter(option => optionTypes?.find(optionType => optionType === option.optionType))
    .map(extra => ({
      price: extra.price,
      optionType: extra.optionType,
      type: extra.type
    }));
}