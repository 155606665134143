import React, {FC} from "react";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {BooleanFormField} from "shared-components/dist/models/form-field/variants/BooleanFormField";
import BooleanQuestion from "../../../../../../structure/questions/question/variants/boolean-question/BooleanQuestion";
import NonMotoringConvictionsStop from "./NonMotoringConvictionsStop";

interface OwnProps {
  id: string;
  title: TranslationKey;
  nonMotoringConvictionsField: BooleanFormField;
  onComplete: (value: boolean) => void;
  isStopVisible: boolean;
}

const NonMotoringConvictionsQuestion: FC<OwnProps> = (
  {
    id,
    title,
    nonMotoringConvictionsField,
    onComplete,
    isStopVisible
  }
) => {
  return (
    <>
      <BooleanQuestion
        id={id}
        title={title}
        formField={nonMotoringConvictionsField}
        onAnswer={onComplete}
        tooltip={{
          description: "quoteJourney.shared.nonMotoringConvictionsQuestion.tooltip.description"
        }}
      />
      <NonMotoringConvictionsStop
        isVisible={isStopVisible}
      />
    </>
  );
};
export default NonMotoringConvictionsQuestion;