import {updateUserAttribute} from "../update/UpdateUserAttributeService";
import {UpdateUserAttributeResult} from "../../models/UpdateUserAttributeResult";
import {getCurrentAuthenticatedUser} from "../../../authentication/services/UserAuthenticationService";
import {verifyUserAttribute} from "../verify/VerifyUserAttributeService";
import {sendAttributeVerificationCode} from "../send-code/SendAttributeVerificationCodeService";
import {VerifyUserAttributeResult} from "../../models/VerifyUserAttributeResult";
import {EMAIL_ATTRIBUTE} from "../../Constants";
import {SendAttributeVerificationResult} from "../../models/SendAttributeVerificationResult";
import {InternalCognitoUser} from "../get/GetUserAttributesService";

export async function updateEmailAddress(newEmailAddress: string): Promise<UpdateUserAttributeResult> {
  const authenticatedUser = await getCurrentAuthenticatedUser();
  if (!authenticatedUser) return "Failed";

  const updateResult = await updateUserAttribute({
    cognitoUser: authenticatedUser as InternalCognitoUser,
    attributeName: EMAIL_ATTRIBUTE,
    newAttributeValue: newEmailAddress
  });
  if (updateResult !== "Success") return updateResult;

  return await sendAttributeVerificationCode(authenticatedUser, EMAIL_ATTRIBUTE);
}

export async function verifyEmailAddress(verificationCode: string): Promise<VerifyUserAttributeResult> {
  const authenticatedUser = await getCurrentAuthenticatedUser();
  if (!authenticatedUser) return "Failed";

  return await verifyUserAttribute({
    cognitoUser: authenticatedUser,
    attributeName: EMAIL_ATTRIBUTE,
    verificationCode
  });
}

export async function sendEmailVerificationCode(): Promise<SendAttributeVerificationResult> {
  const authenticatedUser = await getCurrentAuthenticatedUser();
  if (!authenticatedUser) return "Failed";

  return await sendAttributeVerificationCode(authenticatedUser, EMAIL_ATTRIBUTE);
}