import {
  primaryEmploymentIndustryInvalid,
  primaryEmploymentJobInvalid,
  primaryEmploymentStatusInvalid,
  primaryEmploymentWorkedInPastYearInvalid
} from "../redux/UpdateAdditionalDriverSlice";
import {AppThunkDispatch} from "../../../../../../redux/Store";
import {isValidFormField} from "shared-components/dist/models/form-field/FormField";
import {isEmployed, isRetired, isUnemployed} from "../../../shared/validation/ValidateEmploymentStatus";
import {UpdateAdditionalDriver} from "../redux/UpdateAdditionalDriver";

export function flagUnansweredPrimaryEmploymentQuestions(additionalDriver: UpdateAdditionalDriver, dispatch: AppThunkDispatch): void {
  if (additionalDriver.primaryEmployment.employmentStatus.status === "default") {
    dispatch(primaryEmploymentStatusInvalid("quote.errors.questionIncomplete"));
  } else if (isEmployed(additionalDriver.primaryEmployment.employmentStatus.value)) {
    checkIndustryQuestion(additionalDriver, dispatch);
    checkJobQuestion(additionalDriver, dispatch);
  } else if (isRetired(additionalDriver.primaryEmployment.employmentStatus.value)) {
    checkJobQuestion(additionalDriver, dispatch);
  } else if (isUnemployed(additionalDriver.primaryEmployment.employmentStatus.value)) {
    checkUnemployedQuestions(additionalDriver, dispatch);
  }
}

function checkIndustryQuestion({primaryEmployment}: UpdateAdditionalDriver, dispatch: AppThunkDispatch): void {
  if (!isValidFormField(primaryEmployment.industry)) dispatch(primaryEmploymentIndustryInvalid("quote.errors.questionIncomplete"));
}

function checkJobQuestion({primaryEmployment}: UpdateAdditionalDriver, dispatch: AppThunkDispatch): void {
  if (!isValidFormField(primaryEmployment.job)) dispatch(primaryEmploymentJobInvalid("quote.errors.questionIncomplete"));
}

function checkUnemployedQuestions(additionalDriver: UpdateAdditionalDriver, dispatch: AppThunkDispatch): void {
  checkWorkedInPastYearQuestion(additionalDriver, dispatch);
  if (additionalDriver.primaryEmployment.workedInPastYear.value === true) {
    checkJobQuestion(additionalDriver, dispatch);
  }
}

function checkWorkedInPastYearQuestion({primaryEmployment}: UpdateAdditionalDriver, dispatch: AppThunkDispatch): void {
  if (!isValidFormField(primaryEmployment.workedInPastYear)) dispatch(primaryEmploymentWorkedInPastYearInvalid("quote.errors.questionIncomplete"));
}
