import React, {FC} from "react";
import {Vehicle} from "../../../../../../graphql/queries/vehicle-lookup/registration-lookup/model/Vehicle";
import {Close} from "@mui/icons-material";
import Button from "shared-components/dist/buttons/button/Button";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import "./VehicleSummaryCard.css";
import VehicleDetailsList from "../vehicle-details-list/VehicleDetailsList";
import {logYourCarGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {formatRegistrationNumber} from "../../../../../../utils/validation/RegistrationNumber";
import PlainCard from "shared-components/dist/cards/plain-card/PlainCard";

interface Props {
  vehicle: Vehicle | undefined;
  onVehicleRejection?: () => void;
  buttonText: TranslationKey;
}

const VehicleSummaryCard: FC<Props> = (
  {
    vehicle,
    onVehicleRejection,
    buttonText
  }
) => {
  if (!vehicle) return null;

  const title = `${vehicle.make} ${vehicle.model}`;
  const description = vehicleDetailsFrom(vehicle);

  function onNotMyCarClicked(): void {
    onVehicleRejection?.();
    logYourCarGoogleAnalyticsEvent({
      categorySuffix: "not_my_car",
      action: "Click"
    });
  }

  return (
    <PlainCard>
      <div className="vehicle-summary-header">
        <div>
          <h4>{title}</h4>
          <p>{description}</p>
        </div>

        {onVehicleRejection && (
          <Button
            variant="pill"
            onClick={onNotMyCarClicked}
            rightIcon={<Close/>}
          >
            {lookupI18nString(buttonText)}
          </Button>
        )}
      </div>

      <VehicleDetailsList
        title="vehicleQuote.vehicleAssumptions.listTitle"
      />
    </PlainCard>
  );
};

function vehicleDetailsFrom(vehicle: Vehicle): string {
  const vehicleDetails = [
    vehicle.trim,
    vehicle.bodyStyle,
    vehicle.fuelType,
    vehicle.yearOfManufacture,
    vehicle.transmissionType,
  ];

  if (vehicle.engineCapacityCc && Number(vehicle.engineCapacityCc) > 0) vehicleDetails.unshift(`${vehicle.engineCapacityCc}cc`);

  if (vehicle.registration) vehicleDetails.unshift(formatRegistrationNumber(vehicle.registration));

  return vehicleDetails
    .filter(item => item !== "")
    .join(", ");
}

export default VehicleSummaryCard;
