import React, {FC} from "react";
import {previouslyHeldInsuranceChanged} from "../../redux/CoverDetailsSlice";
import BooleanQuestion from "../../../../../../structure/questions/question/variants/boolean-question/BooleanQuestion";
import {mapValueToYesOrNoEventType} from "shared-components/dist/analytics/GoogleAnalyticsEventsService";
import {logYourCoverGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import NamedDriverAndCompanyCarQuestionPanel from "../named-driver/NamedDriverAndCompanyCarQuestionPanel";
import {isNamedDriverAXAEligibleSelector} from "../../redux/selectors/named-driver/IsNamedDriverAXAEligible";
import {useValidateNamedDriverFlow} from "../../hooks/ValidateNamedDriverFlow";
import NamedDriverIneligiblePanel
  from "../named-driver/shared/named-driver-ineligible-panel/NamedDriverIneligiblePanel";
import "./PreviouslyHeldInsurance.css";
import {
  selectIsVehiclePurchasedMoreThan14DaysAgo
} from "../../redux/selectors/named-driver/VehicleBreakInCoverSelectors";
import {useValidateNamedDriverBreakInCoverFlow} from "../../hooks/ValidateNamedDriverBreakInCoverFlow";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const PreviouslyHeldInsuranceQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const previouslyHeldInsuranceField = useAppSelector(state => state.coverDetails.previouslyHeldInsurance);
  const isEligibleForNamedDriver = useAppSelector(isNamedDriverAXAEligibleSelector);
  const isVehiclePurchasedMoreThan14DaysAgo = useAppSelector(selectIsVehiclePurchasedMoreThan14DaysAgo);
  const hasEnteredNamedDriverFlow = useAppSelector(state => state.coverDetails.hasEnteredNamedDriverFlow);

  const isPreviouslyInsured = previouslyHeldInsuranceField.value;
  const shouldShowNamedDriverIneligiblePanel = !isPreviouslyInsured && !isEligibleForNamedDriver && hasEnteredNamedDriverFlow;
  const shouldShowNamedDriverAndCompanyCarQuestionPanel = isPreviouslyInsured || (isPreviouslyInsured === false && isEligibleForNamedDriver);
  useValidateNamedDriverFlow();
  useValidateNamedDriverBreakInCoverFlow();

  const onPreviouslyHeldInsuranceChanged = (value: boolean): void => {
    logYourCoverGoogleAnalyticsEvent({
      categorySuffix: "previous_car_insurance",
      action: mapValueToYesOrNoEventType(value),
    });

    dispatch(previouslyHeldInsuranceChanged({value, isEligibleForNamedDriver, isVehiclePurchasedMoreThan14DaysAgo}));
  };

  return (
    <>
      <BooleanQuestion
        id="previously-held-insurance-question"
        title={"coverDetails.previouslyHeldInsuranceQuestion.title.new"}
        formField={previouslyHeldInsuranceField}
        onAnswer={onPreviouslyHeldInsuranceChanged}
        description={"coverDetails.previouslyHeldInsuranceQuestion.description"}
      />
      <>
        {shouldShowNamedDriverIneligiblePanel &&
          <NamedDriverIneligiblePanel className="previously-held-insurance-ineligible-panel"/>}
        {shouldShowNamedDriverAndCompanyCarQuestionPanel && <NamedDriverAndCompanyCarQuestionPanel/>}
      </>
    </>
  );
};

export default PreviouslyHeldInsuranceQuestion;