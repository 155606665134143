import React, {FC} from "react";
import {
  ADDITIONAL_DRIVER_CLAIMED_POLICY_DESCRIPTIONS,
  ADDITIONAL_DRIVER_CLAIMED_POLICY_OPTIONS,
  CLAIMED_POLICIES,
  WhichPolicyClaimedAgainstOption,
  PROPOSER_CLAIMED_POLICY_DESCRIPTIONS,
  PROPOSER_CLAIMED_POLICY_OPTIONS
} from "../models/WhichPolicyClaimedAgainstOption";
import DropdownSubQuestion
  from "../../../../../../../structure/questions/sub-question/variants/dropdown-sub-question/DropdownSubQuestion";
import {ClaimsFormSubQuestionProps} from "./ClaimsFormSubQuestionProps";

type Props = ClaimsFormSubQuestionProps<WhichPolicyClaimedAgainstOption>;

const ClaimedPolicySubQuestion: FC<Props> = (
  {
    value,
    onChange,
    status,
    isAdditionalDriverForm
  }
) => {
  const onIncidentPolicySelected = (selection: string): void => {
    const selectedIncidentPolicy = CLAIMED_POLICIES.find(policy => policy.vtCode === selection);
    if (!selectedIncidentPolicy) return;

    onChange(selectedIncidentPolicy);
  };

  return (
    <DropdownSubQuestion
      id="claimed-policy-sub-question"
      title={
        isAdditionalDriverForm
          ? "additionalDriver.claims.claimedPolicy.title"
          : "personalDetails.claims.claimedPolicy.title"
      }
      tooltip={{
        description: isAdditionalDriverForm
          ? "additionalDriver.claims.claimedPolicy.tooltip.description"
          : "personalDetails.claims.claimedPolicy.tooltip.description"
      }}
      errorMessage="quote.errors.questionIncomplete"
      status={status}
      value={value?.vtCode}
      options={
        isAdditionalDriverForm
          ? ADDITIONAL_DRIVER_CLAIMED_POLICY_OPTIONS
          : PROPOSER_CLAIMED_POLICY_OPTIONS
      }
      displayValues={
        isAdditionalDriverForm
          ? ADDITIONAL_DRIVER_CLAIMED_POLICY_DESCRIPTIONS
          : PROPOSER_CLAIMED_POLICY_DESCRIPTIONS
      }
      onChange={onIncidentPolicySelected}
    />
  );
};

export default ClaimedPolicySubQuestion;