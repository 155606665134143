import {QuoteConvictionDetails} from "shared/dist/generated/graphql/resolvers-types";
import {SerializableConviction} from "../../../../quote/vehicle/shared/questions/convictions/models/Conviction";
import {ALCOHOL_TEST_METHODS} from "../../../../quote/vehicle/shared/questions/convictions/models/AlcoholTestMethods";
import {calculateDuration} from "../ReduxHelpers";

export function buildConviction(conviction: QuoteConvictionDetails): SerializableConviction {
  return {
    date: conviction.date,
    type: conviction.type,
    alcoholLevel: conviction.sampleLevel ?? undefined,
    drivingBan: calculateDuration(conviction.disqualificationPeriodMonths),
    fine: conviction.fine,
    offence: conviction.offence,
    penaltyPoints: conviction.penaltyPoints,
    testMethod: ALCOHOL_TEST_METHODS.find(method => method.id === conviction.sampleType)
  };
}