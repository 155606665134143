import {AbiListItem} from "shared/dist/generated/graphql/resolvers-types";

export const COMMON_TITLES: AbiListItem[] = [
  {
    vt: "",
    vtCode: "Mr",
    vtDescription: "Mr"
  },
  {
    vt: "",
    vtCode: "Mrs",
    vtDescription: "Mrs"
  },
  {
    vt: "",
    vtCode: "Ms",
    vtDescription: "Ms"
  },
  {
    vt: "",
    vtCode: "Miss",
    vtDescription: "Miss"
  },
];