import {FC} from "react";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {Info} from "@mui/icons-material";

export interface PolicyHolderAddressProps {
  address: string;
}

const PolicyHolderAddress: FC<PolicyHolderAddressProps> = (props) => <>
  <div className="request-print-documents-modal__body__policy-holder-address">
    <p className="request-print-documents-modal__body__policy-holder-address__title">
      {lookupI18nString("policies.documents.requestPrintedDocuments.modal.address.title")}
    </p>
    <h5 className="request-print-documents-modal__body__policy-holder-address__content">
      {props.address}
    </h5>
  </div>
  <div className="request-print-documents-modal__body__policy-holder-address__old-address">
    <Info/>
    <h5 className="request-print-documents-modal__body__policy-holder-address__old-address__title">
      {lookupI18nString("policies.documents.requestPrintedDocuments.modal.oldAddress.description")}
    </h5>
  </div>
</>;

export default PolicyHolderAddress;