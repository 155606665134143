import React, {FC} from "react";
import {AbiListItem} from "shared/dist/generated/graphql/resolvers-types";
import {FormField} from "shared-components/dist/models/form-field/FormField";
import {vtDescriptionsFrom} from "../../../../../../utils/forms/descriptionsFrom";
import RadioSelectQuestion from "../../../../../../structure/questions/question/variants/radio-select-question/RadioSelectQuestion";
import {COMMON_TITLES} from "./CommonTitles";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {useTitles} from "../../../your-details/components/title/queries/titles-lookup/TitlesLookup";

interface OwnProps {
  id: string;
  questionTitle: TranslationKey;
  titleField: FormField<AbiListItem>;
  titleChanged: (title: AbiListItem) => void;
  titleReset: () => void;
  placeholder: TranslationKey;
  hideSpacer?: boolean;
}

const TitleQuestion: FC<OwnProps> = (
  {
    id,
    questionTitle,
    titleField,
    titleChanged,
    titleReset,
    placeholder,
    hideSpacer = false
  }
) => {
  const {loading, data} = useTitles();

  const setTitle = (selectedTitle: string | undefined): void => {
    if (!selectedTitle) return titleReset();

    const titles = !loading && data ? data.quote.abiCodes.titles : [];
    const allTitles = COMMON_TITLES.concat(titles);
    const title = allTitles.find(title => title.vtDescription === selectedTitle);

    if (title) titleChanged(title);
  };

  return (
    <RadioSelectQuestion
      id={id}
      title={questionTitle}
      status={titleField.status}
      errorMessage={titleField.errorMessage}
      primaryOptions={vtDescriptionsFrom(COMMON_TITLES)}
      secondaryOptions={!loading && data ? vtDescriptionsFrom(data.quote.abiCodes.titles) : []}
      dropdownPlaceholder={placeholder}
      onSelection={setTitle}
      selectedOption={titleField.value?.vtDescription}
      hideSpacer={hideSpacer}
      isFourColumns
    />
  );
};

export default TitleQuestion;