import {AppThunkDispatch, RootState} from "../../../../../../redux/Store";
import {
  insurerQuotesChanged,
  magicLinkIdChanged,
  quoteErrorChanged,
  quoteErrorCleared,
  quoteRetrievalStatusChanged
} from "../QuoteDetailsSlice";
import {logGeneralQuoteGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {logger} from "../../../../../../utils/logging/Logger";
import {QuoteRetrievalStatus} from "../../models/QuoteRetrievalStatus";
import {QuotesResult} from "shared/dist/generated/graphql/resolvers-types";
import {currentQuoteQueryArgsSelector} from "../selectors/QuoteDetailsSelectors";
import {getQuotesMutation} from "../../../../../../graphql/mutations/quote/get-quotes/GetQuotesMutation";
import {QuotedLoaded} from "../../models/QuoteLoaded";

export async function getQuotes(dispatch: AppThunkDispatch, state: RootState): Promise<QuotedLoaded> {
  try {
    const quoteArguments = currentQuoteQueryArgsSelector(state);
    const result = await getQuotesMutation(quoteArguments);

    if (!result) {
      await dispatch(quoteErrorChanged("No result returned"));
      logGeneralQuoteGoogleAnalyticsEvent({
        categorySuffix: "quote_provided",
        action: "Failed"
      });
      return QuotedLoaded.FAILURE;
    }

    if (!result.quotes || !result.quotes.length) {
      updateFailedQuoteStatus(dispatch, result.magicLinkId);
      return QuotedLoaded.FAILURE;
    }

    updateSuccessfulQuotes(dispatch, result, result.magicLinkId);
    return QuotedLoaded.SUCCESS;
  } catch (error) {
    logger.error("Unable to generate new quote: ", error);
    dispatch(quoteErrorChanged(error.message ?? "Unknown error"));
    dispatch(quoteRetrievalStatusChanged(QuoteRetrievalStatus.NOT_FOUND));
    logGeneralQuoteGoogleAnalyticsEvent({
      categorySuffix: "quote_provided",
      action: "Failed"
    });
    return QuotedLoaded.FAILURE;
  }
}

function updateFailedQuoteStatus(dispatch: AppThunkDispatch, magicLinkId: string): void {
  dispatch(magicLinkIdChanged(magicLinkId));
  dispatch(quoteRetrievalStatusChanged(QuoteRetrievalStatus.NOT_FOUND));
  dispatch(quoteErrorChanged("No quotes found"));
  logGeneralQuoteGoogleAnalyticsEvent({
    categorySuffix: "quote_provided",
    action: "Failed"
  });
}

function updateSuccessfulQuotes(dispatch: AppThunkDispatch, result: QuotesResult, magicLinkId: string): void {
  const insurerQuotes = result.quotes ?? [];
  dispatch(magicLinkIdChanged(magicLinkId));
  dispatch(insurerQuotesChanged(insurerQuotes));
  dispatch(quoteErrorCleared());
  logGeneralQuoteGoogleAnalyticsEvent({
    categorySuffix: "quote_provided",
    action: "Success"
  });
}
