import {isNamedDriverAXAEligibleSelector} from "./IsNamedDriverAXAEligible";
import {RootState} from "../../../../../../../redux/Store";
import {createSelector} from "@reduxjs/toolkit";
import {isCompanyCarBonusValid} from "../helpers/IsCompanyCarBonusValid";

const coverStartDate = (state: RootState): Date | undefined => state.coverDetails.coverStartDate.value ? new Date(state.coverDetails.coverStartDate.value.date) : undefined;
const companyCarBonusExpiryDate = (state: RootState): Date | undefined => state.coverDetails.companyCarPolicyExpiryDate.value ? new Date(state.coverDetails.companyCarPolicyExpiryDate.value) : undefined;

export const selectIsCompanyCarBonusInvalid = createSelector(
  isNamedDriverAXAEligibleSelector,
  coverStartDate,
  companyCarBonusExpiryDate,
  (
    isEligibleForNamedDriver: boolean,
    coverStartDate: Date | undefined,
    companyCarBonusExpiryDate: Date | undefined
  ): boolean => {
    if (coverStartDate === undefined || companyCarBonusExpiryDate === undefined) return false;
    const isCompanyCarBonusExpiryInvalid = !isCompanyCarBonusValid(coverStartDate, companyCarBonusExpiryDate);

    return isEligibleForNamedDriver && isCompanyCarBonusExpiryInvalid;
  }
);