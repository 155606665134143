import {
  EMAIL_ATTRIBUTE,
  EMAIL_VERIFIED_ATTRIBUTE,
  PHONE_NUMBER_ATTRIBUTE,
  PHONE_NUMBER_VERIFIED_ATTRIBUTE
} from "../../Constants";
import {CognitoUser, CognitoUserAttribute} from "amazon-cognito-identity-js";
import {getCurrentAuthenticatedUser} from "../../../authentication/services/UserAuthenticationService";
import {getUserAttributes, InternalCognitoUser} from "./GetUserAttributesService";
import {logger} from "../../../../utils/logging/Logger";

export interface UserDetails {
  phoneNumber: string;
  isPhoneNumberUnverified: boolean;
  email: string;
  isEmailUnverified: boolean;
}

export async function getCurrentUserDetails(): Promise<UserDetails | undefined> {
  const currentUser = await getCurrentAuthenticatedUser();
  if (!currentUser) return undefined;

  return getUserDetails(currentUser);
}

async function getUserDetails(cognitoUser: CognitoUser): Promise<UserDetails | undefined> {
  const userAttributes = await getUserAttributes(cognitoUser as InternalCognitoUser);
  if (!userAttributes) return undefined;

  return getUserDetailsFromAttributes(userAttributes);
}

function getUserDetailsFromAttributes(userAttributes: CognitoUserAttribute[]): UserDetails | undefined {
  const phoneNumber = getUserAttribute(userAttributes, PHONE_NUMBER_ATTRIBUTE);
  const phoneNumberVerified = getUserAttribute(userAttributes, PHONE_NUMBER_VERIFIED_ATTRIBUTE);
  const email = getUserAttribute(userAttributes, EMAIL_ATTRIBUTE);
  const emailVerified = getUserAttribute(userAttributes, EMAIL_VERIFIED_ATTRIBUTE);

  if (!phoneNumber || !phoneNumberVerified || !email || !emailVerified) {
    logger.error("Could not find all user details within user attributes");
    return undefined;
  }

  return {
    phoneNumber: phoneNumber.getValue(),
    isPhoneNumberUnverified: isUserAttributeFalse(phoneNumberVerified),
    email: email.getValue(),
    isEmailUnverified:  isUserAttributeFalse(emailVerified)
  };
}

function getUserAttribute(userAttributes: CognitoUserAttribute[], attributeName: string): CognitoUserAttribute | undefined {
  return userAttributes?.find(userAttribute => userAttribute.getName() === attributeName) ?? undefined;
}

const isUserAttributeFalse = (userAttribute: CognitoUserAttribute): boolean => userAttribute.getValue().toLowerCase() === "false";
