import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  engineCapacityLitresSelected,
  fuelTypeSelected,
  fullSpecSelected,
  makeSelected,
  modelSelected,
  policyPurchased,
  quoteReset,
  storedQuoteLoaded,
  transmissionTypeSelected,
  trimLevelSelected,
  vehicleDetailsReset,
  yearOfManufactureSelected
} from "../../../../../redux/Actions";
import {allOrderedConditionalFormFields} from "shared-components/dist/models/form-field/FormFields";
import {conditionalFieldReset, fieldChanged, fieldInvalid} from "shared-components/dist/models/form-field/FormField";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {LoadedQuote} from "../../your-quote/models/LoadedQuote";
import {VehicleSpecLookupItem} from "shared/dist/generated/graphql/resolvers-types";
import {OrderedConditionalFormField} from "shared-components/dist/models/form-field/ConditionalFormField";
import {ManualVehicleLookupForm} from "./ManualVehicleLookupForm";

const MINIMUM_YEAR_OF_MANUFACTURE = 1980;

export const manualVehicleLookupFormInitialState: ManualVehicleLookupForm = {
  make: {
    status: "default",
    value: undefined,
    order: 1,
    isActive: false
  },
  model: {
    status: "default",
    value: undefined,
    order: 2,
    isActive: false
  },
  fuelType: {
    status: "default",
    value: undefined,
    order: 3,
    isActive: false
  },
  transmissionType: {
    status: "default",
    value: undefined,
    order: 4,
    isActive: false
  },
  yearOfManufacture: {
    status: "default",
    value: undefined,
    order: 5,
    isActive: false
  },
  engineCapacityLitres: {
    status: "default",
    value: undefined,
    order: 6,
    isActive: false
  },
  trimLevel: {
    status: "default",
    value: undefined,
    order: 7,
    isActive: false
  },
  fullSpec: {
    status: "default",
    value: undefined,
    order: 8,
    isActive: false,
  },
  registrationNumber: {
    status: "default",
    value: undefined,
    order: 9,
    isActive: false,
  }
};

const manualVehicleLookupFormSlice = createSlice({
  name: "manualVehicleLookupForm",
  initialState: manualVehicleLookupFormInitialState,
  reducers: {
    manualVehicleLookupFormOpened(state): void {
      state.make.isActive = true;
    },
    makeInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.make, action.payload);
    },
    modelInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.model, action.payload);
    },
    fuelTypeInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.fuelType, action.payload);
    },
    transmissionTypeInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.transmissionType, action.payload);
    },
    yearOfManufactureInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.yearOfManufacture, action.payload);
    },
    engineCapacityLitresInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.engineCapacityLitres, action.payload);
    },
    trimLevelInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.trimLevel, action.payload);
    },
    fullSpecInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.fullSpec, action.payload);
    },
    registrationNumberChanged(state, action: PayloadAction<string>): void {
      fieldChanged(state.registrationNumber, action.payload);
    },
    registrationNumberInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.registrationNumber, action.payload);
    }
  },
  extraReducers: {
    [quoteReset.type]: () => manualVehicleLookupFormInitialState,
    [vehicleDetailsReset.type]: () => manualVehicleLookupFormInitialState,
    [makeSelected.type]: (state, action: PayloadAction<string>): void => {
      fieldChanged(state.make, action.payload);
      resetAllSubsequentFields(state, state.make.order);
      setNextIsVisible(state, state.make.order);
    },
    [modelSelected.type]: (state, action: PayloadAction<string>): void => {
      fieldChanged(state.model, action.payload);
      resetAllSubsequentFields(state, state.model.order);
      setNextIsVisible(state, state.model.order);
    },
    [fuelTypeSelected.type]: (state, action: PayloadAction<string>): void => {
      fieldChanged(state.fuelType, action.payload);
      resetAllSubsequentFields(state, state.fuelType.order);
      setNextIsVisible(state, state.fuelType.order);
    },
    [transmissionTypeSelected.type]: (state, action: PayloadAction<string>): void => {
      fieldChanged(state.transmissionType, action.payload);
      resetAllSubsequentFields(state, state.transmissionType.order);
      setNextIsVisible(state, state.transmissionType.order);
    },
    [yearOfManufactureSelected.type]: (state, action: PayloadAction<string>): void => {
      fieldChanged(state.yearOfManufacture, action.payload);
      resetAllSubsequentFields(state, state.yearOfManufacture.order);
      if (!isProhibitedYearOfManufacture(action.payload)) setNextIsVisible(state, state.yearOfManufacture.order);
    },
    [engineCapacityLitresSelected.type]: (state, action: PayloadAction<string>): void => {
      fieldChanged(state.engineCapacityLitres, action.payload);
      resetAllSubsequentFields(state, state.engineCapacityLitres.order);
      setNextIsVisible(state, state.engineCapacityLitres.order);
    },
    [trimLevelSelected.type]: (state, action: PayloadAction<string>): void => {
      fieldChanged(state.trimLevel, action.payload);
      resetAllSubsequentFields(state, state.trimLevel.order);
      setNextIsVisible(state, state.trimLevel.order);
    },
    [fullSpecSelected.type]: (state, action: PayloadAction<VehicleSpecLookupItem>): void => {
      fieldChanged(state.fullSpec, action.payload);
    },
    [policyPurchased.type]: () => manualVehicleLookupFormInitialState,
    [storedQuoteLoaded.type]: (state, action: PayloadAction<LoadedQuote>) => action.payload.quoteArguments.manualVehicleLookupForm
  }
});

function resetAllSubsequentFields(state: ManualVehicleLookupForm, order: number): void {
  subsequentFields(state, order).forEach(conditionalFieldReset);
}

function setNextIsVisible(state: ManualVehicleLookupForm, order: number): void {
  const next = subsequentFields(state, order).find(element => element.order === order + 1);
  if (next) {
    next.isActive = true;
  }
}

export function subsequentFields(state: ManualVehicleLookupForm, order: number): OrderedConditionalFormField<unknown>[] {
  return allOrderedConditionalFormFields(state).filter(element => element.order > order);
}

export const isProhibitedYearOfManufacture = (yearOfManufacture: string | undefined): boolean => {
  return yearOfManufacture !== undefined && parseInt(yearOfManufacture) < MINIMUM_YEAR_OF_MANUFACTURE;
};

export const {
  manualVehicleLookupFormOpened,
  makeInvalid,
  modelInvalid,
  fuelTypeInvalid,
  transmissionTypeInvalid,
  yearOfManufactureInvalid,
  engineCapacityLitresInvalid,
  trimLevelInvalid,
  fullSpecInvalid,
  registrationNumberChanged,
  registrationNumberInvalid
} = manualVehicleLookupFormSlice.actions;

export const manualVehicleLookupFormReducer = manualVehicleLookupFormSlice.reducer;
