import {AtFault, CountryOfIssue, LicenceType, ProposedClassOfUse} from "shared/dist/generated/graphql/resolvers-types";
import {Claim, Claims} from "../../../../shared/questions/claims/models/Claims";
import {Convictions} from "../../../../shared/questions/convictions/models/Conviction";
import {yearsBetween} from "shared/dist/stdlib/Dates";
import {AdditionalDriver,} from "../../../../additional-driver/shared/models/AdditionalDriver";
import {createSelector} from "@reduxjs/toolkit";
import {
  additionalDriversSelector,
  claimsSelector,
  convictionsSelector,
  dateOfBirthAsDateSelector,
  licenceCountryOfIssueSelector,
  licenceDurationSelector,
  licenceTypeSelector,
  primaryOccupationSelector,
  secondaryOccupationSelector,
  solicitingSelector
} from "../../../../your-details/redux/selectors/PersonalDetailsSelectors";
import {mainDriverSelector} from "../../../../your-details/redux/selectors/ProhibitedMainDriverStopVisibleSelector";
import {DateFormField} from "shared-components/dist/models/form-field/variants/DateFormField";
import {FormField, RequiredFormField} from "shared-components/dist/models/form-field/FormField";
import {LicenceTypeOption} from "../../../../shared/questions/licence-type/LicenceTypeOption";
import {LicenceCountryOfIssue} from "../../../../shared/questions/licence-country-of-issue/LicenceCountryOfIssue";
import {ConditionalFormField} from "shared-components/dist/models/form-field/ConditionalFormField";
import {CustomisedAbiListItem} from "shared-components/dist/models/CustomisedAbiListItem";
import {NDE_INELIGIBLE_OCCUPATION_CODES} from "./InvalidOccupationCodes";
import {isWorkingRelationshipToProposer} from "shared/dist/class-of-use/RelationshipToProposerHelpers";

const MAX_NDE_YEARS_BONUS = 5;
const MAX_NUM_CONVICTIONS = 2;
const MAX_NUM_PENALTY_POINTS = 3;
const PROPOSER_MIN_AGE_LIMIT = 19;
const ADDITIONAL_DRIVER_MIN_AGE_LIMIT = 25;
const MAX_AGE_LIMIT = 90;
const MIN_LICENCE_LENGTH_YEARS = 1;

export const maxNDEYearsForLicenceDurationSelector = createSelector(
  additionalDriversSelector,
  licenceDurationSelector,
  (additionalDrivers: AdditionalDriver[], proposerLicenceDuration: FormField<Duration>): number => {
    const licenceDurations = additionalDrivers.map(additionalDriver => additionalDriver.licenceDuration.years ?? 0);
    licenceDurations.push(proposerLicenceDuration.value?.years ?? 0);

    const leastExperiencedLicenceDuration = Math.min(...licenceDurations);

    if (leastExperiencedLicenceDuration < MIN_LICENCE_LENGTH_YEARS) {
      return 0;
    }
    return leastExperiencedLicenceDuration > MAX_NDE_YEARS_BONUS ? MAX_NDE_YEARS_BONUS : leastExperiencedLicenceDuration;
  }
);

export const maxNDEYearsForClaimsSelector = createSelector(
  additionalDriversSelector,
  claimsSelector,
  (additionalDrivers: AdditionalDriver[], proposerClaims: RequiredFormField<Claims>): number => {
    const claimDates = additionalDrivers.filter(driver => driver.claims !== null).flatMap(driver => getAtFaultClaimDates(driver.claims));
    if (proposerClaims !== null) claimDates.push(...getAtFaultClaimDates(proposerClaims.value.claims));

    if (claimDates.length) {
      const mostRecentClaimDate = claimDates.sort((a, b) => b.getTime() - a.getTime())[0];
      const timeSinceLastClaim = yearsBetween(mostRecentClaimDate, new Date());
      return timeSinceLastClaim > MAX_NDE_YEARS_BONUS ? MAX_NDE_YEARS_BONUS : timeSinceLastClaim;
    }

    return MAX_NDE_YEARS_BONUS;
  }
);

export const allDriversMeetConvictionsRestrictionsSelector = createSelector(
  additionalDriversSelector,
  convictionsSelector,
  (additionalDrivers: AdditionalDriver[], proposerConvictions: RequiredFormField<Convictions>): boolean => {
    const convictions = additionalDrivers.filter(driver => driver.convictions !== null).flatMap(driver => driver.convictions);
    if (proposerConvictions !== null) convictions.push(...proposerConvictions.value.convictions);

    return convictions.length <= MAX_NUM_CONVICTIONS && convictions.every(conviction => conviction.penaltyPoints <= MAX_NUM_PENALTY_POINTS);
  }
);

export const allDriversMeetAgeLimitsSelector = createSelector(
  additionalDriversSelector,
  mainDriverSelector,
  dateOfBirthAsDateSelector,
  (additionalDrivers: AdditionalDriver[], mainDriverId: string | undefined, proposerDateOfBirth: DateFormField): boolean => {
    if (proposerDateOfBirth.value === undefined) return false;
    const proposerAge = getDriverAge(proposerDateOfBirth.value);

    if (proposerAge < PROPOSER_MIN_AGE_LIMIT || proposerAge > MAX_AGE_LIMIT) {
      return false;
    }

    if (additionalDrivers.length) {
      return additionalDrivers.every(driver => isAdditionalDriverWithinAgeLimits(driver, mainDriverId));
    }
    return true;
  }
);

export const allDriversMeetLicenceTypeRestrictionsSelector = createSelector(
  additionalDriversSelector,
  licenceTypeSelector,
  (additionalDrivers: AdditionalDriver[], proposerLicenceType: FormField<LicenceTypeOption>): boolean => {
    return proposerLicenceType.value?.id === LicenceType.Full && additionalDrivers.every(driver => driver.licenceType.id === LicenceType.Full);
  }
);

export const allDriversMeetLicenceCountryRestrictionsSelector = createSelector(
  additionalDriversSelector,
  licenceCountryOfIssueSelector,
  (additionalDrivers: AdditionalDriver[], proposerLicenceCountryOfIssue: FormField<LicenceCountryOfIssue>): boolean => {
    const licenceCountries = [proposerLicenceCountryOfIssue.value, ...additionalDrivers.map(driver => driver.licenceCountryOfIssue)];
    return licenceCountries.every(country => country?.id === CountryOfIssue.UnitedKingdom);
  }
);

export const allDriversMeetClassOfUseRestrictionsSelector = createSelector(
  additionalDriversSelector,
  solicitingSelector,
  (additionalDrivers: AdditionalDriver[], isProposerSoliciting: FormField<boolean>): boolean => {
    if (isProposerSoliciting.value === true) return false;

    return additionalDrivers.every(driver => {
      if (isWorkingRelationshipToProposer(driver.relationshipToProposer.id)) {
        return driver.classOfUse.id === ProposedClassOfUse.Social;
      }
      return true;
    });
  }
);

export const proposerHasEligibleOccupationSelector = createSelector(
  primaryOccupationSelector,
  secondaryOccupationSelector,
  (primaryJob: ConditionalFormField<CustomisedAbiListItem>, secondaryJob: ConditionalFormField<CustomisedAbiListItem>): boolean => {
    const primaryJobCode = primaryJob?.value?.vtCode;
    if (primaryJobCode === undefined) return true;

    if (!hasValidOccupation(primaryJobCode)) {
      return false;
    }

    const secondaryJobCode = secondaryJob?.value?.vtCode;
    if (secondaryJobCode === undefined) return true;

    return hasValidOccupation(secondaryJobCode);
  }
);

export const isNamedDriverAXAEligibleSelector = createSelector(
  maxNDEYearsForLicenceDurationSelector,
  maxNDEYearsForClaimsSelector,
  allDriversMeetConvictionsRestrictionsSelector,
  allDriversMeetAgeLimitsSelector,
  allDriversMeetLicenceTypeRestrictionsSelector,
  allDriversMeetLicenceCountryRestrictionsSelector,
  allDriversMeetClassOfUseRestrictionsSelector,
  proposerHasEligibleOccupationSelector,
  (
    maxNDEYearsForLicenceDuration: number,
    maxNDEYearsForClaims: number,
    allDriversMeetConvictions: boolean,
    allDriversMeetAgeLimits: boolean,
    allDriversMeetLicenceType: boolean,
    allDriversMeetLicenceCountry: boolean,
    allDriversMeetClassOfUse: boolean,
    proposerHasEligibleOccupation: boolean): boolean => {
    return maxNDEYearsForLicenceDuration !== 0
      && maxNDEYearsForClaims !== 0
      && allDriversMeetConvictions
      && allDriversMeetAgeLimits
      && allDriversMeetLicenceType
      && allDriversMeetLicenceCountry
      && allDriversMeetClassOfUse
      && proposerHasEligibleOccupation;
  }
);

function getAtFaultClaimDates(claims: Claim[]): Date[] {
  return claims.filter(claim => claim.atFault.vtCode !== AtFault.NoBlame).map(claim => claim.date);
}

function getDriverAge(dateOfBirth: Date): number {
  return yearsBetween(dateOfBirth, new Date());
}

function isAdditionalDriverWithinAgeLimits(driver: AdditionalDriver, mainDriverId: string | undefined): boolean {
  const driverAge = getDriverAge(driver.dateOfBirth);
  if (driverAge > MAX_AGE_LIMIT) return false;
  if (driver.id !== mainDriverId && driverAge < ADDITIONAL_DRIVER_MIN_AGE_LIMIT) return false;
  return true;
}

function hasValidOccupation(jobCode: string): boolean {
  return NDE_INELIGIBLE_OCCUPATION_CODES.find(code => code === jobCode) === undefined;
}

export const maxYearsForAXAEligibilitySelector = createSelector(
  maxNDEYearsForLicenceDurationSelector,
  maxNDEYearsForClaimsSelector,
  (maxNDEYearsForLicenceDuration: number,
   maxNDEYearsForClaims: number,
  ): number => {
    return Math.min(maxNDEYearsForLicenceDuration, maxNDEYearsForClaims);
  }
);