import React, {FC} from "react";
import {hasTrackerChanged} from "../../redux/VehicleDetailsSlice";
import {mainHasTrackerQuestionVisibleSelector} from "../../redux/selectors/VehiclePageStateSelectors";
import BooleanQuestion from "../../../../../../structure/questions/question/variants/boolean-question/BooleanQuestion";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const MainVehicleHasTrackerQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const mainHasTrackerQuestion = useAppSelector(state => state.vehicleDetails.hasTracker);
  const isVisible = useAppSelector(mainHasTrackerQuestionVisibleSelector);

  if (!isVisible) return null;

  return (
    <BooleanQuestion
      id="main-vehicle-tracker"
      title="vehicleQuote.trackerQuestion"
      formField={mainHasTrackerQuestion}
      onAnswer={value => dispatch(hasTrackerChanged(value))}
    />
  );
};

export default MainVehicleHasTrackerQuestion;
