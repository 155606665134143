import React, {FC} from "react";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import Button from "shared-components/dist/buttons/button/Button";
import {LOGIN} from "../../../router/models/Routes";
import {useNavigate} from "react-router-dom";
import {logNavigationGoogleAnalyticsEvent} from "../../../utils/analytics/NavigationAnalytics";

interface Props {
  variant?: "primary" | "ghost"
}

const LoginButton: FC<Props> = ({variant}) => {
  const navigate = useNavigate();

  const redirectToLoginPage = (): void => {
    logNavigationGoogleAnalyticsEvent({
      categorySuffix: "login",
      action: "Click"
    });
    navigate(LOGIN);
  };

  return (
    <div className="login-button">
      <Button variant={variant} small onClick={redirectToLoginPage}>
        {lookupI18nString("structure.navigation.buttons.login")}
      </Button>
    </div>
  );
};

export default LoginButton;
