import React, {FC} from "react";
import {transmissionTypeSelected} from "../../../../../../../redux/Actions";
import {StringRadioColumnSubQuestion} from "../../../../../../../structure/questions/sub-question/variants/radio-column-sub-question/variants/StringRadioColumnSubQuestion";
import {transmissionTypeInvalid} from "../../../redux/ManualVehicleLookupFormSlice";
import {useTransmissionTypes} from "../../../../../../../graphql/queries/vehicle-lookup/manual-vehicle-lookup/ManualVehicleLookup";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const YourVehicleTransmissionType: FC = () => {
  const dispatch = useAppDispatch();
  const makeValue = useAppSelector(state => state.manualVehicleLookupForm.make.value);
  const modelValue = useAppSelector(state => state.manualVehicleLookupForm.model.value);
  const fuelTypeValue = useAppSelector(state => state.manualVehicleLookupForm.fuelType.value);
  const transmissionType = useAppSelector(state => state.manualVehicleLookupForm.transmissionType);

  const {error, loading, data} = useTransmissionTypes(makeValue, modelValue, fuelTypeValue);

  const fetchError = !!(!loading && error);

  if (fetchError) dispatch(transmissionTypeInvalid("vehicleQuote.manualVehicleLookup.errors.transmissionTypesFetchFailed"));

  const radioOptions = !loading && data
    ? data.vehicle.vehicleOptions.transmissionType
    : [];

  return (
    <StringRadioColumnSubQuestion
      subQuestionProps={{
        title: "vehicleQuote.manualVehicleLookup.transmissionType",
        id: "manual-vehicle-transmission-type",
        errorMessage: transmissionType.errorMessage,
        status: loading ? "loading" : transmissionType.status
      }}
      radioColumnProps={{
        name: "manual-vehicle-transmission-type-radio-column",
        options: radioOptions,
        selectedOption: transmissionType.value,
        onSelection: transmissionType => dispatch(transmissionTypeSelected(transmissionType)),
        disabled: fetchError
      }}
    />
  );
};

export default YourVehicleTransmissionType;
