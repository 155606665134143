import React, {cloneElement, FC, ReactElement} from "react";
import "./Question.css";
import SectionHeader from "../section-header/SectionHeader";
import Spacer from "shared-components/dist/spacer/Spacer";
import SubContainer from "../sub-container/SubContainer";
import Rag from "shared-components/dist/information/rag/Rag";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import {Status} from "shared-components/dist/status-indicator/Status";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";

export interface QuestionProps {
  title: TranslationKey;
  id: string;
  description?: TranslationKey;
  descriptionListItems?: TranslationKey[];
  status?: Status;
  children?: ReactElement;
  errorMessage?: TranslationKey;
  errorTooltip?: TranslationKey;
  tooltip?: TooltipProps;
  hideSpacer?: boolean;
  className?: string;
}

const Question: FC<QuestionProps> = (
  {
    title,
    id,
    description,
    descriptionListItems,
    status,
    children,
    errorMessage,
    errorTooltip,
    tooltip,
    hideSpacer,
    className
  }
) => {
  const inErrorOrWarning = status && ["error", "warning"].includes(status);

  const getClassName = (): string => {
    let name = "question";
    if (hideSpacer) name += " question--hide-spacer";
    if (className) name += ` ${className}`;
    return name;
  };

  return (
    <div className={getClassName()} id={id}>
      <Spacer className="question__spacer"/>
      <SubContainer status={inErrorOrWarning ? status : "hidden"}>
        <SectionHeader
          title={title}
          description={description}
          descriptionListItems={descriptionListItems}
          status={status}
          htmlFor={id}
          errorMessage={errorMessage}
          tooltip={tooltip}
        />
        {children && cloneElement(children, {...children.props})}
        {inErrorOrWarning && errorTooltip &&
          <Rag status={status} description={errorTooltip}/>
        }
      </SubContainer>
    </div>
  );
};

export default Question;
