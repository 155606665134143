import React, {FC} from "react";
import "./PolicyCard.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {Policy} from "shared/dist/generated/graphql/resolvers-types";
import {getRenewalDateTitleFor, getRenewalDateValueFor, PortalPolicyStatus} from "../../../shared/utils/PolicyStatusUtils";

interface Props {
  policyStatus: PortalPolicyStatus
  policy: Policy
}

const PolicySummaryCardRow: FC<Props> = ({policyStatus, policy}) => (
  <div className="policy-card__row">
    <div className="policy-card__policy-number-column">
      <div className={`policy-card__policy-number-title policy-card__policy-number-title__${policyStatus}`}>
        {lookupI18nString("policies.header.subHeader.policySummaryCardRow.policyNumber")}
      </div>
      <h5 className={`policy-card__policy-number policy-card__policy-number__${policyStatus}`}>
        {policy.coverDetails.number ?? lookupI18nString("policies.header.subHeader.policySummaryCardRow.checkBackSoon")}
      </h5>
    </div>
    <div className="policy-card__column">
      <div className={`policy-card__renewal-date-title policy-card__renewal-date-title__${policyStatus}`}>
        {getRenewalDateTitleFor(policyStatus)}
      </div>
      <h5 className={`policy-card__renewal-date policy-card__renewal-date__${policyStatus}`}>
        {getRenewalDateValueFor(policyStatus, policy)}
      </h5>
    </div>
  </div>
);

export default PolicySummaryCardRow;
