import React, {FC, useState} from "react";
import {RequiredFormField} from "shared-components/dist/models/form-field/FormField";
import {Claim, Claims} from "./models/Claims";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import ClaimItem from "./components/ClaimItem";
import MultipleItemsQuestion from "../../../../../../structure/questions/multiple-items-question/MultipleItemsQuestion";
import ClaimsForm from "./components/ClaimsForm";
import {Status} from "shared-components/dist/status-indicator/Status";
import TooManyClaimsStop from "./components/stops/TooManyClaimsStop";
import {scrollToElement} from "../../../../../../utils/navigation/ScrollToHelpers";

interface OwnProps {
  id: string;
  title: TranslationKey;
  description: TranslationKey;
  tooltip: TooltipProps;
  claims: RequiredFormField<Claims>;
  shouldFlagUnansweredQuestions: boolean;
  flagUnansweredSubQuestions: () => void;
  isFormVisible: boolean;
  hasClaimsChanged: (value: boolean) => void;
  isFormVisibleSet: (value: boolean) => void;
  claimAdded: (claim: Claim) => void;
  claimRemoved: (index: number) => void;
  isAdditionalDriverForm?: boolean;
  claimStatusChanged: (status: Status) => void;
}

const ClaimsQuestion: FC<OwnProps> = (
  {
    id,
    title,
    description,
    tooltip,
    claims,
    shouldFlagUnansweredQuestions,
    flagUnansweredSubQuestions,
    isFormVisible,
    hasClaimsChanged,
    isFormVisibleSet,
    claimAdded,
    claimRemoved,
    isAdditionalDriverForm = false,
    claimStatusChanged
  }
) => {
  const [isTooManyClaimsStop, setIsTooManyClaimsStop] = useState<boolean>(false);

  const mapClaims = (): JSX.Element[] => (
    claims.value.claims.map((claim, index) => (
      <ClaimItem claim={claim} key={index}/>
    ))
  );

  const onHasClaimsChanged = (hasClaims: boolean): void => {
    hasClaimsChanged(hasClaims);
    setIsTooManyClaimsStop(false);
  };

  const removeClaim = (index: number): void => {
    setIsTooManyClaimsStop(false);
    claimRemoved(index);
  };

  return (
    <>
      <MultipleItemsQuestion
        id={id}
        name={id}
        title={title}
        description={description}
        tooltip={tooltip}
        form={
          <ClaimsForm
            claimAdded={(claim) => {
              claimAdded(claim);
              scrollToElement(id);
            }}
            shouldFlagUnansweredQuestions={shouldFlagUnansweredQuestions}
            flagUnansweredQuestions={flagUnansweredSubQuestions}
            isAdditionalDriverForm={isAdditionalDriverForm}
          />
        }
        items={mapClaims()}
        confirmation={claims.value.hasClaims}
        setConfirmation={onHasClaimsChanged}
        itemRemoved={removeClaim}
        error={claims.errorMessage}
        status={claims.status}
        isFormVisible={isFormVisible}
        setIsFormVisible={isFormVisibleSet}
        maxItems={5}
        onMaxItems={() => setIsTooManyClaimsStop(true)}
        onCancel={() => claimStatusChanged("success")}
        addButtonDisabled={isTooManyClaimsStop}
        disableSubmitItemWhenFormIsInvalid={false}
      />
      <TooManyClaimsStop
        isVisible={isTooManyClaimsStop}
        isAdditionalDriverForm={isAdditionalDriverForm}
        setIsVisible={setIsTooManyClaimsStop}
      />
    </>
  );
};

export default ClaimsQuestion;