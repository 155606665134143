import {base64PdfToBlob} from "../base64/Base64Conversion";

export function openBase64FileInWindow(base64String: string): void {
  const pdfBlob = base64PdfToBlob(base64String);
  openFileInBrowserWindow(pdfBlob);
}

function openFileInBrowserWindow(blob: Blob): void {
  const url = window.URL.createObjectURL(blob);
  window.open(url);
}