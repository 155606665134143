import {
  validationFailure,
  ValidationResult,
  validationSuccess,
  Validator
} from "shared-components/dist/utils/validation/Validator";
import {isAboveMinimumAgeOn} from "./IsAboveMinimumAge";

export function isAboveMinimumAgeOnCoverStartDate(coverStartDate?: Date): Validator<Date> {
  return (dateOfBirth: Date): ValidationResult => {
    if (!coverStartDate) return validationSuccess;

    return isAboveMinimumAgeOn(coverStartDate, dateOfBirth)
      ? validationSuccess
      : validationFailure("personalDetails.dateOfBirthQuestion.errors.isTooYoung.onStartDate");
  };
}
