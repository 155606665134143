import React, {cloneElement, FC, ReactElement} from "react";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import "./InlineQuestion.css";
import {Status} from "shared-components/dist/status-indicator/Status";
import Rag from "shared-components/dist/information/rag/Rag";
import SubContainer from "../sub-container/SubContainer";

interface OwnProps {
  id: string;
  children: ReactElement;
  label?: TranslationKey;
  className?: string;
  status?: Status;
  errorMessage?: TranslationKey;
  errorDescription?: TranslationKey;
}

const InlineQuestion: FC<OwnProps> = ({
  id,
  children,
  label,
  className,
  status= "default",
  errorMessage,
  errorDescription
}) => {
  const getClassName = (): string => {
    let inlineQuestionClass = `inline-question inline-question--${status}`;
    if (className) inlineQuestionClass += ` ${className}`;
    return inlineQuestionClass;
  };

  const getSubContainerStatus = (): Status => {
    if (status === "error") return "error";
    return "hidden";
  };

  return (
    <SubContainer className={getClassName()} status={getSubContainerStatus()}>
      {status === "error" && errorMessage &&
        <div className="inline-question__error-message">
          {lookupI18nString(errorMessage)}
        </div>
      }

      {label && <label htmlFor={id} className="inline-question__label">{lookupI18nString(label)}</label>}

      {cloneElement(children, {...children.props, id: id})}

      {status === "error" && errorDescription &&
        <Rag
          className="inline-question__error-description"
          description={errorDescription}
          status={status}
        />
      }
    </SubContainer>
  );
};

export default InlineQuestion;