import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client";
import fetch from "cross-fetch";
import {API_ENDPOINT} from "./Constants";
import {setContext} from "@apollo/client/link/context";
import {getCurrentAvailableSession} from "../../user/authentication/session/SessionManager";
import {strollSessionId, strollVersionNumber} from "../../providers/Environment";

const WEB_PLATFORM_HEADER_VALUE = "web";

const httpLink = createHttpLink({
  fetch
});

const context = setContext(async (request, {headers}) => {
  const idToken = await getIdToken();

  if (idToken) {
    return {
      uri: `${API_ENDPOINT}-private`,
      headers: {
        ...headers,
        "session-id": strollSessionId,
        "Authorization": idToken,
        "stroll-platform": WEB_PLATFORM_HEADER_VALUE,
        "stroll-app-version": strollVersionNumber
      }
    };
  }

  return {
    uri: API_ENDPOINT,
    headers: {
      ...headers,
      "session-id": strollSessionId,
      "stroll-platform": WEB_PLATFORM_HEADER_VALUE,
      "stroll-app-version": strollVersionNumber
    }
  };
});

const getIdToken = async (): Promise<string | undefined> => {
  try {
    return (await getCurrentAvailableSession()).getIdToken().getJwtToken();
  } catch {
    return undefined;
  }
};

export const apolloClientOptions = {
  link: context.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          vehicle: {
            merge: true
          },
          abiCodes: {
            merge: true
          },
          address: {
            merge: true
          },
          quote: {
            merge: true
          },
          policySetup: {
            merge: true
          },
        }
      },
      Mutation: {
        fields: {
          quote: {
            merge: true
          },
          emailAddress: {
            merge: true
          }
        }
      }
    }
  })
};
export const apolloClient = new ApolloClient(apolloClientOptions);
