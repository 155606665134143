import React, {FC, KeyboardEvent, useRef, useState} from "react";
import InputField from "shared-components/dist/form/input-field/InputField";
import InlineQuestion from "../../../../../../structure/questions/inline-question/InlineQuestion";
import {sortCodeChanged, sortCodeInvalid} from "../../redux/MonthlyPaymentSlice";
import {isRegexValid} from "shared-components/dist/utils/validators/StringValidators";
import {
  CURSOR_KEYS,
  DELETE_KEYS,
  eventKeyIsOneOf,
  INTEGER_KEYS,
  isCtrlKeyCombo
} from "shared-components/dist/utils/input/InputHelpers";
import {logPaymentGoogleAnalyticsEvent} from "../../../../../../utils/analytics/PaymentAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const MAXIMUM_UNITED_KINGDOM_BANK_SORT_CODE_LENGTH = 6;
const UNITED_KINGDOM_SORT_CODE_FORMAT = /(\d{2}-?){2}\d{2}/;

const SortCodeField: FC = () => {
  const dispatch = useAppDispatch();
  const sortCodeField = useAppSelector(state => state.monthlyPayment.sortCode);

  const [value, setValue] = useState(sortCodeField.value ?? "");
  const fieldRef = useRef<HTMLInputElement>(null);

  function updatePersistedSortCode(updatedSortCode: string): void {
    const numericSortCode = removeFormatting(updatedSortCode).replace(/\D/g, "");
    const formattedNewSortCode = formatSortCode(numericSortCode.substring(0, MAXIMUM_UNITED_KINGDOM_BANK_SORT_CODE_LENGTH));
    setValue(formattedNewSortCode);
  }

  return (
    <InlineQuestion
      id="sort-code-field"
      label="paymentFlow.monthly.directDebit.form.bankDetails.sortCode.title"
      status={sortCodeField.status}
      errorMessage={sortCodeField.errorMessage}
    >
      <InputField
        value={value}
        onInvalid={(errorMessage) => dispatch(sortCodeInvalid(errorMessage))}
        changeIf={[isSuitableSortCodeInputKey, isCtrlKeyCombo]}
        onChange={event => {
          updatePersistedSortCode(event.currentTarget.value);
          dispatch(sortCodeChanged(value));
        }}
        onClick={() => {
          const sortCodeLength = value.length;
          fieldRef?.current?.setSelectionRange(sortCodeLength, sortCodeLength);
        }}
        onBlur={() => {
          logPaymentGoogleAnalyticsEvent({
            categorySuffix: "monthly.bank_details.sort_code",
            action: "Input"
          });
          dispatch(sortCodeChanged(value));
        }}
        validators={[
          isRegexValid(UNITED_KINGDOM_SORT_CODE_FORMAT, "paymentFlow.monthly.directDebit.form.bankDetails.sortCode.error")
        ]}
        ref={fieldRef}
        pattern="\d*"
      />
    </InlineQuestion>
  );
};

const removeFormatting = (sortCode: string): string => sortCode.replace(/-/g, "");

const formatSortCode = (sortCode: string): string => sortCode.replace(/(\d{2})(?=\d)/g, "$1-");

function isSuitableSortCodeInputKey(event: KeyboardEvent<HTMLInputElement>): boolean {
  return eventKeyIsOneOf(event, [
    INTEGER_KEYS,
    DELETE_KEYS,
    CURSOR_KEYS
  ]);
}

export default SortCodeField;