import {FC, ReactElement} from "react";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import classNames from "classnames";
import FocusTrap from "focus-trap-react";
import "./ModalStop.css";

interface Props {
  className?: string;
  visible: boolean;
  title: TranslationKey;
  description: TranslationKey;
  footer: ReactElement | ReactElement[];
}

const ModalStop: FC<Props> = ({
  className,
  visible,
  title,
  description,
  footer
}) => {
  if (!visible) return null;

  return (
    <FocusTrap>
      <div className={classNames("modal-stop", className)}>
        <div className="modal-stop__content">
          <div className="modal-stop__content__title">
            <h3>{lookupI18nString(title)}</h3>
          </div>

          <div className="modal-stop__content__description">
            <p>{lookupI18nString(description)}</p>
          </div>

          {footer && <div className="modal-stop__content__footer">{footer}</div>}
        </div>
      </div>
    </FocusTrap>
  );
};

export default ModalStop;