import React, {FC} from "react";
import {
  existingPolicyExpiryDateChanged,
  existingPolicyExpiryDateInvalid,
  existingPolicyExpiryDateReset
} from "../../../redux/CoverDetailsSlice";
import {existingPolicyExpiryDateAsDateSelector} from "../../../redux/selectors/CoverDetailsSelectors";
import DateQuestion from "../../../../../../../structure/questions/question/variants/date-question/DateQuestion";
import {isNamedDriverAXAEligibleSelector} from "../../../redux/selectors/named-driver/IsNamedDriverAXAEligible";
import {endOfDay} from "date-fns";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const ExistingPolicyExpiryDateQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const existingPolicyExpiryDate = useAppSelector(existingPolicyExpiryDateAsDateSelector);
  const isEligibleForNamedDriver = useAppSelector(isNamedDriverAXAEligibleSelector);

  const onPolicyExpiryDateChanged = (date: Date): void => {
    const isoDate = endOfDay(date).toISOString();
    dispatch(existingPolicyExpiryDateChanged({value: isoDate, isEligibleForNamedDriver}));

    return;
  };

  return (
    <>
      <DateQuestion
        id="existing-policy-expiry-date-question"
        title="coverDetails.existingPolicyExpiryDateSubQuestion.title"
        formField={existingPolicyExpiryDate}
        onComplete={date => onPolicyExpiryDateChanged(date)}
        onInvalid={errorMessage => dispatch(existingPolicyExpiryDateInvalid(errorMessage))}
        onClear={() => dispatch(existingPolicyExpiryDateReset())}
        tooltip={{
          description: "coverDetails.existingPolicyExpiryDateSubQuestion.tooltip.description"
        }}
        hideSpacer
      />
    </>
  );
};

export default ExistingPolicyExpiryDateQuestion;
