import React, {FC} from "react";
import {voluntaryExcessChanged} from "../../redux/CoverDetailsSlice";
import DropdownSubQuestion
  from "../../../../../../structure/questions/sub-question/variants/dropdown-sub-question/DropdownSubQuestion";
import SubQuestionForm from "../../../../../../structure/questions/sub-question-form/SubQuestionForm";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";
import {useVoluntaryExcessOptions} from "./UseVoluntaryExcessOptions";

const VoluntaryExcessQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const voluntaryExcess = useAppSelector(state => state.coverDetails.voluntaryExcess);
  const voluntaryExcessOptions = useVoluntaryExcessOptions(voluntaryExcess.value);

  if (!voluntaryExcess.isActive) return null;

  return (
    <SubQuestionForm>
      <DropdownSubQuestion
        id="voluntary-excess-question"
        title="coverDetails.voluntaryExcessQuestion.title"
        prefix="structure.currencies.gbp"
        status={voluntaryExcess.status}
        value={voluntaryExcess.value}
        options={voluntaryExcessOptions}
        onChange={value => dispatch(voluntaryExcessChanged(value))}
        tooltip={{
          title: "coverDetails.voluntaryExcessQuestion.tooltip.title",
          description: "coverDetails.voluntaryExcessQuestion.tooltip.description"
        }}
      />
    </SubQuestionForm>
  );
};

export default VoluntaryExcessQuestion;