import React, {FC, FormEvent, Ref} from "react";
import "./SubQuestionForm.css";

interface Props {
  formRef?: Ref<HTMLFormElement>;
  onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
  id?: string;
  infoPanel?: React.ReactNode;
}

const SubQuestionForm: FC<Props> = (
  {
    children,
    formRef,
    onSubmit,
    id,
    infoPanel
  }
) => (
  <>
    <form
      className={`sub-question-form ${infoPanel ? "info-displayed" : ""}`}
      onSubmit={onSubmit}
      ref={formRef}
      id={id}
    >
      {children}
    </form>
    {infoPanel}
  </>
);

export default SubQuestionForm;