import React, {FC, SyntheticEvent, useEffect, useState} from "react";
import "./Login.css";
import PasswordInput from "shared-components/dist/form/password-input-field/PasswordInput";
import Button from "shared-components/dist/buttons/button/Button";
import {login} from "./redux/thunks/Login";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import InlineQuestion from "../../../structure/questions/inline-question/InlineQuestion";
import InlineQuestionEmailInput from "../../../structure/form/inline-question-email-input/InlineQuestionEmailInput";
import ForgotPasswordModal from "./components/forgot-password-modal/ForgotPasswordModal";
import SuccessCard from "shared-components/dist/cards//success-card/SuccessCard";
import {isValidEmailAddress} from "shared-components/dist/utils/validation/EmailAddresses";
import {ForgotPasswordModalDismissedReason} from "./components/forgot-password-modal/body/ForgotPasswordModalBody";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import LoginFormLogo from "../shared/components/login-form-logo/LoginFormLogo";
import {logPortalGoogleAnalyticsEvent} from "../../../utils/analytics/PortalAnalytics";
import {useAppDispatch, useAppSelector} from "../../../redux/Hooks";
import {userAttemptedToLogin} from "../shared/redux/UserAuthenticationSlice";

export type LoginFormState = "Initialised" | "Valid" | "Incomplete" | "InvalidEmailAddress"

const Login: FC = () => {
  const dispatch = useAppDispatch();

  const loginResult = useAppSelector(state => state.userAuthentication.loginResult);
  const userSessionHasExpired = useAppSelector(state => state.userAuthentication.userSessionHasExpired);

  const [emailAddress, setEmailAddress] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [displayUserSessionTimeoutError, setDisplayUserSessionTimeoutError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [emailSentCardVisible, setEmailSentCardVisibility] = useState(false);
  const userFailedToLogin = loginResult !== undefined && loginResult !== "Success";

  useEffect(() => {
    userSessionHasExpired ? setDisplayUserSessionTimeoutError(true) : setDisplayUserSessionTimeoutError(false);
  }, [userSessionHasExpired, setDisplayUserSessionTimeoutError]);

  const onEmailInvalid = (): void => {
    setEmailAddress("");
  };

  const onEmailEntered = (value: string): void => {
    setEmailAddress(value);
    setDisplayUserSessionTimeoutError(false);
  };

  const getLoginValidationResult = (): LoginFormState =>
    (isValidEmailAddress(emailAddress) && password) ? "Valid" : "Incomplete";

  const onPasswordEntered = (value: string): void => {
    setPassword(value);
    setDisplayUserSessionTimeoutError(false);
  };

  const onForgotPasswordSelected = (): void => {
    setModalOpen(true);
    logPortalGoogleAnalyticsEvent({
      categorySuffix: "login_page.forgotten_password_link",
      action: "Click",
    });
  };

  const handleForgotPasswordDismissal = (reason: ForgotPasswordModalDismissedReason): void => {
    setModalOpen(false);
    if (reason === "EmailSent") {
      setEmailSentCardVisibility(true);
      logPortalGoogleAnalyticsEvent({
        categorySuffix: "login_page.forgotten_password_modal.send_link",
        action: "Click",
      });
    } else {
      logPortalGoogleAnalyticsEvent({
        categorySuffix: "login_page.forgotten_password_modal.cancel",
        action: "Click",
      });
    }
  };

  const performLoginAttempt = (event: SyntheticEvent): void => {
    event.preventDefault();

    if (getLoginValidationResult() === "Incomplete") {
      dispatch(userAttemptedToLogin("InvalidCredentials"));
      return;
    }

    dispatch(login(emailAddress.trim(), password));
    logPortalGoogleAnalyticsEvent({
      categorySuffix: "login_page.login_button",
      action: "Click",
    });
  };

  const getLoginErrorMessage = (): TranslationKey => {
    if (loginResult === "InvalidCredentials") return "login.errors.incorrectCredentialsEntered";
    if (loginResult === "AttemptsExceeded") return "login.errors.tooManyFailedAttempts";
    if (userSessionHasExpired) return "login.errors.sessionTimeout";

    return "login.errors.genericLoginFailure";
  };

  const navigateToStrollWelcomePage = (): void => {
    window.location.href = "https://strollinsurance.co.uk/welcome-to-stroll";
  };

  return (
    <div className="login-page">
      <div className="stroll-logo-container">
        <div className="stroll-logo"/>
      </div>
      <SuccessCard
        visible={emailSentCardVisible}
        onDismiss={() => setEmailSentCardVisibility(false)}
        title="login.forgotPassword.helpIsOnTheWayMessage.title"
        description="login.forgotPassword.helpIsOnTheWayMessage.description"
      />
      <div className="login-dialog">
        <LoginFormLogo/>
        <form onSubmit={performLoginAttempt}>
          <div className="form-fields">
            <InlineQuestion
              id="login-inputs"
              status={(userFailedToLogin || displayUserSessionTimeoutError) ? "error" : "hidden"}
              errorMessage={getLoginErrorMessage()}
            >
              <div>
                <InlineQuestionEmailInput
                  inlineQuestionId="login-inline-email-question"
                  emailInputName="login-email-input"
                  onChange={onEmailEntered}
                  onComplete={onEmailEntered}
                  onInvalid={onEmailInvalid}
                />
                <PasswordInput
                  name="login-password"
                  value={password}
                  onChange={onPasswordEntered}
                  onComplete={onPasswordEntered}
                  placeholder="login.passwordInput.placeholder"
                  autoCompleteType="current-password"
                />
              </div>
            </InlineQuestion>
          </div>
          <Button type="submit" expanded>
            {lookupI18nString("login.loginButton")}
          </Button>
          <Button variant="ghost" expanded onClick={navigateToStrollWelcomePage}>
            {lookupI18nString("login.newHereButton")}
          </Button>
          <Button
            variant="link"
            onClick={onForgotPasswordSelected}>
            {lookupI18nString("login.forgotPasswordLink")}
          </Button>
        </form>
        <ForgotPasswordModal
          visible={modalOpen}
          onDismissed={handleForgotPasswordDismissal}
          initialEmailAddress={emailAddress}
        />
      </div>
    </div>
  );
};

export default Login;
