import {FC} from "react";
import FullPageSpinner from "shared-components/dist/spinner/full-page-spinner/FullPageSpinner";
import Navbar from "../../../../../structure/navigation/navbar/Navbar";

const RenewalLoading: FC = () => (
  <div data-testid="renewal-loading">
    <Navbar/>
    <FullPageSpinner message="portal.spinner.message"/>
  </div>
);

export default RenewalLoading;