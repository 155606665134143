import React, {FC} from "react";
import {maritalStatusChanged, maritalStatusInvalid} from "../../redux/UpdateAdditionalDriverSlice";
import MaritalStatusQuestion from "../../../../shared/questions/martial-status/MaritalStatusQuestion";
import {MaritalStatusOption} from "../../../../shared/questions/martial-status/MaritalStatusOption";
import {logAdditionalDriverGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {runValidators} from "shared-components/dist/utils/validation/Validator";
import {maritalStatusMatchesRelationshipToProposerValidator} from "./validators/AdditionalDriverMaritalStatusValidators";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const AdditionalDriverMaritalStatusQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const maritalStatusField = useAppSelector(state => state.updateAdditionalDriver.maritalStatus);
  const relationshipToProposer = useAppSelector(state => state.updateAdditionalDriver.relationshipToProposer.value?.id);

  const onMaritalStatusChanged = (answer: MaritalStatusOption): void => {
    const validationResult = runValidators(answer,
      maritalStatusMatchesRelationshipToProposerValidator(relationshipToProposer)
    );

    if (!validationResult.passed) {
      dispatch(maritalStatusInvalid(validationResult.errorMessage));
    } else {
      logAdditionalDriverGoogleAnalyticsEvent({
        categorySuffix: "relationship_status",
        action: "Answered"
      });

      dispatch(maritalStatusChanged(answer));
    }
  };

  return (
    <MaritalStatusQuestion
      id="additional-driver-marital-status-question"
      title="additionalDriver.maritalStatusQuestion"
      maritalStatusField={maritalStatusField}
      tooltip={{
        description: "additionalDriver.maritalStatusQuestion.tooltip.description"
      }}
      onComplete={onMaritalStatusChanged}
      placeholder="additionalDriver.maritalStatusQuestion.dropdownPlaceholder"
    />
  );
};

export default AdditionalDriverMaritalStatusQuestion;
