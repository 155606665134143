import {EmploymentStatusOption} from "../questions/employment/models/EmploymentStatusOption";
import {EmploymentStatus} from "shared/dist/generated/graphql/resolvers-types";

const employedVtCodes = [EmploymentStatus.Employed, EmploymentStatus.SelfEmployed];

export function isEmployed(employmentStatus: EmploymentStatusOption | undefined): boolean {
  if (!employmentStatus) return false;

  return employedVtCodes.some(vtCode => employmentStatus.vtCode === vtCode);
}

export function isUnemployed(employmentStatus: EmploymentStatusOption | undefined): boolean {
  if (!employmentStatus) return false;

  return employmentStatus.vtCode === EmploymentStatus.Unemployed;
}

export function isRetired(employmentStatus: EmploymentStatusOption | undefined): boolean {
  if (!employmentStatus) return false;

  return employmentStatus.vtCode === EmploymentStatus.Retired;
}

export function isVoluntaryWorker(employmentStatus: EmploymentStatusOption | undefined): boolean {
  if (!employmentStatus) return false;

  return employmentStatus.vtCode === EmploymentStatus.VoluntaryWorker;
}

export function employmentStatusAllowsSoliciting(
  primaryEmployment: EmploymentStatusOption | undefined,
  secondaryEmployment: EmploymentStatusOption | undefined
): boolean {
  return (isEmployed(primaryEmployment) || isVoluntaryWorker(primaryEmployment)) ||
    (isEmployed(secondaryEmployment) || isVoluntaryWorker(secondaryEmployment));
}