import {gql} from "@apollo/client";
import {SavedQuote} from "../../../../pages/quote/vehicle/your-quote/models/SavedQuote";
import {apolloClient} from "../../../apollo/ApolloClientProvider";
import {
  QuoteMutationSaveQuotesArgs,
  SaveQuoteParams,
  SaveQuoteResult
} from "shared/dist/generated/graphql/resolvers-types";

export const SAVE_QUOTES = gql`
  mutation SaveQuotes($params: SaveQuoteParams!) {
    quote {
      saveQuotes(params: $params) {
        magicLinkId,
        reference,
        sequenceNumber
      }
    }
  }
`;

export async function saveQuotesMutation(email: string, savedQuote: SavedQuote, magicLinkId: string): Promise<SaveQuoteResult | undefined> {
  const params = buildSaveQuoteParams(email, savedQuote, magicLinkId);

  return new Promise<SaveQuoteResult | undefined>((resolve, reject) => {
    apolloClient.mutate<SaveQuoteResult, QuoteMutationSaveQuotesArgs>({
      mutation: SAVE_QUOTES,
      variables: {
        params
      }
    }).then(result => resolve(result.data ?? undefined))
      .catch(error => reject(error));
  });
}

function buildSaveQuoteParams(email: string, savedQuote: SavedQuote, magicLinkId: string): SaveQuoteParams {
  const serialisedQuote = encodeURIComponent(JSON.stringify(savedQuote));
  const policyStartDate = savedQuote.coverDetails.coverStartDate.value?.date
    ? savedQuote.coverDetails.coverStartDate.value.date
    : "Unknown";

  return {
    magicLinkId,
    email,
    serialisedQuote,
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    firstName: savedQuote.personalDetails.firstName.value!,
    surname: savedQuote.personalDetails.surname.value!,
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
    coverType: savedQuote.coverDetails.coverType.value?.description ?? "Unknown",
    policyStartDate,
    additionalDrivers: savedQuote.additionalDrivers?.map(additionalDriver => ({
      name: `${additionalDriver.firstName} ${additionalDriver.surname}`
    })),
    quoteAdditionalDrivers: savedQuote.additionalDrivers,
    policyExtras: savedQuote.policyExtras.map(extra => ({
      type: extra.id,
      optionType: extra.optionType
    })),
    reference: savedQuote.quoteReference,
    sequenceNumber: savedQuote.quoteSequenceNumber
  };
}
