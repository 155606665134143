import React, {cloneElement, FC, ReactElement, ReactNode, Ref, useRef, useState} from "react";
import Question from "../question/Question";
import RadioConfirmation from "shared-components/dist/form/radio-confirmation/RadioConfirmation";
import GuideButton from "shared-components/dist/buttons/guide-button/GuideButton";
import ActionCard from "shared-components/dist/cards/action-card/ActionCard";
import "./MultipleItemsQuestion.css";
import Button from "shared-components/dist/buttons/button/Button";
import {Status} from "shared-components/dist/status-indicator/Status";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import {DeleteForever} from "@mui/icons-material";
import ActionCardInteractiveContent from "shared-components/dist/cards/action-card/ActionCardInteractiveContent";
import ActionCardContent from "shared-components/dist/cards/action-card/ActionCardContent";
import classNames from "classnames";

interface Props {
  name: string;
  title: TranslationKey;
  description?: TranslationKey;
  descriptionListItems?: TranslationKey[];
  tooltip?: TooltipProps;
  id: string;
  form: ReactElement;
  items: ReactNode[];
  confirmation?: boolean;
  setConfirmation: (value: boolean) => void;
  itemRemoved: (index: number) => void;
  error?: TranslationKey;
  status?: Status;
  isFormVisible: boolean;
  setIsFormVisible: (value: boolean) => void;
  addItemAllowed?: boolean;
  onAddButtonClicked?: () => void;
  maxItems: number;
  onMaxItems: () => void;
  onCancel?: () => void;
  addButtonDisabled?: boolean;
  disableSubmitItemWhenFormIsInvalid?: boolean
}

export interface MultipleItemsQuestionSubFormProps {
  formRef?: Ref<HTMLFormElement>;
  setIsFormValid?: (value: boolean) => void;
}

const MultipleItemsQuestion: FC<Props> = (
  {
    name,
    title,
    description,
    descriptionListItems,
    tooltip,
    id,
    form,
    items,
    confirmation,
    setConfirmation,
    itemRemoved,
    error,
    status,
    isFormVisible,
    setIsFormVisible,
    maxItems,
    onMaxItems,
    onCancel,
    addButtonDisabled = false,
    disableSubmitItemWhenFormIsInvalid = true
  }
) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const cancelButtonVisible = items.length > 0;

  function addButtonClicked(): void {
    setIsFormVisible(items.length < maxItems);

    if (items.length === maxItems) {
      onMaxItems();
    }
  }

  function drawItemsContainer(): ReactElement | null {
    if (confirmation !== true) return null;

    return (
      <div className="multiple-items__container">
        {items.map((item, index) => drawItem(item, index))}
        {
          !isFormVisible &&
          <GuideButton
            text="structure.questions.multipleItems.addButton"
            onClick={addButtonClicked}
            disabled={addButtonDisabled}
          />
        }
      </div>
    );
  }

  function drawItem(item: ReactNode, index: number): ReactElement {
    return (
      <ActionCard key={index}>
        <ActionCardContent>
          {item}
        </ActionCardContent>

        <ActionCardInteractiveContent onClick={() => onItemCardClick(index)}>
          <DeleteForever/>
        </ActionCardInteractiveContent>
      </ActionCard>
    );
  }

  function onItemCardClick(index: number): void {
    if (items.length <= 1) {
      setIsFormVisible(true);
    }
    itemRemoved(index);
  }

  function drawFormContainer(): ReactElement | null {
    if (!isFormVisible) return null;

    return (
      <div className={classNames("multiple-items__form", {"multiple-items__form--cancel-hidden": items.length === 0})}>
        {
          cloneElement(form, {
            ...form.props,
            formRef: formRef,
            setIsFormValid: setIsFormValid
          })
        }

        <div className="buttons">
          {
            cancelButtonVisible &&
            <Button
              variant="link"
              onClick={onCancelClicked}
            >
              {lookupI18nString("structure.questions.multipleItems.cancelButton")}
            </Button>
          }

          <GuideButton
            className={classNames({"guide-button--disabled": !isFormValid && disableSubmitItemWhenFormIsInvalid})}
            text="structure.questions.multipleItems.addButton"
            onClick={onFormSubmit}
          />
        </div>
      </div>
    );
  }

  function onFormSubmit(): void {
    // https://stackoverflow.com/a/49592762/8445339
    // Firefox won't fire preventDefault unless the event is cancelable
    // Event also needs to bubble for React 17 - https://github.com/final-form/react-final-form/issues/878
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event("submit", {cancelable: true, bubbles: true}));
    }

    if (isFormValid) setIsFormVisible(false);
  }

  function onCancelClicked(): void {
    onCancel?.();
    setIsFormVisible(false);
  }

  function changeConfirmation(value: boolean): void {
    setConfirmation(value);
    setIsFormVisible(value && items.length === 0);
  }

  return (
    <div className="multiple-items">
      <Question
        title={title}
        id={id}
        status={status}
        errorMessage={error}
        description={description}
        descriptionListItems={descriptionListItems}
        tooltip={tooltip}
      >
        <>
          <RadioConfirmation
            name={name}
            selectedGroupOption={confirmation}
            setSelectedGroupOption={changeConfirmation}
          />
          {drawItemsContainer()}
          {drawFormContainer()}
        </>
      </Question>
    </div>
  );
};

export default MultipleItemsQuestion;
