import React, {FC} from "react";
import PlainHeader from "../../../../structure/navigation/plain-header/PlainHeader";
import Spacer from "shared-components/dist/spacer/Spacer";
import Button from "shared-components/dist/buttons/button/Button";
import "./RefusedQuote.css";
import {useLocation, useNavigate} from "react-router-dom";
import {buildQuoteRoute} from "../../../../utils/navigation/NavigationHelpers";
import {YOUR_CAR} from "../../../../router/models/Routes";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import BulletList from "shared-components/dist/lists/bullet-list/BulletList";
import {useAppSelector} from "../../../../redux/Hooks";

const RefusedQuote: FC = () => {
  const quoteReference = useAppSelector(state => state.quoteDetails.quoteReference);

  const navigate = useNavigate();
  const location = useLocation();

  const openZendeskChat = "javascript:$zopim.livechat.window.show();";

  return (
    <div className="refused-quote">
      <PlainHeader/>

      <div className="refused-quote__container">
        <div className="refused-quote__container__body">
          <h1>{lookupI18nString("refusedQuote.title")}</h1>

          {quoteReference &&
            <h4 className="refused-quote__container__body__reference">
              {lookupI18nString("refusedQuote.reference")} {quoteReference}
            </h4>
          }

          <div className="refused-quote__container__body__buttons">
            <Button
              expanded
              onClick={() => navigate(buildQuoteRoute(location.pathname, YOUR_CAR))}
              className="refused-quote__container__body__buttons__edit"
            >
              {lookupI18nString("refusedQuote.callToAction.edit")}
            </Button>

            <a
              className="refused-quote__container__body__buttons__live-chat button tertiary expanded"
              href={openZendeskChat}
            >
              {lookupI18nString("refusedQuote.callToAction.chat")}
            </a>
          </div>

          <Spacer/>

          <h4 className="refused-quote__container__body__description">
            {lookupI18nString("refusedQuote.description")}
          </h4>

          <div className="refused-quote__container__body__list">
            <BulletList
              items={[
                lookupI18nString("refusedQuote.list.checkDateOfBirthAndLicenseInfo"),
                lookupI18nString("refusedQuote.list.vehicleBeyondDrivingExperience"),
                lookupI18nString("refusedQuote.list.claimsAndConvictionHistory")
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefusedQuote;