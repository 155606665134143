import {PolicyExtraOptionType, PolicyExtraType, QuoteExtra} from "shared/dist/generated/graphql/resolvers-types";

type PickEnum<T, K extends T> = {
  [P in keyof K]: P extends K ? P : never;
};

export interface RenewalPolicyExtra<T> {
  enabled: boolean;
  type: T;
}

export type ExcessInsuranceOptionTypes = PickEnum<
  PolicyExtraOptionType,
  | PolicyExtraOptionType.ExcessInsurance1
  | PolicyExtraOptionType.ExcessInsurance2
  | PolicyExtraOptionType.ExcessInsurance3
  | PolicyExtraOptionType.ExcessInsurance4
  | PolicyExtraOptionType.ExcessInsurance5
>;

export interface RenewalPolicyExtras {
  courtesyCar: RenewalPolicyExtra<PickEnum<PolicyExtraOptionType, PolicyExtraOptionType.CourtesyCar>>;
  legalExpenses: RenewalPolicyExtra<PickEnum<PolicyExtraOptionType, PolicyExtraOptionType.LegalExpenses>>;
  roadsideAssistancePlatinum: RenewalPolicyExtra<PickEnum<PolicyExtraOptionType, PolicyExtraOptionType.RoadsideAssistancePlatinum>>;
  smartfobKeycare: RenewalPolicyExtra<PickEnum<PolicyExtraOptionType, PolicyExtraOptionType.SmartfobKeycare>>;
  excessInsurance: RenewalPolicyExtra<ExcessInsuranceOptionTypes>;
}

export function mapRenewalPolicyExtrasToQuoteExtras(renewalPolicyExtras: RenewalPolicyExtras): QuoteExtra[] {
  const quoteExtras: QuoteExtra[] = [];

  Object.keys(renewalPolicyExtras).forEach(key => {
    const typedKey = key as keyof RenewalPolicyExtras;
    const value = renewalPolicyExtras[typedKey];
    if (!value.enabled) return;
    quoteExtras.push({
      optionType: value.type,
      type: mapRenewalPolicyExtrasKeyToPolicyExtraType(typedKey)
    });
  });

  return quoteExtras;
}

function mapRenewalPolicyExtrasKeyToPolicyExtraType(key: keyof RenewalPolicyExtras): PolicyExtraType {
  switch (key) {
    case "courtesyCar": return PolicyExtraType.CourtesyCar;
    case "legalExpenses": return PolicyExtraType.LegalExpenses;
    case "roadsideAssistancePlatinum": return PolicyExtraType.RoadsideAssistancePlatinum;
    case "smartfobKeycare": return PolicyExtraType.SmartfobKeycare;
    case "excessInsurance": return PolicyExtraType.ExcessInsurance;
  }
}