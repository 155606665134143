declare global {
  interface Window {
    ApplePaySession?: ApplePaySession;
  }
}

export async function loadApplePaySessionProvider(): Promise<void> {
  if (window.ApplePaySession) return;

  await loadApplePayJsLibrary();
}

function loadApplePayJsLibrary(): Promise<void> {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.defer = true;
    script.src = "https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js";
    script.onload = () => {
      if (window.ApplePaySession) {
        resolve();
      } else {
        reject();
      }
    };
    document.body.appendChild(script);
  });
}