import React, {FC, useState} from "react";
import ContentWithSidebarLayout
  from "../../../../../../structure/layouts/content-with-sidebar-layout/ContentWithSidebarLayout";
import SidebarWithBackNavigation
  from "../../../../../../structure/sidebar/sidebar-with-back-navigation/SidebarWithBackNavigation";
import {useNavigate} from "react-router-dom";
import {usePolicyQueryParams} from "../../../../../../router/params/Params";
import StepTitle from "../../../../../../structure/questions/step-title/StepTitle";
import AccordionList from "shared-components/dist/lists/accordian-list/AccordionList";
import DocumentList from "../../../../shared/document-list/DocumentList";
import "./PolicyViewUpcomingDocuments.css";
import {selectPolicyHolder} from "../../../../shared/redux/PortalSelectors";
import {groupPolicyDocumentsByDate} from "shared-components/dist/utils/helpers/GroupPolicyDocuments";
import {formatOrdinalDateStringFromIsoString} from "shared/dist/stdlib/DateFormat";
import PortalHeader from "../../../../shared/portal-header/PortalHeader";
import Button from "shared-components/dist/buttons/button/Button";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import RequestPrintDocumentsModal from "../request-print-documents/modal/RequestPrintDocumentsModal";
import PolicyErrorMessage from "../../../../shared/error/PolicyErrorMessage";
import {DocumentType} from "shared/dist/generated/graphql/resolvers-types";
import {useAppSelector} from "../../../../../../redux/Hooks";
import {fetchPolicyFor} from "shared/dist/graphql/queries/portal/PortalDashboardQuery";
import PolicyErrorPage from "../../../../policy-error/PolicyErrorPage";
import PolicyLoading from "../../../loading/PolicyLoading";

const PolicyViewUpcomingDocuments: FC = () => {
  const navigate = useNavigate();
  const {policyId} = usePolicyQueryParams();
  const policyHolder = useAppSelector(selectPolicyHolder);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);

  const {loading, error, data} = fetchPolicyFor(policyId);

  if (loading) return <PolicyLoading/>;

  if (error) return <PolicyErrorPage/>;

  if (!data || !data.policy || !policyHolder) return <PolicyErrorMessage/>;

  const upcomingDocuments = data.policy.documentation.upcoming ?? [];
  const activeDocuments = data.policy.documentation.active ?? [];
  const groupedDocuments = groupPolicyDocumentsByDate(upcomingDocuments);

  if (upcomingDocuments.length === 0) navigate(-1);

  const policyBooklet = activeDocuments.find(document => document.type === DocumentType.PolicyBooklet);

  return (
    <ContentWithSidebarLayout
      isPageFooterVisible={true}
      className="upcoming-documents"
      header={<PortalHeader/>}
      mainContent={
        <div>
          <StepTitle title="policies.upcomingDocuments.title"/>
          <AccordionList
            className="upcoming-documents__accordion-list"
            items={groupedDocuments.reverse().map((upcomingDocument, upcomingDocumentsIndex) => ({
              title: formatOrdinalDateStringFromIsoString(upcomingDocument.date),
              contents: (
                <>
                  <DocumentList documents={upcomingDocument.documents}/>
                  <Button
                    variant="ghost"
                    className={`accordion-contents__request-print-button accordion-contents__request-print-button__${upcomingDocument.date}`}
                    onClick={() => setSelectedIndex(upcomingDocumentsIndex)}
                  >
                    {lookupI18nString("policies.documents.requestPrintedDocuments.ready")}
                  </Button>

                  <RequestPrintDocumentsModal
                    documentBatchProps={{
                      policyId: data.policy.id,
                      policyReference: data.policy.policyReference,
                      policyEffectiveDate: upcomingDocument.documents[0].effectiveDate,
                      documentsToPrint: upcomingDocument.documents
                    }}
                    policyHolder={policyHolder}
                    policyBookletUrl={policyBooklet?.url}
                    visible={selectedIndex === upcomingDocumentsIndex}
                    onDismiss={() => setSelectedIndex(undefined)}
                  />
                </>
              )
            }))}
            defaultOpenIndexes={[0]}
          />
        </div>
      }
      sidebar={
        <SidebarWithBackNavigation
          title="policies.upcomingDocuments.sidebar.goBack"
          onBackButtonClicked={() => navigate(-1)}
        />
      }
    />
  );
};

export default PolicyViewUpcomingDocuments;
