import React, {FC} from "react";
import Question from "../../Question";
import RadioSelect from "shared-components/dist/form/radio-select/RadioSelect";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {Status} from "shared-components/dist/status-indicator/Status";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";

interface Props {
  id: string;
  title: TranslationKey;
  status?: Status;
  errorMessage?: TranslationKey;
  primaryOptions: string[];
  secondaryOptions?: string[];
  otherOptionEnabled?: boolean;
  dropdownPlaceholder?: TranslationKey;
  selectedOption: string | undefined;
  onSelection: (value: string | undefined) => void;
  hideSpacer?: boolean;
  errorTooltip?: TranslationKey;
  isFourColumns?: boolean;
  tooltip?: TooltipProps;
}

const RadioSelectQuestion: FC<Props> = (
  {
    id,
    title,
    status,
    errorMessage,
    primaryOptions,
    secondaryOptions,
    otherOptionEnabled = true,
    dropdownPlaceholder,
    selectedOption,
    onSelection,
    hideSpacer,
    errorTooltip,
    isFourColumns,
    tooltip
  }
) => {
  return (
    <Question
      id={id}
      title={title}
      status={status}
      errorMessage={errorMessage}
      hideSpacer={hideSpacer}
      errorTooltip={errorTooltip}
      tooltip={tooltip}
    >
      <RadioSelect
        name={`${id}RadioSelect`}
        primaryOptions={primaryOptions}
        secondaryOptions={secondaryOptions}
        otherOptionEnabled={otherOptionEnabled}
        dropdownPlaceholder={dropdownPlaceholder}
        selectedOption={selectedOption}
        onSelection={onSelection}
        isFourColumns={isFourColumns}
      />
    </Question>
  );
};

export default RadioSelectQuestion;