import React, {FC} from "react";
import QuoteJourneyStop from "../../stops/quote-journey-stop/QuoteJourneyStop";

interface Props {
  isVisible: boolean;
}

const InsuranceDeclinedStop: FC<Props> = ({isVisible}) => (
  <QuoteJourneyStop
    stopProps={{
      visible: isVisible,
      title: "quoteJourney.shared.insuranceDeclinedQuestion.stop.title",
      description: "quoteJourney.shared.softStop.description"
    }}
  />
);

export default InsuranceDeclinedStop;