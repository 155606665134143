import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PaymentFrequency, SavedQuote} from "../models/SavedQuote";
import {
  Aggregator,
  Price,
  ProspectiveLoan,
  ProspectiveLoanWithPrice,
  QuotePremiumPriceBreakdownDetails
} from "shared/dist/generated/graphql/resolvers-types";
import {policyPurchased, quoteReset, storedQuoteLoaded} from "../../../../../redux/Actions";
import {QuoteRetrievalStatus} from "../models/QuoteRetrievalStatus";
import {QuoteDetails} from "./QuoteDetails";
import {StoredPolicyExtra} from "../models/StoredPolicyExtra";
import {fieldChanged, fieldInvalidWithValue, fieldReset} from "shared-components/dist/models/form-field/FormField";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {loadNoClaimsDiscountProtectionData} from "./thunks/LoadNoClaimsDiscountProtectionData";
import {loadNoClaimsDiscountProtectionCost} from "./thunks/LoadNoClaimsDiscountProtectionCost";
import {InsurerQuote} from "../models/InsurerQuote";
import {LoadingStatus} from "../models/LoadingStatus";
import {Maybe} from "graphql/jsutils/Maybe";
import {quoteValidationErrorsEntityAdapter} from "./quote-validation-errors/QuoteValidationErrorEntityAdapter";
import {QuoteValidationError, QuoteValidationErrorSection} from "./quote-validation-errors/QuoteValidationError";

export const initialState: QuoteDetails = {
  quoteLoading: false,
  magicLinkId: undefined,
  quoteError: undefined,
  quoteRetrievalStatus: undefined,
  quotePrice: undefined,
  premiumPriceBreakdown: undefined,
  insurerName: undefined,
  quoteReference: undefined,
  quoteSequenceNumber: undefined,
  quoteInsurerSchemeReference: undefined,
  depositPercentage: 20,
  selectedLoan: undefined,
  prospectiveLoans: [],
  closeBrothersLoanStatus: LoadingStatus.DEFAULT,
  policyExtras: [],
  emailAddress: "",
  savedQuote: undefined,
  paymentFrequency: "Annual",
  isRetrievedQuote: false,
  hasSubmitBeenClicked: false,
  underageFinanceStopVisible: false,
  compulsoryExcess: undefined,
  voluntaryExcess: undefined,
  numberOfQuoteJourneyStops: 0,
  savedQuoteGenerationDate: undefined,
  transactionId: undefined,
  hasContactDetailsBeenConfirmed: {
    status: "default",
    value: undefined
  },
  noClaimsDiscountProtectionData: undefined,
  noClaimsDiscountProtectionDataStatus: LoadingStatus.DEFAULT,
  noClaimsDiscountProtectionCost: undefined,
  noClaimsDiscountProtectionCostAsPrice: undefined,
  noClaimsDiscountProtectionCostStatus: LoadingStatus.DEFAULT,
  insurerQuotes: undefined,
  quoteType: undefined,
  requotedWithoutComparison: false,
  quotesRetrievedAt: undefined,
  isRequoteRequired: false,
  totalExtrasPrice: undefined,
  totalUnfinancedAmountPayable: undefined,
  prospectiveLoansWithPrice: [],
  selectedLoanWithPrice: undefined,
  premiumAsPrice: undefined,
  premiumPriceBreakdownDetails: undefined,
  voluntaryExcessAsPrice: undefined,
  compulsoryExcessAsPrice: undefined,
  referredBy: undefined,
  quoteValidationErrors: quoteValidationErrorsEntityAdapter.getInitialState(),
  quoteValidationSectionBeingResolved: undefined,
  didUserSelectQuoteOnCompareYourQuotes: false,
};

export const quoteDetailsSliceName = "quoteDetailsSlice";

const quoteDetailsSlice = createSlice({
  name: quoteDetailsSliceName,
  initialState,
  reducers: {
    quoteLoading(state): void {
      state.quoteLoading = true;
    },
    quoteFinishedLoading(state, {payload}: PayloadAction<string | undefined>): void {
      state.quoteLoading = false;
      state.quotesRetrievedAt = payload;
    },
    quoteErrorChanged(state, {payload}: PayloadAction<string>): void {
      state.quoteError = payload;
    },
    quoteErrorCleared(state): void {
      state.quoteError = undefined;
    },
    quotePremiumPriceBreakdownDetailsChanged(state, {payload}: PayloadAction<QuotePremiumPriceBreakdownDetails>): void {
      state.premiumPriceBreakdownDetails = payload;
    },
    quoteReferenceChanged(state, {payload}: PayloadAction<string>): void {
      state.quoteReference = payload;
    },
    quoteSequenceNumberChanged(state, {payload}: PayloadAction<number>): void {
      state.quoteSequenceNumber = payload;
    },
    quoteInsurerSchemeReferenceChanged(state, {payload}: PayloadAction<string>): void {
      state.quoteInsurerSchemeReference = payload;
    },
    quoteStateReset(state): void {
      state.magicLinkId = undefined;
      state.quoteError = undefined;
      state.quotePrice = undefined;
      state.premiumAsPrice = undefined;
      state.premiumPriceBreakdown = undefined;
      state.premiumPriceBreakdownDetails = undefined;
      state.insurerName = undefined;
      state.quoteReference = undefined;
      state.quoteSequenceNumber = undefined;
      state.isRequoteRequired = false;
    },
    insurerNameChanged(state, action: PayloadAction<string>): void {
      state.insurerName = action.payload;
    },
    policyExtraAdded(state, action: PayloadAction<StoredPolicyExtra>): void {
      state.policyExtras.push(action.payload);
    },
    policyExtraRemoved(state, action: PayloadAction<StoredPolicyExtra>): void {
      state.policyExtras = state.policyExtras.filter(item => item.id !== action.payload.id);
    },
    policyExtrasChanged(state, action: PayloadAction<StoredPolicyExtra[]>): void {
      state.policyExtras = action.payload;
      state.totalExtrasPrice = undefined;
      state.totalUnfinancedAmountPayable = undefined;
    },
    policyExtraEdited(state, action: PayloadAction<StoredPolicyExtra>): void {
      state.policyExtras = state.policyExtras
        .filter(item => item.id !== action.payload.id)
        .concat(action.payload);
    },
    emailAddressChanged(state, action: PayloadAction<string>): void {
      state.emailAddress = action.payload;
    },
    quoteSaved(state, action: PayloadAction<SavedQuote>): void {
      state.savedQuote = action.payload;
    },
    paymentFrequencyChanged(state, action: PayloadAction<PaymentFrequency>): void {
      state.paymentFrequency = action.payload;
    },
    selectedLoanChanged(state, action: PayloadAction<ProspectiveLoan>): void {
      state.selectedLoan = action.payload;
    },
    selectedLoanWithPriceChanged(state, action: PayloadAction<ProspectiveLoanWithPrice | undefined>): void {
      state.selectedLoanWithPrice = action.payload;
    },
    depositPercentageChanged(state, action: PayloadAction<number>): void {
      state.depositPercentage = action.payload;
    },
    startedLoadingCloseBrothersLoan(state): void {
      state.closeBrothersLoanStatus = LoadingStatus.LOADING;
    },
    loadingCloseBrothersLoanFailed(state): void {
      state.closeBrothersLoanStatus = LoadingStatus.ERRORED;
    },
    loadingCloseBrothersLoanSucceeded(state): void {
      state.closeBrothersLoanStatus = LoadingStatus.SUCCESS;
    },
    hasSubmitBeenClickedChanged(state, action: PayloadAction<boolean>): void {
      state.hasSubmitBeenClicked = action.payload;
    },
    quoteRetrievalStatusChanged(state, action: PayloadAction<QuoteRetrievalStatus | undefined>): void {
      state.quoteRetrievalStatus = action.payload;
    },
    underageFinanceStopVisibleChanged(state, action: PayloadAction<boolean>): void {
      state.underageFinanceStopVisible = action.payload;
    },
    compulsoryExcessChanged(state, action: PayloadAction<number>): void {
      state.compulsoryExcess = action.payload;
    },
    voluntaryExcessChanged(state, action: PayloadAction<number>): void {
      state.voluntaryExcess = action.payload;
    },
    magicLinkIdChanged(state, action: PayloadAction<string>): void {
      state.magicLinkId = action.payload;
    },
    quoteJourneyStopShown(state): void {
      state.numberOfQuoteJourneyStops += 1;
    },
    quoteJourneyStopHidden(state): void {
      state.numberOfQuoteJourneyStops -= 1;
    },
    transactionIdChanged(state, action: PayloadAction<string>): void {
      state.transactionId = action.payload;
    },
    savedQuoteGenerationDateCleared(state): void {
      state.savedQuoteGenerationDate = undefined;
    },
    hasContactDetailsBeenConfirmedChanged(state, action: PayloadAction<boolean>): void {
      fieldChanged(state.hasContactDetailsBeenConfirmed, action.payload);
    },
    hasContactDetailsBeenConfirmedInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalidWithValue(state.hasContactDetailsBeenConfirmed, action.payload);
    },
    hasContactDetailsBeenConfirmedReset(state): void {
      fieldReset(state.hasContactDetailsBeenConfirmed);
    },
    insurerQuotesChanged(state, action: PayloadAction<InsurerQuote[]>): void {
      state.insurerQuotes = action.payload;
    },
    selectedQuoteChanged(state, action: PayloadAction<InsurerQuote>): void {
      const insurerQuote = action.payload;

      state.quoteType = insurerQuote.quoteType;
      state.quoteReference = insurerQuote.reference;
      state.quoteSequenceNumber = insurerQuote.sequenceNumber;
      state.insurerName = insurerQuote.insurer;
      state.quoteInsurerSchemeReference = insurerQuote.insurerSchemeReference;
      state.quotePrice = insurerQuote.premium;
      state.premiumAsPrice = insurerQuote.premiumPrice;
      state.premiumPriceBreakdown = insurerQuote.premiumPriceBreakdown ?? undefined;
      state.premiumPriceBreakdownDetails = insurerQuote.premiumPriceBreakdownDetails ?? undefined;
      state.compulsoryExcess = insurerQuote.compulsoryExcess;
      state.compulsoryExcessAsPrice = insurerQuote.compulsoryExcessPrice;
      state.voluntaryExcessAsPrice = insurerQuote.voluntaryExcessPrice;
      state.voluntaryExcess = insurerQuote.voluntaryExcess;
      state.selectedLoan = insurerQuote.selectedLoan;
      state.selectedLoanWithPrice = insurerQuote.selectedLoanWithPrice;
      state.prospectiveLoans = insurerQuote.loans ?? [];
      state.prospectiveLoansWithPrice = insurerQuote.loansWithPrice ?? [];
      state.requotedWithoutComparison = false;
      state.totalUnfinancedAmountPayable = insurerQuote.totalUnfinancedAmountPayable;
    },
    userHasSelectedQuoteUsingCompareYourQuotes(state): void {
      state.didUserSelectQuoteOnCompareYourQuotes = true;
    },
    resetSelectedQuote(state): void {
      state.quoteType = undefined;
      state.quoteReference = undefined;
      state.quoteSequenceNumber = undefined;
      state.insurerName = undefined;
      state.quoteInsurerSchemeReference = undefined;
      state.quotePrice = undefined;
      state.premiumAsPrice = undefined;
      state.premiumPriceBreakdown = undefined;
      state.premiumPriceBreakdownDetails = undefined;
      state.compulsoryExcess = undefined;
      state.voluntaryExcess = undefined;
      state.compulsoryExcessAsPrice = undefined;
      state.voluntaryExcessAsPrice = undefined;
      state.selectedLoan = undefined;
      state.selectedLoanWithPrice = undefined;
      state.prospectiveLoans = [];
      state.prospectiveLoansWithPrice = [];
      state.requotedWithoutComparison = false;
    },
    requotedWithoutComparisonChanged(state, action: PayloadAction<boolean>): void {
      state.requotedWithoutComparison = action.payload;
    },
    requoteIsRequired(state): void {
      state.isRequoteRequired = true;
    },
    totalExtrasPriceChanged(state, action: PayloadAction<Maybe<Price> | undefined>): void {
      state.totalExtrasPrice = action.payload ?? undefined;
    },
    totalUnfinancedAmountPayableChanged(state, action: PayloadAction<Price>): void {
      state.totalUnfinancedAmountPayable = action.payload;
    },
    prospectiveLoansWithPriceChanged(state, {payload: newProspectiveLoans}: PayloadAction<ProspectiveLoanWithPrice[]>): void {
      state.closeBrothersLoanStatus = LoadingStatus.SUCCESS;
      state.selectedLoanWithPrice = calculateNewSelectedLoanWithPrice(state.selectedLoanWithPrice, newProspectiveLoans);
      state.prospectiveLoansWithPrice = newProspectiveLoans;
    },
    premiumAsPriceChanged(state, {payload}: PayloadAction<Price>): void {
      state.premiumAsPrice = payload;
    },
    insurerQuoteChanged(state, action: PayloadAction<InsurerQuote>): void {
      const updatedInsurerQuote = action.payload;
      state.insurerQuotes = state.insurerQuotes?.map(quote => {
        if (quote.reference === updatedInsurerQuote.reference && quote.sequenceNumber === updatedInsurerQuote.sequenceNumber) {
          return updatedInsurerQuote;
        }
        return quote;
      });
    },
    referredByChanged(state, {payload}: PayloadAction<Aggregator>): void {
      state.referredBy = payload;
    },
    removeQuoteValidationErrors(state, {payload}: PayloadAction<QuoteValidationError[]>): void {
      quoteValidationErrorsEntityAdapter.removeMany(state.quoteValidationErrors, payload.map(error => error.id));
    },
    setQuoteValidationErrors(state, {payload}: PayloadAction<QuoteValidationError[]>): void {
      quoteValidationErrorsEntityAdapter.removeAll(state.quoteValidationErrors);
      quoteValidationErrorsEntityAdapter.addMany(state.quoteValidationErrors, payload);
    },
    setQuoteValidationSectionBeingResolved(state, {payload}: PayloadAction<QuoteValidationErrorSection>): void {
      state.quoteValidationSectionBeingResolved = payload;
    },
    resetQuoteValidationSectionBeingResolved(state): void {
      state.quoteValidationSectionBeingResolved = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(quoteReset, () => initialState);
    builder.addCase(policyPurchased, () => initialState);
    builder.addCase(storedQuoteLoaded, (state, {payload}) => {
      state.isRetrievedQuote = true;
      state.didUserSelectQuoteOnCompareYourQuotes = false;
      state.policyExtras = payload.quoteArguments.policyExtras;
      state.depositPercentage = payload.quoteArguments.depositPercentage;
      state.referredBy = payload.isReferredBy;

      // TODO: We need to figure out what to do if someone has a loan against a rate that is no longer valid
      //       What data should we be persisting (possibly the loan details/selectedLoan instead)
      //       How will this affect the existing data model
      state.selectedLoan = state.prospectiveLoans.find(loan => loan.rate.rateReference === payload.quoteArguments.selectedRate?.rateReference);
      state.selectedLoanWithPrice = state.prospectiveLoansWithPrice.find(loan => loan.rateReference === payload.quoteArguments.selectedRate?.rateReference);
      state.paymentFrequency = payload.quoteArguments.paymentFrequency;
      state.quoteReference = payload.reference;
      state.quoteSequenceNumber = payload.sequenceNumber;
      state.insurerName = payload.insurer;
      state.quoteInsurerSchemeReference = payload.insurerSchemeReference;
      state.quotePrice = payload.price;
      state.premiumPriceBreakdown = payload.premiumPriceBreakdown;
      state.compulsoryExcess = payload.compulsoryExcess;
      state.voluntaryExcess = payload.voluntaryExcess;
      state.magicLinkId = payload.magicLinkId;
      state.savedQuoteGenerationDate = payload.quoteGenerationDate;
      state.quoteType = payload.quoteType;
      state.insurerQuotes = payload.insurerQuotes;
      state.totalExtrasPrice = payload.totalExtrasPrice;
      state.premiumAsPrice = payload.premiumAsPrice;
      state.totalUnfinancedAmountPayable = payload.totalUnfinancedAmountPayable;
      state.voluntaryExcessAsPrice = payload.voluntaryExcessAsPrice;
      state.compulsoryExcessAsPrice = payload.compulsoryExcessAsPrice;
      state.premiumPriceBreakdownDetails = payload.premiumPriceBreakdownDetails;
    });
    builder.addCase(loadNoClaimsDiscountProtectionData.pending, (state) => {
      state.noClaimsDiscountProtectionDataStatus = LoadingStatus.LOADING;
      state.noClaimsDiscountProtectionData = undefined;
    });
    builder.addCase(loadNoClaimsDiscountProtectionData.fulfilled, (state, {payload}) => {
      state.noClaimsDiscountProtectionDataStatus = LoadingStatus.SUCCESS;
      state.noClaimsDiscountProtectionData = payload;
    });
    builder.addCase(loadNoClaimsDiscountProtectionData.rejected, (state) => {
      state.noClaimsDiscountProtectionDataStatus = LoadingStatus.ERRORED;
    });
    builder.addCase(loadNoClaimsDiscountProtectionCost.pending, (state) => {
      state.noClaimsDiscountProtectionCostStatus = LoadingStatus.LOADING;
      state.noClaimsDiscountProtectionCost = undefined;
      state.noClaimsDiscountProtectionCostAsPrice = undefined;
    });
    builder.addCase(loadNoClaimsDiscountProtectionCost.fulfilled, (state, {payload}) => {
      state.noClaimsDiscountProtectionCostStatus = LoadingStatus.SUCCESS;
      state.noClaimsDiscountProtectionCost = payload;
      state.noClaimsDiscountProtectionCostAsPrice = payload === undefined ? undefined
        : {amount: `${payload}`, currency: "gbp"};
    });
    builder.addCase(loadNoClaimsDiscountProtectionCost.rejected, (state) => {
      state.noClaimsDiscountProtectionCostStatus = LoadingStatus.ERRORED;
    });
  }
});

export function calculateNewSelectedLoanWithPrice(previousSelectedLoan: ProspectiveLoanWithPrice | undefined, newProspectiveLoans: ProspectiveLoanWithPrice[]): ProspectiveLoanWithPrice | undefined {
  if (newProspectiveLoans.length === 0) return undefined;

  if (!previousSelectedLoan) return newProspectiveLoans[0];

  const previousRateReference = previousSelectedLoan.rateReference;
  return newProspectiveLoans.find(loan => loan.rateReference === previousRateReference);
}

export const {
  quoteLoading,
  quoteFinishedLoading,
  quoteErrorChanged,
  quoteErrorCleared,
  quoteStateReset,
  insurerNameChanged,
  quoteReferenceChanged,
  quoteSequenceNumberChanged,
  quoteInsurerSchemeReferenceChanged,
  policyExtraAdded,
  policyExtraRemoved,
  policyExtraEdited,
  emailAddressChanged,
  quoteSaved,
  paymentFrequencyChanged,
  selectedLoanChanged,
  depositPercentageChanged,
  startedLoadingCloseBrothersLoan,
  loadingCloseBrothersLoanFailed,
  hasSubmitBeenClickedChanged,
  quoteRetrievalStatusChanged,
  underageFinanceStopVisibleChanged,
  compulsoryExcessChanged,
  voluntaryExcessChanged,
  magicLinkIdChanged,
  quoteJourneyStopShown,
  quoteJourneyStopHidden,
  transactionIdChanged,
  savedQuoteGenerationDateCleared,
  hasContactDetailsBeenConfirmedChanged,
  hasContactDetailsBeenConfirmedInvalid,
  hasContactDetailsBeenConfirmedReset,
  insurerQuotesChanged,
  loadingCloseBrothersLoanSucceeded,
  selectedQuoteChanged,
  userHasSelectedQuoteUsingCompareYourQuotes,
  resetSelectedQuote,
  requotedWithoutComparisonChanged,
  requoteIsRequired,
  totalExtrasPriceChanged,
  totalUnfinancedAmountPayableChanged,
  policyExtrasChanged,
  selectedLoanWithPriceChanged,
  insurerQuoteChanged,
  prospectiveLoansWithPriceChanged,
  premiumAsPriceChanged,
  quotePremiumPriceBreakdownDetailsChanged,
  referredByChanged,
  removeQuoteValidationErrors,
  setQuoteValidationErrors,
  setQuoteValidationSectionBeingResolved,
  resetQuoteValidationSectionBeingResolved
} = quoteDetailsSlice.actions;

export const quoteDetailsReducer = quoteDetailsSlice.reducer;
