import {gql} from "@apollo/client";
import {VehicleOptions} from "shared/dist/generated/graphql/resolvers-types";

export const TRANSMISSION_TYPES_LOOKUP_QUERY = gql`
  query TransmissionTypesLookup($filters: VehicleOptionsFilters!) {
    vehicle {
      vehicleOptions(optionsFilters: $filters) {
        transmissionType
      }
    }
  }
`;

export interface TransmissionTypesLookupResult {
  vehicleOptions: Pick<VehicleOptions, "transmissionType">;
}
