import {FormField, RequiredFormField} from "shared-components/dist/models/form-field/FormField";
import {
  ConditionalFormField,
  OrderedConditionalFormField
} from "shared-components/dist/models/form-field/ConditionalFormField";

export function buildFormField<T>(
  value: T | undefined,
  {
    status = value === undefined ? "default" : "success"
  }: Partial<FormField<T>> = {}
): FormField<T> {
  return {
    status,
    value
  };
}

export function buildConditionalFormField<T>(
  value: T | undefined,
  {
    status = (value === undefined) ? "default" : "success",
    isActive = value !== undefined
  }: Partial<ConditionalFormField<T>> = {}
): ConditionalFormField<T> {
  return {
    status,
    value,
    isActive
  };
}

export function buildRequiredFormField<T>(
  value: T,
  {
    status = "default"
  }: Partial<RequiredFormField<T>> = {}
): RequiredFormField<T> {
  return {
    status,
    value
  };
}

export function buildOrderedConditionalFormField<T>(
  value: T | undefined,
  {
    status = "default",
    isActive = true,
    order = 1
  }: Partial<OrderedConditionalFormField<T>> = {}
): OrderedConditionalFormField<T> {
  return {
    status,
    value,
    isActive,
    order
  };
}