import {ProposedFinancedPolicySetupRequest} from "../../../../shared/models/ProposedFinancedPolicySetupRequest";
import {parseGooglePayPaymentToken} from "../../../../shared/google-pay/parsers/ParseGooglePayPaymentToken";
import {GooglePayPurchase} from "../../../../shared/google-pay/model/GooglePayPurchase";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {QUOTE_PAYMENT_SUCCESS} from "../../../../../../router/models/Routes";
import {logger} from "../../../../../../utils/logging/Logger";
import {
  FinancedPolicyPaymentResult,
  GooglePayFinancedPolicySetUpRequest,
  GooglePayFinancedProcessRenewalRequest,
  Mutation
} from "shared/dist/generated/graphql/resolvers-types";
import {apolloClient} from "../../../../../../graphql/apollo/ApolloClientProvider";
import {
  GOOGLE_PAY_SET_UP_FINANCED_POLICY,
  GooglePayFinancedPolicySetUpVariables
} from "shared/dist/graphql/mutations/payment/GooglePaySetUpFinancedPolicyMutation";
import {ProposedFinancedRenewalRequest} from "../../../../shared/models/ProposedFinancedRenewalRequest";
import {
  GOOGLE_PAY_PROCESS_FINANCED_RENEWAL,
  GooglePayFinancedRenewalPaymentVariables
} from "shared/dist/graphql/mutations/payment/GooglePayProcessFinancedRenewalMutation";

export function useGooglePayPurchaseFinancedPolicy(proposedPolicyRequest: ProposedFinancedPolicySetupRequest): GooglePayPurchase {
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const makePayment = async (paymentData: google.payments.api.PaymentData): Promise<void> => {
    setLoading(true);

    try {
      const paymentResult = await purchasePolicyMutation({
        payment: {
          token: parseGooglePayPaymentToken(paymentData)
        },
        depositPercentage: proposedPolicyRequest.depositPercentage,
        financeInformation: proposedPolicyRequest.financeInformation,
        loanSetUp: proposedPolicyRequest.loanSetUp,
        ...proposedPolicyRequest.quoteValues
      });

      if (paymentResult === FinancedPolicyPaymentResult.Success) {
        navigate(QUOTE_PAYMENT_SUCCESS);
      } else {
        setPaymentFailed(true);
      }
    } catch (error) {
      logger.error("Payment failed", error);
      setPaymentFailed(true);
    } finally {
      setLoading(false);
    }
  };

  const resetPaymentFailed = (): void => setPaymentFailed(false);

  return {
    makePayment,
    loading,
    paymentFailed,
    resetPaymentFailed
  };
}

export function useGooglePayPurchaseFinancedRenewal(proposedFinancedRenewalRequest: ProposedFinancedRenewalRequest): GooglePayPurchase {
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const makePayment = async (paymentData: google.payments.api.PaymentData): Promise<void> => {
    setLoading(true);

    try {
      const paymentResult = await purchaseRenewalMutation({
        payment: {
          token: parseGooglePayPaymentToken(paymentData)
        },
        ...proposedFinancedRenewalRequest
      });

      if (paymentResult === FinancedPolicyPaymentResult.Success) {
        navigate(`/portal/policy/${proposedFinancedRenewalRequest.proposedRenewalRequest.policyId}/renewals/payment/success`);
      } else {
        setPaymentFailed(true);
      }
    } catch (error) {
      logger.error("Payment failed", error);
      setPaymentFailed(true);
    } finally {
      setLoading(false);
    }
  };

  const resetPaymentFailed = (): void => setPaymentFailed(false);

  return {
    makePayment,
    loading,
    paymentFailed,
    resetPaymentFailed
  };
}

async function purchasePolicyMutation(request: GooglePayFinancedPolicySetUpRequest): Promise<FinancedPolicyPaymentResult> {
  const response = await apolloClient.mutate<Pick<Mutation, "policySetup">, GooglePayFinancedPolicySetUpVariables>({
    mutation: GOOGLE_PAY_SET_UP_FINANCED_POLICY,
    variables: {
      request
    }
  });
  return response.data?.policySetup.googlePay.setUpFinancedPolicy.result ?? FinancedPolicyPaymentResult.SomethingWentWrong;
}

async function purchaseRenewalMutation(request: GooglePayFinancedProcessRenewalRequest): Promise<FinancedPolicyPaymentResult> {
  const response = await apolloClient.mutate<Pick<Mutation, "renewals">, GooglePayFinancedRenewalPaymentVariables>({
    mutation: GOOGLE_PAY_PROCESS_FINANCED_RENEWAL,
    variables: {
      request
    }
  });
  return response.data?.renewals.googlePay.processFinancedRenewal.result ?? FinancedPolicyPaymentResult.SomethingWentWrong;
}