import React, {FC} from "react";
import {coverStartDateReset, existingPolicyExpiryDateReset} from "../your-cover/redux/CoverDetailsSlice";
import PlainHeader from "../../../../structure/navigation/plain-header/PlainHeader";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import Button from "shared-components/dist/buttons/button/Button";
import {useNavigate} from "react-router-dom";
import {buildQuoteRoute} from "../../../../utils/navigation/NavigationHelpers";
import {YOUR_CAR} from "../../../../router/models/Routes";
import "./ExpiredQuote.css";
import {useAppDispatch} from "../../../../redux/Hooks";

const ExpiredQuote: FC = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const onButtonClicked = (): void => {
    navigate(buildQuoteRoute(location.pathname, YOUR_CAR));
    dispatch(coverStartDateReset());
    dispatch(existingPolicyExpiryDateReset());
  };

  return (
    <div className="expired-quote">
      <PlainHeader/>
      <div className="expired-quote__body">
        <h2>{lookupI18nString("expiredQuote.title")}</h2>
        <p>{lookupI18nString("expiredQuote.description")}</p>
        <Button
          expanded
          onClick={onButtonClicked}
        >
          {lookupI18nString("expiredQuote.button")}
        </Button>
      </div>
    </div>
  );
};

export default ExpiredQuote;