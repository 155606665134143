import {
  FetchProspectiveLoansResponse,
  PolicySetupQueryFetchProspectiveLoansArgs,
} from "shared/dist/generated/graphql/resolvers-types";
import {apolloClient} from "../../../../../../graphql/apollo/ApolloClientProvider";
import {FETCH_PROSPECTIVE_LOANS} from "shared/dist/graphql/queries/quote/FetchProspectiveLoans";
import {FetchProspectiveLoansResult} from "shared/src/models/quote/FetchProspectiveLoansResult";

export function fetchProspectiveLoansQuery(quoteReference: string, quoteSequenceNumber: number, depositPercentage?: number): Promise<FetchProspectiveLoansResponse> {
  return apolloClient.query<FetchProspectiveLoansResult, PolicySetupQueryFetchProspectiveLoansArgs>({
    query: FETCH_PROSPECTIVE_LOANS,
    variables: {
      quoteReference,
      quoteSequenceNumber,
      depositPercentage,
    },
    fetchPolicy: "no-cache"
  })
    .then(result => Promise.resolve(result.data.policySetup.fetchProspectiveLoans))
    .catch(error => Promise.reject(error));
}
