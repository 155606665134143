import React, {FC, ReactChild, ReactChildren, ReactElement} from "react";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./PaymentStep.css";
import {SidebarPresentationMode} from "../../../../structure/sidebar/collapsible-sidebar/CollapsibleSidebar";
import ContentWithSidebarLayout
  from "../../../../structure/layouts/content-with-sidebar-layout/ContentWithSidebarLayout";
import PaymentStepSidebar from "./PaymentStepSidebar";
import QuoteHeader from "../../../quote/vehicle/shared/quote-step/header/QuoteHeader";

interface Props {
  title: TranslationKey;
  description?: TranslationKey;
  titleImage?: ReactChild | ReactChildren;
  footerContent?: ReactChild | ReactChildren;
  sidebarTitle: ReactChild | ReactChildren;
  sidebarContent?: ReactChild | ReactChildren;
}

const PaymentStep: FC<Props> = (
  {
    title,
    description,
    titleImage,
    children,
    footerContent,
    sidebarTitle,
    sidebarContent,
  }
) => {
  const buildSidebar = (presentationMode: SidebarPresentationMode): ReactElement => (
    <PaymentStepSidebar
      presentationMode={presentationMode}
      sidebarContent={sidebarContent}
      sidebarTitle={sidebarTitle}
    />
  );

  return (
    <ContentWithSidebarLayout
      className="payment-step"
      sidebar={
        <div className="payment-step__sidebar--desktop">
          {buildSidebar("OPEN")}
        </div>
      }
      header={
        <QuoteHeader showButtons={false}/>
      }
      mainContent={
        <>
          <div className="payment-step__sidebar--mobile">
            {buildSidebar("CLOSED")}
          </div>

          <h3 className="payment-step__title">{lookupI18nString(title)}</h3>

          {description && <p className="payment-step__description">{lookupI18nString(description)}</p>}

          {titleImage && <div className="payment-step__image">{titleImage}</div>}

          {children}
        </>
      }
      footer={
        <div className="payment-step__footer">
          {footerContent}
        </div>
      }
    />
  );
};

export default PaymentStep;