import {AppThunk, AppThunkDispatch} from "../../../../../redux/Store";
import {CoverDetailsFields} from "../redux/CoverDetailsFields";
import {coverStartDateReset} from "../redux/CoverDetailsSlice";

type CheckFunction = (coverDetails: CoverDetailsFields, dispatch: AppThunkDispatch) => void;

export const resetCoverDetailsErrorStates = (): AppThunk => async (dispatch, getState) => {
  [
    resetCoverStartDate
  ].forEach((checkFunction: CheckFunction) => checkFunction(getState().coverDetails, dispatch));
};

function resetCoverStartDate(coverDetails: CoverDetailsFields, dispatch: AppThunkDispatch): void {
  const coverStartDate = coverDetails.coverStartDate.value;
  if (!coverStartDate) dispatch(coverStartDateReset());
}
