import React, {FC, useState} from "react";
import {ReactComponent as Logo} from "shared-components/dist/assets/icons/logos/stroll/stroll-logo-primary-dark.svg";
import "./PriorQuoteHeader.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import Button from "shared-components/dist/buttons/button/Button";
import {formatDateStringFromIsoString} from "shared/dist/stdlib/DateFormat";
import {convertBooleanToConfirmation} from "shared-components/dist/models/Confirmation";
import {isStartDateValid} from "shared-components/dist/utils/validation/StartDate";
import {totalPolicyExcessSelector} from "../../../../../shared/redux/TotalPolicyExcessSelector";
import RetrieveQuoteHeaderButton
  from "../../../../../../../../structure/buttons/retrieve-a-quote/RetrieveQuoteHeaderButton";
import LoginButton from "../../../../../../../../structure/buttons/login-button/LoginButton";
import {useNavigateAfterValidation} from "../../../../../shared/hooks/NavigateAfterValidation";
import {formatRegistrationNumber} from "../../../../../../../../utils/validation/RegistrationNumber";
import {LoadingStatus} from "../../../../models/LoadingStatus";
import CheaperQuoteNotificationBanner
  from "../../../shared/cheaper-quote-notification-banner/CheaperQuoteNotificationBanner";
import {useAppSelector} from "../../../../../../../../redux/Hooks";
import {useQuotePriceProps} from "../../../../../shared/quote-summary/components/quote-price-card/hooks/UseQuotePricesProps";
import {CurrencyFormat} from "../../../../../../../../utils/money/CurrencyFormat";

interface Props {
  validateForm: () => void;
  isFormValid: boolean;
  route: string;
}

const PriorQuoteHeader: FC<Props> = (
  {
    isFormValid,
    route,
    validateForm
  }
) => {
  const closeBrothersLoanStatus = useAppSelector(state => state.quoteDetails.closeBrothersLoanStatus);
  const quoteLoading = useAppSelector(state => state.quoteDetails.quoteLoading);
  const {totalUnfinancedAmountPayable} = useQuotePriceProps();
  const vehicleRegistration = useAppSelector(state => state.vehicleDetails.vehicleFromRegLookup?.registration);
  const noClaimsProtection = useAppSelector(state => state.coverDetails.protectNoClaimsBonus.value);
  const policyExcess = useAppSelector(totalPolicyExcessSelector);
  const coverType = useAppSelector(state => state.coverDetails.coverType.value?.description);
  const startDate = useAppSelector(state => state.coverDetails.coverStartDate.value?.date);
  const quoteReference = useAppSelector(state => state.quoteDetails.quoteReference ?? "");

  const {navigateWhenValid} = useNavigateAfterValidation(route, isFormValid);
  const [validationInProgress, setValidationInProgress] = useState(false);

  function onBuyNowClicked(): void {
    setValidationInProgress(true);
    validateForm();
    setValidationInProgress(false);
    navigateWhenValid();
  }

  const isPlaceholderShown = (): boolean => {
    return quoteLoading || closeBrothersLoanStatus === LoadingStatus.LOADING;
  };

  return (
    <div className="prior-quote-header">
      <div className="prior-quote-header__top">
        <div className="prior-quote-header__top__logo">
          <Logo/>
        </div>

        <div className="prior-quote-header__top__buttons">
          <RetrieveQuoteHeaderButton/>
          <LoginButton/>
        </div>
      </div>

      <CheaperQuoteNotificationBanner/>

      <div className="prior-quote-header__bottom">
        <div className="prior-quote-header__bottom__header">
          <div className="prior-quote-header__bottom__title">
            <div>{lookupI18nString("priorQuote.header.quotePrice.title")}</div>
            <div> {quoteReference}</div>
          </div>
          <h3>
            {
              isPlaceholderShown()
                ? lookupI18nString("priorQuote.header.quotePrice.placeholder")
                : CurrencyFormat.formatPriceWithSymbol(totalUnfinancedAmountPayable)
            }
          </h3>
        </div>

        <div className="prior-quote-header__bottom__content">
          <div className="prior-quote-header__bottom__content-item">
            <span className="prior-quote-header__bottom__title">
              {lookupI18nString("priorQuote.header.vehicleReg")}
            </span>
            <h5>{vehicleRegistration ? formatRegistrationNumber(vehicleRegistration) : lookupI18nString("priorQuote.header.vehicleReg.placeholder")}</h5>
          </div>

          {noClaimsProtection !== undefined && (
            <div className="prior-quote-header__bottom__content-item">
              <span className="prior-quote-header__bottom__title">
                {lookupI18nString("priorQuote.header.noClaimsProtection")}
              </span>
              <h5>{convertBooleanToConfirmation(noClaimsProtection)}</h5>
            </div>
          )}

          <div className="prior-quote-header__bottom__content-item">
            <span className="prior-quote-header__bottom__title">
              {lookupI18nString("priorQuote.header.policyExcess")}
            </span>
            <h5>£{policyExcess.toString()}</h5>
          </div>
          <div className="prior-quote-header__bottom__content-item">
            <span className="prior-quote-header__bottom__title">
              {lookupI18nString("priorQuote.header.startDate")}
            </span>
            <h5>
              {startDate ?
                formatDateStringFromIsoString(startDate) :
                lookupI18nString("priorQuote.header.startDate.placeholder")
              }
            </h5>
          </div>
          <div className="prior-quote-header__bottom__content-item">
            <span className="prior-quote-header__bottom__title">
              {lookupI18nString("priorQuote.header.coverType")}
            </span>
            <h5>{coverType ?? lookupI18nString("priorQuote.header.coverType.placeholder")}</h5>
          </div>

          <div className="prior-quote-header__bottom__button">
            <Button
              variant="secondary"
              onClick={onBuyNowClicked}
              disabled={!isStartDateValid(startDate)}
              loading={validationInProgress}
            >
              {lookupI18nString("priorQuote.header.buyNow")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriorQuoteHeader;
