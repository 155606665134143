import {sendPasswordChangedEmail} from "../../../graphql/mutations/policy-holder/EmailConfirmPasswordChangeMutation";
import {noticeError} from "../../../newrelic/NoticeError";
import {logger} from "../../../utils/logging/Logger";

export async function sendPasswordSuccessfullyUpdatedEmail(): Promise<void> {
  try {
    await sendPasswordChangedEmail();
  } catch (error) {
    await noticeError(error);
    logger.error("Error occurred sending password changed email", error);
  }
}
