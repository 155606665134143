import React, {FC} from "react";
import NameInputField from "shared-components/dist/form/name-input-field/NameInputField";
import Question from "../../../../../../structure/questions/question/Question";
import {FormField} from "shared-components/dist/models/form-field/FormField";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {Validator} from "shared-components/dist/utils/validation/Validator";

interface OwnProps {
  id: string;
  title: TranslationKey;
  surnameField: FormField<string>;
  onComplete: (value: string) => void;
  onInvalid: (errorMessage: TranslationKey) => void;
  validators: Validator<string>[];
  placeholder?: TranslationKey;
}

const SurnameQuestion: FC<OwnProps> = (
  {
    id,
    title,
    surnameField,
    onComplete,
    onInvalid,
    validators,
    placeholder
  }
) => (
  <Question
    id={id}
    title={title}
    status={surnameField.status}
    errorMessage={surnameField.errorMessage}
  >
    <NameInputField
      name="surname"
      value={surnameField.value}
      onComplete={onComplete}
      onInvalid={onInvalid}
      validators={validators}
      placeholder={placeholder}
    />
  </Question>
);

export default SurnameQuestion;