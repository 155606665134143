import React, {FC} from "react";
import QuoteStep from "../shared/quote-step/QuoteStep";
import {quoteSteps} from "../shared/quote-step/QuoteSteps";
import VoluntaryExcessQuestion from "./components/voluntary-excess/VoluntaryExcessQuestion";
import CoverStartDateQuestion from "./components/cover-start-date/CoverStartDateQuestion";
import {flagUnansweredCoverDetailsQuestions} from "./validation/FlagUnansweredCoverDetailsQuestions";
import PreviouslyHeldInsuranceQuestion from "./components/previously-held-insurance/PreviouslyHeldInsuranceQuestion";
import AverageMileageQuestion from "./components/average-mileage/AverageMileageQuestion";
import CoverTypeQuestion from "./components/cover-type/CoverTypeQuestion";
import {buildQuoteRoute} from "../../../../utils/navigation/NavigationHelpers";
import {useLocation, useNavigate} from "react-router-dom";
import AverageBusinessMileageQuestion from "./components/average-business-mileage/AverageBusinessMileageQuestion";
import {logYourCoverGoogleAnalyticsEvent} from "../../../../utils/analytics/MotorAnalytics";
import {resetCoverDetailsErrorStates} from "./validation/ResetCoverDetailsErrorStates";
import {useScrollToHash} from "../../../../utils/navigation/ScrollToHash";
import {insurerQuotesChanged} from "../your-quote/redux/QuoteDetailsSlice";
import NamedDriverSecondaryQuestionPanel from "./components/named-driver/NamedDriverSecondaryQuestionPanel";
import SidebarCompanyCarIneligibleInfoCard
  from "./components/named-driver/company-car-ineligible-info-card/SidebarCompanyCarIneligibleInfoCard";
import {selectIsNoClaimsBonusInvalid} from "./redux/selectors/named-driver/IsNoClaimsBonusInvalidSelector";
import {selectIsCompanyCarBonusInvalid} from "./redux/selectors/named-driver/IsCompanyCarBonusInvalidSelector";
import {useAppDispatch, useAppSelector} from "../../../../redux/Hooks";
import CreditSearchesStatement from "./components/consent-for-searches/CreditSearchesStatement";

const YourCover: FC = () => {
  const dispatch = useAppDispatch();
  const isPreviousPageValid = useAppSelector(quoteSteps.yourCover.previousRoute.validator);
  const areCoverDetailsValid = useAppSelector(quoteSteps.yourCover.nextRoute.validator);
  const companyCarDeclarationAcceptance = useAppSelector(state => state.coverDetails.companyCarDeclarationAcceptance.value);
  const isNoClaimsBonusInvalid = useAppSelector(selectIsNoClaimsBonusInvalid);
  const isCompanyCarBonusInvalid = useAppSelector(selectIsCompanyCarBonusInvalid);

  const navigate = useNavigate();
  const location = useLocation();

  useScrollToHash();

  const previousRoute = buildQuoteRoute(location.pathname, quoteSteps.yourCover.previousRoute);
  const nextRoute = quoteSteps.yourQuote.routes.compare;

  if (!isPreviousPageValid) {
    navigate(previousRoute);
    return null;
  }

  const onSubmit = (): void => {
    dispatch(flagUnansweredCoverDetailsQuestions());

    logYourCoverGoogleAnalyticsEvent({
      categorySuffix: "go_to_next_page",
      action: areCoverDetailsValid ? "Success" : "Failure",
    });

    if (areCoverDetailsValid) {
      dispatch(insurerQuotesChanged([]));
    }
  };

  const sidebarInfoPanel = companyCarDeclarationAcceptance === false ? <SidebarCompanyCarIneligibleInfoCard/> : undefined;

  return (
    <QuoteStep
      forwardButtonLabel="coverDetails.footerButton"
      previousRoute={previousRoute}
      onGoBackClicked={() => dispatch(resetCoverDetailsErrorStates())}
      nextRoute={nextRoute}
      onSubmit={onSubmit}
      isFormValid={areCoverDetailsValid}
      title="coverDetails.title"
      sidebarInfoPanel={sidebarInfoPanel}
    >
      <CoverTypeQuestion/>
      <VoluntaryExcessQuestion/>
      <AverageMileageQuestion/>
      <AverageBusinessMileageQuestion/>
      <PreviouslyHeldInsuranceQuestion/>
      {companyCarDeclarationAcceptance === false && <NamedDriverSecondaryQuestionPanel/>}
      <CoverStartDateQuestion/>
      {(isNoClaimsBonusInvalid || isCompanyCarBonusInvalid) && <NamedDriverSecondaryQuestionPanel/>}
      <CreditSearchesStatement/>
    </QuoteStep>
  );
};

export default YourCover;
