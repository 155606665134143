import {useEffect} from "react";
import {scroller} from "react-scroll";
import {useLocation} from "react-router-dom";
import {DEFAULT_SCROLL_TO_PROPS} from "../validation/hooks/ScrollToProps";

export function useScrollToHash(): void {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "") return;

    scroller.scrollTo(location.hash.replace("#", ""), DEFAULT_SCROLL_TO_PROPS);
  }, [location]);
}
