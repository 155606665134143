import React, {FC, FormEvent, MouseEvent, ReactElement, useState} from "react";
import "./SaveQuoteSidebar.css";
import CollapsibleSidebar, {
  SidebarPresentationMode
} from "../../../../../../../structure/sidebar/collapsible-sidebar/CollapsibleSidebar";
import EmailAddressInput from "shared-components/dist/form/email-address-input/EmailAddressInput";
import Button from "shared-components/dist/buttons/button/Button";
import {isValidEmailAddress} from "shared-components/dist/utils/validation/EmailAddresses";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {emailAddressChanged} from "../../../redux/QuoteDetailsSlice";
import {emailAddressExistsSelector} from "../../../redux/selectors/EmailAddressExistsSelector";
import {hasUnsavedChangesSelector} from "../../../redux/selectors/QuoteDetailsSelectors";
import {saveQuote} from "./thunks/SaveQuote";
import {logGoogleAnalyticsEvent} from "shared-components/dist/analytics/GoogleAnalyticsEventsService";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";
import {isAggregatorQuoteSelector} from "../../../redux/selectors/AggregatorQuoteSelectors";
import AggregatorQuoteSidebarContent from "../aggregator-quote-sidebar-content/AggregatorQuoteSidebarContent";
import {useIsEditFlowEnabledForQuote} from "../../../hooks/IsEditFlowEnabledForQuote";

interface Props {
  sidebarPresentationMode?: SidebarPresentationMode;
}

const SaveQuoteSidebar: FC<Props> = ({sidebarPresentationMode}) => {
  const dispatch = useAppDispatch();
  const emailAddress = useAppSelector(state => state.quoteDetails.emailAddress);
  const hasEmailAddress = useAppSelector(emailAddressExistsSelector);
  const hasUnsavedChanges = useAppSelector(hasUnsavedChangesSelector);
  const quoteLoading = useAppSelector(state => state.quoteDetails.quoteLoading);
  const isAggregatorQuote = useAppSelector(isAggregatorQuoteSelector);
  const shouldShowSaveQuote = useIsEditFlowEnabledForQuote();

  const [quoteSaveInProgress, setQuoteSaveInProgress] = useState(false);
  const [validEmailEntered, setValidEmailEntered] = useState(hasEmailAddress);
  const [emailAddressInputText, setEmailAddressInputText] = useState(emailAddress);

  const onEmailChanged = (value: string): void => {
    setValidEmailEntered(isValidEmailAddress(value));
    setEmailAddressInputText(value);
    logGoogleAnalyticsEvent({
      categoryPrefix: "motor.quote.save_quote",
      categorySuffix: "email_field",
      action: "Input"
    });
  };

  const onChangeEmailButtonClicked = (): void => {
    logGoogleAnalyticsEvent({
      categoryPrefix: "motor.quote.save_quote",
      categorySuffix: "change_email",
      action: "Click"
    });
    setValidEmailEntered(false);
    dispatch(emailAddressChanged(""));
  };

  const onSaveQuote = async (event: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    if (validEmailEntered) {
      logGoogleAnalyticsEvent({
        categoryPrefix: "motor.quote.save_quote",
        categorySuffix: (hasEmailAddress && hasUnsavedChanges) ? "send_updates" : "save_quote",
        action: "Click"
      });
      setQuoteSaveInProgress(true);
      await dispatch(saveQuote(emailAddressInputText));
      setQuoteSaveInProgress(false);
    }
  };

  const title = getTitle(hasUnsavedChanges, hasEmailAddress);
  const [mainDescription, secondaryDescription] = getDescription(hasUnsavedChanges, hasEmailAddress, emailAddress);
  const [saveQuoteButtonDisabled, saveQuoteButtonLabel] = getSaveQuoteButtonProps(quoteLoading, validEmailEntered, hasEmailAddress, hasUnsavedChanges);

  const saveQuoteContent = <>
    <div className="save-quote-sidebar__content__description">
      {mainDescription}
      <br/>
      {secondaryDescription}
    </div>

    {!hasEmailAddress && (
      <form onSubmit={onSaveQuote}>
        <EmailAddressInput
          name="save-quote-email-input"
          value={emailAddress}
          onChange={onEmailChanged}
        />
      </form>
    )}

    <Button
      variant="secondary"
      disabled={saveQuoteButtonDisabled}
      onClick={onSaveQuote}
      loading={quoteSaveInProgress}
      expanded
    >
      {saveQuoteButtonLabel}
    </Button>

    {hasEmailAddress && (
      <div className="save-quote-sidebar__content--link-button">
        <Button
          variant="link"
          onClick={onChangeEmailButtonClicked}
        >
          {lookupI18nString("quoteDetails.sidebar.button.changeEmailAddress")}
        </Button>
      </div>
    )}
  </>;

  if (isAggregatorQuote) {
    return (
      <div className="save-quote-sidebar sidebar">
        {isAggregatorQuote && <AggregatorQuoteSidebarContent/>}

        {shouldShowSaveQuote &&
          <div className="save-quote-sidebar__content">
            <h4 className="save-quote-sidebar__content__aggregator-save-quote-title">{title}</h4>
            {saveQuoteContent}
          </div>
        }
      </div>
    );
  }

  return (
    <CollapsibleSidebar
      title={title}
      className="save-quote-sidebar"
      presentationMode={sidebarPresentationMode}
    >
      <div className="save-quote-sidebar__content">
        {saveQuoteContent}
      </div>
    </CollapsibleSidebar>
  );
};

function getTitle(hasUnsavedChanges: boolean, hasEmailAddress: boolean): string {
  if (hasUnsavedChanges && hasEmailAddress) return lookupI18nString("quoteDetails.sidebar.title.quoteEdited");
  if (hasEmailAddress) return lookupI18nString("quoteDetails.sidebar.title.quoteSaved");
  return lookupI18nString("quoteDetails.sidebar.title.saveQuote");
}

function getDescription(hasUnsavedChanges: boolean, hasEmailAddress: boolean, emailAddress: string): [ReactElement, ReactElement?] {
  if (hasUnsavedChanges && hasEmailAddress) {
    return [
      <div key="description-main">
        {lookupI18nString("quoteDetails.sidebar.description.quoteEdited.main")}
      </div>,
      <div key="description-secondary">
        {lookupI18nString("quoteDetails.sidebar.description.quoteEdited.secondary")} <a
        href={`mailto:${emailAddress}`}>{emailAddress}</a>
      </div>
    ];
  } else if (hasEmailAddress) {
    return [
      <div key="description-main">
        {lookupI18nString("quoteDetails.sidebar.description.quoteSaved.main")} <a
        href={`mailto:${emailAddress}`}>{emailAddress}</a>
      </div>
    ];
  } else {
    return [
      <div key="description-main">
        {lookupI18nString("quoteDetails.sidebar.description.saveYourQuote.main")}
      </div>,
      <div key="description-secondary">
        {lookupI18nString("quoteDetails.sidebar.description.saveYourQuote.secondary")}
      </div>
    ];
  }
}

function getSaveQuoteButtonProps(quoteLoading: boolean, validEmailEntered: boolean, hasEmailAddress: boolean, hasUnsavedChanges: boolean): [boolean, string] {
  const saveQuoteButtonDisabled = quoteLoading || !validEmailEntered || (hasEmailAddress && !hasUnsavedChanges);
  const saveQuoteButtonLabel = (hasEmailAddress && hasUnsavedChanges)
    ? lookupI18nString("quoteDetails.sidebar.button.quoteEdited")
    : lookupI18nString("quoteDetails.sidebar.button.saveQuote");

  return [saveQuoteButtonDisabled, saveQuoteButtonLabel];
}

export default SaveQuoteSidebar;