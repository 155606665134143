import {AddressInput, RetrieveAddressResult} from "shared/dist/generated/graphql/resolvers-types";

export function mapAddressInput(address: RetrieveAddressResult): AddressInput {
  return {
    firstLine: address.firstLine,
    city: address.city,
    postcode: address.postcode,
    county: address.county,
    country: address.country,
    region: address.region
  };
}
