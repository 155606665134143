import {FC} from "react";
import "./DepositPaymentLanding.css";
import PaymentStep from "../../shared/payment-step/PaymentStep";
import DepositAmountCard from "./deposit-amount-card/DepositAmountCard";
import Title from "shared-components/dist/title/Title";
import Spacer from "shared-components/dist/spacer/Spacer";
import CardPaymentForm, {MakeCardPaymentResponse} from "../../shared/card-payment-form/CardPaymentForm";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import ApplePay from "../../shared/apple-pay/ApplePay";
import GooglePay from "../../shared/google-pay/GooglePay";
import PaymentInProgress from "../../shared/in-progress/PaymentInProgress";
import PaymentFailedModal from "../../shared/payment-failed-modal/PaymentFailedModal";
import {FinancedPolicyPaymentResult, Price} from "shared/dist/generated/graphql/resolvers-types";
import {isSafari} from "react-device-detect";
import {PaymentFlowType} from "../../shared/models/PaymentFlowType";
import {ProposedFinancedPolicySetupRequest} from "../../shared/models/ProposedFinancedPolicySetupRequest";
import {ProposedFinancedRenewalRequest} from "../../shared/models/ProposedFinancedRenewalRequest";
import {ISuccess} from "@globalpayments/js/types/internal/gateways";

interface Props {
  loading: boolean;
  paymentFlowType: PaymentFlowType
  successPagePath: string
  proposedRequest: ProposedFinancedPolicySetupRequest | ProposedFinancedRenewalRequest
  depositPaymentAmount: Price | undefined
  onPaymentByCard: (globalPayFormResponse: ISuccess, proposedRequest: ProposedFinancedPolicySetupRequest | ProposedFinancedRenewalRequest, threeDSecureTransactionReference?: string) => Promise<MakeCardPaymentResponse>;
  onPaymentWithApplePay: (payment: ApplePayJS.ApplePayPayment) => Promise<FinancedPolicyPaymentResult>;
  onPaymentWithGooglePay: (paymentData: google.payments.api.PaymentData) => void;
  paymentFailed: boolean;
  resetPaymentFailed: () => void;
  id?: string;
}

const DepositPaymentLanding: FC<Props> = ({
  loading,
  paymentFlowType,
  successPagePath,
  proposedRequest,
  depositPaymentAmount,
  onPaymentByCard,
  onPaymentWithApplePay,
  onPaymentWithGooglePay,
  paymentFailed,
  resetPaymentFailed,
  id
}) => (
  <div data-testid={id}>
    {loading
      ? <PaymentInProgress/>
      : <PaymentStep
        title="paymentFlow.monthly.depositPayment.title"
        description="paymentFlow.monthly.depositPayment.description"
        sidebarTitle={lookupI18nString("paymentFlow.monthly.depositPayment.sidebar.title")}
        sidebarContent={lookupI18nString("paymentFlow.monthly.depositPayment.sidebar.description")}
      >
        {depositPaymentAmount &&
          <div className="deposit-payment">
            <Title title="paymentFlow.monthly.depositPayment.yourDeposit.title"/>
            <DepositAmountCard depositPaymentAmount={depositPaymentAmount}/>

            <Spacer/>

            <CardPaymentForm
              paymentFlowType={paymentFlowType}
              makePayment={(globalPayFormResponse, threeDSecureTransactionReference) => onPaymentByCard(globalPayFormResponse, proposedRequest, threeDSecureTransactionReference)}
            />

            <div className="deposit-payment alternative-payment-options">
              <Title title="paymentFlow.monthly.depositPayment.yourDeposit.alternativePaymentMethods"/>

              {isSafari &&
                <ApplePay
                  successPagePath={successPagePath}
                  amountPayable={depositPaymentAmount}
                  makePayment={onPaymentWithApplePay}
                />
              }

              <GooglePay
                amountPayable={depositPaymentAmount}
                makePayment={onPaymentWithGooglePay}
              />
            </div>
          </div>
        }
      </PaymentStep>
    }
    <PaymentFailedModal
      visible={paymentFailed}
      onDismiss={resetPaymentFailed}
      message="paymentFlow.error.modal.footer.description.contact"
    />
  </div>
);

export default DepositPaymentLanding;