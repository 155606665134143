import {Dispatch, FC, SetStateAction} from "react";
import "./RenewalPriceSummary.css";
import RenewalPriceSummaryCard from "./RenewalPriceSummaryCard";
import {useAppSelector} from "../../../../../redux/Hooks";
import {
  selectActiveProspectiveLoan,
  selectActiveRenewalNotice,
  selectAreRenewalProspectiveLoansLoading,
  selectPreviouslyHadCloseBrothersLoan,
  selectSelectedQuoteType
} from "../../redux/RenewalSelectors";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {useFeatureFlags} from "shared-components/dist/feature-flags/hooks/UseFeatureFlags";

interface Props {
  setShowOnlineRenewalsSoftStop: Dispatch<SetStateAction<boolean>>;
}

const RenewalPriceSummary: FC<Props> = ({setShowOnlineRenewalsSoftStop}) => {
  const previouslyHadCloseBrothersLoan = useAppSelector(selectPreviouslyHadCloseBrothersLoan);
  const areRenewalProspectiveLoansLoading = useAppSelector(selectAreRenewalProspectiveLoansLoading);
  const renewalNotice = useAppSelector(selectActiveRenewalNotice);
  const selectedQuoteType = useAppSelector(selectSelectedQuoteType);
  const activeProspectiveLoan = useAppSelector(selectActiveProspectiveLoan);
  const {monthlyPaymentsFlag} = useFeatureFlags();

  if (!renewalNotice) return null;

  const shouldShowMonthlyPaymentCard = monthlyPaymentsFlag && (!!activeProspectiveLoan || selectedQuoteType === undefined || areRenewalProspectiveLoansLoading);

  return (
    <div className="renewal-price-summary" id="renewal-price-summary" data-testid="renewal-price-summary">
      <div className="renewal-price-summary__container">
        <h2>{shouldShowMonthlyPaymentCard ? lookupI18nString("portal.renewals.summary.priceSummary.title") : lookupI18nString("portal.renewals.summary.priceSummary.annualOnly.title")}</h2>
        {shouldShowMonthlyPaymentCard &&
          <p>
            {previouslyHadCloseBrothersLoan
              ? lookupI18nString("portal.renewals.summary.priceSummary.monthly.description")
              : lookupI18nString("portal.renewals.summary.priceSummary.annual.description")
            }
          </p>
        }

        <div className="renewal-price-summary__container__cards">
          {shouldShowMonthlyPaymentCard && <RenewalPriceSummaryCard
            paymentFrequency="Monthly"
            apr={activeProspectiveLoan?.apr}
            previouslyUsed={previouslyHadCloseBrothersLoan}
            monthlyInstallmentAmount={activeProspectiveLoan?.subsequentInstalmentAmounts}
            setShowOnlineRenewalsSoftStop={setShowOnlineRenewalsSoftStop}
          />}

          <RenewalPriceSummaryCard
            paymentFrequency="Annual"
            previouslyUsed={!previouslyHadCloseBrothersLoan}
            setShowOnlineRenewalsSoftStop={setShowOnlineRenewalsSoftStop}
          />
        </div>
      </div>
    </div>
  );
};

export default RenewalPriceSummary;