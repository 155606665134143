import React, {FC} from "react";
import SubQuestion from "../../SubQuestion";
import Typeahead, {TypeaheadProps} from "../../../../form/typeahead/Typeahead";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {Status} from "shared-components/dist/status-indicator/Status";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";

interface Props<T> {
  id: string;
  title: TranslationKey;
  status: Status | undefined;
  typeaheadProps: TypeaheadProps<T>;
  errorMessage?: TranslationKey;
  tooltip?: TooltipProps;
  showQuestionWhenLoading?: boolean;
}

export type TypeheadSubQuestionWithGenericProp<T = unknown> = FC<Props<T>>;

const TypeaheadSubQuestion: TypeheadSubQuestionWithGenericProp = (
  {
    id,
    title,
    status,
    typeaheadProps,
    errorMessage,
    tooltip,
    showQuestionWhenLoading = true
  }
) => {
  const getStatus = (): Status | undefined => {
    if (!showQuestionWhenLoading) return status;

    return status !== "error" ? "default" : "error";
  };

  return (
    <SubQuestion
      id={id}
      title={title}
      status={getStatus()}
      errorMessage={errorMessage}
      tooltip={tooltip}
    >
      <Typeahead
        {...typeaheadProps}
      />
    </SubQuestion>
  );
};

export default TypeaheadSubQuestion;