import React, {FC} from "react";
import Question from "../../../../../../structure/questions/question/Question";
import {FormField} from "shared-components/dist/models/form-field/FormField";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import EmailAddressInput from "shared-components/dist/form/email-address-input/EmailAddressInput";

interface OwnProps {
  id: string;
  title: TranslationKey;
  tooltip?: TooltipProps;
  emailAddressField: FormField<string>;
  onComplete: (value: string) => void;
  onInvalid: () => void;
  placeholder?: TranslationKey;
}

const EmailAddressQuestion: FC<OwnProps> = (
  {
    id,
    title,
    tooltip,
    emailAddressField,
    onComplete,
    onInvalid,
    placeholder
  }
) => (
  <Question
    id={id}
    title={title}
    status={emailAddressField.status}
    errorMessage={emailAddressField.errorMessage}
    tooltip={tooltip}
  >
    <EmailAddressInput
      name="email-address-input"
      value={emailAddressField.value}
      onComplete={onComplete}
      onInvalid={onInvalid}
      placeholder={placeholder}
    />
  </Question>
);

export default EmailAddressQuestion;
