import {CrossFieldValidator, ReduxQuestion, SectionQuestionsRecord} from "./Questions";
import {AppThunkDispatch, RootState} from "../Store";
import {createAppAsyncThunk} from "../CreateAppAsyncThunk";
import {
  runOptionalValidators,
  SuccessfulValidationResult,
  ValidationResult,
  Validator
} from "shared-components/dist/utils/validation/Validator";

// It makes more sense to infer the type from the createAppAsyncThunk return type
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
export const buildThunkToRunAllValidationForSection = (section: SectionQuestionsRecord, sectionSliceName: string) => (
  createAppAsyncThunk(`${sectionSliceName}/validateAllQuestions`, async (_, {getState, dispatch}) => {
      const state = getState();

      const questions = Object.values(section);

      await Promise.all(questions.map(question => {
        validateQuestion(question, state, dispatch);
      }));
    }
  )
);

const validateQuestion = async <T>(question: ReduxQuestion<T>, state: RootState, dispatch: AppThunkDispatch): Promise<void> => {
  const validators = [
    ...(question.validators ?? []),
    ...(buildCrossFieldValidators(question.crossFieldValidators, state) ?? [])
  ];

  const validationResult = runOptionalValidators(
    question.selector(state).value,
    ...validators,
  );

  if (isSuccessResult(validationResult) || !question.onInvalid) return;

  await dispatch(question.onInvalid(validationResult.errorMessage));
};

const isSuccessResult = (result: ValidationResult): result is SuccessfulValidationResult => result.passed;

const buildCrossFieldValidators = <T>(
  validators: readonly CrossFieldValidator<T>[] | undefined,
  state: RootState
): Validator<T>[] | undefined => (
  validators?.flatMap(crossFieldValidator => {
    const argument = crossFieldValidator.argumentSelector(state);

    return crossFieldValidator.validatorsForArgument.map(validator => (
      validator(argument)
    ));
  })
);