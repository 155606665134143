import {gql, useQuery} from "@apollo/client";

interface QueryResult {
  isLoading: boolean;
  documentLinks?: {
    commonQuestions: string,
    creditInformation: string,
    directDebitGuarantee: string
  }
}

export function useCloseBrothersDocumentsLookup(): QueryResult {
  const result = useQuery(CLOSE_BROTHERS_DOCUMENTS_LOOKUP_QUERY);
  const closeBrotherDocuments = result.data?.documentation?.closeBrothers;

  if (!closeBrotherDocuments) return {isLoading: result.loading};

  return {
    isLoading: result.loading,
    documentLinks: {
      commonQuestions: closeBrotherDocuments.qAndA,
      creditInformation: closeBrotherDocuments.preContract,
      directDebitGuarantee: closeBrotherDocuments.directDebits
    }
  };
}

const CLOSE_BROTHERS_DOCUMENTS_LOOKUP_QUERY = gql`
  query CloseBrothersDocumentsLookupQuery {
    documentation {
      closeBrothers {
        directDebits
        preContract
        qAndA
      }
    }
  }
`;