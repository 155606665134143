import {dateOfPurchaseInvalid, isCurrentOwnerInvalid} from "../redux/VehicleDetailsSlice";
import {AppThunkDispatch} from "../../../../../redux/Store";
import {isFieldAnswered} from "shared-components/dist/models/form-field/FormField";
import {VehicleDetails} from "../redux/VehicleDetails";

export function flagUnansweredCarPurchaseQuestions(
  {
    isCurrentOwner,
    dateOfPurchase
  }: VehicleDetails,
  dispatch: AppThunkDispatch
): void {
  if (!isFieldAnswered(isCurrentOwner)) {
    dispatch(isCurrentOwnerInvalid("quote.errors.questionIncomplete"));
    return;
  }

  if (isCurrentOwner.value === true && !isFieldAnswered(dateOfPurchase) && dateOfPurchase.status !== "error") {
    dispatch(dateOfPurchaseInvalid("quote.errors.questionIncomplete"));
  }
}