import {ReduxQuestion} from "../../Questions";
import {phoneNumberChanged, phoneNumberInvalid, phoneNumberReset} from "../../../../pages/quote/vehicle/your-details/redux/PersonalDetailsSlice";
import {phoneNumberValidator} from "../../shared/detail-validation/PhoneNumberValidator";

export const phoneNumber: ReduxQuestion<string> = {
  selector: state => state.personalDetails.phoneNumber,
  onChange: phoneNumberChanged,
  onInvalid: phoneNumberInvalid,
  onReset: phoneNumberReset,
  validators: [phoneNumberValidator()]
};