import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fieldChanged, fieldInvalid, fieldReset} from "shared-components/dist/models/form-field/FormField";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {quoteReset} from "../../../../../redux/Actions";
import {MonthlyPayment} from "./MonthlyPayment";

export const initialState: Readonly<MonthlyPayment> = {
  sortCode: {
    status: "default",
    value: undefined
  },
  accountNumber: {
    status: "default",
    value: undefined
  }
};

const monthlyPaymentSlice = createSlice({
  name: "monthlyPaymentSlice",
  initialState,
  reducers: {
    resetBankDetailsForm(state): void {
      fieldReset(state.sortCode);
      fieldReset(state.accountNumber);
    },
    sortCodeChanged(state, action: PayloadAction<string>): void {
      fieldChanged(state.sortCode, action.payload);
    },
    sortCodeInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.sortCode, action.payload);
    },
    accountNumberChanged(state, action: PayloadAction<string>): void {
      fieldChanged(state.accountNumber, action.payload);
    },
    accountNumberInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.accountNumber, action.payload);
    }
  },
  extraReducers: {
    [quoteReset.type]: () => initialState
  }
});

export const {
  sortCodeChanged,
  sortCodeInvalid,
  accountNumberChanged,
  accountNumberInvalid,
  resetBankDetailsForm,
} = monthlyPaymentSlice.actions;

export const monthlyPaymentReducer = monthlyPaymentSlice.reducer;
