import React, {FC, ReactElement} from "react";

interface Props {
  onClick?: () => void;
  icon: ReactElement;
  url: string;
}

const AppStoreButton: FC<Props> = (
  {
    onClick,
    icon,
    url
  }
) => {
  const onAppStoreButtonClicked = (): void => {
    onClick?.();
    window.open(url);
  };

  return (
    <a onClick={onAppStoreButtonClicked} className="app-store-button">
      {icon}
    </a>
  );
};

export default AppStoreButton;