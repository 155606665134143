import {AggregatorQuoteResult, QuoteType} from "shared/src/generated/graphql/resolvers-types";
import {LoadedQuote} from "../quote/vehicle/your-quote/models/LoadedQuote";
import {buildPersonalDetails} from "./redux/builder/build-personal-details/BuildPersonalDetails";
import {buildVehicle} from "./redux/builder/BuildVehicle";
import {buildCover} from "./redux/builder/BuildCover";
import {manualVehicleLookupFormInitialState} from "../quote/vehicle/your-vehicle/redux/ManualVehicleLookupFormSlice";
import {buildPolicyExtras} from "./redux/builder/BuildPolicyExtras";
import {initialState} from "../quote/vehicle/your-quote/redux/QuoteDetailsSlice";
import {LoadingStatus} from "../quote/vehicle/your-quote/models/LoadingStatus";
import {InsurerQuote} from "../quote/vehicle/your-quote/models/InsurerQuote";

export function mapAggregatorQuoteResult(quote: AggregatorQuoteResult, isCompareNiPhaseTwoFlagEnabled: boolean): LoadedQuote {
  const insurerQuote: InsurerQuote = {
    closeBrothersLoanStatus: LoadingStatus.DEFAULT,
    loans: undefined,
    loansWithPrice: undefined,
    selectedLoan: undefined,
    selectedLoanWithPrice: undefined,
    compulsoryExcessPrice: quote.quoteDetails.compulsoryExcessAsPrice,
    premium: quote.quoteDetails.price,
    premiumPrice: quote.quoteDetails.premiumAsPrice,
    premiumPriceBreakdownDetails: quote.quoteDetails.premiumPriceBreakdownDetails ?? undefined,
    totalUnfinancedAmountPayable: quote.quoteDetails.totalUnfinancedAmountPayable,
    voluntaryExcessPrice: quote.quoteDetails.voluntaryExcessAsPrice,
    reference: quote.quoteDetails.reference,
    sequenceNumber: +quote.quoteDetails.sequenceNumber,
    insurer: quote.quoteDetails.insurer,
    insurerSchemeReference: quote.quoteDetails.insurerSchemeReference,
    compulsoryExcess: quote.quoteDetails.compulsoryExcess,
    voluntaryExcess: quote.quoteDetails.voluntaryExcess,
    quoteType: quote.quoteDetails.type ?? QuoteType.Default
  };

  return {
    insurerQuotes: [insurerQuote],
    compulsoryExcessAsPrice: insurerQuote.compulsoryExcessPrice,
    premiumAsPrice: insurerQuote.premiumPrice,
    premiumPriceBreakdownDetails: insurerQuote.premiumPriceBreakdownDetails ?? undefined,
    totalUnfinancedAmountPayable: insurerQuote.totalUnfinancedAmountPayable,
    voluntaryExcessAsPrice: insurerQuote.voluntaryExcessPrice,
    totalExtrasPrice: quote.quoteDetails.totalExtrasPrice ?? undefined,
    reference: insurerQuote.reference,
    sequenceNumber: insurerQuote.sequenceNumber,
    insurer: insurerQuote.insurer,
    insurerSchemeReference: insurerQuote.insurerSchemeReference,
    price: insurerQuote.premium,
    compulsoryExcess: insurerQuote.compulsoryExcess,
    voluntaryExcess: insurerQuote.voluntaryExcess,
    magicLinkId: quote.quoteDetails.magicLinkId,
    quoteGenerationDate: quote.quoteDetails.quoteGenerationDate,
    quoteType: insurerQuote.quoteType,
    quoteArguments: {
      vehicleDetails: buildVehicle(quote.vehicleDetails),
      manualVehicleLookupForm: manualVehicleLookupFormInitialState,
      personalDetails: buildPersonalDetails(quote),
      coverDetails: buildCover(quote, isCompareNiPhaseTwoFlagEnabled),
      paymentFrequency: initialState.paymentFrequency,
      depositPercentage: initialState.depositPercentage,
      policyExtras: buildPolicyExtras(quote?.quoteExtras),
      selectedRate: undefined
    },
    isReferredBy: quote.quoteDetails.referredBy ?? undefined
  };
}
