import React, {FC, FormEvent, useState} from "react";
import DropdownSubQuestion
  from "../../../../../../../structure/questions/sub-question/variants/dropdown-sub-question/DropdownSubQuestion";
import {MODIFICATION_TYPE_OPTIONS} from "../model/ModificationTypeOption";
import AlloyWheelsQuestion from "./AlloyWheelsQuestion";
import WindowTintQuestion from "./WindowTintQuestion";
import SubQuestionForm from "../../../../../../../structure/questions/sub-question-form/SubQuestionForm";
import {Modification} from "../model/Modification";
import {MultipleItemsQuestionSubFormProps} from "../../../../../../../structure/questions/multiple-items-question/MultipleItemsQuestion";
import {Status} from "shared-components/dist/status-indicator/Status";
import {ModificationType} from "shared/dist/generated/graphql/resolvers-types";
import OtherModificationStop from "./stops/OtherModificationStop";
import ProhibitedAlloyWheelsModificationStop from "./stops/ProhibitedAlloyWheelsModificationStop";
import ProhibitedWindowTintModificationStop from "./stops/ProhibitedWindowTintModificationStop";
import {getAvailableModificationOptions} from "../model/GetAvailableModificationOptions";

interface Props extends MultipleItemsQuestionSubFormProps {
  modifications: Modification[];
  modificationsChanged: (modifications: Modification[]) => void;
  shouldFlagUnansweredQuestions: boolean;
}

const ModificationsSubQuestionForm: FC<Props> = (
  {
    modifications,
    modificationsChanged,
    formRef,
    setIsFormValid,
    shouldFlagUnansweredQuestions
  }
) => {
  const [modification, setModification] = useState<Modification | undefined>(undefined);
  const [isOtherModificationStop, setIsOtherModificationStop] = useState(false);
  const [isAlloyWheelStop, setIsAlloyWheelStop] = useState(false);
  const [isWindowTintStop, setIsWindowTintStop] = useState(false);

  const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (modification) {
      modificationsChanged([...modifications, modification]);
      setIsFormValid?.(false);
    }
  };

  const modificationTypeChange = (selection: string): void => {
    const modificationOption = MODIFICATION_TYPE_OPTIONS.find(modification => modification.description === selection);
    if (!modificationOption) return;

    onModificationSet({type: modificationOption});
    setIsOtherModificationStop(modificationOption.id === ModificationType.Other);
    setIsAlloyWheelStop(false);
    setIsWindowTintStop(false);
  };

  const onModificationSet = (modification: Modification, isFormValid = false): void => {
    setModification(modification);
    setIsFormValid?.(isFormValid);
  };

  return (
    <SubQuestionForm onSubmit={onSubmit} formRef={formRef}>
      <DropdownSubQuestion
        id="modification-options"
        title="vehicleQuote.modifications.selection.title"
        onChange={modificationTypeChange}
        options={getAvailableModificationOptions(modifications)}
        value={modification?.type.description}
        status={getSubQuestionStatus(shouldFlagUnansweredQuestions, modification?.type)}
        errorMessage="quote.errors.questionIncomplete"
      />
      <OtherModificationStop isVisible={isOtherModificationStop}/>

      <AlloyWheelsQuestion
        modification={modification}
        onSetModification={onModificationSet}
        setIsAlloyWheelStop={setIsAlloyWheelStop}
        status={getSubQuestionStatus(shouldFlagUnansweredQuestions, modification?.option)}
      />
      <ProhibitedAlloyWheelsModificationStop isVisible={isAlloyWheelStop}/>

      <WindowTintQuestion
        modification={modification}
        onSetModification={onModificationSet}
        setIsWindowTintStop={setIsWindowTintStop}
        status={getSubQuestionStatus(shouldFlagUnansweredQuestions, modification?.option)}
      />
      <ProhibitedWindowTintModificationStop isVisible={isWindowTintStop}/>
    </SubQuestionForm>
  );
};

function getSubQuestionStatus(shouldFlagUnansweredQuestions: boolean, questionResponse: unknown | undefined): Status {
  return shouldFlagUnansweredQuestions && questionResponse === undefined ? "error" : "default";
}

export default ModificationsSubQuestionForm;