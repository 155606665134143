import {
  FullPolicyPaymentResponse,
  FullPolicyPaymentResult,
  HostedPaymentPageFullPolicySetUpRequest,
  HostedPaymentPageFullProcessRenewalRequest,
  Mutation,
  ProposedRenewalRequest
} from "shared/dist/generated/graphql/resolvers-types";
import {apolloClient} from "../../../../../graphql/apollo/ApolloClientProvider";
import {ISuccess} from "@globalpayments/js/types/internal/gateways";
import {
  HOSTED_PAYMENT_PAGE_SET_UP_FULL_POLICY,
  HostedPaymentPageFullPolicyPaymentVariables
} from "shared/dist/graphql/mutations/payment/HostedPaymentPageSetUpFullPolicyMutation";
import {
  HOSTED_PAYMENT_PAGE_PROCESS_FULL_RENEWAL,
  HostedPaymentPageFullRenewalPaymentVariables
} from "shared/dist/graphql/mutations/payment/HostedPaymentPageProcessFullRenewalMutation";
import {PolicySetupQuoteValues} from "shared/dist/event-models/policy-setup/PolicySetupEvent";

export async function processFullPolicySetup(
  globalPayFormResponse: ISuccess,
  quoteValues: PolicySetupQuoteValues,
  threeDSecureTransactionReference: string | undefined
): Promise<FullPolicyPaymentResponse> {
  if (!globalPayFormResponse?.details?.reference) {
    throw Error("No global pay reference so cannot buy from this card");
  }

  if (!globalPayFormResponse?.details?.cardholderName) {
    throw Error("No card holder name so cannot buy from this card");
  }

  return await setUpFullPolicy({
    payment: {
      reference: globalPayFormResponse.details.reference,
      cardholderName: globalPayFormResponse.details.cardholderName,
      paymentMethodId: globalPayFormResponse.paymentReference,
      threeDSecureTransactionReference
    },
    proposerEmail: quoteValues.proposerEmail,
    proposerPhoneNumber: quoteValues.proposerPhoneNumber,
    quoteReference: quoteValues.quoteReference,
    quoteSequenceNumber: quoteValues.quoteSequenceNumber,
  });
}

export async function processFullRenewal(
  globalPayFormResponse: ISuccess,
  request: ProposedRenewalRequest,
  threeDSecureTransactionReference: string | undefined
): Promise<FullPolicyPaymentResponse> {
  if (!globalPayFormResponse?.details?.reference) {
    throw Error("No global pay reference so cannot buy from this card");
  }

  if (!globalPayFormResponse?.details?.cardholderName) {
    throw Error("No card holder name so cannot buy from this card");
  }

  return await processFullRenewalRequest({
    payment: {
      reference: globalPayFormResponse.details.reference,
      cardholderName: globalPayFormResponse.details.cardholderName,
      paymentMethodId: globalPayFormResponse.paymentReference,
      threeDSecureTransactionReference
    },
    proposedRenewalRequest: request,
  });
}

function setUpFullPolicy(request: HostedPaymentPageFullPolicySetUpRequest): Promise<FullPolicyPaymentResponse> {
  return new Promise((resolve, reject) => {
    apolloClient.mutate<Pick<Mutation, "policySetup">, HostedPaymentPageFullPolicyPaymentVariables>({
      mutation: HOSTED_PAYMENT_PAGE_SET_UP_FULL_POLICY,
      variables: {
        request
      }
    }).then((response) => resolve(response.data?.policySetup.hostedPaymentPage.setUpFullPolicy ?? {result: FullPolicyPaymentResult.SomethingWentWrong}))
      .catch(error => reject(error));
  });
}

function processFullRenewalRequest(request: HostedPaymentPageFullProcessRenewalRequest): Promise<FullPolicyPaymentResponse> {
  return new Promise((resolve, reject) => {
    apolloClient.mutate<Pick<Mutation, "renewals">, HostedPaymentPageFullRenewalPaymentVariables>({
      mutation: HOSTED_PAYMENT_PAGE_PROCESS_FULL_RENEWAL,
      variables: {
        request
      }
    }).then((response) => resolve(response.data?.renewals.hostedPaymentPage.processFullRenewal ?? {result: FullPolicyPaymentResult.SomethingWentWrong}))
      .catch(error => reject(error));
  });
}
