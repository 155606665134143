import {Status} from "shared-components/dist/status-indicator/Status";
import {
  companyCarPolicyExpiryDateAsDateSelector,
  vehiclePreviousPolicyExpiryDateAsDateSelector
} from "../../../your-cover/redux/selectors/CoverDetailsSelectors";
import {useAppSelector} from "../../../../../../redux/Hooks";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {
  isCarOwnerOnCoverStartDate,
  isDateAfterCoverStartDate
} from "../../quote-summary/components/edit-cover-summary/validators/CoverStartDateValidators";
import {runValidators} from "shared-components/dist/utils/validation/Validator";
import {DateOption} from "shared/dist/models/date-option/DateOption";
import {getCoverStartDateWarning} from "./GetCoverStartDateWarning";
import {buildPolicyExpiryWarningTooltip} from "./VehiclePolicyExpiryWarningTooltip";

interface CoverStartDateStatus {
  status: Status;
  errorMessage: TranslationKey | undefined;
  errorTooltip: TranslationKey | undefined;
}

interface Props {
  coverStartDate: DateOption | undefined;
  carPurchaseDate: Date | undefined;
  existingPolicyExpiryDate: Date | undefined;
}

export function useModalCoverStartDateStatus(
  {
    coverStartDate,
    carPurchaseDate,
    existingPolicyExpiryDate
  }: Props
): CoverStartDateStatus {
  const companyCarPolicyExpiryDate = useAppSelector(state => companyCarPolicyExpiryDateAsDateSelector(state).value);
  const vehiclePreviousPolicyExpiryDate = useAppSelector(state => vehiclePreviousPolicyExpiryDateAsDateSelector(state).value);

  if (coverStartDate?.date) {
    const validationResult = runValidators(
      coverStartDate,
      isCarOwnerOnCoverStartDate(carPurchaseDate),
      isDateAfterCoverStartDate({
        date: existingPolicyExpiryDate,
        validationErrorMessage: "coverDetails.errors.coverStartDateQuestion.policyStartBeforeCurrentPolicyExpiryDate"
      }),
    );

    return validationResult.passed
      ? onValidCoverStartDate(coverStartDate.date, existingPolicyExpiryDate, companyCarPolicyExpiryDate, vehiclePreviousPolicyExpiryDate)
      : onInvalidCoverStartDate(validationResult.errorMessage);
  }

  return {
    status: "hidden",
    errorMessage: undefined,
    errorTooltip: undefined
  };
}

function onValidCoverStartDate(
  coverStartDate: Date | undefined,
  existingPolicyExpiryDate: Date | undefined,
  companyCarPolicyExpiryDate: Date | undefined,
  vehiclePreviousPolicyExpiryDate: Date | undefined
): CoverStartDateStatus {
  const coverStartDateWarning = getCoverStartDateWarning(
    coverStartDate,
    existingPolicyExpiryDate,
    companyCarPolicyExpiryDate,
    vehiclePreviousPolicyExpiryDate
  );

  switch (coverStartDateWarning) {
    case "NO_CLAIMS_BONUS_INELIGIBLE": {
      return {
        status: "warning",
        errorMessage: "quoteDetails.summary.coverStartDate.warning.noClaims",
        errorTooltip: "quoteDetails.summary.coverStartDate.warning.noClaims.tooltip"
      };
    }
    case "COMPANY_CAR_BONUS_INELIGIBLE": {
      return {
        status: "warning",
        errorMessage: "quoteDetails.summary.coverStartDate.warning.companyCarBonus",
        errorTooltip: "quoteDetails.summary.coverStartDate.warning.companyCarBonus.tooltip"
      };
    }
    case "VEHICLE_PREVIOUS_POLICY_EXPIRY_INVALID": {
      return {
        status: "warning",
        errorMessage: "quoteDetails.summary.coverStartDate.warning.vehiclePolicyExpiryDate",
        errorTooltip: buildPolicyExpiryWarningTooltip("quoteDetails.summary.coverStartDate.warning.vehiclePolicyExpiryDate.tooltip", vehiclePreviousPolicyExpiryDate)
      };
    }
    case "COMPANY_CAR_BONUS_INELIGIBLE_AND_PREVIOUS_POLICY_EXPIRY_INVALID": {
      return {
        status: "warning",
        errorMessage: "quoteDetails.summary.coverStartDate.warning.combinedCompanyCarAndPolicyExpiryDate",
        errorTooltip: buildPolicyExpiryWarningTooltip("quoteDetails.summary.coverStartDate.warning.combinedCompanyCarAndPolicyExpiryDate.tooltip", vehiclePreviousPolicyExpiryDate)
      };
    }
    default:
      break;
  }

  return {
    status: "hidden",
    errorMessage: undefined,
    errorTooltip: undefined
  };
}

function onInvalidCoverStartDate(errorMessage: TranslationKey): CoverStartDateStatus {
  return {
    status: "error",
    errorMessage: errorMessage,
    errorTooltip: undefined
  };
}
