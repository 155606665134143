import {RootState} from "../../../../../../redux/Store";
import {AdditionalVehicleDetailsFormQuestions} from "../AdditionalVehicleDetailsFormQuestions";
import {createSelector} from "@reduxjs/toolkit";
import {isValidAdditionalVehicleDetailsQuestions} from "../../../../../../graphql/queries/vehicle-lookup/registration-lookup/model/Vehicle";
import {mainHasTrackerQuestionVisibleSelector} from "./VehiclePageStateSelectors";
import {hasVehicleSummarySelector} from "./VehicleSummarySelectors";

const additionalVehicleDetailsQuestionsSelector = (state: RootState): AdditionalVehicleDetailsFormQuestions => state.vehicleDetails.additionalVehicleDetails;

export const additionalVehicleDetailsHasTrackerQuestionVisibleSelector = createSelector(
  hasVehicleSummarySelector,
  mainHasTrackerQuestionVisibleSelector,
  (hasVehicleSummary: boolean, mainTrackerQuestionVisible: boolean) => hasVehicleSummary && !mainTrackerQuestionVisible
);

export const validAdditionalVehicleDetailsQuestions = createSelector(
  additionalVehicleDetailsQuestionsSelector,
  (additionalVehicleDetails: AdditionalVehicleDetailsFormQuestions) => {
    return isValidAdditionalVehicleDetailsQuestions(additionalVehicleDetails);
  }
);