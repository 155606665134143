import React, {FC} from "react";
import AppleAppStoreButtonSmall from "../../buttons/app-stores/AppleAppStoreButtonSmall";
import GooglePlayStoreButtonSmall from "../../buttons/app-stores/GooglePlayStoreButtonSmall";
import {logNavigationGoogleAnalyticsEvent} from "../../../utils/analytics/NavigationAnalytics";

const AppStoreBadges: FC = () => {
  const onAppleStoreClicked = (): void => {
    logNavigationGoogleAnalyticsEvent({
      categorySuffix: "apple_store",
      action: "Click",
    });
  };

  const onGooglePlayStoreClicked = (): void => {
    logNavigationGoogleAnalyticsEvent({
      categorySuffix: "google_play",
      action: "Click",
    });
  };

  return (
    <div className="app-store-buttons">
      <AppleAppStoreButtonSmall onClick={onAppleStoreClicked}/>
      <GooglePlayStoreButtonSmall onClick={onGooglePlayStoreClicked}/>
    </div>
  );
};

export default AppStoreBadges;