import {useRef} from "react";
import {useEffectOnFirstRender} from "shared-components/dist/utils/hooks/UseEffectOnFirstRender";

export function useIsFirstRender(): boolean {
  const firstRender = useRef(true);

  useEffectOnFirstRender(() => {
    firstRender.current = false;
  });

  return firstRender.current;
}