import {DependencyList, useEffect, useState} from "react";

export function useDoAfterFinalRenderAction(finalRenderAction: () => void, dependencies?: DependencyList): () => void {
  const [shouldExecuteFinalRenderAction, setShouldExecuteFinalRenderAction] = useState(false);
  const dependencyList = dependencies ? dependencies : [];

  useEffect(() => {
    if (shouldExecuteFinalRenderAction) {
      setShouldExecuteFinalRenderAction(false);
      finalRenderAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalRenderAction, shouldExecuteFinalRenderAction, ...dependencyList]);

  return () => {
    setShouldExecuteFinalRenderAction(true);
  };
}