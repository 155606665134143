import {createAppAsyncThunk} from "../../../../redux/CreateAppAsyncThunk";
import {loadQuotes} from "../../../quote/vehicle/your-quote/redux/thunks/LoadQuotes";
import {handleRefreshedQuotes} from "../../../quote/vehicle/shared/redux/HandleRefreshedQuotes";
import {
  breakInCoverChanged,
  breakInCoverDeclarationAcceptanceChanged,
  coverStartDateChanged,
  existingPolicyExpiryDateChanged
} from "../../../quote/vehicle/your-cover/redux/CoverDetailsSlice";
import {dateOfPurchaseChanged} from "../../../quote/vehicle/your-vehicle/redux/VehicleDetailsSlice";
import {BreakInCover} from "../../../quote/vehicle/shared/services/BreakInCoverDetector";
import {AppThunkDispatch} from "../../../../redux/Store";
import {DateOption, toSerializableDateOption} from "shared/dist/models/date-option/DateOption";
import {exitResolveValidationErrorFlow} from "../../../quote/aggregator-quote/redux/ExitResolveValidationErrorFlow";

export interface AggregatorMissingAnswersFulfilledParams {
  monthlyPaymentsFlag: boolean;
  newPreviousPolicyExpiryDate: Date | undefined;
  newVehicleDateOfPurchase: Date | undefined;
  breakInCover: BreakInCover;
  breakInCoverDeclarationAcceptance: boolean | undefined;
  coverStartDate: DateOption | undefined;
}

export const aggregatorMissingAnswersFulfilled = createAppAsyncThunk(
  "quoteDetails/aggregatorMissingAnswersFulfilled",
  async (params: AggregatorMissingAnswersFulfilledParams, {dispatch, getState}) => {
    await editFields(params, dispatch);

    await dispatch(exitResolveValidationErrorFlow());

    await dispatch(loadQuotes({monthlyPaymentsEnabled: params.monthlyPaymentsFlag}));

    handleRefreshedQuotes(getState, dispatch);
  }
);

const editFields = async (
  {
    breakInCover,
    breakInCoverDeclarationAcceptance,
    newPreviousPolicyExpiryDate,
    newVehicleDateOfPurchase,
    coverStartDate
  }: Omit<AggregatorMissingAnswersFulfilledParams, "monthlyPaymentsFlag">, dispatch: AppThunkDispatch
): Promise<void> => {
  await Promise.all([
    editCoverStartDate(coverStartDate, dispatch),
    editPreviousPolicyExpiryDate(newPreviousPolicyExpiryDate, dispatch),
    editBreakInCover(breakInCover, dispatch),
    editBreakInCoverDeclarationAcceptance(breakInCoverDeclarationAcceptance, dispatch),
    editVehiclePurchaseDate(newVehicleDateOfPurchase, dispatch)
  ]);
};

const editCoverStartDate = async (coverStartDate: DateOption | undefined, dispatch: AppThunkDispatch): Promise<void> => {
  if (!coverStartDate) return;

  await dispatch(coverStartDateChanged({
    value: toSerializableDateOption(coverStartDate),
    isEligibleForNamedDriver: true
  }));
};

const editPreviousPolicyExpiryDate = async (
  newPreviousPolicyExpiryDate: Date | undefined,
  dispatch: AppThunkDispatch
): Promise<void> => {
  if (!newPreviousPolicyExpiryDate) return;

  await dispatch(existingPolicyExpiryDateChanged({
    value: newPreviousPolicyExpiryDate.toISOString(),
    isEligibleForNamedDriver: true
  }));
};

const editVehiclePurchaseDate = async (newVehicleDateOfPurchase: Date | undefined, dispatch: AppThunkDispatch): Promise<void> => {
  if (!newVehicleDateOfPurchase) return;

  await dispatch(dateOfPurchaseChanged(newVehicleDateOfPurchase.toISOString()));
};

const editBreakInCover = async (breakInCover: BreakInCover, dispatch: AppThunkDispatch): Promise<void> => {
  await dispatch(breakInCoverChanged(breakInCover));
};

const editBreakInCoverDeclarationAcceptance = async (breakInCoverDeclarationAcceptance: boolean | undefined, dispatch: AppThunkDispatch): Promise<void> => {
  if (breakInCoverDeclarationAcceptance) dispatch(breakInCoverDeclarationAcceptanceChanged(breakInCoverDeclarationAcceptance));
};

