import {createSelector} from "@reduxjs/toolkit";
import {now} from "shared/dist/stdlib/Dates";
import {QuoteDetails} from "../QuoteDetails";
import {addDays, isBefore} from "date-fns";

const DAYS_TILL_REQUOTE = 5;

export const shouldReloadQuotesSelector = createSelector(
  (state: QuoteDetails) => state.quotesRetrievedAt,
  (quotesRetrievedAt) => {
    if (!quotesRetrievedAt) return true;
    return isBefore(addDays(new Date(quotesRetrievedAt), DAYS_TILL_REQUOTE), now());
  }
);