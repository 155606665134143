import {RootState} from "../../../../../../redux/Store";
import {createSelector} from "@reduxjs/toolkit";
import {isValidFormField} from "shared-components/dist/models/form-field/FormField";
import {allFormFields} from "shared-components/dist/models/form-field/FormFields";

export const prohibitedFuelTypes = [
  "LPG"
];

const manualVehicleLookupFuelTypeSelector = (state: RootState): string | undefined => state.manualVehicleLookupForm.fuelType.value;
const regLookupFuelTypeSelector = (state: RootState): string | undefined => state.vehicleDetails.vehicleFromRegLookup?.fuelType;
const assumptionsCorrectSelector = (state: RootState): boolean | undefined => state.vehicleDetails.assumptionsCorrect.value;

export const isProhibitedFuelType = (fuelType: string | undefined): boolean => fuelType !== undefined && prohibitedFuelTypes.includes(fuelType);

export const shouldShowAssumptionsProhibitedFuelTypeStopSelector = createSelector(
  regLookupFuelTypeSelector,
  assumptionsCorrectSelector,
  (
    fuelType: string | undefined,
    assumptionsCorrect: boolean | undefined
  ): boolean =>
    isProhibitedFuelType(fuelType) && assumptionsCorrect === true
);

export const shouldShowManualVehicleLookupProhibitedFuelTypeStopSelector = createSelector(manualVehicleLookupFuelTypeSelector, isProhibitedFuelType);
export const shouldShowIncompleteVehicleProhibitedFuelTypeStopSelector = createSelector(regLookupFuelTypeSelector, isProhibitedFuelType);

export function manualVehicleLookupFormCompleted(state: RootState): boolean {
  return isValidFormField(...allFormFields(state.manualVehicleLookupForm, ["registrationNumber"]));
}

export const isManualVehicleLookupFormActive = (state: RootState): boolean => state.manualVehicleLookupForm.make.isActive;
