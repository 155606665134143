import {AppThunk, AppThunkDispatch} from "../../../../../redux/Store";
import {hasContactDetailsBeenConfirmedInvalid, hasSubmitBeenClickedChanged} from "../redux/QuoteDetailsSlice";
import {QuoteDetails} from "../redux/QuoteDetails";

export const flagUnansweredQuoteDetailsQuestions = (): AppThunk => async (dispatch, getState) => {
  dispatch(hasSubmitBeenClickedChanged(true));

  checkProposerHasConfirmedContactDetails(getState().quoteDetails, dispatch);
};

function checkProposerHasConfirmedContactDetails({hasContactDetailsBeenConfirmed}: QuoteDetails, dispatch: AppThunkDispatch): void {
  if (!hasContactDetailsBeenConfirmed.value) dispatch(hasContactDetailsBeenConfirmedInvalid("quoteDetails.contactDetails.confirmation.error"));
}
