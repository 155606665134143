import {useSearchParams} from "../../../utils/custom-hooks/SearchParams";
import {AggregatorQuoteExtraInput, PolicyExtraType} from "shared/src/generated/graphql/resolvers-types";

export function useAggregatorQuotePolicyExtrasParams(): AggregatorQuoteExtraInput[] {
  const params = useSearchParams();
  const response: AggregatorQuoteExtraInput[] = [];

  if (params.has("Breakdown")) response.push({type: PolicyExtraType.RoadsideAssistancePlatinum});
  if (params.has("Legal")) response.push({type: PolicyExtraType.LegalExpenses});

  return response;
}