import React, {FC} from "react";
import {usePolicyQueryParams} from "../../../../../router/params/Params";
import PolicyLoading from "../../loading/PolicyLoading";
import PolicyErrorPage from "../../../policy-error/PolicyErrorPage";
import CustomerPortalContainerWithSidebar from "../../../shared/container/CustomerPortalContainerWithSidebar";
import PoliciesHeader from "../../header/PoliciesHeader";
import PoliciesSidebar from "../../sidebar/PoliciesSidebar";
import {policyViewSidebarMenuItems} from "../../../../../structure/navigation/navbar/menu/MenuItems";
import RenewalStateContainer from "./RenewalStateContainer";
import {fetchPolicyFor} from "shared/dist/graphql/queries/portal/PortalDashboardQuery";

const PolicyViewRenewals: FC = () => {
  const {policyId} = usePolicyQueryParams();
  const {loading, error, data} = fetchPolicyFor(policyId);

  if (loading) return <PolicyLoading/>;

  if (error) return <PolicyErrorPage/>;

  if (!data || !data.policy) return <PolicyErrorPage/>;

  return (
    <CustomerPortalContainerWithSidebar
      header={
        <PoliciesHeader
          policy={data.policy}
          pageSubHeader="policies.header.subHeader.renewals"
        />
      }
      mainContent={
        <RenewalStateContainer policy={data.policy}/>
      }
      sidebar={<PoliciesSidebar menuItems={policyViewSidebarMenuItems(policyId, true)}/>}
    />
  );
};

export default PolicyViewRenewals;