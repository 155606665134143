import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {FormField} from "shared-components/dist/models/form-field/FormField";
import {FC} from "react";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./ContactDetailConfirmationListItem.css";

interface Props {
  label: TranslationKey;
  formField: FormField<string>;
}

const ContactDetailConfirmationListItem: FC<Props> = ({label, formField: {value}}) => (
  <li className="contact-detail-confirmation-list-item">{lookupI18nString(label)} <h5>{value}</h5></li>
);

export default ContactDetailConfirmationListItem;
