import {Cancel, InfoOutlined} from "@mui/icons-material";
import React, {FC, ReactChild, ReactChildren, ReactElement, useState} from "react";
import "./PolicyExtrasListItem.css";
import TooltipCard from "shared-components/dist/cards/tooltip-card/TooltipCard";
import {TranslationId, TranslationKey} from "shared-components/dist/translations/TranslationKey";
import Switch from "shared-components/dist/form/switch/Switch";
import FormattedMessage from "shared-components/src/translations/components/FormattedMessage";
import {logYourQuoteGoogleAnalyticsEvent} from "../../../../../../../../../utils/analytics/MotorAnalytics";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {PolicyExtraType} from "shared/dist/generated/graphql/resolvers-types";
import {
  mapGoogleAnalyticsPolicyExtraNameFrom
} from "../../../../../../../../../utils/analytics/helpers/MapGoogleAnalyticsPolicyExtraName";
import {formatDateStringToDocumentDateFormat} from "shared/src/stdlib/DateFormat";

export interface PolicyExtrasListItemProps {
  type: PolicyExtraType;
  name: TranslationKey;
  description: TranslationId;
  anchorText?: TranslationKey;
  onAnchorClick?: () => void;
  onToggleChanged?: (value: boolean) => void;
  active?: boolean;
  price?: number | undefined;
  startDate?: string;
  listItemContent?: ReactChild | ReactChildren;
  toggleIsVisible?: boolean;
  optionDescription?: TranslationKey;
}

type Props = PolicyExtrasListItemProps;

const PolicyExtrasListItem: FC<Props> = (
  {
    type,
    active = false,
    description,
    onAnchorClick,
    onToggleChanged,
    name,
    price = undefined,
    startDate,
    anchorText,
    listItemContent,
    toggleIsVisible = true,
    optionDescription
  }
) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [isActive, setIsActive] = useState(active);

  const getClassName = (): string => {
    let className = "policy-extras-list-item";
    if (tooltipVisible) className += " policy-extras-list-item--description-visible";
    return className;
  };

  const onSwitchClick = (value: boolean): void => {
    setIsActive(value);
    onToggleChanged?.(value);

    logYourQuoteGoogleAnalyticsEvent({
      categorySuffix: `policy_extra.${mapGoogleAnalyticsPolicyExtraNameFrom(type).toLowerCase().split(" ").join("_")}`,
      action: value ? "On" : "Off"
    });
  };

  return (
    <div className={getClassName()}>
      <div className="policy-extras-list-item__container">
        <div className="policy-extras-list-item--top">
          <div className="policy-extras-list-item--top--left">
            <button
              className="policy-extras-list-item__description-toggle"
              onClick={() => setTooltipVisible(!tooltipVisible)}
            >
              {tooltipVisible ? <Cancel/> : <InfoOutlined/>}
            </button>
            <span className="policy-extras-list-item__name">{lookupI18nString(name)}</span>
          </div>

          <div className="policy-extras-list-item__option-description">
            {optionDescription && <p className="policy-extras-list-item__option-type">{lookupI18nString(optionDescription)}</p>}
            {price && (
              <div className="policy-extras-list-item--top--right">
                <span className="policy-extras-list-item__price">£{price.toFixed(2)}</span>
                {toggleIsVisible &&
                  <Switch value={isActive} onChange={onSwitchClick}/>
                }
              </div>
            )}
            {startDate && (
              <div className="policy-extras-list-item--top-right">
                <span className="policy-extras-list-item__start-date">{lookupI18nString({
                    id: "policies.mainContent.policyExtras.policyExtras.startDate",
                    templateVariables: {startDate: formatDateStringToDocumentDateFormat(startDate)}
                  })}</span>
              </div>
            )}
          </div>

        </div>
        <div className="policy-extras-list-item__description">
          <TooltipCard
            isVisible={tooltipVisible}
            description={
              <FormattedMessage
                id={description}
                values={{
                  b: function bold(msg: string): ReactElement {
                    return <b>{msg}</b>;
                  },
                  br: <br/>
                }}
              />}
            onAnchorClick={onAnchorClick}
            anchorText={anchorText ? anchorText : "structure.lists.policyExtras.description.anchorText"}
          />
        </div>
        {listItemContent}
      </div>
    </div>
  );
};

export default PolicyExtrasListItem;
