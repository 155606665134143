import {FC, useEffect, useMemo, useState} from "react";
import {Option} from "./models/Option";
import "./Typeahead.css";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import Select from "react-select";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {matchSorter} from "match-sorter";

export interface TypeaheadProps<T> {
  options: Option<T>[];
  selectedOption: Option<T> | undefined;
  onSelection: (selected: Option<T>) => void;
  placeholder?: TranslationKey;
  loading?: boolean;
  onInputChanged?: (newInput: string) => void;
  id?: string;
  name: string;
  menuIsOpen?: boolean;
}

export type TypeaheadGenericProp<T = unknown> = FC<TypeaheadProps<T>>;

const Typeahead: TypeaheadGenericProp = (
  {
    options,
    selectedOption,
    onSelection,
    placeholder = "placeholders.dropdown",
    loading = false,
    onInputChanged,
    id,
    name
  }
) => {
  const typeaheadClassName = (): string => {
    let className = "typeahead";
    if (loading) className += " typeahead--loading";
    return className;
  };

  useEffect(() => {
    if (options.length === 1 && selectedOption?.value !== options[0].value) onSelection(options[0]);
  });

  const [input, setInput] = useState("");

  const sortedOptions = useMemo(() => matchSorter(options, input, {keys: ["label"]}),
    [options, input]);

  function inputChangedHandler(value: string): void {
    setInput(value);
    onInputChanged?.(value);
  }

  return (
    <Select
      className={typeaheadClassName()}
      classNamePrefix="typeahead"
      options={sortedOptions}
      placeholder={lookupI18nString(placeholder)}
      isLoading={loading}
      value={selectedOption}
      onChange={(value) => value !== null && onSelection(value)}
      onInputChange={inputChangedHandler}
      inputId={id}
      name={name}
    />
  );
};

export default Typeahead;
