import {FC} from "react";
import FinancePaymentSummaryCardItem from "./FinancePaymentSummaryCardItem";
import "./FinancePaymentSummaryCard.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {CurrencyFormat} from "../../../../../../utils/money/CurrencyFormat";
import {Price, ProspectiveLoanWithPrice} from "shared/dist/generated/graphql/resolvers-types";

interface Props {
  depositPercentage: number
  adminFee: Price | undefined
  totalExtrasAmount: Price | undefined
  premiumAmount: Price | undefined
  selectedProspectiveLoan: ProspectiveLoanWithPrice | undefined
}

const FinancePaymentSummaryCard: FC<Props> = ({
  depositPercentage,
  adminFee,
  totalExtrasAmount,
  premiumAmount,
  selectedProspectiveLoan
}) => {
  if (!selectedProspectiveLoan) throw Error("Missing loan information");
  const {
    subsequentInstalmentAmounts,
    firstInstalmentAmount,
    financeFee,
    totalFinance,
    totalAmountPayable
  } = selectedProspectiveLoan;

  return (
    <div className="finance-payment-summary-card">
      {subsequentInstalmentAmounts &&
        <div className="finance-payment-summary-card__monthly-cost">
          <span>
            {
              lookupI18nString({
                id: "paymentFlow.financeCard.monthlyCost",
                templateVariables: {
                  price: CurrencyFormat.formatPriceWithSymbol(subsequentInstalmentAmounts)
                }
              })
            }
          </span>
        </div>
      }

      <div className="finance-payment-summary-card__breakdown">
        <FinancePaymentSummaryCardItem
          title="paymentFlow.financeCard.insurancePremium.title"
          value={CurrencyFormat.formatPriceWithSymbol(premiumAmount)}
        />
        {adminFee &&
          <FinancePaymentSummaryCardItem
            title="paymentFlow.financeCard.adminFee.title"
            value={CurrencyFormat.formatPriceWithSymbol(adminFee)}
          />
        }

        {totalExtrasAmount &&
          <FinancePaymentSummaryCardItem
            title="paymentFlow.financeCard.extras.title"
            value={CurrencyFormat.formatPriceWithSymbol(totalExtrasAmount)}
          />
        }

        {financeFee &&
          <FinancePaymentSummaryCardItem
            title="paymentFlow.financeCard.financeFee.title"
            value={CurrencyFormat.formatPriceWithSymbol(financeFee)}
          />
        }

        {depositPercentage === 0
          ? <FinancePaymentSummaryCardItem
            title="paymentFlow.financeCard.depositPaid.title"
            value={"£0.00"}
          />
          : firstInstalmentAmount &&
          <FinancePaymentSummaryCardItem
            title="paymentFlow.financeCard.depositPaid.title"
            value={`-${CurrencyFormat.formatPriceWithSymbol(firstInstalmentAmount)}`}
          />
        }
      </div>

      {depositPercentage === 0
        ? totalAmountPayable &&
        <div className="finance-payment-summary-card__total">
          <FinancePaymentSummaryCardItem
            title="paymentFlow.financeCard.total.title"
            value={CurrencyFormat.formatPriceWithSymbol(totalAmountPayable)}
          />
        </div>
        : totalFinance &&
        <div className="finance-payment-summary-card__total">
          <FinancePaymentSummaryCardItem
            title="paymentFlow.financeCard.total.title"
            value={CurrencyFormat.formatPriceWithSymbol(totalFinance)}
          />
        </div>
      }
    </div>
  );
};

export default FinancePaymentSummaryCard;
