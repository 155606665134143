import {FC} from "react";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./PolicyDocumentUpload.css";
import CustomerPortalContainerWithoutSidebar from "../shared/container/CustomerPortalContainerWithoutSidebar";
import PolicyDocumentUploadForm from "./form/PolicyDocumentUploadForm";

const PolicyDocumentUpload: FC = () => (
    <div className="document-upload">
      <CustomerPortalContainerWithoutSidebar
        header={
          <div className="document-upload__header">
            <h1>{lookupI18nString("document.upload.header.title")}</h1>
          </div>
        }
        mainContent={
          <PolicyDocumentUploadForm/>
        }
      />
    </div>
  );

export default PolicyDocumentUpload;
