import React, {FC, FormEvent, MouseEvent, useState} from "react";
import "./ForgotPasswordModalBody.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import ForgotPasswordModalButtons, {SendLinkButtonState} from "./ForgotPasswordModalButtons";
import InlineQuestionEmailInput
  from "../../../../../../structure/form/inline-question-email-input/InlineQuestionEmailInput";
import InlineQuestion from "../../../../../../structure/questions/inline-question/InlineQuestion";
import {isValidEmailAddress} from "shared-components/dist/utils/validation/EmailAddresses";
import {sendUserPasswordResetEmail} from "../../../../../../user/authentication/services/UserPasswordManagementService";

export type ForgotPasswordFormState = "Initialised" | "Valid" | "InvalidEmailAddress" | "SendingEmail" | "SendEmailFailed"
export type ForgotPasswordModalDismissedReason = "Cancelled" | "EmailSent"

interface Props {
  onDismissed: (reason: ForgotPasswordModalDismissedReason) => void;
  initialEmailAddress?: string;
}

const ForgotPasswordModalBody: FC<Props> = ({onDismissed, initialEmailAddress}) => {
  const [emailAddress, setEmailAddress] = useState<string>(initialEmailAddress ?? "");
  const [formState, setFormState] = useState<ForgotPasswordFormState>(initialEmailAddress ? "Valid" : "Initialised");
  const isFormValid = formState === "Valid";

  function onEmailEntered(value: string): void {
    setEmailAddress(value);
    const emailValidationResult = isValidEmailAddress(value) ? "Valid" : "InvalidEmailAddress";
    setFormState(emailValidationResult);
  }

  function onEmailInvalid(): void {
    setEmailAddress("");
    setFormState("InvalidEmailAddress");
  }

  async function onSendPasswordResetEmail(event: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>): Promise<void> {
    event.preventDefault();
    if (!isFormValid) return;

    setFormState("SendingEmail");
    const result = await sendUserPasswordResetEmail(emailAddress.trim());
    if (result !== "Success") {
      setFormState("SendEmailFailed");
      return;
    }

    onDismissed("EmailSent");
  }

  function getSendLinkButtonState(): SendLinkButtonState {
    if (formState === "SendingEmail") return "Loading";
    if (!isFormValid) return "Disabled";

    return "Enabled";
  }

  return (
    <form onSubmit={onSendPasswordResetEmail}>
      <p>{lookupI18nString("login.forgotPassword.modal.description")}</p>

      <InlineQuestion
        id="send-reset-password-email-error"
        status={formState === "SendEmailFailed" ? "error" : "hidden"}
        errorMessage="login.errors.genericLoginFailure"
      >
        <div>
          <h5>{lookupI18nString("login.forgotPassword.modal.emailInput.label")}</h5>
          <InlineQuestionEmailInput
            inlineQuestionId="forgot-password-inline-email-question"
            emailInputName="forgot-password-email-input"
            onChange={onEmailEntered}
            onComplete={onEmailEntered}
            onInvalid={onEmailInvalid}
            initialEmailAddress={initialEmailAddress}
          />
        </div>
      </InlineQuestion>

      <ForgotPasswordModalButtons
        onSendEmail={onSendPasswordResetEmail}
        onDismiss={() => onDismissed("Cancelled")}
        sendLinkButtonState={getSendLinkButtonState()}
      />
    </form>
  );
};

export default ForgotPasswordModalBody;
