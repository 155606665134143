import {QuoteAdditionalDriverDetails, RelationshipToProposer} from "shared/src/generated/graphql/resolvers-types";
import {buildClaim} from "./BuildClaim";
import {
  buildClassOfUse,
  buildCountryOfIssue,
  buildGender,
  buildLicenceType,
  buildMartialStatus,
  buildMedicalCondition,
  livedInUKSinceBirth,
} from "./PersonFieldsHelpers";
import {buildConviction} from "./BuildConviction";
import {
  RELATIONSHIP_TO_PROPOSER_OPTIONS,
  RelationshipToProposerOption
} from "../../../../quote/vehicle/additional-driver/shared/components/relationship-to-proposer/model/RelationshipToProposerOption";
import {AggregatorAdditionalDriver} from "../../../../quote/vehicle/additional-driver/shared/models/AdditionalDriver";
import {buildEmploymentValues} from "./BuildEmployment";
import {calculateDuration} from "../ReduxHelpers";
import {isUnderTwentyFive} from "../../../../quote/vehicle/shared/questions/date-of-birth/validators/IsUnderTwentyFive";

export function buildAdditionalDriver(additionalDriverDetails: QuoteAdditionalDriverDetails): AggregatorAdditionalDriver {
  const hasUkResidencyFromBirth = livedInUKSinceBirth(
    additionalDriverDetails.dateOfBirth,
    additionalDriverDetails.ukResidencyDurationMonths
  );

  return {
    id: additionalDriverDetails.id,
    dateOfBirth: additionalDriverDetails.dateOfBirth,
    claims: additionalDriverDetails.incidents?.map(buildClaim) ?? [],
    classOfUse: buildClassOfUse(additionalDriverDetails.proposedClassOfUse),
    convictions: additionalDriverDetails.convictions?.map(buildConviction) ?? [],
    firstName: additionalDriverDetails.firstName,
    gender: buildGender(additionalDriverDetails.gender),
    hasInsuranceDeclined: additionalDriverDetails.hasCancellations,
    hasMedicalCondition: !!additionalDriverDetails.medicalCondition,
    hasNonMotoringConvictions: additionalDriverDetails.hasNonMotoringConvictions,
    hasSecondaryEmployment: !!additionalDriverDetails.secondaryEmployment,
    hasUkResidencyFromBirth,
    householdNumberOfCars: isUnderTwentyFive(new Date(additionalDriverDetails.dateOfBirth))
      ? additionalDriverDetails.householdNumberOfCars ?? undefined
      : undefined,
    licenceCountryOfIssue: buildCountryOfIssue(additionalDriverDetails.licenceCountry),
    licenceDuration: calculateDuration(additionalDriverDetails.licenceDurationMonths),
    licenceType: buildLicenceType(additionalDriverDetails.licenceType),
    maritalStatus: buildMartialStatus(additionalDriverDetails.maritalStatus),
    medicalCondition: buildMedicalCondition(additionalDriverDetails.medicalCondition),
    primaryEmployment: buildEmploymentValues(additionalDriverDetails.primaryEmployment, additionalDriverDetails.gender),
    relationshipToProposer: buildRelationshipToProposerOption(additionalDriverDetails.relationshipToProposer),
    secondaryEmployment: additionalDriverDetails.secondaryEmployment
      ? buildEmploymentValues(additionalDriverDetails.secondaryEmployment, additionalDriverDetails.gender)
      : {
        employmentStatus: undefined,
        industry: undefined,
        job: undefined,
        workedInPastYear: undefined
      },
    surname: additionalDriverDetails.lastName,
    title: additionalDriverDetails.title,
    ukResidencyDuration: hasUkResidencyFromBirth
      ? undefined
      : calculateDuration(additionalDriverDetails.ukResidencyDurationMonths),
    isMainDriver: additionalDriverDetails.isMainDriver
  };
}

function buildRelationshipToProposerOption(relationship: RelationshipToProposer): RelationshipToProposerOption {
  const relationshipToProposer = RELATIONSHIP_TO_PROPOSER_OPTIONS.find(relationshipToProposer => relationshipToProposer.id === relationship);
  if (!relationshipToProposer) throw new Error(`Unknown relationship to proposer: ${relationship}`);

  return relationshipToProposer;
}