import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {buildQuoteRoute} from "../../../../../utils/navigation/NavigationHelpers";
import {REFUSED_QUOTE} from "../../../../../router/models/Routes";
import {quoteErrorCleared, quoteRetrievalStatusChanged} from "../redux/QuoteDetailsSlice";
import {useAppDispatch, useAppSelector} from "../../../../../redux/Hooks";

export function useNavigateOnInvalidQuote(): void {
  const quoteError = useAppSelector(state => state.quoteDetails.quoteError);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (quoteError === undefined) return;

    navigate(buildQuoteRoute(location.pathname, REFUSED_QUOTE));
    dispatch(quoteRetrievalStatusChanged(undefined));
    dispatch(quoteErrorCleared());
  }, [quoteError, navigate, location, dispatch]);
}