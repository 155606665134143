import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  PolicyHolder,
  PolicyList,
  UpdatePolicyHolderMarketingPreferencesResult
} from "shared/dist/generated/graphql/resolvers-types";
import {portalReset, userAttemptedToLogout} from "../../../../redux/Actions";
import {PortalDetails, PortalLoadingStatus} from "./PortalDetails";
import {ReminderType} from "../reminders/thunk/ReminderType";

const initialState: PortalDetails = {
  policyHolder: undefined,
  policyList: undefined,
  portalLoadingStatus: PortalLoadingStatus.NOT_STARTED,
  visibleReminderTypes: undefined,
  documentUploaded: false
};

const portalSlice = createSlice({
  name: "portal",
  initialState,
  reducers: {
    policyListLoaded(state, action: PayloadAction<PolicyList>): void {
      state.policyList = action.payload;
    },
    policyHolderLoaded(state, action: PayloadAction<PolicyHolder>): void {
      state.policyHolder = action.payload;
    },
    updatePortalLoadingStatus(state, action: PayloadAction<PortalLoadingStatus>): void {
      state.portalLoadingStatus = action.payload;
    },
    marketingPreferencesUpdated(state, action: PayloadAction<UpdatePolicyHolderMarketingPreferencesResult>): void {
      if (!state.policyHolder || !state.policyHolder.metadata) return;

      state.policyHolder.metadata.emailMarketingEnabled = action.payload.emailMarketingEnabled;
      state.policyHolder.metadata.smsMarketingEnabled = action.payload.smsMarketingEnabled;
    },
    visibleReminderTypesUpdated(state, action: PayloadAction<ReminderType[]>): void {
      state.visibleReminderTypes = action.payload;
    },
    documentHasBeenUploaded(state, action: PayloadAction<boolean>): void {
      state.documentUploaded = action.payload;
    }
  },
  extraReducers: {
    [userAttemptedToLogout.type]: () => initialState,
    [portalReset.type]: () => initialState
  }
});

export const {
  policyListLoaded,
  policyHolderLoaded,
  updatePortalLoadingStatus,
  marketingPreferencesUpdated,
  visibleReminderTypesUpdated,
  documentHasBeenUploaded
} = portalSlice.actions;

export const portalReducer = portalSlice.reducer;
