import React, {FC} from "react";
import Question from "../../../../../../structure/questions/question/Question";
import {averageMileageChanged} from "../../redux/CoverDetailsSlice";
import MileageInput from "../../../../../../structure/form/mileage-input/MileageInput";
import {logYourCoverGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const AverageMileageQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const averageMileage = useAppSelector(state => state.coverDetails.averageMileage);

  const onAverageMileageChanged = (value: number): void => {
    logYourCoverGoogleAnalyticsEvent({
      categorySuffix: "how_many_miles",
      action: "Answered",
    });

    dispatch(averageMileageChanged(value));
  };

  return (
    <Question
      id="average-mileage-question"
      title="coverDetails.averageMileageQuestion.title"
      description="coverDetails.averageMileageQuestion.description"
      status={averageMileage.status}
      errorMessage={averageMileage.errorMessage}
      tooltip={{
        title: "coverDetails.averageMileageQuestion.tooltip.title",
        description: "coverDetails.averageMileageQuestion.tooltip.description"
      }}
    >
      <MileageInput
        id="average-mileage-input"
        value={averageMileage.value}
        onComplete={onAverageMileageChanged}
      />
    </Question>
  );
};

export default AverageMileageQuestion;