import React, {FC} from "react";
import QuoteJourneyStop, {QuoteJourneyStopType} from "../../../../stops/quote-journey-stop/QuoteJourneyStop";

interface Props {
  isVisible: boolean;
  setIsVisible: (isTooManyModifications: boolean) => void;
  isAdditionalDriverForm: boolean | undefined;
}

const TooManyConvictionsStop: FC<Props> = (
  {
    isVisible,
    setIsVisible,
    isAdditionalDriverForm
  }
) => (
  <QuoteJourneyStop
    stopProps={{
      visible: isVisible,
      title: isAdditionalDriverForm
        ? "additionalDriver.convictionsQuestions.tooManyConvictions.stop.title"
        : "personalDetails.convictionsQuestions.tooManyConvictions.stop.title",
      description: "quoteJourney.shared.softStop.description"
    }}
    setIsVisible={setIsVisible}
    stopType={QuoteJourneyStopType.MaxItemLimitExceeded}
  />
);

export default TooManyConvictionsStop;