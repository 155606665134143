import {isAfter, isBefore, isEqual, startOfDay, subYears} from "date-fns";

export function isNoClaimsBonusValid(
  yearsNoClaimsBonus: number,
  existingPolicyExpiryDate: Date,
  coverStartDate: Date
): boolean {
  return yearsNoClaimsBonus !== 0 && isNoClaimsBonusExpiryDateValid(coverStartDate, existingPolicyExpiryDate);
}

export function isNoClaimsBonusExpiryDateValid(coverStartDate: Date, existingPolicyExpiryDate: Date): boolean {
  const startOfDayCoverStartDate = startOfDay(coverStartDate);
  const startOfDayExistingPolicyExpiryDate = startOfDay(existingPolicyExpiryDate);

  return isEndDateYoungerThan2YearsBeforeCoverStartDate(startOfDayCoverStartDate, startOfDayExistingPolicyExpiryDate) && isEndDateEqualOrBeforeCoverStartDate(startOfDayCoverStartDate, startOfDayExistingPolicyExpiryDate);
}

function isEndDateYoungerThan2YearsBeforeCoverStartDate(startOfDayCoverStartDate: Date, startOfDayExistingPolicyExpiryDate: Date): boolean {
  const coverStartDateMinus2Years = subYears(startOfDayCoverStartDate, 2);

  return isAfter(startOfDayExistingPolicyExpiryDate, coverStartDateMinus2Years) || isEqual(startOfDayExistingPolicyExpiryDate, coverStartDateMinus2Years);
}

function isEndDateEqualOrBeforeCoverStartDate(startOfDayCoverStartDate: Date, startOfDayExistingPolicyExpiryDate: Date): boolean {
  return isEqual(startOfDayExistingPolicyExpiryDate, startOfDayCoverStartDate) || isBefore(startOfDayExistingPolicyExpiryDate, startOfDayCoverStartDate);
}