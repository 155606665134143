import {QuoteMutationGetQuotesArgs, QuotesResult} from "shared/dist/generated/graphql/resolvers-types";
import {apolloClient} from "../../../apollo/ApolloClientProvider";
import {GET_QUOTES} from "shared/dist/graphql/queries/quote/GetQuotes";
import {GetQuotesResult} from "shared/dist/models/quote/GetQuotesResult";

export function getQuotesMutation(queryMutationArgs: QuoteMutationGetQuotesArgs): Promise<QuotesResult | undefined> {
  return new Promise((resolve, reject) => {
    apolloClient.mutate<GetQuotesResult, QuoteMutationGetQuotesArgs>({
      mutation: GET_QUOTES,
      variables: {
        ...queryMutationArgs
      },
    }).then(result => resolve(result.data?.quote.getQuotes))
      .catch(error => reject(error));
  });
}
