import {QuoteType} from "shared/dist/generated/graphql/resolvers-types";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";

export function getBuyQuoteRequirementsDescription(quoteType: QuoteType): TranslationKey {
  switch (quoteType) {
    case QuoteType.CompanyCar:
      return "quote.shared.buyQuoteRequirements.companyCar.description";
    case QuoteType.Default:
      return "quote.shared.buyQuoteRequirements.noExperience.description";
    case QuoteType.NamedDriver:
      return "quote.shared.buyQuoteRequirements.namedDriver.description";
    case QuoteType.NoClaimsBonus:
      return "quote.shared.buyQuoteRequirements.noClaimsBonus.description";
  }
}