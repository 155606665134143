import React, {FC} from "react";
import "./RenewalStateContainer.css";
import {Policy, RenewalInviteStatus} from "shared/dist/generated/graphql/resolvers-types";
import {useFetchRenewalNoticesQueryFor} from "shared/dist/graphql/queries/portal/RenewalNoticeQuery";
import RenewalDueSoon from "./states/due-soon/RenewalDueSoon";
import RenewalExpired from "./states/expired/RenewalExpired";
import RenewalReady from "./states/ready/RenewalReady";
import RenewalRevoked from "./states/revoked/RenewalRevoked";
import RenewalInviteOverdue from "./states/overdue/RenewalInviteOverdue";
import {now} from "shared/src/stdlib/Dates";
import {isAfter, subHours} from "date-fns";
import FullPageSpinner from "shared-components/dist/spinner/full-page-spinner/FullPageSpinner";
import RenewalErrorMessage from "./components/renewal-error-message/RenewalErrorMessage";
import RenewalComplete from "./states/complete/RenewalComplete";

interface Props {
  policy: Policy
}

const RenewalStateContainer: FC<Props> = ({policy}) => {
  const {error, loading, data} = useFetchRenewalNoticesQueryFor(policy.id);

  if (loading) return <FullPageSpinner message="portal.spinner.message"/>;

  if (error || !data) return <RenewalErrorMessage/>;

  if (!data.renewals.renewalNotice) return buildRenewalStateContainer(
    <RenewalDueSoon renewalDate={new Date(policy.metadata.coverEndDate)}/>
  );

  const {insurer, expiryTimestamp, renewalTimestamp} = data.renewals.renewalNotice;

  const renewalDate = new Date(renewalTimestamp);
  const policyEndDate = new Date(expiryTimestamp);
  const status = policy.metadata.renewalInviteStatus;

  if (status === RenewalInviteStatus.Complete) return buildRenewalStateContainer(<RenewalComplete/>);
  if (isAfter(now(), subHours(policyEndDate, 1))) return buildRenewalStateContainer(<RenewalExpired policyEndDate={policyEndDate}/>);

  switch (status) {
    case RenewalInviteStatus.RenewalOffered:
    case RenewalInviteStatus.RenewalOfferedWithException:
      return buildRenewalStateContainer(<RenewalReady insurer={insurer}/>);
    case RenewalInviteStatus.RebrokeOnly:
      return buildRenewalStateContainer(<RenewalReady insurer={insurer} isRebrokeOnly/>);
    case RenewalInviteStatus.MtaDetected:
    case RenewalInviteStatus.DuplicateInviteReceived:
      return buildRenewalStateContainer(<RenewalRevoked/>);
    case RenewalInviteStatus.InviteNotReceived:
      return buildRenewalStateContainer(<RenewalInviteOverdue renewalDate={renewalDate}/>);
    default:
      return buildRenewalStateContainer(<RenewalDueSoon renewalDate={renewalDate}/>);
  }
};

function buildRenewalStateContainer(children: JSX.Element): JSX.Element {
  return (
    <div className="renewal-state-container">
      {children}
    </div>
  );
}

export default RenewalStateContainer;