import {CognitoUser} from "amazon-cognito-identity-js";
import {SendAttributeVerificationResult} from "../../models/SendAttributeVerificationResult";
import {logger} from "../../../../utils/logging/Logger";

export function sendAttributeVerificationCode(cognitoUser: CognitoUser, attributeName: string): Promise<SendAttributeVerificationResult> {
  return new Promise<SendAttributeVerificationResult>(resolve => {
    cognitoUser.getAttributeVerificationCode(
      attributeName,
      {
        onFailure: error => resolve(handleErrorStates(error, attributeName)),
        onSuccess: () => {
          logger.log("Successfully sent verification code for user attribute:", attributeName);
          return resolve("Success");
        }
      }
    );
  });
}

function handleErrorStates(error: Error, attributeName: string): SendAttributeVerificationResult {
  switch (error.name) {
    case "LimitExceededException": {
      logger.error("Too many attempts to send verification code for user attribute:", attributeName, error);
      return "TooManyAttempts";
    }
    default: {
      logger.error("Failed to send verification code for user attribute:", attributeName, error);
      return "Failed";
    }
  }
}