import {buildCrossFieldValidator, ReduxQuestion} from "../../Questions";
import {ClassOfUseOption} from "../../../../pages/quote/vehicle/shared/questions/class-of-use/ClassOfUseOption";
import {
  additionalDriverClassOfUseChanged,
  additionalDriverClassOfUseInvalid
} from "../../../../pages/quote/vehicle/additional-driver/shared/redux/UpdateAdditionalDriverSlice";
import {
  hasValidClassOfUse
} from "../../../../pages/quote/vehicle/additional-driver/shared/components/class-of-use/validation/ValidateAdditionalDriverClassOfUse";

export const classOfUse: ReduxQuestion<ClassOfUseOption> = {
  selector: state => state.updateAdditionalDriver.classOfUse,
  onChange: additionalDriverClassOfUseChanged,
  onInvalid: additionalDriverClassOfUseInvalid,
  crossFieldValidators: [
    buildCrossFieldValidator(
      state => ({
        relationshipToProposer: state.updateAdditionalDriver.relationshipToProposer.value,
        secondaryEmploymentStatus: state.updateAdditionalDriver.secondaryEmployment.employmentStatus.value,
        primaryEmploymentStatus: state.updateAdditionalDriver.primaryEmployment.employmentStatus.value
      }),
      [hasValidClassOfUse]
    )
  ]
};