import {validationFailure, validationSuccess, Validator} from "shared-components/dist/utils/validation/Validator";
import {EmploymentStatus, RelationshipToProposer} from "shared/dist/generated/graphql/resolvers-types";
import {RelationshipToProposerOption} from "../../relationship-to-proposer/model/RelationshipToProposerOption";
import {EmploymentStatusOption} from "../../../../../shared/questions/employment/models/EmploymentStatusOption";

interface EmploymentRelationshipValidatorProps {
  proposerEmploymentStatus?: EmploymentStatus;
  employmentStatus?: EmploymentStatus;
}
type EmploymentRelationshipValidator = (props: EmploymentRelationshipValidatorProps) => Validator<RelationshipToProposerOption | undefined>

interface ReverseEmploymentRelationshipValidatorProps {
  proposerEmploymentStatus?: EmploymentStatus;
  relationshipToProposer?: RelationshipToProposerOption;
}
type ReversedEmploymentRelationshipValidator = (props: ReverseEmploymentRelationshipValidatorProps) => Validator<EmploymentStatusOption | undefined>

export const reverseEmploymentRelationshipValidator = (
  validator: EmploymentRelationshipValidator
): ReversedEmploymentRelationshipValidator => {
  return ({proposerEmploymentStatus, relationshipToProposer}) => employmentStatus => validator({
    employmentStatus: employmentStatus?.vtCode,
    proposerEmploymentStatus: proposerEmploymentStatus
  })(relationshipToProposer);
};

export function employeeSelectedValidator(
  {
    proposerEmploymentStatus, employmentStatus
  }: EmploymentRelationshipValidatorProps
): Validator<RelationshipToProposerOption | undefined> {
  return (relationshipToProposer?: RelationshipToProposerOption) => {
    if (!relationshipToProposer || !isProposersEmployee(relationshipToProposer.id)) return validationSuccess;

    return (isNotEmployedOrSelfEmployed(proposerEmploymentStatus) || isNotEmployed(employmentStatus))
      ? validationFailure("additionalDriver.primaryEmploymentQuestion.invalid.employmentStatus.employee")
      : validationSuccess;
  };
}

export function employerSelectedValidator(
  {
    proposerEmploymentStatus,
    employmentStatus
  }: EmploymentRelationshipValidatorProps
): Validator<RelationshipToProposerOption | undefined> {
  return (relationshipToProposer?: RelationshipToProposerOption) => {
    if (!relationshipToProposer || !isProposersEmployer(relationshipToProposer.id)) return validationSuccess;

    return (isNotEmployedOrSelfEmployed(employmentStatus) || isNotEmployed(proposerEmploymentStatus))
      ? validationFailure("additionalDriver.primaryEmploymentQuestion.invalid.employmentStatus.employer")
      : validationSuccess;
  };
}

export function businessPartnerSelectedValidator(
  {
    proposerEmploymentStatus,
    employmentStatus
  }: EmploymentRelationshipValidatorProps
): Validator<RelationshipToProposerOption | undefined> {
  return (relationshipToProposer?: RelationshipToProposerOption) => {
    if (!relationshipToProposer || !isBusinessPartner(relationshipToProposer.id)) return validationSuccess;

    return (isNotEmployedOrSelfEmployed(employmentStatus) || isNotEmployedOrSelfEmployed(proposerEmploymentStatus))
      ? validationFailure("additionalDriver.primaryEmploymentQuestion.invalid.employmentStatus.businessPartner")
      : validationSuccess;
  };
}

const isProposersEmployee = (status: RelationshipToProposer): boolean => status === RelationshipToProposer.ProposersEmployee;
const isProposersEmployer = (status: RelationshipToProposer): boolean => status === RelationshipToProposer.ProposersEmployer;
const isBusinessPartner = (status: RelationshipToProposer): boolean => status === RelationshipToProposer.BusinessPartner;

const isNotEmployed = (status: EmploymentStatus | undefined): boolean => status !== undefined && !isEmployed(status);
const isNotEmployedOrSelfEmployed = (status: EmploymentStatus | undefined): boolean =>
  status !== undefined && !(isEmployed(status) || isSelfEmployed(status));

const isEmployed = (status: EmploymentStatus): boolean => status === EmploymentStatus.Employed;
const isSelfEmployed = (status: EmploymentStatus): boolean => status === EmploymentStatus.SelfEmployed;