import React, {FC} from "react";
import {hasNonMotoringConvictionsChanged} from "../../redux/PersonalDetailsSlice";
import NonMotoringConvictionsQuestion from "../../../shared/questions/non-motoring-convictions/NonMotoringConvictionsQuestion";
import {nonMotoringConvictionsStopVisibleSelector} from "../../../your-vehicle/redux/selectors/NonMotoringConvictionsStopVisibleSelector";
import {logYourDetailsGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const ProposerNonMotoringConvictionsQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const nonMotoringConvictionsField = useAppSelector(state => state.personalDetails.hasNonMotoringConvictions);
  const isStopVisible = useAppSelector(nonMotoringConvictionsStopVisibleSelector);

  const onNonMotoringConvictionsQuestionChanged = (answer: boolean): void => {
    logYourDetailsGoogleAnalyticsEvent({
      categorySuffix: "non_motoring_convictions",
      action: answer,
    });

    dispatch(hasNonMotoringConvictionsChanged(answer));
  };

  return (
    <NonMotoringConvictionsQuestion
      id="proposer-non-motoring-convictions-question"
      title="personalDetails.nonMotoringConvictionsQuestion"
      nonMotoringConvictionsField={nonMotoringConvictionsField}
      onComplete={onNonMotoringConvictionsQuestionChanged}
      isStopVisible={isStopVisible}
    />
  );
};

export default ProposerNonMotoringConvictionsQuestion;