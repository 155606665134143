import {createAction} from "@reduxjs/toolkit";
import {Vehicle} from "../graphql/queries/vehicle-lookup/registration-lookup/model/Vehicle";
import {SerializableAdditionalDriver} from "../pages/quote/vehicle/additional-driver/shared/models/AdditionalDriver";
import {LoadedQuote} from "../pages/quote/vehicle/your-quote/models/LoadedQuote";
import {VehicleSpecLookupItem} from "shared/dist/generated/graphql/resolvers-types";

export const vehicleDetailsReset = createAction<void>("app/resetVehiclePage");

export const makeSelected = createAction<string>("app/makeSelected");
export const modelSelected = createAction<string>("app/modelSelected");
export const fuelTypeSelected = createAction<string>("app/fuelTypeSelected");
export const transmissionTypeSelected = createAction<string>("app/transmissionTypeSelected");
export const yearOfManufactureSelected = createAction<string>("app/yearOfManufactureSelected");
export const engineCapacityLitresSelected = createAction<string>("app/engineCapacityLitresSelected");
export const trimLevelSelected = createAction<string>("app/trimLevelSelected");
export const fullSpecSelected = createAction<VehicleSpecLookupItem | undefined>("app/fullSpecSelected");

export const vehicleLookupReceived = createAction<Vehicle>("app/vehicleLookupReceived");

export const storedQuoteLoaded = createAction<LoadedQuote>("app/storedQuoteLoaded");

export const policyPurchased = createAction<void>("app/policyPurchased");

export const additionalDriverAdded = createAction<SerializableAdditionalDriver>("app/additionalDriverAdded");
export const additionalDriverEdited = createAction<SerializableAdditionalDriver>("app/additionalDriverEdited");
export const additionalDriverRemoved = createAction<string>("app/additionalDriverRemoved");

export const quoteReset = createAction<void>("app/resetStore");

export const userAttemptedToLogout = createAction<void>("app/userAttemptedToLogout");
export const portalReset = createAction<void>("app/portalReset");
