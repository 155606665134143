import {
  PolicyExtraType,
  ProspectivePolicyExtra,
  ProspectivePolicyExtraOption
} from "shared/dist/generated/graphql/resolvers-types";

export interface StoredPolicyExtra extends ProspectivePolicyExtraOption {
  id: PolicyExtraType;
}

export function mapToStoredPolicyExtra(
  policyExtra: ProspectivePolicyExtra,
  selectedPolicyExtraOption?: ProspectivePolicyExtraOption
): StoredPolicyExtra {
  return {
    id: policyExtra.type,
    optionType: selectedPolicyExtraOption ? selectedPolicyExtraOption.optionType : policyExtra.options[0].optionType,
    description: selectedPolicyExtraOption ? selectedPolicyExtraOption.description : policyExtra.options[0].description,
    price: selectedPolicyExtraOption ? selectedPolicyExtraOption.price : policyExtra.options[0].price
  };
}