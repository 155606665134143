import {
  validationFailure,
  ValidationResult,
  validationSuccess,
  Validator
} from "shared-components/dist/utils/validation/Validator";
import {now} from "shared/dist/stdlib/Dates";
import {endOfDay, isAfter, isBefore, startOfDay, subYears} from "date-fns/fp";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";

export function isNotInTheFuture(today: Date = now()): Validator<Date> {
  return (claimDate: Date): ValidationResult => {
    return isAfter(today, claimDate)
      ? validationFailure("personalDetails.claims.date.error.inFuture")
      : validationSuccess;
  };
}

export function isNotOverFiveYearsAgo(today: Date = now()): Validator<Date> {
  return (claimDate: Date): ValidationResult => {
    return isOlderThanFiveYears(today, claimDate)
      ? validationFailure("personalDetails.claims.date.error.overFiveYearsInPast")
      : validationSuccess;
  };
}

export function isNotBeforePolicyCoverStartDate(
  originalPolicyStartDate: Date,
  errorMessage: TranslationKey = "claims.form.invalidDate.beforeOriginalPolicyStartDate"
): Validator<Date> {
  return (claimDate: Date): ValidationResult => {
    return isBefore(startOfDay(originalPolicyStartDate), claimDate)
      ? validationFailure(errorMessage)
      : validationSuccess;
  };
}

export function isNotAfterPolicyCoverEndDate(policyCoverEndDate: Date): Validator<Date> {
  return (claimDate: Date): ValidationResult => {
    return isAfter(endOfDay(policyCoverEndDate), claimDate)
      ? validationFailure("claims.form.invalidDate.afterSelectedPolicyEndDate")
      : validationSuccess;
  };
}

const isOlderThanFiveYears = (today: Date, claimDate: Date): boolean =>
  isBefore(subYears(5, startOfDay(today)), claimDate);
