import React, {FC} from "react";
import {hasMedicalConditionChanged, medicalConditionChanged} from "../../redux/PersonalDetailsSlice";
import MedicalConditionQuestion from "../../../shared/questions/medical-condition/MedicalConditionQuestion";
import {medicalConditionStopVisibleSelector} from "../../redux/selectors/MedicalConditionStopVisibleSelector";
import {logYourDetailsGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const ProposerMedicalConditionQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const hasMedicalConditionField = useAppSelector(state => state.personalDetails.hasMedicalCondition);
  const medicalConditionField = useAppSelector(state => state.personalDetails.medicalCondition);
  const isStopVisible = useAppSelector(state => medicalConditionStopVisibleSelector(state));

  const onYesNoQuestionAnswered = (answer: boolean): void => {
    logYourDetailsGoogleAnalyticsEvent({
      categorySuffix: "medical_conditions",
      action: answer,
    });
    dispatch(hasMedicalConditionChanged(answer));
  };

  return (
    <MedicalConditionQuestion
      id="proposer-has-medical-condition-question"
      title="personalDetails.hasMedicalCondition"
      tooltip={{
        description: "personalDetails.hasMedicalConditionQuestion.tooltip.description",
      }}
      hasMedicalConditionField={hasMedicalConditionField}
      medicalConditionField={medicalConditionField}
      hasMedicalConditionChanged={onYesNoQuestionAnswered}
      medicalConditionChanged={value => dispatch(medicalConditionChanged(value))}
      subQuestionId="proposer-medical-condition-question"
      subQuestionTitle="personalDetails.medicalCondition"
      isStopVisible={isStopVisible}
    />
  );
};

export default ProposerMedicalConditionQuestion;
