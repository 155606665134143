import React, {FC} from "react";
import LeftHandDriveVehicleStop from "../../../../shared/stops/left-hand-drive-vehicle-stop/LeftHandDriveVehicleStop";
import {shouldShowAssumptionsLeftHandDriveVehicleStopSelector} from "../../../redux/selectors/VehiclePageStateSelectors";
import {useAppSelector} from "../../../../../../../redux/Hooks";

const AssumptionsLeftHandDriveVehicleStop: FC = () => {
  const isVisible = useAppSelector(shouldShowAssumptionsLeftHandDriveVehicleStopSelector);

  return (
    <LeftHandDriveVehicleStop
      isVisible={isVisible}
      isSubContainer={false}
    />
  );
};

export default AssumptionsLeftHandDriveVehicleStop;