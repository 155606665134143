import {FC, useState} from "react";
import Modal from "shared-components/dist/modals/Modal";
import {PolicyDocument, PolicyDocumentInput, PolicyHolder,} from "shared/dist/generated/graphql/resolvers-types";
import "./RequestPrintDocumentsModal.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import ButtonGroup from "shared-components/dist/buttons/button-group/ButtonGroup";
import Button from "shared-components/dist/buttons/button/Button";
import RequestPrintDocumentsModalBody, {PrintRequestBodyState} from "./body/RequestPrintDocumentsModalBody";
import {
  requestPrintDocumentsFromDocmail
} from "../../../../../../../graphql/queries/documentation/request-print-documents/RequestPrintDocumentsFromDocmailQuery";
import {noticeError} from "../../../../../../../newrelic/NoticeError";

export interface DocumentBatchProps {
  policyId: string;
  policyReference: string;
  policyEffectiveDate: string;
  documentsToPrint: PolicyDocument[]
}

interface Props {
  documentBatchProps: DocumentBatchProps;
  policyHolder: PolicyHolder;
  policyBookletUrl?: string;
  visible: boolean;
  onDismiss: () => void;
}

const RequestPrintDocumentsModal: FC<Props> = (
  {
    documentBatchProps,
    policyHolder,
    policyBookletUrl,
    visible,
    onDismiss
  }
) => {
  const [printRequestStatus, setPrintRequestStatus] = useState<PrintRequestBodyState>("INITIAL_VIEW");
  const [isLoading, setIsLoading] = useState(false);

  const onClose = (): void => {
    onDismiss();

    if (printRequestStatus === "GENERIC_ERROR") {
      setPrintRequestStatus("INITIAL_VIEW");
    }
  };

  const isButtonGroupVisable = printRequestStatus === "INITIAL_VIEW" || printRequestStatus === "GENERIC_ERROR";
  const address = `${policyHolder.address.firstLine}\n${policyHolder.address.city}\n${policyHolder.address.postcode}`;

  const onPrintRequest = async (): Promise<void> => {
    setIsLoading(true);
    setPrintRequestStatus("INITIAL_VIEW");

    if (!documentBatchProps.documentsToPrint.length) {
      setPrintRequestStatus("GENERIC_ERROR");
      await noticeError(new Error("Documents list is empty on print request."), {
        policyHolderId: policyHolder.id,
        policyId: documentBatchProps.policyId
      });
    }

    try {
      const response = await requestPrintDocumentsFromDocmail({
        ...documentBatchProps,
        documentsToPrint: documentBatchProps.documentsToPrint.map(mapPolicyDocumentToPolicyDocumentInput)
      });
      setPrintRequestStatus(response);
    } catch (error) {
      setPrintRequestStatus("GENERIC_ERROR");
      await noticeError(new Error("Error sending request for documents.", error), {
        policyHolderId: policyHolder.id,
        policyId: documentBatchProps.policyId
      });
    }

    setIsLoading(false);
  };

  return (
    <Modal
      visible={visible}
      displayCloseButton
      header={<h4>{lookupI18nString("policies.documents.requestPrintedDocuments.modal.title")}</h4>}
      body={
        <RequestPrintDocumentsModalBody
          address={address}
          printRequestBodyState={printRequestStatus}
          policyBookletUrl={policyBookletUrl}
        />
      }
      footer={isButtonGroupVisable
        ? (
          <ButtonGroup className="request-print-documents-modal__button-group"
                       data-testid="request-print-documents-button-group">
            <Button
              onClick={onClose}
              variant="link"
              className="request-print-documents-modal__button-group__maybe-later"
            >
              {lookupI18nString("policies.documents.requestPrintedDocuments.modal.dismiss")}
            </Button>
            <Button
              onClick={onPrintRequest}
              loading={isLoading}
            >
              {lookupI18nString("policies.documents.requestPrintedDocuments.modal.orderPrints")}
            </Button>
          </ButtonGroup>
        )
        : undefined
      }
      onDismiss={onClose}
      className="request-print-documents-modal"
    />
  );
};

const mapPolicyDocumentToPolicyDocumentInput = (document: PolicyDocument): PolicyDocumentInput => ({
  type: document.type,
  effectiveDate: document.effectiveDate,
  fileSize: document.fileSize,
  version: document.version
});

export default RequestPrintDocumentsModal;
