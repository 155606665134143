import {DateOption} from "shared/dist/models/date-option/DateOption";
import {addDays} from "date-fns/fp";
import {now} from "shared/dist/stdlib/Dates";
import {dateOptionsBetween} from "shared/dist/models/date-option/DateOptionsBetween";
import {startOfDayTomorrowPlus1Minute} from "../services/StartDate";

export function startDateOptions(): DateOption[] {
  const today = now();
  const startDate = addDays(2, today);
  const endDate = addDays(30, today);

  return todayAndTomorrow().concat(dateOptionsBetween(startDate, endDate));
}

const todayAndTomorrow = (): DateOption[] => [
  {
    date: now(),
    description: "Today"
  },
  {
    date: startOfDayTomorrowPlus1Minute(),
    description: "Tomorrow"
  }
];