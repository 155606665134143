import React, {FC, ReactElement} from "react";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import "./CurrentDocument.css";
import {GetApp} from "@mui/icons-material";

interface Props {
  title: TranslationKey;
  body: ReactElement;
  details?: ReactElement;
}

const CurrentDocument: FC<Props> = (
  {
    title,
    body,
    details
  }
) => (
  <div className="current-document">
    <div className="current-document__container">
      <div className="current-document__container__content">
        <GetApp/>

        <div className="current-document__container__content__text">
          <span>{lookupI18nString(title)}</span>
          {body}
        </div>
      </div>

      <div className="current-document__container__meta-data">{details}</div>
    </div>
    <hr/>
  </div>
);

export default CurrentDocument;
