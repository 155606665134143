import React, {FC} from "react";
import "./AppStoreBadges.css";
import AppleAppStoreButton from "../../../../../structure/buttons/app-stores/AppleAppStoreButton";
import GooglePlayStoreButton from "../../../../../structure/buttons/app-stores/GooglePlayStoreButton";
import {logPaymentGoogleAnalyticsEvent} from "../../../../../utils/analytics/PaymentAnalytics";

const AppStoreBadges: FC = () => {
  const onAppleStoreClicked = (): void => {
    logPaymentGoogleAnalyticsEvent({
      categorySuffix: "success.apple_store",
      action: "Click",
    });
  };

  const onGooglePlayStoreClicked = (): void => {
    logPaymentGoogleAnalyticsEvent({
      categorySuffix: "success.google_play",
      action: "Click",
    });
  };

  return (
    <div className="app-store-badges">
      <AppleAppStoreButton onClick={onAppleStoreClicked}/>
      <GooglePlayStoreButton onClick={onGooglePlayStoreClicked}/>
    </div>
  );
};

export default AppStoreBadges;