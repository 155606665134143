import {buildCrossFieldValidator, ReduxQuestion} from "../../Questions";
import {
  dateOfBirthAsDateSelector
} from "../../../../pages/quote/vehicle/additional-driver/shared/redux/selectors/AdditionalDriverDateTypeSelectors";
import {
  dateOfBirthChanged,
  dateOfBirthInvalid,
  dateOfBirthReset
} from "../../../../pages/quote/vehicle/additional-driver/shared/redux/UpdateAdditionalDriverSlice";
import {
  dateOfBirthCrossFieldValidators,
  dateOfBirthValidators
} from "../../shared/driver-validation/DateOfBirthValidators";
import {
  dateOfBirthAsDateSelector as proposerDateOfBirthSelector
} from "../../../../pages/quote/vehicle/your-details/redux/selectors/PersonalDetailsSelectors";
import {ageRelationshipValidators} from "./RelationshipToProposer";
import {
  reverseAgeRelationshipValidator
} from "../../../../pages/quote/vehicle/additional-driver/shared/components/date-of-birth/validators/RelationshipToProposerValidators";

export const dateOfBirth: ReduxQuestion<Date> = {
  selector: dateOfBirthAsDateSelector,
  onChange: dateOfBirthChanged,
  onReset: dateOfBirthReset,
  onInvalid: dateOfBirthInvalid,
  validators: dateOfBirthValidators,
  crossFieldValidators: [
    ...dateOfBirthCrossFieldValidators("updateAdditionalDriver"),
    buildCrossFieldValidator(
      state => ({
        relationshipToProposer: state.updateAdditionalDriver.relationshipToProposer.value,
        proposerDateOfBirth: proposerDateOfBirthSelector(state).value
      }),
      ageRelationshipValidators.map(reverseAgeRelationshipValidator)
    )
  ]
};