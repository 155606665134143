import {updateUserAttribute} from "../update/UpdateUserAttributeService";
import {UpdateUserAttributeResult} from "../../models/UpdateUserAttributeResult";
import {getCurrentAuthenticatedUser} from "../../../authentication/services/UserAuthenticationService";
import {verifyUserAttribute} from "../verify/VerifyUserAttributeService";
import {sendAttributeVerificationCode} from "../send-code/SendAttributeVerificationCodeService";
import {VerifyUserAttributeResult} from "../../models/VerifyUserAttributeResult";
import {PHONE_NUMBER_ATTRIBUTE} from "../../Constants";
import {SendAttributeVerificationResult} from "../../models/SendAttributeVerificationResult";
import {InternalCognitoUser} from "../get/GetUserAttributesService";

export async function updatePhoneNumber(newPhoneNumber: string): Promise<UpdateUserAttributeResult> {
  const authenticatedUser = await getCurrentAuthenticatedUser();
  if (!authenticatedUser) return "Failed";

  const updateResult = await updateUserAttribute({
    cognitoUser: authenticatedUser as InternalCognitoUser,
    attributeName: PHONE_NUMBER_ATTRIBUTE,
    newAttributeValue: newPhoneNumber
  });
  if (updateResult !== "Success") return updateResult;

  return await sendAttributeVerificationCode(authenticatedUser, PHONE_NUMBER_ATTRIBUTE);
}

export async function verifyPhoneNumber(verificationCode: string): Promise<VerifyUserAttributeResult> {
  const authenticatedUser = await getCurrentAuthenticatedUser();
  if (!authenticatedUser) return "Failed";

  return await verifyUserAttribute({
    cognitoUser: authenticatedUser,
    attributeName: PHONE_NUMBER_ATTRIBUTE,
    verificationCode
  });
}

export async function sendPhoneNumberVerificationCode(): Promise<SendAttributeVerificationResult> {
  const authenticatedUser = await getCurrentAuthenticatedUser();
  if (!authenticatedUser) return "Failed";

  return await sendAttributeVerificationCode(authenticatedUser, PHONE_NUMBER_ATTRIBUTE);
}