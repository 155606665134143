import {getIntl} from "./IntlProvider";
import {TranslationKey} from "./TranslationKey";

const NOT_FOUND_I18N = "Text not found";

/**
 * @deprecated The method should not be used, use formatjs methods instead.
 * @see {@link /docs/decisions/0002-use-formatjs-formatting-in-react.md Use formatjs in react}
 */
export function lookupI18nString(params: TranslationKey): string {
  const intl = getIntl();

  if (typeof params === "string") {
    return intl.formatMessage(
      {
        id: params,
        defaultMessage: NOT_FOUND_I18N
      },
    );
  }

  return intl.formatMessage(
    {
      id: params.id,
      defaultMessage: NOT_FOUND_I18N
    },
    params.templateVariables
  );
}