import {useSearchParams} from "../../../../utils/custom-hooks/SearchParams";

export interface FirstSignInQueryParamsHook {
  email: string | undefined;
  token: string | undefined;
}

export function useFirstSignInQueryParams(): FirstSignInQueryParamsHook {
  const params = useSearchParams();
  return {
    email: params.get("email") ?? undefined,
    token: params.get("token") ?? undefined
  };
}
