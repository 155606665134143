import React, {FC} from "react";
import {additionalDetailsHasTrackerChanged} from "../../../redux/VehicleDetailsSlice";
import {additionalVehicleDetailsHasTrackerQuestionVisibleSelector} from "../../../redux/selectors/AdditionalQuestionsSelectors";
import BooleanSubQuestion from "../../../../../../../structure/questions/sub-question/variants/boolean-sub-question/BooleanSubQuestion";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const AdditionalDetailsHasTracker: FC = () => {
  const dispatch = useAppDispatch();
  const hasTracker = useAppSelector(state => state.vehicleDetails.additionalVehicleDetails.hasTracker);
  const isVisible = useAppSelector(additionalVehicleDetailsHasTrackerQuestionVisibleSelector);

  if (!isVisible) return null;

  return (
    <BooleanSubQuestion
      title="vehicleQuote.additionalVehicleDetailsForm.hasTracker"
      id="hasTracker"
      value={hasTracker.value}
      status={hasTracker.status}
      errorMessage={hasTracker.errorMessage}
      onAnswer={value => dispatch(additionalDetailsHasTrackerChanged(value))}
    />
  );
};

export default AdditionalDetailsHasTracker;
