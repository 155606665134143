import React, {FC} from "react";
import "./RenewalDetailsConfirmationTableSection.css";

interface Props {
  title: string;
  subtitle?: string | undefined;
}

const RenewalDetailsConfirmationTableSection: FC<Props> = ({
  children,
  title,
  subtitle
}) => {
  return (
    <div className="renewal-details-confirmation-table-section">
      <div className="renewal-details-confirmation-table-section__header">
          <h3 data-testid="renewal-details-confirmation-table-section__title">{title}</h3>
          <p data-testid="renewal-details-confirmation-table-section__subtitle">{subtitle}</p>
      </div>

      <div
        className="renewal-details-confirmation-table-section__children"
        data-testid="renewal-details-confirmation-table-section__children"
      >
        {children}
      </div>
    </div>
  );
};

export default RenewalDetailsConfirmationTableSection;
