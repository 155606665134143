import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../../../../../../../redux/Store";
import {FormField} from "shared-components/dist/models/form-field/FormField";
import {intervalToDuration} from "date-fns/fp";
import {now} from "shared/dist/stdlib/Dates";

const vehiclePurchaseDate = (state: RootState): FormField<string> => state.vehicleDetails.dateOfPurchase;

export const selectDurationSinceVehiclePurchase = createSelector(
  vehiclePurchaseDate,
  (vehiclePurchaseDate: FormField<string>): Duration => {
    if (!vehiclePurchaseDate.value) return {};

    const purchaseDateAsDate = new Date(vehiclePurchaseDate.value);
    const today = now();

    return intervalToDuration({
      start: purchaseDateAsDate,
      end: today
    });
  }
);