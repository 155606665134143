import {FC} from "react";
import {useAppSelector} from "../../../../../../../redux/Hooks";
import FinancePaymentSummaryCard from "../FinancePaymentSummaryCard";
import {
  selectActiveProspectiveLoan,
  selectActiveRenewalNotice, selectPremium,
  selectRenewalDepositPercentage,
  selectTotalPolicyExtraAmount
} from "../../../../../../portal/renewals/redux/RenewalSelectors";

const RenewalFinancePaymentSummaryCard: FC = () => {
  const depositPercentage = useAppSelector(selectRenewalDepositPercentage);
  const extrasAmount = useAppSelector(selectTotalPolicyExtraAmount);
  const renewalNotice = useAppSelector(selectActiveRenewalNotice);
  const premium = useAppSelector(selectPremium);
  const selectedProspectiveLoan = useAppSelector(selectActiveProspectiveLoan);

  return <FinancePaymentSummaryCard
    depositPercentage={depositPercentage}
    adminFee={renewalNotice?.adminFee}
    totalExtrasAmount={extrasAmount}
    premiumAmount={premium}
    selectedProspectiveLoan={selectedProspectiveLoan}/>;
};

export default RenewalFinancePaymentSummaryCard;
