import {QuoteEmployment, QuoteProposer} from "shared/dist/generated/graphql/resolvers-types";
import {mapEmploymentFields} from "./EmploymentMapper";
import {startOfDay} from "date-fns/fp";
import {parseDurationAsMonths} from "../../../../../utils/service/parsers/DurationToMonthsParser";
import {FormField} from "shared-components/dist/models/form-field/FormField";
import {mapQuoteConvictions} from "./ConvictionsMapper";
import {mapQuoteIncidents} from "./IncidentsMapper";
import {mapAddressInput} from "../../../../../utils/graphql/MapAddressInput";
import {parseMainDriver} from "../../../../../pages/quote/vehicle/your-details/redux/parsers/ParseMainDriver";
import {fullMonthsBetween, now} from "shared/dist/stdlib/Dates";
import {formatDateStringFromIsoString} from "shared/dist/stdlib/DateFormat";
import {PersonalDetails} from "../../../../../pages/quote/vehicle/your-details/redux/PersonalDetails";

export function mapProposerToGraphql(personalDetails: PersonalDetails, proposerEmailAddress: FormField<string>): QuoteProposer {
  if (
    personalDetails.firstName.value === undefined ||
    personalDetails.surname.value === undefined ||
    personalDetails.address.value === undefined ||
    personalDetails.gender.value?.id === undefined ||
    personalDetails.title.value?.vtCode === undefined ||
    personalDetails.storedAtNight.value?.id === undefined ||
    personalDetails.primaryEmployment.employmentStatus.value === undefined ||
    personalDetails.hasSecondaryEmployment.value === undefined ||
    personalDetails.classOfUse.value === undefined ||
    personalDetails.dateOfBirth.value === undefined ||
    (personalDetails.mainDriver.isActive && personalDetails.mainDriver.value === undefined) ||
    personalDetails.maritalStatus.value?.id === undefined ||
    personalDetails.licenceType.value?.id === undefined ||
    personalDetails.licenceCountryOfIssue.value?.id === undefined ||
    personalDetails.licenceDuration.value === undefined ||
    (personalDetails.ukResidencyDuration.isActive && personalDetails.ukResidencyDuration.value === undefined)
  ) {
    throw Error("Unable to determine valid personal details to run quote for");
  }

  return {
    firstName: personalDetails.firstName.value,
    surname: personalDetails.surname.value,
    address: mapAddressInput(personalDetails.address.value),
    gender: personalDetails.gender.value.id,
    title: personalDetails.title.value.vtCode,
    email: proposerEmailAddress.value,
    storedAtNight: personalDetails.storedAtNight.value.id,
    isHomeowner: personalDetails.isHomeowner.value ?? false,
    primaryEmployment: mapEmploymentFields(
      personalDetails.primaryEmployment.employmentStatus.value,
      personalDetails.primaryEmployment.job.value,
      personalDetails.primaryEmployment.industry.value,
      personalDetails.primaryEmployment.workedInPastYear.value
    ),
    secondaryEmployment: parseSecondaryEmployment(personalDetails),
    proposedClassOfUse: personalDetails.classOfUse.value.id,
    soliciting: personalDetails.soliciting.value ?? undefined,
    dateOfBirth: formatDateStringFromIsoString(personalDetails.dateOfBirth.value),
    isMainDriver: parseMainDriver(personalDetails),
    phoneNumber: personalDetails.phoneNumber.value ?? "",
    maritalStatus: personalDetails.maritalStatus.value.id,
    licenceType: personalDetails.licenceType.value.id,
    licenceCountry: personalDetails.licenceCountryOfIssue.value.id,
    licenceDurationMonths: parseDurationAsMonths(personalDetails.licenceDuration.value),
    ukResidencyDurationMonths: personalDetails.ukResidencyDuration.isActive
      ? parseDurationAsMonths(personalDetails.ukResidencyDuration.value)
      : fullMonthsBetween(new Date(personalDetails.dateOfBirth.value), startOfDay(now())),
    incidents: mapQuoteIncidents(personalDetails.claims.value.claims),
    convictions: mapQuoteConvictions(personalDetails.convictions.value.convictions),
    medicalCondition: personalDetails.medicalCondition.value?.id
  };
}

function parseSecondaryEmployment({hasSecondaryEmployment, secondaryEmployment}: PersonalDetails): QuoteEmployment | undefined {
  if (!hasSecondaryEmployment.value) return undefined;

  return mapEmploymentFields(
    secondaryEmployment.employmentStatus.value,
    secondaryEmployment.job.value,
    secondaryEmployment.industry.value,
    secondaryEmployment.workedInPastYear.value
  );
}