import {AdditionalVehicleDetails, Vehicle} from "../../../../../../graphql/queries/vehicle-lookup/registration-lookup/model/Vehicle";
import {AdditionalVehicleDetailsFormQuestions} from "../AdditionalVehicleDetailsFormQuestions";
import {FormField, isValidFormField} from "shared-components/dist/models/form-field/FormField";
import {activeFormFields} from "shared-components/dist/models/form-field/FormFields";
import {ASSUME_TRACKER_TRADE_PRICE} from "../../../../../../utils/constants/Quote";
import {VehicleDetails} from "../VehicleDetails";

export function validatedAdditionalVehicleDetailsFromFormInput(
  vehicleDetails: VehicleDetails,
  useMainTrackerQuestion: boolean
): AdditionalVehicleDetails | undefined {
  const additionalDetailsQuestions = vehicleDetails.additionalVehicleDetails;

  if (additionalDetailsQuestionsInValidState(vehicleDetails.additionalVehicleDetails, useMainTrackerQuestion)) {
    return {
      hasAlarm: additionalDetailsQuestions.hasAlarm.value,
      alarmFittedBy: additionalDetailsQuestions.alarmFittedBy.value,
      driverPosition: additionalDetailsQuestions.driverPosition.value,
      seats: additionalDetailsQuestions.seats.value,
      hasTracker: useMainTrackerQuestion ? vehicleDetails.hasTracker.value : additionalDetailsQuestions.hasTracker.value,
      imported: additionalDetailsQuestions.imported.value,
      importType: additionalDetailsQuestions.importType.value,
      estimatedValue: additionalDetailsQuestions.estimatedValue.value,
    };
  }
}

export function shouldUseMainTrackerQuestion(vehicle: Vehicle | undefined): boolean {
  return vehicle !== undefined &&
    vehicle.estimatedValue !== undefined &&
    vehicle.estimatedValue >= ASSUME_TRACKER_TRADE_PRICE;
}

function additionalDetailsQuestionsInValidState(
  additionalVehicleDetails: AdditionalVehicleDetailsFormQuestions,
  useMainTrackerQuestion: boolean
): boolean {
  if (useMainTrackerQuestion) {
    return isValidFormField(...additionalVehicleDetailsFormFieldsWithoutTracker(additionalVehicleDetails));
  }
  return isValidFormField(...additionalVehicleDetailsFormFieldsWithTracker(additionalVehicleDetails));
}

const additionalVehicleDetailsFormFieldsWithTracker = (additionalVehicleDetails: AdditionalVehicleDetailsFormQuestions): FormField<unknown>[] => activeFormFields(additionalVehicleDetails);

const additionalVehicleDetailsFormFieldsWithoutTracker = (additionalVehicleDetails: AdditionalVehicleDetailsFormQuestions): FormField<unknown>[] => activeFormFields(additionalVehicleDetails, ["hasTracker"]);
