import InlineQuestion from "../../../../../structure/questions/inline-question/InlineQuestion";
import React, {FC} from "react";

export const cardNumberId = "global-pay-card-number-input";

const CardNumber: FC = () => (
  <InlineQuestion
    id={cardNumberId}
    label="paymentFlow.byCard.form.cardDetails.cardNumber"
  >
    <div id={cardNumberId} className="card-details__card-number"/>
  </InlineQuestion>
);

export default CardNumber;