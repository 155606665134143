import "./AggregatorQuoteValidationFailureTableItem.css";
import {FC} from "react";
import Button from "shared-components/dist/buttons/button/Button";
import {ChevronRight} from "@mui/icons-material";
import FormattedMessage, {FormattedMessageProps} from "shared-components/dist/translations/components/FormattedMessage";

export interface AggregatorQuoteValidationFailureItemProps {
  heading: FormattedMessageProps;
  subHeading: FormattedMessageProps;
  errorMessage: FormattedMessageProps;
  onResolveClicked: () => void;
}

const AggregatorQuoteValidationFailureTableItem: FC<AggregatorQuoteValidationFailureItemProps> = ({heading, subHeading, errorMessage, onResolveClicked}) => {
  return (
    <div className="aggregator-quote-validation-failure-table-item">
      <div className="aggregator-quote-validation-failure-table-item__content">
        <h3><FormattedMessage {...heading}/></h3>
        <h4><FormattedMessage {...subHeading}/></h4>
        <p><FormattedMessage {...errorMessage}/></p>
      </div>
      <Button
        variant="link"
        rightIcon={<ChevronRight/>}
        onClick={onResolveClicked}
      >
        <FormattedMessage id="aggregatorQuote.validationIssueTable.item.resolve"/>
      </Button>
    </div>
  );
};

export default AggregatorQuoteValidationFailureTableItem;