import {CustomisedAbiListItem} from "shared-components/dist/models/CustomisedAbiListItem";

export const COVER_TYPES = [
  {
    vtCode: "C",
    description: "Comprehensive"
  },
  {
    vtCode: "T",
    description: "Third Party Fire and Theft"
  },
  {
    vtCode: "O",
    description: "Third Party"
  },
] as const;

type CoverTypeVtCode = typeof COVER_TYPES[number]["vtCode"];
export type CoverTypeDescription = typeof COVER_TYPES[number]["description"];

export type CoverType = CustomisedAbiListItem<CoverTypeVtCode, CoverTypeDescription>;

export const COVER_TYPE_OPTIONS: CoverTypeDescription[] = COVER_TYPES.map(coverType => coverType.description);

export function castToCoverType(coverType: string): CoverTypeDescription {
  switch (coverType) {
    case "Comprehensive": return "Comprehensive";
    case "Third Party Fire and Theft": return "Third Party Fire and Theft";
    case "Third Party": return "Third Party";
    default: throw Error(`Unknown cover type "${coverType}"`);
  }
}
