import React, {FC} from "react";
import "./PolicyCards.css";
import {useAppSelector} from "../../../../redux/Hooks";
import {selectPolicyList} from "../../shared/redux/PortalSelectors";
import PolicyErrorMessage from "../../shared/error/PolicyErrorMessage";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import PolicyCard from "../components/policy-card/PolicyCard";
import {Policy} from "shared/dist/generated/graphql/resolvers-types";
import {Maybe} from "graphql/jsutils/Maybe";
import {PortalPolicyStatus} from "../../shared/utils/PolicyStatusUtils";

const PolicyCards: FC = () => {
  const policyList = useAppSelector(selectPolicyList);

  if (!policyList) return <PolicyErrorMessage/>;

  const activePolicyCards = getPolicyCardsFor("active", policyList.active);
  const upcomingPolicyCards = getPolicyCardsFor("upcoming", policyList.upcoming);
  const expiredPolicyCards = getPolicyCardsFor("expired", policyList.expired);
  const cancelledPolicyCards = getPolicyCardsFor("cancelled", policyList.cancelled);

  return (
    <div className="dashboard-policies">
      <div className="dashboard-policies__header">
        {lookupI18nString("dashboard.subheader.yourPolicies")}
      </div>
      <div className="dashboard-policies__policy-cards">
        {activePolicyCards}
        {upcomingPolicyCards}
        {expiredPolicyCards}
        {cancelledPolicyCards}
      </div>
    </div>
  );
};

function getPolicyCardsFor(policyStatus: PortalPolicyStatus, policies: Maybe<Array<Policy>>): JSX.Element[] {
  if (!policies) return [];

  return policies.map((policy, index) => {
    return (
      <PolicyCard
        isDashboard={true}
        key={index}
        policyStatus={policyStatus}
        policy={policy}
      />
    );
  });
}

export default PolicyCards;
