import {AppThunk} from "../../../redux/Store";
import {getAggregatorQuoteMutation} from "../../../graphql/mutations/quote/get-quotes/GetAggregatorQuoteMutation";
import {
  setQuoteValidationErrors,
  quoteRetrievalStatusChanged
} from "../../quote/vehicle/your-quote/redux/QuoteDetailsSlice";
import {QuoteRetrievalStatus} from "../../quote/vehicle/your-quote/models/QuoteRetrievalStatus";
import {mapAggregatorQuoteResult} from "../MapAggregatorQuote";
import {storedQuoteLoaded} from "../../../redux/Actions";
import {noticeError} from "../../../newrelic/NoticeError";
import {AggregatorQuoteExtraInput} from "shared/dist/generated/graphql/resolvers-types";
import {loadAdditionalQuoteInfo} from "../../quote/vehicle/your-quote/redux/thunks/LoadQuotes";
import {mapQuoteValidationErrors} from "../MapQuoteValidationErrors";

export const loadAggregatorQuoteWithPolicyExtras = (
  quoteReference: string,
  policyExtras: AggregatorQuoteExtraInput[],
  monthlyPaymentsEnabled: boolean,
  isCompareNiPhaseTwoFlagEnabled: boolean
): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const aggregatorQuote = await getAggregatorQuoteMutation({quoteReference, policyExtras});
    const loadedQuote = mapAggregatorQuoteResult(aggregatorQuote, isCompareNiPhaseTwoFlagEnabled);
    dispatch(storedQuoteLoaded(loadedQuote));
    dispatch(setQuoteValidationErrors(
      mapQuoteValidationErrors(aggregatorQuote.quoteValidationErrors)
    ));
    await loadAdditionalQuoteInfo(
      monthlyPaymentsEnabled,
      state,
      dispatch
    );
    dispatch(quoteRetrievalStatusChanged(QuoteRetrievalStatus.SUCCESS));
  } catch (error) {
    noticeError(error);
    dispatch(quoteRetrievalStatusChanged(QuoteRetrievalStatus.NOT_FOUND));
  }
};