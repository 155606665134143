import {AppThunk} from "../../../../../../redux/Store";
import {ProspectiveLoanWithPrice} from "shared/dist/generated/graphql/resolvers-types";
import {insurerQuotesChanged, selectedLoanWithPriceChanged} from "../QuoteDetailsSlice";
import {noticeError} from "../../../../../../newrelic/NoticeError";

export const updateLoanRatesNew = (selectedQuoteLoans: ProspectiveLoanWithPrice[], rateReference: string): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const selectedQuoteLoan = getSelectedLoanNew(selectedQuoteLoans, rateReference);
  if (!selectedQuoteLoan) {
    await noticeError(`Rate Reference: [${rateReference}] not found for Quote Reference: [${state.quoteDetails.quoteReference}], Quote Sequence: [${state.quoteDetails.quoteSequenceNumber}]`);
  } else {
    dispatch(selectedLoanWithPriceChanged(selectedQuoteLoan));
  }

  const quotes = state.quoteDetails.insurerQuotes;
  if (quotes) {
    const insurerQuotes = quotes.map(quote => (
      {
        ...quote,
        selectedLoanWithPrice: getSelectedLoanNew(quote.loansWithPrice, rateReference)
      })
    );
    dispatch(insurerQuotesChanged(insurerQuotes));
  }
};

function getSelectedLoanNew(loans: ProspectiveLoanWithPrice[] | undefined, rateReference: string): ProspectiveLoanWithPrice | undefined {
  if (!loans) return undefined;
  return loans.find(loan => loan.rateReference === rateReference);
}
