import {
  INDUSTRY_DOMESTIC_SERVICE_VT_CODE,
  INDUSTRY_EDUCATION_VT_CODE,
  INDUSTRY_NOT_EMPLOYED_DUE_TO_DISABILITY_VT_CODE,
  INDUSTRY_RETIRED_VT_CODE,
  INDUSTRY_UNEMPLOYED_VT_CODE,
  INDUSTRY_VOLUNTARY_ORGANISATION_VT_CODE,
  JOBS_HOUSE_SPOUSE_VT_CODE,
  JOBS_INDEPENDENT_MEANS_VT_CODE,
  JOBS_NOT_EMPLOYED_DUE_TO_DISABILITY_VT_CODE,
  JOBS_RETIRED_VT_CODE,
  JOBS_STUDENT_VT_CODE,
  JOBS_UNEMPLOYED_VT_CODE,
  JOBS_VOLUNTARY_WORKER_VT_CODE
} from "../../../../../utils/constants/AbiCodes";
import {EmploymentStatusOption} from "../../../../../pages/quote/vehicle/shared/questions/employment/models/EmploymentStatusOption";
import {CustomisedAbiListItem} from "shared-components/dist/models/CustomisedAbiListItem";
import {EmploymentStatus, QuoteEmployment} from "shared/dist/generated/graphql/resolvers-types";

export function mapEmploymentFields(
  status?: EmploymentStatusOption,
  job?: CustomisedAbiListItem,
  industry?: CustomisedAbiListItem,
  workedInPastYear?: boolean
): QuoteEmployment {
  if (!status?.vtCode) {
    throw Error("Unable to determine employment status to run quote for");
  }

  switch (status.vtCode) {
    case EmploymentStatus.Employed:
    case EmploymentStatus.SelfEmployed:
      return {
        ...getEmployedJobAndIndustry(job, industry),
        status: status.vtCode
      };
    case EmploymentStatus.Retired:
      return {
        job: JOBS_RETIRED_VT_CODE,
        industry: INDUSTRY_RETIRED_VT_CODE,
        status: status.vtCode
      };
    case EmploymentStatus.Unemployed:
      return {
        ...getUnemployedJobAndIndustry(workedInPastYear, job),
        status: status.vtCode
      };
    case EmploymentStatus.FullTimeStudent:
      return {
        job: JOBS_STUDENT_VT_CODE,
        industry: INDUSTRY_EDUCATION_VT_CODE,
        status: status.vtCode
      };
    case EmploymentStatus.UnemployedDueToDisability:
      return {
        job: JOBS_NOT_EMPLOYED_DUE_TO_DISABILITY_VT_CODE,
        industry: INDUSTRY_NOT_EMPLOYED_DUE_TO_DISABILITY_VT_CODE,
        status: status.vtCode
      };
    case EmploymentStatus.HouseSpouse:
      return {
        job: JOBS_HOUSE_SPOUSE_VT_CODE,
        industry: INDUSTRY_DOMESTIC_SERVICE_VT_CODE,
        status: status.vtCode
      };
    case EmploymentStatus.VoluntaryWorker:
      return {
        job: JOBS_VOLUNTARY_WORKER_VT_CODE,
        industry: INDUSTRY_VOLUNTARY_ORGANISATION_VT_CODE,
        status: status.vtCode
      };
    case EmploymentStatus.IndependentMeans:
      return {
        job: JOBS_INDEPENDENT_MEANS_VT_CODE,
        industry: INDUSTRY_DOMESTIC_SERVICE_VT_CODE,
        status: status.vtCode
      };
    default:
      throw Error("Unknown employment status, unable to determine job or industry");
  }
}

function getEmployedJobAndIndustry(job?: CustomisedAbiListItem, industry?: CustomisedAbiListItem): {job: string, industry: string} {
  if (!job?.vtCode || !industry?.vtCode) {
    throw Error("Unable to determine job or industry for employed worker");
  }

  return {
    job: job.vtCode,
    industry: industry.vtCode,
  };
}

function getUnemployedJobAndIndustry(workedInPastYear?: boolean, job?: CustomisedAbiListItem): {job: string, industry: string} {
  if (workedInPastYear === undefined) {
    throw Error("Unable to determine job or industry for unemployed person");
  }

  if (!workedInPastYear) {
    return {
      job: JOBS_UNEMPLOYED_VT_CODE,
      industry: INDUSTRY_UNEMPLOYED_VT_CODE,
    };
  }

  if (!job?.vtCode) {
    throw Error("Unable to determine job for unemployed person");
  }

  return {
    job: job.vtCode,
    industry: INDUSTRY_UNEMPLOYED_VT_CODE,
  };
}
