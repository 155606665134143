import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {FindAddressResult, RetrieveAddressResult} from "shared/dist/generated/graphql/resolvers-types";
import {findAddressBy} from "../../../../graphql/queries/address/find-address/FindAddressLookup";
import {retrieveAddressBy} from "../../../../graphql/queries/address/retrieve-address/RetrieveAddressLookup";
import {runValidators, Validator} from "shared-components/dist/utils/validation/Validator";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {Option} from "../../typeahead/models/Option";
import {useLoading} from "../../../../graphql/hooks/UseLoading";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "../../typeahead/Typeahead.css";
import {SelectInstance} from "react-select";
import AsyncSelect from "react-select/async";
import classNames from "classnames";

interface OwnProps {
  onComplete: (result: RetrieveAddressResult) => void;
  onInvalid: (error: TranslationKey) => void;
  isVisible: boolean;
  validators?: Validator<RetrieveAddressResult>[];
  placeholder?: TranslationKey;
}

interface AddressOption extends Option<string> {
  isAddress: boolean;
}

type Props = OwnProps;

type ValidationStatus = "INITIAL" | "INVALID" | "INVALID_BLURRED";

const AddressLookup: FC<Props> = (
  {
    onComplete,
    onInvalid,
    isVisible,
    validators = [],
    placeholder
  }
) => {
  const [search, setSearch] = useState("");
  const [results, setResults] = useState<AddressOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<AddressOption | undefined>(undefined);
  const [validationStatus, setValidationStatus] = useState<ValidationStatus>("INITIAL");
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const {loading, withLoading} = useLoading();

  // Needed to keep the input cursor (flashing `|`) visible after validation fails
  useEffect(() => {
    if (validationStatus === "INITIAL") return setMenuIsOpen(false);
    if (validationStatus === "INVALID_BLURRED") {
      typeaheadRef.current?.focusInput();
      setValidationStatus("INITIAL");
      return;
    }
    setValidationStatus("INVALID_BLURRED");
    typeaheadRef.current?.blur();
  }, [validationStatus]);

  const typeaheadRef = useRef<SelectInstance<AddressOption>>(null);
  // https://github.com/JedWatson/react-select/issues/1879
  // Changing the key allows us to programmatically trigger a load, this may get fixed in the future, there's ongoing discussion in the ticket linked above.
  const typeaheadKey = useMemo(() => JSON.stringify(results), [results]);

  const queryError = useCallback((): void => onInvalid("personalDetails.addressQuestion.errors.queryError"), [onInvalid]);

  const onSelection = (selected: AddressOption): void => {
    setSelectedOption(selected);

    if (selected.isAddress) {
      withLoading(retrieveAddressBy(selected.value)
        .then(onSelectedAddressReceived)
        .catch(queryError));
    } else {
      withLoading(findAddressBy(search, selected.value)
        .then(mapFindAddressResultToAddressOption)
        .then(setResults)
        .then(() => typeaheadRef.current?.inputRef?.focus())
        .catch(queryError));
    }
  };

  const onSelectedAddressReceived = (selectedAddress: RetrieveAddressResult): void => {
    const validation = runValidators(selectedAddress, ...validators);

    if (validation.passed) {
      onComplete(selectedAddress);
      setSelectedOption(undefined);
    } else {
      setValidationStatus("INVALID");
      onInvalid(validation.errorMessage);
      typeaheadRef?.current?.clearValue();
    }

    setMenuIsOpen(false);
    setResults([]);
  };

  const loadOptions = (inputValue: string, callback: (options: ReadonlyArray<AddressOption>) => void): void => {
    withLoading(findAddressBy(inputValue)
      .then(mapFindAddressResultToAddressOption)
      .then(callback)
      .catch(queryError));
  };

  const mapFindAddressResultToAddressOption = (results: FindAddressResult[]): AddressOption[] => {
    return results.map(address => ({
      value: address.id,
      label: address.description,
      isAddress: address.isAddress
    }));
  };

  const onInputChanged = (value: string): void => {
    setSearch(value);

    if (value.length > 0) {
      setSelectedOption(undefined);
      setMenuIsOpen(true);
    }
  };

  const noOptionsMessage = (): string => {
    if (loading) return lookupI18nString("structure.form.typeahead.loading");
    return lookupI18nString("structure.form.typeahead.noOptions");
  };

  if (!isVisible) return null;

  return (
    <div className="address-lookup">
      <AsyncSelect
        ref={typeaheadRef}
        className={classNames("typeahead", {"typeahead--loading": loading})}
        classNamePrefix="typeahead"
        key={typeaheadKey}
        loadOptions={loadOptions}
        onChange={value => onSelection(value as AddressOption)}
        onInputChange={onInputChanged}
        cacheOptions={false}
        inputValue={search}
        name="address-lookup-field"
        value={selectedOption}
        menuIsOpen={menuIsOpen}
        defaultOptions={results}
        noOptionsMessage={noOptionsMessage}
        onBlur={() => setMenuIsOpen(false)}
        onFocus={() => {if (selectedOption) setMenuIsOpen(true);}}
        placeholder={placeholder && lookupI18nString(placeholder)}
      />
    </div>
  );
};

export default AddressLookup;