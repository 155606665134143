export default {
  '@global': {
    '.small-uppercase': {
      'font-family': 'Boing, sans-serif',
      'font-size': '0.75rem',
      'line-height': '1.125rem',
      'font-weight': '600',
      'letter-spacing': 'normal',
      'text-transform': 'uppercase'
    },
    '.semi-bold': {
      'font-weight': '500'
    },
    h1: {
      'font-family': 'Boing, sans-serif',
      'font-size': '3rem',
      'line-height': '3rem',
      'font-weight': '500',
      'letter-spacing': 'normal'
    },
    h2: {
      'font-family': 'Boing, sans-serif',
      'font-size': '2rem',
      'line-height': '2.5625rem',
      'font-weight': '500',
      'letter-spacing': 'normal'
    },
    h3: {
      'font-family': 'Boing, sans-serif',
      'font-size': '1.5rem',
      'line-height': '1.9375rem',
      'font-weight': '500',
      'letter-spacing': 'normal'
    },
    h4: {
      'font-family': 'Boing, sans-serif',
      'font-size': '1.3125rem',
      'line-height': '1.5rem',
      'font-weight': '500',
      'letter-spacing': 'normal'
    },
    h5: {
      'font-family': 'Boing, sans-serif',
      'font-size': '1.125rem',
      'line-height': '1.5rem',
      'font-weight': '500',
      'letter-spacing': 'normal'
    },
    '.button': {
      'font-family': 'Boing, sans-serif',
      'font-size': '1rem',
      'line-height': '1.25rem',
      'font-weight': '500',
      'letter-spacing': 'normal',
      '-webkit-transition': 'opacity 150ms linear, background 150ms linear, color 150ms linear, border-color 150ms linear, outline 150ms linear',
      '-moz-transition': 'opacity 150ms linear, background 150ms linear, color 150ms linear, border-color 150ms linear, outline 150ms linear',
      '-ms-transition': 'opacity 150ms linear, background 150ms linear, color 150ms linear, border-color 150ms linear, outline 150ms linear',
      '-o-transition': 'opacity 150ms linear, background 150ms linear, color 150ms linear, border-color 150ms linear, outline 150ms linear',
      transition: 'opacity 150ms linear, background 150ms linear, color 150ms linear, border-color 150ms linear, outline 150ms linear',
      border: '1px solid #6f2fde',
      'border-radius': 4,
      'box-sizing': 'border-box',
      outline: 'none',
      height: '3.5rem',
      'white-space': 'nowrap'
    },
    '.button:disabled': {
      opacity: '0.65'
    },
    '.button.primary, .button.small': {
      background: '#6f2fde',
      border: '2px solid #6f2fde',
      color: 'white'
    },
    '.button.primary:hover, .button.small:hover': {
      background: '#331f59',
      color: 'white',
      border: '2px solid #331f59'
    },
    '.button.primary:focus, .button.small:focus': {
      color: '#331f59',
      border: '2px solid #6f2fde',
      background: 'white'
    },
    '.button.primary:disabled, .button.primary:hover:disabled, .button.small:disabled, .button.small:hover:disabled': {
      color: '#331f59',
      border: '0',
      background: 'rgba(111, 47, 222, 0.2)'
    },
    '.button.primary:active, .button.small:active': {
      background: 'rgba(111, 47, 222, 0.4)',
      border: '2px solid #6f2fde',
      color: '#331f59'
    },
    '.button.secondary': {
      background: '#8cffd0',
      border: '2px solid #8cffd0',
      color: '#282727'
    },
    '.button.secondary:hover': {
      background: '#00f0a2',
      border: '2px solid #00f0a2',
      color: '#282727'
    },
    '.button.secondary:focus': {
      background: '#8cffd0',
      border: '2px solid rgba(40, 39, 39, 0.4)',
      color: '#282727'
    },
    '.button.secondary:disabled, .button.secondary:hover:disabled': {
      background: '#e6e6e6',
      border: '0',
      color: '#282727'
    },
    '.button.secondary:active': {
      background: 'rgba(140, 255, 208, 0.4)',
      border: '2px solid rgba(40, 39, 39, 0.4)',
      color: '#282727'
    },
    '.button.tertiary': {
      border: '1px solid #b5b5b5',
      background: 'white',
      color: '#282727'
    },
    '.button.tertiary:hover': {
      border: '1px solid #b5b5b5',
      background: '#e6e6e6',
      color: '#282727'
    },
    '.button.tertiary:focus': {
      border: '2px solid #6f2fde',
      background: '#e6e6e6',
      color: '#282727'
    },
    '.button.tertiary:disabled, .button.tertiary:hover:disabled': {
      border: '1px solid #b5b5b5',
      background: 'white',
      color: '#282727'
    },
    '.button.tertiary:active': {
      background: 'rgba(40, 39, 39, 0.2)',
      border: '2px solid #6f2fde',
      color: '#282727'
    },
    '.button.ghost': {
      background: 'rgba(111, 47, 222, 0.1)',
      border: '2px solid transparent',
      color: '#331f59'
    },
    '.button.ghost:hover': {
      color: '#331f59',
      border: '2px solid transparent',
      background: 'rgba(111, 47, 222, 0.2)'
    },
    '.button.ghost:focus': {
      color: '#331f59',
      border: '2px solid #6f2fde',
      background: 'white'
    },
    '.button.ghost:disabled, .button.ghost:hover:disabled': {
      color: '#331f59',
      border: '2px solid transparent',
      background: 'rgba(111, 47, 222, 0.2)'
    },
    '.button.ghost:active': {
      background: 'rgba(111, 47, 222, 0.4)',
      border: '2px solid #6f2fde',
      color: '#331f59'
    },
    '.button.pill': {
      'border-radius': '20rem',
      border: '2px solid #6f2fde',
      'background-color': 'white',
      color: '#6f2fde'
    },
    '.button.pill * + *': {
      'margin-left': '0.5rem'
    },
    '.button.pill:hover': {
      'background-color': 'rgba(111, 47, 222, 0.1)'
    },
    '.button.pill:focus, .button.pill:active': {
      'box-shadow': '0 0 0 4px rgba(111, 47, 222, 0.2)'
    },
    '.button.dark': {
      border: '1px solid #282727',
      background: '#282727',
      color: 'white'
    },
    '.button.dark span': {
      display: 'flex',
      'justify-content': 'center'
    },
    '.button.link': {
      'font-family': 'Boing, sans-serif',
      'font-size': '0.875rem',
      'line-height': '1rem',
      'font-weight': '400',
      'letter-spacing': 'normal',
      background: 'none',
      border: 'none',
      color: '#6f2fde',
      padding: '0',
      height: 'auto'
    },
    '.button.link:hover, .button.link:focus': {
      color: '#331f59'
    },
    '.button.link:focus': {
      'border-radius': 4,
      'box-shadow': '0 0 0 0.25rem rgba(111, 47, 222, 0.2)',
      outline: '0.03125rem solid #6f2fde'
    },
    '.button.link[disabled], .button.link[disabled]:hover, .button.link[disabled]:focus': {
      background: 'none',
      border: 'none',
      color: '#6f2fde',
      cursor: 'not-allowed',
      opacity: '0.35'
    },
    '.button.link.icon > *:last-child': {
      'margin-left': '0.5rem'
    },
    '.button.header-light': {
      'font-family': 'Boing, sans-serif',
      'font-size': '0.75rem',
      'line-height': '0.75rem',
      'font-weight': 'bold',
      'letter-spacing': 'normal',
      background: 'none',
      border: '1px solid #6f2fde',
      color: '#331f59',
      fallbacks: [
        {
          'font-weight': '400'
        }
      ],
      height: 'auto',
      padding: '0.25rem 0.5rem'
    },
    '.button.header-dark': {
      'font-family': 'Boing, sans-serif',
      'font-size': '0.75rem',
      'line-height': '0.75rem',
      'font-weight': 'bold',
      'letter-spacing': 'normal',
      background: 'none',
      border: '1px solid #00f0a2',
      color: 'white',
      fallbacks: [
        {
          'font-weight': '400'
        }
      ],
      height: 'auto',
      padding: '0.25rem 0.5rem'
    },
    '.button.small': {
      'font-family': 'Boing, sans-serif',
      'font-size': '1rem',
      'line-height': '1.25rem',
      'font-weight': '500',
      'letter-spacing': 'normal',
      height: '2.5rem',
      padding: '0 1rem'
    },
    '.button.icon': {
      display: 'flex',
      'flex-wrap': 'nowrap',
      'justify-content': 'space-between',
      'align-items': 'center'
    },
    '.button.icon-only': {
      display: 'flex',
      'justify-content': 'center',
      padding: '0',
      width: 56
    },
    '.button.cancel': {
      background: 'white',
      border: '2px solid white',
      color: '#fc5555'
    },
    '.button.cancel:hover, .button.cancel:focus, .button.cancel:active': {
      border: '2px solid #fc5555',
      background: '#fc5555',
      color: 'white'
    },
    '.button.cancel:disabled, .button.cancel:hover:disabled': {
      background: 'rgba(252, 85, 85, 0.1)',
      border: '2px solid #fc5555',
      color: '#fc5555',
      opacity: '0.45'
    },
    '.button.delete': {
      background: 'rgba(252, 85, 85, 0.1)',
      border: '2px solid #fc5555',
      color: '#fc5555'
    },
    '.button.delete:hover, .button.delete:focus, .button.delete:active': {
      border: '2px solid #fc5555',
      background: '#fc5555',
      color: 'white'
    },
    '.button.delete:disabled, .button.delete:hover:disabled': {
      background: 'rgba(252, 85, 85, 0.1)',
      border: '2px solid #fc5555',
      color: '#fc5555',
      opacity: '0.45'
    },
    'input, [type=text], [type=password], [type=date], [type=datetime], [type=datetime-local], [type=month], [type=week], [type=email], [type=number], [type=search], [type=tel], [type=time], [type=url], [type=color]': {
      'font-family': 'Boing, sans-serif',
      'font-size': '1rem',
      'line-height': 'normal !important',
      'font-weight': '400',
      'letter-spacing': 'normal',
      'box-sizing': 'border-box',
      fallbacks: [
        {
          'line-height': '1.25rem'
        }
      ],
      border: '1px solid #b5b5b5',
      padding: '1rem',
      color: '#282727'
    },
    'input:hover, [type=text]:hover, [type=password]:hover, [type=date]:hover, [type=datetime]:hover, [type=datetime-local]:hover, [type=month]:hover, [type=week]:hover, [type=email]:hover, [type=number]:hover, [type=search]:hover, [type=tel]:hover, [type=time]:hover, [type=url]:hover, [type=color]:hover': {
      background: 'rgba(230, 230, 230, 0.4)'
    },
    'input:focus, [type=text]:focus, [type=password]:focus, [type=date]:focus, [type=datetime]:focus, [type=datetime-local]:focus, [type=month]:focus, [type=week]:focus, [type=email]:focus, [type=number]:focus, [type=search]:focus, [type=tel]:focus, [type=time]:focus, [type=url]:focus, [type=color]:focus': {
      background: 'white',
      border: '2px solid #6f2fde',
      padding: 'calc(0.9375rem)',
      outline: 'none !important',
      'box-shadow': 'none !important'
    },
    'input.uppercase, [type=text].uppercase, [type=password].uppercase, [type=date].uppercase, [type=datetime].uppercase, [type=datetime-local].uppercase, [type=month].uppercase, [type=week].uppercase, [type=email].uppercase, [type=number].uppercase, [type=search].uppercase, [type=tel].uppercase, [type=time].uppercase, [type=url].uppercase, [type=color].uppercase': {
      'text-transform': 'uppercase'
    },
    'input.uppercase::placeholder, [type=text].uppercase::placeholder, [type=password].uppercase::placeholder, [type=date].uppercase::placeholder, [type=datetime].uppercase::placeholder, [type=datetime-local].uppercase::placeholder, [type=month].uppercase::placeholder, [type=week].uppercase::placeholder, [type=email].uppercase::placeholder, [type=number].uppercase::placeholder, [type=search].uppercase::placeholder, [type=tel].uppercase::placeholder, [type=time].uppercase::placeholder, [type=url].uppercase::placeholder, [type=color].uppercase::placeholder': {
      'text-transform': 'none'
    },
    'input::placeholder, [type=text]::placeholder, [type=password]::placeholder, [type=date]::placeholder, [type=datetime]::placeholder, [type=datetime-local]::placeholder, [type=month]::placeholder, [type=week]::placeholder, [type=email]::placeholder, [type=number]::placeholder, [type=search]::placeholder, [type=tel]::placeholder, [type=time]::placeholder, [type=url]::placeholder, [type=color]::placeholder': {
      color: '#b5b5b5',
      opacity: '1'
    },
    'input[disabled], [type=text][disabled], [type=password][disabled], [type=date][disabled], [type=datetime][disabled], [type=datetime-local][disabled], [type=month][disabled], [type=week][disabled], [type=email][disabled], [type=number][disabled], [type=search][disabled], [type=tel][disabled], [type=time][disabled], [type=url][disabled], [type=color][disabled]': {
      background: 'white',
      opacity: '0.65'
    },
    'input.input--loading, [type=text].input--loading, [type=password].input--loading, [type=date].input--loading, [type=datetime].input--loading, [type=datetime-local].input--loading, [type=month].input--loading, [type=week].input--loading, [type=email].input--loading, [type=number].input--loading, [type=search].input--loading, [type=tel].input--loading, [type=time].input--loading, [type=url].input--loading, [type=color].input--loading': {
      'background-color': 'white',
      'background-image': 'url(/assets/loading.gif)',
      'background-position': 'right 10px center',
      'background-repeat': 'no-repeat',
      'background-size': '38px 38px',
      padding: '1rem 3.5rem 1rem 1rem'
    },
    'input.input--loading:focus, [type=text].input--loading:focus, [type=password].input--loading:focus, [type=date].input--loading:focus, [type=datetime].input--loading:focus, [type=datetime-local].input--loading:focus, [type=month].input--loading:focus, [type=week].input--loading:focus, [type=email].input--loading:focus, [type=number].input--loading:focus, [type=search].input--loading:focus, [type=tel].input--loading:focus, [type=time].input--loading:focus, [type=url].input--loading:focus, [type=color].input--loading:focus': {
      padding: 'calc(0.9375rem) calc(3.4375rem) calc(0.9375rem) calc(0.9375rem)'
    },
    '@media print, screen and (min-width: 40em)': {
      'input, [type=text], [type=password], [type=date], [type=datetime], [type=datetime-local], [type=month], [type=week], [type=email], [type=number], [type=search], [type=tel], [type=time], [type=url], [type=color]': {
        'max-width': '18.75rem'
      },
      textarea: {
        'max-width': '27.5rem'
      }
    },
    textarea: {
      'font-family': 'Boing, sans-serif',
      'font-size': '1rem',
      'line-height': 'normal !important',
      'font-weight': '400',
      'letter-spacing': 'normal',
      'box-sizing': 'border-box',
      fallbacks: [
        {
          'line-height': '1.25rem'
        }
      ],
      border: '1px solid #b5b5b5',
      padding: '1rem',
      color: '#282727',
      height: '13.4375rem'
    },
    'textarea:hover': {
      background: 'rgba(230, 230, 230, 0.4)'
    },
    'textarea:focus': {
      background: 'white',
      border: '2px solid #6f2fde',
      padding: 'calc(0.9375rem)',
      outline: 'none !important',
      'box-shadow': 'none !important'
    },
    'textarea::placeholder': {
      color: '#b5b5b5',
      opacity: '1'
    },
    'textarea[disabled]': {
      background: 'white',
      opacity: '0.65'
    },
    '.top-bar': {
      flex: '1 1 auto',
      'margin-right': 'auto',
      padding: '1rem'
    },
    '.top-bar .logo': {
      display: 'flex'
    },
    '.top-bar .top-bar-left': {
      flex: '1 1 auto',
      'margin-right': 'auto'
    },
    '.top-bar .top-bar-right': {
      flex: '0 1 auto',
      'margin-left': 'auto',
      'text-align': 'right'
    },
    '.top-bar .menu': {
      'align-items': 'center'
    },
    '.top-bar .menu .burger': {
      'margin-left': '2rem'
    },
    '#secure-payment-field-wrapper': {
      'flex-direction': 'row'
    },
    '#secure-payment-field': {
      'font-family': 'Boing, sans-serif',
      'font-size': '1rem',
      'line-height': '1.25rem',
      'font-weight': '400',
      'letter-spacing': 'normal',
      'border-radius': '0.25rem'
    },
    '#secure-payment-field[type=button]': {
      '-webkit-transition': 'opacity 150ms linear, background 150ms linear, color 150ms linear, border-color 150ms linear, outline 150ms linear',
      '-moz-transition': 'opacity 150ms linear, background 150ms linear, color 150ms linear, border-color 150ms linear, outline 150ms linear',
      '-ms-transition': 'opacity 150ms linear, background 150ms linear, color 150ms linear, border-color 150ms linear, outline 150ms linear',
      '-o-transition': 'opacity 150ms linear, background 150ms linear, color 150ms linear, border-color 150ms linear, outline 150ms linear',
      transition: 'opacity 150ms linear, background 150ms linear, color 150ms linear, border-color 150ms linear, outline 150ms linear',
      'font-family': 'Boing, sans-serif',
      'font-size': '1rem',
      'line-height': '1.25rem',
      'font-weight': '500',
      'letter-spacing': 'normal',
      background: '#6f2fde',
      border: '2px solid #6f2fde',
      'box-sizing': 'border-box',
      color: 'white',
      cursor: 'pointer',
      height: '3.5rem',
      width: '100%'
    },
    '#secure-payment-field[type=button]:hover': {
      background: '#331f59',
      border: '2px solid #331f59'
    },
    '#secure-payment-field[type=button]:focus': {
      background: 'white',
      color: '#331f59',
      border: '2px solid #6f2fde'
    }
  }
};
