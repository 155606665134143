import {
  AccidentDescription,
  AtFault,
  ClaimStatus,
  IncidentType,
  Maybe,
  NoClaimsAffected,
  QuoteIncidentDetails,
  WhichPolicyClaimedAgainst
} from "shared/src/generated/graphql/resolvers-types";
import {SerializableClaim} from "../../../../quote/vehicle/shared/questions/claims/models/Claims";
import {
  ACCIDENT_DESCRIPTIONS,
  AccidentDescriptionOption
} from "../../../../quote/vehicle/shared/questions/claims/models/AccidentDescriptionOption";
import {INCIDENT_TYPES, IncidentTypeOption} from "../../../../quote/vehicle/shared/questions/claims/models/IncidentTypeOption";
import {AtFaultOption, FAULT_STATUSES} from "../../../../quote/vehicle/shared/questions/claims/models/AtFaultOption";
import {
  CLAIMED_POLICIES,
  WhichPolicyClaimedAgainstOption
} from "../../../../quote/vehicle/shared/questions/claims/models/WhichPolicyClaimedAgainstOption";
import {
  AFFECTED_NCB,
  NoClaimsAffectedOption
} from "../../../../quote/vehicle/shared/questions/claims/models/NoClaimsAffectedOption";
import {CLAIM_STATUSES, ClaimStatusOption} from "../../../../quote/vehicle/shared/questions/claims/models/ClaimStatusOption";

export function buildClaim(incident: QuoteIncidentDetails): SerializableClaim {
  return {
    date: incident.date,
    status: buildClaimStatus(incident.status),
    incidentType: buildIncidentType(incident.incidentType),
    atFault: buildAtFault(incident.atFault),
    accidentDescription: buildAccidentDescription(incident.accidentDescription),
    whichPolicyClaimedAgainst: buildLinkedPolicy(incident.whichPolicyClaimedAgainst),
    noClaimsAffected: buildNoClaimsAffected(incident.noClaimsAffected),
    bodilyInjuryOccurred: incident.bodilyInjuryOccurred,
    value: incident.value
  };
}

function buildAccidentDescription(descriptionType?: Maybe<AccidentDescription>): AccidentDescriptionOption {
  const accidentDescription = ACCIDENT_DESCRIPTIONS.find(description => description.vtCode === descriptionType);
  if (!accidentDescription) throw new Error(`Unknown Accident Description: ${accidentDescription}`);

  return accidentDescription;
}

function buildIncidentType(incidentType: IncidentType): IncidentTypeOption {
  const userIncidentType = INCIDENT_TYPES.find(type => type.vtCode === incidentType);
  if (!userIncidentType) throw new Error(`Unknown Incident Type: ${userIncidentType}`);

  return userIncidentType;
}

function buildAtFault(faultStatus: AtFault): AtFaultOption {
  const userFaultStatus = FAULT_STATUSES.find(status => status.vtCode === faultStatus);
  if (!userFaultStatus) throw new Error(`Unknown Fault status: ${userFaultStatus}`);

  return userFaultStatus;
}

function buildLinkedPolicy(policyAgainst: WhichPolicyClaimedAgainst): WhichPolicyClaimedAgainstOption {
  const claimedPolicies = CLAIMED_POLICIES.find(policy => policy.vtCode === policyAgainst);
  if (!claimedPolicies) throw new Error(`Unknown Claimed Policy status: ${claimedPolicies}`);

  return claimedPolicies;
}

function buildNoClaimsAffected(claimsAffected: NoClaimsAffected): NoClaimsAffectedOption {
  const affectedNcb = AFFECTED_NCB.find(affectedNCBType => affectedNCBType.vtCode === claimsAffected);
  if (!affectedNcb) throw new Error(`Unknown Affected No Claims Bonus status: ${affectedNcb}`);

  return affectedNcb;
}

function buildClaimStatus(claimStatus: ClaimStatus): ClaimStatusOption {
  const userClaimStatus = CLAIM_STATUSES.find(status => status.id === claimStatus);
  if (!userClaimStatus) throw new Error(`Unknown Claim status: ${userClaimStatus}`);

  return userClaimStatus;
}