import React, {FC} from "react";
import {
  breakInCoverChanged,
  breakInCoverDeclarationAcceptanceChanged,
  coverStartDateChanged,
  coverStartDateInvalid
} from "../../redux/CoverDetailsSlice";
import {coverStartDateDescriptionsFrom} from "../../../../../../utils/forms/descriptionsFrom";
import {DateOption, toSerializableDateOption} from "shared/dist/models/date-option/DateOption";
import RadioSelectQuestion
  from "../../../../../../structure/questions/question/variants/radio-select-question/RadioSelectQuestion";
import {startDateOptions} from "./helpers/StartDateOptions";
import {runValidators} from "shared-components/dist/utils/validation/Validator";
import {useValidateCoverStartDate} from "./hooks/ValidateCoverStartDate";
import BreakInCoverDetection from "../../../shared/break-in-cover/BreakInCoverDetection";
import {logYourCoverGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {
  isAboveMinimumAgeOnCoverStartDate,
  isBelowMaximumAgeOnCoverStartDate
} from "./validators/CoverStartDateValidators";
import {
  isCarOwnerOnCoverStartDate,
  isDateAfterCoverStartDate
} from "../../../shared/quote-summary/components/edit-cover-summary/validators/CoverStartDateValidators";
import {
  coverStartDateAsDateOptionSelector,
  existingPolicyExpiryDateAsDateSelector
} from "../../redux/selectors/CoverDetailsSelectors";
import {dateOfBirthAsDateSelector} from "../../../your-details/redux/selectors/PersonalDetailsSelectors";
import {vehiclePurchaseDateAsDateSelector} from "../../../your-vehicle/redux/selectors/VehicleDateTypeSelectors";
import {isNamedDriverAXAEligibleSelector} from "../../redux/selectors/named-driver/IsNamedDriverAXAEligible";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const CoverStartDateQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const coverStartDate = useAppSelector(coverStartDateAsDateOptionSelector);
  const dateOfBirth = useAppSelector(state => dateOfBirthAsDateSelector(state).value);
  const carPurchaseDate = useAppSelector(state => vehiclePurchaseDateAsDateSelector(state).value);
  const previousCoverExpiryDate = useAppSelector(state => existingPolicyExpiryDateAsDateSelector(state).value);
  const breakInCover = useAppSelector(state => state.coverDetails.breakInCover);
  const breakInCoverDeclarationAcceptance = useAppSelector(state => state.coverDetails.breakInCoverDeclarationAcceptance);
  const noClaimsBonusExpiryDate = useAppSelector(state => existingPolicyExpiryDateAsDateSelector(state).value);
  const isEligibleForNamedDriver = useAppSelector(isNamedDriverAXAEligibleSelector);

  const [today, tomorrow, ...secondaryOptions] = startDateOptions();
  const primaryOptions = [today, tomorrow];
  const coverStartDates = primaryOptions.concat(secondaryOptions);

  useValidateCoverStartDate();

  const onCoverStartDateChanged = (selectedCoverStartDate: string | undefined): void => {
    const startDate = coverStartDates.find(coverStartDate => coverStartDate.description === selectedCoverStartDate);
    if (!startDate) return;

    const validationResult = runValidators(
      startDate,
      isBelowMaximumAgeOnCoverStartDate(dateOfBirth),
      isAboveMinimumAgeOnCoverStartDate(dateOfBirth),
      isCarOwnerOnCoverStartDate(carPurchaseDate),
      isDateAfterCoverStartDate({
        date: noClaimsBonusExpiryDate,
        validationErrorMessage: "coverDetails.errors.coverStartDateQuestion.policyStartBeforeCurrentPolicyExpiryDate"
      })
    );

    validationResult.passed
      ? onValidCoverStartDate(startDate)
      : dispatch(coverStartDateInvalid(validationResult.errorMessage));
  };

  const onValidCoverStartDate = (startDate: DateOption): void => {
    logYourCoverGoogleAnalyticsEvent({
      categorySuffix: "cover_start_date",
      action: getActionValue(startDate)
    });

    dispatch(coverStartDateChanged({value: toSerializableDateOption(startDate), isEligibleForNamedDriver}));
  };

  const getActionValue = ({description}: DateOption): string => {
    switch (description) {
      case "Today":
      case "Tomorrow":
        return description.toLowerCase();
      default:
        return "from_dropdown";
    }
  };

  return (
    <>
      <RadioSelectQuestion
        id="cover-start-date-question"
        title="coverDetails.coverStartDateQuestion.title"
        dropdownPlaceholder="coverDetails.coverStartDateQuestion.placeholder"
        status={coverStartDate.status}
        errorMessage={coverStartDate.errorMessage}
        selectedOption={coverStartDate.value?.description}
        primaryOptions={coverStartDateDescriptionsFrom(primaryOptions)}
        secondaryOptions={coverStartDateDescriptionsFrom(secondaryOptions)}
        onSelection={onCoverStartDateChanged}
        otherOptionEnabled={false}
        errorTooltip={coverStartDate.errorTooltip}
        tooltip={{description: "coverDetails.coverStartDateQuestion.tooltip"}}
      />

      <BreakInCoverDetection
        carPurchaseDate={carPurchaseDate}
        coverStartDate={coverStartDate.value?.date}
        previousCoverExpiryDate={previousCoverExpiryDate}
        breakInCover={breakInCover}
        breakInCoverDeclarationAcceptance={breakInCoverDeclarationAcceptance}
        breakInCoverChanged={value => dispatch(breakInCoverChanged(value))}
        breakInCoverDeclarationAcceptanceChanged={value => dispatch(breakInCoverDeclarationAcceptanceChanged(value))}
      />
    </>
  );
};

export default CoverStartDateQuestion;
