import {gql} from "@apollo/client";
import {Query} from "shared/dist/generated/graphql/resolvers-types";

export type TitlesResult = Pick<Query, "quote">;

export const TITLES_LOOKUP_QUERY = gql`
  query GetTitles {
    quote {
      abiCodes {
        titles {
          vt
          vtCode
          vtDescription
        }
      }
    }
  }
`;
