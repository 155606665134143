import React, {FC} from "react";
import {averageBusinessMileageChanged} from "../../redux/CoverDetailsSlice";
import MileageInput from "../../../../../../structure/form/mileage-input/MileageInput";
import Question from "../../../../../../structure/questions/question/Question";
import {logYourCoverGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const AverageBusinessMileageQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const averageBusinessMileage = useAppSelector(state => state.coverDetails.averageBusinessMileage);

  if (!averageBusinessMileage.isActive) return null;

  const onAverageBusinessMileageChanged = (value: number): void => {
    logYourCoverGoogleAnalyticsEvent({
      categorySuffix: "how_many_business_miles",
      action: "Answered",
    });

    dispatch(averageBusinessMileageChanged(value));
  };

  return (
    <Question
      id="average-business-mileage-question"
      title="coverDetails.averageBusinessMileageQuestion.title"
      status={averageBusinessMileage.status}
      errorMessage={averageBusinessMileage.errorMessage}
    >
      <MileageInput
        id="average-business-mileage-input"
        value={averageBusinessMileage.value}
        onComplete={onAverageBusinessMileageChanged}
      />
    </Question>
  );
};

export default AverageBusinessMileageQuestion;