import React, {Dispatch, FC, SetStateAction} from "react";
import Button from "shared-components/dist/buttons/button/Button";
import "./RenewalDetailsConfirmationButtonFooter.css";
import {useScrollTo} from "../../../../../utils/navigation/hooks/ScrollTo";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";

interface Props {
  testId: string;
  confirmed: boolean;
  setConfirmed: Dispatch<SetStateAction<boolean>>;
  setEditModalVisible: Dispatch<SetStateAction<boolean>>;
  disabled: boolean
}

const RenewalDetailsConfirmationButtonFooter: FC<Props> = ({testId, confirmed, setConfirmed, setEditModalVisible, disabled}) => {
  const {scroll} = useScrollTo("unconfirmed");

  const confirm = (): void => {
    setConfirmed(true);
    scroll();
  };

  return (
    <div className="renewal-details-confirmation-table-button-footer" data-testid={testId}>
      {
        confirmed
          ? <div className="renewal-details-confirmation-table-button-footer__change-details">
            <Button data-testid={`${testId}-change-details`} variant="ghost" onClick={() => setConfirmed(false)}>{lookupI18nString("portal.renewals.detailConfirmation.buttonFooter.changeDetails")}</Button>
          </div>
          : <div className="renewal-details-confirmation-table-button-footer__confirm-buttons">
            <Button data-testid={`${testId}-edit`} variant="ghost" onClick={() => setEditModalVisible(true)} disabled={disabled}>{lookupI18nString("portal.renewals.detailConfirmation.buttonFooter.update")}</Button>
            <Button data-testid={`${testId}-confirm`} variant="primary" onClick={confirm} disabled={disabled}>{lookupI18nString("portal.renewals.detailConfirmation.buttonFooter.confirm")}</Button>
          </div>
      }
    </div>
  );
};

export default RenewalDetailsConfirmationButtonFooter;