import "./QuotePriceCardAggregatorFooter.css";
import "../../QuotePriceCard.css";
import {FC} from "react";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {quoteSteps} from "../../../../../quote-step/QuoteSteps";
import {useNavigate} from "react-router-dom";
import Button from "shared-components/dist/buttons/button/Button";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {useHasQuoteValidationErrors} from "../../../../../../your-quote/hooks/hasQuoteValidationErrors";
import {scrollToElement} from "../../../../../../../../../utils/navigation/ScrollToHelpers";

interface Props {
  title: TranslationKey;
  description: TranslationKey;
  buttonText: TranslationKey;
  onClick: () => void;
  id: string;
}

const BaseQuotePriceCardFooter: FC<Props> = (
  {
    title,
    description,
    buttonText,
    onClick,
    id
  }) => {
  return (
    <div className="quote-price__aggregator-footer" id={id}>
      <div className="quote-price__aggregator-footer__column">
        <h5 className="quote-price__aggregator-footer__title">{lookupI18nString(title)}</h5>
        <div className="quote-price__aggregator-footer__description">{lookupI18nString(description)}</div>
      </div>
      <div>
        <Button
          variant="primary"
          onClick={onClick}
          className="edit-quote-button">
          {lookupI18nString(buttonText)}
        </Button>
      </div>
    </div>
  );
};

const QuotePriceCardEditFooter: FC = () => {
  const navigate = useNavigate();
  const redirectToStartOfQuoteFlow = (): void => navigate(quoteSteps.yourCar.routes.new);
  return (
    <BaseQuotePriceCardFooter
      title="aggregatorQuote.priceCard.footer.editQuote.title"
      description="aggregatorQuote.priceCard.footer.editQuote.description"
      buttonText="aggregatorQuote.priceCard.footer.editQuote.button"
      onClick={redirectToStartOfQuoteFlow}
      id="aggregator-edit-quote-footer"
    />
  );
};

const QuotePriceCardResolveErrorsFooter: FC = () => {
  return (
    <BaseQuotePriceCardFooter
      title="aggregatorQuote.priceCard.footer.errorsPresent.title"
      description="aggregatorQuote.priceCard.footer.errorsPresent.description"
      buttonText="aggregatorQuote.priceCard.footer.errorsPresent.button"
      onClick={() => scrollToElement("aggregator-quote-validation-failure-cards")}
      id="aggregator-resolve-errors-footer"
    />
  );
};

const QuotePriceCardAggregatorFooter: FC = () => {
  const hasQuoteValidationErrors = useHasQuoteValidationErrors();

  return hasQuoteValidationErrors
    ? <QuotePriceCardResolveErrorsFooter/>
    : <QuotePriceCardEditFooter/>;
};

export default QuotePriceCardAggregatorFooter;