import {FC, MouseEvent} from "react";
import "./RenewalPriceCard.css";
import HeadlinePrice from "../headline-price/HeadlinePrice";
import Button from "shared-components/dist/buttons/button/Button";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import FormattedMessage from "shared-components/src/translations/components/FormattedMessage";
import {CurrencyFormat} from "../../../../../utils/money/CurrencyFormat";
import {useAppSelector} from "../../../../../redux/Hooks";
import {
  selectAreRenewalProspectiveLoansLoading,
  selectRenewalDepositPercentage,
  selectShowMonthlyPaymentsAsDefault
} from "../../redux/RenewalSelectors";
import {Price, ProspectiveLoanWithPrice} from "shared/dist/generated/graphql/resolvers-types";
import {scrollToElement} from "../../../../../utils/navigation/ScrollToHelpers";
import {RadioButtonChecked, RadioButtonUnchecked} from "@mui/icons-material";
import {RenewalQuoteType} from "../../models/RenewalQuoteType";
import {useFeatureFlags} from "shared-components/dist/feature-flags/hooks/UseFeatureFlags";
import {LoadingStatus} from "../../../../quote/vehicle/your-quote/models/LoadingStatus";
import {displayLoanValue} from "../../../../../utils/service/string/DisplayLoanValue";
import classNames from "classnames";

export interface RenewalPriceCardProps {
  quoteType: RenewalQuoteType;
  insurer?: string;
  totalUnfinancedAmount?: Price;
  prospectiveLoan?: ProspectiveLoanWithPrice;
  displayRadioButton: boolean;
  selected: boolean;
  onSelection: (quoteType: RenewalQuoteType) => void;
  isBestPrice: boolean;
}

const RenewalPriceCard: FC<RenewalPriceCardProps> = (
  {
    quoteType,
    insurer,
    totalUnfinancedAmount,
    prospectiveLoan,
    displayRadioButton,
    selected,
    isBestPrice,
    onSelection
  }: RenewalPriceCardProps
) => {
  const areRenewalProspectiveLoansLoading = useAppSelector(selectAreRenewalProspectiveLoansLoading);
  const depositPercentage = useAppSelector(selectRenewalDepositPercentage);

  const {monthlyPaymentsFlag} = useFeatureFlags();
  const showMonthlyPaymentAsDefault = useAppSelector(selectShowMonthlyPaymentsAsDefault) && monthlyPaymentsFlag;

  const rootId = `renewal-price-card-${isBestPrice ? "primary" : "secondary"}`;
  const isRenewal = quoteType === "RENEWAL";
  const loadingStatus = areRenewalProspectiveLoansLoading ? LoadingStatus.LOADING : LoadingStatus.DEFAULT;

  const getHeadlinePrice = (): Price | undefined => {
    if (areRenewalProspectiveLoansLoading) return undefined;

    return showMonthlyPaymentAsDefault
      ? prospectiveLoan?.totalAmountPayable
      : totalUnfinancedAmount;
  };

  const getUpfrontPayment = (): string => {
    if (depositPercentage === 0) return CurrencyFormat.formatPriceWithSymbol({amount: "0", currency: "gbp"});
    return displayLoanValue(prospectiveLoan?.firstInstalmentAmount, loadingStatus);
  };

  const onClick = (event: MouseEvent): void => {
    event.preventDefault();

    if (displayRadioButton && !areRenewalProspectiveLoansLoading) onSelection(quoteType);
  };

  return (
    <div
      className={classNames("renewal-price-card", {"renewal-price-card__selected": displayRadioButton && selected && !areRenewalProspectiveLoansLoading}, {"renewal-price-card__unselected": displayRadioButton && !selected})}
      data-testid={rootId}
      data-testname={`${quoteType.toLowerCase()}-quote`}
      onClick={onClick}
    >
      {areRenewalProspectiveLoansLoading && <div className="renewal-price-card__overlay"/>}
      <div className="renewal-price-card__header">
        <div
          className="renewal-price-card__header__radio-button"
          data-testid={`${rootId}__header__radio-button`}
          hidden={!displayRadioButton}
        >
          <input
            type="radio"
            name={"foo"}
            id={"renewal-price-card__header__radio-button__input"}
            data-testid={`${rootId}__header__radio-button__input`}
            checked={selected}
            readOnly={true}
          />
          <label htmlFor={"renewal-price-card__header__radio-button__input"}>
            <div
              className="renewal-price-card__header__radio-button__icon"
              data-testid={`${rootId}__header__radio-button__icon`}
            >
              <RadioButtonUnchecked/>
              <RadioButtonChecked/>
            </div>
          </label>
        </div>
        <span
          className="renewal-price-card__header__title"
          data-testid={`${rootId}__header__title`}
        >
          {lookupI18nString(
            !displayRadioButton && isRenewal
              ? "portal.renewals.summary.priceCard.renewalOnlyPriceTitle"
              : isBestPrice
                ? "portal.renewals.summary.priceCard.bestPriceTitle"
                : "portal.renewals.summary.priceCard.nextBestPriceTitle"
          )}
        </span>
        {isRenewal && <span
          className="renewal-price-card__header__tag"
          data-testid={`${rootId}__header__tag`}
        >
          {lookupI18nString("portal.renewals.summary.priceCard.currentInsurerTag")}
        </span>}
      </div>

      <div className="renewal-price-card__body">
        <p
          className={`renewal-price-card__body__title${!showMonthlyPaymentAsDefault ? "__annual" : ""}`}
          data-testid={`${rootId}__title-with-insurer`}
        >
          {
            lookupI18nString({
              id: isRenewal
                ? "portal.renewals.summary.priceCard.renewalInsurer"
                : "portal.renewals.summary.priceCard.rebrokeInsurer",
              templateVariables: {insurerName: insurer ?? "---"}
            })
          }
        </p>
        <HeadlinePrice
          price={getHeadlinePrice()}
          testId={`${rootId}__total-amount`}
        />
        <p className={`renewal-price-card__body__price-description${!showMonthlyPaymentAsDefault ? "__annual" : ""}`}>
          {lookupI18nString("portal.renewals.summary.priceCard.iptText")}
        </p>
        {showMonthlyPaymentAsDefault &&
          <>
            <p
              className="renewal-price-card__body__upfront"
              data-testid={`${rootId}__upfront-payment`}
            >
              <FormattedMessage
                id="portal.renewals.summary.priceCard.upfrontPayment"
                values={{
                  upfrontPayment: getUpfrontPayment(),
                  primary: (msg: string) => <span className="color-primary">{msg}</span>
                }}
              />
            </p>
            <p
              className="renewal-price-card__body__installments"
              data-testid={`${rootId}__installment-amount`}
            >
              <FormattedMessage
                id="portal.renewals.summary.priceCard.instalments"
                values={{
                  monthlyInstalmentAmount: displayLoanValue(prospectiveLoan?.subsequentInstalmentAmounts, loadingStatus),
                  instalments: (areRenewalProspectiveLoansLoading ? undefined : prospectiveLoan)?.numberOfInstalments ?? "--",
                  primary: (msg: string) => <span className="color-primary">{msg}</span>
                }}
              />
            </p>
          </>
        }

        <Button
          variant="secondary"
          expanded
          small
          disabled={!selected}
          onClick={() => scrollToElement("compare-cover")}
        >
          {lookupI18nString("portal.renewals.summary.priceCard.button")}
        </Button>
      </div>
    </div>
  );
};

export default RenewalPriceCard;