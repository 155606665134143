import React, {FC} from "react";
import BooleanQuestion from "../../../../../../structure/questions/question/variants/boolean-question/BooleanQuestion";
import {isOwnerAndRegisteredKeeperChanged} from "../../redux/VehicleDetailsSlice";
import OwnerRegisteredKeeperForm from "./owner-registered-keeper-form/OwnerRegisteredKeeperForm";
import {hasVehicleSummarySelector} from "../../redux/selectors/VehicleSummarySelectors";
import {logYourCarGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const OwnerRegisteredKeeperQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const isOwnerAndRegisteredKeeper = useAppSelector(state => state.vehicleDetails.isOwnerAndRegisteredKeeper);
  const isVisible = useAppSelector(hasVehicleSummarySelector);

  if (!isVisible) return null;

  function onOwnerChanged(value: boolean): void {
    dispatch(isOwnerAndRegisteredKeeperChanged(value));

    logYourCarGoogleAnalyticsEvent({
      categorySuffix: "owner_keeper",
      action: value,
    });
  }

  return (
    <>
      <BooleanQuestion
        id="OwnerRegisteredKeeper"
        title="vehicleQuote.ownerOrRegisteredKeeperQuestion.title"
        description="vehicleQuote.ownerOrRegisteredKeeperQuestion.description"
        formField={isOwnerAndRegisteredKeeper}
        onAnswer={onOwnerChanged}
      />
      <OwnerRegisteredKeeperForm/>
    </>
  );
};

export default OwnerRegisteredKeeperQuestion;
