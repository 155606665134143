import React, {FC, useEffect} from "react";
import CarDetailsList from "../car-details-list/CarDetailsList";
import PersonalDetailsList from "../personal-details-list/PersonalDetailsList";
import CoverDetailsList from "../cover-details-list/CoverDetailsList";
import "./QuoteAccordionList.css";
import AdditionalDriversSummary from "../additional-drivers-summary/AdditionalDriversSummary";
import {useAccordion} from "shared-components/dist/lists/accordian-list/hooks/UseAccordion";
import AccordionItem from "shared-components/dist/lists/accordian-list/components/accordion-item/AccordionItem";
import AccordionButton from "shared-components/dist/lists/accordian-list/components/accordion-button/AccordionButton";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import AccordionContents from "shared-components/dist/lists/accordian-list/components/accordion-contents/AccordionContents";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {Status} from "shared-components/dist/status-indicator/Status";
import {carDetailsAccordionStatusSelector} from "../../../../your-quote/redux/selectors/QuoteDetailsSelectors";
import {ErrorOutline} from "@mui/icons-material";
import {AccordionItemProps} from "shared-components/dist/lists/accordian-list/AccordionList";
import {logYourQuoteGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {useAppSelector} from "../../../../../../../redux/Hooks";
import {additionalDriversSelector} from "../../../../your-details/redux/selectors/PersonalDetailsSelectors";
import {useIsEditFlowEnabledForQuote} from "../../../../your-quote/hooks/IsEditFlowEnabledForQuote";

type QuoteAccordionItem = Omit<AccordionItemProps, "title"> & {
  title: TranslationKey;
  categorySuffix: "your_car" | "your_details" | "your_cover" | "additional_drivers";
  status?: Status;
  description?: TranslationKey;
}

const QuoteAccordionList: FC = () => {
  const carDetailsStatus = useAppSelector(carDetailsAccordionStatusSelector);
  const additionalDrivers = useAppSelector(additionalDriversSelector);
  const isEditFlowEnabled = useIsEditFlowEnabledForQuote();

  const shouldDisplayAdditionalDriversSection = isEditFlowEnabled || additionalDrivers.length > 0;

  const items: QuoteAccordionItem[] = [
    {
      title: "quoteDetails.summary.yourCar",
      contents: <CarDetailsList/>,
      status: carDetailsStatus,
      description: carDetailsStatus === "hidden"
        ? undefined
        : "quoteDetails.summary.yourCar.description",
      categorySuffix: "your_car"
    },
    {
      title: "quoteDetails.summary.yourDetails",
      contents: <PersonalDetailsList/>,
      categorySuffix: "your_details"
    },
    {
      title: "quoteDetails.summary.yourCover",
      contents: <CoverDetailsList/>,
      categorySuffix: "your_cover"
    }
  ];
    shouldDisplayAdditionalDriversSection ? items.push({
        title: "quoteDetails.summary.additionalDrivers",
        description: "quoteDetails.summary.additionalDrivers.description",
        contents: <AdditionalDriversSummary/>,
        categorySuffix: "additional_drivers"
      }) : undefined;

  const {openIndexes, toggleIndex} = useAccordion([0]);

  useEffect(() => {
    if (carDetailsStatus === "error" && !openIndexes.includes(0)) {
      toggleIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carDetailsStatus]);

  return (
    <div className="quote-accordion-list">
      {
        items.map((item, index) => (
          <AccordionItem key={index} isVisible={item.isVisible}>
            <AccordionButton
              className={`quote-accordion-list__button quote-accordion-list__button--${item.status === "error" ? "error error" : item.status}`}
              isOpen={openIndexes.includes(index)}
              onClick={() => {
                toggleIndex(index);
                logYourQuoteGoogleAnalyticsEvent({
                  categorySuffix: item.categorySuffix,
                  action: "Click"
                });
              }}
            >
              <div className="quote-accordion-list__button__content">
                {item.status && item.status !== "hidden" && (
                  <div className="quote-accordion-list__button__status-icon">
                    <ErrorOutline/>
                  </div>
                )}

                <div className="quote-accordion-list__button__info">
                  <div className="quote-accordion-list__button__title">{lookupI18nString(item.title)}</div>

                  {item.description && item.status !== "hidden" && (
                    <div className="quote-accordion-list__button__description">
                      {lookupI18nString(item.description)}
                    </div>
                  )}
                </div>
              </div>
            </AccordionButton>

            <AccordionContents isOpen={openIndexes.includes(index)}>
              {item.contents}
            </AccordionContents>
          </AccordionItem>
        ))
      }
    </div>
  );
};

export default QuoteAccordionList;
