import React, {FC} from "react";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./BreakInCoverDeclaration.css";
import {ConditionalBooleanFormField} from "shared-components/dist/models/form-field/variants/BooleanFormField";
import QuoteJourneyStop from "../../stops/quote-journey-stop/QuoteJourneyStop";
import SubContainer from "../../../../../../structure/questions/sub-container/SubContainer";
import RadioConfirmation from "shared-components/dist/form/radio-confirmation/RadioConfirmation";
import {mapValueToYesOrNoEventType} from "shared-components/dist/analytics/GoogleAnalyticsEventsService";
import {logYourCoverGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import ErrorHeader from "shared-components/dist/questions/error-header/ErrorHeader";
import PlainCard from "shared-components/dist/cards/plain-card/PlainCard";

interface OwnProps {
  isWithinModal: boolean;
  breakInCoverDeclarationAcceptance: ConditionalBooleanFormField;
  breakInCoverDeclarationAcceptanceChanged: (value: boolean) => void;
}

const BreakInCoverDeclaration: FC<OwnProps> = (
  {
    isWithinModal,
    breakInCoverDeclarationAcceptance,
    breakInCoverDeclarationAcceptanceChanged
  }
) => {
  const onBreakInCoverDeclarationAcceptanceChanged = (value: boolean): void => {
    logYourCoverGoogleAnalyticsEvent({
      categorySuffix: "break_in_cover_declaration",
      action: mapValueToYesOrNoEventType(value),
    });

    breakInCoverDeclarationAcceptanceChanged(value);
  };

  return (
    <PlainCard className={`break-in-cover-declaration${isWithinModal ? "--modal" : ""}`}>
      <div className="break-in-cover-declaration__header">
        <h4>{lookupI18nString("coverDetails.breakInCover.upToFourteenDays.title")}</h4>
        <h5>{lookupI18nString("coverDetails.breakInCover.upToFourteenDays.description")}</h5>
      </div>

      <div>
        <SubContainer status={breakInCoverDeclarationAcceptance.status === "error" ? "error" : "hidden"}>
          {breakInCoverDeclarationAcceptance.errorMessage && (
            <ErrorHeader
              status={breakInCoverDeclarationAcceptance.status === "error" ? "error" : "hidden"}
              title={breakInCoverDeclarationAcceptance.errorMessage}
            />
          )}

          <div className="break-in-cover-declaration__declarations">
            <p>{lookupI18nString("coverDetails.breakInCover.upToFourteenDays.confirmation")}</p>
            <ul>
              <li>
                <span>{lookupI18nString("coverDetails.breakInCover.upToFourteenDays.hasNotBeenInAnAccident")}</span>
              </li>
              <li>
                <span>{lookupI18nString("coverDetails.breakInCover.upToFourteenDays.hasNoPendingConvictions")}</span>
              </li>
              <li>
                <span>{lookupI18nString("coverDetails.breakInCover.upToFourteenDays.hasBeenKeptAtHomeAddress")}</span>
              </li>
            </ul>
          </div>

          <div className="break-in-cover-declaration__question">
            <RadioConfirmation
              name="break-in-cover-declaration"
              selectedGroupOption={breakInCoverDeclarationAcceptance.value}
              setSelectedGroupOption={onBreakInCoverDeclarationAcceptanceChanged}
            />

            <QuoteJourneyStop
              isWithinModal={isWithinModal}
              stopProps={{
                title: "coverDetails.breakInCover.softStops.underFourteenDays.title",
                description: "coverDetails.breakInCover.softStops.underFourteenDays.description",
                visible: breakInCoverDeclarationAcceptance.value === false
              }}
            />
          </div>
        </SubContainer>
      </div>
    </PlainCard>
  );
};

export default BreakInCoverDeclaration;