import {AppThunk} from "../../../../../redux/Store";
import {Policy} from "shared/dist/generated/graphql/resolvers-types";
import {showUnverifiedEmailAddressReminder} from "../rules/cognito/ShowUnverifiedEmailAddressReminder";
import {showUnverifiedPhoneNumberReminder} from "../rules/cognito/ShowUnverifiedPhoneNumberReminder";
import {showUploadDocumentsReminder} from "../rules/ShowUploadDocumentsReminder";
import {visibleReminderTypesUpdated} from "../../redux/PortalSlice";
import {ReminderType} from "./ReminderType";
import {showReviewIsInProgressReminder} from "../rules/ShowReviewIsInProgressReminder";
import {showWithCustomerReminder} from "../rules/ShowWithCustomerReminder";
import {showPolicyVerifiedReminder} from "../rules/ShowPolicyVerifiedReminder";
import {showPendingCancellationReminder} from "../rules/ShowPendingCancellationReminder";
import {showRenewalsAwaitingInviteReminder} from "../rules/renewals/ShowRenewalsAwaitingInviteReminder";
import {showRenewalOfferedReminder} from "../rules/renewals/ShowRenewalOfferedReminder";
import "shared/dist/extensions/ArrayExtensions";

export type ShowReminderCallback = (policy?: Policy) => Promise<boolean>;

interface ReminderRule {
  rule: ShowReminderCallback;
  type: ReminderType;
}

const reminderRules: ReminderRule[] = [
  {
    rule: showUnverifiedEmailAddressReminder,
    type: ReminderType.UNVERIFIED_EMAIL_ADDRESS
  },
  {
    rule: showUnverifiedPhoneNumberReminder,
    type: ReminderType.UNVERIFIED_PHONE_NUMBER
  },
  {
    rule: showUploadDocumentsReminder,
    type: ReminderType.UPLOAD_DOCUMENTS
  },
  {
    rule: showReviewIsInProgressReminder,
    type: ReminderType.REVIEW_IN_PROGRESS
  },
  {
    rule: showWithCustomerReminder,
    type: ReminderType.WITH_CUSTOMER
  },
  {
    rule: showPolicyVerifiedReminder,
    type: ReminderType.POLICY_VERIFIED
  },
  {
    rule: showPendingCancellationReminder,
    type: ReminderType.PENDING_CANCELLATION
  },
  {
    rule: showRenewalsAwaitingInviteReminder,
    type: ReminderType.RENEWALS_AWAITING_INVITE
  },
  {
    rule: showRenewalOfferedReminder,
    type: ReminderType.RENEWAL_OFFERED
  }
];

export const updateVisibleReminderTypes = (): AppThunk => async (dispatch, getState) => {
  const reminders: ReminderType[] = [];
  const policyList = getState().portal.policyList;

  if (!policyList) return;

  if (policyList.upcoming) {
    await policyList.upcoming.forEachAsync(async (policy) => {
      const upcomingPolicyReminders = await getRemindersToDisplay(policy);
      reminders.push(...upcomingPolicyReminders);
    });
  }

  if (policyList.active) {
    await policyList.active.forEachAsync(async (activePolicy) => {
      const activePolicyReminders = await getRemindersToDisplay(activePolicy);
      const newReminders = activePolicyReminders.filter(item => reminderNotAlreadyInList(reminders, item));
      reminders.push(...newReminders);
    });
  }

  const hasUploadedDocuments = getState().portal.documentUploaded;
  const updatedVisibleReminderTypes = hasUploadedDocuments && reminders.includes(ReminderType.UPLOAD_DOCUMENTS)
    ? getUpdatedVisibleReminderTypes(reminders)
    : reminders;

  dispatch(visibleReminderTypesUpdated(updatedVisibleReminderTypes));
};

const reminderNotAlreadyInList = (reminders: ReminderType[], item: ReminderType): boolean => reminders.indexOf(item) < 0;

async function getRemindersToDisplay(policy: Policy | undefined): Promise<ReminderType[]> {
  const results = await Promise.all(reminderRules.map(async reminderRule => {
    const shouldShowReminder = await reminderRule.rule(policy);
    if (!shouldShowReminder) return undefined;

    return reminderRule.type;
  }));

  return results.filter((reminder): reminder is ReminderType => reminder !== undefined);
}

const getUpdatedVisibleReminderTypes = (reminders: ReminderType[]): ReminderType[] => {
  const updatedVisibleReminderTypes = reminders.filter(reminder => reminder !== ReminderType.UPLOAD_DOCUMENTS);
  updatedVisibleReminderTypes.push(ReminderType.REVIEW_IN_PROGRESS);

  return updatedVisibleReminderTypes;
};
