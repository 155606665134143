import React, {FC} from "react";
import DropdownQuestion from "../../../../../../structure/questions/question/variants/dropdown-question/DropdownQuestion";
import {FormField} from "shared-components/dist/models/form-field/FormField";
import EmploymentJobAndIndustryQuestions, {EmployedQuestionsDisplayProps} from "./components/EmploymentJobAndIndustryQuestions";
import UnemployedSubQuestions, {UnemployedQuestionsDisplayProps} from "./components/UnemployedSubQuestions";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {EMPLOYMENT_STATUS_OPTIONS, EmploymentStatusOption} from "./models/EmploymentStatusOption";
import {ConditionalFormField} from "shared-components/dist/models/form-field/ConditionalFormField";
import {CustomisedAbiListItem} from "shared-components/dist/models/CustomisedAbiListItem";
import {ConditionalBooleanFormField} from "shared-components/dist/models/form-field/variants/BooleanFormField";
import SubQuestionForm from "../../../../../../structure/questions/sub-question-form/SubQuestionForm";

interface OwnProps {
  id: string;
  title: TranslationKey;
  placeholder: TranslationKey;
  employmentStatus: FormField<EmploymentStatusOption>;
  employmentStatusChanged: (selection: string) => void;
  employedQuestionsActive: boolean;
  unemployedQuestionsActive: boolean;
  industry: ConditionalFormField<CustomisedAbiListItem>;
  job: ConditionalFormField<CustomisedAbiListItem>;
  workedInPastYear: ConditionalBooleanFormField;
  industryChanged: (value: CustomisedAbiListItem) => void;
  jobChanged: (value: CustomisedAbiListItem) => void;
  workedInPastYearChanged: (value: boolean) => void;
  employedQuestions: EmployedQuestionsDisplayProps;
  unemployedQuestions: UnemployedQuestionsDisplayProps;
}

const PrimaryEmploymentQuestion: FC<OwnProps> = (
  {
    id,
    title,
    placeholder,
    employmentStatus,
    employmentStatusChanged,
    employedQuestionsActive,
    unemployedQuestionsActive,
    industry,
    job,
    workedInPastYear,
    industryChanged,
    jobChanged,
    workedInPastYearChanged,
    employedQuestions,
    unemployedQuestions,
  }
) => (
  <>
    <DropdownQuestion
      id={id}
      title={title}
      status={employmentStatus.status}
      errorMessage={employmentStatus.errorMessage}
      value={employmentStatus.value?.description}
      onChange={employmentStatusChanged}
      options={EMPLOYMENT_STATUS_OPTIONS}
      placeholder={placeholder}
    >
    </DropdownQuestion>
    {(employedQuestionsActive || unemployedQuestionsActive) && <SubQuestionForm>
      {employedQuestionsActive &&
        <EmploymentJobAndIndustryQuestions
          idPrefix={id}
          industry={industry}
          job={job}
          industryChanged={industryChanged}
          jobChanged={jobChanged}
          {...employedQuestions}
        />}

      {unemployedQuestionsActive && (
        <UnemployedSubQuestions
          idPrefix={id}
          job={job}
          workedInPastYear={workedInPastYear}
          jobChanged={jobChanged}
          workedInPastYearChanged={workedInPastYearChanged}
          {...unemployedQuestions}
        />
      )}
    </SubQuestionForm>
    }
  </>
);

export default PrimaryEmploymentQuestion;