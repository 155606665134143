import React, {FC} from "react";
import "./SectionHeader.css";
import Title from "shared-components/dist/title/Title";
import {Status} from "shared-components/dist/status-indicator/Status";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import Tooltip, {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import SectionSubtitle from "./section-subtitle/SectionSubtitle";
import ErrorHeader from "shared-components/dist/questions/error-header/ErrorHeader";

interface Props {
  title: TranslationKey;
  description?: TranslationKey;
  descriptionListItems?: TranslationKey[];
  status?: Status;
  htmlFor?: string;
  errorMessage?: TranslationKey;
  tooltip?: TooltipProps;
}

const SectionHeader: FC<Props> = ({
  children,
  title,
  description,
  descriptionListItems,
  status= "default",
  htmlFor,
  errorMessage,
  tooltip
}) => (
  <div className="section-header">
    {["error", "warning"].includes(status) && errorMessage &&
      <ErrorHeader title={errorMessage} status={status}/>
    }
    <Title title={title} status={status} htmlFor={htmlFor}/>
    {description && <SectionSubtitle value={description}/>}
    {descriptionListItems && (
      <ul className="section-header__description-list">
        {descriptionListItems.map((descriptionTranslationKey, index) => (
          <li key={index} className={"section-header__description-list__item"}>
            <SectionSubtitle value={descriptionTranslationKey}/>
          </li>
        ))}
      </ul>
    )}
    {tooltip && <Tooltip {...tooltip}/>}
    {children}
  </div>
);

export default SectionHeader;
