import {
  hasMedicalConditionChanged,
  medicalConditionChanged
} from "../../../../pages/quote/vehicle/your-details/redux/PersonalDetailsSlice";
import {SectionQuestionsRecord} from "../../Questions";

export const medicalCondition = {
  hasMedicalCondition: {
    selector: state => state.personalDetails.hasMedicalCondition,
    onChange: hasMedicalConditionChanged
  },
  medicalCondition: {
    selector: state => state.personalDetails.medicalCondition,
    onChange: medicalConditionChanged
  },
} as const satisfies SectionQuestionsRecord;