import React, {FC, useState} from "react";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import Button from "shared-components/dist/buttons/button/Button";
import RetrieveQuoteModal from "../../modals/retrieve-quote/RetrieveQuoteModal";
import {logNavigationGoogleAnalyticsEvent} from "../../../utils/analytics/NavigationAnalytics";

const RetrieveQuoteHeaderButton: FC = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const onClick = (): void => {
    logNavigationGoogleAnalyticsEvent({
      categorySuffix: "retrieve_a_quote",
      action: "Click"
    });
    setModalOpen(true);
  };

  return (
    <>
      <div className="retrieve-quote-header-button">
        <Button
          variant="link"
          onClick={onClick}
        >
          {lookupI18nString("structure.buttons.viewSavedQuotesButton")}
        </Button>
      </div>
      <RetrieveQuoteModal visible={modalOpen} dismiss={() => setModalOpen(false)}/>
    </>
  );
};

export default RetrieveQuoteHeaderButton;
