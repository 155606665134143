import React, {FC} from "react";
import PortalHeader from "../shared/portal-header/PortalHeader";
import "./AnnualisedBreakdownPage.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import AnnualisedBreakdownCard from "./components/annualised-breakdown-card/AnnualisedBreakdownCard";
import ContentWithoutSidebarLayout
  from "../../../structure/layouts/content-without-sidebar-layout/ContentWithoutSidebarLayout";
import {usePolicyQueryParams} from "../../../router/params/Params";
import PolicyLoading from "../policies/loading/PolicyLoading";
import {useFetchMidTermAdjustmentMetadataFor} from "shared/dist/graphql/queries/portal/MidTermAdjustmentMetadataQuery";
import AnnualisedBreakdownError from "./components/annualised-breakdown-error/AnnualisedBreakdownError";
import GoBackHeader from "../renewals/components/go-back-header/GoBackHeader";
import {useAppDispatch, useAppSelector} from "../../../redux/Hooks";
import {useEffectOnFirstRender} from "shared-components/dist/utils/hooks/UseEffectOnFirstRender";
import {selectActiveRenewalNotice} from "../renewals/redux/RenewalSelectors";
import {loadRenewalSummary} from "../renewals/redux/thunks/LoadRenewalSummary";

const AnnualisedBreakdownPage: FC = () => {
  const dispatch = useAppDispatch();
  const loadRenewalNoticeStatus = useAppSelector(state => state.renewal.loadRenewalSummaryStatus);
  const activeRenewalNotice = useAppSelector(selectActiveRenewalNotice);
  const {policyId} = usePolicyQueryParams();
  const mtaMetadataQuery = useFetchMidTermAdjustmentMetadataFor(policyId);

  useEffectOnFirstRender(() => {
    if (activeRenewalNotice) return;
    dispatch(loadRenewalSummary({policyId}));
  });

  if (mtaMetadataQuery.loading || loadRenewalNoticeStatus === "loading") return <PolicyLoading/>;

  if (mtaMetadataQuery.error || loadRenewalNoticeStatus === "error" || !activeRenewalNotice) {
    return <AnnualisedBreakdownError/>;
  }

  return (
    <ContentWithoutSidebarLayout
      className="annualised-breakdown-page"
      header={
        <>
          <PortalHeader/>
          <GoBackHeader text={"portal.renewals.annualised.breakdown.backToQuote"}/>
        </>
      }
    >
      <div className="annualised-breakdown-page__breakdown">
        <h1>{lookupI18nString("portal.renewals.annualised.breakdown.title")}</h1>
        <p>{lookupI18nString("portal.renewals.annualised.breakdown.description")}</p>

        <AnnualisedBreakdownCard
          midTermAdjustmentMetadata={mtaMetadataQuery.data?.midTermAdjustmentMetadata ?? []}
          renewalNotice={activeRenewalNotice}
        />
      </div>
    </ContentWithoutSidebarLayout>
  );
};

export default AnnualisedBreakdownPage;