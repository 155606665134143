import {FC} from "react";
import Button from "shared-components/dist/buttons/button/Button";
import "./HelpCard.css";
import {openZendeskChat} from "../../../../../zendesk/Zendesk";
import {Comment} from "@mui/icons-material";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";

const HelpCard: FC = () => (
  <div className="help-card" data-testid="help-card">
    <p className="help-card__title">
      {lookupI18nString("portal.renewals.summary.helpCard.title")}
    </p>

    <p className="help-card__description">
      {lookupI18nString("portal.renewals.summary.helpCard.description")}
    </p>

    <Button
      variant="ghost"
      onClick={openZendeskChat}
      data-testid="help-card__chat-button"
    >
      <Comment/>
      {lookupI18nString("portal.renewals.summary.helpCard.button")}
    </Button>

    <p className="help-card__subtitle">
      {lookupI18nString("portal.renewals.summary.helpCard.talkToOurTeam")}
    </p>

    <a className="help-card__phone-number" href={`tel:${lookupI18nString("portal.renewals.summary.helpCard.phoneNumber")}`}>
      {lookupI18nString("portal.renewals.summary.helpCard.phoneNumber")}
    </a>
  </div>
);

export default HelpCard;