import React, {FC} from "react";
import {shouldShowAdditionalDetailsLeftHandDriveVehicleStopSelector} from "../../../../redux/selectors/VehiclePageStateSelectors";
import LeftHandDriveVehicleStop
  from "../../../../../shared/stops/left-hand-drive-vehicle-stop/LeftHandDriveVehicleStop";
import {useAppSelector} from "../../../../../../../../redux/Hooks";

const AdditionalDetailsLeftHandDriveVehicleStop: FC = () => {
  const isVisible = useAppSelector(shouldShowAdditionalDetailsLeftHandDriveVehicleStopSelector);

  return (
    <LeftHandDriveVehicleStop
      isVisible={isVisible}
      isSubContainer
    />
  );
};

export default AdditionalDetailsLeftHandDriveVehicleStop;
