import {FC} from "react";
import "./RenewalDetailsConfirmationRightDetailTableItem.css";

interface Props {
  title: string;
  subtitle: string;
}

const RenewalDetailsConfirmationRightDetailTableItem: FC<Props> = ({title, subtitle}) => (
  <div className="renewal-details-confirmation-right-detail-table-item">
    <h4 data-testid="renewal-details-confirmation-right-detail-table-item__title">{title}</h4>
    <p data-testid="renewal-details-confirmation-right-detail-table-item__subtitle">{subtitle}</p>
  </div>
);

export default RenewalDetailsConfirmationRightDetailTableItem;