import React, {FC} from "react";
import {firstNameChanged, firstNameInvalid} from "../../redux/UpdateAdditionalDriverSlice";
import FirstNameQuestion from "../../../../shared/questions/first-name/FirstNameQuestion";
import {hasValidName} from "shared-components/dist/form/name-input-field/validation/NameValidator";
import {logAdditionalDriverGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const AdditionalDriverFirstNameQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const firstNameField = useAppSelector(state => state.updateAdditionalDriver.firstName);

  const onFirstNameChanged = (value: string): void => {
    logAdditionalDriverGoogleAnalyticsEvent({
      categorySuffix: "first_name",
      action: "Answered",
    });

    dispatch(firstNameChanged(value));
  };

  return (
    <FirstNameQuestion
      id="additional-driver-first-name"
      title="additionalDriver.firstNameQuestion.title"
      firstNameField={firstNameField}
      onComplete={onFirstNameChanged}
      onInvalid={errorMessage => dispatch(firstNameInvalid(errorMessage))}
      validators={[
        hasValidName("additionalDriver.firstNameQuestion.errors.invalidEntry")
      ]}
      placeholder="additionalDriver.firstNameQuestion.placeholder"
    />
  );
};

export default AdditionalDriverFirstNameQuestion;