export const QUOTE_CONVERSION_DESTINATION = "AW-10817202611/vqTfCOfJvdEDELPThaYo";
export const PURCHASE_CONVERSION_DESTINATION = "AW-10817202611/ulRVCL6LjtEDELPThaYo";

declare global {
  interface Window {
    gtag?: Gtag.Gtag;
  }
}

export interface GoogleTagConversionEventParams {
  sendTo: typeof QUOTE_CONVERSION_DESTINATION | typeof PURCHASE_CONVERSION_DESTINATION;
  emailAddress: string;
  phoneNumber: string;
}

export interface GoogleTagConversionPurchaseEventParams extends GoogleTagConversionEventParams {
  transactionId: string;
  value: string;
  currency: string;
}

export function emitGoogleTagManagerEvent(googleTagConversionEvent: GoogleTagConversionEventParams): void {
  window.gtag("set", "user_data", {
      email: googleTagConversionEvent.emailAddress,
      phone_number: googleTagConversionEvent.phoneNumber
    });
  window.gtag("event", "conversion", {
    send_to: window.Stroll.IS_PRODUCTION ? googleTagConversionEvent.sendTo: ""
  });
}

export function emitGoogleTagManagerPurchaseEvent(googleTagPurchaseConversionEvent: GoogleTagConversionPurchaseEventParams): void {
  window.gtag("set", "user_data", {
    email: googleTagPurchaseConversionEvent.emailAddress,
    phone_number: googleTagPurchaseConversionEvent.phoneNumber
  });
  window.gtag("event", "conversion", {
    send_to: window.Stroll.IS_PRODUCTION ? googleTagPurchaseConversionEvent.sendTo : "",
    transaction_id: googleTagPurchaseConversionEvent.transactionId,
    value: googleTagPurchaseConversionEvent.value,
    currency: googleTagPurchaseConversionEvent.currency
  });
}