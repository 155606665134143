import {gql} from "@apollo/client";
import {apolloClient} from "../../../../graphql/apollo/ApolloClientProvider";
import {Query, UploadedDocumentType} from "shared/dist/generated/graphql/resolvers-types";
import {noticeError} from "../../../../newrelic/NoticeError";
import {logger} from "../../../../utils/logging/Logger";

export type DocumentUploadPresignedUrlResult = Pick<Query, "generatePolicyHolderDocumentUploadPresignedUrl">;

const GENERATE_UPLOAD_PRESIGNED_URL_QUERY = gql`
  query GeneratePresignedUrlForUpload($policyId: String!, $documentType: UploadedDocumentType!) {
    generatePolicyHolderDocumentUploadPresignedUrl(policyId: $policyId, documentType: $documentType) {
      url
    }
  }
`;

export async function generateDocumentUploadPresignedUrl(policyId: string, documentType: UploadedDocumentType): Promise<string> {
  try {
    const result = await apolloClient.query<DocumentUploadPresignedUrlResult>({
      query: GENERATE_UPLOAD_PRESIGNED_URL_QUERY,
      fetchPolicy: "no-cache",
      variables: {
        policyId,
        documentType
      }
    });

    if (!result.data?.generatePolicyHolderDocumentUploadPresignedUrl?.url) {
      throw new Error("Unable to generate document upload presigned url");
    }

    return result.data.generatePolicyHolderDocumentUploadPresignedUrl.url;
  } catch (error) {
    logger.error("Failed to generate presigned url", error);
    await noticeError(error);
    throw error;
  }
}
