import React, {FC} from "react";
import "./CoverSummary.css";
import SummaryList from "shared-components/dist/lists/summary-list/SummaryList";
import {castToCoverType} from "../../../../../quote/vehicle/your-cover/components/cover-type/models/CoverType";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {usePolicyOverviewList} from "./hooks/UsePolicyOverviewList";
import {Policy} from "shared/dist/generated/graphql/resolvers-types";

interface OwnProps {
  policy: Policy;
}

const CoverSummary: FC<OwnProps> = ({policy}) => {
  const policyOverviewListItems = usePolicyOverviewList({
      coverType: castToCoverType(policy.coverDetails.coverType),
      noClaimsProtection: policy.coverDetails.noClaimsProtection
    }
  );

  return (
    <div className="cover-summary">
      <div className="cover-summary__title">
        <h3>{lookupI18nString("policies.mainContent.cover.title")}</h3>
      </div>

      <SummaryList
        items={policyOverviewListItems}
      />
    </div>
  );
};

export default CoverSummary;
