import React, {FC} from "react";
import ErrorPage from "../shared/error/ErrorPage";
import Navbar from "../../../structure/navigation/navbar/Navbar";
import {useEffectOnFirstRender} from "shared-components/dist/utils/hooks/UseEffectOnFirstRender";
import {noticeError} from "../../../newrelic/NoticeError";

const PolicyErrorPage: FC = () => {
  useEffectOnFirstRender(() => {
    noticeError(new Error("Error occurred loading policy for portal."));
  });

  return (
    <ErrorPage
      title={"portal.error.page.title"}
      description={"portal.error.page.description"}
      header={<Navbar/>}
    />
  );
};

export default PolicyErrorPage;
