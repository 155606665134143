import {FC} from "react";
import {
  useQuotePriceProps
} from "../../../../../../quote/vehicle/shared/quote-summary/components/quote-price-card/hooks/UseQuotePricesProps";
import {useAppSelector} from "../../../../../../../redux/Hooks";
import {
  selectedProspectiveLoanWithPriceSelector,
  selectQuoteDepositPercentageSelector
} from "../../../../../../quote/vehicle/your-quote/redux/selectors/QuoteDetailsSelectors";
import FinancePaymentSummaryCard from "../FinancePaymentSummaryCard";

const QuoteFinancePaymentSummaryCard: FC = () => {
  const {adminFee, totalExtrasAmount, premiumAmount} = useQuotePriceProps();
  const depositPercentage = useAppSelector(selectQuoteDepositPercentageSelector);
  const selectedProspectiveLoan = useAppSelector(selectedProspectiveLoanWithPriceSelector);

  return <FinancePaymentSummaryCard
    depositPercentage={depositPercentage}
    adminFee={adminFee}
    totalExtrasAmount={totalExtrasAmount}
    premiumAmount={premiumAmount}
    selectedProspectiveLoan={selectedProspectiveLoan}
  />;
};

export default QuoteFinancePaymentSummaryCard;
