import {differenceInDays, endOfDay, isBefore} from "date-fns";

export const MAX_BREAK_IN_COVER_THRESHOLD = 14;
export const MAX_BREAK_IN_COVER_ALLOWED = 1;

export type BreakInCover = "CANNOT_DETECT" | "NO_BREAK_IN_COVER" | "DECLARATION_REQUIRED" | "CANNOT_INSURE_ONLINE";

export interface BreakInCoverProps {
  carPurchaseDate: Date | undefined;
  previousCoverExpiryDate: Date | undefined;
  coverStartDate: Date | undefined;
  hasPreviouslyBeenInsured: boolean | undefined;
  vehiclePreviousPolicyExpiryDate: Date | undefined;
}

export function detectBreakInCover(
  {
    carPurchaseDate,
    previousCoverExpiryDate,
    coverStartDate,
    hasPreviouslyBeenInsured,
    vehiclePreviousPolicyExpiryDate,
  }: BreakInCoverProps
): BreakInCover {
  if (!carPurchaseDate || !coverStartDate || hasPreviouslyBeenInsured === undefined) return "CANNOT_DETECT";

  if (vehiclePreviousPolicyExpiryDate) return checkPolicyExpiryDateBreakInCover(coverStartDate, vehiclePreviousPolicyExpiryDate, carPurchaseDate);

  if (!hasPreviouslyBeenInsured) return checkForBreakInCover(coverStartDate, endOfDay(carPurchaseDate));

  if (!previousCoverExpiryDate) return "CANNOT_DETECT";

  return checkPolicyExpiryDateBreakInCover(coverStartDate, previousCoverExpiryDate, carPurchaseDate);
}

function checkPolicyExpiryDateBreakInCover(coverStartDate: Date, policyExpiryDate: Date, carPurchaseDate: Date): BreakInCover {
  return isBefore(policyExpiryDate, carPurchaseDate)
    ? checkForBreakInCover(coverStartDate, endOfDay(carPurchaseDate))
    : checkForBreakInCover(coverStartDate, policyExpiryDate);
}

function checkForBreakInCover(coverStartDate: Date, target: Date): BreakInCover {
  const difference = differenceInDays(coverStartDate, target);

  if (difference < MAX_BREAK_IN_COVER_ALLOWED) return "NO_BREAK_IN_COVER";
  if (difference < MAX_BREAK_IN_COVER_THRESHOLD) return "DECLARATION_REQUIRED";

  return "CANNOT_INSURE_ONLINE";
}