import {
  AdditionalVehicleDetailsFormQuestions
} from "../../../../../pages/quote/vehicle/your-vehicle/redux/AdditionalVehicleDetailsFormQuestions";
import {ImportType} from "./ImportType";
import {DriverPosition} from "./DriverPosition";
import {Maybe} from "graphql/jsutils/Maybe";
import {
  AlarmInstallerOption, VEHICLE_ALARM_INSTALLER_OPTIONS
} from "../../../../../pages/quote/vehicle/shared/questions/vehicle-alarm/VehicleAlarmInstallerOptions";
import {AlarmInstaller} from "shared/dist/generated/graphql/resolvers-types";

export interface VehicleSummary {
  abi: string;
  make: string;
  model: string;
  trim: string;
  bodyStyle: string;
  engineCapacityCc?: string;
  engineCapacityLitres?: Maybe<string>;
  fuelType: string;
  transmissionType: string;
  yearOfManufacture: string;
}

export interface AdditionalVehicleDetails {
  driverPosition?: DriverPosition;
  doors?: number;
  seats?: number;
  hasTracker?: boolean;
  hasAlarm?: boolean;
  alarmFittedBy?: AlarmInstallerOption;
  imported?: boolean;
  importType?: ImportType;
  estimatedValue?: number;
}

export interface Vehicle extends VehicleSummary, AdditionalVehicleDetails {
  commercialVehicle: boolean;
  registration?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isValidVehicleSummary = (object: any): object is Required<VehicleSummary> => {
  return [
    object?.make,
    object?.model,
    object?.trim,
    object?.bodyStyle,
    object?.fuelType,
    object?.transmissionType,
    object?.yearOfManufacture,
  ].every(it => it != null);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isValidAdditionalVehicleDetails = (object: any): object is Required<AdditionalVehicleDetails> => {
  const importedValid = hasValidImportStatus(object?.imported, object?.importType);

  return ([
    object?.driverPosition,
    object?.seats,
    object?.doors,
    object?.estimatedValue
  ].every(it => it != null) && importedValid);
};

export const isValidAdditionalVehicleDetailsQuestions = (additionalVehicleDetails: AdditionalVehicleDetailsFormQuestions): boolean => {
  const importedValid = hasValidImportStatus(additionalVehicleDetails.imported.value, additionalVehicleDetails.importType.value);

  const seatsValid = additionalVehicleDetails.seats.value !== 0;

  return importedValid &&
    seatsValid &&
    ([
      additionalVehicleDetails.driverPosition.value,
      additionalVehicleDetails.seats.value,
      additionalVehicleDetails.estimatedValue.value
    ].every(it => it != null));
};

const hasValidImportStatus = (imported?: boolean, importType?: ImportType): boolean => {
  return imported === false || (imported === true && importType != null);
};

export const withDefaultAssumptions = (vehicle: Vehicle): Vehicle => {
  return {
    ...vehicle,
    hasTracker: false,
    hasAlarm: true,
    alarmFittedBy: {
     id: AlarmInstaller.Manufacturer,
     description: VEHICLE_ALARM_INSTALLER_OPTIONS[AlarmInstaller.Manufacturer]
    }
  };
};
