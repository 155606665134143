import React, {FC} from "react";
import ErrorPage from "../portal/shared/error/ErrorPage";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import Button from "shared-components/dist/buttons/button/Button";
import {useNavigate} from "react-router-dom";
import {quoteSteps} from "../quote/vehicle/shared/quote-step/QuoteSteps";

const NotFound: FC = () => {
  const navigate = useNavigate();

  const redirectToStartOfQuoteFlow = (): void => navigate(quoteSteps.yourCar.routes.new);

  return (
    <ErrorPage
      title={"notFound.body.errorMessage.slowDown"}
      description={"notFound.body.errorMessage.takenAWrongTurn"}
    >
      <Button
        variant="primary"
        onClick={redirectToStartOfQuoteFlow}
        className="start-new-quote-button">
          {lookupI18nString("notFound.body.button.startANewQuote")}
      </Button>
    </ErrorPage>
  );
};

export default NotFound;
