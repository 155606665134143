import React, {FC, FormEvent, MouseEvent, useEffect, useState} from "react";
import RegistrationNumberInputField from "./registration-number-input-field/RegistrationNumberInputField";
import Question from "../../../../../../structure/questions/question/Question";
import "./RegistrationNumberLookup.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {
  Vehicle,
  withDefaultAssumptions
} from "../../../../../../graphql/queries/vehicle-lookup/registration-lookup/model/Vehicle";
import {getVehicleByRegistrationNumber} from "../../../../../../graphql/queries/vehicle-lookup/registration-lookup/VehicleLookup";
import Button from "shared-components/dist/buttons/button/Button";
import {isProhibitedFuelType} from "../../redux/selectors/ManualVehicleLookupFormSelectors";
import {vehicleDetailsReset, vehicleLookupReceived} from "../../../../../../redux/Actions";
import {
  registrationNumberLookupChanged,
  registrationNumberLookupInvalid,
  registrationNumberLookupStatusChanged
} from "../../redux/VehicleDetailsSlice";
import {useQueryParams} from "../../../../../../utils/navigation/GetQueryParams";
import {logYourCarGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {
  formatRegistrationNumber, isIsleOfManRegistration,
  isQPlateRegistration,
  isValidRegistrationNumber
} from "../../../../../../utils/validation/RegistrationNumber";
import {handleVehicleQueryError} from "./HandleVehicleQueryError";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const RegistrationNumberLookup: FC = () => {
  const dispatch = useAppDispatch();
  const registrationNumberLookup = useAppSelector(state => state.vehicleDetails.registrationNumberLookup);

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const registrationNumberParam = useQueryParams("registrationNumber");

  useEffect(() => {
    if (registrationNumberParam) {
      submitRegistrationNumber(registrationNumberParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationNumberParam]);

  const onSubmit = (event: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    dispatch(vehicleDetailsReset());
    submitRegistrationNumber(registrationNumberLookup.value);
    logYourCarGoogleAnalyticsEvent({
      categorySuffix: "search_by_registration_number",
      action: "Click"
    });
  };

  const submitRegistrationNumber = (registrationNumber: string | undefined): void => {
    if (!registrationNumber) {
      dispatch(registrationNumberLookupInvalid("quote.errors.questionIncomplete"));
      return;
    }

    if (isQPlateRegistration(registrationNumber)) {
      handleError("vehicleQuote.registrationLookup.errors.validation.qRegistration");
    } else if (!isValidRegistrationNumber(registrationNumber)) {
      handleError("vehicleQuote.registrationLookup.errors.validation.invalidRegistrationNumber");
    } else if (isIsleOfManRegistration(registrationNumber)) {
      handleError("vehicleQuote.registrationLookup.errors.validation.invalidRegistrationNumber.isleOfManRegistration");
    } else {
      requestRegisteredVehicle(registrationNumber);
    }

    dispatch(registrationNumberLookupChanged(formatRegistrationNumber(registrationNumber)));
  };

  const requestRegisteredVehicle = (registrationNumber: string): void => {
    dispatch(registrationNumberLookupStatusChanged("loading"));
    getVehicleByRegistrationNumber(registrationNumber)
      .then(handleResponse)
      .catch(error => handleVehicleQueryError(error.message, handleError));
  };

  const handleResponse = (response: Vehicle): void => {
    const vehicle = withDefaultAssumptions(response);
    if (vehicle.commercialVehicle) {
      handleError("vehicleQuote.registrationLookup.errors.validation.commercialVehicle");
    } else if (isProhibitedFuelType(vehicle.fuelType)) {
      handleError("vehicleQuote.registrationLookup.errors.validation.prohibitedFuelType");
    } else {
      handleVehicleReceived(vehicle);
    }
  };

  const handleError = (errorMessage: TranslationKey): void => {
    dispatch(registrationNumberLookupInvalid(errorMessage));
    setButtonDisabled(true);
  };

  const handleVehicleReceived = (vehicle: Vehicle): void => {
    dispatch(vehicleLookupReceived(vehicle));
    setButtonDisabled(false);
  };

  return (
    <div className="registration-number-lookup">
      <form
        className={`registration-number-lookup__form registration-number-lookup__form--${registrationNumberLookup.status}`}
        onSubmit={onSubmit}
      >
        <Question
          title="vehicleQuote.registrationLookup.questionTitle"
          status={registrationNumberLookup.status}
          id="registration-number-lookup"
          errorMessage={registrationNumberLookup.errorMessage}
          hideSpacer
        >
          <div className="registration-lookup-row">
            <RegistrationNumberInputField
              registrationNumber={registrationNumberLookup.value ?? ""}
              onChange={value => {
                dispatch(registrationNumberLookupChanged(value));
                setButtonDisabled(false);
              }}
              placeholder="vehicleQuote.registrationLookup.placeholder"
              loading={registrationNumberLookup.status === "loading"}
            />
            <Button
              disabled={buttonDisabled}
              onClick={onSubmit}
              variant="secondary"
              data-test-id="reg-number-submit-button"
            >
              {lookupI18nString("vehicleQuote.registrationLookup.submitButton")}
            </Button>
          </div>
        </Question>
      </form>
    </div>
  );
};

export default RegistrationNumberLookup;
