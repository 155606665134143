import {gql} from "@apollo/client";
import {apolloClient} from "../../apollo/ApolloClientProvider";
import {Result} from "shared/dist/generated/graphql/resolvers-types";

export const EMAIL_CONFIRM_PASSWORD_CHANGE = gql`
  mutation EmailConfirmPasswordChange {
    policyHolder {
      emailConfirmPasswordChange
    }
  }
`;

export async function sendPasswordChangedEmail(): Promise<Result> {
  return new Promise<Result>((resolve, reject) => {
    apolloClient.mutate<Result>({
      mutation: EMAIL_CONFIRM_PASSWORD_CHANGE,
    })
      .then(result => resolve(result.data ?? Result.Failure))
      .catch(error => reject(error));
  });
}
