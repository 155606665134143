import {apolloClient} from "../../../apollo/ApolloClientProvider";
import {SavedQuote} from "../../../../pages/quote/vehicle/your-quote/models/SavedQuote";
import {gql} from "@apollo/client";
import {LoadedQuote} from "../../../../pages/quote/vehicle/your-quote/models/LoadedQuote";
import {logger} from "../../../../utils/logging/Logger";
import {LoadQuoteResult} from "shared/dist/generated/graphql/resolvers-types";

interface LoadQuoteQueryVariables {
  magicLinkId: string;
  quoteReference: string;
  sequenceNumber: number;
}

export interface LoadQuotesQueryResult {
  quote: {
    loadQuotes: LoadQuoteResult
  }
}

const LOAD_QUOTES_QUERY = gql`
  query LoadQuotes(
    $magicLinkId: String!,
    $quoteReference: String!,
    $sequenceNumber: Int!
  ) {
    quote {
      loadQuotes(
        magicLinkId: $magicLinkId, 
        quoteReference: $quoteReference, 
        sequenceNumber: $sequenceNumber
      ) {
        serialisedQuote,
        result {
          reference,
          sequenceNumber,
          insurer,
          insurerSchemeReference,
          price,
          compulsoryExcess,
          voluntaryExcess
          magicLinkId
          quoteType,
          referredBy,
          premiumPriceBreakdown {
            originalPrice,
            discount,
            adminFee
          }
          compulsoryExcessAsPrice {
            amount
            currency
          }
          premiumAsPrice {
            amount
            currency
          }
          premiumPriceBreakdownDetails {
            originalPrice {
              amount
              currency
            }
            discount {
              amount
              currency
            }
            adminFee {
              amount
              currency
            }
          }
          totalExtrasPrice {
            amount
            currency
          }
          totalUnfinancedAmountPayable {
            amount
            currency
          }
          voluntaryExcessAsPrice {
            amount
            currency
          }
        },
        quoteGenerationDate
      }
    }
  }
`;

export async function loadQuotesQuery(magicLinkId: string, quoteReference: string, sequenceNumber: number): Promise<LoadedQuote | undefined> {
  try {
    const queryResult = await apolloClient.query<LoadQuotesQueryResult, LoadQuoteQueryVariables>({
      query: LOAD_QUOTES_QUERY,
      variables: {
        magicLinkId,
        quoteReference,
        sequenceNumber
      }
    });

    if (!queryResult || !queryResult.data || !queryResult.data.quote.loadQuotes) return undefined;

    const {serialisedQuote, result, quoteGenerationDate} = queryResult.data.quote.loadQuotes;

    if (result) {
      const parsedSerialisedQuote = JSON.parse(decodeURIComponent(serialisedQuote)) as SavedQuote;

      return {
        quoteArguments: parsedSerialisedQuote,
        price: result.price,
        insurer: result.insurer,
        insurerSchemeReference: result.insurerSchemeReference,
        reference: result.reference,
        sequenceNumber: result.sequenceNumber,
        compulsoryExcess: result.compulsoryExcess,
        voluntaryExcess: result.voluntaryExcess,
        magicLinkId: result.magicLinkId,
        quoteType: result.quoteType ?? undefined,
        quoteGenerationDate,
        premiumPriceBreakdown: result.premiumPriceBreakdown ?? undefined,
        insurerQuotes: parsedSerialisedQuote.insurerQuotes,
        totalExtrasPrice: result.totalExtrasPrice ?? undefined,
        premiumAsPrice: result.premiumAsPrice,
        totalUnfinancedAmountPayable: result.totalUnfinancedAmountPayable,
        voluntaryExcessAsPrice: result.voluntaryExcessAsPrice,
        compulsoryExcessAsPrice: result.compulsoryExcessAsPrice,
        premiumPriceBreakdownDetails: result.premiumPriceBreakdownDetails ?? undefined,
        isReferredBy: result.referredBy ?? undefined
      };
    }

    return undefined;
  } catch (error) {
    logger.error(`Failed to load quotes. MagicLinkId: [${magicLinkId}], Reference: [${quoteReference}, Sequence: [${sequenceNumber}]]`, error);
    return undefined;
  }
}
