import React, {FC} from "react";
import {
  convictionAdded,
  convictionRemoved,
  convictionStatusChanged,
  flagUnansweredConvictionsSubQuestions,
  hasConvictionsChanged,
  isConvictionsFormVisibleSet
} from "../../redux/PersonalDetailsSlice";
import {toSerializableConviction} from "../../../shared/questions/convictions/models/Conviction";
import ConvictionsQuestion from "../../../shared/questions/convictions/ConvictionsQuestion";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import {logYourDetailsGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {convictionsSelector} from "../../redux/selectors/PersonalDetailsSelectors";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const ProposerConvictionsQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const convictions = useAppSelector(convictionsSelector);
  const shouldFlagUnansweredQuestions = useAppSelector(state => state.personalDetails.convictions.value.shouldFlagUnansweredQuestions);
  const isFormVisible = useAppSelector(state => state.personalDetails.convictions.value.isFormVisible);

  const tooltip: TooltipProps = {
    title: "personalDetails.convictionsQuestions.hasConviction.tooltip.title",
    description: "personalDetails.convictionsQuestions.hasConviction.tooltip.description"
  };

  const onYesNoQuestionAnswered = (answer: boolean): void => {
    logYourDetailsGoogleAnalyticsEvent({
      categorySuffix: "motor_related_accidents",
      action: answer,
    });
    dispatch(hasConvictionsChanged(answer));
  };

  return (
    <ConvictionsQuestion
      id="proposer-convictions"
      title="personalDetails.convictionsQuestions.hasConviction.title"
      descriptionListItems={[
        "personalDetails.convictionsQuestions.hasConviction.description.motoringOrFixedPenalty",
        "personalDetails.convictionsQuestions.hasConviction.description.pendingProsecutions",
        "personalDetails.convictionsQuestions.hasConviction.description.drivingDisqualification"
      ]}
      tooltip={tooltip}
      convictions={convictions}
      hasConvictionsChanged={onYesNoQuestionAnswered}
      convictionAdded={conviction => dispatch(convictionAdded(toSerializableConviction(conviction)))}
      convictionRemoved={index => dispatch(convictionRemoved(index))}
      shouldFlagUnansweredQuestions={shouldFlagUnansweredQuestions}
      flagUnansweredSubQuestions={() => dispatch(flagUnansweredConvictionsSubQuestions())}
      isFormVisible={isFormVisible}
      isFormVisibleSet={value => dispatch(isConvictionsFormVisibleSet(value))}
      convictionStatusChanged={value => dispatch(convictionStatusChanged(value))}
    />
  );
};

export default ProposerConvictionsQuestion;
