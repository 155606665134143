import {
  hasSecondaryEmploymentInvalid,
  secondaryEmploymentIndustryInvalid,
  secondaryEmploymentJobInvalid,
  secondaryEmploymentStatusInvalid,
  secondaryEmploymentWorkedInPastYearInvalid
} from "../redux/UpdateAdditionalDriverSlice";
import {AppThunkDispatch} from "../../../../../../redux/Store";
import {isEmployed, isRetired, isUnemployed} from "../../../shared/validation/ValidateEmploymentStatus";
import {isValidFormField} from "shared-components/dist/models/form-field/FormField";
import {UpdateAdditionalDriver} from "../redux/UpdateAdditionalDriver";

export function flagUnansweredSecondaryEmploymentQuestions(updateAdditionalDriver: UpdateAdditionalDriver, dispatch: AppThunkDispatch): void {
  if (updateAdditionalDriver.hasSecondaryEmployment.status === "default") {
    dispatch(hasSecondaryEmploymentInvalid("quote.errors.questionIncomplete"));
  }

  if (!updateAdditionalDriver.secondaryEmployment.employmentStatus.isActive) return;

  if (updateAdditionalDriver.secondaryEmployment.employmentStatus.status === "default") {
    dispatch(secondaryEmploymentStatusInvalid("quote.errors.questionIncomplete"));
  } else if (isEmployed(updateAdditionalDriver.secondaryEmployment.employmentStatus.value)) {
    checkIndustryQuestion(updateAdditionalDriver, dispatch);
    checkJobQuestion(updateAdditionalDriver, dispatch);
  } else if (isRetired(updateAdditionalDriver.secondaryEmployment.employmentStatus.value)) {
    checkJobQuestion(updateAdditionalDriver, dispatch);
  } else if (isUnemployed(updateAdditionalDriver.secondaryEmployment.employmentStatus.value)) {
    checkUnemployedQuestions(updateAdditionalDriver, dispatch);
  }
}

function checkIndustryQuestion({secondaryEmployment}: UpdateAdditionalDriver, dispatch: AppThunkDispatch): void {
  if (!isValidFormField(secondaryEmployment.industry)) dispatch(secondaryEmploymentIndustryInvalid("quote.errors.questionIncomplete"));
}

function checkJobQuestion({secondaryEmployment}: UpdateAdditionalDriver, dispatch: AppThunkDispatch): void {
  if (!isValidFormField(secondaryEmployment.job)) dispatch(secondaryEmploymentJobInvalid("quote.errors.questionIncomplete"));
}

function checkUnemployedQuestions(updateAdditionalDriver: UpdateAdditionalDriver, dispatch: AppThunkDispatch): void {
  checkWorkedInPastYearQuestion(updateAdditionalDriver, dispatch);
  if (updateAdditionalDriver.secondaryEmployment.workedInPastYear.value) {
    checkJobQuestion(updateAdditionalDriver, dispatch);
  }
}

function checkWorkedInPastYearQuestion({secondaryEmployment}: UpdateAdditionalDriver, dispatch: AppThunkDispatch): void {
  if (!isValidFormField(secondaryEmployment.workedInPastYear)) dispatch(secondaryEmploymentWorkedInPastYearInvalid("quote.errors.questionIncomplete"));
}