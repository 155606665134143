import {RootState} from "../../../../../../redux/Store";
import {Vehicle, VehicleSummary} from "../../../../../../graphql/queries/vehicle-lookup/registration-lookup/model/Vehicle";
import {validatedAdditionalVehicleDetailsFromFormInput} from "./ValidatedAdditionalVehicleDetailsFromFormInput";

export function validatedVehicleSummaryFromManualLookup(state: RootState): VehicleSummary {
  if (!state.manualVehicleLookupForm.fullSpec.value || !state.manualVehicleLookupForm.yearOfManufacture.value) {
    throw Error("Unable to construct VehicleSummary from manual vehicle lookup form");
  }

  return {
    ...state.manualVehicleLookupForm.fullSpec.value,
    yearOfManufacture: state.manualVehicleLookupForm.yearOfManufacture.value,
  };
}

export function validatedVehicleFromManualLookup(state: RootState): Vehicle | undefined {
  const vehicleSummary = validatedVehicleSummaryFromManualLookup(state);
  const additionalVehicleDetails = validatedAdditionalVehicleDetailsFromFormInput(state.vehicleDetails, false);

  if (!vehicleSummary || !additionalVehicleDetails) return undefined;

  return {
    ...vehicleSummary,
    ...additionalVehicleDetails,
    commercialVehicle: false,
  };
}
