import React, {FC, ReactChild} from "react";
import "./ContentWithSidebarLayout.css";
import PageFooter from "../../footer/PageFooter";

interface Props {
  header: ReactChild;
  footer?: ReactChild;
  beforeContent?: ReactChild;
  mainContent: ReactChild;
  sidebar?: ReactChild;
  sidebarInfoPanel?: ReactChild;
  className?: string;
  isPortal?: boolean;
  isPageFooterVisible?: boolean;
  additionalContent?: ReactChild;
}

const ContentWithSidebarLayout: FC<Props> = (
  {
    header,
    footer,
    beforeContent,
    mainContent,
    sidebar,
    sidebarInfoPanel,
    className,
    isPortal,
    isPageFooterVisible = true,
    additionalContent
  }
) => (
  <div className={`content-with-sidebar ${className ? className : ""}`}>
    <div className={`content-with-sidebar__wrapper ${className ? `${className}__wrapper` : ""}`}>
      <div className="content-with-sidebar__header">
        {header}
      </div>

      <div className="content-with-sidebar__container__wrapper">
        <div className="content-with-sidebar__container__wrapper__background"></div>
        <div className="content-with-sidebar__container">
          {beforeContent}

          {additionalContent &&
            <div className="content-with-sidebar__container__navigate">
              {additionalContent}
            </div>
          }

          {sidebar &&
            <div className="content-with-sidebar__container__sidebar">
              {sidebar}
              {sidebarInfoPanel}
            </div>
          }

          <div className="content-with-sidebar__container__content">
            {mainContent}

            <div className="content-with-sidebar__footer--desktop">
              {footer}
            </div>
          </div>
        </div>
      </div>

      <div className="content-with-sidebar__footer--mobile">
        {footer}
      </div>
    </div>

    {isPageFooterVisible && <PageFooter isPortal={isPortal}/>}
  </div>
);

export default ContentWithSidebarLayout;