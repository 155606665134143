import {CountryOfIssue} from "shared/dist/generated/graphql/resolvers-types";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";

export const LICENCE_COUNTRIES_OF_ISSUE = [
  {
    id: CountryOfIssue.UnitedKingdom,
    description: lookupI18nString("personalDetails.licenceCountryOfIssueQuestion.answer.uk")
  },
  {
    id: CountryOfIssue.RepublicOfIreland,
    description: lookupI18nString("personalDetails.licenceCountryOfIssueQuestion.answer.roi")
  },
  {
    id: CountryOfIssue.Other,
    description: lookupI18nString("personalDetails.licenceCountryOfIssueQuestion.answer.other")
  }
] as const;

export type LicenceCountryOfIssue = typeof LICENCE_COUNTRIES_OF_ISSUE[number];

export const LICENCE_COUNTRIES_OF_ISSUE_OPTIONS = LICENCE_COUNTRIES_OF_ISSUE.map(country => country.description);
