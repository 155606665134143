import React, {FC, ReactChild, ReactChildren} from "react";
import SpinnerIcon from "../../spinner/spinner-icon/SpinnerIcon";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary" | "tertiary" | "ghost" | "pill" | "link" | "dark" | "header-light" | "header-dark" | "delete" | "cancel";
  expanded?: boolean;
  small?: boolean;
  rightIcon?: ReactChild | ReactChildren;
  leftIcon?: ReactChild | ReactChildren;
  loading?: boolean;
}

const Button: FC<Props> = ({
  type = "button",
  variant = "primary",
  expanded = false,
  small = false,
  rightIcon,
  leftIcon,
  loading = false,
  children,
  className,
  ...props
}) => {
  const getClassName = (): string => {
    let baseClassName = `button ${variant}`;

    if (expanded) baseClassName += " expanded";
    if (small) baseClassName += " small";
    if (children === undefined) baseClassName += " icon-only";
    if (rightIcon || leftIcon) baseClassName += " icon";
    if (className) baseClassName += ` ${className}`;

    return baseClassName;
  };

  return (
    <button
      {...props}
      type={type}
      className={getClassName()}
      disabled={props.disabled || loading}
    >
      {loading ? <SpinnerIcon/> :
        <>
          {leftIcon}
          <span>{children}</span>
          {rightIcon}
        </>
      }
    </button>
  );
};

export default Button;
