import {useSearchParams} from "../../../../utils/custom-hooks/SearchParams";

export interface ResetPasswordQueryParamsHook {
  email: string | undefined;
  code: string | undefined;
}

export function useResetPasswordQueryParams(): ResetPasswordQueryParamsHook {
  const params = useSearchParams();
  return {
    email: params.get("email") ?? undefined,
    code: params.get("code") ?? undefined
  };
}
