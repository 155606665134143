import {FC} from "react";
import {ReactComponent as Icon} from "../../../../../assets/icons/your-car.svg";
import AggregatorQuoteValidationFailureTable from "./AggregatorQuoteValidationFailureTable";
import {useAppSelector} from "../../../../../redux/Hooks";
import quoteValidationErrorSelectors
  from "../../../vehicle/your-quote/redux/quote-validation-errors/QuoteValidationErrorSelectors";
import {useInitiateResolveErrorFlowForSection} from "../../redux/InitiateResolveErrorFlowForSection";
import {quoteSteps} from "../../../vehicle/shared/quote-step/QuoteSteps";
import {
  validateAllVehicleDetailsQuestions
} from "../../../../../redux/questions/vehicle-details/VehicleDetailsQuestionRecord";

const YourCarQuoteValidationFailureTable: FC = () => {
  const {initiateResolveErrorFlow} = useInitiateResolveErrorFlowForSection({
    section: "VEHICLE_DETAILS",
    validationThunk: validateAllVehicleDetailsQuestions
  });
  const numberOfIssues = useAppSelector(state => quoteValidationErrorSelectors.selectQuoteValidationErrorsForVehicleDetails(state).length);

  if (numberOfIssues === 0) return null;

  return (
    <AggregatorQuoteValidationFailureTable
      id={"your-car-quote-validation-table"}
      title="aggregatorQuote.validationIssueTable.yourCar.title"
      description="aggregatorQuote.validationIssueTable.yourCar.description"
      icon={<Icon className={"icon"}/>}
      items={[{
        heading: {id: "aggregatorQuote.validationIssueTable.yourCar.tableItem.heading"},
        subHeading: {id: "aggregatorQuote.validationIssueTable.yourCar.tableItem.subHeading"},
        errorMessage: {
          id: "aggregatorQuote.validationIssueTable.yourCar.tableItem.errorMessage",
          values: {numberOfIssues}
        },
        onResolveClicked: () => initiateResolveErrorFlow(quoteSteps.yourCar.routes.new)
      }]}
    />
  );
};

export default YourCarQuoteValidationFailureTable;