import {
  FetchProspectiveLoansResponse,
  Price,
  QuoteExtra,
  RenewalDocuments,
  RenewalNotice,
} from "shared/dist/generated/graphql/resolvers-types";
import {loadRenewalProspectiveLoansQuery} from "../../../../../graphql/queries/portal/LoadRenewalProspectiveLoansQuery";
import {RenewalQuote} from "../../../../quote/vehicle/your-quote/models/RenewalQuote";
import {loadRenewalDocumentsQuery} from "../../../../../graphql/queries/portal/LoadRenewalDocumentsQuery";
import {logger} from "../../../../../utils/logging/Logger";

export interface RenewalQuoteValues {
  renewalQuote: RenewalQuote | undefined;
  extrasTotalAmount: Price | undefined;
}

export async function getRenewalQuoteValues(
  policyId: string,
  depositPercentage: number,
  policyExtras: QuoteExtra[],
  renewalNotice: RenewalNotice,
  insurerSchemeReference: string | undefined
): Promise<RenewalQuoteValues> {
  try {
    const prospectiveLoansResponse = await getProspectiveLoans(policyId, depositPercentage, policyExtras);

    if (!prospectiveLoansResponse) throw new Error(`Failed to fetch renewal prospective loans for policy with id [${policyId}]`);

    const documents = await getRenewalDocuments(policyId);

    return {
      renewalQuote: {
        quoteType: "RENEWAL",
        prospectiveLoan: prospectiveLoansResponse.prospectiveLoans?.[0],
        totalUnfinancedAmountPayable: prospectiveLoansResponse.totalUnfinancedAmountPayable,
        totalFinancedAmountPayable: prospectiveLoansResponse.prospectiveLoans?.[0].totalAmountPayable,
        insurerName: renewalNotice.insurer,
        insurerSchemeReference,
        brokerDiscount: renewalNotice?.brokerDiscount,
        premium: renewalNotice?.renewalPremium,
        adminFee: renewalNotice.adminFee,
        documents: documents
      },
      extrasTotalAmount: prospectiveLoansResponse.extrasTotalAmount ?? undefined
    };
  } catch (error) {
    logger.warn(error);
    return {
      renewalQuote: undefined,
      extrasTotalAmount: undefined
    };
  }
}

async function getProspectiveLoans(policyId: string, depositPercentage: number, policyExtras: QuoteExtra[]): Promise<FetchProspectiveLoansResponse> {
  return await loadRenewalProspectiveLoansQuery({
    policyId,
    depositPercentage,
    policyExtras
  });
}

async function getRenewalDocuments(policyId: string): Promise<RenewalDocuments | undefined> {
  try {
    return await loadRenewalDocumentsQuery(policyId);
  } catch (error) {
    logger.warn(`Failed to load renewal documents for for policy id [${policyId}]`);
    return undefined;
  }
}
