import React, {FC, useState} from "react";
import InlineQuestion from "../../questions/inline-question/InlineQuestion";
import EmailAddressInput from "shared-components/dist/form/email-address-input/EmailAddressInput";
import "./InlineQuestionEmailInput.css";

interface InlineQuestionEmailInputProps {
  inlineQuestionId: string;
  emailInputName: string;
  onChange: (value: string) => void;
  onComplete: (value: string) => void;
  onInvalid: () => void;
  initialEmailAddress?: string;
}

const InlineQuestionEmailInput: FC<InlineQuestionEmailInputProps> = ({
  inlineQuestionId,
  emailInputName,
  onChange,
  onComplete,
  onInvalid,
  initialEmailAddress
}) => {
  const [emailAddressFormatIsInvalid, setEmailAddressFormatIsInvalid] = useState(false);

  const onEmailAddressChanged = (value: string): void => {
    setEmailAddressFormatIsInvalid(false);
    onChange(value);
  };

  const onEmailAddressComplete = (value: string): void => {
    setEmailAddressFormatIsInvalid(false);
    onComplete(value);
  };

  const onEmailInvalid = (): void => {
    setEmailAddressFormatIsInvalid(true);
    onInvalid();
  };

  return (
    <InlineQuestion
      id={inlineQuestionId}
      status={emailAddressFormatIsInvalid ? "error" : "hidden"}
      errorMessage="structure.form.inlineQuestionEmailInput.invalidEmailAddressFormat"
      className="inline-question-email-input"
    >
      <EmailAddressInput
        name={emailInputName}
        value={initialEmailAddress ?? ""}
        onComplete={onEmailAddressComplete}
        onInvalid={onEmailInvalid}
        onChange={onEmailAddressChanged}
        placeholder="structure.form.emailAddressInput.placeholder"
        autoCompleteType="username"
      />
    </InlineQuestion>
  );
};

export default InlineQuestionEmailInput;
