import React, {FC} from "react";
import {householdNumberOfCarsChanged} from "../../redux/UpdateAdditionalDriverSlice";
import DropdownSubQuestion from "../../../../../../../structure/questions/sub-question/variants/dropdown-sub-question/DropdownSubQuestion";
import {NUMBER_OF_CARS_DROPDOWN_OPTIONS} from "./constants/HouseholdNumberOfCars";
import HouseholdHasTooManyCarsStop from "./HouseholdHasTooManyCarsStop";
import SubQuestionForm from "../../../../../../../structure/questions/sub-question-form/SubQuestionForm";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const HouseholdNumberOfCarsSubQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const householdNumberOfCarsField = useAppSelector(state => state.updateAdditionalDriver.householdNumberOfCars);

  return (
    <>
      <SubQuestionForm>
        <DropdownSubQuestion
          id="household-number-of-cars-sub-question"
          title="additionalDriver.householdNumberOfCarsSubQuestion.title"
          value={householdNumberOfCarsField.value}
          onChange={value => dispatch(householdNumberOfCarsChanged(value))}
          options={NUMBER_OF_CARS_DROPDOWN_OPTIONS}
          status={householdNumberOfCarsField.status}
          errorMessage={householdNumberOfCarsField.errorMessage}
        />
        <HouseholdHasTooManyCarsStop/>
      </SubQuestionForm>
    </>
  );
};

export default HouseholdNumberOfCarsSubQuestion;
