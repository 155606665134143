import {FC} from "react";
import {
  useQuotePriceProps
} from "../../../../quote/vehicle/shared/quote-summary/components/quote-price-card/hooks/UseQuotePricesProps";
import {useAppSelector} from "../../../../../redux/Hooks";
import {dateOfFirstPaymentAsSerializableDateOptionSelector} from "../redux/MonthlyPaymentSelectors";
import DirectDebitSetup from "../DirectDebitSetup";
import {PaymentFlowType} from "../../../shared/models/PaymentFlowType";
import {LoanType} from "../../../shared/models/LoanType";
import {
  selectQuoteDepositPercentageSelector
} from "../../../../quote/vehicle/your-quote/redux/selectors/QuoteDetailsSelectors";

const QuoteDirectDebitSetup: FC = () => {
  const {selectedLoan} = useQuotePriceProps();
  const dateOfFirstPayment = useAppSelector(state => dateOfFirstPaymentAsSerializableDateOptionSelector(state));
  const depositPercentage = useAppSelector(selectQuoteDepositPercentageSelector);

  return (
    <DirectDebitSetup
      depositPercentage={depositPercentage}
      loanType={LoanType.CREATE}
      paymentFlowType={PaymentFlowType.QUOTE}
      dateOfFirstPayment={dateOfFirstPayment.value ? dateOfFirstPayment.value.date : undefined}
      selectedLoan={selectedLoan}
    />
  );
};

export default QuoteDirectDebitSetup;
