import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import React, {FC} from "react";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";

interface Props {
  value: TranslationKey;
}

const SectionSubtitle: FC<Props> = ({value}) => (
  <p className="section-header__subtitle">{lookupI18nString(value)}</p>
);

export default SectionSubtitle;