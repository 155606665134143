import React, {FC, ReactElement} from "react";
import "./PageFooter.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {
  ReactComponent as LogoPrimaryDark
} from "shared-components/dist/assets/icons/logos/stroll/stroll-logo-primary-dark.svg";
import ExternalLink from "shared-components/dist/links/ExternalLink";
import {ReactComponent as PrestigeLogo} from "../../assets/icons/logos/regulatory/prestige-logo.svg";
import {ReactComponent as SgsLogoWhite} from "../../assets/icons/logos/regulatory/sgs-logo.svg";
import {BLOG_URL, CONTACT_US_URL, COOKIE_POLICY_URL, PRIVACY_POLICY_URL} from "shared/dist/constants/Urls";
import AppleAppStoreButtonSmall from "../buttons/app-stores/AppleAppStoreButtonSmall";
import GooglePlayStoreButtonSmall from "../buttons/app-stores/GooglePlayStoreButtonSmall";
import AppStoreBadges from "./app-store-badges/AppStoreBadges";
import {getCurrentYear} from "shared/dist/stdlib/Dates";

interface Props {
  isPortal?: boolean;
  preFooterContent?: ReactElement;
}

const PageFooter: FC<Props> = ({isPortal = false}) => {
  return (
    <footer className="page-footer">
      <div className="page-footer__container">
        <div className={`page-footer__container__top${!isPortal ? "__quote" : ""}`}>
          <div className="page-footer__container__top__logo">
            <a href={isPortal ? "/portal" : "/"}>
              <LogoPrimaryDark/>
            </a>
          </div>

          <div className="page-footer__container__top__content">
            {isPortal &&
              <p>
                <ExternalLink url={CONTACT_US_URL}>
                  {lookupI18nString("structure.footer.helpAndSupport")}
                </ExternalLink>
                <ExternalLink url={BLOG_URL}>
                  {lookupI18nString("structure.footer.blog")}
                </ExternalLink>
              </p>
            }
          </div>

          <div className="footer-app-store-logos--top">
            <AppleAppStoreButtonSmall/>
            <GooglePlayStoreButtonSmall/>
          </div>
        </div>

        <hr/>

        <div className="page-footer__container__bottom">
          <div className="page-footer__container__bottom__content">
            <p>
              {lookupI18nString({
                id: "structure.footer.copyright",
                templateVariables: {
                  currentYear: `${getCurrentYear()}`
                }
              })}
            </p>
            <p>
              {lookupI18nString("structure.footer.registration")}
            </p>
            <p>
              <ExternalLink url={PRIVACY_POLICY_URL}>
                {lookupI18nString("structure.footer.privacyPolicy")}
              </ExternalLink>
              <ExternalLink url={COOKIE_POLICY_URL}>
                {lookupI18nString("structure.footer.cookiePolicy")}
              </ExternalLink>
              <ExternalLink url={CONTACT_US_URL}>
                {lookupI18nString("structure.footer.contactUs")}
              </ExternalLink>
            </p>
          </div>

          <div className="footer-regulatory-logos">
            <SgsLogoWhite/>
            <PrestigeLogo/>
          </div>

          <div className="footer-app-store-logos--bottom">
            <AppStoreBadges/>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default PageFooter;
