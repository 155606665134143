import React, {FC} from "react";
import {AbiListItem} from "shared/dist/generated/graphql/resolvers-types";
import TitleQuestion from "../../../../shared/questions/title/TitleQuestion";
import {titleChanged, titleReset} from "../../redux/UpdateAdditionalDriverSlice";
import {logAdditionalDriverGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const ProposerTitleQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const titleField = useAppSelector(state => state.updateAdditionalDriver.title);

  const onTitleChanged = (title: AbiListItem): void => {
    dispatch(titleChanged(title));

    logAdditionalDriverGoogleAnalyticsEvent({
      categorySuffix: "title",
      action: title.vtDescription.toLowerCase(),
    });
  };

  return (
    <TitleQuestion
      id="additional-driver-title"
      questionTitle="additionalDriver.titleQuestion.title"
      titleField={titleField}
      titleChanged={onTitleChanged}
      titleReset={() => dispatch(titleReset())}
      placeholder="personalDetails.titleDropdownPlaceholder"
    />
  );
};

export default ProposerTitleQuestion;