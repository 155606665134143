import React, {FC} from "react";
import "./DashboardHeader.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {useAppSelector} from "../../../../redux/Hooks";
import {selectPolicyHolder} from "../../shared/redux/PortalSelectors";
import PolicyErrorMessage from "../../shared/error/PolicyErrorMessage";

const DashboardHeader: FC = () => {
  const policyHolder = useAppSelector(selectPolicyHolder);

  if (!policyHolder) return <PolicyErrorMessage/>;

  return (
    <div className="dashboard-header">
      <div className="dashboard-header__container">
        <div className="dashboard-header__text">
          <h3>{lookupI18nString({
            id: "dashboard.header.welcomeText",
            templateVariables: {
              firstName: policyHolder.firstName
            }
          })}</h3>
        </div>
        <div className="dashboard-header__separator"/>
      </div>
    </div>
  );
};

export default DashboardHeader;
