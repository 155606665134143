import {Done, ErrorOutline} from "@mui/icons-material";
import React, {FC} from "react";
import "./StatusIndicator.scss";
import {Status} from "./Status";
import SpinnerIcon from "../spinner/spinner-icon/SpinnerIcon";

export const statusMap = {
  "default": <></>,
  "success": <Done/>,
  "warning": <ErrorOutline/>,
  "error": <ErrorOutline/>,
  "loading": <SpinnerIcon/>,
  "hidden": <></>
};

interface Props {
  status: Status;
}

const StatusIndicator: FC<Props> = ({status}) => (
  <div className={`status status--${status}`}>
    {statusMap[status]}
  </div>
);

export default StatusIndicator;