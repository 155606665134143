import {CustomisedAbiListItem} from "shared-components/dist/models/CustomisedAbiListItem";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {NoClaimsAffected} from "shared/dist/generated/graphql/resolvers-types";

export const AFFECTED_NCB = [
  {
    vtCode: NoClaimsAffected.Yes,
    description: lookupI18nString("structure.questions.claims.affectedNCB.yes")
  },
  {
    vtCode: NoClaimsAffected.No,
    description: lookupI18nString("structure.questions.claims.affectedNCB.no")
  },
  {
    vtCode: NoClaimsAffected.Protected,
    description: lookupI18nString("structure.questions.claims.affectedNCB.noProtected")
  },
] as const;

export type NoClaimsAffectedOption = CustomisedAbiListItem<NoClaimsAffected>;

export const AFFECTED_NCB_OPTIONS = AFFECTED_NCB.map(affectedNCB => affectedNCB.description);
