import React, {ChangeEvent, FC, useState} from "react";
import Modal from "shared-components/dist/modals/Modal";
import SimpleModalHeader from "shared-components/dist/modals/simple-modal-header/SimpleModalHeader";
import BreakInCoverDetection from "../../quote/vehicle/shared/break-in-cover/BreakInCoverDetection";
import {useAppDispatch, useAppSelector} from "../../../redux/Hooks";
import {
  coverStartDateAsDateOptionSelector,
  existingPolicyExpiryDateAsDateSelector
} from "../../quote/vehicle/your-cover/redux/selectors/CoverDetailsSelectors";
import {
  vehiclePurchaseDateAsDateSelector
} from "../../quote/vehicle/your-vehicle/redux/selectors/VehicleDateTypeSelectors";
import DateModalQuestion from "../../../structure/questions/modal-question/variants/DateModalQuestion";
import ButtonGroup from "shared-components/dist/buttons/button-group/ButtonGroup";
import Button from "shared-components/dist/buttons/button/Button";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {useFeatureFlags} from "shared-components/dist/feature-flags/hooks/UseFeatureFlags";
import {
  useDismissOnQuoteRefreshed
} from "../../quote/vehicle/shared/quote-summary/components/edit-car-details-modal/hooks/useDismissOnQuoteRefreshed";
import {aggregatorMissingAnswersFulfilled} from "./redux/AggregatorMissingAnswersFulfilled";
import {
  isAfterManufactureDate,
  isValidPurchaseDate
} from "../../quote/vehicle/your-vehicle/components/have-you-bought-car/validators/PurchaseDateValidators";
import {currentQuoteQueryArgsSelector} from "../../quote/vehicle/your-quote/redux/selectors/QuoteDetailsSelectors";
import {useBreakInCoverWithinModal} from "../../quote/vehicle/shared/break-in-cover/hooks/UseBreakInCoverWithinModal";
import {coverStartDateDescriptionsFrom} from "../../../utils/forms/descriptionsFrom";
import DropdownModalQuestion from "../../../structure/questions/modal-question/variants/DropdownModalQuestion";
import {
  startDateOptionsByDateOfBirth
} from "../../quote/vehicle/shared/quote-summary/components/edit-cover-summary/helpers/StartDateOptionsByDateOfBirth";
import {dateOfBirthAsDateSelector} from "../../quote/vehicle/your-details/redux/selectors/PersonalDetailsSelectors";
import {useModalCoverStartDateStatus} from "../../quote/vehicle/shared/hooks/cover-start-date/ModalCoverStartDateStatus";
import {endOfDay, isBefore, startOfDay} from "date-fns";
import {DateOption} from "shared/dist/models/date-option/DateOption";
import {shouldDisableAggregatorModalConfirmButton} from "./helpers/ShouldDisableAggregatorModalConfirmButton";

interface Props {
  visible: boolean;
  setIsVisible: (value: boolean) => void
}

const AggregatorMissingAnswersModal: FC<Props> = ({visible, setIsVisible}) => {
  const dispatch = useAppDispatch();
  const isQuoteLoading = useAppSelector(state => state.quoteDetails.quoteLoading);
  const dateOfBirth = useAppSelector(state => dateOfBirthAsDateSelector(state).value);
  const {monthlyPaymentsFlag} = useFeatureFlags();
  const [isFocusTrapPaused, setIsFocusTrapPaused] = useState(false);

  const vehicle = useAppSelector(state => currentQuoteQueryArgsSelector(state).vehicle);
  const coverStartDate = useAppSelector(coverStartDateAsDateOptionSelector);
  const carPurchaseDate = useAppSelector(state => vehiclePurchaseDateAsDateSelector(state));
  const previousPolicyExpiryDate = useAppSelector(state => existingPolicyExpiryDateAsDateSelector(state));

  const isPreviouslyInsured = useAppSelector(state => !!state.coverDetails.previouslyHeldInsurance.value);

  const [newPreviousPolicyExpiryDate, setNewPreviousPolicyExpiryDate] = useState(previousPolicyExpiryDate);
  const [newVehicleDateOfPurchase, setNewVehicleDateOfPurchase] = useState(carPurchaseDate);
  const [newCoverStartDateValue, setNewCoverStartDateValue] = useState(
    getInitialCoverStartDateValue(coverStartDate.value)
  );

  const {
    status: coverStartDateStatus,
    errorMessage: coverStartDateErrorMessage,
    errorTooltip: coverStartDateErrorTooltip
  } = useModalCoverStartDateStatus({
    coverStartDate: newCoverStartDateValue,
    carPurchaseDate: newVehicleDateOfPurchase.value,
    existingPolicyExpiryDate: newPreviousPolicyExpiryDate.value
  });

  const onSubmit = (): void => {
    if (!newVehicleDateOfPurchase.value) return;

    dispatch(aggregatorMissingAnswersFulfilled({
      monthlyPaymentsFlag,
      newPreviousPolicyExpiryDate: newPreviousPolicyExpiryDate.value,
      newVehicleDateOfPurchase: newVehicleDateOfPurchase.value,
      breakInCover,
      breakInCoverDeclarationAcceptance: breakInCoverDeclarationAcceptance.value,
      coverStartDate: newCoverStartDateValue
    }));
  };

  useDismissOnQuoteRefreshed(isQuoteLoading, visible, () => setIsVisible(false));

  const {
    breakInCover,
    breakInCoverDeclarationAcceptance,
    onBreakInCoverChanged,
    onBreakInCoverDeclarationAnswered,
  } = useBreakInCoverWithinModal();

  const coverStartDates = startDateOptionsByDateOfBirth(dateOfBirth);

  const shouldDisableConfirmButton = shouldDisableAggregatorModalConfirmButton({
    breakInCover,
    breakInCoverDeclarationAcceptance,
    isPreviouslyInsured,
    newPreviousPolicyExpiryDate,
    newVehicleDateOfPurchase,
    coverStartDateStatus,
    newCoverStartDateValue
  });

  const onCoverStartDateChanged = (selectedCoverStartDate: string | undefined): void => {
    const value = coverStartDates.find(coverStartDate => coverStartDate.description === selectedCoverStartDate);

    if (value) setNewCoverStartDateValue(value);
  };

  return (
    <Modal
      isFocusTrapPaused={isFocusTrapPaused}
      visible={visible}
      header={<SimpleModalHeader title="quoteDetails.aggregatorMissingAnswersModal.title"/>}
      body={
        <>
          <DateModalQuestion
            id="aggregator-modal-edit-car-date-of-purchase"
            title="quoteDetails.aggregatorMissingAnswersModal.carPurchaseDate.title"
            description="quoteDetails.aggregatorMissingAnswersModal.carPurchaseDate.description"
            name="aggregator-modal-edit-car-date-of-purchase"
            value={newVehicleDateOfPurchase.value}
            onComplete={value => setNewVehicleDateOfPurchase({
              ...newVehicleDateOfPurchase,
              status: "success",
              value: startOfDay(value)
            })}
            onInvalid={(errorMessage, invalidDate) => setNewVehicleDateOfPurchase({
              value: invalidDate,
              status: "error",
              errorMessage
            })}
            setIsFocusTrapPaused={setIsFocusTrapPaused}
            onClear={() => setNewVehicleDateOfPurchase(carPurchaseDate)}
            status={newVehicleDateOfPurchase.status}
            errorMessage={newVehicleDateOfPurchase.errorMessage}
            validators={[
              isAfterManufactureDate(vehicle.yearOfManufacture),
              isValidPurchaseDate("quoteDetails.carDetails.modal.dateOfPurchase.afterToday")
            ]}
          />

          {isPreviouslyInsured && (
            <DateModalQuestion
              id="aggregator-modal-previous-policy-expiry-date-question"
              name="aggregator-modal-previous-policy-expiry-date-question"
              title="quoteDetails.aggregatorMissingAnswersModal.existingPolicyExpiryDate.title"
              description="quoteDetails.aggregatorMissingAnswersModal.existingPolicyExpiryDate.description"
              value={newPreviousPolicyExpiryDate.value}
              onComplete={value => {
               setNewPreviousPolicyExpiryDate({
                 ...newPreviousPolicyExpiryDate,
                 status: "success",
                 value: endOfDay(value)
               });
              }}
              onInvalid={errorMessage => {
                setNewPreviousPolicyExpiryDate({
                  ...newPreviousPolicyExpiryDate,
                  status: "error",
                  errorMessage
                });
              }}
              onClear={() => setNewPreviousPolicyExpiryDate(previousPolicyExpiryDate)}
              setIsFocusTrapPaused={setIsFocusTrapPaused}
            />
          )}

          <DropdownModalQuestion
            id="aggregator-modal-cover-start-date-question"
            title="quoteDetails.aggregatorMissingAnswersModal.policyStartDate.title"
            description="quoteDetails.aggregatorMissingAnswersModal.policyStartDate.description"
            name="cover-start-date-aggregator-modal-dropdown"
            value={newCoverStartDateValue?.description}
            options={coverStartDateDescriptionsFrom(coverStartDates)}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => onCoverStartDateChanged(event.target.value)}
            status={coverStartDateStatus}
            errorMessage={coverStartDateErrorMessage}
            errorTooltip={coverStartDateErrorTooltip}
          />

          <BreakInCoverDetection
            coverStartDate={newCoverStartDateValue?.date}
            carPurchaseDate={newVehicleDateOfPurchase.value}
            previousCoverExpiryDate={newPreviousPolicyExpiryDate.value}
            breakInCover={breakInCover}
            breakInCoverDeclarationAcceptance={breakInCoverDeclarationAcceptance}
            breakInCoverChanged={onBreakInCoverChanged}
            breakInCoverDeclarationAcceptanceChanged={onBreakInCoverDeclarationAnswered}
            isWithinModal
          />
        </>
      }
      footer={
        <ButtonGroup>
          <Button
            className="aggregator-modal-confirmation-button"
            onClick={onSubmit}
            loading={isQuoteLoading}
            disabled={shouldDisableConfirmButton}
            expanded
          >
            {lookupI18nString("quoteDetails.aggregatorMissingAnswersModal.confirm")}
          </Button>
        </ButtonGroup>
      }
    />
  );
};

const getInitialCoverStartDateValue = (coverStartDate: DateOption | undefined): DateOption | undefined => {
  if (!coverStartDate) return undefined;

  return !isBefore(coverStartDate.date, startOfDay(new Date()))
    ? coverStartDate
    : undefined;
};

export default AggregatorMissingAnswersModal;