import {Action, AnyAction, combineReducers, configureStore, EnhancedStore} from "@reduxjs/toolkit";
import {vehicleDetailsReducer} from "../pages/quote/vehicle/your-vehicle/redux/VehicleDetailsSlice";
import {personalDetailsReducer} from "../pages/quote/vehicle/your-details/redux/PersonalDetailsSlice";
import {manualVehicleLookupFormReducer} from "../pages/quote/vehicle/your-vehicle/redux/ManualVehicleLookupFormSlice";
import {coverDetailsReducer} from "../pages/quote/vehicle/your-cover/redux/CoverDetailsSlice";
import {quoteDetailsReducer} from "../pages/quote/vehicle/your-quote/redux/QuoteDetailsSlice";
import {
  updateAdditionalDriverReducer
} from "../pages/quote/vehicle/additional-driver/shared/redux/UpdateAdditionalDriverSlice";
import {userAuthenticationReducer} from "../pages/user-authentication/shared/redux/UserAuthenticationSlice";
import {portalReducer} from "../pages/portal/shared/redux/PortalSlice";
import {monthlyPaymentReducer} from "../pages/payment/monthly/direct-debit-setup/redux/MonthlyPaymentSlice";
import {FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE,} from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import {PersistConfig} from "redux-persist/es/types";
import {ThunkDispatch} from "redux-thunk";
import {RiskChangesTrackingMiddleware} from "./middleware/RiskChangesTrackingMiddleware";
import {renewalReducer} from "../pages/portal/renewals/redux/RenewalSlice";

const rootPersistConfig: PersistConfig<RootState> = {
  key: "root",
  storage: storageSession
};

const rootReducer = combineReducers({
  vehicleDetails: vehicleDetailsReducer,
  personalDetails: personalDetailsReducer,
  updateAdditionalDriver: updateAdditionalDriverReducer,
  manualVehicleLookupForm: manualVehicleLookupFormReducer,
  coverDetails: coverDetailsReducer,
  quoteDetails: quoteDetailsReducer,
  monthlyPayment: monthlyPaymentReducer,
  userAuthentication: userAuthenticationReducer,
  portal: portalReducer,
  renewal: renewalReducer
});

export const createStore = (): EnhancedStore => {
  return configureStore({
    reducer: persistReducer(rootPersistConfig, rootReducer),
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
      RiskChangesTrackingMiddleware
    ],
    devTools: !(window.Stroll?.IS_PRODUCTION)
  });
};

export const store = createStore();

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;

export type ThunkAction<
  R, // Return type of the thunk function
  S, // state type used by getState
  E, // any "extra argument" injected into the thunk
  A extends Action // known types of actions that can be dispatched
  > = (dispatch: ThunkDispatch<S, E, A>, getState: () => S, extraArgument: E) => R

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>
export type AppThunkDispatch = ThunkDispatch<RootState, unknown, AnyAction>;