import React, {FC, FormEvent, MouseEvent} from "react";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import Button from "shared-components/dist/buttons/button/Button";
import ButtonGroup from "shared-components/dist/buttons/button-group/ButtonGroup";

export type SendLinkButtonState = "Enabled" | "Disabled" | "Loading"

interface Props {
  onSendEmail: (event: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>) => void;
  onDismiss: () => void;
  sendLinkButtonState: SendLinkButtonState;
}

const ForgotPasswordModalButtons: FC<Props> = ({onSendEmail, onDismiss, sendLinkButtonState}) => (
  <ButtonGroup>
    <Button
      variant="tertiary"
      onClick={onDismiss}
      expanded
    >
      {lookupI18nString("login.forgotPassword.modal.buttons.cancel")}
    </Button>
    <Button
      type="submit"
      variant="primary"
      onClick={onSendEmail}
      disabled={sendLinkButtonState === "Disabled"}
      loading={sendLinkButtonState === "Loading"}
      expanded
    >
      {lookupI18nString("login.forgotPassword.modal.buttons.sendLink")}
    </Button>
  </ButtonGroup>
);

export default ForgotPasswordModalButtons;
