import React, {FC} from "react";
import "./GoBackHeader.css";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {useNavigate} from "react-router-dom";
import Button from "shared-components/dist/buttons/button/Button";

interface Props {
  text: TranslationKey;
  testId?: string;
}

const GoBackHeader: FC<Props> = ({text, testId}) => {
  const navigate = useNavigate();
  return (
    <div className="go-back-header" data-testid={testId}>
      <div className="go-back-header__container">
        <Button onClick={() => navigate(-1)} variant="link" data-testid="back-button">
          {lookupI18nString(text)}
        </Button>
      </div>
    </div>
  );
};

export default GoBackHeader;