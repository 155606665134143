import {SummaryListItemProps} from "shared-components/dist/lists/summary-list/components/summary-list-item/SummaryListItem";
import {CoverTypeDescription} from "../../../../../../quote/vehicle/your-cover/components/cover-type/models/CoverType";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";

export interface PolicyOverviewList {
  coverType: CoverTypeDescription | undefined;
  noClaimsProtection: boolean | undefined;
}

export function usePolicyOverviewList(
  {
    coverType,
    noClaimsProtection,
  }: Partial<PolicyOverviewList> = {}
): SummaryListItemProps[] {
  return [
    {title: "policies.summary.noClaimsProtection", value: getNoClaimsProtectionStatus(noClaimsProtection)},
    {title: "policies.summary.totalPolicyExcess", value: lookupI18nString("policies.summary.checkYourDocuments")},
    {title: "policies.summary.coverType", value: coverType}
  ];
}

const getNoClaimsProtectionStatus = (noClaimsProtection: boolean | undefined): string => {
  if (!noClaimsProtection) return lookupI18nString("policies.summary.noClaimsProtection.unprotected");
  return lookupI18nString("policies.summary.noClaimsProtection.protected");
};
