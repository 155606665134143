import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {
  CLAIMS,
  POLICIES,
  POLICY_DOCUMENTS,
  POLICY_DRIVERS,
  POLICY_SUMMARY,
  PORTAL,
  SETTINGS,
  UPLOADS
} from "../../../../router/models/Routes";

export interface MenuItem {
  title: TranslationKey;
  path: string;
  googleAnalyticsSuffix: string;
  subMenuItems?: MenuItem[]
}

interface Menu {
  dashboard: MenuItem
  policies: MenuItem;
  claims: MenuItem;
  uploads: MenuItem
  settings: MenuItem;
}

export const menuItems: Menu = {
  dashboard: {
    title: "navbar.menu.dashboard",
    googleAnalyticsSuffix: "navigation.dashboard",
    path: PORTAL
  },
  policies: {
    title: "navbar.menu.policies",
    googleAnalyticsSuffix: "navigation.policies",
    path: POLICIES,
    subMenuItems: [
      {
        title: "navbar.menu.policies.summary",
        googleAnalyticsSuffix: "navigation.policy.summary",
        path: POLICY_SUMMARY,
      },
      {
        title: "navbar.menu.policies.documents",
        googleAnalyticsSuffix: "navigation.policy.documents",
        path: POLICY_DOCUMENTS,
      },
      {
        title: "navbar.menu.policies.additionalDrivers",
        googleAnalyticsSuffix: "navigation.policy.additional_drivers",
        path: POLICY_DRIVERS,
      }
    ]
  },
  claims: {
    title: "navbar.menu.claims",
    googleAnalyticsSuffix: "navigation.claims",
    path: CLAIMS
  },
  uploads: {
    title: "navbar.menu.uploads",
    googleAnalyticsSuffix: "navigation.uploads",
    path: UPLOADS
  },
  settings: {
    title: "navbar.menu.settings",
    googleAnalyticsSuffix: "navigation.settings",
    path: SETTINGS
  }
};

const subMenuItemsForPolicy = (policyId: string, showRenewalsTab: boolean): MenuItem[] => {
  const summaryTab: MenuItem = {
    title: "navbar.menu.policies.summary",
    googleAnalyticsSuffix: "navigation.policy.summary",
    path: `/portal/policy/${policyId}`,
  };
  const documentsTab: MenuItem = {
    title: "navbar.menu.policies.documents",
    googleAnalyticsSuffix: "navigation.policy.documents",
    path: `/portal/policy/${policyId}/documents`,
  };
  const renewalsTab: MenuItem = {
    title: "navbar.menu.policies.renewals",
    googleAnalyticsSuffix: "navigation.policy.renewals",
    path: `/portal/policy/${policyId}/renewals`,
  };
  const additionalDriversTab: MenuItem = {
    title: "navbar.menu.policies.additionalDrivers",
    googleAnalyticsSuffix: "navigation.policy.additional_drivers",
    path: `/portal/policy/${policyId}/drivers`,
  };
  return showRenewalsTab
    ? [summaryTab, documentsTab, renewalsTab, additionalDriversTab]
    : [summaryTab, documentsTab, additionalDriversTab];
};

const coreMenuItems: MenuItem[] = [
  {
    title: menuItems.claims.title,
    googleAnalyticsSuffix: menuItems.claims.googleAnalyticsSuffix,
    path: menuItems.claims.path
  },
  {
    title: menuItems.uploads.title,
    googleAnalyticsSuffix: menuItems.uploads.googleAnalyticsSuffix,
    path: menuItems.uploads.path
  },
  {
    title: menuItems.settings.title,
    googleAnalyticsSuffix: menuItems.settings.googleAnalyticsSuffix,
    path: menuItems.settings.path
  }
];

export const navbarMenuItems = [{
  title: menuItems.dashboard.title,
  path: menuItems.dashboard.path,
  googleAnalyticsSuffix: menuItems.policies.googleAnalyticsSuffix
}, ...coreMenuItems];

export const policySidebarMenuItems: MenuItem[] = menuItems.policies.subMenuItems ?? [];

export const policyViewSidebarMenuItems = (policyId: string, hasRenewalsTab = false): MenuItem[] => subMenuItemsForPolicy(policyId, hasRenewalsTab);