import React, {FC} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import KeyItemList from "shared-components/dist/lists/key-item-list/KeyItemList";
import Button from "shared-components/dist/buttons//button/Button";
import "./AdditionalDriverSummary.css";
import {createEditAdditionalDriverUrlFor} from "../../../services/CreateEditAdditionalDriverUrl";
import {ADD_ADDITIONAL_DRIVER} from "../../../../../../../router/models/Routes";
import {buildQuoteRoute} from "../../../../../../../utils/navigation/NavigationHelpers";
import {additionalDriverReset} from "../../../../additional-driver/shared/redux/UpdateAdditionalDriverSlice";
import {logYourQuoteGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {additionalDriversSelector} from "../../../../your-details/redux/selectors/PersonalDetailsSelectors";
import {ArrowForward} from "@mui/icons-material";
import {MAX_ADDITIONAL_DRIVERS} from "../../../../../../../utils/constants/Quote";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";
import {useIsEditFlowEnabledForQuote} from "../../../../your-quote/hooks/IsEditFlowEnabledForQuote";

const AdditionalDriversSummary: FC = () => {
  const dispatch = useAppDispatch();
  const additionalDrivers = useAppSelector(additionalDriversSelector);
  const isEditFlowEnabled = useIsEditFlowEnabledForQuote();

  const navigate = useNavigate();
  const location = useLocation();

  const drivers = additionalDrivers.map(driver => {
    const editDriverLink = (
      <Button
        variant="link"
        className="edit-additional-driver-button"
        onClick={() => {
          navigate(createEditAdditionalDriverUrlFor(location.pathname, driver.id),
            {state: {pageTitle: "additionalDriver.edit.sidebar.title.refineYourQuote"}});
        }}
      >
        {isEditFlowEnabled && <>
          {lookupI18nString("quoteDetails.summary.additionalDrivers.editDriver")}
          <div className="edit-additional-driver-button__icon">
            <ArrowForward/>
          </div>
        </>
        }
      </Button>
    );

    return {
      keyValue: `${driver.firstName} ${driver.surname}`,
      item: editDriverLink
    };
  });

  const routeToAddAdditionalDriver = (): void => {
    logYourQuoteGoogleAnalyticsEvent({
      categorySuffix: "additional_drivers.add_driver",
      action: "Click"
    });
    dispatch(additionalDriverReset());
    navigate(buildQuoteRoute(location.pathname, ADD_ADDITIONAL_DRIVER),
      {state: {pageTitle: "additionalDriver.add.sidebar.title.refineYourQuote"}}
    );
  };

  return (
    <>
      <div className="additional-drivers-summary">
        <KeyItemList rows={drivers}/>
      </div>

      {isEditFlowEnabled &&
        <Button
          id="add-driver-button"
          className="quote-summary-edit-button"
          variant="ghost"
          onClick={routeToAddAdditionalDriver}
          disabled={drivers.length === MAX_ADDITIONAL_DRIVERS}
        >
          {lookupI18nString("quoteDetails.summary.additionalDrivers.button")}
        </Button>
      }
    </>
  );
};

export default AdditionalDriversSummary;