import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

interface UseNavigateAfterValidation {
  navigateWhenValid: () => void;
}

export function useNavigateAfterValidation(
  route: string | undefined,
  isFormValid: boolean | undefined,
  afterForwardNavigation?: () => void
): UseNavigateAfterValidation {
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!shouldNavigate) return;
    setShouldNavigate(false);

    if (route && isFormValid) {
      navigate(route);
      afterForwardNavigation?.();
    }
  }, [navigate, isFormValid, route, shouldNavigate, afterForwardNavigation]);

  const canNavigate = (): void => setShouldNavigate(true);

  return {navigateWhenValid: canNavigate};
}