import {DocumentType} from "shared/dist/generated/graphql/resolvers-types";

export function mapDocumentTypeToGoogleAnalyticsEventSuffix(documentType: DocumentType): string {
  switch (documentType) {
    case DocumentType.CertificateAndScheduleOfInsurance:
      return "policy.documents.certificate_of_car_insurance";
    case DocumentType.StatementOfFact:
      return "policy.documents.statement_of_fact";
    case DocumentType.NoClaimsBonusProtection:
      return "policy.documents.no_claims_bonus";
    case DocumentType.InsuranceProductInformation:
      return "policy.documents.car_insurance_ipid";
    case DocumentType.TermsOfBusinessAgreement:
      return "policy.documents.terms_and_conditions";
    case DocumentType.PolicyBooklet:
      return "policy.documents.policy_wording_booklet";
    case DocumentType.SmartfobKeycare:
      return "policy.documents.policy_extras.smartfob_keycare";
    case DocumentType.RoadsideAssistancePlatinum:
      return "policy.documents.policy_extras.roadside_assistance_platinum";
    case DocumentType.LegalExpenses:
      return "policy.documents.policy_extras.legal_expenses";
    case DocumentType.ExcessInsurance:
      return "policy.documents.policy_extras.excess_insurance";
    case DocumentType.ExcessInsuranceScheduleOfInsurance:
      return "policy.documents.policy_extras.excess_insurance.schedule_of_insurance";
    case DocumentType.CourtesyCar:
      return "policy.documents.policy_extras.courtesy_car";
    case DocumentType.PolicySummary:
      return "policy.documents.policy_summary";
    default:
      throw Error(`No Google Analytics suffix found for ${documentType}`);
  }
}