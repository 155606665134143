import {
  validationFailure,
  ValidationResult,
  validationSuccess,
  Validator
} from "shared-components/dist/utils/validation/Validator";
import {now} from "shared/dist/stdlib/Dates";
import {isAfter, startOfDay} from "date-fns";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";

export function isAfterManufactureDate(yearOfManufacture: string | undefined): Validator<Date> {
  return (dateOfPurchase: Date): ValidationResult => {
    const yearOfManufactureAsNumber = Number(yearOfManufacture);
    if (!yearOfManufactureAsNumber) {
      return validationFailure("vehicleQuote.haveYouBoughtTheCarYetQuestion.purchaseDate.invalidYearOfManufacture");
    }

    if (isAfter(new Date(yearOfManufactureAsNumber, 0), dateOfPurchase)) {
      return validationFailure("vehicleQuote.haveYouBoughtTheCarYetQuestion.purchaseDate.beforeItWasManufactured");
    }

    return validationSuccess;
  };
}

export function isValidPurchaseDate(validationMessage: TranslationKey = "vehicleQuote.haveYouBoughtTheCarYetQuestion.purchaseDate.afterToday"): Validator<Date> {
  return (dateOfPurchase: Date): ValidationResult => {
    return isAfter(startOfDay(dateOfPurchase), now())
      ? validationFailure(validationMessage)
      : validationSuccess;
  };
}