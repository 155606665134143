import {RootState} from "../../../../../../redux/Store";
import {
  isValidAdditionalVehicleDetails,
  isValidVehicleSummary,
  Vehicle
} from "../../../../../../graphql/queries/vehicle-lookup/registration-lookup/model/Vehicle";
import {createSelector} from "@reduxjs/toolkit";
import {ASSUME_TRACKER_TRADE_PRICE} from "../../../../../../utils/constants/Quote";

export const regLookupVehicleSelector = (state: RootState): Vehicle | undefined => state.vehicleDetails.vehicleFromRegLookup;

export const validRegLookupVehicleSummarySelector = createSelector(
  regLookupVehicleSelector,
  (vehicle: Vehicle | undefined): boolean => isValidVehicleSummary(vehicle)
);

export const validRegLookupVehicleAssumptionsSelector = createSelector(
  regLookupVehicleSelector,
  (vehicle: Vehicle | undefined): boolean => isValidAdditionalVehicleDetails(vehicle)
);

export const regLookupPriceBelowTrackerThresholdSelector = createSelector(
  regLookupVehicleSelector,
  (vehicle: Vehicle | undefined) => {
    return !vehicle || !vehicle.estimatedValue || Number(vehicle.estimatedValue) < ASSUME_TRACKER_TRADE_PRICE;
  }
);
