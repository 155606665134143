import React, {FC} from "react";
import Dropdown, {DropdownProps} from "shared-components/dist/form/dropdown/Dropdown";
import ModalQuestion, {ModalQuestionProps} from "../ModalQuestion";

type Props = DropdownProps & Omit<ModalQuestionProps, "children">;

const DropdownModalQuestion: FC<Props> = (
  {
    id,
    title,
    description,
    inputLabel,
    name,
    value,
    prefix,
    options,
    onChange,
    displayValues,
    disabled,
    status,
    errorMessage,
    errorTooltip,
    placeholder,
    suffix
  }
) => (
  <ModalQuestion
    id={id}
    title={title}
    description={description}
    inputLabel={inputLabel}
    status={status}
    errorMessage={errorMessage}
    errorTooltip={errorTooltip}
  >
    <Dropdown
      name={name}
      value={value}
      prefix={prefix}
      options={options}
      onChange={onChange}
      displayValues={displayValues}
      disabled={disabled}
      placeholder={placeholder}
      suffix={suffix}
    />
  </ModalQuestion>
);

export default DropdownModalQuestion;