import {FC, useEffect} from "react";
import {useAppSelector} from "../../../../../redux/Hooks";
import DirectDebitSetup from "../DirectDebitSetup";
import {
  selectActiveProspectiveLoan,
  selectActiveRenewalNotice,
  selectExistingPolicyForRenewal,
  selectPreviouslyHadCloseBrothersLoan,
  selectRenewalDepositPercentage
} from "../../../../portal/renewals/redux/RenewalSelectors";
import {addDays, isAfter, startOfDay} from "date-fns";
import {LoanType} from "../../../shared/models/LoanType";
import RenewalErrorMessage
  from "../../../../portal/policies/sub-pages/renewals/components/renewal-error-message/RenewalErrorMessage";
import {useNavigate} from "react-router-dom";
import {usePolicyQueryParams} from "../../../../../router/params/Params";
import {policyCanBeRenewedOrRebroked} from "../../../../portal/shared/utils/PolicyStatusUtils";
import {
  useRenewalQuoteTypePaymentValues
} from "../../../../portal/renewals/redux/hooks/UseRenewalQuoteTypePaymentValues";

const RenewalDirectDebitSetup: FC = () => {
  const navigate = useNavigate();
  const {policyId} = usePolicyQueryParams();
  const depositPercentage = useAppSelector(selectRenewalDepositPercentage);
  const existingPolicy = useAppSelector(selectExistingPolicyForRenewal);
  const selectedLoan = useAppSelector(selectActiveProspectiveLoan);
  const renewalNotice = useAppSelector(selectActiveRenewalNotice);
  const previouslyHadCloseBrothersLoan = useAppSelector(selectPreviouslyHadCloseBrothersLoan);
  const renewalTypeParams = useRenewalQuoteTypePaymentValues(policyId);

  useEffect(() => {
    if (!policyCanBeRenewedOrRebroked(existingPolicy)) navigate(`/portal/policy/${policyId}/renewals`);
  }, [existingPolicy, navigate, policyId]);

  if (!renewalNotice || !renewalNotice.renewalTimestamp || !renewalTypeParams) return <RenewalErrorMessage/>;

  const nineDaysAway = addDays(startOfDay(new Date()), 9);
  const renewalDate = new Date(renewalNotice.renewalTimestamp);
  const dateOfFirstPaymentForRenewingLoan = isAfter(renewalDate, nineDaysAway) ? renewalDate : nineDaysAway;
  const dateOfFirstPayment = previouslyHadCloseBrothersLoan ? dateOfFirstPaymentForRenewingLoan : addDays(startOfDay(new Date()), 30);

  return <DirectDebitSetup
    loanType={previouslyHadCloseBrothersLoan ? LoanType.RENEW : LoanType.CREATE}
    paymentFlowType={renewalTypeParams.paymentFlowType}
    dateOfFirstPayment={dateOfFirstPayment.toISOString()}
    selectedLoan={selectedLoan}
    depositPercentage={depositPercentage}
    proposedFinancedRenewalRequest={renewalTypeParams.setupRequest}
  />;
};

export default RenewalDirectDebitSetup;
