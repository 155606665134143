import React, {FC, ReactChild, ReactElement, useEffect} from "react";
import QuoteHeader from "./header/QuoteHeader";
import "./QuoteStep.css";
import SidebarMenu from "../../../../../structure/sidebar/sidebar-menu/SidebarMenu";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import ContentWithSidebarLayout
  from "../../../../../structure/layouts/content-with-sidebar-layout/ContentWithSidebarLayout";
import {useScrollToError} from "../../../../../utils/validation/hooks/ScrollToError";
import FooterButtonGroup from "./footer-button-group/FooterButtonGroup";
import {AppThunk} from "../../../../../redux/Store";
import MainContent from "../../../../../structure/layouts/main-content/MainContent";
import {useAppDispatch, useAppSelector} from "../../../../../redux/Hooks";
import {selectIsResolveQuoteValidationFlowActive} from "../../your-quote/redux/selectors/QuoteDetailsSelectors";
import {quoteSteps} from "./QuoteSteps";
import {exitResolveValidationErrorFlow} from "../../../aggregator-quote/redux/ExitResolveValidationErrorFlow";

interface Props {
  forwardButtonLabel: TranslationKey;
  nextRoute?: string;
  previousRoute?: string;
  onSubmit?: () => void;
  onAsyncSubmit?: () => Promise<void> | Promise<AppThunk>;
  isFormValid?: boolean;
  title: TranslationKey;
  description?: TranslationKey;
  sidebar?: ReactElement;
  sidebarInfoPanel?: ReactElement;
  showFooter?: boolean;
  onGoBackClicked?: () => void;
  additionalContent?: ReactChild;
  afterHeader?: ReactElement;
  isFinalFlowRoute?: boolean;
}

const QuoteStep: FC<Props> = (
  {
    forwardButtonLabel,
    nextRoute,
    previousRoute,
    onSubmit,
    onAsyncSubmit,
    isFormValid = true,
    children,
    title,
    description,
    sidebar,
    sidebarInfoPanel,
    showFooter = true,
    onGoBackClicked,
    additionalContent,
    afterHeader,
    isFinalFlowRoute
  }
) => {
  const dispatch = useAppDispatch();
  const {scrollToError} = useScrollToError({
    includeStops: true
  });
  const isResolveQuoteValidationFlowActive = useAppSelector(selectIsResolveQuoteValidationFlowActive);

  useEffect(() => {
    if (isResolveQuoteValidationFlowActive) scrollToError();
  }, [isResolveQuoteValidationFlowActive, scrollToError]);

  const getNextRoute = (): string | undefined => {
    if (!isResolveQuoteValidationFlowActive) return nextRoute;

    return quoteSteps.yourQuote.routes.new;
  };

  const getForwardButtonLabel = (): TranslationKey => {
    if (!isResolveQuoteValidationFlowActive) return forwardButtonLabel;

    return "aggregatorQuote.resolveErrorFlow.confirm";
  };

  const exitQuoteStepResolveValidationErrorFlow = (): void => {
    dispatch(exitResolveValidationErrorFlow());
  };

  return (
    <>
      <div className="quote-step">
        <ContentWithSidebarLayout
          header={<>
            <QuoteHeader/>
            {afterHeader}
          </>}
          footer={
            !showFooter ? undefined : (
              <FooterButtonGroup
                forwardButtonLabel={getForwardButtonLabel()}
                nextRoute={getNextRoute()}
                previousRoute={previousRoute}
                onSubmit={() => {
                  onSubmit?.();
                  scrollToError();
                }}
                afterForwardNavigation={isResolveQuoteValidationFlowActive ? exitQuoteStepResolveValidationErrorFlow : undefined}
                onAsyncSubmit={onAsyncSubmit}
                isFormValid={isFormValid}
                onGoBackClicked={onGoBackClicked}
                isFinalFlowRoute={isFinalFlowRoute || isResolveQuoteValidationFlowActive}
              />
            )
          }
          mainContent={<MainContent title={title} description={description}>{children}</MainContent>}
          sidebar={
            sidebar ? sidebar : <SidebarMenu/>
          }
          sidebarInfoPanel={sidebarInfoPanel}
          additionalContent={additionalContent}
        />
      </div>
    </>
  );
};

export default QuoteStep;