import {Policy, PolicyList} from "shared/dist/generated/graphql/resolvers-types";
import {Maybe} from "graphql/jsutils/Maybe";

export const policyListIsEmpty = (policyList: PolicyList): boolean => {
  return doesNotExistOrIsEmpty(policyList.active)
    && doesNotExistOrIsEmpty(policyList.upcoming)
    && doesNotExistOrIsEmpty(policyList.expired)
    && doesNotExistOrIsEmpty(policyList.cancelled);
};
const doesNotExistOrIsEmpty = (policies: Maybe<Policy[]> | undefined): boolean => {
  return !policies || policies.length === 0;
};

export const getInitialSelectedPolicy = (policyList: PolicyList | undefined): Policy => {
  if (!policyList) return {} as Policy;

  if (policyList.active && policyList.active.length > 0) return policyList.active[0];
  if (policyList.upcoming && policyList.upcoming.length > 0) return policyList.upcoming[0];
  if (policyList.expired && policyList.expired.length > 0) return policyList.expired[0];
  if (policyList.cancelled && policyList.cancelled.length > 0) return policyList.cancelled[0];

  return {} as Policy;
};
