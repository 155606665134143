import {FC} from "react";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./InsurerQuoteCardHeader.css";

interface Props {
  title: TranslationKey;
  description: TranslationKey;
  quoteReference: string;
  sequenceNumber: number;
}

const InsurerQuoteCardHeader: FC<Props> = (
  {
    title,
    description,
    quoteReference,
    sequenceNumber
  }) => (
  <div className="insurer-quote-card-header">
    <div className="insurer-quote-card-header__body">
      <span className="insurer-quote-card-header__body__title">
        <p>{lookupI18nString(title)}</p>
      </span>
      <p className="insurer-quote-card-header__body__description">{lookupI18nString(description)} {quoteReference}-{sequenceNumber}</p>
    </div>
  </div>
);

export default InsurerQuoteCardHeader;
