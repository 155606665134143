import React, {FC} from "react";
import PrimaryEmploymentQuestion from "../../../shared/questions/employment/PrimaryEmploymentQuestion";
import {EMPLOYMENT_STATUS} from "../../../shared/questions/employment/models/EmploymentStatusOption";
import {
  primaryEmploymentIndustryChanged,
  primaryEmploymentJobChanged,
  primaryEmploymentStatusChanged,
  primaryEmploymentWorkedInPastYearChanged
} from "../../redux/PersonalDetailsSlice";
import {isEmployed, isUnemployed} from "../../../shared/validation/ValidateEmploymentStatus";
import {logYourDetailsGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const ProposerPrimaryEmploymentQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const employmentStatus = useAppSelector(state => state.personalDetails.primaryEmployment.employmentStatus);
  const employedQuestionsActive = useAppSelector(state => isEmployed(state.personalDetails.primaryEmployment.employmentStatus.value));
  const unemployedQuestionsActive = useAppSelector(state => isUnemployed(state.personalDetails.primaryEmployment.employmentStatus.value));
  const industry = useAppSelector(state => state.personalDetails.primaryEmployment.industry);
  const job = useAppSelector(state => state.personalDetails.primaryEmployment.job);
  const workedInPastYear = useAppSelector(state => state.personalDetails.primaryEmployment.workedInPastYear);

  const onEmploymentStatusChanged = (selection: string): void => {
    const employmentStatus = EMPLOYMENT_STATUS.find(type => type.description === selection);

    if (employmentStatus) {
      logYourDetailsGoogleAnalyticsEvent({
        categorySuffix: "employment_status",
        action: "Answered",
      });
      dispatch(primaryEmploymentStatusChanged(employmentStatus));
    }
  };

  return (
    <PrimaryEmploymentQuestion
      id="proposer-primary-employment"
      title="personalDetails.primaryEmploymentQuestion"
      placeholder="personalDetails.primaryEmploymentQuestion.dropdownPlaceholder"
      employmentStatus={employmentStatus}
      industry={industry}
      job={job}
      workedInPastYear={workedInPastYear}
      employmentStatusChanged={onEmploymentStatusChanged}
      industryChanged={value => dispatch(primaryEmploymentIndustryChanged(value))}
      jobChanged={value => dispatch(primaryEmploymentJobChanged(value))}
      workedInPastYearChanged={value => dispatch(primaryEmploymentWorkedInPastYearChanged(value))}
      employedQuestionsActive={employedQuestionsActive}
      unemployedQuestionsActive={unemployedQuestionsActive}
      employedQuestions={{
        industryQuestionTitle: "personalDetails.primaryEmploymentQuestion.employed.industry.title",
        jobQuestionTitle: "personalDetails.primaryEmploymentQuestion.employed.job.title",
        industryQuestionTooltip: {
          title: "personalDetails.primaryEmploymentQuestion.employed.industry.tooltip.title",
          description: "personalDetails.primaryEmploymentQuestion.employed.industry.tooltip.description",
        },
        jobQuestionTooltip: {
          description: "personalDetails.primaryEmploymentQuestion.employed.job.tooltip.description",
        },
      }}
      unemployedQuestions={{
        workedInPastYearQuestionTitle: "personalDetails.primaryEmploymentQuestion.unemployed.hasWorkedInPastYear.title",
        previousJobQuestionTitle: "personalDetails.primaryEmploymentQuestion.unemployed.previousJob.title",
        previousJobQuestionTooltip: {
          description: "personalDetails.primaryEmploymentQuestion.unemployed.previousJob.tooltip.description"
        },
      }}
    />
  );
};

export default ProposerPrimaryEmploymentQuestion;
