import {PolicySetupQueryPolicyExtrasArgs, ProspectivePolicyExtra} from "shared/dist/generated/graphql/resolvers-types";
import {apolloClient} from "../../apollo/ApolloClientProvider";
import {
  GET_POLICY_EXTRAS,
  GetPolicyExtrasResult
} from "../../../pages/quote/vehicle/shared/quote-summary/components/policy-extras-selection/hooks/AvailablePolicyExtrasQuery";

export async function loadAvailablePolicyExtrasQuery(policyExtraStartDate: string): Promise<ProspectivePolicyExtra[]> {
  return new Promise((resolve, reject) => {
    apolloClient.query<GetPolicyExtrasResult, PolicySetupQueryPolicyExtrasArgs>({
        query: GET_POLICY_EXTRAS,
        variables: {policyExtraStartDate}
    })
      .then(result => resolve(result.data.policySetup.policyExtras))
      .catch(error => reject(error));
  });
}
