export const HOME_PAGE = "/";

export const QUOTE = "/quote";
export const NEW_QUOTE_BASE = `${QUOTE}/new`;
export const SAVED_QUOTE_BASE = `${QUOTE}/saved/:magicLinkId/:quoteReference?/:sequenceNumber?`;

export const YOUR_CAR = "/your-car";
export const YOUR_DETAILS = "/your-details";
export const YOUR_COVER = "/your-cover";
export const YOUR_QUOTE = "/your-quote";
export const COMPARE_QUOTES = "/compare-quotes";
export const PROTECTED_NO_CLAIMS_BONUS_INFORMATION = "/protected-no-claims-bonus-information";
export const REFUSED_QUOTE = "/refused-quote";
export const EXPIRED_QUOTE = "/expired-quote";
export const ADD_ADDITIONAL_DRIVER = "/add-additional-driver";
export const EDIT_ADDITIONAL_DRIVER = "/edit-additional-driver/:driverId";

export const QUOTE_PAYMENT_SUCCESS = "/quote/payment/success";
export const QUOTE_PAYMENT_SUCCESS_CONFETTI = "/quote/payment/success-confetti";
export const QUOTE_ANNUAL_PAYMENT = "/quote/payment/annual-payment";
export const QUOTE_MONTHLY_PAYMENT = "/quote/payment/monthly-payment";
export const QUOTE_MONTHLY_PAYMENT_DEPOSIT = "/quote/payment/monthly-payment/deposit";
export const QUOTE_ANNUAL_PAYMENT_BY_CARD = "/quote/payment/annual-payment-card-details";

export const LOGIN = "/login";
export const ACTIVATE = "/activate";
export const ACCOUNT_ALREADY_ACTIVATED = "/account-already-activated";
export const RESET_PASSWORD = "/reset-password";

export const PORTAL = "/portal";
export const POLICIES = "/portal/policies";
export const POLICY_SUMMARY = "/portal/policy";
export const POLICY_SUMMARY_BY_ID = "/portal/policy/:policyId";
export const POLICY_DOCUMENTS_BY_ID = "/portal/policy/:policyId/documents";
export const POLICY_RENEWALS_BY_ID = "/portal/policy/:policyId/renewals";
export const POLICY_RENEWALS_ANNUALISED_BREAKDOWN = "/portal/policy/:policyId/renewals/summary/breakdown";
export const POLICY_RENEWALS_CONFIRM_DETAILS = "/portal/policy/:policyId/renewals/summary/confirm-details";
export const POLICY_RENEWALS_PROTECTED_NO_CLAIMS_BONUS_INFORMATION = "/portal/policy/:policyId/renewals/summary/confirm-details/protected-no-claims-bonus-information";
export const POLICY_DOCUMENTS_UPCOMING_BY_ID = "/portal/policy/:policyId/documents/upcoming";
export const POLICY_DRIVERS_BY_ID = "/portal/policy/:policyId/drivers";
export const POLICY_DOCUMENTS = "/portal/policy/documents";
export const POLICY_DRIVERS = "/portal/policy/drivers";

export const RENEWAL_SUMMARY = "/portal/policy/:policyId/renewals/summary";
export const RENEWAL_ANNUAL_PAYMENT = "/portal/policy/:policyId/renewals/payment/annual-payment";
export const RENEWAL_MONTHLY_PAYMENT = "/portal/policy/:policyId/renewals/payment/monthly-payment";
export const DEPOSIT_PATH = "deposit";
export const RENEWAL_MONTHLY_PAYMENT_DEPOSIT = `/portal/policy/:policyId/renewals/payment/monthly-payment/${DEPOSIT_PATH}`;
export const RENEWAL_PAYMENT_SUCCESS = "/portal/policy/:policyId/renewals/payment/success";
export const CONFETTI_PATH = "confetti";
export const RENEWAL_PAYMENT_SUCCESS_CONFETTI = `/portal/policy/:policyId/renewals/payment/success/${CONFETTI_PATH}`;
export const PAY_BY_CARD_PATH = "card-details";
export const RENEWAL_ANNUAL_PAYMENT_BY_CARD = `/portal/policy/:policyId/renewals/payment/annual-payment/${PAY_BY_CARD_PATH}`;

export const SETTINGS = "/portal/settings";
export const CLAIMS = "/portal/claims";
export const UPLOADS = "/portal/uploads";

export const HELP = "/help";

export const COMPARE_NI_YOUR_QUOTE = "/quote/compare-ni/your-quote";
