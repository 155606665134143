import {RenewalDocuments} from "shared/dist/generated/graphql/resolvers-types";
import {apolloClient} from "../../apollo/ApolloClientProvider";
import {
  PORTAL_FETCH_RENEWAL_DOCUMENTS_QUERY,
  PortalFetchRenewalDocumentsResult
} from "shared/dist/graphql/queries/portal/RenewalDocumentsQuery";

export async function loadRenewalDocumentsQuery(policyId: string): Promise<RenewalDocuments> {
  return new Promise((resolve, reject) => {
    apolloClient.query<PortalFetchRenewalDocumentsResult>({
      query: PORTAL_FETCH_RENEWAL_DOCUMENTS_QUERY,
      variables: {policyId},
      fetchPolicy: "no-cache"
    }).then(result => resolve(result.data?.renewals.documents))
      .catch(error => reject(error));
  });
}