import React, {Dispatch, FC, SetStateAction} from "react";
import "./GetInTouchModal.css";
import Modal from "shared-components/src/modals/Modal";
import Button from "shared-components/src/buttons/button/Button";
import {openZendeskChat} from "../../../../../zendesk/Zendesk";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";

interface Props {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>
}

const GetInTouchModal: FC<Props> = ({isVisible, setIsVisible}) => {
  return (
    <Modal
      className="get-in-touch-modal"
      displayCloseButton={true}
      visible={isVisible}
      onDismiss={() => setIsVisible(false)}
      header={
        <h1>{lookupI18nString("portal.renewals.getInTouchModal.title")}</h1>
      }
      body={
        <p tabIndex={0}>{lookupI18nString("portal.renewals.getInTouchModal.description")}</p>
      }
      footer={
        <Button
          variant="primary"
          onClick={() => {
            openZendeskChat();
            setIsVisible(false);
          }}
          data-testid="get-in-touch-button"
        >
          {lookupI18nString("portal.renewals.getInTouchModal.buttonText")}
        </Button>
      }/>
  );
};

export default GetInTouchModal;