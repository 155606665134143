import {gql} from "@apollo/client";
import {QuoteDocuments, QuoteQueryDocumentsArgs} from "shared/dist/generated/graphql/resolvers-types";
import {apolloClient} from "../../../apollo/ApolloClientProvider";

export interface QuoteDocumentsResult {
  quote: {
    documents: QuoteDocuments;
  };
}

export const GET_QUOTE_DOCUMENTS = gql`
  query QuoteDocumentsLookup(
    $quoteReference: String!,
    $schemeReference: String!,
    $coverType: String!,
    $effectiveDate: String!
  ) {
    quote {
      documents(
        quoteReference: $quoteReference
        schemeReference: $schemeReference
        coverType: $coverType
        effectiveDate: $effectiveDate
      ) {
        insuranceProductInformation
        policyBooklet
      }
    }
  }
`;

export async function quoteDocumentsQuery(queryQuoteDocumentsArgs: QuoteQueryDocumentsArgs): Promise<QuoteDocuments | undefined> {
  return new Promise((resolve, reject) => {
    apolloClient.query<QuoteDocumentsResult, QuoteQueryDocumentsArgs>({
      query: GET_QUOTE_DOCUMENTS,
      variables: queryQuoteDocumentsArgs,
    }).then(result => resolve(result.data.quote.documents))
      .catch(error => reject(error));
  });
}
