import {CognitoUser, CognitoUserAttribute, CognitoUserPool, ICognitoStorage, ICognitoUserAttributeData} from "amazon-cognito-identity-js";
import {logger} from "../../../../utils/logging/Logger";
import {noticeError} from "../../../../newrelic/NoticeError";

export interface InternalCognitoUser extends CognitoUser {
  storage: ICognitoStorage,
  pool: CognitoUserPool,
  username: string
}

function getCacheKey(cognitoUser: InternalCognitoUser): string {
  const clientId = cognitoUser.pool.getClientId();
  return `CognitoIdentityServiceProvider.${clientId}.${cognitoUser.username}.userAttributes`;
}

function serializeUserAttributes(userAttributes: CognitoUserAttribute[]): string {
  return JSON.stringify(userAttributes);
}

function deserializeUserAttributes(userAttributesStr: string): CognitoUserAttribute[] {
  return JSON.parse(userAttributesStr).map((attrs: ICognitoUserAttributeData) =>
    new CognitoUserAttribute(attrs));
}

export function getCachedUserAttributes(cognitoUser: InternalCognitoUser): CognitoUserAttribute[] | undefined {
  const cachedUserAttributes = cognitoUser.storage.getItem(getCacheKey(cognitoUser));
  if (!cachedUserAttributes) return;
  return deserializeUserAttributes(cachedUserAttributes);
}

export function setCachedUserAttributes(cognitoUser: InternalCognitoUser, userAttributes: CognitoUserAttribute[]): void {
  cognitoUser.storage.setItem(getCacheKey(cognitoUser), serializeUserAttributes(userAttributes));
}

export function clearCachedUserAttributes(cognitoUser: InternalCognitoUser): void {
  cognitoUser.storage.removeItem(getCacheKey(cognitoUser));
}

export async function getUserAttributes(cognitoUser: InternalCognitoUser): Promise<CognitoUserAttribute[] | undefined> {
  return new Promise<CognitoUserAttribute[] | undefined>(resolve => {
    const cachedUserAttributes = getCachedUserAttributes(cognitoUser);

    if (cachedUserAttributes) {
      return resolve(cachedUserAttributes);
    }

    cognitoUser.getUserAttributes((error: Error | undefined, userAttributes: CognitoUserAttribute[] | undefined) => {
        if (error || !userAttributes) {
          logger.error("Failed to get user attributes", error);
          noticeError("Failed to get cognito user attributes", {username: cognitoUser.username});
          return resolve(undefined);
        }

        if (userAttributes.length === 0) {
          logger.error("User attributes are empty", error);
          noticeError("User attributes are empty", {username: cognitoUser.username});
          return resolve(undefined);
        }

        setCachedUserAttributes(cognitoUser, userAttributes);
        return resolve(userAttributes);
      }
    );
  });
}