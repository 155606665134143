import {isBefore, isSameDay} from "date-fns";
import {subYears} from "date-fns/fp";
import {
  validationFailure,
  ValidationResult,
  validationSuccess,
  Validator
} from "shared-components/dist/utils/validation/Validator";
import {lastValidPolicyStartDateFrom} from "../services/Dates";
import {now} from "shared/dist/stdlib/Dates";

export function isAboveMinimumAge(): Validator<Date> {
  return (dateOfBirth: Date): ValidationResult => {
    return isAboveMinimumAgeOn(lastValidPolicyStartDateFrom(now()), dateOfBirth)
      ? validationSuccess
      : validationFailure("personalDetails.dateOfBirthQuestion.errors.isTooYoung.before");
  };
}

export function isAboveMinimumAgeOn(target: Date, dateOfBirth: Date): boolean {
  const seventeenYearsAgo = minusSeventeenYears(target);

  return isBefore(dateOfBirth, seventeenYearsAgo) || isSameDay(dateOfBirth, seventeenYearsAgo);
}

const minusSeventeenYears = subYears(17);