import {useEffect, useState} from "react";
import {
  getCurrentUserDetails,
  UserDetails
} from "../../../../user/attributes/services/get/GetCurrentUserDetailsService";
import {logger} from "../../../../utils/logging/Logger";

interface CurrentUserInformationResponse {
  currentUserDetails: UserDetails | undefined;
  updateUserDetails: () => Promise<void>;
}

export function useCurrentUserDetails(): CurrentUserInformationResponse {
  const [currentUserDetails, setCurrentUserDetails] = useState<UserDetails>();

  function updateUserDetails(): Promise<void> {
    return getCurrentUserDetails()
      .then(setCurrentUserDetails)
      .catch(err => {
        logger.error("Unable to get current user details", err);
      });
  }

  useEffect(() => {
    updateUserDetails();
  }, []);

  return {
    currentUserDetails,
    updateUserDetails
  };
}
