export enum ReminderType {
  UNVERIFIED_EMAIL_ADDRESS,
  UNVERIFIED_PHONE_NUMBER,
  UPLOAD_DOCUMENTS,
  REVIEW_IN_PROGRESS,
  WITH_CUSTOMER,
  POLICY_VERIFIED,
  PENDING_CANCELLATION,
  RENEWALS_AWAITING_INVITE,
  RENEWAL_OFFERED
}