import {FC} from "react";
import Button from "shared-components/dist/buttons/button/Button";
import "./ReducedHelpCard.css";
import {Comment} from "@mui/icons-material";
import {openZendeskChat} from "../../../../../../../zendesk/Zendesk";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import FormattedMessage from "shared-components/src/translations/components/FormattedMessage";

const ReducedHelpCard: FC = () => (
  <div className="renewal-reduced-help-card">
    <p className="renewal-reduced-help-card__title">
      {lookupI18nString("helpCard.needHelp")}
    </p>

    <p className="renewal-reduced-help-card__description">
      <FormattedMessage
        id="helpCard.needAHand"
        values={{
          a: (phoneNumber: string) => <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
        }}
      />
    </p>

    <Button
      data-testid="renewal-reduced-help-card__chat-button"
      variant="ghost"
      onClick={openZendeskChat}
    >
      <Comment/>
      {lookupI18nString("helpCard.chatToUs")}
    </Button>
  </div>
);

export default ReducedHelpCard;