import React, {FC} from "react";
import Question from "../../Question";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import Dropdown from "shared-components/dist/form/dropdown/Dropdown";
import {Status} from "shared-components/dist/status-indicator/Status";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";

interface Props {
  id: string;
  title: TranslationKey;
  options: string[];
  description?: TranslationKey;
  status?: Status;
  value?: string;
  errorMessage?: TranslationKey;
  tooltip?: TooltipProps;
  onChange?: (value: string) => void;
  placeholder?: TranslationKey;
  prefix?: TranslationKey;
  hideSpacer?: boolean;
  displayValues?: string[];
  autoFocus?: boolean;
  className?: string;
}

const DropdownQuestion: FC<Props> = ({
  id,
  title,
  description,
  status = "default",
  errorMessage,
  tooltip,
  value,
  onChange,
  placeholder,
  options,
  prefix,
  hideSpacer,
  displayValues,
  autoFocus,
  className,
  children
}) => {
  return (
    <Question
      id={id}
      title={title}
      description={description}
      status={status}
      errorMessage={errorMessage}
      tooltip={tooltip}
      className={className}
      hideSpacer={hideSpacer}
    >
      <>
        <Dropdown
          id={id}
          name={id}
          value={value}
          onChange={(event) => onChange?.(event.target.value)}
          placeholder={placeholder}
          options={options}
          prefix={prefix}
          displayValues={displayValues}
          autoFocus={autoFocus}
        />
        {children}
      </>
    </Question>
  );
};

export default DropdownQuestion;