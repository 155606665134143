import {EmploymentStatus} from "shared/dist/generated/graphql/resolvers-types";
import {fullMonthsBetween, now} from "shared/dist/stdlib/Dates";
import _ from "lodash";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {monthsToYears} from "date-fns";

export const isIndustryApplicableToEmploymentStatus = (employmentStatus: EmploymentStatus): boolean => [EmploymentStatus.Employed, EmploymentStatus.SelfEmployed].includes(employmentStatus);
export const isJobTitleApplicableToEmploymentStatus = (employmentStatus: EmploymentStatus, jobTitle: string): boolean => {
  if (employmentStatus === EmploymentStatus.Unemployed && jobTitle === "Unemployed") return false;

  return [EmploymentStatus.Employed, EmploymentStatus.SelfEmployed, EmploymentStatus.Retired, EmploymentStatus.Unemployed].includes(employmentStatus);
};

export function titleInfersGender(title: string): boolean {
  const titlesWithInferredGender = ["Mr", "Mrs", "Ms", "Miss"];

  return titlesWithInferredGender.includes(title);
}

// TODO ONLINE REBROKE - remove addition of year when rebroke sync is enabled
// 3 month range allows for timings between getting a quote and buying a quote and 60 days out renewal timing
export function haveLivedInUkSinceBirth(dateOfBirth: string, ukResidencyDurationMonths: number): boolean {
  const ukResidencyDurationMonthsPlusYear = ukResidencyDurationMonths + 12;
  const policyHolderAgeInMonths = fullMonthsBetween(new Date(dateOfBirth), now());

  const lowerRange = ukResidencyDurationMonthsPlusYear - 3;
  const upperRange = ukResidencyDurationMonthsPlusYear + 3;

  return _.inRange(policyHolderAgeInMonths, lowerRange, upperRange);
}

export function convertDurationInMonthsToYears(monthlyDuration: number): string {
  // TODO ONLINE REBROKE - remove addition of year when rebroke sync is enabled
  const durationInYears = monthsToYears(monthlyDuration) + 1;

  if (durationInYears < 2) return lookupI18nString("structure.durations.1Year");

  return `${lookupI18nString({
    id: "structure.durations.years",
    templateVariables: {
      prefix: durationInYears
    }
  })}`;
}
