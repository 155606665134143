import {FC} from "react";
import InlineQuestion from "../../../../../../../../../../structure/questions/inline-question/InlineQuestion";
import RadioConfirmation from "shared-components/dist/form/radio-confirmation/RadioConfirmation";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./ContactDetailsConfirmationQuestion.css";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {Status} from "shared-components/dist/status-indicator/Status";

interface Props {
  value: boolean | undefined;
  errorMessage?: TranslationKey;
  onChange: (value: boolean) => void;
  status: Status
}

const ContactDetailsConfirmationQuestion: FC<Props> = ({onChange, value, errorMessage, status}) => (
  <div className="contact-details-confirmation-question">
    <p>{lookupI18nString("quoteDetails.contactDetails.confirmation.question.label")}</p>
    <InlineQuestion
      id="quoteDetails.contactDetails.confirmation.question.container"
      status={status}
      errorMessage={errorMessage}
    >
      <RadioConfirmation
        name="quoteDetails.contactDetails.confirmation.radioQuestion"
        selectedGroupOption={value}
        setSelectedGroupOption={onChange}
      />
    </InlineQuestion>
  </div>
);

export default ContactDetailsConfirmationQuestion;