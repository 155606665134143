import {isAfter, isBefore, isEqual, startOfDay, subDays} from "date-fns";

export function isCompanyCarBonusValid(coverStartDate: Date, companyCarPolicyExpiryDate: Date): boolean {
  const startOfDayCoverStartDate = startOfDay(coverStartDate);
  const startOfDayCompanyCarPolicyExpiryDate = startOfDay(companyCarPolicyExpiryDate);

  return isEndDateYoungerThan30DaysBeforeCoverStartDate(startOfDayCoverStartDate, startOfDayCompanyCarPolicyExpiryDate) && isEndDateEqualOrBeforeCoverStartDate(startOfDayCoverStartDate, startOfDayCompanyCarPolicyExpiryDate);
}

function isEndDateYoungerThan30DaysBeforeCoverStartDate(startOfDayCoverStartDate: Date, startOfDayCompanyCarPolicyExpiryDate: Date): boolean {
  const coverStartDateMinus30Days = subDays(startOfDayCoverStartDate, 30);

  return isAfter(startOfDayCompanyCarPolicyExpiryDate, coverStartDateMinus30Days) || isEqual(startOfDayCompanyCarPolicyExpiryDate, coverStartDateMinus30Days);
}

function isEndDateEqualOrBeforeCoverStartDate(startOfDayCoverStartDate: Date, startOfDayCompanyCarPolicyExpiryDate: Date): boolean {
  return isEqual(startOfDayCompanyCarPolicyExpiryDate, startOfDayCoverStartDate) || isBefore(startOfDayCompanyCarPolicyExpiryDate, startOfDayCoverStartDate);
}