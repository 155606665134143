import React, {FC} from "react";
import {logYourCoverGoogleAnalyticsEvent} from "../../../../../../../../utils/analytics/MotorAnalytics";
import {yearsCompanyCarBonusChanged} from "../../../../redux/CoverDetailsSlice";
import "./YearsCompanyCarBonusQuestion.css";
import DurationQuestion
  from "../../../../../../../../structure/questions/question/variants/duration-question/DurationQuestion";
import {QuestionProps} from "../../../../../../../../structure/questions/question/Question";
import {useDurationDropdownProps} from "shared-components/dist/form/duration-dropdown/hooks/useDurationDropdownProps";
import {useAppDispatch, useAppSelector} from "../../../../../../../../redux/Hooks";

const YearsCompanyCarBonusQuestion: FC = () => {
  const yearsCompanyCarBonus = useAppSelector(state => state.coverDetails.yearsCompanyCarBonus);
  const dispatch = useAppDispatch();

  const onYearsCompanyCarBonusChanged = (duration: Duration): void => {
    logYourCoverGoogleAnalyticsEvent({
      categorySuffix: "years_company_ncb",
      action: duration.years ? duration.years.toString() : "0",
    });
    dispatch(yearsCompanyCarBonusChanged(duration));
  };

  const questionProps: QuestionProps = {
    id: "years-company-car-bonus-question",
    title: "coverDetails.companyCarBonusQuestion.title",
    tooltip: {description: "coverDetails.companyCarBonusQuestion.tooltip.description"},
    errorMessage: yearsCompanyCarBonus.errorMessage,
    status: yearsCompanyCarBonus.status,
    hideSpacer: true,
    className: "years-company-car-bonus-question"
  };

  const {yearsDropdown, monthsDropdown} = useDurationDropdownProps({
    id: "years-company-car-bonus-question-dropdown",
    duration: yearsCompanyCarBonus.value,
    onChange: onYearsCompanyCarBonusChanged,
    maxYear: 5,
    appendPlusToMaxNumber: true,
    hasLessThanOneYear: false
  });

  return (
    <DurationQuestion
      question={questionProps}
      yearsDropdown={yearsDropdown}
      monthsDropdown={monthsDropdown}
      hideMonths={true}
    />
  );
};

export default YearsCompanyCarBonusQuestion;