import {gql} from "@apollo/client";
import {VehicleOptions} from "shared/dist/generated/graphql/resolvers-types";

export const TRIM_LEVELS_LOOKUP_QUERY = gql`
  query TrimLevelsLookup($filters: VehicleOptionsFilters!) {
    vehicle {
      vehicleOptions(optionsFilters: $filters) {
        trim
      }
    }
  }
`;

export interface TrimLevelLookupResult {
  vehicleOptions: Pick<VehicleOptions, "trim">;
}
