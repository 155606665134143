import React, {FC} from "react";
import QuoteJourneyStop, {QuoteJourneyStopType} from "../../stops/quote-journey-stop/QuoteJourneyStop";

interface Props {
  isVisible: boolean;
}

const LicenceCountryOfIssueStop: FC<Props> = ({isVisible}) => (
  <QuoteJourneyStop
    stopProps={{
      visible: isVisible,
      title: "quoteJourney.shared.licenceCountryOfIssueQuestion.stop.title"
    }}
    stopType={QuoteJourneyStopType.HardStop}
  />
);

export default LicenceCountryOfIssueStop;