import React, {FC} from "react";
import "./PlainHeader.css";
import {ReactComponent as LogoPrimaryDark} from "shared-components/dist/assets/icons/logos/stroll/stroll-logo-primary-dark.svg";
import RetrieveQuoteHeaderButton from "../../buttons/retrieve-a-quote/RetrieveQuoteHeaderButton";
import LoginButton from "../../buttons/login-button/LoginButton";

const PlainHeader: FC = () => (
  <div className="plain-header">
    <div className="plain-header__container">
      <div className="plain-header__logo">
        <LogoPrimaryDark/>
      </div>
      <div className="plain-header__container__buttons">
        <RetrieveQuoteHeaderButton/>
        <LoginButton/>
      </div>
    </div>
  </div>
);

export default PlainHeader;
