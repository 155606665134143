import {FC} from "react";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {toDecimalPlaces} from "shared/dist/stdlib/ToDecimalPlaces";
import {NoClaimsDiscount} from "shared/dist/generated/graphql/resolvers-types";

interface Props {
  discounts: NoClaimsDiscount[];
}

const NoClaimsDiscountTable: FC<Props> = ({discounts}) => (
  <table cellSpacing="0" cellPadding="0" id="discount-table">
    <thead>
    <tr>
      <th>{lookupI18nString("protectedNoClaimsDiscountInfo.discountTable.yearsNCD")}</th>
      <th>{lookupI18nString("protectedNoClaimsDiscountInfo.discountTable.averageDiscount")}</th>
    </tr>
    </thead>
    <tbody>
    {discounts.map(discount => (
      <tr
        role="row"
        key={discount.year}
      >
        <td>{discount.year} Year(s) NCD</td>
        <td>{toDecimalPlaces(discount.averageDiscount, 2)}%</td>
      </tr>
    ))}
    </tbody>
  </table>
);

export default NoClaimsDiscountTable;