import {MenuItem} from "./MenuItems";
import React, {FC} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./NavigationMenu.css";
import {isCurrentRoute} from "../../../../utils/navigation/NavigationHelpers";
import {FiberManualRecord} from "@mui/icons-material";
import LogoutButton from "../logout-button/LogoutButton";
import {
  EventCategoryPrefix,
  logGoogleAnalyticsEvent
} from "shared-components/dist/analytics/GoogleAnalyticsEventsService";

interface Props {
  menuItems: MenuItem[];
  googleAnalyticsPrefix: EventCategoryPrefix;
}

const NavigationMenu: FC<Props> = (
  {
    menuItems,
    googleAnalyticsPrefix
  }
) => {
  const location = useLocation();
  const navigate = useNavigate();

  const onButtonClick = (item: MenuItem): void => {
    navigate(item.path);
    logGoogleAnalyticsEvent({
      categoryPrefix: googleAnalyticsPrefix,
      categorySuffix: item.googleAnalyticsSuffix,
      action: "Click"
    });
  };

  return (
    <div className="navigation-menu">
      {
        menuItems.map(item => {
          const isActiveView = isCurrentRoute(item.path, location?.pathname);
          const locationClassName = isActiveView ? " navigation-menu__item--active" : "";

          return (
            <div
              className={`navigation-menu__item${locationClassName}`}
              key={lookupI18nString(item.title)}>

              <button onClick={() => onButtonClick(item)}>
                {lookupI18nString(item.title)}
              </button>

              <SubNavigationMenu subMenuItems={item.subMenuItems} onButtonClick={onButtonClick}/>
            </div>
          );
        })
      }
      <LogoutButton googleAnalyticsPrefix={googleAnalyticsPrefix}/>
    </div>
  );
};

interface SubMenuItemProps {
  subMenuItems: MenuItem[] | undefined;
  onButtonClick: (item: MenuItem) => void;
}

const SubNavigationMenu: FC<SubMenuItemProps> = (
  {
    subMenuItems,
    onButtonClick
  }
) => {
  const location = useLocation();

  if (!subMenuItems || subMenuItems.length === 0) return null;

  return (
    <div className="sub-navigation-menu">
      {
        subMenuItems.map(item => {
          const locationClassName = isCurrentRoute(item.path, location.pathname) ? " sub-navigation-menu__item--active" : "";

          return (
            <div
              className={`sub-navigation-menu__item${locationClassName}`}
              key={lookupI18nString(item.title)}>

              {locationClassName && <FiberManualRecord/>}

              <button onClick={() => onButtonClick(item)}>
                {lookupI18nString(item.title)}
              </button>
            </div>
          );
        })
      }
    </div>
  );
};

export default NavigationMenu;