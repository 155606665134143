import {RootState} from "../../../../../redux/Store";
import {createSelector} from "@reduxjs/toolkit";

const voluntaryExcessSelector = (state: RootState): number | undefined => state.quoteDetails.voluntaryExcess;
const compulsoryExcessSelector = (state: RootState): number | undefined => state.quoteDetails.compulsoryExcess;

export const totalPolicyExcessSelector = createSelector(
  voluntaryExcessSelector,
  compulsoryExcessSelector,
  (voluntaryExcess: number | undefined, compulsoryExcess: number | undefined): number => {
    const voluntaryExcessAsNumber = voluntaryExcess ?? 0;
    const compulsoryExcessAsNumber = compulsoryExcess ?? 0;

    return voluntaryExcessAsNumber + compulsoryExcessAsNumber;
  }
);