import {AppThunk, AppThunkDispatch, RootState} from "../../../../../../redux/Store";
import {quoteFinishedLoading, quoteLoading, quoteRetrievalStatusChanged, quoteStateReset,} from "../QuoteDetailsSlice";
import {loadProspectiveLoans} from "./LoadProspectiveLoans";
import {quoteReset, storedQuoteLoaded} from "../../../../../../redux/Actions";
import {QuoteRetrievalStatus} from "../../models/QuoteRetrievalStatus";
import {logGeneralQuoteGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {logger} from "../../../../../../utils/logging/Logger";
import {loadNoClaimsDiscountProtectionData} from "./LoadNoClaimsDiscountProtectionData";
import {canProtectNoClaimsBonusSelector} from "../../../your-cover/redux/selectors/CoverDetailsSelectors";
import {loadNoClaimsDiscountProtectionCost} from "./LoadNoClaimsDiscountProtectionCost";
import {now} from "shared/dist/stdlib/Dates";
import {loadQuotesQuery} from "../../../../../../graphql/queries/quote/load-quotes/LoadQuotesQuery";
import {QuotedLoaded} from "../../models/QuoteLoaded";
import {getQuotes} from "./GetQuotes";

export interface LoadQuoteParams {
  monthlyPaymentsEnabled: boolean;
  magicLinkId?: string;
  quoteReference?: string;
  sequenceNumber?: number;
}

export const loadQuotes = (params: LoadQuoteParams): AppThunk => async (dispatch, getState) => {
  const state = getState();
  dispatch(quoteLoading());
  dispatch(quoteStateReset());

  const quoteLoaded = await tryLoadQuote(dispatch, getState, params);
  if (quoteLoaded === QuotedLoaded.SUCCESS) {
    await loadAdditionalQuoteInfo(params.monthlyPaymentsEnabled, state, dispatch);
  }

  dispatch(quoteFinishedLoading(now().toISOString()));
};

export const loadAdditionalQuoteInfo = async (
  monthlyPaymentsEnabled: boolean,
  state: RootState,
  dispatch: AppThunkDispatch,
): Promise<void> => {
  const additionalQueries = [];
  const hasProtectedNoClaimsBonus = state.coverDetails.protectNoClaimsBonus.value;

  additionalQueries.push(loadMonthlyPayments(dispatch, monthlyPaymentsEnabled));
  if (canProtectNoClaimsBonusSelector(state)) additionalQueries.push(dispatch(loadNoClaimsDiscountProtectionData()));
  if (hasProtectedNoClaimsBonus) additionalQueries.push(dispatch(loadNoClaimsDiscountProtectionCost()));

  await Promise.all(additionalQueries);
};

async function loadMonthlyPayments(dispatch: AppThunkDispatch, monthlyPaymentsEnabled: boolean): Promise<void> {
  try {
    if (monthlyPaymentsEnabled) await dispatch(loadProspectiveLoans());
    dispatch(quoteRetrievalStatusChanged(QuoteRetrievalStatus.SUCCESS));
  } catch (error) {
    logger.error("Failed to load close brother loans", error);
    dispatch(quoteRetrievalStatusChanged(QuoteRetrievalStatus.NOT_FOUND));
  }
}

async function tryLoadQuote(dispatch: AppThunkDispatch, getState: () => RootState, params: LoadQuoteParams): Promise<QuotedLoaded> {
  if (params.magicLinkId && params.quoteReference && params.sequenceNumber) {
    return await loadSavedQuote(dispatch, params.magicLinkId, params.quoteReference, params.sequenceNumber);
  } else {
    return await getQuotes(dispatch, getState());
  }
}

async function loadSavedQuote(
  dispatch: AppThunkDispatch,
  magicLinkId: string,
  quoteReference: string,
  sequenceNumber: number
): Promise<QuotedLoaded> {
  dispatch(quoteReset());
  const savedQuote = await loadQuotesQuery(magicLinkId, quoteReference, sequenceNumber);

  if (!savedQuote) {
    dispatch(quoteRetrievalStatusChanged(QuoteRetrievalStatus.NOT_FOUND));
    logGeneralQuoteGoogleAnalyticsEvent({
      categorySuffix: "quote_provided",
      action: "Failed"
    });
    return QuotedLoaded.FAILURE;
  }

  dispatch(storedQuoteLoaded(savedQuote));
  logGeneralQuoteGoogleAnalyticsEvent({
    categorySuffix: "quote_provided",
    action: "Success"
  });
  return QuotedLoaded.SUCCESS;
}
