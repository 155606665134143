import {gql} from "@apollo/client";
import {
  EmailLatestSavedQuoteResult,
  QuoteQueryEmailLatestSavedQuoteArgs
} from "shared/dist/generated/graphql/resolvers-types";
import {apolloClient} from "../../../apollo/ApolloClientProvider";

const EMAIL_LATEST_SAVED_QUOTE = gql`
  query EmailLatestSavedQuote($email: String!) {
    quote {
      emailLatestSavedQuote(email: $email) {
        email
      }
    }
  }
`;

export function emailLatestSavedQuote(email: string): Promise<void> {
  return new Promise((resolve, reject) => {
    apolloClient.query<EmailLatestSavedQuoteResult, QuoteQueryEmailLatestSavedQuoteArgs>({
      query: EMAIL_LATEST_SAVED_QUOTE,
      variables: {
        email
      },
      fetchPolicy: "network-only"
    }).then(() => resolve())
      .catch(error => reject(error));
  });
}