import React, {FC, useEffect, useState} from "react";
import ContentWithSidebarLayout from "../../../../../../structure/layouts/content-with-sidebar-layout/ContentWithSidebarLayout";
import PriorQuoteHeader from "./components/prior-quote-header/PriorQuoteHeader";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {loadQuotes, LoadQuoteParams} from "../../redux/thunks/LoadQuotes";
import "./PriorQuote.css";
import {isYourQuoteValidSelector} from "../../redux/selectors/QuoteDetailsSelectors";
import {quoteRetrievalStatusChanged} from "../../redux/QuoteDetailsSlice";
import PlainHeader from "../../../../../../structure/navigation/plain-header/PlainHeader";
import {flagUnansweredQuoteDetailsQuestions} from "../../validation/FlagUnansweredQuoteDetailsQuestions";
import QuoteSummary from "../../../shared/quote-summary/QuoteSummary";
import QuoteNotFound from "./components/quote-not-found/QuoteNotFound";
import {QuoteRetrievalStatus} from "../../models/QuoteRetrievalStatus";
import QuoteLoading from "../../../shared/quote-loading/QuoteLoading";
import {useEffectOnFirstRender} from "shared-components/dist/utils/hooks/UseEffectOnFirstRender";
import {useExpiredSavedQuoteDetection} from "./hooks/ExpiredSavedQuoteDetection";
import CoverStartDateExpiredModal from "./components/cover-start-date-expired-modal/CoverStartDateExpiredModal";
import {useScrollToError} from "../../../../../../utils/validation/hooks/ScrollToError";
import {logYourQuoteGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import PriceGuaranteeExpiredModal from "./components/price-guarantee-expired-modal/PriceGuaranteeExpiredModal";
import {useExpiredPriceGuaranteeDetection} from "./hooks/ExpiredPriceGuaranteeDetection";
import SaveQuoteSidebar from "../shared/save-quote-sidebar/SaveQuoteSidebar";
import StepTitle from "../../../../../../structure/questions/step-title/StepTitle";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {isCoverStartDateExpiredSelector} from "../../../your-cover/redux/selectors/CoverDetailsSelectors";
import FooterButtonGroup from "../../../shared/quote-step/footer-button-group/FooterButtonGroup";
import {useFeatureFlags} from "shared-components/dist/feature-flags/hooks/UseFeatureFlags";
import {useExpiredQuoteStartDateDetection} from "../../hooks/ExpiredQuoteStartDateValidation";
import {useProposerEmailIsUniqueValidation} from "../../hooks/ProposerEmailIsUniqueValidation";
import Button from "shared-components/dist/buttons/button/Button";
import {quoteSteps} from "../../../shared/quote-step/QuoteSteps";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";
import {refreshQuotes} from "../../../shared/redux/RefreshQuotes";
import {shouldAllowUserToCompareQuotesSelector} from "../../redux/selectors/CompareYourQuotesSelectors";
import {selectPaymentNavigationRoute} from "../../../../../../redux/selectors/PolicyPaymentSelectors";

const PriorQuote: FC = () => {
  const dispatch = useAppDispatch();
  const isYourQuoteValid = useAppSelector(isYourQuoteValidSelector);
  const quoteRetrievalStatus = useAppSelector(state => state.quoteDetails.quoteRetrievalStatus);
  const paymentNavigationRoute = useAppSelector(selectPaymentNavigationRoute);
  const isCoverStartDateExpired = useAppSelector(isCoverStartDateExpiredSelector);
  const firstName = useAppSelector(state => state.personalDetails.firstName.value);
  const shouldAllowUserToCompareQuotes = useAppSelector(shouldAllowUserToCompareQuotesSelector);
  const isRequoteRequired = useAppSelector(state => state.quoteDetails.isRequoteRequired);

  const {monthlyPaymentsFlag} = useFeatureFlags();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    magicLinkId,
    quoteReference,
    sequenceNumber
  } = useParams<{ magicLinkId: string, quoteReference: string, sequenceNumber: string }>();
  const {scrollToError} = useScrollToError();
  const {expiredPriceGuaranteeModalVisible, hideExpiredPriceGuaranteeModal} = useExpiredPriceGuaranteeDetection();
  const [coverStartDateExpiredModalVisible, setCoverStartDateExpiredModalVisible] = useState(false);
  const {
    validateQuoteStartDate,
    isCoverStartDateValid
  } = useExpiredQuoteStartDateDetection(setCoverStartDateExpiredModalVisible);
  const {validateProposerEmail, isProposerEmailValid} = useProposerEmailIsUniqueValidation();
  const isFormValid = isYourQuoteValid && isCoverStartDateValid && isProposerEmailValid;

  useExpiredSavedQuoteDetection();

  useEffectOnFirstRender(() => {
    const loadQuoteParams: LoadQuoteParams = {
      monthlyPaymentsEnabled: monthlyPaymentsFlag,
      magicLinkId,
      quoteReference,
      sequenceNumber: sequenceNumber ? parseInt(sequenceNumber) : undefined
    };
    if (quoteRetrievalStatus === QuoteRetrievalStatus.SUCCESS) {
      dispatch(loadQuotes(loadQuoteParams));
    } else {
      dispatch(quoteRetrievalStatusChanged(QuoteRetrievalStatus.LOADING));
      dispatch(loadQuotes(loadQuoteParams));
    }
  });

  useEffect(() => {
    if (isCoverStartDateExpired && quoteRetrievalStatus === QuoteRetrievalStatus.SUCCESS) {
      setCoverStartDateExpiredModalVisible(true);
    }
  }, [isCoverStartDateExpired, quoteRetrievalStatus]);

  useEffect(() => {
    if (isRequoteRequired) {
      dispatch(refreshQuotes({monthlyPaymentsEnabled: monthlyPaymentsFlag}));
    }

    // we only want this to run when this page has been navigated to
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const onSubmit = (): void => {
    validateQuoteStartDate();
    dispatch(flagUnansweredQuoteDetailsQuestions());
    logYourQuoteGoogleAnalyticsEvent({
      categorySuffix: "go_to_next_page",
      action: isYourQuoteValid ? "Success" : "Failure"
    });
    scrollToError();
  };

  const onNavigateAway = (): void => {
    logYourQuoteGoogleAnalyticsEvent({
      categorySuffix: "compare_quotes",
      action: "Click"
    });

    navigate(quoteSteps.yourQuote.routes.compare, {state: {fromQuotePage: true}});
  };

  if (quoteRetrievalStatus === QuoteRetrievalStatus.SUCCESS) {
    return (
      <>
        <ContentWithSidebarLayout
          className="prior-quote"
          header={
            <>
              <PriorQuoteHeader
                route={paymentNavigationRoute}
                isFormValid={isYourQuoteValid}
                validateForm={onSubmit}
              />
            </>
          }
          mainContent={
            <>
              <div className="main-content__title">
                <StepTitle title={"priorQuote.title"}/>
              </div>
              <div className="main-content__description">
                {lookupI18nString({
                  id: "priorQuote.description",
                  templateVariables: {firstName: firstName ?? ""}
                })}
              </div>

              <QuoteSummary/>

              <FooterButtonGroup
                forwardButtonLabel="quoteDetails.footerButton"
                nextRoute={paymentNavigationRoute}
                onSubmit={onSubmit}
                isFormValid={isFormValid}
                onAsyncSubmit={validateProposerEmail}
                isFinalFlowRoute
              />
            </>
          }
          sidebar={<SaveQuoteSidebar/>}
          additionalContent={shouldAllowUserToCompareQuotes
            ? <Button
              variant="link"
              onClick={onNavigateAway}
              className="refine-your-quote__navigate-button"
              id="navigateButton"
            >
              {lookupI18nString("quoteDetails.goBack")}
            </Button>
            : undefined
          }
        />

        <PriceGuaranteeExpiredModal
          onDismiss={hideExpiredPriceGuaranteeModal}
          visible={expiredPriceGuaranteeModalVisible}
        />
        <CoverStartDateExpiredModal
          visible={coverStartDateExpiredModalVisible}
          onDismiss={() => setCoverStartDateExpiredModalVisible(false)}
        />
      </>
    );
  }

  if (quoteRetrievalStatus === QuoteRetrievalStatus.NOT_FOUND) {
    return (
      <div className="prior-quote--not-found">
        <PlainHeader/>
        <QuoteNotFound/>
      </div>
    );
  }

  return <QuoteLoading/>;
};

export default PriorQuote;
