import {FC} from "react";
import ExpandableCard from "../expandable-card/ExpandableCard";
import Button from "shared-components/dist/buttons/button/Button";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {Comment} from "@mui/icons-material";
import "./NotRenewingCard.css";
import {openZendeskChat} from "../../../../../../../zendesk/Zendesk";

const NotRenewingCard: FC = () => (
  <ExpandableCard
    title="portal.renewals.summary.footer.notRenewing.title"
    description="portal.renewals.summary.footer.notRenewing.description"
    className="not-renewing-card"
  >
    <Button
      variant="ghost"
      expanded
      onClick={openZendeskChat}
      data-testid="not-renewing-card__zendesk-button"
    >
      <Comment/>
      {lookupI18nString("helpCard.chatToUs")}
    </Button>

    <p>{lookupI18nString("portal.renewals.summary.footer.notRenewing.talkToUs")}</p>

    <a href="tel:08000113121" data-testid="not-renewing-card__phone-number">
      {lookupI18nString("portal.renewals.summary.footer.notRenewing.phoneNumber")}
    </a>
  </ExpandableCard>
);

export default NotRenewingCard;