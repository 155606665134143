import {Selector} from "react-redux";
import {RootState} from "../Store";
import {FormField} from "shared-components/dist/models/form-field/FormField";
import {Validator} from "shared-components/dist/utils/validation/Validator";
import {ActionCreatorWithoutPayload, ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {useAppDispatch, useAppSelector} from "../Hooks";
import {useCallback} from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface CrossFieldValidator<Value, CrossFieldArgument = any> {
  argumentSelector: Selector<RootState, CrossFieldArgument>;
  validatorsForArgument: readonly CrossValidatorFunction<Value, CrossFieldArgument>[]
}
type CrossValidatorFunction<Value, CrossFieldArgument> = (otherField: CrossFieldArgument) => Validator<Value>;

export const buildCrossFieldValidator = <Value, CrossFieldArgument>(
  argumentSelector: Selector<RootState, CrossFieldArgument>,
  validatorsForArgument: CrossValidatorFunction<Value, CrossFieldArgument>[]
): CrossFieldValidator<Value, CrossFieldArgument> => ({
  argumentSelector,
  validatorsForArgument
});

export interface ReduxQuestion<
  Value,
  Field = FormField<Value>,
  OnChangePayload = Value extends Date ? string : Value
> {
  selector: Selector<RootState, Field>;
  validators?: readonly Validator<Value>[];
  crossFieldValidators?: readonly CrossFieldValidator<Value>[];
  onInvalid?: ActionCreatorWithPayload<TranslationKey>;
  onChange: ActionCreatorWithPayload<OnChangePayload>;
  onReset?: ActionCreatorWithoutPayload;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SectionQuestionsRecord = Record<string, ReduxQuestion<any, FormField<any>>>

export type Question<QuestionToMap> = QuestionToMap extends ReduxQuestion<infer Value, infer Field, infer OnChangePayload> ? {
  formField: Field,
  validators: Validator<Value>[];
  onInvalid: (errorMessage: TranslationKey) => void;
  onChange: (value: OnChangePayload) => void;
  onReset: () => void
} : never

export const buildUseReduxQuestionFromRecord = <QuestionRecord extends SectionQuestionsRecord>(
  record: QuestionRecord
): <QuestionName extends keyof QuestionRecord>(key: QuestionName) => Question<QuestionRecord[QuestionName]> => {
  return <QuestionName extends keyof QuestionRecord>(key: QuestionName) => {
    const question: QuestionRecord[QuestionName] = record[key];
    const dispatch = useAppDispatch();
    const formField = useAppSelector(question.selector);
    const crossFieldValidators = useBuildCrossFieldValidators(question.crossFieldValidators);

    return {
      formField,
      validators: [...question.validators ?? [], ...crossFieldValidators],
      onInvalid: useCallback((errorMessage) => {
        if (question.onInvalid) dispatch(question.onInvalid(errorMessage));
      }, [question, dispatch]),
      onChange: useCallback((value): void => {
        dispatch(question.onChange(value));
      }, [question, dispatch]),
      onReset: useCallback(() => {
        if (question.onReset) dispatch(question.onReset());
      }, [question, dispatch])
    } as Question<QuestionRecord[QuestionName]>;
  };
};

const useBuildCrossFieldValidators = <T>(validators: readonly CrossFieldValidator<T>[] | undefined): Validator<T>[] => {
  const mappedValidators: Validator<T>[] = [];

  for (const crossFieldValidator of validators ?? []) {
    // we can disable this rule as the array is read only
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const argument = useAppSelector(crossFieldValidator.argumentSelector);

    mappedValidators.push(...crossFieldValidator.validatorsForArgument.map(validator => (
      validator(argument)
    )));
  }

  return mappedValidators;
};