import React, {FC, useEffect, useState} from "react";
import PaymentStep from "../../shared/payment-step/PaymentStep";
import Button from "shared-components/dist/buttons/button/Button";
import CloseBrothersSidebarContent from "../shared/close-brothers-sidebar-content/CloseBrothersSidebarContent";
import "./DirectDebitSetup.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {isMonthlyPaymentValid as isMonthlyPaymentValidSelector} from "./redux/MonthlyPaymentSelectors";
import Spacer from "shared-components/dist/spacer/Spacer";
import BankAccountForm from "./components/bank-account-form/BankAccountForm";
import DirectDebitPaymentInfo from "./components/direct-debit-payment-info/DirectDebitPaymentInfo";
import {useNavigate} from "react-router-dom";
import {DEPOSIT_PATH, QUOTE_MONTHLY_PAYMENT_DEPOSIT} from "../../../../router/models/Routes";
import {useEcommerceBeginCheckout} from "../../../../utils/analytics/hooks/EcommerceBeginCheckout";
import {CLOSE_BROTHERS_INTEREST_RATE} from "shared/dist/constants/CloseBrothersInterestRate";
import {logPaymentGoogleAnalyticsEvent} from "../../../../utils/analytics/PaymentAnalytics";
import {validateBankDetails} from "../../../../graphql/queries/bank-details/validate/ValidateBankDetails";
import Rag from "shared-components/dist/information/rag/Rag";
import {useScrollToError} from "../../../../utils/validation/hooks/ScrollToError";
import {
  sendEcommerceAnalyticsAddPaymentInfoEvent
} from "shared-components/dist/analytics/GoogleAnalyticsEcommerceEventsService";
import {useBuildEcommerceItemsList} from "../../../../utils/analytics/helpers/UseBuildEcommerceItemsList";
import {useAppDispatch, useAppSelector} from "../../../../redux/Hooks";
import Title from "shared-components/src/title/Title";
import {formatDateStringFromIsoString} from "shared/src/stdlib/DateFormat";
import {
  SelectedLoan
} from "../../../quote/vehicle/shared/quote-summary/components/quote-price-card/hooks/UseQuotePricesProps";
import QuoteFinancePaymentSummaryCard
  from "./components/finance-payment-summary-card/quote/QuoteFinancePaymentSummaryCard";
import {PaymentFlowType} from "../../shared/models/PaymentFlowType";
import RenewalFinancePaymentSummaryCard
  from "./components/finance-payment-summary-card/renewal/RenewalFinancePaymentSummaryCard";
import {LoanType} from "../../shared/models/LoanType";
import {useEffectOnFirstRender} from "shared-components/dist/utils/hooks/UseEffectOnFirstRender";
import {resetBankDetailsForm} from "./redux/MonthlyPaymentSlice";
import {renewalTransactionIdChanged} from "../../../portal/renewals/redux/RenewalSlice";
import {usePolicyQueryParams} from "../../../../router/params/Params";
import {
  processZeroDepositFinancedRenewal
} from "../deposit-payment/deposit-payment-processors/hosted-payment-page/PolicyDepositPaymentProcessor";
import {FinancedPolicyPaymentResult} from "shared/dist/generated/graphql/resolvers-types";
import {ProposedFinancedRenewalRequest} from "../../shared/models/ProposedFinancedRenewalRequest";
import {ProposedFinancedPolicySetupRequest} from "../../shared/models/ProposedFinancedPolicySetupRequest";
import {selectSelectedQuoteType} from "../../../portal/renewals/redux/RenewalSelectors";
import {totalFinancedAmountSelector} from "../../../../redux/selectors/PolicyPaymentSelectors";

type BankDetailsValidationStatus = "Unvalidated" | "Valid" | "Invalid";

interface Props {
  depositPercentage: number;
  dateOfFirstPayment: string | undefined;
  selectedLoan: SelectedLoan | undefined;
  loanType: LoanType;
  paymentFlowType: PaymentFlowType;
  proposedFinancedRenewalRequest?: ProposedFinancedRenewalRequest | ProposedFinancedPolicySetupRequest | undefined;
}

const DirectDebitSetup: FC<Props> = ({
  depositPercentage,
  dateOfFirstPayment,
  selectedLoan,
  paymentFlowType,
  loanType,
  proposedFinancedRenewalRequest
}) => {
  const {policyId} = usePolicyQueryParams();
  const dispatch = useAppDispatch();
  const isMonthlyPaymentValid = useAppSelector(state => isMonthlyPaymentValidSelector(state));
  const sortCode = useAppSelector(state => state.monthlyPayment.sortCode.value);
  const accountNumber = useAppSelector(state => state.monthlyPayment.accountNumber.value);
  const selectedQuoteType = useAppSelector(selectSelectedQuoteType);
  const totalAmountPayable = useAppSelector(totalFinancedAmountSelector);
  const isNewLoan = loanType === LoanType.CREATE;

  const [zeroDepositLoanFailed, setZeroDepositLoanFailed] = useState(false);
  const [showEditBankDetails, setShowEditBankDetails] = useState(false);
  const [validationInProgress, setValidationInProgress] = useState(false);
  const [bankDetailsValidationStatus, setBankDetailsValidationStatus] = useState<BankDetailsValidationStatus>("Unvalidated");
  const navigate = useNavigate();
  const {scrollToError} = useScrollToError();
  const ecommerceItems = useBuildEcommerceItemsList();
  useEcommerceBeginCheckout();

  useEffectOnFirstRender(() => {
    if (!isNewLoan) {
      dispatch(resetBankDetailsForm);
    }
  });

  useEffect(() => {
    setBankDetailsValidationStatus("Unvalidated");
  }, [sortCode, accountNumber]);

  async function handleZeroDepositRequest(): Promise<void> {
    if (!proposedFinancedRenewalRequest) {
      setZeroDepositLoanFailed(true);
      setValidationInProgress(false);
    } else {
      const financedPolicyPaymentResponse = await processZeroDepositFinancedRenewal(proposedFinancedRenewalRequest, selectedQuoteType);
      if (financedPolicyPaymentResponse.result === FinancedPolicyPaymentResult.Success) {
        dispatch(renewalTransactionIdChanged(policyId));
        navigate(`/portal/policy/${policyId}/renewals/payment/success`);
      } else {
        setZeroDepositLoanFailed(true);
        setValidationInProgress(false);
      }
    }
  }

  async function onConfirmClicked(): Promise<void> {
    setValidationInProgress(true);
    const hasValidBankDetails = await validateBankDetails(sortCode, accountNumber);

    if (hasValidBankDetails || (!isNewLoan && !showEditBankDetails)) {
      logPaymentGoogleAnalyticsEvent({
        categorySuffix: "monthly.direct_debit.submit",
        action: "Click"
      });
      sendEcommerceAnalyticsAddPaymentInfoEvent({
        items: ecommerceItems ?? [],
        payment_type: "Monthly",
        value: Number(totalAmountPayable.amount),
        currency: totalAmountPayable.currency
      });
      if (paymentFlowType === PaymentFlowType.QUOTE) {
        navigate(QUOTE_MONTHLY_PAYMENT_DEPOSIT);
      } else {
        if (loanHasZeroDeposit) {
          await handleZeroDepositRequest();
        } else {
          navigate(DEPOSIT_PATH);
        }
      }
    } else {
      setBankDetailsValidationStatus(hasValidBankDetails ? "Valid" : "Invalid");
      setValidationInProgress(false);
      scrollToError();
    }
  }

  const loanHasZeroDeposit = depositPercentage === 0;

  return (
    <PaymentStep
      title="paymentFlow.monthly.directDebit.title"
      description="paymentFlow.monthly.directDebit.description"
      sidebarTitle={lookupI18nString({
        id: "paymentFlow.monthly.directDebit.sidebar.title",
        templateVariables: {
          totalSteps: loanHasZeroDeposit ? "1" : "2",
        }
      })}
      sidebarContent={<CloseBrothersSidebarContent/>}
      footerContent={
        <Button
          expanded
          disabled={!isMonthlyPaymentValid && (isNewLoan || showEditBankDetails)}
          onClick={onConfirmClicked}
          loading={validationInProgress}
        >
          {loanHasZeroDeposit
            ? lookupI18nString("paymentFlow.monthly.directDebit.confirmRenewalButton")
            : lookupI18nString("paymentFlow.monthly.directDebit.confirmationButton")
          }
        </Button>
      }
    >
      <div className="direct-debit-setup">
        {paymentFlowType === PaymentFlowType.QUOTE
          ? <QuoteFinancePaymentSummaryCard/>
          : <RenewalFinancePaymentSummaryCard/>
        }

        <p className="direct-debit-setup__interest-rate">
          {lookupI18nString({
            id: "paymentFlow.monthly.directDebit.interestRate",
            templateVariables: {
              apr: selectedLoan?.apr ?? "",
              interestRate: CLOSE_BROTHERS_INTEREST_RATE
            }
          })}
        </p>

        <div className="direct-debit-setup__form">

          <Title title="paymentFlow.monthly.directDebit.monthlyInstalments.title"/>
          <p className={"direct-debit-setup__monthly-instalments-info"}>
            {isNewLoan
              ? lookupI18nString({
                id: "paymentFlow.monthly.directDebit.monthlyInstalments.description",
                templateVariables: {
                  dateOfFirstPayment: dateOfFirstPayment ? formatDateStringFromIsoString((dateOfFirstPayment)) : lookupI18nString("structure.form.dateInput.genericError")
                }
              })
              : lookupI18nString("paymentFlow.monthly.directDebit.monthlyInstalmentsForLoanRenewal.description")
            }
          </p>

          {(isNewLoan || showEditBankDetails)
            ? <BankAccountForm showEditBankDetails={showEditBankDetails} setShowEditBankDetails={setShowEditBankDetails}/>
            : (<>
                <Spacer/>

                <p className={"direct-debit-setup__monthly-instalments-renew-info"}>
                  {lookupI18nString("paymentFlow.monthly.directDebit.bankDetails.NotRequiredForRenewal.description")}
                </p>

                <Button
                  expanded
                  onClick={() => setShowEditBankDetails(true)}
                >
                  {lookupI18nString("paymentFlow.monthly.directDebit.bankDetails.NotRequiredForRenewal.button.title")}
                </Button>
              </>
            )
          }

          {bankDetailsValidationStatus === "Invalid" &&
            <Rag
              description="paymentFlow.monthly.directDebit.form.bankDetails.error"
              status="error"
            />
          }
        </div>

        <DirectDebitPaymentInfo/>
        {zeroDepositLoanFailed && <Rag
          description="paymentFlow.monthly.directDebit.confirmRenewal.error"
          status="error"
        />}
      </div>
    </PaymentStep>
  );
};

export default DirectDebitSetup;
