import {
  isAboveMinimumAge
} from "../../../../pages/quote/vehicle/shared/questions/date-of-birth/validators/IsAboveMinimumAge";
import {
  isBelowMaximumAge
} from "../../../../pages/quote/vehicle/shared/questions/date-of-birth/validators/IsBelowMaximumAge";
import {
  isOldEnoughForLicenceLengthValidator
} from "../../../../pages/quote/vehicle/shared/questions/date-of-birth/validators/isOldEnoughForLicenceLengthValidator";
import {
  hasBeenUKResidentForLessThanAlive
} from "../../../../pages/quote/vehicle/shared/questions/date-of-birth/validators/HasBeenUKResidentForLessThanAlive";
import {
  coverStartDateAsDateOptionSelector
} from "../../../../pages/quote/vehicle/your-cover/redux/selectors/CoverDetailsSelectors";
import {
  isAboveMinimumAgeOnCoverStartDate
} from "../../../../pages/quote/vehicle/shared/questions/date-of-birth/validators/IsAboveMinimumAgeOnCoverStartDate";
import {
  isBelowMaximumAgeOnCoverStartDate
} from "../../../../pages/quote/vehicle/shared/questions/date-of-birth/validators/IsBelowMaximumAgeOnCoverStartDate";
import {buildCrossFieldValidator, CrossFieldValidator} from "../../Questions";

export const dateOfBirthValidators = [
  isAboveMinimumAge(),
  isBelowMaximumAge()
];

export const dateOfBirthCrossFieldValidators = (path: "personalDetails" | "updateAdditionalDriver"): CrossFieldValidator<Date>[] => [
    buildCrossFieldValidator(
      state => state[path].licenceDuration.value,
      [isOldEnoughForLicenceLengthValidator]
    ),
    buildCrossFieldValidator(
      state => state[path].ukResidencyDuration.value?.years,
      [hasBeenUKResidentForLessThanAlive]
    ),
    buildCrossFieldValidator(
      state => coverStartDateAsDateOptionSelector(state).value?.date,
      [isAboveMinimumAgeOnCoverStartDate, isBelowMaximumAgeOnCoverStartDate]
    ),
];