import {ApplePaySessionResponse, Query} from "shared/dist/generated/graphql/resolvers-types";
import {gql} from "@apollo/client";
import {apolloClient} from "../../../../../graphql/apollo/ApolloClientProvider";
import {removeTypename} from "../../../../../utils/graphql/ApolloClient";

export async function validateMerchant(): Promise<ApplePaySessionResponse> {
  const response = await apolloClient.query<Pick<Query, "policySetup">>({
    query: gql`{
      policySetup {
        applePaySession {
          epochTimestamp
          expiresAt
          merchantSessionIdentifier
          nonce
          merchantIdentifier
          domainName
          displayName
          signature
          operationalAnalyticsIdentifier
          retries
        }
      }
    }`
  });
  return removeTypename(response.data.policySetup.applePaySession);
}
