import React, {FC, useState} from "react";
import {ReactComponent as LogoPrimaryDark} from "shared-components/dist/assets/icons/logos/stroll/stroll-logo-primary-dark.svg";
import "./QuoteHeader.css";
import RetrieveQuoteModal from "../../../../../../structure/modals/retrieve-quote/RetrieveQuoteModal";
import RetrieveQuoteHeaderButton from "../../../../../../structure/buttons/retrieve-a-quote/RetrieveQuoteHeaderButton";
import LoginButton from "../../../../../../structure/buttons/login-button/LoginButton";
import {logNavigationGoogleAnalyticsEvent} from "../../../../../../utils/analytics/NavigationAnalytics";
import {OptionalDebugPanel} from "../../debug-panel/OptionalDebugPanel";

interface OwnProps {
  showButtons?: boolean;
}

const QuoteHeader: FC<OwnProps> = ({showButtons = true}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const onLogoClicked = (): void => {
    logNavigationGoogleAnalyticsEvent({
      categorySuffix: "stroll_logo",
      action: "Click"
    });
  };

  return (
    <>
      <OptionalDebugPanel isQuoteFlow={true}/>
      <div className="quote-header">
        <div className="quote-header__container">
          <div className="quote-header__container__logo" onClick={onLogoClicked}>
            <LogoPrimaryDark/>
          </div>
          {showButtons &&
            <div className="quote-header__container__buttons">
              <RetrieveQuoteHeaderButton/>
              <LoginButton/>
            </div>
          }
        </div>
      </div>
      <RetrieveQuoteModal visible={modalOpen} dismiss={() => setModalOpen(false)}/>
    </>
  );
};

export default QuoteHeader;
