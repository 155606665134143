import React, {FC, useEffect} from "react";
import {BreakInCover, detectBreakInCover} from "../services/BreakInCoverDetector";
import BreakInCoverDeclaration from "./declaration/BreakInCoverDeclaration";
import QuoteJourneyStop from "../stops/quote-journey-stop/QuoteJourneyStop";
import {ConditionalBooleanFormField} from "shared-components/dist/models/form-field/variants/BooleanFormField";
import {
  vehiclePreviousPolicyExpiryDateAsDateSelector
} from "../../your-cover/redux/selectors/CoverDetailsSelectors";
import {useAppSelector} from "../../../../../redux/Hooks";

interface Props {
  carPurchaseDate: Date | undefined;
  coverStartDate: Date | undefined;
  previousCoverExpiryDate: Date | undefined;
  breakInCover: BreakInCover;
  breakInCoverChanged: (value: BreakInCover) => void;
  breakInCoverDeclarationAcceptance: ConditionalBooleanFormField;
  breakInCoverDeclarationAcceptanceChanged: (value: boolean) => void;
  isWithinModal?: boolean;
}

const BreakInCoverDetection: FC<Props> = (
  {
    carPurchaseDate,
    coverStartDate,
    previousCoverExpiryDate,
    breakInCover,
    breakInCoverChanged,
    breakInCoverDeclarationAcceptance,
    breakInCoverDeclarationAcceptanceChanged,
    isWithinModal = false
  }
) => {
  const hasPreviouslyBeenInsured = useAppSelector(state => state.coverDetails.previouslyHeldInsurance.value);
  const vehiclePreviousPolicyExpiryDate = useAppSelector(state => vehiclePreviousPolicyExpiryDateAsDateSelector(state).value);

  useEffect(() => {
    breakInCoverChanged(detectBreakInCover({
        carPurchaseDate,
        previousCoverExpiryDate,
        coverStartDate,
        hasPreviouslyBeenInsured,
        vehiclePreviousPolicyExpiryDate
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carPurchaseDate, previousCoverExpiryDate, coverStartDate, hasPreviouslyBeenInsured, vehiclePreviousPolicyExpiryDate]);

  return (
    <>
      {breakInCover === "DECLARATION_REQUIRED" && (
        <BreakInCoverDeclaration
          breakInCoverDeclarationAcceptance={breakInCoverDeclarationAcceptance}
          breakInCoverDeclarationAcceptanceChanged={breakInCoverDeclarationAcceptanceChanged}
          isWithinModal={isWithinModal}
        />
      )}
      <QuoteJourneyStop
        isWithinModal={isWithinModal}
        stopProps={{
          title: "coverDetails.breakInCover.softStops.overFourteenDays.title",
          description: "coverDetails.breakInCover.softStops.overFourteenDays.description",
          visible: breakInCover === "CANNOT_INSURE_ONLINE"
        }}
      />
    </>
  );
};

export default BreakInCoverDetection;
