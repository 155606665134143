import {
  ADD_ADDITIONAL_DRIVER,
  COMPARE_QUOTES,
  EDIT_ADDITIONAL_DRIVER,
  EXPIRED_QUOTE,
  NEW_QUOTE_BASE,
  PROTECTED_NO_CLAIMS_BONUS_INFORMATION,
  REFUSED_QUOTE,
  SAVED_QUOTE_BASE,
  YOUR_CAR,
  YOUR_COVER,
  YOUR_DETAILS,
  YOUR_QUOTE
} from "../../../../../router/models/Routes";
import {yourVehicleFormValid} from "../../your-vehicle/redux/selectors/ValidatedVehicleSelectors";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {RootState} from "../../../../../redux/Store";
import {personalDetailsValidSelector} from "../../your-details/redux/selectors/PersonalDetailsSelectors";
import {coverDetailsValidSelector} from "../../your-cover/redux/selectors/CoverDetailsSelectors";
import {additionalDriverDetailsValidSelector} from "../../additional-driver/shared/redux/selectors/AdditionalDriverDetailsValidSelector";

interface QuoteSteps {
  yourCar: Omit<QuoteStep, "previousRoute">;
  yourDetails: QuoteStep;
  yourCover: QuoteStep;
  yourQuote: Omit<QuoteStep, "nextRoute">;
  addAdditionalDriver: QuoteSideStep;
  editAdditionalDriver: QuoteSideStep;
  refusedQuote: Omit<QuoteSideStep, "validator">;
  expiredQuote: QuoteSideStepSavedOnly;
  protectedNoClaimsBonusInformation: Omit<QuoteSideStep, "validator">;
}

interface QuoteStep {
  headerTitle: TranslationKey;
  sidebarDescription: TranslationKey;
  routes: {
    compare: string;
    savedCompare?: string;
    new: string;
    saved: string;
  };
  nextRoute: RouteConfiguration;
  previousRoute: RouteConfiguration;
  stepNumber: number;
}

export interface RouteConfiguration {
  route: string;
  validator: (state: RootState) => boolean;
}

interface QuoteSideStep {
  routes: {
    saved: string;
    new: string;
  };
  validator: (state: RootState) => boolean;
}

interface QuoteSideStepSavedOnly {
  routes: {
    saved: string;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isRouteConfiguration(toCheck: any): toCheck is RouteConfiguration {
  return typeof toCheck.route === "string" && typeof toCheck.validator === "function";
}

export const quoteSteps: QuoteSteps = {
  yourCar: {
    headerTitle: "quoteSteps.header.yourCar",
    sidebarDescription: "quoteSteps.sidebarDescription.yourCar",
    routes: {
      compare: `${NEW_QUOTE_BASE}${COMPARE_QUOTES}`,
      new: `${NEW_QUOTE_BASE}${YOUR_CAR}`,
      saved: `${SAVED_QUOTE_BASE}${YOUR_CAR}`
    },
    nextRoute: {
      route: YOUR_DETAILS,
      validator: yourVehicleFormValid()
    },
    stepNumber: 1
  },
  yourDetails: {
    headerTitle: "quoteSteps.header.yourDetails",
    sidebarDescription: "quoteSteps.sidebarDescription.yourDetails",
    routes: {
      compare: `${NEW_QUOTE_BASE}${COMPARE_QUOTES}`,
      new: `${NEW_QUOTE_BASE}${YOUR_DETAILS}`,
      saved: `${SAVED_QUOTE_BASE}${YOUR_DETAILS}`
    },
    nextRoute: {
      route: YOUR_COVER,
      validator: personalDetailsValidSelector
    },
    previousRoute: {
      route: YOUR_CAR,
      validator: yourVehicleFormValid()
    },
    stepNumber: 2
  },
  yourCover: {
    headerTitle: "quoteSteps.header.yourCover",
    sidebarDescription: "quoteSteps.sidebarDescription.yourCover",
    routes: {
      compare: `${NEW_QUOTE_BASE}${COMPARE_QUOTES}`,
      new: `${NEW_QUOTE_BASE}${YOUR_COVER}`,
      saved: `${SAVED_QUOTE_BASE}${YOUR_COVER}`
    },
    nextRoute: {
      route: YOUR_QUOTE,
      validator: coverDetailsValidSelector
    },
    previousRoute: {
      route: YOUR_DETAILS,
      validator: personalDetailsValidSelector
    },
    stepNumber: 3
  },
  yourQuote: {
    headerTitle: "quoteSteps.header.yourQuote",
    sidebarDescription: "quoteSteps.sidebarDescription.yourQuote",
    routes: {
      compare: `${NEW_QUOTE_BASE}${COMPARE_QUOTES}`,
      new: `${NEW_QUOTE_BASE}/your-quote`,
      saved: `${SAVED_QUOTE_BASE}/your-quote`,
      savedCompare: `${SAVED_QUOTE_BASE}${COMPARE_QUOTES}`
    },
    previousRoute: {
      route: YOUR_COVER,
      validator: coverDetailsValidSelector
    },
    stepNumber: 4
  },
  addAdditionalDriver: {
    routes: {
      new: `${NEW_QUOTE_BASE}${ADD_ADDITIONAL_DRIVER}`,
      saved: `${SAVED_QUOTE_BASE}${ADD_ADDITIONAL_DRIVER}`
    },
    validator: additionalDriverDetailsValidSelector,
  },
  editAdditionalDriver: {
    routes: {
      new: `${NEW_QUOTE_BASE}${EDIT_ADDITIONAL_DRIVER}`,
      saved: `${SAVED_QUOTE_BASE}${EDIT_ADDITIONAL_DRIVER}`
    },
    validator: additionalDriverDetailsValidSelector,
  },
  refusedQuote: {
    routes: {
      new: `${NEW_QUOTE_BASE}${REFUSED_QUOTE}`,
      saved: `${SAVED_QUOTE_BASE}${REFUSED_QUOTE}`
    }
  },
  expiredQuote: {
    routes: {
      saved: `${SAVED_QUOTE_BASE}${EXPIRED_QUOTE}`
    }
  },
  protectedNoClaimsBonusInformation: {
    routes: {
      new: `${NEW_QUOTE_BASE}${PROTECTED_NO_CLAIMS_BONUS_INFORMATION}`,
      saved: `${NEW_QUOTE_BASE}${PROTECTED_NO_CLAIMS_BONUS_INFORMATION}`
    }
  }
};