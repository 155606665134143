import {AppThunk} from "../../../../../redux/Store";
import {signOutUser} from "../../../../../user/authentication/services/UserAuthenticationService";
import {signOutZendeskChat} from "../../../../../zendesk/Zendesk";
import {apolloClient} from "../../../../../graphql/apollo/ApolloClientProvider";
import {userAttemptedToLogout} from "../../../../../redux/Actions";

export function logout(): AppThunk {
  return async (dispatch) => {
    signOutUser();
    signOutZendeskChat();
    await dispatch(userAttemptedToLogout());
    await apolloClient.clearStore();
  };
}
