import React, {FC} from "react";
import DocumentLinkSection from "../document-links/DocumentLinkSection";
import {InfoOutlined} from "@mui/icons-material";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import Button from "shared-components/dist/buttons//button/Button";
import {useQuoteDocuments} from "./hooks/useQuoteDocuments";
import {openBase64FileInWindow} from "../../../../../../../utils/service/pdf/PdfService";
import {NO_POLICY_BOOKLET_URL} from "shared/dist/constants/Urls";
import {logger} from "../../../../../../../utils/logging/Logger";
import {useAppSelector} from "../../../../../../../redux/Hooks";

const QuoteDocuments: FC = () => {
  const quoteReference = useAppSelector(state => state.quoteDetails.quoteReference);
  const schemeReference = useAppSelector(state => state.quoteDetails.quoteInsurerSchemeReference);
  const coverType = useAppSelector(state => state.coverDetails.coverType.value?.description);
  const policyStartDate = useAppSelector(state => state.coverDetails.coverStartDate.value?.date);
  const quoteDocuments = useQuoteDocuments({quoteReference, schemeReference, coverType, policyStartDate});

  function openFile(fileOrUrl: string | undefined | null): void {
    if (!fileOrUrl) {
      logger.warn("No url or base64 string provided, opening default policy booklet");
      window.open(NO_POLICY_BOOKLET_URL);
      return;
    }

    if (isUrl(fileOrUrl)) {
      window.open(fileOrUrl);
      return;
    }

    openBase64FileInWindow(fileOrUrl);
  }

  return (
    <DocumentLinkSection
      title="quoteDetails.quoteDocuments.title"
      description="quoteDetails.quoteDocuments.description"
    >
      <Button
        variant="link"
        leftIcon={<InfoOutlined/>}
        onClick={() => openFile(quoteDocuments?.insuranceProductInformation)}
      >
        {lookupI18nString("quoteDetails.quoteDocuments.ipid")}
      </Button>
      <Button
        variant="link"
        leftIcon={<InfoOutlined/>}
        onClick={() => openFile(quoteDocuments?.policyBooklet)}
      >
        {lookupI18nString("quoteDetails.quoteDocuments.policyBooklet")}
      </Button>
    </DocumentLinkSection>
  );
};

const isUrl = (input: string): boolean => input.startsWith("http");

export default QuoteDocuments;
