import React, {FC} from "react";
import {Modification} from "../model/Modification";

interface Props {
  modification: Modification;
}

const ModificationItem: FC<Props> = ({modification}) => (
  <>
    <p>{modification.type.description}</p>
    <p>{modification.option?.description}</p>
  </>
);

export default ModificationItem;