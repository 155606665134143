import {LicenceType} from "shared/dist/generated/graphql/resolvers-types";

export const LICENCE_TYPES = [
  {
    id: LicenceType.Full,
    description: "Full"
  },
  {
    id: LicenceType.Provisional,
    description: "Provisional"
  }
] as const;

export type LicenceTypeOption = typeof LICENCE_TYPES[number];

export const LICENCE_TYPE_OPTIONS = LICENCE_TYPES.map(type => type.description);
