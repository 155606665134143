import React, {FC} from "react";
import {createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider} from "react-router-dom";
import PaymentSuccess from "../../pages/payment/payment-success/PaymentSuccess";
import AuthenticatedRoute from "./AuthenticatedRoute";
import UnauthenticatedRoute from "./UnauthenticatedRoute";
import {
  ACCOUNT_ALREADY_ACTIVATED,
  ACTIVATE,
  CLAIMS,
  COMPARE_NI_YOUR_QUOTE,
  HELP,
  HOME_PAGE,
  LOGIN,
  POLICIES,
  POLICY_DOCUMENTS_BY_ID,
  POLICY_DOCUMENTS_UPCOMING_BY_ID,
  POLICY_DRIVERS_BY_ID,
  POLICY_RENEWALS_ANNUALISED_BREAKDOWN,
  POLICY_RENEWALS_BY_ID,
  POLICY_RENEWALS_CONFIRM_DETAILS,
  POLICY_RENEWALS_PROTECTED_NO_CLAIMS_BONUS_INFORMATION,
  POLICY_SUMMARY,
  POLICY_SUMMARY_BY_ID,
  PORTAL,
  QUOTE,
  QUOTE_ANNUAL_PAYMENT,
  QUOTE_ANNUAL_PAYMENT_BY_CARD,
  QUOTE_MONTHLY_PAYMENT,
  QUOTE_MONTHLY_PAYMENT_DEPOSIT,
  QUOTE_PAYMENT_SUCCESS,
  QUOTE_PAYMENT_SUCCESS_CONFETTI,
  RENEWAL_ANNUAL_PAYMENT,
  RENEWAL_ANNUAL_PAYMENT_BY_CARD,
  RENEWAL_MONTHLY_PAYMENT,
  RENEWAL_MONTHLY_PAYMENT_DEPOSIT,
  RENEWAL_PAYMENT_SUCCESS,
  RENEWAL_PAYMENT_SUCCESS_CONFETTI,
  RENEWAL_SUMMARY,
  RESET_PASSWORD,
  SETTINGS,
  UPLOADS
} from "../models/Routes";
import NotFound from "../../pages/not-found/NotFound";
import {quoteSteps} from "../../pages/quote/vehicle/shared/quote-step/QuoteSteps";
import YourVehicle from "../../pages/quote/vehicle/your-vehicle/YourVehicle";
import YourDetails from "../../pages/quote/vehicle/your-details/YourDetails";
import YourCover from "../../pages/quote/vehicle/your-cover/YourCover";
import YourQuote from "../../pages/quote/vehicle/your-quote/components/new/YourQuote";
import AddAdditionalDriver from "../../pages/quote/vehicle/additional-driver/add-additional-driver/AddAdditionalDriver";
import PriorQuote from "../../pages/quote/vehicle/your-quote/components/saved/PriorQuote";
import AccountActivation from "../../pages/user-authentication/account-activation/AccountActivation";
import Login from "../../pages/user-authentication/login/Login";
import ResetPassword from "../../pages/user-authentication/reset-password/ResetPassword";
import AccountSettings from "../../pages/portal/account/AccountSettings";
import EditAdditionalDriver
  from "../../pages/quote/vehicle/additional-driver/edit-additional-driver/EditAdditionalDriver";
import Claims from "../../pages/portal/claims/Claims";
import Help from "../../pages/portal/help/Help";
import RefusedQuote from "../../pages/quote/vehicle/refused-quote/RefusedQuote";
import PaymentSuccessConfetti from "../../pages/payment/payment-success/PaymentSuccessConfetti";
import ExpiredQuote from "../../pages/quote/vehicle/expired-quote/ExpiredQuote";
import AccountAlreadyActivated
  from "../../pages/user-authentication/account-activation/account-already-activated/AccountAlreadyActivated";
import QuoteSummaryProtectedNoClaimsBonusInformation
  from "../../pages/quote/vehicle/protected-no-claims-bonus-information/QuoteSummaryProtectedNoClaimsBonusInformation";
import CompareYourQuotes from "../../pages/quote/vehicle/your-quote/components/compare-quotes/CompareYourQuotes";
import Dashboard from "../../pages/portal/dashboard/Dashboard";
import PolicyView from "../../pages/portal/policies/PolicyView";
import PolicyViewDocuments from "../../pages/portal/policies/sub-pages/documents/PolicyViewDocuments";
import PolicyViewDrivers from "../../pages/portal/policies/sub-pages/additional-drivers/PolicyViewDrivers";
import PolicyViewUpcomingDocuments
  from "../../pages/portal/policies/sub-pages/documents/upcoming-documents/PolicyViewUpcomingDocuments";
import PolicyDocumentUpload from "../../pages/portal/document-upload/PolicyDocumentUpload";
import BasePage from "./BasePage";
import FullPageSpinner from "shared-components/dist/spinner/full-page-spinner/FullPageSpinner";
import RenewalSummaryPage from "../../pages/portal/renewals/summary/RenewalSummaryPage";
import AggregatorQuoteLandingPage from "../../pages/aggregator-loading/AggregatorQuoteLandingPage";
import AnnualisedBreakdownPage from "../../pages/portal/annualised-breakdown/AnnualisedBreakdownPage";
import PolicyViewRenewals from "../../pages/portal/policies/sub-pages/renewals/PolicyViewRenewals";
import QuoteAnnualPaymentLanding
  from "../../pages/payment/annual/annual-payment-landing/quote/QuoteAnnualPaymentLanding";
import {PaymentFlowType} from "../../pages/payment/shared/models/PaymentFlowType";
import QuoteAnnualPaymentByCard from "../../pages/payment/annual/annual-payment-by-card/quote/QuoteAnnualPaymentByCard";
import RenewalAnnualPaymentLanding
  from "../../pages/payment/annual/annual-payment-landing/renewal/RenewalAnnualPaymentLanding";
import RenewalAnnualPaymentByCard
  from "../../pages/payment/annual/annual-payment-by-card/renewal/RenewalAnnualPaymentByCard";
import RenewalDetailsConfirmationPage
  from "../../pages/portal/renewals/details-confirmation/RenewalDetailsConfirmationPage";
import RenewalsConfirmProtectedNoClaimsBonusInformation from "../../pages/portal/renewals/details-confirmation/no-claims-bonus-information/RenewalsConfirmProtectedNoClaimsBonusInformation";
import QuoteDirectDebitSetup from "../../pages/payment/monthly/direct-debit-setup/quote/QuoteDirectDebitSetup";
import QuoteDepositPaymentLanding from "../../pages/payment/monthly/deposit-payment/quote/QuoteDepositPaymentLanding";
import RenewalDirectDebitSetup from "../../pages/payment/monthly/direct-debit-setup/renewal/RenewalDirectDebitSetup";
import RenewalRebrokeDepositPaymentLanding
  from "../../pages/payment/monthly/deposit-payment/renewal/RenewalRebrokeDepositPaymentLanding";

const RouterOutlet: FC = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="*" element={<NotFound/>}/>

        <Route element={<UnauthenticatedRoute/>}>
          {buildRoute([LOGIN], <Login/>)}
        </Route>

        {buildRoute([ACTIVATE], <AccountActivation/>)}
        {buildRoute([ACCOUNT_ALREADY_ACTIVATED], <AccountAlreadyActivated/>)}
        {buildRoute([RESET_PASSWORD], <ResetPassword/>)}

        {buildRoute([POLICIES], <Navigate to={PORTAL} replace/>)}

        {buildRoute([POLICY_SUMMARY], <Navigate to={PORTAL} replace/>)}

        <Route element={<AuthenticatedRoute/>}>
          {buildRoute([PORTAL], <Dashboard/>)}
          {buildRoute([POLICY_SUMMARY_BY_ID], <PolicyView/>)}
          {buildRoute([POLICY_DOCUMENTS_BY_ID], <PolicyViewDocuments/>)}
          {buildRoute([POLICY_RENEWALS_BY_ID], <PolicyViewRenewals/>)}
          {buildRoute([POLICY_DOCUMENTS_UPCOMING_BY_ID], <PolicyViewUpcomingDocuments/>)}
          {buildRoute([POLICY_DRIVERS_BY_ID], <PolicyViewDrivers/>)}
          {buildRoute([UPLOADS], <PolicyDocumentUpload/>)}
          {buildRoute([CLAIMS], <Claims/>)}
          {buildRoute([SETTINGS], <AccountSettings/>)}

          {buildRoute([RENEWAL_SUMMARY], <RenewalSummaryPage/>)}
          {buildRoute([POLICY_RENEWALS_ANNUALISED_BREAKDOWN], <AnnualisedBreakdownPage/>)}
          {buildRoute([POLICY_RENEWALS_CONFIRM_DETAILS], <RenewalDetailsConfirmationPage/>)}
          {buildRoute([POLICY_RENEWALS_PROTECTED_NO_CLAIMS_BONUS_INFORMATION], <RenewalsConfirmProtectedNoClaimsBonusInformation/>)}

          {buildRoute([RENEWAL_ANNUAL_PAYMENT], <RenewalAnnualPaymentLanding/>)}
          {buildRoute([RENEWAL_ANNUAL_PAYMENT_BY_CARD], <RenewalAnnualPaymentByCard/>)}
          {buildRoute([RENEWAL_MONTHLY_PAYMENT], <RenewalDirectDebitSetup/>)}
          {buildRoute([RENEWAL_MONTHLY_PAYMENT_DEPOSIT], <RenewalRebrokeDepositPaymentLanding/>)}
          {buildRoute([RENEWAL_PAYMENT_SUCCESS], <PaymentSuccess paymentFlowType={PaymentFlowType.RENEWAL}/>)}
          {buildRoute([RENEWAL_PAYMENT_SUCCESS_CONFETTI], <PaymentSuccessConfetti paymentFlowType={PaymentFlowType.RENEWAL}/>)}
        </Route>

        {buildRoute([HOME_PAGE], <Navigate to={quoteSteps.yourCar.routes.new} replace/>)}

        {buildRoute([QUOTE], <Navigate to={quoteSteps.yourCar.routes.new} replace/>)}

        {buildRoute([quoteSteps.yourCar.routes.new, quoteSteps.yourCar.routes.saved], <YourVehicle/>)}
        {buildRoute([quoteSteps.yourDetails.routes.new, quoteSteps.yourDetails.routes.saved], <YourDetails/>)}
        {buildRoute([quoteSteps.yourCover.routes.new, quoteSteps.yourCover.routes.saved], <YourCover/>)}
        {buildRoute([quoteSteps.addAdditionalDriver.routes.new, quoteSteps.addAdditionalDriver.routes.saved], <AddAdditionalDriver/>)}
        {buildRoute([quoteSteps.editAdditionalDriver.routes.new, quoteSteps.editAdditionalDriver.routes.saved], <EditAdditionalDriver/>)}
        {buildRoute([quoteSteps.refusedQuote.routes.new, quoteSteps.refusedQuote.routes.saved], <RefusedQuote/>)}
        {buildRoute([quoteSteps.protectedNoClaimsBonusInformation.routes.new, quoteSteps.protectedNoClaimsBonusInformation.routes.saved], <QuoteSummaryProtectedNoClaimsBonusInformation/>)}
        {buildRoute([quoteSteps.yourQuote.routes.new], <YourQuote/>)}
        {buildRoute([quoteSteps.yourQuote.routes.saved], <PriorQuote/>)}
        {buildRoute([quoteSteps.expiredQuote.routes.saved], <ExpiredQuote/>)}
        {buildRoute([quoteSteps.yourQuote.routes.compare, quoteSteps.yourQuote.routes.savedCompare ?? ""], <CompareYourQuotes/>)}

        {buildRoute([QUOTE_ANNUAL_PAYMENT], <QuoteAnnualPaymentLanding/>)}
        {buildRoute([QUOTE_ANNUAL_PAYMENT_BY_CARD], <QuoteAnnualPaymentByCard/>)}
        {buildRoute([QUOTE_MONTHLY_PAYMENT], <QuoteDirectDebitSetup/>)}
        {buildRoute([QUOTE_MONTHLY_PAYMENT_DEPOSIT], <QuoteDepositPaymentLanding/>)}
        {buildRoute([QUOTE_PAYMENT_SUCCESS], <PaymentSuccess paymentFlowType={PaymentFlowType.QUOTE}/>)}
        {buildRoute([QUOTE_PAYMENT_SUCCESS_CONFETTI], <PaymentSuccessConfetti paymentFlowType={PaymentFlowType.QUOTE}/>)}

        {buildRoute([HELP], <Help/>)}

        {buildRoute([COMPARE_NI_YOUR_QUOTE], <AggregatorQuoteLandingPage/>)}
      </>
    )
  );

  return (
    <RouterProvider
      router={router}
      fallbackElement={<FullPageSpinner/>}
    />
  );
};

const createPage = (element: React.ReactNode): React.ReactNode => <BasePage>{element}</BasePage>;

const buildRoute = (paths: string[], element: React.ReactNode): JSX.Element[] =>
  paths.map((path) => <Route key={path} path={path} element={createPage(element)} errorElement={<NotFound/>}/>);

export default RouterOutlet;
