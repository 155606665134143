import {useCallback, useEffect, useState} from "react";
import {scroller} from "react-scroll";
import {DEFAULT_SCROLL_TO_PROPS} from "./ScrollToProps";

interface UseScrollToError {
  scrollToError: () => void;
}

interface UseScrollToErrorProps {
  shouldForceScroll?: boolean
  includeStops?: boolean
}

export function useScrollToError({shouldForceScroll = false, includeStops = false}: UseScrollToErrorProps = {}): UseScrollToError {
  const [shouldScroll, setShouldScroll] = useState(false);
  const scrollToError = useCallback(() => setShouldScroll(true), [setShouldScroll]);

  const erroredElement = document.querySelector(
    getErrorQuerySelector(includeStops)
  );

  const activeElement = document?.activeElement as HTMLElement;

  useEffect(() => {
    if (!shouldScroll) return;

    setShouldScroll(false);

    if (!erroredElement && !shouldForceScroll) return;

    scroller.scrollTo(erroredElement?.className ?? "error", DEFAULT_SCROLL_TO_PROPS);
    activeElement?.blur();
    if (erroredElement) (erroredElement as HTMLElement)?.focus();
  }, [shouldScroll, erroredElement, activeElement, shouldForceScroll]);

  return {scrollToError};
}

function getErrorQuerySelector(includeStops: boolean): string {
  const errorQuerySelectors = [
    ".error input",
    ".error select",
    ".key-item-row--error",
    ".error"
  ];

  if (includeStops) errorQuerySelectors.push(".stop");

  return errorQuerySelectors.join(", ");
}