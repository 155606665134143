import React, {FC} from "react";
import VehicleSummaryCard from "../vehicle-summary-card/VehicleSummaryCard";
import {vehicleDetailsReset} from "../../../../../../redux/Actions";
import {validRegLookupVehicleSummarySelector} from "../../redux/selectors/RegLookupVehicleSelectors";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const VehicleConfirmation: FC = () => {
  const dispatch = useAppDispatch();
  const vehicle = useAppSelector(state => state.vehicleDetails.vehicleFromRegLookup);
  const validRegLookupVehicle = useAppSelector(validRegLookupVehicleSummarySelector);

  if (!validRegLookupVehicle) return null;

  return (
    <VehicleSummaryCard
      vehicle={vehicle}
      onVehicleRejection={() => dispatch(vehicleDetailsReset())}
      buttonText="vehicleQuote.vehicleConfirmation.rejectButton"
    />
  );
};

export default VehicleConfirmation;
