import {ReduxQuestion} from "../../../Questions";
import {
  additionalDetailsHasTrackerChanged,
  additionalDetailsHasTrackerInvalid
} from "../../../../../pages/quote/vehicle/your-vehicle/redux/VehicleDetailsSlice";

export const additionalVehicleDetailsHasTracker: ReduxQuestion<boolean> = {
  selector: state => state.vehicleDetails.additionalVehicleDetails.hasTracker,
  onChange: additionalDetailsHasTrackerChanged,
  onInvalid: additionalDetailsHasTrackerInvalid
};