import React, {FC} from "react";
import {CLAIM_STATUS_OPTIONS, CLAIM_STATUSES, ClaimStatusOption} from "../models/ClaimStatusOption";
import {StringRadioColumnSubQuestion} from "../../../../../../../structure/questions/sub-question/variants/radio-column-sub-question/variants/StringRadioColumnSubQuestion";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import {ClaimsFormSubQuestionProps} from "./ClaimsFormSubQuestionProps";

type Props = ClaimsFormSubQuestionProps<ClaimStatusOption>;

const ClaimStatusSubQuestion: FC<Props> = (
  {
    value,
    onChange,
    status,
    isAdditionalDriverForm
  }
) => {
  const title = isAdditionalDriverForm
    ? "additionalDriver.claims.status.title"
    : "personalDetails.claims.status.title";

  const onClaimStatusSelected = (selection: string): void => {
    const selectedClaimStatus = CLAIM_STATUSES.find(status => status.description === selection);
    if (!selectedClaimStatus) return;

    onChange(selectedClaimStatus);
  };

  return (
    <StringRadioColumnSubQuestion
      subQuestionProps={{
        id: "claim-status-sub-question",
        errorMessage: "quote.errors.questionIncomplete",
        title,
        tooltip: getTooltip(isAdditionalDriverForm),
        status,
      }}
      radioColumnProps={{
        name: "claim-status-sub-question-radio-column",
        selectedOption: value?.description,
        options: CLAIM_STATUS_OPTIONS,
        onSelection: onClaimStatusSelected
      }}
    />
  );
};

function getTooltip(isAdditionalDriverForm: boolean): TooltipProps {
  if (isAdditionalDriverForm) {
    return {
      title: "additionalDriver.claims.status.tooltip.title",
      description: "additionalDriver.claims.status.tooltip.description",
    };
  }
  return {
    title: "personalDetails.claims.status.tooltip.title",
    description: "personalDetails.claims.status.tooltip.description",
  };
}

export default ClaimStatusSubQuestion;