import {FC} from "react";
import {useAppSelector} from "../../../../../redux/Hooks";
import {rebrokeQuoteValuesSelector} from "../../../shared/redux/RenewalRequestSelector";
import AnnualPaymentByCard from "../AnnualPaymentByCard";
import {PaymentFlowType} from "../../../shared/models/PaymentFlowType";
import RenewalSummaryError from "../../../../portal/renewals/components/renewal-summary-error/RenewalSummaryError";

const RebrokeAnnualPaymentByCard: FC = () => {
  const rebrokeQuoteValues = useAppSelector(rebrokeQuoteValuesSelector);

  if (!rebrokeQuoteValues) return <RenewalSummaryError/>;

  return <AnnualPaymentByCard proposedRequest={rebrokeQuoteValues} paymentFlowType={PaymentFlowType.REBROKE}/>;
};

export default RebrokeAnnualPaymentByCard;