import {insertSpaceAt} from "shared-components/dist/utils/stdlib/StringUtils";
import {KeyboardEvent} from "react";
import {SPECIAL_CHARS} from "shared-components/dist/utils/constants/Regex";
import {TestRegistration} from "shared/dist/constants/TestRegistration";
import {removeWhiteSpace} from "shared/dist/stdlib/StringFormatters";

export const MAX_REG_NUMBER_LENGTH = 8;
export const NI_REG_NUMBER_SPACE_INDEX = 3;
export const GB_REG_NUMBER_SPACE_INDEX = 4;
const NI_REG_NUMBER = /(^[A-Z]{3}[0-9]{1,4}$)/;
const GB_REG_NUMBER = /(^[A-Z]{2}[0-9]{2}[A-Z]{3}$)/;
const ISLE_OF_MAN_REG_NUMBER = /(^(?![AIQSZ])[A-Z]MN\s*[0-9]{1,3}(?![IOQSZ])[A-Z]$)/;
export const VALID_REGISTRATION_INPUT = /(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}\s?[0-9]{1,4}$)|(^[0-9]{3}[DX][0-9]{3}$)/;

export const testRegistrations: TestRegistration[] = [
  "JPIMPORT",
  "EUIMPORT",
  "LHDRIVE",
  "LPGFUEL"
];

export const isValidRegistrationNumberCharacter = (event: KeyboardEvent<HTMLInputElement>): boolean => {
  return !SPECIAL_CHARS.test(event.key);
};

export function isValidRegistrationNumber(input: string): boolean {
  const registrationNumber = input.trim().toUpperCase();

  if (isTestRegistration(registrationNumber)) return true;

  return VALID_REGISTRATION_INPUT.test(removeWhiteSpace(registrationNumber));
}

function isTestRegistration(registration: string): boolean {
  return testRegistrations.includes(registration as TestRegistration);
}

export function isQPlateRegistration(registration: string): boolean {
  return registration.trim().toUpperCase().startsWith("Q");
}

export function formatRegistrationNumber(registration: string): string {
  if (isNIRegistration(registration)) {
    return formatAsNIRegistrationNumber(registration);
  } else if (isCurrentGBRegistration(registration)) {
    return formatAsCurrentGBRegistrationNumber(registration);
  } else {
    return registration.trim().toUpperCase();
  }
}

type RegistrationPatternMatcher = (registration: string) => boolean

function buildRegistrationFormatMatcher(format: RegExp): RegistrationPatternMatcher {
  return registration => format.test(registration.trim().toUpperCase());
}

export const isIsleOfManRegistration = buildRegistrationFormatMatcher(ISLE_OF_MAN_REG_NUMBER);

const isNIRegistration = buildRegistrationFormatMatcher(NI_REG_NUMBER);

const isCurrentGBRegistration = buildRegistrationFormatMatcher(GB_REG_NUMBER);

function formatAsNIRegistrationNumber(registration: string): string {
  return insertSpaceAt(registration.trim().toUpperCase(), NI_REG_NUMBER_SPACE_INDEX);
}

function formatAsCurrentGBRegistrationNumber(registration: string): string {
  return insertSpaceAt(registration.trim().toUpperCase(), GB_REG_NUMBER_SPACE_INDEX);
}
