import React, {FC} from "react";
import QuoteResult from "./components/quote-result/QuoteResult";
import SaveQuoteSidebar from "../../your-quote/components/shared/save-quote-sidebar/SaveQuoteSidebar";
import PolicyExtrasSelection from "./components/policy-extras-selection/PolicyExtrasSelection";
import QuoteCoverSummary from "./components/quote-cover-summary/QuoteCoverSummary";
import QuoteAccordionList from "./components/quote-accordion-list/QuoteAccordionList";
import Spacer from "shared-components/dist/spacer/Spacer";
import QuoteDocuments from "./components/quote-documents/QuoteDocuments";
import LegalDocuments from "./components/legal-documents/LegalDocuments";
import {useNavigateOnInvalidQuote} from "../../your-quote/hooks/NavigateOnInvalidQuote";
import UnderageFinanceStop from "./components/underage-finance-stop/UnderageFinanceStop";
import ContactDetails from "../../your-quote/components/shared/contact-details/ContactDetails";
import QuoteSummaryNoClaimsProtectionCard from "./components/no-claims-protection-card/QuoteSummaryNoClaimsProtectionCard";
import {useNavigateOnQuoteUnavailable} from "../../your-quote/hooks/NavigateOnQuoteUnavailable";
import DepositAndPaymentsQuestion from "./components/deposit-and-payments-question/DepositAndPaymentsQuestion";
import AggregatorQuoteValidationFailureCards
  from "../../../aggregator-quote/components/aggregator-quote-validation-failure-cards/AggregatorQuoteValidationFailureCards";

const QuoteSummary: FC = () => {
  useNavigateOnInvalidQuote();
  useNavigateOnQuoteUnavailable();

  return (
    <>
      <QuoteResult/>
      <div className="main-content-save-quote">
        <SaveQuoteSidebar sidebarPresentationMode="CLOSED"/>
      </div>
      <AggregatorQuoteValidationFailureCards/>
      <UnderageFinanceStop/>
      <DepositAndPaymentsQuestion/>
      <PolicyExtrasSelection/>
      <QuoteCoverSummary/>
      <QuoteAccordionList/>
      <Spacer/>
      <ContactDetails/>
      <Spacer/>
      <QuoteSummaryNoClaimsProtectionCard/>
      <QuoteDocuments/>
      <LegalDocuments/>
    </>
  );
};

export default QuoteSummary;
