import React, {FC} from "react";
import Spacer from "shared-components/dist/spacer/Spacer";
import Title from "shared-components/dist/title/Title";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./DirectDebitPaymentInfo.css";
import {OpenInNew} from "@mui/icons-material";
import ExternalLink from "shared-components/dist/links//ExternalLink";
import SpinnerIcon from "shared-components/dist/spinner/spinner-icon/SpinnerIcon";
import {useCloseBrothersDocumentsLookup} from "./CloseBrothersDocumentsLookup";
import {logPaymentGoogleAnalyticsEvent} from "../../../../../../utils/analytics/PaymentAnalytics";

const DirectDebitPaymentInfo: FC = () => {
  const documentResponse = useCloseBrothersDocumentsLookup();

  return (
    <div className="direct-debit-payment-info">
      <Spacer/>

      <Title title="paymentFlow.monthly.directDebit.paymentInfo.title"/>

      <p className="direct-debit-payment-info__description">
        {lookupI18nString("paymentFlow.monthly.directDebit.paymentInfo.description")}
      </p>

      {documentResponse.isLoading &&
        <SpinnerIcon className="direct-debit-payment-info__loading-spinner"/>
      }

      {!documentResponse.isLoading && !documentResponse.documentLinks &&
        <p className="direct-debit-payment-info__error-message">
          {lookupI18nString("paymentFlow.monthly.directDebit.paymentInfo.errorMessage")}
        </p>
      }

      {!documentResponse.isLoading && documentResponse.documentLinks &&
        <>
          <ExternalLink
            url={documentResponse.documentLinks.commonQuestions}
            onClick={() => logPaymentGoogleAnalyticsEvent({
              categorySuffix: "monthly.common_questions",
              action: "Click"
            })}
          >
            <OpenInNew/>
            {lookupI18nString("paymentFlow.monthly.directDebit.paymentInfo.links.commonQuestions")}
          </ExternalLink>

          <ExternalLink
            url={documentResponse.documentLinks.creditInformation}
            onClick={() => logPaymentGoogleAnalyticsEvent({
              categorySuffix: "monthly.credit_information",
              action: "Click"
            })}
          >
            <OpenInNew/>
            {lookupI18nString("paymentFlow.monthly.directDebit.paymentInfo.links.creditInformation")}
          </ExternalLink>

          <ExternalLink
            url={documentResponse.documentLinks.directDebitGuarantee}
            onClick={() => logPaymentGoogleAnalyticsEvent({
              categorySuffix: "monthly.direct_debit_guarantee",
              action: "Click"
            })}
          >
            <OpenInNew/>
            {lookupI18nString("paymentFlow.monthly.directDebit.paymentInfo.links.directDebitGuarantee")}
          </ExternalLink>
        </>
      }
    </div>
  );
};

export default DirectDebitPaymentInfo;