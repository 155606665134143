import React, {FC} from "react";
import {shouldShowAdditionalDetailsNonEuImportVehicleStopSelector} from "../../../../redux/selectors/VehiclePageStateSelectors";
import NonEuImportTypeStop from "../../../../../shared/stops/import-type-stops/NonEuImportTypeStop";
import {useAppSelector} from "../../../../../../../../redux/Hooks";

const AdditionalDetailsNonEuImportVehicleStop: FC = () => {
  const isVisible = useAppSelector(shouldShowAdditionalDetailsNonEuImportVehicleStopSelector);

  return (
    <NonEuImportTypeStop
      isVisible={isVisible}
      isSubContainer
    />
  );
};

export default AdditionalDetailsNonEuImportVehicleStop;
