import CoverSummary from "./cover-summary/CoverSummary";
import PolicyExtras from "./policy-extras/PolicyExtras";
import {FC} from "react";
import "./PolicySummary.css";
import YourCarDetails from "./dropdown-details/YourCarDetails";
import YourDetails from "./dropdown-details/YourDetails";
import YourAdditionalDrivers from "./dropdown-details/YourAdditionalDrivers";
import MakeAChangeCard from "./make-a-change-card/MakeAChangeCard";
import AccordionList, {AccordionItemProps} from "shared-components/dist/lists/accordian-list/AccordionList";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import PolicyErrorMessage from "../../../shared/error/PolicyErrorMessage";
import {useAppSelector} from "../../../../../redux/Hooks";
import {selectPolicyHolder} from "../../../shared/redux/PortalSelectors";
import {Policy} from "shared/src/generated/graphql/resolvers-types";

interface Props {
  policy: Policy;
}

const PolicySummary: FC<Props> = ({policy}) => {
  const policyHolder = useAppSelector(selectPolicyHolder);

  if (!policyHolder) return <PolicyErrorMessage/>;

  const accordionList: AccordionItemProps[] = [
    {
      title: lookupI18nString("policies.mainContent.yourCar"),
      contents: <YourCarDetails policy={policy}/>
    },
    {
      title: lookupI18nString("policies.mainContent.yourDetails"),
      contents: <YourDetails policyHolder={policyHolder}/>
    }
  ];

  const yourAdditionalDrivers: AccordionItemProps = {
    title: lookupI18nString("policies.mainContent.yourAdditionalDrivers"),
    contents: <YourAdditionalDrivers policy={policy}/>
  };

  if (policy.additionalDrivers.length > 0) accordionList.push(yourAdditionalDrivers);

  return (
    <div className="policy-summary">
      <div className="policy-summary__overview">
        <CoverSummary policy={policy}/>
        <AccordionList items={accordionList}/>
        {policy.policyExtrasMetadata && <PolicyExtras policyExtras={policy.policyExtrasMetadata}/>}
        <MakeAChangeCard/>
      </div>
    </div>
  );
};

export default PolicySummary;
