import {AppThunk, AppThunkDispatch} from "../../../../../../../../redux/Store";
import {
  breakInCoverChanged,
  breakInCoverDeclarationAcceptanceChanged,
  coverStartDateChanged,
  coverTypeChanged,
  protectNoClaimsBonusChanged,
  voluntaryExcessChanged,
} from "../../../../../your-cover/redux/CoverDetailsSlice";
import {EditCoverSummaryPayload} from "./model/EditCoverSummaryPayload";
import {loadQuotes} from "../../../../../your-quote/redux/thunks/LoadQuotes";
import {toSerializableDateOption} from "shared/dist/models/date-option/DateOption";
import {handleRefreshedQuotes} from "../../../../redux/HandleRefreshedQuotes";

export const coverSummaryEdited = (
  monthlyPaymentsEnabled: boolean,
  updatedCoverSummary: EditCoverSummaryPayload,
  isEligibleForNamedDriver: boolean
): AppThunk => async (dispatch, getState) => {

  return editCoverSummary(updatedCoverSummary, dispatch, isEligibleForNamedDriver)
    .then(() => dispatch(loadQuotes({monthlyPaymentsEnabled})))
    .then(() => handleRefreshedQuotes(getState, dispatch));
};

async function editCoverSummary(updatedCoverSummary: EditCoverSummaryPayload, dispatch: AppThunkDispatch, isEligibleForNamedDriver: boolean): Promise<void> {
  await Promise.all([
    editCoverStartDate(updatedCoverSummary, dispatch, isEligibleForNamedDriver),
    editVoluntaryExcess(updatedCoverSummary, dispatch),
    editCoverType(updatedCoverSummary, dispatch),
    editProtectNoClaimsBonus(updatedCoverSummary, dispatch),
    editBreakInCover(updatedCoverSummary, dispatch),
    editBreakInCoverDeclarationAcceptance(updatedCoverSummary, dispatch)
  ]);
}

const editCoverStartDate = async ({selectedCoverStartDate}: EditCoverSummaryPayload, dispatch: AppThunkDispatch, isEligibleForNamedDriver: boolean): Promise<void> => {
  if (selectedCoverStartDate) await dispatch(coverStartDateChanged({value: toSerializableDateOption(selectedCoverStartDate), isEligibleForNamedDriver}));
};

const editVoluntaryExcess = async ({selectedVoluntaryExcess}: EditCoverSummaryPayload, dispatch: AppThunkDispatch): Promise<void> => {
  if (selectedVoluntaryExcess) await dispatch(voluntaryExcessChanged(selectedVoluntaryExcess));
};

const editCoverType = async ({selectedCoverType}: EditCoverSummaryPayload, dispatch: AppThunkDispatch): Promise<void> => {
  if (selectedCoverType) await dispatch(coverTypeChanged(selectedCoverType));
};

const editProtectNoClaimsBonus = async ({selectedProtectNoClaimsBonus}: EditCoverSummaryPayload, dispatch: AppThunkDispatch): Promise<void> => {
  if (selectedProtectNoClaimsBonus !== undefined) await dispatch(protectNoClaimsBonusChanged(selectedProtectNoClaimsBonus));
};

const editBreakInCover = async ({breakInCover}: EditCoverSummaryPayload, dispatch: AppThunkDispatch): Promise<void> => {
  await dispatch(breakInCoverChanged(breakInCover));
};

const editBreakInCoverDeclarationAcceptance = async ({breakInCoverDeclarationAcceptance}: EditCoverSummaryPayload, dispatch: AppThunkDispatch): Promise<void> => {
  if (breakInCoverDeclarationAcceptance) dispatch(breakInCoverDeclarationAcceptanceChanged(breakInCoverDeclarationAcceptance));
};
