import React, {FC} from "react";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./FinancePaymentSummaryCardItem.css";

interface Props {
  title: TranslationKey;
  value: string;
}

const FinancePaymentSummaryCardItem: FC<Props> = ({title, value}) => (
  <div className="finance-payment-summary-card-item">
    <span className="finance-payment-summary-card-item__title">{lookupI18nString(title)}</span>
    <span className="finance-payment-summary-card-item__value">{value}</span>
  </div>
);

export default FinancePaymentSummaryCardItem;