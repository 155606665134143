import {SerializableClaim} from "../../../../../pages/quote/vehicle/shared/questions/claims/models/Claims";
import {QuoteIncident} from "shared/dist/generated/graphql/resolvers-types";

export function mapQuoteIncidents(claims: SerializableClaim[]): QuoteIncident[] {
  return claims.map(claim => {
    return {
      date: claim.date,
      atFault: claim.atFault.vtCode,
      incidentType: claim.incidentType.vtCode,
      accidentDescription: claim.accidentDescription?.vtCode,
      whichPolicyClaimedAgainst: claim.whichPolicyClaimedAgainst.vtCode,
      noClaimsAffected: claim.noClaimsAffected.vtCode,
      bodilyInjuryOccurred: claim.bodilyInjuryOccurred,
      value: claim.value,
      status: claim.status.id
    };
  });
}
