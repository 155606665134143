import React, {FC} from "react";
import FullPageSpinner from "shared-components/dist/spinner/full-page-spinner/FullPageSpinner";
import "./QuoteLoading.css";
import QuoteHeader from "../quote-step/header/QuoteHeader";

const QuoteLoading: FC = () => (
  <div className="quote-loading">
    <QuoteHeader/>
    <FullPageSpinner/>
  </div>
);

export default QuoteLoading;