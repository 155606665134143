import React, {FC} from "react";
import DocumentLinkSection from "../document-links/DocumentLinkSection";
import {InfoOutlined} from "@mui/icons-material";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import Button from "shared-components/dist/buttons//button/Button";
import {PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL} from "shared/dist/constants/Urls";

export const LegalDocuments: FC = () => (
  <DocumentLinkSection
    title="quoteDetails.legalDocuments.title"
    description="quoteDetails.legalDocuments.description"
  >
    <Button
      variant="link"
      leftIcon={<InfoOutlined/>}
      onClick={() => open(TERMS_AND_CONDITIONS_URL)}
    >
      {lookupI18nString("quoteDetails.legalDocuments.termsAndConditions")}
    </Button>

    <Button
      variant="link"
      leftIcon={<InfoOutlined/>}
      onClick={() => open(PRIVACY_POLICY_URL)}
    >
      {lookupI18nString("quoteDetails.legalDocuments.privacyPolicy")}
    </Button>
  </DocumentLinkSection>
);

export default LegalDocuments;