import React, {FC, FormEvent, MouseEvent} from "react";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import LogoutModalButtons from "./LogoutModalButtons";
import "./LogoutModalBody.css";
import {logout} from "../../../../../user-authentication/login/redux/thunks/Logout";
import {
  EventCategoryPrefix,
  logGoogleAnalyticsEvent
} from "shared-components/dist/analytics/GoogleAnalyticsEventsService";
import {useAppDispatch} from "../../../../../../redux/Hooks";

interface Props {
  dismiss: () => void;
  googleAnalyticsPrefix: EventCategoryPrefix;
}

const LogoutModalBody: FC<Props> = (
  {
    dismiss,
    googleAnalyticsPrefix
  }
) => {
  const dispatch = useAppDispatch();

  const onDismiss = (): void => {
    dismiss();
    logGoogleAnalyticsEvent({
      categoryPrefix: googleAnalyticsPrefix,
      categorySuffix: "navigation.logout_modal.cancel",
      action: "Click"
    });
  };

  const onConfirmLogOut = (event: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    dispatch(logout());
    dismiss();
    logGoogleAnalyticsEvent({
      categoryPrefix: googleAnalyticsPrefix,
      categorySuffix: "navigation.logout_modal.yes_im_sure",
      action: "Click"
    });
  };

  return (
    <form onSubmit={onConfirmLogOut}>
      <p>{lookupI18nString("navbar.logout.modal.description")}</p>

      <LogoutModalButtons
        onConfirmLogout={onConfirmLogOut}
        onDismiss={onDismiss}
      />
    </form>
  );
};

export default LogoutModalBody;
