import {LoadingStatus} from "../../../pages/quote/vehicle/your-quote/models/LoadingStatus";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {CurrencyFormat} from "../../money/CurrencyFormat";
import {Price} from "shared/dist/generated/graphql/resolvers-types";

export const displayLoanValue = (value: Price | undefined, closeBrothersLoanStatus?: LoadingStatus): string => {
  if (closeBrothersLoanStatus === LoadingStatus.LOADING || (!closeBrothersLoanStatus && !value) || !value) {
    return lookupI18nString("quoteDetails.depositAndPayments.placeholder");
  }

  return CurrencyFormat.formatPriceWithSymbol(value);
};
