import {
  conditionalFieldReset,
  fieldChanged,
  fieldInvalid,
  fieldReset
} from "shared-components/dist/models/form-field/FormField";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {RelationshipToProposerOption} from "../components/relationship-to-proposer/model/RelationshipToProposerOption";
import {AbiListItem} from "shared/dist/generated/graphql/resolvers-types";
import {isUnderTwentyFive} from "../../../shared/questions/date-of-birth/validators/IsUnderTwentyFive";
import {CustomisedAbiListItem} from "shared-components/dist/models/CustomisedAbiListItem";
import {LicenceCountryOfIssue} from "../../../shared/questions/licence-country-of-issue/LicenceCountryOfIssue";
import {MedicalConditionOption} from "../../../shared/questions/medical-condition/MedicalConditionOption";
import {SerializableConviction} from "../../../shared/questions/convictions/models/Conviction";
import {removeAtIndex} from "shared-components/dist/utils/stdlib/Arrays";
import {ClassOfUseOption} from "../../../shared/questions/class-of-use/ClassOfUseOption";
import {EmploymentStatusOption} from "../../../shared/questions/employment/models/EmploymentStatusOption";
import {GenderOption} from "../../../shared/questions/gender/Genders";
import {MaritalStatusOption} from "../../../shared/questions/martial-status/MaritalStatusOption";
import {LicenceTypeOption} from "../../../shared/questions/licence-type/LicenceTypeOption";
import {
  additionalDriverAdded,
  additionalDriverEdited,
  additionalDriverRemoved,
  policyPurchased,
  quoteReset
} from "../../../../../../redux/Actions";
import {employmentChanged} from "../../../shared/redux/ChangeEmployment";
import {SerializableClaim} from "../../../shared/questions/claims/models/Claims";
import {Status} from "shared-components/dist/status-indicator/Status";
import {UpdateAdditionalDriver} from "./UpdateAdditionalDriver";
import {convertTitleToGender} from "../../../shared/redux/ConvertTitleToGender";

export const initialState: UpdateAdditionalDriver = {
  relationshipToProposer: {
    status: "default",
    value: undefined
  },
  title: {
    status: "default",
    value: undefined
  },
  firstName: {
    status: "default",
    value: undefined
  },
  surname: {
    status: "default",
    value: undefined
  },
  dateOfBirth: {
    status: "default",
    value: undefined
  },
  householdNumberOfCars: {
    status: "default",
    value: undefined,
    isActive: false
  },
  gender: {
    status: "default",
    value: undefined,
    isActive: false
  },
  hasUkResidencyFromBirth: {
    status: "default",
    value: undefined
  },
  ukResidencyDuration: {
    status: "default",
    value: undefined,
    isActive: false
  },
  maritalStatus: {
    status: "default",
    value: undefined
  },
  primaryEmployment: {
    employmentStatus: {
      status: "default",
      value: undefined
    },
    industry: {
      status: "default",
      value: undefined,
      isActive: false
    },
    job: {
      status: "default",
      value: undefined,
      isActive: false
    },
    workedInPastYear: {
      status: "default",
      value: undefined,
      isActive: false
    },
  },
  hasSecondaryEmployment: {
    status: "default",
    value: undefined
  },
  secondaryEmployment: {
    employmentStatus: {
      status: "default",
      value: undefined,
      isActive: false
    },
    industry: {
      status: "default",
      value: undefined,
      isActive: false
    },
    job: {
      status: "default",
      value: undefined,
      isActive: false
    },
    workedInPastYear: {
      status: "default",
      value: undefined,
      isActive: false
    },
  },
  classOfUse: {
    status: "default",
    value: undefined
  },
  licenceCountryOfIssue: {
    status: "default",
    value: undefined
  },
  licenceType: {
    status: "default",
    value: undefined
  },
  licenceDuration: {
    status: "default",
    value: undefined
  },
  hasMedicalCondition: {
    status: "default",
    value: undefined
  },
  medicalCondition: {
    status: "default",
    value: undefined,
    isActive: false
  },
  claims: {
    status: "default",
    value: {
      hasClaims: undefined,
      claims: [],
      shouldFlagUnansweredQuestions: false,
      isFormVisible: false
    }
  },
  convictions: {
    status: "default",
    value: {
      hasConvictions: undefined,
      convictions: [],
      shouldFlagUnansweredQuestions: false,
      isFormVisible: false
    }
  },
  hasNonMotoringConvictions: {
    status: "default",
    value: undefined
  },
  hasInsuranceDeclined: {
    status: "default",
    value: undefined
  },
  idOfDriverBeingEdited: undefined
};

export const updateAdditionalDriverSliceName = "updateAdditionalDriver";

const updateAdditionalDriverSlice = createSlice({
  name: updateAdditionalDriverSliceName,
  initialState,
  reducers: {
    relationshipToProposerChanged(state, action: PayloadAction<RelationshipToProposerOption>): void {
      fieldChanged(state.relationshipToProposer, action.payload);
    },
    relationshipToProposerInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.relationshipToProposer, action.payload);
    },
    titleChanged(state, action: PayloadAction<AbiListItem>): void {
      fieldChanged(state.title, action.payload);
      convertTitleToGender(state, action.payload);
    },
    titleInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.title, action.payload);
    },
    titleReset(state): void {
      fieldReset(state.title);
    },
    firstNameChanged(state, action: PayloadAction<string>): void {
      fieldChanged(state.firstName, action.payload);
    },
    firstNameInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.firstName, action.payload);
    },
    surnameChanged(state, action: PayloadAction<string>): void {
      fieldChanged(state.surname, action.payload);
    },
    surnameInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.surname, action.payload);
    },
    dateOfBirthChanged(state, action: PayloadAction<string>): void {
      fieldChanged(state.dateOfBirth, action.payload);

      if (isUnderTwentyFive(new Date(action.payload))) {
        state.householdNumberOfCars.isActive = true;
      } else {
        state.householdNumberOfCars.isActive = false;
        fieldReset(state.householdNumberOfCars);
      }
    },
    dateOfBirthInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.dateOfBirth, action.payload);
    },
    dateOfBirthReset(state): void {
      fieldReset(state.dateOfBirth);
    },
    householdNumberOfCarsChanged(state, action: PayloadAction<string>): void {
      fieldChanged(state.householdNumberOfCars, action.payload);
    },
    householdNumberOfCarsInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.householdNumberOfCars, action.payload);
    },
    householdNumberOfCarsReset(state): void {
      fieldReset(state.householdNumberOfCars);
    },
    genderChanged(state, action: PayloadAction<GenderOption>): void {
      fieldChanged(state.gender, action.payload);
    },
    genderInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.gender, action.payload);
    },
    hasUkResidencyFromBirthChanged(state, action: PayloadAction<boolean>): void {
      fieldChanged(state.hasUkResidencyFromBirth, action.payload);
      if (!action.payload) {
        state.ukResidencyDuration.isActive = true;
      } else {
        conditionalFieldReset(state.ukResidencyDuration);
      }
    },
    hasUkResidencyFromBirthInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.hasUkResidencyFromBirth, action.payload);
    },
    ukResidencyDurationChanged(state, action: PayloadAction<Duration>): void {
      fieldChanged(state.ukResidencyDuration, action.payload);
    },
    ukResidencyDurationInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.ukResidencyDuration, action.payload);
    },
    ukResidencyDurationReset(state): void {
      fieldReset(state.ukResidencyDuration);
    },
    maritalStatusChanged(state, action: PayloadAction<MaritalStatusOption>): void {
      fieldChanged(state.maritalStatus, action.payload);
    },
    maritalStatusInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.maritalStatus, action.payload);
    },
    primaryEmploymentStatusChanged(state, action: PayloadAction<EmploymentStatusOption>): void {
      fieldChanged(state.primaryEmployment.employmentStatus, action.payload);

      employmentChanged(state.primaryEmployment, initialState.primaryEmployment);
    },
    primaryEmploymentStatusInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.primaryEmployment.employmentStatus, action.payload);
    },
    primaryEmploymentIndustryChanged(state, action: PayloadAction<CustomisedAbiListItem>): void {
      fieldChanged(state.primaryEmployment.industry, action.payload);
    },
    primaryEmploymentIndustryInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.primaryEmployment.industry, action.payload);
    },
    primaryEmploymentJobChanged(state, action: PayloadAction<CustomisedAbiListItem>): void {
      fieldChanged(state.primaryEmployment.job, action.payload);
    },
    primaryEmploymentJobInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.primaryEmployment.job, action.payload);
    },
    additionalDriverClassOfUseChanged(state, action: PayloadAction<ClassOfUseOption>): void {
      fieldChanged(state.classOfUse, action.payload);
    },
    additionalDriverClassOfUseInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.classOfUse, action.payload);
    },
    primaryEmploymentWorkedInPastYearChanged(state, action: PayloadAction<boolean>): void {
      fieldChanged(state.primaryEmployment.workedInPastYear, action.payload);

      if (!action.payload) {
        state.primaryEmployment.job = initialState.primaryEmployment.job;
      } else {
        state.primaryEmployment.job = {...initialState.primaryEmployment.job, isActive: true};
      }
    },
    primaryEmploymentWorkedInPastYearInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.primaryEmployment.workedInPastYear, action.payload);
    },
    hasSecondaryEmploymentChanged(state, action: PayloadAction<Boolean>): void {
      fieldChanged(state.hasSecondaryEmployment, action.payload);

      if (action.payload) {
        state.secondaryEmployment.employmentStatus.isActive = true;
      } else {
        conditionalFieldReset(state.secondaryEmployment.employmentStatus);
        conditionalFieldReset(state.secondaryEmployment.job);
        conditionalFieldReset(state.secondaryEmployment.industry);
        conditionalFieldReset(state.secondaryEmployment.workedInPastYear);
      }
    },
    hasSecondaryEmploymentInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.hasSecondaryEmployment, action.payload);
    },
    secondaryEmploymentStatusChanged(state, action: PayloadAction<EmploymentStatusOption>): void {
      fieldChanged(state.secondaryEmployment.employmentStatus, action.payload);

      employmentChanged(state.secondaryEmployment, initialState.secondaryEmployment);
    },
    secondaryEmploymentStatusInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.secondaryEmployment.employmentStatus, action.payload);
    },
    secondaryEmploymentIndustryChanged(state, action: PayloadAction<CustomisedAbiListItem>): void {
      fieldChanged(state.secondaryEmployment.industry, action.payload);
    },
    secondaryEmploymentIndustryInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.secondaryEmployment.industry, action.payload);
    },
    secondaryEmploymentJobChanged(state, action: PayloadAction<CustomisedAbiListItem>): void {
      fieldChanged(state.secondaryEmployment.job, action.payload);
    },
    secondaryEmploymentJobInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.secondaryEmployment.job, action.payload);
    },
    secondaryEmploymentWorkedInPastYearChanged(state, action: PayloadAction<boolean>): void {
      fieldChanged(state.secondaryEmployment.workedInPastYear, action.payload);

      if (!action.payload) {
        state.secondaryEmployment.job = initialState.secondaryEmployment.job;
      } else {
        state.secondaryEmployment.job = {...initialState.secondaryEmployment.job, isActive: true};
      }
    },
    secondaryEmploymentWorkedInPastYearInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.secondaryEmployment.workedInPastYear, action.payload);
    },
    licenceCountryOfIssueChanged(state, action: PayloadAction<LicenceCountryOfIssue>): void {
      fieldChanged(state.licenceCountryOfIssue, action.payload);
    },
    licenceCountryOfIssueInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.licenceCountryOfIssue, action.payload);
    },
    licenceTypeChanged(state, action: PayloadAction<LicenceTypeOption>): void {
      fieldChanged(state.licenceType, action.payload);
    },
    licenceTypeInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.licenceType, action.payload);
    },
    licenceDurationChanged(state, action: PayloadAction<Duration>): void {
      fieldChanged(state.licenceDuration, action.payload);
    },
    licenceDurationInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.licenceDuration, action.payload);
    },
    licenceDurationReset(state): void {
      fieldReset(state.licenceDuration);
    },
    hasMedicalConditionChanged(state, action: PayloadAction<boolean>): void {
      fieldChanged(state.hasMedicalCondition, action.payload);

      state.medicalCondition = {...initialState.medicalCondition, isActive: action.payload};
    },
    hasMedicalConditionInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.hasMedicalCondition, action.payload);
    },
    medicalConditionChanged(state, action: PayloadAction<MedicalConditionOption>): void {
      fieldChanged(state.medicalCondition, action.payload);
    },
    medicalConditionInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.medicalCondition, action.payload);
    },
    medicalConditionReset(state): void {
      state.medicalCondition = {...initialState.medicalCondition, isActive: !!state.hasMedicalCondition.value};
    },
    hasClaimsChanged(state, action: PayloadAction<boolean>): void {
      state.claims.value.hasClaims = action.payload;
      state.claims.value.claims = [];
      state.claims.errorMessage = undefined;
      state.claims.status = "success";
    },
    claimsInvalid(state, action: PayloadAction<TranslationKey>): void {
      state.claims.status = "error";
      state.claims.errorMessage = action.payload;
    },
    flagUnansweredClaimsSubQuestions(state): void {
      state.claims.value.shouldFlagUnansweredQuestions = true;
    },
    isClaimsFormVisibleSet(state, action: PayloadAction<boolean>): void {
      state.claims.value.isFormVisible = action.payload;
      state.claims.value.shouldFlagUnansweredQuestions = false;
    },
    claimAdded(state, action: PayloadAction<SerializableClaim>): void {
      state.claims.value.claims.push(action.payload);
      state.claims.status = "success";
    },
    claimRemoved(state, action: PayloadAction<number>): void {
      state.claims.value.claims = removeAtIndex(state.claims.value.claims, action.payload);
    },
    claimStatusChanged(state, action: PayloadAction<Status>): void {
      state.claims.status = action.payload;
    },
    hasConvictionsChanged(state, action: PayloadAction<boolean>): void {
      state.convictions.value.hasConvictions = action.payload;
      state.convictions.value.convictions = [];
      state.convictions.errorMessage = undefined;
      state.convictions.status = "success";
    },
    flagUnansweredConvictionsSubQuestions(state): void {
      state.convictions.value.shouldFlagUnansweredQuestions = true;
    },
    isConvictionsFormVisibleSet(state, action: PayloadAction<boolean>): void {
      state.convictions.value.isFormVisible = action.payload;
      state.convictions.value.shouldFlagUnansweredQuestions = false;
    },
    convictionsInvalid(state, action: PayloadAction<TranslationKey>): void {
      state.convictions.status = "error";
      state.convictions.errorMessage = action.payload;
    },
    convictionAdded(state, action: PayloadAction<SerializableConviction>): void {
      state.convictions.value.convictions.push(action.payload);
      state.convictions.status = "success";
    },
    convictionRemoved(state, action: PayloadAction<number>): void {
      state.convictions.value.convictions = removeAtIndex(state.convictions.value.convictions, action.payload);
    },
    convictionStatusChanged(state, action: PayloadAction<Status>): void {
      state.convictions.status = action.payload;
    },
    hasNonMotoringConvictionsChanged(state, action: PayloadAction<boolean>): void {
      fieldChanged(state.hasNonMotoringConvictions, action.payload);
    },
    hasNonMotoringConvictionsInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.hasNonMotoringConvictions, action.payload);
    },
    insuranceDeclinedQuestionChanged(state, action: PayloadAction<boolean>): void {
      fieldChanged(state.hasInsuranceDeclined, action.payload);
    },
    insuranceDeclinedQuestionInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.hasInsuranceDeclined, action.payload);
    },
    additionalDriverReset: () => initialState,
    additionalDriverLoaded: (state, action: PayloadAction<UpdateAdditionalDriver>) => action.payload,
  },
  extraReducers: {
    [quoteReset.type]: () => initialState,
    [policyPurchased.type]: () => initialState,
    [additionalDriverAdded.type]: () => initialState,
    [additionalDriverEdited.type]: () => initialState,
    [additionalDriverRemoved.type]: () => initialState
  }
});

export const {
  relationshipToProposerChanged,
  relationshipToProposerInvalid,
  titleChanged,
  titleInvalid,
  titleReset,
  firstNameChanged,
  firstNameInvalid,
  surnameChanged,
  surnameInvalid,
  dateOfBirthChanged,
  dateOfBirthInvalid,
  dateOfBirthReset,
  householdNumberOfCarsChanged,
  householdNumberOfCarsInvalid,
  householdNumberOfCarsReset,
  genderChanged,
  genderInvalid,
  hasUkResidencyFromBirthChanged,
  hasUkResidencyFromBirthInvalid,
  ukResidencyDurationChanged,
  ukResidencyDurationInvalid,
  ukResidencyDurationReset,
  maritalStatusChanged,
  maritalStatusInvalid,
  primaryEmploymentIndustryChanged,
  primaryEmploymentIndustryInvalid,
  primaryEmploymentJobChanged,
  primaryEmploymentJobInvalid,
  primaryEmploymentStatusChanged,
  primaryEmploymentStatusInvalid,
  primaryEmploymentWorkedInPastYearChanged,
  primaryEmploymentWorkedInPastYearInvalid,
  hasSecondaryEmploymentChanged,
  hasSecondaryEmploymentInvalid,
  secondaryEmploymentStatusChanged,
  secondaryEmploymentStatusInvalid,
  secondaryEmploymentIndustryChanged,
  secondaryEmploymentIndustryInvalid,
  secondaryEmploymentJobChanged,
  secondaryEmploymentJobInvalid,
  secondaryEmploymentWorkedInPastYearChanged,
  secondaryEmploymentWorkedInPastYearInvalid,
  additionalDriverClassOfUseChanged,
  additionalDriverClassOfUseInvalid,
  licenceCountryOfIssueChanged,
  licenceCountryOfIssueInvalid,
  licenceTypeChanged,
  licenceTypeInvalid,
  licenceDurationChanged,
  licenceDurationInvalid,
  licenceDurationReset,
  hasMedicalConditionChanged,
  hasMedicalConditionInvalid,
  medicalConditionChanged,
  medicalConditionInvalid,
  medicalConditionReset,
  hasClaimsChanged,
  isClaimsFormVisibleSet,
  flagUnansweredClaimsSubQuestions,
  claimAdded,
  claimRemoved,
  claimsInvalid,
  claimStatusChanged,
  hasConvictionsChanged,
  flagUnansweredConvictionsSubQuestions,
  isConvictionsFormVisibleSet,
  convictionsInvalid,
  convictionAdded,
  convictionRemoved,
  convictionStatusChanged,
  insuranceDeclinedQuestionChanged,
  insuranceDeclinedQuestionInvalid,
  hasNonMotoringConvictionsChanged,
  hasNonMotoringConvictionsInvalid,
  additionalDriverReset,
  additionalDriverLoaded,
} = updateAdditionalDriverSlice.actions;

export const updateAdditionalDriverReducer = updateAdditionalDriverSlice.reducer;