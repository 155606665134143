import React, {FC, useEffect, useState} from "react";
import DocumentUploadDropdown from "./components/DocumentUploadDropdown";
import "./PolicyDocumentUploadForm.css";
import {generateDocumentUploadPresignedUrl} from "../service/GeneratePresignedUrlService";
import DragAndDrop from "shared-components/dist/form/drag-and-drop/DragAndDrop";
import {uploadFileToStrollBucket} from "shared-components/dist/service/upload/UploadService";
import {Policy, UploadedDocumentType} from "shared/dist/generated/graphql/resolvers-types";
import {useFileStore} from "shared-components/dist/form/drag-and-drop/hooks/UseFileStore";
import {logPortalGoogleAnalyticsEvent} from "../../../../utils/analytics/PortalAnalytics";
import {RequiredFormField} from "shared-components/dist/models/form-field/FormField";
import {useAppDispatch, useAppSelector} from "../../../../redux/Hooks";
import {documentHasBeenUploaded} from "../../shared/redux/PortalSlice";
import {updateVisibleReminderTypes} from "../../shared/reminders/thunk/UpdateVisibleReminderTypes";
import PolicySelector from "../../shared/policy-selection/PolicySelector";
import {getInitialSelectedPolicy, policyListIsEmpty} from "../../shared/utils/PolicySelectionUtils";
import {selectPolicyList} from "../../shared/redux/PortalSelectors";
import PolicyErrorMessage from "../../shared/error/PolicyErrorMessage";

const initialDocumentUploadTypeField: RequiredFormField<UploadedDocumentType> = {value: UploadedDocumentType.Unknown, status: "default"};

const PolicyDocumentUploadForm: FC = () => {
  const policyList = useAppSelector(selectPolicyList);
  const dispatch = useAppDispatch();
  const [documentUploadTypeField, setDocumentUploadTypeField] = useState<RequiredFormField<UploadedDocumentType>>(initialDocumentUploadTypeField);
  const [selectedPolicy, setSelectedPolicy] = useState<Policy>(getInitialSelectedPolicy(policyList));
  const pendingFileStore = useFileStore();
  const failedFileStore = useFileStore();
  const successfulFileStore = useFileStore();

  useEffect(() => {
    setDocumentUploadTypeField({value: UploadedDocumentType.Unknown, status: "default"});
  }, [selectedPolicy]);

  const uploadFile = async (file: File): Promise<void> => {
    if (selectedPolicy) {
      const url = await generateDocumentUploadPresignedUrl(selectedPolicy.id, documentUploadTypeField.value);
      await uploadFileToStrollBucket(file, url);
      updateReminderDisplayed();
    }
  };

  const updateReminderDisplayed = (): void => {
    dispatch(documentHasBeenUploaded(true));
    dispatch(updateVisibleReminderTypes());
  };

  const validateFields = (): void => {
    if (documentUploadTypeField.status !== "success") {
      setDocumentUploadTypeField({...initialDocumentUploadTypeField, status: "error", errorMessage: "document.upload.documentTypeError"});
    }
  };

  function onDocumentTypeChanged(documentUploadType: UploadedDocumentType): void {
    failedFileStore.clear();
    successfulFileStore.clear();
    setDocumentUploadTypeField({value: documentUploadType, status: "success"});

    const googleAnalyticsSuffix = getGoogleAnalyticsSuffixFromDocumentType(documentUploadType);
    if (googleAnalyticsSuffix) {
      logPortalGoogleAnalyticsEvent({
        categorySuffix: googleAnalyticsSuffix,
        action: "From_dropdown"
      });
    }
  }

  const onBrowseFilesButtonClick = (): void => {
    logPortalGoogleAnalyticsEvent({
      categorySuffix: "uploads.form.browse_files",
      action: "Click"
    });
  };

  const onUploadFilesButtonClick = (): void => {
    logPortalGoogleAnalyticsEvent({
      categorySuffix: "uploads.form.confirm_and_upload",
      action: "Click"
    });
  };

  if (!policyList || policyListIsEmpty(policyList)) return <PolicyErrorMessage/>;

  return (
    <div className="policy-document-upload-form">
      <PolicySelector policyList={policyList} selectedPolicy={selectedPolicy} setSelectedPolicy={setSelectedPolicy}/>

      <DocumentUploadDropdown
        onDocumentTypeChanged={onDocumentTypeChanged}
        documentTypeFormField={documentUploadTypeField}
        selectedPolicy={selectedPolicy}
      />

      <DragAndDrop
        header="document.upload.form.header"
        description="document.upload.form.description"
        buttonText="document.upload.form.button.text"
        onBrowseFilesButtonClick={onBrowseFilesButtonClick}
        fileUploadHandler={uploadFile}
        onUploadFilesButtonClick={onUploadFilesButtonClick}
        pendingFileStore={pendingFileStore}
        failedFileStore={failedFileStore}
        successfulFileStore={successfulFileStore}
        acceptedFileTypes={["JPG", "PNG", "PDF"]}
        mobileHeader="document.upload.form.mobile.header"
        isValid={documentUploadTypeField.status === "success" && pendingFileStore.files.length > 0}
        validate={validateFields}
      />
    </div>
  );
};

function getGoogleAnalyticsSuffixFromDocumentType(documentType: UploadedDocumentType): string | undefined {
  switch (documentType) {
    case UploadedDocumentType.LicencePlasticFront:
      return "uploads.form.driving_licence_plastic_front";
    case UploadedDocumentType.LicencePlasticBack:
      return "uploads.form.driving_licence_plastic_back";
    case UploadedDocumentType.LicencePaperFront:
      return "uploads.form.driving_licence_paper_front";
    case UploadedDocumentType.LicencePaperBack:
      return "uploads.form.driving_licence_paper_back";
    case UploadedDocumentType.NcbProof:
      return "uploads.form.proof_of_no_claims_bonus";
    case UploadedDocumentType.CompanyCarBonusProof:
      return "uploads.form.proof_of_company_car_bonus";
    case UploadedDocumentType.NamedDriverExperienceProof:
      return "uploads.form.proof_of_named_driver_experience";
    case UploadedDocumentType.Other:
      return "uploads.form.other";
    default:
      return undefined;
  }
}

export default PolicyDocumentUploadForm;
