import React, {FC} from "react";
import {Claim} from "../models/Claims";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {isSettledAccident} from "../helpers/IsSettledAccident";
import {NoClaimsAffected} from "shared/dist/generated/graphql/resolvers-types";
import {toGregorianFormat} from "shared/dist/stdlib/DateFormat";

interface Props {
  claim: Claim;
}

const ClaimItem: FC<Props> = ({claim}) => {
  const faultStatus = isSettledAccident(claim.status, claim.incidentType)
    ? claim.atFault.description
    : undefined;

  const affectedNoClaimsBonus = (): string => {
    switch (claim.noClaimsAffected.vtCode) {
      case NoClaimsAffected.No:
        return lookupI18nString("structure.actionCard.claims.affectedNCB.no");
      case NoClaimsAffected.Protected:
        return lookupI18nString("structure.actionCard.claims.affectedNCB.noProtected");
      case NoClaimsAffected.Yes:
        return lookupI18nString("structure.actionCard.claims.affectedNCB.yes");
    }
  };

  return (
    <>
      <p>
        {claim.incidentType.description}
        {faultStatus && (
          <span>: {faultStatus}</span>
        )}
      </p>
      <p>{toGregorianFormat(claim.date)}</p>
      <p>{affectedNoClaimsBonus()}</p>
    </>
  );
};

export default ClaimItem;