import React, {FC} from "react";
import LargeRag from "shared-components/dist/information/large-rag/LargeRag";
import {LargeRagSectionProps} from "shared-components/dist/information/large-rag/large-rag-section/LargeRagSection";
import {ChangePasswordResult} from "../../../../../../../../user/authentication/models/password-management/ChangePasswordResult";

interface Props {
  passwordsMatch?: boolean | undefined;
  passwordFormatValid: boolean;
  changePasswordResult?: ChangePasswordResult | undefined;
}

const EditPasswordErrorMessage: FC<Props> = (
  {
    passwordsMatch,
    passwordFormatValid,
    changePasswordResult
  }
) => {
  const errorSections: LargeRagSectionProps[] = [];

  const userIsNotAuthorizedToChangePassword = changePasswordResult === "CurrentPasswordInvalidException";
  const passwordFormatIsIncorrect = changePasswordResult === "PasswordPolicyViolationException";
  const tooManyChangePasswordAttempts = changePasswordResult === "LimitExceededException";
  const failedToChangePassword = changePasswordResult === "Failed";

  if (userIsNotAuthorizedToChangePassword) errorSections.push({title: "passwordConfirmation.error.message.unauthorized"});

  if (tooManyChangePasswordAttempts) errorSections.push({title: "passwordConfirmation.error.message.tooManyFailedAttempts"});

  if (failedToChangePassword) errorSections.push({title: "passwordConfirmation.error.message.genericFailure"});

  if (passwordsMatch !== undefined) {
    if (!passwordsMatch) errorSections.push({title: "passwordConfirmation.matchingPasswords.error.message"});
  }

  if (!passwordFormatValid || passwordFormatIsIncorrect) {
    errorSections.push({
      title: "accountSettings.form.error.password.format.title",
      descriptions: [
        "accountSettings.form.error.password.specialCharacter",
        "accountSettings.form.error.password.upperCaseLetter",
        "accountSettings.form.error.password.number",
        "accountSettings.form.error.password.minimumLength",
        "accountSettings.form.error.password.emailAddress"
      ]
    });
  }

  return (
    <>
      {
        errorSections.length > 0 &&
          <LargeRag
            sections={errorSections}
            status={"error"}
          />
      }
    </>
  );
};

export default EditPasswordErrorMessage;
