import React, {FC, useState} from "react";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./MarketingPreferences.css";
import CheckboxWithLabel from "shared-components/dist/form/checkbox/checkbox-with-label/CheckboxWithLabel";
import Button from "shared-components/dist/buttons/button/Button";
import {useUpdateMarketingPreferences} from "./hooks/UseUpdateMarketingPreferences";
import {selectEmailMarketingPreference, selectSmsMarketingPreference} from "../../shared/redux/PortalSelectors";
import {logPortalGoogleAnalyticsEvent} from "../../../../utils/analytics/PortalAnalytics";
import {useAppSelector} from "../../../../redux/Hooks";
import SuccessCard from "shared-components/dist/cards/success-card/SuccessCard";

const MarketingPreferences: FC = () => {
  const emailMarketingEnabled = useAppSelector(selectEmailMarketingPreference);
  const smsMarketingEnabled = useAppSelector(selectSmsMarketingPreference);
  const [isEmailChecked, setEmailChecked] = useState(emailMarketingEnabled);
  const [isSmsChecked, setSmsChecked] = useState(smsMarketingEnabled);
  const [marketingPreferencesUpdated, setMarketingPreferencesUpdated] = useState(false);
  const [updateMarketingPreferences, {loading}] = useUpdateMarketingPreferences(isEmailChecked, isSmsChecked);
  const isFormUnedited = isEmailChecked === emailMarketingEnabled && isSmsChecked === smsMarketingEnabled;

  const onSaveButtonClicked = (): void => {
    updateMarketingPreferences();
    logGoogleAnalytics(isEmailChecked, isSmsChecked);
    setMarketingPreferencesUpdated(true);
  };

  return (
    <div className="marketing-preferences">
      <SuccessCard
        visible={marketingPreferencesUpdated && !loading}
        onDismiss={() => setMarketingPreferencesUpdated(false)}
        title={"accountSettings.marketingPreferences.updated"}
      />
      <h4>{lookupI18nString("accountSettings.header.marketingPreferences")}</h4>
      <p>{lookupI18nString("accountSettings.marketingPreferences.content")}</p>
      <CheckboxWithLabel
        id="marketing-preferences-email-checkbox"
        onValueChanged={() => setEmailChecked(!isEmailChecked)}
        value={isEmailChecked}
        label="accountSettings.marketingPreferences.email.text"
      />
      <CheckboxWithLabel
        id="marketing-preferences-sms-checkbox"
        onValueChanged={() => setSmsChecked(!isSmsChecked)}
        value={isSmsChecked}
        label="accountSettings.marketingPreferences.sms.text"
      />
      <div className="save-button-container">
        <Button
          onClick={onSaveButtonClicked}
          disabled={isFormUnedited}
          loading={loading}
          className="save-marketing-preferences-button"
        >
          {lookupI18nString("accountSettings.marketingPreferences.savePreferences")}
        </Button>
      </div>
    </div>
  );
};

function logGoogleAnalytics(isEmailChecked: boolean, isSmsChecked: boolean): void {
  logPortalGoogleAnalyticsEvent({
    categorySuffix: "account.marketing_preferences.save",
    action: determineAction(isEmailChecked, isSmsChecked)
  });
}

function determineAction(isEmailChecked: boolean, isSmsChecked: boolean): string {
  if (isEmailChecked && isSmsChecked) return "Email_and_SMS";
  if (isEmailChecked && !isSmsChecked) return "Email_only";
  if (!isEmailChecked && isSmsChecked) return "SMS_only";
  return "None";
}

export default MarketingPreferences;
