import React, {FC} from "react";
import {householdNumberOfCarsStopVisibleSelector} from "../../redux/selectors/HouseholdNumberOfCarsStopVisibleSelector";
import QuoteJourneyStop from "../../../../shared/stops/quote-journey-stop/QuoteJourneyStop";
import {useAppSelector} from "../../../../../../../redux/Hooks";

const HouseholdHasTooManyCarsStop: FC = () => {
  const isVisible = useAppSelector(householdNumberOfCarsStopVisibleSelector);

  return (
    <QuoteJourneyStop
      stopProps={{
        visible: isVisible,
        title: "additionalDriver.householdNumberOfCarsSubQuestion.stop.title",
        description: "quoteJourney.shared.softStop.description"
      }}
      isSubContainer
    />
  );
};

export default HouseholdHasTooManyCarsStop;