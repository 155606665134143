import {TranslationId} from "../TranslationKey";
import React, {FC, ReactNode} from "react";
import type {Props as ReactIntlFormattedMessageProps} from "react-intl/src/components/message";
import {FormattedMessage as IntlFormattedMessage} from "react-intl";

export type FormattedMessageProps = ReactIntlFormattedMessageProps<
  Record<string, ReactNode>
> & {
  id?: TranslationId;
};

const FormattedMessage: FC<FormattedMessageProps> = (props) => (
  <IntlFormattedMessage {...props}/>
);

export default FormattedMessage;