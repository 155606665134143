import React, {FC} from "react";
import SubQuestion from "../../SubQuestion";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {Status} from "shared-components/dist/status-indicator/Status";
import CurrencyInput from "shared-components/dist/form/currency-input/CurrencyInput";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";

interface Props {
  id: string;
  title: TranslationKey;
  value: number | undefined;
  status?: Status;
  errorMessage?: TranslationKey;
  onAnswer: (value: number) => void;
  step?: number;
  placeholder?: TranslationKey;
  tooltip?: TooltipProps
}

const CurrencyInputSubQuestion: FC<Props> = (
  {
    id,
    title,
    value,
    status,
    errorMessage,
    onAnswer,
    step,
    placeholder,
    tooltip
  }
) => (
  <SubQuestion
    id={id}
    title={title}
    status={status}
    tooltip={tooltip}
    errorMessage={errorMessage}
  >
    <CurrencyInput
      id={id}
      value={value}
      onComplete={onAnswer}
      step={step}
      placeholder={placeholder}
    />
  </SubQuestion>
);

export default CurrencyInputSubQuestion;