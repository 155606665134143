import React, {FC} from "react";
import "./StepTitle.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";

interface Props {
  title: TranslationKey;
  titleValue?: string;
}

const StepTitle: FC<Props> = (
  {
    title,
    titleValue,
  }
) =>
  <div className="step-title">
    <h2>{lookupI18nString(title) + (titleValue ? ` ${titleValue}` : "")}</h2>
  </div>;

export default StepTitle;