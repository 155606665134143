import {SimpleQueryResult} from "../../../apollo/SimpleQueryResult";
import {GetJobsResult, useJobsQuery} from "./models/Query";
import {decodeDomEncodedString} from "shared-components/dist/utils/stdlib/StringUtils";
import {CustomisedAbiListItem} from "shared-components/dist/models/CustomisedAbiListItem";

export function useJobs(): SimpleQueryResult<CustomisedAbiListItem[]> {
  const queryResult = useJobsQuery();
  return {
    loading: queryResult.loading,
    error: queryResult.error,
    data: mapResult(queryResult.data)
  };
}

function mapResult(queryResult: GetJobsResult | undefined): CustomisedAbiListItem[] {
  if (!queryResult) return [];

  if (!queryResult.quote || !queryResult.quote.abiCodes || !queryResult.quote.abiCodes.jobs) throw Error("Critical failure - Invalid graphql response");

  return queryResult.quote.abiCodes.jobs.map(abiListItem => ({
    vtCode: abiListItem.vtCode,
    description: decodeDomEncodedString(abiListItem.vtDescription)
  }));
}
