import React, {FC} from "react";
import {driverPositionOptions, DriverPositionOptions} from "../models/DriverPositionOptions";
import {driverPositionRadioOptionFrom} from "../../../../../../../graphql/queries/vehicle-lookup/registration-lookup/model/DriverPosition";
import {driverPositionChanged} from "../../../redux/VehicleDetailsSlice";
import {DriverPositionRadioColumnSubQuestion} from "../../../../../../../structure/questions/sub-question/variants/radio-column-sub-question/variants/DriverPositionRadioColumnSubQuestion";
import AdditionalDetailsLeftHandDriveVehicleStop from "./left-hand-drive-vehicle-stop/AdditionalDetailsLeftHandDriveVehicleStop";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const AdditionalDetailsDriverPosition: FC = () => {
  const dispatch = useAppDispatch();
  const driverPositionField = useAppSelector(state => state.vehicleDetails.additionalVehicleDetails.driverPosition);

  const onDriverPositionChanged = (value: DriverPositionOptions): void => {
    dispatch(driverPositionChanged(value === "Left Hand Drive" ? "Left" : "Right"));
  };

  return (
    <>
      <DriverPositionRadioColumnSubQuestion
        subQuestionProps={{
          title: "vehicleQuote.additionalVehicleDetailsForm.driverPosition",
          id: "driver-position-sub-question",
          errorMessage: driverPositionField.errorMessage,
          status: driverPositionField.status
        }}
        radioColumnProps={{
          options: driverPositionOptions,
          name: "driverPosition",
          selectedOption: driverPositionField.value && driverPositionRadioOptionFrom(driverPositionField.value),
          onSelection: onDriverPositionChanged
        }}
      />
      <AdditionalDetailsLeftHandDriveVehicleStop/>
    </>
  );
};

export default AdditionalDetailsDriverPosition;
