import {VehicleDetails} from "../redux/VehicleDetails";

export function haveYouBoughtYourCarYetQuestionIsValid(vehicleDetails: VehicleDetails): boolean {
  const question = vehicleDetails.isCurrentOwner;
  if (question.status !== "success") return false;

  if (question.value === false) return true;

  const date = vehicleDetails.dateOfPurchase;
  return question.value === true && date.status === "success" && date.value !== undefined;
}