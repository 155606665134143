import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {Policy, PolicyList, PolicyStatus, RenewalInviteStatus} from "shared/dist/generated/graphql/resolvers-types";
import {formatTimeWithoutSecondsStringFromIsoString, toGregorianFormat} from "shared/dist/stdlib/DateFormat";
import {differenceInDays, isAfter, isBefore, startOfDay} from "date-fns";
import {now} from "shared/dist/stdlib/Dates";

const daysInCalendarYear = 365;

export type PortalPolicyStatus = Exclude<keyof PolicyList, "__typename">;

export function policyCanBeRenewedOrRebroked(policy: Policy | undefined): boolean {
  if (!policy || !policy.metadata.renewalInviteStatus) return false;

  return [
    RenewalInviteStatus.RenewalOffered,
    RenewalInviteStatus.RenewalOfferedWithException,
    RenewalInviteStatus.RebrokeOnly
  ].includes(policy.metadata.renewalInviteStatus);
}

export function getCardTitleFor(policyStatus: PortalPolicyStatus): string {
  switch (policyStatus) {
    case "active":
      return lookupI18nString("portal.policyCard.activePolicy.title");
    case "upcoming":
      return lookupI18nString("portal.policyCard.upcomingPolicy.title");
    case "cancelled":
      return lookupI18nString("portal.policyCard.cancelledPolicy.title");
    case "expired":
      return lookupI18nString("portal.policyCard.expiredPolicy.title");
  }
}

export function getProgressBarTextFor(policyStatus: PortalPolicyStatus, daysUntilCoverChange: number, policy: Policy): string {
  switch (policyStatus) {
    case "active":
      return getDaysLeftDescription(daysUntilCoverChange, policy);
    case "upcoming":
      return getDaysUntilActiveDescription(daysUntilCoverChange, policy);
    case "cancelled":
      return lookupI18nString("dashboard.policyCard.cancelledPolicy.progressBarDescription");
    case "expired":
      return lookupI18nString("dashboard.policyCard.expiredPolicy.progressBarDescription");
  }
}

function getDaysLeftDescription(daysUntilCoverChange: number, policy: Policy): string {
  return daysUntilCoverChange === 0
    ? lookupI18nString({
      id: "dashboard.policyCard.activePolicy.progressBarDescription.dueTime",
      templateVariables: {
        time: formatTimeWithoutSecondsStringFromIsoString(policy.renewalNotice?.expiryTimestamp ?? policy.coverDetails.endDate)
      }
    })
    : lookupI18nString({
      id: "dashboard.policyCard.activePolicy.progressBarDescription.daysLeft",
      templateVariables: {days: daysUntilCoverChange}
    });
}

function getDaysUntilActiveDescription(daysUntilCoverChange: number, policy: Policy): string {
  return daysUntilCoverChange === 0
    ? lookupI18nString({
      id: "dashboard.policyCard.upcomingPolicy.progressBarDescription.activeTime",
      templateVariables: {
        time: formatTimeWithoutSecondsStringFromIsoString(policy.coverDetails.startDate)
      }
    })
    : lookupI18nString({
      id: "dashboard.policyCard.upcomingPolicy.progressBarDescription.activeInDays",
      templateVariables: {days: daysUntilCoverChange}
    });
}

export function getPolicyStatusFor(policy: Policy): PortalPolicyStatus {
  if (policy.policyStatus === PolicyStatus.PolicyCancelled) return "cancelled";
  if (isBefore(new Date(policy.coverDetails.endDate), now())) return "expired";
  if (isAfter(new Date(policy.coverDetails.startDate), now())) return "upcoming";

  return "active";
}

export function calculateProgressBarValue(policyStatus: PortalPolicyStatus, daysUntilCoverChange: number): number {
  if (policyStatus === "active" || policyStatus === "upcoming") return daysInCalendarYear - daysUntilCoverChange;

  return daysInCalendarYear;
}

export function getDaysUntilCoverChange(policyStatus: PortalPolicyStatus, policy: Policy): number {
  switch (policyStatus) {
    case "active":
      return differenceInDays(policy.renewalNotice?.expiryTimestamp ? startOfDay(new Date(policy.renewalNotice?.expiryTimestamp)) : startOfDay(new Date(policy.coverDetails.endDate)), startOfDay(now()));
    case "upcoming":
      return differenceInDays(startOfDay(new Date(policy.coverDetails.startDate)), startOfDay(now()));
    default:
      return 0;
  }
}

export function getRenewalDateTitleFor(policyStatus: PortalPolicyStatus): string {
  switch (policyStatus) {
    case "active":
      return lookupI18nString("policies.header.subHeader.policySummaryCardRow.renewalDue");
    case "upcoming":
      return lookupI18nString("policies.header.subHeader.policySummaryCardRow.activeOn");
    case "cancelled":
      return lookupI18nString("policies.header.subHeader.policySummaryCardRow.startedOn");
    case "expired":
      return lookupI18nString("policies.header.subHeader.policySummaryCardRow.expiredOn");
  }
}

export function getRenewalDateValueFor(policyStatus: PortalPolicyStatus, policy: Policy): string {
  switch (policyStatus) {
    case "active":
    case "expired":
      return toGregorianFormat(new Date(policy.coverDetails.endDate));
    case "upcoming":
    case "cancelled":
      return toGregorianFormat(new Date(policy.coverDetails.startDate));
  }
}