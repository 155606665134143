import {AppThunk} from "../../../../../redux/Store";
import {policyHolderLoaded, policyListLoaded, updatePortalLoadingStatus} from "../PortalSlice";
import {logger} from "../../../../../utils/logging/Logger";
import {PortalLoadingStatus} from "../PortalDetails";
import {loadPortalDashboardDetails} from "../../../../../graphql/queries/portal/LoadPortalDashboardDetails";

export const loadPortalDashboard = (): AppThunk => async (dispatch) => {
  dispatch(updatePortalLoadingStatus(PortalLoadingStatus.LOADING));

  try {
    const portalDetails = await loadPortalDashboardDetails();
    if (portalDetails) {
      dispatch(policyHolderLoaded(portalDetails.policyHolder));
      dispatch(policyListLoaded(portalDetails.listPolicies));
      dispatch(updatePortalLoadingStatus(PortalLoadingStatus.SUCCESS));
    } else {
      logger.error("Portal details are empty");
      dispatch(updatePortalLoadingStatus(PortalLoadingStatus.FAILED));
    }
  } catch (error) {
    logger.error("Error occurred loading portal details", error);
    dispatch(updatePortalLoadingStatus(PortalLoadingStatus.FAILED));
  }
};
