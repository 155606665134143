import React, {FC} from "react";
import {CustomisedAbiListItem} from "shared-components/dist/models/CustomisedAbiListItem";
import {StringTypeaheadSubQuestion} from "../../../../../../../structure/questions/sub-question/variants/typeahead-sub-question/variants/StringTypeaheadSubQuestion";
import {toStringOptionFromCustomisedAbiListItem} from "../../../../../../../structure/form/typeahead/variants/StringTypeahead";
import {ConditionalFormField} from "shared-components/dist/models/form-field/ConditionalFormField";
import BooleanSubQuestion
  from "../../../../../../../structure/questions/sub-question/variants/boolean-sub-question/BooleanSubQuestion";
import {ConditionalBooleanFormField} from "shared-components/dist/models/form-field/variants/BooleanFormField";
import {useJobsProps} from "../hooks/UseJobsProps";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";

export interface UnemployedQuestionsDisplayProps {
  workedInPastYearQuestionTitle: TranslationKey;
  previousJobQuestionTitle: TranslationKey;
  previousJobQuestionTooltip: TooltipProps;
}

interface OwnProps {
  idPrefix: string;
  job: ConditionalFormField<CustomisedAbiListItem | undefined>;
  workedInPastYear: ConditionalBooleanFormField;
  jobChanged: (value: CustomisedAbiListItem) => void;
  workedInPastYearChanged: (value: boolean) => void;
}

type Props = UnemployedQuestionsDisplayProps & OwnProps;

const UnemployedSubQuestions: FC<Props> = (
  {
    idPrefix,
    workedInPastYearQuestionTitle,
    previousJobQuestionTitle,
    previousJobQuestionTooltip,
    job,
    workedInPastYear,
    jobChanged,
    workedInPastYearChanged,
  }
) => {
  const {status: jobsLookupStatus, onJobChanged, jobs} = useJobsProps(job, jobChanged);

  return (
    <>
      <BooleanSubQuestion
        id={`${idPrefix}-unemployed-worked-past-year-sub-question`}
        title={workedInPastYearQuestionTitle}
        value={workedInPastYear.value}
        status={workedInPastYear.status}
        errorMessage="quote.errors.questionIncomplete"
        onAnswer={workedInPastYearChanged}
      />
      {workedInPastYear.value === true && (
        <StringTypeaheadSubQuestion
          id={`${idPrefix}-unemployed-job-sub-question`}
          title={previousJobQuestionTitle}
          errorMessage="quote.errors.questionIncomplete"
          status={jobsLookupStatus}
          tooltip={previousJobQuestionTooltip}
          typeaheadProps={{
            options: jobs,
            selectedOption: toStringOptionFromCustomisedAbiListItem(job),
            onSelection: onJobChanged,
            name: `${idPrefix}-unemployed-job-typeahead`,
            loading: jobsLookupStatus === "loading",
            placeholder: "personalDetails.employmentQuestion.unemployed.previousJob.placeholder"
          }}
        />
      )}
    </>
  );
};

export default UnemployedSubQuestions;