import {SessionState} from "../../../../user/authentication/session/SessionState";
import {AccountActivationResult} from "../../../../user/authentication/models/account-activation/AccountActivationResult";
import {ResetPasswordResult} from "../../../../user/authentication/models/password-management/ResetPasswordResult";
import {LoginResult} from "../../../../user/authentication/models/login/LoginResponse";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  mapAccountActivationResultToCurrentSessionState,
  mapLoginResultToCurrentSessionState,
  mapResetPasswordResultToCurrentSessionState
} from "../../../../user/authentication/session/SessionStateMappers";
import {ChangePasswordResult} from "../../../../user/authentication/models/password-management/ChangePasswordResult";
import {userAttemptedToLogout} from "../../../../redux/Actions";
import {UserAuthenticationDetails} from "./UserAuthenticationDetails";

const initialState: UserAuthenticationDetails = {
  currentSessionState: "Unknown",
  loginResult: undefined,
  accountActivationResult: undefined,
  resetPasswordResult: undefined,
  changePasswordResult: undefined,
  userSessionHasExpired: undefined
};

const userAuthenticationSlice = createSlice({
  name: "userAuthentication",
  initialState,
  reducers: {
    userAttemptedToLogin(state, action: PayloadAction<LoginResult>): void {
      state.currentSessionState = mapLoginResultToCurrentSessionState(action.payload);
      state.loginResult = action.payload;
    },
    refreshCurrentSessionState(state, action: PayloadAction<SessionState>): void {
      state.currentSessionState = action.payload;
    },
    userAttemptedToActivateAccount(state, action: PayloadAction<AccountActivationResult>): void {
      state.currentSessionState = mapAccountActivationResultToCurrentSessionState(action.payload);
      state.accountActivationResult = action.payload;
    },
    userAttemptedToResetPassword(state, action: PayloadAction<ResetPasswordResult>): void {
      state.currentSessionState = mapResetPasswordResultToCurrentSessionState(action.payload);
      state.resetPasswordResult = action.payload;
    },
    userAttemptedToChangePassword(state, action: PayloadAction<ChangePasswordResult>): void {
      state.changePasswordResult = action.payload;
    },
    userSessionHasExpired(state): void {
      state.userSessionHasExpired = true;
    },
    resetUserSessionHasExpired(state): void {
      state.userSessionHasExpired = undefined;
    },
  },
  extraReducers: {
    [userAttemptedToLogout.type]: (state) => {
      state.currentSessionState = "Invalid";
      state.loginResult = undefined;
    }
  }
});

export const {
  userAttemptedToLogin,
  refreshCurrentSessionState,
  userAttemptedToActivateAccount,
  userAttemptedToResetPassword,
  userAttemptedToChangePassword,
  userSessionHasExpired,
  resetUserSessionHasExpired
} = userAuthenticationSlice.actions;

export const userAuthenticationReducer = userAuthenticationSlice.reducer;
