import {AgentWorkflowStage, Maybe} from "shared/dist/generated/graphql/resolvers-types";
import {differenceInDays} from "date-fns";
import {now} from "shared/dist/stdlib/Dates";
import type {ShowReminderCallback} from "../thunk/UpdateVisibleReminderTypes";

export const showPolicyVerifiedReminder: ShowReminderCallback = async (policy) => {
  if (!policy) return false;
  const agentWorkflowStage = policy.metadata.agentWorkflowStage;
  const agentWorkflowStageUpdatedOn = policy.metadata.agentWorkflowStageUpdatedOn;

  return agentWorkflowStage === AgentWorkflowStage.PolicyVerified && wasUpdatedInTheLast5Days(agentWorkflowStageUpdatedOn);
};

function wasUpdatedInTheLast5Days(agentWorkflowStageUpdatedOn: Maybe<string> | undefined): boolean {
  if (!agentWorkflowStageUpdatedOn) return true;

  return differenceInDays(now(), new Date(agentWorkflowStageUpdatedOn)) <= 5;
}

