import {FC, useEffect, useState} from "react";
import InsurerQuoteCard from "../../../../../../structure/cards/insurer-quote-card/InsurerQuoteCard";
import {isProposerUnderageForFinanceSelector} from "../../redux/selectors/QuoteDetailsSelectors";
import {quoteSteps} from "../../../shared/quote-step/QuoteSteps";
import {logYourCoverGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {useNavigate} from "react-router-dom";
import ContentWithSidebarLayout
  from "../../../../../../structure/layouts/content-with-sidebar-layout/ContentWithSidebarLayout";
import QuoteHeader from "../../../shared/quote-step/header/QuoteHeader";
import MainContent from "../../../../../../structure/layouts/main-content/MainContent";
import {
  paymentFrequencyChanged,
  selectedQuoteChanged,
  userHasSelectedQuoteUsingCompareYourQuotes
} from "../../redux/QuoteDetailsSlice";
import "./CompareYourQuotes.css";
import TabGroup, {TabGroupOption} from "../../../../../../structure/navigation/tab-group/TabGroup";
import {useFeatureFlags} from "shared-components/dist/feature-flags/hooks/UseFeatureFlags";
import Carousel from "shared-components/dist/carousel/Carousel";
import QuoteLoading from "../../../shared/quote-loading/QuoteLoading";
import {useNavigateOnInvalidQuote} from "../../hooks/NavigateOnInvalidQuote";
import {sortBy} from "lodash";
import ChangedQuotesNotificationBanner from "./components/changed-quotes-notification-banner/ChangedQuotesNotificationBanner";
import UnderageFinanceStop from "./components/underage-finance-stop/UnderageFinanceStop";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";
import {useHandleSingleQuoteAvailable} from "./hooks/use-handle-single-quote-available/UseHandleSingleQuoteAvailable";
import {useLoadQuotesOnFirstRender} from "./hooks/use-load-quotes-on-first-render/UseLoadQuotesOnFirstRender";
import {InsurerQuote} from "../../models/InsurerQuote";
import {LoadingStatus} from "../../models/LoadingStatus";
import {useQuotePriceProps} from "../../../shared/quote-summary/components/quote-price-card/hooks/UseQuotePricesProps";

const CompareYourQuotes: FC = () => {
  const dispatch = useAppDispatch();
  const {selectedLoan, totalExtrasAmount} = useQuotePriceProps();
  const isProposerUnderageForFinance = useAppSelector(isProposerUnderageForFinanceSelector);
  const insurerQuotes = useAppSelector(state => state.quoteDetails.insurerQuotes);
  const depositPercentage = useAppSelector(state => state.quoteDetails.depositPercentage);
  const closeBrothersLoanStatus = useAppSelector(state => state.quoteDetails.closeBrothersLoanStatus);
  const paymentFrequency = useAppSelector(state => state.quoteDetails.paymentFrequency);
  const isPreviousPageValid = useAppSelector(quoteSteps.yourQuote.previousRoute.validator);
  const [financeStopVisible, setFinanceStopVisible] = useState(false);
  const {monthlyPaymentsFlag} = useFeatureFlags();
  const navigate = useNavigate();
  useNavigateOnInvalidQuote();
  useLoadQuotesOnFirstRender();
  useHandleSingleQuoteAvailable();

  useEffect(() => {
    if (!isPreviousPageValid) {
      navigate(quoteSteps.yourCover.routes.new);
    }
  }, [navigate, isPreviousPageValid]);

  const isLoading = !insurerQuotes || !insurerQuotes.length || closeBrothersLoanStatus === LoadingStatus.LOADING;

  if (isLoading) return <QuoteLoading/>;

  let displayQuotes = insurerQuotes.map(quote => quote);

  if (displayQuotes.length === 3) {
    displayQuotes = setCheapestQuoteToCentre(displayQuotes);
  }
  const monthlyPaymentAvailable = displayQuotes.some(quote => quote.loansWithPrice !== undefined);

  const tabGroupOptions: TabGroupOption[] = [
    {
      title: "quoteDetails.tabGroup.annualCost",
      onClick: () => dispatch(paymentFrequencyChanged("Annual"))
    },
    {
      title: "quoteDetails.tabGroup.monthlyCost",
      onClick: () => {
        if (isProposerUnderageForFinance) return setFinanceStopVisible(true);
        dispatch(paymentFrequencyChanged("Monthly"));
      }
    }
  ];

  const onChooseQuoteClick = (selectedQuote: InsurerQuote): void => {
    navigate(quoteSteps.yourQuote.routes.new);

    logYourCoverGoogleAnalyticsEvent({
      categorySuffix: "go_to_next_page",
      action: "Success",
    });
    dispatch(selectedQuoteChanged(selectedQuote));
    dispatch(userHasSelectedQuoteUsingCompareYourQuotes());
  };

  return (
    <ContentWithSidebarLayout
      key={"unique"}
      className="compare-quotes"
      header={<>
        <QuoteHeader/>
        <ChangedQuotesNotificationBanner/>
      </>}
      mainContent={
        <MainContent title={"compareYourQuote.title"} description={"compareYourQuote.description"}>
          {monthlyPaymentAvailable && monthlyPaymentsFlag &&
            <TabGroup
              option={paymentFrequency === "Annual" ? tabGroupOptions[0].title : tabGroupOptions[1].title}
              options={tabGroupOptions}
            />
          }
          <Carousel
            initialSlideMobile={displayQuotes.length === 3 ? 1 : 0}
            items={displayQuotes.map((quote, index) =>
              <InsurerQuoteCard
                key={index}
                index={index}
                isEmphasised={displayQuotes.length === 3 && index === 1}
                totalCost={quote.totalUnfinancedAmountPayable}
                depositPercentage={depositPercentage}
                closeBrothersLoanStatus={closeBrothersLoanStatus}
                onButtonClick={onChooseQuoteClick}
                isMonthly={paymentFrequency === "Monthly" && quote.loansWithPrice !== undefined}
                quote={quote}
                extrasTotal={totalExtrasAmount}
                numberOfInstalments={selectedLoan.numberOfInstalments ?? 10}
                adminFee={quote.premiumPriceBreakdownDetails?.adminFee}
              />
            )}>
          </Carousel>

          <UnderageFinanceStop visible={financeStopVisible} dismiss={() => setFinanceStopVisible(false)}/>
        </MainContent>}
    />
  );
};

function setCheapestQuoteToCentre(quotes: InsurerQuote[]): InsurerQuote[] {
  const sortedQuotes = sortBy(quotes, "premium");
  const cheapest = sortedQuotes.splice(0, 1)[0];
  sortedQuotes.splice(1, 0, cheapest);
  return sortedQuotes;
}

export default CompareYourQuotes;
