import {AppThunkDispatch, RootState} from "../../../../../redux/Store";
import {shouldUseMainTrackerQuestion} from "../redux/selectors/ValidatedAdditionalVehicleDetailsFromFormInput";
import {hasValidAssumptionsCorrectQuestion} from "./AssumptionsCorrectValidation";
import {assumptionsCorrectInvalid, hasTrackerInvalid} from "../redux/VehicleDetailsSlice";
import {flagUnansweredOwnerRegisteredKeeperQuestions} from "./FlagUnansweredOwnerRegisteredKeeperQuestions";
import {isFieldAnswered} from "shared-components/dist/models/form-field/FormField";
import {flagUnansweredModificationsQuestions} from "./FlagUnansweredModificationsQuestions";
import {flagUnansweredCarPurchaseQuestions} from "./FlagUnansweredCarPurchaseQuestions";
import {VehicleDetails} from "../redux/VehicleDetails";

type CheckFunction = (vehicleDetails: VehicleDetails, dispatch: AppThunkDispatch) => void;

export function flagUnansweredMainVehicleFormQuestions(rootState: RootState, dispatch: AppThunkDispatch): void {
  const vehicleDetails = rootState.vehicleDetails;

  checkAssumptionsQuestion(rootState, dispatch);
  if (shouldUseMainTrackerQuestion(vehicleDetails.vehicleFromRegLookup)) {
    checkMainHasTrackerQuestion(vehicleDetails, dispatch);
  }

  [
    flagUnansweredModificationsQuestions,
    flagUnansweredCarPurchaseQuestions,
    flagUnansweredOwnerRegisteredKeeperQuestions,
  ].forEach((checkFunction: CheckFunction) => checkFunction(vehicleDetails, dispatch));
}

function checkAssumptionsQuestion(rootState: RootState, dispatch: AppThunkDispatch): void {
  if (!hasValidAssumptionsCorrectQuestion(rootState)) {
    dispatch(assumptionsCorrectInvalid("quote.errors.questionIncomplete"));
  }
}

function checkMainHasTrackerQuestion({hasTracker}: VehicleDetails, dispatch: AppThunkDispatch): void {
  if (!isFieldAnswered(hasTracker)) {
    dispatch(hasTrackerInvalid("quote.errors.questionIncomplete"));
  }
}
