import {RelationshipToProposerOption} from "../../relationship-to-proposer/model/RelationshipToProposerOption";
import {EmploymentStatusOption} from "../../../../../shared/questions/employment/models/EmploymentStatusOption";
import {
  validationFailure,
  ValidationResult,
  validationSuccess,
  Validator
} from "shared-components/dist/utils/validation/Validator";
import {ClassOfUseOption} from "../../../../../shared/questions/class-of-use/ClassOfUseOption";
import {EmploymentStatus, ProposedClassOfUse} from "shared/dist/generated/graphql/resolvers-types";
import {
  isSpousalRelationshipToProposer,
  isWorkingRelationshipToProposer
} from "shared/dist/class-of-use/RelationshipToProposerHelpers";
import {
  isEmploymentEntitledToBusinessUse,
  isEmploymentEntitledToCommutingUse
} from "shared/dist/class-of-use/EmploymentStatusHelpers";

interface HasValidClassOfUseProps {
  relationshipToProposer: RelationshipToProposerOption | undefined;
  primaryEmploymentStatus: EmploymentStatusOption | undefined;
  secondaryEmploymentStatus: EmploymentStatusOption | undefined;
}

export function hasValidClassOfUse(
  {relationshipToProposer, primaryEmploymentStatus, secondaryEmploymentStatus}: HasValidClassOfUseProps
): Validator<ClassOfUseOption> {
  return (proposedClassOfUse: ClassOfUseOption): ValidationResult => {
    if (!relationshipToProposer || !primaryEmploymentStatus) {
      return validationSuccess;
    }

    switch (proposedClassOfUse.id) {
      case ProposedClassOfUse.SocialCommutingAndBusiness:
        return validateBusinessUse(relationshipToProposer, primaryEmploymentStatus, secondaryEmploymentStatus);
      case ProposedClassOfUse.SocialAndCommuting:
        return validateCommutingUse(relationshipToProposer, primaryEmploymentStatus, secondaryEmploymentStatus);
      case ProposedClassOfUse.Social:
        return validationSuccess;
    }
  };
}

function validateBusinessUse(
  relationshipToProposer: RelationshipToProposerOption,
  primaryEmploymentStatus: EmploymentStatusOption,
  secondaryEmploymentStatus: EmploymentStatusOption | undefined
): ValidationResult {
  if (isSpousalRelationshipToProposer(relationshipToProposer.id)) {
    return validateSpousalBusinessUse(primaryEmploymentStatus.vtCode, secondaryEmploymentStatus?.vtCode);
  }

  if (isWorkingRelationshipToProposer(relationshipToProposer.id)) {
    return validateWorkingRelationshipBusinessUse(primaryEmploymentStatus.vtCode, secondaryEmploymentStatus?.vtCode);
  }

  return validationFailure("additionalDriver.classOfUse.softStop.businessOrCommutingUse.namedDriver");
}

function validateSpousalBusinessUse(
  primaryEmploymentStatus: EmploymentStatus,
  secondaryEmploymentStatus: EmploymentStatus | undefined
): ValidationResult {
  if (isStudent(primaryEmploymentStatus, secondaryEmploymentStatus)) {
    return validationFailure("additionalDriver.classOfUse.softStop.businessUse.spousalRelationship.student");
  }

  if (!isEmploymentEntitledToBusinessUse(primaryEmploymentStatus, secondaryEmploymentStatus)) {
    return validationFailure("additionalDriver.classOfUse.softStop.businessOrCommutingUse.spousalRelationship.unemployed");
  }

  return validationSuccess;
}

const isStudent = (
  primaryEmploymentStatus: EmploymentStatus,
  secondaryEmploymentStatus: EmploymentStatus | undefined
): boolean => [primaryEmploymentStatus, secondaryEmploymentStatus].some(status => status === EmploymentStatus.FullTimeStudent);

function validateWorkingRelationshipBusinessUse(
  primaryEmploymentStatus: EmploymentStatus,
  secondaryEmploymentStatus: EmploymentStatus | undefined
): ValidationResult {
  return isEmploymentEntitledToBusinessUse(primaryEmploymentStatus, secondaryEmploymentStatus)
    ? validationSuccess
    : validationFailure("additionalDriver.classOfUse.softStop.businessOrCommutingUse.workingRelationship.unemployed");
}

function validateCommutingUse(
  relationshipToProposer: RelationshipToProposerOption,
  primaryEmploymentStatus: EmploymentStatusOption,
  secondaryEmploymentStatus: EmploymentStatusOption | undefined
): ValidationResult {
  if (isSpousalRelationshipToProposer(relationshipToProposer.id)) {
    return validateSpousalCommutingUse(primaryEmploymentStatus.vtCode, secondaryEmploymentStatus?.vtCode);
  }

  if (isWorkingRelationshipToProposer(relationshipToProposer.id)) {
    return validateWorkingRelationshipCommutingUse(primaryEmploymentStatus.vtCode, secondaryEmploymentStatus?.vtCode);
  }

  return validationFailure("additionalDriver.classOfUse.softStop.businessOrCommutingUse.namedDriver");
}

function validateSpousalCommutingUse(
  primaryEmploymentStatus: EmploymentStatus,
  secondaryEmploymentStatus: EmploymentStatus | undefined
): ValidationResult {
  return isEmploymentEntitledToCommutingUse(primaryEmploymentStatus, secondaryEmploymentStatus)
    ? validationSuccess
    : validationFailure("additionalDriver.classOfUse.softStop.businessOrCommutingUse.spousalRelationship.unemployed");
}

function validateWorkingRelationshipCommutingUse(
  primaryEmploymentStatus: EmploymentStatus,
  secondaryEmploymentStatus: EmploymentStatus | undefined
): ValidationResult {
  return isEmploymentEntitledToCommutingUse(primaryEmploymentStatus, secondaryEmploymentStatus)
    ? validationFailure("additionalDriver.classOfUse.softStop.commutingUse.workingRelationship.employed")
    : validationFailure("additionalDriver.classOfUse.softStop.businessOrCommutingUse.workingRelationship.unemployed");
}