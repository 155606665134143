import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../../../../redux/Store";
import {
  mapRenewalPolicyExtrasToQuoteExtras,
  RenewalPolicyExtras
} from "../../../portal/renewals/models/RenewalPolicyExtras";
import {Price, ProposedRenewalRequest, ProspectiveLoanWithPrice} from "shared/dist/generated/graphql/resolvers-types";
import {MonthlyPayment} from "../../monthly/direct-debit-setup/redux/MonthlyPayment";
import {ProposedFinancedRenewalRequest} from "../models/ProposedFinancedRenewalRequest";
import {
  selectActiveProspectiveLoan,
  selectActiveRenewalNotice,
  selectExistingPolicyForRenewal,
  selectExistingPolicyHolderForRenewal,
  selectPolicyExtras,
  selectRebrokedQuote,
  selectRenewalDepositPercentage,
  selectSelectedQuoteType,
  selectTotalUnfinancedRenewalAmount
} from "../../../portal/renewals/redux/RenewalSelectors";
import {ProposedFinancedPolicySetupRequest} from "../models/ProposedFinancedPolicySetupRequest";
import {CLOSE_BROTHERS_INTEREST_RATE} from "shared/dist/constants/CloseBrothersInterestRate";
import {PolicySetupQuoteValues} from "shared/dist/event-models/policy-setup/PolicySetupEvent";
import {RenewalQuoteType} from "../../../portal/renewals/models/RenewalQuoteType";

const monthlyPaymentSelector = (state: RootState): MonthlyPayment => state.monthlyPayment;
const policyReferenceSelector = (state: RootState): string | undefined => state.renewal.policy?.policyReference;

type Selector<S> = (state: RootState) => S;

export const financedRenewalRequestSelector = (policyId: string): Selector<ProposedFinancedRenewalRequest | undefined> => createSelector(
  selectPolicyExtras,
  monthlyPaymentSelector,
  policyReferenceSelector,
  selectActiveProspectiveLoan,
  selectRenewalDepositPercentage,
  selectSelectedQuoteType,
  (
    policyExtras: RenewalPolicyExtras,
    monthlyPayment: MonthlyPayment,
    policyReference: string | undefined,
    loan: ProspectiveLoanWithPrice | undefined,
    depositPercentage: number,
    selectedQuoteType: RenewalQuoteType | undefined
  ): ProposedFinancedRenewalRequest | undefined => {
    if (!selectedQuoteType || selectedQuoteType === "REBROKE") return undefined;
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    return {
      loanRequest: {
        rateReference: loan!.rateReference,
        depositPercentage: depositPercentage,
        brokerLoanReference: policyReference ? policyReference.substring(0, 20) : "", // Close Brothers can't handle this being longer than 20 characters - which is not a problem in a real scenario only in tests
        bankSortCode: monthlyPayment.sortCode.value!,
        bankAccountNumber: monthlyPayment.accountNumber.value!
      },
      proposedRenewalRequest: {
        policyExtras: mapRenewalPolicyExtrasToQuoteExtras(policyExtras),
        policyId
      }
    };
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }
);

export const renewalRequestSelector = (policyId: string): Selector<ProposedRenewalRequest> => createSelector(
  selectPolicyExtras,
  (policyExtras: RenewalPolicyExtras): ProposedRenewalRequest => {
    return {
      policyExtras: mapRenewalPolicyExtrasToQuoteExtras(policyExtras),
      policyId
    };
  }
);

export const rebrokeQuoteValuesSelector = createSelector(
  selectRebrokedQuote,
  selectExistingPolicyForRenewal,
  selectExistingPolicyHolderForRenewal,
  (rebrokedQuote, existingPolicy, existingPolicyHolder): PolicySetupQuoteValues | undefined => {
    if (!rebrokedQuote) return undefined;
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    return {
      proposerEmail: existingPolicyHolder!.metadata.email,
      proposerPhoneNumber: existingPolicyHolder!.metadata.phoneNumber,
      quoteReference: rebrokedQuote!.reference!,
      quoteSequenceNumber: rebrokedQuote!.sequenceNumber!,
    };
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }
);

export const rebrokeFinancedPolicyRequestSelector = createSelector(
  monthlyPaymentSelector,
  selectActiveProspectiveLoan,
  selectRenewalDepositPercentage,
  rebrokeQuoteValuesSelector,
  selectRebrokedQuote,
  selectActiveRenewalNotice,
  selectSelectedQuoteType,
  (monthlyPayment,
   loan,
   depositPercentage,
   quoteValues,
   rebrokedQuote,
   renewalNotice,
   selectedQuoteType
  ): ProposedFinancedPolicySetupRequest | undefined => {
    if (!selectedQuoteType || selectedQuoteType === "RENEWAL" || !quoteValues) return undefined;

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    return {
      depositPercentage: depositPercentage,
      installments: loan!.numberOfInstalments,
      installmentAmount: parseFloat(loan!.subsequentInstalmentAmounts.amount),
      loanSetUp: {
        rateReference: loan!.rateReference,
        brokerLoanReference: `${quoteValues.quoteReference}-${quoteValues.quoteSequenceNumber}`,
        bankSortCode: monthlyPayment.sortCode.value!,
        bankAccountNumber: monthlyPayment.accountNumber.value!
      },
      quoteValues,
      financeInformation:
        {
          pricePerMonth: loan!.subsequentInstalmentAmounts.amount,
          premium: rebrokedQuote!.premium!.amount,
          adminFee: renewalNotice!.adminFee.amount,
          financeFee: loan!.financeFee.amount,
          deposit: loan!.firstInstalmentAmount.amount,
          totalFinance: loan!.totalFinance.amount,
          interestRate: `${CLOSE_BROTHERS_INTEREST_RATE}`,
          annualPercentageRate: `${loan!.apr}`,
          numberOfInstalments: loan!.numberOfInstalments
        }
    };
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }
);

export const totalPolicyCostSelector = createSelector(
  selectTotalUnfinancedRenewalAmount,
  (totalUnfinancedAmountPayable: Price | undefined): Price => {
    if (!totalUnfinancedAmountPayable) return {amount: "0.00", currency: "GBP"};
    return totalUnfinancedAmountPayable;
  }
);