import {SimpleQueryResult} from "../../../apollo/SimpleQueryResult";
import {GetConvictionOffencesResult, useConvictionOffencesQuery} from "./models/Query";
import {ConvictionOffenceItem} from "shared/dist/generated/graphql/resolvers-types";

export function useConvictionOffences(typeId: string): SimpleQueryResult<ConvictionOffenceItem[]> {
  const queryResult = useConvictionOffencesQuery(typeId);
  return {
    loading: queryResult.loading,
    error: queryResult.error,
    data: mapResult(queryResult.data)
  };
}

function mapResult(queryResult: GetConvictionOffencesResult | undefined): ConvictionOffenceItem[] {
  if (!queryResult) return [];

  if (!queryResult.convictions || !queryResult.convictions.offences) throw Error("Critical failure - Invalid conviction types response from GraphQL");

  if (!queryResult.convictions.offences.find(o => o.description === "Other")) {
    queryResult.convictions.offences.push({id: "NE", oldReference: "", description: "Other"});
  }
  return queryResult.convictions.offences;
}
