import {AggregatorQuoteValidationFailureItemProps} from "../AggregatorQuoteValidationFailureTableItem";
import {useAppSelector} from "../../../../../../redux/Hooks";
import quoteValidationErrorSelectors
  from "../../../../vehicle/your-quote/redux/quote-validation-errors/QuoteValidationErrorSelectors";
import {additionalDriversSelector} from "../../../../vehicle/your-details/redux/selectors/PersonalDetailsSelectors";
import {groupBy} from "lodash";
import {useInitiateResolveErrorFlowForSection} from "../../../redux/InitiateResolveErrorFlowForSection";
import {quoteSteps} from "../../../../vehicle/shared/quote-step/QuoteSteps";

export const useAdditionalDriverQuoteValidationFailureTableItems = (): AggregatorQuoteValidationFailureItemProps[] => {
  const additionalDriversIssues = useAppSelector(state => quoteValidationErrorSelectors.selectQuoteValidationErrorsForAdditionalDrivers(state));
  const additionalDrivers = useAppSelector(additionalDriversSelector);
  const {initiateResolveErrorFlow} = useInitiateResolveErrorFlowForSection({
    section: "ADDITIONAL_DRIVER"
  });

  const uniqueDrivers = groupBy(additionalDriversIssues, "driverId");

  const items: (AggregatorQuoteValidationFailureItemProps | undefined)[] = Object.entries(uniqueDrivers).map(([driver, errors]) => {
    const additionalDriver = additionalDrivers.find(additionalDriver => additionalDriver.id === driver);

    if (!additionalDriver) return undefined;

    const routeForDriver = (quoteSteps.editAdditionalDriver.routes.new).replace(":driverId", additionalDriver.id);

    return {
      heading: {id: "aggregatorQuote.validationIssueTable.additionalDrivers.tableItem.heading"},
      subHeading: {
        id: "aggregatorQuote.validationIssueTable.additionalDrivers.tableItem.subHeading",
        values: {
          firstName: additionalDriver.firstName,
          lastName: additionalDriver.surname,
          relationshipToProposer: additionalDriver.relationshipToProposer.description
        }
      },
      errorMessage: {
        id: "aggregatorQuote.validationIssueTable.yourCar.tableItem.errorMessage",
        values: {numberOfIssues: errors.length}
      },
      onResolveClicked: () => initiateResolveErrorFlow(routeForDriver)
    };
  });

  return items.filter((item): item is AggregatorQuoteValidationFailureItemProps => item !== undefined);
};