import {buildCrossFieldValidator, ReduxQuestion} from "../../Questions";
import {DateOption, SerializableDateOption} from "shared/dist/models/date-option/DateOption";
import {FormField} from "shared-components/dist/models/form-field/FormField";
import {
  coverStartDateChanged,
  coverStartDateInvalid,
  ValueWithNamedDriverEligibility
} from "../../../../pages/quote/vehicle/your-cover/redux/CoverDetailsSlice";
import {
  coverStartDateAsDateOptionSelector,
  existingPolicyExpiryDateAsDateSelector
} from "../../../../pages/quote/vehicle/your-cover/redux/selectors/CoverDetailsSelectors";
import {
  dateOfBirthAsDateSelector
} from "../../../../pages/quote/vehicle/your-details/redux/selectors/PersonalDetailsSelectors";
import {
  isAboveMinimumAgeOnCoverStartDate,
  isBelowMaximumAgeOnCoverStartDate
} from "../../../../pages/quote/vehicle/your-cover/components/cover-start-date/validators/CoverStartDateValidators";
import {
  vehiclePurchaseDateAsDateSelector
} from "../../../../pages/quote/vehicle/your-vehicle/redux/selectors/VehicleDateTypeSelectors";
import {
  isCarOwnerOnCoverStartDate,
  isDateAfterCoverStartDate
} from "../../../../pages/quote/vehicle/shared/quote-summary/components/edit-cover-summary/validators/CoverStartDateValidators";

export const coverStartDate: ReduxQuestion<
  DateOption,
  FormField<DateOption>,
  ValueWithNamedDriverEligibility<SerializableDateOption>
> = {
  selector: coverStartDateAsDateOptionSelector,
  onChange: coverStartDateChanged,
  onInvalid: coverStartDateInvalid,
  crossFieldValidators: [
    buildCrossFieldValidator(
      state => dateOfBirthAsDateSelector(state).value,
      [isBelowMaximumAgeOnCoverStartDate, isAboveMinimumAgeOnCoverStartDate]
    ),
    buildCrossFieldValidator(
      state => vehiclePurchaseDateAsDateSelector(state).value,
      [isCarOwnerOnCoverStartDate]
    ),
    buildCrossFieldValidator(
      state => ({
        date: existingPolicyExpiryDateAsDateSelector(state).value,
        validationErrorMessage: "coverDetails.errors.coverStartDateQuestion.policyStartBeforeCurrentPolicyExpiryDate" as const
      }),
      [isDateAfterCoverStartDate]
    )
  ]
};