import {FC} from "react";
import InformationPanel from "shared-components/dist/information/panel/InformationPanel";

const CompanyCarBonusIneligiblePanel: FC = () => (
  <InformationPanel
    className="company-car-bonus-ineligible-panel"
    title="coverDetails.companyCar.ineligiblePanel.title"
    subTitle="coverDetails.companyCar.ineligiblePanel.subtitle"
    description="coverDetails.companyCar.ineligiblePanel.description"
  />
);

export default CompanyCarBonusIneligiblePanel;