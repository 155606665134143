import {setQuoteValidationSectionBeingResolved} from "../../vehicle/your-quote/redux/QuoteDetailsSlice";
import {useAppDispatch} from "../../../../redux/Hooks";
import {useNavigate} from "react-router-dom";
import {buildThunkToRunAllValidationForSection} from "../../../../redux/questions/RunAllValidationForSection";
import {QuoteValidationErrorSection} from "../../vehicle/your-quote/redux/quote-validation-errors/QuoteValidationError";

export interface UseInitiateResolveErrorFlowForSectionResponse {
  initiateResolveErrorFlow: (route: string) => Promise<void>;
}

export interface UseInitiateResolveErrorFlowForSectionProps {
  section: QuoteValidationErrorSection
  validationThunk?: ReturnType<typeof buildThunkToRunAllValidationForSection>;
}

export const useInitiateResolveErrorFlowForSection = (
  {
    section,
    validationThunk
  }: UseInitiateResolveErrorFlowForSectionProps
): UseInitiateResolveErrorFlowForSectionResponse => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return {
    initiateResolveErrorFlow: async (route: string) => {
      if (validationThunk) await dispatch(validationThunk());
      await dispatch(setQuoteValidationSectionBeingResolved(section));
      navigate(route);
    }
  };
};