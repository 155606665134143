import {apolloClient} from "../../apollo/ApolloClientProvider";
import {
  FetchProspectiveLoansResponse,
  RenewalsQueryGetProspectiveRenewalLoansArgs
} from "shared/dist/generated/graphql/resolvers-types";
import {
  PORTAL_FETCH_RENEWAL_PROSPECTIVE_LOANS_QUERY,
  PortalFetchRenewalProspectiveLoansResult
} from "shared/dist/graphql/queries/portal/RenewalProspectiveLoansQuery";

export async function loadRenewalProspectiveLoansQuery(args: RenewalsQueryGetProspectiveRenewalLoansArgs): Promise<FetchProspectiveLoansResponse> {
  return new Promise((resolve, reject) => {
    apolloClient.query<PortalFetchRenewalProspectiveLoansResult, RenewalsQueryGetProspectiveRenewalLoansArgs>({
      query: PORTAL_FETCH_RENEWAL_PROSPECTIVE_LOANS_QUERY,
      variables: args
    }).then(result => resolve(result.data.renewals.getProspectiveRenewalLoans))
      .catch(error => reject(error));
  });
}