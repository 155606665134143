import {FC} from "react";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import ConfirmationDialog from "../../../../../../../structure/information/confirmation-dialog/ConfirmationDialog";
import {namedDriverDeclarationAcceptanceChanged} from "../../../redux/CoverDetailsSlice";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const namedDriverConfirmationList: TranslationKey[] = [
  "coverDetails.namedDriver.confirmation.proofOfYears",
  "coverDetails.namedDriver.confirmation.consecutiveYears",
  "coverDetails.namedDriver.confirmation.noBreaks",
  "coverDetails.namedDriver.confirmation.noOtherVehicle",
  "coverDetails.namedDriver.confirmation.notPartOfFleet",
];

const NamedConfirmation: FC = () => {
  const namedDriverDeclarationAcceptance = useAppSelector(state => state.coverDetails.namedDriverDeclarationAcceptance);
  const dispatch = useAppDispatch();

  const namedDriverConfirmation = (value: boolean): void => {
    dispatch(namedDriverDeclarationAcceptanceChanged(value));
  };

  return (
    <ConfirmationDialog
      id="named-confirmation"
      className="named-confirmation"
      hasConfirmed={namedDriverDeclarationAcceptance.value}
      status={namedDriverDeclarationAcceptance.status}
      errorMessage={namedDriverDeclarationAcceptance.errorMessage}
      onAnswer={namedDriverConfirmation}
      title="coverDetails.namedDriver.confirmation.title"
      subTitle="coverDetails.namedDriver.confirmation.subtitle"
      description="coverDetails.namedDriver.confirmation.description"
      listDescription={namedDriverConfirmationList}
    />
  );
};

export default NamedConfirmation;