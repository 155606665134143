import React, {FC} from "react";
import "./TabGroup.css";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {usePropsPriorityState} from "../../../utils/custom-hooks/UsePropsPriorityState";

export interface TabGroupOption {
  title: TranslationKey;
  onClick?: () => void;
}

interface Props {
  options: TabGroupOption[];
  option: TranslationKey;
}

const TabGroup: FC<Props> = ({
  options,
  option
}) => {
  const [selectedOption, setSelectedOption] = usePropsPriorityState(option);

  const isSelectedOption = (option: TabGroupOption): boolean => option.title === selectedOption;

  return (
    <div className="tab-group">
      {
        options.map((option, index) => (
          <button
            key={index}
            onClick={() => {
              option.onClick?.();
              setSelectedOption(option.title);
            }}
            className={`tab-group__button${isSelectedOption(option) ? " tab-group__button--selected": ""}`}
          >
            {lookupI18nString(option.title)}
          </button>
        ))
      }
    </div>
  );
};

export default TabGroup;