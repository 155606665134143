import {RootState} from "../../../../../../../../redux/Store";
import {isCompanyCarBonusValid} from "../../../../../your-cover/redux/selectors/helpers/IsCompanyCarBonusValid";
import {
  isNamedDriverAXAEligibleSelector
} from "../../../../../your-cover/redux/selectors/named-driver/IsNamedDriverAXAEligible";
import {createSelector} from "@reduxjs/toolkit";

const coverStartDate = (state: RootState): Date | undefined => state.coverDetails.coverStartDate.value ? new Date(state.coverDetails.coverStartDate.value.date) : undefined;
const companyCarBonusExpiryDate = (state: RootState): Date | undefined => state.coverDetails.companyCarPolicyExpiryDate.value ? new Date(state.coverDetails.companyCarPolicyExpiryDate.value) : undefined;
const companyCarDeclarationAccepted = (state: RootState): boolean => !!state.coverDetails.companyCarDeclarationAcceptance.value;
const namedDriverDeclarationAccepted = (state: RootState): boolean => !!state.coverDetails.namedDriverDeclarationAcceptance.value;

export const selectIsCompanyCarEligibleAndAccepted = createSelector(
  isNamedDriverAXAEligibleSelector,
  companyCarDeclarationAccepted,
  coverStartDate,
  companyCarBonusExpiryDate,
  (
    isEligibleForNamedDriver: boolean,
    companyCarDeclarationAccepted: boolean,
    coverStartDate: Date | undefined,
    companyCarBonusExpiryDate: Date | undefined
  ): boolean => {
    if (!coverStartDate || !companyCarBonusExpiryDate) return false;
    const isCompanyCarBonusExpiryValid = isCompanyCarBonusValid(coverStartDate, companyCarBonusExpiryDate);

    return companyCarDeclarationAccepted && isCompanyCarBonusExpiryValid;
  }
);

export const selectHasNamedDriverOrCompanyCarBonus = createSelector(
  namedDriverDeclarationAccepted,
  selectIsCompanyCarEligibleAndAccepted,
  (namedDriverDeclarationAccepted: boolean, isCompanyCarEligibleAndAccepted: boolean): boolean => {
    return namedDriverDeclarationAccepted || isCompanyCarEligibleAndAccepted;
  }
);