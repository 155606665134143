import React, {FC} from "react";
import "./PlainCard.scss";

interface Props {
  className?: string;
}

const PlainCard: FC<Props> = ({className, children}) => (
  <div className={`plain-card ${className ?? ""}`}>
    {children}
  </div>
);

export default PlainCard;