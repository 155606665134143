import {FC} from "react";
import {usePolicyQueryParams} from "../../../../../router/params/Params";
import {useAppSelector} from "../../../../../redux/Hooks";
import {renewalRequestSelector} from "../../../shared/redux/RenewalRequestSelector";
import AnnualPaymentByCard from "../AnnualPaymentByCard";
import {PaymentFlowType} from "../../../shared/models/PaymentFlowType";
import {selectSelectedQuoteType} from "../../../../portal/renewals/redux/RenewalSelectors";
import RebrokeAnnualPaymentByCard from "./RebrokeAnnualPaymentByCard";

const RenewalAnnualPaymentByCard: FC = () => {
  const {policyId} = usePolicyQueryParams();

  const proposedRenewalRequest = useAppSelector(renewalRequestSelector(policyId));
  const selectedQuoteType = useAppSelector(selectSelectedQuoteType);
  if (!selectedQuoteType) throw new Error("No renewal quote selected");

  if (selectedQuoteType === "REBROKE") {
    return <RebrokeAnnualPaymentByCard/>;
  }

  return <AnnualPaymentByCard proposedRequest={proposedRenewalRequest} paymentFlowType={PaymentFlowType.RENEWAL}/>;
};

export default RenewalAnnualPaymentByCard;