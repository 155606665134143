import React, {FC} from "react";
import Modal from "shared-components/dist/modals/Modal";
import "./PaymentFailedModal.css";
import CircleIcon from "shared-components/dist/icons/circle-icon/CircleIcon";
import {Close} from "@mui/icons-material";
import Spacer from "shared-components/dist/spacer/Spacer";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import Button from "shared-components/dist/buttons/button/Button";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";

interface Props {
  visible: boolean;
  onDismiss: () => void;
  message: TranslationKey;
  buttonText?: TranslationKey;
}

const PaymentFailedModal: FC<Props> = ({
  visible,
  onDismiss,
  message,
  buttonText = "paymentFlow.error.modal.footer.button"
}) => (
  <Modal
    visible={visible}
    onDismiss={onDismiss}
    className="payment-failed-modal"
    header={
      <div className="payment-failed-modal__header">
        <CircleIcon status="error"><Close/></CircleIcon>
        <h2>{lookupI18nString("paymentFlow.error.modal.body.title")}</h2>
      </div>
    }
    body={
      <div className="payment-failed-modal__body">
        <Spacer/>
          <h4>{lookupI18nString("paymentFlow.error.modal.footer.title")}</h4>
          <p>{lookupI18nString(message)}</p>
          <Button
            variant="tertiary"
            onClick={onDismiss}
            expanded
          >
            {lookupI18nString(buttonText)}
          </Button>
      </div>
    }
  />
);

export default PaymentFailedModal;