import {EcommerceAnalyticsItem} from "shared-components/dist/analytics/GoogleAnalyticsEcommerceEventsService";
import {mapGoogleAnalyticsPolicyExtraNameFrom} from "./MapGoogleAnalyticsPolicyExtraName";
import {useAppSelector} from "../../../redux/Hooks";
import {PolicyExtraOptionType, PolicyExtraType, Price} from "shared/dist/generated/graphql/resolvers-types";
import {
  selectPolicyPaymentAmount,
  selectPolicyAdminFee, selectPolicyExtrasForEcommerce,
  selectPolicyInsurerName
} from "../../../redux/selectors/PolicyPaymentSelectors";

export const useBuildEcommerceItemsList = (): EcommerceAnalyticsItem[] | undefined => {
  const insurerName = useAppSelector(selectPolicyInsurerName);
  const policyPrice = useAppSelector(selectPolicyPaymentAmount);
  const adminFee = useAppSelector(selectPolicyAdminFee);
  const policyExtras = useAppSelector(selectPolicyExtrasForEcommerce);

  if (policyPrice === undefined || adminFee === undefined) return undefined;

  return [
    buildCarInsuranceEcommerceItem(policyPrice, insurerName),
    adminEcommerceItem(adminFee),
    ...mapPolicyExtrasToEcommerceItems(policyExtras)
  ];
};

const buildCarInsuranceEcommerceItem = (quotePrice: Price, insurerName?: string): EcommerceAnalyticsItem => ({
  item_id: "CarInsurance",
  item_name: "Car Insurance",
  item_brand: insurerName,
  item_category: "Insurance",
  price: +quotePrice.amount,
  quantity: 1
});

export const adminEcommerceItem = (adminFee: Price): EcommerceAnalyticsItem => ({
  item_id: "AdminFee",
  item_name: "Admin Fee",
  item_brand: "Stroll",
  item_category: "Admin Fee",
  price: +adminFee.amount,
  quantity: 1
});

const mapPolicyExtrasToEcommerceItems = (policyExtras: EcommercePolicyExtra[]): EcommerceAnalyticsItem[] => policyExtras.map((extra) => {
  const ecommerceName = mapGoogleAnalyticsPolicyExtraNameFrom(extra.type);

  return {
    item_id: extra.optionType,
    item_name: ecommerceName,
    item_brand: ecommerceName,
    item_category: "Policy Extra",
    price: extra.price,
    quantity: 1
  };
});

export interface EcommercePolicyExtra {
  price: number,
  optionType: PolicyExtraOptionType,
  type: PolicyExtraType
}