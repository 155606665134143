import {FC} from "react";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./InsurerQuoteCardFooter.css";

interface Props {
  title: TranslationKey;
  description: TranslationKey;
}

const InsurerQuoteCardFooter: FC<Props> = ({
  title,
  description
 }) => (
  <div className="insurer-quote-card-footer">
    <p className="insurer-quote-card-footer__title">{lookupI18nString(title)}</p>
    <p className="insurer-quote-card-footer__description">{lookupI18nString(description)}</p>
  </div>
);

export default InsurerQuoteCardFooter;
