export function buildPaymentRequest(amount: string, merchantId: string, gatewayMerchantId: string): google.payments.api.PaymentDataRequest {
  return {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
      {
        type: "CARD",
        parameters: {
          allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
          allowedCardNetworks: ["MASTERCARD", "VISA"],
        },
        tokenizationSpecification: {
          type: "PAYMENT_GATEWAY",
          parameters: {
            gateway: "globalpayments",
            gatewayMerchantId,
          },
        },
      },
    ],
    merchantInfo: {
      merchantId,
      merchantName: `Stroll Insurance Services Ltd.${window.Stroll.IS_PRODUCTION ? "" : " (TEST)"}`,
    },
    transactionInfo: {
      totalPriceStatus: "FINAL",
      totalPriceLabel: "Total",
      totalPrice: amount,
      currencyCode: "GBP",
      countryCode: "GB",
    },
  };
}
