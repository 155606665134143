import React, {FC} from "react";
import QuoteJourneyStop, {QuoteJourneyStopType} from "../../../../stops/quote-journey-stop/QuoteJourneyStop";

interface Props {
  isVisible: boolean;
  isAdditionalDriverForm: boolean;
  setIsVisible: (isTooManyClaims: boolean) => void;
}

const TooManyClaimsStop: FC<Props> = (
  {
    isVisible,
    isAdditionalDriverForm,
    setIsVisible
  }
) => (
  <QuoteJourneyStop
    stopProps={{
      visible: isVisible,
      title: isAdditionalDriverForm
        ? "additionalDriver.claims.stops.claimsLimit.title"
        : "personalDetails.claims.stops.claimsLimit.title",
      description: "quoteJourney.shared.softStop.description",
    }}
    setIsVisible={setIsVisible}
    stopType={QuoteJourneyStopType.MaxItemLimitExceeded}
    />
);

export default TooManyClaimsStop;