import React, {FC} from "react";
import {shouldShowNamedDriverIneligiblePanelSelector} from "../../redux/selectors/CoverDetailsSelectors";
import NamedConfirmation from "./named-confirmation/NamedConfirmation";
import "./NamedDriverSecondaryQuestionPanel.css";
import PreviousNamedDriverQuestions from "./shared/is-previous-named-driver/PreviousNamedDriverQuestions";
import IsPreviousNamedDriverQuestion from "./shared/is-previous-named-driver/IsPreviousNamedDriverQuestion";
import NamedDriverIneligiblePanel from "./shared/named-driver-ineligible-panel/NamedDriverIneligiblePanel";
import {useAppSelector} from "../../../../../../redux/Hooks";
import SubQuestionForm from "../../../../../../structure/questions/sub-question-form/SubQuestionForm";

const NamedDriverSecondaryQuestionPanel: FC = () => {
  const shouldShowNamedDriverIneligiblePanel = useAppSelector(state => shouldShowNamedDriverIneligiblePanelSelector(state));
  const namedDriverDeclarationAcceptanceField = useAppSelector(state => state.coverDetails.namedDriverDeclarationAcceptance);
  const isPreviousNamedDriverField = useAppSelector(state => state.coverDetails.isPreviousNamedDriver);

  const getInfoPanel = (): React.ReactNode => {
    if (shouldShowNamedDriverIneligiblePanel) return <NamedDriverIneligiblePanel/>;
    if (namedDriverDeclarationAcceptanceField.isActive) return <NamedConfirmation/>;

    return null;
  };

  return (
    <div className="named-driver-secondary-question-panel">
      {isPreviousNamedDriverField.isActive && <IsPreviousNamedDriverQuestion/>}
      {isPreviousNamedDriverField.value === true &&
        <SubQuestionForm
          infoPanel={getInfoPanel()}
          id="named-driver-secondary-question-panel"
        >
          <PreviousNamedDriverQuestions/>
        </SubQuestionForm>
      }
    </div>
  );
};

export default NamedDriverSecondaryQuestionPanel;