import React, {FC, useEffect} from "react";
import {engineCapacityLitresSelected} from "../../../../../../../redux/Actions";
import {StringRadioColumnSubQuestion} from "../../../../../../../structure/questions/sub-question/variants/radio-column-sub-question/variants/StringRadioColumnSubQuestion";
import {engineCapacityLitresInvalid} from "../../../redux/ManualVehicleLookupFormSlice";
import {useEngineCapacities} from "../../../../../../../graphql/queries/vehicle-lookup/manual-vehicle-lookup/ManualVehicleLookup";
import {isElectricVehicleByFuelType} from "../../../../../../../utils/validation/IsElectricVehicleByFuelType";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const YourVehicleEngineCapacityLitres: FC = () => {
  const dispatch = useAppDispatch();
  const makeValue = useAppSelector(state => state.manualVehicleLookupForm.make.value);
  const modelValue = useAppSelector(state => state.manualVehicleLookupForm.model.value);
  const fuelTypeValue = useAppSelector(state => state.manualVehicleLookupForm.fuelType.value);
  const transmissionTypeValue = useAppSelector(state => state.manualVehicleLookupForm.transmissionType.value);
  const yearOfManufactureValue = useAppSelector(state => state.manualVehicleLookupForm.yearOfManufacture.value);
  const engineCapacityLitres = useAppSelector(state => state.manualVehicleLookupForm.engineCapacityLitres);

  const {
    error,
    loading,
    data
  } = useEngineCapacities(makeValue, modelValue, fuelTypeValue, transmissionTypeValue, yearOfManufactureValue);

  const isElectricVehicle = isElectricVehicleByFuelType(fuelTypeValue);

  useEffect(() => {
    if (isElectricVehicle) dispatch(engineCapacityLitresSelected("None"));
  }, [dispatch, isElectricVehicle]);

  const fetchError = !!(!loading && error);

  if (fetchError) dispatch(engineCapacityLitresInvalid("vehicleQuote.manualVehicleLookup.errors.engineCapacityLitresFetchFailed"));

  const radioOptions = !loading && data
    ? data.vehicle.vehicleOptions.engineCapacityLitres
    : [];

  return (
    <>
      {!isElectricVehicle &&
        <StringRadioColumnSubQuestion
          subQuestionProps={{
            title: "vehicleQuote.manualVehicleLookup.engineCapacity",
            id: "manual-vehicle-lookup-engine-capacity-litres",
            errorMessage: engineCapacityLitres.errorMessage,
            status: loading ? "loading" : engineCapacityLitres.status
          }}
          radioColumnProps={{
            name: "manual-vehicle-lookup-engine-capacity-litres-radio-column",
            options: radioOptions,
            selectedOption: engineCapacityLitres.value,
            onSelection: value => dispatch(engineCapacityLitresSelected(value)),
            disabled: fetchError
          }}
        />
      }
    </>
  );
};

export default YourVehicleEngineCapacityLitres;
