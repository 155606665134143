import {
  FinancedPolicyPaymentResponse,
  FinancedPolicyPaymentResult,
  HostedPaymentPageFinancedPolicySetUpRequest,
  HostedPaymentPageFinancedProcessRenewalRequest,
  Mutation,
  ZeroDepositFinancedProcessRenewalRequest,
  ZeroDepositPolicySetUpRequest
} from "shared/dist/generated/graphql/resolvers-types";
import {apolloClient} from "../../../../../../graphql/apollo/ApolloClientProvider";
import {ISuccess} from "@globalpayments/js/types/internal/gateways";
import {ProposedFinancedPolicySetupRequest} from "../../../../shared/models/ProposedFinancedPolicySetupRequest";
import {
  FinancedPolicySetUpVariables,
  HOSTED_PAYMENT_PAGE_SET_UP_FINANCED_POLICY
} from "shared/dist/graphql/mutations/payment/HostedPaymentPageSetUpFinancedPolicyMutation";
import {ProposedFinancedRenewalRequest} from "../../../../shared/models/ProposedFinancedRenewalRequest";
import {
  HOSTED_PAYMENT_PAGE_FINANCED_RENEWAL,
  HostedPaymentPageFinancedRenewalVariables
} from "shared/dist/graphql/mutations/payment/HostedPaymentPageFinancedRenewalMutation";
import {
  ZERO_DEPOSIT_FINANCED_RENEWAL,
  ZeroDepositFinancedRenewalVariables
} from "shared/dist/graphql/mutations/payment/ZeroDepositFinancedRenewalMutation";
import {
  ZERO_DEPOSIT_POLICY_SETUP,
  ZeroDepositPolicySetupVariables
} from "shared/dist/graphql/mutations/payment/ZeroDepositPolicySetupMutation";
import {RenewalQuoteType} from "../../../../../portal/renewals/models/RenewalQuoteType";

export async function processFinancedPolicySetUp(
  globalPayFormResponse: ISuccess,
  request: ProposedFinancedPolicySetupRequest,
  threeDSecureTransactionReference?: string
): Promise<FinancedPolicyPaymentResponse> {
  if (!globalPayFormResponse?.details?.reference) {
    throw Error("No global pay reference so cannot buy from this card");
  }

  if (!globalPayFormResponse?.details?.cardholderName) {
    throw Error("No card holder name so cannot buy from this card");
  }

  return await setUpFinancedPolicy({
    payment: {
      reference: globalPayFormResponse.details.reference,
      cardholderName: globalPayFormResponse.details.cardholderName,
      paymentMethodId: globalPayFormResponse.paymentReference,
      threeDSecureTransactionReference
    },
    depositPercentage: request.depositPercentage,
    financeInformation: request.financeInformation,
    loanSetUp: request.loanSetUp,
    ...request.quoteValues
  });
}

function setUpFinancedPolicy(request: HostedPaymentPageFinancedPolicySetUpRequest): Promise<FinancedPolicyPaymentResponse> {
  return new Promise((resolve, reject) => {
    apolloClient.mutate<Pick<Mutation, "policySetup">, FinancedPolicySetUpVariables>({
      mutation: HOSTED_PAYMENT_PAGE_SET_UP_FINANCED_POLICY,
      variables: {
        request
      }
    }).then((response) => resolve(response.data?.policySetup.hostedPaymentPage.setUpFinancedPolicy ?? {result: FinancedPolicyPaymentResult.SomethingWentWrong}))
      .catch(error => reject(error));
  });
}

export async function processFinancedRenewal(
  globalPayFormResponse: ISuccess,
  proposedFinancedRenewalRequest: ProposedFinancedRenewalRequest,
  threeDSecureTransactionReference?: string
): Promise<FinancedPolicyPaymentResponse> {
  if (!globalPayFormResponse?.details?.reference) {
    throw Error("No global pay reference so cannot buy from this card");
  }

  if (!globalPayFormResponse?.details?.cardholderName) {
    throw Error("No card holder name so cannot buy from this card");
  }

  return await setUpFinancedRenewal({
    payment: {
      reference: globalPayFormResponse.details.reference,
      cardholderName: globalPayFormResponse.details.cardholderName,
      paymentMethodId: globalPayFormResponse.paymentReference,
      threeDSecureTransactionReference
    },
    ...proposedFinancedRenewalRequest
  });
}

export async function processZeroDepositFinancedRenewal(
  proposedFinancedRenewalRequest: ProposedFinancedRenewalRequest | ProposedFinancedPolicySetupRequest,
  selectedQuoteType: RenewalQuoteType | undefined
): Promise<FinancedPolicyPaymentResponse> {
  if (!selectedQuoteType) throw new Error("No renewal quote selected");

  if (selectedQuoteType === "REBROKE") {
    const request = proposedFinancedRenewalRequest as ProposedFinancedPolicySetupRequest;
    return await setUpZeroDepositFinancedRebroke({
      depositPercentage: request.depositPercentage,
      financeInformation: request.financeInformation,
      loanSetUp: request.loanSetUp,
      quoteReference: request.quoteValues.quoteReference,
      quoteSequenceNumber: request.quoteValues.quoteSequenceNumber,
      proposerEmail: request.quoteValues.proposerEmail,
      proposerPhoneNumber: request.quoteValues.proposerPhoneNumber,
    });
  }
  return await setUpZeroDepositFinancedRenewal({...proposedFinancedRenewalRequest as ZeroDepositFinancedProcessRenewalRequest});
}

function setUpFinancedRenewal(request: HostedPaymentPageFinancedProcessRenewalRequest): Promise<FinancedPolicyPaymentResponse> {
  return new Promise((resolve, reject) => {
    apolloClient.mutate<Pick<Mutation, "renewals">, HostedPaymentPageFinancedRenewalVariables>({
      mutation: HOSTED_PAYMENT_PAGE_FINANCED_RENEWAL,
      variables: {
        request
      }
    }).then((response) => resolve(response.data?.renewals.hostedPaymentPage.processFinancedRenewal ?? {result: FinancedPolicyPaymentResult.SomethingWentWrong}))
      .catch(error => reject(error));
  });
}

function setUpZeroDepositFinancedRenewal(request: ZeroDepositFinancedProcessRenewalRequest): Promise<FinancedPolicyPaymentResponse> {
  return new Promise((resolve, reject) => {
    apolloClient.mutate<Pick<Mutation, "renewals">, ZeroDepositFinancedRenewalVariables>({
      mutation: ZERO_DEPOSIT_FINANCED_RENEWAL,
      variables: {
        request
      }
    }).then((response) => resolve(response.data?.renewals.zeroDeposit.processFinancedRenewal ?? {result: FinancedPolicyPaymentResult.SomethingWentWrong}))
      .catch(error => reject(error));
  });
}

function setUpZeroDepositFinancedRebroke(request: ZeroDepositPolicySetUpRequest): Promise<FinancedPolicyPaymentResponse> {
  return new Promise((resolve, reject) => {
    apolloClient.mutate<Pick<Mutation, "policySetup">, ZeroDepositPolicySetupVariables>({
      mutation: ZERO_DEPOSIT_POLICY_SETUP,
      variables: {
        request
      }
    }).then((response) => resolve(response.data?.policySetup.zeroDeposit.setUpFinancedPolicy ?? {result: FinancedPolicyPaymentResult.SomethingWentWrong}))
      .catch(error => reject(error));
  });
}