import {gql} from "@apollo/client";
import {apolloClient} from "../../../apollo/ApolloClientProvider";
import {Vehicle} from "./model/Vehicle";
import {removeAll} from "shared-components/dist/utils/stdlib/StringUtils";
import {WHITESPACE} from "shared-components/dist/utils/constants/Regex";
import {VehicleOptions} from "shared/dist/generated/graphql/resolvers-types";

export interface VehicleOptionsQueryResult {
  vehicle: VehicleOptionsResult;
}

export interface VehicleOptionsResult {
  makes: string[];
  models: string[];
  registrationLookup: Vehicle;
  vehicleOptions: VehicleOptions;
}

export interface VehicleQueryVariables {
  registrationNumber: string;
}

export const REGISTRATION_LOOKUP = gql`
  query RegistrationLookup($registrationNumber: String!) {
    vehicle {
      registrationLookup(registrationNumber: $registrationNumber) {
        abi
        registration
        make
        model
        trim
        bodyStyle
        engineCapacityCc
        engineCapacityLitres
        fuelType
        transmissionType
        yearOfManufacture
        driverPosition
        seats
        doors
        imported
        importType
        estimatedValue
        commercialVehicle
      }
    }
  }
`;

export function getVehicleByRegistrationNumber(registrationNumber: string): Promise<Vehicle> {
  return new Promise((resolve, reject) => {
    apolloClient.query<VehicleOptionsQueryResult, VehicleQueryVariables>({
      query: REGISTRATION_LOOKUP,
      variables: {
        registrationNumber: removeAll(registrationNumber, WHITESPACE).toUpperCase()
      }
    }).then(result => resolve(result.data.vehicle.registrationLookup))
      .catch(error => reject(error));
  });
}
