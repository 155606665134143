import React, {FC, ReactNode} from "react";
import "./QuotePriceCardRow.css";

interface OwnProps {
  id: string,
  name: ReactNode | string;
  value: ReactNode;
  filled?: boolean;
}

const QuotePriceCardRow: FC<OwnProps> = (
  {
    id,
    name,
    value,
    filled
  }
) => (
  <div role="row" className={`quote-price-card-row${filled ? " quote-price-card-row--filled" : ""}`} data-testid={`quote-price-card-row-${id}`}>
    <span className="quote-price-card-row__label">{name}</span>
    <span className="quote-price-card-row__value">{value}</span>
  </div>
);

export default QuotePriceCardRow;