import {CognitoUser} from "amazon-cognito-identity-js";
import {VerifyUserAttributeResult} from "../../models/VerifyUserAttributeResult";
import {logger} from "../../../../utils/logging/Logger";

interface VerifyUserAttributeParams {
  cognitoUser: CognitoUser;
  attributeName: string;
  verificationCode: string;
}

export function verifyUserAttribute(
  {
    cognitoUser,
    attributeName,
    verificationCode
  }: VerifyUserAttributeParams
): Promise<VerifyUserAttributeResult> {
  return new Promise<VerifyUserAttributeResult>(resolve => {
    cognitoUser.verifyAttribute(
      attributeName,
      verificationCode,
      {
        onFailure: error => resolve(handleErrorStates(error, attributeName)),
        onSuccess: () => {
          logger.log("Successfully updated user attribute:", attributeName);
          return resolve("Success");
        }
      }
    );
  });
}

function handleErrorStates(error: Error, attributeName: string): VerifyUserAttributeResult {
  switch (error.name) {
    case "CodeMismatchException": {
      logger.error("Invalid verification code provided for user attribute:", attributeName, error);
      return "InvalidCode";
    }
    case "ExpiredCodeException": {
      logger.error("Expired confirmation code provided for user attribute:", attributeName, error);
      return "CodeExpired";
    }
    case "LimitExceededException": {
      logger.error("Too many attempts to update user attribute:", attributeName, error);
      return "TooManyAttempts";
    }
    default: {
      logger.error("Failed to verify user attribute:", attributeName, error);
      return "Failed";
    }
  }
}