import {FC} from "react";
import {useAppDispatch, useAppSelector} from "../../../redux/Hooks";
import {useIdleTimer} from "react-idle-timer";
import {logout} from "../login/redux/thunks/Logout";
import {userSessionHasExpired} from "../shared/redux/UserAuthenticationSlice";

const UserInactivityMonitor: FC = () => {
  const dispatch = useAppDispatch();
  const currentSessionState = useAppSelector(state => state.userAuthentication.currentSessionState);
  const isUserLoggedIn = currentSessionState === "Valid";
  const fifteenMinutesInMilliseconds = 900000;

  const onIdle = (): void => {
    if (isUserLoggedIn) {
      dispatch(logout());
      dispatch(userSessionHasExpired());
    }
  };

  useIdleTimer({
    timeout: fifteenMinutesInMilliseconds,
    onIdle
  });

  return null;
};

export default UserInactivityMonitor;