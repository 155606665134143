import React, {FC, useEffect} from "react";
import {Status} from "shared-components/dist/status-indicator/Status";
import SubQuestion from "../../SubQuestion";
import Dropdown from "shared-components/dist/form/dropdown/Dropdown";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";

interface Props {
  id: string;
  title: TranslationKey;
  onChange: (value: string) => void;
  options: string[];
  errorMessage?: TranslationKey;
  errorTooltip?: TranslationKey;
  status?: Status;
  value?: string;
  placeholder?: TranslationKey;
  disabled?: boolean;
  tooltip?: TooltipProps;
  prefix?: TranslationKey;
  displayValues?: string[];
}

const DropdownSubQuestion: FC<Props> = (
  {
    id,
    title,
    errorMessage,
    errorTooltip,
    status,
    value,
    onChange,
    options,
    placeholder,
    disabled,
    tooltip,
    prefix,
    displayValues
  }
) => {
  useEffect(() => {
    if (options.length === 1 && !value) {
      onChange(options[0]);
    }
  }, [options, onChange, value]);

  return (
    <SubQuestion
      title={title}
      id={id}
      errorMessage={errorMessage}
      errorTooltip={errorTooltip}
      status={status}
      tooltip={tooltip}
    >
      <Dropdown
        name={`${id}Dropdown`}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        options={options}
        placeholder={placeholder}
        disabled={disabled}
        prefix={prefix}
        displayValues={displayValues}
      />
    </SubQuestion>
  );
};

export default DropdownSubQuestion;