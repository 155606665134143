import {
  licenceCountryOfIssueChanged,
  licenceDurationChanged,
  licenceDurationInvalid,
  licenceDurationReset,
  licenceTypeChanged
} from "../../../../pages/quote/vehicle/additional-driver/shared/redux/UpdateAdditionalDriverSlice";
import {licenceDurationValidators} from "../../shared/driver-validation/LicenceDurationValidators";
import {dateOfBirth} from "./DateOfBirth";
import {SectionQuestionsRecord} from "../../Questions";

export const licence = {
  licenceType: {
    selector: state => state.updateAdditionalDriver.licenceType,
    onChange: licenceTypeChanged
  },
  licenceCountryOfIssue: {
    selector: state => state.updateAdditionalDriver.licenceCountryOfIssue,
    onChange: licenceCountryOfIssueChanged
  },
  licenceDuration: {
    selector: state => state.updateAdditionalDriver.licenceDuration,
    onChange: licenceDurationChanged,
    onInvalid: licenceDurationInvalid,
    onReset: licenceDurationReset,
    crossFieldValidators: licenceDurationValidators(dateOfBirth)
  }
} as const satisfies SectionQuestionsRecord;