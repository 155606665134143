import {BreakInCover} from "../../../../services/BreakInCoverDetector";
import {AppThunk, AppThunkDispatch} from "../../../../../../../../redux/Store";
import {
  breakInCoverChanged,
  breakInCoverDeclarationAcceptanceChanged
} from "../../../../../your-cover/redux/CoverDetailsSlice";
import {registrationNumberChanged} from "../../../../../your-vehicle/redux/ManualVehicleLookupFormSlice";
import {
  dateOfPurchaseChanged,
  isCurrentOwnerChanged,
  aggregatorQuoteVehicleRegistrationChanged
} from "../../../../../your-vehicle/redux/VehicleDetailsSlice";
import {loadQuotes} from "../../../../../your-quote/redux/thunks/LoadQuotes";
import {handleRefreshedQuotes} from "../../../../redux/HandleRefreshedQuotes";

export interface EditCarDetailsPayload {
  registrationNumber: string | undefined;
  dateOfPurchase: Date | undefined;
  breakInCover: BreakInCover;
  breakInCoverDeclarationAcceptance: boolean | undefined;
  isAggregatorQuote: boolean | undefined;
}

export const carDetailsEdited = (monthlyPaymentsEnabled: boolean, updatedCarDetails: EditCarDetailsPayload, shouldReloadQuotes: boolean): AppThunk => async (dispatch, getState) => {
  const result = await Promise.all([
    editRegistrationNumber(updatedCarDetails, dispatch),
    editDateOfPurchase(updatedCarDetails, dispatch),
    editBreakInCover(updatedCarDetails, dispatch),
    editBreakInCoverDeclarationAcceptance(updatedCarDetails, dispatch),
  ]);

  if (shouldReloadQuotes) {
    await dispatch(loadQuotes({monthlyPaymentsEnabled}));
    handleRefreshedQuotes(getState, dispatch);
  }

  return result;
};

const editRegistrationNumber = async ({registrationNumber, isAggregatorQuote}: EditCarDetailsPayload, dispatch: AppThunkDispatch): Promise<void> => {
  if (!registrationNumber) return;

  if (isAggregatorQuote) {
    dispatch(aggregatorQuoteVehicleRegistrationChanged(registrationNumber));
  } else {
    dispatch(registrationNumberChanged(registrationNumber));
  }
};

const editDateOfPurchase = async ({dateOfPurchase}: EditCarDetailsPayload, dispatch: AppThunkDispatch): Promise<void> => {
  if (dateOfPurchase) {
    dispatch(isCurrentOwnerChanged(true));
    dispatch(dateOfPurchaseChanged(dateOfPurchase.toISOString()));
  }
};

const editBreakInCover = async ({breakInCover}: EditCarDetailsPayload, dispatch: AppThunkDispatch): Promise<void> => {
  await dispatch(breakInCoverChanged(breakInCover));
};

const editBreakInCoverDeclarationAcceptance = async ({breakInCoverDeclarationAcceptance}: EditCarDetailsPayload, dispatch: AppThunkDispatch): Promise<void> => {
  if (breakInCoverDeclarationAcceptance) dispatch(breakInCoverDeclarationAcceptanceChanged(breakInCoverDeclarationAcceptance));
};
