import React, {FC} from "react";
import DropdownQuestion from "../../../../../../structure/questions/question/variants/dropdown-question/DropdownQuestion";
import {mainDriverChanged} from "../../redux/PersonalDetailsSlice";
import {additionalDriversOptionsSelector} from "../../redux/selectors/AdditionalDriversOptionsSelector";
import {Element} from "react-scroll";
import ProhibitedMainDriverStop from "./ProhibitedMainDriverStop";
import {logYourDetailsGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {additionalDriversSelector} from "../../redux/selectors/PersonalDetailsSelectors";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

interface Props {
  autoFocus: boolean;
}

const MainDriverQuestion: FC<Props> = ({autoFocus}) => {
  const dispatch = useAppDispatch();
  const additionalDrivers = useAppSelector(additionalDriversSelector);
  const mainDriver = useAppSelector(state => state.personalDetails.mainDriver);
  const policyHolderFirstName = useAppSelector(state => state.personalDetails.firstName.value ?? "");
  const policyHolderSurname = useAppSelector(state => state.personalDetails.surname.value ?? "");
  const additionalDriversOptions = useAppSelector(additionalDriversOptionsSelector);

  if (!mainDriver.isActive) return null;

  const policyHolder = "Policy Holder";
  const dropdownOptions = [
    policyHolder,
    ...additionalDriversOptions
  ];
  const additionalDriverNames = additionalDrivers.map(driver => `${driver.firstName} ${driver.surname}`);

  const updateSelectedDriver = (selectedDriver: string): void => {
    if (selectedDriver === policyHolder) {
      logYourDetailsGoogleAnalyticsEvent({
        categorySuffix: "who_is_main_driver",
        action: "Click",
      });
      dispatch(mainDriverChanged(policyHolder));

      return;
    }

    const additionalDriver = additionalDrivers.find(driver => driver.id === selectedDriver);
    if (additionalDriver) {
      logYourDetailsGoogleAnalyticsEvent({
        categorySuffix: "who_is_main_driver",
        action: "Click",
      });
      dispatch(mainDriverChanged(additionalDriver.id));
    }
  };

  const questionId = "main-driver-question";

  return (
    <Element name={questionId}>
      <DropdownQuestion
        id={questionId}
        title="personalDetails.mainDriver"
        options={dropdownOptions}
        value={mainDriver.value ?? policyHolder}
        onChange={updateSelectedDriver}
        status={mainDriver.status}
        errorMessage={mainDriver.errorMessage}
        displayValues={[
          `${policyHolderFirstName} ${policyHolderSurname} [${policyHolder}]`,
          ...additionalDriverNames
        ]}
        autoFocus={autoFocus}
      />
      <ProhibitedMainDriverStop/>
    </Element>
  );
};

export default MainDriverQuestion;