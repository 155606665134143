import {FC, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../../redux/Hooks";
import {selectPortalLoadingStatus} from "../redux/PortalSelectors";
import {PortalLoadingStatus} from "../redux/PortalDetails";
import {loadPortalDashboard} from "../redux/thunks/LoadPortalDashboard";
import {initializeZendesk} from "../../../../zendesk/Zendesk";
import PolicyErrorPage from "../../policy-error/PolicyErrorPage";
import FullPageSpinner from "shared-components/dist/spinner/full-page-spinner/FullPageSpinner";

const CustomerPortalContainer: FC = ({children}) => {
  const portalLoadingStatus = useAppSelector(selectPortalLoadingStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (portalLoadingStatus === PortalLoadingStatus.NOT_STARTED) {
      dispatch(loadPortalDashboard());
      initializeZendesk();
    }
  });

  if ([PortalLoadingStatus.LOADING, PortalLoadingStatus.NOT_STARTED].includes(portalLoadingStatus)) {
    return <FullPageSpinner message="portal.spinner.message"/>;
  }

  if (portalLoadingStatus === PortalLoadingStatus.FAILED) {
    return <PolicyErrorPage/>;
  }

  return (
    <div
      data-testid="customer-portal-container"
      className="customer-portal-container"
    >
      {children}
    </div>
  );
};

export default CustomerPortalContainer;