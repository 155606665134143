import React, {FC} from "react";
import QuoteJourneyStop, {QuoteJourneyStopType} from "../../../../../shared/stops/quote-journey-stop/QuoteJourneyStop";

interface Props {
  isVisible: boolean;
  setIsVisible: (isTooManyModifications: boolean) => void;
}

const TooManyModificationsStop: FC<Props> = ({isVisible, setIsVisible}) => (
  <QuoteJourneyStop
    stopProps={{
      visible: isVisible,
      title: "vehicleQuote.modifications.tooManyModificationsStop.title",
      description: "quoteJourney.shared.softStop.description"
    }}
    setIsVisible={setIsVisible}
    stopType={QuoteJourneyStopType.MaxItemLimitExceeded}
  />
);

export default TooManyModificationsStop;