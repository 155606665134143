import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {ModificationType} from "shared/dist/generated/graphql/resolvers-types";

export const MODIFICATION_TYPE_OPTIONS = [
  {
    id: ModificationType.AlloyWheels,
    description: lookupI18nString("vehicleQuote.modifications.selection.options.alloyWheels")
  },
  {
    id: ModificationType.WindowTint,
    description: lookupI18nString("vehicleQuote.modifications.selection.options.windowTint")
  },
  {
    id: ModificationType.Other,
    description: lookupI18nString("vehicleQuote.modifications.selection.options.other")
  }
] as const;

export type ModificationTypeOption = typeof MODIFICATION_TYPE_OPTIONS[number];
export type ModificationTypeDescription = typeof MODIFICATION_TYPE_OPTIONS[number]["description"];