import React, {FC, ReactChild, ReactChildren, useState} from "react";
import {KeyboardArrowDown} from "@mui/icons-material";
import "./CollapsibleSidebar.css";

export type SidebarPresentationMode = "CLOSED" | "OPEN";

interface Props {
  className?: string;
  title: ReactChild | ReactChildren;
  presentationMode?: SidebarPresentationMode;
}

const CollapsibleSidebar: FC<Props> = ({
  children,
  className,
  title,
  presentationMode = "OPEN"
}) => {
  const [isOpen, setIsOpen] = useState(presentationMode === "OPEN");

  const sidebarClassName = (): string => {
    let sidebarClass = "sidebar";
    if (className) sidebarClass += ` ${className}`;
    return sidebarClass;
  };

  return (
    <div className={sidebarClassName()}>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className={`sidebar__toggle${isOpen ? " sidebar__toggle--open" : ""}`}
      >
        {title}
        <KeyboardArrowDown/>
      </button>
      {isOpen && children}
    </div>
  );
};

export default CollapsibleSidebar;