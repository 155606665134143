import React, {FC} from "react";
import ProtectedNoClaimsBonusInformation from "../../../../quote/vehicle/protected-no-claims-bonus-information/ProtectedNoClaimsBonusInformation";
import "../../../../quote/vehicle/protected-no-claims-bonus-information/ProtectedNoClaimsBonusInformation.css";
import {useAppSelector} from "../../../../../redux/Hooks";
import {
  selectExistingPolicyHolderForRenewal,
  selectRenewalInsurerName,
  selectNoClaimsBonusProtectionData
} from "../../redux/RenewalSelectors";
import PortalHeader from "../../../shared/portal-header/PortalHeader";
import {useNavigate} from "react-router-dom";
import {useEffectOnFirstRender} from "shared-components/src/utils/hooks/UseEffectOnFirstRender";

const RenewalsConfirmProtectedNoClaimsBonusInformation: FC = () => {
  const noClaimsDiscountProtectionData = useAppSelector(selectNoClaimsBonusProtectionData);
  const {noClaimsBonusYears} = useAppSelector(selectExistingPolicyHolderForRenewal) ?? {noClaimsBonusYears: undefined};
  const insurerName = useAppSelector(selectRenewalInsurerName);

  const navigate = useNavigate();

  useEffectOnFirstRender(() => {
    if (!noClaimsDiscountProtectionData || !noClaimsBonusYears || !insurerName) navigate(-1);
  });

  return (
    <div data-testid="renewals-confirm-protected-no-claims-info">
      <ProtectedNoClaimsBonusInformation
        header={<PortalHeader/>}
        isPortal={true}
        noClaimsDiscountProtectionData={noClaimsDiscountProtectionData}
        yearsNoClaimsBonus={noClaimsBonusYears ?? undefined}
        insurerName={insurerName}
      />
    </div>
  );
};

export default RenewalsConfirmProtectedNoClaimsBonusInformation;