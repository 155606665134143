import React, {FC} from "react";
import "./RenewalInviteOverdue.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import Button from "shared-components/dist/buttons/button/Button";
import FormattedMessage from "shared-components/src/translations/components/FormattedMessage";
import {openZendeskChat} from "../../../../../../../zendesk/Zendesk";
import PlainCard from "shared-components/src/cards/plain-card/PlainCard";
import {toGregorianFormat} from "shared/dist/stdlib/DateFormat";
import {isToday} from "date-fns";

interface Props {
  renewalDate: Date
}

const RenewalInviteOverdue: FC<Props> = ({renewalDate}) => {
  const isDueToday = isToday(renewalDate);

  return (
    <PlainCard>
      <div className="renewal-invite-overdue">
        <h1 className="renewal-invite-overdue__title" data-testid="renewal-invite-overdue__title">
          {isDueToday
            ? lookupI18nString("renewal.holdingPage.renewalDueToday")
            : <FormattedMessage
              id="renewal.holdingPage.renewalDue"
              values={{
                b: (msg: string) => <b>{msg}</b>,
                renewalDate: toGregorianFormat(renewalDate)
              }}
            />
          }
        </h1>

        <p className="renewal-invite-overdue__description">
          <FormattedMessage
            id="renewal.inviteOverduePage.description"
            values={{
              a: (phoneNumber: string) => <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            }}
          />
        </p>

        <Button
          data-testid="renewal-invite-overdue__button"
          variant="secondary"
          small={true}
          onClick={openZendeskChat}>
          {lookupI18nString("renewal.inviteOverduePage.getInTouch")}
        </Button>
      </div>
    </PlainCard>
  );
};

export default RenewalInviteOverdue;