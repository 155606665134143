import React, {FC} from "react";
import DropdownSubQuestion
  from "../../../../../../../structure/questions/sub-question/variants/dropdown-sub-question/DropdownSubQuestion";
import {Status} from "shared-components/dist/status-indicator/Status";
import {useConvictionTypes} from "../../../../../../../graphql/queries/convictions/conviction-types/ConvictionTypesLookup";
import {convictionTypeDescriptionsFrom, convictionTypesFrom} from "./helpers/DropdownOptions";
import {ConvictionTypeItem} from "shared/dist/generated/graphql/resolvers-types";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";

interface Props {
  onChange: (value: ConvictionTypeItem | undefined) => void;
  status: Status;
  value: ConvictionTypeItem | undefined;
  isAdditionalDriverForm: boolean;
}

const ConvictionTypeSubQuestion: FC<Props> = (
  {
    onChange,
    status,
    value,
    isAdditionalDriverForm
  }
) => {
  const {error, loading, data} = useConvictionTypes();

  function getConvictionType(selectedType: string): ConvictionTypeItem | undefined {
    if (loading || !data) return;

    return data.find(type => type.id === selectedType);
  }

  const fetchError = !loading && error !== undefined;

  function getErrorMessage(): TranslationKey {
    return fetchError
      ? "personalDetails.convictionsQuestions.subQuestion.type.fetchError"
      : "quote.errors.questionIncomplete";
  }

  return (
    <DropdownSubQuestion
      id="conviction-type-sub-question"
      title={
        isAdditionalDriverForm
          ? "additionalDriver.convictionsQuestions.subQuestion.type.title"
          : "personalDetails.convictionsQuestions.subQuestion.type.title"
      }
      onChange={value => onChange(getConvictionType(value))}
      options={!loading && data ? convictionTypesFrom(data) : []}
      displayValues={!loading && data ? convictionTypeDescriptionsFrom(data) : []}
      value={value?.id}
      status={fetchError ? "error" : status}
      errorMessage={getErrorMessage()}
      disabled={loading || error !== undefined}
      tooltip={isAdditionalDriverForm
        ? {description: "additionalDriver.convictionsQuestions.subQuestion.type.tooltip.description"}
        : {description: "personalDetails.convictionsQuestions.subQuestion.type.tooltip.description"}
      }
    />
  );
};

export default ConvictionTypeSubQuestion;
