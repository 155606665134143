import {AuthenticationDetails, CognitoUser, CognitoUserPool} from "amazon-cognito-identity-js";
import {clientId, userPoolId} from "../../providers/Environment";

export const userPool: CognitoUserPool = new CognitoUserPool({
  UserPoolId: userPoolId,
  ClientId: clientId,
});

export const createCognitoUser = (email: string, userPool: CognitoUserPool): CognitoUser => new CognitoUser({
  Username: email,
  Pool: userPool
});

export const createAuthenticationDetails = (email: string, password: string): AuthenticationDetails => new AuthenticationDetails({
  Username: email,
  Password: password,
});
