import React, {FC, ReactChild} from "react";
import "./CustomerPortalContainerWithSidebar.css";
import ContentWithSidebarLayout
  from "../../../../structure/layouts/content-with-sidebar-layout/ContentWithSidebarLayout";
import CustomerPortalContainer from "./CustomerPortalContainer";

interface Props {
  header: ReactChild;
  mainContent: ReactChild;
  sidebar?: ReactChild;
  className?: string;
}

const CustomerPortalContainerWithSidebar: FC<Props> = ({
  header,
  mainContent,
  sidebar,
  className
}) => (
  <CustomerPortalContainer>
    <ContentWithSidebarLayout
      className={className}
      header={header}
      mainContent={mainContent}
      sidebar={sidebar}
      isPortal
    />
  </CustomerPortalContainer>
);

export default CustomerPortalContainerWithSidebar;
