import React, {FC, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {quoteSteps} from "../quote/vehicle/shared/quote-step/QuoteSteps";
import {useEffectOnFirstRender} from "shared-components/dist/utils/hooks/UseEffectOnFirstRender";
import {useAggregatorQuoteReferenceQueryParams} from "./hooks/GetAggregatorQuoteReferenceHook";
import {useAppDispatch, useAppSelector} from "../../redux/Hooks";
import {loadAggregatorQuoteWithPolicyExtras} from "./redux/LoadAggregatorQuote";
import QuoteLoading from "../quote/vehicle/shared/quote-loading/QuoteLoading";
import PlainHeader from "../../structure/navigation/plain-header/PlainHeader";
import QuoteNotFound from "../quote/vehicle/your-quote/components/saved/components/quote-not-found/QuoteNotFound";
import {QuoteRetrievalStatus} from "../quote/vehicle/your-quote/models/QuoteRetrievalStatus";
import {quoteRetrievalStatusChanged} from "../quote/vehicle/your-quote/redux/QuoteDetailsSlice";
import {useAggregatorQuotePolicyExtrasParams} from "./hooks/GetAggregatorQuotePolicyExtrasHook";
import {useFeatureFlags} from "shared-components/dist/feature-flags/hooks/UseFeatureFlags";

const AggregatorQuoteLandingPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const quoteRetrievalStatus = useAppSelector(state => state.quoteDetails.quoteRetrievalStatus);
  const {quoteReference} = useAggregatorQuoteReferenceQueryParams();
  const policyExtras = useAggregatorQuotePolicyExtrasParams();
  const {monthlyPaymentsFlag, compareNiPhase2Flag} = useFeatureFlags();

  useEffectOnFirstRender(() => {
    if (quoteReference) {
      loadQuoteIntoRedux(encodeURIComponent(quoteReference));
    } else {
      dispatch(quoteRetrievalStatusChanged(QuoteRetrievalStatus.NOT_FOUND));
    }
  });

  useEffect(() => {
    if (quoteRetrievalStatus === QuoteRetrievalStatus.SUCCESS) navigate(quoteSteps.yourQuote.routes.new);
  }, [quoteRetrievalStatus, navigate]);

  function loadQuoteIntoRedux(quoteReference: string): void {
    dispatch(quoteRetrievalStatusChanged(QuoteRetrievalStatus.LOADING));
    dispatch(loadAggregatorQuoteWithPolicyExtras(quoteReference, policyExtras, monthlyPaymentsFlag, compareNiPhase2Flag));
  }

  if (quoteRetrievalStatus === QuoteRetrievalStatus.NOT_FOUND) {
    return (
      <div className="aggregator-quote--not-found">
        <PlainHeader/>
        <QuoteNotFound/>
      </div>
    );
  }

  return <QuoteLoading/>;
};

export default AggregatorQuoteLandingPage;