import InlineQuestion from "../../../../../structure/questions/inline-question/InlineQuestion";
import React, {FC} from "react";

export const cardExpiryId = "global-pay-card-expiry-input";

const CardExpiry: FC = () => (
  <InlineQuestion
    id={cardExpiryId}
    label="paymentFlow.byCard.form.cardDetails.expiry"
  >
    <div id={cardExpiryId} className="card-details__expiry"/>
  </InlineQuestion>
);

export default CardExpiry;