import React, {FC} from "react";
import {licenceCountryOfIssueChanged} from "../../redux/PersonalDetailsSlice";
import LicenceCountryOfIssueQuestion from "../../../shared/questions/licence-country-of-issue/LicenceCountryOfIssueQuestion";
import {LicenceCountryOfIssue} from "../../../shared/questions/licence-country-of-issue/LicenceCountryOfIssue";
import {licenceCountryOfIssueStopVisibleSelector} from "../../redux/selectors/LicenceCountryOfIssueStopVisibleSelector";
import {logYourDetailsGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const ProposerLicenceCountryOfIssueQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const licenceCountryOfIssueField = useAppSelector(state => state.personalDetails.licenceCountryOfIssue);
  const isStopVisible = useAppSelector(licenceCountryOfIssueStopVisibleSelector);

  const onLicenceCountryOfIssueChanged = (answer: LicenceCountryOfIssue): void => {
    logYourDetailsGoogleAnalyticsEvent({
      categorySuffix: "where_licence_issued",
      action: answer.description.toLowerCase(),
    });
    dispatch(licenceCountryOfIssueChanged(answer));
  };

  return (
    <LicenceCountryOfIssueQuestion
      id="proposer-country-of-issue"
      title="personalDetails.licenceCountryOfIssueQuestion"
      licenceCountryOfIssueField={licenceCountryOfIssueField}
      onComplete={onLicenceCountryOfIssueChanged}
      isStopVisible={isStopVisible}
    />
  );
};

export default ProposerLicenceCountryOfIssueQuestion;