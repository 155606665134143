import React, {FC, useState} from "react";
import {
  hasModificationsChanged,
  isFormVisibleSet,
  modificationsChanged,
  modificationsStatusChanged
} from "../../../redux/VehicleDetailsSlice";
import MultipleItemsQuestion
  from "../../../../../../../structure/questions/multiple-items-question/MultipleItemsQuestion";
import {removeAtIndex} from "shared-components/dist/utils/stdlib/Arrays";
import ModificationsSubQuestionForm from "./ModificationsSubQuestionForm";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import ModificationItem from "./ModificationItem";
import TooManyModificationsStop from "./stops/TooManyModificationsStop";
import {logYourCarGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {logger} from "../../../../../../../utils/logging/Logger";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const ModificationsQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const modifications = useAppSelector(state => state.vehicleDetails.modifications);
  const shouldFlagUnansweredQuestions = useAppSelector(state => state.vehicleDetails.modifications.value.shouldFlagUnansweredQuestions);
  const isFormVisible = useAppSelector(state => state.vehicleDetails.modifications.value.isFormVisible);

  const [tooManyModifications, setTooManyModifications] = useState(false);

  const mapModificationsToItems = (): JSX.Element[] => (
    modifications.value.modifications.map((modification, index) => (
      <ModificationItem key={index} modification={modification}/>
    ))
  );

  const removeItem = (index: number): void => {
    if (modifications.value.modifications.length - 1 < index) {
      logger.warn("Tried to remove modification that doesn't exist");
      return;
    }

    dispatch(modificationsChanged(removeAtIndex(modifications.value.modifications, index)));
    setTooManyModifications(false);
  };

  const tooltip: TooltipProps = {
    description: "vehicleQuote.modifications.tooltip.description"
  };

  function onModificationsChanged(value: boolean): void {
    dispatch(hasModificationsChanged(value));
    setTooManyModifications(false);

    logYourCarGoogleAnalyticsEvent({
      categorySuffix: "car_modified",
      action: value,
    });
  }

  return (
    <>
      <MultipleItemsQuestion
        id="modificationOptionSelection"
        name="incidentFormWithMultipleItems"
        title="vehicleQuote.modifications.title"
        tooltip={tooltip}
        error={modifications.errorMessage}
        status={modifications.status}
        form={
          <ModificationsSubQuestionForm
            modifications={modifications.value.modifications}
            modificationsChanged={modifications => dispatch(modificationsChanged(modifications))}
            shouldFlagUnansweredQuestions={shouldFlagUnansweredQuestions}
          />
        }
        items={mapModificationsToItems()}
        confirmation={modifications.value.hasModifications}
        setConfirmation={onModificationsChanged}
        itemRemoved={removeItem}
        isFormVisible={isFormVisible}
        setIsFormVisible={value => dispatch(isFormVisibleSet(value))}
        maxItems={2}
        onMaxItems={() => setTooManyModifications(true)}
        onCancel={() => dispatch(modificationsStatusChanged("success"))}
      />
      <TooManyModificationsStop isVisible={tooManyModifications} setIsVisible={setTooManyModifications}/>
    </>
  );
};

export default ModificationsQuestion;
