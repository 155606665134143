import {SimpleQueryResult} from "../../../../../../../../graphql/apollo/SimpleQueryResult";
import {ProspectivePolicyExtra} from "shared/dist/generated/graphql/resolvers-types";
import {gql, useQuery} from "@apollo/client";

export interface GetPolicyExtrasResult {
  policySetup: {
    policyExtras: ProspectivePolicyExtra[];
  };
}

export const GET_POLICY_EXTRAS = gql`
  query GetPolicyExtras($policyExtraStartDate: String!) {
    policySetup {
      policyExtras(policyExtraStartDate: $policyExtraStartDate) {
        type
        document {
          url
        }
        options {
          optionType
          price
          description
          excessProtectedAmount {
            amount
            currency
          }
        }
      }
    }
  }
`;

export function useAvailablePolicyExtrasQuery(policyExtraStartDate: string | undefined): SimpleQueryResult<ProspectivePolicyExtra[]> {
  const queryResult = useQuery<GetPolicyExtrasResult>(
    GET_POLICY_EXTRAS,
    {variables: {policyExtraStartDate}}
  );

  return {
    loading: queryResult.loading,
    error: queryResult.error,
    data: queryResult.data?.policySetup.policyExtras ?? []
  };
}
