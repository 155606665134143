import {VehicleHolder} from "shared/dist/generated/graphql/resolvers-types";

export const VEHICLE_HOLDERS = [
  {
    id: VehicleHolder.PolicyHolder,
    description: "Policy Holder"
  },
  {
    id: VehicleHolder.Spouse,
    description: "Spouse"
  },
  {
    id: VehicleHolder.VehicleLeasingCompany,
    description: "Vehicle Leasing Company"
  },
  {
    id: VehicleHolder.Other,
    description: "Other"
  }
] as const;

export type VehicleHolderOption = typeof VEHICLE_HOLDERS[number];

export const VEHICLE_HOLDER_DESCRIPTIONS = VEHICLE_HOLDERS.map(option => option.description);
