import {SerializableAdditionalDriver} from "../../../../quote/vehicle/additional-driver/shared/models/AdditionalDriver";
import {ProposedPolicyAdditionalDriver} from "shared/src/generated/graphql/resolvers-types";
import {parseDurationAsMonths} from "../../../../../utils/service/parsers/DurationToMonthsParser";
import {startOfDay} from "date-fns/fp";
import {fullMonthsBetween, now} from "shared/dist/stdlib/Dates";
import {mapPolicyConvictions} from "./PolicyConvictionsMapper";
import {mapPolicyClaims} from "./PolicyClaimsMapper";
import {formatIsoDateStringFromIsoDateTime} from "shared/dist/stdlib/DateFormat";

export function mapAdditionalDrivers(additionalDrivers: SerializableAdditionalDriver[], mainDriverId: string | undefined): ProposedPolicyAdditionalDriver[] {
  return additionalDrivers.map(additionalDriver => (
    {
      title: additionalDriver.title.vtCode,
      firstName: additionalDriver.firstName,
      lastName: additionalDriver.surname,
      dateOfBirth: formatIsoDateStringFromIsoDateTime(additionalDriver.dateOfBirth),
      gender: additionalDriver.gender.id,
      primaryEmployment: {
        status: additionalDriver.primaryEmployment.employmentStatus.vtCode,
        industry: additionalDriver.primaryEmployment.industry?.description,
        job: additionalDriver.primaryEmployment.job?.description,
      },
      secondaryEmployment: !additionalDriver.hasSecondaryEmployment ? undefined : {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        status: additionalDriver.secondaryEmployment.employmentStatus!.vtCode,
        industry: additionalDriver.secondaryEmployment.industry?.description,
        job: additionalDriver.secondaryEmployment.job?.description,
      },
      ukResidencyDurationMonths: !additionalDriver.hasUkResidencyFromBirth
        ? parseDurationAsMonths(additionalDriver.ukResidencyDuration)
        : fullMonthsBetween(new Date(additionalDriver.dateOfBirth), startOfDay(now())),
      maritalStatus: additionalDriver.maritalStatus.id,
      relationshipToPolicyHolder: additionalDriver.relationshipToProposer.id,
      isMainDriver: additionalDriver.id === mainDriverId,
      licence: {
        countryOfIssue: additionalDriver.licenceCountryOfIssue.id,
        type: additionalDriver.licenceType.id,
        durationMonths: parseDurationAsMonths(additionalDriver.licenceDuration)
      },
      convictions: mapPolicyConvictions(additionalDriver.convictions),
      claims: mapPolicyClaims(additionalDriver.claims),
      hasCancellations: additionalDriver.hasInsuranceDeclined,
      medicalConditionsDeclared: additionalDriver.hasMedicalCondition,
      medicalCondition: additionalDriver.medicalCondition?.id
    }
  ));
}