import {FC, useState} from "react";
import Modal from "shared-components/src/modals/Modal";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import Button from "shared-components/dist/buttons/button/Button";
import {useAppDispatch, useAppSelector} from "../../../../../redux/Hooks";
import {
  selectAvailablePolicyExtras,
  selectPolicyExtras,
  selectRebrokedQuote,
  selectRenewalDepositPercentage,
  selectRenewalQuote
} from "../../redux/RenewalSelectors";
import RadioColumn, {RadioColumnWithGenericProp} from "shared-components/dist/form/radio-column/RadioColumn";
import {PolicyExtraType} from "shared/dist/generated/graphql/resolvers-types";
import {ExcessInsuranceOptionTypes, RenewalPolicyExtras} from "../../models/RenewalPolicyExtras";
import {policyExtrasChanged} from "../../redux/RenewalSlice";
import {loadRenewalProspectiveLoans} from "../../redux/thunks/LoadRenewalProspectiveLoans";
import {usePolicyQueryParams} from "../../../../../router/params/Params";
import "./ExcessProtectionOptionModal.css";
import {upsertRebrokeExtras} from "../../redux/thunks/UpsertRebrokeExtras";

interface Props {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
}

const ExcessInsuranceOptionTypesRadioColumn = RadioColumn as RadioColumnWithGenericProp<ExcessInsuranceOptionTypes>;

const ExcessProtectionOptionModal: FC<Props> = ({isVisible, setIsVisible}) => {
  const {policyId} = usePolicyQueryParams();
  const depositPercentage = useAppSelector(selectRenewalDepositPercentage);
  const dispatch = useAppDispatch();
  const policyExtras = useAppSelector(selectPolicyExtras);
  const availablePolicyExtras = useAppSelector(selectAvailablePolicyExtras);
  const renewalQuote = useAppSelector(selectRenewalQuote);
  const rebrokeQuote = useAppSelector(selectRebrokedQuote);
  const [selectedOption, setSelectedOption] = useState(policyExtras.excessInsurance.type);

  const excessProtectionOptions = availablePolicyExtras.find(extra => extra.type === PolicyExtraType.ExcessInsurance)?.options;
  const excessProtectionOptionTypes = excessProtectionOptions?.map(option => option.optionType) as ExcessInsuranceOptionTypes[];

  const onDismiss = (): void => {
    setSelectedOption(policyExtras.excessInsurance.type);
    setIsVisible(false);
  };

  const onConfirm = (): void => {
    const newPolicyExtras: RenewalPolicyExtras = {
      ...policyExtras,
      excessInsurance: {enabled: true, type: selectedOption}
    };
    dispatch(policyExtrasChanged(newPolicyExtras));

    if (renewalQuote) dispatch(loadRenewalProspectiveLoans({
      policyId,
      depositPercentage,
      policyExtras: newPolicyExtras
    }));
    if (rebrokeQuote && rebrokeQuote.reference && rebrokeQuote.sequenceNumber) {
      dispatch(upsertRebrokeExtras({
        quoteReference: rebrokeQuote.reference,
        quoteSequenceNumber: rebrokeQuote.sequenceNumber,
        policyExtras: newPolicyExtras,
        depositPercentage,
      }));
    }
    setIsVisible(false);
  };

  const priceDifferenceBetweenSelected = (price: number): string => {
    const currentPrice = excessProtectionOptions?.find(option => option.optionType === selectedOption)?.price;
    if (!currentPrice) return " (-£--)";
    if (currentPrice < price) return ` (+£${price - currentPrice})`;
    if (currentPrice > price) return ` (-£${currentPrice - price})`;
    return "";
  };

  return (
    <Modal
      className="excess-protection-option-modal"
      displayCloseButton={false}
      visible={isVisible}
      onDismiss={onDismiss}
      header={<h3>{lookupI18nString("portal.renewals.excessProtectionModal.title")}</h3>}
      body={<>
        <label className="excess-protection-option-modal__description" htmlFor="excess-protection-option-selector">
          {lookupI18nString("portal.renewals.excessProtectionModal.description")}
        </label>

        <ExcessInsuranceOptionTypesRadioColumn
          options={excessProtectionOptionTypes ?? []}
          name="excess-protection-option-selector"
          id="excess-protection-option-selector"
          selectedOption={selectedOption}
          onSelection={setSelectedOption}
        >
          {excessProtectionOptions?.map((option, key) => <div key={key}
                                                              data-testid={`excess-protection-option-modal__option${key}`}>
            {option.description}{priceDifferenceBetweenSelected(option.price)}
          </div>)}
        </ExcessInsuranceOptionTypesRadioColumn>
      </>}
      footer={<>
        <Button
          data-testid="excess-protection-option-modal__confirm"
          onClick={onConfirm}
          expanded
        >
          {lookupI18nString("portal.renewals.excessProtectionModal.confirm")}
        </Button>
        <Button
          data-testid="excess-protection-option-modal__cancel"
          variant="tertiary"
          onClick={onDismiss}
          expanded
        >
          {lookupI18nString("portal.renewals.excessProtectionModal.cancel")}
        </Button>
      </>}
    />
  );
};

export default ExcessProtectionOptionModal;