import React, {FC} from "react";
import Button from "shared-components/dist/buttons/button/Button";
import "./NoClaimsProtectionCard.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import FormattedMessage from "shared-components/src/translations/components/FormattedMessage";
import {NoClaimsDiscountProtectionDataResult, Price} from "shared/src/generated/graphql/resolvers-types";
import {displayLoanValue} from "../../../../../../../utils/service/string/DisplayLoanValue";

export interface NoClaimsProtectionCardProps {
  canProtectNoClaimsBonus: boolean | undefined;
  noClaimsDiscountProtectionData: NoClaimsDiscountProtectionDataResult | undefined;
  protectNoClaimsBonus: boolean | undefined;
  yearsNoClaimsBonus: string | undefined;
  noClaimsDiscountProtectionCost: Price | undefined;
  onInfoClicked: () => void;
  onEditClicked: (() => void) | undefined;
}

const NoClaimsProtectionCard: FC<NoClaimsProtectionCardProps> = ({
  canProtectNoClaimsBonus,
  noClaimsDiscountProtectionData,
  protectNoClaimsBonus,
  yearsNoClaimsBonus,
  noClaimsDiscountProtectionCost,
  onInfoClicked,
  onEditClicked
}: NoClaimsProtectionCardProps) => {
  if (!canProtectNoClaimsBonus || yearsNoClaimsBonus === undefined) return null;

  const noClaimsDiscountPercentage = (): number | undefined => {
    const yearsNoClaims = parseInt(yearsNoClaimsBonus);
    const discounts = noClaimsDiscountProtectionData?.discounts;

    if (!discounts || discounts.length === 0) return undefined;

    return (
      discounts.find(discount => discount.year === yearsNoClaims)
      ?? discounts[discounts.length - 1]
    ).averageDiscount;
  };

  return (
    <div className="no-claims-protection-card" data-testid="no-claims-protection-card">
      <div className="no-claims-protection-card__heading">
        <div>{lookupI18nString("quoteDetails.noClaimsProtectionCard.heading.title")}</div>
        <div data-testid="no-claims-protection-card__included">
          {protectNoClaimsBonus
            ? lookupI18nString({
              id: "quoteDetails.noClaimsProtectionCard.heading.included",
              templateVariables: {price: displayLoanValue(noClaimsDiscountProtectionCost)}
            })
            : lookupI18nString("quoteDetails.noClaimsProtectionCard.heading.notIncluded")
          }
        </div>
      </div>

      <div className="no-claims-protection-card__body">
        {protectNoClaimsBonus &&
          <p data-testid="no-claims-protection-card__price">
            {lookupI18nString({
              id: "quoteDetails.noClaimsProtectionCard.body.includedAtPrice",
              templateVariables: {price: displayLoanValue(noClaimsDiscountProtectionCost)}
            })}
          </p>
        }

        <p data-testid="no-claims-protection-card__why">
          <FormattedMessage
            id={protectNoClaimsBonus
              ? "quoteDetails.noClaimsProtectionCard.body.included.whyProtectYourBonus"
              : "quoteDetails.noClaimsProtectionCard.body.notIncluded.whyProtectYourBonus"
            }
            values={{
              b: (message: string) => <b>{message}</b>,
              discountPercentage: <>{noClaimsDiscountPercentage() ?? "--.--"}</>,
              years: <>{yearsNoClaimsBonus}</>
            }}
          />
        </p>

        <p data-testid="no-claims-protected-card__optional">
          <FormattedMessage
            id={protectNoClaimsBonus
              ? "quoteDetails.noClaimsProtectionCard.body.included.isNCBProtectionOptional"
              : "quoteDetails.noClaimsProtectionCard.body.notIncluded.isNCBProtectionOptional"
            }
            values={{b: (message: string) => <b>{message}</b>}}
          />
        </p>

        <p>
          <FormattedMessage
            id="quoteDetails.noClaimsProtectionCard.body.doesThisProtectMyPriceNextYear"
            values={{b: (message: string) => <b>{message}</b>}}
          />
        </p>

        <Button
          data-testid="no-claims-protection-card__info"
          onClick={onInfoClicked}
          variant="link"
        >
          {lookupI18nString("quoteDetails.noClaimsProtectionCard.body.button")}
        </Button>
      </div>

      {onEditClicked !== undefined &&
        <div className="no-claims-protection-card__footer">
          <Button
            data-testid="no-claims-protection-card__edit"
            expanded
            onClick={onEditClicked}
            variant="ghost"
          >
            {lookupI18nString("quoteDetails.noClaimsProtectionCard.footer.button")}
          </Button>
        </div>
      }
    </div>
  );
};

export default NoClaimsProtectionCard;