import {FC, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {PAY_BY_CARD_PATH} from "../../../../../router/models/Routes";
import {logPaymentGoogleAnalyticsEvent} from "../../../../../utils/analytics/PaymentAnalytics";
import {FullPolicyPaymentResult} from "shared/dist/generated/graphql/resolvers-types";
import {useGooglePayPurchaseRenewal} from "../../payment-processors/google-pay/GooglePayPolicyPaymentProcessor";
import {applePayPurchaseFullRenewalMutation} from "../../payment-processors/apple-pay/ApplePayPolicyPaymentProcessor";
import {useAppSelector} from "../../../../../redux/Hooks";
import AnnualPaymentLanding from "../AnnualPaymentLanding";
import {renewalRequestSelector, totalPolicyCostSelector} from "../../../shared/redux/RenewalRequestSelector";
import {usePolicyQueryParams} from "../../../../../router/params/Params";
import {selectExistingPolicyForRenewal} from "../../../../portal/renewals/redux/RenewalSelectors";
import {policyCanBeRenewedOrRebroked} from "../../../../portal/shared/utils/PolicyStatusUtils";

const RenewalAnnualPaymentLanding: FC = () => {
  const {policyId} = usePolicyQueryParams();
  const existingPolicy = useAppSelector(selectExistingPolicyForRenewal);
  const totalUnfinancedAmountPayable = useAppSelector(totalPolicyCostSelector);
  const proposedRenewalRequest = useAppSelector(renewalRequestSelector(policyId));
  const navigate = useNavigate();

  useEffect(() => {
    if (!policyCanBeRenewedOrRebroked(existingPolicy)) navigate(`/portal/policy/${policyId}/renewals`);
  }, [existingPolicy, navigate, policyId]);

  const {
    makePayment,
    loading,
    paymentFailed,
    resetPaymentFailed
  } = useGooglePayPurchaseRenewal(proposedRenewalRequest);

  const onPaymentWithApplePay = async (payment: ApplePayJS.ApplePayPayment): Promise<FullPolicyPaymentResult> => {
    return await applePayPurchaseFullRenewalMutation(payment, proposedRenewalRequest);
  };

  const onPaymentByCard = (): void => {
    logPaymentGoogleAnalyticsEvent({
      categorySuffix: "annual.type.card",
      action: "Click",
    });
    navigate(PAY_BY_CARD_PATH);
  };

  return <AnnualPaymentLanding
    loading={loading}
    successPagePath={`/portal/policy/${policyId}/renewals/payment/success`}
    totalUnfinancedAmountPayable={totalUnfinancedAmountPayable}
    onPaymentByCard={onPaymentByCard}
    onPaymentWithApplePay={onPaymentWithApplePay}
    onPaymentWithGooglePay={makePayment}
    paymentFailed={paymentFailed}
    resetPaymentFailed={resetPaymentFailed}
  />;
};

export default RenewalAnnualPaymentLanding;