import {buildCrossFieldValidator, SectionQuestionsRecord} from "../../Questions";
import {
  primaryEmploymentIndustryChanged,
  primaryEmploymentStatusChanged,
  primaryEmploymentStatusInvalid,
  primaryEmploymentJobChanged,
  primaryEmploymentWorkedInPastYearChanged
} from "../../../../pages/quote/vehicle/additional-driver/shared/redux/UpdateAdditionalDriverSlice";
import {workingRelationshipValidators} from "./RelationshipToProposer";
import {
  reverseEmploymentRelationshipValidator
} from "../../../../pages/quote/vehicle/additional-driver/shared/components/employment/validators/EmploymentStatusValidators";
export const primaryEmployment = {
  primaryEmploymentStatus: {
    selector: state => state.updateAdditionalDriver.primaryEmployment.employmentStatus,
    onChange: primaryEmploymentStatusChanged,
    onInvalid: primaryEmploymentStatusInvalid,
    crossFieldValidators: [buildCrossFieldValidator(
      state => ({
        proposerEmploymentStatus: state.personalDetails.primaryEmployment.employmentStatus.value?.vtCode,
        relationshipToProposer: state.updateAdditionalDriver.relationshipToProposer.value
      }),
      workingRelationshipValidators.map(reverseEmploymentRelationshipValidator)
    )]
  },
  primaryEmploymentIndustry: {
    selector: state => state.updateAdditionalDriver.primaryEmployment.industry,
    onChange: primaryEmploymentIndustryChanged
  },
  primaryEmploymentJob: {
    selector: state => state.updateAdditionalDriver.primaryEmployment.job,
    onChange: primaryEmploymentJobChanged
  },
  primaryEmploymentWorkedInPastYear: {
    selector: state => state.updateAdditionalDriver.primaryEmployment.workedInPastYear,
    onChange: primaryEmploymentWorkedInPastYearChanged
  },
} as const satisfies SectionQuestionsRecord;