import {MaritalStatus, RelationshipToProposer} from "shared/dist/generated/graphql/resolvers-types";
import {validationFailure, ValidationResult, validationSuccess} from "shared-components/dist/utils/validation/Validator";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {RelationshipToProposerOption} from "../../relationship-to-proposer/model/RelationshipToProposerOption";
import {MaritalStatusOption} from "../../../../../shared/questions/martial-status/MaritalStatusOption";

export const maritalStatusMatchesRelationshipToProposerValidator =
  (relationshipToProposer: RelationshipToProposer | undefined) => (maritalStatus: MaritalStatusOption): ValidationResult =>
    validateRelationshipToProposerAndMaritalStatus(
      relationshipToProposer,
      maritalStatus.id,
      "additionalDriver.maritalStatusQuestion.driverRelationshipStatusInvalid"
    );

export const relationshipToProposerMatchesMaritalStatusValidator =
  (maritalStatus: MaritalStatus | undefined) => (relationshipToProposer: RelationshipToProposerOption): ValidationResult =>
    validateRelationshipToProposerAndMaritalStatus(
      relationshipToProposer.id,
      maritalStatus,
      "additionalDriver.maritalStatusQuestion.driverRelationshipStatusInvalid"
    );

function validateRelationshipToProposerAndMaritalStatus(
  relationshipToProposer: RelationshipToProposer | undefined,
  maritalStatus: MaritalStatus | undefined,
  failureMessage: TranslationKey
): ValidationResult {
  if (!maritalStatus || !relationshipToProposer) return validationSuccess;

  if (relationshipToProposer !== RelationshipToProposer.Spouse &&
    relationshipToProposer !== RelationshipToProposer.CommonLawSpouse &&
    relationshipToProposer !== RelationshipToProposer.PartnerCivil) return validationSuccess;

  if ((relationshipToProposer === RelationshipToProposer.Spouse && maritalStatus === MaritalStatus.Married) ||
    (relationshipToProposer === RelationshipToProposer.CommonLawSpouse && maritalStatus === MaritalStatus.MarriedCommonLaw) ||
    (relationshipToProposer === RelationshipToProposer.PartnerCivil && maritalStatus === MaritalStatus.CivilPartnership)) return validationSuccess;

  return validationFailure(failureMessage);
}
