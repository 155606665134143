import {DateOption} from "shared/dist/models/date-option/DateOption";
import {addYears, differenceInDays, isAfter, isBefore, startOfDay, subYears} from "date-fns/fp";
import {now} from "shared/dist/stdlib/Dates";
import {startDateOptions} from "../../../../../your-cover/components/cover-start-date/helpers/StartDateOptions";

export function startDateOptionsByDateOfBirth(dateOfBirth: Date | undefined): DateOption[] {
  if (!dateOfBirth) return startDateOptions();

  if (isTurningSeventeenWithinThirtyDays(dateOfBirth)) {
    return startDateOptions().filter(option => coverStartDatesFromSeventeenthBirthday(dateOfBirth, option));
  } else if (isTurningNinetyFiveWithinThirtyDays(dateOfBirth)) {
    return startDateOptions().filter(option => coverStartDatesToNinetyFifthBirthday(dateOfBirth, option));
  } else {
    return startDateOptions();
  }
}

const isTurningSeventeenWithinThirtyDays = (dateOfBirth: Date): boolean => {
  const seventeenYearsAgo = subYears(17, startOfDay(now()));

  return differenceInDays(seventeenYearsAgo, dateOfBirth) > 0
    && differenceInDays(seventeenYearsAgo, dateOfBirth) <= 30;
};

const coverStartDatesFromSeventeenthBirthday = (dateOfBirth: Date, {date}: DateOption): boolean => {
  const seventeenthBirthday = startOfDay(addYears(17, dateOfBirth));

  return isAfter(seventeenthBirthday, date);
};

const isTurningNinetyFiveWithinThirtyDays = (dateOfBirth: Date): boolean => {
  const ninetyFiveYearsAgo = subYears(95, startOfDay(now()));

  return differenceInDays(ninetyFiveYearsAgo, dateOfBirth) > 0
    && differenceInDays(ninetyFiveYearsAgo, dateOfBirth) <= 30;
};

const coverStartDatesToNinetyFifthBirthday = (dateOfBirth: Date, {date}: DateOption): boolean => {
  const ninetyFifthBirthday = addYears(95, dateOfBirth);

  return isBefore(ninetyFifthBirthday, date);
};