import {PolicyHolder, PolicyList} from "shared/dist/generated/graphql/resolvers-types";
import {ReminderType} from "../reminders/thunk/ReminderType";

export enum PortalLoadingStatus {
  NOT_STARTED = "NOT_STARTED",
  LOADING = "LOADING",
  FAILED = "FAILED",
  SUCCESS = "SUCCESS"
}

export interface PortalDetails {
  policyHolder: PolicyHolder | undefined;
  policyList: PolicyList | undefined;
  portalLoadingStatus: PortalLoadingStatus;
  visibleReminderTypes: ReminderType[] | undefined;
  documentUploaded: boolean;
}