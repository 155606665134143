import {ConvictionTypeItem} from "shared/dist/generated/graphql/resolvers-types";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";

export const DRIVING_WHILE_USING_MOBILE_PHONE_PARENT_CATEGORY = "MP";

export const DRIVING_WHILE_USING_MOBILE_PHONE_CONVICTION_TYPE: ConvictionTypeItem = {
  id: "driving-while-using-mobile-phone-id",
  parentCategory: DRIVING_WHILE_USING_MOBILE_PHONE_PARENT_CATEGORY,
  parentCategoryDescription: lookupI18nString("personalDetails.convictionsQuestions.convictionType.drivingWhileUsingMobilePhone")
};

export function isMobilePhoneConvictionType(parentCategory: string | undefined): boolean {
  return parentCategory === DRIVING_WHILE_USING_MOBILE_PHONE_PARENT_CATEGORY;
}
