import React, {FC, ReactElement, useEffect} from "react";
import "./Modal.scss";
import {Close} from "@mui/icons-material";
import FocusTrap from "focus-trap-react";
import classNames from "classnames";

interface Props {
  visible: boolean | (() => boolean);
  className?: string;
  header?: ReactElement | ReactElement[];
  body?: ReactElement | ReactElement[];
  footer?: ReactElement | ReactElement[];
  displayCloseButton?: boolean;
  onDismiss?: () => void;
  isFocusTrapPaused?: boolean;
}

const Modal: FC<Props> = (props) => {
  if (!props.visible) return null;

  return (
    <FocusTrap
      paused={props.isFocusTrapPaused}
      focusTrapOptions={{
        allowOutsideClick: true,
        tabbableOptions: {
          displayCheck: "none"
        }
      }}
    >
      <div className={classNames("modal", props.className)}>
        <ModalContent {...props}/>
      </div>
    </FocusTrap>
  );
};

const ModalContent: FC<Props> = (
  {
    className,
    header,
    body,
    footer,
    displayCloseButton,
    onDismiss,
  }
) => {
  useEffect(() => {
    const handleEscapeKeyPress = (event: KeyboardEvent): void => {
      // event.key === "Esc" provided for compatibility with Edge/IE
      if (!onDismiss) return;
      if (event.key === "Escape" || event.key === "Esc") onDismiss();
    };

    document.body.style.overflow = "hidden";
    window.addEventListener("keydown", handleEscapeKeyPress);

    return () => {
      document.body.style.overflow = "auto";
      window.removeEventListener("keydown", handleEscapeKeyPress);
    };
  }, [onDismiss]);

  return (
    <div className={`modal__content ${className ? `${className}__content` : ""}`}>
      <div className={`modal__content__header ${className ? `${className}__header` : ""}`}>
        {header}
        {displayCloseButton &&
          <div className={`modal__content__dismiss-modal ${className ? `${className}__dismiss-modal` : ""}`}>
            <button
              onClick={onDismiss}
              className={`modal__content__dismiss-modal__button ${className ? `${className}__dismiss-modal__button` : ""}`}
            >
              <Close/>
            </button>
          </div>
        }
      </div>
      <div className={`modal__content__body ${className ? `${className}__body` : ""}`}>
        {body}
      </div>
      <div className={`modal__content__footer ${className ? `${className}__footer` : ""}`}>
        {footer}
      </div>
    </div>
  );
};

export default Modal;
