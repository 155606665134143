import React, {FC} from "react";
import PolicyExtrasList from "./components/policy-extras-list/PolicyExtrasList";
import AccordionList from "shared-components/dist/lists/accordian-list/AccordionList";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./PolicyExtrasSelection.css";
import {usePolicyExtrasListItemProps} from "./hooks/UsePolicyExtrasListItemProps";
import Spacer from "shared-components/dist/spacer/Spacer";
import {useAppSelector} from "../../../../../../../redux/Hooks";

const PolicyExtrasSelection: FC = () => {
  const quoteLoading = useAppSelector(state => state.quoteDetails.quoteLoading);
  const policyStartDate = useAppSelector(state => state.coverDetails.coverStartDate.value?.date);
  const {loading: policyExtrasLoading, policyExtraListItems} = usePolicyExtrasListItemProps(policyStartDate);

  if (quoteLoading || policyExtrasLoading) return <div>Loading policy extras...</div>;

  return (
    <div className="policy-extras-selection-accordion">
      <Spacer/>
      <AccordionList
        items={[{
          title: lookupI18nString("quoteDetails.policyExtrasQuestion.title"),
          contents: <PolicyExtrasList items={policyExtraListItems}/>
        }]}
        defaultOpenIndexes={[0]}
      />
    </div>
  );
};

export default PolicyExtrasSelection;
