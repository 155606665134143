import {ReduxQuestion} from "../../Questions";
import {FormField} from "shared-components/dist/models/form-field/FormField";
import {
  previouslyHeldInsuranceChanged,
  PreviouslyHeldInsurancePayload
} from "../../../../pages/quote/vehicle/your-cover/redux/CoverDetailsSlice";

export const previouslyHeldInsurance: ReduxQuestion<
  boolean,
  FormField<boolean>,
  PreviouslyHeldInsurancePayload
> = {
  selector: state => state.coverDetails.previouslyHeldInsurance,
  onChange: previouslyHeldInsuranceChanged
};