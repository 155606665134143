import {CustomisedAbiListItem} from "shared-components/dist/models/CustomisedAbiListItem";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {WhichPolicyClaimedAgainst} from "shared/dist/generated/graphql/resolvers-types";

export const CLAIMED_POLICIES = [
  {
    vtCode: WhichPolicyClaimedAgainst.Own,
    description: lookupI18nString("structure.questions.claims.incidentPolicy.own")
  },
  {
    vtCode: WhichPolicyClaimedAgainst.Company,
    description: lookupI18nString("structure.questions.claims.incidentPolicy.company")
  },
  {
    vtCode: WhichPolicyClaimedAgainst.Other,
    description: lookupI18nString("structure.questions.claims.incidentPolicy.other")
  },
  {
    vtCode: WhichPolicyClaimedAgainst.Proposers,
    description: lookupI18nString("structure.questions.claims.incidentPolicy.proposers")
  },
] as const;

export type WhichPolicyClaimedAgainstOption = CustomisedAbiListItem<WhichPolicyClaimedAgainst>;

export const PROPOSER_CLAIMED_POLICY_DESCRIPTIONS = CLAIMED_POLICIES
  .filter(policy => policy.vtCode !== WhichPolicyClaimedAgainst.Proposers)
  .map(policy => policy.description);
export const PROPOSER_CLAIMED_POLICY_OPTIONS = CLAIMED_POLICIES
  .filter(policy => policy.vtCode !== WhichPolicyClaimedAgainst.Proposers)
  .map(policy => policy.vtCode);

export const ADDITIONAL_DRIVER_CLAIMED_POLICY_DESCRIPTIONS = CLAIMED_POLICIES.map(policy => policy.description);
export const ADDITIONAL_DRIVER_CLAIMED_POLICY_OPTIONS = CLAIMED_POLICIES.map(policy => policy.vtCode);
