import InlineQuestion from "../../../../../structure/questions/inline-question/InlineQuestion";
import React, {FC} from "react";

export const cardSecurityId = "global-pay-card-security-input";

const CardSecurityNumber: FC = () => (
  <InlineQuestion
    id={cardSecurityId}
    label="paymentFlow.byCard.form.cardDetails.cvv"
  >
    <div id={cardSecurityId} className="card-details__cvv"/>
  </InlineQuestion>
);

export default CardSecurityNumber;