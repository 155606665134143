import {FC} from "react";
import {useEffectOnFirstRender} from "shared-components/dist/utils/hooks/UseEffectOnFirstRender";
import ErrorPage from "../../../shared/error/ErrorPage";
import Navbar from "../../../../../structure/navigation/navbar/Navbar";
import {noticeError} from "../../../../../newrelic/NoticeError";

const RenewalSummaryError: FC = () => {
  useEffectOnFirstRender(() => {
    noticeError(new Error("Error occurred loading renewal summary."));
  });

  return (
    <ErrorPage
      title="portal.renewals.summary.error.title"
      description="portal.renewals.summary.error.description"
      header={<Navbar/>}
    />
  );
};

export default RenewalSummaryError;
