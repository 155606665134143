import {PolicyExtraOptionType, ProspectivePolicyExtraOption} from "shared/dist/generated/graphql/resolvers-types";
import {parseLastCharacterAsNumber} from "../../../../../../../../utils/service/parsers/LastCharacterAsNumberParser";

export function getDefaultExcessInsurance(
  options: ProspectivePolicyExtraOption[],
  policyExcess: number
): ProspectivePolicyExtraOption | undefined {
  if (policyExcess > 1000) return getPolicyExtraOption(options, PolicyExtraOptionType.ExcessInsurance5);
  if (policyExcess > 750) return getPolicyExtraOption(options, PolicyExtraOptionType.ExcessInsurance4);
  if (policyExcess > 500) return getPolicyExtraOption(options, PolicyExtraOptionType.ExcessInsurance3);
  if (policyExcess > 350) return getPolicyExtraOption(options, PolicyExtraOptionType.ExcessInsurance2);

  return getPolicyExtraOption(options, PolicyExtraOptionType.ExcessInsurance1);
}

function getPolicyExtraOption(
  options: ProspectivePolicyExtraOption[],
  optionType: PolicyExtraOptionType
): ProspectivePolicyExtraOption | undefined {
  return options.find(option => option.optionType === optionType);
}

export function isSelectionBelowDefault(
  defaultOption: ProspectivePolicyExtraOption,
  selectedOption?: ProspectivePolicyExtraOption
): boolean {
  if (!selectedOption) return false;

  return parseLastCharacterAsNumber(selectedOption.optionType) < parseLastCharacterAsNumber(defaultOption.optionType);
}