import React, {FC} from "react";
import {Status} from "shared-components/dist/status-indicator/Status";
import {ConvictionOffenceItem} from "shared/dist/generated/graphql/resolvers-types";
import {Conviction} from "../../models/Conviction";
import ConvictionOffenceSubQuestionDropdown from "./ConvictionOffenceSubQuestionDropdown";
import {isMobilePhoneConvictionType} from "../../validation/IsMobilePhoneConvictionType";

interface Props {
  conviction: Partial<Conviction>;
  onChange: (value: ConvictionOffenceItem | undefined) => void;
  status: Status;
  isAdditionalDriverForm: boolean;
}

const ConvictionOffenceSubQuestion: FC<Props> = (
  {
    conviction,
    onChange,
    status,
    isAdditionalDriverForm
  }
) => {
  if (!conviction.type || isMobilePhoneConvictionType(conviction.type.parentCategory)) return null;

  return (
    <ConvictionOffenceSubQuestionDropdown
      onChange={onChange}
      status={status}
      value={conviction.offence}
      typeId={conviction.type.id}
      isAdditionalDriverForm={isAdditionalDriverForm}
    />
  );
};

export default ConvictionOffenceSubQuestion;
