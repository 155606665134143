import {TranslationId, TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {toGregorianFormat} from "shared/dist/stdlib/DateFormat";

export const buildPolicyExpiryWarningTooltip = (tooltip: TranslationId, date: Date | undefined): TranslationKey => {
  if (!date) return tooltip;

  return {
    id: tooltip,
    templateVariables: {
      vehiclePolicyExpiryDate: toGregorianFormat(date)
    }
  };
};