import React, {FC} from "react";
import "./DepositAmountCard.css";
import {CurrencyFormat} from "../../../../../utils/money/CurrencyFormat";
import {Price} from "shared/dist/generated/graphql/resolvers-types";

interface Props {
  depositPaymentAmount: Price;
}

const DepositAmountCard: FC<Props> = ({depositPaymentAmount}) => {
  return (
    <div className="deposit-amount-card">
      {CurrencyFormat.formatPriceWithSymbol(depositPaymentAmount)}
    </div>
  );
};

export default DepositAmountCard;
