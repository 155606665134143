import React, {FC} from "react";

import InputField from "shared-components/dist/form/input-field/InputField";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {
  isValidRegistrationNumberCharacter,
  MAX_REG_NUMBER_LENGTH
} from "../../../../../../../utils/validation/RegistrationNumber";

interface Props {
  registrationNumber?: string;
  onChange: (value: string) => void;
  id?: string;
  placeholder?: TranslationKey;
  loading?: boolean;
}

const RegistrationNumberInputField: FC<Props> = (
  {
    registrationNumber,
    onChange,
    id,
    placeholder,
    loading = false
  }
) => (
  <InputField
    value={registrationNumber}
    placeholder={placeholder}
    className="uppercase"
    maxLength={MAX_REG_NUMBER_LENGTH}
    changeIf={[isValidRegistrationNumberCharacter]}
    onValueChange={onChange}
    data-test-id="reg-number-input-field"
    id={id}
    loading={loading}
  />
);

export default RegistrationNumberInputField;
