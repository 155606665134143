import React, {FC} from "react";
import "./SpinnerIcon.scss";

interface Props {
  className?: string;
}

const SpinnerIcon: FC<Props> = ({className}) => (
  <div className={`spinner-icon${className ? ` ${className}`: ""}`}/>
);

export default SpinnerIcon;