import React, {FC} from "react";
import {mapValueToYesOrNoEventType} from "shared-components/dist/analytics/GoogleAnalyticsEventsService";
import {logYourCoverGoogleAnalyticsEvent} from "../../../../../../../../utils/analytics/MotorAnalytics";
import BooleanQuestion
  from "../../../../../../../../structure/questions/question/variants/boolean-question/BooleanQuestion";
import {hasVehicleBeenInsuredChanged} from "../../../../redux/CoverDetailsSlice";
import {useAppDispatch, useAppSelector} from "../../../../../../../../redux/Hooks";
import {
  selectDurationSinceVehiclePurchase
} from "../../../../redux/selectors/named-driver/DurationSinceVehiclePurchaseSelector";
import {getIdFromTranslationKey, TranslationKey} from "shared-components/dist/translations/TranslationKey";

const HasVehicleBeenInsuredQuestion: FC = () => {
  const hasVehicleBeenInsured = useAppSelector(state => state.coverDetails.hasVehicleBeenInsured);
  const durationSinceVehiclePurchase = useAppSelector(selectDurationSinceVehiclePurchase);
  const dispatch = useAppDispatch();

  const onAnswer = (value: boolean): void => {
    logYourCoverGoogleAnalyticsEvent({
      categorySuffix: "vehicle_been_insured",
      action: mapValueToYesOrNoEventType(value),
    });

    dispatch(hasVehicleBeenInsuredChanged(value));
  };

  return (
    <BooleanQuestion
      id="has-vehicle-been-insured-question"
      title={getTitle(durationSinceVehiclePurchase)}
      formField={hasVehicleBeenInsured}
      onAnswer={onAnswer}
      hideSpacer
    />
  );
};

export default HasVehicleBeenInsuredQuestion;

function getTitle(durationSinceVehiclePurchase: Duration): TranslationKey {
  if (durationSinceVehiclePurchase.years) {
    if (durationSinceVehiclePurchase.years === 1) {
      return getIdFromTranslationKey("coverDetails.hasVehicleBeenInsuredQuestion.title.oneYearOnly");
    }
    return {
      id: "coverDetails.hasVehicleBeenInsuredQuestion.title.yearsOnly",
      templateVariables: {
        years: durationSinceVehiclePurchase.years
      }
    };
  }

  if (durationSinceVehiclePurchase.months) {
    if (durationSinceVehiclePurchase.months === 1) {
      return getIdFromTranslationKey("coverDetails.hasVehicleBeenInsuredQuestion.title.oneMonthOnly");
    }
    return {
      id: "coverDetails.hasVehicleBeenInsuredQuestion.title.monthsOnly",
      templateVariables: {
        months: durationSinceVehiclePurchase.months
      }
    };
  }

  if (durationSinceVehiclePurchase.days === 1) {
    return getIdFromTranslationKey("coverDetails.hasVehicleBeenInsuredQuestion.title.oneDayOnly");
  }
  return {
    id: "coverDetails.hasVehicleBeenInsuredQuestion.title.daysOnly",
    templateVariables: {
      days: durationSinceVehiclePurchase.days ?? 0
    }
  };
}