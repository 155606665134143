import React, {FC, useState} from "react";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import LogoutModal from "../../../../pages/portal/policies/header/logout-modal/LogoutModal";
import Button from "shared-components/dist/buttons/button/Button";
import {
  EventCategoryPrefix,
  logGoogleAnalyticsEvent
} from "shared-components/dist/analytics/GoogleAnalyticsEventsService";

interface Props {
  googleAnalyticsPrefix: EventCategoryPrefix;
}

const LogoutButton: FC<Props> = ({googleAnalyticsPrefix}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const onLogoutButtonClick = (): void => {
    setModalOpen(true);
    logGoogleAnalyticsEvent({
      categoryPrefix: googleAnalyticsPrefix,
      categorySuffix: "navigation.logout_button",
      action: "Click"
    });
  };

  return (
    <>
      <div className="logout-button">
        <Button small onClick={onLogoutButtonClick}>
          {lookupI18nString("navbar.logout")}
        </Button>
      </div>
      <LogoutModal
        visible={modalOpen}
        onDismiss={() => setModalOpen(false)}
        googleAnalyticsPrefix={googleAnalyticsPrefix}
      />
    </>
  );
};

export default LogoutButton;
