import React, {FC} from "react";
import Spacer from "shared-components/dist/spacer/Spacer";
import AccordionList from "shared-components/dist/lists/accordian-list/AccordionList";
import {addYears} from "date-fns";
import DocumentList from "../../../../shared/document-list/DocumentList";
import "./ArchivedDocuments.css";
import {groupPolicyDocumentsByPolicyDate} from "shared-components/dist/utils/helpers/GroupPolicyDocuments";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {formatOrdinalDateStringFromIsoString} from "shared/dist/stdlib/DateFormat";

import {Policy} from "shared/dist/generated/graphql/resolvers-types";

interface Props {
  policy: Policy;
}

const ArchivedDocuments: FC<Props> = ({policy}) => {
  if (!policy.documentation || !policy.documentation.archived) return null;
  if (policy.documentation.archived.length === 0) return null;

  const groupedDocuments = groupPolicyDocumentsByPolicyDate(policy.documentation.archived, policy?.coverDetails.startDate);

  return (
    <div className="archived-documents">
      <Spacer/>
      <h2 className="archived-documents__title">{lookupI18nString("policies.archivedDocuments.title")}</h2>

      <AccordionList
        items={groupedDocuments.map(archivedDocument => ({
          title: `
            ${formatOrdinalDateStringFromIsoString(archivedDocument.date)} - 
            ${formatOrdinalDateStringFromIsoString(addYears(new Date(archivedDocument.date), 1).toISOString())}
          `,
          contents: (
            <DocumentList documents={archivedDocument.documents} showDate/>
          )
        }))}
        defaultOpenIndexes={[0]}
      />
    </div>
  );
};

export default ArchivedDocuments;
