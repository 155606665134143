import {CompanyCarExperience, NamedDriverExperience, QuoteCover} from "shared/dist/generated/graphql/resolvers-types";
import {
  appliedNoClaimsBonusSelector,
  breakInCoverDeclarationSelector,
  companyCarDeclarationAcceptanceSelector,
  companyCarPolicyExpiryDateAsDateSelector,
  coverStartDateAsDateOptionSelector,
  namedDriverDeclarationAcceptanceSelector,
  periodBetweenInsuranceSelector,
  yearsCompanyCarBonusValueSelector,
  yearsNamedDriverValueSelector
} from "../../../../../pages/quote/vehicle/your-cover/redux/selectors/CoverDetailsSelectors";
import {RootState} from "../../../../../redux/Store";
import {SerializableDateOption} from "shared/dist/models/date-option/DateOption";
import {now} from "shared/dist/stdlib/Dates";
import {
  maxYearsForAXAEligibilitySelector
} from "../../../../../pages/quote/vehicle/your-cover/redux/selectors/named-driver/IsNamedDriverAXAEligible";
import {
  isCompanyCarBonusValid
} from "../../../../../pages/quote/vehicle/your-cover/redux/selectors/helpers/IsCompanyCarBonusValid";

export function mapCoverDetailsToGraphql(rootState: RootState): QuoteCover {
  const coverDetails = rootState.coverDetails;

  const errorPrefix = "Unable to determine valid cover details to run quote for:";
  const errorSuffix = ` is undefined${rootState.quoteDetails.quoteReference ? ` for quote reference [${rootState.quoteDetails.quoteReference}]`: ""}`;
  if (!coverDetails.coverStartDate.value) throw Error(`${errorPrefix} cover start date${errorSuffix}`);
  if (!coverDetails.coverType.value?.vtCode) throw Error(`${errorPrefix} cover type${errorSuffix}`);
  if (coverDetails.averageMileage.value === undefined) throw Error(`${errorPrefix} average mileage${errorSuffix}`);
  if (coverDetails.voluntaryExcess.value === undefined) throw Error(`${errorPrefix} voluntary excess${errorSuffix}`);

  const startDate = getCoverStartDate(coverDetails.coverStartDate.value);
  return {
    startDate,
    coverType: coverDetails.coverType.value.vtCode,
    previouslyHeldInsurance: coverDetails.previouslyHeldInsurance.value ?? false,
    yearsNoClaimsBonus: appliedNoClaimsBonusSelector(rootState),
    protectNoClaimsBonus: coverDetails.protectNoClaimsBonus.value ?? false,
    mileage: coverDetails.averageMileage.value,
    businessMileage: coverDetails.averageBusinessMileage.value,
    voluntaryExcess: parseInt(coverDetails.voluntaryExcess.value),
    periodBetweenInsurance: periodBetweenInsuranceSelector(rootState),
    namedDriverExperience: getNamedDriverExperience(rootState),
    companyCarExperience: getCompanyCarExperience(rootState),
    breakInCoverDeclaration: breakInCoverDeclarationSelector(rootState)
  };
}

const getCoverStartDate = (selectedCoverStartDate: SerializableDateOption): string =>
  selectedCoverStartDate.description === "Today" ? now().toISOString() : selectedCoverStartDate.date;

function getNamedDriverExperience(rootState: RootState): NamedDriverExperience | undefined {
  const yearsNamedDriver = yearsNamedDriverValueSelector(rootState);
  const namedDriverDeclarationAcceptance = namedDriverDeclarationAcceptanceSelector(rootState);

  if (yearsNamedDriver === undefined
    || yearsNamedDriver === 0
    || namedDriverDeclarationAcceptance.value !== true) return undefined;

  const yearsNoClaimsBonus = getEligibleYears(yearsNamedDriver, rootState);
  if (!yearsNoClaimsBonus) return undefined;

  return {yearsNoClaimsBonus};
}

function getCompanyCarExperience(rootState: RootState): CompanyCarExperience | undefined {
  const yearsCompanyCarBonus = yearsCompanyCarBonusValueSelector(rootState);
  const companyCarExperienceEndDate = companyCarPolicyExpiryDateAsDateSelector(rootState).value;
  const companyCarDeclarationAcceptance = companyCarDeclarationAcceptanceSelector(rootState);
  const coverStartDate = coverStartDateAsDateOptionSelector(rootState).value?.date;

  if (yearsCompanyCarBonus === undefined
    || companyCarExperienceEndDate === undefined
    || companyCarDeclarationAcceptance.value !== true
    || !isCompanyCarExperienceEndDateValid(companyCarExperienceEndDate, coverStartDate)
  ) return undefined;

  const yearsNoClaimsBonus = getEligibleYears(yearsCompanyCarBonus, rootState);
  if (!yearsNoClaimsBonus) return undefined;

  return {
    yearsNoClaimsBonus,
    companyCarExperienceEndDate: companyCarExperienceEndDate.toISOString()
  };
}

function isCompanyCarExperienceEndDateValid(experienceEndDate: Date | undefined, coverStartDate: Date | undefined): boolean {
  if (!experienceEndDate || !coverStartDate) return false;

  return isCompanyCarBonusValid(coverStartDate, experienceEndDate);
}

function getEligibleYears(yearsSelected: number, rootState: RootState): number | undefined {
  const yearsAXAEligible = maxYearsForAXAEligibilitySelector(rootState);

  if (yearsAXAEligible === 0) return undefined;
  return yearsSelected >= yearsAXAEligible ? yearsAXAEligible : yearsSelected;
}
