import React, {FC} from "react";
import {prohibitedMainDriverStopVisibleSelector} from "../../redux/selectors/ProhibitedMainDriverStopVisibleSelector";
import QuoteJourneyStop from "../../../shared/stops/quote-journey-stop/QuoteJourneyStop";
import {useAppSelector} from "../../../../../../redux/Hooks";

const ProhibitedMainDriverStop: FC = () => {
  const isVisible = useAppSelector(prohibitedMainDriverStopVisibleSelector);

  return (
    <QuoteJourneyStop
      stopProps={{
        visible: isVisible,
        title: "quoteJourney.shared.mainDriverQuestion.stop.title",
        description: "quoteJourney.shared.softStop.description"
      }}
    />
  );
};

export default ProhibitedMainDriverStop;