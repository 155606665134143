import React, {FC} from "react";
import "./RenewalDueSoon.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import FormattedMessage from "shared-components/src/translations/components/FormattedMessage";
import PlainCard from "shared-components/dist/cards/plain-card/PlainCard";
import {toGregorianFormat} from "shared/dist/stdlib/DateFormat";

interface Props {
  renewalDate: Date
}

const RenewalDueSoon: FC<Props> = ({renewalDate}) => {
  return (
    <PlainCard>
      <div className="renewal-due-soon">
        <h1 data-testid="renewal-due-soon__title" className="renewal-due-soon__title">
          <FormattedMessage
            id="renewal.holdingPage.renewalDue"
            values={{
              b: (msg: string) => <b>{msg}</b>,
              renewalDate: toGregorianFormat(renewalDate)
            }}
          />
        </h1>

        <h2 className="renewal-due-soon__subtitle">
          {lookupI18nString("renewal.holdingPage.checkBack")}
        </h2>

        <p className="renewal-due-soon__description">
          {lookupI18nString("renewal.holdingPage.weWillContactYou")}
        </p>
      </div>
    </PlainCard>
  );
};

export default RenewalDueSoon;