import {Maybe, PolicyExtraOptionType, PolicyExtraType} from "shared/dist/generated/graphql/resolvers-types";
import {TranslationId} from "shared-components/dist/translations/TranslationKey";

export const getPolicyExtraDescriptionKeyFrom = (policyExtraType: PolicyExtraType): TranslationId => {
  switch (policyExtraType) {
    case PolicyExtraType.SmartfobKeycare: return "policies.extra.smartfobKeycare.description";
    case PolicyExtraType.CourtesyCar: return "policies.extra.replacementCar.description";
    case PolicyExtraType.ExcessInsurance: return "policies.extra.excessInsurance.description";
    case PolicyExtraType.RoadsideAssistancePlatinum: return "policies.extra.roadsideAssistancePlatinum.description";
    case PolicyExtraType.LegalExpenses: return "policies.extra.legalExpenses.description";
  }
};

export const getPolicyExtraNameKeyFrom = (policyExtraType: PolicyExtraType): TranslationId => {
  switch (policyExtraType) {
    case PolicyExtraType.SmartfobKeycare: return "policies.mainContent.policyExtras.smartfobKeycare.title";
    case PolicyExtraType.CourtesyCar: return "policies.mainContent.policyExtras.replacementCar.title";
    case PolicyExtraType.ExcessInsurance: return "policies.mainContent.policyExtras.excessInsurance.title";
    case PolicyExtraType.RoadsideAssistancePlatinum: return "policies.mainContent.policyExtras.roadsideAssistancePlatinum.title";
    case PolicyExtraType.LegalExpenses: return "policies.mainContent.policyExtras.legalExpenses.title";
  }
};

export const getPolicyExtraOptionDescriptionKeyFrom = (policyExtraOptionType: Maybe<PolicyExtraOptionType> | undefined): TranslationId | undefined => {
  switch (policyExtraOptionType) {
    case PolicyExtraOptionType.ExcessInsurance1: return "policies.extra.excess.insurance.option.type1.description";
    case PolicyExtraOptionType.ExcessInsurance2: return "policies.extra.excess.insurance.option.type2.description";
    case PolicyExtraOptionType.ExcessInsurance3: return "policies.extra.excess.insurance.option.type3.description";
    case PolicyExtraOptionType.ExcessInsurance4: return "policies.extra.excess.insurance.option.type4.description";
    case PolicyExtraOptionType.ExcessInsurance5: return "policies.extra.excess.insurance.option.type5.description";
    default: return undefined;
  }
};
