import React, {FC, useState} from "react";
import MultipleItemsQuestion from "../../../../../../structure/questions/multiple-items-question/MultipleItemsQuestion";
import ConvictionsSubQuestionForm from "./components/ConvictionsSubQuestionForm";
import {Conviction, Convictions} from "./models/Conviction";
import {RequiredFormField} from "shared-components/dist/models/form-field/FormField";
import ConvictionItem from "./components/ConvictionItem";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import TooManyConvictionsStop from "./components/stops/TooManyConvictionsStop";
import {Status} from "shared-components/dist/status-indicator/Status";
import {logger} from "../../../../../../utils/logging/Logger";
import {scrollToElement} from "../../../../../../utils/navigation/ScrollToHelpers";

interface OwnProps {
  id: string;
  title: TranslationKey;
  description?: TranslationKey;
  descriptionListItems?: TranslationKey[];
  tooltip?: TooltipProps;
  convictions: RequiredFormField<Convictions>;
  shouldFlagUnansweredQuestions: boolean;
  flagUnansweredSubQuestions: () => void;
  isFormVisible: boolean;
  hasConvictionsChanged: (hasConvictions: boolean) => void;
  convictionAdded: (conviction: Conviction) => void;
  convictionRemoved: (index: number) => void;
  isFormVisibleSet: (value: boolean) => void;
  isAdditionalDriverForm?: boolean;
  convictionStatusChanged: (status: Status) => void;
}

const ConvictionsQuestion: FC<OwnProps> = (
  {
    id,
    title,
    description,
    descriptionListItems,
    tooltip,
    convictions,
    hasConvictionsChanged,
    convictionAdded,
    convictionRemoved,
    shouldFlagUnansweredQuestions,
    flagUnansweredSubQuestions,
    isFormVisible,
    isFormVisibleSet,
    isAdditionalDriverForm = false,
    convictionStatusChanged
  }
) => {
  const [isTooManyConvictionsStop, setIsTooManyConvictionsStop] = useState(false);

  const mapConvictionsToItems = (): JSX.Element[] => (
    convictions.value.convictions.map((conviction, index) => (
      <ConvictionItem key={index} conviction={conviction}/>
    ))
  );

  const onHasConvictionsChanged = (hasConvictions: boolean): void => {
    hasConvictionsChanged(hasConvictions);
    setIsTooManyConvictionsStop(false);
  };

  const removeItem = (index: number): void => {
    if (convictions.value.convictions.length - 1 < index) {
      logger.warn("Tried to remove conviction that doesn't exist");
      return;
    }

    setIsTooManyConvictionsStop(false);
    convictionRemoved(index);
  };

  return (
    <>
      <MultipleItemsQuestion
        id={id}
        name="convictions-form-with-multiple-items"
        title={title}
        description={description}
        descriptionListItems={descriptionListItems}
        tooltip={tooltip}
        form={
          <ConvictionsSubQuestionForm
            convictionAdded={(conviction) => {
              convictionAdded(conviction);
              scrollToElement(id);
            }}
            shouldFlagUnansweredQuestions={shouldFlagUnansweredQuestions}
            flagUnansweredQuestions={flagUnansweredSubQuestions}
            isAdditionalDriverForm={isAdditionalDriverForm}
          />
        }
        items={mapConvictionsToItems()}
        confirmation={convictions.value.hasConvictions}
        setConfirmation={onHasConvictionsChanged}
        itemRemoved={removeItem}
        error={convictions.errorMessage}
        status={convictions.status}
        isFormVisible={isFormVisible}
        setIsFormVisible={isFormVisibleSet}
        maxItems={5}
        onMaxItems={() => setIsTooManyConvictionsStop(true)}
        onCancel={() => convictionStatusChanged("success")}
        disableSubmitItemWhenFormIsInvalid={false}
      />
      <TooManyConvictionsStop
        isVisible={isTooManyConvictionsStop}
        setIsVisible={setIsTooManyConvictionsStop}
        isAdditionalDriverForm={isAdditionalDriverForm}
      />
    </>
  );
};

export default ConvictionsQuestion;
