import {removeAll} from "shared-components/dist/utils/stdlib/StringUtils";

export function redactPhoneNumber(phoneNumber: string): string {
  if (phoneNumber.length === 0) return "";
  if (phoneNumber.length < 4) return phoneNumber;

  const phoneNumberWithoutSpaces = removeAll(phoneNumber, " ");

  const halfPhoneNumberLength = phoneNumberWithoutSpaces.length / 2;
  const startCharacters = phoneNumberWithoutSpaces.slice(0, halfPhoneNumberLength - 2);
  const endCharacters = phoneNumberWithoutSpaces.slice(-2);
  const stars = "*".repeat(phoneNumberWithoutSpaces.length - (startCharacters.length + endCharacters.length));
  return `${startCharacters}${stars}${endCharacters}`;
}