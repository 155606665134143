import {apolloClient} from "../../apollo/ApolloClientProvider";
import {
  PORTAL_CONFIRM_DETAILS_QUERY,
  PortalConfirmDetailsResult
} from "shared/dist/graphql/queries/portal/ConfirmDetailsQuery";

export async function loadRenewalConfirmationDetails(): Promise<PortalConfirmDetailsResult | undefined> {
  const result = await apolloClient.query<PortalConfirmDetailsResult>({
    query: PORTAL_CONFIRM_DETAILS_QUERY,
  });
  return result.data;
}
