import {
  shouldCompanyCarBonusAndPreviousPolicyExpiryWarningBeVisible,
  shouldCompanyCarBonusWarningBeVisible,
  shouldNoClaimsBonusWarningBeVisible,
  shouldPreviousPolicyExpiryWarningBeVisible
} from "../../quote-summary/components/edit-cover-summary/helpers/CoverStartDateWarnings";

export type GetCoverStartDateWarning =
  "NO_CLAIMS_BONUS_INELIGIBLE"
  | "COMPANY_CAR_BONUS_INELIGIBLE"
  | "VEHICLE_PREVIOUS_POLICY_EXPIRY_INVALID"
  | "COMPANY_CAR_BONUS_INELIGIBLE_AND_PREVIOUS_POLICY_EXPIRY_INVALID";

export function getCoverStartDateWarning(
  coverStartDate: Date | undefined,
  existingPolicyExpiryDate: Date | undefined,
  companyCarPolicyExpiryDate: Date | undefined,
  vehiclePreviousPolicyExpiryDate: Date | undefined
): GetCoverStartDateWarning | undefined {
  if (shouldNoClaimsBonusWarningBeVisible(coverStartDate, existingPolicyExpiryDate)) return "NO_CLAIMS_BONUS_INELIGIBLE";
  if (shouldCompanyCarBonusAndPreviousPolicyExpiryWarningBeVisible(coverStartDate, vehiclePreviousPolicyExpiryDate, companyCarPolicyExpiryDate)) return "COMPANY_CAR_BONUS_INELIGIBLE_AND_PREVIOUS_POLICY_EXPIRY_INVALID";
  if (shouldCompanyCarBonusWarningBeVisible(coverStartDate, companyCarPolicyExpiryDate)) return "COMPANY_CAR_BONUS_INELIGIBLE";
  if (shouldPreviousPolicyExpiryWarningBeVisible(coverStartDate, vehiclePreviousPolicyExpiryDate)) return "VEHICLE_PREVIOUS_POLICY_EXPIRY_INVALID";

  return undefined;
}