import {RootState} from "../../../../../redux/Store";
import {vehicleAssumptionsVisibleSelector} from "../redux/selectors/VehiclePageStateSelectors";

export function hasValidAssumptionsCorrectQuestion(rootState: RootState): boolean {
  const assumptionsShown = vehicleAssumptionsVisibleSelector(rootState);
  const assumptionsCorrect = rootState.vehicleDetails.assumptionsCorrect;

  return !assumptionsShown
    ? true
    : assumptionsCorrect.value !== undefined;
}
