import {FC} from "react";
import {ReactComponent as Icon} from "../../../../../assets/icons/your-details.svg";
import AggregatorQuoteValidationFailureTable from "./AggregatorQuoteValidationFailureTable";
import {useAppSelector} from "../../../../../redux/Hooks";
import quoteValidationErrorSelectors
  from "../../../vehicle/your-quote/redux/quote-validation-errors/QuoteValidationErrorSelectors";
import {
  useInitiateResolveErrorFlowForSection
} from "../../redux/InitiateResolveErrorFlowForSection";
import {
  validateAllYourDetailsQuestions
} from "../../../../../redux/questions/personal-details/YourDetailsQuestionRecord";
import {quoteSteps} from "../../../vehicle/shared/quote-step/QuoteSteps";

const YourDetailsQuoteValidationFailureTable: FC = () => {
  const {initiateResolveErrorFlow} = useInitiateResolveErrorFlowForSection({
    section: "PERSONAL_DETAILS",
    validationThunk: validateAllYourDetailsQuestions
  });
  const numberOfIssues = useAppSelector(state => quoteValidationErrorSelectors.selectQuoteValidationErrorsForPersonalDetails(state).length);

  if (numberOfIssues === 0) return null;

  return (
    <AggregatorQuoteValidationFailureTable
      id={"your-details-quote-validation-table"}
      title="aggregatorQuote.validationIssueTable.yourDetails.title"
      description="aggregatorQuote.validationIssueTable.yourDetails.description"
      icon={<Icon className={"icon"}/>}
      items={[{
        heading: {id: "aggregatorQuote.validationIssueTable.yourDetails.tableItem.heading"},
        subHeading: {id: "aggregatorQuote.validationIssueTable.yourDetails.tableItem.subHeading"},
        errorMessage: {
          id: "aggregatorQuote.validationIssueTable.yourDetails.tableItem.errorMessage",
          values: {numberOfIssues}
        },
        onResolveClicked: () => initiateResolveErrorFlow(quoteSteps.yourDetails.routes.new)
      }]}
    />
  );
};

export default YourDetailsQuoteValidationFailureTable;