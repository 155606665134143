import {QuoteAdditionalDriver, QuoteEmployment} from "shared/dist/generated/graphql/resolvers-types";
import {SerializableAdditionalDriver} from "../../../../../pages/quote/vehicle/additional-driver/shared/models/AdditionalDriver";
import {parseDurationAsMonths} from "../../../../../utils/service/parsers/DurationToMonthsParser";
import {startOfDay} from "date-fns/fp";
import {mapEmploymentFields} from "./EmploymentMapper";
import {mapQuoteConvictions} from "./ConvictionsMapper";
import {mapQuoteIncidents} from "./IncidentsMapper";
import {fullMonthsBetween, now} from "shared/dist/stdlib/Dates";
import {formatDateStringFromIsoString} from "shared/dist/stdlib/DateFormat";
import {PersonalDetails} from "../../../../../pages/quote/vehicle/your-details/redux/PersonalDetails";

export function mapAdditionalDriversToGraphql(personalDetails: PersonalDetails): QuoteAdditionalDriver[] {
  return personalDetails.additionalDrivers.map(additionalDriver => toGraphql(additionalDriver, personalDetails));
}

function toGraphql(additionalDriver: SerializableAdditionalDriver, personalDetails: PersonalDetails): QuoteAdditionalDriver {
  return {
    relationshipToProposer: additionalDriver.relationshipToProposer.id,
    title: additionalDriver.title.vtCode,
    firstName: additionalDriver.firstName,
    surname: additionalDriver.surname,
    dateOfBirth: formatDateStringFromIsoString(additionalDriver.dateOfBirth),
    gender: additionalDriver.gender.id,
    ukResidencyDurationMonths: parseUkResidencyDuration(additionalDriver),
    maritalStatus: additionalDriver.maritalStatus.id,
    primaryEmployment: mapEmploymentFields(
      additionalDriver.primaryEmployment.employmentStatus,
      additionalDriver.primaryEmployment.job,
      additionalDriver.primaryEmployment.industry,
      additionalDriver.primaryEmployment.workedInPastYear
    ),
    secondaryEmployment: parseSecondaryEmployment(additionalDriver),
    proposedClassOfUse: additionalDriver.classOfUse.id,
    licenceType: additionalDriver.licenceType.id,
    licenceCountry: additionalDriver.licenceCountryOfIssue.id,
    licenceDurationMonths: parseDurationAsMonths(additionalDriver.licenceDuration),
    isMainDriver: parseMainDriver(additionalDriver, personalDetails),
    convictions: mapQuoteConvictions(additionalDriver.convictions),
    incidents: mapQuoteIncidents(additionalDriver.claims),
    medicalCondition: additionalDriver.medicalCondition?.id,
    hasCancellations: additionalDriver.hasInsuranceDeclined,
    householdNumberOfCars: additionalDriver.householdNumberOfCars
  };
}

function parseSecondaryEmployment({hasSecondaryEmployment, secondaryEmployment}: SerializableAdditionalDriver): QuoteEmployment | undefined {
  if (!hasSecondaryEmployment) return undefined;

  return mapEmploymentFields(
    secondaryEmployment.employmentStatus,
    secondaryEmployment.job,
    secondaryEmployment.industry,
    secondaryEmployment.workedInPastYear
  );
}

function parseUkResidencyDuration(additionalDriver: SerializableAdditionalDriver): number {
  return additionalDriver.hasUkResidencyFromBirth
    ? fullMonthsBetween(new Date(additionalDriver.dateOfBirth), startOfDay(now()))
    : parseDurationAsMonths(additionalDriver.ukResidencyDuration);
}

function parseMainDriver(additionalDriver: SerializableAdditionalDriver, personalDetails: PersonalDetails): boolean {
  return personalDetails.mainDriver.value === additionalDriver.id;
}
