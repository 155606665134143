import React, {FC} from "react";
import {useEffectOnFirstRender} from "shared-components/dist/utils/hooks/UseEffectOnFirstRender";
import ErrorPage from "../../../shared/error/ErrorPage";
import Navbar from "../../../../../structure/navigation/navbar/Navbar";
import {noticeError} from "../../../../../newrelic/NoticeError";

const AnnualisedBreakdownError: FC = () => {
  useEffectOnFirstRender(() => {
    noticeError(new Error("Error occurred loading renewal summary breakdown."));
  });

  return (
    <ErrorPage
      title="portal.renewals.annualised.breakdown.error.title"
      description="portal.renewals.annualised.breakdown.error.description"
      header={<Navbar/>}
    />
  );
};

export default AnnualisedBreakdownError;
