import {RootState} from "../../../../../../redux/Store";
import {createSelector} from "@reduxjs/toolkit";
import {activeFormFields} from "shared-components/dist/models/form-field/FormFields";
import {FormField, isValidFormField, RequiredFormField} from "shared-components/dist/models/form-field/FormField";
import {isValidConvictionsList} from "../../../shared/questions/convictions/validation/ValidConviction";
import {
  Convictions,
  SerializableConvictions,
  toConviction
} from "../../../shared/questions/convictions/models/Conviction";
import {isValidClaimsList} from "../../../shared/questions/claims/validation/ClaimValidator";
import {Claims, SerializableClaims, toClaim} from "../../../shared/questions/claims/models/Claims";
import {toSafeDate} from "shared/dist/stdlib/Dates";
import {DateFormField} from "shared-components/dist/models/form-field/variants/DateFormField";
import {
  AdditionalDriver,
  SerializableAdditionalDriver,
  toAdditionalDriver
} from "../../../additional-driver/shared/models/AdditionalDriver";
import {PersonalDetails} from "../PersonalDetails";
import {LicenceTypeOption} from "../../../shared/questions/licence-type/LicenceTypeOption";
import {LicenceCountryOfIssue} from "../../../shared/questions/licence-country-of-issue/LicenceCountryOfIssue";
import {ConditionalFormField} from "shared-components/dist/models/form-field/ConditionalFormField";
import {CustomisedAbiListItem} from "shared-components/dist/models/CustomisedAbiListItem";

const personalDetailsSelector = (state: RootState): PersonalDetails => state.personalDetails;
export const phoneNumberSelector = (state: RootState): FormField<string> => state.personalDetails.phoneNumber;
const dateOfBirthSelector = (state: RootState): FormField<string> => state.personalDetails.dateOfBirth;
const serializableClaimsSelector = (state: RootState): RequiredFormField<SerializableClaims> => state.personalDetails.claims;
const serializableConvictionsSelector = (state: RootState): RequiredFormField<SerializableConvictions> => state.personalDetails.convictions;
const serializableAdditionalDriversSelector = (state: RootState): SerializableAdditionalDriver[] => state.personalDetails.additionalDrivers;
export const licenceDurationSelector = (state: RootState): FormField<Duration> => state.personalDetails.licenceDuration;
export const licenceTypeSelector = (state: RootState): FormField<LicenceTypeOption> => state.personalDetails.licenceType;
export const licenceCountryOfIssueSelector = (state: RootState): FormField<LicenceCountryOfIssue> => state.personalDetails.licenceCountryOfIssue;
export const solicitingSelector = (state: RootState): FormField<boolean> => state.personalDetails.soliciting;
export const primaryOccupationSelector = (state: RootState): ConditionalFormField<CustomisedAbiListItem> => state.personalDetails.primaryEmployment.job;
export const secondaryOccupationSelector = (state: RootState): ConditionalFormField<CustomisedAbiListItem> => state.personalDetails.secondaryEmployment.job;

export const dateOfBirthAsDateSelector = createSelector(
  dateOfBirthSelector,
  (dateOfBirth: FormField<string>): DateFormField => {
    return {
      ...dateOfBirth,
      value: toSafeDate(dateOfBirth.value)
    };
  }
);

export const claimsSelector = createSelector(
  serializableClaimsSelector,
  (serializableClaims: RequiredFormField<SerializableClaims>): RequiredFormField<Claims> => {
      return {
        ...serializableClaims,
        value: {
          ...serializableClaims.value,
          claims: serializableClaims.value.claims.map(toClaim)
        }
      };
  }
);

export const convictionsSelector = createSelector(
  serializableConvictionsSelector,
  (serializableConvictions: RequiredFormField<SerializableConvictions>): RequiredFormField<Convictions> => {
    return {
      ...serializableConvictions,
      value: {
       ...serializableConvictions.value,
        convictions: serializableConvictions.value.convictions.map(toConviction)
      }
    };
  }
);

export const additionalDriversSelector = createSelector(
  serializableAdditionalDriversSelector,
  (serializableAdditionalDrivers: SerializableAdditionalDriver[]): AdditionalDriver[] => {
    return serializableAdditionalDrivers.map(toAdditionalDriver);
  }
);

export const personalDetailsValidSelector = createSelector(
  personalDetailsSelector,
  claimsSelector,
  convictionsSelector,
  (
    personalDetails: PersonalDetails,
    claims: RequiredFormField<Claims>,
    convictions: RequiredFormField<Convictions>
  ): boolean =>
    isValidFormField(...activeFormFields(personalDetails)) &&
    isValidClaimsList(claims.value) &&
    isValidConvictionsList(convictions.value) &&
    isValidFormField(...activeFormFields(personalDetails.primaryEmployment)) &&
    isValidFormField(...activeFormFields(personalDetails.secondaryEmployment))
);