import {FC} from "react";
import "./AggregatorQuoteSidebarContent.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";

const AggregatorQuoteSidebarContent: FC = () => {
  return (
    <div className="aggregator-quote-sidebar-content">
      <div className="aggregator-quote-sidebar-content__aggregator-logo">
        <img src="/assets/quotezone.jpg" alt="Quote Zone Logo"/>
      </div>
      <p>{lookupI18nString("aggregatorQuote.sidebar.description")}</p>
    </div>
  );
};

export default AggregatorQuoteSidebarContent;
