import {ReduxQuestion} from "../../Questions";
import {
  isOwnerAndRegisteredKeeperChanged,
  isOwnerAndRegisteredKeeperInvalid
} from "../../../../pages/quote/vehicle/your-vehicle/redux/VehicleDetailsSlice";

export const isOwnerAndRegisteredKeeper: ReduxQuestion<boolean> = {
  selector: state => state.vehicleDetails.isOwnerAndRegisteredKeeper,
  onChange: isOwnerAndRegisteredKeeperChanged,
  onInvalid: isOwnerAndRegisteredKeeperInvalid
};