import {PolicyExtrasListItemProps} from "../components/policy-extras-list-item/PolicyExtrasListItem";
import {useAvailablePolicyExtrasQuery} from "./AvailablePolicyExtrasQuery";
import {
  PolicyExtraType,
  ProspectivePolicyExtra,
  ProspectivePolicyExtraOption
} from "shared/dist/generated/graphql/resolvers-types";
import {getPolicyExtraDescriptionKeyFrom, getPolicyExtraNameKeyFrom} from "../helpers/PolicyExtraTranslationKeyMapper";
import {mapToStoredPolicyExtra, StoredPolicyExtra} from "../../../../../your-quote/models/StoredPolicyExtra";
import {getDefaultExcessInsurance, isSelectionBelowDefault} from "../helpers/ExcessInsuranceDefaultOption";
import PolicyExtrasListItemDropdown from "../components/policy-extras-list-item/dropdown/PolicyExtrasListItemDropdown";
import React from "react";
import {policyExtrasChanged, startedLoadingCloseBrothersLoan} from "../../../../../your-quote/redux/QuoteDetailsSlice";
import {totalPolicyExcessSelector} from "../../../../redux/TotalPolicyExcessSelector";
import {getExcessInsuranceDisplayValue} from "../helpers/ExcessInsuranceDisplayValue";
import {
  MultipleItemPolicyExtra,
  MultipleItemPolicyExtraOption
} from "../../../../../your-quote/models/MultipleItemPolicyExtra";
import {useAppDispatch, useAppSelector} from "../../../../../../../../redux/Hooks";
import {upsertQuoteExtras} from "../../../../../your-quote/redux/thunks/UpsertQuoteExtras";
import {isGBPostcodeSelector} from "../../../../../your-quote/redux/selectors/QuoteDetailsSelectors";

export interface PolicyExtrasResult {
  loading: boolean;
  policyExtraListItems: PolicyExtrasListItemProps[];
}

export function usePolicyExtrasListItemProps(policyStartDate: string | undefined): PolicyExtrasResult {
  const {loading, data} = useAvailablePolicyExtrasQuery(policyStartDate);
  const dispatch = useAppDispatch();
  const isGBPostcode = useAppSelector(isGBPostcodeSelector);
  const extrasForGBQuotes: PolicyExtraType[] = [PolicyExtraType.SmartfobKeycare, PolicyExtraType.ExcessInsurance];
  const selectedPolicyExtras = useAppSelector(state => state.quoteDetails.policyExtras);
  const totalPolicyExcess = useAppSelector(totalPolicyExcessSelector);

  const onToggleChanged = (isActive: boolean, policyExtraToggled: ProspectivePolicyExtra, defaultOption?: ProspectivePolicyExtraOption): void => {
    storeExtras(isActive, policyExtraToggled, defaultOption);
  };

  const storeExtras = (isActive: boolean, policyExtraToggled: ProspectivePolicyExtra, defaultOption?: ProspectivePolicyExtraOption): void => {
    const updatedExtras = getUpdatedExtras(isActive, policyExtraToggled, defaultOption);
    dispatch(policyExtrasChanged(updatedExtras));

    dispatch(startedLoadingCloseBrothersLoan());
    dispatch(upsertQuoteExtras(updatedExtras));
  };

  const getUpdatedExtras = (isActive: boolean, policyExtraToggled: ProspectivePolicyExtra, defaultOption?: ProspectivePolicyExtraOption): StoredPolicyExtra[] => {
    if (isActive) {
      const newExtra = mapToStoredPolicyExtra(policyExtraToggled, defaultOption);
      return [...selectedPolicyExtras, newExtra];
    } else {
      const removedExtra = mapToStoredPolicyExtra(policyExtraToggled);
      return selectedPolicyExtras.filter(item => item.id !== removedExtra.id);
    }
  };

  const onDropdownOptionChanged = (selectedOption: string, policyExtra: ProspectivePolicyExtra): void => {
    const selectedPolicyExtraOption = policyExtra.options.find(availableOptions => availableOptions.description === selectedOption);
    const updatedExtra = mapToStoredPolicyExtra(policyExtra, selectedPolicyExtraOption);

    const updatedExtras = selectedPolicyExtras
      .filter(item => item.id !== updatedExtra.id)
      .concat(updatedExtra);

    dispatch(policyExtrasChanged(updatedExtras));
    dispatch(upsertQuoteExtras(updatedExtras, false));
  };

  let prospectivePolicyExtras = data;

  if (isGBPostcode) {
    prospectivePolicyExtras = prospectivePolicyExtras?.filter(prospectivePolicyExtra => extrasForGBQuotes.includes(prospectivePolicyExtra.type));
  }

  const policyExtraListItems: PolicyExtrasListItemProps[] = prospectivePolicyExtras?.map(prospectivePolicyExtra => mapToPolicyExtraItem(
      prospectivePolicyExtra,
      selectedPolicyExtras,
      totalPolicyExcess,
      onToggleChanged,
      onDropdownOptionChanged
  )) ?? [];

  return {loading, policyExtraListItems: policyExtraListItems};
}

function mapToPolicyExtraItem(
  prospectivePolicyExtra: ProspectivePolicyExtra,
  selectedPolicyExtras: StoredPolicyExtra[],
  totalPolicyExcess: number,
  onToggleChanged: (isActive: boolean, policyExtraToggled: ProspectivePolicyExtra, defaultOption?: ProspectivePolicyExtraOption) => void,
  onDropdownOptionChanged: (selectedOption: string, policyExtra: ProspectivePolicyExtra) => void
): PolicyExtrasListItemProps {
  const selectedPolicyExtra = selectedPolicyExtras.find(item => item.id === prospectivePolicyExtra.type);
  const sharedProps: PolicyExtrasListItemProps = {
    type: prospectivePolicyExtra.type,
    active: selectedPolicyExtra !== undefined,
    name: getPolicyExtraNameKeyFrom(prospectivePolicyExtra.type),
    description: getPolicyExtraDescriptionKeyFrom(prospectivePolicyExtra.type),
    onAnchorClick: () => window.open(prospectivePolicyExtra.document.url),
  };

  if (!isExcessInsurance(prospectivePolicyExtra)) {
    return {
      ...sharedProps,
      price: prospectivePolicyExtra.options[0].price,
      onToggleChanged: isActive => onToggleChanged(isActive, prospectivePolicyExtra)
    };
  }

  const selectedExcessInsuranceOption = selectedPolicyExtra as MultipleItemPolicyExtraOption | undefined;
  const defaultExcessInsuranceOption = getDefaultExcessInsurance(prospectivePolicyExtra.options, totalPolicyExcess) ?? prospectivePolicyExtra.options[0];

  return {
    ...sharedProps,
    price: selectedExcessInsuranceOption?.price ?? defaultExcessInsuranceOption.price,
    onToggleChanged: isActive => onToggleChanged(isActive, prospectivePolicyExtra, defaultExcessInsuranceOption),
    listItemContent: selectedExcessInsuranceOption && (
      <PolicyExtrasListItemDropdown
        description="policies.extra.excessInsurance.question"
        options={prospectivePolicyExtra.options.map(option => option.description)}
        displayValues={prospectivePolicyExtra.options
          .map(option => getExcessInsuranceDisplayValue(option, selectedExcessInsuranceOption?.price ?? defaultExcessInsuranceOption.price))
        }
        onSelection={value => onDropdownOptionChanged(value, prospectivePolicyExtra)}
        value={selectedExcessInsuranceOption?.description ?? defaultExcessInsuranceOption.description}
        errored={isSelectionBelowDefault(defaultExcessInsuranceOption, selectedExcessInsuranceOption)}
        errorText="policies.extra.excessInsurance.error"
      />
    )
  };
}

function isExcessInsurance(prospectivePolicyExtra: ProspectivePolicyExtra): prospectivePolicyExtra is MultipleItemPolicyExtra {
  return prospectivePolicyExtra.type === PolicyExtraType.ExcessInsurance;
}
