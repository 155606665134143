import React, {FC} from "react";
import "./SubContainerStop.css";
import SubContainer from "../../../questions/sub-container/SubContainer";
import Stop, {StopProps} from "../Stop";

interface Props {
  stopProps: StopProps;
}

const SubContainerStop: FC<Props> = ({stopProps}) => {
  if (!stopProps.visible) return null;

  return (
    <SubContainer className="sub-container-stop">
      <Stop {...stopProps}/>
    </SubContainer>
  );
};

export default SubContainerStop;