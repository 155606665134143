import {validationFailure, validationSuccess, Validator} from "shared-components/dist/utils/validation/Validator";
import {isAfter, isBefore, isSameDay} from "date-fns";
import {RelationshipToProposer} from "shared/dist/generated/graphql/resolvers-types";
import {RelationshipToProposerOption} from "../../relationship-to-proposer/model/RelationshipToProposerOption";

interface AgeRelationshipValidatorProps {
  additionalDriverDateOfBirth?: Date;
  proposerDateOfBirth?: Date;
}
type AgeRelationshipValidator = (props: AgeRelationshipValidatorProps) => Validator<RelationshipToProposerOption | undefined>;

interface ReverseAgeRelationshipValidatorProps {
  relationshipToProposer?: RelationshipToProposerOption;
  proposerDateOfBirth?: Date;
}
type ReversedAgeRelationshipValidator = (props: ReverseAgeRelationshipValidatorProps) => Validator<Date>

export const reverseAgeRelationshipValidator = (validator: AgeRelationshipValidator): ReversedAgeRelationshipValidator => {
  return ({proposerDateOfBirth, relationshipToProposer}) => additionalDriverDateOfBirth => validator({
    additionalDriverDateOfBirth,
    proposerDateOfBirth: proposerDateOfBirth
  })(relationshipToProposer);
};

export function childSelectedValidator(
  {
    additionalDriverDateOfBirth,
    proposerDateOfBirth
  }: AgeRelationshipValidatorProps
): Validator<RelationshipToProposerOption | undefined> {
  return (relationshipToProposer?: RelationshipToProposerOption) => {
    if (!proposerDateOfBirth || !additionalDriverDateOfBirth || !relationshipToProposer) return validationSuccess;

    return isChildDateOfBirthInvalidForProposer(relationshipToProposer.id, additionalDriverDateOfBirth, proposerDateOfBirth)
      ? validationFailure("additionalDriver.dateOfBirthQuestion.invalid.childDateOfBirthBeforeProposer")
      : validationSuccess;
  };
}

export function grandchildSelectedValidator(
  {
    additionalDriverDateOfBirth,
    proposerDateOfBirth
  }: AgeRelationshipValidatorProps
): Validator<RelationshipToProposerOption | undefined> {
  return (relationshipToProposer?: RelationshipToProposerOption) => {
    if (!proposerDateOfBirth || !additionalDriverDateOfBirth || !relationshipToProposer) return validationSuccess;

    return isGrandchildDateOfBirthInvalidForProposer(relationshipToProposer.id, additionalDriverDateOfBirth, proposerDateOfBirth)
      ? validationFailure("additionalDriver.dateOfBirthQuestion.invalid.grandchildDateOfBirthBeforeProposer")
      : validationSuccess;
  };
}

export function parentSelectedValidator(
  {
    additionalDriverDateOfBirth,
    proposerDateOfBirth
  }: AgeRelationshipValidatorProps
): Validator<RelationshipToProposerOption | undefined> {
  return (relationshipToProposer?: RelationshipToProposerOption) => {
    if (!proposerDateOfBirth || !additionalDriverDateOfBirth || !relationshipToProposer) return validationSuccess;

    return isParentDateOfBirthInvalidForProposer(relationshipToProposer.id, additionalDriverDateOfBirth, proposerDateOfBirth)
      ? validationFailure("additionalDriver.dateOfBirthQuestion.invalid.parentDateOfBirthBeforeProposer")
      : validationSuccess;
  };
}

export function grandparentSelectedValidator(
  {
    additionalDriverDateOfBirth,
    proposerDateOfBirth
  }: AgeRelationshipValidatorProps
): Validator<RelationshipToProposerOption | undefined> {
  return (relationshipToProposer?: RelationshipToProposerOption) => {
    if (!proposerDateOfBirth || !additionalDriverDateOfBirth || !relationshipToProposer) return validationSuccess;

    return isGrandparentDateOfBirthInvalidForProposer(relationshipToProposer.id, additionalDriverDateOfBirth, proposerDateOfBirth)
      ? validationFailure("additionalDriver.dateOfBirthQuestion.invalid.grandparentDateOfBirthBeforeProposer")
      : validationSuccess;
  };
}

const isChildDateOfBirthInvalidForProposer = (
  relationshipToProposer: RelationshipToProposer,
  additionalDriverDateOfBirth: Date,
  proposerDateOfBirth: Date
): boolean => relationshipToProposer === RelationshipToProposer.DaughterOrSon && isSameDayOrBefore(additionalDriverDateOfBirth, proposerDateOfBirth);

const isGrandchildDateOfBirthInvalidForProposer = (
  relationshipToProposer: RelationshipToProposer,
  additionalDriverDateOfBirth: Date,
  proposerDateOfBirth: Date
): boolean => relationshipToProposer === RelationshipToProposer.Grandchild && isSameDayOrBefore(additionalDriverDateOfBirth, proposerDateOfBirth);

const isParentDateOfBirthInvalidForProposer = (
  relationshipToProposer: RelationshipToProposer,
  additionalDriverDateOfBirth: Date,
  proposerDateOfBirth: Date
): boolean => relationshipToProposer === RelationshipToProposer.Parent && isSameDayOrAfter(additionalDriverDateOfBirth, proposerDateOfBirth);

const isGrandparentDateOfBirthInvalidForProposer = (
  relationshipToProposer: RelationshipToProposer,
  additionalDriverDateOfBirth: Date,
  proposerDateOfBirth: Date
): boolean => relationshipToProposer === RelationshipToProposer.Grandparent && isSameDayOrAfter(additionalDriverDateOfBirth, proposerDateOfBirth);

const isSameDayOrAfter = (
  additionalDriverDateOfBirth: Date,
  proposerDateOfBirth: Date
): boolean => (isSameDay(additionalDriverDateOfBirth, proposerDateOfBirth) || isAfter(additionalDriverDateOfBirth, proposerDateOfBirth));

const isSameDayOrBefore = (
  additionalDriverDateOfBirth: Date,
  proposerDateOfBirth: Date
): boolean => (isSameDay(additionalDriverDateOfBirth, proposerDateOfBirth) || isBefore(additionalDriverDateOfBirth, proposerDateOfBirth));