import {AppThunkDispatch} from "../../../../../redux/Store";
import {
  engineCapacityLitresInvalid,
  fuelTypeInvalid,
  fullSpecInvalid,
  makeInvalid,
  modelInvalid,
  transmissionTypeInvalid,
  trimLevelInvalid,
  yearOfManufactureInvalid
} from "../redux/ManualVehicleLookupFormSlice";
import {ManualVehicleLookupForm} from "../redux/ManualVehicleLookupForm";

type CheckFunction = (manualLookup: ManualVehicleLookupForm, dispatch: AppThunkDispatch) => void;

export function flagUnansweredManualLookupQuestions(manualLookup: ManualVehicleLookupForm, dispatch: AppThunkDispatch): void {
  [
    checkManualLookupMake,
    checkManualLookupModel,
    checkManualLookupFuelType,
    checkManualLookupTransmissionType,
    checkManualLookupYearOfManufacture,
    checkManualLookupEngineCapacity,
    checkManualLookupTrimLevel,
    checkManualLookupFullSpec,
  ].forEach((checkFunction: CheckFunction) => checkFunction(manualLookup, dispatch));
}

function checkManualLookupMake({make}: ManualVehicleLookupForm, dispatch: AppThunkDispatch): void {
  if (make.isActive && make.status === "default") {
    dispatch(makeInvalid("quote.errors.questionIncomplete"));
  }
}

function checkManualLookupModel({model}: ManualVehicleLookupForm, dispatch: AppThunkDispatch): void {
  if (model.isActive && model.status === "default") {
    dispatch(modelInvalid("quote.errors.questionIncomplete"));
  }
}

function checkManualLookupFuelType({fuelType}: ManualVehicleLookupForm, dispatch: AppThunkDispatch): void {
  if (fuelType.isActive && fuelType.status === "default") {
    dispatch(fuelTypeInvalid("quote.errors.questionIncomplete"));
  }
}

function checkManualLookupTransmissionType({transmissionType}: ManualVehicleLookupForm, dispatch: AppThunkDispatch): void {
  if (transmissionType.isActive && transmissionType.status === "default") {
    dispatch(transmissionTypeInvalid("quote.errors.questionIncomplete"));
  }
}

function checkManualLookupYearOfManufacture({yearOfManufacture}: ManualVehicleLookupForm, dispatch: AppThunkDispatch): void {
  if (yearOfManufacture.isActive && yearOfManufacture.status === "default") {
    dispatch(yearOfManufactureInvalid("quote.errors.questionIncomplete"));
  }
}

function checkManualLookupEngineCapacity({engineCapacityLitres}: ManualVehicleLookupForm, dispatch: AppThunkDispatch): void {
  if (engineCapacityLitres.isActive && engineCapacityLitres.status === "default") {
    dispatch(engineCapacityLitresInvalid("quote.errors.questionIncomplete"));
  }
}

function checkManualLookupTrimLevel({trimLevel}: ManualVehicleLookupForm, dispatch: AppThunkDispatch): void {
  if (trimLevel.isActive && trimLevel.status === "default") {
    dispatch(trimLevelInvalid("quote.errors.questionIncomplete"));
  }
}

function checkManualLookupFullSpec({fullSpec}: ManualVehicleLookupForm, dispatch: AppThunkDispatch,): void {
  if (fullSpec.isActive && fullSpec.status === "default") {
    dispatch(fullSpecInvalid("quote.errors.questionIncomplete"));
  }
}
