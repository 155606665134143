import {gql} from "@apollo/client";
import {apolloClient} from "../../../apollo/ApolloClientProvider";

export const VEHICLE_LOOKUP_WARM_UP_QUERY = gql`
    query WarmUp {
      vehicle {
        warmUp
      }
    }
`;

export function warmUpVehicleLookupLambda(): void {
  apolloClient.query<string>({query: VEHICLE_LOOKUP_WARM_UP_QUERY});
}
