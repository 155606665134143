import React, {FC} from "react";
import {firstNameChanged, firstNameInvalid} from "../../redux/PersonalDetailsSlice";
import FirstNameQuestion from "../../../shared/questions/first-name/FirstNameQuestion";
import {hasValidName} from "shared-components/dist/form/name-input-field/validation/NameValidator";
import {logYourDetailsGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const ProposerFirstNameQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const firstNameField = useAppSelector(state => state.personalDetails.firstName);

  const onFirstNameChanged = (value: string): void => {
    logYourDetailsGoogleAnalyticsEvent({
      categorySuffix: "first_name",
      action: "Answered",
    });
    dispatch(firstNameChanged(value));
  };

  return (
    <FirstNameQuestion
      id="proposer-first-name"
      title="personalDetails.firstNameQuestion"
      firstNameField={firstNameField}
      onComplete={onFirstNameChanged}
      onInvalid={errorMessage => dispatch(firstNameInvalid(errorMessage))}
      validators={[
        hasValidName("personalDetails.firstNameQuestion.errors.invalidEntry")
      ]}
      placeholder="personalDetails.firstNameQuestion.placeholder"
    />
  );
};

export default ProposerFirstNameQuestion;