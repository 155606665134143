import {ReduxQuestion} from "../../Questions";
import {
  surnameChanged,
  surnameInvalid
} from "../../../../pages/quote/vehicle/additional-driver/shared/redux/UpdateAdditionalDriverSlice";
import {hasValidName} from "shared-components/dist/form/name-input-field/validation/NameValidator";

export const surname: ReduxQuestion<string> = {
  selector: state => state.updateAdditionalDriver.surname,
  onChange: surnameChanged,
  onInvalid: surnameInvalid,
  validators: [
    hasValidName("additionalDriver.surnameQuestion.errors.invalidEntry")
  ]
};