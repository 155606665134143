import React, {FC} from "react";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./CloseBrothersSidebarContent.css";

const CloseBrothersSidebarContent: FC = () => (
  <div className="close-brothers-sidebar-content">
    <p>{lookupI18nString("paymentFlow.monthly.directDebit.sidebar.description")}</p>
    <div className="close-brothers-sidebar-content__logo"/>
  </div>
);

export default CloseBrothersSidebarContent;