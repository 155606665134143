import {useEffect, useState} from "react";

export const BASE_VOLUNTARY_EXCESS_OPTIONS = ["0", "100", "200", "300", "400", "500"];

export const useVoluntaryExcessOptions = (currentVoluntaryExcess: string | undefined): string[] => {
  const [voluntaryExcessOptions, setVoluntaryExcessOptions] = useState(BASE_VOLUNTARY_EXCESS_OPTIONS);

  useEffect(() => {
    if (!currentVoluntaryExcess || voluntaryExcessOptions.includes(currentVoluntaryExcess)) return;

    const newOptions = [...voluntaryExcessOptions, currentVoluntaryExcess].sort((a, b) => +a - +b);
    setVoluntaryExcessOptions(newOptions);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVoluntaryExcess]);

  return voluntaryExcessOptions;
};