import React, {FC} from "react";
import DateQuestion from "../../../../../../structure/questions/question/variants/date-question/DateQuestion";
import {isAboveMinimumAge} from "./validators/IsAboveMinimumAge";
import {isBelowMaximumAge} from "./validators/IsBelowMaximumAge";
import {isAboveMinimumAgeOnCoverStartDate} from "./validators/IsAboveMinimumAgeOnCoverStartDate";
import {isBelowMaximumAgeOnCoverStartDate} from "./validators/IsBelowMaximumAgeOnCoverStartDate";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {DateFormField} from "shared-components/dist/models/form-field/variants/DateFormField";
import {Validator} from "shared-components/dist/utils/validation/Validator";
import {subYears} from "date-fns";
import {now} from "lodash";

interface Props {
  id: string;
  title: TranslationKey;
  dateOfBirthField: DateFormField;
  coverStartDate: Date | undefined;
  dateOfBirthChanged: (value: Date) => void;
  dateOfBirthInvalid: (errorMessage: TranslationKey) => void;
  dateOfBirthReset: () => void;
  revalidate?: boolean;
  onRevalidated?: () => void;
  validators?: Validator<Date>[];
}

const DateOfBirthQuestion: FC<Props> = (
  {
    id,
    title,
    dateOfBirthField,
    dateOfBirthChanged,
    dateOfBirthInvalid,
    dateOfBirthReset,
    coverStartDate,
    revalidate,
    onRevalidated,
    validators = [],
    children
  }
) => (
  <DateQuestion
    id={id}
    title={title}
    formField={dateOfBirthField}
    defaultCalendarValue={subYears(now(), 17)}
    onComplete={dateOfBirthChanged}
    onInvalid={dateOfBirthInvalid}
    onClear={dateOfBirthReset}
    revalidate={revalidate}
    onRevalidated={onRevalidated}
    validators={[
      ...validators,
      isAboveMinimumAge(),
      isBelowMaximumAge(),
      isAboveMinimumAgeOnCoverStartDate(coverStartDate),
      isBelowMaximumAgeOnCoverStartDate(coverStartDate)
    ]}
  >
    {children}
  </DateQuestion>
);

export default DateOfBirthQuestion;
