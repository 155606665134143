import {AppThunk} from "../../../../../../../redux/Store";
import {mapToSerializableAdditionalDriver} from "./mappers/AdditionalDriverMapper";
import {additionalDriverEdited} from "../../../../../../../redux/Actions";
import {updateBusinessMileageQuestionVisibility} from "../../../../shared/redux/ShouldShowBusinessMileageQuestion";
import {
  handleAdditionalDriverChangeForValidationFlow
} from "../../../../../aggregator-quote/redux/ExitResolveValidationErrorFlow";

export const saveAdditionalDriver = (driverId: string): AppThunk => async (dispatch, getState) => {
  const additionalDriverForm = await getState().updateAdditionalDriver;
  const additionalDriver = mapToSerializableAdditionalDriver(additionalDriverForm, driverId);

  dispatch(handleAdditionalDriverChangeForValidationFlow());
  dispatch(additionalDriverEdited(additionalDriver));

  const state = getState();

  dispatch(updateBusinessMileageQuestionVisibility(
    state.personalDetails.classOfUse.value
  ));
};