import React, {ChangeEvent, FC, useEffect, useState} from "react";
import EditCoverSummaryFooter from "./components/edit-cover-summary-footer/EditCoverSummaryFooter";
import {COVER_TYPE_OPTIONS, CoverTypeDescription} from "../../../../your-cover/components/cover-type/models/CoverType";
import {convertBooleanToConfirmation} from "shared-components/dist/models/Confirmation";
import {
  canProtectNoClaimsBonusSelector,
  coverStartDateAsDateOptionSelector, existingPolicyExpiryDateAsDateSelector
} from "../../../../your-cover/redux/selectors/CoverDetailsSelectors";
import {coverSummaryEdited} from "./thunks/CoverSummaryEdited";
import DropdownModalQuestion from "../../../../../../../structure/questions/modal-question/variants/DropdownModalQuestion";
import Modal from "shared-components/dist/modals/Modal";
import SimpleModalHeader from "shared-components/dist/modals/simple-modal-header/SimpleModalHeader";
import {startDateOptionsByDateOfBirth} from "./helpers/StartDateOptionsByDateOfBirth";
import {coverStartDateDescriptionsFrom} from "../../../../../../../utils/forms/descriptionsFrom";
import BreakInCoverDetection from "../../../break-in-cover/BreakInCoverDetection";
import {useBreakInCoverWithinModal} from "../../../break-in-cover/hooks/UseBreakInCoverWithinModal";
import {useDismissOnQuoteRefreshed} from "../edit-car-details-modal/hooks/useDismissOnQuoteRefreshed";
import {dateOfBirthAsDateSelector} from "../../../../your-details/redux/selectors/PersonalDetailsSelectors";
import {vehiclePurchaseDateAsDateSelector} from "../../../../your-vehicle/redux/selectors/VehicleDateTypeSelectors";
import {isSameDay} from "date-fns";
import {useFeatureFlags} from "shared-components/dist/feature-flags/hooks/UseFeatureFlags";
import {useModalCoverStartDateStatus} from "../../../hooks/cover-start-date/ModalCoverStartDateStatus";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";
import {
  isNamedDriverAXAEligibleSelector
} from "../../../../your-cover/redux/selectors/named-driver/IsNamedDriverAXAEligible";
import {useVoluntaryExcessOptions} from "../../../../your-cover/components/voluntary-excess/UseVoluntaryExcessOptions";

interface Props {
  visible: boolean;
  onDismiss: () => void;
}

const EditCoverSummary: FC<Props> = ({visible, onDismiss}) => {
  const dispatch = useAppDispatch();
  const isQuoteLoading = useAppSelector(state => state.quoteDetails.quoteLoading);
  const dateOfBirth = useAppSelector(state => dateOfBirthAsDateSelector(state).value);
  const coverStartDate = useAppSelector(state => coverStartDateAsDateOptionSelector(state).value);
  const existingPolicyExpiryDate = useAppSelector(state => existingPolicyExpiryDateAsDateSelector(state).value);
  const compulsoryExcess = useAppSelector(state => state.quoteDetails.compulsoryExcess);
  const voluntaryExcess = useAppSelector(state => state.coverDetails.voluntaryExcess.value);
  const voluntaryExcessOptions = useVoluntaryExcessOptions(voluntaryExcess);
  const coverType = useAppSelector(state => state.coverDetails.coverType.value?.description);
  const canProtectNoClaimsBonus = useAppSelector(canProtectNoClaimsBonusSelector);
  const protectNoClaimsBonus = useAppSelector(state => state.coverDetails.protectNoClaimsBonus.value);
  const carPurchaseDate = useAppSelector(state => vehiclePurchaseDateAsDateSelector(state).value);
  const isEligibleForNamedDriver = useAppSelector(isNamedDriverAXAEligibleSelector);

  const {monthlyPaymentsFlag} = useFeatureFlags();
  const [selectedCoverStartDate, setSelectedCoverStartDate] = useState(coverStartDate);
  const [selectedCoverType, setSelectedCoverType] = useState(coverType);
  const [selectedVoluntaryExcess, setSelectedVoluntaryExcess] = useState(voluntaryExcess);
  const [selectedProtectNoClaimsBonus, setSelectedProtectNoClaimsBonus] = useState(protectNoClaimsBonus);
  const {
    breakInCover,
    breakInCoverDeclarationAcceptance,
    onBreakInCoverChanged,
    onBreakInCoverDeclarationAnswered,
    breakInCoverReset,
  } = useBreakInCoverWithinModal();
  const {
    status: coverStartDateStatus,
    errorMessage: coverStartDateErrorMessage,
    errorTooltip: coverStartDateErrorTooltip
  } = useModalCoverStartDateStatus({
    coverStartDate: selectedCoverStartDate,
    carPurchaseDate,
    existingPolicyExpiryDate
  });
  useDismissOnQuoteRefreshed(isQuoteLoading, visible, onDismiss);

  const coverStartDates = startDateOptionsByDateOfBirth(dateOfBirth);

  useEffect(() => {
    const dateToFind = coverStartDate?.date;

    if (!dateToFind) return;

    const coverStartDateDropdownOption = coverStartDates.find(({date}) => (
      isSameDay(date, dateToFind)
    ));

    setSelectedCoverStartDate(coverStartDateDropdownOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverStartDate]);

  const onSave = (): void => {
    const updatedCoverSummary = {
      selectedVoluntaryExcess,
      selectedCoverType,
      selectedProtectNoClaimsBonus,
      selectedCoverStartDate,
      breakInCover,
      breakInCoverDeclarationAcceptance: breakInCoverDeclarationAcceptance.value
    };
    dispatch(coverSummaryEdited(monthlyPaymentsFlag, updatedCoverSummary, isEligibleForNamedDriver));
  };

  const onCancel = (): void => {
    onDismiss();

    setSelectedCoverStartDate(coverStartDate);
    setSelectedCoverType(coverType);
    setSelectedVoluntaryExcess(voluntaryExcess);
    setSelectedProtectNoClaimsBonus(protectNoClaimsBonus);
    breakInCoverReset();
  };

  const onCoverStartDateChanged = (selectedCoverStartDate: string | undefined): void => {
    const coverStartDate = coverStartDates.find(coverStartDate => coverStartDate.description === selectedCoverStartDate);

    if (coverStartDate) setSelectedCoverStartDate(coverStartDate);
  };

  const compulsoryExcessAmount = (): string => {
    if (selectedCoverType === "Third Party" || !compulsoryExcess) return "0";

    return compulsoryExcess.toString();
  };

  const isUpdateDisabled = (): boolean => {
    const isDeclarationShownAndNotConfirmed = breakInCoverDeclarationAcceptance.isActive && breakInCoverDeclarationAcceptance.value !== true;
    const isBreakInCoverSoftStopShown = breakInCover === "CANNOT_INSURE_ONLINE";
    const isSelectedCoverStartDateInError = coverStartDateStatus === "error";

    return isDeclarationShownAndNotConfirmed || isBreakInCoverSoftStopShown || isSelectedCoverStartDateInError;
  };

  return (
    <Modal
      className="edit-cover-summary"
      visible={visible}
      onDismiss={onCancel}
      header={
        <SimpleModalHeader
          onDismiss={onCancel}
          title="quoteDetails.summary.modal.title"
        />
      }
      body={
        <>
          <DropdownModalQuestion
            id="edit-cover-start-date-question"
            title="quoteDetails.summary.coverStartDate"
            description="quoteDetails.summary.coverStartDate.description"
            name="edit-cover-start-date-dropdown"
            value={selectedCoverStartDate?.description}
            options={coverStartDateDescriptionsFrom(coverStartDates)}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => onCoverStartDateChanged(event.target.value)}
            status={coverStartDateStatus}
            errorMessage={coverStartDateErrorMessage}
            errorTooltip={coverStartDateErrorTooltip}
          />

          <BreakInCoverDetection
            carPurchaseDate={carPurchaseDate}
            previousCoverExpiryDate={existingPolicyExpiryDate}
            coverStartDate={selectedCoverStartDate?.date}
            breakInCover={breakInCover}
            breakInCoverChanged={onBreakInCoverChanged}
            breakInCoverDeclarationAcceptance={breakInCoverDeclarationAcceptance}
            breakInCoverDeclarationAcceptanceChanged={onBreakInCoverDeclarationAnswered}
            isWithinModal
          />

          <DropdownModalQuestion
            id="edit-cover-type-question"
            title="quoteDetails.summary.coverType"
            name="edit-cover-type-dropdown"
            value={selectedCoverType}
            options={COVER_TYPE_OPTIONS}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              setSelectedCoverType(event.target.value as CoverTypeDescription);
              if (event.target.value === "Third Party") setSelectedVoluntaryExcess("0");
            }}
          />

          <DropdownModalQuestion
            id="edit-policy-excess-question"
            title="quoteDetails.summary.policyExcess"
            inputLabel="quoteDetails.summary.voluntaryExcess"
            description={{
              id: "quoteDetails.summary.policyExcess.description",
              templateVariables: {
                excess: compulsoryExcessAmount()
              }
            }}
            name="edit-policy-excess-dropdown"
            prefix="structure.currencies.gbp"
            value={selectedVoluntaryExcess}
            options={voluntaryExcessOptions}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => setSelectedVoluntaryExcess(event.target.value)}
            disabled={selectedCoverType === "Third Party"}
          />

          {canProtectNoClaimsBonus && (
            <DropdownModalQuestion
              id="edit-protect-no-claims-question"
              title="quoteDetails.summary.noClaimsProtection"
              description="quoteDetails.summary.noClaimsProtection.description"
              name="edit-protect-no-claims-dropdown"
              value={convertBooleanToConfirmation(selectedProtectNoClaimsBonus)}
              options={["No", "Yes"]}
              onChange={(event: ChangeEvent<HTMLSelectElement>) => setSelectedProtectNoClaimsBonus(event.target.value === "Yes")}
            />
          )}
        </>
      }
      footer={
        <EditCoverSummaryFooter
          onDismiss={onCancel}
          onSave={onSave}
          disabled={isUpdateDisabled()}
          loading={isQuoteLoading}
        />
      }
    />
  );
};

export default EditCoverSummary;
