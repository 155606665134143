import React, {FC, useEffect, useState} from "react";
import "./PoliciesSidebar.css";
import {KeyboardArrowDown} from "@mui/icons-material";
import {useWindowSize} from "../../../../utils/custom-hooks/UseWindowSize";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {MenuItem} from "../../../../structure/navigation/navbar/menu/MenuItems";
import {isCurrentRoute} from "../../../../utils/navigation/NavigationHelpers";
import {useLocation, useNavigate} from "react-router-dom";
import {logPortalGoogleAnalyticsEvent} from "../../../../utils/analytics/PortalAnalytics";

const LARGE_WINDOW_BREAKPOINT_WIDTH = 1024;

interface Props {
  menuItems: MenuItem[];
}

const PoliciesSidebar: FC<Props> = ({menuItems}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {width} = useWindowSize();
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setIsOpen(width >= LARGE_WINDOW_BREAKPOINT_WIDTH);
  }, [width]);

  const onMenuItemClicked = (item: MenuItem): void => {
    navigate(item.path);
    logPortalGoogleAnalyticsEvent({
      categorySuffix: item.googleAnalyticsSuffix,
      action: "Click"
    });
  };

  const menuItemComponents = menuItems.map((menuItem, index) => (
      <button
        onClick={() => onMenuItemClicked(menuItem)}
        key={index}
        className="portal-sidebar__content__item"
      >
        <div className={`portal-sidebar__content__item__title ${isCurrentRoute(menuItem.path, location.pathname) && "portal-sidebar__content__item__title--active"}`}>
          <h5>{lookupI18nString(menuItem.title)}</h5>
        </div>
      </button>
  ));

  return (
    <div className="portal-sidebar">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className={`portal-sidebar__toggle${isOpen ? " portal-sidebar__toggle--open" : ""}`}
      >
        Menu
        <KeyboardArrowDown/>
      </button>
      {isOpen &&
      <div className="portal-sidebar__content">
        {menuItemComponents}
      </div>
      }
    </div>
  );
};

export default PoliciesSidebar;
