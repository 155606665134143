import React, {FC} from "react";
import Navbar from "../../../../structure/navigation/navbar/Navbar";
import RemindersContainer from "../../shared/reminders/RemindersContainer";
import FullPageSpinner from "shared-components/dist/spinner/full-page-spinner/FullPageSpinner";

const PolicyLoading: FC = () => (
  <div data-testid="policy-loading">
    <Navbar/>
    <RemindersContainer/>
    <FullPageSpinner message="portal.spinner.message"/>
  </div>
);

export default PolicyLoading;