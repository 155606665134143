import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  conditionalFieldReset,
  ErrorPayload,
  fieldChanged,
  fieldInvalid,
  fieldReset
} from "shared-components/dist/models/form-field/FormField";
import {SerializableDateOption} from "shared/dist/models/date-option/DateOption";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {canProtectNoClaimsBonus} from "../components/previously-held-insurance/helpers/CanProtectNoClaimsBonus";
import {policyPurchased, quoteReset, storedQuoteLoaded} from "../../../../../redux/Actions";
import {COVER_TYPES, CoverTypeDescription} from "../components/cover-type/models/CoverType";
import {BreakInCover} from "../../shared/services/BreakInCoverDetector";
import {CoverDetailsFields} from "./CoverDetailsFields";
import {
  OwnOrCompanyCar,
  OwnOrCompanyCarOption
} from "../components/named-driver/own-or-company-car/OwnOrCompanyCarOption";
import {VehicleType, VehicleTypeOption} from "../components/named-driver/shared/vehicle-type/VehicleTypeOption";
import {isNoClaimsBonusValid} from "./selectors/helpers/IsNoClaimsBonusValid";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {isCompanyCarBonusValid} from "./selectors/helpers/IsCompanyCarBonusValid";

export interface ValueWithNamedDriverEligibility<T> {
  value: T;
  isEligibleForNamedDriver: boolean;
}

export interface PreviouslyHeldInsurancePayload extends ValueWithNamedDriverEligibility<boolean> {
  isVehiclePurchasedMoreThan14DaysAgo: boolean;
}

export interface OwnOrCompanyCarPayload extends ValueWithNamedDriverEligibility<OwnOrCompanyCarOption> {
  isVehiclePurchasedMoreThan14DaysAgo: boolean;
}

export const initialState: CoverDetailsFields = {
  coverStartDate: {
    status: "default",
    value: undefined
  },
  coverType: {
    status: "default",
    value: undefined
  },
  voluntaryExcess: {
    status: "default",
    value: "0",
    isActive: false
  },
  previouslyHeldInsurance: {
    status: "default",
    value: undefined
  },
  yearsNoClaimsBonus: {
    status: "default",
    value: undefined,
    isActive: false
  },
  protectNoClaimsBonus: {
    status: "default",
    value: undefined,
    isActive: false
  },
  existingPolicyExpiryDate: {
    status: "default",
    value: undefined,
    isActive: false
  },
  averageMileage: {
    status: "default",
    value: undefined,
  },
  averageBusinessMileage: {
    status: "default",
    value: undefined,
    isActive: false
  },
  breakInCover: "CANNOT_DETECT",
  breakInCoverDeclarationAcceptance: {
    status: "default",
    value: undefined,
    isActive: false
  },
  ownOrCompanyCar: {
    status: "default",
    value: undefined,
    isActive: false
  },
  isPreviousNamedDriver: {
    status: "default",
    value: undefined,
    isActive: false
  },
  isCurrentlyOrPreviouslyInsured: {
    status: "default",
    value: undefined,
    isActive: false
  },
  vehicleType: {
    status: "default",
    value: undefined,
    isActive: false
  },
  yearsNamedDriver: {
    status: "default",
    value: undefined,
    isActive: false
  },
  yearsCompanyCarBonus: {
    status: "default",
    value: undefined,
    isActive: false
  },
  companyCarDeclarationAcceptance: {
    status: "default",
    value: undefined,
    isActive: false
  },
  companyCarPolicyExpiryDate: {
    status: "default",
    value: undefined,
    isActive: false
  },
  namedDriverDeclarationAcceptance: {
    status: "default",
    value: undefined,
    isActive: false
  },
  hasEnteredNamedDriverFlow: false,
  hasVehicleBeenInsured: {
    status: "default",
    value: undefined,
    isActive: false
  },
  namedDriverOnOwnVehicle: {
    status: "default",
    value: undefined,
    isActive: false
  },
  vehiclePreviousPolicyExpiryDate: {
    status: "default",
    value: undefined,
    isActive: false
  },
};

const coverDetailsSlice = createSlice({
  name: "coverDetailsSlice",
  initialState,
  reducers: {
    coverStartDateChanged(state, action: PayloadAction<ValueWithNamedDriverEligibility<SerializableDateOption>>): void {
      fieldChanged(state.coverStartDate, action.payload.value);

      if (state.ownOrCompanyCar.value?.id !== OwnOrCompanyCar.OwnPolicy) return;

      if ((isNoClaimsBonusInvalid(state) || isCompanyCarBonusInvalid(state)) && action.payload.isEligibleForNamedDriver) {
        state.isPreviousNamedDriver.isActive = true;
        state.hasEnteredNamedDriverFlow = true;
      } else {
        conditionalFieldReset(state.isPreviousNamedDriver);
        resetNamedDriverFields(state);
        state.hasEnteredNamedDriverFlow = false;
      }
    },
    coverStartDateWarningSet(state, action: PayloadAction<ErrorPayload>): void {
      state.coverStartDate.status = "warning";
      state.coverStartDate.errorMessage = action.payload.errorMessage;
      state.coverStartDate.errorTooltip = action.payload.errorTooltip;
    },
    coverStartDateWarningReset(state): void {
      state.coverStartDate.status = "success";
      delete state.coverStartDate.errorMessage;
      delete state.coverStartDate.errorTooltip;
    },
    coverStartDateInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.coverStartDate, action.payload);
    },
    coverStartDateReset(state): void {
      state.coverStartDate = initialState.coverStartDate;
    },
    coverTypeChanged(state, action: PayloadAction<CoverTypeDescription>): void {
      const value = COVER_TYPES.find(coverType => coverType.description === action.payload);
      if (!value) return;

      fieldChanged(state.coverType, value);

      if (action.payload === "Third Party") {
        state.voluntaryExcess = initialState.voluntaryExcess;
      } else {
        state.voluntaryExcess.isActive = true;
      }
    },
    coverTypeInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.coverType, action.payload);
    },
    voluntaryExcessChanged(state, action: PayloadAction<string>): void {
      fieldChanged(state.voluntaryExcess, action.payload);
    },
    previouslyHeldInsuranceChanged(state, {payload}: PayloadAction<PreviouslyHeldInsurancePayload>): void {
      fieldChanged(state.previouslyHeldInsurance, payload.value);

      if (payload.value) {
        conditionalFieldReset(state.ownOrCompanyCar);
        state.ownOrCompanyCar.isActive = true;
        state.hasEnteredNamedDriverFlow = false;
      } else {
        conditionalFieldReset(state.ownOrCompanyCar);
        resetCompanyCarFields(state);
        state.hasEnteredNamedDriverFlow = false;
      }

      conditionalFieldReset(state.isPreviousNamedDriver);
      resetBreakInCoverFields(state);
      resetNamedDriverFields(state);
      resetNoClaimsBonusFields(state);

      if (!payload.value && payload.isEligibleForNamedDriver && !payload.isVehiclePurchasedMoreThan14DaysAgo) {
        state.isPreviousNamedDriver.isActive = true;
        state.hasEnteredNamedDriverFlow = true;
      }
    },
    previouslyHeldInsuranceInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.previouslyHeldInsurance, action.payload);
    },
    yearsNoClaimsBonusChanged(state, action: PayloadAction<ValueWithNamedDriverEligibility<string>>): void {
      fieldChanged(state.yearsNoClaimsBonus, action.payload.value);

      state.protectNoClaimsBonus.isActive = canProtectNoClaimsBonus(action.payload.value);
      if (!canProtectNoClaimsBonus(action.payload.value)) conditionalFieldReset(state.protectNoClaimsBonus);

      if (isNoClaimsBonusInvalid(state) && action.payload.isEligibleForNamedDriver) {
        state.isPreviousNamedDriver.isActive = true;
        state.hasEnteredNamedDriverFlow = true;
      } else {
        conditionalFieldReset(state.isPreviousNamedDriver);
        resetNamedDriverFields(state);
        state.hasEnteredNamedDriverFlow = false;
      }
    },
    yearsNoClaimsBonusInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.yearsNoClaimsBonus, action.payload);
    },
    protectNoClaimsBonusChanged(state, action: PayloadAction<boolean>): void {
      fieldChanged(state.protectNoClaimsBonus, action.payload);
    },
    protectNoClaimsBonusInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.protectNoClaimsBonus, action.payload);
    },
    existingPolicyExpiryDateChanged(state, action: PayloadAction<ValueWithNamedDriverEligibility<string>>): void {
      fieldChanged(state.existingPolicyExpiryDate, action.payload.value);

      if (isNoClaimsBonusInvalid(state) && action.payload.isEligibleForNamedDriver) {
        state.isPreviousNamedDriver.isActive = true;
        state.hasEnteredNamedDriverFlow = true;
      } else {
        conditionalFieldReset(state.isPreviousNamedDriver);
        resetNamedDriverFields(state);
        state.hasEnteredNamedDriverFlow = false;
      }
    },
    existingPolicyExpiryDateInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.existingPolicyExpiryDate, action.payload);
    },
    existingPolicyExpiryDateReset(state): void {
      state.existingPolicyExpiryDate.value = initialState.existingPolicyExpiryDate.value;
      state.existingPolicyExpiryDate.status = initialState.existingPolicyExpiryDate.status;
    },
    averageMileageChanged(state, action: PayloadAction<number>): void {
      fieldChanged(state.averageMileage, action.payload);
    },
    averageMileageInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.averageMileage, action.payload);
    },
    averageBusinessMileageChanged(state, action: PayloadAction<number>): void {
      fieldChanged(state.averageBusinessMileage, action.payload);
    },
    averageBusinessMileageInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.averageBusinessMileage, action.payload);
    },
    averageBusinessMileageIsActiveSet(state, action: PayloadAction<boolean>): void {
      if (action.payload) {
        state.averageBusinessMileage.isActive = true;
      } else {
        conditionalFieldReset(state.averageBusinessMileage);
      }
    },
    breakInCoverChanged(state, action: PayloadAction<BreakInCover>): void {
      if (state.breakInCover !== action.payload) fieldReset(state.breakInCoverDeclarationAcceptance);
      state.breakInCoverDeclarationAcceptance.isActive = action.payload === "DECLARATION_REQUIRED";
      state.breakInCover = action.payload;
    },
    breakInCoverDeclarationAcceptanceChanged(state, action: PayloadAction<boolean>): void {
      fieldChanged(state.breakInCoverDeclarationAcceptance, action.payload);
    },
    breakInCoverDeclarationAcceptanceInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.breakInCoverDeclarationAcceptance, action.payload);
    },
    ownOrCompanyCarChanged(state, {payload}: PayloadAction<OwnOrCompanyCarPayload>): void {
      fieldChanged(state.ownOrCompanyCar, payload.value);

      if (payload.value.id === OwnOrCompanyCar.CompanyCar) {
        conditionalFieldReset(state.isPreviousNamedDriver);
        resetNoClaimsBonusFields(state);
        resetBreakInCoverFields(state);
        resetNamedDriverFields(state);
        state.hasEnteredNamedDriverFlow = false;
      } else {
        state.yearsNoClaimsBonus.isActive = true;
        state.existingPolicyExpiryDate.isActive = true;

        conditionalFieldReset(state.isPreviousNamedDriver);
        resetBreakInCoverFields(state);
        resetNamedDriverFields(state);
        resetCompanyCarFields(state);
        state.hasEnteredNamedDriverFlow = false;
      }

      if (payload.value.id === OwnOrCompanyCar.CompanyCar && payload.isEligibleForNamedDriver && !payload.isVehiclePurchasedMoreThan14DaysAgo) {
        state.companyCarDeclarationAcceptance.isActive = true;
        state.hasEnteredNamedDriverFlow = true;
      }
    },
    ownOrCompanyCarInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.ownOrCompanyCar, action.payload);
    },
    isPreviousNamedDriverChanged(state, action: PayloadAction<boolean>): void {
      if (action.payload) {
        state.isCurrentlyOrPreviouslyInsured.isActive = true;
      } else {
        resetNamedDriverFields(state);
      }

      fieldChanged(state.isPreviousNamedDriver, action.payload);
    },
    isPreviousNamedDriverInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.isPreviousNamedDriver, action.payload);
    },
    isCurrentlyOrPreviouslyInsuredChanged(state, action: PayloadAction<boolean>): void {
      if (action.payload) {
        state.vehicleType.isActive = true;
      } else {
        conditionalFieldReset(state.vehicleType);
        conditionalFieldReset(state.yearsNamedDriver);
        conditionalFieldReset(state.namedDriverDeclarationAcceptance);
      }

      fieldChanged(state.isCurrentlyOrPreviouslyInsured, action.payload);
    },
    isCurrentlyOrPreviouslyInsuredInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.isCurrentlyOrPreviouslyInsured, action.payload);
    },
    vehicleTypeChanged(state, action: PayloadAction<VehicleTypeOption>): void {
      if (action.payload.id === VehicleType.Car) {
        state.yearsNamedDriver.isActive = true;
      } else {
        conditionalFieldReset(state.yearsNamedDriver);
        conditionalFieldReset(state.namedDriverDeclarationAcceptance);
      }

      fieldChanged(state.vehicleType, action.payload);
    },
    vehicleTypeInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.vehicleType, action.payload);
    },
    yearsNamedDriverChanged(state, action: PayloadAction<Duration>): void {
      fieldChanged(state.yearsNamedDriver, action.payload);

      if (action.payload.years !== 0) {
        state.namedDriverDeclarationAcceptance.isActive = true;
      } else {
        conditionalFieldReset(state.namedDriverDeclarationAcceptance);
      }
    },
    yearsNamedDriverInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.yearsNamedDriver, action.payload);
    },
    yearsCompanyCarBonusChanged(state, action: PayloadAction<Duration>): void {
      fieldChanged(state.yearsCompanyCarBonus, action.payload);

      state.companyCarPolicyExpiryDate.isActive = true;
    },
    yearsCompanyCarBonusInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.yearsCompanyCarBonus, action.payload);
    },
    companyCarDeclarationAcceptanceChanged(state, action: PayloadAction<boolean>): void {
      if (action.payload === false) {
        conditionalFieldReset(state.yearsCompanyCarBonus);
        conditionalFieldReset(state.companyCarPolicyExpiryDate);

        state.isPreviousNamedDriver.isActive = true;
      } else {
        conditionalFieldReset(state.isPreviousNamedDriver);
        resetNamedDriverFields(state);

        state.yearsCompanyCarBonus.isActive = true;
      }

      fieldChanged(state.companyCarDeclarationAcceptance, action.payload);
    },
    companyCarDeclarationAcceptanceInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.companyCarDeclarationAcceptance, action.payload);
    },
    companyCarPolicyExpiryDateChanged(state, action: PayloadAction<string>): void {
      fieldChanged(state.companyCarPolicyExpiryDate, action.payload);

      if (isCompanyCarBonusInvalid(state)) {
        state.isPreviousNamedDriver.isActive = true;
      } else {
        conditionalFieldReset(state.isPreviousNamedDriver);
        resetNamedDriverFields(state);
      }
    },
    companyCarPolicyExpiryDateInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.companyCarPolicyExpiryDate, action.payload);
    },
    companyCarPolicyExpiryDateReset(state): void {
      fieldReset(state.companyCarPolicyExpiryDate);
    },
    namedDriverDeclarationAcceptanceChanged(state, action: PayloadAction<boolean>): void {
      fieldChanged(state.namedDriverDeclarationAcceptance, action.payload);
    },
    namedDriverDeclarationAcceptanceInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.namedDriverDeclarationAcceptance, action.payload);
    },
    hasVehicleBeenInsuredChanged(state, action: PayloadAction<boolean>): void {
      fieldChanged(state.hasVehicleBeenInsured, action.payload);

      if (action.payload) {
        state.namedDriverOnOwnVehicle.isActive = true;
      } else {
        conditionalFieldReset(state.namedDriverOnOwnVehicle);
        conditionalFieldReset(state.vehiclePreviousPolicyExpiryDate);
        conditionalFieldReset(state.isPreviousNamedDriver);
        resetNamedDriverFields(state);
        resetCompanyCarFields(state);
      }
    },
    hasVehicleBeenInsuredMadeActive(state): void {
      state.hasVehicleBeenInsured.isActive = true;
      state.hasEnteredNamedDriverFlow = true;
    },
    hasVehicleBeenInsuredInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.hasVehicleBeenInsured, action.payload);
    },
    namedDriverOnOwnVehicleChanged(state, action: PayloadAction<boolean>): void {
      fieldChanged(state.namedDriverOnOwnVehicle, action.payload);

      if (action.payload) {
        state.vehiclePreviousPolicyExpiryDate.isActive = true;
      } else {
        conditionalFieldReset(state.vehiclePreviousPolicyExpiryDate);
        conditionalFieldReset(state.isPreviousNamedDriver);
        resetNamedDriverFields(state);
        resetCompanyCarFields(state);
      }
    },
    namedDriverOnOwnVehicleInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.namedDriverOnOwnVehicle, action.payload);
    },
    vehiclePreviousPolicyExpiryDateChanged(state, action: PayloadAction<string>): void {
      fieldChanged(state.vehiclePreviousPolicyExpiryDate, action.payload);
    },
    vehiclePreviousPolicyExpiryDateInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.vehiclePreviousPolicyExpiryDate, action.payload);
    },
    vehiclePreviousPolicyExpiryDateReset(state): void {
      fieldReset(state.vehiclePreviousPolicyExpiryDate);
    },
    namedDriverAndCompanyCarQuestionsReset(state): void {
      conditionalFieldReset(state.isPreviousNamedDriver);
      resetNamedDriverFields(state);
      resetCompanyCarFields(state);
    },
    namedDriverQuestionsReset(state): void {
      conditionalFieldReset(state.isPreviousNamedDriver);
      resetNamedDriverFields(state);
    },
    namedDriverAndCompanyCarQuestionsMadeActive(state): void {
      if (state.previouslyHeldInsurance.value === false || isNoClaimsBonusInvalid(state)) {
        state.isPreviousNamedDriver.isActive = true;
        state.hasEnteredNamedDriverFlow = true;
      }

      if (state.ownOrCompanyCar.value?.id === OwnOrCompanyCar.CompanyCar) {
        state.companyCarDeclarationAcceptance.isActive = true;
        state.hasEnteredNamedDriverFlow = true;
      }
    },
    namedOnOwnVehicleNamedDriverQuestionsMadeActive(state): void {
      conditionalFieldReset(state.isPreviousNamedDriver);
      state.isCurrentlyOrPreviouslyInsured.isActive = true;
      state.hasEnteredNamedDriverFlow = true;
    },
    breakInCoverQuestionsReset(state): void {
      resetBreakInCoverFields(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(quoteReset, () => initialState);
    builder.addCase(policyPurchased, () => initialState);
    builder.addCase(storedQuoteLoaded, (state, {payload}) => {
      state = {
        ...initialState,
        ...payload.quoteArguments.coverDetails
      };

      populateUnansweredQuestionsFromOldSavedQuotes(state);
      if (["9", "10+"].includes(state.yearsNoClaimsBonus.value ?? "")) state.yearsNoClaimsBonus.value = "9+";

      return state;
    });
  }
});

export const {
  coverStartDateChanged,
  coverStartDateWarningSet,
  coverStartDateWarningReset,
  coverStartDateInvalid,
  coverStartDateReset,
  coverTypeChanged,
  coverTypeInvalid,
  voluntaryExcessChanged,
  previouslyHeldInsuranceChanged,
  previouslyHeldInsuranceInvalid,
  yearsNoClaimsBonusChanged,
  yearsNoClaimsBonusInvalid,
  protectNoClaimsBonusChanged,
  protectNoClaimsBonusInvalid,
  existingPolicyExpiryDateChanged,
  existingPolicyExpiryDateInvalid,
  existingPolicyExpiryDateReset,
  averageMileageChanged,
  averageMileageInvalid,
  averageBusinessMileageChanged,
  averageBusinessMileageInvalid,
  averageBusinessMileageIsActiveSet,
  breakInCoverChanged,
  breakInCoverDeclarationAcceptanceChanged,
  breakInCoverDeclarationAcceptanceInvalid,
  ownOrCompanyCarChanged,
  ownOrCompanyCarInvalid,
  isPreviousNamedDriverChanged,
  isPreviousNamedDriverInvalid,
  isCurrentlyOrPreviouslyInsuredChanged,
  isCurrentlyOrPreviouslyInsuredInvalid,
  vehicleTypeChanged,
  vehicleTypeInvalid,
  yearsNamedDriverChanged,
  yearsNamedDriverInvalid,
  yearsCompanyCarBonusChanged,
  yearsCompanyCarBonusInvalid,
  companyCarDeclarationAcceptanceChanged,
  companyCarDeclarationAcceptanceInvalid,
  companyCarPolicyExpiryDateChanged,
  companyCarPolicyExpiryDateInvalid,
  companyCarPolicyExpiryDateReset,
  namedDriverDeclarationAcceptanceChanged,
  namedDriverDeclarationAcceptanceInvalid,
  hasVehicleBeenInsuredChanged,
  hasVehicleBeenInsuredInvalid,
  vehiclePreviousPolicyExpiryDateChanged,
  vehiclePreviousPolicyExpiryDateInvalid,
  vehiclePreviousPolicyExpiryDateReset,
  namedDriverAndCompanyCarQuestionsReset,
  namedDriverAndCompanyCarQuestionsMadeActive,
  hasVehicleBeenInsuredMadeActive,
  namedDriverOnOwnVehicleChanged,
  namedDriverOnOwnVehicleInvalid,
  breakInCoverQuestionsReset,
  namedDriverQuestionsReset,
  namedOnOwnVehicleNamedDriverQuestionsMadeActive
} = coverDetailsSlice.actions;

export const coverDetailsReducer = coverDetailsSlice.reducer;

export const coverDetailsSliceName = coverDetailsSlice.name;

const isNoClaimsBonusInvalid = (state: CoverDetailsFields): boolean => {
  if (state.yearsNoClaimsBonus.value === undefined || state.existingPolicyExpiryDate.value === undefined || state.coverStartDate.value === undefined) return false;

  return !isNoClaimsBonusValid(
    parseInt(state.yearsNoClaimsBonus.value),
    new Date(state.existingPolicyExpiryDate.value),
    new Date(state.coverStartDate.value.date)
  );
};

const isCompanyCarBonusInvalid = (state: CoverDetailsFields): boolean => {
  if (state.coverStartDate.value === undefined || state.companyCarPolicyExpiryDate.value === undefined) return false;

  return !isCompanyCarBonusValid(
    new Date(state.coverStartDate.value.date),
    new Date(state.companyCarPolicyExpiryDate.value)
  );
};

const resetNoClaimsBonusFields = (state: CoverDetailsFields): void => {
  conditionalFieldReset(state.yearsNoClaimsBonus);
  conditionalFieldReset(state.protectNoClaimsBonus);
  conditionalFieldReset(state.existingPolicyExpiryDate);
};

const resetNamedDriverFields = (state: CoverDetailsFields): void => {
  conditionalFieldReset(state.namedDriverDeclarationAcceptance);
  conditionalFieldReset(state.isCurrentlyOrPreviouslyInsured);
  conditionalFieldReset(state.vehicleType);
  conditionalFieldReset(state.yearsNamedDriver);
};

const resetCompanyCarFields = (state: CoverDetailsFields): void => {
  conditionalFieldReset(state.companyCarDeclarationAcceptance);
  conditionalFieldReset(state.yearsCompanyCarBonus);
  conditionalFieldReset(state.companyCarPolicyExpiryDate);
};

const resetBreakInCoverFields = (state: CoverDetailsFields): void => {
  conditionalFieldReset(state.hasVehicleBeenInsured);
  conditionalFieldReset(state.namedDriverOnOwnVehicle);
  conditionalFieldReset(state.vehiclePreviousPolicyExpiryDate);
};

const populateUnansweredQuestionsFromOldSavedQuotes = (state: CoverDetailsFields): void => {
  if (state.ownOrCompanyCar.value === undefined && state.yearsNoClaimsBonus.value) {
    state.ownOrCompanyCar = {
      isActive: true,
      status: "success",
      value: {
        id: OwnOrCompanyCar.OwnPolicy,
        description: lookupI18nString("coverDetails.ownOrCompanyCarQuestion.option.ownPolicy")
      }
    };
  }
};