import {createAppAsyncThunk} from "../../../../redux/CreateAppAsyncThunk";
import {
  quoteDetailsSliceName,
  removeQuoteValidationErrors,
  resetQuoteValidationSectionBeingResolved
} from "../../vehicle/your-quote/redux/QuoteDetailsSlice";
import {
  selectAllValidationErrorsForSectionBeingResolved, selectValidationErrorsForDriverBeingEdited
} from "../../vehicle/your-quote/redux/selectors/QuoteDetailsSelectors";

export const exitResolveValidationErrorFlow = createAppAsyncThunk(
  `${quoteDetailsSliceName}/exitResolveValidationErrorFlow`,
  (_, {dispatch, getState}) => {
    const errorsToRemove = selectAllValidationErrorsForSectionBeingResolved(getState());

    dispatch(removeQuoteValidationErrors(errorsToRemove));
    dispatch(resetQuoteValidationSectionBeingResolved());
  }
);

export const handleAdditionalDriverChangeForValidationFlow = createAppAsyncThunk(
  `${quoteDetailsSliceName}/handleAdditionalDriverChangeForValidationFlow`,
  async (_, {dispatch, getState}) => {
    const state = getState();
    const sectionBeingResolvedForAggregatorQuote = state.quoteDetails.quoteValidationSectionBeingResolved;

    if (sectionBeingResolvedForAggregatorQuote === "ADDITIONAL_DRIVER") {
      await dispatch(exitResolveValidationErrorFlow());
    } else {
      const errorsToRemove = selectValidationErrorsForDriverBeingEdited(state);

      await dispatch(removeQuoteValidationErrors(errorsToRemove));
    }
  }
);