import React, {FC} from "react";
import ProhibitedFuelTypeStop from "../../../shared/stops/prohibited-fuel-type-stop/ProhibitedFuelTypeStop";
import {shouldShowIncompleteVehicleProhibitedFuelTypeStopSelector} from "../../redux/selectors/ManualVehicleLookupFormSelectors";
import "./IncompleteVehicleProhibitedFuelTypeStop.css";
import {useAppSelector} from "../../../../../../redux/Hooks";

const IncompleteVehicleProhibitedFuelTypeStop: FC = () => {
  const isVisible = useAppSelector(shouldShowIncompleteVehicleProhibitedFuelTypeStopSelector);

  return (
    <div className="incomplete-vehicle-stop">
      <ProhibitedFuelTypeStop
        isVisible={isVisible}
        isSubContainer={false}
      />
    </div>
  );
};

export default IncompleteVehicleProhibitedFuelTypeStop;