import {AbiListItem} from "shared/dist/generated/graphql/resolvers-types";
import {GenderOption} from "../../../shared/questions/gender/Genders";
import {MaritalStatusOption} from "../../../shared/questions/martial-status/MaritalStatusOption";
import {RelationshipToProposerOption} from "../components/relationship-to-proposer/model/RelationshipToProposerOption";
import {EmploymentStatusOption} from "../../../shared/questions/employment/models/EmploymentStatusOption";
import {CustomisedAbiListItem} from "shared-components/dist/models/CustomisedAbiListItem";
import {ClassOfUseOption} from "../../../shared/questions/class-of-use/ClassOfUseOption";
import {LicenceCountryOfIssue} from "../../../shared/questions/licence-country-of-issue/LicenceCountryOfIssue";
import {LicenceTypeOption} from "../../../shared/questions/licence-type/LicenceTypeOption";
import {MedicalConditionOption} from "../../../shared/questions/medical-condition/MedicalConditionOption";
import {
  Conviction,
  SerializableConviction,
  toConviction,
  toSerializableConviction
} from "../../../shared/questions/convictions/models/Conviction";
import {Claim, SerializableClaim, toClaim, toSerializableClaim} from "../../../shared/questions/claims/models/Claims";

export interface AdditionalDriverEmployment<T extends EmploymentStatusOption | undefined> {
  employmentStatus: T;
  industry?: CustomisedAbiListItem;
  job?: CustomisedAbiListItem;
  workedInPastYear?: boolean;
}

export interface SharedAdditionalDriverProps {
  id: string;
  title: AbiListItem;
  firstName: string;
  surname: string;
  householdNumberOfCars?: string;
  gender: GenderOption;
  hasUkResidencyFromBirth: boolean;
  ukResidencyDuration?: Duration;
  maritalStatus: MaritalStatusOption;
  primaryEmployment: AdditionalDriverEmployment<EmploymentStatusOption>
  hasSecondaryEmployment: boolean;
  secondaryEmployment: AdditionalDriverEmployment<EmploymentStatusOption | undefined>
  classOfUse: ClassOfUseOption;
  licenceCountryOfIssue: LicenceCountryOfIssue;
  licenceType: LicenceTypeOption;
  licenceDuration: Duration;
  hasMedicalCondition: boolean;
  medicalCondition?: MedicalConditionOption;
  relationshipToProposer: RelationshipToProposerOption;
  hasNonMotoringConvictions: boolean;
  hasInsuranceDeclined: boolean;
}

export interface AdditionalDriver extends SharedAdditionalDriverProps {
  dateOfBirth: Date;
  claims: Claim[];
  convictions: Conviction[];
}

export interface SerializableAdditionalDriver extends SharedAdditionalDriverProps {
  dateOfBirth: string;
  claims: SerializableClaim[];
  convictions: SerializableConviction[];
}

export interface AggregatorAdditionalDriver extends SerializableAdditionalDriver {
  isMainDriver: boolean;
}

export const toAdditionalDriver = (serializableAdditionalDriver: SerializableAdditionalDriver): AdditionalDriver => {
  return {
    ...serializableAdditionalDriver,
    dateOfBirth: new Date(serializableAdditionalDriver.dateOfBirth),
    claims: serializableAdditionalDriver.claims.map(toClaim),
    convictions: serializableAdditionalDriver.convictions.map(toConviction)
  };
};

export const toSerializableAdditionalDriver = (additionalDriver: AdditionalDriver): SerializableAdditionalDriver => {
  return {
    ...additionalDriver,
    dateOfBirth: additionalDriver.dateOfBirth.toISOString(),
    claims: additionalDriver.claims.map(toSerializableClaim),
    convictions: additionalDriver.convictions.map(toSerializableConviction)
  };
};