import {FormField} from "shared-components/dist/models/form-field/FormField";
import {FC, useEffect, useState} from "react";
import InlineQuestion from "../../../../../../../../../structure/questions/inline-question/InlineQuestion";
import EmailAddressInput from "shared-components/dist/form/email-address-input/EmailAddressInput";
import PhoneNumberInput from "shared-components/dist/form/phone-number-input/PhoneNumberInput";
import "./ContactDetailsForm.css";
import {Close} from "@mui/icons-material";
import Button from "shared-components/dist/buttons/button/Button";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {doesPolicyHolderExistQuery} from "../../../../../../../../../graphql/queries/quote/does-policy-holder-exist/DoesPolicyHolderExistQuery";
import {logger} from "../../../../../../../../../utils/logging/Logger";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";

interface Props {
  proposerEmailAddress: FormField<string>;
  proposerPhoneNumber: FormField<string>;
  onSave: (newEmail?: string, newPhoneNumber?: string) => void;
  onClose: () => void;
  show: boolean;
}

export const ContactDetailsForm: FC<Props> = ({proposerEmailAddress, proposerPhoneNumber, show, onClose, onSave}) => {
  const [newEmail, setNewEmail] = useState(proposerEmailAddress);
  const [newPhoneNumber, setNewPhoneNumber] = useState(proposerPhoneNumber);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setNewEmail(proposerEmailAddress);
    setNewPhoneNumber(proposerPhoneNumber);
  }, [show, proposerEmailAddress, proposerPhoneNumber]);

  const onEmailComplete = (value: string): void => setNewEmail({
    value,
    status: "success",
    errorMessage: undefined
  });

  const onEmailInvalid = (): void => setErrorMessageForNewEmail("quoteDetails.contactDetails.email.error.invalidEntry");

  const onPhoneNumberComplete = (value: string): void => setNewPhoneNumber({
    value,
    status: "success",
    errorMessage: undefined
  });

  const onPhoneNumberInvalid = (): void => setNewPhoneNumber({
    ...newPhoneNumber,
    status: "error",
    errorMessage: "personalDetails.phoneNumberQuestion.errors.invalidPhoneNumber"
  });

  const onSubmit = async (): Promise<void> => {
    setIsLoading(true);
    const errorForNewEmail = await getErrorKeyForEmail(newEmail.value);

    if (errorForNewEmail) {
      setErrorMessageForNewEmail(errorForNewEmail);
    } else {
      onSave(newEmail.value, newPhoneNumber.value);
      onClose();
    }
    setIsLoading(false);
  };

  const setErrorMessageForNewEmail = (key: TranslationKey): void => setNewEmail({
    ...newEmail,
    status: "error",
    errorMessage: key
  });

  const saveButtonIsDisabled =
    newEmail.status === "error"
    || newPhoneNumber.status === "error"
    || (newPhoneNumber.value === proposerPhoneNumber.value && newEmail.value === proposerEmailAddress.value);

  if (!show) return null;

  return (
    <div className="contact-details__form">
      <div className="contact-details__form__header">
        <h5>{lookupI18nString("quoteDetails.contactDetails.form.title")}</h5>
        <button onClick={() => onClose()}>
          <Close/>
        </button>
      </div>
      <div className="contact-details__form__fields">
        <InlineQuestion
          label="quoteDetails.contactDetails.email.label"
          id="email-address-input"
          status={newEmail.status}
          errorMessage={newEmail.errorMessage}
        >
          <EmailAddressInput
            name="email-address-input"
            value={newEmail.value}
            onComplete={onEmailComplete}
            onInvalid={onEmailInvalid}
            placeholder="quoteDetails.contactDetails.email.placeholder"
          />
        </InlineQuestion>

        <InlineQuestion
          label="quoteDetails.contactDetails.phone.label"
          id="phone-number-input"
          status={newPhoneNumber.status}
          errorMessage={newPhoneNumber.errorMessage}
        >
          <PhoneNumberInput
            name="phone-number-input"
            value={newPhoneNumber.value}
            onComplete={onPhoneNumberComplete}
            onInvalid={onPhoneNumberInvalid}
            placeholder="quoteDetails.contactDetails.phone.placeholder"
            invalidateIfFieldOnlyContainsCountryCode
          />
        </InlineQuestion>
      </div>
      <Button
        className="contact-details__form__save-button"
        onClick={onSubmit}
        loading={isLoading}
        disabled={saveButtonIsDisabled}
      >
        {lookupI18nString("quoteDetails.contactDetails.form.save")}
      </Button>
    </div>
  );
};

const getErrorKeyForEmail = async (email?: string): Promise<TranslationKey | undefined> => {
  if (!email || email === "") return "quoteDetails.contactDetails.email.error.genericFailure";

  try {
    return await doesPolicyHolderExistQuery({email})
      ? "quoteDetails.contactDetails.email.error.accountAlreadyExists"
      : undefined;
  } catch (error) {
    logger.error(`Unable to check if policy holder exists for email: ${email}`, error);
    return "quoteDetails.contactDetails.email.error.genericFailure";
  }
};
