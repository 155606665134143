import React, {FC} from "react";
import DropdownSubQuestion
  from "../../../../../../../../structure/questions/sub-question/variants/dropdown-sub-question/DropdownSubQuestion";
import {Status} from "shared-components/dist/status-indicator/Status";
import {
  convictionOffenceDescriptionsFrom,
  convictionOffencesFrom
} from "../helpers/DropdownOptions";
import {ConvictionOffenceItem} from "shared/dist/generated/graphql/resolvers-types";
import {useConvictionOffences} from "../../../../../../../../graphql/queries/convictions/conviction-offences/ConvictionOffencesLookup";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";

interface Props {
  onChange: (value: ConvictionOffenceItem | undefined) => void;
  status: Status;
  value: ConvictionOffenceItem | undefined;
  typeId: string;
  isAdditionalDriverForm: boolean;
  tooltip?: TooltipProps;
}

const ConvictionOffenceSubQuestionDropdown: FC<Props> = (
  {
    onChange,
    status,
    value,
    typeId,
    isAdditionalDriverForm,
    tooltip
  }
) => {
  const {error, loading, data} = useConvictionOffences(typeId);

  function getConvictionOffence(selectedOffence: string): ConvictionOffenceItem | undefined {
    if (loading || !data) return;

    return data.find(offence => offence.id === selectedOffence);
  }

  const fetchError = !loading && error !== undefined;

  function getErrorMessage(): TranslationKey {
    return fetchError
      ? "personalDetails.convictionsQuestions.subQuestion.offence.fetchError"
      : "quote.errors.questionIncomplete";
  }

  return (
    <DropdownSubQuestion
      id="conviction-offence-sub-question"
      title={
        isAdditionalDriverForm
          ? "additionalDriver.convictionsQuestions.subQuestion.offence.title"
          : "personalDetails.convictionsQuestions.subQuestion.offence.title"
      }
      onChange={value => onChange(getConvictionOffence(value))}
      options={!loading && data ? convictionOffencesFrom(data) : []}
      displayValues={!loading && data ? convictionOffenceDescriptionsFrom(data) : []}
      value={value?.id}
      status={fetchError ? "error" : status}
      errorMessage={getErrorMessage()}
      disabled={loading || error !== undefined}
      tooltip={tooltip}
    />
  );
};

export default ConvictionOffenceSubQuestionDropdown;
