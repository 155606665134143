import {FC} from "react";
import "./AggregatorQuoteValidationFailureCards.css";
import YourCarQuoteValidationFailureTable
  from "../aggregator-quote-validation-failure-table/YourCarQuoteValidationFailureTable";
import YourDetailsQuoteValidationFailureTable
  from "../aggregator-quote-validation-failure-table/YourDetailsQuoteValidationFailureTable";
import YourCoverQuoteValidationFailureTable
  from "../aggregator-quote-validation-failure-table/YourCoverQuoteValidationFailureTable";
import AdditionalDriversQuoteValidationFailureTable
  from "../aggregator-quote-validation-failure-table/additional-drivers-quote-validation-failure-table/AdditionalDriversQuoteValidationFailureTable";
import {useHasQuoteValidationErrors} from "../../../vehicle/your-quote/hooks/hasQuoteValidationErrors";

const AggregatorQuoteValidationFailureCards: FC = () => {
  const quoteHasValidationErrors = useHasQuoteValidationErrors();

  if (!quoteHasValidationErrors) return null;

  return (
    <div className="aggregator-quote-validation-failure-cards" data-testid="aggregator-quote-validation-failure-cards">
      <YourCarQuoteValidationFailureTable/>
      <YourDetailsQuoteValidationFailureTable/>
      <YourCoverQuoteValidationFailureTable/>
      <AdditionalDriversQuoteValidationFailureTable/>
    </div>
  );
};

export default AggregatorQuoteValidationFailureCards;