import {ReduxQuestion} from "../../Questions";
import {surnameChanged, surnameInvalid} from "../../../../pages/quote/vehicle/your-details/redux/PersonalDetailsSlice";
import {hasValidName} from "shared-components/dist/form/name-input-field/validation/NameValidator";

export const surname: ReduxQuestion<string> = {
  selector: state => state.personalDetails.surname,
  onChange: surnameChanged,
  onInvalid: surnameInvalid,
  validators: [
    hasValidName("personalDetails.surnameQuestion.errors.invalidEntry")
  ]
};