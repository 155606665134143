import {useAppSelector} from "../../../../../../../../redux/Hooks";
import {useEffect, useState} from "react";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {StoredPolicyExtra} from "../../../../../your-quote/models/StoredPolicyExtra";
import {Price} from "shared/dist/generated/graphql/resolvers-types";

export interface SelectedLoan {
  apr?: number;
  monthlyPaymentAmount?: Price,
  numberOfInstalments?: number
}

interface QuotePrices {
  totalUnfinancedAmountPayable?: Price;
  premiumAmount?: Price;
  totalExtrasAmount?: Price;
  adminFee?: Price;
  compulsoryExcess?: Price;
  voluntaryExcess?: Price;
  premiumPriceBreakdownOriginalPrice?: Price;
  premiumPriceBreakdownDiscount?: Price;
  monthlyPaymentsAvailable?: boolean;
  selectedLoanTotalWithoutExtras?: Price;
  selectedLoan: SelectedLoan,
  policyExtras?: StoredPolicyExtra[]
}

export function useQuotePriceProps(): QuotePrices {
  const [quotePrices, setQuotePrices] = useState<QuotePrices>({
    monthlyPaymentsAvailable: false,
    selectedLoan: {
      apr: 0,
      monthlyPaymentAmount: {amount: lookupI18nString("quoteDetails.depositAndPayments.placeholder"), currency: "GBP"},
      numberOfInstalments: 10
    }
  });

  const totalUnfinancedAmountPayable = useAppSelector(state => state.quoteDetails.totalUnfinancedAmountPayable);
  const monthlyPaymentsAvailable = useAppSelector(state => state.quoteDetails.prospectiveLoansWithPrice?.length > 0);
  const selectedLoan = useAppSelector(state => state.quoteDetails.selectedLoanWithPrice);
  const totalExtrasAmount = useAppSelector(state => state.quoteDetails.totalExtrasPrice);
  const premiumAmount = useAppSelector(state => state.quoteDetails.premiumAsPrice);
  const premiumBreakDown = useAppSelector(state => state.quoteDetails.premiumPriceBreakdownDetails);
  const compulsoryExcess = useAppSelector(state => state.quoteDetails.compulsoryExcessAsPrice);
  const voluntaryExcess = useAppSelector(state => state.quoteDetails.voluntaryExcessAsPrice);
  const policyExtras = useAppSelector(state => state.quoteDetails.policyExtras);

  useEffect(() => {
    const premiumPriceBreakdownOriginalPrice = premiumBreakDown?.originalPrice;
    const premiumPriceBreakdownDiscount = premiumBreakDown?.discount;
    const adminFee = premiumBreakDown?.adminFee;

    setQuotePrices({
        totalUnfinancedAmountPayable,
        totalExtrasAmount,
        policyExtras,
        premiumAmount,
        premiumPriceBreakdownOriginalPrice,
        premiumPriceBreakdownDiscount,
        adminFee,
        compulsoryExcess,
        voluntaryExcess,
        monthlyPaymentsAvailable,
        selectedLoan: {
          apr: selectedLoan?.apr,
          monthlyPaymentAmount: selectedLoan?.subsequentInstalmentAmounts,
          numberOfInstalments: selectedLoan?.numberOfInstalments
        }
      });
  }, [
    totalUnfinancedAmountPayable,
    totalExtrasAmount,
    premiumAmount,
    premiumBreakDown,
    compulsoryExcess,
    voluntaryExcess,
    selectedLoan,
    monthlyPaymentsAvailable,
    policyExtras,
  ]);

  return quotePrices;
}
