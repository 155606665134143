import React, {FC, ReactChild} from "react";
import "./CustomerPortalContentLayout.css";
import PageFooter from "../../footer/PageFooter";

interface Props {
  header: ReactChild;
  mainContent: ReactChild;
  className?: string;
}

const CustomerPortalContentLayout: FC<Props> = (
  {
    header,
    mainContent,
    className,
  }
) => (
  <div className="customer-portal-content">
    <div className="customer-portal-content__wrapper">
      <div className="customer-portal-content__header">
        {header}
      </div>

      <div className="customer-portal-content__container__wrapper">
        <div className="customer-portal-content__container__wrapper__background"></div>
        <div className={`customer-portal-content__container${className ? `__${className}` : ""}`}>

          <div className={`customer-portal-content__container${className ? `__${className}` : ""}__content`}>
            {mainContent}
          </div>

        </div>
      </div>
    </div>

    <PageFooter isPortal/>
  </div>
);

export default CustomerPortalContentLayout;
