import React, {FC, useEffect, useState} from "react";
import "./YourQuote.css";
import QuoteStep from "../../../shared/quote-step/QuoteStep";
import {quoteSteps} from "../../../shared/quote-step/QuoteSteps";
import SaveQuoteSidebar from "../shared/save-quote-sidebar/SaveQuoteSidebar";
import {isYourQuoteValidSelector} from "../../redux/selectors/QuoteDetailsSelectors";
import QuoteSummary from "../../../shared/quote-summary/QuoteSummary";
import {scrollToElement, scrollToTop} from "../../../../../../utils/navigation/ScrollToHelpers";
import QuoteLoading from "../../../shared/quote-loading/QuoteLoading";
import {QuoteRetrievalStatus} from "../../models/QuoteRetrievalStatus";
import {useEffectOnFirstRender} from "shared-components/dist/utils/hooks/UseEffectOnFirstRender";
import {useLocation, useNavigate} from "react-router-dom";
import {logYourQuoteGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {useFeatureFlags} from "shared-components/dist/feature-flags/hooks/UseFeatureFlags";
import CoverStartDateExpiredModal from "../saved/components/cover-start-date-expired-modal/CoverStartDateExpiredModal";
import {useExpiredQuoteStartDateDetection} from "../../hooks/ExpiredQuoteStartDateValidation";
import {useProposerEmailIsUniqueValidation} from "../../hooks/ProposerEmailIsUniqueValidation";
import {flagUnansweredQuoteDetailsQuestions} from "../../validation/FlagUnansweredQuoteDetailsQuestions";
import Button from "shared-components/dist/buttons/button/Button";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import CheaperQuoteNotificationBanner from "../shared/cheaper-quote-notification-banner/CheaperQuoteNotificationBanner";
import {useValidateWholeQuoteOnLoad} from "../../hooks/ValidateWholeQuoteOnLoad";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";
import {emitQuotePageEvent,} from "../../../../../../utils/analytics/service/TagManagerService";
import {refreshQuotes} from "../../../shared/redux/RefreshQuotes";
import {
  hasMultipleQuotesSelector,
  shouldAllowUserToCompareQuotesSelector
} from "../../redux/selectors/CompareYourQuotesSelectors";
import {isAggregatorQuoteSelector} from "../../redux/selectors/AggregatorQuoteSelectors";
import {
  useDetectShouldShowAggregatorUnansweredQuestionsModal,
} from "../../hooks/UseDetectShouldShowAggregatorUnansweredQuestionsModal";
import AggregatorMissingAnswersModal from "../../../../../aggregator-loading/missing-answers-modal/AggregatorMissingAnswersModal";
import {useHasQuoteValidationErrors} from "../../hooks/hasQuoteValidationErrors";
import {resetQuoteValidationSectionBeingResolved} from "../../redux/QuoteDetailsSlice";
import {selectPaymentNavigationRoute} from "../../../../../../redux/selectors/PolicyPaymentSelectors";

type PageLoadValidationStatus = "DEFAULT" | "CAN_VALIDATE" | "VALIDATED";

const YourQuote: FC = () => {
  const isValidating = useValidateWholeQuoteOnLoad();
  const dispatch = useAppDispatch();
  const firstName = useAppSelector(state => state.personalDetails.firstName.value);
  const isYourQuoteValid = useAppSelector(isYourQuoteValidSelector);
  const paymentNavigationRoute = useAppSelector(selectPaymentNavigationRoute);
  const quoteRetrievalStatus = useAppSelector(state => state.quoteDetails.quoteRetrievalStatus);
  const hasMultipleQuotes = useAppSelector(hasMultipleQuotesSelector);
  const shouldAllowUserToCompareQuotes = useAppSelector(shouldAllowUserToCompareQuotesSelector);
  const emailAddress = useAppSelector(state => state.personalDetails.proposerEmailAddress.value);
  const phoneNumber = useAppSelector(state => state.personalDetails.phoneNumber.value);
  const isRequoteRequired = useAppSelector(state => state.quoteDetails.isRequoteRequired);
  const isAggregatorQuote = useAppSelector(isAggregatorQuoteSelector);
  const hasQuoteValidationErrors = useHasQuoteValidationErrors();
  const location = useLocation();
  const {monthlyPaymentsFlag} = useFeatureFlags();
  const navigate = useNavigate();
  const [hasRunPageLoadValidation, setHasRunPageLoadValidation] = useState<PageLoadValidationStatus>("DEFAULT");

  const [coverStartDateExpiredModalVisible, setCoverStartDateExpiredModalVisible] = useState(false);
  const {
    validateQuoteStartDate,
    isCoverStartDateValid
  } = useExpiredQuoteStartDateDetection(shouldShowCoverStartDateModal => {
    setCoverStartDateExpiredModalVisible(shouldShowCoverStartDateModal && !shouldShowAggregatorUnansweredQuestionsModal);
  });

  const {validateProposerEmail, isProposerEmailValid} = useProposerEmailIsUniqueValidation();
  const isFormValid = isProposerEmailValid && isYourQuoteValid && isCoverStartDateValid && !hasQuoteValidationErrors;

  const scrollToQuoteValidationCards = (): void => {
    if (hasQuoteValidationErrors) {
      scrollToElement("aggregator-quote-validation-failure-cards");
    }
  };

  useEffectOnFirstRender(() => {
    scrollToTop();
    emitQuotePageEvent({emailAddress, phoneNumber});
  });

  useEffect(() => {
    if (isRequoteRequired && !hasQuoteValidationErrors) {
      dispatch(refreshQuotes({monthlyPaymentsEnabled: monthlyPaymentsFlag}));
    }

    dispatch(resetQuoteValidationSectionBeingResolved());
    // we only want this to run when this page has been navigated to
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const [shouldShowAggregatorUnansweredQuestionsModal, setShouldShowAggregatorUnansweredQuestionsModal] = useState(false);
  useDetectShouldShowAggregatorUnansweredQuestionsModal(setShouldShowAggregatorUnansweredQuestionsModal);

  useEffect(() => {
    if (hasRunPageLoadValidation === "VALIDATED") return;
    if (quoteRetrievalStatus === QuoteRetrievalStatus.SUCCESS && hasRunPageLoadValidation === "CAN_VALIDATE") {
      setHasRunPageLoadValidation("VALIDATED");
      validateQuoteStartDate();
    }
    if (quoteRetrievalStatus === QuoteRetrievalStatus.SUCCESS) setHasRunPageLoadValidation("CAN_VALIDATE");
  }, [quoteRetrievalStatus, validateQuoteStartDate, hasRunPageLoadValidation]);

  const previousRoute = (): string => {
    if (hasMultipleQuotes) {
      return quoteSteps.yourQuote.routes.compare;
    }
    return quoteSteps.yourCover.routes.new;
  };

  const onSubmit = (): void => {
    validateQuoteStartDate();
    dispatch(flagUnansweredQuoteDetailsQuestions());
    scrollToQuoteValidationCards();
    logYourQuoteGoogleAnalyticsEvent({
      categorySuffix: "go_to_next_page",
      action: isYourQuoteValid ? "Success" : "Failure"
    });
  };

  const onNavigateAway = (): void => {
    logYourQuoteGoogleAnalyticsEvent({
      categorySuffix: "compare_quotes",
      action: "Click"
    });

    navigate(quoteSteps.yourQuote.routes.compare, {state: {fromQuotePage: true}});
  };

  if (quoteRetrievalStatus === QuoteRetrievalStatus.LOADING || isValidating) return <QuoteLoading/>;

  return (
    <div className="refine-your-quote">
      <QuoteStep
        forwardButtonLabel="quoteDetails.footerButton"
        previousRoute={previousRoute()}
        onSubmit={onSubmit}
        onAsyncSubmit={validateProposerEmail}
        isFormValid={isFormValid}
        nextRoute={paymentNavigationRoute}
        title="quoteDetails.title"
        description={{
          id: "quoteDetails.description",
          templateVariables: {
            firstName: firstName ?? ""
          }
        }}
        sidebar={<SaveQuoteSidebar/>}
        onGoBackClicked={() => logYourQuoteGoogleAnalyticsEvent({
          categorySuffix: "back",
          action: "Click"
        })}
        additionalContent={shouldAllowUserToCompareQuotes ?
          <Button
            variant="link"
            onClick={onNavigateAway}
            className="refine-your-quote__navigate-button"
            id="navigateButton"
          >
            {lookupI18nString("quoteDetails.goBack")}
          </Button>
          :
          undefined
        }
        afterHeader={<CheaperQuoteNotificationBanner/>}
        isFinalFlowRoute={isAggregatorQuote}
      >
        <QuoteSummary/>
      </QuoteStep>

      <AggregatorMissingAnswersModal
        visible={shouldShowAggregatorUnansweredQuestionsModal}
        setIsVisible={setShouldShowAggregatorUnansweredQuestionsModal}
      />

      <CoverStartDateExpiredModal
        visible={coverStartDateExpiredModalVisible && !hasQuoteValidationErrors}
        onDismiss={() => setCoverStartDateExpiredModalVisible(false)}
      />
    </div>
  );
};

export default YourQuote;