import React, {FC} from "react";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";
import getKeyForValueInRecord from "shared/dist/stdlib/GetKeyForValueInRecord";
import {isAlarmFittedByChanged} from "../../../redux/VehicleDetailsSlice";
import {AlarmInstaller} from "shared/dist/generated/graphql/resolvers-types";
import {VEHICLE_ALARM_INSTALLER_OPTIONS} from "../../../../shared/questions/vehicle-alarm/VehicleAlarmInstallerOptions";
import DropdownSubQuestion
  from "../../../../../../../structure/questions/sub-question/variants/dropdown-sub-question/DropdownSubQuestion";

const AdditionalDetailsAlarmInstaller: FC = () => {
  const dispatch = useAppDispatch();
  const alarmFittedBy = useAppSelector(state => state.vehicleDetails.additionalVehicleDetails.alarmFittedBy);

  const onChange = (selectedDescription: string): void => {
    const alarmFittedBy = getKeyForValueInRecord(selectedDescription, VEHICLE_ALARM_INSTALLER_OPTIONS);

    dispatch(isAlarmFittedByChanged({
      id: alarmFittedBy as AlarmInstaller,
      description: selectedDescription
    }));

  };

  if (!alarmFittedBy.isActive) return null;

  return (
    <>
      <DropdownSubQuestion
        id="alarmType"
        title="vehicleQuote.additionalVehicleDetailsForm.alarmInstaller"
        value={alarmFittedBy.value?.description}
        options={Object.values(VEHICLE_ALARM_INSTALLER_OPTIONS)}
        onChange={onChange}
        status={alarmFittedBy.status}
        errorMessage={alarmFittedBy.errorMessage}
      />
    </>
  );
};

export default AdditionalDetailsAlarmInstaller;
