import React, {FC} from "react";
import "./GreyMessageCard.css";
import {TranslationId} from "shared-components/src/translations/TranslationKey";
import FormattedMessage from "shared-components/src/translations/components/FormattedMessage";

interface Props {
  message: TranslationId
  title?: TranslationId
  testId?: string;
}

const GreyMessageCard: FC<Props> = ({title, message, testId}) => (
  <div className="grey-message-card" data-testid={testId}>
    <div className="grey-message-card__first_grey_line"/>
    <div className="grey-message-card__second_grey_line"/>
    <div className="grey-message-card__message">
      {title && (
        <h4>
          <FormattedMessage id={title}/>
        </h4>
      )}
      <p>
        <FormattedMessage id={message}/>
      </p>
    </div>
  </div>
);

export default GreyMessageCard;
