import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";

export enum VehicleType {
  Car = "CAR",
  Motorbike = "MOTORBIKE",
  Van = "VAN",
  Other = "OTHER"
}

export const VEHICLE_TYPE = [
  {
    id: VehicleType.Car,
    description: lookupI18nString("coverDetails.vehicleTypeQuestion.option.car")
  },
  {
    id: VehicleType.Motorbike,
    description: lookupI18nString("coverDetails.vehicleTypeQuestion.option.motorBike")
  },
  {
    id: VehicleType.Van,
    description: lookupI18nString("coverDetails.vehicleTypeQuestion.option.van")
  },
  {
    id: VehicleType.Other,
    description: lookupI18nString("coverDetails.vehicleTypeQuestion.option.other")
  }
];

export type VehicleTypeOption = typeof VEHICLE_TYPE[number];

export const VEHICLE_TYPE_DESCRIPTIONS = VEHICLE_TYPE.map(option => option.description);