import React, {FC} from "react";
import "./CurrentDocumentsBar.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import Button from "shared-components/dist/buttons//button/Button";
import {Print} from "@mui/icons-material";

interface Props {
  startDate: string;
  endDate: string;
  onRequestPrintDocuments: () => void;
  customerCanRequestPrintedDocuments: boolean;
  hidePrintRequestButton: boolean;
}

const CurrentDocumentsBar: FC<Props> = (
  {
    startDate,
    endDate,
    onRequestPrintDocuments,
    customerCanRequestPrintedDocuments,
    hidePrintRequestButton
  }
) => (
  <div className="current-documents-bar">
    <h5>{startDate} - {endDate}</h5>
    {!hidePrintRequestButton &&
      <Button
        className={"current-documents-bar__request-prints-button"}
        variant="link"
        rightIcon={<Print className="current-documents-bar__icon"/>}
        onClick={onRequestPrintDocuments}
        disabled={!customerCanRequestPrintedDocuments}
      >
        {lookupI18nString("policies.documents.requestPrintedDocuments.ready")}
      </Button>
    }
  </div>
);

export default CurrentDocumentsBar;
