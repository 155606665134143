import React, {FC} from "react";
import {useNavigate} from "react-router-dom";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import Spacer from "shared-components/dist/spacer/Spacer";
import Button from "shared-components/dist/buttons//button/Button";
import FeedbackCard from "shared-components/dist/cards/feedback-card/FeedbackCard";
import "./QuoteNotFound.css";
import CircleIcon from "shared-components/dist/icons/circle-icon/CircleIcon";
import {Close} from "@mui/icons-material";
import {quoteSteps} from "../../../../../shared/quote-step/QuoteSteps";

const QuoteNotFound: FC = () => {
  const navigate = useNavigate();

  const redirectToStartOfQuoteFlow = (): void => navigate(quoteSteps.yourCar.routes.new);

  return (
    <div className="quote-not-found">
      <FeedbackCard type="simple">
        <div className="quote-not-found__content">
          <div className="icon-container">
            <CircleIcon status="error">
              <Close/>
            </CircleIcon>
          </div>
          <h2>
            {lookupI18nString("priorQuote.quoteNotFound.title")}
          </h2>
          <Spacer className="spacer"/>
          <h4>
            {lookupI18nString("priorQuote.quoteNotFound.subTitle")}
          </h4>
          <div className="description">
            {lookupI18nString("priorQuote.quoteNotFound.description")}
          </div>
          <Button
            variant="tertiary"
            onClick={redirectToStartOfQuoteFlow}
          >
            {lookupI18nString("priorQuote.quoteNotFound.button")}
          </Button>
        </div>
      </FeedbackCard>
    </div>
  );
};

export default QuoteNotFound;