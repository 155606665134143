import React, {FC, ReactChild, useState} from "react";
import "./RemindersBanner.css";
import RemindersPaginationButtons from "./components/RemindersPaginationButtons";
import RemindersMobilePaginationButtons from "./components/RemindersMobilePaginationButtons";

interface Props {
  reminders: ReactChild[]
}

const RemindersBanner: FC<Props> = ({reminders}) => {
  const [visibleReminderIndex, setVisibleReminderIndex] = useState(0);

  if (reminders.length === 0) return null;

  return (
    <div className="reminders-banner">
      {reminders.length > 1 &&
        <RemindersMobilePaginationButtons
          numberOfReminders={reminders.length}
          onIndexChanged={setVisibleReminderIndex}
          visibleIndex={visibleReminderIndex}
        />
      }

      {reminders[visibleReminderIndex]}

      {reminders.length > 1 &&
        <RemindersPaginationButtons
          className="reminders-banner__pagination_buttons"
          numberOfReminders={reminders.length}
          onIndexChanged={setVisibleReminderIndex}
          visibleIndex={visibleReminderIndex}
        />
      }
    </div>
  );
};

export default RemindersBanner;
