import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import Dropdown from "shared-components/dist/form/dropdown/Dropdown";
import React, {FC} from "react";
import "./PolicyExtrasListItemDropdown.css";

export interface PolicyExtrasListItemDropdownProps {
  description: TranslationKey;
  options: string[];
  displayValues?: string[];
  value?: string;
  onSelection: (value: string) => void;
  errored?: boolean;
  errorText?: TranslationKey;
}

const PolicyExtrasListItemDropdown: FC<PolicyExtrasListItemDropdownProps> = (
  {
    description,
    options,
    displayValues,
    value,
    onSelection,
    errored = false,
    errorText
  }
) => (
  <div className="policy-extras-list-item-dropdown">
    <div className="policy-extras-list-item-dropdown--left">
      <span className="policy-extras-list-item-dropdown__description">{lookupI18nString(description)}</span>
    </div>
    <div className="policy-extras-list-item-dropdown--right">
      <Dropdown
        name="policy-extras-list-item-dropdown"
        options={options}
        displayValues={displayValues}
        onChange={(event) => onSelection?.(event.target.value)}
        value={value}
        errored={errored}
      />
      {errored && errorText &&
        <span className="policy-extras-list-item-dropdown__error">{lookupI18nString(errorText)}</span>
      }
    </div>
  </div>
);

export default PolicyExtrasListItemDropdown;