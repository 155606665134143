import {FC} from "react";
import DropdownQuestion from "../../../../../../../structure/questions/question/variants/dropdown-question/DropdownQuestion";
import {OWN_OR_COMPANY_CAR, OWN_OR_COMPANY_CAR_DESCRIPTIONS} from "./OwnOrCompanyCarOption";
import {ownOrCompanyCarChanged} from "../../../redux/CoverDetailsSlice";
import {logYourCoverGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {isNamedDriverAXAEligibleSelector} from "../../../redux/selectors/named-driver/IsNamedDriverAXAEligible";
import {selectIsVehiclePurchasedMoreThan14DaysAgo} from "../../../redux/selectors/named-driver/VehicleBreakInCoverSelectors";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const OwnOrCompanyCarQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const ownOrCompanyCarField = useAppSelector(state => state.coverDetails.ownOrCompanyCar);
  const isEligibleForNamedDriver = useAppSelector(isNamedDriverAXAEligibleSelector);
  const isVehiclePurchasedMoreThan14DaysAgo = useAppSelector(selectIsVehiclePurchasedMoreThan14DaysAgo);

  const onOwnOrCompanyCarChanged = (selectedOwnOrCompanyCar: string): void => {
    const ownOrCompanyCar = OWN_OR_COMPANY_CAR.find(ownOrCompanyCar => ownOrCompanyCar.description === selectedOwnOrCompanyCar);

    if (ownOrCompanyCar) {
      logYourCoverGoogleAnalyticsEvent({
        categorySuffix: "own_or_company_car",
        action: ownOrCompanyCar.description,
      });

      dispatch(ownOrCompanyCarChanged({value: ownOrCompanyCar, isEligibleForNamedDriver, isVehiclePurchasedMoreThan14DaysAgo}));
    }
  };

  return (
    <>
      <DropdownQuestion
        id="own-or-company-car-question"
        title="coverDetails.ownOrCompanyCarQuestion.title"
        options={OWN_OR_COMPANY_CAR_DESCRIPTIONS}
        value={ownOrCompanyCarField.value?.description}
        onChange={onOwnOrCompanyCarChanged}
        placeholder="coverDetails.ownOrCompanyCarQuestion.placeholder"
        status={ownOrCompanyCarField.status}
        errorMessage={ownOrCompanyCarField.errorMessage}
        hideSpacer
      />
    </>
  );
};

export default OwnOrCompanyCarQuestion;