import React, {FC} from "react";
import {STORED_AT_NIGHT, STORED_AT_NIGHT_DESCRIPTIONS} from "./constants/StoredAtNightOption";
import DropdownQuestion from "../../../../../../structure/questions/question/variants/dropdown-question/DropdownQuestion";
import {storedAtNightChanged} from "../../redux/PersonalDetailsSlice";
import {logYourDetailsGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const StoredAtNightQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const storedAtNightField = useAppSelector(state => state.personalDetails.storedAtNight);

  const onStoredAtNightChanged = (selectedStoredAtNight: string): void => {
    const storedAtNight = STORED_AT_NIGHT.find(storedAtNight => storedAtNight.description === selectedStoredAtNight);

    if (storedAtNight) {
      logYourDetailsGoogleAnalyticsEvent({
        categorySuffix: "where_car_is_kept",
        action: "Answered",
      });
      dispatch(storedAtNightChanged(storedAtNight));
    }
  };

  return (
    <DropdownQuestion
      id="stored-at-night-question"
      title="personalDetails.storedAtNightQuestion.title"
      options={STORED_AT_NIGHT_DESCRIPTIONS}
      value={storedAtNightField.value?.description}
      onChange={onStoredAtNightChanged}
      tooltip={{
        title: "personalDetails.storedAtNightQuestion.tooltip.title",
        description: "personalDetails.storedAtNightQuestion.tooltip.description"
      }}
      placeholder="personalDetails.storedAtNightQuestion.placeholder"
      status={storedAtNightField.status}
      errorMessage={storedAtNightField.errorMessage}
    />
  );
};

export default StoredAtNightQuestion;