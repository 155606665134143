import React, {FC, useCallback} from "react";
import {
  licenceDurationChanged,
  licenceDurationInvalid,
  licenceDurationReset
} from "../../redux/UpdateAdditionalDriverSlice";
import LicenceDurationQuestion from "../../../../shared/questions/licence-duration/LicenceDurationQuestion";
import {dateOfBirthAsDateSelector} from "../../redux/selectors/AdditionalDriverDateTypeSelectors";
import {logAdditionalDriverGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const AdditionalDriverLicenceDurationQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const licenceDuration = useAppSelector(state => state.updateAdditionalDriver.licenceDuration);
  const dateOfBirth = useAppSelector(state => dateOfBirthAsDateSelector(state).value);

  const onReset = useCallback(() => {
    dispatch(licenceDurationReset());
  }, [dispatch]);

  return (
    <LicenceDurationQuestion
      id="additional-driver-licence-duration"
      title="additionalDriver.licenceDurationQuestion"
      licenceDuration={licenceDuration}
      licenceDurationChanged={duration => dispatch(licenceDurationChanged(duration))}
      licenceDurationInvalid={errorMessage => dispatch(licenceDurationInvalid(errorMessage))}
      licenceDurationReset={onReset}
      dateOfBirth={dateOfBirth}
      googleAnalyticsLogger={logAdditionalDriverGoogleAnalyticsEvent}
      tooltip={{
        description: "additionalDriver.licenceDurationQuestion.tooltip.description"
      }}
    />
  );
};

export default AdditionalDriverLicenceDurationQuestion;
