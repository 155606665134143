import React, {FC, useEffect, useState} from "react";
import {Nullable} from "shared/dist/stdlib/TypeScriptHelpers";
import "./DisableLayer.css";

interface Props {
  anchorBelow: Nullable<Element>;
}

const DisableLayer: FC<Props> = ({anchorBelow}) => {
  const [dimensions, setDimensions] = useState<Dimensions | undefined>(calculateDimensions(anchorBelow));

  useEffect(() => {
    const handleResize = (): void => setDimensions(calculateDimensions(anchorBelow));

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleResize);
    };
  }, [anchorBelow]);

  if (!dimensions) return null;

  const {y, height} = dimensions;

  return (
    <div className="stop-disable-layer" style={{
      top: toPx(y),
      height: toPx(height)
    }}/>
  );
};

interface Dimensions {
  y: number;
  height: number;
}

export function calculateDimensions(anchorBelow: Nullable<Element>): Dimensions | undefined {
  if (!anchorBelow) return undefined;

  return {
    y: anchorBelow.getBoundingClientRect().bottom,
    height: document.body.offsetHeight
  };
}

const toPx = (input: number): string => `${input}px`;

export default DisableLayer;