import {RootState} from "../../../../../../../redux/Store";
import {createSelector} from "@reduxjs/toolkit";

const householdNumberOfCarsSelector = (state: RootState): string | undefined => state.updateAdditionalDriver.householdNumberOfCars.value;

export const householdNumberOfCarsStopVisibleSelector = createSelector(
  householdNumberOfCarsSelector,
  (householdNumberOfCars: string | undefined): boolean => {
    if (!householdNumberOfCars) return false;

    return parseInt(householdNumberOfCars) >= 3;
  }
);