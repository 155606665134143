import React, {FC} from "react";
import {trimLevelSelected} from "../../../../../../../redux/Actions";
import {trimLevelInvalid} from "../../../redux/ManualVehicleLookupFormSlice";
import DropdownSubQuestion
  from "../../../../../../../structure/questions/sub-question/variants/dropdown-sub-question/DropdownSubQuestion";
import {useTrimLevels} from "../../../../../../../graphql/queries/vehicle-lookup/manual-vehicle-lookup/ManualVehicleLookup";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const YourVehicleTrimLevel: FC = () => {
  const dispatch = useAppDispatch();
  const makeValue = useAppSelector(state => state.manualVehicleLookupForm.make.value);
  const modelValue = useAppSelector(state => state.manualVehicleLookupForm.model.value);
  const fuelTypeValue = useAppSelector(state => state.manualVehicleLookupForm.fuelType.value);
  const transmissionTypeValue = useAppSelector(state => state.manualVehicleLookupForm.transmissionType.value);
  const yearOfManufactureValue = useAppSelector(state => state.manualVehicleLookupForm.yearOfManufacture.value);
  const engineCapacityLitresValue = useAppSelector(state => state.manualVehicleLookupForm.engineCapacityLitres.value);
  const trimLevel = useAppSelector(state => state.manualVehicleLookupForm.trimLevel);

  const {error, loading, data} = useTrimLevels(makeValue, modelValue, fuelTypeValue, transmissionTypeValue, yearOfManufactureValue, engineCapacityLitresValue);

  const fetchError = !!(!loading && error);

  if (fetchError) dispatch(trimLevelInvalid("vehicleQuote.manualVehicleLookup.errors.trimLevelsFetchFailed"));

  return (
    <DropdownSubQuestion
      title="vehicleQuote.manualVehicleLookup.trimLevel"
      id="manual-vehicle-lookup-trim-level"
      errorMessage={trimLevel.errorMessage}
      status={loading ? "loading" : trimLevel.status}
      value={trimLevel.value}
      options={data?.vehicle?.vehicleOptions?.trim ?? []}
      onChange={trimLevel => dispatch(trimLevelSelected(trimLevel))}
      disabled={fetchError}
    />
  );
};

export default YourVehicleTrimLevel;
