import {
  ApplePayFullPolicySetUpRequest,
  ApplePayPaymentToken,
  FullPolicyPaymentResult,
  Mutation,
  ProposedRenewalRequest
} from "shared/dist/generated/graphql/resolvers-types";
import {apolloClient} from "../../../../../graphql/apollo/ApolloClientProvider";
import {
  APPLE_PAY_SET_UP_FULL_POLICY,
  ApplePayFullPolicyPaymentVariables
} from "shared/dist/graphql/mutations/payment/ApplePaySetUpFullPolicyMutation";
import {
  APPLE_PAY_PROCESS_FULL_RENEWAL,
  ApplePayFullRenewalPaymentVariables
} from "shared/dist/graphql/mutations/payment/ApplePayProcessFullRenewalMutation";

export async function applePayPurchaseFullPolicyMutation(request: ApplePayFullPolicySetUpRequest): Promise<FullPolicyPaymentResult> {
  const response = await apolloClient.mutate<Pick<Mutation, "policySetup">, ApplePayFullPolicyPaymentVariables>({
    mutation: APPLE_PAY_SET_UP_FULL_POLICY,
    variables: {
      request
    }
  });
  return response.data?.policySetup.applePay.setUpFullPolicy.result ?? FullPolicyPaymentResult.SomethingWentWrong;
}

export async function applePayPurchaseFullRenewalMutation(payment: ApplePayJS.ApplePayPayment, proposedRenewalRequest: ProposedRenewalRequest): Promise<FullPolicyPaymentResult> {
  const response = await apolloClient.mutate<Pick<Mutation, "renewals">, ApplePayFullRenewalPaymentVariables>({
    mutation: APPLE_PAY_PROCESS_FULL_RENEWAL,
    variables: {
      request: {
        payment: {
          token: payment.token.paymentData as ApplePayPaymentToken
        },
        proposedRenewalRequest: proposedRenewalRequest
      }
    }
  });
  return response.data?.renewals.applePay.processFullRenewal.result ?? FullPolicyPaymentResult.SomethingWentWrong;
}
