import {
  coverStartDateInvalid,
  coverStartDateWarningReset,
  coverStartDateWarningSet
} from "../../../redux/CoverDetailsSlice";
import {useEffect} from "react";
import {
  companyCarPolicyExpiryDateAsDateSelector,
  coverStartDateValueAsDateOptionSelector,
  existingPolicyExpiryDateAsDateSelector,
  vehiclePreviousPolicyExpiryDateAsDateSelector
} from "../../../redux/selectors/CoverDetailsSelectors";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";
import {
  isDateAfterCoverStartDate
} from "../../../../shared/quote-summary/components/edit-cover-summary/validators/CoverStartDateValidators";
import {runValidators} from "shared-components/dist/utils/validation/Validator";
import {getCoverStartDateWarning} from "../../../../shared/hooks/cover-start-date/GetCoverStartDateWarning";
import {
  buildPolicyExpiryWarningTooltip
} from "../../../../shared/hooks/cover-start-date/VehiclePolicyExpiryWarningTooltip";

export function useValidateCoverStartDate(): void {
  const dispatch = useAppDispatch();
  const coverStartDate = useAppSelector(state => coverStartDateValueAsDateOptionSelector(state));
  const existingPolicyExpiryDate = useAppSelector(state => existingPolicyExpiryDateAsDateSelector(state).value);
  const vehiclePreviousPolicyExpiryDate = useAppSelector(state => vehiclePreviousPolicyExpiryDateAsDateSelector(state).value);
  const companyCarPolicyExpiryDate = useAppSelector(state => companyCarPolicyExpiryDateAsDateSelector(state).value);

  useEffect(() => {
    if (!coverStartDate) return;

    const onValidCoverStartDate = (): void => {
      const coverStartDateWarning = getCoverStartDateWarning(
        coverStartDate.date,
        existingPolicyExpiryDate,
        companyCarPolicyExpiryDate,
        vehiclePreviousPolicyExpiryDate
      );

      switch (coverStartDateWarning) {
        case "NO_CLAIMS_BONUS_INELIGIBLE": {
          dispatch(coverStartDateWarningSet({
            errorMessage: "quoteDetails.summary.coverStartDate.warning.noClaims",
            errorTooltip: "quoteDetails.summary.coverStartDate.warning.noClaims.tooltip"
          }));
          return;
        }
        case "COMPANY_CAR_BONUS_INELIGIBLE": {
          dispatch(coverStartDateWarningSet({
            errorMessage: "quoteDetails.summary.coverStartDate.warning.companyCarBonus",
            errorTooltip: "quoteDetails.summary.coverStartDate.warning.companyCarBonus.tooltip"
          }));
          return;
        }
        case "VEHICLE_PREVIOUS_POLICY_EXPIRY_INVALID": {
          dispatch(coverStartDateWarningSet({
            errorMessage: "quoteDetails.summary.coverStartDate.warning.vehiclePolicyExpiryDate",
            errorTooltip: buildPolicyExpiryWarningTooltip("quoteDetails.summary.coverStartDate.warning.vehiclePolicyExpiryDate.tooltip", vehiclePreviousPolicyExpiryDate)
          }));
          return;
        }
        case "COMPANY_CAR_BONUS_INELIGIBLE_AND_PREVIOUS_POLICY_EXPIRY_INVALID": {
          dispatch(coverStartDateWarningSet({
            errorMessage: "quoteDetails.summary.coverStartDate.warning.combinedCompanyCarAndPolicyExpiryDate",
            errorTooltip: buildPolicyExpiryWarningTooltip("quoteDetails.summary.coverStartDate.warning.combinedCompanyCarAndPolicyExpiryDate.tooltip", vehiclePreviousPolicyExpiryDate)
          }));
          return;
        }
        default: dispatch(coverStartDateWarningReset());
      }
    };

    const validationResult = runValidators(
      coverStartDate,
      isDateAfterCoverStartDate({
        date: existingPolicyExpiryDate,
        validationErrorMessage: "coverDetails.errors.coverStartDateQuestion.policyStartBeforeCurrentPolicyExpiryDate"
      })
    );

    validationResult.passed
      ? onValidCoverStartDate()
      : dispatch(coverStartDateInvalid(validationResult.errorMessage));
  }, [coverStartDate, existingPolicyExpiryDate, dispatch, companyCarPolicyExpiryDate, vehiclePreviousPolicyExpiryDate]);
}