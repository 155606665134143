import React, {FC, SyntheticEvent, useEffect, useState} from "react";
import EditPasswordErrorMessage from "./error-message/EditPasswordErrorMessage";
import "./EditAccountPasswordSetting.css";
import EditPassword, {SaveButtonState} from "./EditPassword";
import {changePassword} from "./redux/ChangePassword";
import {
  userAttemptedToChangePassword
} from "../../../../../../user-authentication/shared/redux/UserAuthenticationSlice";
import {
  checkPasswordFormat,
  checkPasswordsMatch,
  PasswordFormatState,
  PasswordMatchState
} from "../../../../../../../structure/form/password-confirmation/PasswordValidators";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

interface Props {
  isVisible: boolean;
  userEmail: string;
  onPasswordUpdated: () => void;
}

const EditAccountPasswordSetting: FC<Props> = (
  {
    isVisible,
    userEmail,
    onPasswordUpdated
  }
) => {
  const dispatch = useAppDispatch();
  const changePasswordResult = useAppSelector(state => state.userAuthentication.changePasswordResult);

  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [repeatedPassword, setRepeatedPassword] = useState<string>("");
  const [passwordFormatState, setPasswordFormatState] = useState<PasswordFormatState>("Initialised");
  const [passwordsMatchState, setPasswordsMatchState] = useState<PasswordMatchState>("Initialised");
  const [saveButtonState, setSaveButtonState] = useState<SaveButtonState>("Default");
  const isFormValid = passwordFormatState === "Valid" && passwordsMatchState === "Valid" && currentPassword !== "";
  const passwordsMatch = passwordsMatchState !== "Invalid";
  const passwordFormatValid = passwordFormatState !== "Invalid";
  const saveButtonNeedsReset = changePasswordResult !== "Success" && changePasswordResult !== "Initialised" && saveButtonState !== "Default";

  useEffect(() => {
    if (changePasswordResult === "Success") onPasswordUpdated();
  }, [changePasswordResult, onPasswordUpdated]);

  useEffect(() => {
    if (saveButtonNeedsReset) setSaveButtonState("Default");
  }, [saveButtonNeedsReset, setSaveButtonState]);

  useEffect(() => {
    if (!isVisible) return;

    setPasswordFormatState("Initialised");
    setPasswordsMatchState("Initialised");
    setSaveButtonState("Default");
    dispatch(userAttemptedToChangePassword("Initialised"));
  }, [dispatch, isVisible]);

  if (!isVisible) {
    return null;
  }

  function onNewPasswordEntered(newPassword: string): void {
    setNewPassword(newPassword);
    validatePasswords(newPassword, repeatedPassword);
  }

  function onConfirmPasswordEntered(repeatedPassword: string): void {
    setRepeatedPassword(repeatedPassword);
    validatePasswords(newPassword, repeatedPassword);
  }

  function validatePasswords(newPassword: string, repeatedPassword: string): void {
    setPasswordFormatState(checkPasswordFormat(newPassword, userEmail));
    setPasswordsMatchState(checkPasswordsMatch(newPassword, repeatedPassword));
  }

  function savePassword(): void {
    setSaveButtonState("Saving");
    dispatch(changePassword(currentPassword, repeatedPassword));
  }

  function updateUserPassword(event: SyntheticEvent): void {
    event.preventDefault();
    if (isFormValid) savePassword();
  }

  return (
    <div className="change-account-password">
      <div className="change-account-password__container">
        <form onSubmit={updateUserPassword}>
          <div className="change-account-password__container__input">
            <EditPassword
              onCurrentPasswordEntered={setCurrentPassword}
              onNewPasswordEntered={onNewPasswordEntered}
              onConfirmPasswordEntered={onConfirmPasswordEntered}
              isSaveButtonEnabled={isFormValid}
              saveButtonState={saveButtonState}
            />

            <EditPasswordErrorMessage
              passwordsMatch={passwordsMatch}
              passwordFormatValid={passwordFormatValid}
              changePasswordResult={changePasswordResult}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditAccountPasswordSetting;
