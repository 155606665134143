import React, {FC, useState} from "react";
import DisplayAccountSetting from "../../account-setting/display-account-setting/DisplayAccountSetting";
import EditAccountPasswordSetting from "./edit-password/EditAccountPasswordSetting";
import {logPortalGoogleAnalyticsEvent} from "../../../../../../utils/analytics/PortalAnalytics";

interface Props {
  emailAddress: string;
  onPasswordUpdated: () => void;
}

const PasswordAccountSetting: FC<Props> = ({emailAddress, onPasswordUpdated}) => {
  const [isPasswordInputVisible, setIsPasswordInputVisible] = useState(false);

  function successfullyUpdatedPassword(): void {
    setIsPasswordInputVisible(false);
    onPasswordUpdated();
  }

  return (
    <>
      <DisplayAccountSetting
        name="password"
        headerText="accountSettings.header.password"
        value="*****"
        onEditToggled={() => {
          setIsPasswordInputVisible(!isPasswordInputVisible);
          logPortalGoogleAnalyticsEvent({
            categorySuffix: "account.edit.password.section",
            action: "Click"
          });
        }}
        isEditingAccountSetting={isPasswordInputVisible}
      />
      <EditAccountPasswordSetting
        isVisible={isPasswordInputVisible}
        userEmail={emailAddress}
        onPasswordUpdated={successfullyUpdatedPassword}
      />
    </>
  );
};

export default PasswordAccountSetting;
