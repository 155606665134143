import React, {FC} from "react";
import InsurerQuoteCardHeader from "./components/InsurerQuoteCardHeader";
import InsurerQuoteCardBody from "./components/InsurerQuoteCardBody";
import InsurerQuoteCardFooter from "./components/InsurerQuoteCardFooter";
import "./InsurerQuoteCard.css";
import {Price, QuoteType} from "shared/dist/generated/graphql/resolvers-types";
import QuoteCardToolTip from "./components/tooltip/QuoteCardToolTip";
import {QuoteToolTipDetails} from "./components/tooltip/QuoteToolTipDetails";
import {InsurerQuote} from "../../../pages/quote/vehicle/your-quote/models/InsurerQuote";
import {
  getBuyQuoteRequirementsDescription
} from "../../../pages/quote/vehicle/shared/services/GetBuyQuoteRequirementsDescription";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {LoadingStatus} from "../../../pages/quote/vehicle/your-quote/models/LoadingStatus";

interface Props {
  isMonthly?: boolean;
  isEmphasised?: boolean;
  depositPercentage: number;
  closeBrothersLoanStatus: LoadingStatus;
  extrasTotal?: Price;
  index: number;
  totalCost: Price;
  quote: InsurerQuote;
  onButtonClick: (selectedQuote: InsurerQuote) => void;
  numberOfInstalments: number | undefined;
  adminFee: Price | undefined;
}

const InsurerQuoteCard: FC<Props> = (
  {
    isMonthly,
    isEmphasised,
    quote,
    depositPercentage,
    closeBrothersLoanStatus,
    index,
    onButtonClick,
    totalCost,
    extrasTotal,
    numberOfInstalments,
    adminFee
  }) => {
  const header = getHeader(quote.quoteType);
  const footer = getBuyQuoteRequirementsDescription(quote.quoteType);
  const cost = (isMonthly && quote.selectedLoanWithPrice) ? quote.selectedLoanWithPrice.subsequentInstalmentAmounts : totalCost;
  const tooltip = getToolTip(quote.quoteType, index);

  return (
    <div className={`insurer-quote-card ${isEmphasised ? "emphasised" : ""}`}>
      <InsurerQuoteCardHeader
        title={header}
        description={"insurerQuoteCard.subheader"}
        quoteReference={quote.reference}
        sequenceNumber={quote.sequenceNumber}
      />
      <hr className="insurer-quote-card__section-break"/>
      <InsurerQuoteCardBody
        index={index}
        isMonthly={isMonthly}
        cost={cost}
        quote={quote}
        depositPercentage={depositPercentage}
        closeBrothersLoanStatus={closeBrothersLoanStatus}
        onButtonClick={onButtonClick}
        extrasTotal={extrasTotal}
        toolTip={tooltip}
        numberOfInstalments={numberOfInstalments}
        adminFee={adminFee}
      />
      <hr className="insurer-quote-card__section-break"/>
      <InsurerQuoteCardFooter
        title={"quote.shared.buyQuoteRequirements.title"}
        description={footer}
      />
    </div>
  );
};

function getHeader(quoteType: QuoteType): TranslationKey {
  switch (quoteType) {
    case QuoteType.CompanyCar:
      return "insurerQuoteCard.companyCar.header";
    case QuoteType.NamedDriver:
      return "insurerQuoteCard.namedDriver.header";
    case QuoteType.NoClaimsBonus:
      return "insurerQuoteCard.noClaimsBonus.header";
    case QuoteType.Default:
      return "insurerQuoteCard.noExperience.header";
  }
}

function getToolTip(quoteType: QuoteType, index: number): JSX.Element {
  let details: QuoteToolTipDetails = {
    calculatedHeader: "insurerQuoteCard.tooltip.calculated.header",
    calculatedDescription: "insurerQuoteCard.noExperience.tooltip.calculated.description",
    changesHeader: "insurerQuoteCard.noExperience.tooltip.changes.header",
    changesDescription: "insurerQuoteCard.noExperience.tooltip.changes.description",
  };

  switch (quoteType) {
    case QuoteType.CompanyCar:
      details = {
        calculatedHeader: "insurerQuoteCard.tooltip.calculated.header",
        calculatedDescription: "insurerQuoteCard.companyCar.tooltip.calculated.description",
        changesHeader: "insurerQuoteCard.companyCar.tooltip.changes.header",
        changesDescription: "insurerQuoteCard.companyCar.tooltip.changes.description",
      };
      break;
    case QuoteType.NamedDriver:
      details = {
        calculatedHeader: "insurerQuoteCard.tooltip.calculated.header",
        calculatedDescription: "insurerQuoteCard.namedDriver.tooltip.calculated.description",
        changesHeader: "insurerQuoteCard.namedDriver.tooltip.changes.header",
        changesDescription: "insurerQuoteCard.namedDriver.tooltip.changes.description",
      };
      break;
    case QuoteType.NoClaimsBonus:
      details = {
        calculatedHeader: "insurerQuoteCard.tooltip.calculated.header",
        calculatedDescription: "insurerQuoteCard.noClaimsBonus.tooltip.calculated.description",
        changesHeader: "insurerQuoteCard.noClaimsBonus.tooltip.changes.header",
        changesDescription: "insurerQuoteCard.noClaimsBonus.tooltip.changes.description",
      };
      break;
  }
  return <QuoteCardToolTip id={`tool-tip-${index}`} details={details}/>;
}

export default InsurerQuoteCard;
