import {FC} from "react";
import InformationPanel from "shared-components/dist/information/panel/InformationPanel";
import "./PointOfContactCard.css";
import FormattedMessage from "shared-components/src/translations/components/FormattedMessage";

const strollContactNumber = {
  phoneNumber: "08000 11 31 21",
  phoneNumberLink: "tel:08000113121",
  googleAnalyticsSuffix: "dashboard.helpline.normal_hours"
};

const PointsOfContactCard: FC = () => (
  <InformationPanel
    title="claims.pointOfContactCard.title"
    subTitle="claims.pointOfContactCard.description"
    className="claims-points-of-contact">
    <div className={"contact-numbers"} data-testid="contact-numbers">
      <div className={"contact-numbers__general-claims"}>
        <div className={"contact-numbers__general-claims__title"}>
          <FormattedMessage id="dashboard.contactCard.generalClaims.title"/>
        </div>
        <a
          className="contact-numbers__general-claims__phone-number"
          data-testid="general-phone-number"
          href={strollContactNumber.phoneNumberLink}
        >
          {strollContactNumber.phoneNumber}
        </a>
      </div>
      <a
        className={"contact-numbers__button"}
        href="https://strollinsurance.co.uk/help/faqs-category/claims"
        data-testid="need-help"
      >
        <FormattedMessage id="claims.pointOfContactCard.Claims.FAQ"/>
      </a>
    </div>
  </InformationPanel>
);

export default PointsOfContactCard;