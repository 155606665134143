import {FC} from "react";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {companyCarDeclarationAcceptanceChanged} from "../../../redux/CoverDetailsSlice";
import YearsCompanyCarBonusQuestion from "./years-company-car-bonus/YearsCompanyCarBonusQuestion";
import CompanyCarPolicyExpiryDateQuestion
  from "./company-car-policy-expiry-date-question/CompanyCarPolicyExpiryDateQuestion";
import ConfirmationDialog from "../../../../../../../structure/information/confirmation-dialog/ConfirmationDialog";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const CompanyCarBonusQuestions: FC = () => {
  const companyCarConfirmationList: TranslationKey[] = [
    "coverDetails.companyCar.confirmation.soleUse",
    "coverDetails.companyCar.confirmation.proofOfYears",
    "coverDetails.companyCar.confirmation.consecutiveYears",
    "coverDetails.companyCar.confirmation.noBreaks",
    "coverDetails.companyCar.confirmation.ukPolicyOnly",
    "coverDetails.companyCar.confirmation.noOtherVehicle",
    "coverDetails.companyCar.confirmation.noLongerInsured"
  ];
  const companyCarDeclarationAcceptanceField = useAppSelector(state => state.coverDetails.companyCarDeclarationAcceptance);
  const yearsCompanyCarBonusField = useAppSelector(state => state.coverDetails.yearsCompanyCarBonus);
  const companyCarPolicyExpiryDateField = useAppSelector(state => state.coverDetails.companyCarPolicyExpiryDate);
  const dispatch = useAppDispatch();

  const companyCarConfirmation = (value: boolean): void => {
    dispatch(companyCarDeclarationAcceptanceChanged(value));
  };

  return (
    <div className="company-car-bonus-questions">
      {companyCarDeclarationAcceptanceField.isActive &&
        <ConfirmationDialog
          id="company-car-confirmation"
          className="company-car-confirmation"
          title="coverDetails.companyCar.confirmation.title"
          subTitle="coverDetails.companyCar.confirmation.subtitle"
          description="coverDetails.companyCar.confirmation.description"
          hasConfirmed={companyCarDeclarationAcceptanceField.value}
          listDescription={companyCarConfirmationList}
          onAnswer={companyCarConfirmation}
          status={companyCarDeclarationAcceptanceField.status}
          errorMessage={companyCarDeclarationAcceptanceField.errorMessage}
        />
      }
      {yearsCompanyCarBonusField.isActive && <YearsCompanyCarBonusQuestion/>}
      {companyCarPolicyExpiryDateField.isActive && <CompanyCarPolicyExpiryDateQuestion/>}
    </div>
  );
};

export default CompanyCarBonusQuestions;