import {
  doesPolicyHolderExistQuery
} from "../../../../../graphql/queries/quote/does-policy-holder-exist/DoesPolicyHolderExistQuery";
import {logger} from "../../../../../utils/logging/Logger";
import {proposerEmailAddressInvalid} from "../../your-details/redux/PersonalDetailsSlice";
import {useAppDispatch} from "../../../../../redux/Hooks";

export type EmailValidatorResult = (email: string | undefined) => void;

export function useEmailValidator(): EmailValidatorResult {
  const dispatch = useAppDispatch();

  return async email => {
    if (!email || email === "") return;

    try {
      if (await doesPolicyHolderExistQuery({email})) {
        dispatch(proposerEmailAddressInvalid("quoteDetails.contactDetails.email.error.accountAlreadyExists"));
      }
    } catch (error) {
      logger.error("Unable to check if policy holder exists", error);
      dispatch(proposerEmailAddressInvalid("quoteDetails.contactDetails.email.error.genericFailure"));
    }
  };
}
