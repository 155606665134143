import {AppThunkDispatch, RootState} from "../../../../../redux/Store";
import {additionalVehicleDetailsFormVisibleSelector} from "../redux/selectors/VehiclePageStateSelectors";
import {
  additionalDetailsHasTrackerInvalid,
  driverPositionInvalid,
  estimatedValueInvalid,
  hasAlarmInvalid,
  importTypeInvalid,
  isAlarmFittedByInvalid,
  isImportedInvalid,
  seatsInvalid
} from "../redux/VehicleDetailsSlice";
import {additionalVehicleDetailsHasTrackerQuestionVisibleSelector} from "../redux/selectors/AdditionalQuestionsSelectors";
import {AdditionalVehicleDetailsFormQuestions} from "../redux/AdditionalVehicleDetailsFormQuestions";

type CheckFunction = (additionalVehicleDetails: AdditionalVehicleDetailsFormQuestions, dispatch: AppThunkDispatch) => void;

export function flagUnansweredAdditionalVehicleDetailsQuestions(rootState: RootState, dispatch: AppThunkDispatch): void {
  const additionalVehicleDetails = rootState.vehicleDetails.additionalVehicleDetails;

  if (additionalVehicleDetailsFormVisibleSelector(rootState)) {
    [
      checkDriverPosition,
      checkSeats,
      checkImported,
      checkImportType,
      checkEstimatedValue,
      checkHasAlarm,
      checkAlarmFittedBy
    ].forEach((checkFunction: CheckFunction) => checkFunction(additionalVehicleDetails, dispatch));
  }

  if (additionalVehicleDetailsHasTrackerQuestionVisibleSelector(rootState)) {
    checkAdditionalDetailsHasTracker(additionalVehicleDetails, dispatch);
  }
}

function checkDriverPosition({driverPosition}: AdditionalVehicleDetailsFormQuestions, dispatch: AppThunkDispatch): void {
  if (driverPosition.status === "default") {
    dispatch(driverPositionInvalid("quote.errors.questionIncomplete"));
  }
}

function checkSeats({seats}: AdditionalVehicleDetailsFormQuestions, dispatch: AppThunkDispatch): void {
  if (seats.status === "default") {
    dispatch(seatsInvalid("quote.errors.questionIncomplete"));
  }
}

function checkAdditionalDetailsHasTracker({hasTracker}: AdditionalVehicleDetailsFormQuestions, dispatch: AppThunkDispatch): void {
  if (hasTracker.status === "default") {
    dispatch(additionalDetailsHasTrackerInvalid("quote.errors.questionIncomplete"));
  }
}

function checkImported({imported}: AdditionalVehicleDetailsFormQuestions, dispatch: AppThunkDispatch): void {
  if (imported.status === "default") {
    dispatch(isImportedInvalid("quote.errors.questionIncomplete"));
  }
}

function checkImportType({importType}: AdditionalVehicleDetailsFormQuestions, dispatch: AppThunkDispatch): void {
  if (importType.isActive && importType.status === "default") {
    dispatch(importTypeInvalid("quote.errors.questionIncomplete"));
  }
}

function checkEstimatedValue({estimatedValue}: AdditionalVehicleDetailsFormQuestions, dispatch: AppThunkDispatch): void {
  if (estimatedValue.status === "default") {
    dispatch(estimatedValueInvalid("quote.errors.questionIncomplete"));
  }
}

function checkHasAlarm({hasAlarm}: AdditionalVehicleDetailsFormQuestions, dispatch: AppThunkDispatch): void {
  if (hasAlarm.status === "default") {
    dispatch(hasAlarmInvalid("quote.errors.questionIncomplete"));
  }
}

function checkAlarmFittedBy({alarmFittedBy}: AdditionalVehicleDetailsFormQuestions, dispatch: AppThunkDispatch): void {
  if (alarmFittedBy.isActive && alarmFittedBy.status === "default") {
    dispatch(isAlarmFittedByInvalid("quote.errors.questionIncomplete"));
  }
}

