import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  conditionalFieldReset,
  fieldChanged,
  fieldInvalid,
  fieldInvalidWithValue,
  fieldReset
} from "shared-components/dist/models/form-field/FormField";
import {AbiListItem, RetrieveAddressResult, StoredAtNight} from "shared/dist/generated/graphql/resolvers-types";
import {CustomisedAbiListItem} from "shared-components/dist/models/CustomisedAbiListItem";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {ClassOfUseOption} from "../../shared/questions/class-of-use/ClassOfUseOption";
import {
  additionalDriverAdded,
  additionalDriverEdited,
  additionalDriverRemoved,
  policyPurchased,
  quoteReset,
  storedQuoteLoaded
} from "../../../../../redux/Actions";
import {SerializableConviction} from "../../shared/questions/convictions/models/Conviction";
import {removeAtIndex} from "shared-components/dist/utils/stdlib/Arrays";
import {MedicalConditionOption} from "../../shared/questions/medical-condition/MedicalConditionOption";
import {GenderOption} from "../../shared/questions/gender/Genders";
import {MaritalStatusOption} from "../../shared/questions/martial-status/MaritalStatusOption";
import {LicenceCountryOfIssue} from "../../shared/questions/licence-country-of-issue/LicenceCountryOfIssue";
import {LicenceTypeOption} from "../../shared/questions/licence-type/LicenceTypeOption";
import {SerializableAdditionalDriver} from "../../additional-driver/shared/models/AdditionalDriver";
import {employmentChanged} from "../../shared/redux/ChangeEmployment";
import {SerializableClaim} from "../../shared/questions/claims/models/Claims";
import {LoadedQuote} from "../../your-quote/models/LoadedQuote";
import {garageOption, StoredAtNightOption} from "../components/stored-at-night/constants/StoredAtNightOption";
import {Status} from "shared-components/dist/status-indicator/Status";
import {PersonalDetails} from "./PersonalDetails";
import {convertTitleToGender} from "../../shared/redux/ConvertTitleToGender";
import {updateSolicitingQuestionStatus} from "./reducers/UpdateSolicitingQuestionStatus";
import {EmploymentStatusOption} from "../../shared/questions/employment/models/EmploymentStatusOption";

export const initialState: PersonalDetails = {
  title: {
    status: "default",
    value: undefined
  },
  firstName: {
    status: "default",
    value: undefined
  },
  surname: {
    status: "default",
    value: undefined
  },
  dateOfBirth: {
    status: "default",
    value: undefined
  },
  gender: {
    status: "default",
    value: undefined,
    isActive: false
  },
  address: {
    status: "default",
    value: undefined
  },
  isCarKeptAtHome: {
    status: "default",
    value: undefined
  },
  storedAtNight: {
    status: "default",
    value: undefined
  },
  isHomeowner: {
    status: "default",
    value: undefined
  },
  hasUkResidencyFromBirth: {
    status: "default",
    value: undefined
  },
  ukResidencyDuration: {
    status: "default",
    value: undefined,
    isActive: false
  },
  proposerEmailAddress: {
    status: "default",
    value: undefined,
  },
  phoneNumber: {
    status: "default",
    value: undefined
  },
  maritalStatus: {
    status: "default",
    value: undefined
  },
  primaryEmployment: {
    employmentStatus: {
      status: "default",
      value: undefined
    },
    industry: {
      status: "default",
      value: undefined,
      isActive: false
    },
    job: {
      status: "default",
      value: undefined,
      isActive: false
    },
    workedInPastYear: {
      status: "default",
      value: undefined,
      isActive: false
    },
  },
  hasSecondaryEmployment: {
    status: "default",
    value: undefined
  },
  secondaryEmployment: {
    employmentStatus: {
      status: "default",
      value: undefined,
      isActive: false
    },
    industry: {
      status: "default",
      value: undefined,
      isActive: false
    },
    job: {
      status: "default",
      value: undefined,
      isActive: false
    },
    workedInPastYear: {
      status: "default",
      value: undefined,
      isActive: false
    },
  },
  classOfUse: {
    status: "default",
    value: undefined
  },
  soliciting: {
    status: "default",
    value: undefined,
    isActive: false
  },
  licenceType: {
    status: "default",
    value: undefined
  },
  licenceCountryOfIssue: {
    status: "default",
    value: undefined
  },
  licenceDuration: {
    status: "default",
    value: undefined
  },
  hasMedicalCondition: {
    status: "default",
    value: undefined
  },
  medicalCondition: {
    status: "default",
    value: undefined,
    isActive: false
  },
  claims: {
    status: "default",
    value: {
      hasClaims: undefined,
      claims: [],
      shouldFlagUnansweredQuestions: false,
      isFormVisible: false
    }
  },
  convictions: {
    status: "default",
    value: {
      hasConvictions: undefined,
      convictions: [],
      shouldFlagUnansweredQuestions: false,
      isFormVisible: false
    }
  },
  hasNonMotoringConvictions: {
    status: "default",
    value: undefined
  },
  hasInsuranceDeclined: {
    status: "default",
    value: undefined
  },
  mainDriver: {
    status: "success",
    value: undefined,
    isActive: false,
  },
  additionalDrivers: []
};

const personalDetailsSlice = createSlice({
  name: "personalDetails",
  initialState,
  reducers: {
    titleChanged(state, action: PayloadAction<AbiListItem>): void {
      fieldChanged(state.title, action.payload);
      convertTitleToGender(state, action.payload);
    },
    titleInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.title, action.payload);
    },
    titleReset(state): void {
      fieldReset(state.title);
    },
    firstNameChanged(state, action: PayloadAction<string>): void {
      fieldChanged(state.firstName, action.payload);
    },
    firstNameInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.firstName, action.payload);
    },
    surnameChanged(state, action: PayloadAction<string>): void {
      fieldChanged(state.surname, action.payload);
    },
    surnameInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.surname, action.payload);
    },
    genderChanged(state, action: PayloadAction<GenderOption>): void {
      fieldChanged(state.gender, action.payload);
    },
    genderInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.gender, action.payload);
    },
    dateOfBirthChanged(state, action: PayloadAction<string>): void {
      fieldChanged(state.dateOfBirth, action.payload);
    },
    dateOfBirthInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.dateOfBirth, action.payload);
    },
    dateOfBirthReset(state): void {
      fieldReset(state.dateOfBirth);
    },
    storedAtNightChanged(state, action: PayloadAction<StoredAtNightOption>): void {
      fieldChanged(state.storedAtNight, action.payload);
    },
    storedAtNightInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.storedAtNight, action.payload);
    },
    isHomeownerChanged(state, action: PayloadAction<boolean>): void {
      fieldChanged(state.isHomeowner, action.payload);
    },
    isHomeownerInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.isHomeowner, action.payload);
    },
    isHomeownerReset(state): void {
      fieldReset(state.isHomeowner);
    },
    classOfUseChanged(state, action: PayloadAction<ClassOfUseOption>): void {
      fieldChanged(state.classOfUse, action.payload);

      updateSolicitingQuestionStatus(action.payload.id, state);
    },
    classOfUseReset(state): void {
      fieldReset(state.classOfUse);
    },
    classOfUseInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.classOfUse, action.payload);
      conditionalFieldReset(state.soliciting);
    },
    solicitingChanged(state, action: PayloadAction<boolean>): void {
      fieldChanged(state.soliciting, action.payload);
    },
    solicitingInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.soliciting, action.payload);
    },
    solicitingReset(state): void {
      conditionalFieldReset(state.soliciting);
    },
    solicitingActivated(state): void {
      state.soliciting.isActive = true;
    },
    proposerEmailAddressChanged(state, action: PayloadAction<string>): void {
      fieldChanged(state.proposerEmailAddress, action.payload);
    },
    proposerEmailAddressInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalidWithValue(state.proposerEmailAddress, action.payload);
    },
    phoneNumberChanged(state, action: PayloadAction<string>): void {
      fieldChanged(state.phoneNumber, action.payload);
    },
    phoneNumberReset(state): void {
      fieldReset(state.phoneNumber);
    },
    phoneNumberInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.phoneNumber, action.payload);
    },
    maritalStatusChanged(state, action: PayloadAction<MaritalStatusOption>): void {
      fieldChanged(state.maritalStatus, action.payload);
    },
    maritalStatusInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.maritalStatus, action.payload);
    },
    maritalStatusReset(state): void {
      fieldReset(state.maritalStatus);
    },
    primaryEmploymentStatusChanged(state, action: PayloadAction<EmploymentStatusOption>): void {
      fieldChanged(state.primaryEmployment.employmentStatus, action.payload);

      employmentChanged(state.primaryEmployment, initialState.primaryEmployment);

      updateSolicitingQuestionStatus(state.classOfUse.value?.id, state);
    },
    primaryEmploymentStatusInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.primaryEmployment.employmentStatus, action.payload);
    },
    primaryEmploymentIndustryChanged(state, action: PayloadAction<CustomisedAbiListItem>): void {
      fieldChanged(state.primaryEmployment.industry, action.payload);
    },
    primaryEmploymentIndustryInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.primaryEmployment.industry, action.payload);
    },
    primaryEmploymentJobChanged(state, action: PayloadAction<CustomisedAbiListItem>): void {
      fieldChanged(state.primaryEmployment.job, action.payload);
    },
    primaryEmploymentJobInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.primaryEmployment.job, action.payload);
    },
    primaryEmploymentWorkedInPastYearChanged(state, action: PayloadAction<boolean>): void {
      fieldChanged(state.primaryEmployment.workedInPastYear, action.payload);

      if (!action.payload) {
        state.primaryEmployment.job = initialState.primaryEmployment.job;
      } else {
        state.primaryEmployment.job = {...initialState.primaryEmployment.job, isActive: true};
      }
    },
    primaryEmploymentWorkedInPastYearInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.primaryEmployment.workedInPastYear, action.payload);
    },
    hasSecondaryEmploymentChanged(state, action: PayloadAction<boolean>): void {
      fieldChanged(state.hasSecondaryEmployment, action.payload);

      if (action.payload) {
        state.secondaryEmployment.employmentStatus.isActive = true;
      } else {
        conditionalFieldReset(state.secondaryEmployment.employmentStatus);
        conditionalFieldReset(state.secondaryEmployment.industry);
        conditionalFieldReset(state.secondaryEmployment.job);
        conditionalFieldReset(state.secondaryEmployment.workedInPastYear);
      }
    },
    hasSecondaryEmploymentInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.hasSecondaryEmployment, action.payload);
    },
    secondaryEmploymentStatusChanged(state, action: PayloadAction<EmploymentStatusOption>): void {
      fieldChanged(state.secondaryEmployment.employmentStatus, action.payload);

      employmentChanged(state.secondaryEmployment, initialState.secondaryEmployment);

      updateSolicitingQuestionStatus(state.classOfUse.value?.id, state);
    },
    secondaryEmploymentStatusInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.secondaryEmployment.employmentStatus, action.payload);
    },
    secondaryEmploymentIndustryChanged(state, action: PayloadAction<CustomisedAbiListItem>): void {
      fieldChanged(state.secondaryEmployment.industry, action.payload);
    },
    secondaryEmploymentIndustryInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.secondaryEmployment.industry, action.payload);
    },
    secondaryEmploymentJobChanged(state, action: PayloadAction<CustomisedAbiListItem>): void {
      fieldChanged(state.secondaryEmployment.job, action.payload);
    },
    secondaryEmploymentJobInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.secondaryEmployment.job, action.payload);
    },
    secondaryEmploymentWorkedInPastYearChanged(state, action: PayloadAction<boolean>): void {
      fieldChanged(state.secondaryEmployment.workedInPastYear, action.payload);

      if (!action.payload) {
        state.secondaryEmployment.job = initialState.secondaryEmployment.job;
      } else {
        state.secondaryEmployment.job = {...initialState.secondaryEmployment.job, isActive: true};
      }
    },
    secondaryEmploymentWorkedInPastYearInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.secondaryEmployment.workedInPastYear, action.payload);
    },
    licenceCountryOfIssueChanged(state, action: PayloadAction<LicenceCountryOfIssue>): void {
      fieldChanged(state.licenceCountryOfIssue, action.payload);
    },
    licenceCountryOfIssueInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.licenceCountryOfIssue, action.payload);
    },
    licenceTypeChanged(state, action: PayloadAction<LicenceTypeOption>): void {
      fieldChanged(state.licenceType, action.payload);
    },
    licenceTypeInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.licenceType, action.payload);
    },
    licenceDurationChanged(state, action: PayloadAction<Duration>): void {
      fieldChanged(state.licenceDuration, action.payload);
    },
    licenceDurationInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.licenceDuration, action.payload);
    },
    licenceDurationReset(state): void {
      fieldReset(state.licenceDuration);
    },
    addressChanged(state, action: PayloadAction<RetrieveAddressResult>): void {
      fieldChanged(state.address, action.payload);
    },
    addressInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.address, action.payload);
    },
    addressReset(state): void {
      fieldReset(state.address);
    },
    isCarKeptAtHomeChanged(state, action: PayloadAction<boolean>): void {
      fieldChanged(state.isCarKeptAtHome, action.payload);
    },
    isCarKeptAtHomeInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.isCarKeptAtHome, action.payload);
    },
    hasMedicalConditionChanged(state, action: PayloadAction<boolean>): void {
      fieldChanged(state.hasMedicalCondition, action.payload);

      state.medicalCondition = {...initialState.medicalCondition, isActive: action.payload};
    },
    hasMedicalConditionInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.hasMedicalCondition, action.payload);
    },
    medicalConditionChanged(state, action: PayloadAction<MedicalConditionOption>): void {
      fieldChanged(state.medicalCondition, action.payload);
    },
    medicalConditionInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.medicalCondition, action.payload);
    },
    medicalConditionReset(state): void {
      state.medicalCondition = {...initialState.medicalCondition, isActive: !!state.hasMedicalCondition.value};
    },
    hasUkResidencyFromBirthChanged(state, action: PayloadAction<boolean>): void {
      fieldChanged(state.hasUkResidencyFromBirth, action.payload);
      if (!action.payload) {
        state.ukResidencyDuration.isActive = true;
      } else {
        conditionalFieldReset(state.ukResidencyDuration);
      }
    },
    hasUkResidencyFromBirthInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.hasUkResidencyFromBirth, action.payload);
    },
    ukResidencyDurationChanged(state, action: PayloadAction<Duration>): void {
      fieldChanged(state.ukResidencyDuration, action.payload);
    },
    ukResidencyDurationInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.ukResidencyDuration, action.payload);
    },
    ukResidencyDurationReset(state): void {
      fieldReset(state.ukResidencyDuration);
    },
    hasClaimsChanged(state, action: PayloadAction<boolean>): void {
      state.claims.value.hasClaims = action.payload;
      state.claims.value.claims = [];
      state.claims.errorMessage = undefined;
      state.claims.status = "success";
    },
    claimsInvalid(state, action: PayloadAction<TranslationKey>): void {
      state.claims.status = "error";
      state.claims.errorMessage = action.payload;
    },
    flagUnansweredClaimsSubQuestions(state): void {
      state.claims.value.shouldFlagUnansweredQuestions = true;
    },
    isClaimsFormVisibleSet(state, action: PayloadAction<boolean>): void {
      state.claims.value.isFormVisible = action.payload;
      state.claims.value.shouldFlagUnansweredQuestions = false;
    },
    claimAdded(state, action: PayloadAction<SerializableClaim>): void {
      state.claims.value.claims.push(action.payload);
      state.claims.status = "success";
    },
    claimRemoved(state, action: PayloadAction<number>): void {
      state.claims.value.claims = removeAtIndex(state.claims.value.claims, action.payload);
    },
    claimStatusChanged(state, action: PayloadAction<Status>): void {
      state.claims.status = action.payload;
    },
    hasConvictionsChanged(state, action: PayloadAction<boolean>): void {
      state.convictions.value.hasConvictions = action.payload;
      state.convictions.value.convictions = [];
      state.convictions.errorMessage = undefined;
      state.convictions.status = "success";
    },
    convictionsInvalid(state, action: PayloadAction<TranslationKey>): void {
      state.convictions.status = "error";
      state.convictions.errorMessage = action.payload;
    },
    flagUnansweredConvictionsSubQuestions(state): void {
      state.convictions.value.shouldFlagUnansweredQuestions = true;
    },
    isConvictionsFormVisibleSet(state, action: PayloadAction<boolean>): void {
      state.convictions.value.isFormVisible = action.payload;
      state.convictions.value.shouldFlagUnansweredQuestions = false;
    },
    convictionAdded(state, action: PayloadAction<SerializableConviction>): void {
      state.convictions.value.convictions.push(action.payload);
      state.convictions.status = "success";
    },
    convictionRemoved(state, action: PayloadAction<number>): void {
      state.convictions.value.convictions = removeAtIndex(state.convictions.value.convictions, action.payload);
    },
    convictionStatusChanged(state, action: PayloadAction<Status>): void {
      state.convictions.status = action.payload;
    },
    mainDriverChanged(state, action: PayloadAction<string>): void {
      fieldChanged(state.mainDriver, action.payload);
    },
    mainDriverReset(state): void {
      fieldReset(state.mainDriver);
    },
    insuranceDeclinedQuestionChanged(state, action: PayloadAction<boolean>): void {
      fieldChanged(state.hasInsuranceDeclined, action.payload);
    },
    insuranceDeclinedQuestionInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.hasInsuranceDeclined, action.payload);
    },
    hasNonMotoringConvictionsChanged(state, action: PayloadAction<boolean>): void {
      fieldChanged(state.hasNonMotoringConvictions, action.payload);
    },
    hasNonMotoringConvictionsInvalid(state, action: PayloadAction<TranslationKey>): void {
      fieldInvalid(state.hasNonMotoringConvictions, action.payload);
    },
    personalDetailsReset: () => initialState
  },
  extraReducers: {
    [quoteReset.type]: () => initialState,
    [policyPurchased.type]: () => initialState,
    [storedQuoteLoaded.type]: (state, action: PayloadAction<LoadedQuote>) => {
      const storedAtNightField = action.payload.quoteArguments.personalDetails.storedAtNight;
      const storedAtNightValue = storedAtNightField.value;
      const isCarStoredInGarage =
        storedAtNightValue?.id as StoredAtNight === StoredAtNight.LockedGarage
        || storedAtNightValue?.id as StoredAtNight === StoredAtNight.UnlockedGarage;

      return ({
        ...initialState,
        ...action.payload.quoteArguments.personalDetails,
        storedAtNight: {
          ...storedAtNightField,
          value: isCarStoredInGarage ? garageOption : storedAtNightValue
        }
      });
    },
    [additionalDriverAdded.type]: (state, action: PayloadAction<SerializableAdditionalDriver>) => {
      state.additionalDrivers.push(action.payload);
      state.mainDriver.isActive = true;
      if (!state.mainDriver.value) state.mainDriver.value = "Policy Holder";
    },
    [additionalDriverEdited.type]: (state, action: PayloadAction<SerializableAdditionalDriver>) => {
      const additionalDriver = state.additionalDrivers.findIndex(additionalDriver => additionalDriver.id === action.payload.id);

      if (additionalDriver === -1) throw Error("Cannot find additional driver to edit using id");

      state.additionalDrivers.splice(additionalDriver, 1, action.payload);
      state.mainDriver.isActive = true;
      if (!state.mainDriver.value) state.mainDriver.value = "Policy Holder";
    },
    [additionalDriverRemoved.type]: (state, action: PayloadAction<string>) => {
      const index = state.additionalDrivers.map(driver => driver.id).indexOf(action.payload);
      state.additionalDrivers = removeAtIndex(state.additionalDrivers, index);
      if (state.additionalDrivers.length === 0) state.mainDriver = initialState.mainDriver;
    }
  }
});

export const {
  titleChanged,
  titleReset,
  titleInvalid,
  firstNameChanged,
  firstNameInvalid,
  surnameChanged,
  surnameInvalid,
  genderChanged,
  genderInvalid,
  dateOfBirthChanged,
  dateOfBirthInvalid,
  dateOfBirthReset,
  storedAtNightChanged,
  storedAtNightInvalid,
  isHomeownerChanged,
  isHomeownerInvalid,
  isHomeownerReset,
  classOfUseChanged,
  classOfUseInvalid,
  solicitingChanged,
  solicitingInvalid,
  solicitingReset,
  solicitingActivated,
  proposerEmailAddressChanged,
  proposerEmailAddressInvalid,
  phoneNumberChanged,
  phoneNumberInvalid,
  phoneNumberReset,
  maritalStatusInvalid,
  maritalStatusChanged,
  maritalStatusReset,
  primaryEmploymentIndustryChanged,
  primaryEmploymentIndustryInvalid,
  primaryEmploymentJobChanged,
  primaryEmploymentJobInvalid,
  primaryEmploymentStatusChanged,
  primaryEmploymentStatusInvalid,
  primaryEmploymentWorkedInPastYearChanged,
  primaryEmploymentWorkedInPastYearInvalid,
  hasSecondaryEmploymentChanged,
  hasSecondaryEmploymentInvalid,
  secondaryEmploymentStatusChanged,
  secondaryEmploymentStatusInvalid,
  secondaryEmploymentIndustryChanged,
  secondaryEmploymentIndustryInvalid,
  secondaryEmploymentJobChanged,
  secondaryEmploymentJobInvalid,
  secondaryEmploymentWorkedInPastYearChanged,
  secondaryEmploymentWorkedInPastYearInvalid,
  licenceCountryOfIssueChanged,
  licenceCountryOfIssueInvalid,
  licenceTypeChanged,
  licenceTypeInvalid,
  licenceDurationChanged,
  licenceDurationInvalid,
  licenceDurationReset,
  addressChanged,
  addressInvalid,
  addressReset,
  isCarKeptAtHomeChanged,
  isCarKeptAtHomeInvalid,
  hasMedicalConditionChanged,
  hasMedicalConditionInvalid,
  medicalConditionChanged,
  medicalConditionInvalid,
  medicalConditionReset,
  hasUkResidencyFromBirthChanged,
  hasUkResidencyFromBirthInvalid,
  ukResidencyDurationChanged,
  ukResidencyDurationInvalid,
  ukResidencyDurationReset,
  hasClaimsChanged,
  claimsInvalid,
  flagUnansweredClaimsSubQuestions,
  isClaimsFormVisibleSet,
  claimAdded,
  claimRemoved,
  claimStatusChanged,
  hasConvictionsChanged,
  convictionsInvalid,
  convictionAdded,
  convictionRemoved,
  convictionStatusChanged,
  flagUnansweredConvictionsSubQuestions,
  isConvictionsFormVisibleSet,
  mainDriverChanged,
  mainDriverReset,
  insuranceDeclinedQuestionChanged,
  insuranceDeclinedQuestionInvalid,
  hasNonMotoringConvictionsChanged,
  hasNonMotoringConvictionsInvalid,
  personalDetailsReset,
  classOfUseReset
} = personalDetailsSlice.actions;

export const personalDetailsReducer = personalDetailsSlice.reducer;

export const personalDetailsSliceName = personalDetailsSlice.name;
