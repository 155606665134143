import {QuoteVehicleDetails, VehicleAlarmDetails, VehicleHolder} from "shared/src/generated/graphql/resolvers-types";
import {buildModification} from "./BuildModification";
import {VehicleDetails} from "../../../quote/vehicle/your-vehicle/redux/VehicleDetails";
import {VEHICLE_HOLDERS, VehicleHolderOption} from "../../../../utils/constants/VehicleHolderOption";
import {RequiredFormField} from "shared-components/dist/models/form-field/FormField";
import {Modifications} from "../../../quote/vehicle/your-vehicle/components/modifications/model/Modification";
import {buildConditionalFormField, buildFormField, buildRequiredFormField} from "./BuildFormField";
import {
  additionalVehicleDetailsFormQuestionsInitialState
} from "../../../quote/vehicle/your-vehicle/redux/AdditionalVehicleDetailsFormQuestionsReducer";
import {
  AlarmInstallerOption,
  VEHICLE_ALARM_INSTALLER_OPTIONS
} from "../../../quote/vehicle/shared/questions/vehicle-alarm/VehicleAlarmInstallerOptions";

export function buildVehicle(vehicleDetails: QuoteVehicleDetails): VehicleDetails {
  const isOwnerAndRegisteredKeeper = isPolicyHolderOwnerAndKeeper(vehicleDetails);

  return {
    registrationNumberLookup: buildFormField(vehicleDetails.registration ?? undefined),
    vehicleFromRegLookup: {
      commercialVehicle: vehicleDetails.isCommercialVehicle ?? false,
      registration: vehicleDetails.registration ?? undefined,
      abi: vehicleDetails.abi,
      make: vehicleDetails.make,
      model: vehicleDetails.model,
      trim: vehicleDetails.trim ?? "",
      bodyStyle: vehicleDetails.bodyStyle ?? "",
      engineCapacityCc: vehicleDetails.engineCapacityCc?.toString(),
      engineCapacityLitres: vehicleDetails.engineCapacityLitres?.toString(),
      fuelType: vehicleDetails.fuelType,
      transmissionType: vehicleDetails.transmissionType,
      yearOfManufacture: (vehicleDetails.yearOfManufacture).toString(),
      driverPosition: vehicleDetails.driverPosition ?? undefined,
      doors: vehicleDetails.doors ?? undefined,
      seats: vehicleDetails.seats,
      hasTracker: vehicleDetails.hasTracker,
      hasAlarm: vehicleDetails.vehicleAlarm?.hasAlarm,
      alarmFittedBy: vehicleDetails.vehicleAlarm ? buildAlarmFittedByFieldOption(vehicleDetails.vehicleAlarm) : undefined,
      imported: vehicleDetails.isImported ?? undefined,
      importType: vehicleDetails?.importType ?? undefined,
      estimatedValue: vehicleDetails.estimatedValue
    },
    assumptionsCorrect: buildFormField(true),
    additionalVehicleDetails: additionalVehicleDetailsFormQuestionsInitialState,
    hasTracker: buildFormField(vehicleDetails.hasTracker),
    modifications: buildModificationFields(vehicleDetails),
    isCurrentOwner: buildFormField(!!vehicleDetails.dateOfPurchase),
    dateOfPurchase: buildFormField(vehicleDetails.dateOfPurchase ?? undefined),
    isOwnerAndRegisteredKeeper: buildFormField(isOwnerAndRegisteredKeeper),
    owner: buildConditionalFormField(
      buildVehicleHolderOption(vehicleDetails.vehicleOwner, isOwnerAndRegisteredKeeper)
    ),
    registeredKeeper: buildConditionalFormField(
      buildVehicleHolderOption(vehicleDetails.registeredKeeper, isOwnerAndRegisteredKeeper)
    )
  };
}

function buildVehicleHolderOption(holder: VehicleHolder, isPolicyHolderOwnerAndKeeper: boolean): VehicleHolderOption | undefined {
  if (isPolicyHolderOwnerAndKeeper) return undefined;

  return VEHICLE_HOLDERS.find(vehicleHolder => vehicleHolder.id === holder);
}

function isPolicyHolderOwnerAndKeeper(vehicleDetails: QuoteVehicleDetails): boolean {
  return vehicleDetails.registeredKeeper === VehicleHolder.PolicyHolder && vehicleDetails.vehicleOwner === VehicleHolder.PolicyHolder;
}

function buildModificationFields(vehicleDetails: QuoteVehicleDetails): RequiredFormField<Modifications> {
  return buildRequiredFormField({
    hasModifications: vehicleDetails.modifications.length >= 1,
    modifications: vehicleDetails.modifications.map(modification => buildModification(modification)),
    shouldFlagUnansweredQuestions: false,
    isFormVisible: false
  }, {status: "success"});
}

function buildAlarmFittedByFieldOption(vehicleAlarm: VehicleAlarmDetails): AlarmInstallerOption | undefined {
  if (!vehicleAlarm.fittedBy) return undefined;

  return {
    id: vehicleAlarm.fittedBy,
    description: VEHICLE_ALARM_INSTALLER_OPTIONS[vehicleAlarm.fittedBy]
  };
}
