import React, {FC, FormEvent, useState} from "react";
import SubQuestionForm from "../../../../../../../structure/questions/sub-question-form/SubQuestionForm";
import {
  MultipleItemsQuestionSubFormProps
} from "../../../../../../../structure/questions/multiple-items-question/MultipleItemsQuestion";
import {Claim} from "../models/Claims";
import ClaimDateSubQuestion from "./ClaimDateSubQuestion";
import ClaimStatusSubQuestion from "./ClaimStatusSubQuestion";
import IncidentTypeSubQuestion from "./IncidentTypeSubQuestion";
import FaultStatusSubQuestion from "./FaultStatusSubQuestion";
import AccidentDescriptionSubQuestion from "./AccidentDescriptionSubQuestion";
import ClaimedPolicySubQuestion from "./ClaimedPolicySubQuestion";
import NoClaimsAffectedSubQuestion from "./NoClaimsAffectedSubQuestion";
import BooleanSubQuestion
  from "../../../../../../../structure/questions/sub-question/variants/boolean-sub-question/BooleanSubQuestion";
import CurrencyInputSubQuestion
  from "../../../../../../../structure/questions/sub-question/variants/currency-input-sub-question/CurrencyInputSubQuestion";
import {
  getMultipleItemsSubQuestionStatus
} from "../../../../../../../structure/questions/multiple-items-question/helpers/SubQuestionStatus";
import {isValidClaim} from "../validation/ClaimValidator";
import {ClaimStatusOption} from "../models/ClaimStatusOption";
import {IncidentTypeOption} from "../models/IncidentTypeOption";
import {OWN_FAULT, PENDING_FAULT} from "../models/AtFaultOption";
import {isSettledAccident} from "../helpers/IsSettledAccident";
import {ClaimStatus, IncidentType} from "shared/dist/generated/graphql/resolvers-types";
import {useScrollToError} from "../../../../../../../utils/validation/hooks/ScrollToError";

interface Props extends MultipleItemsQuestionSubFormProps {
  claimAdded: (value: Claim) => void;
  shouldFlagUnansweredQuestions: boolean;
  flagUnansweredQuestions: () => void
  isAdditionalDriverForm: boolean;
}

const ClaimsForm: FC<Props> = (
  {
    formRef,
    setIsFormValid,
    claimAdded,
    shouldFlagUnansweredQuestions,
    flagUnansweredQuestions,
    isAdditionalDriverForm
  }
) => {
  const [claim, setClaim] = useState<Partial<Claim>>({});
  const {scrollToError} = useScrollToError({shouldForceScroll: true});

  const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (isValidClaim(claim)) {
      claimAdded(claim);
      setIsFormValid?.(false);
    } else {
      flagUnansweredQuestions();
      scrollToError();
    }
  };

  const updateClaim = (partialClaim: Partial<Claim>): void => {
    const updatedClaim = {...claim, ...partialClaim};

    setClaim(updatedClaim);
    setIsFormValid?.(isValidClaim(updatedClaim));
  };

  const mapClaimStatusAndIncidentType = (status: ClaimStatusOption | undefined, incidentType: IncidentTypeOption | undefined): void => {
    if (status === undefined || incidentType === undefined) updateClaim({status, incidentType});

    if (isSettled(status)) {
      isAccident(incidentType)
        ? updateClaim({status, incidentType, atFault: undefined, accidentDescription: undefined})
        : updateClaim({status, incidentType, atFault: OWN_FAULT, accidentDescription: undefined});
    } else {
      updateClaim({status, incidentType, atFault: PENDING_FAULT, accidentDescription: undefined});
    }
  };

  const isSettled = (status: ClaimStatusOption | undefined): boolean => status?.id === ClaimStatus.Settled;
  const isAccident = (incidentType: IncidentTypeOption | undefined): boolean => incidentType?.vtCode === IncidentType.Accident;

  return (
    <SubQuestionForm formRef={formRef} onSubmit={onSubmit}>
      <ClaimDateSubQuestion
        value={claim?.date}
        onChange={value => updateClaim({date: value})}
        shouldFlagUnansweredQuestions={shouldFlagUnansweredQuestions}
        isAdditionalDriverForm={isAdditionalDriverForm}
      />

      <ClaimStatusSubQuestion
        value={claim?.status}
        onChange={value => mapClaimStatusAndIncidentType(value, claim.incidentType)}
        status={getMultipleItemsSubQuestionStatus(shouldFlagUnansweredQuestions, claim.status)}
        isAdditionalDriverForm={isAdditionalDriverForm}
      />

      <IncidentTypeSubQuestion
        value={claim?.incidentType}
        onChange={value => mapClaimStatusAndIncidentType(claim.status, value)}
        status={getMultipleItemsSubQuestionStatus(shouldFlagUnansweredQuestions, claim.incidentType)}
        isAdditionalDriverForm={isAdditionalDriverForm}
      />

      {isSettledAccident(claim.status, claim.incidentType) &&
        <FaultStatusSubQuestion
          value={claim?.atFault}
          onChange={value => updateClaim({atFault: value})}
          status={getMultipleItemsSubQuestionStatus(shouldFlagUnansweredQuestions, claim.atFault)}
          isAdditionalDriverForm={isAdditionalDriverForm}
        />
      }

      {isAccident(claim.incidentType) &&
        <AccidentDescriptionSubQuestion
          value={claim.accidentDescription}
          onChange={value => updateClaim({accidentDescription: value})}
          status={getMultipleItemsSubQuestionStatus(shouldFlagUnansweredQuestions, claim.accidentDescription)}
          isAdditionalDriverForm={isAdditionalDriverForm}
        />
      }

      <ClaimedPolicySubQuestion
        value={claim?.whichPolicyClaimedAgainst}
        onChange={value => updateClaim({whichPolicyClaimedAgainst: value})}
        status={getMultipleItemsSubQuestionStatus(shouldFlagUnansweredQuestions, claim.whichPolicyClaimedAgainst)}
        isAdditionalDriverForm={isAdditionalDriverForm}
      />

      <NoClaimsAffectedSubQuestion
        value={claim?.noClaimsAffected}
        onChange={value => updateClaim({noClaimsAffected: value})}
        status={getMultipleItemsSubQuestionStatus(shouldFlagUnansweredQuestions, claim.noClaimsAffected)}
        isAdditionalDriverForm={isAdditionalDriverForm}
      />

      <BooleanSubQuestion
        id="injuries-occurred-sub-question"
        title={
          isAdditionalDriverForm
            ? "additionalDriver.claims.injuriesOccurred.title"
            : "personalDetails.claims.injuriesOccurred.title"
        }
        value={claim?.bodilyInjuryOccurred}
        onAnswer={value => updateClaim({bodilyInjuryOccurred: value})}
        status={getMultipleItemsSubQuestionStatus(shouldFlagUnansweredQuestions, claim.bodilyInjuryOccurred)}
        errorMessage="quote.errors.questionIncomplete"
      />

      <CurrencyInputSubQuestion
        id="claim-value-sub-question"
        title={
          isAdditionalDriverForm
            ? "additionalDriver.claims.cost.title"
            : "personalDetails.claims.cost.title"
        }
        value={claim?.value}
        onAnswer={value => updateClaim({value: value})}
        status={getMultipleItemsSubQuestionStatus(shouldFlagUnansweredQuestions, claim.value)}
        errorMessage="quote.errors.questionIncomplete"
        step={50}
      />
    </SubQuestionForm>
  );
};

export default ClaimsForm;
