import React, {FC} from "react";
import {logYourDetailsGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import EmailAddressQuestion from "../../../shared/questions/email-address/EmailAddressQuestion";
import {proposerEmailAddressChanged, proposerEmailAddressInvalid} from "../../redux/PersonalDetailsSlice";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const ProposerEmailAddressQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const emailAddressField = useAppSelector(state => state.personalDetails.proposerEmailAddress);

  const onEmailAddressChanged = (emailAddress: string): void => {
    logYourDetailsGoogleAnalyticsEvent({
      categorySuffix: "email_address",
      action: "Answered",
    });
    dispatch(proposerEmailAddressChanged(emailAddress));
  };

  const onInvalid = (): void => {
    dispatch(proposerEmailAddressInvalid("quoteDetails.contactDetails.email.error.invalidEntry"));
  };

  return (
    <EmailAddressQuestion
      id="proposer-email-address"
      title="personalDetails.emailAddressQuestion"
      tooltip={{description: "personalDetails.emailAddressQuestion.tooltip"}}
      emailAddressField={emailAddressField}
      onComplete={onEmailAddressChanged}
      onInvalid={onInvalid}
      placeholder="quoteDetails.contactDetails.email.placeholder"
    />
  );
};

export default ProposerEmailAddressQuestion;
