import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../../../../redux/Store";
import {PolicyHolder} from "shared/dist/generated/graphql/resolvers-types";
import {PersonalDetails} from "../../../quote/vehicle/your-details/redux/PersonalDetails";
import {PaymentFlowType} from "../models/PaymentFlowType";

const personalDetailsSelector = (state: RootState): PersonalDetails => state.personalDetails;
const policyHolderSelector = (state: RootState): PolicyHolder | undefined => state.portal.policyHolder;

type Selector<S> = (state: RootState) => S;

export const emailSelector = (paymentFlowType: PaymentFlowType): Selector<string | undefined> => createSelector(
  personalDetailsSelector,
  policyHolderSelector,
  (details, policyHolder): string | undefined => {
    return paymentFlowType === PaymentFlowType.QUOTE
      ? details.proposerEmailAddress.value
      : policyHolder?.metadata.email;
  }
);

export const phoneNumberSelector = (paymentFlowType: PaymentFlowType): Selector<string | undefined> => createSelector(
  personalDetailsSelector,
  policyHolderSelector,
  (details, policyHolder): string | undefined => {
    return paymentFlowType === PaymentFlowType.QUOTE
      ? details.phoneNumber.value
      : policyHolder?.metadata.phoneNumber;
  }
);

export const paymentTransactionIdSelector = (paymentFlowType: PaymentFlowType): Selector<string | undefined> => createSelector(
  (state: RootState) => state.quoteDetails.transactionId,
  (state: RootState) => state.renewal.transactionId,
  (quoteTransactionId, renewalTransactionId): string | undefined => {
    return paymentFlowType === PaymentFlowType.QUOTE
      ? quoteTransactionId
      : renewalTransactionId;
  }
);