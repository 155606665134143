import React, {FC} from "react";
import "./DocumentLinkSection.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import Title from "shared-components/dist/title/Title";

interface Props {
  title: TranslationKey;
  description?: TranslationKey;
}

const DocumentLinkSection: FC<Props> = (
  {
    title,
    description,
    children,
  }
) => (
  <div className="document-link-section">
    <Title title={title}/>
    {description && <p className="document-link-section__description">{lookupI18nString(description)}</p>}
    <div className="document-link-section__links">{children}</div>
  </div>
);

export default DocumentLinkSection;
