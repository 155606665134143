import React, {FC, ReactChild} from "react";
import {useNavigate} from "react-router-dom";

interface Props {
  href: string;
  label: ReactChild;
}

const DisplayButton: FC<Props> = ({label, href}) => {
  const navigate = useNavigate();
  return <>
    <button className={"reminder__notification__link"} onClick={() => navigate(href)}>
      {label}
    </button>
  </>;
};

export default DisplayButton;
