import React, {FC} from "react";
import "./AccountSettingsHeader.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";

const AccountSettingsHeader: FC = () => (
  <div className="account-settings-header">
    <h1 className="account-settings-header__title">{lookupI18nString("accountSettings.title")}</h1>
  </div>
);

export default AccountSettingsHeader;
