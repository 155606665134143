import {BreakInCover} from "../../../quote/vehicle/shared/services/BreakInCoverDetector";
import {ConditionalBooleanFormField} from "shared-components/dist/models/form-field/variants/BooleanFormField";
import {ConditionalDateFormField, DateFormField} from "shared-components/dist/models/form-field/variants/DateFormField";
import {Status} from "shared-components/dist/status-indicator/Status";
import {DateOption} from "shared/dist/models/date-option/DateOption";

export interface Props {
  breakInCover: BreakInCover;
  breakInCoverDeclarationAcceptance: ConditionalBooleanFormField;
  isPreviouslyInsured: boolean;
  newPreviousPolicyExpiryDate: ConditionalDateFormField;
  newVehicleDateOfPurchase: DateFormField;
  newCoverStartDateValue: DateOption | undefined;
  coverStartDateStatus: Status
}

export const shouldDisableAggregatorModalConfirmButton = (
  {
    breakInCover,
    breakInCoverDeclarationAcceptance,
    isPreviouslyInsured,
    newPreviousPolicyExpiryDate,
    newVehicleDateOfPurchase,
    coverStartDateStatus,
    newCoverStartDateValue
  }: Props
): boolean => {
  const isDeclarationShownAndNotConfirmed = breakInCover === "DECLARATION_REQUIRED" && !breakInCoverDeclarationAcceptance.value;
  const isBreakInCoverSoftStopShown = breakInCover === "CANNOT_INSURE_ONLINE";
  const isPreviousPolicyExpiryDateShownAndUnanswered = isPreviouslyInsured && newPreviousPolicyExpiryDate.value === undefined;
  const hasCoverStartDate = newCoverStartDateValue !== undefined;
  const hasAnError = [
    newPreviousPolicyExpiryDate.status,
    newVehicleDateOfPurchase.status,
    coverStartDateStatus
  ].includes("error");

  return [
    hasAnError,
    isDeclarationShownAndNotConfirmed,
    isBreakInCoverSoftStopShown,
    isPreviousPolicyExpiryDateShownAndUnanswered,
    !hasCoverStartDate
  ].includes(true);
};