import {QuoteExtras} from "shared/dist/generated/graphql/resolvers-types";
import {RootState} from "../../../../../redux/Store";

export function mapQuoteExtras(state: RootState): QuoteExtras {
  return {
    policyExtras: state.quoteDetails.policyExtras.map(policyExtra => ({
      type: policyExtra.id,
      optionType: policyExtra.optionType
    }))
  };
}