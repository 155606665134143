import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {MedicalCondition} from "shared/dist/generated/graphql/resolvers-types";

export const MEDICAL_CONDITIONS = [
  {
    id: MedicalCondition.DvlaAwareNoRestrictions,
    description: lookupI18nString("structure.questions.medicalConditions.dvlaAwareNoRestrictions"),
  },
  {
    id: MedicalCondition.RestrictionOneYear,
    description: lookupI18nString("structure.questions.medicalConditions.restricted.oneYear"),
  },
  {
    id: MedicalCondition.RestrictionTwoYears,
    description: lookupI18nString("structure.questions.medicalConditions.restricted.twoYears"),
  },
  {
    id: MedicalCondition.RestrictionThreeYears,
    description: lookupI18nString("structure.questions.medicalConditions.restricted.threeYears"),
  },
  {
    id: MedicalCondition.RestrictionFiveYears,
    description: lookupI18nString("structure.questions.medicalConditions.restricted.fiveYears"),
  },
  {
    id: MedicalCondition.DvlaUnaware,
    description: lookupI18nString("structure.questions.medicalConditions.dvlaUnaware"),
  }
] as const;

export type MedicalConditionOption = typeof MEDICAL_CONDITIONS[number];

export const MEDICAL_CONDITION_DESCRIPTIONS = MEDICAL_CONDITIONS.map(condition => condition.description);