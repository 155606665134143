import {hotjar} from "react-hotjar";
import {useEffect} from "react";
import {getCurrentPolicyHolderId} from "../user/authentication/services/GetCurrentPolicyHolderId";
import {strollSessionId} from "../providers/Environment";

const HotjarId = 2614490;
const HotjarVersion = 6;

export const initializeHotjar = (): void => {
  hotjar.initialize(HotjarId, HotjarVersion);
};

export const useHotjarIdentifier = (): void => {
  useEffect(() => {
    const identifyUser = async (): Promise<void> => {
      const policyHolderId = await getCurrentPolicyHolderId();

      hotjar.identify(policyHolderId ?? null, {
        stroll_session_id: strollSessionId
      });
    };

    if (hotjar.initialized()) {
      identifyUser();
    }
  });
};