import React, {FC} from "react";
import {RetrieveAddressResult} from "shared/dist/generated/graphql/resolvers-types";
import ActionCard from "shared-components/dist/cards/action-card/ActionCard";
import ActionCardInteractiveContent from "shared-components/dist/cards/action-card/ActionCardInteractiveContent";
import {DeleteForever} from "@mui/icons-material";
import ActionCardContent from "shared-components/dist/cards/action-card/ActionCardContent";

interface Props {
  address: RetrieveAddressResult | undefined;
  onReset: () => void;
}

const RemovableAddressCard: FC<Props> = ({address, onReset}) => {
  if (!address) return null;

  return (
    <ActionCard>
      <ActionCardContent>
        <strong>{address.firstLine}</strong>
        <p>{address.city}</p>
        <p>{address.postcode}</p>
      </ActionCardContent>

      <ActionCardInteractiveContent onClick={() => onReset()}>
        <DeleteForever/>
      </ActionCardInteractiveContent>
    </ActionCard>
  );
};

export default RemovableAddressCard;
