import {FC} from "react";
import {useAppSelector} from "../../../../../redux/Hooks";
import {selectSelectedQuoteType} from "../../../../portal/renewals/redux/RenewalSelectors";
import RenewalDepositPaymentLanding from "./RenewalDepositPaymentLanding";
import RebrokeDepositPaymentLanding from "./RebrokeDepositPaymentLanding";

const RenewalRebrokeDepositPaymentLanding: FC = () => {
  const selectedQuoteType = useAppSelector(selectSelectedQuoteType);
  if (!selectedQuoteType) throw new Error("No renewal quote selected");

  if (selectedQuoteType === "REBROKE") {
    return <RebrokeDepositPaymentLanding/>;
  }
  return <RenewalDepositPaymentLanding/>;
};

export default RenewalRebrokeDepositPaymentLanding;