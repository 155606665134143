import React, {FC} from "react";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import BadgeButton from "shared-components/dist/buttons/badge-button/BadgeButton";
import {Edit} from "@mui/icons-material";
import "./DisplayAccountSetting.css";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";

interface Props {
  name: string;
  headerText: TranslationKey;
  value: string;
  onEditToggled: () => void;
  isEditingAccountSetting: boolean
}

const DisplayAccountSetting: FC<Props> = (
  {
    name,
    headerText,
    value,
    onEditToggled,
    isEditingAccountSetting
  }
) => (
  <div className="display-account-setting">
    <h4>{lookupI18nString(headerText)}</h4>
    <div className="display-account-setting__content">
      <p id={`${name}-title`}>{value}</p>
      {isEditingAccountSetting ?
        <BadgeButton
          name={`cancel-edit-${name}-button`}
          onClick={onEditToggled}
          variant="gray">
          {lookupI18nString("accountSettings.form.cancel")}
        </BadgeButton>
        :
        <BadgeButton
          name={`edit-${name}-button`}
          onClick={onEditToggled}
          icon={<Edit/>}
          variant="gray">
          {lookupI18nString("accountSettings.form.edit")}
        </BadgeButton>
      }
    </div>
  </div>
);

export default DisplayAccountSetting;
