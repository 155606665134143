import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import React, {FC} from "react";
import Button from "shared-components/dist/buttons/button/Button";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./Stop.css";
import PlainCard from "shared-components/dist/cards/plain-card/PlainCard";
import ButtonGroup from "shared-components/dist/buttons/button-group/ButtonGroup";

export interface StopProps {
  visible: boolean;
  title: TranslationKey;
  classname?: string;
  description?: TranslationKey;
  primaryButtonTitle?: TranslationKey;
  secondaryButtonTitle?: TranslationKey;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
}

const Stop: FC<StopProps> = (
  {
    visible,
    title,
    classname,
    description,
    primaryButtonTitle,
    secondaryButtonTitle,
    onPrimaryButtonClick,
    onSecondaryButtonClick
  }
) => {
  if (!visible) return null;

  return (
    <PlainCard className="stop">
      <div className="stop__content">
        <div className="stop__content_header">
          <h4>{lookupI18nString(title)}</h4>
          {description && <p>{lookupI18nString(description)}</p>}
        </div>
        <ButtonGroup className="stop__content__button-group">
          {secondaryButtonTitle && onSecondaryButtonClick &&
            <div className={`stop__content__button-group__secondary ${classname ? `${classname}__button` : ""}`}>
              <Button onClick={onSecondaryButtonClick} variant="link">
                {lookupI18nString(secondaryButtonTitle)}
              </Button>
            </div>
          }
          {primaryButtonTitle && onPrimaryButtonClick &&
            <div className={`stop__content__button-group__primary ${classname ? `${classname}__button` : ""}`}>
              <Button onClick={onPrimaryButtonClick}>
                {lookupI18nString(primaryButtonTitle)}
              </Button>
            </div>
          }
        </ButtonGroup>
      </div>
    </PlainCard>
  );
};

export default Stop;