import {childSelectedValidator, grandchildSelectedValidator, grandparentSelectedValidator, parentSelectedValidator} from "../../../../pages/quote/vehicle/additional-driver/shared/components/date-of-birth/validators/RelationshipToProposerValidators";
import {RelationshipToProposerOption} from "../../../../pages/quote/vehicle/additional-driver/shared/components/relationship-to-proposer/model/RelationshipToProposerOption";
import {buildCrossFieldValidator, ReduxQuestion} from "../../Questions";
import {relationshipToProposerChanged, relationshipToProposerInvalid} from "../../../../pages/quote/vehicle/additional-driver/shared/redux/UpdateAdditionalDriverSlice";
import {
  civilPartnerSelectedValidator,
  commonLawSpouseSelectedValidator,
  multipleCivilPartnerSelectedValidator,
  multipleCommonLawSpouseSelectedValidator,
  multipleSpouseSelectedValidator,
  spouseSelectedValidator
} from "../../../../pages/quote/vehicle/additional-driver/shared/components/relationship-to-proposer/validators/MaritalStatusValidators";
import {
  businessPartnerSelectedValidator,
  employeeSelectedValidator, employerSelectedValidator
} from "../../../../pages/quote/vehicle/additional-driver/shared/components/employment/validators/EmploymentStatusValidators";
import {
  dateOfBirthAsDateSelector
} from "../../../../pages/quote/vehicle/additional-driver/shared/redux/selectors/AdditionalDriverDateTypeSelectors";
import {dateOfBirthAsDateSelector as proposerDateOfBirthSelector} from "../../../../pages/quote/vehicle/your-details/redux/selectors/PersonalDetailsSelectors";
import {
  relationshipToProposerMatchesMaritalStatusValidator
} from "../../../../pages/quote/vehicle/additional-driver/shared/components/marital-status/validators/AdditionalDriverMaritalStatusValidators";

export const ageRelationshipValidators = [childSelectedValidator, grandchildSelectedValidator, parentSelectedValidator, grandparentSelectedValidator];

export const workingRelationshipValidators = [employeeSelectedValidator, employerSelectedValidator, businessPartnerSelectedValidator];

export const relationshipToProposer: ReduxQuestion<RelationshipToProposerOption> = {
  selector: state => state.updateAdditionalDriver.relationshipToProposer,
  onChange: relationshipToProposerChanged,
  onInvalid: relationshipToProposerInvalid,
  crossFieldValidators: [
    buildCrossFieldValidator(
      state => state.updateAdditionalDriver.maritalStatus.value?.id,
      [relationshipToProposerMatchesMaritalStatusValidator]
    ),
    buildCrossFieldValidator(
      state => state.personalDetails.maritalStatus.value?.id,
      [spouseSelectedValidator, civilPartnerSelectedValidator, commonLawSpouseSelectedValidator]
    ),
    buildCrossFieldValidator(
      state => ({
        additionalDrivers: state.personalDetails.additionalDrivers,
        driverId: state.updateAdditionalDriver.idOfDriverBeingEdited
      }),
      [multipleSpouseSelectedValidator, multipleCivilPartnerSelectedValidator, multipleCommonLawSpouseSelectedValidator]
    ),
    buildCrossFieldValidator(
      state => ({
        additionalDriverDateOfBirth: dateOfBirthAsDateSelector(state).value,
        proposerDateOfBirth: proposerDateOfBirthSelector(state).value
      }),
      ageRelationshipValidators
    ),
    buildCrossFieldValidator(
      state => ({
        proposerEmploymentStatus: state.personalDetails.primaryEmployment.employmentStatus.value?.vtCode,
        employmentStatus: state.updateAdditionalDriver.primaryEmployment.employmentStatus.value?.vtCode
      }),
      workingRelationshipValidators
    )
  ]
};