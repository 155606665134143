import {FC} from "react";
import {VEHICLE_TYPE, VEHICLE_TYPE_DESCRIPTIONS} from "./VehicleTypeOption";
import {logYourCoverGoogleAnalyticsEvent} from "../../../../../../../../utils/analytics/MotorAnalytics";
import DropdownQuestion
  from "../../../../../../../../structure/questions/question/variants/dropdown-question/DropdownQuestion";
import {vehicleTypeChanged} from "../../../../redux/CoverDetailsSlice";
import {useAppDispatch, useAppSelector} from "../../../../../../../../redux/Hooks";

const VehicleTypeQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const vehicleTypeField = useAppSelector(state => state.coverDetails.vehicleType);

  const onVehicleTypeChanged = (selectedVehicleType: string): void => {
    const vehicleType = VEHICLE_TYPE.find(vehicleType => vehicleType.description === selectedVehicleType);

    if (vehicleType) {
      logYourCoverGoogleAnalyticsEvent({
        categorySuffix: "vehicle_type",
        action: vehicleType.description,
      });

      dispatch(vehicleTypeChanged(vehicleType));
    }
  };
  return (
    <DropdownQuestion
      id="vehicle-type-question"
      title="coverDetails.vehicleTypeQuestion.title"
      options={VEHICLE_TYPE_DESCRIPTIONS}
      value={vehicleTypeField.value?.description}
      onChange={onVehicleTypeChanged}
      placeholder="coverDetails.ownOrCompanyCarQuestion.placeholder"
      status={vehicleTypeField.status}
      errorMessage={vehicleTypeField.errorMessage}
      hideSpacer
    />
  );
};

export default VehicleTypeQuestion;