import {AlarmInstaller} from "shared/dist/generated/graphql/resolvers-types";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";

export const VEHICLE_ALARM_INSTALLER_OPTIONS: Record<AlarmInstaller, string> = {
  [AlarmInstaller.Manufacturer]: lookupI18nString("structure.questions.vehicle.alarmInstaller.manufacturer"),
  [AlarmInstaller.ManufacturerApproved]: lookupI18nString("structure.questions.vehicle.alarmInstaller.manufacturerApproved"),
  [AlarmInstaller.VsibAccredited]: lookupI18nString("structure.questions.vehicle.alarmInstaller.VSIBAccredited"),
  [AlarmInstaller.Proposer]: lookupI18nString("structure.questions.vehicle.alarmInstaller.proposer"),
  [AlarmInstaller.Unknown]: lookupI18nString("structure.questions.vehicle.alarmInstaller.unknown")
};

export interface AlarmInstallerOption {
  id: AlarmInstaller,
  description: string
}