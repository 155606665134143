import React, {FC} from "react";
import BooleanSubQuestion from "../../../../../../../structure/questions/sub-question/variants/boolean-sub-question/BooleanSubQuestion";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";
import {hasAlarmChanged} from "../../../redux/VehicleDetailsSlice";

const AdditionalDetailsHasAlarm: FC = () => {
  const dispatch = useAppDispatch();
  const hasAlarm = useAppSelector(state => state.vehicleDetails.additionalVehicleDetails.hasAlarm);

  return (
    <BooleanSubQuestion
      title="vehicleQuote.additionalVehicleDetailsForm.hasAlarm"
      id="hasAlarm"
      value={hasAlarm.value}
      status={hasAlarm.status}
      errorMessage={hasAlarm.errorMessage}
      onAnswer={value => dispatch(hasAlarmChanged(value))}
    />
  );
};

export default AdditionalDetailsHasAlarm;
