import React, {FC} from "react";
import GooglePayButton from "@google-pay/button-react";
import "./GooglePay.css";
import {usePaymentMerchantIdQuery} from "./query/PaymentMerchantIdQuery";
import {buildPaymentRequest} from "./builders/PaymentRequestBuilder";
import {logger} from "../../../../utils/logging/Logger";
import {Price} from "shared/dist/generated/graphql/resolvers-types";

interface Props {
  amountPayable: Price;
  makePayment: (paymentData: google.payments.api.PaymentData) => void;
}

const GooglePay: FC<Props> = ({amountPayable, makePayment}) => {
  const {error, loading, data} = usePaymentMerchantIdQuery();

  if (error) {
    logger.error("Error fetching merchant id", error);
    return null;
  }

  if (loading || !data) return null;

  return (
    <GooglePayButton
      buttonSizeMode="fill"
      buttonType="buy"
      className="google-pay"
      environment={getGooglePayEnvironment()}
      paymentRequest={buildPaymentRequest(amountPayable.amount, data.policySetup.googlePayMerchantId, data.policySetup.paymentMerchantId)}
      onLoadPaymentData={makePayment}
    />
  );
};

function getGooglePayEnvironment(): google.payments.api.Environment {
  return window.Stroll.IS_PRODUCTION ? "PRODUCTION" : "TEST";
}

export default GooglePay;
