import {validationFailure, ValidationResult, validationSuccess} from "shared-components/dist/utils/validation/Validator";
import {startOfDay} from "date-fns/fp";
import {isBefore, sub} from "date-fns";
import {now} from "shared/dist/stdlib/Dates";

export function validateConvictionDate(convictionDate: Date): ValidationResult {
  if (isFuture(convictionDate)) {
    return validationFailure("personalDetails.convictionsQuestions.subQuestion.date.dateInFutureError");
  } else if (isOlderThanFiveYears(convictionDate)) {
    return validationFailure("personalDetails.convictionsQuestions.subQuestion.date.dateOverFiveYearsError");
  }

  return validationSuccess;
}

const isFuture = (date: Date): boolean => {
  return startOfDay(date) > startOfDay(now());
};

const isOlderThanFiveYears = (date: Date): boolean => {
  const convictionDate = startOfDay(date);
  const todayDate = startOfDay(now());
  const todayMinus5Years = sub(todayDate, {years: 5});

  return isBefore(convictionDate, todayMinus5Years);
};