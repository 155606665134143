import React, {FC} from "react";
import "./Help.css";
import Navbar from "../../../structure/navigation/navbar/Navbar";
import Spacer from "shared-components/dist/spacer/Spacer";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import PageFooter from "../../../structure/footer/PageFooter";

const Help: FC = () => (
  <div className="help">
    <div>
      <Navbar/>
      <h1>{lookupI18nString("navbar.menu.helpAndSupport")}</h1>
      <div className="help__spacer">
        <Spacer/>
      </div>
    </div>
    <PageFooter isPortal/>
  </div>
);

export default Help;
