import React, {FC} from "react";
import QuoteJourneyStop, {QuoteJourneyStopType} from "../../../../../shared/stops/quote-journey-stop/QuoteJourneyStop";

interface Props {
  isVisible: boolean;
}

const ProhibitedAlloyWheelsModificationStop: FC<Props> = ({isVisible}) => (
  <QuoteJourneyStop
    stopProps={{
      visible: isVisible,
      title: "vehicleQuote.modifications.alloyWheelsQuestion.options.moreThan2Inches.stop.title"
    }}
    stopType={QuoteJourneyStopType.HardStop}
    isSubContainer
  />
);

export default ProhibitedAlloyWheelsModificationStop;