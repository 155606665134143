import React, {FC} from "react";
import "./PoliciesHeader.css";
import Navbar from "../../../../structure/navigation/navbar/Navbar";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import RemindersContainer from "../../shared/reminders/RemindersContainer";
import {Policy} from "shared/dist/generated/graphql/resolvers-types";
import PolicyCard from "../../dashboard/components/policy-card/PolicyCard";
import {getPolicyStatusFor} from "../../shared/utils/PolicyStatusUtils";

interface Props {
  pageSubHeader: TranslationKey;
  policy: Policy;
}

const PoliciesHeader: FC<Props> = ({pageSubHeader, policy}) => (
  <div className="policies-header">
    <Navbar/>
    <RemindersContainer/>
    <div className="policies-subheader">
      <div className="policies-subheader__container">
        <PolicyCard isDashboard={false} policyStatus={getPolicyStatusFor(policy)} policy={policy}/>
        <div className="policies-subheader__text">
          <h3>{lookupI18nString("policies.header.title")}</h3>
          <h1>{lookupI18nString(pageSubHeader)}</h1>
        </div>
      </div>
    </div>
  </div>
);

export default PoliciesHeader;
