import {
  Maybe,
  PolicyExtraOptionType,
  PolicyExtraType,
  QuoteExtra
} from "shared/dist/generated/graphql/resolvers-types";
import {StoredPolicyExtra} from "../../../quote/vehicle/your-quote/models/StoredPolicyExtra";
import {FullPolicyExtra, POLICY_EXTRA_OPTIONS} from "shared/dist/models/policy/AvailablePolicyExtras";

export function buildPolicyExtras(policyExtras: Maybe<Array<QuoteExtra>> | undefined): StoredPolicyExtra[] {
  if (!policyExtras) return [];

  return policyExtras
    .map(policyExtra => getDetailsFor(policyExtra))
    .filter((storedPolicyExtra): storedPolicyExtra is StoredPolicyExtra => storedPolicyExtra !== undefined);
}

function getDetailsFor(aggregatorQuoteExtra: QuoteExtra): StoredPolicyExtra | undefined {
  const policyExtraOption = aggregatorQuoteExtra.optionType ? getPolicyDetailsByTypeAndOptionType(aggregatorQuoteExtra.type, aggregatorQuoteExtra.optionType) : getPolicyDetailsByType(aggregatorQuoteExtra.type);

  if (!policyExtraOption) return undefined;

  return {
    optionType: policyExtraOption.optionType,
    id: policyExtraOption.type,
    price: policyExtraOption.grossAmount
  };
}

const getPolicyDetailsByType = (type: PolicyExtraType): FullPolicyExtra | undefined =>
  POLICY_EXTRA_OPTIONS.find(fullPolicyExtra => fullPolicyExtra.type === type);

const getPolicyDetailsByTypeAndOptionType = (type: PolicyExtraType, optionType: PolicyExtraOptionType): FullPolicyExtra | undefined =>
  POLICY_EXTRA_OPTIONS.find(fullPolicyExtra => fullPolicyExtra.type === type && fullPolicyExtra.optionType === optionType);
