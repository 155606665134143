import React, {FC} from "react";
import {FAULT_STATUS_DESCRIPTIONS, FAULT_STATUS_OPTIONS, FAULT_STATUSES, AtFaultOption} from "../models/AtFaultOption";
import DropdownSubQuestion
  from "../../../../../../../structure/questions/sub-question/variants/dropdown-sub-question/DropdownSubQuestion";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import {ClaimsFormSubQuestionProps} from "./ClaimsFormSubQuestionProps";

type Props = ClaimsFormSubQuestionProps<AtFaultOption>;

const FaultStatusSubQuestion: FC<Props> = (
  {
    value,
    onChange,
    status,
    isAdditionalDriverForm
  }
) => {
  const getTooltip = (): TooltipProps => {
    if (isAdditionalDriverForm) {
      return {
        title: "additionalDriver.claims.faultStatus.tooltip.title",
        description: "additionalDriver.claims.faultStatus.tooltip.description",
      };
    } else {
      return {
        title: "personalDetails.claims.faultStatus.tooltip.title",
        description: "personalDetails.claims.faultStatus.tooltip.description",
      };
    }
  };

  const onFaultStatusSelected = (selection: string): void => {
    const selectedFaultStatus = FAULT_STATUSES.find(status => status.vtCode === selection);
    if (!selectedFaultStatus) return;

    onChange(selectedFaultStatus);
  };

  return (
    <DropdownSubQuestion
      id="fault-status-sub-question"
      title={
        isAdditionalDriverForm
          ? "additionalDriver.claims.faultStatus.title"
          : "personalDetails.claims.faultStatus.title"
      }
      tooltip={getTooltip()}
      errorMessage="quote.errors.questionIncomplete"
      status={status}
      value={value?.vtCode}
      displayValues={FAULT_STATUS_DESCRIPTIONS}
      options={FAULT_STATUS_OPTIONS}
      onChange={onFaultStatusSelected}
    />
  );
};

export default FaultStatusSubQuestion;