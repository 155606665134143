import React, {FC} from "react";
import {assumptionsCorrectChanged} from "../../redux/VehicleDetailsSlice";
import BooleanQuestion from "../../../../../../structure/questions/question/variants/boolean-question/BooleanQuestion";
import {vehicleAssumptionsVisibleSelector} from "../../redux/selectors/VehiclePageStateSelectors";
import "./VehicleAssumptions.css";
import AssumptionsLeftHandDriveVehicleStop from "./stops/AssumptionsLeftHandDriveVehicleStop";
import AssumptionsEuImportVehicleStop from "./stops/AssumptionsEuImportVehicleStop";
import AssumptionsNonEuImportVehicleStop from "./stops/AssumptionsNonEuImportVehicleStop";
import AssumptionsProhibitedFuelTypeStop from "./stops/AssumptionsProhibitedFuelTypeStop";
import {logYourCarGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const VehicleAssumptions: FC = () => {
  const dispatch = useAppDispatch();
  const assumptionsCorrect = useAppSelector(state => state.vehicleDetails.assumptionsCorrect);
  const isVisible = useAppSelector(vehicleAssumptionsVisibleSelector);

  if (!isVisible) return null;

  function onAssumptionsCorrectChanged(value: boolean): void {
    dispatch(assumptionsCorrectChanged(value));
    logYourCarGoogleAnalyticsEvent({
      categorySuffix: "are_these_details_correct",
      action: value
    });
  }

  return (
    <div className="vehicle-assumptions-question">
      <BooleanQuestion
        id="areAssumptionsCorrect"
        title="vehicleQuote.vehicleAssumptions.questionTitle"
        formField={assumptionsCorrect}
        onAnswer={onAssumptionsCorrectChanged}
      />
      <AssumptionsLeftHandDriveVehicleStop/>
      <AssumptionsEuImportVehicleStop/>
      <AssumptionsNonEuImportVehicleStop/>
      <AssumptionsProhibitedFuelTypeStop/>
    </div>
  );
};

export default VehicleAssumptions;
