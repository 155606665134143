import {ClassOfUseOption} from "../../shared/questions/class-of-use/ClassOfUseOption";
import {EmploymentStatus, ProposedClassOfUse} from "shared/dist/generated/graphql/resolvers-types";
import {EmploymentStatusOption} from "../../shared/questions/employment/models/EmploymentStatusOption";
import {validationFailure, validationSuccess, Validator} from "shared-components/dist/utils/validation/Validator";

const businessUseEntitledEmploymentStatuses = [
  EmploymentStatus.Employed,
  EmploymentStatus.SelfEmployed,
  EmploymentStatus.VoluntaryWorker,
  EmploymentStatus.HouseSpouse,
];

const commutingUseEmploymentStatus = [
  EmploymentStatus.Employed,
  EmploymentStatus.SelfEmployed,
  EmploymentStatus.VoluntaryWorker,
  EmploymentStatus.FullTimeStudent,
  EmploymentStatus.IndependentMeans,
];

export const isClassOfUseValid = (employmentStatus: EmploymentStatusOption | undefined): Validator<ClassOfUseOption | undefined> => {
  return classOfUse => {
    const isBusinessClassOfUse = classOfUse?.id === ProposedClassOfUse.SocialCommutingAndBusiness;
    const isCommutingClassOfUse = classOfUse?.id === ProposedClassOfUse.SocialAndCommuting;

    if (!employmentStatus) return validationSuccess;

    if (!businessUseEntitledEmploymentStatuses.includes(employmentStatus.vtCode) && isBusinessClassOfUse) {
      return validationFailure("personalDetails.classOfUseQuestion.errors.nonBusinessEmploymentStatus");
    } else if (!commutingUseEmploymentStatus.includes(employmentStatus.vtCode) && isCommutingClassOfUse) {
      return validationFailure("personalDetails.classOfUseQuestion.errors.nonCommutingEmploymentStatus");
    }

    return validationSuccess;
  };
};
