import {FC} from "react";
import "./DetailConfirmationSidebar.css";
import KeyItemList from "shared-components/dist/lists/key-item-list/KeyItemList";
import NeedHelpSidebarFooter from "../need-help-sidebar-footer/NeedHelpSidebarFooter";
import PolicyExtraSidebarSelectorList from "../policy-extra-sidebar-selector-list/PolicyExtraSidebarSelectorList";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import FormattedMessage from "shared-components/src/translations/components/FormattedMessage";
import {
  selectActiveProspectiveLoan,
  selectActiveRenewalNotice,
  selectRenewalAdminFee,
  selectAreRenewalProspectiveLoansLoading,
  selectExistingPolicyForRenewal,
  selectRenewalRebrokePaymentFrequency,
  selectPremium,
  selectRenewalDepositPercentage,
  selectTotalFinancedRenewalAmount,
  selectTotalPolicyExtraAmount,
  selectTotalUnfinancedRenewalAmount
} from "../../redux/RenewalSelectors";
import {useAppSelector} from "../../../../../redux/Hooks";
import {CurrencyFormat} from "../../../../../utils/money/CurrencyFormat";
import RenewalSummaryError from "../renewal-summary-error/RenewalSummaryError";

const DetailConfirmationSidebar: FC = () => {
  const policy = useAppSelector(selectExistingPolicyForRenewal);
  const renewalNotice = useAppSelector(selectActiveRenewalNotice);
  const paymentFrequency = useAppSelector(selectRenewalRebrokePaymentFrequency);
  const activeProspectiveLoan = useAppSelector(selectActiveProspectiveLoan);
  const depositPercentage = useAppSelector(selectRenewalDepositPercentage);
  const totalFinancedAmount = useAppSelector(selectTotalFinancedRenewalAmount);
  const totalUnfinancedAmount = useAppSelector(selectTotalUnfinancedRenewalAmount);
  const policyExtrasAmount = useAppSelector(selectTotalPolicyExtraAmount);
  const loansAreLoading = useAppSelector(selectAreRenewalProspectiveLoansLoading);
  const premium = useAppSelector(selectPremium);
  const adminFee = useAppSelector(selectRenewalAdminFee);

  if (!renewalNotice) return <RenewalSummaryError/>;

  const isMonthly = paymentFrequency === "Monthly";

  const getDepositAmount = (): string => {
    if (depositPercentage === 0) return CurrencyFormat.formatPriceWithSymbol({amount: "0", currency: "gbp"});
    return CurrencyFormat.formatPriceWithSymbol(activeProspectiveLoan?.firstInstalmentAmount);
  };

  const getSummaryRows = (): { keyValue: string, item: string }[] => {
    const summaryRows = [
      {
        keyValue: lookupI18nString("portal.renewals.summary.priceSummary.card.table.insurancePremium"),
        item: CurrencyFormat.formatPriceWithSymbol(premium)
      },
      {
        keyValue: lookupI18nString("portal.renewals.summary.priceSummary.card.table.adminFee"),
        item: CurrencyFormat.formatPriceWithSymbol(adminFee)
      }
    ];

    if (isMonthly) {
      summaryRows.push({
        keyValue: lookupI18nString("portal.renewals.summary.priceSummary.card.monthly.deposit.title"),
        item: `${depositPercentage}% - ${getDepositAmount()}`
      });
    }

    summaryRows.push({
      keyValue: lookupI18nString("portal.renewals.summary.priceSummary.card.table.policyExtras"),
      item: CurrencyFormat.formatPriceWithSymbol(!loansAreLoading ? policyExtrasAmount : undefined)
    });

    return summaryRows;
  };

  return (
    <div className="detail-confirmation-sidebar" data-testid="detail-confirmation-sidebar">
      <div className="detail-confirmation-sidebar__summary-section">
        <div className="detail-confirmation-sidebar__summary-section__title">
          <h2>{lookupI18nString("portal.renewals.detailConfirmationSidebar.thisYearsPrice")}</h2>
          <h3>{policy?.vehicleDetails.registeredVehicle.registration}</h3>
        </div>

        <h1>
          {CurrencyFormat.formatPriceWithSymbol(isMonthly ? totalFinancedAmount : totalUnfinancedAmount)}
        </h1>

        {
          isMonthly &&
          <h3>
            <FormattedMessage
              id="portal.renewals.detailConfirmationSidebar.monthlyPrice"
              values={{
                installments: activeProspectiveLoan?.numberOfInstalments,
                price: CurrencyFormat.formatPriceWithSymbol(activeProspectiveLoan?.subsequentInstalmentAmounts),
                span: (msg: string) => <span>{msg}</span>
              }}/>
          </h3>
        }

        <KeyItemList rows={getSummaryRows()}/>
      </div>

      <div className="detail-confirmation-sidebar__policy-extras-section">
        <h2>Policy Extras</h2>

        <PolicyExtraSidebarSelectorList/>
      </div>

      <NeedHelpSidebarFooter/>
    </div>
  );
};

export default DetailConfirmationSidebar;
