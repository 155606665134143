import React, {FC, useEffect} from "react";
import SubQuestionForm from "../../../../../../structure/questions/sub-question-form/SubQuestionForm";
import DurationSubQuestion
  from "../../../../../../structure/questions/sub-question/variants/duration-sub-question/DurationSubQuestion";
import {useDurationDropdownProps} from "shared-components/dist/form/duration-dropdown/hooks/useDurationDropdownProps";
import {MAX_RESIDENCY_YEARS_OPTION} from "./constants/ResidencyDurations";
import {DurationConditionalFormField} from "shared-components/dist/models/form-field/variants/DurationFormField";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import ResidencyDurationStop from "./ResidencyDurationStop";
import {hasBeenUKResidentForLessThanAlive} from "../date-of-birth/validators/HasBeenUKResidentForLessThanAlive";
import {useIsFirstRender} from "../../../../../../utils/custom-hooks/UseIsFirstRender";

interface Props {
  id: string;
  title: TranslationKey;
  ukResidencyDuration: DurationConditionalFormField;
  ukResidencyDurationChanged: (value: Duration) => void;
  ukResidencyDurationInvalid: (value: TranslationKey) => void;
  ukResidencyDurationReset: () => void;
  dateOfBirth: Date | undefined;
  isStopVisible: boolean;
  isAdditionalDriverForm: boolean;
}

const ResidencyDurationSubQuestion: FC<Props> = ({
  id,
  title,
  ukResidencyDuration,
  ukResidencyDurationChanged,
  ukResidencyDurationInvalid,
  ukResidencyDurationReset,
  dateOfBirth,
  isStopVisible,
  isAdditionalDriverForm
}) => {
  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    if (ukResidencyDuration.status === "error" && !isFirstRender) ukResidencyDurationReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateOfBirth, ukResidencyDurationReset]);

  const onChange = (duration: Duration): void => {
    if (dateOfBirth) {
      const validationResult = hasBeenUKResidentForLessThanAlive(duration.years)(dateOfBirth);

      if (!validationResult.passed) {
        ukResidencyDurationInvalid(validationResult.errorMessage);
        return;
      }
    }

    ukResidencyDurationChanged(duration);
  };

  const {yearsDropdown, monthsDropdown} = useDurationDropdownProps({
    id,
    duration: ukResidencyDuration.value,
    onChange,
    maxYear: MAX_RESIDENCY_YEARS_OPTION,
  });

  if (!ukResidencyDuration.isActive) return null;

  return (
    <SubQuestionForm>
      <DurationSubQuestion
        subQuestion={{
          id,
          title,
          errorMessage: ukResidencyDuration.errorMessage,
          status: ukResidencyDuration.status
        }}
        yearsDropdown={yearsDropdown}
        monthsDropdown={{
          ...monthsDropdown,
          disabled: true,
          disabledBehaviour: "Hide"
        }}
      />
      <ResidencyDurationStop
        isVisible={isStopVisible}
        isAdditionalDriverForm={isAdditionalDriverForm}
      />
    </SubQuestionForm>
  );
};

export default ResidencyDurationSubQuestion;