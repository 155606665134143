import {SerializableConviction} from "../../../../quote/vehicle/shared/questions/convictions/models/Conviction";
import {
  ConvictionOffenceInput,
  ConvictionOffenceItem,
  ConvictionTypeInput,
  ConvictionTypeItem,
  ProposedPolicyConviction
} from "shared/dist/generated/graphql/resolvers-types";
import {removeTypename} from "../../../../../utils/graphql/ApolloClient";
import {parseDurationAsMonths} from "../../../../../utils/service/parsers/DurationToMonthsParser";
import {formatIsoDateStringFromIsoDateTime} from "shared/dist/stdlib/DateFormat";

export function mapPolicyConvictions(convictions: SerializableConviction[]): ProposedPolicyConviction[] {
  return convictions.map(conviction => (
    {
      type: removeTypename<ConvictionTypeItem, ConvictionTypeInput>(conviction.type),
      offence: removeTypename<ConvictionOffenceItem, ConvictionOffenceInput>(conviction.offence),
      date: formatIsoDateStringFromIsoDateTime(conviction.date),
      penaltyPoints: conviction.penaltyPoints,
      disqualificationPeriodMonths: parseDurationAsMonths(conviction.drivingBan),
      fine: conviction.fine,
      testMethod: conviction.testMethod?.id,
      alcoholLevel: conviction.alcoholLevel
    }
  ));
}