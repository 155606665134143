import {FC} from "react";
import {unstable_usePrompt as usePrompt} from "react-router-dom";

interface Props {
  when: boolean;
  message: string;
}

const Prompt: FC<Props> = ({when, message}) => {
  usePrompt({when, message});

  return null;
};

export default Prompt;
