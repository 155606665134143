import {BreakInCoverDeclaration} from "shared/dist/generated/graphql/resolvers-types";

export function mapBreakInCoverDeclaration(accepted: boolean | undefined): BreakInCoverDeclaration {
  switch (accepted) {
    case true:
      return BreakInCoverDeclaration.Accepted;
    case false:
      return BreakInCoverDeclaration.NotAccepted;
    case undefined:
      return BreakInCoverDeclaration.NotRequired;
  }
}