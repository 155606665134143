import {AppThunk} from "../../../../../../../../redux/Store";
import {currentQuoteDetailsWithExtrasSelector} from "../../../../redux/selectors/QuoteDetailsSelectors";
import {emailAddressChanged, quoteSaved} from "../../../../redux/QuoteDetailsSlice";
import {saveQuotesMutation} from "../../../../../../../../graphql/mutations/quote/save-quote/Mutation";

export const saveQuote = (emailAddress: string): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const savedQuote = currentQuoteDetailsWithExtrasSelector(state);
  const magicLinkId = state.quoteDetails.magicLinkId;

  if (!savedQuote || !magicLinkId) return;

  await saveQuotesMutation(emailAddress, savedQuote, magicLinkId);

  dispatch(emailAddressChanged(emailAddress));
  dispatch(quoteSaved(savedQuote));
};