import {
  hasSecondaryEmploymentChanged, secondaryEmploymentIndustryChanged, secondaryEmploymentJobChanged,
  secondaryEmploymentStatusChanged, secondaryEmploymentWorkedInPastYearChanged
} from "../../../../pages/quote/vehicle/additional-driver/shared/redux/UpdateAdditionalDriverSlice";
import {SectionQuestionsRecord} from "../../Questions";

export const secondaryEmployment = {
  hasSecondaryEmployment: {
    selector: state => state.updateAdditionalDriver.hasSecondaryEmployment,
    onChange: hasSecondaryEmploymentChanged
  },
  secondaryEmploymentStatus: {
    selector: state => state.updateAdditionalDriver.secondaryEmployment.employmentStatus,
    onChange: secondaryEmploymentStatusChanged,
  },
  secondaryEmploymentIndustry: {
    selector: state => state.updateAdditionalDriver.secondaryEmployment.industry,
    onChange: secondaryEmploymentIndustryChanged
  },
  secondaryEmploymentJob: {
    selector: state => state.updateAdditionalDriver.secondaryEmployment.job,
    onChange: secondaryEmploymentJobChanged
  },
  secondaryEmploymentWorkedInPastYear: {
    selector: state => state.updateAdditionalDriver.secondaryEmployment.workedInPastYear,
    onChange: secondaryEmploymentWorkedInPastYearChanged
  },
} as const satisfies SectionQuestionsRecord;