import React, {FC} from "react";
import Question from "../../../../../../structure/questions/question/Question";
import AddressLookup from "../../../../../../structure/form/address-lookup/components/AddressLookup";
import {addressChanged, addressInvalid, addressReset} from "../../redux/PersonalDetailsSlice";
import {RetrieveAddressResult} from "shared/dist/generated/graphql/resolvers-types";
import RemovableAddressCard from "./removable-address-card/RemovableAddressCard";
import {logYourDetailsGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const AddressQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const address = useAppSelector(state => state.personalDetails.address);

  const addressProvided = address.value !== undefined;
  const questionTitle = addressProvided ? "personalDetails.addressQuestion.titleWhenSet" : "personalDetails.addressQuestion";

  const onAddressChanged = (address: RetrieveAddressResult): void => {
    logYourDetailsGoogleAnalyticsEvent({
      categorySuffix: "postcode",
      action: "Answered",
    });
    dispatch(addressChanged(address));
  };

  return (
    <Question
      title={questionTitle}
      id="DetailsAddress"
      status={address.status}
      errorMessage={address.errorMessage}
      tooltip={{description: "personalDetails.addressQuestion.tooltip.description"}}
    >
      <>
        <AddressLookup
          onComplete={onAddressChanged}
          onInvalid={errorMessage => dispatch(addressInvalid(errorMessage))}
          isVisible={!addressProvided}
          placeholder="personalDetails.addressQuestion.placeholder"
        />
        <RemovableAddressCard
          address={address.value}
          onReset={() => dispatch(addressReset())}
        />
      </>
    </Question>
  );
};

export default AddressQuestion;
