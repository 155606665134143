import React, {FC, useState} from "react";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import CurrentDocumentsBar from "./current-documents-bar/CurrentDocumentsBar";
import {selectPolicyHolder} from "../../../../shared/redux/PortalSelectors";
import "./CurrentDocuments.css";
import PolicyErrorMessage from "../../../../shared/error/PolicyErrorMessage";
import {DocumentType, Policy, PolicyStatus} from "shared/dist/generated/graphql/resolvers-types";
import GreyMessageCard from "../../grey-message-card/GreyMessageCard";
import {formatOrdinalDateStringFromIsoString} from "shared/dist/stdlib/DateFormat";
import {useFeatureFlags} from "shared-components/dist/feature-flags/hooks/UseFeatureFlags";
import CurrentPolicyDocument from "./current-policy-document/CurrentPolicyDocument";
import PolicyExtrasDocumentList from "./current-document/policy-extras-document-list/PolicyExtrasDocumentList";
import RequestPrintDocumentsModal from "../request-print-documents/modal/RequestPrintDocumentsModal";
import {useAppSelector} from "../../../../../../redux/Hooks";
import {isAfter} from "date-fns";
import {now} from "shared/dist/stdlib/Dates";

const POLICY_EXTRA_DOCUMENT_TYPES = [
  DocumentType.SmartfobKeycare,
  DocumentType.CourtesyCar,
  DocumentType.ExcessInsurance,
  DocumentType.LegalExpenses,
  DocumentType.ExcessInsuranceScheduleOfInsurance,
  DocumentType.RoadsideAssistancePlatinum
];

interface Props {
  policy: Policy;
}

const CurrentDocuments: FC<Props> = ({policy}) => {
  const {requiredDocumentsCheckFlag, ipidIsARequiredDocumentFlag} = useFeatureFlags();
  const policyHolder = useAppSelector(selectPolicyHolder);
  const documents = (policyIsUpcoming() ? policy.documentation.upcoming : policy.documentation.active) ?? [];
  const [isPrintRequestModalVisible, setIsPrintRequestModalVisible] = useState(false);

  if (!policyHolder) return <PolicyErrorMessage/>;

  if (!allRequiredCoreDocumentsAreLoaded()) {
    return <GreyMessageCard message="policies.documents.processingMessage"/>;
  }

  function policyIsUpcoming(): boolean {
    return isAfter(new Date(policy.coverDetails.startDate), now());
  }

  function policyIsExpired(): boolean {
    return isAfter(now(), new Date(policy.coverDetails.endDate));
  }

  function policyIsCancelled(): boolean {
    return policy.policyStatus === PolicyStatus.PolicyCancelled;
  }

  function allRequiredCoreDocumentsAreLoaded(): boolean {
    if (!requiredDocumentsCheckFlag) return true;

    const documentTypes: DocumentType[] = documents.map(document => document.type);

    return (
      documentsContainType(documentTypes, DocumentType.CertificateAndScheduleOfInsurance) &&
      documentsContainType(documentTypes, DocumentType.StatementOfFact) &&
      documentsContainType(documentTypes, DocumentType.InsuranceProductInformation) &&
      documentsContainType(documentTypes, DocumentType.PolicyBooklet) &&
      documentsContainType(documentTypes, DocumentType.TermsOfBusinessAgreement)
    );
  }

  function documentsContainType(documentTypes: DocumentType[], type: DocumentType): boolean {
    if (type === DocumentType.InsuranceProductInformation && !ipidIsARequiredDocumentFlag) return true;

    return documentTypes.some(documentType => documentType === type);
  }

  const policyBooklet = documents.find(document => document.type === DocumentType.PolicyBooklet);

  const shouldEnablePrintRequests = (): boolean =>
    documents.some(document => document.type === DocumentType.InsuranceProductInformation);

  const getDocumentsTitle = (): string => {
    if (policyIsCancelled()) return lookupI18nString("policies.cancelledDocuments.title");
    if (policyIsExpired()) return lookupI18nString("policies.expiredDocuments.title");
    if (policyIsUpcoming()) return lookupI18nString("policies.upcomingDocuments.title");

    return lookupI18nString("policies.documents.activeDocuments");
  };

  return (
    <div className="current-documents">
      <div className="current-documents__title">
        <h1>{getDocumentsTitle()}</h1>
      </div>
      <CurrentDocumentsBar
        startDate={formatOrdinalDateStringFromIsoString(policy.coverDetails.startDate)}
        endDate={formatOrdinalDateStringFromIsoString(policy.coverDetails.endDate)}
        onRequestPrintDocuments={() => setIsPrintRequestModalVisible(true)}
        hidePrintRequestButton={policyIsExpired() || policyIsCancelled()}
        customerCanRequestPrintedDocuments={shouldEnablePrintRequests()}
      />
      <div className="current-documents__list">
        {documents
          .filter(document => !POLICY_EXTRA_DOCUMENT_TYPES.includes(document.type))
          .map((document, position) => <CurrentPolicyDocument policyDocument={document} key={position}/>
          )}

        <PolicyExtrasDocumentList policyExtraDocuments={documents.filter(document => POLICY_EXTRA_DOCUMENT_TYPES.includes(document.type))}/>
      </div>

      <RequestPrintDocumentsModal
        documentBatchProps={{
          policyId: policy.id,
          policyReference: policy.policyReference,
          policyEffectiveDate: policy.effectiveDate,
          documentsToPrint: documents
        }}
        policyHolder={policyHolder}
        policyBookletUrl={policyBooklet?.url}
        visible={isPrintRequestModalVisible}
        onDismiss={() => setIsPrintRequestModalVisible(false)}
      />
    </div>
  );
};

export default CurrentDocuments;
