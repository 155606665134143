import {AppThunk} from "../../../../../../../redux/Store";
import {additionalDriverRemoved} from "../../../../../../../redux/Actions";
import {updateBusinessMileageQuestionVisibility} from "../../../../shared/redux/ShouldShowBusinessMileageQuestion";
import {
  handleAdditionalDriverChangeForValidationFlow
} from "../../../../../aggregator-quote/redux/ExitResolveValidationErrorFlow";

export const removeAdditionalDriver = (driverId: string): AppThunk => async (dispatch, getState) => {
  await dispatch(handleAdditionalDriverChangeForValidationFlow());
  dispatch(additionalDriverRemoved(driverId));

  const state = getState();

  dispatch(updateBusinessMileageQuestionVisibility(
    state.personalDetails.classOfUse.value
  ));
};