import React, {FC, ReactElement} from "react";
import "./PolicyExtrasTableRow.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import FormattedMessage from "shared-components/src/translations/components/FormattedMessage";
import {CurrencyFormat} from "../../../../../utils/money/CurrencyFormat";
import {Price} from "shared/dist/generated/graphql/resolvers-types";
import Switch from "shared-components/dist/form/switch/Switch";
import {NorthEast} from "@mui/icons-material";
import Button from "shared-components/dist/buttons/button/Button";
import classNames from "classnames";
import {useAppSelector} from "../../../../../redux/Hooks";
import {selectAreRenewalProspectiveLoansLoading} from "../../redux/RenewalSelectors";

export interface PolicyExtrasTableRowProps {
  title: TranslationKey;
  description: TranslationKey;
  documentLink: string | undefined;
  thisYearsPrice: number | undefined;
  included: boolean;
  toggleIncluded: (included: boolean) => void;
  lastYearsPrice: Price | undefined;
  priceCellChildren?: ReactElement;
  testId?: string;
}

const PolicyExtrasTableRow: FC<PolicyExtrasTableRowProps> = ({
  title,
  description,
  documentLink,
  thisYearsPrice,
  included,
  toggleIncluded,
  lastYearsPrice,
  priceCellChildren,
  testId
}) => {
  const isSwitchDisabled = useAppSelector(selectAreRenewalProspectiveLoansLoading);

  const isPriceGreaterThanZero = (price: Price): boolean => parseFloat(price.amount) > 0;

  return (
    <div className="policy-extras-table-row" data-testid={testId}>
      <div className="policy-extras-table-row__cell">
        <h5>{lookupI18nString(title)}</h5>

        <p>{lookupI18nString(description)}</p>

        <Button
          data-testid="policy-extras-table-row__document-link"
          onClick={() => window.open(documentLink, "_blank")}
          disabled={!documentLink}
          variant="link"
        >
          {lookupI18nString("portal.renewals.summary.extrasTable.row.viewPolicyDocument")} <NorthEast/>
        </Button>
      </div>

      <div className={classNames("policy-extras-table-row__cell", "policy-extras-table-row__cell--centered")}>
        <span data-testid="policy-extras-table-row__current-price">
          <FormattedMessage
            id={included
              ? "portal.renewals.summary.extrasTable.row.addedToPlan"
              : "portal.renewals.summary.extrasTable.row.addToPlan"
            }
            values={{
              b: function formatBoldTags(chunks: string) {
                return <b>{chunks}</b>;
              },
              price: thisYearsPrice
                ? CurrencyFormat.formatPriceWithSymbol({amount: thisYearsPrice.toString(), currency: "gbp"})
                : "£--.--"
            }}
          />
        </span>

        {priceCellChildren}

        <span
          className="policy-extras-table-row__last-years-price--mobile"
          data-testid="policy-extras-table-row__last-years-price--mobile"
        >
          <FormattedMessage
            id={lastYearsPrice && isPriceGreaterThanZero(lastYearsPrice)
              ? "portal.renewals.summary.extrasTable.row.includedLastYear.mobile"
              : "portal.renewals.summary.extrasTable.row.notIncludedLastYear"
            }
            values={{
              price: lastYearsPrice && isPriceGreaterThanZero(lastYearsPrice)
                ? CurrencyFormat.formatPriceWithSymbol(lastYearsPrice)
                : "£--.--"
            }}
          />
        </span>

        <Switch value={included} onChange={toggleIncluded} disabled={isSwitchDisabled}/>
      </div>

      <div className={classNames("policy-extras-table-row__cell", "policy-extras-table-row__cell--centered")}>
        <span data-testid="policy-extras-table-row__last-years-price">
          <FormattedMessage
            id={lastYearsPrice && isPriceGreaterThanZero(lastYearsPrice)
              ? "portal.renewals.summary.extrasTable.row.includedLastYear"
              : "portal.renewals.summary.extrasTable.row.notIncludedLastYear"
            }
          />
        </span>
      </div>
    </div>
  );
};

export default PolicyExtrasTableRow;