import {useState} from "react";
import {doesPolicyHolderExistQuery} from "../../../../../graphql/queries/quote/does-policy-holder-exist/DoesPolicyHolderExistQuery";
import {proposerEmailAddressInvalid} from "../../your-details/redux/PersonalDetailsSlice";
import {hasContactDetailsBeenConfirmedChanged} from "../redux/QuoteDetailsSlice";
import {useAppDispatch, useAppSelector} from "../../../../../redux/Hooks";

interface UseProposerEmailIsUniqueValidation {
  validateProposerEmail: () => Promise<void>;
  isProposerEmailValid: boolean;
}

export function useProposerEmailIsUniqueValidation(): UseProposerEmailIsUniqueValidation {
  const dispatch = useAppDispatch();
  const [isProposerEmailValid, setIsProposerEmailValid] = useState(false);
  const proposerEmail = useAppSelector(state => state.personalDetails.proposerEmailAddress.value);

  const validateProposerEmail = async (): Promise<void> => {
    if (proposerEmail === undefined) return;
    const isProposerEmailInUse = await doesPolicyHolderExistQuery({email: proposerEmail});

    if (isProposerEmailInUse) {
      dispatch(hasContactDetailsBeenConfirmedChanged(false));
      dispatch(proposerEmailAddressInvalid("quoteDetails.contactDetails.email.error.accountAlreadyExists"));
    }
    setIsProposerEmailValid(!isProposerEmailInUse);
  };

  return {validateProposerEmail, isProposerEmailValid};
}