import React, {FC} from "react";
import SecondaryEmploymentQuestion from "../../../shared/questions/employment/SecondaryEmploymentQuestion";
import {
  hasSecondaryEmploymentChanged,
  secondaryEmploymentIndustryChanged,
  secondaryEmploymentJobChanged,
  secondaryEmploymentStatusChanged,
  secondaryEmploymentWorkedInPastYearChanged
} from "../../redux/PersonalDetailsSlice";
import {EMPLOYMENT_STATUS} from "../../../shared/questions/employment/models/EmploymentStatusOption";
import {isEmployed, isUnemployed} from "../../../shared/validation/ValidateEmploymentStatus";
import {logYourDetailsGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const ProposerSecondaryEmploymentQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const hasSecondaryEmployment = useAppSelector(state => state.personalDetails.hasSecondaryEmployment);
  const employmentStatus = useAppSelector(state => state.personalDetails.secondaryEmployment.employmentStatus);
  const industry = useAppSelector(state => state.personalDetails.secondaryEmployment.industry);
  const job = useAppSelector(state => state.personalDetails.secondaryEmployment.job);
  const workedInPastYear = useAppSelector(state => state.personalDetails.secondaryEmployment.workedInPastYear);
  const employedQuestionsActive = useAppSelector(state => isEmployed(state.personalDetails.secondaryEmployment.employmentStatus.value));
  const unemployedQuestionsActive = useAppSelector(state => isUnemployed(state.personalDetails.secondaryEmployment.employmentStatus.value));

  const onEmploymentYesNoQuestionChanged = (answer: boolean): void => {
    logYourDetailsGoogleAnalyticsEvent({
      categorySuffix: "employment_status.second_job",
      action: answer,
    });
    dispatch(hasSecondaryEmploymentChanged(answer));
  };

  const onSecondaryEmploymentStatusChanged = (selection: string): void => {
    const secondaryEmploymentStatus = EMPLOYMENT_STATUS.find(type => type.description === selection);

    if (secondaryEmploymentStatus) {
      dispatch(secondaryEmploymentStatusChanged(secondaryEmploymentStatus));
    }
  };

  return (
    <SecondaryEmploymentQuestion
      id="proposer-secondary-employment"
      secondaryEmploymentTitle="personalDetails.secondaryEmploymentQuestion"
      secondaryEmploymentTooltip={{
        title: "personalDetails.secondaryEmploymentQuestion.tooltip.title",
        description: "personalDetails.secondaryEmploymentQuestion.tooltip.description"
      }}
      hasSecondaryEmployment={hasSecondaryEmployment}
      hasSecondaryEmploymentChanged={onEmploymentYesNoQuestionChanged}
      employmentStatus={employmentStatus}
      industry={industry}
      job={job}
      workedInPastYear={workedInPastYear}
      employmentStatusChanged={onSecondaryEmploymentStatusChanged}
      industryChanged={value => dispatch(secondaryEmploymentIndustryChanged(value))}
      jobChanged={value => dispatch(secondaryEmploymentJobChanged(value))}
      workedInPastYearChanged={value => dispatch(secondaryEmploymentWorkedInPastYearChanged(value))}
      employedQuestionsActive={employedQuestionsActive}
      unemployedQuestionsActive={unemployedQuestionsActive}
      employmentStatusQuestion={{
        employmentStatusQuestionTitle: "personalDetails.secondaryEmploymentQuestion.employmentStatus.title",
        employmentStatusQuestionPlaceholder: "personalDetails.secondaryEmploymentQuestion.employmentStatus.placeholder"
      }}
      employedQuestions={{
        industryQuestionTitle: "personalDetails.secondaryEmploymentQuestion.employed.industry.title",
        jobQuestionTitle: "personalDetails.secondaryEmploymentQuestion.employed.job.title",
        industryQuestionTooltip: {
          title: "personalDetails.secondaryEmploymentQuestion.employed.industry.tooltip.title",
          description: "personalDetails.secondaryEmploymentQuestion.employed.industry.tooltip.description",
        },
        jobQuestionTooltip: {
          description: "personalDetails.secondaryEmploymentQuestion.employed.job.tooltip.description",
        },
      }}
      unemployedQuestions={{
        workedInPastYearQuestionTitle: "personalDetails.secondaryEmploymentQuestion.unemployed.hasWorkedInPastYear.title",
        previousJobQuestionTitle: "personalDetails.secondaryEmploymentQuestion.unemployed.previousJob.title",
        previousJobQuestionTooltip: {
          description: "personalDetails.secondaryEmploymentQuestion.unemployed.previousJob.tooltip.description"
        },
      }}
    />
  );
};

export default ProposerSecondaryEmploymentQuestion;
