import {RootState} from "../../../../../../redux/Store";
import {createSelector} from "@reduxjs/toolkit";
import {AdditionalDriver} from "../../../additional-driver/shared/models/AdditionalDriver";
import {RelationshipToProposer} from "shared/dist/generated/graphql/resolvers-types";
import {additionalDriversSelector} from "./PersonalDetailsSelectors";

export const allowedRelationshipsToProposer: RelationshipToProposer[] = [
  RelationshipToProposer.Spouse,
  RelationshipToProposer.CommonLawSpouse,
  RelationshipToProposer.PartnerCivil
];

export const mainDriverSelector = (state: RootState): string | undefined => state.personalDetails.mainDriver.value;

export const prohibitedMainDriverStopVisibleSelector = createSelector(
  mainDriverSelector,
  additionalDriversSelector,
  (mainDriver: string | undefined, additionalDrivers: AdditionalDriver[]): boolean => {
    if (!mainDriver || mainDriver === "Policy Holder") return false;

    const additionalDriver = additionalDrivers.find(driver => driver.id === mainDriver);
    return !(additionalDriver && allowedRelationshipsToProposer.includes(additionalDriver.relationshipToProposer.id));
  }
);