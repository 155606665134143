import {gql, useQuery} from "@apollo/client";

interface QueryResult {
  hasAccessTokenRetrievalError: boolean;
  accessToken?: string
}

export function useGlobalPayAccessToken(): QueryResult {
  const queryResult = useQuery(GET_ACCESS_TOKEN);

  return {
    hasAccessTokenRetrievalError: !!queryResult.error,
    accessToken: queryResult.data?.policySetup?.paymentAccessToken
  };
}

const GET_ACCESS_TOKEN = gql`
  query GetAccessToken {
    policySetup {
      paymentAccessToken
    }
  }
`;