import React, {FC} from "react";
import "./ManageYourPolicies.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import UploadDocumentsCard from "../components/upload-documents-card/UploadDocumentsCard";
import ContactCard from "../components/contact-card/ContactCard";

const ManageYourPolicies: FC = () => {
  return (
    <div className="dashboard-manage-your-policies">
      <div className="dashboard-manage-your-policies__header">
        {lookupI18nString("dashboard.subheader.manageYourPolicies")}
      </div>
      <div className="dashboard-manage-your-policies__cards">
        <UploadDocumentsCard/>
        <ContactCard/>
      </div>
    </div>
  );
};

export default ManageYourPolicies;
