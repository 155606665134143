import React, {FC} from "react";
import "./ClaimsHeader.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";

const ClaimsHeader: FC = () => (
  <div className="claims-header">
    <h1 className="claims-header__title">{lookupI18nString("navbar.menu.claims")}</h1>
  </div>
);

export default ClaimsHeader;
