import React, {FC, useState} from "react";
import {Status} from "shared-components/dist/status-indicator/Status";
import DateSubQuestion
  from "../../../../../../../structure/questions/sub-question/variants/date-sub-question/DateSubQuestion";
import {validateConvictionDate} from "../validation/ValidateConvictionDate";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";

interface Props {
  onChange: (value: Date | undefined) => void;
  value: Date | undefined;
  shouldFlagUnansweredQuestions: boolean;
  isAdditionalDriverForm: boolean;
}

const ConvictionDateSubQuestion: FC<Props> = (
  {
    onChange,
    value,
    shouldFlagUnansweredQuestions,
    isAdditionalDriverForm
  }
) => {
  const [dateValidatorError, setDateValidatorError] = useState<TranslationKey | undefined>(undefined);

  const tooltip: TooltipProps = {
    description: isAdditionalDriverForm
      ? "additionalDriver.convictionsQuestions.subQuestion.date.tooltip.description"
      : "personalDetails.convictionsQuestions.subQuestion.date.tooltip.description"
  };

  function onComplete(value: Date | undefined): void {
    setDateValidatorError(undefined);
    onChange(value);
  }

  function getDateErrorStatus(): Status {
    if (dateValidatorError || (shouldFlagUnansweredQuestions && value === undefined)) return "error";

    return "default";
  }

  function getDateErrorMessage(): TranslationKey {
    return dateValidatorError ?? "quote.errors.questionIncomplete";
  }

  return (
    <DateSubQuestion
      id="date-of-conviction-sub-question"
      title={
        isAdditionalDriverForm
          ? "additionalDriver.convictionsQuestions.subQuestion.date.title"
          : "personalDetails.convictionsQuestions.subQuestion.date.title"
      }
      value={value}
      onComplete={onComplete}
      onInvalid={setDateValidatorError}
      onClear={() => onChange(undefined)}
      validators={[validateConvictionDate]}
      status={getDateErrorStatus()}
      errorMessage={getDateErrorMessage()}
      tooltip={tooltip}
    />
  );
};

export default ConvictionDateSubQuestion;