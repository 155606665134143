import {useState} from "react";
import {isAfter, startOfDay} from "date-fns";
import {now} from "shared/dist/stdlib/Dates";
import {useAppSelector} from "../../../../../redux/Hooks";

interface UseExpiredQuoteDetection {
  validateQuoteStartDate: () => void;
  isCoverStartDateValid: boolean;
}

export function useExpiredQuoteStartDateDetection(
  setCoverStartDateExpiredModalVisible: (value: boolean) => void
): UseExpiredQuoteDetection {
  const [isCoverStartDateValid, setIsCoverStartDateValid] = useState(false);
  const coverStartDate = useAppSelector(state => state.coverDetails.coverStartDate?.value?.date);

  const validateQuoteStartDate = (): void => {
    if (coverStartDate === undefined) return completeValidation(false);

    const isCoverStartDateAfterStartOfToday = isAfter(new Date(coverStartDate), startOfDay(now()));
    completeValidation(isCoverStartDateAfterStartOfToday);
  };

  const completeValidation = (valid: boolean): void => {
    if (!valid) setCoverStartDateExpiredModalVisible(true);
    setIsCoverStartDateValid(valid);
  };

  return {
    validateQuoteStartDate,
    isCoverStartDateValid
  };
}