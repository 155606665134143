import React, {FC, useCallback} from "react";
import ResidencyQuestion from "../../../shared/questions/residency/ResidencyQuestion";
import {
  hasUkResidencyFromBirthChanged,
  ukResidencyDurationChanged,
  ukResidencyDurationInvalid, ukResidencyDurationReset
} from "../../redux/PersonalDetailsSlice";
import {residencyDurationStopVisibleSelector} from "../../redux/selectors/ResidencyDurationStopVisibleSelector";
import {logYourDetailsGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {dateOfBirthAsDateSelector} from "../../redux/selectors/PersonalDetailsSelectors";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const ProposerResidencyQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const hasUkResidencyFromBirth = useAppSelector(state => state.personalDetails.hasUkResidencyFromBirth);
  const ukResidencyDuration = useAppSelector(state => state.personalDetails.ukResidencyDuration);
  const dateOfBirth = useAppSelector(state => dateOfBirthAsDateSelector(state).value);
  const isStopVisible = useAppSelector(residencyDurationStopVisibleSelector);

  const onChanged = (answer: boolean): void => {
    logYourDetailsGoogleAnalyticsEvent({
      categorySuffix: "continuously_live_in_uk",
      action: answer,
    });
    dispatch(hasUkResidencyFromBirthChanged(answer));
  };

  const onResidencyDurationReset = useCallback(() => {
    dispatch(ukResidencyDurationReset());
  }, [dispatch]);

  return (
    <ResidencyQuestion
      id="proposer-uk-residency-from-birth-question"
      title="personalDetails.residency.fromBirth"
      subQuestionId="proposer-uk-residency-duration-sub-question"
      subQuestionTitle="personalDetails.residency.duration"
      tooltip={{description: "personalDetails.residency.fromBirth.tooltip"}}
      hasUkResidencyFromBirth={hasUkResidencyFromBirth}
      hasUkResidencyFromBirthChanged={onChanged}
      ukResidencyDuration={ukResidencyDuration}
      ukResidencyDurationChanged={value => dispatch(ukResidencyDurationChanged(value))}
      ukResidencyDurationInvalid={errorMessage => dispatch(ukResidencyDurationInvalid(errorMessage))}
      ukResidencyDurationReset={onResidencyDurationReset}
      dateOfBirth={dateOfBirth}
      isStopVisible={isStopVisible}
    />
  );
};

export default ProposerResidencyQuestion;