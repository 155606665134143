import {format, isMatch, parse, parseISO} from "date-fns";
import {formatInTimeZone} from "date-fns-tz";

export const DEFAULT_USER_TIMEZONE = "Europe/London";
const GREGORIAN_FORMAT = "dd/MM/yyyy";

export const toGregorianFormat = (date: Date): string => formatInTimeZone(date, DEFAULT_USER_TIMEZONE, GREGORIAN_FORMAT);

export function formatIsoDate(date: Date): string {
  return formatInTimeZone(date, DEFAULT_USER_TIMEZONE, "yyyy-MM-dd");
}

export function formatDateStringFromIsoString(isoDate: string): string {
  if (!isValidIsoDate(isoDate)) throw Error(`Not a valid ISO date string: ${isoDate}`);
  return formatInTimeZone(isoDate, DEFAULT_USER_TIMEZONE, "dd/MM/yyyy");
}

export function formatOrdinalDateStringFromIsoString(isoDate: string): string {
  if (!isValidIsoDate(isoDate)) throw Error(`Not a valid ISO date string: ${isoDate}`);
  return formatInTimeZone(isoDate, DEFAULT_USER_TIMEZONE, "do MMM yyyy");
}

export function formatDateAndTimeFromIsoString(isoDate: string): string {
  if (!isValidIsoDate(isoDate)) throw Error(`Not a valid ISO date string: ${isoDate}`);
  return formatInTimeZone(isoDate, DEFAULT_USER_TIMEZONE, "dd/MM/yyyy HH:mm");
}

export function formatDateStringWithDashesFromIsoString(isoDate: string): string {
  if (!isValidIsoDate(isoDate)) throw Error(`Not a valid ISO date string: ${isoDate}`);
  return formatInTimeZone(isoDate, DEFAULT_USER_TIMEZONE, "dd-MM-yyyy");
}

export function formatIsoDateStringFromIsoDateTime(isoString: string): string {
  if (!isValidIsoDate(isoString)) throw Error(`Not a valid ISO date string: ${isoString}`);
  return formatInTimeZone(isoString, DEFAULT_USER_TIMEZONE, "yyyy-MM-dd");
}

export function formatTimeStringFromIsoString(isoDate: string): string {
  if (!isValidIsoDate(isoDate)) throw Error(`Not a valid ISO date string: ${isoDate}`);
  return formatInTimeZone(isoDate, DEFAULT_USER_TIMEZONE, "HH:mm:ss");
}

export function formatTimeWithoutSecondsStringFromIsoString(isoDate: string): string {
  if (!isValidIsoDate(isoDate)) throw Error(`Not a valid ISO date string: ${isoDate}`);
  return formatInTimeZone(isoDate, DEFAULT_USER_TIMEZONE, "HH:mm");
}

export function formatTimeAmPmStringFromIsoString(isoDate: string): string {
  if (!isValidIsoDate(isoDate)) throw Error(`Not a valid ISO date string: ${isoDate}`);
  return formatInTimeZone(isoDate, DEFAULT_USER_TIMEZONE, "h:mm a");
}

export function formatDateStringToDocumentDateTimeFormat(isoDate: string): string {
  if (!isValidIsoDate(isoDate)) throw Error("Not a valid ISO date string");
  return formatInTimeZone(isoDate, DEFAULT_USER_TIMEZONE, "do MMM, yyyy HH:mm");
}

export function formatDateStringToDocumentDateFormat(isoDate: string): string {
  if (!isValidIsoDate(isoDate)) throw Error("Not a valid ISO date string");
  return formatInTimeZone(isoDate, DEFAULT_USER_TIMEZONE, "do MMM, yyyy");
}

export function formatGregorianDateStringToIsoDateString(gregorianDate: string): string {
  if (!isValidGregorianDate(gregorianDate)) throw Error("Not a valid Gregorian date string");
  return formatIsoDate(parse(gregorianDate, GREGORIAN_FORMAT, new Date()));
}

export function formatGregorianDateStringToDate(gregorianDate: string): Date {
  if (!isValidGregorianDate(gregorianDate)) throw Error("Not a valid Gregorian date string");
  //returns yyyy-mm-dd.
  const isoDateString = formatIsoDate(parse(gregorianDate, GREGORIAN_FORMAT, new Date()));
  return new Date(isoDateString);
}

export function isValidGregorianDate(gregorianDate: string): boolean {
  return isMatch(gregorianDate, GREGORIAN_FORMAT);
}

export const toReadableDateFormat = (date: Date): string => {
  return format(date, "EEEE do LLL");
};

export function isValidIsoDate(isoDate: string): boolean {
  return !isNaN(parseISO(isoDate).getTime());
}
