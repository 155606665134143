import {gql, useQuery} from "@apollo/client";
import {QueryResult} from "@apollo/client/react/types/types";

interface MerchantIdQuery {
  policySetup: {
    paymentMerchantId: string;
    googlePayMerchantId: string;
  }
}

const MERCHANT_ID_QUERY = gql`
  {
    policySetup {
      paymentMerchantId
      googlePayMerchantId
    }
  }
`;

export const usePaymentMerchantIdQuery = (): QueryResult<MerchantIdQuery> => useQuery<MerchantIdQuery>(MERCHANT_ID_QUERY);