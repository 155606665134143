import {ModificationType} from "shared/src/generated/graphql/resolvers-types";
import {
  MODIFICATION_TYPE_OPTIONS
} from "../../../quote/vehicle/your-vehicle/components/modifications/model/ModificationTypeOption";
import {Modification} from "../../../quote/vehicle/your-vehicle/components/modifications/model/Modification";

export function buildModification(modification: ModificationType): Modification {
  const modificationType = MODIFICATION_TYPE_OPTIONS.find(type => type.id === modification);
  if (modificationType === undefined) throw new Error(`Unknown Modification Type: ${modificationType}`);

  return {type: modificationType};
}