import React, {FC} from "react";
import {licenceCountryOfIssueChanged} from "../../redux/UpdateAdditionalDriverSlice";
import LicenceCountryOfIssueQuestion from "../../../../shared/questions/licence-country-of-issue/LicenceCountryOfIssueQuestion";
import {LicenceCountryOfIssue} from "../../../../shared/questions/licence-country-of-issue/LicenceCountryOfIssue";
import {licenceCountryOfIssueStopVisibleSelector} from "../../redux/selectors/LicenceCountryOfIssueStopVisibleSelector";
import {logAdditionalDriverGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const AdditionalDriverLicenceCountryOfIssueQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const licenceCountryOfIssueField = useAppSelector(state => state.updateAdditionalDriver.licenceCountryOfIssue);
  const isStopVisible = useAppSelector(licenceCountryOfIssueStopVisibleSelector);

  const onLicenceCountryOfIssueChanged = (answer: LicenceCountryOfIssue): void => {
    logAdditionalDriverGoogleAnalyticsEvent({
      categorySuffix: "where_licence_issued",
      action: answer.description.toLowerCase()
    });

    dispatch(licenceCountryOfIssueChanged(answer));
  };

  return (
    <LicenceCountryOfIssueQuestion
      id="additional-driver-licence-country-of-issue"
      title="additionalDriver.licenceCountryOfIssueQuestion"
      licenceCountryOfIssueField={licenceCountryOfIssueField}
      onComplete={onLicenceCountryOfIssueChanged}
      isStopVisible={isStopVisible}
    />
  );
};

export default AdditionalDriverLicenceCountryOfIssueQuestion;