import {BreakInCover} from "../../services/BreakInCoverDetector";
import {ConditionalBooleanFormField} from "shared-components/dist/models/form-field/variants/BooleanFormField";
import {useEffect, useState} from "react";
import {useAppSelector} from "../../../../../../redux/Hooks";

export interface ModalBreakInCover {
  breakInCover: BreakInCover;
  breakInCoverDeclarationAcceptance: ConditionalBooleanFormField;
  onBreakInCoverChanged: (value: BreakInCover) => void;
  onBreakInCoverDeclarationAnswered: (value: boolean) => void;
  breakInCoverReset: () => void;
}

export function useBreakInCoverWithinModal(): ModalBreakInCover {
  const initialBreakInCover = useAppSelector(state => state.coverDetails.breakInCover);
  const [breakInCover, setBreakInCover] = useState<BreakInCover>(initialBreakInCover);

  const initialBreakInCoverDeclarationAcceptance = useAppSelector(state => state.coverDetails.breakInCoverDeclarationAcceptance);
  const [breakInCoverDeclarationAcceptance, setBreakInCoverDeclarationAcceptance] = useState<ConditionalBooleanFormField>(initialBreakInCoverDeclarationAcceptance);

  useEffect(() => {
    setBreakInCover(initialBreakInCover);
  }, [initialBreakInCover]);

  useEffect(() => {
    setBreakInCoverDeclarationAcceptance(initialBreakInCoverDeclarationAcceptance);
  }, [initialBreakInCoverDeclarationAcceptance]);

  const onBreakInCoverChanged = (value: BreakInCover): void => {
    if (value !== breakInCover) {
      setBreakInCoverDeclarationAcceptance({
        value: undefined,
        status: "default",
        errorMessage: undefined,
        isActive: value === "DECLARATION_REQUIRED"
      });
    }

    setBreakInCover(value);
  };

  const onBreakInCoverDeclarationAnswered = (value: boolean): void => {
    setBreakInCoverDeclarationAcceptance({
      ...breakInCoverDeclarationAcceptance,
      value,
    });
  };

  const breakInCoverReset = (): void => {
    setBreakInCover(initialBreakInCover);
    setBreakInCoverDeclarationAcceptance(initialBreakInCoverDeclarationAcceptance);
  };

  return {
    breakInCover,
    breakInCoverDeclarationAcceptance,
    onBreakInCoverChanged,
    onBreakInCoverDeclarationAnswered,
    breakInCoverReset,
  };
}
