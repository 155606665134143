import {DocumentType} from "shared/dist/generated/graphql/resolvers-types";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";

export function mapDocumentTypeToDocumentDescription(documentType: DocumentType): TranslationKey {
  switch (documentType) {
    case DocumentType.CertificateAndScheduleOfInsurance:
      return "policies.documents.type.certificateAndScheduleOfInsurance.description";
    case DocumentType.StatementOfFact:
      return "policies.documents.type.statementOfFact.description";
    case DocumentType.NoClaimsBonusProtection:
      return "policies.documents.type.noClaimsBonusProtection.description";
    case DocumentType.InsuranceProductInformation:
      return "policies.documents.type.insuranceProductInformation.description";
    case DocumentType.TermsOfBusinessAgreement:
      return "policies.documents.type.termsOfBusinessAgreement.description";
    case DocumentType.PolicyBooklet:
      return "policies.documents.type.policyWordingBooklet.description";
    default:
      return "policies.documents.type.default.description.placeholder";
  }
}