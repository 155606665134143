import React, {FC, useState} from "react";
import {isCtrlKeyCombo, isPositiveNumberInputKey} from "shared-components/dist/utils/input/InputHelpers";
import InlineQuestion from "../../../../../../structure/questions/inline-question/InlineQuestion";
import InputField from "shared-components/dist/form/input-field/InputField";
import {isMinLength} from "shared-components/dist/utils/validators/StringValidators";
import {accountNumberChanged, accountNumberInvalid,} from "../../redux/MonthlyPaymentSlice";
import {logPaymentGoogleAnalyticsEvent} from "../../../../../../utils/analytics/PaymentAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const MINIMUM_UNITED_KINGDOM_BANK_ACCOUNT_NUMBER_LENGTH = 6;
const MAXIMUM_UNITED_KINGDOM_BANK_ACCOUNT_NUMBER_LENGTH = 8;

const AccountNumberField: FC = () => {
  const dispatch = useAppDispatch();
  const accountNumberField = useAppSelector(state => state.monthlyPayment.accountNumber);

  const [value, setValue] = useState(accountNumberField.value ?? "");

  return (
    <InlineQuestion
      id="account-number-field"
      label="paymentFlow.monthly.directDebit.form.bankDetails.accountNumber.title"
      status={accountNumberField.status}
      errorMessage={accountNumberField.errorMessage}
    >
      <InputField
        value={value}
        onChange={(event) => {
          const numericAccountNumber = event.currentTarget.value.replace(/\D/g, "");
          setValue(numericAccountNumber.substring(0, MAXIMUM_UNITED_KINGDOM_BANK_ACCOUNT_NUMBER_LENGTH));
          dispatch(accountNumberChanged(value));
        }}
        onInvalid={(errorMessage) => dispatch(accountNumberInvalid(errorMessage))}
        changeIf={[isPositiveNumberInputKey, isCtrlKeyCombo]}
        onBlur={() => {
          logPaymentGoogleAnalyticsEvent({
            categorySuffix: "monthly.bank_details.account_number",
            action: "Input"
          });
          dispatch(accountNumberChanged(value));
        }}
        validators={[
          isMinLength(MINIMUM_UNITED_KINGDOM_BANK_ACCOUNT_NUMBER_LENGTH, "paymentFlow.monthly.directDebit.form.bankDetails.accountNumber.error")
        ]}
        pattern="\d*"
      />
    </InlineQuestion>
  );
};

export default AccountNumberField;