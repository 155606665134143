import React, {FC} from "react";
import "./RenewalDetailsConfirmationTableItem.css";

export interface RenewalDetailsConfirmationTableItemProps {
  testId: string;
  title: string;
  subtitle: string;
  listItems?: string[];
}

const RenewalDetailsConfirmationTableItem: FC<RenewalDetailsConfirmationTableItemProps> = ({
  testId,
  title,
  subtitle,
  listItems = []
}) => (
  <div
    data-testid={`renewal-details-confirmation-table-item__${testId}`}
    className="renewal-details-confirmation-table-item"
  >
    <h3 data-testid="renewal-details-confirmation-table-item__title">{title}</h3>

    {listItems.length > 0 &&
      <ul
        className="renewal-details-confirmation-table-item__list"
        data-testid="renewal-details-confirmation-table-item__list"
      >
        {listItems.map((item, index) => (
          <li key={index} data-testid={`renewal-details-confirmation-table-item__list--item${index}`}>{item}</li>
        ))}
      </ul>
    }

    <h4 id={testId} data-testid="renewal-details-confirmation-table-item__subtitle" title={subtitle}>{subtitle}</h4>
  </div>
);

export default RenewalDetailsConfirmationTableItem;