import {ReduxQuestion} from "../../Questions";
import {
  assumptionsCorrectChanged,
  assumptionsCorrectInvalid,
  assumptionsCorrectReset
} from "../../../../pages/quote/vehicle/your-vehicle/redux/VehicleDetailsSlice";

export const assumptionsCorrect: ReduxQuestion<boolean> = {
  selector: state => state.vehicleDetails.assumptionsCorrect,
  onChange: assumptionsCorrectChanged,
  onReset: assumptionsCorrectReset,
  onInvalid: assumptionsCorrectInvalid,
};