import {ReduxQuestion} from "../../Questions";
import {
  proposerEmailAddressChanged,
  proposerEmailAddressInvalid
} from "../../../../pages/quote/vehicle/your-details/redux/PersonalDetailsSlice";

export const emailAddress: ReduxQuestion<string> = {
  selector: state => state.personalDetails.proposerEmailAddress,
  onChange: proposerEmailAddressChanged,
  onInvalid: proposerEmailAddressInvalid,
};