import React, {FC, FormEvent, MouseEvent, useState} from "react";
import Modal from "shared-components/dist/modals/Modal";
import "./RetrieveQuoteModal.css";
import {isValidEmailAddress} from "shared-components/dist/utils/validation/EmailAddresses";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import EmailAddressInput from "shared-components/dist/form/email-address-input/EmailAddressInput";
import ButtonGroup from "shared-components/dist/buttons/button-group/ButtonGroup";
import Button from "shared-components/dist/buttons/button/Button";
import SimpleModalHeader from "shared-components/dist/modals/simple-modal-header/SimpleModalHeader";
import {logger} from "../../../utils/logging/Logger";
import {emailLatestSavedQuote} from "../../../graphql/queries/quote/email-latest-saved-quote/EmailLatestSavedQuote";

interface Props {
  visible: boolean;
  dismiss: () => void;
}

const RetrieveQuoteModal: FC<Props> = ({visible, dismiss}) => {
  const [emailAddress, setEmailAddress] = useState("");
  const [validEmailEntered, setValidEmailEntered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasFormBeenSubmitted, setHasFormBeenSubmitted] = useState(false);

  const onRetrieveQuote = (event: FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>): void => {
    setHasFormBeenSubmitted(true);
    event.preventDefault();
    setLoading(true);
    emailLatestSavedQuote(emailAddress)
      .catch(error => {
        logger.error("Error sending retrieve quote email", error);
      });
  };

  const onEmailChanged = (value: string): void => {
    setValidEmailEntered(isValidEmailAddress(value));
    setEmailAddress(value);
  };

  const resetModal = (): void => {
    setEmailAddress("");
    setValidEmailEntered(false);
    setLoading(false);
    setHasFormBeenSubmitted(false);
  };

  const onDismiss = (): void => {
    dismiss();
    resetModal();
  };

  return (
    <>
      <Modal
        visible={visible}
        onDismiss={onDismiss}
        className="retrieve-quote-modal"
        header={<SimpleModalHeader
          title={hasFormBeenSubmitted ? "quote.header.modal.retrieveQuote.checkYourEmails.title" : "quote.header.modal.retrieveQuote"}
          onDismiss={onDismiss}
        />}
        body={
          hasFormBeenSubmitted
            ? <p>{lookupI18nString({
              id: "quote.header.modal.retrieveQuote.checkYourEmails.description",
              templateVariables: {emailAddress}
            })}</p>
            : <form onSubmit={onRetrieveQuote}>
              <h5>{lookupI18nString("quote.header.modal.retrieveQuote.emailAddress")}</h5>
              <EmailAddressInput
                name="retrieve-quote-modal-email-address-input"
                value={emailAddress}
                onChange={onEmailChanged}
                disabled={loading}/>
              <p>{lookupI18nString("quote.header.modal.retrieveQuote.description")}</p>
            </form>
        }
        footer={
        hasFormBeenSubmitted ? <></> :
          <ButtonGroup>
            <Button
              variant="tertiary"
              onClick={dismiss}
              expanded
            >
              {lookupI18nString("quote.header.modal.retrieveQuote.footer.cancel")}
            </Button>

            <Button
              type="submit"
              variant="primary"
              onClick={onRetrieveQuote}
              disabled={!validEmailEntered}
              loading={loading}
              expanded
            >
              {lookupI18nString("quote.header.modal.retrieveQuote.footer.sendLink")}
            </Button>
          </ButtonGroup>
        }
      />
    </>
  );
};

export default RetrieveQuoteModal;