import {StoredAtNight} from "shared/dist/generated/graphql/resolvers-types";

export const garageOption = {
  id: StoredAtNight.Garage,
  description: "Garage"
} as const;

export const STORED_AT_NIGHT = [
  garageOption,
  {
    id: StoredAtNight.OnTheDriveway,
    description: "On the driveway"
  },
  {
    id: StoredAtNight.OnTheStreet,
    description: "On the street (near home)"
  },
  {
    id: StoredAtNight.LockedCompound,
    description: "Locked compound"
  },
  {
    id: StoredAtNight.UnlockedCompound,
    description: "Unlocked compound"
  },
  {
    id: StoredAtNight.Carport,
    description: "Carport"
  },
  {
    id: StoredAtNight.Carpark,
    description: "Car park"
  }
] as const;

export type StoredAtNightOption = typeof STORED_AT_NIGHT[number];

export const STORED_AT_NIGHT_DESCRIPTIONS = STORED_AT_NIGHT.map(option => option.description);