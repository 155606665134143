import React, {FC} from "react";
import {mapValueToYesOrNoEventType} from "shared-components/dist/analytics/GoogleAnalyticsEventsService";
import {logYourCoverGoogleAnalyticsEvent} from "../../../../../../../../utils/analytics/MotorAnalytics";
import BooleanQuestion
  from "../../../../../../../../structure/questions/question/variants/boolean-question/BooleanQuestion";
import {isCurrentlyOrPreviouslyInsuredChanged} from "../../../../redux/CoverDetailsSlice";
import {useAppDispatch, useAppSelector} from "../../../../../../../../redux/Hooks";
import {
  selectShouldShowNamedOnOwnVehicleNamedDriverQuestions
} from "../../../../redux/selectors/named-driver/VehicleBreakInCoverSelectors";

const CurrentlyOrPreviouslyInsuredQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const isCurrentlyOrPreviouslyInsured = useAppSelector(state => state.coverDetails.isCurrentlyOrPreviouslyInsured);
  const shouldShowNamedOnOwnVehicleNamedDriverQuestions = useAppSelector(selectShouldShowNamedOnOwnVehicleNamedDriverQuestions);

  const onAnswer = (answer: boolean): void => {
    logYourCoverGoogleAnalyticsEvent({
      categorySuffix: "previously_insured_other",
      action: mapValueToYesOrNoEventType(answer),
    });

    dispatch(isCurrentlyOrPreviouslyInsuredChanged(answer));
  };

  return (
    <BooleanQuestion
      id="currently-or-previously-insured-question"
      title={
        shouldShowNamedOnOwnVehicleNamedDriverQuestions
          ? "coverDetails.currentlyOrPreviouslyInsuredQuestion.namedOnOwnVehicle.title"
          : "coverDetails.currentlyOrPreviouslyInsuredQuestion.title"
      }
      formField={isCurrentlyOrPreviouslyInsured}
      onAnswer={onAnswer}
      hideSpacer
      tooltip={{
        description: "coverDetails.currentlyOrPreviouslyInsuredQuestion.tooltip.description"
      }}
    />
  );
};

export default CurrentlyOrPreviouslyInsuredQuestion;