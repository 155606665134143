import {useAppDispatch, useAppSelector} from "../../../../../redux/Hooks";
import {useEffect} from "react";
import {selectIsVehiclePurchasedMoreThan14DaysAgo} from "../redux/selectors/named-driver/VehicleBreakInCoverSelectors";
import {breakInCoverQuestionsReset, hasVehicleBeenInsuredMadeActive} from "../redux/CoverDetailsSlice";
import {isNamedDriverAXAEligibleSelector} from "../redux/selectors/named-driver/IsNamedDriverAXAEligible";
import {OwnOrCompanyCar} from "../components/named-driver/own-or-company-car/OwnOrCompanyCarOption";

export function useValidateNamedDriverBreakInCoverFlow(): void {
  const dispatch = useAppDispatch();
  const isEligibleForNamedDriver = useAppSelector(isNamedDriverAXAEligibleSelector);
  const isVehiclePurchasedMoreThan14DaysAgo = useAppSelector(selectIsVehiclePurchasedMoreThan14DaysAgo);
  const previouslyHeldInsurance = useAppSelector(state => state.coverDetails.previouslyHeldInsurance.value);
  const ownOrCompanyCar = useAppSelector(state => state.coverDetails.ownOrCompanyCar.value?.id);

  useEffect(() => {
    if (isEligibleForNamedDriver && shouldShowBreakInCoverQuestions(isVehiclePurchasedMoreThan14DaysAgo, previouslyHeldInsurance, ownOrCompanyCar)) {
      dispatch(hasVehicleBeenInsuredMadeActive());
    } else {
      dispatch(breakInCoverQuestionsReset());
    }
  }, [dispatch, isVehiclePurchasedMoreThan14DaysAgo, isEligibleForNamedDriver, ownOrCompanyCar, previouslyHeldInsurance]);
}

const shouldShowBreakInCoverQuestions = (
  isVehiclePurchasedMoreThan14DaysAgo: boolean,
  previouslyHeldInsurance: boolean | undefined,
  ownOrCompanyCar: OwnOrCompanyCar | undefined
): boolean => {
  return isVehiclePurchasedMoreThan14DaysAgo && (previouslyHeldInsurance === false || ownOrCompanyCar === OwnOrCompanyCar.CompanyCar);
};