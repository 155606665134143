import React, {FC, useEffect} from "react";
import {isClassOfUseValid} from "../../validation/ValidateClassOfUse";
import ClassOfUseQuestion from "../../../shared/questions/class-of-use/ClassOfUseQuestion";
import {ClassOfUseOption} from "../../../shared/questions/class-of-use/ClassOfUseOption";
import FormattedMessage from "shared-components/src/translations/components/FormattedMessage";
import SolicitingSubQuestion from "./soliciting-sub-question/SolicitingSubQuestion";
import {logYourDetailsGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {actionValueFrom} from "../../../shared/questions/class-of-use/helpers/ClassOfUseGoogleAnalyticsAction";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";
import {classOfUseChanged, classOfUseInvalid, classOfUseReset} from "../../redux/PersonalDetailsSlice";
import {updateBusinessMileageQuestionVisibility} from "../../../shared/redux/ShouldShowBusinessMileageQuestion";
import {useIsFirstRender} from "../../../../../../utils/custom-hooks/UseIsFirstRender";

const ProposerClassOfUseQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const classOfUse = useAppSelector(state => state.personalDetails.classOfUse);
  const solicitingIsActive = useAppSelector(state => state.personalDetails.soliciting.isActive);
  const primaryEmploymentStatus = useAppSelector(state => state.personalDetails.primaryEmployment.employmentStatus.value);

  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    if (isFirstRender) return;

    if (!classOfUse.value) {
      dispatch(classOfUseReset());
      return;
    }

    validateAnswer(classOfUse.value, updateField);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryEmploymentStatus]);

  const onChange = (answer: ClassOfUseOption): void => {
    logYourDetailsGoogleAnalyticsEvent({
      categorySuffix: "car_use_case",
      action: actionValueFrom(answer),
    });

    validateAnswer(answer, onNewValidAnswer);
  };

  const validateAnswer = (answer: ClassOfUseOption, onValid?: (validAnswer: ClassOfUseOption) => void): void => {
    const classOfUseValidationResult = isClassOfUseValid(primaryEmploymentStatus)(answer);

    if (!classOfUseValidationResult.passed) {
      dispatch(classOfUseInvalid(classOfUseValidationResult.errorMessage));
    } else {
      onValid?.(answer);
    }
  };

  const updateField = (answer: ClassOfUseOption): void => {
    dispatch(classOfUseChanged(answer));
  };

  const onNewValidAnswer = (answer: ClassOfUseOption): void => {
    updateField(answer);
    dispatch(updateBusinessMileageQuestionVisibility(answer));
  };

  return (
    <>
      <ClassOfUseQuestion
        id="proposer-class-of-use"
        title="personalDetails.classOfUseQuestion"
        classOfUse={classOfUse}
        classOfUseChanged={onChange}
        tooltip={{
          title: "personalDetails.classOfUseQuestion.tooltip.title",
          description: (
            <FormattedMessage
              id="personalDetails.classOfUseQuestion.tooltip.description"
              values={{
                b: function formatBoldTags(chunks: string) {
                  return <b>{chunks}</b>;
                },
                ul: function formatUnorderedList(list: string) {
                  return <ul>{list}</ul>;
                },
                li: function formatListItemTags(msg: string) {
                  return <li>{msg}</li>;
                }
              }}
            />
          )
        }}
      />
      {solicitingIsActive && <SolicitingSubQuestion/>}
    </>
  );
};

export default ProposerClassOfUseQuestion;