import React, {Dispatch, FC, ReactNode, SetStateAction} from "react";
import RenewalDetailsConfirmationTableHeader from "../table-items/RenewalDetailsConfirmationTableHeader";
import RenewalDetailsConfirmationButtonFooter from "../table-items/RenewalDetailsConfirmationButtonFooter";
import RenewalDetailsConfirmationTableItem, {RenewalDetailsConfirmationTableItemProps} from "../table-items/RenewalDetailsConfirmationTableItem";
import "./RenewalsConfirmYourAdditionalDrivers.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {AdditionalDriver, EmploymentStatus} from "shared/dist/generated/graphql/resolvers-types";
import RenewalDetailsConfirmationTableSection from "../table-items/RenewalDetailsConfirmationTableSection";
import {convertEnumValueToFormattedString} from "shared-components/dist/utils/helpers/EnumHelper";
import {formatDateStringFromIsoString} from "shared/dist/stdlib/DateFormat";
import {formatClassOfUseToString, formatGender} from "../utils/Formatters";
import {
  convertDurationInMonthsToYears,
  isIndustryApplicableToEmploymentStatus,
  isJobTitleApplicableToEmploymentStatus,
  titleInfersGender
} from "../utils/Helpers";
import _ from "lodash";

interface Props {
  confirmed: boolean;
  setConfirmed: Dispatch<SetStateAction<boolean>>;
  setEditModalVisible: Dispatch<SetStateAction<boolean>>;
  additionalDrivers: AdditionalDriver[];
  previousSectionConfirmed: boolean;
}

const RenewalsConfirmYourAdditionalDrivers: FC<Props> = ({
  confirmed,
  setConfirmed,
  setEditModalVisible,
  additionalDrivers,
  previousSectionConfirmed
}) => {
  const hasAdditionalDrivers = additionalDrivers ? additionalDrivers.length > 0 : false;
  const items = hasAdditionalDrivers ? getStructuredAdditionalDrivers(additionalDrivers) : getEmptyState();

  return (
    <div
      className={confirmed ? "renewals-confirm-your-additional-drivers--confirmed" : `renewals-confirm-your-additional-drivers${previousSectionConfirmed ? "" : "--disabled"} unconfirmed`}
      data-testid="renewals-confirm-your-additional-drivers"
    >
      <RenewalDetailsConfirmationTableHeader
        title={lookupI18nString("portal.renewals.detailConfirmation.additionalDrivers")}
        confirmed={confirmed}
      />
      {items}
      <RenewalDetailsConfirmationButtonFooter
        testId={"your-additional-drivers"}
        confirmed={confirmed}
        setConfirmed={setConfirmed}
        setEditModalVisible={setEditModalVisible}
        disabled={!previousSectionConfirmed}
      />
    </div>
  );
};

function getStructuredAdditionalDrivers(additionalDrivers: AdditionalDriver[]): ReactNode {
  return additionalDrivers.map((additionalDriver, index) => {
    const additionalDriverItems = formatAdditionalDriverItems(additionalDriver);

    return (
      <RenewalDetailsConfirmationTableSection
        key={index}
        title={lookupI18nString({
          id: "portal.renewals.detailConfirmation.additionalDrivers.tableItem.title",
          templateVariables: {
            count: index + 1,
            max: additionalDrivers.length
          }
        })}
        subtitle={`${additionalDriver.firstName} ${additionalDriver.lastName}`}
      >
        {additionalDriverItems.map((item, key) =>
          <RenewalDetailsConfirmationTableItem
            key={key}
            testId={item.testId}
            title={item.title}
            subtitle={item.subtitle}
            listItems={item.listItems}
          />
        )}
      </RenewalDetailsConfirmationTableSection>
    );
  });
}

function formatAdditionalDriverItems(additionalDriver: AdditionalDriver): RenewalDetailsConfirmationTableItemProps[] {
  const additionalDriverItems: RenewalDetailsConfirmationTableItemProps[] = [
    {
      testId: "additional-driver-relationship-to-policyHolder",
      title: lookupI18nString("portal.renewals.detailConfirmation.additionalDrivers.relationshipToPolicyHolder"),
      subtitle: convertEnumValueToFormattedString(additionalDriver.relationshipToPolicyHolder)
    },
    {
      testId: "additional-driver-title",
      title: lookupI18nString("portal.renewals.detailConfirmation.additionalDrivers.title"),
      subtitle: additionalDriver.title
    },
    {
      testId: "additional-driver-first-name",
      title: lookupI18nString("portal.renewals.detailConfirmation.additionalDrivers.firstName"),
      subtitle: additionalDriver.firstName
    },
    {
      testId: "additional-driver-surname",
      title: lookupI18nString("portal.renewals.detailConfirmation.additionalDrivers.surname"),
      subtitle: additionalDriver.lastName
    },
    {
      testId: "additional-driver-dob",
      title: lookupI18nString("portal.renewals.detailConfirmation.additionalDrivers.dateOfBirth"),
      subtitle: formatDateStringFromIsoString(additionalDriver.dateOfBirth)
    },
  ];

  if (!titleInfersGender(additionalDriver.title)) {
    additionalDriverItems.push({
      testId: "additional-driver-gender",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.gender"),
      subtitle: formatGender(additionalDriver.gender),
    });
  }

  additionalDriverItems.push(
    {
      testId: "additional-driver-uk-residency-duration",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourDetails.ukResidency.lengthOfTime"),
      subtitle: convertDurationInMonthsToYears(additionalDriver.ukResidencyDurationMonths)
    },
    {
      testId: "additional-driver-relationship-status",
      title: lookupI18nString("portal.renewals.detailConfirmation.additionalDrivers.relationshipStatus"),
      subtitle: convertEnumValueToFormattedString(additionalDriver.maritalStatus)
    },
    {
      testId: "additional-driver-employment-status",
      title: lookupI18nString("portal.renewals.detailConfirmation.additionalDrivers.employmentStatus"),
      subtitle: _.startCase(additionalDriver.primaryEmployment.status)
    },
  );

  if (additionalDriver.primaryEmployment.industry && isIndustryApplicableToEmploymentStatus(additionalDriver.primaryEmployment.status)) {
    additionalDriverItems.push({
      testId: "additional-driver-industry",
      title: lookupI18nString("portal.renewals.detailConfirmation.additionalDrivers.industry"),
      subtitle: additionalDriver.primaryEmployment.industry
    });
  }

  if (additionalDriver.primaryEmployment.job && isJobTitleApplicableToEmploymentStatus(additionalDriver.primaryEmployment.status, additionalDriver.primaryEmployment.job)) {
    additionalDriverItems.push({
      testId: "additional-driver-job-title",
      title: getTitleBasedOnEmploymentStatus(additionalDriver.primaryEmployment.status),
      subtitle: additionalDriver.primaryEmployment.job
    });
  }

  additionalDriverItems.push(
    {
      testId: "additional-driver-second-job",
      title: lookupI18nString("portal.renewals.detailConfirmation.additionalDrivers.secondJob"),
      subtitle: additionalDriver.secondaryEmployment ? lookupI18nString("general.boolean.yes") : lookupI18nString("general.boolean.no")
    },
  );

  if (additionalDriver.secondaryEmployment?.job && additionalDriver.secondaryEmployment?.industry) {
    additionalDriverItems.push(
      {
        testId: "additional-driver-second-job-industry",
        title: lookupI18nString("portal.renewals.detailConfirmation.additionalDrivers.secondJobIndustry"),
        subtitle: additionalDriver.secondaryEmployment.industry
      },
      {
        testId: "additional-driver-second-job-title",
        title: lookupI18nString("portal.renewals.detailConfirmation.additionalDrivers.secondJobTitle"),
        subtitle: additionalDriver.secondaryEmployment.job
      }
    );
  }

  additionalDriverItems.push(
    {
      testId: "additional-driver-class-of-use",
      title: lookupI18nString("portal.renewals.detailConfirmation.additionalDrivers.carUsage"),
      subtitle: formatClassOfUseToString(additionalDriver.classOfUse ?? undefined)
    },
    {
      testId: "additional-driver-main-driver",
      title: lookupI18nString("portal.renewals.detailConfirmation.additionalDrivers.mainDriver"),
      subtitle: additionalDriver.isMainDriver ? lookupI18nString("general.boolean.yes") : lookupI18nString("general.boolean.no")
    },
    {
      testId: "additional-driver-licence",
      title: lookupI18nString("portal.renewals.detailConfirmation.additionalDrivers.licence"),
      subtitle: `${convertEnumValueToFormattedString(additionalDriver.licence.countryOfIssue)} - ${convertEnumValueToFormattedString(additionalDriver.licence.type)}`
    },
    {
      testId: "additional-driver-licence-duration",
      title: lookupI18nString("portal.renewals.detailConfirmation.additionalDrivers.licenceDuration"),
      subtitle: additionalDriver.licence.durationMonths ? convertDurationInMonthsToYears(additionalDriver.licence.durationMonths) : "-"
    },
    {
      testId: "additional-driver-medical-conditions",
      title: lookupI18nString("portal.renewals.detailConfirmation.additionalDrivers.medicalConditions"),
      subtitle: additionalDriver.medicalConditionsDeclared ? lookupI18nString("general.boolean.yes") : lookupI18nString("general.boolean.no")
    },
    {
      testId: "additional-driver-claims",
      title: lookupI18nString("portal.renewals.detailConfirmation.additionalDrivers.claims"),
      subtitle: additionalDriver.claims && additionalDriver.claims.length > 0 ? lookupI18nString("general.boolean.yes") : lookupI18nString("general.boolean.no")
    },
    {
      testId: "additional-driver-convictions",
      title: lookupI18nString("portal.renewals.detailConfirmation.additionalDrivers.convictions"),
      subtitle: additionalDriver.convictions && additionalDriver.convictions.length > 0 ? lookupI18nString("general.boolean.yes") : lookupI18nString("general.boolean.no"),
      listItems: [
        lookupI18nString("personalDetails.convictionsQuestions.hasConviction.description.motoringOrFixedPenalty"),
        lookupI18nString("personalDetails.convictionsQuestions.hasConviction.description.pendingProsecutions"),
        lookupI18nString("personalDetails.convictionsQuestions.hasConviction.description.drivingDisqualification"),
      ],
    },
    {
      // this is currently an inferred no - causes a hard stop in the quote flow, and we do not store the answer
      testId: "additional-driver-non-motoring-convictions",
      title: lookupI18nString("portal.renewals.detailConfirmation.additionalDrivers.nonMotorConvictions"),
      subtitle: lookupI18nString("general.boolean.no")
    },
    {
      testId: "additional-driver-has-cancellations",
      title: lookupI18nString("portal.renewals.detailConfirmation.additionalDrivers.insuranceDeclined"),
      subtitle: additionalDriver.hasCancellations ? lookupI18nString("general.boolean.yes") : lookupI18nString("general.boolean.no")
    }
  );

  return additionalDriverItems;
}

const getTitleBasedOnEmploymentStatus = (employmentStatus: EmploymentStatus): string => {
  switch (employmentStatus) {
    case EmploymentStatus.Retired:
      return lookupI18nString("portal.renewals.detailConfirmation.additionalDrivers.retired");
    case EmploymentStatus.Unemployed:
      return lookupI18nString("portal.renewals.detailConfirmation.additionalDrivers.unemployed");
    default:
      return lookupI18nString("portal.renewals.detailConfirmation.additionalDrivers.jobTitle");
  }
};

function getEmptyState(): ReactNode {
  return (
    <RenewalDetailsConfirmationTableItem
      testId={"additional-driver-empty-state"}
      title={lookupI18nString("portal.renewals.detailConfirmation.additionalDrivers.emptyState.title")}
      subtitle={lookupI18nString("portal.renewals.detailConfirmation.additionalDrivers.emptyState.subtitle")}
    />
  );
}

export default RenewalsConfirmYourAdditionalDrivers;
