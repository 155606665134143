import React, {FC} from "react";
import "./Dashboard.css";
import DashboardHeader from "./header/DashboardHeader";
import PolicyCards from "./policies-section/PolicyCards";
import ManageYourPolicies from "./manage-your-policies-section/ManageYourPolicies";
import CustomerPortalContainerWithoutSidebar from "../shared/container/CustomerPortalContainerWithoutSidebar";

const Dashboard: FC = () => (
  <CustomerPortalContainerWithoutSidebar
    header={<DashboardHeader/>}
    mainContent={
      <>
        <PolicyCards/>
        <ManageYourPolicies/>
      </>
    }
    className="dashboard"
  />
);

export default Dashboard;
