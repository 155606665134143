import {Gender} from "shared/dist/generated/graphql/resolvers-types";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";

export const MALE = {
  id: Gender.Male,
  description: lookupI18nString("structure.gender.male")
};

export const FEMALE = {
  id: Gender.Female,
  description: lookupI18nString("structure.gender.female")
};

export const GENDERS = [MALE, FEMALE] as const;
export type GenderOption = typeof GENDERS[number];
export const GENDER_OPTION_DESCRIPTIONS = GENDERS.map(gender => gender.description);
