import React, {FC} from "react";
import QuoteJourneyStop, {QuoteJourneyStopType} from "../quote-journey-stop/QuoteJourneyStop";

interface Props {
  isVisible: boolean;
  isSubContainer: boolean;
}

const NonEuImportTypeStop: FC<Props> = (
  {
    isVisible,
    isSubContainer
  }
) => (
  <QuoteJourneyStop
    stopProps={{
      visible: isVisible,
      title: "vehicleQuote.additionalVehicleDetailsForm.importType.nonEu.stop.title"
    }}
    isSubContainer={isSubContainer}
    stopType={QuoteJourneyStopType.HardStop}
  />
);

export default NonEuImportTypeStop;