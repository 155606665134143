import {createAppAsyncThunk} from "../../../../../redux/CreateAppAsyncThunk";
import {mapRenewalPolicyExtrasToQuoteExtras, RenewalPolicyExtras} from "../../models/RenewalPolicyExtras";
import {
  upsertQuoteExtrasMutation
} from "../../../../../graphql/mutations/quote/upsert-quote-extras/UpsertQuoteExtrasMutation";

export interface UpsertRebrokeExtrasArgs {
  quoteReference: string;
  quoteSequenceNumber: number;
  policyExtras: RenewalPolicyExtras;
  depositPercentage: number;
}

export const upsertRebrokeExtras = createAppAsyncThunk(
  "renewalSlice/upsertRebrokeExtras",
  async ({quoteReference, quoteSequenceNumber, policyExtras, depositPercentage}: UpsertRebrokeExtrasArgs) => {
    return await upsertQuoteExtrasMutation({
      depositPercentage: depositPercentage,
      extraOptionTypes: mapRenewalPolicyExtrasToQuoteExtras(policyExtras).map(extra => extra.optionType),
      quoteReference,
      quoteSequence: quoteSequenceNumber,
      shouldLoadLoans: true
    });
  }
);