import React, {FC} from "react";
import NavigationPrompter from "../../../../../router/components/NavigationPrompter";
import {showPromptOnBackButtonSelected} from "./helpers/ShowPromptOnBackButtonSelected";

interface OwnProps {
  promptVisible: boolean;
}

const EditAdditionalDriverNavigationPrompter: FC<OwnProps> = ({promptVisible}) => (
  <NavigationPrompter messageBuilder={showPromptOnBackButtonSelected(promptVisible)}/>
);

export default EditAdditionalDriverNavigationPrompter;
