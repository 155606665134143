import {buildUseReduxQuestionFromRecord, SectionQuestionsRecord} from "../Questions";
import {additionalVehicleDetails} from "./additional-vehicle-details/AdditionalVehicleDetails";
import {registrationNumberLookup} from "./questions/RegistrationNumberLookup";
import {assumptionsCorrect} from "./questions/AssumptionsCorrect";
import {hasTracker} from "./questions/HasTracker";
import {isCurrentOwner} from "./questions/IsCurrentOwner";
import {isOwnerAndRegisteredKeeper} from "./questions/IsOwnerAndRegisteredKeeper";
import {owner} from "./questions/Owner";
import {registeredKeeper} from "./questions/RegisteredKeeper";
import {dateOfPurchase} from "./questions/DateOfPurchase";
import {modifications} from "./questions/Modifications";
import {buildThunkToRunAllValidationForSection} from "../RunAllValidationForSection";
import {vehicleDetailsSliceName} from "../../../pages/quote/vehicle/your-vehicle/redux/VehicleDetailsSlice";

const VehicleDetailsValidatedQuestionsRecord = {
  registrationNumberLookup,
  assumptionsCorrect,
  ...additionalVehicleDetails,
  hasTracker,
  modifications,
  isCurrentOwner,
  dateOfPurchase,
  isOwnerAndRegisteredKeeper,
  owner,
  registeredKeeper
} as const satisfies SectionQuestionsRecord;

export const useVehicleDetailsQuestion = buildUseReduxQuestionFromRecord(VehicleDetailsValidatedQuestionsRecord);

export const validateAllVehicleDetailsQuestions = buildThunkToRunAllValidationForSection(
  VehicleDetailsValidatedQuestionsRecord,
  vehicleDetailsSliceName
);