import {gql, useQuery} from "@apollo/client";
import {ConvictionOffenceItem} from "shared/dist/generated/graphql/resolvers-types";
import {QueryResult} from "@apollo/client/react/types/types";

export interface GetConvictionOffencesResult {
  convictions: {
    offences: ConvictionOffenceItem[];
  };
}

export interface GetConvictionOffencesVariables {
  typeId: string
}

const GET_CONVICTION_OFFENCES = gql`
  query GetConvictionOffences($typeId: String!) {
    convictions {
      offences(typeId: $typeId) {
        id,
        oldReference,
        description
      }
    }
  }
`;

export const useConvictionOffencesQuery = (typeId: string): QueryResult<GetConvictionOffencesResult, GetConvictionOffencesVariables> => {
  return useQuery(GET_CONVICTION_OFFENCES, {
    variables: {typeId},
    fetchPolicy: "no-cache"
  });
};
