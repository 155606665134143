import {Status} from "shared-components/dist/status-indicator/Status";
import {Option} from "../../../../../../../structure/form/typeahead/models/Option";
import {CustomisedAbiListItem} from "shared-components/dist/models/CustomisedAbiListItem";
import {toFormStatus} from "../../../../../../../graphql/services/ToFormStatus";
import {ConditionalFormField} from "shared-components/dist/models/form-field/ConditionalFormField";
import {useIndustries} from "../../../../../../../graphql/queries/employment-lookup/industries-lookup/IndustriesLookup";

export interface IndustriesResult {
  status: Status | undefined;
  industries: Option<string>[];
  onIndustryChanged: (selection: Option<string>) => void;
}

export function useIndustriesProps(
  industry: ConditionalFormField<CustomisedAbiListItem | undefined>,
  industryChanged: (value: CustomisedAbiListItem) => void
): IndustriesResult {
  const {loading, error, data} = useIndustries();
  const industries: Option<string>[] = data?.map(industry => ({
    value: industry.vtCode,
    label: industry.description
  })) ?? [];
  const status = toFormStatus(industry, loading, error);

  const onIndustryChanged = (selection: Option<string>): void => {
    const type = industries.find(type => type.label === selection.label);

    if (type) {
      industryChanged({
        description: selection.label,
        vtCode: selection.value
      });
    }
  };

  return {status, industries, onIndustryChanged};
}