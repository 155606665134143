import {AppThunk} from "../../../../../../../redux/Store";
import {additionalDriverLoaded, initialState} from "../UpdateAdditionalDriverSlice";
import {AdditionalDriver} from "../../models/AdditionalDriver";
import {Status} from "shared-components/dist/status-indicator/Status";
import {toSerializableConviction} from "../../../../shared/questions/convictions/models/Conviction";
import {toSerializableClaim} from "../../../../shared/questions/claims/models/Claims";
import {UpdateAdditionalDriver} from "../UpdateAdditionalDriver";
import {
  validateAllAdditionalDriverQuestions
} from "../../../../../../../redux/questions/additional-drivers/AdditionalDriverQuestionsRecord";

export const loadAdditionalDriver = (additionalDriver: AdditionalDriver): AppThunk => async (dispatch) => {
  const mappedDriver = mapToUpdateAdditionalDriver(additionalDriver);
  await dispatch(additionalDriverLoaded(mappedDriver));
  await dispatch(validateAllAdditionalDriverQuestions());
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getStatus(property: any): Status {
  if (property !== undefined) return "success";

  return "default";
}

function mapToUpdateAdditionalDriver(additionalDriver: AdditionalDriver): UpdateAdditionalDriver {
  const updateAdditionalDriverForm = initialState;

  return {
    ...updateAdditionalDriverForm,
    relationshipToProposer: {
      ...updateAdditionalDriverForm.relationshipToProposer,
      value: additionalDriver.relationshipToProposer,
      status: "success"
    },
    title: {
      ...updateAdditionalDriverForm.title,
      value: additionalDriver.title,
      status: "success"
    },
    firstName: {
      ...updateAdditionalDriverForm.firstName,
      value: additionalDriver.firstName,
      status: "success"
    },
    surname: {
      ...updateAdditionalDriverForm.surname,
      value: additionalDriver.surname,
      status: "success"
    },
    dateOfBirth: {
      ...updateAdditionalDriverForm.dateOfBirth,
      value: additionalDriver.dateOfBirth.toISOString(),
      status: "success"
    },
    householdNumberOfCars: {
      ...updateAdditionalDriverForm.householdNumberOfCars,
      value: additionalDriver.householdNumberOfCars,
      status: getStatus(additionalDriver.householdNumberOfCars),
      isActive: additionalDriver.householdNumberOfCars !== undefined
    },
    hasUkResidencyFromBirth: {
      ...updateAdditionalDriverForm.hasUkResidencyFromBirth,
      value: additionalDriver.hasUkResidencyFromBirth,
      status: "success"
    },
    ukResidencyDuration: {
      ...updateAdditionalDriverForm.ukResidencyDuration,
      value: additionalDriver.ukResidencyDuration,
      status: getStatus(additionalDriver.ukResidencyDuration),
      isActive: additionalDriver.ukResidencyDuration !== undefined
    },
    gender: {
      ...updateAdditionalDriverForm.gender,
      value: additionalDriver.gender,
      status: "success"
    },
    maritalStatus: {
      ...updateAdditionalDriverForm.maritalStatus,
      value: additionalDriver.maritalStatus,
      status: "success"
    },
    primaryEmployment: {
      employmentStatus: {
        ...updateAdditionalDriverForm.primaryEmployment.employmentStatus,
        value: additionalDriver.primaryEmployment.employmentStatus,
        status: "success"
      },
      industry: {
        ...updateAdditionalDriverForm.primaryEmployment.industry,
        value: additionalDriver.primaryEmployment.industry,
        status: getStatus(additionalDriver.primaryEmployment.industry),
        isActive: additionalDriver.primaryEmployment.industry !== undefined
      },
      job: {
        ...updateAdditionalDriverForm.primaryEmployment.job,
        value: additionalDriver.primaryEmployment.job,
        status: getStatus(additionalDriver.primaryEmployment.job),
        isActive: additionalDriver.primaryEmployment.job !== undefined
      },
      workedInPastYear: {
        ...updateAdditionalDriverForm.primaryEmployment.workedInPastYear,
        value: additionalDriver.primaryEmployment.workedInPastYear,
        status: getStatus(additionalDriver.primaryEmployment.workedInPastYear),
        isActive: additionalDriver.primaryEmployment.workedInPastYear !== undefined
      },
    },
    hasSecondaryEmployment: {
      ...updateAdditionalDriverForm.hasSecondaryEmployment,
      value: additionalDriver.hasSecondaryEmployment,
      status: "success",
    },
    secondaryEmployment: {
      employmentStatus: {
        ...updateAdditionalDriverForm.secondaryEmployment.employmentStatus,
        value: additionalDriver.secondaryEmployment.employmentStatus,
        status: getStatus(additionalDriver.secondaryEmployment.employmentStatus),
        isActive: additionalDriver.secondaryEmployment.employmentStatus !== undefined
      },
      industry: {
        ...updateAdditionalDriverForm.secondaryEmployment.industry,
        value: additionalDriver.secondaryEmployment.industry,
        status: getStatus(additionalDriver.secondaryEmployment.industry),
        isActive: additionalDriver.secondaryEmployment.industry !== undefined
      },
      job: {
        ...updateAdditionalDriverForm.secondaryEmployment.job,
        value: additionalDriver.secondaryEmployment.job,
        status: getStatus(additionalDriver.secondaryEmployment.job),
        isActive: additionalDriver.secondaryEmployment.job !== undefined
      },
      workedInPastYear: {
        ...updateAdditionalDriverForm.secondaryEmployment.workedInPastYear,
        value: additionalDriver.secondaryEmployment.workedInPastYear,
        status: getStatus(additionalDriver.secondaryEmployment.workedInPastYear),
        isActive: additionalDriver.secondaryEmployment.workedInPastYear !== undefined
      },
    },
    classOfUse: {
      ...updateAdditionalDriverForm.classOfUse,
      value: additionalDriver.classOfUse,
      status: "success"
    },
    licenceCountryOfIssue: {
      ...updateAdditionalDriverForm.licenceCountryOfIssue,
      value: additionalDriver.licenceCountryOfIssue,
      status: "success"
    },
    licenceType: {
      ...updateAdditionalDriverForm.licenceType,
      value: additionalDriver.licenceType,
      status: "success"
    },
    licenceDuration: {
      ...updateAdditionalDriverForm.licenceDuration,
      value: additionalDriver.licenceDuration,
      status: "success"
    },
    hasMedicalCondition: {
      ...updateAdditionalDriverForm.hasMedicalCondition,
      value: additionalDriver.hasMedicalCondition,
      status: "success"
    },
    medicalCondition: {
      ...updateAdditionalDriverForm.medicalCondition,
      value: additionalDriver.medicalCondition,
      status: getStatus(additionalDriver.medicalCondition),
      isActive: additionalDriver.medicalCondition !== undefined
    },
    claims: {
      ...updateAdditionalDriverForm.claims,
      value: {
        ...updateAdditionalDriverForm.claims.value,
        claims: additionalDriver.claims.map(toSerializableClaim),
        hasClaims: additionalDriver.claims.length > 0
      },
      status: "success"
    },
    convictions: {
      ...updateAdditionalDriverForm.convictions,
      value: {
        ...updateAdditionalDriverForm.convictions.value,
        convictions: additionalDriver.convictions.map(toSerializableConviction),
        hasConvictions: additionalDriver.convictions.length > 0
      },
      status: "success"
    },
    hasNonMotoringConvictions: {
      ...updateAdditionalDriverForm.hasNonMotoringConvictions,
      value: additionalDriver.hasNonMotoringConvictions,
      status: "success"
    },
    hasInsuranceDeclined: {
      ...updateAdditionalDriverForm.hasInsuranceDeclined,
      value: additionalDriver.hasInsuranceDeclined,
      status: "success"
    },
    idOfDriverBeingEdited: additionalDriver.id
  };
}