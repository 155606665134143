import {FC} from "react";
import {ReactComponent as Icon} from "../../../../../../assets/icons/additional-drivers.svg";
import AggregatorQuoteValidationFailureTable from "../AggregatorQuoteValidationFailureTable";
import {
  useAdditionalDriverQuoteValidationFailureTableItems
} from "./UseAdditionalDriverQuoteValidationFailureTableItems";

const AdditionalDriversQuoteValidationFailureTable: FC = () => {
  const tableItems = useAdditionalDriverQuoteValidationFailureTableItems();

  if (tableItems.length === 0) return null;

  return (
    <AggregatorQuoteValidationFailureTable
      id="additional-drivers-quote-validation-table"
      title="aggregatorQuote.validationIssueTable.additionalDrivers.title"
      description="aggregatorQuote.validationIssueTable.additionalDrivers.description"
      icon={<Icon className={"icon"}/>}
      items={tableItems}
    />
  );
};

export default AdditionalDriversQuoteValidationFailureTable;