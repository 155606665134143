import React, {FC} from "react";
import {mapValueToYesOrNoEventType} from "shared-components/dist/analytics/GoogleAnalyticsEventsService";
import {logYourCoverGoogleAnalyticsEvent} from "../../../../../../../../utils/analytics/MotorAnalytics";
import BooleanQuestion
  from "../../../../../../../../structure/questions/question/variants/boolean-question/BooleanQuestion";
import {namedDriverOnOwnVehicleChanged} from "../../../../redux/CoverDetailsSlice";
import {useAppDispatch, useAppSelector} from "../../../../../../../../redux/Hooks";

const NamedDriverOnOwnVehicleQuestion: FC = () => {
  const namedDriverOnOwnVehicle = useAppSelector(state => state.coverDetails.namedDriverOnOwnVehicle);
  const dispatch = useAppDispatch();

  const onAnswer = (value: boolean): void => {
    logYourCoverGoogleAnalyticsEvent({
      categorySuffix: "named_driver_on_own_vehicle",
      action: mapValueToYesOrNoEventType(value),
    });

    dispatch(namedDriverOnOwnVehicleChanged(value));
  };

  return (
    <BooleanQuestion
      id="named-driver-on-own-vehicle-question"
      title="coverDetails.namedDriverOnOwnVehicleQuestion.title"
      formField={namedDriverOnOwnVehicle}
      onAnswer={onAnswer}
      hideSpacer
    />
  );
};

export default NamedDriverOnOwnVehicleQuestion;