import React, {FC} from "react";
import Button from "shared-components/dist/buttons/button/Button";
import "./MakeAChangeCard.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {openZendeskChat} from "../../../../../../zendesk/Zendesk";
import {logPortalGoogleAnalyticsEvent} from "../../../../../../utils/analytics/PortalAnalytics";
import PlainCard from "shared-components/dist/cards/plain-card/PlainCard";

const MakeAChangeCard: FC = () => {
  const onChatToUsButtonClick = (): void => {
    openZendeskChat();
    logPortalGoogleAnalyticsEvent({
      categorySuffix: "policy.summary.chat_to_us.button",
      action: "Click"
    });
  };

  return (
    <PlainCard className="make-a-change-card">
      <div className="make-a-change-card__header">
        <h5>{lookupI18nString("portal.needToMakeAChange.header")}</h5>
        <p className="make-a-change-card__header__description">{lookupI18nString("portal.needToMakeAChange.description")}</p>
      </div>

      <div className="make-a-change-card__body">
        <div>
        <p>{lookupI18nString("portal.needToMakeAChange.openingHours")}</p>
        <p className="phone-number"><a href="tel:08000113121">08000 11 31 21</a></p>
        </div>

        <Button
        variant="primary"
        className="chat-to-us"
        onClick={onChatToUsButtonClick}>
          {lookupI18nString("quoteDetails.tooYoungForFinanceStop.button.contactAgent")}
        </Button>
      </div>
    </PlainCard>
  );
};

export default MakeAChangeCard;
