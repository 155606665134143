import {gql} from "@apollo/client";
import {VehicleOptions} from "shared/dist/generated/graphql/resolvers-types";

export const YEARS_OF_MANUFACTURE_LOOKUP_QUERY = gql`
  query YearsOfManufactureLookup($filters: VehicleOptionsFilters!) {
    vehicle {
      vehicleOptions(optionsFilters: $filters) {
        yearsOfManufacture
      }
    }
  }
`;

export interface YearsOfManufactureLookupResult {
  vehicleOptions: Pick<VehicleOptions, "yearsOfManufacture">;
}
