import {buildCrossFieldValidator, ReduxQuestion} from "../../Questions";
import {
  dateOfPurchaseChanged,
  dateOfPurchaseInvalid,
  dateOfPurchaseReset
} from "../../../../pages/quote/vehicle/your-vehicle/redux/VehicleDetailsSlice";
import {
  isAfterManufactureDate,
  isValidPurchaseDate
} from "../../../../pages/quote/vehicle/your-vehicle/components/have-you-bought-car/validators/PurchaseDateValidators";
import {vehiclePurchaseDateAsDateSelector} from "../../../../pages/quote/vehicle/your-vehicle/redux/selectors/VehicleDateTypeSelectors";

export const dateOfPurchase: ReduxQuestion<Date> = {
  selector: vehiclePurchaseDateAsDateSelector,
  onChange: dateOfPurchaseChanged,
  onInvalid: dateOfPurchaseInvalid,
  onReset: dateOfPurchaseReset,
  validators: [isValidPurchaseDate()],
  crossFieldValidators: [
    buildCrossFieldValidator(
      state => state.vehicleDetails.vehicleFromRegLookup?.yearOfManufacture,
      [isAfterManufactureDate]
    )
  ]
};