import {gql, useQuery} from "@apollo/client";
import {ConvictionTypeItem} from "shared/dist/generated/graphql/resolvers-types";
import {QueryResult} from "@apollo/client/react/types/types";

export interface GetConvictionTypesResult {
  convictions: {
    types: ConvictionTypeItem[];
  };
}

const GET_CONVICTION_TYPES = gql`
  query GetConvictionTypes {
    convictions {
      types {
        id,
        parentCategory,
        parentCategoryDescription,
      }
    }
  }
`;

export const useConvictionTypesQuery = (): QueryResult<GetConvictionTypesResult> => useQuery(GET_CONVICTION_TYPES);
