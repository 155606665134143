import UIForm from "@globalpayments/js/types/ui/form";
import {IError, ISuccess} from "@globalpayments/js/types/internal/gateways";
import stylesheets from "./generated/GlobalPayStyles";
import {configuredGlobalPayForm, GlobalPayInput} from "./GlobalPayForm";
import {GlobalPayProvider} from "./GlobalPayProvider";
import {getGlobalPayEnv} from "./GetGlobalPayEnv";
import {globalPayApiVersion} from "shared/dist/constants/GlobalPay";

interface Props {
  accessToken: string;
  onCardValidationSuccess?: (response: ISuccess) => void;
  onError?: (response: IError) => void;
}

export function createGlobalPayCreditCardForm(
  {
    accessToken,
    onCardValidationSuccess,
    onError
  }: Props
): UIForm {
  GlobalPayProvider.configure({
    accessToken,
    apiVersion: globalPayApiVersion,
    env: getGlobalPayEnv()
  });

  const globalPayForm = configuredGlobalPayForm;
  const cardForm = GlobalPayProvider.ui.form({
    fields: {
      "card-holder-name": buildConfiguredInput(globalPayForm["card-holder-name"]),
      "card-number": buildConfiguredInput(globalPayForm["card-number"]),
      "card-expiration": buildConfiguredInput(globalPayForm["card-expiration"]),
      "card-cvv": buildConfiguredInput(globalPayForm["card-cvv"]),
      "submit": buildConfiguredInput(globalPayForm["submit"]),
    },
    styles: stylesheets["@global"]
  });

  if (onCardValidationSuccess) onTokenSuccess(cardForm, onCardValidationSuccess);
  if (onError) onTokenError(cardForm, onError);

  return cardForm;
}

function buildConfiguredInput(field: GlobalPayInput): GlobalPayInput {
  return {
    ...field,
    target: `#${field.target}`
  };
}

function onTokenSuccess(form: UIForm, callback: (response: ISuccess) => void): void {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  form.on("token-success", callback);
}

function onTokenError(form: UIForm, callback: (response: IError) => void): void {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  form.on("token-error", callback);
}

