import {DateOption, toSerializableDateOption} from "shared/dist/models/date-option/DateOption";
import {AppThunk, AppThunkDispatch} from "../../../../../../../../../redux/Store";
import {
  breakInCoverChanged,
  breakInCoverDeclarationAcceptanceChanged,
  coverStartDateChanged
} from "../../../../../../your-cover/redux/CoverDetailsSlice";
import {BreakInCover} from "../../../../../../shared/services/BreakInCoverDetector";
import {loadQuotes} from "../../../../../redux/thunks/LoadQuotes";
import {handleRefreshedQuotes} from "../../../../../../shared/redux/HandleRefreshedQuotes";

export interface ExpiredCoverStartDateEditedPayload {
  selectedCoverStartDate: DateOption | undefined;
  breakInCover: BreakInCover;
  breakInCoverDeclarationAcceptance: boolean | undefined;
}

export const expiredCoverStartDateEdited = (monthlyPaymentsEnabled: boolean, updatedExpiredCoverStartDate: ExpiredCoverStartDateEditedPayload): AppThunk => async (dispatch, getState) => {
  await editFields(updatedExpiredCoverStartDate, dispatch);
  await dispatch(loadQuotes({monthlyPaymentsEnabled}));
  return handleRefreshedQuotes(getState, dispatch);
};

async function editFields(updatedExpiredCoverStartDate: ExpiredCoverStartDateEditedPayload, dispatch: AppThunkDispatch): Promise<void> {
  await Promise.all([
    editCoverStartDate(updatedExpiredCoverStartDate, dispatch),
    editBreakInCover(updatedExpiredCoverStartDate, dispatch),
    editBreakInCoverDeclarationAcceptance(updatedExpiredCoverStartDate, dispatch),
  ]);
}

const editCoverStartDate = async ({selectedCoverStartDate}: ExpiredCoverStartDateEditedPayload, dispatch: AppThunkDispatch): Promise<void> => {
  if (selectedCoverStartDate) await dispatch(coverStartDateChanged({
    value: toSerializableDateOption(selectedCoverStartDate),
    isEligibleForNamedDriver: true
  }));
};

const editBreakInCover = async ({breakInCover}: ExpiredCoverStartDateEditedPayload, dispatch: AppThunkDispatch): Promise<void> => {
  await dispatch(breakInCoverChanged(breakInCover));
};

const editBreakInCoverDeclarationAcceptance = async ({breakInCoverDeclarationAcceptance}: ExpiredCoverStartDateEditedPayload, dispatch: AppThunkDispatch): Promise<void> => {
  if (breakInCoverDeclarationAcceptance) dispatch(breakInCoverDeclarationAcceptanceChanged(breakInCoverDeclarationAcceptance));
};
