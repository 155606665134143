import InlineQuestion from "../../../../../structure/questions/inline-question/InlineQuestion";
import React, {FC} from "react";

export const cardHolderNameId = "global-pay-name-input";

const CardHolderName: FC = () => (
  <InlineQuestion
    id={cardHolderNameId}
    label="paymentFlow.byCard.form.cardDetails.cardholderName"
  >
    <div id={cardHolderNameId} className="card-details__name"/>
  </InlineQuestion>
);

export default CardHolderName;