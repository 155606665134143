import React, {FC, useEffect} from "react";
import {Location} from "history";
import {browserVersion, isMobileSafari} from "react-device-detect";
import Prompt from "./Prompt";
import {useLocation} from "react-router-dom";

interface OwnProps {
  messageBuilder: (location: Location<unknown>) => string | undefined;
}

const NavigationPrompter: FC<OwnProps> = ({messageBuilder}) => {
  const location = useLocation();

  useEffect(() => {
    window.addEventListener("beforeunload", returnStringBeforeUnload);

    return () => window.removeEventListener("beforeunload", returnStringBeforeUnload);
  });

  function returnStringBeforeUnload(event: BeforeUnloadEvent): void {
    event.preventDefault();
    event.returnValue = "string";
  }

  if (isUnsupportedMobileSafariVersion()) return null;

  return (
    <Prompt
      message={messageBuilder(location) ?? ""}
      when={!!messageBuilder(location)}
    />
  );
};

function isUnsupportedMobileSafariVersion(): boolean {
  if (!isMobileSafari) return false;

  return (+browserVersion < 15);
}

export default NavigationPrompter;
