import {AppThunk, AppThunkDispatch} from "../../../../../../redux/Store";
import {StoredPolicyExtra} from "../../models/StoredPolicyExtra";
import {Price, UpsertQuoteExtrasResponse} from "shared/dist/generated/graphql/resolvers-types";
import {
  calculateNewSelectedLoanWithPrice,
  insurerQuoteChanged,
  loadingCloseBrothersLoanFailed,
  loadingCloseBrothersLoanSucceeded,
  prospectiveLoansWithPriceChanged,
  quoteErrorChanged,
  startedLoadingCloseBrothersLoan,
  totalExtrasPriceChanged,
  totalUnfinancedAmountPayableChanged
} from "../QuoteDetailsSlice";
import {removeTypename} from "../../../../../../utils/graphql/ApolloClient";
import {logger} from "../../../../../../utils/logging/Logger";
import {
  upsertQuoteExtrasMutation
} from "../../../../../../graphql/mutations/quote/upsert-quote-extras/UpsertQuoteExtrasMutation";
import {InsurerQuote} from "../../models/InsurerQuote";
import {loadProspectiveLoans} from "./LoadProspectiveLoans";
import {partition} from "lodash";

export const upsertQuoteExtras = (selectedPolicyExtras: StoredPolicyExtra[], shouldLoadLoans = true): AppThunk => async (dispatch, getState) => {
  const state = getState();
  const quoteDetails = state.quoteDetails;
  const quoteReference = quoteDetails.quoteReference;
  const quoteSequence = quoteDetails.quoteSequenceNumber;
  const magicLinkId = quoteDetails.magicLinkId;

  if (!quoteReference || !quoteSequence || !magicLinkId) {
    dispatch(quoteErrorChanged("Selected quote not found. Cannot update policy extras."));
    logger.error("Selected quote not found. Cannot update policy extras.");
    return;
  }

  try {
    dispatch(startedLoadingCloseBrothersLoan());

    const result = await upsertQuoteExtrasMutation({
      depositPercentage: quoteDetails.depositPercentage,
      extraOptionTypes: selectedPolicyExtras.map(extra => extra.optionType),
      magicLinkId,
      quoteReference,
      quoteSequence,
      shouldLoadLoans
    });

    const insurerQuotes = quoteDetails.insurerQuotes;
    if (!insurerQuotes) return;

    const [quoteToUpdate, allOtherQuotes] = partition(insurerQuotes, quote => (
      quote.reference === quoteReference && quote.sequenceNumber === quoteSequence
    ));
    if (!quoteToUpdate) return;
    updateSelectedQuote(dispatch, quoteToUpdate[0], quoteReference, quoteSequence, result);

    dispatch(loadProspectiveLoans({quotes: allOtherQuotes}));
  } catch (error) {
    dispatch(quoteErrorChanged("Could not update extras."));
    logger.error(`Failed to get loans and update extras. MagicLinkId: [${magicLinkId}], quoteReference: [${quoteReference}], 
    quoteSequenceNumber [${quoteSequence}]. Error [${error.name}] thrown with message [${error.message}]`, error);
    dispatch(loadingCloseBrothersLoanFailed());
  }
};

function updateSelectedQuote(
  dispatch: AppThunkDispatch,
  quote: InsurerQuote,
  quoteReference: string,
  quoteSequence: number,
  upsertExtrasResult: UpsertQuoteExtrasResponse
): void {
  const prospectiveLoans = upsertExtrasResult.prospectiveLoans ?? [];
  const extrasTotalCost = upsertExtrasResult.totalExtrasCost ? removeTypename(upsertExtrasResult?.totalExtrasCost) as Price : undefined;
  const selectedLoan = calculateNewSelectedLoanWithPrice(quote.selectedLoanWithPrice, prospectiveLoans);

  dispatch(totalExtrasPriceChanged(extrasTotalCost));
  dispatch(insurerQuoteChanged(
    {
      ...quote,
      loansWithPrice: prospectiveLoans,
      selectedLoanWithPrice: selectedLoan,
      totalUnfinancedAmountPayable: upsertExtrasResult.totalUnfinancedAmountPayable,
    }));
  dispatch(prospectiveLoansWithPriceChanged(prospectiveLoans));
  dispatch(totalUnfinancedAmountPayableChanged(removeTypename(upsertExtrasResult?.totalUnfinancedAmountPayable)));
  dispatch(loadProspectiveLoans({quotes: [quote]}));
  dispatch(loadingCloseBrothersLoanSucceeded());
}