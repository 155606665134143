import {
  validationFailure,
  ValidationResult,
  validationSuccess,
  Validator
} from "shared-components/dist/utils/validation/Validator";
import {DateOption} from "shared/dist/models/date-option/DateOption";
import {isAboveMinimumAgeOn} from "../../../../shared/questions/date-of-birth/validators/IsAboveMinimumAge";
import {isBelowMaximumAgeOn} from "../../../../shared/questions/date-of-birth/validators/IsBelowMaximumAge";

export function isBelowMaximumAgeOnCoverStartDate(dateOfBirth: Date | undefined): Validator<DateOption> {
  return ({date}: DateOption): ValidationResult => {
    if (!dateOfBirth) return validationSuccess;

    return isBelowMaximumAgeOn(date, dateOfBirth)
      ? validationSuccess
      : validationFailure("personalDetails.dateOfBirthQuestion.errors.isTooOld.onStartDate");
  };
}

export function isAboveMinimumAgeOnCoverStartDate(dateOfBirth: Date | undefined): Validator<DateOption> {
  return ({date}: DateOption): ValidationResult => {
    if (!dateOfBirth) return validationSuccess;

    return isAboveMinimumAgeOn(date, dateOfBirth)
      ? validationSuccess
      : validationFailure("personalDetails.dateOfBirthQuestion.errors.isTooYoung.onStartDate");
  };
}