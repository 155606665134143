import React, {Dispatch, FC, ReactNode, SetStateAction} from "react";
import RenewalDetailsConfirmationTableHeader from "../table-items/RenewalDetailsConfirmationTableHeader";
import RenewalDetailsConfirmationButtonFooter from "../table-items/RenewalDetailsConfirmationButtonFooter";
import RenewalDetailsConfirmationRightDetailTableItem
  from "../table-items/RenewalDetailsConfirmationRightDetailTableItem";
import "./RenewalsConfirmYourCarModifications.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {ModificationType} from "shared/dist/generated/graphql/resolvers-types";
import _ from "lodash";

interface Props {
  confirmed: boolean;
  setConfirmed: Dispatch<SetStateAction<boolean>>;
  setEditModalVisible: Dispatch<SetStateAction<boolean>>;
  modifications: ModificationType[],
  previousSectionConfirmed: boolean
}

const RenewalsConfirmYourCarModifications: FC<Props> = ({confirmed, setConfirmed, setEditModalVisible, modifications, previousSectionConfirmed}) => {
  const items = getStructuredModifications(modifications);

  return (
    <div
      className={confirmed ? "renewals-confirm-your-car-modifications--confirmed" : `renewals-confirm-your-car-modifications${previousSectionConfirmed ? "" : "--disabled"} unconfirmed`}
      data-testid="renewals-confirm-your-car-modifications"
    >
      <RenewalDetailsConfirmationTableHeader
        title={lookupI18nString("portal.renewals.detailConfirmation.yourCarModifications")}
        confirmed={confirmed}
      />
      {items}
      <RenewalDetailsConfirmationButtonFooter
        testId={"your-car-modifications"}
        confirmed={confirmed}
        setConfirmed={setConfirmed}
        setEditModalVisible={setEditModalVisible}
        disabled={!previousSectionConfirmed}
      />
    </div>
  );
};

function getStructuredModifications(modifications: ModificationType[]): ReactNode {
  return modifications.map((modification, index) =>
    <RenewalDetailsConfirmationRightDetailTableItem
      key={index}
      title={lookupI18nString({
        id: "portal.renewals.detailConfirmation.yourCarModifications.tableItem.title",
        templateVariables: {
          count: index + 1,
          max: modifications.length
        }
      })}
      subtitle={_.startCase(modification)}/>
  );
}

export default RenewalsConfirmYourCarModifications;
