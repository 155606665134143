import "./AggregatorQuoteValidationFailureTable.css";
import {FC, ReactNode} from "react";
import AggregatorQuoteValidationFailureTableItem, {AggregatorQuoteValidationFailureItemProps} from "./AggregatorQuoteValidationFailureTableItem";
import {TranslationId} from "shared-components/dist/translations/TranslationKey";
import FormattedMessage from "shared-components/dist/translations/components/FormattedMessage";

interface Props {
  id: string;
  icon: ReactNode;
  title: TranslationId;
  description: TranslationId;
  items: AggregatorQuoteValidationFailureItemProps[];
}

const AggregatorQuoteValidationFailureTable: FC<Props> = ({id, icon, title, description, items}) =>
  (
    <div className="aggregator-quote-validation-failure-table" data-testid={id}>
      <div className="aggregator-quote-validation-failure-table__header">
        {icon}
        <div className="aggregator-quote-validation-failure-table__header__title">
          <h2><FormattedMessage id={title}/></h2>
          <h3><FormattedMessage id={description}/></h3>
        </div>
      </div>
      <div className="aggregator-quote-validation-failure-table__body">
        {items.map((item, index) => <AggregatorQuoteValidationFailureTableItem key={index} {...item}/>)}
      </div>
    </div>
  );

export default AggregatorQuoteValidationFailureTable;