import React, {FC} from "react";
import ModificationQuestion from "./components/ModificationsQuestion";
import {hasVehicleSummarySelector} from "../../redux/selectors/VehicleSummarySelectors";
import {useAppSelector} from "../../../../../../redux/Hooks";

const Modifications: FC = () => {
  const isVisible = useAppSelector(hasVehicleSummarySelector);

  if (!isVisible) return null;

  return (
    <ModificationQuestion/>
  );
};

export default Modifications;
