import React, {FC} from "react";
import StepTitle from "../../../../../structure/questions/step-title/StepTitle";
import RelationshipToProposerQuestion from "./components/relationship-to-proposer/RelationshipToProposerQuestion";
import AdditionalDriverTitleQuestion from "./components/title-question/AdditionalDriverTitleQuestion";
import AdditionalDriverFirstNameQuestion from "./components/first-name-question/AdditionalDriverFirstNameQuestion";
import AdditionalDriverSurnameQuestion from "./components/surname-question/AdditionalDriverSurnameQuestion";
import AdditionalDriverGenderQuestion from "./components/gender-question/AdditionalDriverGenderQuestion";
import AdditionalDriverDateOfBirthQuestion from "./components/date-of-birth/AdditionalDriverDateOfBirthQuestion";
import AdditionalDriverResidencyQuestion from "./components/residency/AdditionalDriverResidencyQuestion";
import AdditionalDriverMaritalStatusQuestion from "./components/marital-status/AdditionalDriverMaritalStatusQuestion";
import AdditionalDriverLicenceCountryOfIssueQuestion
  from "./components/licence-country-of-issue/AdditionalDriverLicenceCountryOfIssueQuestion";
import AdditionalDriverLicenceTypeQuestion from "./components/licence-type/AdditionalDriverLicenceTypeQuestion";
import AdditionalDriverLicenceDurationQuestion
  from "./components/licence-duration/AdditionalDriverLicenceDurationQuestion";
import AdditionalDriverMedicalConditionQuestion
  from "./components/medical-condition/AdditionalDriverMedicalConditionQuestion";
import AdditionalDriverConvictionsQuestion from "./components/convictions/AdditionalDriverConvictionsQuestion";
import AdditionalDriverPrimaryEmploymentQuestion
  from "./components/employment/AdditionalDriverPrimaryEmploymentQuestion";
import AdditionalDriverClassOfUseQuestion from "./components/class-of-use/AdditionalDriverClassOfUseQuestion";
import AdditionalDriverInsuranceDeclinedQuestion
  from "./components/insurance-declined-question/AdditionalDriverInsuranceDeclinedQuestion";
import AdditionalDriverNonMotoringConvictionsQuestion
  from "./components/non-motoring-convictions/AdditionalDriverNonMotoringConvictionsQuestion";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import AdditionalDriverSecondaryEmploymentQuestion
  from "./components/employment/AdditionalDriverSecondaryEmploymentQuestion";
import AdditionalDriverClaimsQuestion from "./components/claims/AdditionalDriverClaimsQuestion";

interface OwnProps {
  title: TranslationKey;
  titleValue?: string;
}

const Questions: FC<OwnProps> = (
  {
    title,
    titleValue,
  }
) => (
  <>
    <StepTitle title={title} titleValue={titleValue}/>

    <RelationshipToProposerQuestion/>
    <AdditionalDriverTitleQuestion/>
    <AdditionalDriverFirstNameQuestion/>
    <AdditionalDriverSurnameQuestion/>
    <AdditionalDriverDateOfBirthQuestion/>
    <AdditionalDriverGenderQuestion/>
    <AdditionalDriverResidencyQuestion/>
    <AdditionalDriverMaritalStatusQuestion/>
    <AdditionalDriverPrimaryEmploymentQuestion/>
    <AdditionalDriverSecondaryEmploymentQuestion/>
    <AdditionalDriverClassOfUseQuestion/>
    <AdditionalDriverLicenceTypeQuestion/>
    <AdditionalDriverLicenceCountryOfIssueQuestion/>
    <AdditionalDriverLicenceDurationQuestion/>
    <AdditionalDriverMedicalConditionQuestion/>
    <AdditionalDriverClaimsQuestion/>
    <AdditionalDriverConvictionsQuestion/>
    <AdditionalDriverNonMotoringConvictionsQuestion/>
    <AdditionalDriverInsuranceDeclinedQuestion/>
  </>
);

export default Questions;