import React, {Dispatch, FC, ReactNode, SetStateAction} from "react";
import RenewalDetailsConfirmationTableHeader from "../table-items/RenewalDetailsConfirmationTableHeader";
import RenewalDetailsConfirmationButtonFooter from "../table-items/RenewalDetailsConfirmationButtonFooter";
import "./RenewalsConfirmYourConvictions.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {PolicyConviction} from "shared/dist/generated/graphql/resolvers-types";
import RenewalDetailsConfirmationTableItem from "../table-items/RenewalDetailsConfirmationTableItem";
import {formatConvictions} from "../utils/Formatters";

interface Props {
  confirmed: boolean;
  setConfirmed: Dispatch<SetStateAction<boolean>>;
  setEditModalVisible: Dispatch<SetStateAction<boolean>>;
  convictions: PolicyConviction[];
  previousSectionConfirmed: boolean;
}

const RenewalsConfirmYourConvictions: FC<Props> = ({confirmed, setConfirmed, setEditModalVisible, convictions, previousSectionConfirmed}) => {
  const hasConvictions = convictions ? convictions.length > 0 : false;
  const items = hasConvictions ? formatConvictions(convictions) : getEmptyState();

  return (
    <div
      className={confirmed ? "renewals-confirm-your-convictions--confirmed" : `renewals-confirm-your-convictions${previousSectionConfirmed ? "" : "--disabled"} unconfirmed`}
      data-testid="renewals-confirm-your-convictions"
    >
      <RenewalDetailsConfirmationTableHeader title={lookupI18nString("portal.renewals.detailConfirmation.yourConvictions")} confirmed={confirmed}/>
      {items}
      <RenewalDetailsConfirmationButtonFooter
        testId={"your-convictions"}
        confirmed={confirmed}
        setConfirmed={setConfirmed}
        setEditModalVisible={setEditModalVisible}
        disabled={!previousSectionConfirmed}
      />
    </div>
  );
};

function getEmptyState(): ReactNode {
  return (
    <RenewalDetailsConfirmationTableItem
      testId={"your-convictions-empty-state"}
      title={lookupI18nString("portal.renewals.detailConfirmation.yourConvictions.emptyState.title")}
      subtitle={lookupI18nString("portal.renewals.detailConfirmation.yourConvictions.emptyState.subtitle")}
    />
  );
}

export default RenewalsConfirmYourConvictions;