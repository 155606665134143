import {createAppAsyncThunk} from "../../../../../redux/CreateAppAsyncThunk";
import {loadRenewalNoticeQuery} from "../../../../../graphql/queries/portal/LoadRenewalNoticeQuery";
import {
  PolicyExtraOptionType,
  PolicyExtraType,
  Price,
  ProspectivePolicyExtra,
  RenewalInviteStatus,
  RenewalNotice,
} from "shared/dist/generated/graphql/resolvers-types";
import {
  ExcessInsuranceOptionTypes,
  mapRenewalPolicyExtrasToQuoteExtras,
  RenewalPolicyExtras
} from "../../models/RenewalPolicyExtras";
import {loadPortalPolicyQueryForRenewals} from "../../../../../graphql/queries/portal/LoadPortalPolicyQuery";
import {loadAvailablePolicyExtrasQuery} from "../../../../../graphql/queries/portal/LoadAvailablePolicyExtrasQuery";
import {loadRenewalConfirmationDetails} from "../../../../../graphql/queries/portal/LoadRenewalConfirmationDetails";
import {getRenewalQuoteValues} from "./GetRenewalQuoteValues";
import {getRebrokedQuote, RebrokedQuoteValues} from "./LoadRebrokedQuote";

const DEFAULT_EXISTING_LOAN_DEPOSIT_PERCENTAGE = 0;
const DEFAULT_NEW_LOAN_DEPOSIT_PERCENTAGE = 20;

export interface LoadRenewalSummaryParams {
  policyId: string;
}

export const loadRenewalSummary = createAppAsyncThunk(
  "renewalSlice/loadRenewalSummary",
  async (args: LoadRenewalSummaryParams) => {
    const [renewalNotice, policy, renewalConfirmationDetails] = await Promise.all([
      loadRenewalNoticeQuery({policyId: args.policyId}),
      loadPortalPolicyQueryForRenewals(args.policyId),
      loadRenewalConfirmationDetails()
    ]);

    if (!renewalNotice) throw new Error("No renewal notices returned.");
    if (!renewalConfirmationDetails) throw new Error("No policy holder information found");

    const availablePolicyExtras = await loadAvailablePolicyExtrasQuery(renewalNotice.renewalTimestamp);

    const shouldShowRenewal = (): boolean => ([
      RenewalInviteStatus.RenewalOfferedWithException,
      RenewalInviteStatus.RenewalOffered
    ].some(hasRenewalInviteStatus));

    const shouldShowRebroke = (): boolean => ([
      RenewalInviteStatus.RebrokeOnly,
      RenewalInviteStatus.RenewalOffered
    ].some(hasRenewalInviteStatus));

    const hasRenewalInviteStatus = (status: RenewalInviteStatus): boolean => policy.metadata.renewalInviteStatus === status;

    const policyHolder = renewalConfirmationDetails.policyHolder;
    const enabledPolicyExtras = buildEnabledPolicyExtras(renewalNotice, availablePolicyExtras);
    const policyExtras = mapRenewalPolicyExtrasToQuoteExtras(enabledPolicyExtras);

    const previouslyHadCloseBrothersLoan = !!policy.paymentDetails?.loanReference;
    const depositPercentage = previouslyHadCloseBrothersLoan ? DEFAULT_EXISTING_LOAN_DEPOSIT_PERCENTAGE : DEFAULT_NEW_LOAN_DEPOSIT_PERCENTAGE;

    let renewalValues = {} as Partial<Awaited<ReturnType<typeof getRenewalQuoteValues>>>;
    if (shouldShowRenewal()) {
      renewalValues = await getRenewalQuoteValues(
        args.policyId,
        depositPercentage,
        policyExtras,
        renewalNotice,
        policy?.schemeReference
      );
    }

    if (policy.metadata.renewalInviteStatus === RenewalInviteStatus.RenewalOfferedWithException && !renewalValues.renewalQuote) {
      throw new Error("No renewal quote for RENEWAL_WITH_EXCEPTION notice.");
    }

    let rebrokedValues = {} as RebrokedQuoteValues;
    if (shouldShowRebroke()) {
      rebrokedValues = await getRebrokedQuote({
        policy,
        policyExtras,
        depositPercentage,
        shouldProtectNoClaimsBonus: policy.coverDetails.noClaimsProtection,
        renewalNotice
      });
    }

    if (policy.metadata.renewalInviteStatus === RenewalInviteStatus.RebrokeOnly && !rebrokedValues.rebrokedQuote) {
      throw new Error("No rebroke quote for REBROKE_ONLY notice.");
    }

    if (policy.metadata.renewalInviteStatus === RenewalInviteStatus.RenewalOffered && !renewalValues.renewalQuote && !rebrokedValues.rebrokedQuote) {
      throw new Error("Neither renewal nor rebroked quote for RENEWAL_OFFERED notice.");
    }

    return {
      policy,
      policyHolder,
      renewalNotice,
      policyExtras: enabledPolicyExtras,
      depositPercentage,
      availablePolicyExtras,
      ...rebrokedValues,
      ...renewalValues
    };
  }
);

const buildEnabledPolicyExtras = (renewalNotice: RenewalNotice, availablePolicyExtras: ProspectivePolicyExtra[]): RenewalPolicyExtras => ({
  courtesyCar: {
    enabled: previouslyHadPolicyExtra(renewalNotice.replacementCar),
    type: PolicyExtraOptionType.CourtesyCar
  },
  smartfobKeycare: {
    enabled: previouslyHadPolicyExtra(renewalNotice.keyCare),
    type: PolicyExtraOptionType.SmartfobKeycare
  },
  roadsideAssistancePlatinum: {
    enabled: previouslyHadPolicyExtra(renewalNotice.breakdownCover),
    type: PolicyExtraOptionType.RoadsideAssistancePlatinum
  },
  legalExpenses: {
    enabled: previouslyHadPolicyExtra(renewalNotice.legalExpenses),
    type: PolicyExtraOptionType.LegalExpenses
  },
  excessInsurance: {
    enabled: previouslyHadPolicyExtra(renewalNotice.excessInsurance),
    type: selectExcessProtectionOptionType(renewalNotice, availablePolicyExtras)
  }
});

const previouslyHadPolicyExtra = (price: Price): boolean => parseFloat(price.amount) > 0;

const selectExcessProtectionOptionType = (
  {renewalExcess}: RenewalNotice,
  availablePolicyExtras: ProspectivePolicyExtra[]
): ExcessInsuranceOptionTypes => {
  const excessInsurance = availablePolicyExtras.find(extra => extra.type === PolicyExtraType.ExcessInsurance);
  if (!excessInsurance) return PolicyExtraOptionType.ExcessInsurance1;

  return getMinimumExcessInsuranceForTotalInsurance(renewalExcess.amount, excessInsurance);
};

function getMinimumExcessInsuranceForTotalInsurance(renewalExcessAmount: string, excessInsurance: ProspectivePolicyExtra): ExcessInsuranceOptionTypes {
  const selectedOption = excessInsurance.options.find(({excessProtectedAmount}) =>
    excessProtectedAmount?.amount && parseFloat(excessProtectedAmount.amount) > parseFloat(renewalExcessAmount)
  );

  return selectedOption?.optionType as ExcessInsuranceOptionTypes ?? PolicyExtraOptionType.ExcessInsurance1;
}
