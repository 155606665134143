import {buildPrimaryEmploymentFields, buildSecondaryEmploymentFields} from "./BuildEmployment";
import {buildClaim} from "./BuildClaim";
import {buildConviction} from "./BuildConviction";
import {AggregatorQuoteResult, ProposedClassOfUse} from "shared/src/generated/graphql/resolvers-types";
import {PersonalDetails} from "../../../../quote/vehicle/your-details/redux/PersonalDetails";
import {buildAdditionalDriver} from "./BuildAdditionalDriver";
import {AggregatorAdditionalDriver} from "../../../../quote/vehicle/additional-driver/shared/models/AdditionalDriver";
import {buildConditionalFormField, buildFormField, buildRequiredFormField} from "../BuildFormField";
import {
  buildClassOfUse,
  buildCountryOfIssue,
  buildGender,
  buildLicenceType,
  buildMartialStatus,
  buildMedicalCondition,
  buildStoredAtNight,
  livedInUKSinceBirth
} from "./PersonFieldsHelpers";
import {calculateDuration} from "../ReduxHelpers";
import {initialState} from "../../../../quote/vehicle/your-details/redux/PersonalDetailsSlice";

export function buildPersonalDetails(quote: AggregatorQuoteResult): PersonalDetails {
  const additionalDrivers = quote.additionalDrivers?.map(buildAdditionalDriver) ?? [];
  const ukSinceBirth = livedInUKSinceBirth(
    quote.personalDetails.dateOfBirth,
    quote.personalDetails.ukResidencyDurationMonths
  );

  return {
    title: buildFormField(quote.personalDetails.title, {status: "success"}),
    firstName: buildFormField(quote.personalDetails.firstName, {status: "success"}),
    surname: buildFormField(quote.personalDetails.lastName, {status: "success"}),
    dateOfBirth: buildFormField(quote.personalDetails.dateOfBirth, {status: "success"}),
    hasUkResidencyFromBirth: buildFormField(ukSinceBirth, {status: "success"}),
    ukResidencyDuration: buildConditionalFormField(
      ukSinceBirth
        ? undefined
        : calculateDuration(quote.personalDetails.ukResidencyDurationMonths)
    ),
    gender: buildConditionalFormField(buildGender(quote.personalDetails.gender), {status: "success", isActive: true}),
    classOfUse: buildFormField(buildClassOfUse(quote.personalDetails.proposedClassOfUse)),
    maritalStatus: buildFormField(buildMartialStatus(quote.personalDetails.maritalStatus)),
    primaryEmployment: buildPrimaryEmploymentFields(quote.personalDetails.primaryEmployment, quote.personalDetails.gender),
    hasSecondaryEmployment: buildFormField(!!quote.personalDetails.secondaryEmployment),
    secondaryEmployment: quote.personalDetails?.secondaryEmployment ?
      buildSecondaryEmploymentFields(quote.personalDetails.secondaryEmployment, quote.personalDetails.gender)
      : initialState.secondaryEmployment,
    licenceCountryOfIssue: buildFormField(buildCountryOfIssue(quote.personalDetails.licenceCountry)),
    licenceType: buildFormField(buildLicenceType(quote.personalDetails.licenceType)),
    licenceDuration: buildFormField(calculateDuration(quote.personalDetails.licenceDurationMonths)),
    hasMedicalCondition: buildFormField(!!quote.personalDetails.medicalCondition),
    medicalCondition: buildConditionalFormField(buildMedicalCondition(quote.personalDetails.medicalCondition)),
    claims: buildRequiredFormField({
      hasClaims: !!quote.personalDetails.incidents && quote.personalDetails.incidents?.length > 0,
      shouldFlagUnansweredQuestions: false,
      isFormVisible: false,
      claims: quote.personalDetails.incidents?.map(buildClaim) ?? []
    }, {status: "success"}),
    convictions: buildRequiredFormField({
      convictions: quote.personalDetails.convictions?.map(buildConviction) ?? [],
      hasConvictions: !!quote.personalDetails.convictions && quote.personalDetails.convictions?.length > 0,
      shouldFlagUnansweredQuestions: false,
      isFormVisible: false
    }, {status: "success"}),
    hasNonMotoringConvictions: buildFormField(quote.personalDetails.hasNonMotoringConvictions),
    hasInsuranceDeclined: buildFormField(quote.personalDetails.hasCancellations),
    address: buildFormField(quote.personalDetails.address),
    proposerEmailAddress: buildFormField(quote.personalDetails.email),
    isHomeowner: buildFormField(quote.personalDetails.isHomeowner),
    storedAtNight: buildFormField(buildStoredAtNight(quote.personalDetails.storedAtNight)),
    soliciting: buildConditionalFormField(
      quote.personalDetails.proposedClassOfUse === ProposedClassOfUse.SocialCommutingAndBusiness
        ? quote.personalDetails.soliciting
        : undefined
    ),
    phoneNumber: buildFormField(quote.personalDetails.phoneNumber),
    mainDriver: buildConditionalFormField(findMainDriver(quote, additionalDrivers), {status: "success"}),
    isCarKeptAtHome: buildFormField(true, {status: "success"}),
    additionalDrivers
  };
}

function findMainDriver(quote: AggregatorQuoteResult, additionalDrivers: AggregatorAdditionalDriver[]): string | undefined {
  if (additionalDrivers.length === 0) return undefined;

  if (quote.personalDetails.isMainDriver) return "Policy Holder";

  return additionalDrivers.find(additionalDriver => additionalDriver.isMainDriver)?.id;
}