import {
  EventType,
  logGoogleAnalyticsEvent,
  mapValueToYesOrNoEventType
} from "shared-components/dist/analytics/GoogleAnalyticsEventsService";

export interface MotorGoogleAnalyticsEvent {
  categorySuffix: string;
  action: EventType | boolean
}

interface CategoryPrefix {
  categoryPrefix:
    | "your_car"
    | "your_details"
    | "additional_driver"
    | "your_cover"
    | "your_quote"
}

export function logYourCarGoogleAnalyticsEvent({categorySuffix, action}: MotorGoogleAnalyticsEvent): void {
  logMotorGoogleAnalyticsEvent({
    categoryPrefix: "your_car",
    categorySuffix,
    action,
  });
}

export function logYourDetailsGoogleAnalyticsEvent({categorySuffix, action}: MotorGoogleAnalyticsEvent): void {
  logMotorGoogleAnalyticsEvent({
    categoryPrefix: "your_details",
    categorySuffix,
    action,
  });
}

export function logYourCoverGoogleAnalyticsEvent({categorySuffix, action}: MotorGoogleAnalyticsEvent): void {
  logMotorGoogleAnalyticsEvent({
    categoryPrefix: "your_cover",
    categorySuffix,
    action,
  });
}

export function logAdditionalDriverGoogleAnalyticsEvent({categorySuffix, action}: MotorGoogleAnalyticsEvent): void {
  logMotorGoogleAnalyticsEvent({
    categoryPrefix: "additional_driver",
    categorySuffix,
    action,
  });
}

export function logYourQuoteGoogleAnalyticsEvent({categorySuffix, action}: MotorGoogleAnalyticsEvent): void {
  logMotorGoogleAnalyticsEvent({
    categoryPrefix: "your_quote",
    categorySuffix,
    action,
  });
}

export function logGeneralQuoteGoogleAnalyticsEvent({categorySuffix, action}: MotorGoogleAnalyticsEvent): void {
  if (typeof action === "boolean") action = mapValueToYesOrNoEventType(action);

  logGoogleAnalyticsEvent({
    categoryPrefix: "motor.quote",
    categorySuffix,
    action,
  });
}

function logMotorGoogleAnalyticsEvent({categoryPrefix, categorySuffix, action}: MotorGoogleAnalyticsEvent & CategoryPrefix): void {
  if (typeof action === "boolean") action = mapValueToYesOrNoEventType(action);

  logGoogleAnalyticsEvent({
    categoryPrefix: `motor.quote.${categoryPrefix}`,
    categorySuffix,
    action,
  });
}