import React, {FC} from "react";
import {
  maritalStatusCompatibleWithAdditionalDriversSelector,
  maritalStatusCompatibleWithRegisteredKeeperAndOwnerSelector
} from "../../redux/selectors/MaritalStatusSelectors";
import {StopProps} from "../../../../../../structure/information/stop/Stop";
import {maritalStatusReset} from "../../redux/PersonalDetailsSlice";
import QuoteJourneyStop from "../../../shared/stops/quote-journey-stop/QuoteJourneyStop";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const ProposerInvalidMaritalStatusStop: FC = () => {
  const dispatch = useAppDispatch();
  const isMaritalStatusCompatibleWithAdditionalDrivers = useAppSelector(maritalStatusCompatibleWithAdditionalDriversSelector);
  const maritalStatusCompatibilityWithRegisteredKeeperAndOwner = useAppSelector(maritalStatusCompatibleWithRegisteredKeeperAndOwnerSelector);

  const isMartialStatusCompatible = maritalStatusCompatibilityWithRegisteredKeeperAndOwner === "COMPATIBLE" && isMaritalStatusCompatibleWithAdditionalDrivers;

  const getTitleAndDescription = (): Pick<StopProps, "title" | "description"> => {
    if (maritalStatusCompatibilityWithRegisteredKeeperAndOwner === "INCOMPATIBLE_OWNER") return {
      title: "personalDetails.maritalStatusQuestion.ownerIncompatible.title",
      description: "personalDetails.maritalStatusQuestion.ownerIncompatible.description",
    };

    if (maritalStatusCompatibilityWithRegisteredKeeperAndOwner === "INCOMPATIBLE_KEEPER") return {
      title: "personalDetails.maritalStatusQuestion.keeperIncompatible.title",
      description: "personalDetails.maritalStatusQuestion.keeperIncompatible.description",
    };

    return {
      title: "personalDetails.maritalStatusQuestion.additionalDriverIncompatibleStop.title"
    };
  };

  return (
    <QuoteJourneyStop
      stopProps={{
        visible: !isMartialStatusCompatible,
        title: getTitleAndDescription().title,
        description: getTitleAndDescription().description,
        primaryButtonTitle: "personalDetails.maritalStatusQuestion.IncompatibleAnswerStop.reset",
        onPrimaryButtonClick: () => dispatch(maritalStatusReset())
      }}
    />
  );
};

export default ProposerInvalidMaritalStatusStop;
