import {RootState} from "../../../../../../redux/Store";
import {createSelector} from "@reduxjs/toolkit";
import {SerializableAdditionalDriver} from "../../../additional-driver/shared/models/AdditionalDriver";

const additionalDriversSelector = (state: RootState): SerializableAdditionalDriver[] => state.personalDetails.additionalDrivers;

export const additionalDriversOptionsSelector = createSelector(
  additionalDriversSelector,
  (additionalDrivers: SerializableAdditionalDriver[]): string[] =>
    additionalDrivers.map(driver => driver.id)
);