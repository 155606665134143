import React, {FC} from "react";
import AccordionList, {AccordionItemProps} from "shared-components/dist/lists/accordian-list/AccordionList";
import TranslatableKeyItemList, {TranslatableKeyItemRowProps} from "shared-components/dist/lists/key-item-list/TranslatableKeyItemList";
import {AdditionalDriver, Licence, Policy} from "shared/dist/generated/graphql/resolvers-types";
import MainDriverBadge from "./main-driver-badge/MainDriverBadge";
import GreyMessageCard from "../grey-message-card/GreyMessageCard";
import {formatDateStringFromIsoString} from "shared/dist/stdlib/DateFormat";
import {convertEnumValueToFormattedString} from "shared-components/dist/utils/helpers/EnumHelper";

interface Props {
  policy: Policy;
}

const CurrentAdditionalDrivers: FC<Props> = ({policy}) => {
  if (policy.additionalDrivers.length === 0) {
    return (
      <GreyMessageCard
        title="policies.additionalDrivers.noAdditionalDrivers.title"
        message="policies.additionalDrivers.noAdditionalDrivers.message"
      />
    );
  }

  return (
    <AccordionList items={policy.additionalDrivers.map(mapAdditionalDriverInformationToAccordionListItem)}/>
  );
};

function mapAdditionalDriverInformationToAccordionListItem(additionalDriver: AdditionalDriver): AccordionItemProps {
  return {
    title: `${additionalDriver.firstName} ${additionalDriver.lastName}`,
    titleBadge: additionalDriver.isMainDriver ? <MainDriverBadge/> : undefined,
    contents: <TranslatableKeyItemList rows={mapDriverInformationToItemRows(additionalDriver)}/>
  };
}

function mapDriverInformationToItemRows(additionalDriver: AdditionalDriver): TranslatableKeyItemRowProps[] {
  return [
    {
      keyValue: "policies.additionalDrivers.dateOfBirth",
      item: formatDateStringFromIsoString(additionalDriver.dateOfBirth)
    },
    {
      keyValue: "policies.additionalDrivers.licenceInformation",
      item: getLicenceInformationString(additionalDriver.licence)
    },
    {
      keyValue: "policies.additionalDrivers.convictions",
      item: additionalDriver.convictions.length > 0 ? additionalDriver.convictions.length : "None"
    },
    {
      keyValue: "policies.additionalDrivers.claims",
      item: additionalDriver.claims.length > 0 ? additionalDriver.claims.length : "None"
    }
  ];
}

function getLicenceInformationString(licence: Licence): string {
  return `${convertEnumValueToFormattedString(licence.type)}, ${convertEnumValueToFormattedString(licence.countryOfIssue)}`;
}

export default CurrentAdditionalDrivers;
