import {
  QuoteMutationUpsertQuoteExtrasArgs,
  UpsertQuoteExtrasResponse
} from "shared/dist/generated/graphql/resolvers-types";
import {apolloClient} from "../../../apollo/ApolloClientProvider";
import {UpsertQuoteExtrasResult} from "shared/dist/models/quote/UpsertQuoteExtrasResult";
import {UPSERT_QUOTE_EXTRAS} from "shared/dist/graphql/mutations/policyExtras/UpsertPolicyExtrasMutation";

export async function upsertQuoteExtrasMutation(args: QuoteMutationUpsertQuoteExtrasArgs): Promise<UpsertQuoteExtrasResponse> {
  const result = await apolloClient.mutate<UpsertQuoteExtrasResult, QuoteMutationUpsertQuoteExtrasArgs>({
    mutation: UPSERT_QUOTE_EXTRAS,
    variables: args
  })
    .then(result => Promise.resolve(result.data?.quote.upsertQuoteExtras))
    .catch(error => Promise.reject(error));

  if (!result) {
   throw new Error("Could not update extras.");
  }

  return result;
}