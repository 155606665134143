import {AppThunk, AppThunkDispatch} from "../../../../../redux/Store";
import {
  averageBusinessMileageInvalid,
  averageMileageInvalid,
  breakInCoverDeclarationAcceptanceInvalid,
  coverStartDateInvalid,
  coverTypeInvalid
} from "../redux/CoverDetailsSlice";
import {isValidFormField} from "shared-components/dist/models/form-field/FormField";
import {checkNoClaimsBonusQuestions} from "./CheckNoClaimsBonusQuestions";
import {CoverDetailsFields} from "../redux/CoverDetailsFields";
import {isAfter} from "date-fns/fp";
import {startOfDay} from "date-fns";
import {checkCompanyCarBonusQuestions} from "./CheckCompanyCarBonusQuestions";
import {checkNamedDriverQuestions} from "./CheckNamedDriverQuestions";

type CheckFunction = (coverDetails: CoverDetailsFields, dispatch: AppThunkDispatch) => void;

export const flagUnansweredCoverDetailsQuestions = (): AppThunk => async (dispatch, getState) => {
  [
    checkCoverStartDate,
    checkCurrentCoverExpiryDate,
    checkCoverType,
    checkNoClaimsBonusQuestions,
    checkAverageMileage,
    checkAverageBusinessMileage,
    checkBreakInCoverDeclaration,
    checkCompanyCarBonusQuestions,
    checkNamedDriverQuestions
  ].forEach((checkFunction: CheckFunction) => checkFunction(getState().coverDetails, dispatch));
};

function checkCoverStartDate(coverDetails: CoverDetailsFields, dispatch: AppThunkDispatch): void {
  const coverStartDate = coverDetails.coverStartDate;
  if (!isValidFormField(coverStartDate)) {
    dispatch(coverStartDateInvalid("quote.errors.questionIncomplete"));
  }
}

function checkCurrentCoverExpiryDate(coverDetails: CoverDetailsFields, dispatch: AppThunkDispatch): void {
  const currentPolicyExpiryDate = coverDetails.existingPolicyExpiryDate.value;
  const coverStartDate = coverDetails.coverStartDate.value;
  if (!currentPolicyExpiryDate || !coverStartDate) return;

  if (isAfter(startOfDay(new Date(coverStartDate.date)), startOfDay(new Date(currentPolicyExpiryDate)))) {
    dispatch(coverStartDateInvalid("coverDetails.errors.coverStartDateQuestion.policyStartBeforeCurrentPolicyExpiryDate"));
  }
}

function checkCoverType({coverType}: CoverDetailsFields, dispatch: AppThunkDispatch): void {
  if (coverType.status === "default") dispatch(coverTypeInvalid("quote.errors.questionIncomplete"));
}

function checkAverageMileage({averageMileage}: CoverDetailsFields, dispatch: AppThunkDispatch): void {
  if (averageMileage.status === "default") dispatch(averageMileageInvalid("quote.errors.questionIncomplete"));
}

function checkAverageBusinessMileage(
  {averageBusinessMileage: {isActive, status}}: CoverDetailsFields,
  dispatch: AppThunkDispatch
): void {
  if (isActive && status === "default") {
    dispatch(averageBusinessMileageInvalid("quote.errors.questionIncomplete"));
  }
}

export function checkBreakInCoverDeclaration(
  {breakInCoverDeclarationAcceptance: {isActive, value}}: CoverDetailsFields,
  dispatch: AppThunkDispatch
): void {
  if (isActive && value === undefined) {
    dispatch(breakInCoverDeclarationAcceptanceInvalid("quote.errors.questionIncomplete"));
  }
}
