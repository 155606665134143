import React, {FC} from "react";
import {phoneNumberChanged, phoneNumberInvalid, phoneNumberReset} from "../../redux/PersonalDetailsSlice";
import PhoneNumberQuestion from "../../../shared/questions/phone-number/PhoneNumberQuestion";
import {logYourDetailsGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const ProposerPhoneNumberQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const phoneNumberField = useAppSelector(state => state.personalDetails.phoneNumber);

  const onPhoneNumberChanged = (phoneNumber: string): void => {
    logYourDetailsGoogleAnalyticsEvent({
      categorySuffix: "phone_number",
      action: "Answered",
    });
    dispatch(phoneNumberChanged(phoneNumber));
  };

  const onInvalid = (): void => {
    dispatch(phoneNumberInvalid("personalDetails.phoneNumberQuestion.errors.invalidPhoneNumber"));
  };

  return (
    <PhoneNumberQuestion
      id="proposer-phone-number"
      title="personalDetails.phoneNumberQuestion"
      tooltip={{description: "personalDetails.phoneNumberQuestion.tooltip"}}
      phoneNumberField={phoneNumberField}
      onComplete={onPhoneNumberChanged}
      onCountryCodeChange={() => dispatch(phoneNumberReset())}
      onInvalid={onInvalid}
      placeholder="personalDetails.phoneNumberQuestion.placeholder"
    />
  );
};

export default ProposerPhoneNumberQuestion;
