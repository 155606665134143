import {FC} from "react";
import {StepbackRule} from "shared/dist/generated/graphql/resolvers-types";
import FormattedMessage from "shared-components/src/translations/components/FormattedMessage";

interface Props {
  stepbackRules: StepbackRule[];
}

const StepbackWithoutProtectionTable: FC<Props> = ({stepbackRules}) => (
  <table cellSpacing="0" cellPadding="0" id="stepback-without-protection-table">
    <thead>
    <tr>
      <th colSpan={2}>
        <FormattedMessage
          id="protectedNoClaimsDiscountInfo.stepBack.withoutProtection.title"
          values={{b: (message: string) => <b>{message}</b>}}
        />
      </th>
    </tr>
    </thead>
    <tbody>
    {stepbackRules.map(rules => (
      <tr key={rules.claims}>
        <td data-testid={`stepback-without-protection__${rules.claims}__claims`}>
          {rules.claims} claim(s) in the next 12 months
        </td>
        <td data-testid={`stepback-without-protection__${rules.claims}__years`}>
          {rules.nonProtectedYears} Years
        </td>
      </tr>
    ))}
    </tbody>
  </table>
);

export default StepbackWithoutProtectionTable;