import React, {FC} from "react";
import {toSerializableClaim} from "../../../shared/questions/claims/models/Claims";
import {
  claimAdded,
  claimRemoved,
  claimStatusChanged,
  flagUnansweredClaimsSubQuestions,
  hasClaimsChanged,
  isClaimsFormVisibleSet
} from "../../redux/PersonalDetailsSlice";
import ClaimsQuestion from "../../../shared/questions/claims/ClaimsQuestion";
import {logYourDetailsGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {claimsSelector} from "../../redux/selectors/PersonalDetailsSelectors";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const ProposerClaimsQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const claims = useAppSelector(claimsSelector);
  const shouldFlagUnansweredQuestions = useAppSelector(state => state.personalDetails.claims.value.shouldFlagUnansweredQuestions);
  const isFormVisible = useAppSelector(state => state.personalDetails.claims.value.isFormVisible);

  const onYesNoQuestionAnswered = (answer: boolean): void => {
    logYourDetailsGoogleAnalyticsEvent({
      categorySuffix: "motor_accidents_claims_losses",
      action: answer,
    });
    dispatch(hasClaimsChanged(answer));
  };

  return (
    <ClaimsQuestion
      id="proposer-claims-question"
      title="personalDetails.claims.hasClaims.title"
      tooltip={{
        description: "personalDetails.claims.hasClaims.tooltip.description",
      }}
      description="personalDetails.claims.hasClaims.description"
      claims={claims}
      shouldFlagUnansweredQuestions={shouldFlagUnansweredQuestions}
      flagUnansweredSubQuestions={() => dispatch(flagUnansweredClaimsSubQuestions())}
      isFormVisible={isFormVisible}
      hasClaimsChanged={onYesNoQuestionAnswered}
      isFormVisibleSet={value => dispatch(isClaimsFormVisibleSet(value))}
      claimAdded={claim => dispatch(claimAdded(toSerializableClaim(claim)))}
      claimRemoved={index => dispatch(claimRemoved(index))}
      claimStatusChanged={status => dispatch(claimStatusChanged(status))}
    />
  );
};

export default ProposerClaimsQuestion;
