import React, {FC} from "react";
import "./PortalSplashScreenHeaderBar.css";

const PortalSplashScreenHeaderBar: FC = () => (
  <div className="portal-splash-screen-header-bar">
    <div className="portal-splash-screen-header-bar__container"/>
  </div>
);

export default PortalSplashScreenHeaderBar;
