import {isNamedDriverAXAEligibleSelector} from "./IsNamedDriverAXAEligible";
import {RootState} from "../../../../../../../redux/Store";
import {isNoClaimsBonusValid} from "../helpers/IsNoClaimsBonusValid";
import {createSelector} from "@reduxjs/toolkit";

const yearsNoClaimsBonus = (state: RootState): number | undefined => state.coverDetails.yearsNoClaimsBonus.value ? parseInt(state.coverDetails.yearsNoClaimsBonus.value) : undefined;
const existingPolicyExpiryDate = (state: RootState): Date | undefined => state.coverDetails.existingPolicyExpiryDate.value ? new Date(state.coverDetails.existingPolicyExpiryDate.value) : undefined;
const coverStartDate = (state: RootState): Date | undefined => state.coverDetails.coverStartDate.value ? new Date(state.coverDetails.coverStartDate.value.date) : undefined;

export const selectIsNoClaimsBonusInvalid = createSelector(
  isNamedDriverAXAEligibleSelector,
  yearsNoClaimsBonus,
  existingPolicyExpiryDate,
  coverStartDate,
  (
    isEligibleForNamedDriver: boolean,
    yearsNoClaimsBonus: number | undefined,
    existingPolicyExpiryDate: Date | undefined,
    coverStartDate: Date | undefined
  ): boolean => {
    if (yearsNoClaimsBonus === undefined || existingPolicyExpiryDate === undefined || coverStartDate === undefined) return false;

    const noClaimsBonusValid = isNoClaimsBonusValid(
      yearsNoClaimsBonus,
      existingPolicyExpiryDate,
      coverStartDate
    );

    return isEligibleForNamedDriver && !noClaimsBonusValid;
  }
);