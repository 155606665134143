import React, {FC} from "react";
import BooleanQuestion from "../../../../../../structure/questions/question/variants/boolean-question/BooleanQuestion";
import {MEDICAL_CONDITION_DESCRIPTIONS, MEDICAL_CONDITIONS, MedicalConditionOption} from "./MedicalConditionOption";
import SubQuestionForm from "../../../../../../structure/questions/sub-question-form/SubQuestionForm";
import {BooleanFormField} from "shared-components/dist/models/form-field/variants/BooleanFormField";
import {
  StringRadioColumnSubQuestion
} from "../../../../../../structure/questions/sub-question/variants/radio-column-sub-question/variants/StringRadioColumnSubQuestion";
import {ConditionalFormField} from "shared-components/dist/models/form-field/ConditionalFormField";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import MedicalConditionStop from "./MedicalConditionStop";

interface OwnProps {
  id: string;
  title: TranslationKey;
  tooltip: TooltipProps;
  hasMedicalConditionField: BooleanFormField;
  medicalConditionField: ConditionalFormField<MedicalConditionOption>;
  hasMedicalConditionChanged: (value: boolean) => void;
  medicalConditionChanged: (value: MedicalConditionOption) => void;
  subQuestionId: string;
  subQuestionTitle: TranslationKey;
  isStopVisible: boolean;
}

const MedicalConditionQuestion: FC<OwnProps> = (
  {
    id,
    title,
    tooltip,
    hasMedicalConditionField,
    medicalConditionField,
    hasMedicalConditionChanged,
    medicalConditionChanged,
    subQuestionId,
    subQuestionTitle,
    isStopVisible
  }
) => {
  const onMedicalConditionSelection = (selection: string): void => {
    const medicalCondition = MEDICAL_CONDITIONS.find(condition => condition.description === selection);
    if (!medicalCondition) return;

    medicalConditionChanged(medicalCondition);
  };

  return (
    <>
      <BooleanQuestion
        id={id}
        title={title}
        formField={hasMedicalConditionField}
        onAnswer={hasMedicalConditionChanged}
        tooltip={tooltip}
      >
      </BooleanQuestion>
      {medicalConditionField.isActive &&
        <SubQuestionForm>
          <StringRadioColumnSubQuestion
            subQuestionProps={{
              id: subQuestionId,
              title: subQuestionTitle,
              errorMessage: medicalConditionField.errorMessage,
              status: medicalConditionField.status
            }}
            radioColumnProps={{
              options: MEDICAL_CONDITION_DESCRIPTIONS,
              name: "medical-condition-question",
              selectedOption: medicalConditionField.value?.description,
              onSelection: onMedicalConditionSelection
            }}
          />
          <MedicalConditionStop isVisible={isStopVisible}/>
        </SubQuestionForm>
      }
    </>
  );
};

export default MedicalConditionQuestion;
