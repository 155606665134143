import React, {FC} from "react";
import Modal from "shared-components/dist/modals/Modal";
import SimpleModalHeader from "shared-components/dist/modals/simple-modal-header/SimpleModalHeader";
import ButtonGroup from "shared-components/dist/buttons/button-group/ButtonGroup";
import Button from "shared-components/dist/buttons/button/Button";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./PriceGuaranteeExpiredModal.css";

interface Props {
  visible: boolean;
  onDismiss: () => void;
}

const PriceGuaranteeExpiredModal: FC<Props> = ({visible, onDismiss}) => (
  <Modal
    className="price-guarantee-expired-modal"
    visible={visible}
    header={<SimpleModalHeader title="priorQuote.priceGuaranteeExpiredModal.title"/>}
    body={<p>{lookupI18nString("priorQuote.priceGuaranteeExpiredModal.description")}</p>}
    footer={
      <ButtonGroup>
        <Button
          onClick={onDismiss}
          expanded
        >
          {lookupI18nString("priorQuote.priceGuaranteeExpiredModal.dismiss")}
        </Button>
      </ButtonGroup>
    }
  />
);

export default PriceGuaranteeExpiredModal;