import React, {FC, ReactChild} from "react";
import "./Reminder.css";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import DisplayButton from "./DisplayButton";

interface Props {
  title: TranslationKey;
  description: TranslationKey;
  href?: string
  icon: ReactChild
}

const Reminder: FC<Props> = ({title, description, href, icon}) => {
  return (
    <div className="reminder">
      <span className="reminder__icon">
        {icon}
      </span>
      <div className="reminder__notification">
        {href
          ? <DisplayButton href={href} label={displayLabel(title, description)}/>
          : displayLabel(title, description)}
      </div>
    </div>
  );
};

function displayLabel(title: TranslationKey, description: TranslationKey): ReactChild {
  return <>
    <span className="reminder__notification__title">
      <h5>{lookupI18nString(title)}</h5>
    </span>
    <span className="reminder__notification__description">
      <p>{lookupI18nString(description)}</p>
    </span>
  </>;
}

export default Reminder;
