import React, {FC} from "react";
import "./PolicyExtrasList.css";
import PolicyExtrasListItem, {PolicyExtrasListItemProps} from "../policy-extras-list-item/PolicyExtrasListItem";

interface Props {
  items: PolicyExtrasListItemProps[];
}

const PolicyExtrasList: FC<Props> = ({items}) => (
  <div className="policy-extras-list">
    {items.map((policyExtra, index) => <PolicyExtrasListItem key={index} {...policyExtra}/>)}
  </div>
);

export default PolicyExtrasList;