import React, {FC, useEffect, useState} from "react";
import SubContainerStop from "../../../../../../structure/information/stop/sub-container/SubContainerStop";
import Stop, {StopProps} from "../../../../../../structure/information/stop/Stop";
import DisableLayer from "../DisableLayer";
import {quoteJourneyStopHidden, quoteJourneyStopShown} from "../../../your-quote/redux/QuoteDetailsSlice";
import {openZendeskChat} from "../../../../../../zendesk/Zendesk";
import {logGeneralQuoteGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import "./QuoteJourneyStop.css";
import classNames from "classnames";
import {useAppDispatch} from "../../../../../../redux/Hooks";

export enum QuoteJourneyStopType {
  HardStop,
  SoftStop,
  MaxItemLimitExceeded
}

interface Props {
  stopProps: StopProps;
  isSubContainer?: boolean;
  isWithinModal?: boolean;
  className?: string;
  setIsVisible?: (isVisible: boolean) => void;
  stopType?: QuoteJourneyStopType;
}

const QuoteJourneyStop: FC<Props> = (
  {
    stopProps,
    isSubContainer = false,
    isWithinModal = false,
    className,
    setIsVisible,
    stopType= QuoteJourneyStopType.SoftStop,
  }
) => {
  const dispatch = useAppDispatch();

  const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    let needsToNotifyOfHide = false;
    if (stopProps.visible) {
      needsToNotifyOfHide = true;
      logGeneralQuoteGoogleAnalyticsEvent({
        categorySuffix: "soft_stop",
        action: "Shown"
      });
      dispatch(quoteJourneyStopShown());
    }

    return () => {
      if (needsToNotifyOfHide) {
        logGeneralQuoteGoogleAnalyticsEvent({
          categorySuffix: "soft_stop",
          action: "Dismissed"
        });
        dispatch(quoteJourneyStopHidden());
      }
    };
  }, [dispatch, stopProps.visible]);

  if (!stopProps.visible) return null;

  const onDismiss = setIsVisible ? () => setIsVisible(false) : undefined;

  return (
    <div className={classNames("quote-journey-stop", {"quote-journey-stop--modal": isWithinModal}, className)}>
      <div ref={setAnchorElement}>
        {selectCorrectStop(isSubContainer, stopProps, stopType,onDismiss)}
      </div>
      {isWithinModal === false && <DisableLayer anchorBelow={anchorElement}/>}
    </div>
  );
};

function selectCorrectStop(
  isSubContainer: boolean,
  stopProps: StopProps,
  stopType: QuoteJourneyStopType,
  dismissStop?: () => void
): React.ReactElement {
  if (isSubContainer) {
    switch (stopType) {
      case QuoteJourneyStopType.HardStop :
        return (
          <SubContainerStop
            stopProps={{
              ...stopProps
            }}
          />
        );
      case QuoteJourneyStopType.MaxItemLimitExceeded:
        return (
          <SubContainerStop
            stopProps={{
              ...stopProps,
              primaryButtonTitle: "vehicleQuote.softStop.button.primary",
              onPrimaryButtonClick: openZendeskChat,
              secondaryButtonTitle: "vehicleQuote.softStop.button.secondary",
              onSecondaryButtonClick: dismissStop
            }}
          />
        );
      default:
        return (
          <SubContainerStop
            stopProps={{
              ...stopProps,
              primaryButtonTitle: "vehicleQuote.softStop.button.primary",
              onPrimaryButtonClick: openZendeskChat
            }}
          />
      );
      }
    }
  switch (stopType) {
    case QuoteJourneyStopType.HardStop :
      return (
        <Stop
          classname={dismissStop && "quote-journey-stop"}
          {...stopProps}
        />
      );
    case QuoteJourneyStopType.MaxItemLimitExceeded:
      return (
        <Stop
          classname={dismissStop && "quote-journey-stop"}
          {...stopProps}
          primaryButtonTitle= {"vehicleQuote.softStop.button.primary"}
          onPrimaryButtonClick= {openZendeskChat}
          secondaryButtonTitle= {"vehicleQuote.softStop.button.secondary"}
          onSecondaryButtonClick= {dismissStop}
        />
      );
    default:
      return (
        <Stop
          classname={dismissStop && "quote-journey-stop"}
          {...stopProps}
          primaryButtonTitle= {stopProps.primaryButtonTitle ?? "vehicleQuote.softStop.button.primary"}
          onPrimaryButtonClick= {stopProps.onPrimaryButtonClick ?? openZendeskChat}
          secondaryButtonTitle= {undefined}
          onSecondaryButtonClick= {undefined}
        />
      );
  }
}

export default QuoteJourneyStop;
