import "./OnlineRenewalSoftStopModal.css";
import {Dispatch, FC, SetStateAction} from "react";
import Modal from "shared-components/src/modals/Modal";
import Button from "shared-components/src/buttons/button/Button";
import {Comment} from "@mui/icons-material";
import FormattedMessage from "shared-components/src/translations/components/FormattedMessage";
import {openZendeskChat} from "../../../../../zendesk/Zendesk";

interface Props {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}

const OnlineRenewalSoftStopModal: FC<Props> = ({isVisible, setIsVisible}) => {
  return (
    <Modal
      className="online-renewal-soft-stop-modal"
      visible={isVisible}
      onDismiss={() => setIsVisible(false)}
      displayCloseButton={true}
      header={
        <>
          <h3>
            <FormattedMessage id="portal.renewals.summary.onlineRenewalSoftStop.ohItLookslike"/>
          </h3>
        </>
      }
      body={
       <div className="online-renewal-soft-stop-modal__body">
         <div className="online-renewal-soft-stop-modal__body__button-group">
           <Button
             data-testId="chat-button"
             variant="ghost"
             leftIcon={<Comment/>}
             onClick={() => {
               setIsVisible(false);
               openZendeskChat();
             }}
           >
             <FormattedMessage id="portal.renewals.summary.onlineRenewalSoftStop.talkToOurTeam"/>
           </Button>
           <Button
             data-testId="close-button"
             onClick={() => setIsVisible(false)}
           >
             <FormattedMessage id="portal.renewals.summary.onlineRenewalSoftStop.gotIt"/>
           </Button>
         </div>
         <p data-testid="footer-text">
           <FormattedMessage
             id="portal.renewals.summary.onlineRenewalSoftStop.stillNeedToTalk"
             values={{
               a: (phoneNumber: string) => <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
             }}
           />
         </p>
       </div>
      }
    />
  );
};

export default OnlineRenewalSoftStopModal;