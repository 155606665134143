import {createSelector} from "@reduxjs/toolkit";
import {daysBetween, now} from "shared/dist/stdlib/Dates";
import {RootState} from "../../../../../../../redux/Store";

const vehiclePurchaseDate = (state: RootState): Date | undefined => state.vehicleDetails.dateOfPurchase.value ? new Date(state.vehicleDetails.dateOfPurchase.value) : undefined;
const vehiclePreviousPolicyExpiryDate = (state: RootState): Date | undefined => state.coverDetails.vehiclePreviousPolicyExpiryDate.value ? new Date(state.coverDetails.vehiclePreviousPolicyExpiryDate.value) : undefined;
const hasVehicleBeenInsured = (state: RootState): boolean | undefined => state.coverDetails.hasVehicleBeenInsured.value;
const namedDriverOnOwnVehicle = (state: RootState): boolean | undefined => state.coverDetails.namedDriverOnOwnVehicle.value;
const previouslyHeldInsurance = (state: RootState): boolean | undefined => state.coverDetails.previouslyHeldInsurance.value;

export const selectIsVehiclePurchasedMoreThan14DaysAgo = createSelector(
  vehiclePurchaseDate,
  (vehiclePurchaseDate: Date | undefined): boolean => {
    if (!vehiclePurchaseDate) return false;

    return daysBetween(vehiclePurchaseDate, now()) > 14;
  }
);

export const selectIsVehiclePurchaseDateWithinMaxBreakInCoverThreshold = createSelector(
  vehiclePurchaseDate,
  (vehiclePurchaseDate: Date | undefined): boolean | undefined => {
    if (!vehiclePurchaseDate) return undefined;

    return daysBetween(vehiclePurchaseDate, now()) <= 14;
  }
);

export const selectIsVehiclePreviousPolicyExpiryDateWithinMaxBreakInCoverThreshold = createSelector(
  vehiclePreviousPolicyExpiryDate,
  (vehiclePreviousPolicyExpiryDate: Date | undefined): boolean | undefined => {
    if (!vehiclePreviousPolicyExpiryDate) return undefined;

    return daysBetween(vehiclePreviousPolicyExpiryDate, now()) <= 14;
  }
);

export const selectIsVehicleBreakInCoverWithinMaxThreshold = createSelector(
  selectIsVehiclePurchaseDateWithinMaxBreakInCoverThreshold,
  selectIsVehiclePreviousPolicyExpiryDateWithinMaxBreakInCoverThreshold,
  (isVehiclePurchaseDateWithinMaxBreakInCoverThreshold: boolean | undefined, isVehiclePreviousPolicyExpiryDateWithinMaxBreakInCoverThreshold: boolean | undefined): boolean | undefined => {
    if (isVehiclePurchaseDateWithinMaxBreakInCoverThreshold === undefined || isVehiclePreviousPolicyExpiryDateWithinMaxBreakInCoverThreshold === undefined) return undefined;

    return isVehiclePurchaseDateWithinMaxBreakInCoverThreshold || isVehiclePreviousPolicyExpiryDateWithinMaxBreakInCoverThreshold;
  }
);

export const selectShouldShowBreakInCoverSoftStop = createSelector(
  hasVehicleBeenInsured,
  namedDriverOnOwnVehicle,
  selectIsVehicleBreakInCoverWithinMaxThreshold,
  (
    hasVehicleBeenInsured: boolean | undefined,
    namedDriverOnOwnVehicle: boolean | undefined,
    isVehicleBreakInCoverWithinMaxBreakInCoverThreshold: boolean | undefined,
  ): boolean => {
    return hasVehicleBeenInsured === false || namedDriverOnOwnVehicle === false || isVehicleBreakInCoverWithinMaxBreakInCoverThreshold === false;
  }
);

export const selectNoBreakInCoverDetected = createSelector(
  selectIsVehiclePurchasedMoreThan14DaysAgo,
  selectIsVehicleBreakInCoverWithinMaxThreshold,
  (
    isVehiclePurchasedMoreThan14DaysAgo: boolean,
    isVehicleBreakInCoverWithinMaxBreakInCoverThreshold: boolean | undefined,
  ): boolean => {
    return !isVehiclePurchasedMoreThan14DaysAgo || isVehicleBreakInCoverWithinMaxBreakInCoverThreshold === true;
  }
);

export const selectShouldShowNamedOnOwnVehicleNamedDriverQuestions = createSelector(
  selectIsVehicleBreakInCoverWithinMaxThreshold,
  previouslyHeldInsurance,
  (
    isVehicleBreakInCoverWithinMaxBreakInCoverThreshold: boolean | undefined,
    previouslyHeldInsurance: boolean | undefined
  ): boolean => {
    return isVehicleBreakInCoverWithinMaxBreakInCoverThreshold === true && previouslyHeldInsurance === false;
  }
);