import React, {FC} from "react";
import "./AdditionalDriversQuestion.css";
import {useLocation, useNavigate} from "react-router-dom";
import {ADD_ADDITIONAL_DRIVER} from "../../../../../../router/models/Routes";
import {createEditAdditionalDriverUrlFor} from "../../../shared/services/CreateEditAdditionalDriverUrl";
import {Element} from "react-scroll";
import Spacer from "shared-components/dist/spacer/Spacer";
import {buildQuoteRoute} from "../../../../../../utils/navigation/NavigationHelpers";
import {additionalDriverReset} from "../../../additional-driver/shared/redux/UpdateAdditionalDriverSlice";
import {logYourDetailsGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {additionalDriversSelector} from "../../redux/selectors/PersonalDetailsSelectors";
import {ReactComponent as DriverIcon} from "../../../../../../assets/icons/driver.svg";
import ActionList from "shared-components/dist/lists/action-list/ActionList";
import {mainDriverSelector} from "../../redux/selectors/ProhibitedMainDriverStopVisibleSelector";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {MAX_ADDITIONAL_DRIVERS} from "../../../../../../utils/constants/Quote";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

export const AdditionalDriversQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const additionalDrivers = useAppSelector(additionalDriversSelector);
  const mainDriverId = useAppSelector(mainDriverSelector);

  const navigate = useNavigate();
  const location = useLocation();
  const questionId = "additional-drivers-question";

  const editDriver = (driverId: string): void => {
    logYourDetailsGoogleAnalyticsEvent({
      categorySuffix: "edit_driver",
      action: "Click",
    });

    navigate(
      createEditAdditionalDriverUrlFor(location.pathname, driverId),
      {state: {pageTitle: "additionalDriver.edit.sidebar.title.yourDetails"}});
  };

  const addDriver = (): void => {
    logYourDetailsGoogleAnalyticsEvent({
      categorySuffix: "add_driver",
      action: "Click",
    });

    dispatch(additionalDriverReset());
    navigate(buildQuoteRoute(location.pathname, ADD_ADDITIONAL_DRIVER),
      {state: {pageTitle: "additionalDriver.add.sidebar.title.yourDetails"}});
  };

  return (
    <Element name={questionId}>
      <div className="additional-drivers-question">
        <Spacer className="question__spacer"/>
        <ActionList
          title="personalDetails.additionalDrivers.title"
          description="personalDetails.additionalDrivers.description"
          buttonText="personalDetails.additionalDrivers.button"
          items={additionalDrivers.map(d => (
            {
              id: d.id,
              title: `${d.firstName} ${d.surname}`,
              prefix: mainDriverId === d.id ? lookupI18nString("personalDetails.additionalDrivers.prefix") : undefined
            }
          ))}
          onItemClick={editDriver}
          newItemClick={addDriver}
          cardIcon={<DriverIcon/>}
          actionType="edit"
          maxItems={MAX_ADDITIONAL_DRIVERS}
        />
      </div>
    </Element>
  );
};

export default AdditionalDriversQuestion;