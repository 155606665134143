import {hasMedicalConditionInvalid, medicalConditionInvalid} from "../redux/PersonalDetailsSlice";
import {AppThunkDispatch} from "../../../../../redux/Store";
import {PersonalDetails} from "../redux/PersonalDetails";

export function flagUnansweredMedicalConditionQuestions(
  {hasMedicalCondition, medicalCondition}: PersonalDetails,
  dispatch: AppThunkDispatch
): void {
  if (hasMedicalCondition.status === "default") {
    dispatch(hasMedicalConditionInvalid("quote.errors.questionIncomplete"));
  } else if (hasMedicalCondition.value && medicalCondition.status === "default") {
    dispatch(medicalConditionInvalid("quote.errors.questionIncomplete"));
  }
}
