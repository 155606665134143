import {useEffect, useState} from "react";

export function useDismissOnQuoteRefreshed(isQuoteLoading: boolean, visible: boolean, onDismiss: () => void): void {
  const [quoteRefreshed, setQuoteRefreshed] = useState(false);

  useEffect(() => {
    if (isQuoteLoading && visible) setQuoteRefreshed(true);
    if (quoteRefreshed && !isQuoteLoading) {
      onDismiss();
      setQuoteRefreshed(false);
    }
  }, [isQuoteLoading, quoteRefreshed, setQuoteRefreshed, onDismiss, visible]);
}