import React, {FC} from "react";
import KeyItemList from "shared-components/dist/lists/key-item-list/KeyItemList";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {AdditionalDriver, Policy} from "shared/dist/generated/graphql/resolvers-types";
import {KeyItemRowProps} from "shared-components/dist/lists/key-item-list/KeyItemRow";

export interface Props {
  policy: Policy;
}

const YourAdditionalDrivers: FC<Props> = ({policy}) => {
  return (
    <KeyItemList rows={getAdditionalDriverRows(policy.additionalDrivers)}/>
  );
};

function getAdditionalDriverRows(additionalDrivers: AdditionalDriver[]): KeyItemRowProps[] {
  return additionalDrivers.map(additionalDriver => ({
      keyValue: `${additionalDriver.firstName} ${additionalDriver.lastName}${(displayMainDriverIfMainDriver(additionalDriver.isMainDriver))}`,
      item: <div/>
    })
  );
}

function displayMainDriverIfMainDriver(isMainDriver: boolean): string {
  return isMainDriver ? ` (${lookupI18nString("policies.mainContent.yourAdditionalDrivers.mainDriver")})` : "";
}

export default YourAdditionalDrivers;
