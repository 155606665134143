import {FC} from "react";
import {
  ReactComponent as LogoPrimaryDark
} from "shared-components/dist/assets/icons/logos/stroll/stroll-logo-primary-dark.svg";
import {logNavigationGoogleAnalyticsEvent} from "../../../../utils/analytics/NavigationAnalytics";
import LogoutButton from "../../../../structure/navigation/navbar/logout-button/LogoutButton";
import "./PortalHeader.css";
import {OptionalDebugPanel} from "../../../quote/vehicle/shared/debug-panel/OptionalDebugPanel";

const PortalHeader: FC = () => {
  const onLogoClicked = (): void => {
    logNavigationGoogleAnalyticsEvent({
      categorySuffix: "stroll_logo",
      action: "Click"
    });
  };

  return (
    <>
      <OptionalDebugPanel/>
      <div className="portal-header">
        <div className="portal-header__container">
          <div className="portal-header__container__logo" onClick={onLogoClicked}>
            <LogoPrimaryDark/>
          </div>
          <div className="portal-header__container__buttons">
            <LogoutButton googleAnalyticsPrefix="portal"/>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortalHeader;