import {FC} from "react";
import Tooltip from "shared-components/dist/tooltips/tooltip/Tooltip";
import "./QuoteCardToolTip.css";
import {QuoteToolTipDetails} from "./QuoteToolTipDetails";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";

interface Props {
  id: string;
  details: QuoteToolTipDetails
}

const QuoteCardToolTip: FC<Props> = ({id, details}) => {

  const description =
    <div id={id}>
      <p>{lookupI18nString(details.calculatedHeader)}</p>
      <p>{lookupI18nString(details.calculatedDescription)}</p>
      <p>{lookupI18nString(details.changesHeader)}</p>
      <p>{lookupI18nString(details.changesDescription)}</p>
    </div>;

  return (
    <Tooltip description={description} shouldDisplayTitle={false}/>
  );
};

export default QuoteCardToolTip;