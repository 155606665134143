import {RelationshipToProposer} from "shared/dist/generated/graphql/resolvers-types";

export const RELATIONSHIP_TO_PROPOSER_OPTIONS = [
  {
    id: RelationshipToProposer.Spouse,
    description: "Spouse"
  },
  {
    id: RelationshipToProposer.CommonLawSpouse,
    description: "Common Law Spouse"
  },
  {
    id: RelationshipToProposer.PartnerCivil,
    description: "Partner - Civil"
  },
  {
    id: RelationshipToProposer.Parent,
    description: "Parent"
  },
  {
    id: RelationshipToProposer.Unrelated,
    description: "Unrelated"
  },
  {
    id: RelationshipToProposer.DaughterOrSon,
    description: "Daughter or Son"
  },
  {
    id: RelationshipToProposer.DaughterOrSonInLaw,
    description: "Daughter/Son in-law"
  },
  {
    id: RelationshipToProposer.BrotherOrSister,
    description: "Brother or Sister"
  },
  {
    id: RelationshipToProposer.Grandparent,
    description: "Grandparent"
  },
  {
    id: RelationshipToProposer.Grandchild,
    description: "Grandchild"
  },
  {
    id: RelationshipToProposer.Family,
    description: "Family"
  },
  {
    id: RelationshipToProposer.Guardian,
    description: "Guardian"
  },
  {
    id: RelationshipToProposer.PartnerOfFamily,
    description: "Partner of Family"
  },
  {
    id: RelationshipToProposer.ProposersEmployee,
    description: "Proposers Employee"
  },
  {
    id: RelationshipToProposer.ProposersEmployer,
    description: "Proposers Employer"
  },
  {
    id: RelationshipToProposer.BusinessPartner,
    description: "Business Partner"
  },
  {
    id: RelationshipToProposer.Director,
    description: "Director"
  },
  {
    id: RelationshipToProposer.Tenant,
    description: "Tenant"
  },
  {
    id: RelationshipToProposer.Lodger,
    description: "Lodger"
  }
] as const;

export const RELATIONSHIP_TO_PROPOSER_DESCRIPTIONS = RELATIONSHIP_TO_PROPOSER_OPTIONS.map(status => status.description);

export type RelationshipToProposerOption = typeof RELATIONSHIP_TO_PROPOSER_OPTIONS[number];

export const relationshipToProposerOptionFromId = (id: RelationshipToProposer): RelationshipToProposerOption => {
  const result = RELATIONSHIP_TO_PROPOSER_OPTIONS.find(option => option.id === id);

  if (result === undefined) throw new Error(`Unknown relationship to proposer: ${id}`);

  return result;
};