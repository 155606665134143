import React, {FC} from "react";
import "./Spacer.scss";

interface Props {
  className?: string;
}

const Spacer: FC<Props> = ({className}) => (
  <hr className={`spacer ${className ? className : ""}`}/>
);

export default Spacer;