import {FC, useEffect} from "react";
import {portalReset, quoteReset} from "../../../redux/Actions";
import {useNavigate} from "react-router-dom";
import {CONFETTI_PATH, QUOTE_PAYMENT_SUCCESS_CONFETTI} from "../../../router/models/Routes";
import {useEcommerceAddPurchase} from "../../../utils/analytics/hooks/EcommerceAddPurchase";
import {emitPaymentSuccessPageEvent} from "../../../utils/analytics/service/TagManagerService";
import {useAppDispatch, useAppSelector} from "../../../redux/Hooks";
import {useEffectOnFirstRender} from "shared-components/dist/utils/hooks/UseEffectOnFirstRender";
import {
  emailSelector,
  paymentTransactionIdSelector,
  phoneNumberSelector
} from "../shared/redux/PersonalDetailsSelector";
import {PaymentFlowType} from "../shared/models/PaymentFlowType";
import {selectPolicyPaymentAmount} from "../../../redux/selectors/PolicyPaymentSelectors";

interface Props {
  paymentFlowType: PaymentFlowType;
}

/**
 * Clears react state and navigates to the "real" success page. Thus disabling back button as it always jumps back
 */
const PaymentSuccess: FC<Props> = ({paymentFlowType}) => {
  const dispatch = useAppDispatch();
  const emailAddress = useAppSelector(emailSelector(paymentFlowType));
  const phoneNumber = useAppSelector(phoneNumberSelector(paymentFlowType));
  const transactionId = useAppSelector(paymentTransactionIdSelector(paymentFlowType));
  const amountPaid = useAppSelector(selectPolicyPaymentAmount);

  const navigate = useNavigate();

  useEcommerceAddPurchase(paymentFlowType);
  useEffectOnFirstRender(() => {
    if (amountPaid && transactionId) {
      emitPaymentSuccessPageEvent({
        emailAddress,
        phoneNumber,
        transactionId,
        value: amountPaid.amount,
        currency: amountPaid.currency
      });
    }
  });

  useEffect(() => {
    if (paymentFlowType === PaymentFlowType.QUOTE) {
      dispatch(quoteReset());
      navigate(QUOTE_PAYMENT_SUCCESS_CONFETTI);
    } else {
      dispatch(portalReset());
      navigate(CONFETTI_PATH);
    }
  });

  return null;
};

export default PaymentSuccess;