import {quoteSteps} from "./QuoteSteps";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {YOUR_CAR, YOUR_COVER, YOUR_DETAILS, YOUR_QUOTE} from "../../../../../router/models/Routes";

export interface QuoteMenuItem {
  title: TranslationKey;
  description: TranslationKey;
  path: string;
}

export const vehicleQuoteMenuItems = [
  {
    path: YOUR_CAR,
    description: quoteSteps.yourCar.sidebarDescription,
    title: quoteSteps.yourCar.headerTitle
  },
  {
    path: YOUR_DETAILS,
    description: quoteSteps.yourDetails.sidebarDescription,
    title: quoteSteps.yourDetails.headerTitle
  },
  {
    path: YOUR_COVER,
    description: quoteSteps.yourCover.sidebarDescription,
    title: quoteSteps.yourCover.headerTitle
  },
  {
    path: YOUR_QUOTE,
    description: quoteSteps.yourQuote.sidebarDescription,
    title: quoteSteps.yourQuote.headerTitle
  },
];