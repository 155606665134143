import {DriverPositionOptions} from "../../../../../pages/quote/vehicle/your-vehicle/components/additional-vehicle-details-form/models/DriverPositionOptions";

export type DriverPosition = "Left" | "Right";

export function driverPositionRadioOptionFrom(value: DriverPosition): DriverPositionOptions {
  switch (value) {
    case "Left": return "Left Hand Drive";
    case "Right": return "Right Hand Drive";
  }
}
