import {gql} from "@apollo/client";
import {RetrieveAddressResult} from "shared/dist/generated/graphql/resolvers-types";
import {apolloClient} from "../../../apollo/ApolloClientProvider";

const RETRIEVE_ADDRESS = gql`
  query RetrieveAddress($id: String!) {
    address {
      retrieveAddress(id: $id) {
        firstLine
        city
        county
        postcode
        region
        country
      }
    }
  }
`;

export interface RetrieveAddressQueryVariables {
  id: string;
}

export interface RetrieveAddressResults {
  address: {
    retrieveAddress: RetrieveAddressResult;
  };
}

export function retrieveAddressBy(id: string): Promise<RetrieveAddressResult> {
  return apolloClient.query<RetrieveAddressResults, RetrieveAddressQueryVariables>({
    query: RETRIEVE_ADDRESS,
    variables: {
      id
    }
  })
  .then(result => Promise.resolve(result.data.address.retrieveAddress))
  .catch(error => Promise.reject(error));
}
