import React, {FC} from "react";
import Button from "shared-components/dist/buttons//button/Button";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import ButtonGroup from "shared-components/dist/buttons//button-group/ButtonGroup";

interface Props {
  onDismiss: () => void;
  onSave: () => void;
  disabled: boolean;
  loading: boolean;
}

const EditCoverSummaryFooter: FC<Props> = ({onDismiss, onSave, disabled, loading}) => (
  <ButtonGroup>
    <Button
      variant="tertiary"
      onClick={() => onDismiss()}
      expanded
    >
      {lookupI18nString("quoteDetails.summary.modal.cancel")}
    </Button>

    <Button
      variant="primary"
      onClick={() => onSave()}
      disabled={disabled}
      loading={loading}
      expanded
    >
      {lookupI18nString("quoteDetails.summary.modal.save")}
    </Button>
  </ButtonGroup>
);

export default EditCoverSummaryFooter;