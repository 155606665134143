import React, {cloneElement, FC, ReactElement} from "react";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import Spacer from "shared-components/dist/spacer/Spacer";
import SectionHeader from "../section-header/SectionHeader";
import "./ModalQuestion.css";
import SubContainer from "../sub-container/SubContainer";
import {Status} from "shared-components/dist/status-indicator/Status";
import Rag from "shared-components/dist/information/rag/Rag";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import ErrorHeader from "shared-components/dist/questions/error-header/ErrorHeader";

interface Props {
  id: string;
  title: TranslationKey;
  description?: TranslationKey;
  inputLabel?: TranslationKey;
  status?: Status;
  children: ReactElement;
  errorMessage?: TranslationKey;
  errorTooltip?: TranslationKey;
}

export type ModalQuestionProps = Props;

const ModalQuestion: FC<Props> = (
  {
    id,
    title,
    description,
    inputLabel,
    status = "hidden",
    children,
    errorMessage,
    errorTooltip,
  }
) => {
  const inErrorOrWarning = ["error", "warning"].includes(status);

  return (
    <div className="modal-question">
      <Spacer/>
      <SubContainer status={inErrorOrWarning ? status : "hidden"}>
        {(inErrorOrWarning && errorMessage) && (
          <ErrorHeader title={errorMessage} status={status}/>
        )}

        <div className="modal-question__container">
          <div className="modal-question__container--left">
            <SectionHeader
              title={title}
              description={description}
              htmlFor={id}
            />
          </div>

          <div
            className={`modal-question__container--right${inputLabel ? " modal-question__container__with-label" : ""}`}>
            {inputLabel && (
              <label className="modal-question__container__input-label" htmlFor={id}>
                {lookupI18nString(inputLabel)}
              </label>
            )}

            {children && cloneElement(children, {...children.props, id: id})}
          </div>
        </div>

        {(inErrorOrWarning && errorTooltip) && (
          <Rag status={status} description={errorTooltip}/>
        )}
      </SubContainer>
    </div>
  );
};

export default ModalQuestion;