import {FC} from "react";
import "./ProtectedNoClaimsTable.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import Button from "shared-components/dist/buttons/button/Button";
import {openZendeskChat} from "../../../../../zendesk/Zendesk";
import {
  selectAreRenewalProspectiveLoansLoading,
  selectIsEligibleForNoClaimsBonusProtection,
  selectNoClaimsBonusProtectionCost,
  selectSelectedQuoteType,
  selectShouldProtectNoClaimsBonus,
} from "../../redux/RenewalSelectors";
import {shouldProtectNoClaimsBonusChanged} from "../../redux/RenewalSlice";
import {useAppDispatch, useAppSelector} from "../../../../../redux/Hooks";
import {displayLoanValue} from "../../../../../utils/service/string/DisplayLoanValue";
import {scrollToElement} from "../../../../../utils/navigation/ScrollToHelpers";
import {loadRebrokedQuote} from "../../redux/thunks/LoadRebrokedQuote";

const ProtectedNoClaimsTable: FC = () => {
  const dispatch = useAppDispatch();
  const isEligibleForNoClaimsBonusProtection = useAppSelector(selectIsEligibleForNoClaimsBonusProtection);
  const selectedQuoteType = useAppSelector(selectSelectedQuoteType);
  const shouldProtectNoClaimsBonus = useAppSelector(selectShouldProtectNoClaimsBonus);
  const noClaimsBonusProtectionCost = useAppSelector(selectNoClaimsBonusProtectionCost);
  const areRenewalProspectiveLoansLoading = useAppSelector(selectAreRenewalProspectiveLoansLoading);

  const toggleShouldProtectNoClaimsBonus = (): void => {
    const updatedProtectNoClaimsBonus = !shouldProtectNoClaimsBonus;

    dispatch(shouldProtectNoClaimsBonusChanged(updatedProtectNoClaimsBonus));
    dispatch(loadRebrokedQuote(updatedProtectNoClaimsBonus));
    scrollToElement("renewal-price-cards");
  };

  if (!isEligibleForNoClaimsBonusProtection) return null;

  return (
    <>
      <div
        id="protected-no-claims-table"
        role="table"
        className="protected-no-claims-table"
        data-testid="protected-no-claims-table"
      >
        <div className="protected-no-claims-table__header">
          <div>{lookupI18nString("portal.renewals.summary.protectNoClaims.header.column1")}</div>

          <div>{lookupI18nString("portal.renewals.summary.protectNoClaims.header.column2")}</div>
        </div>

        <div className="protected-no-claims-table__body">
          <div>
            <h5>{lookupI18nString("portal.renewals.summary.protectNoClaims.body.column1.title")}</h5>
            <p>{lookupI18nString("portal.renewals.summary.protectNoClaims.body.column1.description")}</p>
          </div>
          {selectedQuoteType === "RENEWAL" && (<div className="protect-no-claims-table__addToPlan">
            <h6>
              {lookupI18nString(
                shouldProtectNoClaimsBonus
                  ? "portal.renewals.summary.protectNoClaims.body.renewal.remove.title"
                  : "portal.renewals.summary.protectNoClaims.body.renewal.add.title"
              )}
            </h6>

            <p>
              {lookupI18nString(
                shouldProtectNoClaimsBonus
                  ? "portal.renewals.summary.protectNoClaims.body.renewal.remove.description"
                  : "portal.renewals.summary.protectNoClaims.body.renewal.add.description"
              )}
            </p>

            <Button
              className="protect-no-claims-table__openZendeskChat"
              variant="ghost"
              onClick={openZendeskChat}
              small
              role="button"
            >
              {lookupI18nString("portal.renewals.summary.protectNoClaims.body.renewal.button")}
            </Button>
          </div>)}

          {selectedQuoteType === "REBROKE" && (<div className="protect-no-claims-table__addToPlan">
            <h6 className={`protect-no-claims-table__addToPlan__${shouldProtectNoClaimsBonus ? "remove" : "add"}`}>
              <span>{lookupI18nString(shouldProtectNoClaimsBonus
                ? "portal.renewals.summary.protectNoClaims.body.rebroke.remove.title"
                : "portal.renewals.summary.protectNoClaims.body.rebroke.add.title"
              )}</span>
              {shouldProtectNoClaimsBonus &&
                <span
                  className="protect-no-claims-table__addToPlan__price"
                  data-testid="protect-no-claims-table__addToPlan__price"
                >
                  {displayLoanValue(areRenewalProspectiveLoansLoading
                    ? undefined
                    : noClaimsBonusProtectionCost)
                  }
                </span>
              }
            </h6>
            {shouldProtectNoClaimsBonus && <p>{lookupI18nString("portal.renewals.summary.protectNoClaims.body.rebroke.remove.description")}</p>}
            <Button
              className={shouldProtectNoClaimsBonus
                ? "protect-no-claims-table__addToPlan__toggle__remove"
                : "protect-no-claims-table__addToPlan__toggle__add"
              }
              variant={shouldProtectNoClaimsBonus ? "delete" : "ghost"}
              onClick={toggleShouldProtectNoClaimsBonus}
              small
              role="button"
              disabled={areRenewalProspectiveLoansLoading}
            >
              {lookupI18nString(shouldProtectNoClaimsBonus
                ? "portal.renewals.summary.protectNoClaims.body.rebroke.remove.button"
                : "portal.renewals.summary.protectNoClaims.body.rebroke.add.button"
              )}
            </Button>
          </div>)}
        </div>
      </div>
    </>
  );
};

export default ProtectedNoClaimsTable;