import {Status} from "shared-components/dist/status-indicator/Status";
import {Option} from "../../../../../../../../structure/form/typeahead/models/Option";
import {ConditionalFormField} from "shared-components/dist/models/form-field/ConditionalFormField";
import {useQuery} from "@apollo/client";
import {VehicleOptionsQueryResult} from "../../../../../../../../graphql/queries/vehicle-lookup/registration-lookup/VehicleLookup";
import {toFormStatus} from "../../../../../../../../graphql/services/ToFormStatus";
import {MODELS_LOOKUP_QUERY} from "../../../../../../../../graphql/queries/vehicle-lookup/manual-vehicle-lookup/model/ModelsQuery";

export interface ModelsResult {
  status: Status | undefined;
  models: Option<string>[];
  onModelSelected: (selection: Option<string>) => void;
}

export function useModelsProps(
  make: string | undefined,
  model: ConditionalFormField<string>,
  modelSelected: (value: string) => void
): ModelsResult {
  const {loading, error, data} = useQuery<VehicleOptionsQueryResult>(
    MODELS_LOOKUP_QUERY,
    {
      variables: {make}
    }
  );
  const models: Option<string>[] = data?.vehicle.models.map(model => ({
    value: model,
    label: model
  })) ?? [];
  const status = toFormStatus(model, loading, error);

  const onModelSelected = (selection: Option<string>): void => {
    const type = models.find(type => type.value === selection.value);

    if (type) modelSelected(selection.value);
  };

  return {status, models, onModelSelected};
}