import React, {FC} from "react";
import "./SubSectionTitle.css";
import {Status} from "shared-components/dist/status-indicator/Status";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import Tooltip, {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import ErrorHeader from "shared-components/dist/questions/error-header/ErrorHeader";

interface Props {
  title: TranslationKey;
  status?: Status;
  errorMessage?: TranslationKey;
  htmlFor?: string;
  tooltip?: TooltipProps;
}

const SubSectionTitle: FC<Props> = ({
  title,
  status= "default",
  errorMessage,
  htmlFor,
  tooltip
}) => <div className="sub-section-title">
  {status === "error" && errorMessage && <ErrorHeader title={errorMessage}/>}
  <label htmlFor={htmlFor}>
    {lookupI18nString(title)}
  </label>
  {tooltip && <Tooltip {...tooltip}/>}
</div>;

export default SubSectionTitle;