import {FC} from "react";
import {Status} from "shared-components/dist/status-indicator/Status";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import InlineQuestion from "webapp/src/structure/questions/inline-question/InlineQuestion";
import InformationPanel from "shared-components/dist/information/panel/InformationPanel";
import RadioConfirmation from "shared-components/dist/form/radio-confirmation/RadioConfirmation";
import "./ConfirmationDialog.css";

interface Props {
  id: string;
  title: TranslationKey;
  subTitle?: TranslationKey;
  description?: TranslationKey;
  listDescription?: TranslationKey[];
  className?: string;
  onAnswer: (value: boolean) => void;
  hasConfirmed?: boolean;
  status?: Status;
  errorMessage?: TranslationKey;
}

const ConfirmationDialog: FC<Props> = (
  {
    id,
    title,
    subTitle,
    description,
    listDescription,
    className,
    onAnswer,
    hasConfirmed,
    status,
    errorMessage
  }
) => (
  <div className={`confirmation-panel${className ? ` ${className}` : ""}`} id={id}>
    <InformationPanel
      title={title}
      subTitle={subTitle}
      description={description}
      listDescription={listDescription}
      className={className}>
      <InlineQuestion
        id={`${id}-inline-question`}
        status={status}
        errorMessage={errorMessage}
      >
        <RadioConfirmation
          name={`${id}-radio-options`}
          selectedGroupOption={hasConfirmed}
          setSelectedGroupOption={onAnswer}
        />
      </InlineQuestion>
    </InformationPanel>
  </div>
);

export default ConfirmationDialog;