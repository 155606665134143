import {
  hasSecondaryEmploymentInvalid,
  secondaryEmploymentIndustryInvalid,
  secondaryEmploymentJobInvalid,
  secondaryEmploymentStatusInvalid,
  secondaryEmploymentWorkedInPastYearInvalid
} from "../redux/PersonalDetailsSlice";
import {AppThunkDispatch} from "../../../../../redux/Store";
import {isEmployed, isRetired, isUnemployed} from "../../shared/validation/ValidateEmploymentStatus";
import {isValidFormField} from "shared-components/dist/models/form-field/FormField";
import {PersonalDetails} from "../redux/PersonalDetails";

export function flagUnansweredSecondaryEmploymentQuestions(personalDetails: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (personalDetails.hasSecondaryEmployment.status === "default") {
    dispatch(hasSecondaryEmploymentInvalid("quote.errors.questionIncomplete"));
  }

  if (!personalDetails.secondaryEmployment.employmentStatus.isActive) return;

  if (personalDetails.secondaryEmployment.employmentStatus.status === "default") {
    dispatch(secondaryEmploymentStatusInvalid("quote.errors.questionIncomplete"));
  } else if (isEmployed(personalDetails.secondaryEmployment.employmentStatus.value)) {
    checkIndustryQuestion(personalDetails, dispatch);
    checkJobQuestion(personalDetails, dispatch);
  } else if (isRetired(personalDetails.secondaryEmployment.employmentStatus.value)) {
    checkJobQuestion(personalDetails, dispatch);
  } else if (isUnemployed(personalDetails.secondaryEmployment.employmentStatus.value)) {
    checkUnemployedQuestions(personalDetails, dispatch);
  }
}

function checkIndustryQuestion({secondaryEmployment}: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (!isValidFormField(secondaryEmployment.industry)) dispatch(secondaryEmploymentIndustryInvalid("quote.errors.questionIncomplete"));
}

function checkJobQuestion({secondaryEmployment}: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (!isValidFormField(secondaryEmployment.job)) dispatch(secondaryEmploymentJobInvalid("quote.errors.questionIncomplete"));
}

function checkUnemployedQuestions(personalDetails: PersonalDetails, dispatch: AppThunkDispatch): void {
  checkWorkedInPastYearQuestion(personalDetails, dispatch);
  if (personalDetails.secondaryEmployment.workedInPastYear.value) {
    checkJobQuestion(personalDetails, dispatch);
  }
}

function checkWorkedInPastYearQuestion({secondaryEmployment}: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (!isValidFormField(secondaryEmployment.workedInPastYear)) dispatch(secondaryEmploymentWorkedInPastYearInvalid("quote.errors.questionIncomplete"));
}