import React, {FC} from "react";
import RouterOutlet from "../../router/components/RouterOutlet";
import {ApolloProvider} from "@apollo/client";
import {apolloClient} from "../../graphql/apollo/ApolloClientProvider";
import {Provider as ReduxProvider} from "react-redux";
import {persistor, store} from "../../redux/Store";
import {PersistGate} from "redux-persist/integration/react";
import UserInactivityMonitor from "../../pages/user-authentication/user-inactivity-monitor/UserInactivityMonitor";

const App: FC = () => (
  <ReduxProvider store={store}>
    <PersistGate persistor={persistor}>
      <ApolloProvider client={apolloClient}>
        <RouterOutlet/>
        <UserInactivityMonitor/>
      </ApolloProvider>
    </PersistGate>
  </ReduxProvider>
);

export default App;
