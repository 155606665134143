import {
  isOwnerAndRegisteredKeeperInvalid,
  ownerInvalid,
  registeredKeeperInvalid
} from "../redux/VehicleDetailsSlice";
import {AppThunkDispatch} from "../../../../../redux/Store";
import {isValidFormField} from "shared-components/dist/models/form-field/FormField";
import {VehicleDetails} from "../redux/VehicleDetails";

export function flagUnansweredOwnerRegisteredKeeperQuestions(vehicleDetails: VehicleDetails, dispatch: AppThunkDispatch): void {
  if (vehicleDetails.isOwnerAndRegisteredKeeper.status === "default") {
    dispatch(isOwnerAndRegisteredKeeperInvalid("quote.errors.questionIncomplete"));
  } else {
    checkOwnerQuestion(vehicleDetails, dispatch);
    checkRegisteredKeeperQuestion(vehicleDetails, dispatch);
  }
}

function checkOwnerQuestion({owner}: VehicleDetails, dispatch: AppThunkDispatch): void {
  if (owner.isActive && !isValidFormField(owner)) dispatch(ownerInvalid("quote.errors.questionIncomplete"));
}

function checkRegisteredKeeperQuestion({registeredKeeper}: VehicleDetails, dispatch: AppThunkDispatch): void {
  if (registeredKeeper.isActive && !isValidFormField(registeredKeeper)) dispatch(registeredKeeperInvalid("quote.errors.questionIncomplete"));
}
