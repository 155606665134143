import React, {FC, useEffect, useMemo, useState} from "react";
import ClassOfUseQuestion from "../../../../shared/questions/class-of-use/ClassOfUseQuestion";
import {ClassOfUseOption} from "../../../../shared/questions/class-of-use/ClassOfUseOption";
import {additionalDriverClassOfUseChanged} from "../../redux/UpdateAdditionalDriverSlice";
import FormattedMessage from "shared-components/src/translations/components/FormattedMessage";
import {logAdditionalDriverGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {actionValueFrom} from "../../../../shared/questions/class-of-use/helpers/ClassOfUseGoogleAnalyticsAction";
import {runValidators} from "shared-components/dist/utils/validation/Validator";
import {hasValidClassOfUse} from "./validation/ValidateAdditionalDriverClassOfUse";
import {TranslationId, TranslationKey} from "shared-components/dist/translations/TranslationKey";
import QuoteJourneyStop from "../../../../shared/stops/quote-journey-stop/QuoteJourneyStop";
import {ProposedClassOfUse} from "shared/dist/generated/graphql/resolvers-types";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import DEFAULT_CLASS_OF_USE_DESCRIPTIONS from "../../../../shared/questions/class-of-use/DefaultClassOfUseDescriptions";
import {isWorkingRelationshipToProposer} from "shared/dist/class-of-use/RelationshipToProposerHelpers";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const AdditionalDriverClassOfUseQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const classOfUse = useAppSelector(state => state.updateAdditionalDriver.classOfUse);
  const relationshipToProposer = useAppSelector(state => state.updateAdditionalDriver.relationshipToProposer.value);
  const primaryEmploymentStatus = useAppSelector(state => state.updateAdditionalDriver.primaryEmployment.employmentStatus.value);
  const secondaryEmploymentStatus = useAppSelector(state => state.updateAdditionalDriver.secondaryEmployment.employmentStatus.value);

  const hasWorkRelationshipWithProposer = !!relationshipToProposer && isWorkingRelationshipToProposer(relationshipToProposer?.id);
  const [errorMessage, setErrorMessage] = useState<TranslationKey | undefined>(undefined);

  const classOfUseDescriptions = useMemo(() => {
    if (hasWorkRelationshipWithProposer) return {
      ...DEFAULT_CLASS_OF_USE_DESCRIPTIONS,
      [ProposedClassOfUse.SocialCommutingAndBusiness]: lookupI18nString("structure.questions.classOfUse.socialAndBusiness")
    };

    return DEFAULT_CLASS_OF_USE_DESCRIPTIONS;
  }, [hasWorkRelationshipWithProposer]);

  useEffect(() => {
    if (!classOfUse.value) return;

    const validationResult = runValidators(
      classOfUse.value,
      hasValidClassOfUse({
        relationshipToProposer,
        primaryEmploymentStatus,
        secondaryEmploymentStatus
      })
    );

    validationResult.passed
      ? onValidClassOfUse(classOfUse.value)
      : onInvalidClassOfUse(validationResult.errorMessage, classOfUse.value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationshipToProposer, primaryEmploymentStatus, secondaryEmploymentStatus]);

  const onClassOfUseChanged = (answer: ClassOfUseOption): void => {
    logAdditionalDriverGoogleAnalyticsEvent({
      categorySuffix: "car_use_case",
      action: actionValueFrom(answer)
    });

    const validationResult = runValidators(
      answer,
      hasValidClassOfUse({
        relationshipToProposer,
        primaryEmploymentStatus,
        secondaryEmploymentStatus
      })
    );

    validationResult.passed
      ? onValidClassOfUse(answer)
      : onInvalidClassOfUse(validationResult.errorMessage, answer);
  };

  const onValidClassOfUse = (answer: ClassOfUseOption): void => {
    setErrorMessage(undefined);
    classOfUseChangedWithCorrectDescriptionForRelationship(answer);
  };

  const onInvalidClassOfUse = (errorMessage: TranslationKey, answer: ClassOfUseOption): void => {
    setErrorMessage(errorMessage);
    classOfUseChangedWithCorrectDescriptionForRelationship(answer);
  };

  const classOfUseChangedWithCorrectDescriptionForRelationship = (answer: ClassOfUseOption): void => {
    dispatch(additionalDriverClassOfUseChanged({
      ...answer,
      description: classOfUseDescriptions[answer.id]
    }));
  };

  const getTooltipDescriptionId = (): TranslationId => (
    hasWorkRelationshipWithProposer ? "additionalDriver.classOfUseQuestion.tooltip.description.forWorkingRelationship" : "additionalDriver.classOfUseQuestion.tooltip.description"
  );

  return (
    <>
      <ClassOfUseQuestion
        id="additional-driver-class-of-use"
        title="additionalDriver.classOfUseQuestion"
        classOfUse={classOfUse}
        classOfUseChanged={onClassOfUseChanged}
        classOfUseDescriptions={classOfUseDescriptions}
        tooltip={{
          title: "additionalDriver.classOfUseQuestion.tooltip.title",
          description: (
            <FormattedMessage
              id={getTooltipDescriptionId()}
              values={{
                b: function formatBoldTags(chunks: string) {
                  return <b>{chunks}</b>;
                },
                ul: function formatUnorderedList(list: string) {
                  return <ul>{list}</ul>;
                },
                li: function formatListItem(msg: string) {
                  return <li>{msg}</li>;
                }
              }}
            />
          )
        }}
      />

      {errorMessage && (
        <QuoteJourneyStop
          stopProps={{
            visible: true,
            title: errorMessage,
          }}
        />
      )}
    </>
  );
};

export default AdditionalDriverClassOfUseQuestion;