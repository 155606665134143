import {buildCrossFieldValidator, ReduxQuestion} from "../../Questions";
import {ClassOfUseOption} from "../../../../pages/quote/vehicle/shared/questions/class-of-use/ClassOfUseOption";
import {FormField} from "shared-components/dist/models/form-field/FormField";
import {
  classOfUseChanged,
  classOfUseInvalid
} from "../../../../pages/quote/vehicle/your-details/redux/PersonalDetailsSlice";
import {primaryEmployment} from "./PrimaryEmployment";
import {isClassOfUseValid} from "../../../../pages/quote/vehicle/your-details/validation/ValidateClassOfUse";

export const classOfUse: ReduxQuestion<ClassOfUseOption, FormField<ClassOfUseOption>, ClassOfUseOption> = {
  selector: state => state.personalDetails.classOfUse,
  onChange: classOfUseChanged,
  onInvalid: classOfUseInvalid,
  crossFieldValidators: [buildCrossFieldValidator(
    state => primaryEmployment.primaryEmploymentStatus.selector(state).value,
    [isClassOfUseValid]
  )]
};