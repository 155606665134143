import React, {FC, useState} from "react";
import {Status} from "shared-components/dist/status-indicator/Status";
import DateSubQuestion
  from "../../../../../../../structure/questions/sub-question/variants/date-sub-question/DateSubQuestion";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {isNotInTheFuture, isNotOverFiveYearsAgo} from "../../../../../../../utils/validation/ClaimDateValidators";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";

interface Props {
  onChange: (value: Date | undefined) => void;
  value: Date | undefined;
  shouldFlagUnansweredQuestions: boolean;
  isAdditionalDriverForm: boolean;
}

const ClaimDateSubQuestion: FC<Props> = (
  {
    onChange,
    value,
    shouldFlagUnansweredQuestions,
    isAdditionalDriverForm
  }
) => {
  const [dateValidatorError, setDateValidatorError] = useState<TranslationKey | undefined>(undefined);

  const onComplete = (value: Date | undefined): void => {
    setDateValidatorError(undefined);
    onChange(value);
  };

  const getDateInputStatus = (): Status => {
    if (dateValidatorError || (shouldFlagUnansweredQuestions && value === undefined)) return "error";

    return "default";
  };

  return (
    <DateSubQuestion
      id="date-of-claim-sub-question"
      title={
        isAdditionalDriverForm
          ? "additionalDriver.claims.date.title"
          : "personalDetails.claims.date.title"
      }
      tooltip={getTooltip(isAdditionalDriverForm)}
      value={value}
      onComplete={onComplete}
      validators={[
        isNotInTheFuture(),
        isNotOverFiveYearsAgo()
      ]}
      onInvalid={setDateValidatorError}
      onClear={() => onChange(undefined)}
      errorMessage={dateValidatorError ?? "quote.errors.questionIncomplete"}
      status={getDateInputStatus()}
    />
  );
};

function getTooltip(isAdditionalDriverForm: boolean): TooltipProps {
  if (isAdditionalDriverForm) {
    return {
      title: "additionalDriver.claims.date.tooltip.title",
      description: "additionalDriver.claims.date.tooltip.description",
    };
  }
  return {
    title: "personalDetails.claims.date.tooltip.title",
    description: "personalDetails.claims.date.tooltip.description",
  };
}

export default ClaimDateSubQuestion;