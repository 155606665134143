import "../../QuotePriceCard.css";
import {FC} from "react";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {getBuyQuoteRequirementsDescription} from "../../../../../services/GetBuyQuoteRequirementsDescription";
import {QuoteType} from "shared/dist/generated/graphql/resolvers-types";

interface Props {
  quoteType: QuoteType
}

const QuotePriceCardRequirementsFooter: FC<Props> = ({quoteType}) => (
    <div className="quote-price__more-info">
      <h5
        className="quote-price__more-info__title">{lookupI18nString("quote.shared.buyQuoteRequirements.title")}</h5>
      <div
        className="quote-price__more-info__description">{lookupI18nString(getBuyQuoteRequirementsDescription(quoteType))}</div>
    </div>
);

export default QuotePriceCardRequirementsFooter;