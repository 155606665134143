import {ApolloError} from "@apollo/client/errors";
import {Status} from "shared-components/dist/status-indicator/Status";
import {FormField} from "shared-components/dist/models/form-field/FormField";

export function toFormStatus(formField: FormField<unknown>, loading: boolean, error: ApolloError | undefined): Status | undefined {
  if (formField.status === "error") return "error";

  if (loading) return "loading";
  if (error) return "error";
  return undefined;
}