import {FC, ReactNode, useState} from "react";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./ExpandableCard.css";
import {Add, Remove} from "@mui/icons-material";
import classNames from "classnames";

interface Props {
  title: TranslationKey;
  description: TranslationKey;
  children: ReactNode;
  className?: string;
}

const ExpandableCard: FC<Props> = ({title, description, children, className}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={classNames("expandable-card", className)}>
      <div className="expandable-card__header">
        <div className="expandable-card__header__content">
          <h5>{lookupI18nString(title)}</h5>
          <p>{lookupI18nString(description)}</p>
        </div>

        <button
          className="expandable-card__header__expander"
          onClick={() => setIsOpen(!isOpen)}
          data-testid="expandable-card__expander"
        >
          {isOpen ? <Remove/> : <Add/>}
        </button>
      </div>

      {isOpen &&
        <div className="expandable-card__content">
          {children}
        </div>
      }
    </div>
  );
};

export default ExpandableCard;