import React, {FC} from "react";
import {MARITAL_STATUS_OPTIONS, MARITAL_STATUSES, MaritalStatusOption} from "./MaritalStatusOption";
import DropdownQuestion
  from "../../../../../../structure/questions/question/variants/dropdown-question/DropdownQuestion";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import {FormField} from "shared-components/dist/models/form-field/FormField";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";

interface OwnProps {
  id: string;
  title: TranslationKey;
  tooltip: TooltipProps;
  maritalStatusField: FormField<MaritalStatusOption>;
  onComplete: (value: MaritalStatusOption) => void;
  placeholder: TranslationKey;
}

const MaritalStatusQuestion: FC<OwnProps> = (
  {
    id,
    title,
    tooltip,
    maritalStatusField,
    onComplete,
    placeholder
  }
) => {
  const onMaritalStatusChanged = (selectedMaritalStatus: string): void => {
    const maritalStatus = MARITAL_STATUSES.find(maritalStatus => maritalStatus.description === selectedMaritalStatus);

    if (maritalStatus) onComplete(maritalStatus);
  };

  return (
    <DropdownQuestion
      id={id}
      title={title}
      status={maritalStatusField.status}
      errorMessage={maritalStatusField.errorMessage}
      tooltip={tooltip}
      value={maritalStatusField.value?.description}
      onChange={onMaritalStatusChanged}
      options={MARITAL_STATUS_OPTIONS}
      placeholder={placeholder}
    />
  );
};

export default MaritalStatusQuestion;
