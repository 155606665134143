/* eslint no-console: "off", @typescript-eslint/no-explicit-any: "off" */
import {newRelic} from "../../newrelic/provider/NewRelicBrowserAgentProvider";
import {strollSessionId, strollVersionNumber} from "../../providers/Environment";

export const logger = {
  warn: console.warn,
  info: console.info,
  log: console.log,
  debug: console.debug,
  error: function(...data: any[]): void {
    const newrelic = newRelic();
    if (newrelic && data.length > 0) {
      newrelic.noticeError(getLoggedError(data), {
        "stroll-session-id": strollSessionId,
        "stroll-version": strollVersionNumber
      });
    }
    console.error(...data);
  },
};

interface StrollError extends Error {
  detail?: string;
}

function getLoggedError(data: any[]): Error {
  if (hasErrorLastArg(data)) {
    const error: StrollError = data.pop();
    if (data.length > 0) error.detail = formatArgs(data);
    return error;
  }
  return new Error(formatArgs(data));
}

function hasErrorLastArg(data: any[]): boolean {
  return data.length > 0 && data[data.length - 1] instanceof Error;
}

function formatArgs(data: any[]): string {
  return data.map(formatArg).join(" ");
}

function formatArg(arg: any): string {
  if (typeof arg === "object") {
    return JSON.stringify(arg);
  }

  return arg.toString();
}
