import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {ButtonState} from "../EditAccountSetting";
import {EditAccountSettingState} from "./EditAccountSettingState";
import {Dispatch, SetStateAction} from "react";
import {UpdateUserAttributeResult} from "../../../../../../../user/attributes/models/UpdateUserAttributeResult";
import {VerifyUserAttributeResult} from "../../../../../../../user/attributes/models/VerifyUserAttributeResult";
import {EditAccountSettingError} from "./EditAccountSettingError";
import {SendAttributeVerificationResult} from "../../../../../../../user/attributes/models/SendAttributeVerificationResult";

export function onEditAccountSettingToggled(
  stateSetter: Dispatch<SetStateAction<EditAccountSettingState>>,
  errorSetter: Dispatch<SetStateAction<EditAccountSettingError>>,
  state: EditAccountSettingState
): void {
  errorSetter("None");
  return stateSetter(state === "Closed" ? "Initialised" : "Closed");
}

export const isEditingAccountSetting = (state: EditAccountSettingState): boolean => state !== "Closed";

export function onValidAccountSetting(
  stateSetter: Dispatch<SetStateAction<EditAccountSettingState>>,
  errorSetter: Dispatch<SetStateAction<EditAccountSettingError>>,
): void {
  errorSetter("None");
  return stateSetter("Valid");
}

export function onInvalidAccountSetting(
  stateSetter: Dispatch<SetStateAction<EditAccountSettingState>>,
  errorSetter: Dispatch<SetStateAction<EditAccountSettingError>>,
  inputValueSetter: Dispatch<SetStateAction<string>>
): void {
  stateSetter("Error");
  errorSetter("InvalidInput");
  inputValueSetter("");
}

export function onAccountSettingInputChange(
  stateSetter: Dispatch<SetStateAction<EditAccountSettingState>>,
  errorSetter: Dispatch<SetStateAction<EditAccountSettingError>>,
  inputValueSetter: Dispatch<SetStateAction<string>>,
  inputValue: string,
  inputValidator: (inputValue: string) => boolean
): void {
  errorSetter("None");
  stateSetter(inputValidator(inputValue) ? "Valid" : "Incomplete");
  inputValueSetter(inputValue);
}

export const isEditAccountSettingVisible = (state: EditAccountSettingState): boolean => state !== "Closed";

export function getSendCodeButtonState(state: EditAccountSettingState): ButtonState {
  if (state === "SendingCode") return "Loading";
  if (state === "CodeSent" || state !== "Valid") return "Disabled";
  return "Default";
}

export function isEditAccountValueInvalid(
  state: EditAccountSettingState,
  error: EditAccountSettingError
): boolean {
  if (state !== "Error") return false;

  return error === "EmailAlreadyExists"
    || error === "InvalidInput"
    || error === "TooManyUpdateAttempts"
    || error === "FailedToUpdate";
}

export function getSendCodeInputErrorMessage(
  error: EditAccountSettingError,
  invalidInputErrorMessage: TranslationKey
): TranslationKey {
  switch (error) {
    case "InvalidInput":
      return invalidInputErrorMessage;
    case "EmailAlreadyExists":
      return "accountSettings.form.emailAlreadyExists";
    case "TooManyUpdateAttempts":
      return "accountSettings.form.tooManyAttempts";
    default:
      return "accountSettings.form.genericUpdateFailure";
  }
}

export function getConfirmationCodeInputErrorMessage(error: EditAccountSettingError): TranslationKey {
  switch (error) {
    case "InvalidCode":
      return "accountSettings.form.invalidConfirmationCode";
    case "CodeExpired":
      return "accountSettings.form.confirmationCodeExpired";
    case "TooManyVerifyAttempts":
      return "accountSettings.form.tooManyAttempts";
    default:
      return "accountSettings.form.genericUpdateFailure";
  }
}

export async function onSendConfirmationCode(
  inputValidator: (inputValue: string) => boolean,
  stateSetter: Dispatch<SetStateAction<EditAccountSettingState>>,
  errorSetter: Dispatch<SetStateAction<EditAccountSettingError>>,
  inputValue: string,
  updateAccountSetting: (updatedValue: string) => Promise<UpdateUserAttributeResult>,
  successfullyUpdatedCallback: () => void
): Promise<void> {
  if (!inputValidator(inputValue)) return;

  stateSetter("SendingCode");
  const updateNumberResult = await updateAccountSetting(inputValue);

  switch (updateNumberResult) {
    case "EmailAlreadyExists":
      stateSetter("Error");
      return errorSetter("EmailAlreadyExists");
    case "TooManyAttempts":
      stateSetter("Error");
      return errorSetter("TooManyUpdateAttempts");
    case "Failed":
      stateSetter("Error");
      return errorSetter("FailedToUpdate");
    default:
      errorSetter("None");
      stateSetter("CodeSent");
      successfullyUpdatedCallback();
  }
}

export async function onResendConfirmationCode(
  stateSetter: Dispatch<SetStateAction<EditAccountSettingState>>,
  errorSetter: Dispatch<SetStateAction<EditAccountSettingError>>,
  resendConfirmationCode: () => Promise<SendAttributeVerificationResult>
): Promise<void> {
  stateSetter("SendingCode");
  const sendCodeResult = await resendConfirmationCode();

  switch (sendCodeResult) {
    case "TooManyAttempts":
      stateSetter("Error");
      return errorSetter("TooManyUpdateAttempts");
    case "Failed":
      stateSetter("Error");
      return errorSetter("FailedToUpdate");
    default:
      errorSetter("None");
      stateSetter("CodeSent");
  }
}

export function shouldDisplayConfirmationCodeInput(
  state: EditAccountSettingState,
  error: EditAccountSettingError
): boolean {
  return state === "CodeSent" || state === "Verifying" || isConfirmationCodeEnteredInvalid(error);
}

export function getVerifyButtonState(state: EditAccountSettingState): ButtonState {
  return state === "Verifying" ? "Loading" : "Default";
}

export function isConfirmationCodeEnteredInvalid(error: EditAccountSettingError): boolean {
  return error === "InvalidCode"
    || error === "CodeExpired"
    || error === "TooManyVerifyAttempts"
    || error === "FailedToVerify";
}

export async function onVerifyAccountSetting(
  stateSetter: Dispatch<SetStateAction<EditAccountSettingState>>,
  errorSetter: Dispatch<SetStateAction<EditAccountSettingError>>,
  verifyAccountSetting: (verificationCode: string) => Promise<VerifyUserAttributeResult>,
  verificationCode: string,
  successfullyVerifiedCallback: () => void
): Promise<void> {
  stateSetter("Verifying");
  const verifyNumberResult = await verifyAccountSetting(verificationCode);

  switch (verifyNumberResult) {
    case "InvalidCode":
      stateSetter("Error");
      return errorSetter("InvalidCode");
    case "CodeExpired":
      stateSetter("Error");
      return errorSetter("CodeExpired");
    case "TooManyAttempts":
      stateSetter("Error");
      return errorSetter("TooManyVerifyAttempts");
    case "Failed":
      stateSetter("Error");
      return errorSetter("FailedToVerify");
    default: {
      errorSetter("None");
      stateSetter("Closed");
      successfullyVerifiedCallback();
    }
  }
}