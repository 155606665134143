import {FC} from "react";
import DepositPaymentLanding from "../../../monthly/deposit-payment/DepositPaymentLanding";
import {useAppSelector} from "../../../../../redux/Hooks";
import {PaymentFlowType} from "../../../shared/models/PaymentFlowType";
import {usePolicyQueryParams} from "../../../../../router/params/Params";
import {financedRenewalRequestSelector} from "../../../shared/redux/RenewalRequestSelector";
import {selectActiveProspectiveLoan} from "../../../../portal/renewals/redux/RenewalSelectors";
import {
  useGooglePayPurchaseFinancedRenewal
} from "../deposit-payment-processors/google-pay/GooglePayPolicyDepositPaymentProcessor";
import {FinancedPolicyPaymentResult} from "shared/dist/generated/graphql/resolvers-types";
import {
  applePayPurchaseFinancedRenewalMutation
} from "../deposit-payment-processors/apple-pay/ApplePayPolicyDepositPaymentProcessor";
import {processFinancedRenewal} from "../deposit-payment-processors/hosted-payment-page/PolicyDepositPaymentProcessor";
import {ProposedFinancedRenewalRequest} from "../../../shared/models/ProposedFinancedRenewalRequest";

const RenewalDepositPaymentLanding: FC = () => {
  const {policyId} = usePolicyQueryParams();
  const proposedRequest = useAppSelector(financedRenewalRequestSelector(policyId));
  const selectedLoan = useAppSelector(state => selectActiveProspectiveLoan(state));
  const depositPaymentAmount = selectedLoan?.firstInstalmentAmount;
  if (!proposedRequest) throw new Error("No quote information stored");

  const {
    makePayment,
    loading,
    paymentFailed,
    resetPaymentFailed
  } = useGooglePayPurchaseFinancedRenewal(proposedRequest);

  const onPaymentWithApplePay = async (payment: ApplePayJS.ApplePayPayment): Promise<FinancedPolicyPaymentResult> => {
    return await applePayPurchaseFinancedRenewalMutation(payment, proposedRequest);
  };

  return <DepositPaymentLanding
    id="renewalDirectDebitSetup"
    loading={loading}
    paymentFlowType={PaymentFlowType.RENEWAL}
    successPagePath={`/portal/policy/${policyId}/renewals/payment/success`}
    onPaymentByCard={(globalPayFormResponse, proposedRenewalRequest, threeDSecureTransactionReference) => processFinancedRenewal(globalPayFormResponse, proposedRenewalRequest as ProposedFinancedRenewalRequest, threeDSecureTransactionReference)}
    onPaymentWithApplePay={onPaymentWithApplePay}
    onPaymentWithGooglePay={makePayment}
    paymentFailed={paymentFailed}
    resetPaymentFailed={resetPaymentFailed}
    proposedRequest={proposedRequest}
    depositPaymentAmount={depositPaymentAmount}
  />;
};

export default RenewalDepositPaymentLanding;