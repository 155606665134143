import React, {FC, useEffect, useState} from "react";
import {ReactComponent as LogoPrimaryDark} from "shared-components/dist/assets/icons/logos/stroll/stroll-logo-primary-dark.svg";
import "./Navbar.css";
import MobileMenuButton from "./mobile/MobileMenuButton";
import {navbarMenuItems} from "./menu/MenuItems";
import NavigationMenu from "./menu/NavigationMenu";
import {useWindowSize} from "../../../utils/custom-hooks/UseWindowSize";
import {OptionalDebugPanel} from "../../../pages/quote/vehicle/shared/debug-panel/OptionalDebugPanel";

const MOBILE_MAX_WIDTH_SIZE_IN_PX = 575;

const Navbar: FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const {width} = useWindowSize();

  useEffect(() => {
    if (width > MOBILE_MAX_WIDTH_SIZE_IN_PX) setIsMobileMenuOpen(false);
  }, [width]);

  return (
    <>
      <OptionalDebugPanel/>
      <div className={`navbar-header${isMobileMenuOpen ? " navbar-header__mobile-menu-open" : ""}`}>
        <div className="navbar-header__container">
          <div className="navbar-header__container__logo">
            <a href="/portal">
              <LogoPrimaryDark/>
            </a>
          </div>
          <div className="navbar-header__container__menu">
            <div className="navbar-header__container__menu__mobile-menu-button">
              <MobileMenuButton
                showCloseIcon={isMobileMenuOpen}
                menuButtonToggled={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              />
            </div>
            {isMobileMenuOpen ? null : (
              <div className="navbar-header__container__menu__desktop">
                <NavigationMenu menuItems={navbarMenuItems} googleAnalyticsPrefix="portal"/>
              </div>
            )}
          </div>
        </div>
      </div>
      {isMobileMenuOpen && (
        <div className="mobile-navigation-menu">
          <NavigationMenu menuItems={navbarMenuItems} googleAnalyticsPrefix="portal"/>
        </div>
      )}
    </>
  );
};

export default Navbar;
