import React, {FC, ReactElement, useState} from "react";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {useLocation, useNavigate} from "react-router-dom";
import {currentQuoteQueryArgsSelector} from "../../../../your-quote/redux/selectors/QuoteDetailsSelectors";
import {Maybe} from "shared/dist/generated/graphql/resolvers-types";
import EditCarDetailsModal from "../edit-car-details-modal/EditCarDetailsModal";
import TranslatableKeyItemList from "shared-components/dist/lists/key-item-list/TranslatableKeyItemList";
import Button from "shared-components/dist/buttons//button/Button";
import {buildQuoteRoute} from "../../../../../../../utils/navigation/NavigationHelpers";
import {YOUR_CAR} from "../../../../../../../router/models/Routes";
import {logYourQuoteGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {isElectricVehicleByFuelType} from "../../../../../../../utils/validation/IsElectricVehicleByFuelType";
import {Status} from "shared-components/dist/status-indicator/Status";
import "./CarDetailsList.css";
import {useAppSelector} from "../../../../../../../redux/Hooks";
import {useIsEditFlowEnabledForQuote} from "../../../../your-quote/hooks/IsEditFlowEnabledForQuote";

function booleanToYesNo(value: boolean | undefined): string {
  if (value === undefined) return "";

  return value
    ? lookupI18nString("general.boolean.yes")
    : lookupI18nString("general.boolean.no");
}

const CarDetailsList: FC = () => {
  const vehicle = useAppSelector(state => currentQuoteQueryArgsSelector(state).vehicle);
  const hasModifications = useAppSelector(state => state.vehicleDetails.modifications.value.hasModifications);
  const hasSubmitBeenClicked = useAppSelector(state => state.quoteDetails.hasSubmitBeenClicked);
  const isQuoteLoading = useAppSelector(state => state.quoteDetails.quoteLoading);
  const isEditFlowEnabled = useIsEditFlowEnabledForQuote();

  const [isEditCarDetailsModalVisible, setIsEditCarDetailsModalVisible] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  function formatEngineSize(engineCapacityLitres: string | undefined): string {
    if (!engineCapacityLitres) return "";

    const engineSize = engineCapacityLitres.replace("L", "").trim();
    return `${engineSize} L`;
  }

  const showModalButton = (categorySuffix: "registration_number" | "date_of_purchase", status: Status): ReactElement => (
    <Button
      variant="link"
      onClick={() => {
        logYourQuoteGoogleAnalyticsEvent({
          categorySuffix: `your_car.${categorySuffix}.add_details`,
          action: "Click"
        });
        setIsEditCarDetailsModalVisible(true);
      }}
      disabled={isQuoteLoading}
      className={`car-details-list__add-details-button${status === "error" ? "--error" : ""}`}
    >
      {lookupI18nString("quoteDetails.summary.yourCar.addDetails")}
    </Button>
  );

  const getStatus = (value: Maybe<string> | undefined): Status => {
    return value
      ? "hidden"
      : hasSubmitBeenClicked ? "error" : "default";
  };

  const registrationNumberRowStatus = getStatus(vehicle.registration);
  const dateOfPurchaseRowStatus = getStatus(vehicle.dateOfPurchase);

  return (
    <>
      {vehicle && (
        <TranslatableKeyItemList rows={[
          {
            keyValue: "quoteDetails.summary.yourCar.registrationNumber",
            item: vehicle.registration ?? showModalButton("registration_number", registrationNumberRowStatus),
            status: registrationNumberRowStatus
          },
          {
            keyValue: "quoteDetails.summary.yourCar.dateOfPurchase",
            item: vehicle.dateOfPurchase ?? showModalButton("date_of_purchase", dateOfPurchaseRowStatus),
            status: dateOfPurchaseRowStatus
          },
          {keyValue: "quoteDetails.summary.yourCar.make", item: vehicle.make},
          {keyValue: "quoteDetails.summary.yourCar.model", item: vehicle.model},
          {keyValue: "quoteDetails.summary.yourCar.fuel", item: vehicle.fuelType},
          {keyValue: "quoteDetails.summary.yourCar.transmission", item: vehicle.transmissionType},
          {keyValue: "quoteDetails.summary.yourCar.yearOfReg", item: vehicle.yearOfManufacture},
          {
            keyValue: "quoteDetails.summary.yourCar.engineSize",
            item: formatEngineSize(vehicle.engineCapacityLitres ?? undefined),
            isVisible: !!vehicle?.engineCapacityLitres && !isElectricVehicleByFuelType(vehicle.fuelType)
          },
          {keyValue: "quoteDetails.summary.yourCar.trimLevel", item: vehicle.trim ?? undefined},
          {keyValue: "quoteDetails.summary.yourCar.driverPosition", item: vehicle.driverPosition ?? undefined},
          {keyValue: "quoteDetails.summary.yourCar.seats", item: vehicle.seats},
          {
            keyValue: "quoteDetails.summary.yourCar.hasTracker",
            item: booleanToYesNo(vehicle.hasTracker)
          },
          {keyValue: "quoteDetails.summary.yourCar.alarm", item: ""},
          {keyValue: "quoteDetails.summary.yourCar.imported", item: booleanToYesNo(vehicle.imported ?? undefined)},
          {keyValue: "quoteDetails.summary.yourCar.modifications", item: booleanToYesNo(hasModifications)},
          {
            keyValue: "quoteDetails.summary.yourCar.estimatedValue",
            item: vehicle.estimatedValue && `£${vehicle.estimatedValue}`
          },
        ]}/>
      )}

      <EditCarDetailsModal
        visible={isEditCarDetailsModalVisible}
        onDismiss={() => setIsEditCarDetailsModalVisible(false)}
      />

      {isEditFlowEnabled &&
        <Button
          className="quote-summary-edit-button"
          variant="ghost"
          onClick={() => {
            logYourQuoteGoogleAnalyticsEvent({
              categorySuffix: "your_car.edit",
              action: "Click"
            });
            navigate(buildQuoteRoute(location.pathname, YOUR_CAR));
          }}
        >
          {lookupI18nString("quoteDetails.summary.editButton")}
        </Button>
      }
    </>
  );
};

export default CarDetailsList;
