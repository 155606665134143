import React, {FC} from "react";
import {Check} from "@mui/icons-material";
import "./RenewalDetailsConfirmationTableHeader.css";

interface Props {
  title: string;
  confirmed: boolean;
}

const RenewalDetailsConfirmationTableHeader: FC<Props> = ({title, confirmed}) => (
  <div className="renewal-details-confirmation-table-header">
    <h2 data-testid="renewal-details-confirmation-table-header__title">{title}</h2>

    {confirmed &&
      <div
        data-testid="renewal-details-confirmation-table-header__icon"
        className="renewal-details-confirmation-table-header__icon-container"
      >
        <Check className="renewal-details-confirmation-table-header__icon-container__check"/>
      </div>
    }
  </div>
);
export default RenewalDetailsConfirmationTableHeader;