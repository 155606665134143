import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../../../../../../../redux/Store";
import {VehicleHolderOption} from "../../../../../../../utils/constants/VehicleHolderOption";

const ownerSelector = (state: RootState): VehicleHolderOption | undefined => state.vehicleDetails.owner.value;
const registeredKeeperSelector = (state: RootState): VehicleHolderOption | undefined => state.vehicleDetails.registeredKeeper.value;

export const ownerStopVisibleSelector = createSelector(
  ownerSelector,
  (owner: VehicleHolderOption | undefined): boolean => {
    return owner !== undefined && owner.description === "Other";
  }
);

export const registeredKeeperStopVisibleSelector = createSelector(
  registeredKeeperSelector,
  (registeredKeeper: VehicleHolderOption | undefined): boolean => {
    return registeredKeeper !== undefined && registeredKeeper.description === "Other";
  }
);