import React, {FC} from "react";
import {AFFECTED_NCB, AFFECTED_NCB_OPTIONS, NoClaimsAffectedOption} from "../models/NoClaimsAffectedOption";
import {StringRadioColumnSubQuestion} from "../../../../../../../structure/questions/sub-question/variants/radio-column-sub-question/variants/StringRadioColumnSubQuestion";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import {ClaimsFormSubQuestionProps} from "./ClaimsFormSubQuestionProps";

type Props = ClaimsFormSubQuestionProps<NoClaimsAffectedOption>;

const NoClaimsAffectedSubQuestion: FC<Props> = (
  {
    value,
    onChange,
    status,
    isAdditionalDriverForm
  }
) => {
  const title = isAdditionalDriverForm
    ? "additionalDriver.claims.noClaimsAffected.title"
    : "personalDetails.claims.noClaimsAffected.title";

  const onAffectedNoClaimsBonusSelection = (selection: string): void => {
    const selectedAffectOnNoClaims = AFFECTED_NCB.find(option => option.description === selection);
    if (!selectedAffectOnNoClaims) return;

    onChange(selectedAffectOnNoClaims);
  };

  return (
    <StringRadioColumnSubQuestion
      subQuestionProps={{
        id: "affected-no-claims-bonus-sub-question",
        errorMessage: "quote.errors.questionIncomplete",
        title,
        tooltip: getTooltip(isAdditionalDriverForm),
        status
      }}
      radioColumnProps={{
        name: "affected-no-claims-bonus-radio-column",
        selectedOption: value?.description,
        options: AFFECTED_NCB_OPTIONS,
        onSelection: onAffectedNoClaimsBonusSelection
      }}
    />
  );
};

function getTooltip(isAdditionalDriverForm: boolean): TooltipProps {
  if (isAdditionalDriverForm) {
    return {
      title: "additionalDriver.claims.noClaimsAffected.tooltip.title",
      description: "additionalDriver.claims.noClaimsAffected.tooltip.description",
    };
  }

  return {
    title: "personalDetails.claims.noClaimsAffected.tooltip.title",
    description: "personalDetails.claims.noClaimsAffected.tooltip.description",
  };
}

export default NoClaimsAffectedSubQuestion;