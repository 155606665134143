import {ClassOfUseOption} from "../questions/class-of-use/ClassOfUseOption";
import {ProposedClassOfUse} from "shared/dist/generated/graphql/resolvers-types";
import {averageBusinessMileageIsActiveSet} from "../../your-cover/redux/CoverDetailsSlice";
import {createAppAsyncThunk} from "../../../../../redux/CreateAppAsyncThunk";

export const updateBusinessMileageQuestionVisibility = createAppAsyncThunk(
  "personalDetailsSlice/updateBusinessMileageQuestionVisibility",
  (proposerClassOfUse: ClassOfUseOption | undefined, {dispatch, getState}) => {
    const additionalDrivers = getState().personalDetails.additionalDrivers;

    const shouldShowAverageBusinessMileageQuestion = doesAnyDriverHaveBusinessUse(
      proposerClassOfUse?.id,
      additionalDrivers.map(driver => driver.classOfUse.id)
    );

    dispatch(averageBusinessMileageIsActiveSet(shouldShowAverageBusinessMileageQuestion));
  }
);

export const doesAnyDriverHaveBusinessUse = (
  proposerClassOfUse: ProposedClassOfUse | undefined,
  additionalDriversClassOfUses: ProposedClassOfUse[] | undefined
): boolean => (
  [
    ...(additionalDriversClassOfUses ?? []),
    proposerClassOfUse
  ].includes(ProposedClassOfUse.SocialCommutingAndBusiness)
);