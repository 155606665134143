import {CognitoUser, CognitoUserSession} from "amazon-cognito-identity-js";
import {SessionState} from "./SessionState";
import {userPool} from "../../providers/CognitoProviders";
import {logger} from "../../../utils/logging/Logger";

export async function getCurrentSessionState(): Promise<SessionState> {
  try {
    const session = await getCurrentAvailableSession();
    return session.isValid() ? "Valid" : "Invalid";
  } catch (error) {
    logger.debug("Unable to check if current session is valid", error);
    return "Invalid";
  }
}

export async function getCurrentAvailableSession(cognitoUser?: CognitoUser): Promise<CognitoUserSession> {
  return new Promise<CognitoUserSession>((resolve, reject) => {
    const currentUser = cognitoUser ?? userPool.getCurrentUser();
    if (!currentUser) {
      reject(Error("No current user found"));
      return;
    }

    currentUser.getSession((error: Error | null, session: CognitoUserSession) => {
      if (error) { reject(error); return; }
      resolve(session);
    });
  });
}
