import React, {Dispatch, FC, ReactNode, SetStateAction} from "react";
import "./RenewalsConfirmYourClaims.css";
import RenewalDetailsConfirmationTableHeader from "../table-items/RenewalDetailsConfirmationTableHeader";
import RenewalDetailsConfirmationButtonFooter from "../table-items/RenewalDetailsConfirmationButtonFooter";
import RenewalDetailsConfirmationTableItem, {
  RenewalDetailsConfirmationTableItemProps
} from "../table-items/RenewalDetailsConfirmationTableItem";
import RenewalDetailsConfirmationTableSection
  from "../table-items/RenewalDetailsConfirmationTableSection";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {AtFault, PolicyClaim} from "shared/dist/generated/graphql/resolvers-types";
import {CurrencyFormat} from "../../../../../utils/money/CurrencyFormat";
import {format} from "date-fns";
import {formatClaimDescription} from "../utils/Formatters";

interface Props {
  confirmed: boolean;
  setConfirmed: Dispatch<SetStateAction<boolean>>;
  setEditModalVisible: Dispatch<SetStateAction<boolean>>;
  claims: PolicyClaim[];
  previousSectionConfirmed: boolean;
}

const RenewalsConfirmYourClaims: FC<Props> = ({confirmed, setConfirmed, setEditModalVisible, claims, previousSectionConfirmed}) => {
  const hasClaims = claims ? claims.length > 0 : false;
  const items = hasClaims ? getStructuredClaims(claims) : getEmptyState();

  return (
    <div
      className={confirmed ? "renewals-confirm-your-claims--confirmed" : `renewals-confirm-your-claims${previousSectionConfirmed ? "" : "--disabled"} unconfirmed`}
      data-testid="renewals-confirm-your-claims"
    >
      <RenewalDetailsConfirmationTableHeader
        title={lookupI18nString("portal.renewals.detailConfirmation.yourClaims")}
        confirmed={confirmed}
      />
      {items}
      <RenewalDetailsConfirmationButtonFooter
        testId={"your-claims"}
        confirmed={confirmed}
        setConfirmed={setConfirmed}
        setEditModalVisible={setEditModalVisible}
        disabled={!previousSectionConfirmed}
      />
    </div>
  );
};

function getStructuredClaims(claims: PolicyClaim[]): ReactNode {
  return claims.map((claim, index) => {
    const claimItems = formatClaimItems(claim);

    return (
      <RenewalDetailsConfirmationTableSection
        key={index}
        title={lookupI18nString({
          id: "portal.renewals.detailConfirmation.yourClaims.tableItem.title",
          templateVariables: {
            count: index + 1,
            max: claims.length
          }
        })}
        subtitle={format(new Date(claim.date), "dd/MM/yyyy")}
      >
        {claimItems.map((item, key) => <RenewalDetailsConfirmationTableItem key={key} testId={item.testId} title={item.title} subtitle={item.subtitle}/>)}
      </RenewalDetailsConfirmationTableSection>
    );
  });
}

function formatClaimItems(claim: PolicyClaim): RenewalDetailsConfirmationTableItemProps[] {
  const claimItems = [
    {
      testId: "claim-settled",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourClaims.settled"),
      subtitle: claim.atFault === AtFault.Pending ? "Pending" : "Settled"
    },
    {
      testId: "claim-type",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourClaims.type"),
      subtitle: claim.incidentType
    },
    {
      testId: "claim-at-fault",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourClaims.fault"),
      subtitle: formatAtFault(claim.atFault)
    },
  ];

  if (claim.accidentDescription) {
    claimItems.push({
      testId: "claim-description",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourClaims.description"),
      subtitle: formatClaimDescription(claim.accidentDescription)
    });
  }

  claimItems.push(
    {
      testId: "claim-policy",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourClaims.policy"),
      subtitle: claim.whichPolicyClaimedAgainst
    }
  );

  if (claim.noClaimsAffected) {
    claimItems.push(
      {
        testId: "claim-ncb-affected",
        title: lookupI18nString("portal.renewals.detailConfirmation.yourClaims.ncbAffected"),
        subtitle: claim.noClaimsAffected
      }
    );
  }

  claimItems.push(
    {
      testId: "claim-injuries",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourClaims.injuries"),
      subtitle: claim.bodilyInjuryOccurred ? lookupI18nString("general.boolean.yes") : lookupI18nString("general.boolean.no")
    },
    {
      testId: "claim-cost",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourClaims.cost"),
      subtitle: CurrencyFormat.formatStringAmountWithSymbol(claim.value.toString())
    }
  );

  return claimItems;
}

function formatAtFault(atFault: AtFault): string {
  switch (atFault) {
    case AtFault.NoBlame:
      return lookupI18nString("portal.renewals.detailConfirmation.yourClaims.atFault.noBlame");
    case AtFault.Partial:
      return lookupI18nString("portal.renewals.detailConfirmation.yourClaims.atFault.partial");
    case AtFault.Pending:
      return lookupI18nString("portal.renewals.detailConfirmation.yourClaims.atFault.pending");
    case AtFault.Yes:
      return lookupI18nString("portal.renewals.detailConfirmation.yourClaims.atFault.yes");
  }
}

function getEmptyState(): ReactNode {
  return (
    <RenewalDetailsConfirmationTableItem
      testId={"your-claims-empty-state"}
      title={lookupI18nString("portal.renewals.detailConfirmation.yourClaims.emptyState.title")}
      subtitle={lookupI18nString("portal.renewals.detailConfirmation.yourClaims.emptyState.subtitle")}
    />
  );
}

export default RenewalsConfirmYourClaims;
