import React, {FC} from "react";
import "./PaymentSuccessHeader.css";
import {ReactComponent as LogoWhite} from "shared-components/dist/assets/icons/logos/stroll/stroll-logo-white.svg";

const PaymentSuccessHeader: FC = () => (
  <div className="payment-success-header">
    <div className="payment-success-header__container">
      <div className="payment-success-header__container__logo">
        <LogoWhite/>
      </div>
    </div>
  </div>
);

export default PaymentSuccessHeader;