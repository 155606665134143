import {AppThunk} from "../../../../../../../redux/Store";
import {additionalDriverAdded} from "../../../../../../../redux/Actions";
import {mapToSerializableAdditionalDriver} from "./mappers/AdditionalDriverMapper";
import {updateBusinessMileageQuestionVisibility} from "../../../../shared/redux/ShouldShowBusinessMileageQuestion";

export const addAdditionalDriver = (): AppThunk => async (dispatch, getState) => {
  const additionalDriverForm = await getState().updateAdditionalDriver;
  const additionalDriver = mapToSerializableAdditionalDriver(additionalDriverForm);

  dispatch(additionalDriverAdded(additionalDriver));

  const state = getState();

  dispatch(updateBusinessMileageQuestionVisibility(
    state.personalDetails.classOfUse.value
  ));
};