import React, {FC} from "react";
import {Conviction} from "../models/Conviction";
import {toGregorianFormat} from "shared/dist/stdlib/DateFormat";

interface Props {
  conviction: Conviction;
}

const ConvictionItem: FC<Props> = ({conviction}) => (
  <>
    <p>{conviction.type.parentCategoryDescription}</p>
    <p>{toGregorianFormat(conviction.date)}</p>
  </>
);

export default ConvictionItem;