import {ReduxQuestion} from "../../Questions";
import {
  firstNameChanged,
  firstNameInvalid
} from "../../../../pages/quote/vehicle/additional-driver/shared/redux/UpdateAdditionalDriverSlice";
import {hasValidName} from "shared-components/dist/form/name-input-field/validation/NameValidator";

export const firstName: ReduxQuestion<string> = {
  selector: state => state.updateAdditionalDriver.firstName,
  onChange: firstNameChanged,
  onInvalid: firstNameInvalid,
  validators: [
    hasValidName("additionalDriver.firstNameQuestion.errors.invalidEntry")
  ]
};