import {newRelic} from "./provider/NewRelicBrowserAgentProvider";
import {getCurrentPolicyHolderId} from "../user/authentication/services/GetCurrentPolicyHolderId";

export async function noticeError(error: Error | string, customAttributes?: { [key: string]: string | number }): Promise<void> {
  const newRelicBrowserAgent = newRelic();
  if (!newRelicBrowserAgent) return;

  const policyHolderId = await getCurrentPolicyHolderId();

  newRelicBrowserAgent.noticeError(error, {
    ...customAttributes,
    policyHolderId: policyHolderId ?? "unknown"
  });
}
