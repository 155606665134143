import React, {FC} from "react";
import {FormField} from "shared-components/dist/models/form-field/FormField";
import {StringRadioColumnQuestion} from "../../../../../../structure/questions/question/variants/radio-column-question/StringRadioColumnQuestion";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {LICENCE_TYPE_OPTIONS, LICENCE_TYPES, LicenceTypeOption} from "./LicenceTypeOption";

interface OwnProps {
  id: string;
  title: TranslationKey;
  licenceTypeField: FormField<LicenceTypeOption>;
  licenceTypeChanged: (licenceType: LicenceTypeOption) => void;
}

const LicenceTypeQuestion: FC<OwnProps> = (
  {
    id,
    title,
    licenceTypeField,
    licenceTypeChanged
  }
) => {
  const onLicenceTypeSelected = (licenceTypeSelection: string): void => {
    const licenceType = LICENCE_TYPES.find(type => type.description === licenceTypeSelection);

    if (licenceType) licenceTypeChanged(licenceType);
  };

  return (
    <StringRadioColumnQuestion
      questionProps={{
        id: id,
        title: title,
        status: licenceTypeField.status,
        errorMessage: licenceTypeField.errorMessage
      }}
      radioColumnProps={{
        options: LICENCE_TYPE_OPTIONS,
        name: "licence-type-select",
        selectedOption: licenceTypeField.value?.description,
        onSelection: onLicenceTypeSelected
      }}
    />
  );
};

export default LicenceTypeQuestion;
