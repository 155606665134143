import {Conviction, Convictions} from "../models/Conviction";
import {DRINK_DRIVING} from "./IsDrinkDrivingConvictionType";
import {DRIVING_WHILE_USING_MOBILE_PHONE_PARENT_CATEGORY} from "./IsMobilePhoneConvictionType";
import {OTHER} from "./ConvictionTypeShouldStop";

export function isValidConviction(conviction: Partial<Conviction>): conviction is Conviction {
  if (conviction.type === undefined) return false;

  switch (conviction.type.parentCategory) {
    case OTHER:
      return false;
    case DRINK_DRIVING:
      return isValidDrinkDrivingOffence(conviction);
    case DRIVING_WHILE_USING_MOBILE_PHONE_PARENT_CATEGORY:
      return hasRequiredConvictionFields(conviction);
    default:
      return isValidOffence(conviction);
  }
}

function isValidDrinkDrivingOffence(conviction: Partial<Conviction>): boolean {
  return conviction.testMethod !== undefined && conviction.alcoholLevel !== undefined
    && isValidOffence(conviction);
}

function isValidOffence(conviction: Partial<Conviction>): boolean {
  return conviction.offence !== undefined && hasRequiredConvictionFields(conviction);
}

function hasRequiredConvictionFields(conviction: Partial<Conviction>): conviction is Conviction {
  return conviction.date !== undefined &&
    conviction.penaltyPoints !== undefined &&
    conviction.fine !== undefined &&
    conviction.drivingBan !== undefined;
}

export function isValidConvictionsList(convictions: Convictions): boolean {
  if (!convictions.hasConvictions) return true;

  return convictions.convictions.length !== 0 && !convictions.isFormVisible;
}