import React, {FC} from "react";
import {Policy} from "shared/dist/generated/graphql/resolvers-types";
import TranslatableKeyItemList, {TranslatableKeyItemRowProps} from "shared-components/dist/lists/key-item-list/TranslatableKeyItemList";
import {isElectricVehicleByFuelType} from "../../../../../../utils/validation/IsElectricVehicleByFuelType";
import {format} from "date-fns";

export interface YourCarDetailsProps {
  policy: Policy;
}

const YourCarDetails: FC<YourCarDetailsProps> = ({policy}) => (
  <TranslatableKeyItemList rows={getPolicyHolderVehicleDetailsList(policy)}/>
);

function getPolicyHolderVehicleDetailsList(policy: Policy): TranslatableKeyItemRowProps[] {
  return [
    {
      keyValue: "policies.mainContent.yourCar.make",
      item: policy.vehicleDetails.registeredVehicle.make
    },
    {
      keyValue: "policies.mainContent.yourCar.model",
      item: policy.vehicleDetails.registeredVehicle.model
    },
    {
      keyValue: "policies.mainContent.yourCar.fuel",
      item: policy.vehicleDetails.registeredVehicle.fuelType
    },
    {
      keyValue: "policies.mainContent.yourCar.transmission",
      item: policy.vehicleDetails.registeredVehicle.transmissionType
    },
    {
      keyValue: "policies.mainContent.yourCar.yearOfRegistration",
      item: format(new Date(policy.vehicleDetails.registeredVehicle.yearOfManufacture), "yyyy")
    },
    {
      keyValue: "policies.mainContent.yourCar.engineSize",
      item: policy.vehicleDetails.registeredVehicle?.engineCapacityLitres ?? "",
      isVisible: !!policy.vehicleDetails.registeredVehicle?.engineCapacityLitres && !isElectricVehicleByFuelType(policy.vehicleDetails.registeredVehicle.fuelType)
    },
    {
      keyValue: "policies.mainContent.yourCar.trimLevel",
      item: policy.vehicleDetails.registeredVehicle.trim
    },
  ];
}

export default YourCarDetails;
