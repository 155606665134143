import React, {FC, FormEvent, useState} from "react";
import SubQuestionForm from "../../../../../../../structure/questions/sub-question-form/SubQuestionForm";
import {MultipleItemsQuestionSubFormProps} from "../../../../../../../structure/questions/multiple-items-question/MultipleItemsQuestion";
import IntegerInputSubQuestion
  from "../../../../../../../structure/questions/sub-question/variants/integer-input-sub-question/IntegerInputSubQuestion";
import BooleanSubQuestion
  from "../../../../../../../structure/questions/sub-question/variants/boolean-sub-question/BooleanSubQuestion";
import {Conviction} from "../models/Conviction";
import {isValidConviction} from "../validation/ValidConviction";
import {convictionTypeShouldStop, OTHER} from "../validation/ConvictionTypeShouldStop";
import ConvictionTypeSubQuestion from "./ConvictionTypeSubQuestion";
import ConvictionDateSubQuestion from "./ConvictionDateSubQuestion";
import CurrencyInputSubQuestion
  from "../../../../../../../structure/questions/sub-question/variants/currency-input-sub-question/CurrencyInputSubQuestion";
import DurationSubQuestion
  from "../../../../../../../structure/questions/sub-question/variants/duration-sub-question/DurationSubQuestion";
import {useDurationDropdownProps} from "shared-components/dist/form/duration-dropdown/hooks/useDurationDropdownProps";
import {MAX_DRIVING_BAN_YEARS_OPTION} from "../constants/DrivingBanDurations";
import ConvictionOffenceSubQuestion from "./offence-sub-questions/ConvictionOffenceSubQuestion";
import DrinkDrivingSubQuestions from "./DrinkDrivingSubQuestions";
import {getMultipleItemsSubQuestionStatus} from "../../../../../../../structure/questions/multiple-items-question/helpers/SubQuestionStatus";
import OtherConvictionTypeStop from "./stops/OtherConvictionTypeStop";
import {useScrollToError} from "../../../../../../../utils/validation/hooks/ScrollToError";

interface Props extends MultipleItemsQuestionSubFormProps {
  convictionAdded: (conviction: Conviction) => void;
  shouldFlagUnansweredQuestions: boolean;
  flagUnansweredQuestions: () => void;
  isAdditionalDriverForm: boolean;
}

const ConvictionsSubQuestionForm: FC<Props> = (
  {
    formRef,
    setIsFormValid,
    convictionAdded,
    shouldFlagUnansweredQuestions,
    flagUnansweredQuestions,
    isAdditionalDriverForm
  }
) => {
  const [conviction, setConviction] = useState<Partial<Conviction>>({});
  const [isOtherConvictionTypeStop, setIsOtherConvictionTypeStop] = useState(false);
  const [isOtherOffenceTypeStop, setIsOtherOffenceTypeStop] = useState(false);
  const [receivedPenaltyPoints, setReceivedPenaltyPoints] = useState<boolean | undefined>(undefined);
  const [receivedFine, setReceivedFine] = useState<boolean | undefined>(undefined);
  const [receivedDrivingBan, setReceivedDrivingBan] = useState<boolean | undefined>(undefined);
  const {scrollToError} = useScrollToError({shouldForceScroll: true});

  const subQuestionId = "driving-ban-duration-sub-question";
  const {yearsDropdown, monthsDropdown} = useDurationDropdownProps({
    id: subQuestionId,
    duration: conviction.drivingBan,
    onChange: value => updateConviction({drivingBan: value}),
    maxYear: MAX_DRIVING_BAN_YEARS_OPTION
  });

  const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (isValidConviction(conviction)) {
      convictionAdded(conviction);
      setIsFormValid?.(false);
    } else {
      flagUnansweredQuestions();
      scrollToError();
    }
  };

  function onConvictionChange(partialConviction: Partial<Conviction>): void {
    setIsOtherOffenceTypeStop(false);
    setIsOtherConvictionTypeStop(convictionTypeShouldStop(partialConviction));
    updateConviction(partialConviction);
  }

  function onOffenceChange(partialConviction: Partial<Conviction>): void {
    if (isOtherConvictionTypeStop) {
      setIsOtherOffenceTypeStop(false);
      return;
    }
    setIsOtherOffenceTypeStop(partialConviction.offence?.id === OTHER);
    updateConviction(partialConviction);
  }

  const updateConviction = (partialConviction: Partial<Conviction>): void => {
    const updatedConviction = {...conviction, ...partialConviction};
    setConviction(updatedConviction);

    if (isValidConviction(updatedConviction)) return setIsFormValid?.(true);

    setIsFormValid?.(false);
  };

  return (
    <SubQuestionForm formRef={formRef} onSubmit={onSubmit}>
      <ConvictionTypeSubQuestion
        value={conviction.type}
        onChange={value => onConvictionChange({
          type: value,
          offence: undefined,
          alcoholLevel: undefined,
          testMethod: undefined
        })}
        status={getMultipleItemsSubQuestionStatus(shouldFlagUnansweredQuestions, conviction.type)}
        isAdditionalDriverForm={isAdditionalDriverForm}
      />
      <OtherConvictionTypeStop isVisible={isOtherConvictionTypeStop}/>

      <ConvictionOffenceSubQuestion
        conviction={conviction}
        onChange={value => onOffenceChange({offence: value})}
        status={getMultipleItemsSubQuestionStatus(shouldFlagUnansweredQuestions, conviction.offence)}
        isAdditionalDriverForm={isAdditionalDriverForm}
      />

      <OtherConvictionTypeStop isVisible={isOtherOffenceTypeStop}/>

      <DrinkDrivingSubQuestions
        conviction={conviction}
        onAlcoholTestMethodChanged={value => updateConviction({testMethod: value})}
        alcoholTestMethodStatus={getMultipleItemsSubQuestionStatus(shouldFlagUnansweredQuestions, conviction.testMethod)}
        onAlcoholLevelRecordedChanged={value => updateConviction({alcoholLevel: value})}
        alcoholLevelRecordedStatus={getMultipleItemsSubQuestionStatus(shouldFlagUnansweredQuestions, conviction.alcoholLevel)}
        isAdditionalDriverForm={isAdditionalDriverForm}
      />

      <ConvictionDateSubQuestion
        value={conviction.date}
        onChange={value => updateConviction({date: value})}
        shouldFlagUnansweredQuestions={shouldFlagUnansweredQuestions}
        isAdditionalDriverForm={isAdditionalDriverForm}
      />

      <BooleanSubQuestion
        id="received-penalty-points-sub-question"
        title={
          isAdditionalDriverForm
            ? "additionalDriver.convictionsQuestions.subQuestion.receivedPenaltyPoints.title"
            : "personalDetails.convictionsQuestions.subQuestion.receivedPenaltyPoints.title"
        }
        value={receivedPenaltyPoints}
        onAnswer={value => {
          setReceivedPenaltyPoints(value);
          if (value) {
            updateConviction({penaltyPoints: undefined});
          } else {
            updateConviction({penaltyPoints: 0});
          }
        }}
        status={getMultipleItemsSubQuestionStatus(shouldFlagUnansweredQuestions, conviction.penaltyPoints)}
        errorMessage="quote.errors.questionIncomplete"
      />

      {receivedPenaltyPoints &&
        <IntegerInputSubQuestion
          id="conviction-penalty-points"
          title={
            isAdditionalDriverForm
              ? "additionalDriver.convictionsQuestions.subQuestion.convictionPenaltyPoints.title"
              : "personalDetails.convictionsQuestions.subQuestion.convictionPenaltyPoints.title"
          }
          value={conviction.penaltyPoints}
          onAnswer={value => updateConviction({penaltyPoints: value})}
          errorMessage="quote.errors.questionIncomplete"
          placeholder="personalDetails.convictionsQuestions.subQuestion.convictionPenaltyPoints.placeholder"
        />
      }

      <BooleanSubQuestion
        id="received-fine-sub-question"
        title={
          isAdditionalDriverForm
            ? "additionalDriver.convictionsQuestions.subQuestion.receivedFine.title"
            : "personalDetails.convictionsQuestions.subQuestion.receivedFine.title"
        }
        value={receivedFine}
        onAnswer={value => {
          setReceivedFine(value);
          if (value) {
            updateConviction({fine: undefined});
          } else {
            updateConviction({fine: 0});
          }
        }}
        status={getMultipleItemsSubQuestionStatus(shouldFlagUnansweredQuestions, conviction.fine)}
        errorMessage="quote.errors.questionIncomplete"
      />

      {receivedFine &&
        <CurrencyInputSubQuestion
          id="fine-amount-sub-question"
          title={
            isAdditionalDriverForm
              ? "additionalDriver.convictionsQuestions.subQuestion.fineAmount.title"
              : "personalDetails.convictionsQuestions.subQuestion.fineAmount.title"
          }
          value={conviction.fine}
          onAnswer={value => updateConviction({fine: value})}
          step={20}
          placeholder="personalDetails.convictionsQuestions.subQuestion.fineAmount.title"
        />
      }

      <BooleanSubQuestion
        id="received-driving-ban-sub-question"
        title={
          isAdditionalDriverForm
            ? "additionalDriver.convictionsQuestions.subQuestion.receivedDrivingBan.title"
            : "personalDetails.convictionsQuestions.subQuestion.receivedDrivingBan.title"
        }
        value={receivedDrivingBan}
        onAnswer={value => {
          setReceivedDrivingBan(value);
          updateConviction({drivingBan: {years: 0, months: 0}});
        }}
        status={getMultipleItemsSubQuestionStatus(shouldFlagUnansweredQuestions, conviction.drivingBan)}
        errorMessage="quote.errors.questionIncomplete"
      />

      {receivedDrivingBan &&
        <DurationSubQuestion
          subQuestion={{
            id: subQuestionId,
            title: isAdditionalDriverForm
              ? "additionalDriver.convictionsQuestions.subQuestion.drivingBanDuration.title"
              : "personalDetails.convictionsQuestions.subQuestion.drivingBanDuration.title",
            errorMessage: "quote.errors.questionIncomplete"
          }}
          yearsDropdown={yearsDropdown}
          monthsDropdown={monthsDropdown}
        />
      }
    </SubQuestionForm>
  );
};

export default ConvictionsSubQuestionForm;
