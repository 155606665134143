import {RootState} from "../../../../../../redux/Store";
import {MaritalStatus, RelationshipToProposer} from "shared/dist/generated/graphql/resolvers-types";
import {createSelector} from "@reduxjs/toolkit";
import {additionalDriversSelector} from "./PersonalDetailsSelectors";
import {ownerSelector} from "../../../your-vehicle/redux/selectors/owner-registered-keeper-selectors/OwnerSelector";
import {
  registeredKeeperSelector
} from "../../../your-vehicle/redux/selectors/owner-registered-keeper-selectors/RegisteredKeeperSelector";

const maritalStatusSelector = (state: RootState): MaritalStatus | undefined => state.personalDetails.maritalStatus.value?.id;

export const maritalStatusCompatibleWithAdditionalDriversSelector = createSelector(
  maritalStatusSelector,
  additionalDriversSelector,
  (maritalStatus, additionalDrivers) => {
    if (maritalStatus === undefined) return true;

    const relationships = additionalDrivers.map(driver => driver.relationshipToProposer.id);
    const incompatible =
      (relationships.some(isSpouse) && maritalStatus !== MaritalStatus.Married) ||
      (relationships.some(isCommonLawSpouse) && maritalStatus !== MaritalStatus.MarriedCommonLaw) ||
      (relationships.some(isCivilPartner) && maritalStatus !== MaritalStatus.CivilPartnership);
    return !incompatible;
  });

export type MaritalStatusCompatibleWithRegisteredKeeperAndOwnerSelectorResult = "COMPATIBLE" | "INCOMPATIBLE_OWNER" | "INCOMPATIBLE_KEEPER";

export const maritalStatusCompatibleWithRegisteredKeeperAndOwnerSelector = createSelector(
  maritalStatusSelector,
  ownerSelector,
  registeredKeeperSelector,
  (maritalStatus, owner, keeper): MaritalStatusCompatibleWithRegisteredKeeperAndOwnerSelectorResult => {
    if (!maritalStatus || maritalStatus === "MARRIED") return "COMPATIBLE";

    if (owner?.id === "SPOUSE") return "INCOMPATIBLE_OWNER";
    if (keeper?.id === "SPOUSE") return "INCOMPATIBLE_KEEPER";

    return "COMPATIBLE";
  }
);

const isRelationship = (testRelationship: RelationshipToProposer) =>
  (relationship: RelationshipToProposer) =>
    relationship === testRelationship;

const isSpouse = isRelationship(RelationshipToProposer.Spouse);
const isCivilPartner = isRelationship(RelationshipToProposer.PartnerCivil);
const isCommonLawSpouse = isRelationship(RelationshipToProposer.CommonLawSpouse);