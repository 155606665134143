import {useEffect, useState} from "react";

export type UseStateTuple<State> = [State, (newState: State) => void]

/**
 * This allows the state from the props to have a higher priority.
 * So for example if the component using this isn't connected to redux, but the parent is, then redux and indirectly
 * update the state to change the value rather than rely on the component itself
 */
export function usePropsPriorityState<State>(stateFromProps: State): UseStateTuple<State> {
  const [state, setState] = useState<State>(stateFromProps);

  useEffect(() => {
    setState(stateFromProps);
  }, [stateFromProps]);

  return [state, setState];
}