import React, {FC, useState} from "react";
import Spacer from "shared-components/dist/spacer/Spacer";
import "./AccountSettingsUserInformation.css";
import PhoneNumberAccountSetting from "./user-information/phone-number/PhoneNumberAccountSetting";
import EmailAccountSetting from "./user-information/email/EmailAccountSetting";
import PasswordAccountSetting from "./user-information/password/PasswordAccountSetting";
import {useCurrentUserDetails} from "../hooks/UseCurrentUserDetails";
import SuccessCard from "shared-components/dist/cards/success-card/SuccessCard";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {scrollToElement} from "../../../../utils/navigation/ScrollToHelpers";
import {updateVisibleReminderTypes} from "../../shared/reminders/thunk/UpdateVisibleReminderTypes";
import {useAppDispatch} from "../../../../redux/Hooks";
import MarketingPreferences from "../marketing-preferences/MarketingPreferences";
import FullPageSpinner from "shared-components/dist/spinner/full-page-spinner/FullPageSpinner";

type UserInformationUpdatedState = "NoUpdate" | "PhoneNumberUpdated" | "EmailAddressUpdated" | "PasswordUpdated"

const AccountSettingsUserInformation: FC = () => {
  const dispatch = useAppDispatch();
  const [userInformationUpdatedState, setUserInformationUpdatedState] = useState<UserInformationUpdatedState>("NoUpdate");
  const {currentUserDetails, updateUserDetails} = useCurrentUserDetails();

  if (!currentUserDetails) return (
    <div className="account-settings-loading">
      <FullPageSpinner message="portal.spinner.message"/>
    </div>
  );

  function getSuccessMessageTitle(): TranslationKey {
    if (userInformationUpdatedState === "PhoneNumberUpdated") return "accountSettings.form.phoneNumberUpdated";
    if (userInformationUpdatedState === "EmailAddressUpdated") return "accountSettings.form.emailUpdated";
    if (userInformationUpdatedState === "PasswordUpdated") return "accountSettings.form.passwordUpdated";
    return "general.success";
  }

  const onPhoneNumberVerified = (): void => updateUserInformation("PhoneNumberUpdated");

  const onEmailVerified = (): void => updateUserInformation("EmailAddressUpdated");

  const onPasswordUpdated = (): void => updateUserInformation("PasswordUpdated");

  const onUserDetailUpdated = async (): Promise<void> => {
    await updateUserDetails();
    dispatch(updateVisibleReminderTypes());
  };

  function updateUserInformation(state: UserInformationUpdatedState): void {
    setUserInformationUpdatedState(state);
    scrollToSuccessMessage();
    dispatch(updateVisibleReminderTypes());
  }

  const scrollToSuccessMessage = (): void => scrollToElement("account-settings-header");

  return (
    <div className="account-settings-user-information">
      <div className="account-settings-user-information__content">
        <SuccessCard
          visible={userInformationUpdatedState !== "NoUpdate"}
          onDismiss={() => setUserInformationUpdatedState("NoUpdate")}
          title={getSuccessMessageTitle()}
        />

        <PhoneNumberAccountSetting
          phoneNumber={currentUserDetails.phoneNumber}
          isPhoneNumberUnverified={currentUserDetails.isPhoneNumberUnverified}
          onPhoneNumberUpdated={onUserDetailUpdated}
          onPhoneNumberVerified={onPhoneNumberVerified}
        />

        <Spacer/>

        <EmailAccountSetting
          email={currentUserDetails.email}
          isEmailUnverified={currentUserDetails.isEmailUnverified}
          onEmailUpdated={onUserDetailUpdated}
          onEmailVerified={onEmailVerified}
        />

        <Spacer/>

        <PasswordAccountSetting
          emailAddress={currentUserDetails.email}
          onPasswordUpdated={onPasswordUpdated}
        />

        <Spacer/>

        <MarketingPreferences/>
      </div>
    </div>
  );
};

export default AccountSettingsUserInformation;
