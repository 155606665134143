import React, {FC} from "react";
import "./ContactCard.css";
import {logPortalGoogleAnalyticsEvent} from "../../../../../utils/analytics/PortalAnalytics";
import {FormattedMessage} from "react-intl";

interface ContactInformation {
  phoneNumber: string,
  phoneNumberLink: string,
  googleAnalyticsSuffix: string
}

const customerService: ContactInformation = {
  phoneNumber: "08000 11 31 21",
  phoneNumberLink: "tel:08000113121",
  googleAnalyticsSuffix: "dashboard.helpline.normal_hours"
};

const ContactCard: FC = () => {
  const onPhoneNumberClicked = (contact: ContactInformation): void => {
    logPortalGoogleAnalyticsEvent({
      categorySuffix: contact.googleAnalyticsSuffix,
      action: "Click"
    });
  };

  return (
    <div className="contact-card">
      <div className="contact-card__title">
        <FormattedMessage id={"dashboard.contactCard.title"}/>
      </div>
      <div className="contact-card__description">
        <FormattedMessage id={"dashboard.contactCard.description"}/>
      </div>
      <div className="contact-card__contact-section">
        <div className="contact-card__contact-title">
          <FormattedMessage id={"dashboard.contactCard.generalClaims.title"}/>
        </div>
        <a
          className="contact-card__contact-number"
          href={customerService.phoneNumberLink}
          onClick={() => onPhoneNumberClicked(customerService)}
        >
          {customerService.phoneNumber}
        </a>
      </div>

    </div>
  );
};

export default ContactCard;
