import {hasUkResidencyFromBirthInvalid, ukResidencyDurationInvalid} from "../redux/UpdateAdditionalDriverSlice";
import {AppThunkDispatch} from "../../../../../../redux/Store";
import {ConditionalFormField} from "shared-components/dist/models/form-field/ConditionalFormField";
import {UpdateAdditionalDriver} from "../redux/UpdateAdditionalDriver";

export function flagUnansweredUkResidencyQuestions(
  {hasUkResidencyFromBirth, ukResidencyDuration}: UpdateAdditionalDriver,
  dispatch: AppThunkDispatch
): void {
  if (hasUkResidencyFromBirth.status === "default") {
    dispatch(hasUkResidencyFromBirthInvalid("quote.errors.questionIncomplete"));
  } else if (hasUkResidencyFromBirth.value === false) {
    checkUkResidencyDurationQuestion(ukResidencyDuration, dispatch);
  }
}

function checkUkResidencyDurationQuestion(
  {isActive, status}: ConditionalFormField<Duration>,
  dispatch: AppThunkDispatch
): void {
  if (isActive && status === "default") {
    dispatch(ukResidencyDurationInvalid("quote.errors.questionIncomplete"));
  }
}
