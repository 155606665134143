import {POLICIES} from "../../router/models/Routes";
import {isRouteConfiguration, RouteConfiguration} from "../../pages/quote/vehicle/shared/quote-step/QuoteSteps";

export const isCurrentRoute = (route: string, currentRoute?: string): boolean => {
  return route === currentRoute;
};

export function isCurrentRouteWithin(route: string, currentRoute: string): boolean {
  return currentRoute.includes(route) || (route === POLICIES && currentRoute.includes("policy"));
}

export function buildQuoteRoute(from: string, to: RouteConfiguration | string): string {
  if (isRouteConfiguration(to)) {
    to = to.route;
  }

  return from.substring(0, from.lastIndexOf("/")).concat(to);
}