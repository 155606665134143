import React, {FC} from "react";
import ClaimsQuestion from "../../../../shared/questions/claims/ClaimsQuestion";
import {toSerializableClaim} from "../../../../shared/questions/claims/models/Claims";
import {
  claimAdded,
  claimRemoved,
  claimStatusChanged,
  flagUnansweredClaimsSubQuestions,
  hasClaimsChanged,
  isClaimsFormVisibleSet
} from "../../redux/UpdateAdditionalDriverSlice";
import {logAdditionalDriverGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {claimsSelector} from "../../redux/selectors/AdditionalDriverDateTypeSelectors";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const AdditionalDriverClaimsQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const claims = useAppSelector(claimsSelector);
  const shouldFlagUnansweredQuestions = useAppSelector(state => state.updateAdditionalDriver.claims.value.shouldFlagUnansweredQuestions);
  const isFormVisible = useAppSelector(state => state.updateAdditionalDriver.claims.value.isFormVisible);

  const onHasClaimsAnswered = (answer: boolean): void => {
    logAdditionalDriverGoogleAnalyticsEvent({
      categorySuffix: "motor_accidents_claims_losses",
      action: answer
    });

    dispatch(hasClaimsChanged(answer));
  };

  return (
    <ClaimsQuestion
      id="additional-driver-claims-question"
      title="additionalDriver.claims.hasClaims.title"
      description="additionalDriver.claims.hasClaims.description"
      tooltip={{
        description: "additionalDriver.claims.hasClaims.tooltip.description"
      }}
      claims={claims}
      shouldFlagUnansweredQuestions={shouldFlagUnansweredQuestions}
      flagUnansweredSubQuestions={() => dispatch(flagUnansweredClaimsSubQuestions())}
      isFormVisible={isFormVisible}
      hasClaimsChanged={onHasClaimsAnswered}
      isFormVisibleSet={value => dispatch(isClaimsFormVisibleSet(value))}
      claimAdded={claim => dispatch(claimAdded(toSerializableClaim(claim)))}
      claimRemoved={index => dispatch(claimRemoved(index))}
      isAdditionalDriverForm
      claimStatusChanged={status => dispatch(claimStatusChanged(status))}
    />
  );
};

export default AdditionalDriverClaimsQuestion;