import {Dispatch, SetStateAction, useCallback, useState} from "react";

type LoadingPromise = <T>(promise: Promise<T>) => Promise<T>

interface UseLoading {
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  withLoading: LoadingPromise;
}

export function useLoading(): UseLoading {
  const [loading, setLoading] = useState(false);

  const withLoading = useCallback(async <T>(promise: Promise<T>): Promise<T> => {
    setLoading(true);

    return promise.finally(() => setLoading(false));
  }, []);

  return {loading, setLoading, withLoading};
}
