import {Status} from "shared-components/dist/status-indicator/Status";
import {Option} from "../../../../../../../../structure/form/typeahead/models/Option";
import {ConditionalFormField} from "shared-components/dist/models/form-field/ConditionalFormField";
import {toFormStatus} from "../../../../../../../../graphql/services/ToFormStatus";
import {useQuery} from "@apollo/client";
import {VehicleOptionsQueryResult} from "../../../../../../../../graphql/queries/vehicle-lookup/registration-lookup/VehicleLookup";
import {MAKES_LOOKUP_QUERY} from "../../../../../../../../graphql/queries/vehicle-lookup/manual-vehicle-lookup/model/MakesQuery";

export interface MakesResult {
  status: Status | undefined;
  makes: Option<string>[];
  onMakeSelected: (selection: Option<string>) => void;
}

export function useMakesProps(
  make: ConditionalFormField<string>,
  makeSelected: (value: string) => void
): MakesResult {
  const {loading, error, data} = useQuery<VehicleOptionsQueryResult>(MAKES_LOOKUP_QUERY);
  const makes: Option<string>[] = data?.vehicle.makes.map(make => ({
    value: make,
    label: make
  })) ?? [];
  const status = toFormStatus(make, loading, error);

  const onMakeSelected = (selection: Option<string>): void => {
    const type = makes.find(type => type.value === selection.value);

    if (type) makeSelected(selection.value);
  };

  return {status, makes, onMakeSelected};
}