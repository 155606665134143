import React, {FC, useState} from "react";
import Modal from "shared-components/dist/modals/Modal";
import "./LogoutModal.css";
import LogoutModalBody from "./body/LogoutModalBody";
import ModalTabGroup, {ModalTab} from "shared-components/dist/modals/modal-tab-group/ModalTabGroup";
import {EventCategoryPrefix} from "shared-components/dist/analytics/GoogleAnalyticsEventsService";

interface Props {
  visible: boolean;
  onDismiss: () => void;
  googleAnalyticsPrefix: EventCategoryPrefix;
}

const modalTab: ModalTab = {
  text: "navbar.logout.modal.header"
};

const LogoutModal: FC<Props> = (
  {
    visible,
    onDismiss,
    googleAnalyticsPrefix
  }
) => {
  const [activeTab, setActiveTab] = useState<ModalTab>(modalTab);

  return (
    <Modal
      visible={visible}
      onDismiss={onDismiss}
      className="logout-modal"
      header={
        <ModalTabGroup
          modalTabs={[modalTab]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      }
      body={<LogoutModalBody dismiss={onDismiss} googleAnalyticsPrefix={googleAnalyticsPrefix}/>}
    />
  );
};

export default LogoutModal;
