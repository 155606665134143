import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";

export const WINDOW_TINT_OPTIONS = [
  {
    id: "full-vehicle",
    description: lookupI18nString("vehicleQuote.modifications.windowTintQuestion.options.fullVehicle")
  },
  {
    id: "rear-vehicle",
    description: lookupI18nString("vehicleQuote.modifications.windowTintQuestion.options.rearOfVehicle")
  }
] as const;

export type WindowTintOption = typeof WINDOW_TINT_OPTIONS[number];

export const WINDOW_TINT_DESCRIPTIONS = WINDOW_TINT_OPTIONS.map(windowTint => windowTint.description);
