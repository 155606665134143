import {
  claimsInvalid,
  flagUnansweredClaimsSubQuestions
} from "../redux/UpdateAdditionalDriverSlice";
import {AppThunkDispatch} from "../../../../../../redux/Store";
import {RequiredFormField} from "shared-components/dist/models/form-field/FormField";
import {SerializableClaims} from "../../../shared/questions/claims/models/Claims";
import {UpdateAdditionalDriver} from "../redux/UpdateAdditionalDriver";

export function flagUnansweredClaimsQuestions({claims}: UpdateAdditionalDriver, dispatch: AppThunkDispatch): void {
  if (hasClaimsUnanswered(claims) || claims.value.isFormVisible) {
    dispatch(claimsInvalid("quote.errors.questionIncomplete"));
  }
  if (claims.value.isFormVisible) dispatch(flagUnansweredClaimsSubQuestions());
}

const hasClaimsUnanswered = (claims: RequiredFormField<SerializableClaims>): boolean => {
  return claims.value.hasClaims === undefined && claims.status === "default";
};

