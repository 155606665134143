import {FC} from "react";
import {PrintRequestResponse} from "shared/dist/graphql/queries/request-printed-documents/RequestPrintedDocumentsFromDocmail";
import RagStatus, {RagStatusProps} from "shared-components/dist/information/rag-status/RagStatus";
import {RequestPrintedDocumentsState} from "shared/dist/generated/graphql/resolvers-types";
import {formatDateStringFromIsoString} from "shared/dist/stdlib/DateFormat";
import {MIN_NUMBER_OF_DAYS_BETWEEN_REQUESTS} from "shared/dist/constants/DocumentPrintRequestConfiguration";

interface PrintResponseRagProps {
  printResponse: PrintRequestResponse;
}

const getPrintResponseRagProps = (printResponse: PrintRequestResponse): RagStatusProps => {
  switch (printResponse.state) {
    case RequestPrintedDocumentsState.RequestSent:
      return {
        title: "policies.documents.requestPrintedDocuments.modal.printRequestSent.title",
        description: "policies.documents.requestPrintedDocuments.modal.printRequestSent.description",
        status: "success",
      };
    case RequestPrintedDocumentsState.WeeklyLimitHit:
      return {
        title: "policies.documents.requestPrintedDocuments.modal.printWeeklyLimitHit.title",
        description: {
          id: "policies.documents.requestPrintedDocuments.modal.printWeeklyLimitHit.description",
          templateVariables: {
            date: formatDateStringFromIsoString(printResponse.lastRequestedDate),
            minDaysBetweenRequests: MIN_NUMBER_OF_DAYS_BETWEEN_REQUESTS
          }
        },
        status: "warning",
      };
    case RequestPrintedDocumentsState.BatchLimitHit:
      return {
        title: "policies.documents.requestPrintedDocuments.modal.printBatchLimitHit.title",
        description: "policies.documents.requestPrintedDocuments.modal.printBatchLimitHit.description",
        status: "error",
      };
  }
};

const PrintResponseRag: FC<PrintResponseRagProps> = ({printResponse}) => {
  return <RagStatus {...getPrintResponseRagProps(printResponse)}/>;
};

export default PrintResponseRag;