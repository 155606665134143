import React, {FC} from "react";
import {mapValueToYesOrNoEventType} from "shared-components/dist/analytics/GoogleAnalyticsEventsService";
import {logYourCoverGoogleAnalyticsEvent} from "../../../../../../../../utils/analytics/MotorAnalytics";
import BooleanQuestion
  from "../../../../../../../../structure/questions/question/variants/boolean-question/BooleanQuestion";
import {isPreviousNamedDriverChanged} from "../../../../redux/CoverDetailsSlice";
import FormattedMessage from "shared-components/src/translations/components/FormattedMessage";
import {useAppDispatch, useAppSelector} from "../../../../../../../../redux/Hooks";

interface Props {
  hideSpacer?: boolean;
}

const IsPreviousNamedDriverQuestion: FC<Props> = ({hideSpacer}) => {
  const dispatch = useAppDispatch();
  const isPreviousNamedDriver = useAppSelector(state => state.coverDetails.isPreviousNamedDriver);

  const onAnswer = (answer: boolean): void => {
    logYourCoverGoogleAnalyticsEvent({
      categorySuffix: "named_driver",
      action: mapValueToYesOrNoEventType(answer),
    });

    dispatch(isPreviousNamedDriverChanged(answer));
  };

  return (
    <BooleanQuestion
      id="is-previous-named-driver-question"
      title="coverDetails.previousNamedDriverQuestion.title"
      formField={isPreviousNamedDriver}
      onAnswer={onAnswer}
      hideSpacer={hideSpacer}
      tooltip={{
        description: (
          <FormattedMessage
            id="coverDetails.previousNamedDriverQuestion.tooltip.description"
            values={{
              b: function formatBoldTags(chunks: string) {
                return <b>{chunks}</b>;
              }
            }}
          />
        )
      }}
    />
  );
};

export default IsPreviousNamedDriverQuestion;