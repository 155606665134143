import React, {cloneElement, FC, ReactElement} from "react";
import SubContainer from "../sub-container/SubContainer";
import SubSectionTitle from "../sub-section-title/SubSectionTitle";
import "./SubQuestion.css";
import Rag from "shared-components/dist/information/rag/Rag";
import {Status} from "shared-components/dist/status-indicator/Status";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import SpinnerIcon from "shared-components/dist/spinner/spinner-icon/SpinnerIcon";

export interface SubQuestionProps {
  title: TranslationKey;
  id: string;
  children?: ReactElement;
  status?: Status;
  errorMessage?: TranslationKey;
  errorTooltip?: TranslationKey;
  tooltip?: TooltipProps;
}

type Props = SubQuestionProps;

const SubQuestion: FC<Props> = (
  {
    title,
    status = "default",
    id,
    children,
    errorMessage,
    errorTooltip,
    tooltip
  }
) => (
  <div className={`sub-question sub-question--${status === "error" ? "error" : "default"}`}>
    {status === "loading"
      ? <SpinnerIcon/>
      : (
        <SubContainer status={status === "error" ? "error" : "default"}>
          <SubSectionTitle
            title={title}
            status={status}
            htmlFor={id}
            errorMessage={errorMessage}
            tooltip={tooltip}
          />
          {children && cloneElement(children, {...children.props, id: id})}
          {errorTooltip && <Rag status="error" description={errorTooltip}/>}
        </SubContainer>
      )
    }
  </div>
);

export default SubQuestion;
