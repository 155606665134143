import {FC} from "react";
import PolicySelectionCard from "./PolicySelectionCard";
import "./PolicySelector.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {Policy, PolicyList} from "shared/dist/generated/graphql/resolvers-types";
import {PortalPolicyStatus} from "../utils/PolicyStatusUtils";

interface Props {
  policyList: PolicyList
  selectedPolicy: Policy
  setSelectedPolicy: (selectedPolicy: Policy) => void
}

const PolicySelector: FC<Props> = ({policyList, selectedPolicy, setSelectedPolicy}) => {
  const getPolicyCardsFor = (policyStatus: PortalPolicyStatus): JSX.Element[] => {
    return (
      getPolicyListForStatus(policyStatus, policyList).map((policy, index) => (
          <PolicySelectionCard
            key={index}
            policy={policy}
            policyStatus={policyStatus}
            selectedPolicy={selectedPolicy}
            setSelectedPolicy={setSelectedPolicy}
          />
        )
      )
    );
  };

  return (
    <div className="policy-selector">
      <div className="policy-selector__title">
        {lookupI18nString("portal.policySelector.title")}
      </div>
      <div className="policy-selector__policy-cards">
        {getPolicyCardsFor("active")}
        {getPolicyCardsFor("upcoming")}
        {getPolicyCardsFor("expired")}
        {getPolicyCardsFor("cancelled")}
      </div>
    </div>
  );
};

export default PolicySelector;

function getPolicyListForStatus(policyStatus: PortalPolicyStatus, policyList: PolicyList): Policy[] {
  return policyList[policyStatus] ?? [];
}
