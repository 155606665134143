import {ReduxQuestion} from "../../Questions";
import {RetrieveAddressResult} from "shared/dist/generated/graphql/resolvers-types";
import {
  addressChanged,
  addressInvalid,
  addressReset
} from "../../../../pages/quote/vehicle/your-details/redux/PersonalDetailsSlice";

export const address: ReduxQuestion<RetrieveAddressResult> = {
  selector: state => state.personalDetails.address,
  onChange: addressChanged,
  validators: [],
  onReset: addressReset,
  onInvalid: addressInvalid
};