import React, {FC} from "react";
import QuotePriceCardRow from "../QuotePriceCardRow/QuotePriceCardRow";
import {useAppSelector} from "../../../../../../../../../redux/Hooks";
import {
  selectedProspectiveLoanWithPriceSelector
} from "../../../../../../your-quote/redux/selectors/QuoteDetailsSelectors";
import FormattedMessage from "shared-components/dist/translations/components/FormattedMessage";
import {FormattedPrice} from "../../../../../../../../../utils/money/Price";

const MonthlyQuotePriceCardFooter: FC = () => {
  const depositPercentage = useAppSelector(state => state.quoteDetails.depositPercentage);
  const insurerName = useAppSelector(state => state.quoteDetails.insurerName);
  const policyExtras = useAppSelector(state => state.quoteDetails.policyExtras);
  const premiumAmount = useAppSelector(state => state.quoteDetails.premiumAsPrice);
  const premiumBreakDown = useAppSelector(state => state.quoteDetails.premiumPriceBreakdownDetails);
  const selectedLoan = useAppSelector(state => selectedProspectiveLoanWithPriceSelector(state));
  const totalExtrasAmount = useAppSelector(state => state.quoteDetails.totalExtrasPrice);

  if (!selectedLoan || !premiumAmount) return null;

  const hasPolicyExtras = !!policyExtras?.length;

  return (
    <div role="rowgroup" className="quote-price__column">
      {insurerName &&
        <QuotePriceCardRow
          id="insurer"
          name={<FormattedMessage id={"quoteDetails.priceCard.insurerName"}/>}
          value={insurerName}
          filled
        />
      }

      <QuotePriceCardRow
        id="premium"
        name={<FormattedMessage id={"quoteDetails.priceCard.insurancePremium"}/>}
        value={<FormattedPrice value={premiumAmount}/>}
      />

      <QuotePriceCardRow
        id="adminFee"
        name={<FormattedMessage id={"quoteDetails.priceCard.adminFee"}/>}
        value={<FormattedPrice value={premiumBreakDown?.adminFee}/>}
      />

      {hasPolicyExtras &&
        <QuotePriceCardRow
          id="extras"
          name={<FormattedMessage id={"quoteDetails.priceCard.extras"}/>}
          value={<FormattedPrice value={totalExtrasAmount}/>}
        />
      }

      <QuotePriceCardRow
        id="deposit"
        name={<FormattedMessage id={"quoteDetails.priceCard.deposit"}/>}
        value={<><FormattedPrice value={selectedLoan.firstInstalmentAmount}/> ({depositPercentage}%)</>}
      />

      {selectedLoan.numberOfInstalments &&
        <QuotePriceCardRow
          id="instalments"
          name={<FormattedMessage id={"quoteDetails.priceCard.instalments"} values={{
            numberOfInstalments: selectedLoan.numberOfInstalments
          }}/>}
          value={<FormattedPrice value={selectedLoan.subsequentInstalmentAmounts}/>}
        />
      }

      {selectedLoan.apr &&
        <QuotePriceCardRow
          id="totalAmount"
          name={<FormattedMessage id={"quoteDetails.priceCard.totalPayableAmount"} values={{
            aprPercentage: selectedLoan.apr
          }}/>}
          value={<FormattedPrice value={selectedLoan.totalAmountPayable}/>}
        />
      }
    </div>
  );
};

export default MonthlyQuotePriceCardFooter;
