import {
  isCurrentlyOrPreviouslyInsuredChanged,
  isPreviousNamedDriverChanged,
  vehicleTypeChanged,
  yearsNamedDriverChanged
} from "../../../../pages/quote/vehicle/your-cover/redux/CoverDetailsSlice";
import {SectionQuestionsRecord} from "../../Questions";

export const previousNamedDriverQuestions = {
  isPreviousNamedDriver: {
    selector: state => state.coverDetails.isPreviousNamedDriver,
    onChange: isPreviousNamedDriverChanged
  },
  isCurrentlyOrPreviouslyInsured: {
    selector: state => state.coverDetails.isCurrentlyOrPreviouslyInsured,
    onChange: isCurrentlyOrPreviouslyInsuredChanged
  },
  vehicleType: {
    selector: state => state.coverDetails.vehicleType,
    onChange: vehicleTypeChanged
  },
  yearsNamedDriver: {
    selector: state => state.coverDetails.yearsNamedDriver,
    onChange: yearsNamedDriverChanged
  }
} as const satisfies SectionQuestionsRecord;