import {createSelector} from "@reduxjs/toolkit";
import {isProposerUnderageForFinanceSelector, quotePaymentFrequencySelector} from "./QuoteDetailsSelectors";

export const underageFinanceStopVisibleSelector = createSelector(
  quotePaymentFrequencySelector,
  isProposerUnderageForFinanceSelector,
  (
    paymentFrequency,
    isProposerUnderageForFinance
  ): boolean => paymentFrequency === "Monthly" && isProposerUnderageForFinance
);