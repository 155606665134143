import React, {FC} from "react";
import ModalQuestion, {ModalQuestionProps} from "../ModalQuestion";
import InputField, {InputFieldProps} from "shared-components/dist/form/input-field/InputField";

type Props = InputFieldProps & Omit<ModalQuestionProps, "children">;

const InputFieldModalQuestion: FC<Props> = (
  {
    id,
    title,
    description,
    status,
    errorMessage,
    errorTooltip,
    value,
    type = "text",
    maxLength = Number.MAX_SAFE_INTEGER,
    placeholder,
    changeIf = [],
    onValueChange,
    onChange,
    onFocus,
    onKeyUp,
    onKeyDown,
    onBlur,
    name,
    min,
    max,
    step,
    loading = false,
    disabled,
    validators= [],
    onInvalid,
    pattern,
    className
  }
) => (
  <ModalQuestion
    id={id}
    title={title}
    description={description}
    status={status}
    errorMessage={errorMessage}
    errorTooltip={errorTooltip}
  >
    <InputField
      className={className}
      value={value}
      type={type}
      maxLength={maxLength}
      placeholder={placeholder}
      changeIf={changeIf}
      onValueChange={onValueChange}
      onChange={onChange}
      onFocus={onFocus}
      onKeyUp={onKeyUp}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      name={name}
      id={id}
      min={min}
      max={max}
      step={step}
      loading={loading}
      disabled={disabled}
      validators={validators}
      onInvalid={onInvalid}
      pattern={pattern}
    />
  </ModalQuestion>
);

export default InputFieldModalQuestion;