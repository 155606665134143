import {useSearchParams} from "../../../utils/custom-hooks/SearchParams";

export interface AggregatorQuoteReferenceQueryParamsHook {
  quoteReference: string | undefined;
}

export function useAggregatorQuoteReferenceQueryParams(): AggregatorQuoteReferenceQueryParamsHook {
  const params = useSearchParams();
  return {
    quoteReference: params.get("sr") ?? undefined,
  };
}
