import {gql, useQuery} from "@apollo/client";
import {AbiListItem} from "shared/dist/generated/graphql/resolvers-types";
import {QueryResult} from "@apollo/client/react/types/types";

export interface GetJobsResult {
  quote: {
    abiCodes: {
      jobs: AbiListItem[];
    };
  };
}

const GET_JOBS = gql`
  query GetJobs {
    quote {
      abiCodes {
        jobs {
          vtCode
          vtDescription
        }
      }
    }
  }
`;

export const useJobsQuery = (): QueryResult<GetJobsResult> => useQuery(GET_JOBS);