import {EmploymentFields} from "../../../../quote/vehicle/your-details/redux/models/EmploymentFields";
import {
  EMPLOYMENT_STATUS,
  EmploymentStatusOption
} from "../../../../quote/vehicle/shared/questions/employment/models/EmploymentStatusOption";
import {
  AbiListItem,
  EmploymentStatus,
  Gender,
  QuoteEmploymentDetails
} from "shared/src/generated/graphql/resolvers-types";
import {buildConditionalFormField, buildFormField} from "../BuildFormField";
import {CustomisedAbiListItem} from "shared-components/dist/models/CustomisedAbiListItem";
import {AdditionalDriverEmployment} from "../../../../quote/vehicle/additional-driver/shared/models/AdditionalDriver";
import {isEmployed, isUnemployed} from "../../../../quote/vehicle/shared/validation/ValidateEmploymentStatus";
import {FormField} from "shared-components/dist/models/form-field/FormField";

type EmploymentFieldBuilder<T extends FormField<EmploymentStatusOption>> = (
  employmentDetails: QuoteEmploymentDetails,
  gender: Gender
) => EmploymentFields<T>;

export const buildPrimaryEmploymentFields = constructEmploymentFieldsBuilder({
  employmentStatusFieldBuilder: buildFormField
});
export const buildSecondaryEmploymentFields = constructEmploymentFieldsBuilder({
  employmentStatusFieldBuilder: buildConditionalFormField
});

interface ConstructEmploymentFieldsBuilderProps<T extends FormField<EmploymentStatusOption>> {
  employmentStatusFieldBuilder: (employmentStatusOption: EmploymentStatusOption) => T;
}

function constructEmploymentFieldsBuilder<T extends FormField<EmploymentStatusOption>>(
  {employmentStatusFieldBuilder}: ConstructEmploymentFieldsBuilderProps<T>
): EmploymentFieldBuilder<T> {
  return (employmentDetails, gender) => {
    const values = buildEmploymentValues(employmentDetails, gender);

    return {
      employmentStatus: employmentStatusFieldBuilder(values.employmentStatus),
      industry: buildConditionalFormField(values.industry),
      job: buildConditionalFormField(values.job),
      workedInPastYear: buildConditionalFormField(values.workedInPastYear)
    };
  };
}

export function buildEmploymentValues(employmentDetails: QuoteEmploymentDetails, personGender: Gender): AdditionalDriverEmployment<EmploymentStatusOption> {
  const employmentStatus = getEmploymentStatusOptionFor(employmentDetails.status, personGender);

  return {
    employmentStatus,
    industry: buildEmploymentSubQuestionAbiListItem(employmentDetails.industry, employmentStatus),
    job: buildEmploymentSubQuestionAbiListItem(employmentDetails.job, employmentStatus),
    workedInPastYear: determineHasWorkedInPastYear(employmentStatus)
  };
}

export const determineHasWorkedInPastYear = (employmentStatus: EmploymentStatusOption): false | undefined => (
  isUnemployed(employmentStatus) ? false : undefined
);

function buildEmploymentSubQuestionAbiListItem(abiListItem: AbiListItem, employmentStatus: EmploymentStatusOption): CustomisedAbiListItem | undefined {
  if (!isEmployed(employmentStatus)) return undefined;

  return {
    vtCode: abiListItem.vtCode,
    description: abiListItem.vtDescription
  };
}

export function getEmploymentStatusOptionFor(employmentStatus: EmploymentStatus, gender: Gender): EmploymentStatusOption {
  const userEmploymentStatus = employmentStatus === EmploymentStatus.HouseSpouse
    ? buildHouseSpouseEmploymentOptionFor(gender)
    : EMPLOYMENT_STATUS.find(status => status.vtCode === employmentStatus);

  if (!userEmploymentStatus) throw new Error(`Unexpected Employment Status: ${employmentStatus}`);

  return userEmploymentStatus;
}

function buildHouseSpouseEmploymentOptionFor(gender: Gender): EmploymentStatusOption | undefined {
    return {
      vtCode: EmploymentStatus.HouseSpouse,
      description: gender === Gender.Female ? "House wife" : "House husband"
  };
}