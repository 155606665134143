import React, {FC} from "react";
import {ownerStopVisibleSelector} from "../../../redux/selectors/owner-registered-keeper-selectors/OwnerRegisteredKeeperStopVisibleSelector";
import QuoteJourneyStop from "../../../../shared/stops/quote-journey-stop/QuoteJourneyStop";
import {useAppSelector} from "../../../../../../../redux/Hooks";

const OwnerStop: FC = () => {
  const isVisible = useAppSelector(ownerStopVisibleSelector);

  return (
    <QuoteJourneyStop
      stopProps={{
        visible: isVisible,
        title: "vehicleQuote.ownerQuestion.stop.title",
        description: "vehicleQuote.ownerQuestion.stop.description"
      }}
      isSubContainer={true}
    />
  );
};

export default OwnerStop;