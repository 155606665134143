import React, {FC} from "react";
import "./Claims.css";
import CustomerPortalContainerWithoutSidebar from "../shared/container/CustomerPortalContainerWithoutSidebar";
import ClaimsHeader from "./header/ClaimsHeader";
import PointsOfContactCard from "./components/point-of-contact-card/PointsOfContactCard";

const Claims: FC = () => (
  <CustomerPortalContainerWithoutSidebar
    header={<ClaimsHeader/>}
    mainContent={<PointsOfContactCard/>}
  />
);

export default Claims;
