import React, {FC} from "react";
import {coverTypeChanged} from "../../redux/CoverDetailsSlice";
import {COVER_TYPE_OPTIONS, CoverTypeDescription} from "./models/CoverType";
import RadioColumnQuestion, {
  RadioColumnQuestionWithGenericProps
} from "../../../../../../structure/questions/question/variants/radio-column-question/RadioColumnQuestion";
import FormattedMessage from "shared-components/src/translations/components/FormattedMessage";
import {logYourCoverGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const CoverTypeRadioColumnQuestion = RadioColumnQuestion as RadioColumnQuestionWithGenericProps<CoverTypeDescription>;

const CoverTypeQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const coverType = useAppSelector(state => state.coverDetails.coverType);

  const onCoverTypeChanged = (coverType: CoverTypeDescription): void => {
    logYourCoverGoogleAnalyticsEvent({
      categorySuffix: "cover_type",
      action: actionValueFrom(coverType)
    });

    dispatch(coverTypeChanged(coverType));
  };

  const actionValueFrom = (coverType: CoverTypeDescription): string => {
    return coverType
      .toLowerCase()
      .replace(/\s/g, "_");
  };

  return (
    <CoverTypeRadioColumnQuestion
      questionProps={{
        id: "cover-type-question",
        title: "coverDetails.coverTypeQuestion.title",
        status: coverType.status,
        errorMessage: coverType.errorMessage,
        hideSpacer: true,
        tooltip: {
          description: (
            <FormattedMessage
              id="coverDetails.coverTypeQuestion.tooltip.description"
              values={{
                b: function formatBoldTags(chunks: string) {
                  return <b>{chunks}</b>;
                },
                ul: function formatUnorderedList(list: string) {
                  return <ul>{list}</ul>;
                },
                li: function formatListItemTags(msg: string) {
                  return <li>{msg}</li>;
                }
              }}
            />
          )
        }
      }}
      radioColumnProps={{
        name: "cover-type-radio-column",
        options: COVER_TYPE_OPTIONS,
        selectedOption: coverType.value?.description,
        onSelection: onCoverTypeChanged
      }}
    />
  );
};

export default CoverTypeQuestion;