import {AppThunk} from "../../../../redux/Store";
import {resetPassword} from "../../../../user/authentication/services/UserPasswordManagementService";
import {authenticateUser} from "../../../../user/authentication/services/UserAuthenticationService";
import {userAttemptedToResetPassword} from "../../shared/redux/UserAuthenticationSlice";
import {LoginResult} from "../../../../user/authentication/models/login/LoginResponse";
import {ResetPasswordResult} from "../../../../user/authentication/models/password-management/ResetPasswordResult";
import {sendPasswordSuccessfullyUpdatedEmail} from "../../../../user/authentication/services/SendPasswordSuccessfullyUpdatedEmail";

export const confirmPasswordReset = (email: string, verificationCode: string, newPassword: string): AppThunk => async (dispatch) => {
  const resetPasswordResult = await resetPassword(email, verificationCode, newPassword);

  if (resetPasswordResult === "Success") {
    const loginResponse = await authenticateUser(email, newPassword);
    if (loginResponse.loginResult === "Success") await sendPasswordSuccessfullyUpdatedEmail();

    dispatch(userAttemptedToResetPassword(mapLoginResultToResetPasswordResult(loginResponse.loginResult)));
  } else {
    dispatch(userAttemptedToResetPassword(resetPasswordResult));
  }
};

function mapLoginResultToResetPasswordResult(loginResult: LoginResult): ResetPasswordResult {
  return loginResult === "Success" ? "Success" : "FailedToAuthenticateWithNewPassword";
}
