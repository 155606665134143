import {EmploymentFields} from "../../your-details/redux/models/EmploymentFields";
import {isEmployed, isRetired, isUnemployed} from "../validation/ValidateEmploymentStatus";
import {FormField} from "shared-components/dist/models/form-field/FormField";
import {EmploymentStatusOption} from "../questions/employment/models/EmploymentStatusOption";
import {Status} from "shared-components/dist/status-indicator/Status";
import {CustomisedAbiListItem} from "shared-components/dist/models/CustomisedAbiListItem";
import {ConditionalFormField} from "shared-components/dist/models/form-field/ConditionalFormField";
import {INDUSTRY_RETIRED_VT_CODE, JOBS_RETIRED_VT_CODE} from "../../../../../utils/constants/AbiCodes";

export function employmentChanged<T extends FormField<EmploymentStatusOption>>(employment: EmploymentFields<T>, initialState: EmploymentFields<T>): void {
  if (isEmployed(employment.employmentStatus.value)) {
    updateFieldsWhenEmployed(employment, initialState);
  } else if (isUnemployed(employment.employmentStatus.value)) {
    updateFieldsWhenUnemployed(employment, initialState);
  } else if (isRetired(employment.employmentStatus.value)) {
    updateFieldsWhenRetired(employment, initialState);
  } else {
    updateFieldsWhenOtherEmployment(employment, initialState);
  }
}

function updateFieldsWhenEmployed<T extends FormField<EmploymentStatusOption>>(employment: EmploymentFields<T>, initialState: EmploymentFields<T>): void {
  employment.industry = {...initialState.industry, isActive: true};
  employment.job = {...initialState.job, isActive: true};
  employment.workedInPastYear = initialState.workedInPastYear;
}

function updateFieldsWhenUnemployed<T extends FormField<EmploymentStatusOption>>(employment: EmploymentFields<T>, initialState: EmploymentFields<T>): void {
  employment.industry = initialState.industry;
  employment.job = initialState.job;
  employment.workedInPastYear = {...initialState.workedInPastYear, isActive: true};
}

function updateFieldsWhenRetired<T extends FormField<EmploymentStatusOption>>(employment: EmploymentFields<T>, initialState: EmploymentFields<T>): void {
  employment.industry = buildCustomisedAbiListItemConditionalFormField("None - Retired", INDUSTRY_RETIRED_VT_CODE);
  employment.job = buildCustomisedAbiListItemConditionalFormField("Retired", JOBS_RETIRED_VT_CODE);
  employment.workedInPastYear = initialState.workedInPastYear;
}

function updateFieldsWhenOtherEmployment<T extends FormField<EmploymentStatusOption>>(employment: EmploymentFields<T>, initialState: EmploymentFields<T>): void {
  employment.industry = initialState.industry;
  employment.job = initialState.job;
  employment.workedInPastYear = initialState.workedInPastYear;
}

function buildCustomisedAbiListItemConditionalFormField(description: string, vtCode: string, status: Status = "success", isActive = true): ConditionalFormField<CustomisedAbiListItem> {
  return {
    status,
    isActive,
    value: {
      description,
      vtCode
    }
  };
}