import React, {FC, useEffect, useState} from "react";
import "./ApplePay.css";
import {loadApplePaySessionProvider} from "./provider/ApplePayProvider";
import {logger} from "../../../../utils/logging/Logger";
import {validateMerchant} from "./query/ValidateMerchant";
import {buildApplePayPaymentRequest} from "./builder/ApplePayPaymentRequestBuilder";
import {
  FinancedPolicyPaymentResult,
  FullPolicyPaymentResult,
  Price
} from "shared/dist/generated/graphql/resolvers-types";
import {useNavigate} from "react-router-dom";

const APPLE_PAY_API_VERSION = 3;

interface OwnProps {
  amountPayable: Price;
  successPagePath: string;
  makePayment: (payment: ApplePayJS.ApplePayPayment) => Promise<FullPolicyPaymentResult | FinancedPolicyPaymentResult>;
}

const ApplePay: FC<OwnProps> = ({amountPayable, makePayment, successPagePath}) => {
  const [loadingProvider, setLoadingProvider] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    loadApplePaySessionProvider()
      .catch(() => {
        logger.info("Could not load Apple Pay provider, are you using Safari?");
      })
      .finally(() => {
        setLoadingProvider(false);
      });
  }, []);

  if (loadingProvider || !window.ApplePaySession || !ApplePaySession.canMakePayments()) return null;

  const onClick = (): void => {
    const request = buildApplePayPaymentRequest(amountPayable.amount);
    const session = new ApplePaySession(APPLE_PAY_API_VERSION, request);

    session.onvalidatemerchant = async (): Promise<void> => {
      // User has opened Apple Pay payment sheet, we must validate our merchant details with Apple Pay server
      validateMerchant()
        .then((merchantSession) => {
          session.completeMerchantValidation(merchantSession);
        })
        .catch((error) => {
          logger.error("Unable to get validated Apple Pay merchant session", error);
          session.abort();
        });
    };

    session.onpaymentmethodselected = (): void => {
      // User has selected a payment method, we update the session
      session.completePaymentMethodSelection({
        newTotal: request.total
      });
    };

    // Implementing shipping related handlers in case they are required by ApplePayJS regardless of if we require them.
    session.onshippingmethodselected = (): void => {
      session.completeShippingMethodSelection({
        newTotal: request.total
      });
    };

    session.onshippingcontactselected = (): void => {
      session.completeShippingContactSelection({
        newTotal: request.total
      });
    };

    session.onpaymentauthorized = (event: ApplePayJS.ApplePayPaymentAuthorizedEvent): void => {
      // User has authorised payment, we use the token given in this event to make a payment and complete the session.
      logger.debug("User authorized payment", event);
      makePayment(event.payment)
        .then((result) => {
          if (result === "SUCCESS") {
            session.completePayment({
              status: ApplePaySession.STATUS_SUCCESS
            });
            navigate(successPagePath);
          } else {
            session.completePayment({
              status: ApplePaySession.STATUS_FAILURE
            });
          }
        });
    };

    session.oncancel = (): void => {
      // User has cancelled payment, session is aborted by ApplePayJS.
      logger.info("Apple Pay payment cancelled");
    };

    session.begin();
  };

  return (
    <button
      className="apple-pay-button apple-pay-button-black"
      onClick={onClick}
    />
  );
};

export default ApplePay;
