import {AppThunk, AppThunkDispatch} from "../../../../../redux/Store";
import {
  addressInvalid,
  classOfUseInvalid,
  dateOfBirthInvalid,
  firstNameInvalid,
  genderInvalid,
  hasNonMotoringConvictionsInvalid,
  insuranceDeclinedQuestionInvalid,
  isCarKeptAtHomeInvalid, isHomeownerInvalid,
  licenceCountryOfIssueInvalid,
  licenceDurationInvalid,
  licenceTypeInvalid,
  maritalStatusInvalid,
  phoneNumberInvalid,
  proposerEmailAddressInvalid,
  solicitingInvalid,
  storedAtNightInvalid,
  surnameInvalid,
  titleInvalid
} from "../redux/PersonalDetailsSlice";
import {flagUnansweredPrimaryEmploymentQuestions} from "./FlagUnansweredPrimaryEmploymentQuestions";
import {flagUnansweredMedicalConditionQuestions} from "./FlagUnansweredMedicalConditionQuestions";
import {flagUnansweredUkResidencyQuestions} from "./FlagUnansweredUkResidencyQuestions";
import {flagUnansweredConvictionsQuestions} from "./FlagUnansweredConvictionsQuestions";
import {flagUnansweredSecondaryEmploymentQuestions} from "./FlagUnansweredSecondaryEmploymentQuestions";
import {flagUnansweredClaimsQuestions} from "./FlagUnansweredClaimsQuestions";
import {PersonalDetails} from "../redux/PersonalDetails";

type CheckFunction = (personalDetails: PersonalDetails, dispatch: AppThunkDispatch) => void;

export const flagUnansweredPersonalDetailQuestions = (): AppThunk => async (dispatch, getState) => {
  [
    checkTitle,
    checkFirstName,
    checkSurname,
    checkDateOfBirth,
    checkGender,
    checkCarKeptAtHome,
    checkStoredAtNight,
    checkIsHomeowner,
    checkClassOfUse,
    checkSoliciting,
    checkProposerEmailAddress,
    checkPhoneNumber,
    checkMaritalStatus,
    checkLicenceCountryOfIssue,
    checkAddress,
    checkLicenceType,
    checkLicenceDuration,
    checkLicenceDuration,
    checkNonMotoringConvictions,
    checkInsuranceDeclined,
    flagUnansweredUkResidencyQuestions,
    flagUnansweredMedicalConditionQuestions,
    flagUnansweredPrimaryEmploymentQuestions,
    flagUnansweredSecondaryEmploymentQuestions,
    flagUnansweredClaimsQuestions,
    flagUnansweredConvictionsQuestions,
  ].forEach((checkFunction: CheckFunction) => checkFunction(getState().personalDetails, dispatch));
};

function checkTitle({title}: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (title.status === "default") dispatch(titleInvalid("quote.errors.questionIncomplete"));
}

function checkFirstName({firstName}: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (firstName.status === "default") dispatch(firstNameInvalid("quote.errors.questionIncomplete"));
}

function checkSurname({surname}: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (surname.status === "default") dispatch(surnameInvalid("quote.errors.questionIncomplete"));
}

function checkGender({gender}: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (gender.isActive && gender.status === "default") dispatch(genderInvalid("quote.errors.questionIncomplete"));
}

function checkCarKeptAtHome({isCarKeptAtHome}: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (isCarKeptAtHome.status === "default") dispatch(isCarKeptAtHomeInvalid("quote.errors.questionIncomplete"));
}

function checkStoredAtNight({storedAtNight}: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (storedAtNight.status === "default") dispatch(storedAtNightInvalid("quote.errors.questionIncomplete"));
}

function checkIsHomeowner({isHomeowner}: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (isHomeowner.status === "default") dispatch(isHomeownerInvalid("quote.errors.questionIncomplete"));
}

function checkClassOfUse({classOfUse}: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (classOfUse.status === "default") dispatch(classOfUseInvalid("quote.errors.questionIncomplete"));
}

function checkSoliciting({soliciting}: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (soliciting.isActive && soliciting.status === "default") dispatch(solicitingInvalid("quote.errors.questionIncomplete"));
}

function checkPhoneNumber({phoneNumber}: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (phoneNumber.status === "default") dispatch(phoneNumberInvalid("quote.errors.questionIncomplete"));
}

function checkMaritalStatus({maritalStatus}: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (maritalStatus.status === "default") dispatch(maritalStatusInvalid("quote.errors.questionIncomplete"));
}

function checkLicenceCountryOfIssue({licenceCountryOfIssue}: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (licenceCountryOfIssue.status === "default") dispatch(licenceCountryOfIssueInvalid("quote.errors.questionIncomplete"));
}

function checkLicenceType({licenceType}: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (licenceType.status === "default") dispatch(licenceTypeInvalid("quote.errors.questionIncomplete"));
}

function checkLicenceDuration({licenceDuration}: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (licenceDuration.status === "default") dispatch(licenceDurationInvalid("quote.errors.questionIncomplete"));
}

function checkDateOfBirth({dateOfBirth}: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (dateOfBirth.status === "default") dispatch(dateOfBirthInvalid("quote.errors.questionIncomplete"));
}

function checkAddress({address}: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (address.status === "default") dispatch(addressInvalid("quote.errors.questionIncomplete"));
}

function checkNonMotoringConvictions({hasNonMotoringConvictions}: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (hasNonMotoringConvictions.status === "default") dispatch(hasNonMotoringConvictionsInvalid("quote.errors.questionIncomplete"));
}

function checkInsuranceDeclined({hasInsuranceDeclined}: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (hasInsuranceDeclined.status === "default") dispatch(insuranceDeclinedQuestionInvalid("quote.errors.questionIncomplete"));
}

function checkProposerEmailAddress({proposerEmailAddress}: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (proposerEmailAddress.status === "default") dispatch(proposerEmailAddressInvalid("quote.errors.questionIncomplete"));
}
