import React, {FC} from "react";
import {licenceTypeChanged} from "../../redux/UpdateAdditionalDriverSlice";
import LicenceTypeQuestion from "../../../../shared/questions/licence-type/LicenceTypeQuestion";
import {LicenceTypeOption} from "../../../../shared/questions/licence-type/LicenceTypeOption";
import {logAdditionalDriverGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const AdditionalDriverLicenceTypeQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const licenceTypeField = useAppSelector(state => state.updateAdditionalDriver.licenceType);

  const onLicenceTypeChanged = (answer: LicenceTypeOption): void => {
    logAdditionalDriverGoogleAnalyticsEvent({
      categorySuffix: "type_of_licence",
      action: answer.description.toLowerCase()
    });

    dispatch(licenceTypeChanged(answer));
  };

  return (
    <LicenceTypeQuestion
      id="additional-driver-licence-type"
      title="additionalDriver.licenceTypeQuestion"
      licenceTypeField={licenceTypeField}
      licenceTypeChanged={onLicenceTypeChanged}
    />
  );
};

export default AdditionalDriverLicenceTypeQuestion;
