import {AppThunk, AppThunkDispatch, RootState} from "../../../../../../redux/Store";
import {
  calculateNewSelectedLoanWithPrice,
  insurerQuoteChanged,
  loadingCloseBrothersLoanFailed,
  loadingCloseBrothersLoanSucceeded,
  prospectiveLoansWithPriceChanged,
  startedLoadingCloseBrothersLoan,
  totalExtrasPriceChanged
} from "../QuoteDetailsSlice";
import {FetchProspectiveLoansResponse} from "shared/dist/generated/graphql/resolvers-types";
import {logger} from "../../../../../../utils/logging/Logger";
import {InsurerQuote} from "../../models/InsurerQuote";
import {LoadingStatus} from "../../models/LoadingStatus";
import {fetchProspectiveLoansQuery} from "./FetchProspectiveLoansQuery";

interface LoadProspectiveLoansParams {
  quotes?: InsurerQuote[] | undefined;
}

export const loadProspectiveLoans = ({quotes}: LoadProspectiveLoansParams = {}): AppThunk => async (dispatch, getState) => {
  const state = getState();

  try {
    await loadLoans(dispatch, state, quotes);
  } catch (error) {
    logger.error(`Failed to get loans for magicLinkId [${state.quoteDetails.magicLinkId}]: Error [${error.name}] thrown with message [${error.message}]`, error);
    dispatch(loadingCloseBrothersLoanFailed());
  }
};

async function loadLoans(dispatch: AppThunkDispatch, state: RootState, quotes: InsurerQuote[] | undefined): Promise<void> {
  const insurerQuotes = quotes ?? state.quoteDetails.insurerQuotes;
  const depositPercentage = state.quoteDetails.depositPercentage;

  await loadLoansForInsurerQuotes(dispatch, state, insurerQuotes, depositPercentage);

  dispatch(loadingCloseBrothersLoanSucceeded());
}

async function loadLoansForInsurerQuotes(dispatch: AppThunkDispatch, state: RootState, insurerQuotes: InsurerQuote[] | undefined, depositPercentage: number): Promise<void> {
  if (!insurerQuotes) return;

  dispatch(startedLoadingCloseBrothersLoan());
  let extrasTotalAmount = undefined;
  const updatedInsurerQuotes = await Promise.all(insurerQuotes.map(async quote => {
    try {
      const prospectiveLoansResponse = await fetchProspectiveLoansQuery(
        quote.reference, quote.sequenceNumber, depositPercentage
      );

      const updatedQuote = updateQuoteWithLoansResponse(quote, prospectiveLoansResponse);
      extrasTotalAmount = prospectiveLoansResponse.extrasTotalAmount;
      dispatch(insurerQuoteChanged(updatedQuote));
      return updatedQuote;
    } catch (error) {
      logger.error(`Failed to get loans for quoteRef [${quote.reference}]: Error [${error.name}] thrown with message [${error.message}]`, error);
      throw error;
    }
  }));

  dispatch(totalExtrasPriceChanged(extrasTotalAmount));
  const selectedInsurerQuote = updatedInsurerQuotes.find(quote => (
    quote.reference === state.quoteDetails.quoteReference
    && quote.sequenceNumber === state.quoteDetails.quoteSequenceNumber
  ));

  if (selectedInsurerQuote?.loansWithPrice) dispatch(prospectiveLoansWithPriceChanged(selectedInsurerQuote.loansWithPrice));
}

function updateQuoteWithLoansResponse(quote: InsurerQuote, prospectiveLoansResponse: FetchProspectiveLoansResponse): InsurerQuote {
  const prospectiveLoans = prospectiveLoansResponse.prospectiveLoans;

  if (prospectiveLoans) {
    return {
      ...quote,
      loansWithPrice: prospectiveLoans,
      selectedLoanWithPrice: calculateNewSelectedLoanWithPrice(quote.selectedLoanWithPrice, prospectiveLoans),
      closeBrothersLoanStatus: LoadingStatus.SUCCESS,
      totalUnfinancedAmountPayable: prospectiveLoansResponse.totalUnfinancedAmountPayable
    };
  }
  return quote;
}
