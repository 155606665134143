import {RootState} from "../../../../redux/Store";
import {PolicyHolder, PolicyList} from "shared/dist/generated/graphql/resolvers-types";
import {PortalLoadingStatus} from "./PortalDetails";
import {ReminderType} from "../reminders/thunk/ReminderType";

export const selectPortalLoadingStatus = (state: RootState): PortalLoadingStatus => state.portal.portalLoadingStatus;
export const selectPolicyHolder = (state: RootState): PolicyHolder | undefined => state.portal.policyHolder;
export const selectPolicyList = (state: RootState): PolicyList | undefined => state.portal.policyList;
export const selectVisibleReminderTypes = (state: RootState): ReminderType[] | undefined => state.portal.visibleReminderTypes;
export const selectSmsMarketingPreference = (state: RootState): boolean => state.portal.policyHolder?.metadata?.smsMarketingEnabled ?? true;
export const selectEmailMarketingPreference = (state: RootState): boolean => state.portal.policyHolder?.metadata?.emailMarketingEnabled ?? true;