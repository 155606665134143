import React, {FC} from "react";
import "./AnnualPaymentByCard.css";
import PaymentStep from "../../shared/payment-step/PaymentStep";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import QuoteNavigationPrompter from "../../../quote/vehicle/shared/router/QuoteNavigationPrompter";
import CardPaymentForm from "../../shared/card-payment-form/CardPaymentForm";
import {
  processFullPolicySetup,
  processFullRenewal
} from "../payment-processors/hosted-payment-page/PolicyPaymentProcessor";
import {PaymentFlowType} from "../../shared/models/PaymentFlowType";
import {ProposedRenewalRequest} from "shared/dist/generated/graphql/resolvers-types";
import {PolicySetupQuoteValues} from "shared/dist/event-models/policy-setup/PolicySetupEvent";

interface Props {
  paymentFlowType: PaymentFlowType;
  proposedRequest: ProposedRenewalRequest | PolicySetupQuoteValues;
}

const AnnualPaymentByCard: FC<Props> = ({paymentFlowType, proposedRequest}) => (
  <div className="annual-payment-by-card" data-testid="annual-payment-by-card">
    {paymentFlowType === PaymentFlowType.QUOTE && <QuoteNavigationPrompter/>}
    <PaymentStep
      title="paymentFlow.annual.byCard.mobileTitle"
      sidebarTitle={lookupI18nString("paymentFlow.annual.byCard.sidebar.title")}
      sidebarContent={lookupI18nString("paymentFlow.annual.byCard.sidebar.description")}
    >
      <CardPaymentForm
        paymentFlowType={paymentFlowType}
        makePayment={(globalPayFormResponse, threeDSecureTransactionReference) =>
          paymentFlowType === PaymentFlowType.QUOTE || paymentFlowType === PaymentFlowType.REBROKE
            ? processFullPolicySetup(globalPayFormResponse, proposedRequest as PolicySetupQuoteValues, threeDSecureTransactionReference)
            : processFullRenewal(globalPayFormResponse, proposedRequest as ProposedRenewalRequest, threeDSecureTransactionReference)
        }
      />
    </PaymentStep>
  </div>
);

export default AnnualPaymentByCard;
