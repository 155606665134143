import {FC} from "react";
import InformationPanel from "shared-components/dist/information/panel/InformationPanel";

interface OwnProps {
  className?: string;
}

const NamedDriverIneligiblePanel: FC<OwnProps> = ({className}) => (
  <InformationPanel
    id="named-driver-ineligible-panel"
    className={`named-driver-ineligible-panel${className ? ` ${className}` : ""}`}
    title="coverDetails.namedDriver.ineligiblePanel.title"
    subTitle="coverDetails.namedDriver.ineligiblePanel.subtitle"
    description="coverDetails.namedDriver.ineligiblePanel.description"
  />
);

export default NamedDriverIneligiblePanel;