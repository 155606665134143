import React, {FC} from "react";
import {solicitingChanged} from "../../../redux/PersonalDetailsSlice";
import SubQuestionForm from "../../../../../../../structure/questions/sub-question-form/SubQuestionForm";
import BooleanSubQuestion from "../../../../../../../structure/questions/sub-question/variants/boolean-sub-question/BooleanSubQuestion";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const SolicitingSubQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const soliciting = useAppSelector(state => state.personalDetails.soliciting);

  return (
    <SubQuestionForm>
      <BooleanSubQuestion
        id="soliciting-sub-question"
        title="personalDetails.solicitingSubQuestion.title"
        value={soliciting.value}
        onAnswer={value => dispatch(solicitingChanged(value))}
        errorMessage={soliciting.errorMessage}
        status={soliciting.status}
      />
    </SubQuestionForm>
  );
};

export default SolicitingSubQuestion;