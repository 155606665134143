import React, {FC} from "react";
import "./PolicyCard.css";
import {useNavigate} from "react-router-dom";
import {RenewalStatusPill} from "../renewal-status-pill/RenewalStatusPill";
import {Policy} from "shared/dist/generated/graphql/resolvers-types";
import PolicySummaryCardRow from "./PolicySummaryCardRow";
import {
  calculateProgressBarValue,
  getCardTitleFor,
  getDaysUntilCoverChange,
  getProgressBarTextFor,
  PortalPolicyStatus
} from "../../../shared/utils/PolicyStatusUtils";
import classNames from "classnames";

interface Props {
  isDashboard: boolean
  policyStatus: PortalPolicyStatus
  policy: Policy
}

const PolicyCard: FC<Props> = ({isDashboard, policyStatus, policy}) => {
  const navigate = useNavigate();

  function handleOnClick(): void {
    if (isDashboard) {
      navigate(`/portal/policy/${policy.id}`);
    }
  }

  return (
    <button
      onClick={() => handleOnClick()}
      className={classNames(`policy-card policy-card${isDashboard ? "__dashboard" : "__summary"}`, `policy-card__${policyStatus}`)}
    >
      {isDashboard &&
        <div className={"renewal-status-pill-container"}>
          <RenewalStatusPill
            status={policy.metadata.renewalInviteStatus}
            expiryTimestamp={policy.renewalNotice?.expiryTimestamp}
          />
        </div>
      }

      <div className="policy-card__row">
        <div className="policy-card__column">
          <div className={`policy-card__title policy-card__title__${policyStatus}`}>
            {getCardTitleFor(policyStatus)}
          </div>

          <div className={`policy-card__registration policy-card__registration__${policyStatus}`}>
            {policy.vehicleDetails.registeredVehicle.registration}
          </div>
        </div>
      </div>

      {!isDashboard && <PolicySummaryCardRow policyStatus={policyStatus} policy={policy}/>}

      <div className="policy-card__row">
        <div className="policy-card__column">
          <div className={`policy-card__progress-bar-text policy-card__progress-bar-text__${policyStatus}`}>
            {getProgressBarTextFor(policyStatus, getDaysUntilCoverChange(policyStatus, policy), policy)}
          </div>
        </div>
      </div>

      <div className="policy-card__row">
        <progress
          className={`progress-bar policy-card__progress-bar__${policyStatus}`}
          value={calculateProgressBarValue(policyStatus, getDaysUntilCoverChange(policyStatus, policy))}
          max={365}
        />
      </div>
    </button>
  );
};

export default PolicyCard;
