import {FC, useState} from "react";
import PolicyExtraSidebarSelectorListItem from "./PolicyExtraSidebarSelectorListItem";
import {RenewalPolicyExtras} from "../../models/RenewalPolicyExtras";
import {PolicyExtraType} from "shared/dist/generated/graphql/resolvers-types";
import {useAppDispatch, useAppSelector} from "../../../../../redux/Hooks";
import {
  selectAvailablePolicyExtras,
  selectPolicyExtras,
  selectRebrokedQuote,
  selectRenewalDepositPercentage,
  selectRenewalQuote
} from "../../redux/RenewalSelectors";
import {policyExtrasChanged} from "../../redux/RenewalSlice";
import {loadRenewalProspectiveLoans} from "../../redux/thunks/LoadRenewalProspectiveLoans";
import {usePolicyQueryParams} from "../../../../../router/params/Params";
import Button from "shared-components/dist/buttons/button/Button";
import ExcessProtectionOptionModal from "../excess-protection-option-modal/ExcessProtectionOptionModal";
import {
  getProspectivePolicyExtraByType,
  getProspectivePolicyExtraOptionByOptionType
} from "../../../shared/utils/ProspectivePolicyExtraUtils";
import {upsertRebrokeExtras} from "../../redux/thunks/UpsertRebrokeExtras";

const PolicyExtraSidebarSelectorList: FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const {policyId} = usePolicyQueryParams();
  const dispatch = useAppDispatch();
  const availablePolicyExtras = useAppSelector(selectAvailablePolicyExtras);
  const policyExtras = useAppSelector(selectPolicyExtras);
  const depositPercentage = useAppSelector(selectRenewalDepositPercentage);
  const renewalQuote = useAppSelector(selectRenewalQuote);
  const rebrokeQuote = useAppSelector(selectRebrokedQuote);

  const togglePolicyExtra = (extraType: keyof RenewalPolicyExtras): void => {
    const newPolicyExtras: RenewalPolicyExtras = {
      ...policyExtras,
      [extraType]: {...policyExtras[extraType], enabled: !policyExtras[extraType].enabled}
    };
    dispatch(policyExtrasChanged(newPolicyExtras));

    if (renewalQuote) dispatch(loadRenewalProspectiveLoans({
      policyId,
      depositPercentage,
      policyExtras: newPolicyExtras
    }));
    if (rebrokeQuote && rebrokeQuote.reference && rebrokeQuote.sequenceNumber) {
      dispatch(upsertRebrokeExtras({
        quoteReference: rebrokeQuote.reference,
        quoteSequenceNumber: rebrokeQuote.sequenceNumber,
        policyExtras: newPolicyExtras,
        depositPercentage,
      }));
    }
  };

  return (
    <>
      <div className="policy-extra-sidebar-selector-list" data-testid="policy-extra-sidebar-selector-list">
        <PolicyExtraSidebarSelectorListItem
          testId="smartfob-keycare"
          title="portal.renewals.summary.extrasTable.smartfobKeycare.title"
          description="portal.renewals.summary.extrasTable.smartfobKeycare.description"
          price={getProspectivePolicyExtraByType(availablePolicyExtras, PolicyExtraType.SmartfobKeycare)?.options?.[0]?.price}
          included={policyExtras.smartfobKeycare.enabled}
          toggleIncluded={() => togglePolicyExtra("smartfobKeycare")}
        />

        <PolicyExtraSidebarSelectorListItem
          testId="roadside-assistance"
          title="portal.renewals.summary.extrasTable.roadsideAssistance.title"
          description="portal.renewals.summary.extrasTable.roadsideAssistance.description"
          price={getProspectivePolicyExtraByType(availablePolicyExtras, PolicyExtraType.RoadsideAssistancePlatinum)?.options?.[0]?.price}
          included={policyExtras.roadsideAssistancePlatinum.enabled}
          toggleIncluded={() => togglePolicyExtra("roadsideAssistancePlatinum")}
        />

        <PolicyExtraSidebarSelectorListItem
          testId="courtesy-car"
          title="portal.renewals.summary.extrasTable.courtesyCar.title"
          description="portal.renewals.summary.extrasTable.courtesyCar.description"
          price={getProspectivePolicyExtraByType(availablePolicyExtras, PolicyExtraType.CourtesyCar)?.options?.[0]?.price}
          included={policyExtras.courtesyCar.enabled}
          toggleIncluded={() => togglePolicyExtra("courtesyCar")}
        />

        <PolicyExtraSidebarSelectorListItem
          testId="legal-expenses"
          title="portal.renewals.summary.extrasTable.legalExpenses.title"
          description="portal.renewals.summary.extrasTable.legalExpenses.description"
          price={getProspectivePolicyExtraByType(availablePolicyExtras, PolicyExtraType.LegalExpenses)?.options?.[0]?.price}
          included={policyExtras.legalExpenses.enabled}
          toggleIncluded={() => togglePolicyExtra("legalExpenses")}
        />

        <PolicyExtraSidebarSelectorListItem
          testId="excess-insurance"
          title="portal.renewals.summary.extrasTable.excessInsurance.title"
          description="portal.renewals.summary.extrasTable.excessInsurance.description"
          price={getProspectivePolicyExtraOptionByOptionType(availablePolicyExtras, PolicyExtraType.ExcessInsurance, policyExtras.excessInsurance.type)?.price}
          included={policyExtras.excessInsurance.enabled}
          toggleIncluded={() => togglePolicyExtra("excessInsurance")}
          priceCellChildren={
            <Button
              className="policy-extra-sidebar-selector-list__item__link-button"
              variant="link"
              onClick={() => setIsVisible(true)}
            >
              {getProspectivePolicyExtraOptionByOptionType(availablePolicyExtras, PolicyExtraType.ExcessInsurance, policyExtras.excessInsurance.type)?.description} -
              Edit
            </Button>
          }
        />
      </div>

      <ExcessProtectionOptionModal isVisible={isVisible} setIsVisible={setIsVisible}/>
    </>
  );
};

export default PolicyExtraSidebarSelectorList;
