import React, {FC, useState} from "react";
import {coverStartDateAsDateOptionSelector} from "../../../../your-cover/redux/selectors/CoverDetailsSelectors";
import Question from "../../../../../../../structure/questions/question/Question";
import SummaryListContainer from "shared-components/dist/lists/summary-list/components/summary-list-container/SummaryListContainer";
import SummaryListItem from "shared-components/dist/lists/summary-list/components/summary-list-item/SummaryListItem";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import Button from "shared-components/dist/buttons//button/Button";
import EditCoverSummary from "../edit-cover-summary/EditCoverSummary";
import "./QuoteCoverSummary.css";
import {logYourQuoteGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {totalPolicyExcessSelector} from "../../../redux/TotalPolicyExcessSelector";
import {toGregorianFormat} from "shared/dist/stdlib/DateFormat";
import {useAppSelector} from "../../../../../../../redux/Hooks";
import {useIsEditFlowEnabledForQuote} from "../../../../your-quote/hooks/IsEditFlowEnabledForQuote";

const QuoteCoverSummary: FC = () => {
  const coverStartDate = useAppSelector(state => coverStartDateAsDateOptionSelector(state).value?.date);
  const coverType = useAppSelector(state => state.coverDetails.coverType.value?.description);
  const totalPolicyExcess = useAppSelector(totalPolicyExcessSelector);
  const isEditFlowEnabled = useIsEditFlowEnabledForQuote();

  const [editCoverSummary, setEditCoverSummary] = useState(false);

  const showEditCoverSummaryModal = (): void => {
    setEditCoverSummary(true);
    logYourQuoteGoogleAnalyticsEvent({
      categorySuffix: "cover_summary_modal.edit",
      action: "Click"
    });
  };

  return (
    <>
      <Question
        title="quoteDetails.summary.cover.title"
        id="your-cover-summary"
      >
        <SummaryListContainer className="quote-cover-summary">
          <SummaryListItem
            title="quoteDetails.summary.coverStartDate"
            value={coverStartDate ? toGregorianFormat(coverStartDate) : undefined}
          />

          <SummaryListItem
            title="quoteDetails.summary.policyExcess"
            value={`£${totalPolicyExcess}`}
          />

          <SummaryListItem
            title="quoteDetails.summary.coverType"
            value={coverType}
          />

          {isEditFlowEnabled &&
            <div className="summary-list-container__button">
              <Button
                className="quote-summary-edit-button"
                variant="ghost"
                onClick={showEditCoverSummaryModal}
                expanded
              >
                {lookupI18nString("structure.lists.summaryList.button")}
              </Button>
            </div>
          }
        </SummaryListContainer>
      </Question>

      <EditCoverSummary
        visible={editCoverSummary}
        onDismiss={() => setEditCoverSummary(false)}
      />
    </>
  );
};

export default QuoteCoverSummary;

