import React, {FC} from "react";
import {maritalStatusChanged} from "../../redux/PersonalDetailsSlice";
import MaritalStatusQuestion from "../../../shared/questions/martial-status/MaritalStatusQuestion";
import {MaritalStatusOption} from "../../../shared/questions/martial-status/MaritalStatusOption";
import {logYourDetailsGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import ProposerInvalidMaritalStatusStop from "./ProposerInvalidMaritalStatusStop";
import FormattedMessage from "shared-components/src/translations/components/FormattedMessage";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const ProposerMaritalStatusQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const maritalStatusField = useAppSelector(state => state.personalDetails.maritalStatus);

  const onMartialStatusChanged = (answer: MaritalStatusOption): void => {
    logYourDetailsGoogleAnalyticsEvent({
      categorySuffix: "relationship_status",
      action: "Answered",
    });
    dispatch(maritalStatusChanged(answer));
  };

  return (
    <>
      <MaritalStatusQuestion
        id="proposer-marital-status-question"
        title="personalDetails.maritalStatusQuestion"
        maritalStatusField={maritalStatusField}
        tooltip={{description: (
            <FormattedMessage
              id="personalDetails.maritalStatusQuestion.tooltip.description"
              values={{
                semiBold: (msg: string) => <span className="semi-bold">{msg}</span>
              }}
            />
          )}}
        onComplete={onMartialStatusChanged}
        placeholder="personalDetails.maritalStatusQuestion.dropdownPlaceholder"
      />
      <ProposerInvalidMaritalStatusStop/>
    </>
  );
};

export default ProposerMaritalStatusQuestion;
