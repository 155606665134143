import {AppThunk} from "../../../../../../../../redux/Store";
import {getCurrentAuthenticatedUser} from "../../../../../../../../user/authentication/services/UserAuthenticationService";
import {changeUserPassword} from "../../../../../../../../user/authentication/services/UserPasswordManagementService";
import {userAttemptedToChangePassword} from "../../../../../../../user-authentication/shared/redux/UserAuthenticationSlice";
import {sendPasswordSuccessfullyUpdatedEmail} from "../../../../../../../../user/authentication/services/SendPasswordSuccessfullyUpdatedEmail";

export const changePassword = (
  currentPassword: string,
  newPassword: string
): AppThunk => async (dispatch) => {
  const cognitoUser = await getCurrentAuthenticatedUser();
  if (!cognitoUser) return dispatch(userAttemptedToChangePassword("Failed"));

  const changePasswordResult = await changeUserPassword(cognitoUser, currentPassword, newPassword);
  if (changePasswordResult === "Success") await sendPasswordSuccessfullyUpdatedEmail();

  dispatch(userAttemptedToChangePassword(changePasswordResult));
};
