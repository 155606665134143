import {SimpleQueryResult} from "../../../apollo/SimpleQueryResult";
import {GetConvictionTypesResult, useConvictionTypesQuery} from "./models/Query";
import {ConvictionTypeItem} from "shared/dist/generated/graphql/resolvers-types";
import {DRIVING_WHILE_USING_MOBILE_PHONE_CONVICTION_TYPE} from "../../../../pages/quote/vehicle/shared/questions/convictions/validation/IsMobilePhoneConvictionType";

export function useConvictionTypes(): SimpleQueryResult<ConvictionTypeItem[]> {
  const queryResult = useConvictionTypesQuery();
  return {
    loading: queryResult.loading,
    error: queryResult.error,
    data: mapResult(queryResult.data)
  };
}

function mapResult(queryResult: GetConvictionTypesResult | undefined): ConvictionTypeItem[] {
  if (!queryResult) return [];

  if (!queryResult.convictions || !queryResult.convictions.types) throw Error("Critical failure - Invalid conviction types response from GraphQL");

  return addDrivingWhileUsingMobilePhoneTo(queryResult.convictions.types);
}

function addDrivingWhileUsingMobilePhoneTo(convictionTypes: ConvictionTypeItem[]): ConvictionTypeItem[] {
  return convictionTypes
    .concat(DRIVING_WHILE_USING_MOBILE_PHONE_CONVICTION_TYPE)
    .sort((a, b) => {
      if (a.parentCategoryDescription === "Other") return 1;
      if (b.parentCategoryDescription === "Other") return -1;

      return a.parentCategoryDescription.localeCompare(b.parentCategoryDescription);
    });
}
