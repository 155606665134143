import {Vehicle} from "../../../../../../graphql/queries/vehicle-lookup/registration-lookup/model/Vehicle";
import {createSelector} from "@reduxjs/toolkit";
import {RootState} from "../../../../../../redux/Store";
import {DriverPosition} from "../../../../../../graphql/queries/vehicle-lookup/registration-lookup/model/DriverPosition";
import {ImportType} from "../../../../../../graphql/queries/vehicle-lookup/registration-lookup/model/ImportType";
import {
  regLookupVehicleSelector,
  validRegLookupVehicleAssumptionsSelector,
  validRegLookupVehicleSummarySelector
} from "./RegLookupVehicleSelectors";
import {validManualLookupVehicleSelector} from "./ManualLookupVehicleSelectors";
import {shouldUseMainTrackerQuestion} from "./ValidatedAdditionalVehicleDetailsFromFormInput";
import {BooleanFormField} from "shared-components/dist/models/form-field/variants/BooleanFormField";
import {FormField, isValidFormField} from "shared-components/dist/models/form-field/FormField";

export const assumptionsCorrectSelector = (state: RootState): boolean | undefined => state.vehicleDetails.assumptionsCorrect.value;
const mainHasTrackerQuestionSelector = (state: RootState): BooleanFormField => state.vehicleDetails.hasTracker;

const driverPositionFormFieldSelector = (state: RootState): FormField<DriverPosition | undefined> => state.vehicleDetails.additionalVehicleDetails.driverPosition;
const importedFormFieldSelector = (state: RootState): BooleanFormField => state.vehicleDetails.additionalVehicleDetails.imported;
const importTypeFormFieldSelector = (state: RootState): FormField<ImportType | undefined> => state.vehicleDetails.additionalVehicleDetails.importType;

export const vehicleAssumptionsVisibleSelector = createSelector(
  validRegLookupVehicleSummarySelector,
  validRegLookupVehicleAssumptionsSelector,
  (isValidVehicleSummary: boolean, isValidAssumptions: boolean): boolean => isValidVehicleSummary && isValidAssumptions
);

export const additionalVehicleDetailsFormVisibleSelector = createSelector(
  validRegLookupVehicleSummarySelector,
  validRegLookupVehicleAssumptionsSelector,
  assumptionsCorrectSelector,
  validManualLookupVehicleSelector,
  (
    hasRegLookupSummary,
    hasRegLookupAssumptions,
    assumptionsCorrect,
    hasManualLookupVehicle,
  ): boolean => {
    if (hasRegLookupSummary) {
      return !hasRegLookupAssumptions || assumptionsCorrect === false;
    }
    return hasManualLookupVehicle;
  }
);

export const driverPositionSelector = createSelector(
  regLookupVehicleSelector,
  driverPositionFormFieldSelector,
  assumptionsCorrectSelector,
  (
    regLookupVehicle: Vehicle | undefined,
    driverPositionField: FormField<DriverPosition | undefined>,
    assumptionsCorrect: boolean | undefined
  ): DriverPosition | undefined =>
    (regLookupVehicle && assumptionsCorrect) ? regLookupVehicle.driverPosition : driverPositionField.value
);

export const importedSelector = createSelector(
  regLookupVehicleSelector,
  importedFormFieldSelector,
  assumptionsCorrectSelector,
  (
    regLookupVehicle: Vehicle | undefined,
    importedField: BooleanFormField,
    assumptionsCorrect: boolean | undefined
  ): boolean | undefined =>
    (regLookupVehicle && assumptionsCorrect) ? regLookupVehicle.imported : importedField.value
);

export const importTypeSelector = createSelector(
  regLookupVehicleSelector,
  importTypeFormFieldSelector,
  assumptionsCorrectSelector,
  (
    regLookupVehicle: Vehicle | undefined,
    importTypeField: FormField<ImportType | undefined>,
    assumptionsCorrect: boolean | undefined
  ): ImportType | undefined =>
    (regLookupVehicle && assumptionsCorrect) ? regLookupVehicle.importType : importTypeField.value
);

export const shouldShowAssumptionsLeftHandDriveVehicleStopSelector = createSelector(
  driverPositionSelector,
  importedSelector,
  importTypeSelector,
  assumptionsCorrectSelector,
  (
    driverPosition: DriverPosition | undefined,
    imported: boolean | undefined,
    importType: ImportType | undefined,
    assumptionsCorrect: boolean | undefined
  ): boolean =>
    driverPosition === "Left" && !vehicleIsImported(imported, importType) && assumptionsCorrect === true
);

export const shouldShowAdditionalDetailsLeftHandDriveVehicleStopSelector = createSelector(
  driverPositionSelector,
  importedSelector,
  importTypeSelector,
  assumptionsCorrectSelector,
  vehicleAssumptionsVisibleSelector,
  (
    driverPosition: DriverPosition | undefined,
    imported: boolean | undefined,
    importType: ImportType | undefined,
    assumptionsCorrect: boolean | undefined,
    assumptionsVisible: boolean
  ): boolean =>
    driverPosition === "Left" && !vehicleIsImported(imported, importType) && (assumptionsCorrect === false || !assumptionsVisible)
);

export const shouldShowAssumptionsEuImportVehicleStopSelector = createSelector(
  importedSelector,
  importTypeSelector,
  assumptionsCorrectSelector,
  (
    imported: boolean | undefined,
    importType: ImportType | undefined,
    assumptionsCorrect: boolean | undefined
  ): boolean =>
    vehicleIsEuImport(imported, importType) && assumptionsCorrect === true
);

export const shouldShowAssumptionsNonEuImportVehicleStopSelector = createSelector(
  importedSelector,
  importTypeSelector,
  assumptionsCorrectSelector,
  (
    imported: boolean | undefined,
    importType: ImportType | undefined,
    assumptionsCorrect: boolean | undefined
  ): boolean =>
    vehicleIsNonEuImport(imported, importType) && assumptionsCorrect === true
);

export const shouldShowAdditionalDetailsEuImportVehicleStopSelector = createSelector(
  importedSelector,
  importTypeSelector,
  assumptionsCorrectSelector,
  vehicleAssumptionsVisibleSelector,
  (
    imported: boolean | undefined,
    importType: ImportType | undefined,
    assumptionsCorrect: boolean | undefined,
    assumptionsVisible: boolean
  ): boolean =>
    vehicleIsEuImport(imported, importType) && (assumptionsCorrect === false || !assumptionsVisible)
);

export const shouldShowAdditionalDetailsNonEuImportVehicleStopSelector = createSelector(
  importedSelector,
  importTypeSelector,
  assumptionsCorrectSelector,
  vehicleAssumptionsVisibleSelector,
  (
    imported: boolean | undefined,
    importType: ImportType | undefined,
    assumptionsCorrect: boolean | undefined,
    assumptionsVisible: boolean
  ): boolean =>
    vehicleIsNonEuImport(imported, importType) && (assumptionsCorrect === false || !assumptionsVisible)
);

const vehicleIsImported = (imported: boolean | undefined, importType: ImportType | undefined): boolean => {
  return imported === true && importType !== undefined;
};

const vehicleIsEuImport = (imported: boolean | undefined, importType: ImportType | undefined): boolean => {
  return imported === true && importType === "EU";
};

const vehicleIsNonEuImport = (imported: boolean | undefined, importType: ImportType | undefined): boolean => {
  return imported === true && importType === "NonEU";
};

export const mainHasTrackerQuestionVisibleSelector = createSelector(
  regLookupVehicleSelector,
  (vehicle: Vehicle | undefined) => {
    return shouldUseMainTrackerQuestion(vehicle);
  }
);

export const mainHasTrackerQuestionInSuccessState = createSelector(
  mainHasTrackerQuestionSelector,
  (mainHasTrackerQuestion: BooleanFormField) => {
    return isValidFormField(mainHasTrackerQuestion);
  }
);
