import React, {FC} from "react";
import {APPLE_STORE_URL} from "shared/dist/constants/Urls";
import {ReactComponent as AppleAppStoreLogoSmall} from "../../../assets/icons/logos/app-stores/apple-small.svg";
import "./AppStoreButton.css";
import AppStoreButton from "./AppStoreButton";

interface Props {
  onClick?: () => void;
}

const AppleAppStoreButtonSmall: FC<Props> = ({onClick}) => (
  <AppStoreButton
    onClick={onClick}
    icon={<AppleAppStoreLogoSmall/>}
    url={APPLE_STORE_URL}
  />
);

export default AppleAppStoreButtonSmall;