import React, {FC} from "react";
import SubQuestion from "../../../../../../../structure/questions/sub-question/SubQuestion";
import {seatsChanged, seatsInvalid} from "../../../redux/VehicleDetailsSlice";
import IntegerInputField from "shared-components/dist/form/integer-input-field/IntegerInputField";
import {isAboveMin, isBelowMax} from "shared-components/dist/utils/validators/NumberValidators";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const MIN_SEATS = 2;
const MAX_SEATS = 7;

const AdditionalDetailsSeats: FC = () => {
  const dispatch = useAppDispatch();
  const seats = useAppSelector(state => state.vehicleDetails.additionalVehicleDetails.seats);

  return (
    <SubQuestion
      title="vehicleQuote.additionalVehicleDetailsForm.seats"
      id="seats-question"
      errorMessage={seats.errorMessage}
      status={seats.status}
    >
      <IntegerInputField
        id="seats-input"
        onComplete={value => dispatch(seatsChanged(value))}
        onInvalid={errorMessage => dispatch(seatsInvalid(errorMessage))}
        validators={[
          isAboveMin(MIN_SEATS, "vehicleQuote.additionalVehicleDetailsForm.seats.errors.belowMinimum"),
          isBelowMax(MAX_SEATS, "vehicleQuote.additionalVehicleDetailsForm.seats.errors.aboveMaximum")
        ]}
        value={seats.value}
        min={MIN_SEATS}
        max={MAX_SEATS}
        placeholder="vehicleQuote.additionalVehicleDetailsForm.seats.placeholder"
        showButtons
      />
    </SubQuestion>
  );
};

export default AdditionalDetailsSeats;
