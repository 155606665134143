import {
  validationFailure,
  ValidationResult,
  validationSuccess,
  Validator
} from "shared-components/dist/utils/validation/Validator";
import {isBefore, isSameDay} from "date-fns";
import {subYears} from "date-fns/fp";
import {now} from "shared/dist/stdlib/Dates";

export function isBelowMaximumAge(): Validator<Date> {
  return (dateOfBirth: Date): ValidationResult => {
    return isBelowMaximumAgeOn(now(), dateOfBirth)
      ? validationSuccess
      : validationFailure("personalDetails.dateOfBirthQuestion.errors.isTooOld");
  };
}

export function isBelowMaximumAgeOn(target: Date, dateOfBirth: Date): boolean {
  const ninetyFiveYearsAgo = minusNinetyFiveYears(target);

  return !(isBefore(dateOfBirth, ninetyFiveYearsAgo) || isSameDay(dateOfBirth, ninetyFiveYearsAgo));
}

const minusNinetyFiveYears = subYears(95);