import {
  validationFailure,
  ValidationResult,
  validationSuccess,
  Validator
} from "shared-components/dist/utils/validation/Validator";
import {isBelowMaximumAgeOn} from "./IsBelowMaximumAge";

export function isBelowMaximumAgeOnCoverStartDate(coverStartDate?: Date): Validator<Date> {
  return (dateOfBirth: Date): ValidationResult => {
    if (!coverStartDate) return validationSuccess;

    return isBelowMaximumAgeOn(coverStartDate, dateOfBirth)
      ? validationSuccess
      : validationFailure("personalDetails.dateOfBirthQuestion.errors.isTooOld.onStartDate");
  };
}
