import React, {FC, KeyboardEvent} from "react";
import "./Switch.scss";

interface Props {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}

const Switch: FC<Props> = ({value, onChange, disabled}) => {
  const getClassName = (): string => {
    let className = `switch switch--${value ? "active" : "inactive"}`;
    if (disabled) className += " switch--disabled";
    return className;
  };

  const onChangeHandler = (): void => {
    if (!disabled) onChange(!value);
  };

  const doOnKeyUp = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === " ") onChangeHandler();
  };

  const doOnKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === " ") event.preventDefault();
  };

  return (
    <div
      data-testid="switch"
      onClick={onChangeHandler}
      className={getClassName()}
      tabIndex={0}
      onKeyUp={doOnKeyUp}
      onKeyDown={doOnKeyDown}
    >
      <div className="switch__track"/>
      <div className="switch__knob"/>
    </div>
  );
};

export default Switch;