import {FC} from "react";
import "./InsurerQuoteCardRow.css";
import classNames from "classnames";

interface Props {
  id: string;
  description: string;
  value: string;
  className?: string;
}

const InsurerQuoteCardRow: FC<Props> = (
  {
    id,
    description,
    value,
    className
  }) => (
  <div className={classNames("insurer-quote-card-row", className)}>
    <div id={id} className="insurer-quote-card-row__item">
      <p>{description}</p>
      <p>{value}</p>
    </div>
    <hr/>
  </div>
);

export default InsurerQuoteCardRow;