import {RootState} from "../../../../../../redux/Store";
import {VehicleSummary} from "../../../../../../graphql/queries/vehicle-lookup/registration-lookup/model/Vehicle";
import {validatedVehicleSummaryFromManualLookup} from "./ValidatedVehicleFromManualLookup";
import {createSelector} from "@reduxjs/toolkit";
import {manualVehicleLookupFormCompleted} from "./ManualVehicleLookupFormSelectors";

export const manualLookupVehicleSummarySelector = (state: RootState): VehicleSummary | undefined => {
  if (manualVehicleLookupFormCompleted(state)) {
    return validatedVehicleSummaryFromManualLookup(state);
  }
};

export const validManualLookupVehicleSelector = createSelector(
  manualLookupVehicleSummarySelector,
  (manualLookupVehicleSummary): boolean => manualLookupVehicleSummary !== undefined
);
