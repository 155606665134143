import {validationFailure, validationSuccess, Validator} from "shared-components/dist/utils/validation/Validator";
import {getAge} from "shared/dist/stdlib/Age";

const MINIMUM_AGE_TO_BE_A_HOMEOWNER = 18;

export const isOldEnoughToBeAHomeowner = (isHomeowner: boolean | undefined): Validator<Date> => {
  return dateOfBirth => {
    if (!isHomeowner || isAgeHighEnoughToBeHomeowner(dateOfBirth)) {
      return validationSuccess;
    }

    return validationFailure({
      id: "personalDetails.dateOfBirthQuestion.errors.isTooYoung.toBeAHomeowner",
      templateVariables: {
        age: getAge(dateOfBirth)
      }
    });
  };
};

export const isEligibleToBeHomeownerGivenTheDateOfBirth = (dateOfBirth: Date | undefined): Validator<boolean> => {
  if (!dateOfBirth) return () => validationSuccess;

  return isHomeowner => isOldEnoughToBeAHomeowner(isHomeowner)(dateOfBirth);
};

const isAgeHighEnoughToBeHomeowner = (dateOfBirth: Date): boolean => (
  getAge(dateOfBirth) >= MINIMUM_AGE_TO_BE_A_HOMEOWNER
);
