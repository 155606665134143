import React, {FC} from "react";
import "./ManualVehicleLookup.css";
import YourVehicleMake from "./your-vehicle-make/YourVehicleMake";
import YourVehicleModel from "./your-vehicle-model/YourVehicleModel";
import Button from "shared-components/dist/buttons/button/Button";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import YourVehicleFuelType from "./your-vehicle-fuel-type/YourVehicleFuelType";
import {manualVehicleLookupFormOpened} from "../../redux/ManualVehicleLookupFormSlice";
import YourVehicleTransmissionType from "./your-vehicle-transmission-type/YourVehicleTransmissionType";
import YourVehicleEngineCapacityLitres from "./your-vehicle-engine-capacity-litres/YourVehicleEngineCapacityLitres";
import YourVehicleTrimLevel from "./your-vehicle-trim-level/YourVehicleTrimLevel";
import YourVehicleYearOfManufacture from "./your-vehicle-year-of-manufacture/YourVehicleYearOfManufacture";
import YourVehicleFullSpec from "./your-full-vehicle-spec/YourVehicleFullSpec";
import SubQuestionForm from "../../../../../../structure/questions/sub-question-form/SubQuestionForm";
import {vehicleDetailsReset} from "../../../../../../redux/Actions";
import {logYourCarGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const ManualVehicleLookup: FC = () => {
  const dispatch = useAppDispatch();
  const manualLookupForm = useAppSelector(state => state.manualVehicleLookupForm);
  const make = useAppSelector(state => state.manualVehicleLookupForm.make);

  function onManualVehicleLookupFormOpened(): void {
    dispatch(vehicleDetailsReset());
    dispatch(manualVehicleLookupFormOpened());

    logYourCarGoogleAnalyticsEvent({
      categorySuffix: "search_by_make_and_model",
      action: "Click"
    });
  }

  return (
    <div className="manual-vehicle-lookup">
      <div className="manual-vehicle-lookup__link">
        <p>or,</p>

        <Button variant="link" onClick={onManualVehicleLookupFormOpened}>
          {lookupI18nString("vehicleQuote.manualVehicleLookup.manualButton")}
        </Button>
      </div>

      {make.isActive &&
        <SubQuestionForm>
          <YourVehicleMake/>
          {manualLookupForm.model.isActive && <YourVehicleModel/>}
          {manualLookupForm.fuelType.isActive && <YourVehicleFuelType/>}
          {manualLookupForm.transmissionType.isActive && <YourVehicleTransmissionType/>}
          {manualLookupForm.yearOfManufacture.isActive && <YourVehicleYearOfManufacture/>}
          {manualLookupForm.engineCapacityLitres.isActive && <YourVehicleEngineCapacityLitres/>}
          {manualLookupForm.trimLevel.isActive && <YourVehicleTrimLevel/>}
          {manualLookupForm.fullSpec.isActive && <YourVehicleFullSpec/>}
        </SubQuestionForm>
      }
    </div>
  );
};

export default ManualVehicleLookup;
