import React, {FC} from "react";
import AccountSettingsUserInformation from "./acount-settings-user-information/AccountSettingsUserInformation";
import CustomerPortalContainerWithoutSidebar from "../shared/container/CustomerPortalContainerWithoutSidebar";
import AccountSettingsHeader from "./account-settings-header/AccountSettingsHeader";

const AccountSettings: FC = () => (
  <CustomerPortalContainerWithoutSidebar
    header={<AccountSettingsHeader/>}
    mainContent={
      <AccountSettingsUserInformation/>
    }
  />
);

export default AccountSettings;
