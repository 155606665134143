import {
  PolicyExtraOptionType,
  PolicyExtraType,
  ProspectivePolicyExtra,
  ProspectivePolicyExtraOption
} from "shared/dist/generated/graphql/resolvers-types";

export const getProspectivePolicyExtraByType = (availablePolicyExtras: ProspectivePolicyExtra[], extraType: PolicyExtraType): ProspectivePolicyExtra | undefined => {
  return availablePolicyExtras.find(extra => extra.type === extraType);
};

export const getProspectivePolicyExtraOptionByOptionType = (
  availablePolicyExtras: ProspectivePolicyExtra[],
  extraType: PolicyExtraType,
  extraOptionType: PolicyExtraOptionType
): ProspectivePolicyExtraOption | undefined => {
  return getProspectivePolicyExtraByType(availablePolicyExtras, extraType)?.options.find(option => option.optionType === extraOptionType);
};
