import React, {FC} from "react";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import DropdownSubQuestion
  from "../../../../../../../structure/questions/sub-question/variants/dropdown-sub-question/DropdownSubQuestion";
import {EMPLOYMENT_STATUS_OPTIONS, EmploymentStatusOption} from "../models/EmploymentStatusOption";
import {ConditionalFormField} from "shared-components/dist/models/form-field/ConditionalFormField";

export interface SecondaryEmploymentStatusQuestionDisplayProps {
  employmentStatusQuestionTitle: TranslationKey;
  employmentStatusQuestionPlaceholder: TranslationKey;
}

interface OwnProps {
  idPrefix: string;
  employmentStatus: ConditionalFormField<EmploymentStatusOption>;
  employmentStatusChanged: (selection: string) => void;
}

type Props = SecondaryEmploymentStatusQuestionDisplayProps & OwnProps;

const SecondaryEmploymentStatusSubQuestion: FC<Props> = (
  {
    idPrefix,
    employmentStatusQuestionTitle,
    employmentStatus,
    employmentStatusChanged,
    employmentStatusQuestionPlaceholder,
  }
) => (
  <DropdownSubQuestion
    id={`${idPrefix}-secondary-employment-status-sub-question`}
    title={employmentStatusQuestionTitle}
    value={employmentStatus.value?.description}
    onChange={employmentStatusChanged}
    status={employmentStatus.status}
    errorMessage={employmentStatus.errorMessage}
    options={EMPLOYMENT_STATUS_OPTIONS}
    placeholder={employmentStatusQuestionPlaceholder}
  />
);

export default SecondaryEmploymentStatusSubQuestion;