import {CognitoUser, CognitoUserSession, IAuthenticationCallback} from "amazon-cognito-identity-js";
import {createAuthenticationDetails, createCognitoUser, userPool} from "../../providers/CognitoProviders";
import {LoginResponse} from "../models/login/LoginResponse";
import {getCurrentAvailableSession} from "../session/SessionManager";
import {logger} from "../../../utils/logging/Logger";
import {clearCachedUserAttributes, InternalCognitoUser} from "../../attributes/services/get/GetUserAttributesService";

export async function authenticateUser(email: string, password: string): Promise<LoginResponse> {
  const authenticationDetails = createAuthenticationDetails(email, password);
  const cognitoUser = createCognitoUser(email, userPool);

  return new Promise<LoginResponse>(resolve => {
    cognitoUser.authenticateUser(
      authenticationDetails,
      mapAuthenticationResponseToPromise(resolve, cognitoUser)
    );
  });
}

function mapAuthenticationResponseToPromise(resolve: (value: LoginResponse) => void, cognitoUser: CognitoUser): IAuthenticationCallback {
  return {
    onSuccess: () => {
      logger.log("Authentication successful");
      resolve({
        loginResult: "Success",
        cognitoUser
      });
    },
    onFailure: (error) => {
      logger.error("Authentication failed for user:", cognitoUser.getUsername(), error);
      if (error.code === "NotAuthorizedException" && error.message === "Incorrect username or password.") {
        resolve({
          loginResult: "InvalidCredentials"
        });
      }
      if (error.code === "NotAuthorizedException" && error.message === "Password attempts exceeded") {
        resolve({
          loginResult: "AttemptsExceeded"
        });
      }

      resolve({
        loginResult: "Failed"
      });
    },
    newPasswordRequired: () => {
      logger.log("New password required");
      resolve({
        loginResult: "ResetPassword",
        cognitoUser
      });
    },
    mfaRequired: () => handleUnsupportedAuthenticationResponse("mfaRequired not supported", resolve),
    totpRequired: () => handleUnsupportedAuthenticationResponse("totpRequired not supported", resolve),
    customChallenge: () => handleUnsupportedAuthenticationResponse("customChallenge not supported", resolve),
    mfaSetup: () => handleUnsupportedAuthenticationResponse("mfaSetup not supported", resolve),
    selectMFAType: () => handleUnsupportedAuthenticationResponse("selectMFAType not supported", resolve),
  };
}

function handleUnsupportedAuthenticationResponse(message: string, resolve: (loginResponse: LoginResponse) => void): void {
  logger.error("Handling unsupported authentication attempt", message);
  resolve({loginResult: "UnsupportedAuthenticationResponse"});
}

export function signOutUser(): void {
  const currentUser = userPool.getCurrentUser();
  if (currentUser) {
    clearCachedUserAttributes(currentUser as InternalCognitoUser);
    currentUser.signOut();
  }
}

export async function getCurrentAuthenticatedUser(): Promise<CognitoUser | undefined> {
  const currentUser = userPool.getCurrentUser();
  if (!currentUser) {
    logger.error("Could not get current user from User Pool");
    return undefined;
  }

  const userSession = await refreshUserSession(currentUser);
  if (!userSession) {
    logger.error("Failed to refresh user session");
    return undefined;
  }

  return currentUser;
}

async function refreshUserSession(currentUser: CognitoUser): Promise<CognitoUserSession | undefined> {
  try {
    return await getCurrentAvailableSession(currentUser);
  } catch (error) {
    logger.error("Failed to get user session", error);
    return undefined;
  }
}
