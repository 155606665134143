import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RenewalDetails} from "./RenewalDetails";
import {PaymentFrequency} from "../../../quote/vehicle/your-quote/models/SavedQuote";
import {loadRenewalProspectiveLoans} from "./thunks/LoadRenewalProspectiveLoans";
import {RenewalPolicyExtras} from "../models/RenewalPolicyExtras";
import {PolicyExtraOptionType} from "shared/dist/generated/graphql/resolvers-types";
import {loadRenewalSummary} from "./thunks/LoadRenewalSummary";
import {userAttemptedToLogout} from "../../../../redux/Actions";
import {RenewalQuoteType} from "../models/RenewalQuoteType";
import {upsertRebrokeExtras} from "./thunks/UpsertRebrokeExtras";
import {RenewalQuote} from "../../../quote/vehicle/your-quote/models/RenewalQuote";
import {loadRebrokeProspectiveLoans} from "./thunks/LoadRebrokeProspectiveLoans";
import {loadRebrokedQuote} from "./thunks/LoadRebrokedQuote";

export const initialState: RenewalDetails = {
  depositPercentage: 20,
  availablePolicyExtras: [],
  policyExtras: {
    courtesyCar: {
      enabled: false,
      type: PolicyExtraOptionType.CourtesyCar
    },
    legalExpenses: {
      enabled: false,
      type: PolicyExtraOptionType.LegalExpenses
    },
    smartfobKeycare: {
      enabled: false,
      type: PolicyExtraOptionType.SmartfobKeycare
    },
    roadsideAssistancePlatinum: {
      enabled: false,
      type: PolicyExtraOptionType.RoadsideAssistancePlatinum
    },
    excessInsurance: {
      enabled: false,
      type: PolicyExtraOptionType.ExcessInsurance1
    }
  },
  paymentFrequency: undefined,
  renewalNotice: undefined,
  loadRenewalSummaryStatus: "default",
  loadRebrokedQuoteStatus: "default",
  loadRenewalProspectiveLoansStatus: "default",
  loadRebrokeProspectiveLoansStatus: "default",
  extrasTotalAmount: undefined,
  noClaimsBonusProtectionCost: undefined,
  noClaimsBonusProtectionData: undefined,
  policy: undefined,
  policyHolder: undefined,
  rebrokedQuote: undefined,
  renewalQuote: undefined,
  selectedQuoteType: undefined,
  insurerName: undefined,
  insurerSchemeReference: undefined,
  prospectiveLoan: undefined,
  premium: undefined,
  adminFee: undefined,
  brokerDiscount: undefined,
  totalFinancedAmountPayable: undefined,
  totalUnfinancedAmountPayable: undefined,
  shouldProtectNoClaimsBonus: undefined,
  allDetailsConfirmed: false
};

const renewalSlice = createSlice({
  name: "renewal",
  initialState,
  reducers: {
    paymentFrequencyChanged(state, action: PayloadAction<PaymentFrequency>): void {
      state.paymentFrequency = action.payload;
    },
    depositPercentageChanged(state, action: PayloadAction<number>): void {
      state.depositPercentage = action.payload;
    },
    policyExtrasChanged(state, action: PayloadAction<RenewalPolicyExtras>): void {
      state.policyExtras = action.payload;
    },
    selectedRenewalQuoteChanged(state, action: PayloadAction<RenewalQuoteType | undefined>): void {
      state.selectedQuoteType = action.payload;
      setSelectedQuoteFields(state);
    },
    renewalTransactionIdChanged(state, action: PayloadAction<string>): void {
      state.transactionId = action.payload;
    },
    resetLoadRenewalSummaryStatus(state): void {
      state.loadRenewalSummaryStatus = initialState.loadRenewalSummaryStatus;
    },
    shouldProtectNoClaimsBonusChanged(state, action: PayloadAction<boolean>): void {
      state.shouldProtectNoClaimsBonus = action.payload;
    },
    setAllDetailsConfirmed(state, action: PayloadAction<boolean>): void {
      state.allDetailsConfirmed = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userAttemptedToLogout, () => initialState);
    builder.addCase(loadRenewalSummary.pending, (state) => {
      state.loadRenewalSummaryStatus = "loading";
    });
    builder.addCase(loadRenewalSummary.fulfilled, (state, action) => {
      state.renewalNotice = action.payload.renewalNotice;
      state.extrasTotalAmount = action.payload.extrasTotalAmount ?? undefined;
      state.noClaimsBonusProtectionCost = action.payload.noClaimsBonusProtectionCost ?? undefined;
      state.noClaimsBonusProtectionData = action.payload.noClaimsBonusProtectionData ?? undefined;
      state.policyExtras = action.payload.policyExtras;
      state.policy = action.payload.policy;
      state.shouldProtectNoClaimsBonus = state.policy?.coverDetails.noClaimsProtection;
      state.policyHolder = action.payload.policyHolder;
      state.depositPercentage = action.payload.depositPercentage;
      state.availablePolicyExtras = action.payload.availablePolicyExtras;
      state.loadRenewalSummaryStatus = "success";
      state.loadRebrokedQuoteStatus = "success";
      state.loadRenewalProspectiveLoansStatus = "success";
      state.loadRebrokeProspectiveLoansStatus = "success";
      state.renewalQuote = action.payload.renewalQuote;
      state.rebrokedQuote = action.payload.rebrokedQuote;

      setSelectedQuoteType(state);
      setSelectedQuoteFields(state);
    });
    builder.addCase(loadRenewalSummary.rejected, (state) => {
      state.loadRenewalSummaryStatus = "error";
    });
    builder.addCase(loadRebrokedQuote.pending, (state) => {
      state.loadRebrokedQuoteStatus = "loading";
    });
    builder.addCase(loadRebrokedQuote.fulfilled, (state, action) => {
      state.loadRebrokedQuoteStatus = "success";
      state.rebrokedQuote = action.payload.rebrokedQuote;

      if (state.selectedQuoteType === "REBROKE") setSelectedQuoteFields(state);

      state.extrasTotalAmount = action.payload.extrasTotalAmount ?? undefined;
      state.noClaimsBonusProtectionCost = action.payload.noClaimsBonusProtectionCost ?? undefined;
      state.noClaimsBonusProtectionData = action.payload.noClaimsBonusProtectionData ?? undefined;
    });
    builder.addCase(loadRebrokedQuote.rejected, (state) => {
      state.loadRebrokedQuoteStatus = "error";
    });
    builder.addCase(loadRenewalProspectiveLoans.pending, (state) => {
      state.loadRenewalProspectiveLoansStatus = "loading";
    });
    builder.addCase(loadRenewalProspectiveLoans.fulfilled, (state, action) => {
      state.renewalQuote = state.renewalQuote ?
          {
            ...state.renewalQuote,
            prospectiveLoan: action.payload.prospectiveLoans?.[0],
            totalUnfinancedAmountPayable: action.payload.totalUnfinancedAmountPayable,
            totalFinancedAmountPayable: action.payload.prospectiveLoans?.[0]?.totalAmountPayable,
          }
        : undefined;

      if (state.selectedQuoteType === "RENEWAL") setSelectedQuoteFields(state);

      state.extrasTotalAmount = action.payload.extrasTotalAmount ?? undefined;
      state.loadRenewalProspectiveLoansStatus = "success";
    });
    builder.addCase(loadRenewalProspectiveLoans.rejected, (state) => {
      state.loadRenewalProspectiveLoansStatus = "error";
    });
    builder.addCase(upsertRebrokeExtras.pending, (state) => {
      state.loadRebrokeProspectiveLoansStatus = "loading";
    });
    builder.addCase(upsertRebrokeExtras.fulfilled, (state, action) => {
      state.rebrokedQuote = state.rebrokedQuote ?
          {
            ...state.rebrokedQuote,
            prospectiveLoan: action.payload.prospectiveLoans?.[0],
            totalUnfinancedAmountPayable: action.payload.totalUnfinancedAmountPayable,
            totalFinancedAmountPayable: action.payload.prospectiveLoans?.[0]?.totalAmountPayable,
          }
        : undefined;

      if (state.selectedQuoteType === "REBROKE") setSelectedQuoteFields(state);

      state.extrasTotalAmount = action.payload.totalExtrasCost ?? undefined;
      state.loadRebrokeProspectiveLoansStatus = "success";
    });
    builder.addCase(upsertRebrokeExtras.rejected, (state) => {
      state.loadRebrokeProspectiveLoansStatus = "error";
    });
    builder.addCase(loadRebrokeProspectiveLoans.pending, (state) => {
      state.loadRebrokeProspectiveLoansStatus = "loading";
    });
    builder.addCase(loadRebrokeProspectiveLoans.fulfilled, (state, action) => {
      state.rebrokedQuote = state.rebrokedQuote ?
          {
            ...state.rebrokedQuote,
            prospectiveLoan: action.payload.prospectiveLoans?.[0],
            totalUnfinancedAmountPayable: action.payload.totalUnfinancedAmountPayable,
            totalFinancedAmountPayable: action.payload.prospectiveLoans?.[0]?.totalAmountPayable,
          }
        : undefined;

      if (state.selectedQuoteType === "REBROKE") setSelectedQuoteFields(state);

      state.extrasTotalAmount = action.payload.extrasTotalAmount ?? undefined;
      state.loadRebrokeProspectiveLoansStatus = "success";
    });
    builder.addCase(loadRebrokeProspectiveLoans.rejected, (state) => {
      state.loadRebrokeProspectiveLoansStatus = "error";
    });
  }
});

const getSelectedRenewalQuote = (state: RenewalDetails): RenewalQuote | undefined => {
  switch (state.selectedQuoteType) {
    case "RENEWAL":
      return state.renewalQuote;
    case "REBROKE":
      return state.rebrokedQuote;
    case undefined:
      return undefined;
  }
};

const setSelectedQuoteType = (state: RenewalDetails): void => {
  if (state.renewalQuote !== undefined && state.rebrokedQuote === undefined) {
    state.selectedQuoteType = "RENEWAL";
  } else if (state.renewalQuote === undefined && state.rebrokedQuote !== undefined) {
    state.selectedQuoteType = "REBROKE";
  } else {
    state.selectedQuoteType = undefined;
  }
};

function setSelectedQuoteFields(state: RenewalDetails): void {
  const selectedQuote = getSelectedRenewalQuote(state);
  state.insurerName = selectedQuote?.insurerName;
  state.prospectiveLoan = selectedQuote?.prospectiveLoan;
  state.premium = selectedQuote?.premium;
  state.adminFee = selectedQuote?.adminFee;
  state.brokerDiscount = selectedQuote?.brokerDiscount;
  state.totalUnfinancedAmountPayable = selectedQuote?.totalUnfinancedAmountPayable;
  state.totalFinancedAmountPayable = selectedQuote?.totalFinancedAmountPayable;
  state.insurerSchemeReference = selectedQuote?.insurerSchemeReference;

  if (state.selectedQuoteType === "RENEWAL") {
    state.shouldProtectNoClaimsBonus = state.policy?.coverDetails.noClaimsProtection;
  }
}

export const {
  paymentFrequencyChanged,
  depositPercentageChanged,
  policyExtrasChanged,
  selectedRenewalQuoteChanged,
  renewalTransactionIdChanged,
  resetLoadRenewalSummaryStatus,
  shouldProtectNoClaimsBonusChanged,
  setAllDetailsConfirmed
} = renewalSlice.actions;

export const renewalReducer = renewalSlice.reducer;