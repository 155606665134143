import {useEffectOnFirstRender} from "shared-components/dist/utils/hooks/UseEffectOnFirstRender";
import {
  sendEcommerceAnalyticsBeginCheckoutEvent
} from "shared-components/dist/analytics/GoogleAnalyticsEcommerceEventsService";
import {useBuildEcommerceItemsList} from "../helpers/UseBuildEcommerceItemsList";
import {useAppSelector} from "../../../redux/Hooks";
import {selectPolicyPaymentAmount} from "../../../redux/selectors/PolicyPaymentSelectors";

export function useEcommerceBeginCheckout(): void {
  const items = useBuildEcommerceItemsList();
  const amountPaid = useAppSelector(selectPolicyPaymentAmount);

  useEffectOnFirstRender(() => {
    if (items === undefined) return;

    sendEcommerceAnalyticsBeginCheckoutEvent({
      items,
      value: Number(amountPaid.amount),
      currency: amountPaid.currency
    });
  });
}
