import React, {FC} from "react";
import Question, {QuestionProps} from "../../Question";
import DurationDropdown from "shared-components/dist/form/duration-dropdown/DurationDropdown";
import {DropdownProps} from "shared-components/dist/form/dropdown/Dropdown";

interface Props {
  question: QuestionProps;
  yearsDropdown: DropdownProps;
  monthsDropdown: DropdownProps;
  hideMonths?: boolean;
}

const DurationQuestion: FC<Props> = (
  {
    question,
    yearsDropdown,
    monthsDropdown,
    hideMonths = false
  }
) => (
  <Question {...question}>
    <DurationDropdown
      yearsDropdown={yearsDropdown}
      monthsDropdown={monthsDropdown}
      hideMonths={hideMonths}
    />
  </Question>
);

export default DurationQuestion;