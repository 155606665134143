import {FC, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../redux/Hooks";
import {usePolicyQueryParams} from "../../../../router/params/Params";
import {useEffectOnFirstRender} from "shared-components/dist/utils/hooks/UseEffectOnFirstRender";
import {loadRenewalSummary} from "../redux/thunks/LoadRenewalSummary";
import PortalHeader from "../../shared/portal-header/PortalHeader";
import ContentWithoutSidebarLayout
  from "../../../../structure/layouts/content-without-sidebar-layout/ContentWithoutSidebarLayout";
import RenewalPriceCards from "../components/renewal-price-card/RenewalPriceCards";
import RenewalPriceSummary from "../components/renewal-price-summary/RenewalPriceSummary";
import RenewalSummaryError from "../components/renewal-summary-error/RenewalSummaryError";
import PolicyExtrasTable from "../components/policy-extras-table/PolicyExtrasTable";
import "./RenewalSummaryPage.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import CompareCoverHeader from "../components/compare-cover-header/CompareCoverHeader";
import ProtectedNoClaimsTable from "../components/protected-no-claims-table/ProtectedNoClaimsTable";
import {
  selectActiveRenewalNotice,
  selectAreBothRenewalOptionsAvailable,
  selectExistingPolicyForRenewal,
  selectLoadRenewalSummaryStatus,
  selectRebrokedQuote,
  selectRenewalQuote,
  selectSelectedQuoteType
} from "../redux/RenewalSelectors";
import {useNavigate} from "react-router-dom";
import {RenewalInviteStatus} from "shared/dist/generated/graphql/resolvers-types";
import RenewalSummaryFooter from "../components/renewal-summary-footer/RenewalSummaryFooter";
import {differenceInDays} from "date-fns";
import RenewalLoading from "../components/renewal-loading/RenewalLoading";
import Spacer from "shared-components/src/spacer/Spacer";
import {policyCanBeRenewedOrRebroked} from "../../shared/utils/PolicyStatusUtils";
import OnlineRenewalSoftStopModal from "../components/online-renewal-soft-stop-modal/OnlineRenewalSoftStopModal";
import {useScrollToHash} from "../../../../utils/navigation/ScrollToHash";

const RenewalSummaryPage: FC = () => {
  const dispatch = useAppDispatch();
  const {policyId} = usePolicyQueryParams();
  const policy = useAppSelector(selectExistingPolicyForRenewal);
  const loadRenewalSummaryStatus = useAppSelector(selectLoadRenewalSummaryStatus);
  const activeRenewalNotice = useAppSelector(selectActiveRenewalNotice);
  const renewalQuote = useAppSelector(selectRenewalQuote);
  const rebrokedQuote = useAppSelector(selectRebrokedQuote);
  const selectedQuoteType = useAppSelector(selectSelectedQuoteType);
  const areBothRenewalOptionsAvailable = useAppSelector(selectAreBothRenewalOptionsAvailable);
  const [onlineRenewalSoftStopVisible, setOnlineRenewalSoftStopVisible] = useState(false);

  const isRebrokeOnly = policy?.metadata.renewalInviteStatus === RenewalInviteStatus.RebrokeOnly;

  const navigate = useNavigate();
  useScrollToHash();

  useEffectOnFirstRender(() => {
    if (loadRenewalSummaryStatus === "default") dispatch(loadRenewalSummary({policyId}));
  });

  useEffect(() => {
    if (loadRenewalSummaryStatus !== "success") return;
    if (!policyCanBeRenewedOrRebroked(policy)) navigate(`/portal/policy/${policyId}/renewals`);
  }, [loadRenewalSummaryStatus, policy, navigate, policyId]);

  if (["loading", "default"].includes(loadRenewalSummaryStatus)) return <RenewalLoading/>;

  if (loadRenewalSummaryStatus === "error") return <RenewalSummaryError/>;

  if (loadRenewalSummaryStatus === "success" && renewalQuote === undefined && rebrokedQuote === undefined) return <RenewalSummaryError/>;

  return (
    <>
      <OnlineRenewalSoftStopModal isVisible={onlineRenewalSoftStopVisible} setIsVisible={setOnlineRenewalSoftStopVisible}/>
      <ContentWithoutSidebarLayout
        className="renewal-summary-page"
        testId="renewal-summary-page"
        header={<>
          <PortalHeader/>
          <div className="renewal-summary-page__landing">
            <div className="renewal-summary-page__landing__content">
              <h2>{lookupI18nString("portal.renewals.summary.title")}</h2>
              <p data-testid="renewal-summary-page__description">{lookupI18nString({
                id: areBothRenewalOptionsAvailable
                  ? "portal.renewals.summary.renewalAndRebrokeDescription"
                  : (isRebrokeOnly || rebrokedQuote !== undefined)
                    ? "portal.renewals.summary.rebrokeOnlyDescription"
                    : "portal.renewals.summary.renewalOnlyDescription",
                templateVariables: {insurerName: (isRebrokeOnly ? rebrokedQuote?.insurerName : activeRenewalNotice?.insurer) ?? "---"}
              })}</p>
              {!isRebrokeOnly && <h3 data-testid="renewal-summary-page__days-left">
                {lookupI18nString({
                  id: "portal.renewals.summary.cardTitle",
                  templateVariables: {
                    days: activeRenewalNotice?.renewalTimestamp
                      ? differenceInDays(new Date(activeRenewalNotice.renewalTimestamp), new Date())
                      : "-"
                  }
                })}
              </h3>}
            </div>
          </div>
        </>}
      >
        <RenewalPriceCards/>
        {/*TODO-REBROKE replace with fade and disable*/}
        {selectedQuoteType && <>
          <CompareCoverHeader/>
          <PolicyExtrasTable/>
          <ProtectedNoClaimsTable/>
          <RenewalPriceSummary setShowOnlineRenewalsSoftStop={setOnlineRenewalSoftStopVisible}/>
        </>}
        {!selectedQuoteType && <Spacer/>}
        <RenewalSummaryFooter/>
      </ContentWithoutSidebarLayout>
    </>
  );
};

export default RenewalSummaryPage;
