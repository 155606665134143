import React, {FC} from "react";
import StatusIndicator from "../status-indicator/StatusIndicator";
import "./Title.scss";
import {Status} from "../status-indicator/Status";
import {lookupI18nString} from "../translations/LookupI18nString";
import {TranslationKey} from "../translations/TranslationKey";

interface Props {
  htmlFor?: string;
  title: TranslationKey;
  status?: Status;
}

const Title: FC<Props> = ({
  htmlFor,
  title,
  status = "default"
}) => (
  <div className="title">
    <div className="title__label">
      <label htmlFor={htmlFor}>{lookupI18nString(title)}</label>
    </div>
    <div className="title__status">
      <StatusIndicator status={status}/>
    </div>
  </div>
);

export default Title;