import {FC} from "react";
import DepositPaymentLanding from "../../../monthly/deposit-payment/DepositPaymentLanding";
import {useAppSelector} from "../../../../../redux/Hooks";
import {PaymentFlowType} from "../../../shared/models/PaymentFlowType";
import {
  useGooglePayPurchaseFinancedPolicy
} from "../deposit-payment-processors/google-pay/GooglePayPolicyDepositPaymentProcessor";
import {
  applePayPurchaseFinancedPolicyMutation
} from "../deposit-payment-processors/apple-pay/ApplePayPolicyDepositPaymentProcessor";
import {ApplePayPaymentToken, FinancedPolicyPaymentResult} from "shared/dist/generated/graphql/resolvers-types";
import {
  processFinancedPolicySetUp
} from "../deposit-payment-processors/hosted-payment-page/PolicyDepositPaymentProcessor";
import {ProposedFinancedPolicySetupRequest} from "../../../shared/models/ProposedFinancedPolicySetupRequest";
import {selectActiveProspectiveLoan} from "../../../../portal/renewals/redux/RenewalSelectors";
import {rebrokeFinancedPolicyRequestSelector} from "../../../shared/redux/RenewalRequestSelector";
import {usePolicyQueryParams} from "../../../../../router/params/Params";

const RebrokeDepositPaymentLanding: FC = () => {
  const {policyId} = usePolicyQueryParams();
  const proposedPolicyRequest = useAppSelector(rebrokeFinancedPolicyRequestSelector);
  const selectedLoan = useAppSelector(state => selectActiveProspectiveLoan(state));
  const depositPaymentAmount = selectedLoan?.firstInstalmentAmount;
  if (!proposedPolicyRequest) throw new Error("No quote information stored");

  const {
    makePayment,
    loading,
    paymentFailed,
    resetPaymentFailed
  } = useGooglePayPurchaseFinancedPolicy(proposedPolicyRequest);

  const onPaymentWithApplePay = async (payment: ApplePayJS.ApplePayPayment): Promise<FinancedPolicyPaymentResult> => {
    return await applePayPurchaseFinancedPolicyMutation({
      payment: {
        token: payment.token.paymentData as ApplePayPaymentToken
      },
      depositPercentage: proposedPolicyRequest.depositPercentage,
      financeInformation: proposedPolicyRequest.financeInformation,
      loanSetUp: proposedPolicyRequest.loanSetUp,
      ...proposedPolicyRequest.quoteValues
    });
  };

  return <DepositPaymentLanding
    id="rebrokeDepositPaymentLanding"
    loading={loading}
    paymentFlowType={PaymentFlowType.REBROKE}
    successPagePath={`/portal/policy/${policyId}/renewals/payment/success`}
    proposedRequest={proposedPolicyRequest}
    onPaymentByCard={(globalPayFormResponse, proposedPolicyRequest, threeDSecureTransactionReference) => processFinancedPolicySetUp(globalPayFormResponse, proposedPolicyRequest as ProposedFinancedPolicySetupRequest, threeDSecureTransactionReference)}
    onPaymentWithApplePay={onPaymentWithApplePay}
    onPaymentWithGooglePay={makePayment}
    paymentFailed={paymentFailed}
    resetPaymentFailed={resetPaymentFailed}
    depositPaymentAmount={depositPaymentAmount}
  />;
};

export default RebrokeDepositPaymentLanding;