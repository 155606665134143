import React, {FC} from "react";
import {DropdownProps} from "shared-components/dist/form/dropdown/Dropdown";
import SubQuestion, {SubQuestionProps} from "../../SubQuestion";
import DurationDropdown from "shared-components/dist/form/duration-dropdown/DurationDropdown";

interface Props {
  subQuestion: SubQuestionProps;
  yearsDropdown: DropdownProps;
  monthsDropdown: DropdownProps;
}

const DurationSubQuestion: FC<Props> = ({
  subQuestion,
  yearsDropdown,
  monthsDropdown
}) => (
  <SubQuestion {...subQuestion}>
    <DurationDropdown
      yearsDropdown={yearsDropdown}
      monthsDropdown={monthsDropdown}
    />
  </SubQuestion>
);

export default DurationSubQuestion;