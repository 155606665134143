import {buildCrossFieldValidator, ReduxQuestion} from "../../Questions";
import {MaritalStatusOption} from "../../../../pages/quote/vehicle/shared/questions/martial-status/MaritalStatusOption";
import {
  maritalStatusChanged,
  maritalStatusInvalid
} from "../../../../pages/quote/vehicle/additional-driver/shared/redux/UpdateAdditionalDriverSlice";
import {
  maritalStatusMatchesRelationshipToProposerValidator
} from "../../../../pages/quote/vehicle/additional-driver/shared/components/marital-status/validators/AdditionalDriverMaritalStatusValidators";

export const maritalStatus: ReduxQuestion<MaritalStatusOption> = {
  selector: state => state.updateAdditionalDriver.maritalStatus,
  onChange: maritalStatusChanged,
  onInvalid: maritalStatusInvalid,
  crossFieldValidators: [buildCrossFieldValidator(
    state => state.updateAdditionalDriver.relationshipToProposer.value?.id,
    [maritalStatusMatchesRelationshipToProposerValidator]
  )]
};