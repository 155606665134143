import {Price} from "shared/dist/generated/graphql/resolvers-types";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";

export abstract class CurrencyFormat {
  public static formatPriceWithSymbol(price: Price | undefined): string {
    if (!price) return lookupI18nString("quoteDetails.depositAndPayments.placeholder");

    const {currency} = price;
    return CurrencyFormat.format(price.amount, {style: "currency", currency});
  }

  public static formatPriceWithoutSymbol(price: Price): string {
    return CurrencyFormat.format(price.amount, {minimumFractionDigits: 2});
  }

  //TODO-Money: Remove once all amounts have been converted to Price
  public static formatStringAmountWithSymbol(amount: string | undefined): string {
    if (!amount) return lookupI18nString("quoteDetails.depositAndPayments.placeholder");

    return CurrencyFormat.format(amount, {style: "currency", currency: "GBP"});
  }

  private static format(amount: string, options?: Intl.NumberFormatOptions): string {
    const locale = navigator.languages?.[0] || navigator.language;

    return new Intl.NumberFormat(locale, options).format(Number(amount));
  }
}