import {FC} from "react";
import "./NeedHelpSidebarFooter.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";

const NeedHelpSidebarFooter: FC = () => {
  return (
    <div className="need-help-sidebar-footer">
      <div className="need-help-sidebar-footer__title-section">
        <h3>{lookupI18nString("portal.renewals.detailConfirmationSidebar.needHelpFooter.needHelp")}</h3>
        <p>{lookupI18nString("portal.renewals.detailConfirmationSidebar.needHelpFooter.ourTeamAreHere")}</p>
      </div>
      <div className="need-help-sidebar-footer__contact-us">
        <p>{lookupI18nString("portal.renewals.detailConfirmationSidebar.needHelpFooter.contactUs")}</p>
        <a data-testid="phone-number" href="tel:08000113121">08000 11 31 21</a>
      </div>
    </div>
  );
};

export default NeedHelpSidebarFooter;