import {gql} from "@apollo/client";

export const FUEL_TYPES_LOOKUP_QUERY = gql`
  query FuelTypesLookup($filters: VehicleOptionsFilters!) {
    vehicle {
      vehicleOptions(optionsFilters: $filters) {
        fuelType
      }
    }
  }
`;
