import {WINDOW_TINT_DESCRIPTIONS, WINDOW_TINT_OPTIONS} from "../model/WindowTintOption";
import React, {FC} from "react";
import {Modification} from "../model/Modification";
import {Status} from "shared-components/dist/status-indicator/Status";
import {StringRadioColumnSubQuestion} from "../../../../../../../structure/questions/sub-question/variants/radio-column-sub-question/variants/StringRadioColumnSubQuestion";
import {ModificationType} from "shared/dist/generated/graphql/resolvers-types";

interface Props {
  modification: Modification | undefined;
  onSetModification: (modification: Modification, isFormValid?: boolean) => void;
  setIsWindowTintStop: (value: boolean) => void;
  status: Status;
}

const WindowTintQuestion: FC<Props> = (
  {
    modification,
    onSetModification,
    setIsWindowTintStop,
    status
  }
) => {
  if (!modification || modification.type.id !== ModificationType.WindowTint) return null;

  const onWindowTintOptionSelection = (selection: string): void => {
    const windowTintOption = WINDOW_TINT_OPTIONS.find(windowTint => windowTint.description === selection);
    if (!windowTintOption) return;

    if (windowTintOption.id === "full-vehicle") {
      onSetModification({...modification, option: windowTintOption}, false);
      setIsWindowTintStop(true);
    } else {
      onSetModification({...modification, option: windowTintOption}, true);
      setIsWindowTintStop(false);
    }
  };

  return (
    <StringRadioColumnSubQuestion
      subQuestionProps={{
        title: "vehicleQuote.modifications.windowTintQuestion.title",
        id: "window-tint-question",
        status,
        errorMessage: "quote.errors.questionIncomplete"
      }}
      radioColumnProps={{
        options: WINDOW_TINT_DESCRIPTIONS,
        name: "modification-options",
        onSelection: onWindowTintOptionSelection,
        selectedOption: modification.option?.description
      }}
    />
  );
};

export default WindowTintQuestion;