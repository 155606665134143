import React, {FC} from "react";
import {shouldShowManualVehicleLookupProhibitedFuelTypeStopSelector} from "../../../../redux/selectors/ManualVehicleLookupFormSelectors";
import ProhibitedFuelTypeStop from "../../../../../shared/stops/prohibited-fuel-type-stop/ProhibitedFuelTypeStop";
import {useAppSelector} from "../../../../../../../../redux/Hooks";

const ManualVehicleLookupProhibitedFuelTypeStop: FC = () => {
  const isVisible = useAppSelector(shouldShowManualVehicleLookupProhibitedFuelTypeStopSelector);

  return (
    <ProhibitedFuelTypeStop
      isVisible={isVisible}
      isSubContainer
    />
  );
};

export default ManualVehicleLookupProhibitedFuelTypeStop;