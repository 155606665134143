import React, {FC} from "react";
import "./UploadDocumentsCard.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import Button from "shared-components/dist/buttons/button/Button";
import {UPLOADS} from "../../../../../router/models/Routes";
import {ReactComponent as DocDashboardIcon} from "shared-components/src/assets/DocDashboardIcon.svg";
import {useNavigate} from "react-router-dom";

const UploadDocumentsCard: FC = () => {
  const navigate = useNavigate();

  return (
    <div className={"upload-documents-card"}>
      <div className={"upload-documents-card__row"}>
        <div className={"upload-documents-card__icon"}>
          <DocDashboardIcon className={"doc-dashboard-icon"}/>
        </div>
        <div className={"upload-documents-card__text"}>
          <div className={"upload-documents-card__text__title"}>
            {lookupI18nString("dashboard.uploadDocumentsCard.title")}
          </div>
          <div className={"upload-documents-card__text__description"}>
            {lookupI18nString("dashboard.uploadDocumentsCard.description")}
          </div>
        </div>
      </div>
      <div className={"upload-documents-card__row"}>
        <Button
          variant="ghost"
          className="upload-documents-card__button"
          onClick={() => navigate(UPLOADS)}>
            {lookupI18nString("dashboard.uploadDocumentsCard.button.title")}
        </Button>
      </div>
    </div>
  );
};

export default UploadDocumentsCard;
