import {
  primaryEmploymentIndustryChanged,
  primaryEmploymentJobChanged, primaryEmploymentStatusChanged, primaryEmploymentWorkedInPastYearChanged
} from "../../../../pages/quote/vehicle/your-details/redux/PersonalDetailsSlice";
import {SectionQuestionsRecord} from "../../Questions";

export const primaryEmployment = {
  primaryEmploymentStatus: {
    selector: state => state.personalDetails.primaryEmployment.employmentStatus,
    onChange: primaryEmploymentStatusChanged,
  },
  primaryEmploymentIndustry: {
    selector: state => state.personalDetails.primaryEmployment.industry,
    onChange: primaryEmploymentIndustryChanged
  },
  primaryEmploymentJob: {
    selector: state => state.personalDetails.primaryEmployment.job,
    onChange: primaryEmploymentJobChanged
  },
  primaryEmploymentWorkedInPastYear: {
    selector: state => state.personalDetails.primaryEmployment.workedInPastYear,
    onChange: primaryEmploymentWorkedInPastYearChanged
  },
} as const satisfies SectionQuestionsRecord;