import {UpdateUserAttributeResult} from "../../models/UpdateUserAttributeResult";
import {logger} from "../../../../utils/logging/Logger";
import {InternalCognitoUser, clearCachedUserAttributes} from "../get/GetUserAttributesService";

interface UpdateUserAttributeParams {
  cognitoUser: InternalCognitoUser;
  attributeName: string;
  newAttributeValue: string;
}

export function updateUserAttribute(
  {
    cognitoUser,
    attributeName,
    newAttributeValue
  }: UpdateUserAttributeParams,
): Promise<UpdateUserAttributeResult> {
  return new Promise<UpdateUserAttributeResult>(resolve => {
    cognitoUser.updateAttributes(
      [{
        Name: attributeName,
        Value: newAttributeValue
      }],
      (error) => {
        if (!error) {
          logger.log("Successfully updated user attribute:", attributeName);
          clearCachedUserAttributes(cognitoUser);
          return resolve("Success");
        }

        resolve(handleErrorStates(error, attributeName));
      }
    );
  });
}

function handleErrorStates(error: Error, attributeName: string): UpdateUserAttributeResult {
  switch (error.name) {
    case "AliasExistsException": {
      logger.error("An account with the given email already exists:", attributeName, error);
      return "EmailAlreadyExists";
    }
    case "LimitExceededException": {
      logger.error("Too many attempts to update user attribute:", attributeName, error);
      return "TooManyAttempts";
    }
    default: {
      logger.error("Failed to update user attribute:", attributeName, error);
      return "Failed";
    }
  }
}

