import React, {FC, ReactChild} from "react";
import "./RemindersContainer.css";
import Reminder from "./components/Reminder";
import {SETTINGS, UPLOADS} from "../../../../router/models/Routes";
import RemindersBanner from "./RemindersBanner";
import {selectPolicyList, selectVisibleReminderTypes} from "../redux/PortalSelectors";
import {updateVisibleReminderTypes} from "./thunk/UpdateVisibleReminderTypes";
import {ReminderType} from "./thunk/ReminderType";
import {CheckCircle, Error, InfoRounded} from "@mui/icons-material";
import {ReminderProps} from "./RemindersProps";
import {useAppDispatch, useAppSelector} from "../../../../redux/Hooks";

const RemindersContainer: FC = () => {
  const dispatch = useAppDispatch();
  const visibleReminderTypes = useAppSelector(selectVisibleReminderTypes);
  const policyList = useAppSelector(selectPolicyList);
  if (visibleReminderTypes === undefined) dispatch(updateVisibleReminderTypes());

  if (!visibleReminderTypes || visibleReminderTypes.length === 0) return null;

  function getReminderByReminderType(visibleReminderTypes: ReminderType): ReactChild {
    return <Reminder {...reminderProps[visibleReminderTypes]}/>;
  }

  const getPolicyRenewalLink = (): string | undefined => {
    if (!policyList || !policyList.active || policyList.active.length === 0) return undefined;

    return `/portal/policy/${policyList.active[0].id}/renewals`;
  };

  const reminderProps: Record<ReminderType, ReminderProps> = {
    [ReminderType.UNVERIFIED_EMAIL_ADDRESS]: {
      title: "portal.reminder.unverifiedEmail.title",
      description: "portal.reminder.unverifiedEmail.description",
      href: SETTINGS,
      icon: <Error className="error"/>
    },
    [ReminderType.UNVERIFIED_PHONE_NUMBER]: {
      title: "portal.reminder.unverifiedPhoneNumber.title",
      description: "portal.reminder.unverifiedPhoneNumber.description",
      href: SETTINGS,
      icon: <Error className="error"/>
    },
    [ReminderType.UPLOAD_DOCUMENTS]: {
      title: "portal.reminder.uploadDocuments.title",
      description: "portal.reminder.uploadDocuments.description",
      href: UPLOADS,
      icon: <Error className="error"/>
    },
    [ReminderType.PENDING_CANCELLATION]: {
      title: "portal.reminder.pendingCancellation.title",
      description: "portal.reminder.pendingCancellation.description",
      href: UPLOADS,
      icon: <Error className="error"/>
    },
    [ReminderType.WITH_CUSTOMER]: {
      title: "portal.reminder.withCustomer.title",
      description: "portal.reminder.withCustomer.description",
      href: UPLOADS,
      icon: <Error className="error"/>
    },
    [ReminderType.REVIEW_IN_PROGRESS]: {
      title: "portal.reminder.reviewInProgress.title",
      description: "portal.reminder.reviewInProgress.description",
      icon: <Error className="warning"/>
    },
    [ReminderType.POLICY_VERIFIED]: {
      title: "portal.reminder.verified.title",
      description: "portal.reminder.verified.description",
      icon: <CheckCircle className="success"/>
    },
    [ReminderType.RENEWALS_AWAITING_INVITE]: {
      title: "portal.reminder.renewalsAwaitingInvite.title",
      description: "portal.reminder.renewalsAwaitingInvite.description",
      icon: <InfoRounded className="info"/>
    },
    [ReminderType.RENEWAL_OFFERED]: {
      title: "portal.reminder.renewalOffered.title",
      description: "portal.reminder.renewalOffered.link.description",
      href: getPolicyRenewalLink(),
      icon: <InfoRounded className="info"/>
    }
  };

  return (
    <div className="reminders-container">
      <RemindersBanner reminders={
        visibleReminderTypes.map(reminder => getReminderByReminderType(reminder))
      }/>
    </div>
  );
};

export default RemindersContainer;
