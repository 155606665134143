import {PersonalDetails} from "../../your-details/redux/PersonalDetails";
import {UpdateAdditionalDriver} from "../../additional-driver/shared/redux/UpdateAdditionalDriver";
import {AbiListItem} from "shared/dist/generated/graphql/resolvers-types";
import {conditionalFieldReset, fieldReset} from "shared-components/dist/models/form-field/FormField";
import {FEMALE, GenderOption, MALE} from "../questions/gender/Genders";

export function convertTitleToGender(state: PersonalDetails | UpdateAdditionalDriver, payload: AbiListItem): void {
  if (payload.vt) {
    fieldReset(state.gender);
    state.gender.isActive = true;
  } else {
    conditionalFieldReset(state.gender);
    state.gender.value = mapTitleToGender(payload.vtDescription);
  }
}

const mapTitleToGender = (title: string): GenderOption => title === "Mr" ? MALE : FEMALE;