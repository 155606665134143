export const INDUSTRY_EDUCATION_VT_CODE = "Ed";
export const INDUSTRY_NOT_EMPLOYED_DUE_TO_DISABILITY_VT_CODE = "NnedtD";
export const INDUSTRY_DOMESTIC_SERVICE_VT_CODE = "Dom";
export const INDUSTRY_VOLUNTARY_ORGANISATION_VT_CODE = "Vol";
export const INDUSTRY_RETIRED_VT_CODE = "NR";
export const INDUSTRY_UNEMPLOYED_VT_CODE = "Une";

export const JOBS_STUDENT_VT_CODE = "Stu";
export const JOBS_NOT_EMPLOYED_DUE_TO_DISABILITY_VT_CODE = "NEdtD";
export const JOBS_HOUSE_SPOUSE_VT_CODE = "Hwf";
export const JOBS_VOLUNTARY_WORKER_VT_CODE = "Vol";
export const JOBS_INDEPENDENT_MEANS_VT_CODE = "InM";
export const JOBS_UNEMPLOYED_VT_CODE = "Unm";
export const JOBS_RETIRED_VT_CODE = "Ret";
