import React, {Dispatch, FC, SetStateAction, useState} from "react";
import {PaymentFrequency} from "../../../../quote/vehicle/your-quote/models/SavedQuote";
import KeyItemRow from "shared-components/dist/lists/key-item-list/KeyItemRow";
import {Price} from "shared/dist/generated/graphql/resolvers-types";
import Button from "shared-components/dist/buttons/button/Button";
import "./RenewalPriceSummaryCard.css";
import HeadlinePrice from "../headline-price/HeadlinePrice";
import {ExpandMore} from "@mui/icons-material";
import {NumberRadioColumn} from "shared-components/dist/form/radio-column/variants/NumberRadioColumn";
import {useAppDispatch, useAppSelector} from "../../../../../redux/Hooks";
import {depositPercentageChanged, paymentFrequencyChanged} from "../../redux/RenewalSlice";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {
  selectActiveProspectiveLoan,
  selectActiveRenewalNotice,
  selectRenewalAdminFee,
  selectAreRenewalProspectiveLoansLoading,
  selectBrokerDiscount,
  selectPremium,
  selectRebrokedQuote,
  selectRenewalDepositPercentage,
  selectRenewalPolicyExtras,
  selectRenewalQuote,
  selectTotalFinancedRenewalAmount,
  selectTotalPolicyExtraAmount,
  selectTotalUnfinancedRenewalAmount
} from "../../redux/RenewalSelectors";
import {loadRenewalProspectiveLoans} from "../../redux/thunks/LoadRenewalProspectiveLoans";
import {usePolicyQueryParams} from "../../../../../router/params/Params";
import {useNavigate} from "react-router-dom";
import {CLOSE_BROTHERS_INTEREST_RATE} from "shared/dist/constants/CloseBrothersInterestRate";
import FormattedMessage from "shared-components/src/translations/components/FormattedMessage";
import {displayLoanValue} from "../../../../../utils/service/string/DisplayLoanValue";
import {LoadingStatus} from "../../../../quote/vehicle/your-quote/models/LoadingStatus";
import {loadRebrokeProspectiveLoans} from "../../redux/thunks/LoadRebrokeProspectiveLoans";

export interface RenewalPriceSummaryCardProps {
  paymentFrequency: PaymentFrequency;
  monthlyInstallmentAmount?: Price;
  previouslyUsed: boolean;
  apr?: number;
  setShowOnlineRenewalsSoftStop: Dispatch<SetStateAction<boolean>>;
}

const RenewalPriceSummaryCard: FC<RenewalPriceSummaryCardProps> = (
  {
    paymentFrequency,
    monthlyInstallmentAmount,
    previouslyUsed,
    apr,
    setShowOnlineRenewalsSoftStop
  }) => {
  const {policyId} = usePolicyQueryParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const areRenewalProspectiveLoansLoading = useAppSelector(selectAreRenewalProspectiveLoansLoading);
  const premium = useAppSelector(selectPremium);
  const policyExtrasAmount = useAppSelector(selectTotalPolicyExtraAmount);
  const adminFee = useAppSelector(selectRenewalAdminFee);
  const brokerDiscount = useAppSelector(selectBrokerDiscount);
  const depositPercentage = useAppSelector(selectRenewalDepositPercentage);
  const totalFinancedAmount = useAppSelector(selectTotalFinancedRenewalAmount);
  const totalUnfinancedAmount = useAppSelector(selectTotalUnfinancedRenewalAmount);
  const policyExtras = useAppSelector(selectRenewalPolicyExtras);
  const activeProspectiveLoan = useAppSelector(selectActiveProspectiveLoan);
  const renewalQuote = useAppSelector(selectRenewalQuote);
  const rebrokeQuote = useAppSelector(selectRebrokedQuote);
  const renewalNotice = useAppSelector(selectActiveRenewalNotice);
  const [depositPercentageSelectorVisible, setDepositPercentageSelectorVisible] = useState(false);
  const [selectedDepositPercentage, setSelectedDepositPercentage] = useState(depositPercentage);

  const loadingStatus = areRenewalProspectiveLoansLoading ? LoadingStatus.LOADING : LoadingStatus.DEFAULT;
  const loanDepositOptions = activeProspectiveLoan?.loanDepositOptions || [];
  const hasMonthlyPaymentFrequency = paymentFrequency === "Monthly";

  const toggleDepositPercentageSelectorVisibility = (): void => {
    setDepositPercentageSelectorVisible(!depositPercentageSelectorVisible);
    setSelectedDepositPercentage(depositPercentage);
  };

  const getHeadlinePrice = (): Price | undefined => {
    if (areRenewalProspectiveLoansLoading) return undefined;

    return hasMonthlyPaymentFrequency
      ? monthlyInstallmentAmount
      : totalUnfinancedAmount;
  };

  const getDepositAmount = (): string => {
    if (depositPercentage === 0) return displayLoanValue({amount: "0", currency: "gbp"}, loadingStatus);
    return displayLoanValue(activeProspectiveLoan?.firstInstalmentAmount, loadingStatus);
  };

  const onConfirmDepositChange = (): void => {
    dispatch(depositPercentageChanged(selectedDepositPercentage));

    if (renewalQuote) dispatch(loadRenewalProspectiveLoans({
      policyId,
      depositPercentage: selectedDepositPercentage,
      policyExtras
    }));
    if (rebrokeQuote && rebrokeQuote.reference && rebrokeQuote.sequenceNumber) {
      dispatch(loadRebrokeProspectiveLoans({
        reference: rebrokeQuote.reference,
        sequenceNumber: rebrokeQuote.sequenceNumber,
        depositPercentage: selectedDepositPercentage,
      }));
    }
    toggleDepositPercentageSelectorVisibility();
  };

  const onSelectingQuote = (): void => {
    if (!renewalNotice?.canRenewOnline) {
      setShowOnlineRenewalsSoftStop(true);
      return;
    }
    dispatch(paymentFrequencyChanged(paymentFrequency));
    navigate(`/portal/policy/${policyId}/renewals/summary/confirm-details`);
  };

  return (
    <div className="renewal-price-summary-card" data-testid={`${paymentFrequency.toLowerCase()}-price-summary-card`}>
      <div className="renewal-price-summary-card__header">
        <span className="renewal-price-summary-card__header__title">
          {hasMonthlyPaymentFrequency
            ? lookupI18nString("portal.renewals.summary.priceSummary.card.monthly.title")
            : lookupI18nString("portal.renewals.summary.priceSummary.card.annual.title")}
        </span>

        {previouslyUsed && <span className="renewal-price-summary-card__header__selected">
          {lookupI18nString("portal.renewals.summary.priceSummary.card.previouslySelected")}
        </span>}
      </div>

      <div className="renewal-price-summary-card__body">
        <HeadlinePrice
          price={getHeadlinePrice()}
          showMonthlySuffix={hasMonthlyPaymentFrequency}
          testId={"renewal-price-summary-card__price"}
        />

        <div className="renewal-price-summary-card__body__list">
          <KeyItemRow
            testId="renewal-price-summary-card__insurance-premium"
            keyValue={lookupI18nString("portal.renewals.summary.priceSummary.card.table.insurancePremium")}
            item={displayLoanValue(premium, loadingStatus)}
          />

          <KeyItemRow
            testId="renewal-price-summary-card__policy-extras"
            keyValue={lookupI18nString("portal.renewals.summary.priceSummary.card.table.policyExtras")}
            item={displayLoanValue(policyExtrasAmount, loadingStatus)}
          />

          <KeyItemRow
            testId="renewal-price-summary-card__admin-fee"
            keyValue={lookupI18nString("portal.renewals.summary.priceSummary.card.table.adminFee")}
            item={displayLoanValue(adminFee, loadingStatus)}
          />

          {brokerDiscount &&
            <KeyItemRow
              testId="renewal-price-summary-card__broker-discount"
              keyValue={lookupI18nString("portal.renewals.summary.priceSummary.card.table.brokerDiscount")}
              item={displayLoanValue(brokerDiscount, loadingStatus)}
            />
          }

          {hasMonthlyPaymentFrequency &&
            <>
              <KeyItemRow
                testId="renewal-price-summary-card__broker-discount"
                keyValue={lookupI18nString("portal.renewals.summary.priceSummary.card.monthly.deposit.title")}
                item={`${depositPercentage}% - ${getDepositAmount()}`}
              />
              <KeyItemRow
                keyValue={
                  <FormattedMessage
                    id="portal.renewals.summary.priceSummary.card.table.monthlyTotalPayable"
                    values={{
                      span: (chunks: string) => <span
                        className="renewal-price-summary-card__body__list__monthly-rate">{chunks}</span>,
                      interestRate: CLOSE_BROTHERS_INTEREST_RATE,
                      apr: (areRenewalProspectiveLoansLoading ? undefined : apr) ?? "--.--"
                    }}
                  />
                }
                item={displayLoanValue(totalFinancedAmount, loadingStatus)}
                testId="renewal-price-summary-card__monthly-total-cost"
              />
            </>
          }
        </div>

        {hasMonthlyPaymentFrequency &&
          <div className="renewal-price-summary-card__body__deposit" data-testid="renewal-price-summary-card__deposit">
            <div className="renewal-price-summary-card__body__deposit__info">
              <p className="renewal-price-summary-card__body__deposit__info__title">
                {lookupI18nString("portal.renewals.summary.priceSummary.card.monthly.deposit.title")}
              </p>

              <p className="renewal-price-summary-card__body__deposit__info__description">
                {lookupI18nString("portal.renewals.summary.priceSummary.card.monthly.deposit.description")}
              </p>
            </div>

            <Button
              variant="link"
              rightIcon={<ExpandMore/>}
              onClick={toggleDepositPercentageSelectorVisibility}
              data-testid="renewal-price-summary-card__deposit__selector-toggle"
            >
              {lookupI18nString("portal.renewals.summary.priceSummary.card.monthly.deposit.button")}
            </Button>

            {depositPercentageSelectorVisible &&
              <div
                className="renewal-price-summary-card__body__deposit__percentage-selector"
                data-testid="renewal-price-summary-card__deposit__selector"
              >
                <NumberRadioColumn
                  options={loanDepositOptions.map(loanDepositOption => loanDepositOption.percentage)}
                  name="renewal-deposit-percentage"
                  selectedOption={selectedDepositPercentage}
                  onSelection={setSelectedDepositPercentage}
                >
                  {loanDepositOptions.map(loanDepositOption => (
                    <span key={loanDepositOption.percentage}
                          data-testid={`renewal-price-summary-card__deposit__selector__${loanDepositOption.percentage}percent`}>
                    {<FormattedMessage
                      id="portal.renewals.summary.priceSummary.card.monthly.deposit.option"
                      values={{
                        depositPercentage: loanDepositOption.percentage,
                        depositAmount: displayLoanValue(loanDepositOption.price, loadingStatus),
                        b: (msg: string) => <span
                          className="renewal-price-summary-card__body__deposit__percentage-selector--bold">{msg}</span>
                      }}
                    />}
                  </span>
                  ))}
                </NumberRadioColumn>

                <Button
                  onClick={toggleDepositPercentageSelectorVisibility}
                  small
                  expanded
                  variant="cancel"
                  data-testid="renewal-price-summary-card__deposit__selector__cancel"
                >
                  {lookupI18nString("portal.renewals.summary.priceSummary.card.monthly.deposit.cancel")}
                </Button>

                <Button
                  onClick={onConfirmDepositChange}
                  small
                  expanded
                  data-testid="renewal-price-summary-card__deposit__selector__confirm"
                  disabled={areRenewalProspectiveLoansLoading}
                >
                  {lookupI18nString("portal.renewals.summary.priceSummary.card.monthly.deposit.confirm")}
                </Button>
              </div>
            }
          </div>}

        {!depositPercentageSelectorVisible &&
          <Button
            disabled={areRenewalProspectiveLoansLoading}
            variant="secondary"
            onClick={onSelectingQuote}
            expanded
            small
            data-testid="select-quote-button"
          >
            {lookupI18nString("portal.renewals.summary.priceSummary.card.selectQuoteButton")}
          </Button>
        }
      </div>
    </div>
  );
};

export default RenewalPriceSummaryCard;
