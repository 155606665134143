import React, {FC} from "react";
import RadioColumn, {RadioColumnProps} from "shared-components/dist/form/radio-column/RadioColumn";
import Question, {QuestionProps} from "../../Question";

interface Props<T> {
  questionProps: QuestionProps;
  radioColumnProps: RadioColumnProps<T>;
}

export type RadioColumnQuestionWithGenericProps<T = unknown> = FC<Props<T>>;

const RadioColumnQuestion: RadioColumnQuestionWithGenericProps = ({
  questionProps,
  radioColumnProps
}) => (
  <Question {...questionProps}>
    <RadioColumn {...radioColumnProps}/>
  </Question>
);

export default RadioColumnQuestion;