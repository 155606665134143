import React, {FC} from "react";
import SubQuestionForm from "../../../../../../../structure/questions/sub-question-form/SubQuestionForm";
import OwnerQuestion from "../owner/OwnerQuestion";
import RegisteredKeeperQuestion from "../registered-keeper/RegisteredKeeperQuestion";
import {useAppSelector} from "../../../../../../../redux/Hooks";

const OwnerRegisteredKeeperForm: FC = () => {
  const ownerQuestionIsActive = useAppSelector(state => state.vehicleDetails.owner.isActive);
  const registeredKeeperQuestionIsActive = useAppSelector(state => state.vehicleDetails.registeredKeeper.isActive);

  return (
    <>
      {ownerQuestionIsActive &&
        <SubQuestionForm>
          <OwnerQuestion/>
          {registeredKeeperQuestionIsActive && <RegisteredKeeperQuestion/>}
        </SubQuestionForm>
      }
    </>
  );
};

export default OwnerRegisteredKeeperForm;