import {SerializableAdditionalDriver} from "../../../models/AdditionalDriver";
import {v4 as UUID} from "uuid";
import {UpdateAdditionalDriver} from "../../UpdateAdditionalDriver";

export function mapToSerializableAdditionalDriver(additionalDriverForm: UpdateAdditionalDriver, driverId?: string): SerializableAdditionalDriver {
  if (
    additionalDriverForm.relationshipToProposer.value === undefined ||
    additionalDriverForm.title.value === undefined ||
    additionalDriverForm.firstName.value === undefined ||
    additionalDriverForm.surname.value === undefined ||
    additionalDriverForm.dateOfBirth.value === undefined ||
    additionalDriverForm.gender.value === undefined ||
    additionalDriverForm.maritalStatus.value === undefined ||
    additionalDriverForm.primaryEmployment.employmentStatus.value === undefined ||
    additionalDriverForm.hasSecondaryEmployment.value === undefined ||
    additionalDriverForm.classOfUse.value === undefined ||
    additionalDriverForm.licenceCountryOfIssue.value === undefined ||
    additionalDriverForm.licenceType.value === undefined ||
    additionalDriverForm.licenceDuration.value === undefined ||
    additionalDriverForm.hasUkResidencyFromBirth.value === undefined ||
    additionalDriverForm.hasMedicalCondition.value === undefined ||
    additionalDriverForm.hasNonMotoringConvictions.value === undefined ||
    additionalDriverForm.hasInsuranceDeclined.value === undefined
  ) {
    throw Error("Unable to determine valid additional driver details");
  }

  return {
    id: driverId ?? UUID(),
    relationshipToProposer: additionalDriverForm.relationshipToProposer.value,
    title: additionalDriverForm.title.value,
    firstName: additionalDriverForm.firstName.value,
    surname: additionalDriverForm.surname.value,
    dateOfBirth: additionalDriverForm.dateOfBirth.value,
    householdNumberOfCars: additionalDriverForm.householdNumberOfCars.value,
    gender: additionalDriverForm.gender.value,
    hasUkResidencyFromBirth: additionalDriverForm.hasUkResidencyFromBirth.value,
    ukResidencyDuration: additionalDriverForm.ukResidencyDuration.value,
    maritalStatus: additionalDriverForm.maritalStatus.value,
    primaryEmployment: {
      employmentStatus: additionalDriverForm.primaryEmployment.employmentStatus.value,
      industry: additionalDriverForm.primaryEmployment.industry.value,
      job: additionalDriverForm.primaryEmployment.job.value,
      workedInPastYear: additionalDriverForm.primaryEmployment.workedInPastYear.value
    },
    hasSecondaryEmployment: additionalDriverForm.hasSecondaryEmployment.value,
    secondaryEmployment: {
      employmentStatus: additionalDriverForm.secondaryEmployment.employmentStatus.value,
      industry: additionalDriverForm.secondaryEmployment.industry.value,
      job: additionalDriverForm.secondaryEmployment.job.value,
      workedInPastYear: additionalDriverForm.secondaryEmployment.workedInPastYear.value
    },
    classOfUse: additionalDriverForm.classOfUse.value,
    licenceCountryOfIssue: additionalDriverForm.licenceCountryOfIssue.value,
    licenceType: additionalDriverForm.licenceType.value,
    licenceDuration: additionalDriverForm.licenceDuration.value,
    hasMedicalCondition: additionalDriverForm.hasMedicalCondition.value,
    medicalCondition: additionalDriverForm.medicalCondition.value,
    claims: additionalDriverForm.claims.value.claims,
    convictions: additionalDriverForm.convictions.value.convictions,
    hasNonMotoringConvictions: additionalDriverForm.hasNonMotoringConvictions.value,
    hasInsuranceDeclined: additionalDriverForm.hasInsuranceDeclined.value
  };
}