import {
  hasVehicleBeenInsuredInvalid,
  isCurrentlyOrPreviouslyInsuredInvalid,
  isPreviousNamedDriverInvalid,
  namedDriverDeclarationAcceptanceInvalid,
  namedDriverOnOwnVehicleInvalid,
  ownOrCompanyCarInvalid,
  vehiclePreviousPolicyExpiryDateInvalid,
  vehicleTypeInvalid,
  yearsNamedDriverInvalid
} from "../redux/CoverDetailsSlice";
import {AppThunkDispatch} from "../../../../../redux/Store";
import {isConditionalFormFieldInvalid} from "shared-components/dist/models/form-field/ConditionalFormField";
import {CoverDetailsFields} from "../redux/CoverDetailsFields";

export function checkNamedDriverQuestions(coverDetails: CoverDetailsFields, dispatch: AppThunkDispatch): void {
  checkOwnOrCompanyCarQuestion(coverDetails, dispatch);
  checkHasVehicleBeenInsuredQuestion(coverDetails, dispatch);
  checkNamedDriverOnOwnVehicleQuestion(coverDetails, dispatch);
  checkVehiclePreviousPolicyExpiryDateQuestion(coverDetails, dispatch);
  checkIsPreviousNamedDriverQuestion(coverDetails, dispatch);
  checkIsCurrentlyOrPreviouslyInsuredQuestion(coverDetails, dispatch);
  checkVehicleTypeQuestion(coverDetails, dispatch);
  checkYearsNamedDriverQuestion(coverDetails, dispatch);
  checkNamedDriverDeclarationAcceptanceQuestion(coverDetails, dispatch);
}

function checkOwnOrCompanyCarQuestion(
  {ownOrCompanyCar}: CoverDetailsFields,
  dispatch: AppThunkDispatch
): void {
  if (isConditionalFormFieldInvalid(ownOrCompanyCar)) {
    dispatch(ownOrCompanyCarInvalid("quote.errors.questionIncomplete"));
  }
}

function checkHasVehicleBeenInsuredQuestion(
  {hasVehicleBeenInsured}: CoverDetailsFields,
  dispatch: AppThunkDispatch
): void {
  if (isConditionalFormFieldInvalid(hasVehicleBeenInsured)) {
    dispatch(hasVehicleBeenInsuredInvalid("quote.errors.questionIncomplete"));
  }
}

function checkNamedDriverOnOwnVehicleQuestion(
  {namedDriverOnOwnVehicle}: CoverDetailsFields,
  dispatch: AppThunkDispatch
): void {
  if (isConditionalFormFieldInvalid(namedDriverOnOwnVehicle)) {
    dispatch(namedDriverOnOwnVehicleInvalid("quote.errors.questionIncomplete"));
  }
}

function checkVehiclePreviousPolicyExpiryDateQuestion(
  {vehiclePreviousPolicyExpiryDate}: CoverDetailsFields,
  dispatch: AppThunkDispatch
): void {
  if (isConditionalFormFieldInvalid(vehiclePreviousPolicyExpiryDate)) {
    dispatch(vehiclePreviousPolicyExpiryDateInvalid("quote.errors.questionIncomplete"));
  }
}

function checkIsPreviousNamedDriverQuestion(
  {isPreviousNamedDriver}: CoverDetailsFields,
  dispatch: AppThunkDispatch
): void {
  if (isConditionalFormFieldInvalid(isPreviousNamedDriver)) {
    dispatch(isPreviousNamedDriverInvalid("quote.errors.questionIncomplete"));
  }
}

function checkIsCurrentlyOrPreviouslyInsuredQuestion(
  {isCurrentlyOrPreviouslyInsured}: CoverDetailsFields,
  dispatch: AppThunkDispatch
): void {
  if (isConditionalFormFieldInvalid(isCurrentlyOrPreviouslyInsured)) {
    dispatch(isCurrentlyOrPreviouslyInsuredInvalid("quote.errors.questionIncomplete"));
  }
}

function checkVehicleTypeQuestion(
  {vehicleType}: CoverDetailsFields,
  dispatch: AppThunkDispatch
): void {
  if (isConditionalFormFieldInvalid(vehicleType)) {
    dispatch(vehicleTypeInvalid("quote.errors.questionIncomplete"));
  }
}

function checkYearsNamedDriverQuestion(
  {yearsNamedDriver}: CoverDetailsFields,
  dispatch: AppThunkDispatch
): void {
  if (isConditionalFormFieldInvalid(yearsNamedDriver)) {
    dispatch(yearsNamedDriverInvalid("quote.errors.questionIncomplete"));
  }
}

function checkNamedDriverDeclarationAcceptanceQuestion(
  {namedDriverDeclarationAcceptance}: CoverDetailsFields,
  dispatch: AppThunkDispatch
): void {
  if (isConditionalFormFieldInvalid(namedDriverDeclarationAcceptance)) {
    dispatch(namedDriverDeclarationAcceptanceInvalid("quote.errors.questionIncomplete"));
  }
}