import React, {FC} from "react";
import {logYourCoverGoogleAnalyticsEvent} from "../../../../../../../../utils/analytics/MotorAnalytics";
import {yearsNamedDriverChanged} from "../../../../redux/CoverDetailsSlice";
import FormattedMessage from "shared-components/src/translations/components/FormattedMessage";
import {QuestionProps} from "../../../../../../../../structure/questions/question/Question";
import {useDurationDropdownProps} from "shared-components/dist/form/duration-dropdown/hooks/useDurationDropdownProps";
import DurationQuestion
  from "../../../../../../../../structure/questions/question/variants/duration-question/DurationQuestion";
import {useAppDispatch, useAppSelector} from "../../../../../../../../redux/Hooks";

const YearsNamedDriverQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const yearsNamedDriverField = useAppSelector(state => state.coverDetails.yearsNamedDriver);

  const onYearsNamedDriverChange = (duration: Duration): void => {
    logYourCoverGoogleAnalyticsEvent({
      categorySuffix: "years_named_driver",
      action: duration.years ? duration.years.toString() : "0",
    });
    dispatch(yearsNamedDriverChanged(duration));
  };

  const questionProps: QuestionProps = {
    id: "years-named-driver-question",
    title: "coverDetails.yearsNamedDriverQuestion.title",
    description: "coverDetails.yearsNamedDriverQuestion.description",
    tooltip: {
      description: (
        <FormattedMessage
          id="coverDetails.yearsNamedDriverQuestion.tooltip.description"
          values={{
            b: function formatBoldTags(chunks: string) {
              return <b>{chunks}</b>;
            }
          }}
        />
      )
    },
    errorMessage: yearsNamedDriverField.errorMessage,
    status: yearsNamedDriverField.status,
    hideSpacer: true
  };

  const {yearsDropdown, monthsDropdown} = useDurationDropdownProps({
    id: "years-company-car-bonus-question-dropdown",
    duration: yearsNamedDriverField.value,
    onChange: onYearsNamedDriverChange,
    maxYear: 5,
    appendPlusToMaxNumber: true,
  });

  return (
    <DurationQuestion
      question={questionProps}
      yearsDropdown={yearsDropdown}
      monthsDropdown={monthsDropdown}
      hideMonths={true}
    />
  );
};

export default YearsNamedDriverQuestion;