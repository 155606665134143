import React, {FC} from "react";
import {shouldShowAdditionalDetailsEuImportVehicleStopSelector} from "../../../../redux/selectors/VehiclePageStateSelectors";
import EuImportTypeStop from "../../../../../shared/stops/import-type-stops/EuImportTypeStop";
import {useAppSelector} from "../../../../../../../../redux/Hooks";

const AdditionalDetailsEuImportVehicleStop: FC = () => {
  const isVisible = useAppSelector(shouldShowAdditionalDetailsEuImportVehicleStopSelector);

  return (
    <EuImportTypeStop
      isVisible={isVisible}
      isSubContainer
    />
  );
};

export default AdditionalDetailsEuImportVehicleStop;
