import {FC} from "react";
import "./LegalStuffCard.css";
import Button from "shared-components/dist/buttons/button/Button";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {InfoOutlined} from "@mui/icons-material";
import {PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL} from "shared/dist/constants/Urls";

const LegalStuffCard: FC = () => (
  <div className="legal-stuff-card">
    <h4>{lookupI18nString("portal.renewals.summary.footer.legal.title")}</h4>
    <p>{lookupI18nString("portal.renewals.summary.footer.legal.description")}</p>

    <div className="legal-stuff-card__buttons">
      <Button
        variant="link"
        onClick={() => open(PRIVACY_POLICY_URL)}
        data-testid="legal-stuff-card__buttons__privacy-policy"
      >
        <InfoOutlined/>{lookupI18nString("portal.renewals.summary.footer.legal.button.privacyPolicy")}
      </Button>

      <Button
        variant="link"
        onClick={() => open(TERMS_AND_CONDITIONS_URL)}
        data-testid="legal-stuff-card__buttons__terms-and-conditions"
      >
        <InfoOutlined/>{lookupI18nString("portal.renewals.summary.footer.legal.button.termsAndConditions")}
      </Button>
    </div>
  </div>
);

export default LegalStuffCard;