import React, {Dispatch, FC, SetStateAction} from "react";
import RenewalDetailsConfirmationTableItem, {
  RenewalDetailsConfirmationTableItemProps
} from "../table-items/RenewalDetailsConfirmationTableItem";
import RenewalDetailsConfirmationTableHeader from "../table-items/RenewalDetailsConfirmationTableHeader";
import RenewalDetailsConfirmationButtonFooter from "../table-items/RenewalDetailsConfirmationButtonFooter";
import "./RenewalsConfirmYourCar.css";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {PolicyVehicleDetails, VehicleHolder} from "shared/dist/generated/graphql/resolvers-types";
import {convertEnumValueToFormattedString} from "shared-components/dist/utils/helpers/EnumHelper";
import {
  ImportType,
  importTypeOptionFrom
} from "../../../../../graphql/queries/vehicle-lookup/registration-lookup/model/ImportType";
import {formatDateStringFromIsoString} from "shared/dist/stdlib/DateFormat";

interface Props {
  confirmed: boolean;
  setConfirmed: Dispatch<SetStateAction<boolean>>;
  setEditModalVisible: Dispatch<SetStateAction<boolean>>;
  vehicleDetails: PolicyVehicleDetails;
  previousSectionConfirmed: boolean;
}

const RenewalsConfirmYourCar: FC<Props> = ({confirmed, setConfirmed, setEditModalVisible, vehicleDetails, previousSectionConfirmed}) => {
  const items = getStructuredVehicleDetails(vehicleDetails);

  return (
    <div
      className={confirmed ? "renewals-confirm-your-car--confirmed" : `renewals-confirm-your-car${previousSectionConfirmed ? "" : "--disabled"} unconfirmed`}
      data-testid="renewals-confirm-your-car"
    >
      <RenewalDetailsConfirmationTableHeader
        title={lookupI18nString("portal.renewals.detailConfirmation.yourCar")}
        confirmed={confirmed}
      />
      {items.map((item, index) => (
        <RenewalDetailsConfirmationTableItem
          key={index}
          testId={item.testId}
          title={item.title}
          subtitle={item.subtitle}
        />
      ))}
      <RenewalDetailsConfirmationButtonFooter
        testId={"your-car"}
        confirmed={confirmed}
        setConfirmed={setConfirmed}
        setEditModalVisible={setEditModalVisible}
        disabled={!previousSectionConfirmed}
      />
    </div>
  );
};

function getStructuredVehicleDetails(vehicleDetails: PolicyVehicleDetails): RenewalDetailsConfirmationTableItemProps[] {
  const structuredVehicleDetails = [
    {
      testId: "your-car-registration",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourCar.registration"),
      subtitle: vehicleDetails.registeredVehicle.registration
    }
  ];

  if (vehicleDetails.dateOfPurchase) structuredVehicleDetails.push({
    testId: "your-car-purchase-date",
    title: lookupI18nString("portal.renewals.detailConfirmation.yourCar.purchaseDate"),
    subtitle: formatDateStringFromIsoString(vehicleDetails.dateOfPurchase)
  });

  structuredVehicleDetails.push(
    {
      testId: "your-car-make-and-model",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourCar.vehicle"),
      subtitle: `${vehicleDetails.registeredVehicle.make} ${vehicleDetails.registeredVehicle.model}`
    },
    {
      testId: "your-car-fuel-type",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourCar.fuelType"),
      subtitle: convertEnumValueToFormattedString(vehicleDetails.registeredVehicle.fuelType)
    },
    {
      testId: "your-car-transmission-type",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourCar.transmissionType"),
      subtitle: convertEnumValueToFormattedString(vehicleDetails.registeredVehicle.transmissionType)
    },
    {
      testId: "your-car-year-of-manufacture",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourCar.yearOfManufacture"),
      subtitle: vehicleDetails.registeredVehicle.yearOfManufacture
    },
    {
      testId: "your-car-trim",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourCar.trimLevel"),
      subtitle: vehicleDetails.registeredVehicle.trim
    }
  );

  if (vehicleDetails.registeredVehicle.engineCapacityLitres) structuredVehicleDetails.push({
    testId: "your-car-engine-size",
    title: lookupI18nString("portal.renewals.detailConfirmation.yourCar.engineSize"),
    subtitle: `${vehicleDetails.registeredVehicle.engineCapacityLitres} L`
  });

  if (vehicleDetails.registeredVehicle.driverPosition) structuredVehicleDetails.push({
    testId: "your-car-driver-position",
    title: lookupI18nString("portal.renewals.detailConfirmation.yourCar.driverPosition"),
    subtitle: vehicleDetails.registeredVehicle.driverPosition
  });

  if (vehicleDetails.registeredVehicle.doors) structuredVehicleDetails.push({
    testId: "your-car-doors",
    title: lookupI18nString("portal.renewals.detailConfirmation.yourCar.numberOfDoors"),
    subtitle: vehicleDetails.registeredVehicle.doors.toString()
  });

  if (vehicleDetails.registeredVehicle.seats) structuredVehicleDetails.push({
    testId: "your-car-seats",
    title: lookupI18nString("portal.renewals.detailConfirmation.yourCar.numberOfSeats"),
    subtitle: vehicleDetails.registeredVehicle.seats.toString()
  });

  structuredVehicleDetails.push(
    {
      testId: "your-car-tracker-fitted",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourCar.tracker"),
      subtitle: vehicleDetails.hasTrackingDeviceInstalled ? lookupI18nString("general.boolean.yes") : lookupI18nString("general.boolean.no")
    },
    {
      testId: "your-car-imported",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourCar.imported"),
      subtitle: vehicleDetails.registeredVehicle.imported
        ? `${formatImportType(vehicleDetails.registeredVehicle.importType ?? undefined)}`
        : lookupI18nString("general.boolean.no")
    },
    {
      testId: "your-car-modifications",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourCar.modified"),
      subtitle: vehicleDetails.modifications && vehicleDetails.modifications.length > 0 ? lookupI18nString("general.boolean.yes") : lookupI18nString("general.boolean.no")
    },
    {
      testId: "your-car-registered-owner",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourCar.owner"),
      subtitle: vehicleDetails.owner === VehicleHolder.PolicyHolder
        ? lookupI18nString("general.boolean.yes")
        : `${lookupI18nString("general.boolean.no")} - ${convertEnumValueToFormattedString(vehicleDetails.owner)}`
    },
    {
      testId: "your-car-registered-keeper",
      title: lookupI18nString("portal.renewals.detailConfirmation.yourCar.registeredKeeper"),
      subtitle: vehicleDetails.keeper === VehicleHolder.PolicyHolder
        ? lookupI18nString("general.boolean.yes")
        : `${lookupI18nString("general.boolean.no")} - ${convertEnumValueToFormattedString(vehicleDetails.keeper)}`
    }
  );

  return structuredVehicleDetails;
}

const formatImportType = (importType?: ImportType): string => {
  if (!importType) return lookupI18nString("general.boolean.yes");

  return `${lookupI18nString("general.boolean.yes")} - ${importTypeOptionFrom(importType)}`;
};

export default RenewalsConfirmYourCar;
