import {SectionQuestionsRecord} from "../../Questions";
import {additionalVehicleDetailsDriverPosition} from "./questions/AdditionalVehicleDetailsDriverPosition";
import {additionalVehicleDetailsSeats} from "./questions/AdditionalVehicleDetailsSeats";
import {additionalVehicleDetailsHasTracker} from "./questions/AdditionalVehicleDetailsHasTracker";
import {additionalVehicleDetailsIsImported} from "./questions/AdditionalVehicleDetailsIsImported";
import {additionalVehicleDetailsImportType} from "./questions/AdditionalVehicleDetailsImportType";
import {additionalVehicleDetailsEstimatedValue} from "./questions/AdditionalVehicleDetailsEstimatedValue";
import {additionalVehicleDetailsHasAlarm} from "./questions/AdditionalVehicleDetailsHasAlarm";
import {additionalVehicleDetailsAlarmFittedBy} from "./questions/AdditionalVehicleDetailsAlarmFittedBy";

export const additionalVehicleDetails = {
  additionalVehicleDetailsDriverPosition,
  additionalVehicleDetailsSeats,
  additionalVehicleDetailsHasTracker,
  additionalVehicleDetailsHasAlarm,
  additionalVehicleDetailsAlarmFittedBy,
  additionalVehicleDetailsIsImported,
  additionalVehicleDetailsImportType,
  additionalVehicleDetailsEstimatedValue
} as const satisfies SectionQuestionsRecord;