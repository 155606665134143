import {TranslationKey} from "shared-components/src/translations/TranslationKey";

export const handleVehicleQueryError = (errorMessage: string, handleError: (error: TranslationKey) => void): void => {
  switch (errorMessage) {
    case "VEHICLE_REGISTRATION_INVALID":
      handleError("vehicleQuote.registrationLookup.errors.query.VEHICLE_REGISTRATION_INVALID");
      break;
    case "VEHICLE_REGISTRATION_NOT_FOUND":
      handleError("vehicleQuote.registrationLookup.errors.query.VEHICLE_REGISTRATION_NOT_FOUND");
      break;
    case "UNSUCCESSFUL_RESPONSE_CODE":
      handleError("vehicleQuote.registrationLookup.errors.query.UNSUCCESSFUL_RESPONSE_CODE");
      break;
    case "VEHICLE_INFORMATION_INCOMPLETE":
      handleError("vehicleQuote.registrationLookup.errors.query.VEHICLE_INFORMATION_INCOMPLETE");
      break;
    case "SERVICE_UNAVAILABLE":
      handleError("vehicleQuote.registrationLookup.errors.query.SERVICE_UNAVAILABLE");
      break;
    case "SANDBOX_LIMITATION":
      handleError("vehicleQuote.registrationLookup.errors.query.SANDBOX_LIMITATION");
      break;
    default:
      handleError("vehicleQuote.registrationLookup.errors.query.GENERIC_ERROR");
      break;
  }
};
