import {QuoteMutationGetRebrokedQuoteArgs, RebrokedQuoteResult} from "shared/dist/generated/graphql/resolvers-types";
import {apolloClient} from "../../../apollo/ApolloClientProvider";
import {GET_REBROKED_QUOTE} from "shared/dist/graphql/queries/quote/GetRebrokedQuote";
import {GetRebrokedQuoteResult} from "shared/dist/models/quote/GetRebrokedQuoteResult";

export async function getRebrokedQuoteMutation(queryMutationArgs: QuoteMutationGetRebrokedQuoteArgs): Promise<RebrokedQuoteResult | undefined> {
  return await apolloClient.mutate<GetRebrokedQuoteResult, QuoteMutationGetRebrokedQuoteArgs>({
    mutation: GET_REBROKED_QUOTE,
    variables: {
      ...queryMutationArgs
    },
  }).then(result => Promise.resolve(result.data?.quote.getRebrokedQuote))
    .catch(error => Promise.reject(error));
}
