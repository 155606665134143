import {useEffectOnFirstRender} from "shared-components/dist/utils/hooks/UseEffectOnFirstRender";
import {useAppDispatch, useAppSelector} from "../../../../../../../../redux/Hooks";
import {shouldReloadQuotesSelector} from "../../../../redux/selectors/ShouldReloadQuotesSelector";
import {quoteRetrievalStatusChanged} from "../../../../redux/QuoteDetailsSlice";
import {QuoteRetrievalStatus} from "../../../../models/QuoteRetrievalStatus";
import {useLocation} from "react-router-dom";
import {useFeatureFlags} from "shared-components/dist/feature-flags/hooks/UseFeatureFlags";
import {loadQuotes} from "../../../../redux/thunks/LoadQuotes";

export function useLoadQuotesOnFirstRender(): void {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const {monthlyPaymentsFlag} = useFeatureFlags();
  const shouldReloadQuotes = useAppSelector(state => shouldReloadQuotesSelector(state.quoteDetails));

  useEffectOnFirstRender(() => {
    if (shouldReloadQuotes && location.state?.fromQuotePage) load();
    if (!location.state?.fromQuotePage) load();
  });

  const load = (): void => {
    dispatch(quoteRetrievalStatusChanged(QuoteRetrievalStatus.LOADING));
    dispatch(loadQuotes({
      monthlyPaymentsEnabled: monthlyPaymentsFlag
    }));
  };
}