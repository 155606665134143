import {
  noClaimsDiscountProtectionDataQuery
} from "../../../../../../graphql/queries/quote/no-claims-discount-protection-data/NoClaimsDiscountProtectionDataQuery";
import {logger} from "../../../../../../utils/logging/Logger";
import {createAppAsyncThunk} from "../../../../../../redux/CreateAppAsyncThunk";

export const loadNoClaimsDiscountProtectionData = createAppAsyncThunk(
  "quoteDetailsSlice/loadNoClaimsDiscountProtectionData",
  async (_, {getState}) => {
    const state = getState();
    const coverStartDate = state.coverDetails.coverStartDate?.value?.date;
    const schemeCode = state.quoteDetails.quoteInsurerSchemeReference;

    if (!coverStartDate || !schemeCode) {
      logger.warn(`Can't load NoClaimsDiscountProtectionData as one of scheme code [${schemeCode}] or cover start date [${coverStartDate}] is missing`);
      throw Error(`Can't load NoClaimsDiscountProtectionData as one of scheme code [${schemeCode}] or cover start date [${coverStartDate}] is missing`);
    }

    return await noClaimsDiscountProtectionDataQuery({schemeCode, coverStartDate});
  }
);