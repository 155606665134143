import {FC} from "react";
import NotificationBanner from "shared-components/dist/notification-banner/NotificationBanner";
import {useAppSelector} from "../../../../../../../../redux/Hooks";
import {selectIsSelectedQuoteTheCheapest} from "../../../../../shared/redux/IsSelectedQuoteTheCheapestSelector";

const ChangedQuotesNotificationBanner: FC = () => {
  const isSelectedQuoteTheCheapest = useAppSelector(selectIsSelectedQuoteTheCheapest);
  const hasBeenRequotedOnPage = useAppSelector(state => state.quoteDetails.requotedWithoutComparison);

  if (!hasBeenRequotedOnPage) return null;

  return (
    <NotificationBanner
      status="warning"
      title="quoteDetails.notificationBanner.changeOfDetails"
      description={
        isSelectedQuoteTheCheapest
          ? "quoteDetails.notificationBanner.changeOfDetails.newQuotes"
          : "quoteDetails.notificationBanner.changeOfDetails.foundACheaperQuote"
      }
    />
  );
};

export default ChangedQuotesNotificationBanner;