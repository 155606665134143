import {Policy} from "shared/dist/generated/graphql/resolvers-types";
import {apolloClient} from "../../apollo/ApolloClientProvider";
import {PortalFetchPolicyResult, PORTAL_FETCH_POLICY_FOR_RENEWAL_QUERY} from "shared/dist/graphql/queries/portal/RenewalPolicyQuery";

export async function loadPortalPolicyQueryForRenewals(id: string): Promise<Policy> {
  return new Promise((resolve, reject) => {
    apolloClient.query<PortalFetchPolicyResult>({
      query: PORTAL_FETCH_POLICY_FOR_RENEWAL_QUERY,
      variables: {id}
    }).then(result => resolve(result.data.policy))
      .catch(error => reject(error));
  });
}