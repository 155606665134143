import React, {FC} from "react";
import ModalQuestion from "../ModalQuestion";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {Validator} from "shared-components/dist/utils/validation/Validator";
import {Status} from "shared-components/dist/status-indicator/Status";
import Datepicker from "shared-components/dist/form/datepicker/Datepicker";

interface Props {
  id: string;
  title: TranslationKey;
  description?: TranslationKey;
  inputLabel?: TranslationKey;
  name: string;
  value: Date | undefined;
  onComplete: (date: Date) => void;
  onInvalid: (errorMessage: TranslationKey, invalidDate?: Date) => void;
  onClear: () => void;
  setIsFocusTrapPaused: (b: boolean) => void;
  validators?: Validator<Date>[];
  status?: Status;
  errorMessage?: TranslationKey;
}

const DateModalQuestion: FC<Props> = (
  {
    id,
    title,
    description,
    inputLabel,
    name,
    value,
    onComplete,
    onInvalid,
    onClear,
    setIsFocusTrapPaused,
    validators,
    status,
    errorMessage
  }
) => (
  <ModalQuestion
    id={id}
    title={title}
    description={description}
    inputLabel={inputLabel}
    status={status}
    errorMessage={errorMessage}
  >
    <Datepicker
      id={id}
      name={name}
      value={value}
      onComplete={onComplete}
      onInvalid={onInvalid}
      onClear={onClear}
      onOpen={() => setIsFocusTrapPaused(true)}
      onClose={() => setIsFocusTrapPaused(false)}
      validators={validators}
      popperProps={{container: document.querySelector(".modal")}}
    />
  </ModalQuestion>
);

export default DateModalQuestion;