import React, {FC, useEffect, useState} from "react";
import {dateOfBirthChanged, dateOfBirthInvalid, dateOfBirthReset} from "../../redux/UpdateAdditionalDriverSlice";
import DateOfBirthQuestion from "../../../../shared/questions/date-of-birth/DateOfBirthQuestion";
import HouseholdNumberOfCarsSubQuestion from "./HouseholdNumberOfCarsSubQuestion";
import {runValidators} from "shared-components/dist/utils/validation/Validator";
import {
  childSelectedValidator,
  grandchildSelectedValidator,
  grandparentSelectedValidator,
  parentSelectedValidator
} from "./validators/RelationshipToProposerValidators";
import {
  isOldEnoughForLicenceLengthValidator
} from "../../../../shared/questions/date-of-birth/validators/isOldEnoughForLicenceLengthValidator";
import {logAdditionalDriverGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {coverStartDateAsDateOptionSelector} from "../../../../your-cover/redux/selectors/CoverDetailsSelectors";
import {
  dateOfBirthAsDateSelector as proposerDateOfBirthSelector
} from "../../../../your-details/redux/selectors/PersonalDetailsSelectors";
import {dateOfBirthAsDateSelector} from "../../redux/selectors/AdditionalDriverDateTypeSelectors";
import {
  hasBeenUKResidentForLessThanAlive
} from "../../../../shared/questions/date-of-birth/validators/HasBeenUKResidentForLessThanAlive";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const AdditionalDriverDateOfBirthQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const dateOfBirthField = useAppSelector(dateOfBirthAsDateSelector);
  const coverStartDate = useAppSelector(state => coverStartDateAsDateOptionSelector(state).value?.date);
  const licenceLength = useAppSelector(state => state.updateAdditionalDriver.licenceDuration.value);
  const residencyDuration = useAppSelector(state => state.updateAdditionalDriver.ukResidencyDuration.value?.years);
  const householdNumberOfCarsQuestionIsActive = useAppSelector(state => state.updateAdditionalDriver.householdNumberOfCars.isActive);
  const relationshipToProposer = useAppSelector(state => state.updateAdditionalDriver.relationshipToProposer.value);
  const proposerDateOfBirth = useAppSelector(state => proposerDateOfBirthSelector(state).value);

  const [revalidate, setRevalidate] = useState(false);

  const onRevalidated = (): void => setRevalidate(false);

  useEffect(() => {
    if (!revalidate) setRevalidate(true);
    // Can't have revalidate as a dependency as this will trigger infinite re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationshipToProposer, residencyDuration, licenceLength]);

  const onDateOfBirthChanged = (additionalDriverDateOfBirth: Date): void => {
    const validationResult = runValidators(
      relationshipToProposer,
      childSelectedValidator({additionalDriverDateOfBirth, proposerDateOfBirth}),
      grandchildSelectedValidator({additionalDriverDateOfBirth, proposerDateOfBirth}),
      parentSelectedValidator({additionalDriverDateOfBirth, proposerDateOfBirth}),
      grandparentSelectedValidator({additionalDriverDateOfBirth, proposerDateOfBirth}),
    );

    validationResult.passed
      ? onValidDateOfBirth(additionalDriverDateOfBirth)
      : dispatch(dateOfBirthInvalid(validationResult.errorMessage));
  };

  const onValidDateOfBirth = (dateOfBirth: Date): void => {
    logAdditionalDriverGoogleAnalyticsEvent({
      categorySuffix: "date_of_birth",
      action: "Answered",
    });

    dispatch(dateOfBirthChanged(dateOfBirth.toISOString()));
  };

  return (
    <>
      <DateOfBirthQuestion
        id="additional-driver-date-of-birth"
        title="additionalDriver.dateOfBirthQuestion.title"
        dateOfBirthField={dateOfBirthField}
        dateOfBirthChanged={onDateOfBirthChanged}
        dateOfBirthInvalid={errorMessage => dispatch(dateOfBirthInvalid(errorMessage))}
        dateOfBirthReset={() => dispatch(dateOfBirthReset())}
        coverStartDate={coverStartDate}
        revalidate={revalidate}
        onRevalidated={onRevalidated}
        validators={[
          isOldEnoughForLicenceLengthValidator(licenceLength),
          hasBeenUKResidentForLessThanAlive(residencyDuration)
        ]}
      />
      {householdNumberOfCarsQuestionIsActive && <HouseholdNumberOfCarsSubQuestion/>}
    </>
  );
};

export default AdditionalDriverDateOfBirthQuestion;
