import React, {FC} from "react";
import YearsNoClaimsBonusQuestion from "../years-no-claims-bonus/YearsNoClaimsBonusQuestion";
import ExistingPolicyExpiryDateQuestion from "../existing-policy-expiry-date/ExistingPolicyExpiryDateQuestion";
import ProtectNoClaimsBonusQuestion from "../protect-no-claims-bonus/ProtectNoClaimsBonusQuestion";
import {useAppSelector} from "../../../../../../../redux/Hooks";

const PreviousCoverDetailsForm: FC = () => {
  const protectNoClaimsBonusField = useAppSelector(state => state.coverDetails.protectNoClaimsBonus);

  return (
    <>
      <YearsNoClaimsBonusQuestion/>
      {protectNoClaimsBonusField.isActive && <ProtectNoClaimsBonusQuestion/>}
      <ExistingPolicyExpiryDateQuestion/>
    </>
  );
};

export default PreviousCoverDetailsForm;