// Don't remove this as it'll break the global imports and typing
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as globalPlayInit from "@globalpayments/js";

export let GlobalPayProvider: typeof window.GlobalPayments;

export async function loadGlobalPayProvider(): Promise<void> {
  if (window.GlobalPayments) return;

  await loadGlobalPayJsLibrary();
}

function loadGlobalPayJsLibrary(): Promise<void> {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.defer = true;
    script.src = "https://js.globalpay.com/v1/globalpayments.js";
    script.onload = () => {
      if (window.GlobalPayments) {
        GlobalPayProvider = window.GlobalPayments;
        resolve();
      } else {
        reject();
      }
    };
    document.body.appendChild(script);
  });
}
