import React, {FC} from "react";
import {logYourCoverGoogleAnalyticsEvent} from "../../../../../../../../utils/analytics/MotorAnalytics";
import {
  vehiclePreviousPolicyExpiryDateChanged,
  vehiclePreviousPolicyExpiryDateInvalid,
  vehiclePreviousPolicyExpiryDateReset
} from "../../../../redux/CoverDetailsSlice";
import {useAppDispatch, useAppSelector} from "../../../../../../../../redux/Hooks";
import DateQuestion from "../../../../../../../../structure/questions/question/variants/date-question/DateQuestion";
import {vehiclePreviousPolicyExpiryDateAsDateSelector} from "../../../../redux/selectors/CoverDetailsSelectors";
import {endOfDay} from "date-fns";

const VehiclePreviousPolicyExpiryDateQuestion: FC = () => {
  const vehiclePreviousPolicyExpiryDate = useAppSelector(state => vehiclePreviousPolicyExpiryDateAsDateSelector(state));
  const dispatch = useAppDispatch();

  const onExpiryDateChanged = (expiryDate: Date): void => {
    const dateString = endOfDay(expiryDate).toISOString();

    logYourCoverGoogleAnalyticsEvent({
      categorySuffix: "vehicle_previous_policy_expiry_date",
      action: "Click",
    });

    dispatch(vehiclePreviousPolicyExpiryDateChanged(dateString));
  };

  return (
    <DateQuestion
      id="vehicle-previous-policy-expiry-date-question"
      title="coverDetails.vehiclePreviousPolicyExpiryDateQuestion.title"
      formField={vehiclePreviousPolicyExpiryDate}
      onComplete={onExpiryDateChanged}
      onInvalid={(errorMessage) => dispatch(vehiclePreviousPolicyExpiryDateInvalid(errorMessage))}
      onClear={() => dispatch(vehiclePreviousPolicyExpiryDateReset())}
      tooltip={{
        title: "structure.steps.tooltip.defaultTitle",
        description: "coverDetails.vehiclePreviousPolicyExpiryDateQuestion.tooltip"
      }}
      hideSpacer
    />
  );
};

export default VehiclePreviousPolicyExpiryDateQuestion;