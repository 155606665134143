import {
  noClaimsDiscountProtectionCostQuery
} from "../../../../../../graphql/queries/quote/no-claims-discount-protection-cost/NoClaimsDiscountProtectionCostQuery";
import {logger} from "../../../../../../utils/logging/Logger";
import {createAppAsyncThunk} from "../../../../../../redux/CreateAppAsyncThunk";

export const loadNoClaimsDiscountProtectionCost = createAppAsyncThunk(
  "quoteDetailsSlice/loadNoClaimsDiscountProtectionCost",
  async (_, {getState}) => {
    const state = getState();
    const quoteReference = state.quoteDetails.quoteReference;
    const quoteSequenceNumber = state.quoteDetails.quoteSequenceNumber;

    if (!quoteReference || !quoteSequenceNumber) {
      logger.warn(`Can't load NoClaimsDiscountProtectionCost as one of quote reference [${quoteReference}] or sequence number [${quoteSequenceNumber}] is missing`);
      throw Error(`Can't load NoClaimsDiscountProtectionCost as one of quote reference [${quoteReference}] or sequence number [${quoteSequenceNumber}] is missing`);
    }

    return await noClaimsDiscountProtectionCostQuery({reference: quoteReference, sequenceNumber: quoteSequenceNumber});
  }
);