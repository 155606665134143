import {buildUseReduxQuestionFromRecord, SectionQuestionsRecord} from "../Questions";
import {relationshipToProposer} from "./questions/RelationshipToProposer";
import {dateOfBirth} from "./questions/DateOfBirth";
import {maritalStatus} from "./questions/MaritalStatus";
import {primaryEmployment} from "./questions/PrimaryEmployment";
import {secondaryEmployment} from "./questions/SecondaryEmployment";
import {title} from "./questions/Title";
import {firstName} from "./questions/FirstName";
import {surname} from "./questions/Surname";
import {gender} from "./questions/Gender";
import {ukResidency} from "./questions/UkResidency";
import {classOfUse} from "./questions/ClassOfUse";
import {licence} from "./questions/Licence";
import {medicalCondition} from "./questions/MedicalCondition";
import {hasNonMotoringConvictions} from "./questions/HasNonMotoringConvictions";
import {hasInsuranceDeclined} from "./questions/HasInsuranceDeclined";
import {buildThunkToRunAllValidationForSection} from "../RunAllValidationForSection";
import {updateAdditionalDriverSliceName} from "../../../pages/quote/vehicle/additional-driver/shared/redux/UpdateAdditionalDriverSlice";

const additionalDriverQuestionsRecord = {
  relationshipToProposer,
  title,
  firstName,
  surname,
  dateOfBirth,
  gender,
  ...ukResidency,
  maritalStatus,
  ...primaryEmployment,
  ...secondaryEmployment,
  classOfUse,
  ...licence,
  ...medicalCondition,
  hasNonMotoringConvictions,
  hasInsuranceDeclined,
} as const satisfies SectionQuestionsRecord;

export const useAdditionalDriverQuestion = buildUseReduxQuestionFromRecord(
  additionalDriverQuestionsRecord
);

export const validateAllAdditionalDriverQuestions = buildThunkToRunAllValidationForSection(
  additionalDriverQuestionsRecord,
  updateAdditionalDriverSliceName
);