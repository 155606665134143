import {CustomisedAbiListItem} from "shared-components/dist/models/CustomisedAbiListItem";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {AccidentDescription} from "shared/dist/generated/graphql/resolvers-types";

export const ACCIDENT_DESCRIPTIONS = [
  {
    vtCode: AccidentDescription.HitByThirdPartyInFront,
    description: lookupI18nString("structure.questions.claims.accidentDescription.hitByThirdParty.front")
  },
  {
    vtCode: AccidentDescription.HitByThirdPartyInRear,
    description: lookupI18nString("structure.questions.claims.accidentDescription.hitByThirdParty.rear")
  },
  {
    vtCode: AccidentDescription.HitByThirdPartyInSide,
    description: lookupI18nString("structure.questions.claims.accidentDescription.hitByThirdParty.side")
  },
  {
    vtCode: AccidentDescription.HitByThirdPartyWhenParked,
    description: lookupI18nString("structure.questions.claims.accidentDescription.hitByThirdParty.whenParked")
  },
  {
    vtCode: AccidentDescription.HitByThirdPartyWhenReversing,
    description: lookupI18nString("structure.questions.claims.accidentDescription.hitByThirdParty.whenReversing")
  },
  {
    vtCode: AccidentDescription.HitThirdPartyInFront,
    description: lookupI18nString("structure.questions.claims.accidentDescription.hitThirdParty.front")
  },
  {
    vtCode: AccidentDescription.HitThirdPartyInRear,
    description: lookupI18nString("structure.questions.claims.accidentDescription.hitThirdParty.rear")
  },
  {
    vtCode: AccidentDescription.HitThirdPartyInSide,
    description: lookupI18nString("structure.questions.claims.accidentDescription.hitThirdParty.side")
  },
  {
    vtCode: AccidentDescription.HitThirdPartyWhenParked,
    description: lookupI18nString("structure.questions.claims.accidentDescription.hitThirdParty.whenParked")
  },
  {
    vtCode: AccidentDescription.HitThirdPartyWhenReversing,
    description: lookupI18nString("structure.questions.claims.accidentDescription.hitThirdParty.whenReversing")
  },
  {
    vtCode: AccidentDescription.NoOtherVehicle,
    description: lookupI18nString("structure.questions.claims.accidentDescription.noOtherVehicleInvolved")
  }
] as const;

export type AccidentDescriptionOption = CustomisedAbiListItem<AccidentDescription>;

export const ACCIDENT_DESCRIPTION_OPTIONS = ACCIDENT_DESCRIPTIONS.map(accident => accident.vtCode);
export const ACCIDENT_DESCRIPTION_DISPLAY_OPTIONS = ACCIDENT_DESCRIPTIONS.map(accident => accident.description);
