const SPECIAL_CHARACTERS = "\\^$*.\\[\\]{}\\(\\)?\\-“!@#%&\\/,><\\’:;|_~`";
export const PASSWORD_CRITERIA_PATTERN = new RegExp(`(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[${SPECIAL_CHARACTERS}]).{8,}`);
export type PasswordMatchState = "Initialised" | "Valid" | "Invalid" | "Incomplete";
export type PasswordFormatState = "Initialised" | "Valid" | "Invalid";

export function checkPasswordsMatch(password: string, repeatedPassword: string): PasswordMatchState {
  if (password && repeatedPassword) return password === repeatedPassword ? "Valid" : "Invalid";

  return "Incomplete";
}

export function checkPasswordFormat(password: string, email: string): PasswordFormatState {
  if (passwordContainsEmail(password, email)) return "Invalid";
  if (!isPasswordsCriteriaMet(password)) return "Invalid";

  return "Valid";
}

const passwordContainsEmail = (password: string, email: string): boolean => password.toLowerCase().includes(email.toLowerCase());

const isPasswordsCriteriaMet = (password: string): boolean => PASSWORD_CRITERIA_PATTERN.test(password);
