import React, {FC} from "react";
import "./PolicySelectionCard.css";
import {Policy} from "shared/dist/generated/graphql/resolvers-types";
import {getCardTitleFor, PortalPolicyStatus} from "../utils/PolicyStatusUtils";

interface Props {
  policy: Policy
  policyStatus: PortalPolicyStatus
  selectedPolicy: Policy
  setSelectedPolicy: (selectedPolicy: Policy) => void
}

const PolicySelectionCard: FC<Props> = ({
  policy,
  policyStatus,
  selectedPolicy,
  setSelectedPolicy
}) => (
  <div
    className={`policy-selection-card policy-selection-card__${policyStatus}${policy === selectedPolicy ? "__selected" : ""}`}
    onClick={() => setSelectedPolicy(policy)}
  >
    <div className="policy-selection-card__row">
      <div className="policy-selection-card__column">
        <div className="policy-selection-card__radio-button">
          <input
            id="policy-selection"
            type="radio"
            checked={policy === selectedPolicy}
            readOnly={true}
          />
        </div>
      </div>
      <div className="policy-selection-card__column">
        <div className={`policy-selection-card__title policy-selection-card__title__${policyStatus}`}>
          {getCardTitleFor(policyStatus)}
        </div>
        <div className={`policy-selection-card__registration policy-selection-card__registration__${policyStatus}`}>
          {policy.vehicleDetails.registeredVehicle.registration}
        </div>
      </div>
    </div>
  </div>
);

export default PolicySelectionCard;
