import {buildCrossFieldValidator, ReduxQuestion} from "../../Questions";
import {
  isHomeownerChanged,
  isHomeownerInvalid
} from "../../../../pages/quote/vehicle/your-details/redux/PersonalDetailsSlice";
import {dateOfBirth} from "./DateOfBirth";
import {
  isEligibleToBeHomeownerGivenTheDateOfBirth
} from "../../../../pages/quote/vehicle/shared/questions/date-of-birth/validators/IsOldEnoughToBeAHomeowner";

export const isHomeowner: ReduxQuestion<boolean> = {
  selector: state => state.personalDetails.isHomeowner,
  onChange: isHomeownerChanged,
  onInvalid: isHomeownerInvalid,
  crossFieldValidators: [buildCrossFieldValidator(
    state => dateOfBirth.selector(state).value,
    [isEligibleToBeHomeownerGivenTheDateOfBirth]
  )]
};