import React, {FC} from "react";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import {GENDER_OPTION_DESCRIPTIONS, GenderOption, GENDERS} from "./Genders";
import {StringRadioColumnQuestion} from "../../../../../../structure/questions/question/variants/radio-column-question/StringRadioColumnQuestion";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {ConditionalFormField} from "shared-components/dist/models/form-field/ConditionalFormField";

interface OwnProps {
  id: string;
  title: TranslationKey;
  tooltip: TooltipProps;
  genderField: ConditionalFormField<GenderOption>;
  genderChanged: (gender: GenderOption) => void;
}

const GenderQuestion: FC<OwnProps> = (
  {
    id,
    title,
    tooltip,
    genderField,
    genderChanged
  }
) => {
  const onGenderChanged = (selectedGender: string): void => {
    const gender = GENDERS.find(gender => gender.description === selectedGender);

    if (gender) genderChanged(gender);
  };

  if (!genderField.isActive) return null;

  return (
    <StringRadioColumnQuestion
      questionProps={{
        id,
        title,
        status: genderField.status,
        errorMessage: genderField.errorMessage,
        tooltip: tooltip
      }}
      radioColumnProps={{
        name: "gender-select",
        options: GENDER_OPTION_DESCRIPTIONS,
        selectedOption: genderField.value?.description,
        onSelection: onGenderChanged
      }}
    />
  );
};

export default GenderQuestion;