import React, {FC} from "react";
import NavigationPrompter from "../../../../../router/components/NavigationPrompter";
import {showPromptOnExitWhenLeavingQuoteFlow} from "./helpers/ShowPromptWhenLeavingQuoteFlow";

const QuoteNavigationPrompter: FC = () => {
  return (
    <NavigationPrompter messageBuilder={showPromptOnExitWhenLeavingQuoteFlow}/>
  );
};

export default QuoteNavigationPrompter;
