import {ReduxQuestion} from "../../Questions";
import {AbiListItem} from "shared/dist/generated/graphql/resolvers-types";
import {
  titleChanged,
  titleReset
} from "../../../../pages/quote/vehicle/additional-driver/shared/redux/UpdateAdditionalDriverSlice";

export const title: ReduxQuestion<AbiListItem> = {
  selector: state => state.updateAdditionalDriver.title,
  onChange: titleChanged,
  onReset: titleReset
};