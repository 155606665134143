import React, {Dispatch, FC, SetStateAction} from "react";
import "./RenewalsConfirmNumberOfCars.css";
import RenewalDetailsConfirmationTableHeader from "../table-items/RenewalDetailsConfirmationTableHeader";
import RenewalDetailsConfirmationButtonFooter from "../table-items/RenewalDetailsConfirmationButtonFooter";
import RenewalDetailsConfirmationTableItem from "../table-items/RenewalDetailsConfirmationTableItem";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";

interface Props {
  confirmed: boolean;
  setConfirmed: Dispatch<SetStateAction<boolean>>;
  setEditModalVisible: Dispatch<SetStateAction<boolean>>;
  previousSectionConfirmed: boolean;
}

const RenewalsConfirmNumberOfCars: FC<Props> = ({confirmed, setConfirmed, setEditModalVisible, previousSectionConfirmed}) => (
  <div
    className={confirmed ? "renewals-confirm-number-of-cars--confirmed" : `renewals-confirm-number-of-cars${previousSectionConfirmed ? "" : "--disabled"} unconfirmed`}
    data-testid="renewals-confirm-number-of-cars"
  >
    <RenewalDetailsConfirmationTableHeader title={lookupI18nString("portal.renewals.detailConfirmation.householdVehicles")} confirmed={confirmed}/>
    <RenewalDetailsConfirmationTableItem
      testId={"number-of-cars-confirmation"}
      title={lookupI18nString("portal.renewals.detailConfirmation.householdVehicles.title")}
      subtitle={lookupI18nString("portal.renewals.detailConfirmation.householdVehicles.subtitle")}
    />
    <RenewalDetailsConfirmationButtonFooter
      testId={"number-of-cars"}
      confirmed={confirmed}
      setConfirmed={setConfirmed}
      setEditModalVisible={setEditModalVisible}
      disabled={!previousSectionConfirmed}
    />
  </div>
);

export default RenewalsConfirmNumberOfCars;
