import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {CustomisedAbiListItem} from "shared-components/dist/models/CustomisedAbiListItem";
import {IncidentType} from "shared/dist/generated/graphql/resolvers-types";

export const INCIDENT_TYPES = [
  {
    vtCode: IncidentType.Accident,
    description: lookupI18nString("structure.questions.claims.incidentType.accident")
  },
  {
    vtCode: IncidentType.Theft,
    description: lookupI18nString("structure.questions.claims.incidentType.theft")
  },
  {
    vtCode: IncidentType.Windscreen,
    description: lookupI18nString("structure.questions.claims.incidentType.windscreen")
  },
  {
    vtCode: IncidentType.Fire,
    description: lookupI18nString("structure.questions.claims.incidentType.fire")
  },
  {
    vtCode: IncidentType.Malicious,
    description: lookupI18nString("structure.questions.claims.incidentType.maliciousDamage")
  },
  {
    vtCode: IncidentType.Riot,
    description: lookupI18nString("structure.questions.claims.incidentType.riot")
  },
  {
    vtCode: IncidentType.Storm,
    description: lookupI18nString("structure.questions.claims.incidentType.storm")
  },
  {
    vtCode: IncidentType.Vandalism,
    description: lookupI18nString("structure.questions.claims.incidentType.vandalism")
  },
] as const;

export type IncidentTypeOption = CustomisedAbiListItem<IncidentType>;

export const INCIDENT_TYPE_DESCRIPTIONS = INCIDENT_TYPES.map(type => type.description);
export const INCIDENT_TYPE_OPTIONS = INCIDENT_TYPES.map(type => type.vtCode);
