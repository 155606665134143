import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {FC} from "react";
import StepTitle from "../../questions/step-title/StepTitle";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./MainContent.css";

interface Props {
  title: TranslationKey;
  description?: TranslationKey;
}
const MainContent: FC<Props> = ({title, description, children}) => (
  <>
    <StepTitle title={title}/>

    {description && (
      <div className="main-content__description">
        {lookupI18nString(description)}
      </div>
    )}

    {children}
  </>
);

export default MainContent;