import {buildUseReduxQuestionFromRecord, SectionQuestionsRecord} from "../Questions";
import {dateOfBirth} from "./questions/DateOfBirth";
import {primaryEmployment} from "./questions/PrimaryEmployment";
import {secondaryEmployment} from "./questions/SecondaryEmployment";
import {classOfUse} from "./questions/ClassOfUse";
import {title} from "./questions/Title";
import {firstName} from "./questions/FirstName";
import {surname} from "./questions/Surname";
import {gender} from "./questions/Gender";
import {address} from "./questions/Address";
import {carKeptAtHome} from "./questions/CarKeptAtHome";
import {storedAtNight} from "./questions/StoredAtNight";
import {isHomeowner} from "./questions/IsHomeowner";
import {ukResidency} from "./questions/UkResidency";
import {emailAddress} from "./questions/EmailAddress";
import {phoneNumber} from "./questions/PhoneNumber";
import {licence} from "./questions/Licence";
import {maritalStatus} from "./questions/MaritalStatus";
import {soliciting} from "./questions/Soliciting";
import {medicalCondition} from "./questions/MedicalCondition";
import {hasNonMotoringConvictions} from "./questions/HasNonMotoringConvictions";
import {hasInsuranceDeclined} from "./questions/HasInsuranceDeclined";
import {mainDriver} from "./questions/MainDriver";
import {buildThunkToRunAllValidationForSection} from "../RunAllValidationForSection";
import {personalDetailsSliceName} from "../../../pages/quote/vehicle/your-details/redux/PersonalDetailsSlice";

export const YourDetailsValidatedQuestionsRecord = {
  title,
  firstName,
  surname,
  dateOfBirth,
  gender,
  address,
  carKeptAtHome,
  storedAtNight,
  isHomeowner,
  ...ukResidency,
  emailAddress,
  phoneNumber,
  maritalStatus,
  ...primaryEmployment,
  ...secondaryEmployment,
  classOfUse,
  soliciting,
  ...licence,
  ...medicalCondition,
  hasNonMotoringConvictions,
  hasInsuranceDeclined,
  mainDriver
} as const satisfies SectionQuestionsRecord;

export const useYourDetailsQuestion = buildUseReduxQuestionFromRecord(YourDetailsValidatedQuestionsRecord);

export const validateAllYourDetailsQuestions = buildThunkToRunAllValidationForSection(
  YourDetailsValidatedQuestionsRecord,
  personalDetailsSliceName
);