import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../../../redux/Hooks";
import {isNamedDriverAXAEligibleSelector} from "../redux/selectors/named-driver/IsNamedDriverAXAEligible";
import {
  namedDriverAndCompanyCarQuestionsMadeActive,
  namedDriverAndCompanyCarQuestionsReset,
  namedOnOwnVehicleNamedDriverQuestionsMadeActive
} from "../redux/CoverDetailsSlice";
import {
  selectIsVehicleBreakInCoverWithinMaxThreshold,
  selectNoBreakInCoverDetected,
  selectShouldShowNamedOnOwnVehicleNamedDriverQuestions
} from "../redux/selectors/named-driver/VehicleBreakInCoverSelectors";

export function useValidateNamedDriverFlow(): void {
  const dispatch = useAppDispatch();
  const isEligibleForNamedDriver = useAppSelector(isNamedDriverAXAEligibleSelector);
  const hasEnteredNamedDriverFlow = useAppSelector(state => state.coverDetails.hasEnteredNamedDriverFlow);
  const shouldShowNamedOnOwnVehicleNamedDriverQuestion = useAppSelector(selectShouldShowNamedOnOwnVehicleNamedDriverQuestions);
  const noBreakInCoverDetected = useAppSelector(selectNoBreakInCoverDetected);
  const isVehicleBreakInCoverWithinMaxBreakInCoverThreshold = useAppSelector(selectIsVehicleBreakInCoverWithinMaxThreshold);

  useEffect(() => {
    if (!isEligibleForNamedDriver && hasEnteredNamedDriverFlow) {
      dispatch(namedDriverAndCompanyCarQuestionsReset());
    }

    if (!isEligibleForNamedDriver) return;

    if (shouldShowNamedOnOwnVehicleNamedDriverQuestion) {
      dispatch(namedOnOwnVehicleNamedDriverQuestionsMadeActive());
    } else if (noBreakInCoverDetected) {
      dispatch(namedDriverAndCompanyCarQuestionsMadeActive());
    } else if (isVehicleBreakInCoverWithinMaxBreakInCoverThreshold === false) {
      dispatch(namedDriverAndCompanyCarQuestionsReset());
    }
  }, [isEligibleForNamedDriver, hasEnteredNamedDriverFlow, dispatch, noBreakInCoverDetected, shouldShowNamedOnOwnVehicleNamedDriverQuestion, isVehicleBreakInCoverWithinMaxBreakInCoverThreshold]);
}