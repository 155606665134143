import React, {FC} from "react";
import DropdownSubQuestion from "../../../../../../../structure/questions/sub-question/variants/dropdown-sub-question/DropdownSubQuestion";
import {registeredKeeperChanged} from "../../../redux/VehicleDetailsSlice";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import {
  VEHICLE_HOLDER_DESCRIPTIONS,
  VEHICLE_HOLDERS
} from "../../../../../../../utils/constants/VehicleHolderOption";
import RegisteredKeeperStop from "../stop/RegisteredKeeperStop";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const RegisteredKeeperQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const registeredKeeperField = useAppSelector(state => state.vehicleDetails.registeredKeeper);

  const onRegisteredKeeperSelected = (selectedRegisteredKeeper: string): void => {
    const registeredKeeper = VEHICLE_HOLDERS.find(
      registeredKeeper => registeredKeeper.description === selectedRegisteredKeeper
    );

    if (registeredKeeper) dispatch(registeredKeeperChanged(registeredKeeper));
  };

  const hasError = registeredKeeperField.status === "error";

  const tooltip: TooltipProps = {
    description: "vehicleQuote.registeredKeeperQuestion.tooltip.description",
  };

  return (
    <>
      <DropdownSubQuestion
        id="RegisteredKeeperQuestion"
        title="vehicleQuote.registeredKeeperQuestion.title"
        tooltip={tooltip}
        value={registeredKeeperField.value?.description}
        options={VEHICLE_HOLDER_DESCRIPTIONS}
        onChange={onRegisteredKeeperSelected}
        status={hasError ? "error" : "default"}
        errorMessage={registeredKeeperField.errorMessage}
      />
      <RegisteredKeeperStop/>
    </>
  );
};

export default RegisteredKeeperQuestion;