import React, {FC, useEffect} from "react";
import ErrorPage from "../../../portal/shared/error/ErrorPage";
import "./AccountAlreadyActivated.css";
import {logout} from "../../login/redux/thunks/Logout";
import {useAppDispatch} from "../../../../redux/Hooks";

const AccountAlreadyActivated: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(logout());
  });

  return (
    <ErrorPage
      title={"accountAlreadyActivated.title"}
      description={"accountAlreadyActivated.pleaseLogin"}
    />
  );
};

export default AccountAlreadyActivated;
