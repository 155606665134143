import {FC} from "react";
import {selectPolicySetupQuoteValues} from "../../../shared/redux/ProposedPolicyRequestSelector";
import {useAppSelector} from "../../../../../redux/Hooks";
import {PaymentFlowType} from "../../../shared/models/PaymentFlowType";
import AnnualPaymentByCard from "../AnnualPaymentByCard";

const QuoteAnnualPaymentByCard: FC = () => {
  const proposedPolicyRequest = useAppSelector(selectPolicySetupQuoteValues);

  return <AnnualPaymentByCard proposedRequest={proposedPolicyRequest} paymentFlowType={PaymentFlowType.QUOTE}/>;
};

export default QuoteAnnualPaymentByCard;