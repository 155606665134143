import {useAppSelector} from "../../../../../redux/Hooks";
import {
  financedRenewalRequestSelector,
  rebrokeFinancedPolicyRequestSelector
} from "../../../../payment/shared/redux/RenewalRequestSelector";
import {selectSelectedQuoteType} from "../RenewalSelectors";
import {ProposedFinancedPolicySetupRequest} from "../../../../payment/shared/models/ProposedFinancedPolicySetupRequest";
import {ProposedFinancedRenewalRequest} from "../../../../payment/shared/models/ProposedFinancedRenewalRequest";
import {PaymentFlowType} from "../../../../payment/shared/models/PaymentFlowType";

export interface RenewalTypeParams {
  setupRequest: ProposedFinancedPolicySetupRequest | ProposedFinancedRenewalRequest | undefined,
  paymentFlowType: PaymentFlowType
}

export function useRenewalQuoteTypePaymentValues(policyId: string): RenewalTypeParams | undefined {
  const proposedFinancedRenewalRequest = useAppSelector(financedRenewalRequestSelector(policyId));
  const rebrokeFinancedRequest = useAppSelector(rebrokeFinancedPolicyRequestSelector);
  const selectedQuoteType = useAppSelector(selectSelectedQuoteType);

  if (selectedQuoteType === "REBROKE") {
    return {setupRequest: rebrokeFinancedRequest, paymentFlowType: PaymentFlowType.REBROKE};
  }
  return {setupRequest: proposedFinancedRenewalRequest, paymentFlowType: PaymentFlowType.RENEWAL};
}