import React, {FC} from "react";
import {INCIDENT_TYPE_DESCRIPTIONS, INCIDENT_TYPE_OPTIONS, INCIDENT_TYPES, IncidentTypeOption} from "../models/IncidentTypeOption";
import DropdownSubQuestion
  from "../../../../../../../structure/questions/sub-question/variants/dropdown-sub-question/DropdownSubQuestion";
import {ClaimsFormSubQuestionProps} from "./ClaimsFormSubQuestionProps";

type Props = ClaimsFormSubQuestionProps<IncidentTypeOption>;

const IncidentTypeSubQuestion: FC<Props> = (
  {
    value,
    onChange,
    status,
    isAdditionalDriverForm
  }
) => {
  const onIncidentTypeSelected = (selection: string): void => {
    const selectedIncidentType = INCIDENT_TYPES.find(type => type.vtCode === selection);
    if (!selectedIncidentType) return;

    onChange(selectedIncidentType);
  };

  return (
    <DropdownSubQuestion
      id="incident-type-sub-question"
      title={
        isAdditionalDriverForm
          ? "additionalDriver.claims.type.title"
          : "personalDetails.claims.type.title"
      }
      errorMessage="quote.errors.questionIncomplete"
      value={value?.vtCode}
      status={status}
      displayValues={INCIDENT_TYPE_DESCRIPTIONS}
      options={INCIDENT_TYPE_OPTIONS}
      onChange={onIncidentTypeSelected}
    />
  );
};

export default IncidentTypeSubQuestion;