import React, {FC} from "react";
import RegistrationNumberLookup from "./components/registration-number-lookup/RegistrationNumberLookup";
import VehicleConfirmation from "./components/vehicle-confirmation/VehicleConfirmation";
import QuoteStep from "../shared/quote-step/QuoteStep";
import ManualVehicleLookup from "./components/manual-vehicle-lookup/ManualVehicleLookup";
import {warmUpVehicleLookupLambda} from "../../../../graphql/queries/vehicle-lookup/warm-up/VehicleLookupWarmUp";
import VehicleAssumptions from "./components/vehicle-assumptions/VehicleAssumptions";
import AdditionalVehicleDetailsForm from "./components/additional-vehicle-details-form/AdditionalVehicleDetailsForm";
import VehicleTrackerQuestion from "./components/main-vehicle-has-tracker-question/MainVehicleHasTrackerQuestion";
import {flagUnansweredQuestions} from "./validation/FlagUnansweredQuestions";
import {quoteSteps} from "../shared/quote-step/QuoteSteps";
import HaveYouBoughtCarYetQuestion from "./components/have-you-bought-car/HaveYouBoughtCarYetQuestion";
import OwnerRegisteredKeeperQuestion from "./components/owner-registered-keeper/OwnerRegisteredKeeperQuestion";
import Modifications from "./components/modifications/Modifications";
import {useLocation} from "react-router-dom";
import {buildQuoteRoute} from "../../../../utils/navigation/NavigationHelpers";
import IncompleteVehicleProhibitedFuelTypeStop from "./components/incomplete-vehicle-prohibited-fuel-type-stop/IncompleteVehicleProhibitedFuelTypeStop";
import {logYourCarGoogleAnalyticsEvent} from "../../../../utils/analytics/MotorAnalytics";
import {hasVehicleSummarySelector} from "./redux/selectors/VehicleSummarySelectors";
import {useAppDispatch, useAppSelector} from "../../../../redux/Hooks";

const YourVehicle: FC = () => {
  const dispatch = useAppDispatch();
  const vehicleDetailsFormValid = useAppSelector(quoteSteps.yourCar.nextRoute.validator);
  const showFooter = useAppSelector(hasVehicleSummarySelector);

  const location = useLocation();

  warmUpVehicleLookupLambda();

  const nextRoute = buildQuoteRoute(location.pathname, quoteSteps.yourCar.nextRoute);

  function onSubmit(): void {
    dispatch(flagUnansweredQuestions());

    logYourCarGoogleAnalyticsEvent({
      categorySuffix: "add_your_details",
      action: vehicleDetailsFormValid ? "Success" : "Failure",
    });
  }

  return (
    <QuoteStep
      forwardButtonLabel="vehicleQuote.footerButton"
      nextRoute={nextRoute}
      onSubmit={onSubmit}
      isFormValid={vehicleDetailsFormValid}
      title="vehicleQuote.title"
      showFooter={showFooter}
    >
      <RegistrationNumberLookup/>
      <ManualVehicleLookup/>
      <VehicleConfirmation/>
      <IncompleteVehicleProhibitedFuelTypeStop/>
      <VehicleAssumptions/>
      <AdditionalVehicleDetailsForm/>
      <VehicleTrackerQuestion/>
      <Modifications/>
      <HaveYouBoughtCarYetQuestion/>
      <OwnerRegisteredKeeperQuestion/>
    </QuoteStep>
  );
};

export default YourVehicle;
