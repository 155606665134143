import React, {Dispatch, FC, SetStateAction} from "react";
import SortCodeField from "./SortCodeField";
import Spacer from "shared-components/dist/spacer/Spacer";
import Title from "shared-components/dist/title/Title";
import "./BankAccountForm.css";
import AccountNumberField from "./AccountNumberField";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";

interface Props {
  showEditBankDetails: boolean;
  setShowEditBankDetails: Dispatch<SetStateAction<boolean>>;
}

const BankAccountForm: FC<Props> = ({showEditBankDetails, setShowEditBankDetails}) => (
  <div className="bank-account-form">
    <Spacer/>

    <Title title="paymentFlow.monthly.directDebit.form.bankDetails.title"/>

    <div className="bank-account-form__wrapper">
      <div className="bank-account-form__wrapper__fields">
        <SortCodeField/>
        <AccountNumberField/>
      </div>
      {showEditBankDetails && <a onClick={() => setShowEditBankDetails(false)}>{lookupI18nString("paymentFlow.monthly.directDebit.form.bankDetails.useOldDetails")}</a>}
    </div>
  </div>
);

export default BankAccountForm;
