import React, {FC} from "react";
import {Price} from "shared/dist/generated/graphql/resolvers-types";
import FormattedMessage from "shared-components/dist/translations/components/FormattedMessage";
import {FormattedNumber, FormattedNumberParts} from "react-intl";

export const FormattedPrice: FC<{ value: Price | undefined }> = ({value}) => {
  if (!value) {
    return <FormattedMessage id={"quoteDetails.depositAndPayments.placeholder"}/>;
  }

  return (<FormattedNumber value={Number(value.amount)} style="currency" currency={value.currency}/>);
};

export const FormattedPriceParts: FC<{ value: Price | undefined }> = ({value}) => {
  if (!value) {
    return <FormattedMessage id={"quoteDetails.depositAndPayments.placeholder"}/>;
  }

  return (<FormattedNumberParts value={Number(value.amount)} style="currency" currency={value.currency}>
    {parts => <EmphasisedPrice parts={parts}/>}
  </FormattedNumberParts>);
};

const EmphasisedPrice: FC<{parts: Intl.NumberFormatPart[]}> = ({parts}) => {
  const big: string[] = [];
  const small: string[] = [];

  parts.map(({type, value})=> {
    switch (type) {
      case "integer":
      case "group":
      case "currency":
        big.push(value);
        return;
      default:
        small.push(value);
        return;
    }
  });

  return <>
    <h1>{big.concat()}</h1>
    <h3>{small.concat()}</h3>
  </>;
};