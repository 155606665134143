import {gql} from "@apollo/client";
import {VehicleOptions} from "shared/dist/generated/graphql/resolvers-types";

export const ENGINE_CAPACITY_LITRES_LOOKUP_QUERY = gql`
  query EngineCapacityLitresLookup($filters: VehicleOptionsFilters!) {
    vehicle {
      vehicleOptions(optionsFilters: $filters) {
        engineCapacityLitres
      }
    }
  }
`;

export interface EngineCapacityLitresLookupResult {
  vehicleOptions: Pick<VehicleOptions, "engineCapacityLitres">;
}
