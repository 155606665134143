import {useFeatureFlags} from "shared-components/dist/feature-flags/hooks/UseFeatureFlags";
import {useAppSelector} from "../../../../../redux/Hooks";
import {isAggregatorQuoteSelector} from "../redux/selectors/AggregatorQuoteSelectors";
import {useHasQuoteValidationErrors} from "./hasQuoteValidationErrors";

export const useIsEditFlowEnabledForQuote = (): boolean => {
  const {compareNiPhase2Flag} = useFeatureFlags();
  const isAggregatorQuote = useAppSelector(isAggregatorQuoteSelector);
  const quoteHasValidationErrors = useHasQuoteValidationErrors();

  if (!isAggregatorQuote) return true;

  return compareNiPhase2Flag && !quoteHasValidationErrors;
};
