import React, {FC} from "react";
import DateSubQuestion from "../../../../../../../structure/questions/sub-question/variants/date-sub-question/DateSubQuestion";
import {isAfterManufactureDate, isValidPurchaseDate} from "../validators/PurchaseDateValidators";
import SubQuestionForm from "../../../../../../../structure/questions/sub-question-form/SubQuestionForm";
import {dateOfPurchaseChanged, dateOfPurchaseReset, dateOfPurchaseInvalid} from "../../../redux/VehicleDetailsSlice";
import {vehicleSummarySelector} from "../../../redux/selectors/VehicleSummarySelectors";
import {vehiclePurchaseDateAsDateSelector} from "../../../redux/selectors/VehicleDateTypeSelectors";
import {startOfDay} from "date-fns";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";

const PurchaseDateQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const carPurchaseDate = useAppSelector(vehiclePurchaseDateAsDateSelector);
  const vehicleSummary = useAppSelector(vehicleSummarySelector);

  const onComplete = (date: Date): void => {
    const startOfDate = startOfDay(date);
    dispatch(dateOfPurchaseChanged(startOfDate.toISOString()));
  };

  const onInvalid = (errorMessage: TranslationKey): void => {
    dispatch(dateOfPurchaseInvalid(errorMessage));
  };

  const onClear = (): void => {
    dispatch(dateOfPurchaseReset());
  };

  return (
    <>
      <SubQuestionForm>
        <DateSubQuestion
          id="date-car-purchased"
          title="vehicleQuote.haveYouBoughtTheCarYetQuestion.purchaseDate.title"
          value={carPurchaseDate.value}
          status={carPurchaseDate.status}
          errorMessage={carPurchaseDate.errorMessage}
          onComplete={onComplete}
          onInvalid={onInvalid}
          onClear={onClear}
          validators={[
            isAfterManufactureDate(vehicleSummary?.yearOfManufacture),
            isValidPurchaseDate()
          ]}
        />
      </SubQuestionForm>
    </>
  );
};

export default PurchaseDateQuestion;