import {EmploymentStatus} from "shared/dist/generated/graphql/resolvers-types";

// ABI category for these: UQR07
export const EMPLOYMENT_STATUS = [
  {
    vtCode: EmploymentStatus.Employed,
    description: "Employed"
  },
  {
    vtCode: EmploymentStatus.FullTimeStudent,
    description: "Full time education"
  },
  {
    vtCode: EmploymentStatus.SelfEmployed,
    description: "Self Employed"
  },
  {
    vtCode: EmploymentStatus.Retired,
    description: "Retired"
  },
  {
    vtCode: EmploymentStatus.Unemployed,
    description: "Unemployed"
  },
  {
    vtCode: EmploymentStatus.UnemployedDueToDisability,
    description: "Not in employment due to disability"
  },
  {
    vtCode: EmploymentStatus.HouseSpouse,
    description: "House wife"
  },
  {
    vtCode: EmploymentStatus.HouseSpouse,
    description: "House husband"
  },
  {
    vtCode: EmploymentStatus.VoluntaryWorker,
    description: "Voluntary worker"
  },
  {
    vtCode: EmploymentStatus.IndependentMeans,
    description: "Independent means"
  }
] as const;

export type EmploymentStatusOption = typeof EMPLOYMENT_STATUS[number];

export const EMPLOYMENT_STATUS_OPTIONS = EMPLOYMENT_STATUS.map(employmentStatus => employmentStatus.description);