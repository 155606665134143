import {SessionState} from "./SessionState";
import {LoginResult} from "../models/login/LoginResponse";
import {AccountActivationResult} from "../models/account-activation/AccountActivationResult";
import {ResetPasswordResult} from "../models/password-management/ResetPasswordResult";

export function mapLoginResultToCurrentSessionState(loginResult: LoginResult): SessionState {
  return loginResult === "Success" ? "Valid" : "Invalid";
}

export function mapAccountActivationResultToCurrentSessionState(accountActivationResult: AccountActivationResult): SessionState {
  return accountActivationResult === "Success" ? "Valid" : "Invalid";
}

export function mapResetPasswordResultToCurrentSessionState(resetPasswordResult: ResetPasswordResult): SessionState {
  return resetPasswordResult === "Success" ? "Valid" : "Invalid";
}
