import React, {FC, ReactElement, useState} from "react";
import "./PolicyExtraSidebarSelectorListItem.css";
import Switch from "shared-components/src/form/switch/Switch";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {Cancel, InfoOutlined} from "@mui/icons-material";
import TooltipCard from "shared-components/dist/cards/tooltip-card/TooltipCard";
import FormattedMessage from "shared-components/src/translations/components/FormattedMessage";
import classNames from "classnames";
import {isMobile} from "react-device-detect";
import {
  autoUpdate,
  FloatingFocusManager,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions
} from "@floating-ui/react";
import {CurrencyFormat} from "../../../../../utils/money/CurrencyFormat";
import {getIdFromTranslationKey, TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {useAppSelector} from "../../../../../redux/Hooks";
import {selectAreRenewalProspectiveLoansLoading} from "../../redux/RenewalSelectors";

interface Props {
  testId: string
  title: TranslationKey,
  description: TranslationKey,
  price: number | undefined
  included: boolean;
  toggleIncluded: (included: boolean) => void;
  priceCellChildren?: ReactElement;
  onAnchorClick?: () => void;
  anchorText?: TranslationKey
}

const PolicyExtraSidebarSelectorListItem: FC<Props> = (
  {
    testId,
    title,
    description,
    price,
    included,
    toggleIncluded,
    priceCellChildren,
    onAnchorClick,
    anchorText,
  }
) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const isSwitchDisabled = useAppSelector(selectAreRenewalProspectiveLoansLoading);

  const {x, y, strategy, refs, context} = useFloating({
    strategy: "absolute",
    placement: isMobile ? "bottom" : "right",
    middleware: [offset(() => isMobile ? -15 : 25)],
    open: tooltipVisible,
    onOpenChange: setTooltipVisible,
    whileElementsMounted: autoUpdate
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const focus = useFocus(context);
  const {getReferenceProps, getFloatingProps} = useInteractions([click, dismiss, focus]);

  return (
    <>
      <div className="policy-extra-sidebar-selector-list__item" data-testid={`policy-extra-sidebar-selector-list-${testId}`}>
        <div className="policy-extra-sidebar-selector-list__item__title-section">
          <div
            className="policy-extra-sidebar-selector-list__item__title-section__title"
            ref={!isMobile ? refs.setReference : null} {...getReferenceProps()}
          >
            <h5>{lookupI18nString(title)}</h5>

            <button
              className="policy-extra-sidebar-selector-list__item__description-toggle"
              ref={isMobile ? refs.setReference : null} {...getReferenceProps()}
            >
              {tooltipVisible ? <Cancel/> : <InfoOutlined/>}
            </button>
          </div>

          <Switch value={included} onChange={toggleIncluded} disabled={isSwitchDisabled}/>
        </div>

        <p className="policy-extra-sidebar-selector-list__item__price">
          {CurrencyFormat.formatStringAmountWithSymbol(price?.toFixed(2) ?? undefined)}
        </p>

        {priceCellChildren}

        {tooltipVisible &&
          <FloatingFocusManager context={context} modal={false} initialFocus={-1}>
            <div
              className={classNames("policy-extra-sidebar-selector-list__item__tooltip", {"policy-extra-sidebar-selector-list__item__tooltip--visible": tooltipVisible})}
              ref={refs.setFloating}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0
              }}
              {...getFloatingProps()}
            >
              <TooltipCard
                isVisible={true}
                description={
                  <FormattedMessage
                    id={getIdFromTranslationKey(description)}
                    values={{
                      b: function bold(msg: string): ReactElement {
                        return <b>{msg}</b>;
                      },
                      br: <br/>
                    }}
                  />}
                onAnchorClick={onAnchorClick}
                anchorText={anchorText ? anchorText : "structure.lists.policyExtras.description.anchorText"}
              />
            </div>
          </FloatingFocusManager>
        }
      </div>
    </>
  );
};

export default PolicyExtraSidebarSelectorListItem;
