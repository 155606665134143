import React, {FC} from "react";
import QuoteJourneyStop from "../../stops/quote-journey-stop/QuoteJourneyStop";

interface Props {
  isVisible: boolean;
  isAdditionalDriverForm: boolean;
}

const ResidencyDurationStop: FC<Props> = (
  {
    isVisible,
    isAdditionalDriverForm
  }
) => (
  <QuoteJourneyStop
    stopProps={{
      visible: isVisible,
      title: isAdditionalDriverForm
        ? "additionalDriver.residency.duration.stop.title"
        : "personalDetails.residency.duration.stop.title",
      description: "quoteJourney.shared.softStop.description"
    }}
    isSubContainer
  />
);

export default ResidencyDurationStop;