import {createAppAsyncThunk} from "../../../../../redux/CreateAppAsyncThunk";
import {InsurerQuote} from "../../../../quote/vehicle/your-quote/models/InsurerQuote";
import {
  NoClaimsDiscountProtectionDataResult,
  Policy,
  Price,
  QuoteExtra,
  RenewalDocuments,
  RenewalNotice
} from "shared/dist/generated/graphql/resolvers-types";
import {RenewalQuote} from "../../../../quote/vehicle/your-quote/models/RenewalQuote";
import {fetchProspectiveLoansQuery} from "../../../../quote/vehicle/your-quote/redux/thunks/FetchProspectiveLoansQuery";
import {quoteDocumentsQuery} from "../../../../../graphql/queries/quote/documents/Documents";
import {
  getRebrokedQuoteMutation
} from "../../../../../graphql/mutations/quote/get-rebroked-quote/GetRebrokedQuoteMutation";
import {mapRenewalPolicyExtrasToQuoteExtras} from "../../models/RenewalPolicyExtras";
import {noClaimsDiscountProtectionDataQuery} from "../../../../../graphql/queries/quote/no-claims-discount-protection-data/NoClaimsDiscountProtectionDataQuery";
import {logger} from "../../../../../utils/logging/Logger";

export interface LoadRebrokedQuoteArgs {
  policy: Policy | undefined;
  policyExtras: QuoteExtra[];
  depositPercentage: number;
  shouldProtectNoClaimsBonus: boolean;
  renewalNotice: RenewalNotice | undefined;
}

export interface RebrokedQuoteValues {
  rebrokedQuote: RenewalQuote | undefined;
  extrasTotalAmount: Price | undefined;
  noClaimsBonusProtectionCost: Price | undefined;
  noClaimsBonusProtectionData: NoClaimsDiscountProtectionDataResult | undefined;
}

export const loadRebrokedQuote = createAppAsyncThunk(
  "renewalSlice/loadRebrokedQuote",
  async (shouldProtectNoClaimsBonus: boolean, {getState}) => {
    const state = getState();

    return await getRebrokedQuote({
      policy: state.renewal.policy,
      policyExtras: mapRenewalPolicyExtrasToQuoteExtras(state.renewal.policyExtras),
      depositPercentage: state.renewal.depositPercentage,
      shouldProtectNoClaimsBonus,
      renewalNotice: state.renewal.renewalNotice
    });
  }
);

export async function getRebrokedQuote(
  {
    policy,
    policyExtras,
    depositPercentage,
    shouldProtectNoClaimsBonus,
    renewalNotice
  }: LoadRebrokedQuoteArgs
): Promise<RebrokedQuoteValues> {
  try {
    if (!policy || !renewalNotice) throw Error("Failed to fetch rebroked quote due to missing policy or renewalNotice");

    const rebrokedQuoteResult = await getRebrokedQuoteMutation({
      policyId: policy.id,
      extraOptionTypes: policyExtras.map(extra => (extra.optionType)),
      protectNoClaimsBonus: shouldProtectNoClaimsBonus
    });

    if (!rebrokedQuoteResult || !rebrokedQuoteResult.quote) throw Error("Failed to fetch rebroked quote.");

    const noClaimsBonusProtectionData = await getNoClaimsDiscountProtectionData(
      renewalNotice.renewalTimestamp,
      rebrokedQuoteResult?.quote?.insurerSchemeReference
    );

    return {
      rebrokedQuote: await getRebrokedQuoteValues(rebrokedQuoteResult.quote, depositPercentage, policy, renewalNotice),
      extrasTotalAmount: rebrokedQuoteResult.totalExtrasPrice ?? undefined,
      noClaimsBonusProtectionCost: rebrokedQuoteResult.noClaimsBonusProtectionCost ?? undefined,
      noClaimsBonusProtectionData
    };
  } catch (error) {
    logger.warn(error);
    return {
      rebrokedQuote: undefined,
      extrasTotalAmount: undefined,
      noClaimsBonusProtectionCost: undefined,
      noClaimsBonusProtectionData: undefined
    };
  }
}

async function getRebrokedQuoteValues(
  rebrokedQuote: InsurerQuote | undefined,
  depositPercentage: number,
  policy: Policy,
  renewalNotice: RenewalNotice,
): Promise<RenewalQuote | undefined> {
  if (!rebrokedQuote) return undefined;

  const prospectiveLoansResponse = await fetchProspectiveLoansQuery(
    rebrokedQuote.reference,
    rebrokedQuote.sequenceNumber,
    depositPercentage
  );

  const documents = await getQuoteDocuments(
    policy.coverDetails.coverType,
    renewalNotice.renewalTimestamp,
    rebrokedQuote.reference,
    rebrokedQuote.insurerSchemeReference
  ) as RenewalDocuments;

  return {
    quoteType: "REBROKE",
    prospectiveLoan: prospectiveLoansResponse?.prospectiveLoans?.[0],
    totalUnfinancedAmountPayable: prospectiveLoansResponse?.totalUnfinancedAmountPayable,
    totalFinancedAmountPayable: prospectiveLoansResponse?.prospectiveLoans?.[0].totalAmountPayable,
    insurerName: rebrokedQuote.insurer,
    brokerDiscount: rebrokedQuote.premiumPriceBreakdownDetails?.discount,
    premium: rebrokedQuote.premiumPriceBreakdownDetails?.originalPrice ?? rebrokedQuote.premiumPrice,
    reference: rebrokedQuote.reference,
    insurerSchemeReference: rebrokedQuote.insurerSchemeReference,
    sequenceNumber: rebrokedQuote.sequenceNumber,
    adminFee: rebrokedQuote?.premiumPriceBreakdownDetails?.adminFee,
    documents,
    compulsoryExcess: rebrokedQuote.compulsoryExcessPrice,
    voluntaryExcess: rebrokedQuote.voluntaryExcessPrice
  };
}

async function getQuoteDocuments(
  coverType: string,
  effectiveDate: string,
  quoteReference: string,
  schemeReference: string
): Promise<RenewalDocuments | undefined> {
  try {
    return await quoteDocumentsQuery({
      coverType,
      effectiveDate,
      quoteReference,
      schemeReference
    }) as RenewalDocuments;
  } catch (error) {
    logger.warn(`Failed to load rebroked quote documents for quote reference [${quoteReference}]`);
    return undefined;
  }
}

async function getNoClaimsDiscountProtectionData(
  renewalTimestamp: string,
  insurerSchemeReference: string | undefined
): Promise<NoClaimsDiscountProtectionDataResult | undefined> {
  if (!renewalTimestamp || !insurerSchemeReference) {
    logger.warn(`Can't load NoClaimsDiscountProtectionData as one of scheme code [${insurerSchemeReference}] or renewal timestamp [${renewalTimestamp}] is missing`);
    return undefined;
  }

  try {
    return await noClaimsDiscountProtectionDataQuery({schemeCode: insurerSchemeReference, coverStartDate: renewalTimestamp});
  } catch (error) {
    logger.warn(`Failed to load NoClaimsDiscountProtectionData for scheme code [${insurerSchemeReference}] and renewal timestamp [${renewalTimestamp}]`);
    return undefined;
  }
}
