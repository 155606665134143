import {useNavigate} from "react-router-dom";
import {endOfDay, isAfter, startOfDay} from "date-fns";
import {addDays} from "date-fns/fp";
import {buildQuoteRoute} from "../../../../../../../utils/navigation/NavigationHelpers";
import {EXPIRED_QUOTE} from "../../../../../../../router/models/Routes";
import {useEffect} from "react";
import {useAppSelector} from "../../../../../../../redux/Hooks";

const SAVED_QUOTE_VALID_PERIOD_IN_DAYS = 30;

export function useExpiredSavedQuoteDetection(): void {
  const navigate = useNavigate();
  const quoteGenerationDate = useAppSelector(state => state.quoteDetails.savedQuoteGenerationDate);

  useEffect(() => {
    if (quoteGenerationDate === undefined) return;

    if (hasSavedQuoteExpired(startOfDay(new Date(quoteGenerationDate)))) {
      navigate(buildQuoteRoute(location.pathname, EXPIRED_QUOTE));
      return;
    }
  }, [quoteGenerationDate, navigate]);
}

export function hasSavedQuoteExpired(quoteGeneration: Date): boolean {
  const now = new Date();
  const validPeriodEnd = endOfDay(addDays(SAVED_QUOTE_VALID_PERIOD_IN_DAYS, quoteGeneration));

  return isAfter(now, validPeriodEnd);
}