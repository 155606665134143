import React, {FC, ReactChild} from "react";
import PlainHeader from "../../../../structure/navigation/plain-header/PlainHeader";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import CircleIcon from "shared-components/dist/icons/circle-icon/CircleIcon";
import {Close} from "@mui/icons-material";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./ErrorPage.css";

interface Props {
  title: TranslationKey,
  description: TranslationKey,
  header?: ReactChild
}

const ErrorPage: FC<Props> = (
  {
    title,
    description,
    header,
    children
  }
) => (
  <div className="error-container" data-testid="error-page">
    {header ? header : <PlainHeader/>}
    <div className="error-container__message">
      <div className="error-container__message__icon-container">
        <CircleIcon status="error">
          <Close/>
        </CircleIcon>
      </div>
      <h2>
        {lookupI18nString(title)}
      </h2>
      <h4>
        {lookupI18nString(description)}
      </h4>
      <div className="error-container__message__children">
        {children}
      </div>
    </div>
  </div>
);

export default ErrorPage;
