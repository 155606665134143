import {gql} from "@apollo/client";
import {VehicleSpecLookupItem} from "shared/dist/generated/graphql/resolvers-types";

export interface VehicleFullSpecQueryResult {
  vehicle: VehicleFullSpecResult;
}

export interface VehicleFullSpecResult {
  vehicleSpecLookup: VehicleSpecLookupItem[];
}

export const FULL_SPEC_LOOKUP_QUERY = gql`
  query FullSpecLookup($filters: VehicleSpecLookupFilters!) {
    vehicle {
      vehicleSpecLookup(specLookupFilters: $filters) {
        abi
        make
        model
        trim
        bodyStyle
        engineCapacityCc
        engineCapacityLitres
        fuelType
        transmissionType
        yearOfManufactureStart
        yearOfManufactureEnd
        brakeHorsepower
        doors
      }
    }
  }
`;
