import {RootState} from "../../../../redux/Store";
import {
  CoverDetails,
  NoClaimsDiscountProtectionDataResult,
  Policy,
  PolicyHolder,
  Price,
  ProspectiveLoanWithPrice,
  ProspectivePolicyExtra,
  RenewalDocuments,
  RenewalNotice
} from "shared/dist/generated/graphql/resolvers-types";
import {RenewalPolicyExtras} from "../models/RenewalPolicyExtras";
import {Status} from "shared-components/dist/status-indicator/Status";
import {PaymentFrequency} from "../../../quote/vehicle/your-quote/models/SavedQuote";
import {RenewalQuote} from "../../../quote/vehicle/your-quote/models/RenewalQuote";
import {RenewalQuoteType} from "../models/RenewalQuoteType";
import {createSelector} from "@reduxjs/toolkit";

export const selectRenewalDepositPercentage = (state: RootState): number => state.renewal.depositPercentage;
export const selectActiveRenewalNotice = (state: RootState): RenewalNotice | undefined => state.renewal?.renewalNotice;
export const selectTotalPolicyExtraAmount = (state: RootState): Price => {
  return state.renewal.extrasTotalAmount ?? {
    amount: "0",
    currency: "gbp"
  };
};
export const selectNoClaimsBonusProtectionCost = (state: RootState): Price | undefined => state.renewal.noClaimsBonusProtectionCost;
export const selectNoClaimsBonusProtectionData = (state: RootState): NoClaimsDiscountProtectionDataResult | undefined => state.renewal.noClaimsBonusProtectionData;
export const selectRenewalPolicyExtras = (state: RootState): RenewalPolicyExtras => state.renewal.policyExtras;
export const selectAreRenewalProspectiveLoansLoading = (state: RootState): boolean => state.renewal.loadRebrokedQuoteStatus === "loading" || state.renewal.loadRenewalProspectiveLoansStatus === "loading" || state.renewal.loadRebrokeProspectiveLoansStatus === "loading";
export const selectPreviouslyHadCloseBrothersLoan = (state: RootState): boolean => !!state.renewal.policy?.paymentDetails?.loanReference;
export const selectExistingPolicyForRenewal = (state: RootState): Policy | undefined => state.renewal.policy;
export const selectExistingPolicyHolderForRenewal = (state: RootState): PolicyHolder | undefined => state.renewal.policyHolder;
export const selectIsEligibleForNoClaimsBonusProtection = createSelector(
  selectExistingPolicyHolderForRenewal,
  (policyHolder): boolean | undefined => parseInt(policyHolder?.noClaimsBonusYears ?? "") >= 4
);
export const selectAvailablePolicyExtras = (state: RootState): ProspectivePolicyExtra[] => state.renewal.availablePolicyExtras;
export const selectPolicyExtras = (state: RootState): RenewalPolicyExtras => state.renewal.policyExtras;
export const selectLoadRenewalSummaryStatus = (state: RootState): Status => state.renewal.loadRenewalSummaryStatus;
export const selectRenewalRebrokePaymentFrequency = (state: RootState): PaymentFrequency | undefined => state.renewal.paymentFrequency;
export const selectRenewalQuote = (state: RootState): RenewalQuote | undefined => state.renewal.renewalQuote;
export const selectRebrokedQuote = (state: RootState): RenewalQuote | undefined => state.renewal.rebrokedQuote;
export const selectSortedRenewalQuotes = createSelector(
  selectRenewalQuote,
  selectRebrokedQuote,
  (renewalQuote, rebrokedQuote): RenewalQuote[] => [renewalQuote, rebrokedQuote]
    .filter((quote): quote is RenewalQuote & {premium: Price} => quote !== undefined && quote.premium !== undefined)
    .sort(({premium: first}, {premium: second}) => +first.amount - +second.amount)
);
export const selectAreBothRenewalOptionsAvailable = createSelector(
  selectSortedRenewalQuotes,
  (sortedRenewalQuotes): boolean => sortedRenewalQuotes.length === 2
);
export const selectShowMonthlyPaymentsAsDefault = createSelector(
  selectAreRenewalProspectiveLoansLoading,
  selectPreviouslyHadCloseBrothersLoan,
  selectRenewalQuote,
  selectRebrokedQuote,
  (
    areRenewalProspectiveLoansLoading,
    previouslyHadCloseBrothersLoan,
    renewalQuote,
    rebrokedQuote,
  ): boolean => {
    return !areRenewalProspectiveLoansLoading
      && previouslyHadCloseBrothersLoan
      && (renewalQuote === undefined || renewalQuote.prospectiveLoan !== undefined)
      && (rebrokedQuote === undefined || rebrokedQuote.prospectiveLoan !== undefined);
  }
);
export const selectSelectedQuoteType = (state: RootState): RenewalQuoteType | undefined => state.renewal.selectedQuoteType;
export const selectPremium = (state: RootState): Price | undefined => state.renewal.premium;
export const selectRenewalAdminFee = (state: RootState): Price | undefined => state.renewal.adminFee;
export const selectRebrokeAdminFee = (state: RootState): Price | undefined => state.renewal.rebrokedQuote?.adminFee;
export const selectBrokerDiscount = (state: RootState): Price | undefined => state.renewal.brokerDiscount;
export const selectTotalFinancedRenewalAmount = (state: RootState): Price | undefined => state.renewal.totalFinancedAmountPayable;
export const selectTotalUnfinancedRenewalAmount = (state: RootState): Price | undefined => state.renewal.totalUnfinancedAmountPayable;
export const selectTotalUnFinancedRebrokeAmount = (state: RootState): Price | undefined => state.renewal.rebrokedQuote?.totalUnfinancedAmountPayable;
export const selectTotalFinancedRebrokeAmount = (state: RootState): Price | undefined => state.renewal.rebrokedQuote?.totalFinancedAmountPayable;
export const selectActiveProspectiveLoan = (state: RootState): ProspectiveLoanWithPrice | undefined => state.renewal.prospectiveLoan;
export const selectShouldProtectNoClaimsBonus = (state: RootState): boolean | undefined => state.renewal.shouldProtectNoClaimsBonus;
export const selectAllDetailsConfirmed = (state: RootState): boolean => state.renewal.allDetailsConfirmed;
export const selectPolicyDocuments = (state: RootState): RenewalDocuments | undefined => {
  if (state.renewal.selectedQuoteType === "RENEWAL") return state.renewal.renewalQuote?.documents;
  if (state.renewal.selectedQuoteType === "REBROKE") return state.renewal.rebrokedQuote?.documents;
  return undefined;
};
export const selectRenewalInsurerName = (state: RootState): string | undefined => state.renewal.insurerName;
export const selectRebrokeInsurerName = (state: RootState): string | undefined => state.renewal.rebrokedQuote?.insurerName;
export const selectShouldGetRebrokedQuoteWithNCBProtection = createSelector(
  selectExistingPolicyForRenewal,
  selectNoClaimsBonusProtectionCost,
  (
    existingPolicy,
    noClaimsBonusProtectionCost
  ): boolean => {
    if (!existingPolicy) throw new Error("No existing policy for renewal.");

    return !noClaimsBonusProtectionCost && existingPolicy.coverDetails.noClaimsProtection;
  }
);

export const selectRenewalCoverDetails = createSelector(
  selectExistingPolicyForRenewal,
  selectRebrokedQuote,
  selectSelectedQuoteType,
  (
    policy,
    rebrokeQuote,
    selectedQuoteType
  ): CoverDetails => {
    if (!policy) throw new Error("No existing policy for renewal.");
    if (selectedQuoteType === "REBROKE" && rebrokeQuote?.voluntaryExcess && rebrokeQuote?.compulsoryExcess) {
      return {
        ...policy.coverDetails,
        excess: {
          voluntary: parseFloat(rebrokeQuote.voluntaryExcess.amount),
          compulsory: parseFloat(rebrokeQuote.compulsoryExcess.amount)
        }
      };
    }
    return policy?.coverDetails;
  }
);
