import {AlcoholTestMethod} from "shared/dist/generated/graphql/resolvers-types";

export const ALCOHOL_TEST_METHODS = [
  {
    id: AlcoholTestMethod.Breath,
    description: "Breath"
  },
  {
    id: AlcoholTestMethod.Urine,
    description: "Urine"
  },
  {
    id: AlcoholTestMethod.Blood,
    description: "Blood"
  },
] as const;

export const ALCOHOL_TEST_METHOD_OPTIONS = ALCOHOL_TEST_METHODS.map(testMethod => testMethod.description);

export type AlcoholTestMethodOption = typeof ALCOHOL_TEST_METHODS[number];

export function toAlcoholTestMethod(value: string | undefined): AlcoholTestMethodOption | undefined {
  return ALCOHOL_TEST_METHODS.find(testMethod => testMethod.description === value);
}