import {DateOption} from "shared/dist/models/date-option/DateOption";
import {
  validationFailure,
  ValidationResult,
  validationSuccess,
  Validator
} from "shared-components/dist/utils/validation/Validator";
import {isAfter} from "date-fns/fp";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {startOfDay} from "date-fns";

export function isCarOwnerOnCoverStartDate(dateOfPurchase: Date | undefined): Validator<DateOption> {
  return ({date: coverStartDate}: DateOption): ValidationResult => {
    if (!dateOfPurchase) return validationSuccess;

    return isAfter(coverStartDate, dateOfPurchase)
      ? validationFailure("coverDetails.errors.coverStartDateQuestion.carNotYetPurchased")
      : validationSuccess;
  };
}

interface IsDateAfterCoverStartDateProps {
  date: Date | undefined;
  validationErrorMessage: TranslationKey;
}

export function isDateAfterCoverStartDate({date, validationErrorMessage}: IsDateAfterCoverStartDateProps): Validator<DateOption> {
  return ({date: coverStartDate}: DateOption): ValidationResult => {
    if (!date) return validationSuccess;

    return isAfter(startOfDay(coverStartDate), startOfDay(date))
      ? validationFailure(validationErrorMessage)
      : validationSuccess;
  };
}