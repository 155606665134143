import {QuoteQueryNoClaimsDiscountProtectionCostArgs} from "shared/dist/generated/graphql/resolvers-types";
import {apolloClient} from "../../../apollo/ApolloClientProvider";
import {
  NO_CLAIMS_DISCOUNT_PROTECTION_COST_QUERY,
  NoClaimsDiscountProtectionCostQueryResult
} from "shared/dist/graphql/queries/quote/NoClaimsDiscountProtectionCost";

export async function noClaimsDiscountProtectionCostQuery(args: QuoteQueryNoClaimsDiscountProtectionCostArgs): Promise<number | undefined> {
  return new Promise((resolve, reject) => {
    apolloClient.query<NoClaimsDiscountProtectionCostQueryResult, QuoteQueryNoClaimsDiscountProtectionCostArgs>({
      query: NO_CLAIMS_DISCOUNT_PROTECTION_COST_QUERY,
      variables: args
    }).then(result => resolve(result.data.quote.noClaimsDiscountProtectionCost))
      .catch(error => reject(error));
  });
}