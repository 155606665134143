import React, {FC} from "react";
import {YOUR_DETAILS} from "../../../../../../../router/models/Routes";
import Button from "shared-components/dist/buttons//button/Button";
import {useLocation, useNavigate} from "react-router-dom";
import {currentQuoteQueryArgsSelector} from "../../../../your-quote/redux/selectors/QuoteDetailsSelectors";
import {isRetired, isUnemployed} from "../../../validation/ValidateEmploymentStatus";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import TranslatableKeyItemList from "shared-components/dist/lists/key-item-list/TranslatableKeyItemList";
import {buildQuoteRoute} from "../../../../../../../utils/navigation/NavigationHelpers";
import {convertBooleanToConfirmation} from "shared-components/dist/models/Confirmation";
import {logYourQuoteGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {useAppSelector} from "../../../../../../../redux/Hooks";
import {useIsEditFlowEnabledForQuote} from "../../../../your-quote/hooks/IsEditFlowEnabledForQuote";

const PersonalDetailsList: FC = () => {
  const quotePersonalDetails = useAppSelector(state => currentQuoteQueryArgsSelector(state).proposer);
  const addressLine1 = useAppSelector(state => state.personalDetails.address.value?.firstLine);
  const storedAtNight = useAppSelector(state => state.personalDetails.storedAtNight.value?.description);
  const isHomeowner = useAppSelector(state => state.personalDetails.isHomeowner.value);
  const job = useAppSelector(state => state.personalDetails.primaryEmployment.job.value?.description);
  const employmentStatus = useAppSelector(state => state.personalDetails.primaryEmployment.employmentStatus.value);
  const classOfUse = useAppSelector(state => state.personalDetails.classOfUse.value?.description);
  const licenceCountryOfIssue = useAppSelector(state => state.personalDetails.licenceCountryOfIssue.value?.description);
  const licenceType = useAppSelector(state => state.personalDetails.licenceType.value?.description);
  const numberOfConvictions = useAppSelector(state => state.personalDetails.convictions.value.convictions.length);
  const numberOfClaims = useAppSelector(state => state.personalDetails.claims.value.claims.length);
  const hasInsuranceDeclined = useAppSelector(state => state.personalDetails.hasInsuranceDeclined?.value);
  const medicalCondition = useAppSelector(state => state.personalDetails.medicalCondition.value?.description);
  const isEditFlowEnabled = useIsEditFlowEnabledForQuote();

  const navigate = useNavigate();
  const location = useLocation();

  const getJobTitle = (): string | undefined => {
    if (isRetired(employmentStatus) || isUnemployed(employmentStatus)) {
      return employmentStatus?.description;
    }

    return job ?? employmentStatus?.description;
  };

  return <>
    <TranslatableKeyItemList
      rows={[
        {
          keyValue: "quoteDetails.summary.yourDetails.name",
          item: `${quotePersonalDetails.firstName ?? ""} ${quotePersonalDetails.surname ?? ""}`,
        },
        {
          keyValue: "quoteDetails.summary.yourDetails.dateOfBirth",
          item: quotePersonalDetails.dateOfBirth
        },
        {
          keyValue: "quoteDetails.summary.yourDetails.address",
          item: addressLine1
        },
        {
          keyValue: "quoteDetails.summary.yourDetails.carStorage",
          item: storedAtNight
        },
        {
          keyValue: "quoteDetails.summary.yourDetails.homeowner",
          item: convertBooleanToConfirmation(isHomeowner)
        },
        {
          keyValue: "quoteDetails.summary.yourDetails.jobTitle",
          item: getJobTitle(),
        },
        {
          keyValue: "quoteDetails.summary.yourDetails.classOfUse",
          item: classOfUse
        },
        {
          keyValue: "quoteDetails.summary.yourDetails.licenceInfo",
          item: `${licenceCountryOfIssue} - ${licenceType}`,
          isVisible: licenceCountryOfIssue !== undefined && licenceType !== undefined
        },
        {
          keyValue: "quoteDetails.summary.yourDetails.cancellationsEndorsements",
          item: hasInsuranceDeclined === false
            ? lookupI18nString("general.none")
            : convertBooleanToConfirmation(hasInsuranceDeclined)
        },
        {
          keyValue: "quoteDetails.summary.yourDetails.medicalInfo",
          item: medicalCondition !== undefined ? medicalCondition : lookupI18nString("general.none"),
        },
        {
          keyValue: "quoteDetails.summary.yourDetails.claims",
          item: numberOfClaims !== 0 ? numberOfClaims : lookupI18nString("general.none"),
        },
        {
          keyValue: "quoteDetails.summary.yourDetails.convictions",
          item: numberOfConvictions !== 0 ? numberOfConvictions : lookupI18nString("general.none"),
        },
      ]}
    />

    {isEditFlowEnabled &&
      <Button
        className="quote-summary-edit-button"
        variant="ghost"
        onClick={() => {
          logYourQuoteGoogleAnalyticsEvent({
            categorySuffix: "your_details.edit",
            action: "Click"
          });
          navigate(buildQuoteRoute(location.pathname, YOUR_DETAILS));
        }}
      >
        {lookupI18nString("quoteDetails.summary.editButton")}
      </Button>
    }
  </>;
};

export default PersonalDetailsList;
