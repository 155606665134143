import {MultipleItemPolicyExtraOption} from "../../../../../your-quote/models/MultipleItemPolicyExtra";

export function getExcessInsuranceDisplayValue(
  option: MultipleItemPolicyExtraOption,
  selectedOptionPrice: number
): string {
  const difference = option.price - selectedOptionPrice;

  if (difference > 0) return `${option.description} (+£${difference})`;
  if (difference < 0) return `${option.description} (-£${Math.abs(difference)})`;

  return option.description;
}