import {RenewalInviteStatus} from "shared/dist/generated/graphql/resolvers-types";
import type {ShowReminderCallback} from "../../thunk/UpdateVisibleReminderTypes";
import {isFuture} from "date-fns";

export const showRenewalOfferedReminder: ShowReminderCallback = async (policy) => {
  if (
    !policy ||
    !policy.id ||
    !policy.metadata.renewalInviteStatus ||
    !policy.renewalNotice?.expiryTimestamp
  ) return false;

  return (
    [
      RenewalInviteStatus.RenewalOffered,
      RenewalInviteStatus.RebrokeOnly,
      RenewalInviteStatus.RenewalOfferedWithException,
    ].includes(policy.metadata.renewalInviteStatus)
    && isFuture(new Date(policy.renewalNotice.expiryTimestamp))
  );
};
