import {gql} from "@apollo/client";
import {apolloClient} from "../../../apollo/ApolloClientProvider";
import {ValidateBankDetailsParams, ValidateBankDetailsResult} from "shared/dist/generated/graphql/resolvers-types";

const VALIDATE_BANK_DETAILS = gql`
  query ValidateBankDetails($params: ValidateBankDetailsParams!) {
    bankDetails {
      validateBankDetails(params: $params) {
        isCorrect
      }
    }
  }
`;

export interface ValidateBankDetailsQueryVariables {
  params: ValidateBankDetailsParams;
}

export interface ValidateBankDetailsResults {
  bankDetails: {
    validateBankDetails: ValidateBankDetailsResult;
  };
}

export async function validateBankDetails(sortCode: string | undefined, accountNumber: string | undefined): Promise<boolean> {
  if (!sortCode || !accountNumber) return false;

  return apolloClient.query<ValidateBankDetailsResults, ValidateBankDetailsQueryVariables>({
    query: VALIDATE_BANK_DETAILS,
    variables: {
      params: {
        sortCode,
        accountNumber
      }
    }
  })
    .then(result => Promise.resolve(result.data.bankDetails.validateBankDetails.isCorrect))
    .catch(error => Promise.reject(error));
}
