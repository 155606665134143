import React, {FC} from "react";
import TranslatableKeyItemList, {TranslatableKeyItemRowProps} from "shared-components/dist/lists/key-item-list/TranslatableKeyItemList";
import {PolicyHolder} from "shared/dist/generated/graphql/resolvers-types";
import {formatDateStringFromIsoString} from "shared/dist/stdlib/DateFormat";
import {convertEnumValueToFormattedString} from "shared-components/dist/utils/helpers/EnumHelper";

export interface YourDetailsProps {
  policyHolder: PolicyHolder;
}

const YourDetails: FC<YourDetailsProps> = ({policyHolder}) => (
  <TranslatableKeyItemList rows={getPolicyHolderDetailsList(policyHolder)}/>
);

function getPolicyHolderDetailsList(policyHolder: PolicyHolder): TranslatableKeyItemRowProps[] {
  const policyHolderDetailsList: TranslatableKeyItemRowProps[] = [
    {
      keyValue: "policies.mainContent.yourDetails.name",
      item: `${policyHolder.firstName} ${policyHolder.lastName}`
    },
    {
      keyValue: "policies.mainContent.yourDetails.dateOfBirth",
      item: formatDateStringFromIsoString(policyHolder.dateOfBirth)
    },
    {
      keyValue: "policies.mainContent.yourDetails.address",
      item: policyHolder.address.firstLine
    },
    {
      keyValue: "policies.mainContent.yourDetails.maritalStatus",
      item: convertEnumValueToFormattedString(policyHolder.maritalStatus)
    },
    {
      keyValue: "policies.mainContent.yourDetails.jobTitle",
      item: policyHolder.primaryEmployment.job ?? ""
    }
  ];

  if (policyHolder?.secondaryEmployment?.job) {
    policyHolderDetailsList.push({
      keyValue: "policies.mainContent.yourDetails.secondaryJobTitle",
      item: policyHolder.secondaryEmployment.job
    });
  }

  return policyHolderDetailsList;
}

export default YourDetails;
