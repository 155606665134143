import {animateScroll, scroller} from "react-scroll";

const DURATION_MILLISECONDS = 1500;
const DELAY_MILLISECONDS = 100;

export function scrollToElement(element: string): void {
  scroller.scrollTo(element, {
    duration: DURATION_MILLISECONDS,
    delay: DELAY_MILLISECONDS,
    smooth: true,
  });
}

export function scrollToTop(): void {
  animateScroll.scrollToTop({duration: 0});
}