import React, {FC, ReactChild} from "react";
import {
  NoClaimsDiscountProtectionDataResult,
  StepbackRule,
  StepbackRules
} from "shared/dist/generated/graphql/resolvers-types";
import StepTitle from "../../../../structure/questions/step-title/StepTitle";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./ProtectedNoClaimsBonusInformation.css";
import StepbackWithProtectionTable from "./components/StepbackWithProtectionTable";
import StepbackWithoutProtectionTable from "./components/StepbackWithoutProtectionTable";
import NoClaimsDiscountTable from "./components/NoClaimsDiscountTable";
import ContentWithSidebarLayout from "../../../../structure/layouts/content-with-sidebar-layout/ContentWithSidebarLayout";
import SidebarWithBackNavigation from "../../../../structure/sidebar/sidebar-with-back-navigation/SidebarWithBackNavigation";
import {useNavigate} from "react-router-dom";

export interface ProtectedNoClaimsBonusInformationProps {
  header: ReactChild;
  isPortal?: boolean;
  noClaimsDiscountProtectionData: NoClaimsDiscountProtectionDataResult | undefined;
  yearsNoClaimsBonus: string | undefined;
  insurerName: string | undefined;
}

const ProtectedNoClaimsBonusInformation: FC<ProtectedNoClaimsBonusInformationProps> = ({
  header,
  isPortal,
  noClaimsDiscountProtectionData,
  yearsNoClaimsBonus,
  insurerName
}) => {
  const navigate = useNavigate();

  if (!noClaimsDiscountProtectionData || !yearsNoClaimsBonus || !insurerName) {
    return null;
  }

  const stepbackRules = findStepbackRulesForYearsNoClaimsBonus(noClaimsDiscountProtectionData.stepbackRules, yearsNoClaimsBonus);

  if (!stepbackRules) return null;

  const onBackButtonClicked = (): void => {
    navigate(-1);
  };

  return (
    <ContentWithSidebarLayout
      className="protected-no-claims-bonus-information"
      header={header}
      sidebar={
        <SidebarWithBackNavigation
          title="additionalDriver.edit.sidebar.title.goBack"
          onBackButtonClicked={onBackButtonClicked}
        />
      }
      mainContent={
        <div data-testid="protected-no-claims-discount-info">
          <StepTitle title="protectedNoClaimsDiscountInfo.title"/>
          <p>
            {lookupI18nString({
              id: "protectedNoClaimsDiscountInfo.description",
              templateVariables: {insurerName}
            })}
          </p>

          <NoClaimsDiscountTable discounts={noClaimsDiscountProtectionData.discounts}/>

          <h4>{lookupI18nString("protectedNoClaimsDiscountInfo.stepBack.title")}</h4>
          <p data-testid="protected-no-claims-discount-info__description">
            {lookupI18nString({
              id: "protectedNoClaimsDiscountInfo.stepBack.description",
              templateVariables: {insurerName}
            })}
          </p>

          <StepbackWithoutProtectionTable stepbackRules={stepbackRules}/>
          <StepbackWithProtectionTable stepbackRules={stepbackRules}/>
        </div>
      }
      isPortal={isPortal}
    />
  );
};

const findStepbackRulesForYearsNoClaimsBonus = (
  stepbackRules: StepbackRules[],
  yearsNoClaimsBonus: string
): StepbackRule[] | undefined => {
  if (stepbackRules.length === 0) return undefined;

  return (
    stepbackRules.find(rule => rule.years === Number(yearsNoClaimsBonus))
    ?? stepbackRules[stepbackRules.length - 1]
  ).rules;
};

export default ProtectedNoClaimsBonusInformation;