import {RootState} from "../../../../../../redux/Store";
import {createSelector} from "@reduxjs/toolkit";
import {
  Price,
  ProspectiveLoanWithPrice,
  QuoteMutationGetQuotesArgs,
  QuoteType,
  Rate
} from "shared/dist/generated/graphql/resolvers-types";
import isEqual from "lodash.isequal";
import {PaymentFrequency, SavedQuote} from "../../models/SavedQuote";
import {Status} from "shared-components/dist/status-indicator/Status";
import {FormField} from "shared-components/dist/models/form-field/FormField";
import {dateOfBirthAsDateSelector} from "../../../your-details/redux/selectors/PersonalDetailsSelectors";
import {intervalToDuration} from "date-fns/fp";
import {coverStartDateAsDateOptionSelector,} from "../../../your-cover/redux/selectors/CoverDetailsSelectors";
import {mapVehicleToGraphql} from "../../../../../../graphql/mutations/quote/get-quotes/mappers/VehicleDetailsMapper";
import {mapProposerToGraphql} from "../../../../../../graphql/mutations/quote/get-quotes/mappers/ProposerMapper";
import {mapCoverDetailsToGraphql} from "../../../../../../graphql/mutations/quote/get-quotes/mappers/CoverDetailsMapper";
import {mapAdditionalDriversToGraphql} from "../../../../../../graphql/mutations/quote/get-quotes/mappers/AdditionalDriversMapper";
import {mapQuoteExtras} from "../../../../../../graphql/mutations/quote/get-quotes/mappers/QuoteExtrasMapper";
import {vehiclePurchaseDateAsDateSelector} from "../../../your-vehicle/redux/selectors/VehicleDateTypeSelectors";
import {CoverDetailsFields} from "../../../your-cover/redux/CoverDetailsFields";
import {ManualVehicleLookupForm} from "../../../your-vehicle/redux/ManualVehicleLookupForm";
import {VehicleDetails} from "../../../your-vehicle/redux/VehicleDetails";
import {PersonalDetails} from "../../../your-details/redux/PersonalDetails";
import {StoredPolicyExtra} from "../../models/StoredPolicyExtra";
import {InsurerQuote} from "../../models/InsurerQuote";
import {
  maxYearsForAXAEligibilitySelector
} from "../../../your-cover/redux/selectors/named-driver/IsNamedDriverAXAEligible";
import {isAggregatorQuoteSelector} from "./AggregatorQuoteSelectors";
import quoteValidationErrorsSelectors from "../quote-validation-errors/QuoteValidationErrorSelectors";
import {QuoteValidationError} from "../quote-validation-errors/QuoteValidationError";

const rootStateSelector = (state: RootState): RootState => state;
const vehicleDetailsSelector = (state: RootState): VehicleDetails => state.vehicleDetails;
const manualVehicleLookupFormSelector = (state: RootState): ManualVehicleLookupForm => state.manualVehicleLookupForm;
const personalDetailsSelector = (state: RootState): PersonalDetails => state.personalDetails;
const coverDetailsSelector = (state: RootState): CoverDetailsFields => state.coverDetails;
const yearsNamedDriverSelector = (state: RootState): number | undefined => state.coverDetails.yearsNamedDriver.value?.years;
const yearsCompanyCarSelector = (state: RootState): number | undefined => state.coverDetails.yearsCompanyCarBonus.value?.years;
const policyExtrasSelector = (state: RootState): StoredPolicyExtra[] => state.quoteDetails.policyExtras;
const quoteReferenceSelector = (state: RootState): string | undefined => state.quoteDetails.quoteReference;
const quoteSequenceNumberSelector = (state: RootState): number | undefined => state.quoteDetails.quoteSequenceNumber;
const proposerEmailAddressSelector = (state: RootState): FormField<string> => state.personalDetails.proposerEmailAddress;
const paymentFrequencySelector = (state: RootState): PaymentFrequency => state.quoteDetails.paymentFrequency;
const savedQuoteSelector = (state: RootState): SavedQuote | undefined => state.quoteDetails.savedQuote;
export const selectedProspectiveLoanWithPriceSelector = (state: RootState): ProspectiveLoanWithPrice | undefined => state.quoteDetails.selectedLoanWithPrice;
export const selectQuoteDepositPercentageSelector = (state: RootState): number => state.quoteDetails.depositPercentage;
const selectedLoanRateSelector = (state: RootState): Rate | undefined => state.quoteDetails.selectedLoan?.rate;
const hasSubmitBeenClickedSelector = (state: RootState): boolean => state.quoteDetails.hasSubmitBeenClicked;
const proposerHasConfirmedContactDetailsSelector = (state: RootState): FormField<boolean> => state.quoteDetails.hasContactDetailsBeenConfirmed;
const insurerQuotesSelector = (state: RootState): InsurerQuote[] | undefined => state.quoteDetails.insurerQuotes;
const quoteTypeSelector = (state: RootState): QuoteType | undefined => state.quoteDetails.quoteType;

const registrationNumberSelector = (state: RootState): string | undefined => {
  if (state.vehicleDetails.vehicleFromRegLookup) {
    return state.vehicleDetails.vehicleFromRegLookup.registration;
  } else {
    return state.manualVehicleLookupForm.registrationNumber.value;
  }
};

export const currentQuoteQueryArgsSelector = createSelector(
  rootStateSelector,
  personalDetailsSelector,
  proposerEmailAddressSelector,
  (
    state: RootState,
    personalDetails: PersonalDetails,
    proposerEmailAddress: FormField<string>
  ): QuoteMutationGetQuotesArgs => ({
    vehicle: mapVehicleToGraphql(state),
    proposer: mapProposerToGraphql(personalDetails, proposerEmailAddress),
    cover: mapCoverDetailsToGraphql(state),
    additionalDrivers: mapAdditionalDriversToGraphql(personalDetails),
    quoteExtras: mapQuoteExtras(state),
    referredBy: state.quoteDetails.referredBy
  })
);

export const currentQuoteDetailsWithExtrasSelector = createSelector(
  vehicleDetailsSelector,
  manualVehicleLookupFormSelector,
  personalDetailsSelector,
  coverDetailsSelector,
  policyExtrasSelector,
  selectQuoteDepositPercentageSelector,
  selectedLoanRateSelector,
  paymentFrequencySelector,
  quoteReferenceSelector,
  quoteSequenceNumberSelector,
  proposerEmailAddressSelector,
  insurerQuotesSelector,
  (
    vehicleDetails: VehicleDetails,
    manualVehicleLookupForm: ManualVehicleLookupForm,
    personalDetails: PersonalDetails,
    coverDetails: CoverDetailsFields,
    policyExtras: StoredPolicyExtra[],
    depositPercentage: number,
    selectedRate: Rate | undefined,
    paymentFrequency: PaymentFrequency,
    quoteReference: string | undefined,
    quoteSequenceNumber: number | undefined,
    proposerEmailAddress: FormField<string>,
    insurerQuotes: InsurerQuote[] | undefined
  ): SavedQuote | undefined => {
    if (!quoteReference || !quoteSequenceNumber) return undefined;

    return {
      vehicleDetails,
      manualVehicleLookupForm,
      personalDetails,
      coverDetails,
      policyExtras,
      depositPercentage,
      selectedRate,
      paymentFrequency,
      quoteReference,
      quoteSequenceNumber,
      proposerEmailAddress,
      insurerQuotes,
      additionalDrivers: mapAdditionalDriversToGraphql(personalDetails)
    };
  }
);

export const hasUnsavedChangesSelector = createSelector(
  currentQuoteDetailsWithExtrasSelector,
  savedQuoteSelector,
  (currentQuote: SavedQuote | undefined, savedQuote: SavedQuote | undefined): boolean => {
    if (!savedQuote || !currentQuote) return false;

    return !isEqual(currentQuote, savedQuote);
  }
);

export const carDetailsAccordionStatusSelector = createSelector(
  vehiclePurchaseDateAsDateSelector,
  registrationNumberSelector,
  hasSubmitBeenClickedSelector,
  (dateOfPurchase, registrationNumber, hasSubmitBeenClicked): Status => {
    if (!dateOfPurchase.value || !registrationNumber) {
      if (hasSubmitBeenClicked) return "error";

      return "default";
    }
    return "hidden";
  }
);

const MINIMUM_REQUIRED_AGE_FOR_FINANCE = 18;

export const isProposerUnderageForFinanceSelector = createSelector(
  dateOfBirthAsDateSelector,
  coverStartDateAsDateOptionSelector,
  (
    dateOfBirth,
    coverStartDate
  ): boolean => {
    if (!dateOfBirth.value || !coverStartDate.value) return true;

    const interval = intervalToDuration({
      start: dateOfBirth.value,
      end: coverStartDate.value.date
    });

    return interval.years ? interval.years < MINIMUM_REQUIRED_AGE_FOR_FINANCE : true;
  }
);

export const isYourQuoteValidSelector = createSelector(
  carDetailsAccordionStatusSelector,
  proposerHasConfirmedContactDetailsSelector,
  paymentFrequencySelector,
  isProposerUnderageForFinanceSelector,
  (
    carDetailsAccordionStatus,
    proposerHasConfirmedContactDetails,
    paymentFrequency,
    isProposerUnderageForFinance
  ): boolean => {
    if (carDetailsAccordionStatus !== "hidden") return false;
    if (paymentFrequency === "Monthly" && isProposerUnderageForFinance) return false;

    return proposerHasConfirmedContactDetails.status === "success";
  }
);

export const getNamedDriverEligibleYearsSelector = createSelector(
  maxYearsForAXAEligibilitySelector,
  yearsNamedDriverSelector,
  yearsCompanyCarSelector,
  quoteTypeSelector,
  (
    maxYearsForAXAEligibility,
    yearsNamedDriver,
    yearsCompanyCar,
    quoteType
  ): number | undefined => {
  switch (quoteType) {
    case QuoteType.NamedDriver: return Math.min(maxYearsForAXAEligibility, yearsNamedDriver ?? maxYearsForAXAEligibility);
    case QuoteType.CompanyCar: return Math.min(maxYearsForAXAEligibility, yearsCompanyCar ?? maxYearsForAXAEligibility);
    default: return undefined;
  }
}
);

export const selectIsResolveQuoteValidationFlowActive = createSelector(
  isAggregatorQuoteSelector,
  (state: RootState) => state.quoteDetails.quoteValidationSectionBeingResolved,
  (
    isAggregatorQuote,
    quoteValidationSectionBeingResolved
  ): boolean => isAggregatorQuote && quoteValidationSectionBeingResolved !== undefined
);

export const selectValidationErrorsForDriverBeingEdited = createSelector(
  quoteValidationErrorsSelectors.selectQuoteValidationErrorsForAdditionalDrivers,
  (state: RootState) => state.updateAdditionalDriver.idOfDriverBeingEdited,
  (allAdditionalDriverErrors, idOfDriverBeingEdited) => allAdditionalDriverErrors.filter(error =>
    error.driverId === idOfDriverBeingEdited
  )
);

export const selectAllValidationErrorsForSectionBeingResolved = (state: RootState): QuoteValidationError[] => {
  const currentSection = state.quoteDetails.quoteValidationSectionBeingResolved;

  if (!currentSection) return [];

  if (currentSection === "ADDITIONAL_DRIVER") {
    return selectValidationErrorsForDriverBeingEdited(state);
  }

  return quoteValidationErrorsSelectors.selectAllValidationErrorsInSection(currentSection)(state);
};

export const selectQuoteTotalFinancedAmount = (state: RootState): Price | undefined => {
  return state.quoteDetails.selectedLoanWithPrice?.totalAmountPayable;
};

export const quotePaymentFrequencySelector = (state: RootState): PaymentFrequency => state.quoteDetails.paymentFrequency;
export const selectQuoteInsurerName = (state: RootState): string | undefined => state.quoteDetails.insurerName;
export const selectQuoteAdminFee = (state: RootState): Price | undefined => state.quoteDetails.premiumPriceBreakdownDetails?.adminFee;
export const selectQuoteExtras = (state: RootState): StoredPolicyExtra[] => state.quoteDetails.policyExtras;

export const isGBPostcodeSelector = createSelector(
  (state: RootState) => state.personalDetails.address.value?.postcode,
  postcode => !postcode?.startsWith("BT")
);
