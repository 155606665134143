import {
  primaryEmploymentIndustryInvalid,
  primaryEmploymentJobInvalid,
  primaryEmploymentStatusInvalid,
  primaryEmploymentWorkedInPastYearInvalid
} from "../redux/PersonalDetailsSlice";
import {AppThunkDispatch} from "../../../../../redux/Store";
import {isValidFormField} from "shared-components/dist/models/form-field/FormField";
import {isEmployed, isRetired, isUnemployed} from "../../shared/validation/ValidateEmploymentStatus";
import {PersonalDetails} from "../redux/PersonalDetails";

export function flagUnansweredPrimaryEmploymentQuestions(personalDetails: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (personalDetails.primaryEmployment.employmentStatus.status === "default") {
    dispatch(primaryEmploymentStatusInvalid("quote.errors.questionIncomplete"));
  } else if (isEmployed(personalDetails.primaryEmployment.employmentStatus.value)) {
    checkIndustryQuestion(personalDetails, dispatch);
    checkJobQuestion(personalDetails, dispatch);
  } else if (isRetired(personalDetails.primaryEmployment.employmentStatus.value)) {
    checkJobQuestion(personalDetails, dispatch);
  } else if (isUnemployed(personalDetails.primaryEmployment.employmentStatus.value)) {
    checkUnemployedQuestions(personalDetails, dispatch);
  }
}

function checkIndustryQuestion({primaryEmployment}: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (!isValidFormField(primaryEmployment.industry)) dispatch(primaryEmploymentIndustryInvalid("quote.errors.questionIncomplete"));
}

function checkJobQuestion({primaryEmployment}: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (!isValidFormField(primaryEmployment.job)) dispatch(primaryEmploymentJobInvalid("quote.errors.questionIncomplete"));
}

function checkUnemployedQuestions(personalDetails: PersonalDetails, dispatch: AppThunkDispatch): void {
  checkWorkedInPastYearQuestion(personalDetails, dispatch);
  if (personalDetails.primaryEmployment.workedInPastYear.value) {
    checkJobQuestion(personalDetails, dispatch);
  }
}

function checkWorkedInPastYearQuestion({primaryEmployment}: PersonalDetails, dispatch: AppThunkDispatch): void {
  if (!isValidFormField(primaryEmployment.workedInPastYear)) dispatch(primaryEmploymentWorkedInPastYearInvalid("quote.errors.questionIncomplete"));
}
