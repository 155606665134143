import {createSelector} from "@reduxjs/toolkit";
import {Vehicle, VehicleSummary} from "../../../../../../graphql/queries/vehicle-lookup/registration-lookup/model/Vehicle";
import {regLookupVehicleSelector} from "./RegLookupVehicleSelectors";
import {manualLookupVehicleSummarySelector} from "./ManualLookupVehicleSelectors";

export const vehicleSummarySelector = createSelector(
  regLookupVehicleSelector,
  manualLookupVehicleSummarySelector,
  (
    vehicleFromRegLookup: Vehicle | undefined,
    vehicleFromManualLookup: VehicleSummary | undefined
  ): VehicleSummary | undefined => {
    if (vehicleFromManualLookup) return vehicleFromManualLookup;
    if (vehicleFromRegLookup) return vehicleFromRegLookup;
  }
);

export const hasVehicleSummarySelector = createSelector(
  vehicleSummarySelector,
  (vehicle: VehicleSummary | undefined) => vehicle !== undefined
);
