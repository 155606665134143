import {FC} from "react";
import "./RenewalSummaryFooter.css";
import LegalStuffCard from "./components/legal-stuff-card/LegalStuffCard";
import RenewalDocumentCard from "./components/renewal-document-card/RenewalDocumentCard";
import NotRenewingCard from "./components/not-renewing-card/NotRenewingCard";

const RenewalSummaryFooter: FC = () => (
  <div className="renewal-summary-footer">
    <LegalStuffCard/>

    <div className="renewal-summary-footer__stacked-cards">
      <RenewalDocumentCard/>
      <NotRenewingCard/>
    </div>
  </div>
);

export default RenewalSummaryFooter;