import React, {Dispatch, FC, ReactNode, SetStateAction} from "react";
import "./RenewalsConfirmAdditionalDriverClaims.css";
import RenewalDetailsConfirmationTableHeader from "../table-items/RenewalDetailsConfirmationTableHeader";
import RenewalDetailsConfirmationButtonFooter from "../table-items/RenewalDetailsConfirmationButtonFooter";
import RenewalDetailsConfirmationTableItem, {
  RenewalDetailsConfirmationTableItemProps
} from "../table-items/RenewalDetailsConfirmationTableItem";
import RenewalDetailsConfirmationTableSection
  from "../table-items/RenewalDetailsConfirmationTableSection";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {AdditionalDriver, AtFault, PolicyClaim} from "shared/dist/generated/graphql/resolvers-types";
import {CurrencyFormat} from "../../../../../utils/money/CurrencyFormat";
import {format} from "date-fns";
import {formatClaimDescription} from "../utils/Formatters";

interface Props {
  confirmed: boolean;
  setConfirmed: Dispatch<SetStateAction<boolean>>;
  setEditModalVisible: Dispatch<SetStateAction<boolean>>;
  additionalDrivers: AdditionalDriver[];
  previousSectionConfirmed: boolean;
}

const RenewalsConfirmAdditionalDriverClaims: FC<Props> = ({confirmed, setConfirmed, setEditModalVisible, additionalDrivers, previousSectionConfirmed}) => {
  const items = getStructuredClaims(additionalDrivers);

  return (
    <div
      className={confirmed ? "renewals-confirm-additional-driver-claims--confirmed" : `renewals-confirm-additional-driver-claims${previousSectionConfirmed ? "" : "--disabled"} unconfirmed`}
      data-testid="renewals-confirm-additional-driver-claims"
    >
      <RenewalDetailsConfirmationTableHeader
        title={lookupI18nString("portal.renewals.detailConfirmation.additionalDriverClaims")}
        confirmed={confirmed}
      />
      {items}
      <RenewalDetailsConfirmationButtonFooter
        testId={"your-claims"}
        confirmed={confirmed}
        setConfirmed={setConfirmed}
        setEditModalVisible={setEditModalVisible}
        disabled={!previousSectionConfirmed}
      />
    </div>
  );
};

function getStructuredClaims(additionalDrivers: AdditionalDriver[]): ReactNode {
  return additionalDrivers.map((additionalDriver) => {
    return additionalDriver.claims.map((claim, index) => {
      const claimItems = formatClaimItems(claim);

      return (
        <RenewalDetailsConfirmationTableSection
          key={index}
          title={lookupI18nString({
            id: "portal.renewals.detailConfirmation.additionalDriverClaims.tableItem.title",
            templateVariables: {
              count: index + 1,
              max: additionalDriver.claims.length,
              name: `${additionalDriver.firstName} ${additionalDriver.lastName}`
            }
          })}
          subtitle={format(new Date(claim.date), "dd/MM/yyyy")}
        >
          {claimItems.map((item, key) =>
            <RenewalDetailsConfirmationTableItem key={key} testId={item.testId} title={item.title} subtitle={item.subtitle}/>
          )}
        </RenewalDetailsConfirmationTableSection>
      );
    });
  });
}

function formatClaimItems(claim: PolicyClaim): RenewalDetailsConfirmationTableItemProps[] {
  const claimItems = [
    {
      testId: "additional-driver-claim-status",
      title: lookupI18nString("portal.renewals.detailConfirmation.additionalDriverClaims.settled"),
      subtitle: claim.atFault === AtFault.Pending ? "Pending" : "Settled"
    },
    {
      testId: "additional-driver-claim-type",
      title: lookupI18nString("portal.renewals.detailConfirmation.additionalDriverClaims.type"),
      subtitle: claim.incidentType
    },
    {
      testId: "additional-driver-claim-at-fault",
      title: lookupI18nString("portal.renewals.detailConfirmation.additionalDriverClaims.fault"),
      subtitle: formatAtFault(claim.atFault)
    },
  ];

  if (claim.accidentDescription) {
    claimItems.push({
      testId: "additional-driver-claim-description",
      title: lookupI18nString("portal.renewals.detailConfirmation.additionalDriverClaims.description"),
      subtitle: formatClaimDescription(claim.accidentDescription)
    });
  }

  claimItems.push(
    {
      testId: "additional-driver-claim-policy",
      title: lookupI18nString("portal.renewals.detailConfirmation.additionalDriverClaims.policy"),
      subtitle: claim.whichPolicyClaimedAgainst
    }
  );

  if (claim.noClaimsAffected) {
    claimItems.push(
      {
        testId: "additional-driver-claim-ncb-affected",
        title: lookupI18nString("portal.renewals.detailConfirmation.additionalDriverClaims.ncbAffected"),
        subtitle: claim.noClaimsAffected
      }
    );
  }

  claimItems.push(
    {
      testId: "additional-driver-claim-injuries",
      title: lookupI18nString("portal.renewals.detailConfirmation.additionalDriverClaims.injuries"),
      subtitle: claim.bodilyInjuryOccurred ? lookupI18nString("general.boolean.yes") : lookupI18nString("general.boolean.no")
    },
    {
      testId: "additional-driver-claim-cost",
      title: lookupI18nString("portal.renewals.detailConfirmation.additionalDriverClaims.cost"),
      subtitle: CurrencyFormat.formatStringAmountWithSymbol(claim.value.toString())
    }
  );

  return claimItems;
}

function formatAtFault(atFault: AtFault): string {
  switch (atFault) {
    case AtFault.NoBlame:
      return lookupI18nString("portal.renewals.detailConfirmation.additionalDriverClaims.atFault.noBlame");
    case AtFault.Partial:
      return lookupI18nString("portal.renewals.detailConfirmation.additionalDriverClaims.atFault.partial");
    case AtFault.Pending:
      return lookupI18nString("portal.renewals.detailConfirmation.additionalDriverClaims.atFault.pending");
    case AtFault.Yes:
      return lookupI18nString("portal.renewals.detailConfirmation.additionalDriverClaims.atFault.yes");
  }
}

export default RenewalsConfirmAdditionalDriverClaims;
