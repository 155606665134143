import {MaritalStatus} from "shared/dist/generated/graphql/resolvers-types";

export const MARITAL_STATUSES = [
  {
    id: MaritalStatus.Married,
    description: "Married"
  },
  {
    id: MaritalStatus.Single,
    description: "Single"
  },
  {
    id: MaritalStatus.Partnered,
    description: "Partnered"
  },
  {
    id: MaritalStatus.MarriedCommonLaw,
    description: "Married common law"
  },
  {
    id: MaritalStatus.CivilPartnership,
    description: "Civil partnership"
  },
  {
    id: MaritalStatus.Separated,
    description: "Separated"
  },
  {
    id: MaritalStatus.Divorced,
    description: "Divorced"
  },
  {
    id: MaritalStatus.Widowed,
    description: "Widowed"
  },
  {
    id: MaritalStatus.Estranged,
    description: "Estranged"
  }
] as const;

export const MARITAL_STATUS_OPTIONS = MARITAL_STATUSES.map(maritalStatus => maritalStatus.description);

export type MaritalStatusOption = typeof MARITAL_STATUSES[number];