import React, {FC, ReactChild, ReactChildren} from "react";
import CollapsibleSidebar, {SidebarPresentationMode} from "../../../../structure/sidebar/collapsible-sidebar/CollapsibleSidebar";
import {ReactComponent as MastercardLogo} from "../../../../assets/icons/payments/icons/mastercard-logo.svg";
import {ReactComponent as VisaLogo} from "../../../../assets/icons/payments/icons/visa-logo.svg";
import "./PaymentStepSidebar.css";

interface Props {
  presentationMode: SidebarPresentationMode;
  sidebarContent?: ReactChild | ReactChildren;
  sidebarTitle: ReactChild | ReactChildren;
}

const PaymentStepSidebar: FC<Props> = ({
  presentationMode,
  sidebarContent,
  sidebarTitle,
}) => (
  <CollapsibleSidebar
    title={sidebarTitle}
    className="payment-step-sidebar"
    presentationMode={presentationMode}
  >
    <div className="payment-step-sidebar__content">
      {sidebarContent &&
      <div className="payment-step-sidebar__content__description">
        {sidebarContent}
      </div>
      }

      <div className="payment-step-sidebar__content__logos">
        <VisaLogo/>
        <MastercardLogo/>
      </div>
    </div>
  </CollapsibleSidebar>
);

export default PaymentStepSidebar;