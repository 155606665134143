import React, {FC} from "react";
import {estimatedValueChanged, estimatedValueInvalid} from "../../../redux/VehicleDetailsSlice";
import {VALUE_STEP_SIZE} from "shared-components/dist/utils/constants/Constants";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import CurrencyInputSubQuestion
  from "../../../../../../../structure/questions/sub-question/variants/currency-input-sub-question/CurrencyInputSubQuestion";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const AdditionalDetailsEstimatedValue: FC = () => {
  const dispatch = useAppDispatch();
  const estimatedValue = useAppSelector(state => state.vehicleDetails.additionalVehicleDetails.estimatedValue);

  const onEstimatedValueChanged = (value: number): void => {
    if (value === 0 || isNaN(value)) {
      dispatch(estimatedValueInvalid("quote.errors.questionIncomplete"));
    } else {
      dispatch(estimatedValueChanged(value));
    }
  };

  const tooltip: TooltipProps = {
    title: "vehicleQuote.additionalVehicleDetailsForm.estimatedValue.tooltip.title",
    description: "vehicleQuote.additionalVehicleDetailsForm.estimatedValue.tooltip.description"
  };

  return (
    <CurrencyInputSubQuestion
      title="vehicleQuote.additionalVehicleDetailsForm.estimatedValue"
      id="estimated-value-question"
      tooltip={tooltip}
      errorMessage={estimatedValue.errorMessage}
      status={estimatedValue.status}
      value={estimatedValue.value}
      onAnswer={onEstimatedValueChanged}
      step={VALUE_STEP_SIZE}
    />
  );
};

export default AdditionalDetailsEstimatedValue;
