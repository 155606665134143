import React, {ChangeEvent, FC, useEffect, useState} from "react";
import Dropdown from "shared-components/dist/form/dropdown/Dropdown";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {Policy, QuoteType, UploadedDocumentType} from "shared/dist/generated/graphql/resolvers-types";
import {FormField} from "shared-components/dist/models/form-field/FormField";
import Question from "../../../../../structure/questions/question/Question";
import DocumentUploadDescription from "./DocumentUploadDescription";

interface Props {
  onDocumentTypeChanged: (documentType: UploadedDocumentType) => void;
  documentTypeFormField: FormField<UploadedDocumentType>;
  selectedPolicy: Policy;
}

const DocumentUploadDropdown: FC<Props> = ({onDocumentTypeChanged, documentTypeFormField, selectedPolicy}) => {
  const [value, setValue] = useState<UploadedDocumentType | undefined>(undefined);

  useEffect(() => {
    setValue(undefined);
  }, [selectedPolicy]);

  const quoteType = selectedPolicy.quoteDetails?.quoteType;
  const options = getDropdownOptions(quoteType);

  function setDropdownValue(event: ChangeEvent<HTMLSelectElement>): void {
    const documentType = event.target.value as UploadedDocumentType;
    setValue(documentType);
    onDocumentTypeChanged(documentType);
  }

  return (
    <>
      <Question
        status={documentTypeFormField.status}
        errorMessage={documentTypeFormField.errorMessage}
        title="document.upload.form.title"
        id="document-type-dropdown"
      >
        <Dropdown
          name="document-type-dropdown"
          displayValues={options.map(getOptionDisplayValue)}
          options={options}
          onChange={setDropdownValue}
          placeholder="structure.questions.claims.dropdown.placeholder"
          value={value}
        />
      </Question>

      <DocumentUploadDescription uploadedDocumentType={value}/>
    </>
  );
};

function getDropdownOptions(quoteType: QuoteType | undefined | null): UploadedDocumentType[] {
  const options = [
    UploadedDocumentType.LicencePlasticFront,
    UploadedDocumentType.LicencePlasticBack,
    UploadedDocumentType.LicencePaperFront,
    UploadedDocumentType.LicencePaperBack
  ];
  if (quoteType === QuoteType.NoClaimsBonus) options.push(UploadedDocumentType.NcbProof);
  if (quoteType === QuoteType.NamedDriver) options.push(UploadedDocumentType.NamedDriverExperienceProof);
  if (quoteType === QuoteType.CompanyCar) options.push(UploadedDocumentType.CompanyCarBonusProof);
  options.push(UploadedDocumentType.Other);
  return options;
}

function getOptionDisplayValue(uploadedDocumentType: UploadedDocumentType): string {
  switch (uploadedDocumentType) {
    default:
    case UploadedDocumentType.LicencePlasticFront:
      return lookupI18nString("document.upload.document.type.licence.plastic.front");
    case UploadedDocumentType.LicencePlasticBack:
      return lookupI18nString("document.upload.document.type.licence.plastic.back");
    case UploadedDocumentType.LicencePaperFront:
      return lookupI18nString("document.upload.document.type.licence.paper.front");
    case UploadedDocumentType.LicencePaperBack:
      return lookupI18nString("document.upload.document.type.licence.paper.back");
    case UploadedDocumentType.NcbProof:
      return lookupI18nString("document.upload.document.type.ncb");
    case UploadedDocumentType.CompanyCarBonusProof:
      return lookupI18nString("document.upload.document.type.companyCarBonus");
    case UploadedDocumentType.NamedDriverExperienceProof:
      return lookupI18nString("document.upload.document.type.namedDriverExperience");
    case UploadedDocumentType.Other:
      return lookupI18nString("document.upload.document.type.other");
  }
}

export default DocumentUploadDropdown;
