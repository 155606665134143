import {RenewalInviteStatus} from "shared/dist/generated/graphql/resolvers-types";
import type {ShowReminderCallback} from "../../thunk/UpdateVisibleReminderTypes";

export const showRenewalsAwaitingInviteReminder: ShowReminderCallback = async (policy) => {
  if (!policy || !policy.metadata.renewalInviteStatus) return false;

  return [
    RenewalInviteStatus.AwaitingReminder,
    RenewalInviteStatus.AwaitingInvite,
    RenewalInviteStatus.InviteInProgress
  ].includes(policy.metadata.renewalInviteStatus);
};
