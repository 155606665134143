import React, {FC} from "react";
import {BooleanFormField} from "shared-components/dist/models/form-field/variants/BooleanFormField";
import BooleanQuestion from "../../../../../../structure/questions/question/variants/boolean-question/BooleanQuestion";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import InsuranceDeclinedStop from "./InsuranceDeclinedStop";

interface Props {
  title: TranslationKey;
  formField: BooleanFormField;
  onAnswer: (updatedValue: boolean) => void;
  isStopVisible: boolean;
}

const InsuranceDeclinedQuestion: FC<Props> = (
  {
    title,
    formField,
    onAnswer,
    isStopVisible
  }
) => (
  <>
    <BooleanQuestion
      id="insurance-declined-question"
      title={title}
      formField={formField}
      onAnswer={onAnswer}
    />
    <InsuranceDeclinedStop
      isVisible={isStopVisible}
    />
  </>
);

export default InsuranceDeclinedQuestion;
