import {
  validationFailure,
  ValidationResult,
  validationSuccess,
  Validator
} from "shared-components/dist/utils/validation/Validator";
import {getAge, getAgeInMonths} from "shared/dist/stdlib/Age";
import {addYears} from "date-fns/fp";
import {parseDurationAsMonths} from "../../../../../../../utils/service/parsers/DurationToMonthsParser";
import {fullMonthsBetween, now} from "shared/dist/stdlib/Dates";

export function isOldEnoughForLicenceLengthValidator(licenceLength: Duration | undefined): Validator<Date> {
  return (dateOfBirth: Date): ValidationResult => {
    if (licenceLength?.years === undefined) return validationSuccess;

    if (isOldEnoughForLicenseLength(dateOfBirth, licenceLength)) return validationSuccess;

    if (licenceLength.years === 0) {
      const fullMonthsBetweenDateOfBirthAndNow = fullMonthsBetween(dateOfBirth, now());

      return validationFailure({
        id: "additionalDriver.dateOfBirthQuestion.invalid.licenceDuration.invalidMonths",
        templateVariables: {
          years: Math.floor(fullMonthsBetweenDateOfBirthAndNow / 12),
          months: fullMonthsBetweenDateOfBirthAndNow % 12
        }
      });
    }

    return validationFailure({
      id: "additionalDriver.dateOfBirthQuestion.invalid.licenceDuration",
      templateVariables: {
        licenceLength: licenceLength.years,
        numberOfYearsOld: getAge(dateOfBirth)
      }
    });
  };
}

export function isLicenceLengthLessThanAgeValidator(dateOfBirth: Date | undefined): Validator<Duration | undefined> {
  if (!dateOfBirth) return () => validationSuccess;

  return (duration) => isOldEnoughForLicenceLengthValidator(duration)(dateOfBirth);
}

function isOldEnoughForLicenseLength(dateOfBirth: Date, licenceLength: Duration): boolean {
  const maxLicenceLengthForAge = getAgeInMonths(addYears(17, dateOfBirth));
  return getTotalMonthsFromLicenceLength(licenceLength) <= maxLicenceLengthForAge;
}

function getTotalMonthsFromLicenceLength(duration: Duration): number {
  return parseDurationAsMonths({
    years: duration.years ?? 0,
    months: duration.months ?? 0
  });
}