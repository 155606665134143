import {validationFailure, ValidationResult, validationSuccess, Validator} from "shared-components/dist/utils/validation/Validator";
import {parsePhoneNumber, isPossiblePhoneNumber} from "react-phone-number-input";

export function phoneNumberValidator(): Validator<string> {
  return (phoneNumber: string): ValidationResult => {
    const parsedNumber = parsePhoneNumber(phoneNumber);
    const validCountryCallCode = parsedNumber?.countryCallingCode === "44" || parsedNumber?.countryCallingCode === "353";

    return validCountryCallCode && isPossiblePhoneNumber(parsedNumber.number)
      ? validationSuccess
      : validationFailure("personalDetails.phoneNumberQuestion.errors.invalidPhoneNumber");
  };
}