import React, {FC} from "react";
import {CustomisedAbiListItem} from "shared-components/dist/models/CustomisedAbiListItem";
import {StringTypeaheadSubQuestion} from "../../../../../../../structure/questions/sub-question/variants/typeahead-sub-question/variants/StringTypeaheadSubQuestion";
import {toStringOptionFromCustomisedAbiListItem} from "../../../../../../../structure/form/typeahead/variants/StringTypeahead";
import {ConditionalFormField} from "shared-components/dist/models/form-field/ConditionalFormField";
import {useJobsProps} from "../hooks/UseJobsProps";
import {useIndustriesProps} from "../hooks/UseIndustriesProps";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";

export interface EmployedQuestionsDisplayProps {
  industryQuestionTitle: TranslationKey;
  jobQuestionTitle: TranslationKey;
  industryQuestionTooltip: TooltipProps;
  jobQuestionTooltip: TooltipProps;
}

interface OwnProps {
  idPrefix: string;
  industry: ConditionalFormField<CustomisedAbiListItem>;
  job: ConditionalFormField<CustomisedAbiListItem>;
  industryChanged: (value: CustomisedAbiListItem) => void;
  jobChanged: (value: CustomisedAbiListItem) => void;
}

type Props = EmployedQuestionsDisplayProps & OwnProps;

const EmploymentJobAndIndustryQuestions: FC<Props> = (
  {
    idPrefix,
    industryQuestionTitle,
    jobQuestionTitle,
    industryQuestionTooltip,
    jobQuestionTooltip,
    industry,
    job,
    industryChanged,
    jobChanged
  }
) => {
  const {status: industriesLookupStatus, onIndustryChanged, industries} = useIndustriesProps(industry, industryChanged);
  const {status: jobsLookupStatus, onJobChanged, jobs} = useJobsProps(job, jobChanged);

  return (
    <>
      <StringTypeaheadSubQuestion
        id={`${idPrefix}-employment-industry-question`}
        title={industryQuestionTitle}
        errorMessage="quote.errors.questionIncomplete"
        status={industriesLookupStatus}
        tooltip={industryQuestionTooltip}
        typeaheadProps={{
          options: industries,
          selectedOption: toStringOptionFromCustomisedAbiListItem(industry),
          onSelection: onIndustryChanged,
          name: `${idPrefix}-employment-industry-typeahead`,
          loading: industriesLookupStatus === "loading",
          placeholder: "personalDetails.employmentQuestion.employed.industry.placeholder"
        }}
      />

      <StringTypeaheadSubQuestion
        id={`${idPrefix}-employment-job-question`}
        title={jobQuestionTitle}
        errorMessage="quote.errors.questionIncomplete"
        status={jobsLookupStatus}
        tooltip={jobQuestionTooltip}
        typeaheadProps={{
          options: jobs,
          selectedOption: toStringOptionFromCustomisedAbiListItem(job),
          onSelection: onJobChanged,
          name: `${idPrefix}-employment-job-typeahead`,
          loading: jobsLookupStatus === "loading",
          placeholder: "personalDetails.employmentQuestion.employed.job.placeholder"
        }}
      />
    </>
  );
};

export default EmploymentJobAndIndustryQuestions;