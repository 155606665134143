import {MutationTuple, useMutation} from "@apollo/client";
import {
  Mutation,
  PolicyHolderMutationUpdateMetadataMarketingPreferencesArgs
} from "shared/dist/generated/graphql/resolvers-types";
import {marketingPreferencesUpdated} from "../../../shared/redux/PortalSlice";
import {UPDATE_METADATA_MARKETING_PREFERENCES} from "shared/dist/graphql/mutations/updateMarketingPreferences/UpdateMetadataMarketingPreferences";
import {useAppDispatch} from "../../../../../redux/Hooks";

export function useUpdateMarketingPreferences(
  emailMarketingEnabled: boolean,
  smsMarketingEnabled: boolean
): MutationTuple<Mutation, PolicyHolderMutationUpdateMetadataMarketingPreferencesArgs> {
  const dispatch = useAppDispatch();

  return useMutation<Mutation, PolicyHolderMutationUpdateMetadataMarketingPreferencesArgs>(
    UPDATE_METADATA_MARKETING_PREFERENCES,
    {
      variables: {
        emailMarketingEnabled,
        smsMarketingEnabled
      },
      onCompleted: (result: Mutation): void => {
        dispatch(marketingPreferencesUpdated(result.policyHolder.updateMetadataMarketingPreferences));
      }
    }
  );
}
