import {SerializableClaim} from "../../../../quote/vehicle/shared/questions/claims/models/Claims";
import {ProposedPolicyClaim} from "shared/dist/generated/graphql/resolvers-types";
import {formatIsoDateStringFromIsoDateTime} from "shared/dist/stdlib/DateFormat";

export function mapPolicyClaims(claims: SerializableClaim[]): ProposedPolicyClaim[] {
  return claims.map(claim => (
    {
      date: formatIsoDateStringFromIsoDateTime(claim.date),
      status: claim.status.id,
      atFault: claim.atFault.vtCode,
      incidentType: claim.incidentType.vtCode,
      accidentDescription: claim.accidentDescription?.vtCode,
      whichPolicyClaimedAgainst: claim.whichPolicyClaimedAgainst.vtCode,
      noClaimsAffected: claim.noClaimsAffected.vtCode,
      bodilyInjuryOccurred: claim.bodilyInjuryOccurred,
      value: claim.value
    }
  ));
}