import {
  PrintRequestResponse,
  PrintRequestResult,
  REQUEST_PRINTED_DOCUMENTS_FROM_DOCMAIL_QUERY
} from "shared/dist/graphql/queries/request-printed-documents/RequestPrintedDocumentsFromDocmail";
import {
  RequestPrintedDocumentsV2Params
} from "shared/dist/generated/graphql/resolvers-types";
import {apolloClient} from "../../../apollo/ApolloClientProvider";

export async function requestPrintDocumentsFromDocmail(params: RequestPrintedDocumentsV2Params): Promise<PrintRequestResponse> {
  if (!params.documentsToPrint.length) throw new Error("Documents list is empty on print request.");

  const result = await apolloClient.query<PrintRequestResult, RequestPrintedDocumentsV2Params>({
    query: REQUEST_PRINTED_DOCUMENTS_FROM_DOCMAIL_QUERY,
    variables: params
  });

  return result.data.documentation.requestPrintedDocumentsV2;
}
