import React, {FC} from "react";
import ContentWithSidebarLayout from "../../../../../structure/layouts/content-with-sidebar-layout/ContentWithSidebarLayout";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import SidebarWithBackNavigation from "../../../../../structure/sidebar/sidebar-with-back-navigation/SidebarWithBackNavigation";
import Button from "shared-components/dist/buttons/button/Button";
import {useLocation, useNavigate} from "react-router-dom";
import ButtonGroup from "shared-components/dist/buttons/button-group/ButtonGroup";
import "./AddAdditionalDriver.css";
import {flagUnansweredAdditionalDriverQuestions} from "../shared/validation/FlagUnansweredAdditionalDriverQuestions";
import Questions from "../shared/Questions";
import {addAdditionalDriver} from "../shared/redux/thunks/AddAdditionalDriver";
import {additionalDriverReset} from "../shared/redux/UpdateAdditionalDriverSlice";
import {quoteSteps} from "../../shared/quote-step/QuoteSteps";
import {createAnchorUrl} from "../../../../../utils/navigation/CreateAnchorUrl";
import {YOUR_DETAILS} from "../../../../../router/models/Routes";
import {useDoAfterFinalRenderAction} from "../../../../../utils/custom-hooks/DoAfterFinalRenderAction";
import {useScrollToError} from "../../../../../utils/validation/hooks/ScrollToError";
import {logAdditionalDriverGoogleAnalyticsEvent} from "../../../../../utils/analytics/MotorAnalytics";
import QuoteHeader from "../../shared/quote-step/header/QuoteHeader";
import {useAppDispatch, useAppSelector} from "../../../../../redux/Hooks";

const AddAdditionalDriver: FC = () => {
  const dispatch = useAppDispatch();
  const additionalDriverDetailsValid = useAppSelector(state => quoteSteps.addAdditionalDriver.validator(state));

  const navigate = useNavigate();
  const location = useLocation();
  const {scrollToError} = useScrollToError();

  const onBackButtonClicked = (): void => {
    logAdditionalDriverGoogleAnalyticsEvent({
      categorySuffix: "back",
      action: "Click"
    });

    goBack();
  };

  const onCancel = (): void => {
    logAdditionalDriverGoogleAnalyticsEvent({
      categorySuffix: "cancel",
      action: "Click"
    });

    goBack();
  };

  const goBack = (): void => {
    navigate(-1);
    dispatch(additionalDriverReset());
  };

  const onAddDriver = (): void => {
    logAdditionalDriverGoogleAnalyticsEvent({
      categorySuffix: "submit_additional_driver",
      action: additionalDriverDetailsValid ? "Success" : "Failure"
    });

    if (additionalDriverDetailsValid) {
      dispatch(addAdditionalDriver());
      onDriverAdded();
    } else {
      dispatch(flagUnansweredAdditionalDriverQuestions());
      scrollToError();
    }
  };

  const onDriverAdded = useDoAfterFinalRenderAction(() => {
    if (location?.state?.pageTitle === "additionalDriver.add.sidebar.title.refineYourQuote") {
      navigate(-1);
      return;
    }

    navigate(createAnchorUrl(
      buildYourDetailsRoute(),
      "additional-drivers-question"
    ));
  }, [navigate, location]);

  const buildYourDetailsRoute = (): string => {
    const currentPath = location.pathname.split("/");

    return currentPath
      .slice(0, currentPath.length - 1)
      .join("/")
      .concat(YOUR_DETAILS);
  };

  return (
    <>
      <ContentWithSidebarLayout
        isPageFooterVisible={false}
        className="add-additional-driver"
        header={<QuoteHeader showButtons={false}/>}
        mainContent={<Questions title="additionalDriver.add.title"/>}
        sidebar={
          <SidebarWithBackNavigation
            title={location?.state?.pageTitle ?? "additionalDriver.edit.sidebar.title.goBack"}
            onBackButtonClicked={onBackButtonClicked}
          />
        }
        footer={
          <ButtonGroup className="add-additional-driver__button-group">
            <Button
              variant="tertiary"
              onClick={onCancel}
              expanded
            >
              {lookupI18nString("additionalDriver.add.footer.cancel")}
            </Button>
            <Button
              onClick={onAddDriver}
              expanded
            >
              {lookupI18nString("additionalDriver.add.footer.add")}
            </Button>
          </ButtonGroup>
        }
      />
    </>
  );
};

export default AddAdditionalDriver;
