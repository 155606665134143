import {useEffect} from "react";
import {scrollToElement, scrollToTop} from "../../../../../utils/navigation/ScrollToHelpers";

export function useScrollToElement(id: string | undefined): void {
  useEffect(() => {
    if (id) {
      scrollToElement(id);
    } else {
      scrollToTop();
    }
  }, [id]);
}