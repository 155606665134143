import {FC, useEffect} from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import {LOGIN} from "../models/Routes";
import {checkForValidSession} from "../../pages/user-authentication/login/redux/thunks/CheckForValidSession";
import {reauthenticateZendeskChat} from "../../zendesk/Zendesk";
import {useAppDispatch, useAppSelector} from "../../redux/Hooks";

export interface AuthenticatedRouteState {
  previousAuthenticatedRoute?: string;
}

const AuthenticatedRoute: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const currentSessionState = useAppSelector(state => state.userAuthentication.currentSessionState);

  useEffect(() => {
    dispatch(checkForValidSession());
  });

  if (currentSessionState === "Valid") {
    reauthenticateZendeskChat();
    return <Outlet/>;
  } else if (currentSessionState === "Invalid") {
    return <Navigate
      to={LOGIN}
      replace
      state={{previousAuthenticatedRoute: location.pathname} as AuthenticatedRouteState}
    />;
  }

  return <></>;
};

export default AuthenticatedRoute;
