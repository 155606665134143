import React, {FC} from "react";
import {fullSpecSelected} from "../../../../../../../redux/Actions";
import {StringRadioColumnSubQuestion} from "../../../../../../../structure/questions/sub-question/variants/radio-column-sub-question/variants/StringRadioColumnSubQuestion";
import {fullSpecInvalid} from "../../../redux/ManualVehicleLookupFormSlice";
import {VehicleFullSpecQueryResult} from "../../../../../../../graphql/queries/vehicle-lookup/manual-vehicle-lookup/model/FullSpecsQuery";
import {useFullSpec} from "../../../../../../../graphql/queries/vehicle-lookup/manual-vehicle-lookup/ManualVehicleLookup";
import {VehicleSpecLookupItem} from "shared/dist/generated/graphql/resolvers-types";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";
import "./YourVehicleFullSpec.css";

const YourVehicleFullSpec: FC = () => {
  const dispatch = useAppDispatch();

  const {
    make,
    model,
    fuelType,
    transmissionType,
    yearOfManufacture,
    engineCapacityLitres,
    trimLevel,
    fullSpec
  } = useAppSelector(state => state.manualVehicleLookupForm);

  const {error, loading, data} = useFullSpec({
    make: make.value,
    model: model.value,
    fuelType: fuelType.value,
    transmissionType: transmissionType.value,
    yearOfManufacture: yearOfManufacture.value,
    engineCapacityLitres: engineCapacityLitres.value,
    trim: trimLevel.value,
  });

  const fetchError = !!(!loading && error);

  if (fetchError) dispatch(fullSpecInvalid("vehicleQuote.manualVehicleLookup.errors.fullSpecsFetchFailed"));

  const dropdownOptions = extractDropdownOptions(loading, data);

  const onFullSpecChanged = (selectedAbiCode: string): void => {
    const selectedVehicleSpec = data?.vehicle.vehicleSpecLookup.find(vehicle => vehicle.abi === selectedAbiCode);
    if (selectedVehicleSpec) {
      dispatch(fullSpecSelected(selectedVehicleSpec));
    }
  };

  return (
    <StringRadioColumnSubQuestion
      subQuestionProps={{
        title: "vehicleQuote.manualVehicleLookup.fullSpec",
        id: "manual-vehicle-lookup-full-spec",
        errorMessage: fullSpec.errorMessage,
        status: loading ? "loading" : fullSpec.status
      }}
      radioColumnProps={{
        options: dropdownOptions,
        name: "manual-vehicle-lookup-full-spec-radio-column",
        selectedOption: fullSpec?.value?.abi ?? "",
        onSelection: onFullSpecChanged,
        disabled: fetchError,
        children: data?.vehicle.vehicleSpecLookup.map(vehicle =>
          <div key={vehicle.abi}>
            <div><b>{vehicle.make} {vehicle.model}</b></div>
            <div>{vehicleDetailsFrom(vehicle)}</div>
          </div>
        ),
        className: "your-vehicle-full-spec"
      }}
    />
  );
};

function vehicleDetailsFrom(vehicle: VehicleSpecLookupItem): string {
  return removeEmptyOrNone([
    `${vehicle.yearOfManufactureStart} - ${vehicle.yearOfManufactureEnd}`,
    vehicle.fuelType,
    vehicle.engineCapacityCc !== "None" ? `${vehicle.engineCapacityCc} cc` : "",
    vehicle.brakeHorsepower !== "" ? `${vehicle.brakeHorsepower} bhp` : "",
    vehicle.transmissionType,
    `${vehicle.doors} Doors`,
    vehicle.trim,
    vehicle.bodyStyle,
  ]).join(", ");
}

function removeEmptyOrNone(detailList: string[]): string[] {
  return detailList.filter(item => item !== "" && item !== "None");
}

function extractDropdownOptions(loading: boolean, data: VehicleFullSpecQueryResult | undefined): string[] {
  return !loading && data
    ? abiCodes(data)
    : [];
}

function abiCodes(data: VehicleFullSpecQueryResult): string[] {
  return data.vehicle.vehicleSpecLookup.map(vehicle => vehicle.abi);
}

export default YourVehicleFullSpec;
