import {FC, ReactChild, ReactNode} from "react";
import "./ContentWithoutSidebarLayout.css";
import PageFooter from "../../footer/PageFooter";
import classNames from "classnames";

interface Props {
  header: ReactChild;
  className?: string;
  children: ReactNode;
  isPortal?: boolean;
  testId?: string;
}

const ContentWithoutSidebarLayout: FC<Props> = (
  {
    header,
    className,
    isPortal,
    children,
    testId
  }
) => (
  <div className={classNames("content-without-sidebar", className)} data-testid={testId}>
    <div className="content-without-sidebar__wrapper">
      <div className="content-without-sidebar__header">
        {header}
      </div>

      <div className="content-without-sidebar__container">
          {children}
      </div>
    </div>

    <PageFooter isPortal={isPortal}/>
  </div>
);

export default ContentWithoutSidebarLayout;