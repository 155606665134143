import {validationFailure, validationSuccess, Validator} from "shared-components/dist/utils/validation/Validator";
import {MaritalStatus, RelationshipToProposer} from "shared/dist/generated/graphql/resolvers-types";
import {SerializableAdditionalDriver} from "../../../models/AdditionalDriver";
import {RelationshipToProposerOption} from "../model/RelationshipToProposerOption";

export function spouseSelectedValidator(proposerMaritalStatus?: MaritalStatus): Validator<RelationshipToProposerOption> {
  return (selection) => {
    if (!proposerMaritalStatus) return validationSuccess;

    return isProposerUnmarried(selection.id, proposerMaritalStatus)
      ? validationFailure("additionalDriver.driverRelationshipStatusQuestion.maritalStatusInvalid.spouse")
      : validationSuccess;
  };
}

const isProposerUnmarried = (
  relationshipToProposer: RelationshipToProposer,
  proposerMaritalStatus: MaritalStatus
): boolean => relationshipToProposer === RelationshipToProposer.Spouse && proposerMaritalStatus !== MaritalStatus.Married;

export function commonLawSpouseSelectedValidator(proposerMaritalStatus?: MaritalStatus): Validator<RelationshipToProposerOption> {
  return (selection) => {
    if (!proposerMaritalStatus) return validationSuccess;

    return isProposerNotCommonLawMarried({
      relationshipToProposer: selection.id,
      proposerMaritalStatus: proposerMaritalStatus
    })
      ? validationFailure("additionalDriver.driverRelationshipStatusQuestion.maritalStatusInvalid.spouse.commonLaw")
      : validationSuccess;
  };
}

const isProposerNotCommonLawMarried = (
  {
    relationshipToProposer,
    proposerMaritalStatus
  }: { relationshipToProposer: RelationshipToProposer, proposerMaritalStatus: MaritalStatus }
): boolean => relationshipToProposer === RelationshipToProposer.CommonLawSpouse && proposerMaritalStatus !== MaritalStatus.MarriedCommonLaw;

export function civilPartnerSelectedValidator(proposerMaritalStatus?: MaritalStatus): Validator<RelationshipToProposerOption> {
  return (selection) => {
    if (!proposerMaritalStatus) return validationSuccess;

    return isProposerNotInCivilPartnership(selection.id, proposerMaritalStatus)
      ? validationFailure("additionalDriver.driverRelationshipStatusQuestion.maritalStatusInvalid.civilPartner")
      : validationSuccess;
  };
}

const isProposerNotInCivilPartnership = (
  relationshipToProposer: RelationshipToProposer,
  proposerMaritalStatus: MaritalStatus
): boolean => relationshipToProposer === RelationshipToProposer.PartnerCivil && proposerMaritalStatus !== MaritalStatus.CivilPartnership;

export function multipleSpouseSelectedValidator(
  {additionalDrivers, driverId}: { additionalDrivers: SerializableAdditionalDriver[], driverId: string | undefined }
): Validator<RelationshipToProposerOption> {
  return (selection) => {
    if (additionalDrivers.length === 0 || selection.id !== RelationshipToProposer.Spouse) return validationSuccess;

    const isAnotherAdditionalDriverASpouse = (): boolean => {
      const additionalDriversToCheck = additionalDrivers.filter(driver => driver.id !== driverId);
      const relationships = additionalDriversToCheck.map(driver => driver.relationshipToProposer.id);
      return relationships.some(relationship => relationship === RelationshipToProposer.Spouse);
    };

    return isAnotherAdditionalDriverASpouse()
      ? validationFailure("additionalDriver.driverRelationshipStatusQuestion.maritalStatusInvalid.moreThanOneSpouse")
      : validationSuccess;
  };
}

export function multipleCommonLawSpouseSelectedValidator(
  {additionalDrivers, driverId}: { additionalDrivers: SerializableAdditionalDriver[], driverId: string | undefined }
): Validator<RelationshipToProposerOption> {
  return (selection) => {
    if (additionalDrivers.length === 0 || selection.id !== RelationshipToProposer.CommonLawSpouse) return validationSuccess;

    const isAnotherAdditionalDriverACommonLawSpouse = (): boolean => {
      const additionalDriversToCheck = additionalDrivers.filter(driver => driver.id !== driverId);
      const relationships = additionalDriversToCheck.map(driver => driver.relationshipToProposer.id);
      return relationships.some(relationship => relationship === RelationshipToProposer.CommonLawSpouse);
    };

    return isAnotherAdditionalDriverACommonLawSpouse()
      ? validationFailure("additionalDriver.driverRelationshipStatusQuestion.maritalStatusInvalid.moreThanOneCommonLawSpouse")
      : validationSuccess;
  };
}

export function multipleCivilPartnerSelectedValidator(
  {additionalDrivers, driverId}: { additionalDrivers: SerializableAdditionalDriver[], driverId: string | undefined }
): Validator<RelationshipToProposerOption> {
  return (selection) => {
    if (additionalDrivers.length === 0 || selection.id !== RelationshipToProposer.PartnerCivil) return validationSuccess;

    const isAnotherAdditionalDriverACivilPartner = (): boolean => {
      const additionalDriversToCheck = additionalDrivers.filter(driver => driver.id !== driverId);
      const relationships = additionalDriversToCheck.map(driver => driver.relationshipToProposer.id);
      return relationships.some(relationship => relationship === RelationshipToProposer.PartnerCivil);
    };

    return isAnotherAdditionalDriverACivilPartner()
      ? validationFailure("additionalDriver.driverRelationshipStatusQuestion.maritalStatusInvalid.moreThanOneCivilPartner")
      : validationSuccess;
  };
}
