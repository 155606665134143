import React, {FC} from "react";
import "./QuotePriceCard.css";
import {getNamedDriverEligibleYearsSelector} from "../../../../your-quote/redux/selectors/QuoteDetailsSelectors";
import AnnualQuotePriceCardFooter from "./components/QuotePriceCardAnnualFooter/AnnualQuotePriceCardFooter";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {useAppSelector} from "../../../../../../../redux/Hooks";
import {QuoteType} from "shared/dist/generated/graphql/resolvers-types";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {useQuotePriceProps} from "./hooks/UseQuotePricesProps";
import MonthlyQuotePriceCardFooter from "./components/QuotePriceCardMonthlyFooter/MonthlyQuotePriceCardFooter";
import QuotePriceCardAggregatorFooter from "./components/QuotePriceCardAggregatorFooter/QuotePriceCardAggregatorFooter";
import {useFeatureFlags} from "shared-components/dist/feature-flags/hooks/UseFeatureFlags";
import {isAggregatorQuoteSelector} from "../../../../your-quote/redux/selectors/AggregatorQuoteSelectors";
import QuotePriceCardRequirementsFooter
  from "./components/QuotePriceCardRequirementsFooter/QuotePriceCardRequirementsFooter";
import {FormattedPriceParts} from "../../../../../../../utils/money/Price";

const QuotePriceCard: FC = () => {
  const {totalUnfinancedAmountPayable, selectedLoan, monthlyPaymentsAvailable} = useQuotePriceProps();
  const paymentFrequency = useAppSelector(state => state.quoteDetails.paymentFrequency);
  const quoteReference = useAppSelector(state => state.quoteDetails.quoteReference);
  const quoteType = useAppSelector(state => state.quoteDetails.quoteType);
  const quoteNamedDriverYears = useAppSelector(getNamedDriverEligibleYearsSelector);
  const {compareNiPhase2Flag} = useFeatureFlags();
  const isAggregatorQuote = useAppSelector(isAggregatorQuoteSelector);
  const isAggregatorPhase2Quote = isAggregatorQuote && compareNiPhase2Flag;
  const hasMonthlyPayments = paymentFrequency === "Monthly" && monthlyPaymentsAvailable;

  const price = hasMonthlyPayments ? selectedLoan.monthlyPaymentAmount : totalUnfinancedAmountPayable;

  const headerLabel = <div className="quote-price__header__named-driver">
    {quoteType &&
      <p className="quote-price__header__named-driver__quote-type">
        {lookupI18nString(mapQuoteTypeToTranslationKey(quoteType))}
      </p>
    }
    {quoteReference &&
      <p className="quote-price__header__named-driver__reference">
        {lookupI18nString({
          id: "quoteDetails.priceCard.header.quoteReference",
          templateVariables: {quoteReference}
        })}
      </p>
    }
    {quoteNamedDriverYears &&
      <p className="quote-price__header__named-driver__accepted-named-driver-years">
        {lookupI18nString({
          id: "quoteDetails.priceCard.header.acceptedYearsOfExperience",
          templateVariables: {
            yearsOfExperience: quoteNamedDriverYears,
            drivingExperience: quoteType === QuoteType.NamedDriver ? lookupI18nString("quoteDetails.priceCard.header.namedDrivingExperience") : lookupI18nString("quoteDetails.priceCard.header.companyCarDrivingExperience")
          }
        })}
      </p>
    }
  </div>;

  return (
    <div className="quote-price__container">
      <div className="quote-price quote-price__header">
        {headerLabel}

        <div className="quote-price__header__price">
          <FormattedPriceParts value={price}/>
          {hasMonthlyPayments && <h3>{lookupI18nString("insurerQuoteCard.costPerMonth")}</h3>}
        </div>
      </div>

      <div className="quote-price__body">
        {paymentFrequency === "Monthly" ? <MonthlyQuotePriceCardFooter/> : <AnnualQuotePriceCardFooter/>}
      </div>

      {quoteReference && (
        <div className="quote-price__quote-reference">
          <div className="quote-price__quote-reference__label">
            {lookupI18nString("quoteDetails.priceCard.reference")}
          </div>

          <div className="quote-price__quote-reference__value">{quoteReference}</div>
        </div>
      )}

      <div className="quote-price__ipt">{lookupI18nString("quoteDetails.priceCard.ipt")}</div>
      {
        isAggregatorPhase2Quote
          ? <QuotePriceCardAggregatorFooter/>
          : quoteType && <QuotePriceCardRequirementsFooter quoteType={quoteType}/>
      }
    </div>
  );
};

function mapQuoteTypeToTranslationKey(quoteType: QuoteType): TranslationKey {
  switch (quoteType) {
    case QuoteType.CompanyCar: return "quoteDetails.priceCard.header.quoteType.companyCar";
    case QuoteType.Default: return "quoteDetails.priceCard.header.quoteType.default";
    case QuoteType.NamedDriver: return "quoteDetails.priceCard.header.quoteType.namedDriverExperience";
    case QuoteType.NoClaimsBonus: return "quoteDetails.priceCard.header.quoteType.noClaims";
  }
}

export default QuotePriceCard;
