import React, {FC} from "react";
import {EMPLOYMENT_STATUS} from "../../../../shared/questions/employment/models/EmploymentStatusOption";
import {isEmployed, isUnemployed} from "../../../../shared/validation/ValidateEmploymentStatus";
import SecondaryEmploymentQuestion from "../../../../shared/questions/employment/SecondaryEmploymentQuestion";
import {
  hasSecondaryEmploymentChanged,
  secondaryEmploymentIndustryChanged,
  secondaryEmploymentJobChanged,
  secondaryEmploymentStatusChanged,
  secondaryEmploymentWorkedInPastYearChanged
} from "../../redux/UpdateAdditionalDriverSlice";
import {logAdditionalDriverGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const AdditionalDriverSecondaryEmploymentQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const hasSecondaryEmployment = useAppSelector(state => state.updateAdditionalDriver.hasSecondaryEmployment);
  const employmentStatus = useAppSelector(state => state.updateAdditionalDriver.secondaryEmployment.employmentStatus);
  const industry = useAppSelector(state => state.updateAdditionalDriver.secondaryEmployment.industry);
  const job = useAppSelector(state => state.updateAdditionalDriver.secondaryEmployment.job);
  const workedInPastYear = useAppSelector(state => state.updateAdditionalDriver.secondaryEmployment.workedInPastYear);
  const employedQuestionsActive = useAppSelector(state => isEmployed(state.updateAdditionalDriver.secondaryEmployment.employmentStatus.value));
  const unemployedQuestionsActive = useAppSelector(state => isUnemployed(state.updateAdditionalDriver.secondaryEmployment.employmentStatus.value));

  const onHasSecondaryEmploymentChanged = (answer: boolean): void => {
    logAdditionalDriverGoogleAnalyticsEvent({
      categorySuffix: "employment_status.second_job",
      action: answer,
    });

    dispatch(hasSecondaryEmploymentChanged(answer));
  };

  const onSecondaryEmploymentStatusChanged = (selection: string): void => {
    const employmentStatus = EMPLOYMENT_STATUS.find(type => type.description === selection);

    if (!employmentStatus) return;

    dispatch(secondaryEmploymentStatusChanged(employmentStatus));
  };

  return (
    <SecondaryEmploymentQuestion
      id="additional-driver-secondary-employment"
      secondaryEmploymentTitle="additionalDriver.secondaryEmploymentQuestion"
      secondaryEmploymentTooltip={{
        title: "additionalDriver.secondaryEmploymentQuestion.tooltip.title",
        description: "additionalDriver.secondaryEmploymentQuestion.tooltip.description"
      }}
      hasSecondaryEmployment={hasSecondaryEmployment}
      hasSecondaryEmploymentChanged={onHasSecondaryEmploymentChanged}
      employmentStatus={employmentStatus}
      industry={industry}
      job={job}
      workedInPastYear={workedInPastYear}
      employmentStatusChanged={onSecondaryEmploymentStatusChanged}
      industryChanged={value => dispatch(secondaryEmploymentIndustryChanged(value))}
      jobChanged={value => dispatch(secondaryEmploymentJobChanged(value))}
      workedInPastYearChanged={value => dispatch(secondaryEmploymentWorkedInPastYearChanged(value))}
      employedQuestionsActive={employedQuestionsActive}
      unemployedQuestionsActive={unemployedQuestionsActive}
      employmentStatusQuestion={{
        employmentStatusQuestionTitle: "additionalDriver.secondaryEmploymentQuestion.employmentStatus.title",
        employmentStatusQuestionPlaceholder: "additionalDriver.secondaryEmploymentQuestion.employmentStatus.placeholder"
      }}
      employedQuestions={{
        industryQuestionTitle: "additionalDriver.secondaryEmploymentQuestion.employed.industry.title",
        jobQuestionTitle: "additionalDriver.secondaryEmploymentQuestion.employed.job.title",
        industryQuestionTooltip: {
          title: "additionalDriver.secondaryEmploymentQuestion.employed.industry.tooltip.title",
          description: "additionalDriver.secondaryEmploymentQuestion.employed.industry.tooltip.description",
        },
        jobQuestionTooltip: {
          description: "additionalDriver.secondaryEmploymentQuestion.employed.job.tooltip.description",
        },
      }}
      unemployedQuestions={{
        workedInPastYearQuestionTitle: "additionalDriver.secondaryEmploymentQuestion.unemployed.hasWorkedInPastYear.title",
        previousJobQuestionTitle: "additionalDriver.secondaryEmploymentQuestion.unemployed.previousJob.title",
        previousJobQuestionTooltip: {
          description: "additionalDriver.secondaryEmploymentQuestion.unemployed.previousJob.tooltip.description"
        },
      }}
    />
  );
};

export default AdditionalDriverSecondaryEmploymentQuestion;
