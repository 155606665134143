import React, {FC} from "react";
import {surnameChanged, surnameInvalid} from "../../redux/UpdateAdditionalDriverSlice";
import SurnameQuestion from "../../../../shared/questions/surname/SurnameQuestion";
import {hasValidName} from "shared-components/dist/form/name-input-field/validation/NameValidator";
import {logAdditionalDriverGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const AdditionalDriverSurnameQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const surnameField = useAppSelector(state => state.updateAdditionalDriver.surname);

  const onSurnameChanged = (value: string): void => {
    logAdditionalDriverGoogleAnalyticsEvent({
      categorySuffix: "surname",
      action: "Answered",
    });

    dispatch(surnameChanged(value));
  };

  return (
    <SurnameQuestion
      id="additional-driver-surname"
      title="additionalDriver.surnameQuestion.title"
      surnameField={surnameField}
      onComplete={onSurnameChanged}
      onInvalid={errorMessage => dispatch(surnameInvalid(errorMessage))}
      validators={[
        hasValidName("additionalDriver.surnameQuestion.errors.invalidEntry")
      ]}
      placeholder="additionalDriver.surnameQuestion.placeholder"
    />
  );
};

export default AdditionalDriverSurnameQuestion;