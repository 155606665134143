import {ClaimStatusOption} from "./ClaimStatusOption";
import {IncidentTypeOption} from "./IncidentTypeOption";
import {AtFaultOption} from "./AtFaultOption";
import {AccidentDescriptionOption} from "./AccidentDescriptionOption";
import {WhichPolicyClaimedAgainstOption} from "./WhichPolicyClaimedAgainstOption";
import {NoClaimsAffectedOption} from "./NoClaimsAffectedOption";

export interface SharedClaimsProps {
  hasClaims: boolean | undefined;
  shouldFlagUnansweredQuestions: boolean;
  isFormVisible: boolean;
}

export interface SharedClaimProps {
  status: ClaimStatusOption;
  incidentType: IncidentTypeOption;
  atFault: AtFaultOption;
  accidentDescription?: AccidentDescriptionOption;
  whichPolicyClaimedAgainst: WhichPolicyClaimedAgainstOption;
  noClaimsAffected: NoClaimsAffectedOption;
  bodilyInjuryOccurred: boolean;
  value: number;
}

export interface Claims extends SharedClaimsProps {
  claims: Claim[];
}

export interface Claim extends SharedClaimProps {
  date: Date;
}

export interface SerializableClaims extends SharedClaimsProps {
  claims: SerializableClaim[];
}

export interface SerializableClaim extends SharedClaimProps {
  date: string;
}

export const toClaim = (serializableClaim: SerializableClaim): Claim => {
  return {
    ...serializableClaim,
    date: new Date(serializableClaim.date)
  };
};

export const toSerializableClaim = (claim: Claim): SerializableClaim => {
  return {
    ...claim,
    date: claim.date.toISOString()
  };
};
