import {createCognitoUser, userPool} from "../../providers/CognitoProviders";
import {CognitoUser, IAuthenticationCallback} from "amazon-cognito-identity-js";
import {AccountActivationResult} from "../models/account-activation/AccountActivationResult";
import {SendForgotPasswordEmailResult} from "../models/password-management/SendForgotPasswordEmailResult";
import {ResetPasswordResult} from "../models/password-management/ResetPasswordResult";
import {ChangePasswordResult} from "../models/password-management/ChangePasswordResult";
import {logger} from "../../../utils/logging/Logger";

export async function sendUserPasswordResetEmail(email: string): Promise<SendForgotPasswordEmailResult> {
  const cognitoUser = createCognitoUser(email, userPool);

  return new Promise<SendForgotPasswordEmailResult>(resolve => {
    cognitoUser.forgotPassword({
      onSuccess() {
        logger.log("Successfully sent password reset email");
        resolve("Success");
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onFailure(error: any) {
        logger.error("Failed to send user password reset email", error);
        resolve("Failed");
      }
    });
  });
}

export async function resetPassword(email: string, verificationCode: string, newPassword: string): Promise<ResetPasswordResult> {
  const cognitoUser = createCognitoUser(email, userPool);

  return new Promise<ResetPasswordResult>(resolve => {
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess() {
        logger.log("Successfully reset users password");
        resolve("Success");
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onFailure(error: any) {
        logger.error("Failed to reset users password", error);
        if (error.code === "InvalidParameterException") {
          resolve("InvalidPasswordFormat");
          return;
        }
        if (error.code === "ExpiredCodeException") {
          resolve("ExpiredPasswordResetCode");
          return;
        }
        resolve("Failed");
      }
    });
  });
}

export async function changeUserPassword(cognitoUser: CognitoUser, oldPassword: string, newPassword: string): Promise<ChangePasswordResult> {
  return new Promise<ChangePasswordResult>(resolve => {
    cognitoUser.changePassword(
      oldPassword,
      newPassword,
      (error) => {
        if (!error) {
          logger.log("Change password successful");
          return resolve("Success");
        }

        logger.error("Failed to change users password", error);
        resolve(handleChangePasswordError(error));
      }
    );
  });
}

function handleChangePasswordError(error: Error): ChangePasswordResult {
  switch (error.name) {
    case "PasswordPolicyViolationException": {
      return "PasswordPolicyViolationException";
    }
    case "NotAuthorizedException": {
      return "CurrentPasswordInvalidException";
    }
    case "LimitExceededException": {
      return "LimitExceededException";
    }
    default: {
      return "Failed";
    }
  }
}

export async function confirmNewUserPassword(cognitoUsername: string, cognitoUser: CognitoUser, newPassword: string): Promise<AccountActivationResult> {
  return new Promise<AccountActivationResult>(resolve => {
    cognitoUser.completeNewPasswordChallenge(
      newPassword,
      {},
      mapCompleteNewPasswordResponseToPromise(resolve));
  });
}

function mapCompleteNewPasswordResponseToPromise(
  resolve: (value: AccountActivationResult) => void,
): IAuthenticationCallback {
  return {
    onSuccess: () => {
      logger.log("Password reset successful");
      resolve("Success");
    },
    onFailure: (error) => {
      if (error.code === "InvalidPasswordException") {
        logger.warn("Password reset failed, invalid password format", error);
        return resolve("InvalidPasswordFormat");
      }
      if (error.code === "NotAuthorizedException" && error.message.includes("session is expired")) {
        logger.warn("Password reset failed, session is expired", error);
        return resolve("SessionExpired");
      }

      logger.error("Password reset failed", error);
      resolve("Failed");
    },
  };
}
