import React, {FC, SyntheticEvent, useState} from "react";
import "./AccountActivation.css";
import Button from "shared-components/dist/buttons/button/Button";
import {Navigate} from "react-router-dom";
import {confirmNewPassword} from "./redux/ConfirmNewPassword";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {ACCOUNT_ALREADY_ACTIVATED, LOGIN} from "../../../router/models/Routes";
import {useFirstTimeLoginToken} from "./hooks/UseFirstTimeLoginToken";
import InlineQuestion from "../../../structure/questions/inline-question/InlineQuestion";
import {useFirstSignInQueryParams} from "./hooks/FirstSignInQueryParamsHook";
import {base64ToString} from "../../../utils/service/base64/Base64Conversion";
import FullPageSpinner from "shared-components/dist/spinner/full-page-spinner/FullPageSpinner";
import PortalSplashScreenHeaderBar
  from "../../../structure/navigation/portal-splash-screen-header-bar/PortalSplashScreenHeaderBar";
import PasswordConfirmation from "../../../structure/form/password-confirmation/PasswordConfirmation";
import {
  PasswordFormatState,
  PasswordMatchState
} from "../../../structure/form/password-confirmation/PasswordValidators";
import LoginFormLogo from "../shared/components/login-form-logo/LoginFormLogo";
import {useAppDispatch, useAppSelector} from "../../../redux/Hooks";

const AccountActivation: FC = () => {
  const dispatch = useAppDispatch();
  const accountActivationResult = useAppSelector(state => state.userAuthentication.accountActivationResult);

  const [password, setPassword] = useState<string>("");
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState<boolean>(false);
  const isPasswordViolatingPasswordPolicy = accountActivationResult === "InvalidPasswordFormat";
  const showSessionExpiredError = accountActivationResult === "SessionExpired";
  const {email, token} = useFirstSignInQueryParams();
  const {firstTimeLoginStatus, cognitoUser} = useFirstTimeLoginToken(email, token);

  if (firstTimeLoginStatus === "UserAlreadyActivated") return <Navigate to={ACCOUNT_ALREADY_ACTIVATED} replace/>;

  if (!email || !token || accountActivationResult === "Success") return <Navigate to={LOGIN} replace/>;

  if (firstTimeLoginStatus === "LoggingIn" || !cognitoUser) {
    return (
      <div className="account-activation">
        <PortalSplashScreenHeaderBar/>
        <FullPageSpinner message="portal.spinner.message"/>;
      </div>
    );
  }

  function onConfirmPasswordChange(passwordMatchState: PasswordMatchState, passwordFormatState: PasswordFormatState, newPassword?: string): void {
    setIsSubmitButtonEnabled(passwordMatchState === "Valid" && passwordFormatState === "Valid");
    setPassword(newPassword ?? "");
  }

  function performAccountActivation(event: SyntheticEvent): void {
    event.preventDefault();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (isSubmitButtonEnabled) dispatch(confirmNewPassword(base64ToString(email!), cognitoUser!, password));
  }

  return (
    <div className="account-activation">
      <PortalSplashScreenHeaderBar/>
      <div className="account-activation__content">
        <div className="account-activation__content__dialog">
          <LoginFormLogo/>

          <h5 className="account-activation__content__dialog__title">
            {lookupI18nString("accountActivation.informationPanel.title")}
          </h5>
          <p className="account-activation__content__dialog__subtitle">
            {lookupI18nString("accountActivation.informationPanel.subTitle")}
          </p>
          <p className="account-activation__content__dialog__description">
            {lookupI18nString("accountActivation.informationPanel.description")}
          </p>

          <form onSubmit={performAccountActivation}>
            <div className="form-fields">
              <InlineQuestion
                id="session-expired"
                status={showSessionExpiredError ? "error" : "hidden"}
                errorMessage="accountActivation.sessionExpired.error.message"
              >
                <PasswordConfirmation
                  isPasswordViolatingPasswordPolicy={isPasswordViolatingPasswordPolicy}
                  onChange={onConfirmPasswordChange}
                  submitButton={
                    <Button type="submit"
                            disabled={!isSubmitButtonEnabled}
                            className="save-password-button">
                      {lookupI18nString("accountActivation.savePasswordButton")}
                    </Button>
                  }
                  base64Email={email}
                />
              </InlineQuestion>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AccountActivation;
