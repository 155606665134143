import React, {ChangeEvent, FC, useState} from "react";
import Modal from "shared-components/dist/modals/Modal";
import SimpleModalHeader from "shared-components/dist/modals/simple-modal-header/SimpleModalHeader";
import Button from "shared-components/dist/buttons/button/Button";
import {coverStartDateDescriptionsFrom} from "../../../../../../../../utils/forms/descriptionsFrom";
import DropdownModalQuestion from "../../../../../../../../structure/questions/modal-question/variants/DropdownModalQuestion";
import {startDateOptionsByDateOfBirth} from "../../../../../shared/quote-summary/components/edit-cover-summary/helpers/StartDateOptionsByDateOfBirth";
import {DateOption} from "shared/dist/models/date-option/DateOption";
import ButtonGroup from "shared-components/dist/buttons/button-group/ButtonGroup";
import {useModalCoverStartDateStatus} from "../../../../../shared/hooks/cover-start-date/ModalCoverStartDateStatus";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {expiredCoverStartDateEdited} from "./thunks/ExpiredCoverStartDateEdited";
import BreakInCoverDetection from "../../../../../shared/break-in-cover/BreakInCoverDetection";
import {useBreakInCoverWithinModal} from "../../../../../shared/break-in-cover/hooks/UseBreakInCoverWithinModal";
import "./CoverStartDateExpiredModal.css";
import {dateOfBirthAsDateSelector} from "../../../../../your-details/redux/selectors/PersonalDetailsSelectors";
import {vehiclePurchaseDateAsDateSelector} from "../../../../../your-vehicle/redux/selectors/VehicleDateTypeSelectors";
import {useDismissOnQuoteRefreshed} from "../../../../../shared/quote-summary/components/edit-car-details-modal/hooks/useDismissOnQuoteRefreshed";
import {useFeatureFlags} from "shared-components/dist/feature-flags/hooks/UseFeatureFlags";
import {scrollToTop} from "../../../../../../../../utils/navigation/ScrollToHelpers";
import {useAppDispatch, useAppSelector} from "../../../../../../../../redux/Hooks";
import {existingPolicyExpiryDateAsDateSelector} from "../../../../../your-cover/redux/selectors/CoverDetailsSelectors";

interface Props {
  visible: boolean;
  onDismiss: () => void;
}

const CoverStartDateExpiredModal: FC<Props> = (
  {
    visible,
    onDismiss
  }
) => {
  const dispatch = useAppDispatch();
  const isQuoteLoading = useAppSelector(state => state.quoteDetails.quoteLoading);
  const dateOfBirth = useAppSelector(state => dateOfBirthAsDateSelector(state).value);
  const carPurchaseDate = useAppSelector(state => vehiclePurchaseDateAsDateSelector(state).value);
  const previousCoverExpiryDate = useAppSelector(state => existingPolicyExpiryDateAsDateSelector(state).value);

  const {monthlyPaymentsFlag} = useFeatureFlags();
  const [selectedCoverStartDate, setSelectedCoverStartDate] = useState<DateOption | undefined>(undefined);
  const coverStartDates = startDateOptionsByDateOfBirth(dateOfBirth);
  const {
    status: coverStartDateStatus,
    errorMessage: coverStartDateErrorMessage,
    errorTooltip: coverStartDateErrorTooltip
  } = useModalCoverStartDateStatus({
    coverStartDate: selectedCoverStartDate,
    carPurchaseDate,
    existingPolicyExpiryDate: previousCoverExpiryDate
  });
  const {
    breakInCover,
    breakInCoverDeclarationAcceptance,
    onBreakInCoverChanged,
    onBreakInCoverDeclarationAnswered,
  } = useBreakInCoverWithinModal();
  useDismissOnQuoteRefreshed(isQuoteLoading, visible, onDismiss);

  const onCoverStartDateChanged = (selectedCoverStartDate: string | undefined): void => {
    const coverStartDate = coverStartDates.find(coverStartDate => coverStartDate.description === selectedCoverStartDate);

    if (coverStartDate) setSelectedCoverStartDate(coverStartDate);
  };

  const onSave = (): void => {
    scrollToTop();
    dispatch(expiredCoverStartDateEdited(
      monthlyPaymentsFlag,
      {
        selectedCoverStartDate,
        breakInCover,
        breakInCoverDeclarationAcceptance: breakInCoverDeclarationAcceptance.value
      },
    ));
  };

  const isUpdateDisabled = (): boolean => {
    const isDeclarationShownAndNotConfirmed = breakInCoverDeclarationAcceptance.isActive && breakInCoverDeclarationAcceptance.value !== true;
    const isBreakInCoverSoftStopShown = breakInCover === "CANNOT_INSURE_ONLINE";

    return isDeclarationShownAndNotConfirmed || isBreakInCoverSoftStopShown || !selectedCoverStartDate || coverStartDateStatus === "error";
  };

  return (
    <Modal
      className="cover-start-date-expired-modal"
      visible={visible}
      header={<SimpleModalHeader title="priorQuote.coverStartDateExpiredModal.title"/>}
      body={
        <>
          <DropdownModalQuestion
            id="cover-start-date-expired-modal-question"
            title="priorQuote.coverStartDateExpiredModal.question.title"
            description="priorQuote.coverStartDateExpiredModal.question.description"
            name="cover-start-date-expired-modal-dropdown"
            value={selectedCoverStartDate?.description}
            options={coverStartDateDescriptionsFrom(coverStartDates)}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => onCoverStartDateChanged(event.target.value)}
            status={coverStartDateStatus}
            errorMessage={coverStartDateErrorMessage}
            errorTooltip={coverStartDateErrorTooltip}
          />

          <BreakInCoverDetection
            coverStartDate={selectedCoverStartDate?.date}
            previousCoverExpiryDate={previousCoverExpiryDate}
            carPurchaseDate={carPurchaseDate}
            breakInCover={breakInCover}
            breakInCoverDeclarationAcceptance={breakInCoverDeclarationAcceptance}
            breakInCoverChanged={onBreakInCoverChanged}
            breakInCoverDeclarationAcceptanceChanged={onBreakInCoverDeclarationAnswered}
            isWithinModal
          />
        </>
      }
      footer={
        <ButtonGroup>
          <Button
            loading={isQuoteLoading}
            onClick={onSave}
            disabled={isUpdateDisabled()}
            expanded
          >
            {lookupI18nString("priorQuote.coverStartDateExpiredModal.buttons.update")}
          </Button>
        </ButtonGroup>
      }
    />
  );
};

export default CoverStartDateExpiredModal;