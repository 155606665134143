import {useEffect, useState} from "react";
import {scroller} from "react-scroll";
import {DEFAULT_SCROLL_TO_PROPS} from "../../validation/hooks/ScrollToProps";

interface UseScrollTo {
  scroll: () => void;
}

export function useScrollTo(to: string): UseScrollTo {
  const [shouldScroll, setShouldScroll] = useState(false);
  const scroll = (): void => setShouldScroll(true);

  useEffect(() => {
    if (!shouldScroll) return;

    setShouldScroll(false);

    scroller.scrollTo(to, DEFAULT_SCROLL_TO_PROPS);
  }, [to, shouldScroll]);

  return {scroll};
}
