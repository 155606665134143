import React, {FC, useState} from "react";
import DateModalQuestion from "../../../../../../../structure/questions/modal-question/variants/DateModalQuestion";
import {
  isAfterManufactureDate,
  isValidPurchaseDate
} from "../../../../your-vehicle/components/have-you-bought-car/validators/PurchaseDateValidators";
import InputFieldModalQuestion from "../../../../../../../structure/questions/modal-question/variants/InputFieldModalQuestion";
import Button from "shared-components/dist/buttons//button/Button";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {FormField} from "shared-components/dist/models/form-field/FormField";
import {useDismissOnQuoteRefreshed} from "./hooks/useDismissOnQuoteRefreshed";
import Modal from "shared-components/dist/modals/Modal";
import ButtonGroup from "shared-components/dist/buttons//button-group/ButtonGroup";
import SimpleModalHeader from "shared-components/dist/modals/simple-modal-header/SimpleModalHeader";
import {currentQuoteQueryArgsSelector} from "../../../../your-quote/redux/selectors/QuoteDetailsSelectors";
import {isCheckInvalid, isRegexValid} from "shared-components/dist/utils/validators/StringValidators";
import BreakInCoverDetection from "../../../break-in-cover/BreakInCoverDetection";
import "./EditCarDetailsModal.css";
import {
  isQPlateRegistration,
  MAX_REG_NUMBER_LENGTH,
  VALID_REGISTRATION_INPUT
} from "../../../../../../../utils/validation/RegistrationNumber";
import {useBreakInCoverWithinModal} from "../../../break-in-cover/hooks/UseBreakInCoverWithinModal";
import {carDetailsEdited} from "./thunks/CarDetailsEdited";
import {
  coverStartDateAsDateOptionSelector,
  existingPolicyExpiryDateAsDateSelector
} from "../../../../your-cover/redux/selectors/CoverDetailsSelectors";
import {useFeatureFlags} from "shared-components/dist/feature-flags/hooks/UseFeatureFlags";
import {daysBetween} from "shared/dist/stdlib/Dates";
import {useLocation, useNavigate} from "react-router-dom";
import {YOUR_COVER} from "../../../../../../../router/models/Routes";
import {buildQuoteRoute} from "../../../../../../../utils/navigation/NavigationHelpers";
import {selectHasNamedDriverOrCompanyCarBonus} from "./selectors/HasNamedDriverOrCompanyCarBonusSelector";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";
import {isAggregatorQuoteSelector} from "../../../../your-quote/redux/selectors/AggregatorQuoteSelectors";

interface Props {
  visible: boolean;
  onDismiss: () => void;
}

interface UpdateCarDetailsInReduxStoreParams {
  shouldReloadQuotes: boolean
}

const EditCarDetailsModal: FC<Props> = (
  {
    visible,
    onDismiss,
  }
) => {
  const dispatch = useAppDispatch();
  const isQuoteLoading = useAppSelector(state => state.quoteDetails.quoteLoading);
  const vehicle = useAppSelector(state => currentQuoteQueryArgsSelector(state).vehicle);
  const coverStartDate = useAppSelector(state => coverStartDateAsDateOptionSelector(state).value?.date);
  const previousCoverExpiryDate = useAppSelector(state => existingPolicyExpiryDateAsDateSelector(state).value);
  const hasNamedDriverOrCompanyCarBonus = useAppSelector(selectHasNamedDriverOrCompanyCarBonus);
  const isAggregatorQuote = useAppSelector(isAggregatorQuoteSelector);

  const initialRegistrationNumberField: FormField<string> = {status: "default", value: undefined};
  const initialDateOfPurchaseField: FormField<Date> = {status: "default", value: undefined};

  const {monthlyPaymentsFlag} = useFeatureFlags();
  const [registrationNumberField, setRegistrationNumberField] = useState<FormField<string>>(initialRegistrationNumberField);
  const [dateOfPurchaseField, setDateOfPurchaseField] = useState<FormField<Date>>(initialDateOfPurchaseField);
  const [isFocusTrapPaused, setIsFocusTrapPaused] = useState(false);
  useDismissOnQuoteRefreshed(isQuoteLoading, visible, onDismiss);
  const navigate = useNavigate();
  const location = useLocation();

  const {
    breakInCover,
    breakInCoverDeclarationAcceptance,
    onBreakInCoverChanged,
    onBreakInCoverDeclarationAnswered,
    breakInCoverReset,
  } = useBreakInCoverWithinModal();

  const showDateOfPurchaseField = !vehicle.dateOfPurchase;
  const showRegistrationNumberField = !vehicle.registration;

  function onSave(): void {
    if (hasNamedDriverOrCompanyCarBonus) {
      validateGapInCoverBeforeSaving();
    } else {
      updateCarDetailsInReduxStore({shouldReloadQuotes: true});
    }
  }

  function validateGapInCoverBeforeSaving(): void {
    if (dateOfPurchaseField.value && coverStartDate) {
      validateGapBetween(dateOfPurchaseField.value, coverStartDate);
    } else {
      updateCarDetailsInReduxStore({shouldReloadQuotes: true});
    }
  }

  function validateGapBetween(dateOfPurchase: Date, coverStartDate: Date): void {
    if (daysBetween(dateOfPurchase, coverStartDate) > 1) {
      redirectToYourCoverPageForGapInCoverQuestions();
    } else {
      updateCarDetailsInReduxStore({shouldReloadQuotes: true});
    }
  }

  function redirectToYourCoverPageForGapInCoverQuestions(): void {
    navigate(buildQuoteRoute(location.pathname, YOUR_COVER));
    updateCarDetailsInReduxStore({shouldReloadQuotes: false});
  }

  function updateCarDetailsInReduxStore(updateCarDetailsInReduxStoreParams: UpdateCarDetailsInReduxStoreParams): void {
    dispatch(carDetailsEdited(
      monthlyPaymentsFlag,
      {
        registrationNumber: registrationNumberField.value,
        dateOfPurchase: dateOfPurchaseField.value,
        breakInCover,
        breakInCoverDeclarationAcceptance: breakInCoverDeclarationAcceptance.value,
        isAggregatorQuote
      },
      updateCarDetailsInReduxStoreParams.shouldReloadQuotes
    ));
  }

  function isSaveDisabled(): boolean {
    const isDateOfPurchaseShownAndUnanswered = showDateOfPurchaseField && dateOfPurchaseField.status !== "success";
    const isRegistrationNumberShownAndUnanswered = showRegistrationNumberField && registrationNumberField.status !== "success";
    const isDeclarationShownAndNotConfirmed = breakInCoverDeclarationAcceptance.isActive && breakInCoverDeclarationAcceptance.value !== true;
    const isBreakInCoverSoftStopShown = breakInCover === "CANNOT_INSURE_ONLINE";

    return (
      isDateOfPurchaseShownAndUnanswered
      || isRegistrationNumberShownAndUnanswered
      || isDeclarationShownAndNotConfirmed
      || isBreakInCoverSoftStopShown
    );
  }

  function closeModal(): void {
    onDismiss();

    setRegistrationNumberField(initialRegistrationNumberField);
    setDateOfPurchaseField(initialDateOfPurchaseField);
    breakInCoverReset();
  }

  return (
    <Modal
      className="edit-car-details"
      visible={visible}
      onDismiss={closeModal}
      header={<SimpleModalHeader onDismiss={closeModal} title="quoteDetails.carDetails.modal.title"/>}
      isFocusTrapPaused={isFocusTrapPaused}
      body={
        <>
          {(showRegistrationNumberField || registrationNumberField.value) && (
            <InputFieldModalQuestion
              className="uppercase"
              id="edit-policy-registration-number"
              title="quoteDetails.carDetails.modal.registrationNumber"
              name="edit-policy-registration-number"
              placeholder="quoteDetails.carDetails.modal.registrationNumber.placeholder"
              onValueChange={value => setRegistrationNumberField({
                ...registrationNumberField,
                status: "success",
                value: value.toUpperCase()
              })}
              onInvalid={errorMessage => setRegistrationNumberField({
                ...registrationNumberField,
                status: "error",
                errorMessage
              })}
              status={registrationNumberField.status}
              errorMessage={registrationNumberField.errorMessage}
              maxLength={MAX_REG_NUMBER_LENGTH}
              validators={[
                isCheckInvalid(
                  isQPlateRegistration,
                  "quoteDetails.carDetails.modal.registrationNumber.error.qRegistration"
                ),
                isRegexValid(
                  VALID_REGISTRATION_INPUT,
                  "quoteDetails.carDetails.modal.registrationNumber.error",
                  (value: string) => value.toUpperCase()
                )
              ]}
            />
          )}

          {(showDateOfPurchaseField || dateOfPurchaseField.value) && (
            <>
              <DateModalQuestion
                id="edit-policy-date-of-purchase"
                title="quoteDetails.carDetails.modal.dateOfPurchase"
                name="edit-policy-date-of-purchase"
                value={dateOfPurchaseField.value}
                onComplete={value => setDateOfPurchaseField({...dateOfPurchaseField, status: "success", value})}
                onInvalid={errorMessage => setDateOfPurchaseField({
                  ...dateOfPurchaseField,
                  status: "error",
                  errorMessage
                })}
                onClear={() => setDateOfPurchaseField(initialDateOfPurchaseField)}
                setIsFocusTrapPaused={setIsFocusTrapPaused}
                status={dateOfPurchaseField.status}
                errorMessage={dateOfPurchaseField.errorMessage}
                validators={[
                  isAfterManufactureDate(vehicle.yearOfManufacture),
                  isValidPurchaseDate("quoteDetails.carDetails.modal.dateOfPurchase.afterToday")
                ]}
              />
              {!hasNamedDriverOrCompanyCarBonus &&
                <BreakInCoverDetection
                  carPurchaseDate={dateOfPurchaseField.value}
                  previousCoverExpiryDate={previousCoverExpiryDate}
                  coverStartDate={coverStartDate}
                  breakInCover={breakInCover}
                  breakInCoverDeclarationAcceptance={breakInCoverDeclarationAcceptance}
                  breakInCoverChanged={onBreakInCoverChanged}
                  breakInCoverDeclarationAcceptanceChanged={onBreakInCoverDeclarationAnswered}
                  isWithinModal
                />
              }
            </>
          )}
        </>
      }
      footer={
        <ButtonGroup>
          <Button
            onClick={closeModal}
            variant="tertiary"
          >
            {lookupI18nString("quoteDetails.carDetails.modal.cancel")}
          </Button>

          <Button
            disabled={isSaveDisabled()}
            onClick={onSave}
            loading={isQuoteLoading}
          >
            {lookupI18nString("quoteDetails.carDetails.modal.save")}
          </Button>
        </ButtonGroup>
      }
    />
  );
};

export default EditCarDetailsModal;