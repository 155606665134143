import React, {FC} from "react";
import "./PaymentSuccessBody.css";
import Spacer from "shared-components/dist/spacer/Spacer";
import AppStoreBadges from "../app-store-badges/AppStoreBadges";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {Done} from "@mui/icons-material";
import CircleIcon from "shared-components/dist/icons/circle-icon/CircleIcon";
import {PaymentFlowType} from "../../../shared/models/PaymentFlowType";

interface Props {
  paymentFlowType: PaymentFlowType;
}

const PaymentSuccessBody: FC<Props> = ({paymentFlowType}) => (
  <div className="payment-success-body">
    <div className="payment-success-body__container">
      <div className="payment-success-body__container__content">
        <CircleIcon status="success">
          <Done/>
        </CircleIcon>

        <h2>{lookupI18nString("paymentFlow.success.title")}</h2>
        <h4>
          {paymentFlowType === PaymentFlowType.QUOTE
          ? lookupI18nString("paymentFlow.success.description")
          : lookupI18nString("paymentFlow.success.renewal.description")}
        </h4>

        <Spacer/>

        <div className="payment-success-body__container__content__call-to-action grid-x">
          <div className="medium-8 medium-offset-2">
            <h4>{lookupI18nString("paymentFlow.success.callToAction.title")}</h4>
            <p>
              {paymentFlowType === PaymentFlowType.QUOTE
                ? lookupI18nString("paymentFlow.success.callToAction.checkEmail")
                : lookupI18nString("paymentFlow.success.renewal.weWillSendYouAnEmail")}
            </p>
            <p>
              {paymentFlowType === PaymentFlowType.QUOTE
              ? lookupI18nString("paymentFlow.success.callToAction.downloadMobileApp")
              : lookupI18nString("paymentFlow.success.renewal.weWillBeInTouch")}
            </p>
            <AppStoreBadges/>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PaymentSuccessBody;