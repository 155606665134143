import {cardHolderNameId} from "../components/CardHolderName";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {cardNumberId} from "../components/CardNumber";
import {cardExpiryId} from "../components/CardExpiry";
import {cardSecurityId} from "../components/CardSecurityNumber";
import {submitButtonId} from "../components/SubmitButton";

export type GlobalPayForm = {
  [key in "card-number" | "card-expiration" | "card-cvv" | "card-holder-name" | "submit"]: GlobalPayInput;
}

export interface GlobalPayInput {
  target: string;
  placeholder?: string;
  text?: string; // text param used to set submit button string - https://github.com/globalpayments/globalpayments-js/issues/39
}

export const configuredGlobalPayForm: GlobalPayForm = {
  "card-holder-name": {
    target: cardHolderNameId,
    placeholder: lookupI18nString("paymentFlow.byCard.form.cardDetails.cardholderName.placeholder")
  },
  "card-number": {
    target: cardNumberId,
    placeholder: lookupI18nString("paymentFlow.byCard.form.cardDetails.cardNumber.placeholder")
  },
  "card-expiration": {
    target: cardExpiryId,
    placeholder: lookupI18nString("paymentFlow.byCard.form.cardDetails.expiry.placeholder")
  },
  "card-cvv": {
    target: cardSecurityId,
    placeholder: lookupI18nString("paymentFlow.byCard.form.cardDetails.cvv.placeholder")
  },
  "submit": {
    target: submitButtonId,
    text: lookupI18nString("paymentFlow.byCard.form.button")
  },
};