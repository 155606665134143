import {
  convictionsInvalid,
  flagUnansweredConvictionsSubQuestions
} from "../redux/PersonalDetailsSlice";
import {AppThunkDispatch} from "../../../../../redux/Store";
import {RequiredFormField} from "shared-components/dist/models/form-field/FormField";
import {SerializableConvictions} from "../../shared/questions/convictions/models/Conviction";
import {PersonalDetails} from "../redux/PersonalDetails";

export function flagUnansweredConvictionsQuestions(
  {convictions}: PersonalDetails,
  dispatch: AppThunkDispatch
): void {
  if (hasConvictionsUnanswered(convictions) || convictions.value.isFormVisible) {
    dispatch(convictionsInvalid("quote.errors.questionIncomplete"));
  }
  if (convictions.value.isFormVisible) dispatch(flagUnansweredConvictionsSubQuestions());
}

const hasConvictionsUnanswered = (convictions: RequiredFormField<SerializableConvictions>): boolean => {
  return convictions.value.hasConvictions === undefined && convictions.status === "default";
};
