import {FC, ReactNode} from "react";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./RequestPrintDocumentsModalBody.css";
import PolicyHolderAddress, {PolicyHolderAddressProps} from "./components/PolicyHolderAddress";
import PrintResponseRag from "./components/PrintResponseRag";
import {PrintRequestResponse} from "shared/dist/graphql/queries/request-printed-documents/RequestPrintedDocumentsFromDocmail";
import Rag from "shared-components/dist/information/rag/Rag";

export type PrintRequestBodyState = "INITIAL_VIEW" | "GENERIC_ERROR" | PrintRequestResponse

interface RequestPrintDocumentsModalBodyProps extends PolicyHolderAddressProps {
  printRequestBodyState: PrintRequestBodyState;
  policyBookletUrl?: string;
}

const RequestPrintDocumentsModalBody: FC<RequestPrintDocumentsModalBodyProps> = (
  {
    address,
    printRequestBodyState,
    policyBookletUrl
  }
) => {
  return (
    <>
      {
        printRequestBodyState === "INITIAL_VIEW"
          ? <PolicyHolderAddress address={address}/>
          : getStatusRag(printRequestBodyState)
      }

      <h5 className="request-print-documents-modal__body__policy-booklet__header">
        {lookupI18nString("policies.documents.requestPrintedDocuments.modal.policyBooklet.header")}
      </h5>
      <p className="request-print-documents-modal__body__policy-booklet__description">
        {lookupI18nString("policies.documents.requestPrintedDocuments.modal.policyBooklet.description")} <a
        href={policyBookletUrl} target="_blank"
        rel="noreferrer">{lookupI18nString("policies.documents.requestPrintedDocuments.modal.policyBooklet.link")}</a>
      </p>
    </>
  );
};

const getStatusRag = (status: "GENERIC_ERROR" | PrintRequestResponse): ReactNode => (
  status === "GENERIC_ERROR"
    ? <Rag
      status="error"
      description="policies.documents.requestPrintedDocuments.modal.error"
    />
    : <PrintResponseRag printResponse={status}/>
);

export default RequestPrintDocumentsModalBody;