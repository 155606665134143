import {
  CountryOfIssue,
  Gender,
  LicenceType, MaritalStatus,
  Maybe,
  MedicalCondition,
  ProposedClassOfUse, StoredAtNight
} from "shared/dist/generated/graphql/resolvers-types";
import {GenderOption, GENDERS} from "../../../../quote/vehicle/shared/questions/gender/Genders";
import {ClassOfUseOption} from "../../../../quote/vehicle/shared/questions/class-of-use/ClassOfUseOption";
import DEFAULT_CLASS_OF_USE_DESCRIPTIONS
  from "../../../../quote/vehicle/shared/questions/class-of-use/DefaultClassOfUseDescriptions";
import {
  MEDICAL_CONDITIONS,
  MedicalConditionOption
} from "../../../../quote/vehicle/shared/questions/medical-condition/MedicalConditionOption";
import {
  LICENCE_TYPES,
  LicenceTypeOption
} from "../../../../quote/vehicle/shared/questions/licence-type/LicenceTypeOption";
import {
  LICENCE_COUNTRIES_OF_ISSUE,
  LicenceCountryOfIssue
} from "../../../../quote/vehicle/shared/questions/licence-country-of-issue/LicenceCountryOfIssue";
import {
  MARITAL_STATUSES,
  MaritalStatusOption
} from "../../../../quote/vehicle/shared/questions/martial-status/MaritalStatusOption";
import {now} from "shared/dist/stdlib/Dates";
import {
  STORED_AT_NIGHT,
  StoredAtNightOption
} from "../../../../quote/vehicle/your-details/components/stored-at-night/constants/StoredAtNightOption";
import {differenceInMonths} from "date-fns";

export function buildGender(gender: Gender): GenderOption {
  const userGender = GENDERS.find(GENDER => GENDER.id === gender);
  if (!userGender) throw new Error(`Unexpected Gender id: ${gender}`);

  return userGender;
}

export function buildClassOfUse(classOfUse: ProposedClassOfUse): ClassOfUseOption {
  return {
    id: classOfUse,
    description: DEFAULT_CLASS_OF_USE_DESCRIPTIONS[classOfUse]
  };
}

export function buildMedicalCondition(medicalCondition?: Maybe<MedicalCondition>): MedicalConditionOption | undefined {
  return MEDICAL_CONDITIONS.find(condition => condition.id === medicalCondition);
}

export function buildLicenceType(licenceType: LicenceType): LicenceTypeOption {
  const userLicenceType = LICENCE_TYPES.find(type => type.id === licenceType);
  if (!userLicenceType) throw new Error(`Unexpected Licence Type id: ${licenceType}`);

  return userLicenceType;
}

export function buildCountryOfIssue(countryOfIssue: CountryOfIssue): LicenceCountryOfIssue {
  const licenceCountryOfIssue = LICENCE_COUNTRIES_OF_ISSUE.find(country => country.id === countryOfIssue);
  if (!licenceCountryOfIssue) throw new Error(`Unexpected Country of Issue id: ${countryOfIssue}`);

  return licenceCountryOfIssue;
}

export function buildMartialStatus(martialStatus: MaritalStatus): MaritalStatusOption {
  const userMaritalStatus = MARITAL_STATUSES.find(status => status.id === martialStatus);
  if (!userMaritalStatus) throw new Error(`Unexpected Martial Status id: ${martialStatus}`);

  return userMaritalStatus;
}

export function livedInUKSinceBirth(dateOfBirth: string, ukResidencyDurationMonths: number): boolean {
  const birthDate = new Date(dateOfBirth);
  const monthsSinceBirth = differenceInMonths(now(), birthDate);
  return monthsSinceBirth === ukResidencyDurationMonths;
}

export function buildStoredAtNight(storedAtNight: StoredAtNight): StoredAtNightOption | undefined {
  return STORED_AT_NIGHT.find(type => type.id === storedAtNight);
}