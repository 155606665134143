import {validationFailure, validationSuccess, Validator} from "shared-components/dist/utils/validation/Validator";
import {getAge} from "shared/dist/stdlib/Age";

export const hasBeenUKResidentForLessThanAlive = (residencyDuration: number | undefined): Validator<Date> => {
  return dateOfBirth => {
    if (!residencyDuration) return validationSuccess;

    if (isAgeGreaterThanResidencyDuration(dateOfBirth, residencyDuration)) return validationSuccess;

    return validationFailure({
      id: "personalDetails.dateOfBirthQuestion.errors.isTooYoung.forResidencyDuration",
      templateVariables: {
        residencyDuration,
        age: getAge(dateOfBirth)
      }
    });
  };
};

export const canHaveBeenUkResidentForDurationGivenTheDateOfBirth = (dateOfBirth: Date | undefined): Validator<Duration | undefined> => {
  if (!dateOfBirth) return () => validationSuccess;

  return (residencyDuration) => hasBeenUKResidentForLessThanAlive(residencyDuration?.years)(dateOfBirth);
};

const isAgeGreaterThanResidencyDuration = (dateOfBirth: Date, residencyDuration: number): boolean => (
  getAge(dateOfBirth) >= residencyDuration
);