import React, {FC, useCallback} from "react";
import ResidencyQuestion from "../../../../shared/questions/residency/ResidencyQuestion";
import {
  hasUkResidencyFromBirthChanged,
  ukResidencyDurationChanged,
  ukResidencyDurationInvalid,
  ukResidencyDurationReset
} from "../../redux/UpdateAdditionalDriverSlice";
import {residencyDurationStopVisibleSelector} from "../../redux/selectors/ResidencyDurationStopVisibleSelector";
import {logAdditionalDriverGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {dateOfBirthAsDateSelector} from "../../redux/selectors/AdditionalDriverDateTypeSelectors";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const AdditionalDriverResidencyQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const hasUkResidencyFromBirth = useAppSelector(state => state.updateAdditionalDriver.hasUkResidencyFromBirth);
  const ukResidencyDuration = useAppSelector(state => state.updateAdditionalDriver.ukResidencyDuration);
  const isStopVisible = useAppSelector(residencyDurationStopVisibleSelector);
  const dateOfBirth = useAppSelector(state => dateOfBirthAsDateSelector(state).value);

  const onResidencyStatusChanged = (answer: boolean): void => {
    logAdditionalDriverGoogleAnalyticsEvent({
      categorySuffix: "continuously_live_in_uk",
      action: answer
    });

    dispatch(hasUkResidencyFromBirthChanged(answer));
  };

  const onReset = useCallback(() => {
    dispatch(ukResidencyDurationReset());
  }, [dispatch]);

  return (
    <ResidencyQuestion
      id="additional-driver-uk-residency-from-birth-question"
      title="additionalDriver.residency.fromBirth"
      subQuestionId="additional-driver-uk-residency-duration-sub-question"
      subQuestionTitle="additionalDriver.residency.duration"
      tooltip={{
        description: "additionalDriver.residency.fromBirth.tooltip"
      }}
      hasUkResidencyFromBirth={hasUkResidencyFromBirth}
      hasUkResidencyFromBirthChanged={onResidencyStatusChanged}
      ukResidencyDuration={ukResidencyDuration}
      ukResidencyDurationChanged={value => dispatch(ukResidencyDurationChanged(value))}
      ukResidencyDurationInvalid={errorMessage => dispatch(ukResidencyDurationInvalid(errorMessage))}
      ukResidencyDurationReset={onReset}
      dateOfBirth={dateOfBirth}
      isStopVisible={isStopVisible}
      isAdditionalDriverForm
    />
  );
};

export default AdditionalDriverResidencyQuestion;