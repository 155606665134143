import loadable from "@loadable/component";
import {FC} from "react";
import {isMobile} from "react-device-detect";

interface Props {
  isQuoteFlow?: boolean;
}

const DebugPanel = loadable(() => import("./DebugPanel"));
const EmptyDebugPanel = loadable(() => import("./EmptyDebugPanel"));

// This will ensure the code is only available on a development compile / 'yarn start'
export const OptionalDebugPanel: FC<Props> = ({isQuoteFlow = false}) => {
  const isNotProduction = !window.Stroll?.IS_PRODUCTION ?? false;
  const shouldShowPanel = isNotProduction && !isMobile;

  return (
    <>
      {
        shouldShowPanel
          ? <DebugPanel isQuoteFlow={isQuoteFlow}/>
          : <EmptyDebugPanel/>
      }
    </>
  );
};
