import {
  DriverPosition as GraphqlDriverPosition,
  ImportType as GraphqlImportType,
  ModificationType,
  QuoteVehicle,
  VehicleHolder,
} from "shared/dist/generated/graphql/resolvers-types";
import {valueOrError} from "./ValueOrError";
import {DriverPosition} from "../../../../queries/vehicle-lookup/registration-lookup/model/DriverPosition";
import {ImportType} from "../../../../queries/vehicle-lookup/registration-lookup/model/ImportType";
import {validatedVehicleSelector} from "../../../../../pages/quote/vehicle/your-vehicle/redux/selectors/ValidatedVehicleSelectors";
import {RootState} from "../../../../../redux/Store";
import {Modification} from "../../../../../pages/quote/vehicle/your-vehicle/components/modifications/model/Modification";
import {VehicleHolderOption} from "../../../../../utils/constants/VehicleHolderOption";
import {formatDateStringFromIsoString} from "shared/dist/stdlib/DateFormat";
import {VehicleDetails} from "../../../../../pages/quote/vehicle/your-vehicle/redux/VehicleDetails";
import {manualVehicleRegistrationSelector} from "../../../../../pages/quote/vehicle/your-quote/redux/selectors/ManualVehicleRegistrationSelectors";

export function mapVehicleToGraphql(rootState: RootState): QuoteVehicle {
  const validVehicle = validatedVehicleSelector(rootState);
  const vehicleDetails = rootState.vehicleDetails;
  const dateOfPurchase = rootState.vehicleDetails.dateOfPurchase.value;
  const manualVehicleRegistration = manualVehicleRegistrationSelector(rootState);

  if (!validVehicle) throw Error("Unable to determine valid vehicle to run quote for");
  if (!hasOwner(vehicleDetails)) {
    throw Error("Unable to determine valid vehicle details to run quote for due to missing owner");
  }

  if (validVehicle.hasAlarm === undefined) {
    throw Error("Unable to determine valid vehicle alarm field");
  }

  return {
    abi: validVehicle.abi,
    make: validVehicle.make,
    model: validVehicle.model,
    trim: validVehicle.trim,
    bodyStyle: validVehicle.bodyStyle,
    engineCapacityCc: validVehicle.engineCapacityCc,
    engineCapacityLitres: validVehicle.engineCapacityLitres ?? undefined,
    fuelType: validVehicle.fuelType,
    transmissionType: validVehicle.transmissionType,
    yearOfManufacture: validVehicle.yearOfManufacture,
    doors: valueOrError(validVehicle.doors, "No doors provided"),
    driverPosition: parseDriverPosition(validVehicle.driverPosition),
    seats: valueOrError(validVehicle.seats, "No seats provided"),
    imported: validVehicle.imported ?? false,
    importType: parseImportType(validVehicle.importType),
    estimatedValue: valueOrError(validVehicle.estimatedValue, "No trader price provided"),
    registration: validVehicle.registration ?? manualVehicleRegistration,
    commercialVehicle: validVehicle.commercialVehicle,
    hasTracker: validVehicle.hasTracker ?? false,
    owner: vehicleDetails.isOwnerAndRegisteredKeeper.value ? VehicleHolder.PolicyHolder : parseOwner(vehicleDetails.owner.value),
    keeper: vehicleDetails.isOwnerAndRegisteredKeeper.value ? VehicleHolder.PolicyHolder : parseKeeper(vehicleDetails.registeredKeeper.value),
    modifications: mapModificationsList(vehicleDetails.modifications.value.modifications),
    dateOfPurchase: dateOfPurchase ? formatDateStringFromIsoString(dateOfPurchase) : undefined,
    vehicleAlarm: {
      hasAlarm: validVehicle.hasAlarm,
      fittedBy: validVehicle.alarmFittedBy?.id,
    }
  };
}

function hasOwner(vehicleDetails: VehicleDetails): boolean {
  if (vehicleDetails.isOwnerAndRegisteredKeeper.value) return true;

  return vehicleDetails.owner.value !== undefined && vehicleDetails.registeredKeeper.value !== undefined;
}

function parseDriverPosition(driverPosition: DriverPosition | undefined): GraphqlDriverPosition {
  switch (driverPosition) {
    case "Left":
      return GraphqlDriverPosition.Left;
    case "Right":
      return GraphqlDriverPosition.Right;
    default:
      throw Error("No driver position provided");
  }
}

function parseImportType(importType: ImportType | undefined): GraphqlImportType | undefined {
  switch (importType) {
    case "EU":
      return GraphqlImportType.Eu;
    case "NonEU":
      return GraphqlImportType.NonEu;
    default:
      return undefined;
  }
}

function mapModificationsList(modifications: Modification[]): ModificationType[] {
  return modifications.map(modification => modification.type.id);
}

function parseOwner(owner: VehicleHolderOption | undefined): VehicleHolder {
  if (!owner) throw Error("Unable to determine valid owner for vehicle");

  return owner.id;
}

function parseKeeper(keeper: VehicleHolderOption | undefined): VehicleHolder {
  if (!keeper) throw Error("Unable to determine valid keeper for vehicle");

  return keeper.id;
}
