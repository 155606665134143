import React, {FC} from "react";
import {isImportedChanged} from "../../../redux/VehicleDetailsSlice";
import BooleanSubQuestion
  from "../../../../../../../structure/questions/sub-question/variants/boolean-sub-question/BooleanSubQuestion";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const AdditionalDetailsImported: FC = () => {
  const dispatch = useAppDispatch();
  const imported = useAppSelector(state => state.vehicleDetails.additionalVehicleDetails.imported);

  return (
    <BooleanSubQuestion
      title="vehicleQuote.additionalVehicleDetailsForm.imported"
      id="additional-details-imported"
      tooltip={{
        title: "vehicleQuote.additionalVehicleDetailsForm.imported.tooltip.title",
        description: "vehicleQuote.additionalVehicleDetailsForm.imported.tooltip.description"
      }}
      value={imported.value}
      status={imported.status}
      errorMessage={imported.errorMessage}
      onAnswer={value => dispatch(isImportedChanged(value))}
    />
  );
};

export default AdditionalDetailsImported;
