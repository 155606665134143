import React, {FC} from "react";
import {AbiListItem} from "shared/dist/generated/graphql/resolvers-types";
import TitleQuestion from "../../../shared/questions/title/TitleQuestion";
import {titleChanged, titleReset} from "../../redux/PersonalDetailsSlice";
import {logYourDetailsGoogleAnalyticsEvent} from "../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/Hooks";

const ProposerTitleQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const titleField = useAppSelector(state => state.personalDetails.title);

  const onTitleChanged = (title: AbiListItem): void => {
    dispatch(titleChanged(title));

    logYourDetailsGoogleAnalyticsEvent({
      categorySuffix: "title",
      action: title.vtDescription.toLowerCase(),
    });
  };

  return (
    <TitleQuestion
      id="proposer-title"
      questionTitle="personalDetails.titleQuestion"
      titleField={titleField}
      titleChanged={onTitleChanged}
      titleReset={() => dispatch(titleReset())}
      placeholder="personalDetails.titleDropdownPlaceholder"
      hideSpacer
    />
  );
};

export default ProposerTitleQuestion;