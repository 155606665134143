import {AppThunkDispatch} from "../../../../../../redux/Store";
import {
  hasMedicalConditionInvalid,
  medicalConditionInvalid
} from "../redux/UpdateAdditionalDriverSlice";
import {UpdateAdditionalDriver} from "../redux/UpdateAdditionalDriver";

export function flagUnansweredMedicalConditionQuestions(
  {hasMedicalCondition, medicalCondition}: UpdateAdditionalDriver,
  dispatch: AppThunkDispatch
): void {
  if (hasMedicalCondition.status === "default") {
    dispatch(hasMedicalConditionInvalid("quote.errors.questionIncomplete"));
  } else if (hasMedicalCondition.value === true && medicalCondition.status === "default") {
    dispatch(medicalConditionInvalid("quote.errors.questionIncomplete"));
  }
}
