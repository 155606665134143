import {CustomisedAbiListItem} from "shared-components/dist/models/CustomisedAbiListItem";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {AtFault} from "shared/dist/generated/graphql/resolvers-types";

export const FAULT_STATUSES = [
  {
    vtCode: AtFault.Yes,
    description: lookupI18nString("structure.questions.claims.accidentFault.own")
  },
  {
    vtCode: AtFault.NoBlame,
    description: lookupI18nString("structure.questions.claims.accidentFault.thirdParty")
  },
  {
    vtCode: AtFault.Partial,
    description: lookupI18nString("structure.questions.claims.accidentFault.partial")
  },
  {
    vtCode: AtFault.Pending,
    description: lookupI18nString("structure.questions.claims.accidentFault.pending")
  },
] as const;

export type AtFaultOption = CustomisedAbiListItem<AtFault>;

export const FAULT_STATUS_OPTIONS = FAULT_STATUSES
  .filter(fault => fault.vtCode !== AtFault.Pending)
  .map(fault => fault.vtCode);
export const FAULT_STATUS_DESCRIPTIONS = FAULT_STATUSES
  .filter(fault => fault.vtCode !== AtFault.Pending)
  .map(fault => fault.description);

export const PENDING_FAULT = FAULT_STATUSES.find(fault => fault.vtCode === AtFault.Pending);
export const OWN_FAULT = FAULT_STATUSES.find(fault => fault.vtCode === AtFault.Yes);