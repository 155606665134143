import {
  dateOfBirthAsDateSelector
} from "../../../../pages/quote/vehicle/your-details/redux/selectors/PersonalDetailsSelectors";
import {
  dateOfBirthChanged,
  dateOfBirthInvalid,
  dateOfBirthReset
} from "../../../../pages/quote/vehicle/your-details/redux/PersonalDetailsSlice";
import {
  isOldEnoughToBeAHomeowner
} from "../../../../pages/quote/vehicle/shared/questions/date-of-birth/validators/IsOldEnoughToBeAHomeowner";
import {
  dateOfBirthCrossFieldValidators,
  dateOfBirthValidators
} from "../../shared/driver-validation/DateOfBirthValidators";
import {buildCrossFieldValidator, ReduxQuestion} from "../../Questions";

export const dateOfBirth: ReduxQuestion<Date> = {
  selector: dateOfBirthAsDateSelector,
  onChange: dateOfBirthChanged,
  onInvalid: dateOfBirthInvalid,
  onReset: dateOfBirthReset,
  validators: dateOfBirthValidators,
  crossFieldValidators: [
    ...dateOfBirthCrossFieldValidators("personalDetails"),
    buildCrossFieldValidator(
      state => state.personalDetails.isHomeowner.value,
      [isOldEnoughToBeAHomeowner]
    ),
  ]
};