import Typeahead, {TypeaheadGenericProp} from "../Typeahead";
import {FormField} from "shared-components/dist/models/form-field/FormField";
import {Option} from "../models/Option";
import {CustomisedAbiListItem} from "shared-components/dist/models/CustomisedAbiListItem";

export const StringTypeahead = Typeahead as TypeaheadGenericProp<string>;

export function toStringOptionFromCustomisedAbiListItem(formField: FormField<CustomisedAbiListItem | undefined>): Option<string> | undefined {
  if (!formField.value) return undefined;

  return {
    value: formField.value.vtCode,
    label: formField.value.description
  };
}

export function toStringOptionFromString(formField: FormField<string>): Option<string> | undefined {
  if (!formField.value) return undefined;

  return {
    value: formField.value,
    label: formField.value
  };
}