import React, {FC} from "react";
import {ReactComponent as PdfIcon} from "../../../../assets/icons/documents/pdf-file-icon.svg";
import "./DocumentList.css";
import {format} from "date-fns";
import {PolicyDocument} from "shared/dist/generated/graphql/resolvers-types";
import {mapDocumentTypeToTitle} from "../../policies/sub-pages/documents/current-documents/current-document/helpers/MapDocumentTypeToTitle";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import {calculateDocumentFileSize} from "shared-components/dist/utils/helpers/display/DocumentFileSizeConverter";

interface Props {
  className?: string;
  documents: PolicyDocument[];
  showDate?: boolean;
}

const DocumentList: FC<Props> = ({
  className,
  documents,
  showDate
}) => (
  <div className={`document-list${className ? ` ${className}` : ""}`}>
    {documents.map((document, index) => (
      <a
        href={document.url}
        className="document-list__item"
        key={index}
        target="_blank"
        rel="noreferrer"
        tabIndex={0}
      >
        <span className="document-list__item__icon">
          <PdfIcon/>
        </span>
        <span className="document-list__item__title">
          {lookupI18nString(mapDocumentTypeToTitle(document.type))}
          {document.fileSize ? ` - ${calculateDocumentFileSize(document.fileSize)}` : ""}
        </span>
        {showDate && document.effectiveDate &&
          <span className="document-list__item__effective-date">
            {format(new Date(document.effectiveDate), "do MMM, yyyy")}
          </span>
        }
      </a>
    ))}
  </div>
);

export default DocumentList;