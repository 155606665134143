import {useAppSelector} from "../../../../../redux/Hooks";
import quoteValidationErrorsSelectors from "../redux/quote-validation-errors/QuoteValidationErrorSelectors";
import {useFeatureFlags} from "shared-components/dist/feature-flags/hooks/UseFeatureFlags";

export const useHasQuoteValidationErrors = (): boolean => {
  const quoteValidationErrorTotal = useAppSelector(quoteValidationErrorsSelectors.selectTotal);
  const {compareNiPhase2Flag} = useFeatureFlags();

  if (!compareNiPhase2Flag) return false;

  return (quoteValidationErrorTotal > 0);
};