import React, {FC, ReactNode} from "react";
import Question from "../../../../../../../structure/questions/question/Question";
import {StringRadioColumn} from "shared-components/dist/form/radio-column/variants/StringRadioColumn";
import {ProspectiveLoanWithPrice} from "shared/dist/generated/graphql/resolvers-types";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./DepositAndPaymentsQuestion.css";
import Dropdown from "shared-components/dist/form/dropdown/Dropdown";
import {updateLoanDeposit} from "../../../../your-quote/redux/thunks/UpdateLoanDeposit";
import {LoadingStatus} from "../../../../your-quote/models/LoadingStatus";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";
import {updateLoanRatesNew} from "../../../../your-quote/redux/thunks/UpdateLoanRates";
import {CurrencyFormat} from "../../../../../../../utils/money/CurrencyFormat";

const DepositAndPaymentsQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const quotePrice = useAppSelector(state => state.quoteDetails.quotePrice);
  const paymentFrequency = useAppSelector(state => state.quoteDetails.paymentFrequency);
  const depositPercentage = useAppSelector(state => state.quoteDetails.depositPercentage);
  const prospectiveLoans = useAppSelector(state => state.quoteDetails.prospectiveLoansWithPrice);
  const closeBrothersLoanStatus = useAppSelector(state => state.quoteDetails.closeBrothersLoanStatus);
  const selectedLoan = useAppSelector(state => state.quoteDetails.selectedLoanWithPrice);

  if (!quotePrice || paymentFrequency !== "Monthly" || !prospectiveLoans || !selectedLoan) return null;

  function setSelectedRate(rateReference: string): void {
    dispatch(updateLoanRatesNew(prospectiveLoans, rateReference));
  }

  function getFormattedInstalmentAmount(prospectiveLoan: ProspectiveLoanWithPrice): string {
    return closeBrothersLoanStatus === LoadingStatus.SUCCESS
      ? CurrencyFormat.formatPriceWithSymbol(prospectiveLoan.subsequentInstalmentAmounts)
      : lookupI18nString("quoteDetails.depositAndPayments.placeholder");
  }

  function buildRadioOptions(): ReactNode[] {
    return prospectiveLoans.map(loan => (
      <span key={loan.rateReference}>
        {loan.numberOfInstalments}
        {lookupI18nString("quoteDetails.depositAndPayments.radioOption")}
        {getFormattedInstalmentAmount(loan)}
      </span>
    ));
  }

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const depositPercentage = parseInt(event.target.value);
    dispatch(updateLoanDeposit(depositPercentage));
  };

  return (
    <Question
      className="deposit-and-payments"
      title="quoteDetails.depositAndPayments.title"
      id="deposit-and-payments"
      description="quoteDetails.depositAndPayments.description"
    >
      <>
        <Dropdown
          name="deposit-percentage"
          options={selectedLoan.loanDepositOptions.map(loanDepositOption => loanDepositOption.percentage.toString())}
          onChange={onChange}
          value={depositPercentage.toString()}
          displayValues={selectedLoan.loanDepositOptions.map(loanDepositOption => {
            return `${CurrencyFormat.formatPriceWithSymbol(loanDepositOption.price)} - ${loanDepositOption.percentage}%`;
          })}
        />

        <StringRadioColumn
          options={prospectiveLoans.map(prospectiveLoan => prospectiveLoan.rateReference)}
          name="selected-rate"
          selectedOption={selectedLoan.rateReference}
          onSelection={setSelectedRate}
          wrapOnDesktop={false}
        >
          {buildRadioOptions()}
        </StringRadioColumn>
      </>
    </Question>
  );
};

export default DepositAndPaymentsQuestion;
