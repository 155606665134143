import React, {FC, useState} from "react";
import {
  LICENCE_COUNTRIES_OF_ISSUE,
  LICENCE_COUNTRIES_OF_ISSUE_OPTIONS,
  LicenceCountryOfIssue
} from "./LicenceCountryOfIssue";
import {FormField} from "shared-components/dist/models/form-field/FormField";
import LicenceCountryOfIssueStop from "./LicenceCountryOfIssueStop";
import {StringRadioColumnQuestion} from "../../../../../../structure/questions/question/variants/radio-column-question/StringRadioColumnQuestion";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {CountryOfIssue} from "shared/dist/generated/graphql/resolvers-types";
import Rag from "shared-components/dist/information/rag/Rag";
import "./LicenceCountryOfIssueQuestion.css";

interface OwnProps {
  id: string;
  title: TranslationKey;
  licenceCountryOfIssueField: FormField<LicenceCountryOfIssue>;
  onComplete: (value: LicenceCountryOfIssue) => void;
  isStopVisible: boolean;
}

const LicenceCountryOfIssueQuestion: FC<OwnProps> = (
  {
    id,
    title,
    licenceCountryOfIssueField,
    onComplete,
    isStopVisible
  }
) => {
  const [isRoiLicenseMessageDisplayed, setIsRoiLicenseMessageDisplayed] = useState<boolean>(false);

  const onLicenceCountryOfIssueChanged = (selectedLicenceCountryOfIssue: string): void => {
    const country = LICENCE_COUNTRIES_OF_ISSUE.find(country => country.description === selectedLicenceCountryOfIssue);

    if (country) {
      if (country.id === CountryOfIssue.RepublicOfIreland) {
        setIsRoiLicenseMessageDisplayed(true);
      } else {
        setIsRoiLicenseMessageDisplayed(false);
      }
      onComplete(country);
    }
  };

  return (
    <>
      <StringRadioColumnQuestion
        questionProps={{
          id: id,
          title: title,
          status: licenceCountryOfIssueField.status,
          errorMessage: licenceCountryOfIssueField.errorMessage,
        }}
        radioColumnProps={{
          options: LICENCE_COUNTRIES_OF_ISSUE_OPTIONS,
          name: "country-of-issue-select",
          selectedOption: licenceCountryOfIssueField.value?.description,
          onSelection: onLicenceCountryOfIssueChanged
        }}
      />
      {isRoiLicenseMessageDisplayed && <Rag className={"roi-licence-message-rag"} description={"personalDetails.licenceCountryOfIssueQuestion.roiLicenceMessage"}/>}
      <LicenceCountryOfIssueStop
        isVisible={isStopVisible}
      />
    </>
  );
};
export default LicenceCountryOfIssueQuestion;