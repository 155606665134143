import {VehicleDetails} from "../redux/VehicleDetails";

export function hasKnownOwnerAndKeeper(vehicleDetails: VehicleDetails): boolean {
  const isOwnerAndRegisteredKeeper = vehicleDetails.isOwnerAndRegisteredKeeper;
  if (isOwnerAndRegisteredKeeper.status === "success" && isOwnerAndRegisteredKeeper.value) return true;

  const owner = vehicleDetails.owner;
  const registeredKeeper = vehicleDetails.registeredKeeper;

  return isOwnerAndRegisteredKeeper.value === false && owner.value !== undefined && registeredKeeper.value !== undefined;
}