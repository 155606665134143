import React, {FC} from "react";
import "./UnderageFinanceStop.css";
import {underageFinanceStopVisibleSelector} from "../../../../your-quote/redux/selectors/UnderageFinanceStopVisibleSelector";
import QuoteJourneyStop from "../../../stops/quote-journey-stop/QuoteJourneyStop";
import {useAppSelector} from "../../../../../../../redux/Hooks";

const UnderageFinanceStop: FC = () => {
  const isVisible = useAppSelector(underageFinanceStopVisibleSelector);

  return (
    <QuoteJourneyStop
      className="underage-finance-stop"
      stopProps={{
        visible: isVisible,
        title: "quoteDetails.tooYoungForFinanceStop.title",
        description: "quoteDetails.tooYoungForFinanceStop.description"
      }}
    />
  );
};

export default UnderageFinanceStop;