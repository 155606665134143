import React, {FC} from "react";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./RenewalStatusPill.css";
import {RenewalInviteStatus} from "shared/dist/generated/graphql/resolvers-types";
import {Maybe} from "graphql/jsutils/Maybe";
import {isFuture} from "date-fns";

interface Props {
  status: Maybe<RenewalInviteStatus>;
  expiryTimestamp?: string;
}

export const RenewalStatusPill: FC<Props> = ({status, expiryTimestamp}) => {
  if (!status) return null;

  const getStatusString = (): string | undefined => {
    switch (status) {
      case RenewalInviteStatus.AwaitingInvite:
      case RenewalInviteStatus.InviteInProgress:
        return lookupI18nString("dashboard.policyCard.renewalStatusPill.renewComingSoon");
      case RenewalInviteStatus.RenewalOffered:
      case RenewalInviteStatus.RebrokeOnly:
      case RenewalInviteStatus.RenewalOfferedWithException:
        if (expiryTimestamp && isFuture(new Date(expiryTimestamp))) {
          return lookupI18nString("dashboard.policyCard.renewalStatusPill.timeToRenew");
        }
        return undefined;
      default: return undefined;
    }
  };

  if (!getStatusString()) return null;

  return (
    <div className="renewal-status-pill" data-testid="renewal-status-pill">
      {getStatusString()}
    </div>
  );
};