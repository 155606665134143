import {
  QuoteValidationError as GraphQLQuoteValidationError,
  QuoteValidationErrorSection as GraphQLQuoteValidationErrorSection
} from "shared/dist/generated/graphql/resolvers-types";
import {Maybe} from "graphql/jsutils/Maybe";
import {
  QuoteValidationError
} from "../quote/vehicle/your-quote/redux/quote-validation-errors/QuoteValidationError";

export const mapQuoteValidationErrors = (errors: Maybe<Array<GraphQLQuoteValidationError>> | undefined): QuoteValidationError[] => {
  if (!errors) {
    return [];
  }

  return errors.map(error => {
    if (error.section === GraphQLQuoteValidationErrorSection.AdditionalDriver) {
      return {
        id: error.errorUUID,
        section: error.section,
        driverId: error.driverId as string,
      };
    }

    return {
      id: error.errorUUID,
      section: error.section,
    };
  });
};