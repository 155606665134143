import {gql} from "@apollo/client";
import {FindAddressParams, FindAddressResult} from "shared/dist/generated/graphql/resolvers-types";
import {apolloClient} from "../../../apollo/ApolloClientProvider";

const FIND_ADDRESS = gql`
  query FindAddress($params: FindAddressParams!) {
    address {
      findAddress(params: $params) {
        id
        isAddress
        description
      }
    }
  }
`;

export interface FindAddressQueryVariables {
  params: FindAddressParams;
}

export interface FindAddressResults {
  address: {
    findAddress: FindAddressResult[];
  };
}

export function findAddressBy(search: string, id?: string): Promise<FindAddressResult[]> {
  return apolloClient.query<FindAddressResults, FindAddressQueryVariables>({
    query: FIND_ADDRESS,
    variables: {
      params: {
        id,
        search
      }
    }
  })
  .then(result => Promise.resolve(result.data.address.findAddress))
  .catch(error => Promise.reject(error));
}
