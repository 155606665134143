import React, {FC, useState} from "react";
import DisplayAccountSetting from "../../account-setting/display-account-setting/DisplayAccountSetting";
import EditAccountSetting from "../../account-setting/edit-account-setting/EditAccountSetting";
import PhoneNumberInput from "shared-components/dist/form/phone-number-input/PhoneNumberInput";
import {
  sendPhoneNumberVerificationCode,
  updatePhoneNumber,
  verifyPhoneNumber
} from "../../../../../../user/attributes/services/phone/UpdatePhoneNumberService";
import {redactPhoneNumber} from "../../../../../../user/attributes/services/phone/PhoneNumberRedactionService";
import {EditAccountSettingState} from "../../account-setting/edit-account-setting/state/EditAccountSettingState";
import {
  getConfirmationCodeInputErrorMessage,
  getSendCodeButtonState,
  getSendCodeInputErrorMessage,
  getVerifyButtonState,
  isConfirmationCodeEnteredInvalid,
  isEditAccountSettingVisible,
  isEditAccountValueInvalid,
  isEditingAccountSetting,
  onAccountSettingInputChange,
  onEditAccountSettingToggled,
  onInvalidAccountSetting,
  onResendConfirmationCode,
  onSendConfirmationCode,
  onValidAccountSetting,
  onVerifyAccountSetting,
  shouldDisplayConfirmationCodeInput
} from "../../account-setting/edit-account-setting/state/EditAccountSettingStateHandlers";
import {EditAccountSettingError} from "../../account-setting/edit-account-setting/state/EditAccountSettingError";
import {isPossiblePhoneNumber} from "react-phone-number-input";
import {logPortalGoogleAnalyticsEvent} from "../../../../../../utils/analytics/PortalAnalytics";

interface Props {
  phoneNumber: string;
  isPhoneNumberUnverified: boolean;
  onPhoneNumberUpdated: () => void;
  onPhoneNumberVerified: () => void;
}

const PhoneNumberAccountSetting: FC<Props> = (
  {
    phoneNumber,
    isPhoneNumberUnverified,
    onPhoneNumberUpdated,
    onPhoneNumberVerified
  }
) => {
  const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState(isPhoneNumberUnverified ? phoneNumber : "");
  const [state, setState] = useState<EditAccountSettingState>(isPhoneNumberUnverified ? "CodeSent" : "Closed");
  const [error, setError] = useState<EditAccountSettingError>("None");

  return (
    <>
      <DisplayAccountSetting
        name="phone-number"
        headerText="accountSettings.header.phoneNumber"
        value={redactPhoneNumber(phoneNumber)}
        onEditToggled={() => {
          onEditAccountSettingToggled(setState, setError, state);
          logPortalGoogleAnalyticsEvent({
            categorySuffix: "account.edit.phone_number.section",
            action: "Click"
          });
        }}
        isEditingAccountSetting={isEditingAccountSetting(state)}
      />
      <EditAccountSetting
        accountSettingInputComponent={
          <PhoneNumberInput
            name="edit-phone-number"
            onComplete={() => onValidAccountSetting(setState, setError)}
            onInvalid={() => onInvalidAccountSetting(setState, setError, setUpdatedPhoneNumber)}
            onChange={(inputValue) => onAccountSettingInputChange(
              setState,
              setError,
              setUpdatedPhoneNumber,
              inputValue,
              isPossiblePhoneNumber
            )}
          />
        }
        isVisible={isEditAccountSettingVisible(state)}
        sendCodeButtonState={getSendCodeButtonState(state)}
        showSendCodeInputErrorMessage={isEditAccountValueInvalid(state, error)}
        sendCodeInputErrorMessage={getSendCodeInputErrorMessage(error, "accountSettings.form.invalidPhoneNumber")}
        onSendConfirmationCode={() => onSendConfirmationCode(
          isPossiblePhoneNumber,
          setState,
          setError,
          updatedPhoneNumber,
          updatePhoneNumber,
          onPhoneNumberUpdated
        )}
        onResendConfirmationCode={() => onResendConfirmationCode(setState, setError, sendPhoneNumberVerificationCode)}
        shouldShowConfirmationCodeInput={shouldDisplayConfirmationCodeInput(state, error)}
        verifyButtonState={getVerifyButtonState(state)}
        codeSentDestination={updatedPhoneNumber}
        showConfirmationCodeInputErrorMessage={isConfirmationCodeEnteredInvalid(error)}
        confirmationCodeInputErrorMessage={getConfirmationCodeInputErrorMessage(error)}
        onVerifyAccountSetting={(verificationCode: string) => onVerifyAccountSetting(
          setState,
          setError,
          verifyPhoneNumber,
          verificationCode,
          onPhoneNumberVerified
        )}
      />
    </>
  );
};

export default PhoneNumberAccountSetting;
