import {
  AggregatorQuoteResult,
  QuoteMutationGetAggregatorQuoteArgs
} from "shared/dist/generated/graphql/resolvers-types";
import {apolloClient} from "../../../apollo/ApolloClientProvider";
import {GET_AGGREGATOR_QUOTE} from "shared/dist/graphql/queries/quote/GetAggregatorQuote";
import {GetAggregatorQuoteResult} from "shared/dist/models/quote/GetAggregatorQuoteResult";

export async function getAggregatorQuoteMutation(getAggregatorQuoteMutationArgs: QuoteMutationGetAggregatorQuoteArgs): Promise<AggregatorQuoteResult> {
  const response = await apolloClient.mutate<GetAggregatorQuoteResult, QuoteMutationGetAggregatorQuoteArgs>({
    mutation: GET_AGGREGATOR_QUOTE,
    variables: {
      quoteReference: getAggregatorQuoteMutationArgs.quoteReference,
      policyExtras: getAggregatorQuoteMutationArgs.policyExtras
    }
  });

  if (!response.data?.quote.getAggregatorQuote) throw new Error(`No aggregator quote found for reference: ${getAggregatorQuoteMutationArgs.quoteReference}`);

  return response.data.quote.getAggregatorQuote;
}
