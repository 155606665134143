import {FC} from "react";
import InformationCard from "shared-components/dist/cards/information-card/InformationCard";
import "./QuestionSetCompanyCarIneligibleInfoCard.css";

const QuestionSetCompanyCarIneligibleInfoCard: FC = () => (
  <InformationCard
    className="question-set-company-car-ineligible-info-card"
    title="coverDetails.companyCar.forYourInformation.title"
    description="coverDetails.companyCar.forYourInformation.description"
  />
);

export default QuestionSetCompanyCarIneligibleInfoCard;