import {FC, useEffect} from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import {PORTAL} from "../models/Routes";
import {checkForValidSession} from "../../pages/user-authentication/login/redux/thunks/CheckForValidSession";
import {useAppDispatch, useAppSelector} from "../../redux/Hooks";

const UnauthenticatedRoute: FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const currentSessionState = useAppSelector(state => state.userAuthentication.currentSessionState);

  useEffect(() => {
    dispatch(checkForValidSession());
  });

  if (currentSessionState === "Invalid") return <Outlet/>;
  if (currentSessionState === "Valid") {
    return <Navigate to={location?.state?.previousAuthenticatedRoute ?? PORTAL} replace/>;
  }

  return <></>;
};

export default UnauthenticatedRoute;
