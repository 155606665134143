import React, {FC} from "react";
import {genderChanged} from "../../redux/UpdateAdditionalDriverSlice";
import GenderQuestion from "../../../../shared/questions/gender/GenderQuestion";
import {GenderOption} from "../../../../shared/questions/gender/Genders";
import {logAdditionalDriverGoogleAnalyticsEvent} from "../../../../../../../utils/analytics/MotorAnalytics";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const AdditionalDriverGenderQuestion: FC = () => {
  const dispatch = useAppDispatch();
  const genderField = useAppSelector(state => state.updateAdditionalDriver.gender);

  const onGenderChanged = (gender: GenderOption): void => {
    logAdditionalDriverGoogleAnalyticsEvent({
      categorySuffix: "gender",
      action: gender.description
    });

    dispatch(genderChanged(gender));
  };

  return (
    <GenderQuestion
      id="additional-driver-gender"
      title="additionalDriver.genderQuestion.title"
      tooltip={{
        description: "additionalDriver.genderQuestion.tooltip.description"
      }}
      genderField={genderField}
      genderChanged={onGenderChanged}
    />
  );
};

export default AdditionalDriverGenderQuestion;