import React, {FC} from "react";
import RemindersPaginationButtons, {ReminderPaginationButtonsProps} from "./RemindersPaginationButtons";
import "./RemindersMobilePaginationButtons.css";

const RemindersMobilePaginationButtons: FC<ReminderPaginationButtonsProps> = (props) => (
  <div className="reminders-mobile-pagination-buttons">
    <RemindersPaginationButtons className="reminders-mobile-pagination-buttons__pagination_buttons" {...props}/>
  </div>
);

export default RemindersMobilePaginationButtons;
