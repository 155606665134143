import {FC} from "react";
import NotificationBanner from "shared-components/dist/notification-banner/NotificationBanner";
import {useLocation, useNavigate} from "react-router-dom";
import {buildQuoteRoute} from "../../../../../../../utils/navigation/NavigationHelpers";
import {COMPARE_QUOTES} from "../../../../../../../router/models/Routes";
import {useAppSelector} from "../../../../../../../redux/Hooks";
import {selectIsSelectedQuoteTheCheapest} from "../../../../shared/redux/IsSelectedQuoteTheCheapestSelector";

const CheaperQuoteNotificationBanner: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isSelectedQuoteTheCheapest = useAppSelector(selectIsSelectedQuoteTheCheapest);
  const requotedWithoutComparison = useAppSelector(state => state.quoteDetails.requotedWithoutComparison);

  if (isSelectedQuoteTheCheapest || !requotedWithoutComparison) return null;

  return (
    <NotificationBanner
      status="warning"
      title="quoteDetails.notificationBanner.changeOfDetails"
      description="quoteDetails.notificationBanner.changeOfDetails.foundACheaperQuote"
      callToAction={{
        title: "quoteDetails.notificationBanner.changeOfDetails.callToAction",
        onClick: () => navigate(buildQuoteRoute(location.pathname, COMPARE_QUOTES), {state: {fromQuotePage: true}})
      }}
    />
  );
};

export default CheaperQuoteNotificationBanner;