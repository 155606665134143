import React, {FC} from "react";
import {ArrowBack} from "@mui/icons-material";
import {TranslationKey} from "shared-components/dist/translations/TranslationKey";
import {lookupI18nString} from "shared-components/dist/translations/LookupI18nString";
import "./SidebarWithBackNavigation.css";

interface OwnProps {
  title: TranslationKey;
  onBackButtonClicked: () => void;
}

const SidebarWithBackNavigation: FC<OwnProps> = (
  {
    title,
    onBackButtonClicked,
    children
  }
) => (
  <div className="sidebar-with-back-nav">
    <button
      className="sidebar-with-back-nav__button"
      onClick={() => onBackButtonClicked()}
    >
      <ArrowBack/>
      {lookupI18nString(title)}
    </button>

    {children && (
      <div className="sidebar-with-back-nav__body">
        {children}
      </div>
    )}
  </div>
);

export default SidebarWithBackNavigation;