import React, {FC} from "react";
import {makeSelected} from "../../../../../../../redux/Actions";
import {makeInvalid} from "../../../redux/ManualVehicleLookupFormSlice";
import {StringTypeaheadSubQuestion} from "../../../../../../../structure/questions/sub-question/variants/typeahead-sub-question/variants/StringTypeaheadSubQuestion";
import {useMakesProps} from "./hooks/UseMakesProps";
import {toStringOptionFromString} from "../../../../../../../structure/form/typeahead/variants/StringTypeahead";
import {useAppDispatch, useAppSelector} from "../../../../../../../redux/Hooks";

const YourVehicleMake: FC = () => {
  const dispatch = useAppDispatch();
  const make = useAppSelector(state => state.manualVehicleLookupForm.make);

  const {status, onMakeSelected, makes} = useMakesProps(make, make => dispatch(makeSelected(make)));
  if (status === "error") dispatch(makeInvalid("vehicleQuote.manualVehicleLookup.errors.makesFetchFailed"));

  return (
    <StringTypeaheadSubQuestion
      id="manual-vehicle-lookup-make"
      title="vehicleQuote.manualVehicleLookup.make"
      errorMessage={make.errorMessage}
      status={status}
      typeaheadProps={{
        options: makes,
        selectedOption: toStringOptionFromString(make),
        onSelection: onMakeSelected,
        name: "manual-vehicle-lookup-make-typeahead",
        loading: status === "loading",
        placeholder: "vehicleQuote.manualVehicleLookup.make.typeahead.placeholder"
      }}
    />
  );
};

export default YourVehicleMake;
