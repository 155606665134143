import React, {FC} from "react";
import QuotePriceCardRow from "../QuotePriceCardRow/QuotePriceCardRow";
import {useAppSelector} from "../../../../../../../../../redux/Hooks";
import {useQuotePriceProps} from "../../hooks/UseQuotePricesProps";
import {FormattedPrice} from "../../../../../../../../../utils/money/Price";
import FormattedMessage from "shared-components/src/translations/components/FormattedMessage";

const AnnualQuotePriceCardBody: FC = () => {
  const insurerName = useAppSelector(state => state.quoteDetails.insurerName);
  const {
    premiumAmount,
    totalUnfinancedAmountPayable,
    totalExtrasAmount,
    policyExtras,
    adminFee
  } = useQuotePriceProps();

  const hasPolicyExtras = !!policyExtras?.length;

  return (
    <div role="rowgroup" className="quote-price__column" >
      <QuotePriceCardRow
        id="insurer"
        name={<FormattedMessage id={"quoteDetails.priceCard.insurerName"}/>}
        value={insurerName}
        filled
      />

      <QuotePriceCardRow
        id="premium"
        name={<FormattedMessage id={"quoteDetails.priceCard.insurancePremium"}/>}
        value={<FormattedPrice value={premiumAmount}/>}
      />

      {adminFee &&
        <QuotePriceCardRow
          id="adminFee"
          name={<FormattedMessage id={"quoteDetails.priceCard.adminFee"}/>}
          value={<FormattedPrice value={adminFee}/>}
        />
      }

      {hasPolicyExtras &&
        <QuotePriceCardRow
          id="extras"
          name={<FormattedMessage id={"quoteDetails.priceCard.extras"}/>}
          value={<FormattedPrice value={totalExtrasAmount}/>}
        />
      }

      <QuotePriceCardRow
        id="totalAmount"
        name={<FormattedMessage id={"quoteDetails.priceCard.totalPayable"}/>}
        value={<FormattedPrice value={totalUnfinancedAmountPayable}/>}
      />
    </div>
  );
};

export default AnnualQuotePriceCardBody;
