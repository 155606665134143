import {FC} from "react";
import InformationPanel from "shared-components/dist/information/panel/InformationPanel";
import "./CreditSearchesStatement.css";
import Spacer from "shared-components/dist/spacer/Spacer";

const CreditSearchesStatement: FC = () => {
  return (
    <>
      <Spacer/>
      <InformationPanel
        title="coverDetails.creditSearchesStatement.title"
        description="coverDetails.creditSearchesStatement.description"
        className="credit-searches-statement"
      />
    </>
  );
};

export default CreditSearchesStatement;