import React, {FC} from "react";
import DropdownSubQuestion
  from "../../../../../../../structure/questions/sub-question/variants/dropdown-sub-question/DropdownSubQuestion";
import {Status} from "shared-components/dist/status-indicator/Status";
import IntegerInputSubQuestion
  from "../../../../../../../structure/questions/sub-question/variants/integer-input-sub-question/IntegerInputSubQuestion";
import {ALCOHOL_TEST_METHOD_OPTIONS, AlcoholTestMethodOption, toAlcoholTestMethod} from "../models/AlcoholTestMethods";
import {Conviction} from "../models/Conviction";
import {isDrinkDrivingConvictionType} from "../validation/IsDrinkDrivingConvictionType";
import {TooltipProps} from "shared-components/dist/tooltips/tooltip/Tooltip";

interface Props {
  conviction: Partial<Conviction>;
  onAlcoholTestMethodChanged: (value: AlcoholTestMethodOption | undefined) => void;
  alcoholTestMethodStatus: Status;
  onAlcoholLevelRecordedChanged: (value: number | undefined) => void;
  alcoholLevelRecordedStatus: Status;
  isAdditionalDriverForm: boolean;
}

const DrinkDrivingSubQuestions: FC<Props> = (
  {
    conviction,
    onAlcoholTestMethodChanged,
    alcoholTestMethodStatus,
    onAlcoholLevelRecordedChanged,
    alcoholLevelRecordedStatus,
    isAdditionalDriverForm
  }
) => {
  if (!conviction.type || !isDrinkDrivingConvictionType(conviction.type.parentCategory)) return null;

  const tooltip: TooltipProps = isAdditionalDriverForm
    ? {
      title: "additionalDriver.convictionsQuestions.subQuestion.alcoholLevelRecorded.tooltip.title",
      description: "additionalDriver.convictionsQuestions.subQuestion.alcoholLevelRecorded.tooltip.description"
    }
    : {
      title: "personalDetails.convictionsQuestions.subQuestion.alcoholLevelRecorded.tooltip.title",
      description: "personalDetails.convictionsQuestions.subQuestion.alcoholLevelRecorded.tooltip.description"
    };

  return <>
    <DropdownSubQuestion
      id="alcohol-test-method-sub-question"
      title={
        isAdditionalDriverForm
          ? "additionalDriver.convictionsQuestions.subQuestion.alcoholTestMethod.title"
          : "personalDetails.convictionsQuestions.subQuestion.alcoholTestMethod.title"
      }
      options={ALCOHOL_TEST_METHOD_OPTIONS}
      value={conviction.testMethod?.description}
      onChange={value => onAlcoholTestMethodChanged(toAlcoholTestMethod(value))}
      errorMessage="quote.errors.questionIncomplete"
      status={alcoholTestMethodStatus}
    />

    <IntegerInputSubQuestion
      id="alcohol-level-recorded-sub-question"
      title={
        isAdditionalDriverForm
          ? "additionalDriver.convictionsQuestions.subQuestion.alcoholLevelRecorded.title"
          : "personalDetails.convictionsQuestions.subQuestion.alcoholLevelRecorded.title"
      }
      value={conviction.alcoholLevel}
      onAnswer={onAlcoholLevelRecordedChanged}
      errorMessage="quote.errors.questionIncomplete"
      status={alcoholLevelRecordedStatus}
      placeholder="personalDetails.convictionsQuestions.subQuestion.alcoholLevelRecorded.placeholder"
      tooltip={tooltip}
    />
  </>;
};

export default DrinkDrivingSubQuestions;